import React, { FC, useEffect } from "react";
import { Grid } from "@mui/material";
import { PaperButton } from "../../../../components";
import { Person } from "../../../../domain-common/person";

function loadScriptOnce(src: string) {
  const existingScript = document.querySelector(`script[src="${src}"]`);
  if (existingScript) {
    return;
  }

  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  document.head.appendChild(script);
}
interface DynamicWidgetProps {
  person: Person;
  title: string;
  link: string;
  iconUrl: string;
}
export const DynamicWidget: FC<DynamicWidgetProps> = ({ person, iconUrl, title, link }) => {
  useEffect(() => {
    loadScriptOnce(link);
  }, [link]);

  return (
    <Grid item xs={6}>
      <PaperButton
        title={title}
        small
        onClick={() => {
          (window as any).Widget.init({
            firstName: person.firstName,
            lastName: person.lastName,
            email: person.email,
            toggle: true
          });
        }}
        iconUrl={iconUrl}
      />
    </Grid>
  );
};
