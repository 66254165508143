import React, { FC, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Button, Heading1, Paragraph, Subtitle, useAuth } from "@likemagic-tech/sv-magic-library";
import { MyStayTipList } from "../../portal/components/my-stay-tip-list";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { MyStayHero } from "../../portal/components/my-stay-hero";
import { useArticles } from "../../../util/hooks/use-articles";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { usePropertyCmsData } from "../../../state/property-cms/use-property-cms-data";
import { usePropertyById } from "../../property/use-property-by-id";
import { useNavigate } from "react-router";
import { useSetupInfoStayPageTopNav } from "../use-setup-info-stay-page-top-nav";
import { useGetSearchParamPropertyId } from "../use-get-search-param-propertyId";
import { useAppDispatch } from "../../../state/store";
import { setLastPropertyId } from "../../restore-magic-context/restore-magic-context.slice";

export const InfoStayPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const propertyId = useGetSearchParamPropertyId();
  const { getIsAuthenticated } = useAuth();
  const { authEnabled } = useFeatureFlags();
  useSetupInfoStayPageTopNav({
    propertyId
  });

  const dispatch = useAppDispatch();

  const propertyIdFromUrl = new URL(window.location.href).searchParams.get("propertyId");

  useEffect(() => {
    dispatch(setLastPropertyId(propertyIdFromUrl));
  }, [dispatch, propertyIdFromUrl]);

  const { tips } = useArticles({
    magicId: "",
    propertyId,
    authEnabled,
    authenticated: getIsAuthenticated(),
    isTravelBuddy: false,
    serviceCodes: null,
    unitGroupId: ""
  });
  const { propertyImages, propertyName } = usePropertyCmsData(propertyId);
  const property = usePropertyById(propertyId);
  const navigate = useNavigate();

  return (
    <Box>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <MyStayHero images={propertyImages}>
            <Box textAlign="center" py={2}>
              <Heading1>{propertyName}</Heading1>
              <Subtitle mb={3}>{property.location}</Subtitle>
              <Button onClick={() => navigate("/")}>{t("buttons__find_reservation")}</Button>
            </Box>
          </MyStayHero>
        </Grid>
      </Grid>
      {tips && (
        <Box ml={2.5} mt={2.5}>
          <Paragraph color="textSecondary">{t("labels__tips")}</Paragraph>
        </Box>
      )}
      {tips && <MyStayTipList tips={tips} fixedTips={[]} />}
    </Box>
  );
};
