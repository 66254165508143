import { Workbox } from "workbox-window";

type WaitingListener = (registration: ServiceWorkerRegistration) => any;

export class ServiceWorkerController {
  private wb: Workbox | undefined;
  private waitingListeners = new Set<WaitingListener>();

  private _currentWaitingServiceWorkerRegistration: ServiceWorkerRegistration | null = null;

  get currentWaitingServiceWorkerRegistration(): ServiceWorkerRegistration | null {
    return this._currentWaitingServiceWorkerRegistration;
  }

  skipWaiting() {
    const registration = this.currentWaitingServiceWorkerRegistration;
    if (registration?.waiting) {
      // Send a message to the waiting service worker,
      // instructing it to activate.
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  }

  addWaitingListener(listener: WaitingListener) {
    this.waitingListeners.add(listener);
  }

  removeWaitingListener(listener: WaitingListener) {
    this.waitingListeners.delete(listener);
  }

  setWb(wb: Workbox) {
    this.wb = wb;
  }

  getWb() {
    return this.wb;
  }

  onWaitingServiceWorker(registration: ServiceWorkerRegistration) {
    this._currentWaitingServiceWorkerRegistration = registration;
    this.dispatchWaitingEvent(registration);
  }

  private dispatchWaitingEvent(registration: ServiceWorkerRegistration) {
    for (const listener of this.waitingListeners) {
      listener(registration);
    }
  }
}
