import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DesktopCard, SubmitButton } from "../../components";
import { ContentDetails } from "../portal/components/content-details";
import { useNavbar } from "../../components/organism/top-navbar/navbar-context";
import { useIsMobile } from "../../components/layouts/hooks/use-is-mobile";
import { NotFoundPage } from "../../components/organism/not-found.page";
import { useSetupSubpageNavigation } from "../portal/hooks/use-setup-subpage-navigation";
import { useServiceDetails } from "./use-service-details";
import { isStatusLoading } from "../../state/EntityStateStatus";
import { generatePortalMyStayKeysUrl } from "../../util/routing";
import { useMagicIdParams } from "../magic/use-magic-id-params";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { daysBeforeNow } from "@likemagic-tech/sv-magic-library";

export const ServiceDetailPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const [searchParams] = useSearchParams();
  const { magicId } = useMagicIdParams();
  const { setDisplayNavbar } = useNavbar();
  const isMobile = useIsMobile();
  useSetupSubpageNavigation();
  const { data: serviceDetailCMSData, status } = useServiceDetails(serviceId);
  const navigate = useNavigate();
  const serviceDate = searchParams.get("serviceDate");
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigateToKeysPage = useCallback(() => {
    navigate(generatePortalMyStayKeysUrl(magicId));
  }, [navigate, magicId]);

  const dateDiffToday = useMemo(() => daysBeforeNow(serviceDate || ""), [serviceDate]);

  const subtitleWithKey = useMemo(() => {
    if (dateDiffToday > 0) {
      return t("labels__service_key_subtitle_item_in_future");
    } else if (dateDiffToday < 0) {
      return t("labels__service_key_subtitle_item_in_past");
    }
    return t("labels__service_key_subtitle_item_today");
  }, [dateDiffToday, t]);

  useEffect(() => {
    setDisplayNavbar(!isMobile);
  }, [setDisplayNavbar, isMobile]);

  if (!serviceId) {
    return <NotFoundPage />;
  }
  return (
    <DesktopCard>
      {!isStatusLoading(status) && (
        <ContentDetails
          title={serviceDetailCMSData?.title || serviceId}
          subtitle={serviceDate ? subtitleWithKey : serviceDetailCMSData?.subtitle || serviceId}
          coverImage={serviceDetailCMSData?.coverImage}
          description={serviceDetailCMSData?.description || []}
        />
      )}

      {dateDiffToday === 0 && (
        <SubmitButton
          onClick={navigateToKeysPage}
          label={"buttons__keys"}
          hasBottomNavigation
          hasWhiteBackground
        />
      )}
    </DesktopCard>
  );
};
