import { Box, Divider } from "@mui/material";
import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import {
  CONFIRMATION_TYPE_KEY,
  ConfirmationType,
  DesktopCard,
  DisplayCmsSvg,
  RoundBox,
  SubmitButton
} from "../../components";
import { TotalPrice } from "../../components/molecules/total-price";
import { useAppDispatch } from "../../state/store";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { generatePortalMyStayShopUrl, generatePortalPaymentNavigate } from "../../util/routing";
import { HelmetTitle } from "../gtm/helmet-title";
import { useMagicIdParams } from "../magic/use-magic-id-params";
import { usePreparePayment } from "../payment/use-prepare-payment";
import { useSetupSubpageNavigation } from "../portal/hooks/use-setup-subpage-navigation";
import { CartListItem } from "./components/cart-list-item";
import { useCartPage } from "./hooks/use-cart-page";
import { updateCart } from "./store/cart.slice";
import { PageHeadingInfo } from "../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { useCMSData } from "../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../state/common-cms/common-cms.slice";
import { useReservationContext } from "../reservation-provider/reservation-provider";
import { useFeatureFlags } from "../../util/hooks/use-configuration";
import { toGross, toNet, toVat } from "../../domain-common/full-price";
import { useShopTotalPrice } from "./hooks/use-shop-total-price";

export const CartPage: FC = () => {
  const { showVat } = useFeatureFlags();
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const dispatch = useAppDispatch();
  const { reservation } = useReservationContext();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  useSetupSubpageNavigation(generatePortalMyStayShopUrl);

  const { cartLineItems } = useCartPage();
  const { totalPrice } = useShopTotalPrice();

  const handleOnCountChange = useCallback(
    (serviceId: string, count: number) =>
      dispatch(updateCart({ item: { quantity: count, serviceId }, magicId })),
    [dispatch, magicId]
  );

  const handleDeleteService = useCallback(
    (serviceId: string) => dispatch(updateCart({ item: { quantity: 0, serviceId }, magicId })),
    [dispatch, magicId]
  );

  const { preparePayment } = usePreparePayment(magicId);

  const preparePaymentForCart = useCallback(async () => {
    const shopItems = cartLineItems.map((cli) => ({
      serviceId: cli.product.serviceId,
      quantity: cli.quantity
    }));
    await preparePayment({ shopItems });
  }, [preparePayment, cartLineItems]);

  const handlePayButton = useCallback(async () => {
    try {
      await preparePaymentForCart();
      navigate(
        generatePortalPaymentNavigate(magicId, {
          [CONFIRMATION_TYPE_KEY]: ConfirmationType.SHOP
        })
      );
    } catch (e) {
      console.error("Cart page - Something went wrong with preparing cart", e);
    }
  }, [navigate, magicId, preparePaymentForCart]);

  const isCartEmpty = cartLineItems.length === 0;

  return (
    <>
      <HelmetTitle suffix="Shop cart" />
      <RoundBox hasShadow py={3} px={2}>
        <DesktopCard>
          <PageHeadingInfo
            title={tCommon("title__cart_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__cart_icon?.url} />}
          />

          {isCartEmpty && (
            <>
              <Box mt={10} px={5}>
                <Paragraph align="center">{tCommon("labels__empty_cart")}</Paragraph>
              </Box>
              <SubmitButton
                onClick={() => navigate(generatePortalMyStayShopUrl(magicId))}
                label={tCommon("buttons__continue_shopping")}
                hasBottomNavigation
              />
            </>
          )}
          <Box px={2.5} py={2}>
            {cartLineItems.map((cli) => (
              <Box key={cli.product.serviceId || "" + cli.quantity} pb={2.5}>
                <CartListItem
                  propertyId={reservation.propertyId}
                  cartListItem={cli}
                  onCountChange={handleOnCountChange}
                  onDeleteServiceFromCart={handleDeleteService}
                />
              </Box>
            ))}
          </Box>
          {!isCartEmpty && (
            <Box p={2.5}>
              <Divider />
              {showVat && (
                <>
                  <Box my={2}>
                    <TotalPrice
                      price={toNet(totalPrice)}
                      titleGray={true}
                      priceGray={true}
                      title={tCommon("labels__net_amount")}
                    />
                  </Box>
                  <Box my={2}>
                    <TotalPrice
                      price={toVat(totalPrice)}
                      titleGray={true}
                      priceGray={true}
                      title={tCommon("labels__vat_amount")}
                    />
                  </Box>
                </>
              )}
              <TotalPrice price={toGross(totalPrice)} title={tCommon("labels__total_to_be_paid")} />
              <Box py={1.5}>
                <Divider />
              </Box>
              <SubmitButton
                variant="primary"
                onClick={handlePayButton}
                label={tCommon("buttons__pay")}
              />
            </Box>
          )}
        </DesktopCard>
      </RoundBox>
    </>
  );
};
