import { PaymentMethodsResponse } from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types";
import { PaymentDTO } from "../features/payment/payment-dto";
import { BaseApiClient, JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { Price } from "../domain-common/price";
import { AdyenResponse } from "../domain-common/adyen-responses";

const BASE_URL = "/api/guest-journey-service/magic";

class AdyenApiClient extends BaseApiClient {
  async paymentByPaymentAccount(
    magicId: string,
    data: PaymentDTO,
    init?: RequestInit
  ): Promise<void> {
    const url = `${BASE_URL}/${magicId}/shop/payment-by-payment-account`;

    const response = await this.fetchApi(url, {
      ...init,
      method: "POST",
      body: JSON.stringify(data)
    });

    if (response.status === 204) {
      return new VoidApiResponse(response).value();
    }
    /* TODO handle errors:
      * 'errors_pay_by_payment_account'
        => Apaleo interaction (booking services/shop items/...) succeeded, but payment failed
        => Ideally sent to 'open balance' page:
          * fetch the folios to be paid
          * DON'T pass again the paymentDTO
      * any other error
        => we don't know if Apaleo interactions succeeded
        => we don't want to double book services/shop items
        => The usual error handling of the shop? In particular, don't try to send same payload again?
    */
    return new VoidApiResponse(response).value();
  }

  async fetchPaymentMethods(magicId: string, price: Price, init?: RequestInit): Promise<any> {
    const url = `${BASE_URL}/${magicId}/payment-methods`;

    let response: any = await this.fetchApi(url, {
      ...init,

      method: "POST",
      body: JSON.stringify(price)
    });

    return new JSONApiResponse<PaymentMethodsResponse>(response).value();
  }

  async payment(
    magicId: string,
    data: { dropinPayload?: any; paymentDTO: PaymentDTO },
    init?: RequestInit
  ): Promise<AdyenResponse | void> {
    const url = `${BASE_URL}/${magicId}/shop/payment`;

    const response = await this.fetchApi(url, {
      ...init,
      method: "POST",
      body: JSON.stringify(data)
    });

    if (response.status === 204) {
      return new VoidApiResponse(response).value();
    }
    return new JSONApiResponse<AdyenResponse>(response).value();
  }

  async skipPayment(
    magicId: string,
    data: PaymentDTO,
    init?: RequestInit
  ): Promise<AdyenResponse | void> {
    const url = `${BASE_URL}/${magicId}/shop/skip-payment`;

    const response = await this.fetchApi(url, {
      ...init,
      method: "POST",
      body: JSON.stringify(data)
    });

    return new VoidApiResponse(response).value();
  }

  async paymentDetails(
    magicId: string,
    data: any,
    init?: RequestInit
  ): Promise<AdyenResponse | void> {
    const url = `${BASE_URL}/${magicId}/shop/payment-details`;

    const response = await this.fetchApi(url, {
      ...init,
      method: "POST",
      body: JSON.stringify(data)
    });

    if (response.status === 204) {
      return new VoidApiResponse(response).value();
    }
  }
}

export const PaymentApi = new AdyenApiClient();
