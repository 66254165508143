import { useEffect } from "react";
import { useIsMobile } from "../../components/layouts/hooks/use-is-mobile";

export const useOnScroll = (cb: (yPos: number) => void) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    if (isMobile) {
      let ticking = false;
      let lastKnownScrollPosition = 0;
      const listener = () => {
        lastKnownScrollPosition = window.scrollY;

        if (!ticking) {
          window.requestAnimationFrame(function () {
            cb(lastKnownScrollPosition);
            ticking = false;
          });

          ticking = true;
        }
      };

      window.addEventListener("scroll", listener);

      return () => {
        window.removeEventListener("scroll", listener);
      };
    }
  }, [cb, isMobile]);
};
