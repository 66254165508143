import { FC } from "react";
import { Box } from "@mui/material";
import { useFlowTemplateStyles } from "../hooks/use-flow-template-styles";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";

export const GuestFlowBackgroundBox: FC<
  React.PropsWithChildren<{
    progressBarIsHidden?: boolean;
    bottomNavBarIsHidden?: boolean;
    topNavbarIsHidden?: boolean;
  }>
> = ({ children, progressBarIsHidden, bottomNavBarIsHidden, topNavbarIsHidden }) => {
  const { classes } = useFlowTemplateStyles({
    progress: !progressBarIsHidden,
    bottomNavbarHidden: bottomNavBarIsHidden,
    topNavbarHidden: topNavbarIsHidden
  });
  const isMobile = useIsMobile();
  return (
    <Box className={classes.box}>
      <Box sx={{ pt: isMobile ? 2 : 0 }}>{children}</Box>
    </Box>
  );
};
