import { useMemo } from "react";
import { useAuth } from "@likemagic-tech/sv-magic-library";

import { ReservationStatus } from "../../../domain-common/reservation-status";
import { Reservation } from "../../../domain-common/reservation";
import { useUserInfo } from "./use-user-info";

export enum Editability {
  EDITABLE = "EDITABLE",
  NOT_EDITABLE_NOT_OWNER = "NOT_EDITABLE_NOT_OWNER",
  NOT_EDITABLE_CHECKED_OUT = "NOT_EDITABLE_CHECKED_OUT",
  NOT_EDITABLE_IN_HOUSE = "NOT_EDITABLE_IN_HOUSE",
  NOT_EDITABLE_CONFIRMED = "NOT_EDITABLE_CONFIRMED",
  NOT_EDITABLE_NO_SHOW = "NOT_EDITABLE_NO_SHOW",
  NOT_EDITABLE_REUPLOADED = "NOT_EDITABLE_REUPLOADED",
  NOT_EDITABLE_ID_STATUS_CONFIRMED = "NOT_EDITABLE_ID_STATUS_CONFIRMED"
}

// exported so it can be tested ...
export function computeEditability(
  reservation: Reservation,
  uneditableStatuses: ReservationStatus[],
  hasAdminOrOperatorRole: () => boolean,
  userInfoLocal?: string
) {
  if (
    !reservation.keycloakUUID ||
    reservation.keycloakUUID === userInfoLocal ||
    hasAdminOrOperatorRole()
  ) {
    if (
      reservation.status === ReservationStatus.CHECKED_OUT &&
      uneditableStatuses.includes(reservation.status)
    ) {
      return Editability.NOT_EDITABLE_CHECKED_OUT;
    }
    if (
      reservation.status === ReservationStatus.NO_SHOW &&
      uneditableStatuses.includes(reservation.status)
    ) {
      return Editability.NOT_EDITABLE_NO_SHOW;
    }
    if (
      reservation.status === ReservationStatus.IN_HOUSE &&
      uneditableStatuses.includes(reservation.status)
    ) {
      return Editability.NOT_EDITABLE_IN_HOUSE;
    }
    if (
      reservation.status === ReservationStatus.CONFIRMED &&
      uneditableStatuses.includes(reservation.status)
    ) {
      return Editability.NOT_EDITABLE_CONFIRMED;
    }

    return Editability.EDITABLE;
  }
  return Editability.NOT_EDITABLE_NOT_OWNER;
}

export const useGuestDataEditable = ({
  uneditableStatus,
  reservation
}: {
  uneditableStatus: ReservationStatus[];
  reservation?: Reservation;
}) => {
  const { hasAdminOrOperatorRole } = useAuth();
  const { userInfo, loaded: userInfoLoaded } = useUserInfo();

  const editable = useMemo(() => {
    if (reservation && userInfoLoaded) {
      return computeEditability(
        reservation,
        uneditableStatus,
        hasAdminOrOperatorRole,
        userInfo?.sub
      );
    }

    return Editability.EDITABLE;
  }, [userInfo?.sub, reservation, hasAdminOrOperatorRole, uneditableStatus, userInfoLoaded]);

  return {
    // Note: This will go undefined -> EDITABILITY.something, and never to another EDITABILTY.something_else
    // This is important, because (some) of the forms/input fields don't react nicely to being instantiated with `disabled=true` and then going `disabled=false`
    editable
  };
};
