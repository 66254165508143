import React, { FC, useEffect } from "react";
import { useNavbar } from "../../components/organism/top-navbar/navbar-context";
import { Button } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { HelmetTitle } from "../gtm/helmet-title";
import { PageHeadingInfo } from "../../components/molecules/page-heading-info";
import { Box, Divider } from "@mui/material";
import { useNavigate } from "react-router";
import { FolioPreview } from "../../components/organism/folio/folio-preview";
import { mapFolio } from "../../components/organism/folio/folio-preview.util";
import { FieldArray, Form, Formik } from "formik";
import { TotalPrice } from "../../components/molecules/total-price";
import { useSecondScreenReservation } from "../second-screen-home-page/hooks/useSecondScreenReservation";
import { SecondScreenEventType } from "../../graphql/generated/graphql";
import { useSecondScreenAction } from "../second-screen-checkin/hooks/use-second-screen-action";
import { Surcharges } from "../../components/organism/surcharges/surcharges";

export const SecondScreenCheckBillPage: FC = () => {
  const { setDisplayNavbar } = useNavbar();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { secondScreenAction } = useSecondScreenAction();

  const navigate = useNavigate();

  const { secondScreenReservation: reservation, resetSecondScreenReservation } =
    useSecondScreenReservation();

  useEffect(() => {
    setDisplayNavbar(true);
  }, [setDisplayNavbar]);

  if (!reservation) {
    return <></>;
  }

  const onConfirm = () => {
    secondScreenAction(
      SecondScreenEventType.BillingConfirmed,
      reservation?.magicId,
      reservation?.magicToken,
      {
        accepted: true,
        reason: "Success"
      }
    ).unwrap();
    resetSecondScreenReservation();
    navigate("/");
  };

  return (
    <>
      <HelmetTitle suffix="Guest flow personal data" />
      <Box
        my={5.75}
        px={10}
        mx="auto"
        maxWidth={1200}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <PageHeadingInfo title={tCommon("labels__please_check_your_bill")} />
        <Formik
          enableReinitialize
          initialValues={{
            folios: reservation.folios.map((folio: any) => mapFolio(folio, reservation, tCommon))
          }}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <FieldArray
                name="folios"
                render={() => (
                  <>
                    {formik.values.folios?.map((folio: any, index: number) => (
                      <Box key={folio.id} mt={4}>
                        <FolioPreview
                          folio={folio}
                          reservation={reservation}
                          index={index}
                          readOnly
                        />
                      </Box>
                    ))}
                  </>
                )}
              />
            </Form>
          )}
        </Formik>
        <Box mb={1.5} mt={5}>
          <Divider />
        </Box>
        <TotalPrice
          price={{
            amount: reservation.totalAllowedPayment.amount,
            currency: reservation.totalAllowedPayment.currency
          }}
          title={tCommon("labels__open_for_payment")}
          priceBold={true}
        />
        <Button variant="primary" onClick={onConfirm} sx={{ marginTop: 2 }}>
          {tCommon("buttons__confirm_charges")}
        </Button>
        <Surcharges />
      </Box>
    </>
  );
};
