import { PortalPagesEnum, PortalPageToPath } from "../features/portal/portal-page-to-path";
import { ProfilePagesEnum, ProfilePageToPath } from "../features/portal/profile-page-to-path";
import { MyStayPagesEnum, MyStayPageToPath } from "../features/portal/my-stay-page-to-path";
import { SearchBookingPagesToPath } from "../features/search-for-bookings/search-booking-navigation";
import { SearchBookingPages } from "../features/search-for-bookings/search-booking-pages";
import { BookingOverviewPages } from "../features/booking-overview/booking-overview-pages";
import { generateInitBookingOverviewUrl } from "../features/booking-overview/booking-overview-navigation";
import { CONFIRMATION_TYPE_KEY, ConfirmationType } from "../components";
import { generatePath } from "react-router-dom";
import { CheckoutFlowPages } from "../features/checkout-flow/checkout-flow-pages";
import { CheckoutFlowCheckpointToPath } from "../features/checkout-flow/checkout-flow-checkpoint-to-path";

export function getPwaStartUrl() {
  return window.location.origin;
}

export function getSearchUrl(pathName: string) {
  return window.location.origin + `/search${pathName}`;
}

export const getLocationOrigin = () => window.location.origin;

export const generateMagicLink = (magicId: string) => `/magic/${magicId}`;

export const generateSearchPageUrl = () => "/search";
export const generateContactPageUrl = (propertyId?: string) =>
  "/contact".concat(propertyId ? `?propertyId=${propertyId}` : "");

export const generatePrivacyPolicyPageUrl = () => "/privacy-policy";

export const generatePortalUrl = (magicId: string) => `${generateMagicLink(magicId)}/portal`;

export const generatePortalMyStayUrl = (magicId: string) =>
  `${generatePortalUrl(magicId)}${PortalPageToPath[PortalPagesEnum.MY_STAY]}`;

export const generatePortalMyStayKeysUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.KEYS]}`;

export const generatePortalMyStayAdditionalServicesUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.SERVICES]}`;

export const generatePortalMyStayAdditionalBookedServicesDetailsUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.SERVICES]}${
    MyStayPageToPath[MyStayPagesEnum.SERVICES_DETAIL]
  }`;

export const generatePortalMyStayAdditionalBookedServicesListUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.SERVICES]}${
    MyStayPageToPath[MyStayPagesEnum.SERVICES_LIST]
  }`;

export const generatePortalMyStayExtendStayBillUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.EXTEND_STAY_BILL]}`;

export const generatePortalMyStayBuddiesUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.BUDDIES]}`;

export const generatePortalMyStayBillsUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.BILLS]}`;
export const generatePortalMyStayOpenBalanceUrl = (
  magicId: string,
  queryParams?: {
    [CONFIRMATION_TYPE_KEY]: ConfirmationType;
  }
) => {
  return {
    pathname: `${generatePortalMyStayUrl(magicId)}${
      MyStayPageToPath[MyStayPagesEnum.OPEN_BALANCE]
    }`,
    search: confirmationTypeURLParams(new URLSearchParams(queryParams))
  };
};

export const generatePortalMyStayAllBookingsUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.ALL_BOOKINGS]}`;

export const generatePortalMyStayShopUrl = (magicId: string, query = "") =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.SHOP] + query}`;

export const generatePortalBoxShopPaymentSuccessUrl = (magicId: string, deviceId?: string) =>
  generatePath(
    `${generatePortalMyStayUrl(magicId)}${
      MyStayPageToPath[MyStayPagesEnum.BOX_SHOP_PAYMENT_SUCCESS]
    }`,
    { deviceId }
  );
export const generatePortalSelfPouringStationShopPaymentSuccessUrl = (
  magicId: string,
  deviceId?: string
) =>
  generatePath(
    `${generatePortalMyStayUrl(magicId)}${
      MyStayPageToPath[MyStayPagesEnum.SELF_POURING_STATION_SHOP_PAYMENT_SUCCESS]
    }`,
    { deviceId }
  );
//Dynamic Shop start
export const generatePortalSelfPouringStationShopUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${
    MyStayPageToPath[MyStayPagesEnum.SELF_POURING_STATION_SHOP]
  }`;
export const generatePortalDynamicShopBillOverviewUrl = (magicId: string, shopType: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP]}${
    MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP_BILL_OVERVIEW]
  }?shopType=${shopType}`;
export const generatePortalDynamicShopPaymentUrl = (magicId: string, shopType: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP]}${
    MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP_PAYMENT]
  }?shopType=${shopType}`;
export const generatePortalDynamicShopConfirmationUrl = (magicId: string, shopType: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP]}${
    MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP_CONFIRMATION]
  }?shopType=${shopType}`;
export const generatePortalDynamicShopUrl = (magicId: string, shopType: string) =>
  `${generatePortalMyStayUrl(magicId)}${
    MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP]
  }?shopType=${shopType}`;
//Dynamic Shop end

export const generatePortalBoxShopUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.BOX_SHOP]}`;
export const generatePortalRoomControlUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.ROOM_CONTROL]}`;
export const generatePortalBoxRentUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.BOX_RENT]}`;
export const generatePortalBoxItemRentUrl = (magicId: string, boxId: string) =>
  generatePath(
    `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.BOX_RENT_ITEM]}`,
    { boxId }
  );

export const generatePortalMyStayCartUrl = (magicId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.CART]}`;

export const generatePortalMyStayArticleDetailsUrl = (magicId: string, articleId: string) =>
  `${generatePortalMyStayUrl(magicId)}${MyStayPageToPath[MyStayPagesEnum.ARTICLES]}/${articleId}`;

export const generatePortalProfileUrl = (magicId: string) =>
  `${generatePortalUrl(magicId)}${PortalPageToPath[PortalPagesEnum.PROFILE]}`;

export const generatePortalProfilePagesUrl = (magicId: string, page: ProfilePagesEnum) =>
  `${generatePortalUrl(magicId)}${PortalPageToPath[PortalPagesEnum.PROFILE]}${
    ProfilePageToPath[page]
  }`;

export const generatePortalProfileAccountUrl = (magicId: string) =>
  `${generatePortalUrl(magicId)}${PortalPageToPath[PortalPagesEnum.PROFILE]}${
    ProfilePageToPath[ProfilePagesEnum.ACCOUNT]
  }`;
export const generatePortalProfileAccountCreatedUrl = (magicId: string) =>
  `${generatePortalUrl(magicId)}${PortalPageToPath[PortalPagesEnum.PROFILE]}${
    ProfilePageToPath[ProfilePagesEnum.ACCOUNT_CREATED]
  }`;
export const generatePortalProfileAllBookingsUrl = (magicId: string) =>
  `${generatePortalProfileUrl(magicId)}${ProfilePageToPath[ProfilePagesEnum.ALL_BOOKINGS]}`;

export const generatePortalPaymentUrl = (magicId: string) =>
  `${generatePortalUrl(magicId)}${PortalPageToPath[PortalPagesEnum.PAYMENT]}`;

export const confirmationTypeURLParams = (queryParams: URLSearchParams) => {
  return `?${queryParams.toString()}`;
};

export const generatePortalPaymentNavigate = (
  magicId: string,
  queryParams?: {
    confirmationType: ConfirmationType;
    deviceId?: string;
  }
) => {
  return {
    pathname: `${generatePortalUrl(magicId)}${PortalPageToPath[PortalPagesEnum.PAYMENT]}`,
    search: confirmationTypeURLParams(new URLSearchParams(queryParams))
  };
};

export const generatePortalPaymentSuccessUrl = (magicId: string) =>
  generatePortalUrl(magicId) + PortalPageToPath[PortalPagesEnum.PAYMENT_SUCCESS];

export const generatePortalPaymentRedirectUrl = (magicId: string) =>
  generatePortalUrl(magicId) + PortalPageToPath[PortalPagesEnum.PAYMENT_REDIRECT];

export const generatePortalEventDetailsUrl = (magicId: string, eventId: string) =>
  `${generatePortalUrl(magicId)}${PortalPageToPath[PortalPagesEnum.EVENTS]}/${eventId}`;

export const isInternalLink = (link?: string): boolean => Boolean(link?.startsWith("/"));

export const generatePortalCheckoutSuccessUrl = (magicId: string) =>
  `${generatePortalUrl(magicId)}${PortalPageToPath[PortalPagesEnum.CHECKOUT_SUCCESS]}`;

export const generateSearchBookingCartBookingsUrl = () =>
  SearchBookingPagesToPath[SearchBookingPages.CART_BOOKINGS];

export const generatePostLoginRedirectUrl = (redirectTo: string, magicId: string) => {
  switch (redirectTo) {
    case PortalPagesEnum.PROFILE:
      return generatePortalProfileUrl(magicId);
    case BookingOverviewPages.PROPERTIES:
      return generateInitBookingOverviewUrl(magicId);
    case PortalPagesEnum.MY_STAY:
    default:
      return generatePortalUrl(magicId);
  }
};

//Checkout flow begin
export const generateCheckoutFlowUrl = (magicId: string) =>
  `${generateMagicLink(magicId)}/booking/checkout`;
export const generateCheckoutFlowCheckoutDepartureTimePageUrl = (magicId: string) =>
  `${generateMagicLink(magicId)}/booking/checkout${
    CheckoutFlowCheckpointToPath[CheckoutFlowPages.DEPARTURE_TIME]
  }`;
export const generateCheckoutFlowMiniBarPageUrl = (magicId: string) =>
  `${generateMagicLink(magicId)}/booking/checkout${
    CheckoutFlowCheckpointToPath[CheckoutFlowPages.MINIBAR]
  }`;

export const generateCheckoutFlowBillPageUrl = (magicId: string) =>
  `${generateMagicLink(magicId)}/booking/checkout${
    CheckoutFlowCheckpointToPath[CheckoutFlowPages.BILL]
  }`;
export const generateCheckoutFlowPaymentPageUrl = (magicId: string) =>
  `${generateMagicLink(magicId)}/booking/checkout${
    CheckoutFlowCheckpointToPath[CheckoutFlowPages.PAYMENT]
  }`;
export const generateCheckoutFlowPaymentRedirectPageUrl = (magicId: string) =>
  `${generateMagicLink(magicId)}/booking/checkout${
    CheckoutFlowCheckpointToPath[CheckoutFlowPages.PAYMENT_REDIRECT]
  }`;
export const generateCheckoutFlowConfirmationUrl = (magicId: string) =>
  `${generateMagicLink(magicId)}/booking/checkout${
    CheckoutFlowCheckpointToPath[CheckoutFlowPages.CONFIRMATION]
  }`;

//Checkout flow end

//Info stay begin
export const generateInfoStayUrl = (propertyId: string) => `/info-stay?propertyId=${propertyId}`;
export const generateInfoStayArticleUrl = (articleId: string, propertyId?: string) =>
  `/info-stay${MyStayPageToPath[MyStayPagesEnum.ARTICLES]}/${articleId}?propertyId=${propertyId}`;
//Info stay end
