import { FC, PropsWithChildren } from "react";
import { overrideComponents } from "./override-theme";
import { getLocationOrigin } from "../../util/routing";
import { TenantThemeProvider, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { useSelector } from "react-redux";
import { selectLastPropertyId } from "../../features/restore-magic-context/restore-magic-context.slice";

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const propertyId = useSelector(selectLastPropertyId);
  const { theme } = usePropertyConfig({ propertyId });

  return (
    <TenantThemeProvider
      overrideComponents={overrideComponents}
      origin={getLocationOrigin()}
      overrideTheme={theme}
    >
      {children}
    </TenantThemeProvider>
  );
};
