import { GtmActor } from "./use-gtm";
import { useLocation } from "react-router-dom";
import { MagicObject } from "../../domain-common/magic-object";

export const useActor = ({ magicObject }: { magicObject: MagicObject | null }) => {
  const { pathname } = useLocation();

  const actor = pathname.includes("booker")
    ? GtmActor.BOOKER
    : magicObject?.id.includes("_TB_")
      ? GtmActor.TRAVEL_BUDDY
      : GtmActor.GUEST;
  return { actor };
};
