import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { LegalForm, prepareLegalForm } from "../../../components/molecules/legal-form";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { LegalFormData } from "../../../domain-common/legal-form-data";
import { MagicFileType } from "../../../domain-common/magic-file-type";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useCommonValidationCmsData } from "../../../state/common-cms/use-common-cms-data";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { TenantContext, useApiVersion, useAuth } from "@likemagic-tech/sv-magic-library";
import { HelmetTitle } from "../../gtm/helmet-title";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { useSaveGuestFlowSessionCheckpoint } from "../checkpoint/use-save-guest-flow-session-checkpoint";
import { getNextFlowUrl, getPrevFlowUrl } from "../guest-flow-sequence";
import { useGuestFlow } from "../use-guest-flow";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { useAppDispatch } from "../../../state/store";
import { useSetupGuestFlowNavigation } from "../hooks/use-setup-guest-flow-navigation";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { isReservation } from "../../../util/flow";
import { isKioskMode } from "../../../util/kiosk-mode";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { useDisabledCountriesForIdentification } from "../../../util/hooks/use-configuration";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { isDisabledCountry } from "../../../util/countries";
import { IdCheckStatus } from "../../../domain-common/id-check-status";
import {
  emptyReservationExtras,
  ReservationExtras
} from "../../../domain-common/reservation-extras";

interface LegalPageProps {}

let CURRENT_PAGE = GuestFlowCheckpoint.LEGAL;

export const LegalPage: React.FC<React.PropsWithChildren<LegalPageProps>> = () => {
  const navigate = useNavigate();
  const {
    reservation,
    contactButton,
    getProgress,
    skipButton,
    patchGuestFlowStep,
    prepareLegalFiles
  } = useGuestFlow();
  const validationCmsData = useCommonValidationCmsData();
  const { keycloak } = useAuth();
  const dispatch = useAppDispatch();
  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });
  const { isBottomNavbarActive } = useBottomNavbar();
  const { apiKey } = useContext(TenantContext);
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { isRESTVersion } = useApiVersion();
  const [initialValues, setInitialValues] = useState<LegalFormData | null>(null);
  const disabledCountriesForIdentification = useDisabledCountriesForIdentification();
  const isIdStatusDeclined = useMemo(
    () => reservation.idCheckStatus === IdCheckStatus.DECLINED,
    [reservation.idCheckStatus]
  );

  useSaveGuestFlowSessionCheckpoint(reservation!, CURRENT_PAGE);

  useEffect(() => {
    if (!reservation || !!initialValues) {
      return;
    }
    prepareLegalForm({
      reservation,
      isRESTVersion,
      isIdStatusDeclined,
      apiKey,
      authToken: keycloak.token
    }).then(setInitialValues);
  }, [
    reservation,
    initialValues,
    keycloak.token,
    dispatch,
    isRESTVersion,
    disabledCountriesForIdentification,
    isIdStatusDeclined,
    apiKey
  ]);

  const handleSubmit = React.useCallback(
    async (values: LegalFormData) => {
      try {
        const { signatureImage, identificationImage } = values;
        const emptyExtras: ReservationExtras = emptyReservationExtras();
        const files = await prepareLegalFiles(signatureImage, identificationImage, initialValues);

        const valuesToPatchLegal = {
          ...reservation,
          primaryGuest: {
            ...reservation.primaryGuest,
            birthdate: !!values.primaryGuest?.birthdate
              ? values.primaryGuest?.birthdate
              : undefined,
            identificationNumber: isDisabledCountry(
              disabledCountriesForIdentification,
              values.primaryGuest.nationalityCountryCode
            )
              ? ""
              : values.primaryGuest.identificationNumber!,
            nationalityCountryCode: values.primaryGuest.nationalityCountryCode!
          },
          extras: {
            ...emptyExtras,
            ...reservation.extras,
            ...{ tcGeneral: values.acceptedTerms, tcOnline: values.acceptedTerms }
          }
        };

        await patchGuestFlowStep({
          reservationValues: valuesToPatchLegal,
          checkpoint: CURRENT_PAGE,
          subUpdate: SubUpdate.LEGAL,
          files: files
        });

        navigate(getNextFlowUrl(CURRENT_PAGE, valuesToPatchLegal));
      } catch (e) {
        console.error("Update legal data went wrong", e);
      }
    },
    [
      navigate,
      patchGuestFlowStep,
      prepareLegalFiles,
      reservation,
      initialValues,
      disabledCountriesForIdentification
    ]
  );

  const onBack = useCallback(() => {
    navigate(getPrevFlowUrl(CURRENT_PAGE, reservation));
  }, [navigate, reservation]);

  const progress = useMemo(() => getProgress(CURRENT_PAGE), [getProgress]);

  const uploadedIdentificationImage = React.useMemo(
    () =>
      reservation.files.find(
        (file) => file.metaData.magicFileType === MagicFileType.IDENTIFICATION_DOCUMENT
      ),
    [reservation]
  );

  const uploadedSignatureImage = React.useMemo(
    () =>
      reservation.files.find(
        (file) => file.metaData.magicFileType === MagicFileType.SIGNATURE_DOCUMENT
      ),
    [reservation]
  );
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { legalDataStepValidation } = useFormValidations();

  const topBarIcons = useMemo(() => {
    if (isReservation(reservation)) {
      if (isKioskMode()) {
        return [skipButton];
      }
      return [contactButton];
    }
    return [];
  }, [reservation, contactButton, skipButton]);

  return (
    <>
      <HelmetTitle suffix="Guest flow legal data" />
      <FlowTemplate handleBack={onBack} icons={topBarIcons} progress={progress}>
        <GuestFlowBackgroundBox>
          <Box p={2} pt={0}>
            <DesktopCard>
              <PageHeadingInfo
                title={tCommon("title__legal_page")}
                subtitle={tCommon("subtitle__legal_page")}
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__legal_icon?.url} />}
              />
              {initialValues && (
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={legalDataStepValidation}
                >
                  {(formik) => (
                    <Box mt={4}>
                      <Form>
                        <LegalForm
                          onChange={formik.handleChange}
                          values={formik.values}
                          touched={formik.touched}
                          errors={formik.errors}
                          setFieldValue={formik.setFieldValue}
                          uploadedSignatureImage={uploadedSignatureImage}
                          uploadedIdentificationImage={uploadedIdentificationImage}
                          labels={{
                            dateOfBirth: tCommon("labels__birthdate"),
                            nationality: tCommon("labels__nationality"),
                            identificationNumber: tCommon("labels__identification_number")
                          }}
                          identificationUploadErrorLabels={{
                            "file-too-large": tCommon("validation__file_too_large"),
                            "file-invalid-type": tCommon("validation__invalid_file_type"),
                            "too-many-files": tCommon("validation__too_many_files")
                          }}
                          identificationUploadLabels={{
                            required: tCommon("validation__required"),
                            deleted: tCommon("labels__passport_deleted_text"),
                            uploadButton: tCommon("buttons__upload"),
                            uploadFromPhone: tCommon("labels__scan_passport"),
                            uploadButtonMobile: tCommon("labels__upload_from_mobile")
                          }}
                          signatureUploadLabels={{
                            required: validationCmsData?.validation__signature_required,
                            buttonLabel: tCommon("buttons__signature"),
                            deletedText: tCommon("labels__signature_deleted_text"),
                            dialogTitle: tCommon("modals__signature_dialog_title"),
                            dialogReset: tCommon("buttons__reset"),
                            dialogConfirm: tCommon("buttons__confirm")
                          }}
                          disabled={formik.isSubmitting}
                          isLegalDataDeclined={isIdStatusDeclined}
                        />
                        <SubmitButton
                          label={formik.dirty ? tCommon("buttons__save") : tCommon("buttons__next")}
                          disabled={formik.isSubmitting}
                          hasWhiteBackground
                          hasBottomNavigation={isBottomNavbarActive}
                        />
                      </Form>
                    </Box>
                  )}
                </Formik>
              )}
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
