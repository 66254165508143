import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCMSData } from "../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../state/common-cms/common-cms.slice";
import { useBottomNavbar } from "../../components/organism/bottom-navbar/bottom-navbar-context";
import { FlowTemplate } from "../../components/layouts/flow-template";
import { HelmetTitle } from "../gtm/helmet-title";
import { RoundedFullHeight } from "../../components/layouts/rounded-full-height";
import { DesktopCard, PrismicRichTextWrapper } from "../../components";
import { Box } from "@mui/material";
import { NotFoundPage } from "../../components/organism/not-found.page";

export const useGetStaticPageData = (): {
  [key: string]: { title: string; content: any };
} => {
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  return (cmsData?.data["main__footer_static_page"]?.reduce(
    (acc: { [key: string]: { title: string; content: any } }, item: any) => {
      acc[item.id] = { title: item.title, content: item.content };
      return acc;
    },
    {}
  ) || {}) as { [key: string]: { title: string; content: any } };
};

export const StaticPage: FC = () => {
  const navigate = useNavigate();
  const { contentId } = useParams<{ contentId: string }>();

  const staticPageData = useGetStaticPageData();

  const { setDisplayBottomNavbar } = useBottomNavbar();

  useEffect(() => {
    setDisplayBottomNavbar(true);
  }, [setDisplayBottomNavbar]);

  if (!(staticPageData && contentId && staticPageData[contentId])) {
    return <NotFoundPage />;
  }

  return (
    <FlowTemplate handleBack={() => navigate(-1)} icons={[]}>
      <HelmetTitle suffix="Privacy policy" />
      <RoundedFullHeight>
        <DesktopCard>
          <Box ml={2.5} mr={2.5} pb={2}>
            <PrismicRichTextWrapper render={staticPageData[contentId]?.content} />
          </Box>
        </DesktopCard>
      </RoundedFullHeight>
    </FlowTemplate>
  );
};
