import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { cancelReservationAction, selectCanceledReservation } from "../../portal/portal.slice";
import { useAppDispatch } from "../../../state/store";
import { Reservation } from "../../../domain-common/reservation";
import { useCancelMutation } from "../../../graphql/mutations/cancel-reservation.generated";
import { useSelector } from "react-redux";
import { QueryStatus } from "@reduxjs/toolkit/query";

export const useCancelReservation = ({ reservation }: { reservation?: Reservation }) => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();
  const [cancelAction, status] = useCancelMutation();
  const isReservationCanceledV1 = useSelector(selectCanceledReservation);

  return {
    cancelAction: () => {
      if (!reservation) {
        return Promise.reject("Reservation not found");
      }
      if (isRESTVersion) {
        return dispatch(cancelReservationAction({ reservation })).then(() => {});
      } else {
        return cancelAction({
          magicId: reservation.magicId,
          magicToken: reservation.magicToken
        }).then(() => {});
      }
    },
    isReservationCanceled: isRESTVersion
      ? isReservationCanceledV1
      : status.status === QueryStatus.fulfilled
  };
};
