import { useCallback, useMemo } from "react";
import { Folio } from "../../../domain-common/folio";
import { Reservation } from "../../../domain-common/reservation";
import { AddressFormData } from "./address-from-data";
import { homeAddressDebitor } from "../../../util/reservation";

export interface UseAddressForm {
  initialValues: AddressFormData | null;
  getReservationValuesToPatch: (
    reservation: Reservation,
    changedValues: AddressFormData
  ) => Reservation;
  isMainFolioExists: boolean;
}

export const useAddressForm = (reservation: Reservation): UseAddressForm => {
  const mainFolio = useMemo(
    () => reservation?.folios.find((folio) => folio.isMainFolio),
    [reservation]
  );

  const shouldShowBillingAddress = useMemo(
    () =>
      !!mainFolio &&
      (mainFolio?.company !== null ||
        (mainFolio?.debitor?.address != null &&
          reservation?.primaryGuest.address != null &&
          (mainFolio?.debitor.address.addressLine1 !==
            reservation?.primaryGuest.address.addressLine1 ||
            mainFolio?.debitor.address.postalCode !== reservation.primaryGuest.address.postalCode ||
            mainFolio?.debitor.address.city !== reservation.primaryGuest.address.city ||
            mainFolio?.debitor.address.countryCode !==
              reservation.primaryGuest.address.countryCode ||
            mainFolio?.debitor.company))),
    [mainFolio, reservation]
  );

  const initialValues: AddressFormData | null = useMemo(() => {
    if (!reservation) {
      return null;
    }
    const mainAddress = {
      addressLine1: reservation.primaryGuest.address?.addressLine1 ?? "",
      postalCode: reservation.primaryGuest.address?.postalCode ?? "",
      city: reservation.primaryGuest.address?.city ?? "",
      countryCode: reservation.primaryGuest.address?.countryCode ?? ""
    };
    const billingAddress = {
      firstName: mainFolio?.debitor.firstName ?? "",
      lastName: mainFolio?.debitor.name ?? "",
      companyName: mainFolio?.debitor.company?.name ?? "",
      companyTaxId: mainFolio?.debitor.company?.taxId ?? "",
      addressLine1: mainFolio?.debitor.address?.addressLine1 ?? "",
      postalCode: mainFolio?.debitor.address?.postalCode ?? "",
      city: mainFolio?.debitor.address?.city ?? "",
      countryCode: mainFolio?.debitor.address?.countryCode ?? "",
      email: mainFolio?.debitor.email ?? "",
      reference: mainFolio?.debitor.reference ?? ""
    };

    return {
      mainAddress,
      isSameAddress: !shouldShowBillingAddress,
      areNamesRequired: shouldShowBillingAddress && mainFolio?.company === null,
      billingAddress,
      isBillingAddressEditable:
        mainFolio?.company === null || mainFolio?.company.canCheckOutOnAr === false
    };
  }, [shouldShowBillingAddress, reservation, mainFolio]);

  const getReservationValuesToPatch = useCallback(
    (reservation: Reservation, changedValues: AddressFormData) => {
      const valuesToPatch = {
        ...reservation,
        primaryGuest: {
          ...reservation.primaryGuest,
          address: {
            ...reservation.primaryGuest.address,
            ...changedValues.mainAddress
          }
        }
      };
      if (changedValues.isSameAddress && changedValues.isBillingAddressEditable) {
        const debitor = homeAddressDebitor(reservation);
        valuesToPatch.folios = valuesToPatch.folios.map((f: Folio) => ({
          ...f,
          debitor: {
            ...debitor,
            address: {
              ...debitor.address,
              ...changedValues.mainAddress
            }
          }
        }));
      } else {
        valuesToPatch.folios = valuesToPatch.folios.map((f: Folio) => ({
          ...f,
          debitor: {
            ...f.debitor,
            firstName: changedValues.billingAddress.firstName,
            name: changedValues.billingAddress.lastName,
            company: {
              ...f.debitor.company,
              name: changedValues.billingAddress.companyName,
              taxId: changedValues.billingAddress.companyTaxId
            },
            email: changedValues.billingAddress.email,
            reference: changedValues.billingAddress.reference,
            address: {
              ...f.debitor.address,
              addressLine1: changedValues.billingAddress.addressLine1,
              postalCode: changedValues.billingAddress.postalCode,
              city: changedValues.billingAddress.city,
              countryCode: changedValues.billingAddress.countryCode
            }
          }
        }));
      }
      return valuesToPatch;
    },
    []
  );

  return { initialValues, getReservationValuesToPatch, isMainFolioExists: !!mainFolio };
};
