import React, { FC, useMemo } from "react";
import { BackArrowIcon } from "../../icons";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { IconButton, SxProps, Theme } from "@mui/material";
import { useTheme } from "@mui/styles";

export interface BackButtonProps {
  onClick?: () => void;
  sx?: SxProps<Theme> | undefined;
}

export const BackButton: FC<BackButtonProps> = ({ onClick, sx }) => {
  const isMobile = useIsMobile();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const backButtonLabel = useMemo(() => t("buttons__back"), [t]);

  const theme = useTheme();

  return (
    <IconButton
      onClick={onClick}
      size="large"
      sx={{
        ...sx,
        mb: isMobile ? 0 : 2,
        ml: isMobile ? -1.5 : 0,
        borderRadius: theme.shape.borderRadius,
        ".MuiSvgIcon-root": {
          path: {
            stroke: isMobile ? undefined : theme.palette.common.black
          }
        }
      }}
    >
      <BackArrowIcon />
      {!isMobile && (
        <Paragraph ml={1} fontSize={20}>
          {backButtonLabel}
        </Paragraph>
      )}
    </IconButton>
  );
};
