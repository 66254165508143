import React, { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { PrismicImage } from "../../../state/property-cms/use-unit-group-cms-data";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

const useStyles = makeStyles()((theme) => ({
  surcharge: {
    display: "flex",
    alignItems: "center",
    borderRadius: 6,
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    padding: theme.spacing(0.5),
    gap: theme.spacing(0.25),
    background: theme.palette.common.white
  }
}));

export const Surcharges: FC = () => {
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { classes } = useStyles();
  const { tfeFeatureEnabled } = useFeatureFlags();

  const surcharges: { image: string; percentage: string }[] = useMemo(() => {
    return cmsData?.data?.surcharges?.map(
      (item: { surcharge_icon: PrismicImage; surcharge_percent: string }) => {
        return {
          image: item.surcharge_icon.url,
          percentage: item.surcharge_percent
        };
      }
    );
  }, [cmsData]);

  if (!cmsData?.data?.surcharges || cmsData?.data?.surcharges?.length === 0 || !tfeFeatureEnabled) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      my={2}
      gap={1}
    >
      <Paragraph> {tCommon("labels__additional_surcharges")}</Paragraph>
      <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
        {surcharges?.map((item, index) => (
          <Box key={index} className={classes.surcharge}>
            <img src={item.image} alt="surcharges" />
            <Paragraph sx={{ fontSize: "14px" }}>{item.percentage}</Paragraph>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
