import { useCommonCmsData } from "../../state/common-cms/use-common-cms-data";

export const useCityById = (cityId: string) => {
  const cmsCommonData = useCommonCmsData();

  const listOfTranslations: [{ id: string; text: string }] | undefined =
    cmsCommonData?.data.main__list_of_cities;

  return listOfTranslations?.find((item) => item?.id === cityId)?.text || cityId;
};
