import React, { useCallback, useMemo } from "react";
import { useKioskStyle } from "./find-reservation-kiosk.style";
import { Box, Grid } from "@mui/material";
import { Heading1 } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { KioskOption, KioskOptionProp, KioskOptionType } from "./kiosk-option";
import { getKioskId, hideAssignKeyForKioskMode } from "../../../util/kiosk-mode";
import { useNavigate } from "react-router-dom";
import { generateSearchPageUrl } from "../../../util/routing";
import { useKioskConfig } from "../../../util/hooks/use-configuration";
import { useCommonCmsData } from "../../../state/common-cms/use-common-cms-data";

interface FindReservationKioskInterface {
  redirect: () => void;
}
type KioskOptionMapperInterface = {
  [key in KioskOptionType]: (redirect: () => void) => KioskOptionProp;
};

export const FindReservationKioskIntro: React.FC<
  React.PropsWithChildren<FindReservationKioskInterface>
> = ({ redirect }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const cmsData = useCommonCmsData();

  const navigateToIBE = useCallback(() => {
    navigate(generateSearchPageUrl());
  }, [navigate]);
  const { classes: kioskStyle } = useKioskStyle();
  const { availableOptions: newAvailableOptions } = useKioskConfig();
  const availableOptions = useMemo(() => {
    const isNewKioskConfig = !!getKioskId();

    //TODO when we migrate
    if (isNewKioskConfig) {
      return newAvailableOptions;
    } else {
      const createKeyOption = !hideAssignKeyForKioskMode() ? [KioskOptionType.CREATE_KEY] : [];
      const createBookingOption = newAvailableOptions.includes(KioskOptionType.CREATE_BOOKING)
        ? [KioskOptionType.CREATE_BOOKING]
        : [];

      return [KioskOptionType.FIND_RESERVATION, ...createKeyOption, ...createBookingOption];
    }
  }, [newAvailableOptions]);

  const checkInIcon = cmsData?.data?.kiosk__find_reservation_icon?.url;
  const createKeyIcon = cmsData?.data?.kiosk__create_key_icon?.url;
  const createBookingUrl = cmsData?.data?.kiosk__create_booking_icon?.url;

  const KioskOptionMapper: KioskOptionMapperInterface = useMemo(
    () => ({
      [KioskOptionType.FIND_RESERVATION]: (redirect) => ({
        type: KioskOptionType.FIND_RESERVATION,
        title: "labels__kiosk_just_arrived",
        buttonLabel: "buttons__check_in_here",
        redirect,
        showQRCode: true,
        iconUrl: checkInIcon
      }),
      [KioskOptionType.CREATE_KEY]: (redirect) => ({
        type: KioskOptionType.CREATE_KEY,
        title: "labels__kiosk_key_card",
        buttonLabel: "button__kiosk_create_key_card",
        redirect,
        showQRCode: false,
        iconUrl: createKeyIcon
      }),

      [KioskOptionType.CREATE_BOOKING]: (redirect) => ({
        type: KioskOptionType.CREATE_BOOKING,
        title: "labels__kiosk_create_booking",
        buttonLabel: "buttons__kiosk_create_booking",
        redirect,
        showQRCode: false,
        iconUrl: createBookingUrl
      })
    }),
    [checkInIcon, createBookingUrl, createKeyIcon]
  );

  return (
    <Box>
      <Box
        sx={{ pb: 0 }}
        textAlign="center"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        py={4}
      >
        <Heading1 align="center">{t("title__kiosk_find_reservation")}</Heading1>
      </Box>
      <Grid container m={0} mt={5} spacing={2} direction="row" className={kioskStyle.container}>
        {availableOptions
          .map((key) =>
            KioskOptionMapper[key as KioskOptionType](
              key === KioskOptionType.CREATE_BOOKING ? navigateToIBE : redirect
            )
          )
          .map((item) => (
            <Grid key={item.type} item md={12 / availableOptions.length} xs={12}>
              <KioskOption
                type={item.type}
                title={item.title}
                buttonLabel={item.buttonLabel}
                redirect={item.redirect}
                showQRCode={item.showQRCode}
                iconUrl={item.iconUrl}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
