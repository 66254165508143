import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BookingOnBehalfOfMyselfIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 1.5C4.75736 1.5 3.75 2.50736 3.75 3.75C3.75 4.99264 4.75736 6 6 6C7.24264 6 8.25 4.99264 8.25 3.75C8.25 2.50736 7.24264 1.5 6 1.5ZM2.25 3.75C2.25 1.67893 3.92893 0 6 0C8.07107 0 9.75 1.67893 9.75 3.75C9.75 5.82107 8.07107 7.5 6 7.5C3.92893 7.5 2.25 5.82107 2.25 3.75Z"
        fill="#14263D"
      />
      <path
        d="M1.75736 9.25736C2.88258 8.13214 4.4087 7.5 6 7.5C7.5913 7.5 9.11742 8.13214 10.2426 9.25736C11.3679 10.3826 12 11.9087 12 13.5V15.75C12 16.1642 11.6642 16.5 11.25 16.5H9.67874L8.99628 23.3246C8.95794 23.708 8.63531 24 8.25 24H3.75C3.36469 24 3.04206 23.708 3.00372 23.3246L2.32126 16.5H0.75C0.335786 16.5 0 16.1642 0 15.75V13.5C0 11.9087 0.632141 10.3826 1.75736 9.25736ZM6 9C4.80653 9 3.66193 9.47411 2.81802 10.318C1.97411 11.1619 1.5 12.3065 1.5 13.5V15H3C3.38531 15 3.70794 15.292 3.74628 15.6754L4.42874 22.5H7.57126L8.25372 15.6754C8.29206 15.292 8.61469 15 9 15H10.5V13.5C10.5 12.3065 10.0259 11.1619 9.18198 10.318C8.33807 9.47411 7.19347 9 6 9Z"
        fill="#14263D"
      />
    </svg>
  </SvgIcon>
);
