import { combineReducers } from "@reduxjs/toolkit";
import { commonCMSSlice } from "../common-cms/common-cms.slice";
import { CMSSingleDocumentTypes } from "./cms-single-document-types";
import { CMSPageState } from "./cms-single-page-factory";
import { articleOrderingCMSSlice } from "../article-ordering-cms/article-ordering-cms.slice";

type Lang = string;

export type CMSSingleState = Record<Lang, Document | null>;

export type CMSState = Record<string, CMSSingleState>;

export const cmsReducer = combineReducers<Record<CMSSingleDocumentTypes, CMSPageState>>({
  [CMSSingleDocumentTypes.common]: commonCMSSlice.reducer,
  [CMSSingleDocumentTypes.articleOrdering]: articleOrderingCMSSlice.reducer
});
