import { EntityStateStatus } from "../../state/EntityStateStatus";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Selector
} from "@reduxjs/toolkit";
import { SearchUnitGroup } from "../../domain-common/search-unit-group";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { handleSliceError } from "../../util/error-handling";
import { SearchApi } from "../../api/search.api";
import { getTenantHeaders } from "../../api/custom-headers";

export const searchAvailableUnitGroupAdapter = createEntityAdapter<SearchUnitGroup>({
  selectId: (searchUnitGroup: SearchUnitGroup) => searchUnitGroup.unitGroupId
});

const initialState = searchAvailableUnitGroupAdapter.getInitialState<{
  searchUnitGroupsStatus: EntityStateStatus;
}>({
  searchUnitGroupsStatus: EntityStateStatus.IDLE
});

export const searchAvailableUnitGroupsAction = createAsyncThunk<
  SearchUnitGroup[],
  {
    propertyIds: string;
    arrival: string;
    departure: string;
    adults: number;
    childrenAges: number[];
    promoCodePMS?: string;
    language?: string;
  },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("search/unitGroups", async (arg, thunkAPI) => {
  try {
    return await SearchApi.searchForAvailableUnitGroups(arg, {
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const searchAvailableUnitGroupsSlice = createSlice({
  name: "searchUnitGroupsSlice",
  initialState,
  reducers: {
    clearSearchAvailableUnitGroups: (state) => {
      searchAvailableUnitGroupAdapter.removeAll(state);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchAvailableUnitGroupsAction.pending, (state) => {
        state.searchUnitGroupsStatus = EntityStateStatus.LOADING;
      })
      .addCase(searchAvailableUnitGroupsAction.fulfilled, (state, action) => {
        state.searchUnitGroupsStatus = EntityStateStatus.SUCCEEDED;
        searchAvailableUnitGroupAdapter.removeAll(state);
        searchAvailableUnitGroupAdapter.addMany(state, action.payload);
      })
      .addCase(searchAvailableUnitGroupsAction.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          return;
        }
        state.searchUnitGroupsStatus = EntityStateStatus.FAILED;
      });
  }
});

export const { selectAll: searchAvailableUnitGroups } =
  searchAvailableUnitGroupAdapter.getSelectors<RootState>((state) => state.searchUnitGroupsSlice);

const selectSelf: Selector<
  RootState,
  {
    searchUnitGroupsStatus: EntityStateStatus;
  }
> = (state: RootState) => state[searchAvailableUnitGroupsSlice.name];

export const selectIsSearchAvailableUnitGroupsLoading = createSelector(
  selectSelf,
  (res) => res.searchUnitGroupsStatus === EntityStateStatus.LOADING
);

export const { clearSearchAvailableUnitGroups } = searchAvailableUnitGroupsSlice.actions;
