import React, { FC, useCallback } from "react";
import { DesktopCard, RoundBox } from "../../../components";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { DynamicShop } from "../dynamic-shop";
import { HelmetTitle } from "../../gtm/helmet-title";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { DynamicShopFlowPages, getDynamicShopFlowProgress } from "../dynamic-shop-pages";
import { useNavigate } from "react-router";
import { useGetDynamicShopType } from "../use-get-dynamic-shop-type";
import { capitalizeFirstLetter } from "../../../util/strings";

const currentPage = DynamicShopFlowPages.DYNAMIC_SHOP;

export const DynamicShopPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const shopType = useGetDynamicShopType();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!shopType) {
    return null;
  }

  return (
    <>
      <FlowTemplate
        icons={[]}
        progress={getDynamicShopFlowProgress(currentPage)}
        handleBack={goBack}
      >
        <HelmetTitle suffix={capitalizeFirstLetter(shopType) ?? ""} />
        <RoundedFullHeight px={3} py={2.5}>
          <DesktopCard>
            <RoundBox hasShadow py={3} px={2}>
              <PageHeadingInfo
                title={t(`title__${shopType.toLowerCase()}_shop_page`)}
                subtitle={t(`subtitle__${shopType.toLowerCase()}_shop_page`)}
              />
            </RoundBox>

            <DynamicShop reservation={reservation} shopType={shopType} />
          </DesktopCard>
        </RoundedFullHeight>
      </FlowTemplate>
    </>
  );
};
