import { useMemo } from "react";

export const useListCmsData = (key: string, cmsData?: any) => {
  const translatedKeys = useMemo(
    () =>
      cmsData?.data[key]?.reduce((acc: any, item: any) => {
        acc[item.id] = item.title;
        return acc;
      }, {}) || {},
    [cmsData?.data, key]
  );
  return {
    translatedKeys
  };
};
