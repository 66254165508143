import { useParams } from "react-router-dom";

export const useMagicIdParams = () => {
  const { magicId, bookingOverviewItemId, reservationMagicId } = useParams<{
    magicId: string;
    bookingOverviewItemId: string;
    reservationMagicId: string;
  }>();

  return {
    magicId: magicId || "",
    bookingOverviewItemId: bookingOverviewItemId || "",
    reservationMagicId: reservationMagicId || ""
  };
};
