import React, { useEffect } from "react";
import { SpeedDial as MuiSpeedDial } from "@mui/material";
import { SpeedDialProps as MuiSpeedDialProps } from "@mui/lab";
import { makeStyles } from "tss-react/mui";

const useSpeedDialStyles = makeStyles<SpeedDialProps>()((theme, props) => ({
  fab: {
    width: props.open ? theme.spacing(6) : theme.spacing(8),
    height: props.open ? theme.spacing(6) : theme.spacing(8),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    boxShadow: "0px 6px 15px rgba(65, 60, 54, 0.15)",
    borderRadius: theme.shape.borderRadius * 2.5,
    transition: ["border-radius", "width", "height", "transform"]
      .map((p) =>
        theme.transitions.create(p, {
          duration: theme.transitions.duration.short
        })
      )
      .join(", "),
    "&:hover": {
      backgroundColor: theme.palette.common.black
    },
    zIndex: 1175
  },
  directionUp: {
    flexDirection: "column-reverse",
    "& $actions": {
      flexDirection: "column",
      marginBottom: -48,
      paddingBottom: 0,
      width: 48,
      height: 48
    }
  },
  actions: {
    position: "relative",
    display: "block",
    pointerEvents: "none"
  }
}));

export type SpeedDialProps = Omit<MuiSpeedDialProps, "direction" | "ariaLabel">;

export const SpeedDial: React.FC<React.PropsWithChildren<SpeedDialProps>> = (props) => {
  const { classes } = useSpeedDialStyles(props);
  const fabRef = React.useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (!fabRef.current) {
      return;
    }
    // cleanup default styles for fab button
    fabRef.current.style.transform = "";
    fabRef.current.style.transition = "";
  }, [fabRef]);
  return (
    <MuiSpeedDial
      classes={classes}
      direction="up"
      FabProps={{ ref: fabRef }}
      ariaLabel={"speed dial"}
      {...props}
    />
  );
};
