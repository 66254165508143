import { MinGuaranteeType, Offer, SearchUnitGroup } from "../../domain-common/search-unit-group";
import { GuaranteeType } from "../generated/graphql";
import { GetAvailableUnitGroupsQuery } from "../queries/GetAvailableUnitGroups.generated";
import { transformGrossPriceToPrice } from "./transform-reservation";

type UnitGroupAvailabilityInput = GetAvailableUnitGroupsQuery["GetAvailableUnitGroups"][number];
type Rate = UnitGroupAvailabilityInput["rates"][number];
export const transformSearchAvailableUnitGroups = (
  item?: UnitGroupAvailabilityInput | null
): SearchUnitGroup => {
  return {
    arrival: item?.arrival,
    availableUnits: item?.numberOfAvailableUnits ?? 0,
    availableUnitsThreshold: item?.availableUnitsThreshold ?? 0,
    departure: item?.departure,
    maxPersons: item?.unitGroup?.capacity ?? 0,
    offers: item?.rates?.map(transformOffers) ?? [],
    propertyId: item?.property?.pmsId ?? "",
    unitGroupId: item?.unitGroup?.pmsId ?? "",
    numberOfNights: item?.numberOfNights ?? 0
  };
};

const transformOffers = (rate?: Rate | null): Offer => {
  return {
    cancellationFee: {
      id: rate?.currentCancellationFee?.pmsId ?? "",
      fee: transformGrossPriceToPrice(rate?.currentCancellationFee?.price),
      code: rate?.currentCancellationFee?.pmsId ?? "",
      name: rate?.currentCancellationFee?.name ?? "",
      description: rate?.currentCancellationFee?.description ?? "",
      dueDateTime: rate?.currentCancellationFee?.dueDateTime ?? new Date().toISOString()
    },
    includedServices: [],
    magicIncludedServices: [],
    services: [],
    minGuaranteeType: transformGuaranteeType(rate?.guaranteeType),
    pricePerNight: {
      amount: rate?.averagePricePerNight?.grossPriceInCents ?? 0,
      currency: rate?.averagePricePerNight?.currency ?? "CHF"
    },
    priceTotal: {
      currency: rate?.totalPrice?.currency ?? "CHF",
      amount: rate?.totalPrice?.grossPriceInCents ?? 0
    },
    ratePlanId: rate?.ratePlan?.pmsId ?? "",
    pricePerNightBeforeDiscount: {
      amount: rate?.averagePricePerNight?.grossPriceInCents ?? 0,
      currency: rate?.averagePricePerNight?.currency ?? "CHF"
    },
    priceTotalBeforeDiscount: {
      currency: rate?.totalPrice?.currency ?? "CHF",
      amount: rate?.totalPrice?.grossPriceInCents ?? 0
    },
    promoCodeMagicDiscountPercentage: 0
  };
};

const transformGuaranteeType = (
  guaranteeType: GuaranteeType | null | undefined
): MinGuaranteeType => {
  switch (guaranteeType) {
    case GuaranteeType.Prepayment:
      return "Prepayment";
    case GuaranteeType.Company:
      return "Company";
    case GuaranteeType.Pm6Hold:
      return "PM6Hold";
    case GuaranteeType.CreditCard:
      return "CreditCard";
    default:
      return "Prepayment";
  }
};
