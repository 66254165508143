import { useEffect, useMemo } from "react";

import { RootState, useAppDispatch } from "../../state/store";
import { fetchMagicObject, selectMagicObject, selectMagicObjectStatus } from "./magic-object.slice";
import { ApiError, useApiVersion, useAuth } from "@likemagic-tech/sv-magic-library";
import { useSelector } from "react-redux";
import { MagicObject } from "../../domain-common/magic-object";
import { useGetMagicObjectQuery } from "../../graphql/queries/GetMagicObject.generated";
import {
  transformApiV1ToCommonMagicObject,
  transformApiV2ToCommonMagicObject
} from "../../graphql/transform/transform-magic-object";
import { isStatusSuccess, mapQueryStatusToEntityStateStatus } from "../../state/EntityStateStatus";

export interface UseFetchMagicObjectProps {
  magicId?: string;
  noOfRetries?: number; // Only works for v1
  skipBannerError?: boolean;
}

export const useFetchMagicObject = ({
  magicId,
  noOfRetries,
  skipBannerError
}: UseFetchMagicObjectProps) => {
  const { isRESTVersion } = useApiVersion();

  const dispatch = useAppDispatch();
  const { authenticated } = useAuth();

  const res = useGetMagicObjectQuery(
    {
      magicId: magicId ?? "",
      ...(skipBannerError
        ? {
            skipBannerError
          }
        : {})
    }, //never going to happen because of skip part
    {
      skip: isRESTVersion || !magicId
    }
  );
  const { data, status, error } = res;

  const magicObject = useSelector<RootState, MagicObject | null>(selectMagicObject);
  const magicObjectStatus = useSelector(selectMagicObjectStatus);

  useEffect(() => {
    if (magicId && isRESTVersion) {
      dispatch(fetchMagicObject({ magicId, noOfRetries }));

      //TODO let's double check do we need this abortion (we have cache anyway)
      // return () => {
      //   promise.abort();
      // };
    }
  }, [dispatch, magicId, authenticated, isRESTVersion, noOfRetries]);

  const computedMagicObjectStatus = useMemo(() => {
    return isRESTVersion
      ? magicObjectStatus
      : mapQueryStatusToEntityStateStatus(status, error as ApiError);
  }, [isRESTVersion, magicObjectStatus, status, error]);

  const computedMagicObject = useMemo(() => {
    if (isStatusSuccess(computedMagicObjectStatus)) {
      return isRESTVersion
        ? transformApiV1ToCommonMagicObject(magicObject)
        : transformApiV2ToCommonMagicObject(data?.GetMagicObject);
    }
    return null;
  }, [magicObject, data, isRESTVersion, computedMagicObjectStatus]);

  return {
    magicObject: computedMagicObject,
    magicObjectStatus: computedMagicObjectStatus
  };
};
