import React, { FC } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { Box, useTheme } from "@mui/material";
import { Heading2, Heading3, Paragraph } from "@likemagic-tech/sv-magic-library";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { toGross } from "../../../domain-v1/full-price";
import { SelfPouringStationItem } from "../self-poruing-station.types";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  imgWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  img: {
    width: "80px",
    height: "80px",
    objectFit: "cover"
  }
}));

export const SelfPouringStationModalContent: FC<{
  serviceSelfPouringStationModalItem: SelfPouringStationItem;
  iconSrc: string;
  title: string;
  unit: number;
}> = ({ serviceSelfPouringStationModalItem, unit, iconSrc, title }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { palette } = useTheme();
  const { classes } = useStyles();

  return (
    <Box>
      <Box mt={2}>
        <Heading3 textAlign="center" sx={{ color: palette.text.primary }}>
          {title}
        </Heading3>
      </Box>
      <Box mt={2} className={classes.imgWrapper}>
        <img
          src={iconSrc}
          alt={serviceSelfPouringStationModalItem.drinkId}
          className={classes.img}
        />
      </Box>
      <Box mt={2}>
        <Heading3
          textAlign="center"
          sx={{ color: palette.text.primary, maxWidth: "70%", marginInline: "auto" }}
        >
          {t("labels__self-pouring-station-please-come")}
        </Heading3>
      </Box>
      <Box mt={2}>
        <Paragraph textAlign="center" sx={{ color: palette.text.secondary }}>
          {unit}L
        </Paragraph>
      </Box>
      <Box mt={2}>
        <Heading2 textAlign="center" sx={{ color: palette.text.primary }}>
          {formatPriceToString(toGross(serviceSelfPouringStationModalItem.fullPrice))}
        </Heading2>
      </Box>
    </Box>
  );
};
