import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useEffect, useRef, useState } from "react";
import { MinusIcon, PlusIcon } from "../../../components/icons";
import { useDebounce } from "../../../util/hooks/use-debounce";

interface CartListItemCounterProps {
  initialCount: number;
  /**
   * This callback should be memoized,
   * if not then it will emit count change on every render
   * @param count
   */
  onCountChange?: (count: number) => void;
  classes?: {
    root?: string;
  };
}

const useStyles = makeStyles()({
  counter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  icon: {
    "&.Mui-disabled": {
      opacity: 0.5
    }
  }
});
export const CartListItemCounter: React.FC<React.PropsWithChildren<CartListItemCounterProps>> = ({
  initialCount,
  classes,
  onCountChange
}) => {
  const [count, setCount] = useState<number>(initialCount);
  const debounceCount = useDebounce(count, 400);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (debounceCount && didMountRef.current) {
      onCountChange?.(debounceCount);
    }
    didMountRef.current = true;
  }, [onCountChange, debounceCount]);

  const { classes: styles } = useStyles();
  const rootClassName = [styles.counter, classes?.root].filter(Boolean).join(" ");
  return (
    <div className={rootClassName}>
      <IconButton size="small" onClick={() => setCount((c) => Math.max(1, c - 1))}>
        <MinusIcon fontSize={"small"} />
      </IconButton>
      <Box px={2}>
        <Paragraph>{count}</Paragraph>
      </Box>
      <IconButton size="small" onClick={() => setCount((c) => c + 1)}>
        <PlusIcon fontSize={"small"} />
      </IconButton>
    </div>
  );
};
