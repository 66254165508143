import { Tabs as MuiTabs } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

export const Tabs = withStyles(({ palette }) => ({
  root: {
    borderBottom: `1px solid ${palette.secondary.main}`,
    backgroundColor: palette.background.default
  },
  indicator: {
    backgroundColor: palette.common.black,
    height: "3px"
  }
}))(MuiTabs);
