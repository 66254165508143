import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type RequestAccessMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
}>;

export type RequestAccessMutation = { __typename?: "Mutation"; RequestAccess?: boolean | null };

export const RequestAccessDocument = `
    mutation RequestAccess($magicId: String!) {
  RequestAccess(magicId: $magicId)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    RequestAccess: build.mutation<RequestAccessMutation, RequestAccessMutationVariables>({
      query: (variables) => ({ document: RequestAccessDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useRequestAccessMutation } = injectedRtkApi;
