import { Box } from "@mui/material";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { PreferredCommunicationMethodSelection } from "../../../components/molecules/guest-flow-preferred-communication-method";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { isReservation } from "../../../util/flow";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { useSaveGuestFlowSessionCheckpoint } from "../checkpoint/use-save-guest-flow-session-checkpoint";
import { getNextFlowUrl, getPrevFlowUrl } from "../guest-flow-sequence";
import { useGuestFlow } from "../use-guest-flow";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { useSetupGuestFlowNavigation } from "../hooks/use-setup-guest-flow-navigation";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { isKioskMode } from "../../../util/kiosk-mode";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { PreferredCommunicationAdditionalDataModal } from "../../../components/molecules/preferred-communication-additional-data-modal";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { generatePortalProfileUrl } from "../../../util/routing";

let CURRENT_PAGE = GuestFlowCheckpoint.PREFERRED_CHANNEL;

export const PreferredChannelPage: FC = () => {
  const navigate = useNavigate();
  const { patchGuestFlowStep, contactButton, skipButton, reservation, getProgress } =
    useGuestFlow();
  const { isBottomNavbarActive } = useBottomNavbar();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const [selectedChannel, setSelectedChannel] = useState<string>(
    reservation?.extras?.preferredCommunicationChannel ?? ""
  );
  const [error, setError] = useState<boolean>(false);

  useSaveGuestFlowSessionCheckpoint(reservation!, CURRENT_PAGE);
  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });

  const handleSubmit = useCallback(async () => {
    if (!selectedChannel) {
      setError(true);
      return;
    }
    const valuesToPatch = {
      ...reservation,
      extras: {
        ...reservation.extras!,
        preferredCommunicationChannel: selectedChannel
      }
    };
    try {
      await patchGuestFlowStep({
        reservationValues: valuesToPatch,
        checkpoint: CURRENT_PAGE,
        subUpdate: SubUpdate.PREFERRED_CHANNEL
      });

      navigate(getNextFlowUrl(CURRENT_PAGE, valuesToPatch));
    } catch (e) {
      console.error("An error occurred on preferred channel page", e);
    }
  }, [reservation, selectedChannel, patchGuestFlowStep, navigate]);

  const onBack = useCallback(() => {
    navigate(getPrevFlowUrl(CURRENT_PAGE, reservation));
  }, [navigate, reservation]);

  const progress = useMemo(() => getProgress(CURRENT_PAGE), [getProgress]);
  const { enabledCommunicationChannels } = useContext(TenantContext);

  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const topBarIcons = useMemo(() => {
    if (isReservation(reservation)) {
      if (isKioskMode()) {
        return [skipButton];
      }
      return [contactButton];
    }
    return [];
  }, [reservation, contactButton, skipButton]);

  return (
    <>
      <HelmetTitle suffix="Guest flow communication channel" />
      <FlowTemplate handleBack={onBack} icons={topBarIcons} progress={progress}>
        <GuestFlowBackgroundBox>
          <Box p={2} pt={0}>
            <DesktopCard>
              <PageHeadingInfo
                title={tCommon("title__preferred_channel_page")}
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__preferred_channel_icon?.url} />}
              />
              <PreferredCommunicationMethodSelection
                selected={selectedChannel}
                onSelect={(value) => {
                  setError(false);
                  setSelectedChannel(value);
                }}
                selectableChannels={enabledCommunicationChannels}
                error={error}
              />
              <PreferredCommunicationAdditionalDataModal
                channel={selectedChannel}
                resetToInitValue={() => setSelectedChannel("")}
                nextPageLink={generatePortalProfileUrl(reservation.magicId)}
                t={tCommon}
              />
              <SubmitButton
                label={
                  selectedChannel !== reservation.extras?.preferredCommunicationChannel
                    ? tCommon("buttons__save")
                    : tCommon("buttons__next")
                }
                hasWhiteBackground
                onClick={handleSubmit}
                hasBottomNavigation={isBottomNavbarActive}
              />
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
