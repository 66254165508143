import { createBookingWithoutPayment, selectPaymentSlice } from "../../payment/payment.slice";
import { useAppDispatch } from "../../../state/store";
import { useSelector } from "react-redux";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useCallback, useMemo } from "react";
import {
  CreateBookingMutation,
  useCreateBookingMutation
} from "../../../graphql/mutations/create-booking.generated";
import { mapQueryStatusToEntityStateStatus } from "../../../state/EntityStateStatus";

const transformBookingCreatedResponse = (
  data?: CreateBookingMutation
): { bookingMagicId: string } => {
  if (data?.CreateBooking) {
    return { bookingMagicId: data.CreateBooking.magicId };
  }
  return { bookingMagicId: "" };
};

export const useCreateBookingWithoutPayment = () => {
  const dispatch = useAppDispatch();

  const {
    paymentDTO,
    withoutPaymentStatus: paymentStatusV1,
    paymentResponse: paymentResponseV1
  } = useSelector(selectPaymentSlice);
  const { isRESTVersion } = useApiVersion();

  const [createBookingMutation, { data: paymentResponseV2, status: paymentStatusV2 }] =
    useCreateBookingMutation();

  const bookingCreationV1 = useCallback(() => {
    if (paymentDTO.bookingCreation) {
      dispatch(
        createBookingWithoutPayment({
          bookingCreationDTO: paymentDTO.bookingCreation
        })
      );
    } else {
      throw new Error("bookingCreation obj is missing");
    }
  }, [paymentDTO.bookingCreation, dispatch]);

  const bookingCreationV2 = useCallback(() => {
    createBookingMutation({
      request: {
        booker: paymentDTO.bookingCreation!.booker,
        reservations: paymentDTO.bookingCreation!.reservations!.map((item) => ({
          adultsAmount: item.adults,
          arrival: item.arrival,
          childrenAmount: item.childrenAges.length,
          departure: item.departure,
          pmsPropertyId: item.propertyId,
          pmsRatePlanId: item.ratePlanId,
          pmsUnitGroupId: item.unitGroupId
        }))
      }
    });
  }, [createBookingMutation, paymentDTO.bookingCreation]);

  const createBooking = isRESTVersion ? bookingCreationV1 : bookingCreationV2;

  const createBookingStatus = useMemo(
    () => (isRESTVersion ? paymentStatusV1 : mapQueryStatusToEntityStateStatus(paymentStatusV2)),
    [isRESTVersion, paymentStatusV1, paymentStatusV2]
  );

  const createBookingResponse = useMemo(
    () => (isRESTVersion ? paymentResponseV1 : transformBookingCreatedResponse(paymentResponseV2)),
    [isRESTVersion, paymentResponseV1, paymentResponseV2]
  );

  return { createBooking, createBookingStatus, createBookingResponse };
};
