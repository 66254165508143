import React from "react";
import { hideRootSuspense, showRootSuspense } from "./loader.slice";
import { useAppDispatch } from "../../state/store";

export const SuspenseFallback = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(showRootSuspense());
    return () => {
      dispatch(hideRootSuspense());
    };
  }, [dispatch]);

  return null;
};
