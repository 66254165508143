import { Box } from "@mui/material";
import React, { FC } from "react";
import { useCommonCmsData } from "../../../state/common-cms/use-common-cms-data";
import { LottieAnimationPlayer } from "../../../features/animations/lottie-animation-player";
import { DefaultConfirmationIcon } from "@likemagic-tech/sv-magic-library";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

export const ConfirmationIcon: FC = () => {
  const cmsData = useCommonCmsData();
  const { tfeFeatureEnabled } = useFeatureFlags();

  if (cmsData?.data?.animations__confirmation?.url) {
    return (
      <Box height={220}>
        <LottieAnimationPlayer url={cmsData?.data?.animations__confirmation?.url} />
      </Box>
    );
  } else {
    return <DefaultConfirmationIcon animated={!tfeFeatureEnabled} />;
  }
};
