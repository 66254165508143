import { SvgIcon } from "@mui/material";
import React from "react";
import parse, { domToReact } from "html-react-parser";
import { ElementType } from "domelementtype";
import { SvgUrlIconProps } from "./display-cms-svg";

const cache: Record<string, string> = {};

/**
 * SvgUrlIcon component is used to display SVG icons stored in Prismic
 *
 * Usage:
 * const cmsData = useCMSData(CMS_PORTION_SELECTOR, FETCH_CMS_PORTION_ACTION);
 * ...
 * <SvgUrlIcon src={cmsData.ICON_URL} />
 */
export const SvgUrlIcon: React.FC<React.PropsWithChildren<SvgUrlIconProps>> = (props) => {
  const { url, ...svgIconProps } = props;
  const [svgHtml, setSvgHtml] = React.useState<string>(cache[url] || "");
  React.useEffect(() => {
    let abortController: AbortController;

    async function fetchSrc() {
      if (!url) {
        return;
      }

      let cached = cache[url];

      if (cached) {
        setSvgHtml(cached);
        return;
      }
      abortController = new AbortController();
      try {
        const response = await fetch(url, { signal: abortController.signal });
        if (response.ok) {
          const html = (await response.text()).trim();
          if (html && url) {
            cache[url] = html;
          }
          setSvgHtml(html);
        }
      } catch (e) {
        // console.error(e);
      }
    }

    fetchSrc();

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [url]);

  const response = React.useMemo(() => {
    if (!svgHtml) {
      return null;
    }
    try {
      return parse(svgHtml, {
        replace: (prop: any) => {
          const elem = prop;
          if (elem) {
            if (prop.type === ElementType.Comment) {
              return <> </>;
            }

            if (prop.type === ElementType.Tag) {
              const viewBox = elem.attributes.find(
                (item: { name: string; value: string }) => item.name === "viewBox"
              )?.value;

              return (
                <SvgIcon viewBox={viewBox} {...svgIconProps}>
                  {domToReact(elem.children)}
                </SvgIcon>
              );
            }
          }
        }
      }) as JSX.Element;
    } catch (e) {
      console.warn(e);
      return null;
    }
  }, [svgHtml, svgIconProps]);
  return response;
};
