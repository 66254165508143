import { Box, Link, useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import {
  DoorProvider,
  Paragraph,
  useDoorProviderConfig,
  useGlobalModal,
  useIsNative
} from "@likemagic-tech/sv-magic-library";
import { DesktopCard, DisplayCmsSvg, PrismicRichTextWrapper } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import {
  getDisplayableDoors,
  getDoorApiDoorThatShouldBeDisplayAsPin
} from "../../keys-handlers/keys-speed-dial/helpers";
import { AccessibleDoor } from "../../../domain-common/reservation";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { KeysHandler } from "../../keys-handlers/keys-handler";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useAaDoorSetup } from "../../keys-handlers/use-aa-door-setup";
import { SpecialKeyWithPin } from "../components/special-key-with-pin";
import { GetMobileApp } from "../../keys-handlers/get-mobile-app";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import { useDoorCmsData } from "../../../state/property-cms/use-door-cms-data";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

interface MyStayKeysPageProps {}

export const MyStayKeysPage: React.FC<React.PropsWithChildren<MyStayKeysPageProps>> = () => {
  const { reservation } = useReservationContext();
  const config = useDoorProviderConfig(reservation.propertyId);
  const { spacing, palette } = useTheme();
  const isMobile = useIsMobile();
  const { open: openModal } = useGlobalModal();
  useSetupSubpageNavigation(generatePortalMyStayUrl);
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { privateDoorsDisabled } = useFeatureFlags();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { doorHelpModalContent, doorHelpModalTitle } = useDoorCmsData(reservation.propertyId);

  const specialCaseDoor = useMemo(
    () => getDoorApiDoorThatShouldBeDisplayAsPin(reservation.accessibleDoors, config?.doorProvider),
    [reservation.accessibleDoors, config?.doorProvider]
  );

  const doors = useMemo(
    () =>
      getDisplayableDoors(
        reservation,
        specialCaseDoor?.id && config?.doorProvider !== DoorProvider.NUKI
          ? [specialCaseDoor?.id]
          : undefined
      ).filter((item) => !privateDoorsDisabled || item.is_general),
    [reservation, specialCaseDoor?.id, privateDoorsDisabled, config?.doorProvider]
  );
  const isNative = useIsNative();

  //TODO LM-1196 move AA stuff on the level of mobile keys
  useAaDoorSetup({ reservation });
  const getKeysInfoPage = useMemo(() => {
    if (
      [
        DoorProvider.AA_VISIONLINE,
        DoorProvider.AA_VOSTIO,
        DoorProvider.SALTO_SPACE_MOBILE_KEY
      ].includes(config?.doorProvider ?? DoorProvider.NONE) &&
      !reservation?.accessibleDoors?.length &&
      !isNative
    ) {
      return <GetMobileApp />;
    } else if (!reservation?.accessibleDoors?.length) {
      return (
        <Box mt={25} px={5}>
          <Paragraph align="center">{tCommon("labels__keys_empty_page")}</Paragraph>
        </Box>
      );
    }
  }, [reservation?.accessibleDoors?.length, isNative, tCommon, config?.doorProvider]);

  const openHelpModal = useCallback(
    () =>
      openModal({
        modalProps: {
          title: doorHelpModalTitle,
          content: doorHelpModalContent ? (
            <PrismicRichTextWrapper render={doorHelpModalContent} />
          ) : (
            <> </>
          )
        },
        modalActions: []
      }),
    [openModal, doorHelpModalContent, doorHelpModalTitle]
  );

  return (
    <>
      <HelmetTitle suffix="Keys" />
      <RoundedFullHeight>
        <DesktopCard>
          <FlowTemplate>
            <Box pl={2.5} pr={2.5} pb={2.5}>
              <PageHeadingInfo
                title={tCommon("title__keys_page")}
                subtitle={tCommon("subtitle__keys_page")}
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__assign_key_icon?.url} />}
              />

              <SpecialKeyWithPin
                specialCaseDoor={specialCaseDoor}
                reservation={reservation}
                t={tCommon}
              />

              {doors.map((door: AccessibleDoor) => (
                <KeysHandler
                  doorConfig={config?.doorProvider}
                  door={door}
                  reservation={reservation}
                  key={door.id}
                />
              ))}
              {getKeysInfoPage}
              {doorHelpModalContent && doorHelpModalTitle && (
                <Box
                  textAlign="center"
                  sx={
                    isMobile
                      ? {
                          position: "fixed",
                          left: 0,
                          right: 0,
                          bottom: spacing(7),
                          backgroundColor: palette.background.paper
                        }
                      : {}
                  }
                >
                  <Box display="inline-block">
                    <Link
                      width="fit-content"
                      display="flex"
                      justifyContent="center"
                      style={{ cursor: "pointer" }}
                      onClick={openHelpModal}
                    >
                      <HelpOutlineOutlined sx={{ mr: 0.5 }} />
                      <span>{tCommon("labels__need_help")}</span>
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
          </FlowTemplate>
        </DesktopCard>
      </RoundedFullHeight>
    </>
  );
};
