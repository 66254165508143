import parsePhoneNumber from "libphonenumber-js/mobile";
import * as yup from "yup";
import { StringSchema } from "yup";

yup.addMethod<StringSchema>(yup.string, "phone", function (message: string) {
  return this.test({
    message,
    name: "phone",
    exclusive: true,
    params: {},
    test(value) {
      if (!value) {
        return true;
      }
      const phoneNumber = parsePhoneNumber(value);
      return Boolean(phoneNumber?.isValid() && phoneNumber?.getType() === "MOBILE");
    }
  });
});

const blacklistedEmailDomains = [
  "@guest.booking.com",
  "@m.expediapartnercentral.com",
  "@guest.airbnb.com",
  "@agoda-messaging.com",
  "@hotel.check24.de",
  "@guest.trip.com"
];

yup.addMethod<StringSchema>(yup.string, "notBlacklistedEmail", function (message: string) {
  return this.test({
    message,
    name: "notBlacklistedEmail",
    exclusive: true,
    params: {},
    test(value) {
      if (!value) {
        return true;
      }
      return !blacklistedEmailDomains.some((blacklistedDomain) =>
        value.endsWith(blacklistedDomain)
      );
    }
  });
});

export default yup;
