import { useCallback } from "react";
import { ExtendStayRequestDTO } from "../../api/extend-stay.api";
import { unwrapResult } from "@reduxjs/toolkit";
import { extendStayPriceAction, setExtendStayRequestDTO } from "./extend-stay.slice";
import { generatePortalMyStayExtendStayBillUrl } from "../../util/routing";
import { IgnorableErrorCodes } from "../../components/atoms/error-bounadry/error-codes";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../state/store";

export const useExtendStayAction = ({
  magicId,
  setUnavailable
}: {
  magicId: string;
  setUnavailable: (arg: boolean) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onDatePickerSubmit = useCallback(
    async (from: string, to: string) => {
      const extendStayRequestDTO: ExtendStayRequestDTO = {
        from,
        to
      };

      try {
        unwrapResult(
          await dispatch(
            extendStayPriceAction({
              magicId: magicId,
              extendStayRequestDTO: extendStayRequestDTO,
              skipBannerError: true
            })
          )
        );
        // only on successful price retrieval
        dispatch(setExtendStayRequestDTO(extendStayRequestDTO));
        navigate(generatePortalMyStayExtendStayBillUrl(magicId));
      } catch (e) {
        if ((e as any).id === IgnorableErrorCodes.EXTEND_STAY_UNAVAILABLE) {
          setUnavailable(true);
        } else {
          throw e;
        }
      }
    },
    [dispatch, magicId, navigate, setUnavailable]
  );
  return { onDatePickerSubmit };
};
