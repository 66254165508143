import { useCallback } from "react";
import { Guest } from "../../domain-common/guest";
import { useIsMinor } from "./use-is-minor";

export const useHasMinors = () => {
  const { isMinor } = useIsMinor();

  const hasMinors = useCallback(
    (additionalGuests: Guest[] | undefined) =>
      additionalGuests?.some((guest) => isMinor(guest.birthdate ?? "") ?? false),
    [isMinor]
  );

  return { hasMinors };
};
