import { makeStyles } from "tss-react/mui";

interface UseKeysSpeedDialStylesProps {
  height100vh: number | null;
  error?: string;
  clickable?: boolean;
}

export const useKeysSpeedDialStyles = makeStyles<UseKeysSpeedDialStylesProps>()((theme, props) => ({
  speedDial: {
    position: "fixed",
    bottom: `calc(${theme.spacing(9)} + env(safe-area-inset-bottom))`,
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 2,
    userSelect: "none",

    [`@media (min-width:1024px)`]: {
      right: `calc(50% - 512px + ${theme.spacing(2)})`
    }
  },
  help: {
    zIndex: 1150,
    position: "fixed",
    top: 0,
    width: "100%",
    left: 0,
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    userSelect: "none",
    // center content from top till buttons
    height: props.height100vh ? props.height100vh - 220 : "calc(100vh - 220px)",
    padding: theme.spacing(0, 2)
  },
  helpIcon: {
    height: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  helpText: {
    height: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tooltipPopper: { display: "none" },
  backdrop: {
    zIndex: 1100,
    backgroundColor: props.error ? theme.palette.error.main : "rgba(0, 0, 0, 0.8)",
    transition: theme.transitions.create(["background-color", "opacity"], {
      duration: theme.transitions.duration.short
    })
  },
  alertIcon: {
    width: "166px",
    height: "166px",
    color: theme.palette.common.white
  }
}));
