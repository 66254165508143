export function combineSearchParams(
  searchParams1: URLSearchParams,
  searchParams2: URLSearchParams
) {
  const result = new URLSearchParams("");

  // Display the key/value pairs
  [searchParams1, searchParams2].forEach((params) => {
    for (let pair of params.entries()) {
      //override old value.
      if (result.get(pair[0])) {
        result.delete(pair[0]);
      }
      result.append(pair[0], pair[1]);
    }
  });

  return result;
}
