import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CartBadge } from "../../features/shop/components/cart-badge";
import { generatePortalMyStayCartUrl } from "../../util/routing";
import { ShoppingCartIcon } from "../icons";
import { useIsMobile } from "../layouts/hooks/use-is-mobile";

export const CartButtonNavigation: React.FC<React.PropsWithChildren<{ magicId: string }>> = ({
  magicId
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  return (
    <IconButton
      sx={{ color: "background.paper" }}
      onClick={() => navigate(`${generatePortalMyStayCartUrl(magicId)}`)}
      size="large"
    >
      <CartBadge placement="header">
        <ShoppingCartIcon
          sx={{
            fontSize: 16,
            color: isMobile ? "background.paper" : "common.black"
          }}
        />
      </CartBadge>
    </IconButton>
  );
};
