import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { ConfirmationComponent, DesktopCard } from "../../components";
import { generateInitBookingOverviewUrl } from "../booking-overview/booking-overview-navigation";
import { RoundedFullHeight } from "../../components/layouts/rounded-full-height";
import { FlowTemplate } from "../../components/layouts/flow-template";
import { resetCancelStatus } from "../portal/portal.slice";
import { useUserProfileLastMagicId } from "../user-profile/use-user-profile-last-magic-id";
import { useAppDispatch } from "../../state/store";
import { initBookingOverview } from "../booking-overview/state/booking-overview.slice";
import { clearInitialMagicId } from "../initial-magic-info/initial-magic-id.slice";

// Note: see https://github.com/likemagic-tech/sv-magic-guest-journey/pull/1547#discussion_r1066648147 for a discussion on moving this logic to be part of portal-payment-success.page.tsx
export const ReservationCancelConfirmation: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const dispatch = useAppDispatch();
  const { userProfileLastMagicId } = useUserProfileLastMagicId();

  useEffect(() => {
    dispatch(resetCancelStatus());
    dispatch(initBookingOverview());
    dispatch(clearInitialMagicId());
  }, [dispatch]);

  const navigate = useNavigate();
  const onConfirm = useCallback(() => {
    navigate(userProfileLastMagicId ? generateInitBookingOverviewUrl(userProfileLastMagicId) : "/");
  }, [navigate, userProfileLastMagicId]);

  return (
    <RoundedFullHeight px={2.5}>
      <FlowTemplate>
        <DesktopCard>
          <ConfirmationComponent
            subtitleInfo={t("confirmations__cancel_reservation")}
            onConfirm={onConfirm}
            submitButtonLabel={
              userProfileLastMagicId ? t("buttons__go_to_booking") : t("buttons__find_reservation")
            }
          />
        </DesktopCard>
      </FlowTemplate>
    </RoundedFullHeight>
  );
};
