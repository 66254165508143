import { darken, styled } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";

interface RibbonProps {
  text: string;
  position: "topLeft" | "topRight";
  containerSideLength: number;
}

const ribbonPossitionMap = {
  topRight: {
    top: "-8px",
    right: "-8px",
    "&::before": {
      top: 0,
      left: 0
    },
    "&::after": {
      bottom: 0,
      right: 0
    },
    div: {
      bottom: 0,
      right: 0,
      transform: "rotate(45deg)",
      transformOrigin: "bottom right"
    }
  },
  topLeft: {
    top: "-8px",
    left: "-8px",
    "&::before": {
      bottom: 0,
      left: 0
    },
    "&::after": {
      top: 0,
      right: 0
    },
    div: {
      bottom: "0",
      left: "0",
      transform: "rotate(-45deg)",
      transformOrigin: "bottom left"
    }
  }
};

const useStyles = makeStyles<{ containerSideLength: number }>()(({ palette }, props) => ({
  ribbonText: {
    position: "absolute",
    width: Math.sqrt(2 * Math.pow(props.containerSideLength, 2)) + "px",
    padding: "2px 18px 2px 18px",
    backgroundColor: palette.primary.main,
    boxShadow: "0 5px 10px rgba(0,0,0,.1)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: palette.common.white,
    textShadow: "0 1px 1px rgba(0,0,0,.2)",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "normal"
  }
}));

const RibbonContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "containerSideLength"
})<{ containerSideLength: number; position: "topLeft" | "topRight" }>(
  ({ theme, containerSideLength, position }) => ({
    width: containerSideLength,
    height: containerSideLength,
    overflow: "hidden",
    position: "absolute",
    zIndex: 1,

    "&::before, &::after": {
      position: "absolute",
      zIndex: -1,
      content: '""',
      display: "block",
      border: `4px solid ${darken(theme.palette.primary.main, 0.1)}`,
      boxShadow: "0 0 1px rgba(0, 0, 0, 0.1)",
      borderTopColor: "transparent",
      ...(position === "topRight"
        ? {
            borderRightColor: "transparent"
          }
        : { borderLeftColor: "transparent" })
    },

    ...ribbonPossitionMap[position]
  })
);

export const Ribbon: React.FC<React.PropsWithChildren<RibbonProps>> = ({
  text,
  position,
  containerSideLength
}) => {
  const { classes } = useStyles({ containerSideLength });

  return (
    <RibbonContainer containerSideLength={containerSideLength} position={position}>
      <div className={classes.ribbonText}>{text}</div>
    </RibbonContainer>
  );
};
