import React, { FC, useCallback, useMemo } from "react";
import { Reservation } from "../../domain-common/reservation";
import { ReservationStatus } from "../../domain-common/reservation-status";
import { isTravelBuddy } from "../../util/flow";
import { userFeelsRoomIsClean } from "../../util/room-state-util";
import { buildReservationTranslationContext } from "../../util/translation";
import { NotificationChip, NotificationChipProps, NotificationChipType } from "../atoms";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { differenceInMilliseconds } from "date-fns";
import { IdCheckStatus } from "../../domain-common/id-check-status";
import { daysBeforeNow } from "@likemagic-tech/sv-magic-library";
import { CheckoutFlowPages } from "../../features/checkout-flow/checkout-flow-pages";

export type ReservationChipProps = {
  reservation: Reservation;
  timeZone?: string;
  payAtCheckoutEnabled: boolean;
  checkoutFlowEnabled: boolean;
};
export const ReservationChip: FC<React.PropsWithChildren<ReservationChipProps>> = ({
  reservation,
  timeZone,
  payAtCheckoutEnabled,
  checkoutFlowEnabled
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const labelPrefix = "chip__";
  const isCheckoutTomorrow = useMemo(
    () => daysBeforeNow(reservation.departure) === 1,
    [reservation.departure]
  );
  const isCheckoutToday = useMemo(
    () => daysBeforeNow(reservation.departure) === 0,
    [reservation.departure]
  );

  const isCheckoutFlowDone = useMemo(
    () =>
      reservation?.flowState?.context?.lastConfirmedCheckoutPage === CheckoutFlowPages.CONFIRMATION,
    [reservation?.flowState?.context?.lastConfirmedCheckoutPage]
  );

  const tAppliedReservationContext = useCallback(
    (label: string) => {
      const translationContext = buildReservationTranslationContext(
        reservation,

        {
          timeZone: timeZone
        }
      );
      return t(label, translationContext);
    },
    [t, reservation, timeZone]
  );

  const isOpenBalance = useMemo(
    () => reservation.flowState.wasOrIsCompleted && reservation.totalAllowedPayment?.amount > 0,
    [reservation]
  );

  const notificationChip: NotificationChipProps = useMemo(() => {
    if (
      (isOpenBalance && !payAtCheckoutEnabled) ||
      (isOpenBalance && payAtCheckoutEnabled && isCheckoutToday)
    ) {
      return {
        type: NotificationChipType.Error,
        label: tAppliedReservationContext(labelPrefix + "open_balance")
      };
    }
    if (reservation.idCheckStatus === IdCheckStatus.DECLINED) {
      return {
        type: NotificationChipType.Error,
        label: tAppliedReservationContext(labelPrefix + "id_check_declined")
      };
    } else if (reservation.idCheckStatus === IdCheckStatus.REUPLOADED) {
      return {
        type: NotificationChipType.Error,
        label: tAppliedReservationContext(labelPrefix + "id_check_reuploaded")
      };
    }
    if (reservation.status === ReservationStatus.IN_HOUSE) {
      if (reservation.unit?.privacyMode) {
        return {
          type: NotificationChipType.DoNotDisturb,
          label: tAppliedReservationContext(labelPrefix + "do_not_disturb")
        };
      }

      if (!userFeelsRoomIsClean(reservation)) {
        return {
          type: NotificationChipType.Error,
          label: tAppliedReservationContext(labelPrefix + "in_house_studio_not_ready_message")
        };
      }

      if (reservation?.extras?.billConfirmed || isTravelBuddy(reservation)) {
        if (isCheckoutToday) {
          return {
            type: NotificationChipType.Success,
            label: tAppliedReservationContext(
              labelPrefix + "in_house_ready_for_checkout_today_at_time"
            )
          };
        }

        if (isCheckoutTomorrow) {
          return {
            type: NotificationChipType.Success,
            label: tAppliedReservationContext(
              labelPrefix + "in_house_ready_for_checkout_tomorrow_at_time"
            )
          };
        }
      }

      if ((isCheckoutToday || isCheckoutTomorrow) && checkoutFlowEnabled && isCheckoutFlowDone) {
        return {
          type: NotificationChipType.Success,
          label: tAppliedReservationContext(labelPrefix + "pre_checkout_flow_completed")
        };
      }

      if (isCheckoutToday && checkoutFlowEnabled && !isCheckoutFlowDone) {
        return {
          type: NotificationChipType.Error,
          label: tAppliedReservationContext(labelPrefix + "pre_checkout_flow_not_completed")
        };
      }

      return {
        type: NotificationChipType.Success,
        label: tAppliedReservationContext(labelPrefix + "in_house_studio_ready_message")
      };
    } else if (reservation.status === ReservationStatus.CHECKED_OUT) {
      return {
        type: NotificationChipType.Success,
        label: tAppliedReservationContext(labelPrefix + "checked_out_message")
      };
    }
    // note: will not happen because reservation is filtered out in BE 'isFlowAvailable' => label also not in prismic
    else if (reservation.status === ReservationStatus.NO_SHOW) {
      return {
        type: NotificationChipType.Error,
        label: tAppliedReservationContext(labelPrefix + "no_show_message")
      };
    }
    if (reservation.flowState.completed) {
      if (daysBeforeNow(reservation.arrival) === 0) {
        if (differenceInMilliseconds(new Date(), new Date(reservation.arrival!)) < 0) {
          return {
            type: NotificationChipType.Success,
            label: tAppliedReservationContext(
              labelPrefix + "guest_flow_completed_message_arrival_today_at_time"
            )
          };
        }
        return {
          type: NotificationChipType.Success,
          label: tAppliedReservationContext(
            labelPrefix + "guest_flow_completed_message_arrival_today"
          )
        };
      } else if (daysBeforeNow(reservation.arrival) === 1) {
        return {
          type: NotificationChipType.Success,
          label: tAppliedReservationContext(
            labelPrefix + "guest_flow_completed_message_arrival_tomorrow_at_time"
          )
        };
      } else if (daysBeforeNow(reservation.arrival) < 0) {
        return {
          type: NotificationChipType.Success,
          label: tAppliedReservationContext(
            labelPrefix + "guest_flow_completed_message_arrival_in_past"
          )
        };
      } else {
        return {
          type: NotificationChipType.Success,
          label: tAppliedReservationContext(
            labelPrefix + "guest_flow_completed_message_arrival_in_x_days"
          )
        };
      }
    }
    return {
      type: NotificationChipType.Error,
      label: tAppliedReservationContext(labelPrefix + "confirmed_message")
    };
  }, [
    reservation,
    tAppliedReservationContext,
    labelPrefix,
    isCheckoutToday,
    isCheckoutTomorrow,
    payAtCheckoutEnabled,
    checkoutFlowEnabled,
    isOpenBalance,
    isCheckoutFlowDone
  ]);
  return <NotificationChip type={notificationChip.type} label={notificationChip.label} />;
};
