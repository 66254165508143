import { useAppDispatch } from "../../../state/store";
import { useCallback } from "react";
import { patchTravelBuddy, PatchTravelBuddyArgs } from "../portal.slice";
import { TravelBuddy } from "../../../domain-common/travel-buddy";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useUpdateSecondaryGuestDetailsMutation } from "../../../graphql/mutations/update-secondary-guests.generated";
import { Reservation } from "../../../domain-common/reservation";
import { transformGenderCommonToGenderV2 } from "../../../graphql/transform/transform-utils";

export const usePatchTravelBuddy = ({ reservation }: { reservation: Reservation }) => {
  const dispatch = useAppDispatch();

  const { isRESTVersion } = useApiVersion();
  const [patchV2Action] = useUpdateSecondaryGuestDetailsMutation();
  const patchV1 = useCallback(
    ({
      originalTravelBuddy,
      updatedTravelBuddy,
      sendNotification
    }: {
      originalTravelBuddy: TravelBuddy | undefined;
      updatedTravelBuddy: TravelBuddy;
      sendNotification: boolean;
    }) =>
      dispatch(
        patchTravelBuddy({
          originalTravelBuddy,
          updatedTravelBuddy,
          sendNotification: sendNotification
        })
      ).unwrap(),
    [dispatch]
  );

  const patchV2 = useCallback(
    (args: PatchTravelBuddyArgs) => {
      const persons = reservation.travelBuddies.map((travelBuddy) => {
        const travelBuddyWithUpdatedItem =
          travelBuddy.magicId === args.updatedTravelBuddy.magicId
            ? args.updatedTravelBuddy
            : travelBuddy;

        return {
          gender: transformGenderCommonToGenderV2(travelBuddyWithUpdatedItem.travelBuddy.gender),
          firstName: travelBuddyWithUpdatedItem.travelBuddy.firstName,
          lastName: travelBuddyWithUpdatedItem.travelBuddy.lastName,
          phone: travelBuddyWithUpdatedItem.travelBuddy.phone,
          email: travelBuddyWithUpdatedItem.travelBuddy.email,
          pmsId: travelBuddyWithUpdatedItem.travelBuddy.id
        };
      });

      return patchV2Action({
        magicId: reservation.magicId,
        magicToken: reservation.magicToken,
        persons
      }).unwrap();
    },
    [reservation, patchV2Action]
  );
  return isRESTVersion ? patchV1 : patchV2;
};
