import { BackendModule, InitOptions, ReadCallback, Services } from "i18next";
import { documentTypeToFetchSingleAction } from "../../state/cms/cms-document-type-to-fetch-single-action";
import { documentTypeToFetchSinglePerPropertyAction } from "../../state/cms/cms-document-type-to-fetch-single-per-property-action";
import { CMSResourceStatus } from "../../state/cms/cms-resource-status";
import { CmsSingleDocumentPerPropertyType } from "../../state/cms/cms-single-document-per-property-type";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { CMSPageState } from "../../state/cms/cms-single-page-factory";
import {
  CMSPagePerPropertyState,
  selectIdFromActonPayload
} from "../../state/cms/cms-single-page-per-property-factory";
import { AppDispatch, RootStore } from "../../state/store";
import { getLanguageWithDefault } from "../../util/lang-utils";
import { PrismicData } from "../../api/cms-client/use-cms-client";

interface I18nCmsReduxBackendOptions {
  store: RootStore;
  dispatch: AppDispatch;
  prismic: PrismicData;
  commonPrismic: PrismicData;
  commonPrismicType?: string;
  allowedLocales: Array<string>;
}

export class I18nCmsReduxBackend implements BackendModule<I18nCmsReduxBackendOptions> {
  type: "backend" = "backend";
  private services!: Services;
  private options!: I18nCmsReduxBackendOptions;
  private i18nextOptions!: InitOptions;
  private languageResolvers: Map<string, Promise<any>> = new Map();

  constructor(
    services: Services,
    options: I18nCmsReduxBackendOptions,
    i18nextOptions: InitOptions
  ) {
    this.init(services, options, i18nextOptions);
  }

  init(
    services: Services,
    backendOptions: I18nCmsReduxBackendOptions,
    i18nextOptions: InitOptions
  ): void {
    this.services = services;
    this.options = backendOptions;
    this.i18nextOptions = i18nextOptions;
  }

  read(language: string, namespace: string, callback: ReadCallback): void {
    const verifiedLanguage = getLanguageWithDefault(language, this.options.allowedLocales);

    if (namespace.includes("#")) {
      const [ns, propertyId] = namespace.split("#");
      this.readWithPropertyId(verifiedLanguage, ns, propertyId, callback);
    } else {
      this.readWithoutPropertyId(verifiedLanguage, namespace, callback);
    }
  }

  create(languages: string[], namespace: string, key: string, fallbackValue: string): void {}

  readWithoutPropertyId(language: string, namespace: string, callback: ReadCallback) {
    const lang = language;
    let stateElement: CMSPageState =
      this.options.store.getState().cms[namespace as CMSSingleDocumentTypes];

    if (!stateElement) {
      return callback(new Error(`Namespace '${namespace}' not found in state`), false);
    }

    if (stateElement.loadingStatuses[lang] === CMSResourceStatus.FULFILLED) {
      const result = stateElement.entities[lang]?.data;
      return callback(null, result);
    }

    if (
      !stateElement.loadingStatuses[lang] ||
      stateElement.loadingStatuses[lang] === CMSResourceStatus.IDLE
    ) {
      const promise = this.options.dispatch(
        documentTypeToFetchSingleAction[namespace as CMSSingleDocumentTypes]({
          language: lang,
          prismic: this.options.prismic,
          commonPrismic: this.options.commonPrismic,
          type: this.options.commonPrismicType
        })
      );
      this.languageResolvers.set(lang + namespace, promise);
      promise
        .then(() => {
          callback(
            null,
            this.options.store.getState()?.cms?.[namespace as CMSSingleDocumentTypes]?.entities?.[
              lang
            ]?.data
          );
        })
        .catch(() => {
          callback(new Error("Error loading resource"), true);
        });
      return;
    }

    const languageResolver = this.languageResolvers.get(lang + namespace);
    if (stateElement.loadingStatuses[lang] === CMSResourceStatus.PENDING && languageResolver) {
      languageResolver.then(() => {
        callback(
          null,
          this.options.store.getState()?.cms?.[namespace as CMSSingleDocumentTypes]?.entities?.[
            lang
          ]?.data
        );
      });
    }

    callback(new Error(`Not found ${namespace} for language ${language}`), false);
  }

  readWithPropertyId(
    language: string,
    namespace: string,
    propertyId: string,
    callback: ReadCallback
  ) {
    let stateElement: CMSPagePerPropertyState =
      this.options.store.getState().cmsPerProperty[namespace as CmsSingleDocumentPerPropertyType];

    if (!stateElement) {
      return callback(new Error(`Namespace '${namespace}' not found in state`), false);
    }

    const entityId = selectIdFromActonPayload({
      propertyId,
      language
    });

    if (stateElement.loadingStatuses[entityId] === CMSResourceStatus.FULFILLED) {
      const result = stateElement.entities[entityId]?.data;
      return callback(null, result);
    }

    const languageResolver = this.languageResolvers.get(language + namespace + propertyId);
    if (stateElement.loadingStatuses[entityId] === CMSResourceStatus.PENDING && languageResolver) {
      languageResolver.then(() => {
        callback(
          null,
          this.options.store.getState()?.cmsPerProperty?.[
            namespace as CmsSingleDocumentPerPropertyType
          ]?.entities?.[entityId]?.data
        );
      });
      return;
    }

    if (
      !stateElement.loadingStatuses[entityId] ||
      stateElement.loadingStatuses[entityId] === CMSResourceStatus.IDLE
    ) {
      const promise = this.options.dispatch(
        documentTypeToFetchSinglePerPropertyAction[namespace as CmsSingleDocumentPerPropertyType]({
          propertyId,
          language,
          prismic: this.options.prismic
        })
      );
      this.languageResolvers.set(language + namespace + propertyId, promise);
      promise
        .then(() => {
          callback(
            null,
            this.options.store.getState()?.cmsPerProperty?.[
              namespace as CmsSingleDocumentPerPropertyType
            ]?.entities?.[entityId]?.data
          );
        })
        .catch(() => {
          callback(new Error("Error loading resource"), true);
        });
      return;
    }

    callback(new Error(`Not found ${namespace} for language ${language}`), false);
  }
}
