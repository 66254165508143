import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UpdateFlowStateMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  flowState: Types.FlowStateUpdateInput;
  magicToken: Types.Scalars["String"];
}>;

export type UpdateFlowStateMutation = {
  __typename?: "Mutation";
  UpdateFlowState: { __typename: "ReservationFlowState" };
};

export const UpdateFlowStateDocument = `
    mutation UpdateFlowState($magicId: String!, $flowState: FlowStateUpdateInput!, $magicToken: String!) {
  UpdateFlowState(
    flowState: $flowState
    magicId: $magicId
    magicToken: $magicToken
  ) {
    __typename
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UpdateFlowState: build.mutation<UpdateFlowStateMutation, UpdateFlowStateMutationVariables>({
      query: (variables) => ({ document: UpdateFlowStateDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdateFlowStateMutation } = injectedRtkApi;
