import { PaymentMethodsResponse } from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types";
import { PaymentMethodOptions, PaymentMethods } from "@adyen/adyen-web/dist/types/types";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector
} from "@reduxjs/toolkit";
import { PaymentApi } from "../../api/payment.api";
import { emptyPrice } from "../../domain-common/price";
import { EntityStateStatus, isStatusLoading, isStatusSuccess } from "../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { PaymentDTO, PaymentServicePaymentPayload } from "./payment-dto";
import { getLocalStoragePaymentState } from "./payment-store-local-storage";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { handleSliceError } from "../../util/error-handling";
import { BookingCreationApi } from "../../api/booking-creation.api";
import { ExtendedAdyenResponse } from "../../domain-common/adyen-responses";
import { BookingCreationDTO } from "../../domain-common/create-booking-payment";
import { getTenantHeaders } from "../../api/custom-headers";

export interface PaymentState {
  paymentMethodsConfiguration?: {
    [key in keyof PaymentMethods]?: Partial<PaymentMethodOptions<key>>;
  };
  paymentMethodsObject?: PaymentMethodsResponse;
  // paymentResponse?: ExtendedAdyenResponse;
  // TODO should be ExtendedAdyenResponse
  // extend-stay/payment and booking-creation/payment wraps result in 'action' topLevel; shop doesn't
  paymentResponse?: any;
  paymentDetailsResponse?: any;
  payByPaymentAccountStatus: EntityStateStatus;
  skipPaymentStatus: EntityStateStatus;
  withoutPaymentStatus: EntityStateStatus;
  paymentMethodsObjectStatus: EntityStateStatus;
  paymentStatus: EntityStateStatus;
  paymentDetailsStatus: EntityStateStatus;
  paymentDTO: PaymentDTO;
  referenceId?: string;
  errorMessage?: string;
  errorId?: string;
  magicId?: string;
}

export const defaultState: PaymentState = {
  paymentMethodsConfiguration: {
    ideal: {
      showImage: true
    },
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      enableStoreDetails: true,
      name: "Credit or debit card"
    }
  },
  skipPaymentStatus: EntityStateStatus.IDLE,
  payByPaymentAccountStatus: EntityStateStatus.IDLE,
  withoutPaymentStatus: EntityStateStatus.IDLE,
  paymentMethodsObjectStatus: EntityStateStatus.IDLE,
  paymentStatus: EntityStateStatus.IDLE,
  paymentDetailsStatus: EntityStateStatus.IDLE,
  errorId: "",
  paymentDTO: {
    adyenPrice: emptyPrice()
  },
  referenceId: undefined,
  magicId: undefined
};

export const fetchPaymentMethods = createAsyncThunk<
  PaymentMethodsResponse,
  { magicId?: string },
  { state: RootState; extra: ThunkExtraArguments }
>("payment/fetchPaymentMethods", async (args, thunkApi) => {
  try {
    const adyenPrice = thunkApi.getState().payment.paymentDTO.adyenPrice;
    const bookingCreationDTO = thunkApi.getState().payment.paymentDTO.bookingCreation;

    if (args.magicId) {
      return PaymentApi.fetchPaymentMethods(args.magicId, adyenPrice, {
        signal: thunkApi.signal,
        ...(await getTenantHeaders(thunkApi.extra))
      });
    } else if (bookingCreationDTO) {
      return BookingCreationApi.fetchPaymentMethods(bookingCreationDTO, {
        signal: thunkApi.signal,
        ...(await getTenantHeaders(thunkApi.extra))
      });
    }

    throw new Error("BookingCreationDTO is not provided");
  } catch (e) {
    return handleSliceError(e, thunkApi.rejectWithValue);
  }
});

export const payByPaymentAccount = createAsyncThunk<
  void,
  {
    paymentDTO: PaymentDTO;
    magicId: string;
  },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("payment/payByPaymentAccount", async (args, thunkApi) => {
  try {
    return await PaymentApi.paymentByPaymentAccount(args.magicId, args.paymentDTO, {
      signal: thunkApi.signal,
      ...(await getTenantHeaders(thunkApi.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkApi.rejectWithValue);
  }
});

export const skipPayment = createAsyncThunk<
  void,
  {
    paymentDTO: PaymentDTO;
    magicId: string;
  },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("payment/skipPayment", async (args, thunkApi) => {
  try {
    return await PaymentApi.skipPayment(args.magicId, args.paymentDTO, {
      signal: thunkApi.signal,
      ...(await getTenantHeaders(thunkApi.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkApi.rejectWithValue);
  }
});
export const createBookingWithoutPayment = createAsyncThunk<
  {
    bookingMagicId?: string;
  },
  {
    bookingCreationDTO: BookingCreationDTO;
  },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("payment/createBookingWithoutPayment", async (args, thunkApi) => {
  try {
    return await BookingCreationApi.createBookingWithoutPayment(args.bookingCreationDTO, {
      signal: thunkApi.signal,
      ...(await getTenantHeaders(thunkApi.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkApi.rejectWithValue);
  }
});

export const initiatePayment = createAsyncThunk<
  ExtendedAdyenResponse,
  {
    data: {
      paymentServicePayload?: PaymentServicePaymentPayload; // optional for 'book breakfast donation'
      paymentDTO: PaymentDTO;
      referenceId?: string;
    };
    magicId?: string;
  },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("payment/initiatePayment", async (args, thunkApi) => {
  try {
    return args.magicId
      ? await PaymentApi.payment(args.magicId, args.data, {
          signal: thunkApi.signal,
          ...(await getTenantHeaders(thunkApi.extra))
        })
      : await BookingCreationApi.payment(args.data, {
          signal: thunkApi.signal,
          ...(await getTenantHeaders(thunkApi.extra))
        });
  } catch (e) {
    return handleSliceError(e, thunkApi.rejectWithValue);
  }
});

export const initiatePaymentDetails = createAsyncThunk<
  ExtendedAdyenResponse,
  { data: any; magicId?: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>(
  "payment/initiatePaymentDetails",
  async (args, thunkApi) => {
    try {
      const referenceId = thunkApi.getState().payment.referenceId;
      return args.magicId
        ? await PaymentApi.paymentDetails(
            args.magicId,
            { ...args.data, referenceId },
            {
              signal: thunkApi.signal,
              ...(await getTenantHeaders(thunkApi.extra))
            }
          )
        : await BookingCreationApi.paymentDetails(
            { ...args.data, referenceId },
            {
              signal: thunkApi.signal,
              ...(await getTenantHeaders(thunkApi.extra))
            }
          );
    } catch (e) {
      return handleSliceError(e, thunkApi.rejectWithValue);
    }
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().payment.paymentDetailsStatus;
      if (status === EntityStateStatus.LOADING || status === EntityStateStatus.SUCCEEDED) {
        console.warn(
          "Action initiatePaymentDetails has been declined because it was happened before finishing previous one. ",
          arg
        );
        return false;
      }
    }
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState: defaultState,
  reducers: {
    setReferenceId: (state, action: PayloadAction<string | undefined>) => {
      state.referenceId = action.payload;
    },
    preparePayment: (state, action: PayloadAction<PaymentDTO>) => {
      if (state && state.paymentMethodsConfiguration) {
        state.paymentDTO = action.payload;
      }
    },
    clearPaymentState: () => defaultState,
    clearPaymentResponses: (state) => {
      state.paymentDetailsResponse = undefined;
      state.paymentResponse = undefined;
      state.paymentMethodsObject = undefined;
      state.paymentDetailsStatus = EntityStateStatus.IDLE;
      state.paymentStatus = EntityStateStatus.IDLE;
      state.paymentMethodsObjectStatus = EntityStateStatus.IDLE;
      state.payByPaymentAccountStatus = EntityStateStatus.IDLE;
      state.withoutPaymentStatus = EntityStateStatus.IDLE;
      state.errorId = "";
    },
    initialPaymentState: (_state, action: PayloadAction<{ identifier: string }>) =>
      getLocalStoragePaymentState(action.payload.identifier) || defaultState
  },
  extraReducers: (builder) => {
    builder
      /* payByPaymentAccount method starts */
      .addCase(payByPaymentAccount.pending, (state) => {
        state.payByPaymentAccountStatus = EntityStateStatus.LOADING;
      })
      .addCase(payByPaymentAccount.fulfilled, (state) => {
        state.payByPaymentAccountStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(payByPaymentAccount.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.payByPaymentAccountStatus === EntityStateStatus.LOADING) {
            state.payByPaymentAccountStatus = EntityStateStatus.IDLE;
          }
          return;
        }

        state.errorId = action.payload?.id;
        state.payByPaymentAccountStatus = EntityStateStatus.FAILED;
      })
      /* payByPaymentAccount ends */

      /* skipPaymentStatus method starts */
      .addCase(skipPayment.pending, (state) => {
        state.skipPaymentStatus = EntityStateStatus.LOADING;
      })
      .addCase(skipPayment.fulfilled, (state) => {
        state.skipPaymentStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(skipPayment.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.skipPaymentStatus === EntityStateStatus.LOADING) {
            state.skipPaymentStatus = EntityStateStatus.IDLE;
          }
          return;
        }

        state.errorId = action.payload?.id;
        state.skipPaymentStatus = EntityStateStatus.FAILED;
      })
      /* skipPaymentStatus ends */

      /* payByPaymentAccount method starts */
      .addCase(createBookingWithoutPayment.pending, (state) => {
        state.withoutPaymentStatus = EntityStateStatus.LOADING;
      })
      .addCase(createBookingWithoutPayment.fulfilled, (state, action) => {
        state.withoutPaymentStatus = EntityStateStatus.SUCCEEDED;
        state.paymentResponse = action.payload;
      })
      .addCase(createBookingWithoutPayment.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.withoutPaymentStatus === EntityStateStatus.LOADING) {
            state.withoutPaymentStatus = EntityStateStatus.IDLE;
          }
          return;
        }

        state.errorId = action.payload?.id;
        state.withoutPaymentStatus = EntityStateStatus.FAILED;
      })
      /* payByPaymentAccount ends */

      /* fetchPaymentMethods starts */
      .addCase(fetchPaymentMethods.pending, (state) => {
        state.paymentMethodsObjectStatus = EntityStateStatus.LOADING;
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethodsObjectStatus = EntityStateStatus.SUCCEEDED;
        state.paymentMethodsObject = action.payload;
      })
      .addCase(fetchPaymentMethods.rejected, (state) => {
        state.paymentMethodsObjectStatus = EntityStateStatus.FAILED;
      })
      /* fetchPaymentMethods end */

      /* Initiate payment method starts */
      .addCase(initiatePayment.pending, (state, action) => {
        state.paymentStatus = EntityStateStatus.LOADING;
        state.referenceId = action.meta.arg.data.referenceId;
      })
      .addCase(initiatePayment.fulfilled, (state, action) => {
        state.paymentResponse = action.payload;
        state.paymentStatus = EntityStateStatus.SUCCEEDED;
        state.magicId = action.payload?.bookingMagicId || action.meta.arg.magicId;
      })
      .addCase(initiatePayment.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.paymentStatus === EntityStateStatus.LOADING) {
            state.paymentStatus = EntityStateStatus.IDLE;
          }
          return;
        }

        state.errorId = action.payload?.id;
        state.paymentStatus = EntityStateStatus.FAILED;
      })
      /* Initiate payment method ends */

      /* Additional details method starts */
      .addCase(initiatePaymentDetails.pending, (state) => {
        state.paymentDetailsStatus = EntityStateStatus.LOADING;
      })
      .addCase(initiatePaymentDetails.fulfilled, (state, action) => {
        state.paymentDetailsResponse = action.payload;
        state.paymentDetailsStatus = EntityStateStatus.SUCCEEDED;
        state.magicId = action.payload?.bookingMagicId || action.meta.arg.magicId;
      })
      .addCase(initiatePaymentDetails.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.paymentDetailsStatus === EntityStateStatus.LOADING) {
            state.paymentDetailsStatus = EntityStateStatus.IDLE;
          }
          return;
        }
        state.paymentDetailsStatus = EntityStateStatus.FAILED;
        state.errorId = action.payload?.id;
      });
    /* Additional details method ends */
  }
});

export const {
  setReferenceId,
  preparePayment,
  clearPaymentState,
  initialPaymentState,
  clearPaymentResponses
} = paymentSlice.actions;

export const { reducer: paymentReducer } = paymentSlice;

export const selectPaymentSlice: Selector<RootState, PaymentState> = (state: RootState) =>
  state.payment;

export const selectPaymentMethodsConfiguration = createSelector(
  selectPaymentSlice,
  (s) => s.paymentMethodsConfiguration
);

export const selectIsPayByPaymentAccountLoading = createSelector(
  selectPaymentSlice,
  (s) => s.payByPaymentAccountStatus === EntityStateStatus.LOADING
);
export const selectIsInitiatePaymentLoading = createSelector(
  selectPaymentSlice,
  (s) => s.paymentStatus === EntityStateStatus.LOADING
);

export const selectIsPaymentDetailsLoading = createSelector(
  selectPaymentSlice,
  (s) => s.paymentDetailsStatus === EntityStateStatus.LOADING
);

export const selectIsPaymentMethodObjectLoading = createSelector(
  selectPaymentSlice,
  (s) => s.paymentMethodsObjectStatus === EntityStateStatus.LOADING
);

export const selectIsPaymentByAccountLoading = createSelector(selectPaymentSlice, (s) =>
  isStatusLoading(s.payByPaymentAccountStatus)
);

export const selectIsPaymentByAccountSuccessFinished = createSelector(selectPaymentSlice, (s) =>
  isStatusSuccess(s.payByPaymentAccountStatus)
);

export const selectIsWithoutPaymentLoading = createSelector(selectPaymentSlice, (s) =>
  isStatusLoading(s.withoutPaymentStatus)
);

export const selectIsWithoutPaymentSuccessFinished = createSelector(selectPaymentSlice, (s) =>
  isStatusSuccess(s.withoutPaymentStatus)
);
export const selectIsSkipPaymentLoading = createSelector(selectPaymentSlice, (s) =>
  isStatusLoading(s.skipPaymentStatus)
);

export const selectPaymentMagicId = createSelector(selectPaymentSlice, (s) => s.magicId);
