export enum CameraErrors {
  Error = "Error",
  NotAllowedError = "NotAllowedError",
  NotFoundError = "NotFoundError"
}

export enum CameraErrorsVariations {
  Error = "id_camera_general_issue",
  NotAllowedError = "id_camera_not_allowed",
  NotFoundError = "id_camera_not_found"
}

export interface StyleProps {
  isDesktop: boolean;
  isPortrait: boolean;
}

export interface IdCameraProps {
  onChange: (image: File | null) => void;
  closeCamera: () => void;
}
