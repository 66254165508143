import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { MyStayPagesEnum, MyStayPageToPath } from "../../portal/my-stay-page-to-path";
import { DynamicShopPage } from "./dynamic-shop.page";
import { DynamicShopBillOverviewPage } from "./dynamic-shop-bill-overview.page";
import { DynamicShopPaymentPage } from "./dynamic-shop-payment.page";
import { DynamicShopConfirmationPage } from "./dynamic-shop-confirmation.page";

export const DynamicPageRouter: FC = () => {
  return (
    <Routes>
      <Route path={"/"} element={<DynamicShopPage />} />
      <Route
        path={`${MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP_BILL_OVERVIEW]}`}
        element={<DynamicShopBillOverviewPage />}
      />
      <Route
        path={`${MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP_PAYMENT]}`}
        element={<DynamicShopPaymentPage />}
      />
      <Route
        path={`${MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP_CONFIRMATION]}`}
        element={<DynamicShopConfirmationPage />}
      />
    </Routes>
  );
};
