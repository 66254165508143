import React, { ReactElement, useEffect, useRef } from "react";
import { useNavbar } from "../organism/top-navbar/navbar-context";
import { NotificationBannerWrapper } from "../atoms/notification/notification-banner-wrapper";
import { BackButton } from "../atoms/back-button/back-button";
import { useIsMobile } from "./hooks/use-is-mobile";

interface FormTemplateProps {
  icons?: Array<ReactElement>;
  handleBack?: () => void;
  progress?: number;
  notFixedButton?: boolean;
}

export const FlowTemplate: React.FC<React.PropsWithChildren<FormTemplateProps>> = (props) => {
  const { icons, handleBack, progress, children } = props;
  const { setLeftButtons, setRightButtons, setProgress, setDisplayNavbar } = useNavbar();
  const isMobile = useIsMobile();

  // This is done to avoid infinite loop in for the setRightButtons useEffect.
  const iconsRef = useRef(icons);
  const backIconRef = useRef(
    handleBack ? <BackButton key="back_button" onClick={handleBack} /> : null
  );

  useEffect(() => {
    setDisplayNavbar(true);
  }, [setDisplayNavbar]);

  useEffect(() => {
    setLeftButtons([backIconRef.current]);
  }, [setLeftButtons]);

  useEffect(() => {
    setRightButtons(iconsRef.current);
  }, [setRightButtons]);

  useEffect(() => {
    setProgress(progress || 0);
  }, [progress, setProgress]);

  return (
    <NotificationBannerWrapper marginInline={isMobile ? 2 : 0}>
      {children}
    </NotificationBannerWrapper>
  );
};
