import { MyStayEventCard } from "../domain-common/my-stay-event-card";
import { PrismicDocument } from "@prismicio/client";

export function PrismicDocumentToMyStayEventCardMapper(doc: PrismicDocument): MyStayEventCard {
  return {
    id: doc.id,
    title: doc.data["main__title"],
    subtitle: doc.data["card__subtitle"],
    coverImage: doc.data["main__cover_image"]?.["url"],
    date: doc.data["main__from"] ? new Date(doc.data["main__from"]) : null
  };
}
