import React, {
  createContext,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { TenantContext, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { usePageVisibility } from "../../util/hooks/use-page-visibility";

export interface WebSocketContextValue {
  ws: MutableRefObject<WebSocket | undefined>;
  lastIdentifierToSubscribe: MutableRefObject<string | undefined>;
  initialised: boolean;
}

export const WebSocketContext = createContext<WebSocketContextValue>({
  ws: undefined,
  lastIdentifierToSubscribe: undefined,
  initialised: false
} as any);

const getWsUrl = (tenantId: string) => {
  const protocol = window.location.protocol === "http:" ? "ws:" : "wss:";
  const host = window.location.host;
  return `${protocol}//${host}/api/guest-journey-service/magic-ws/${tenantId}`;
};

export const WebSocketProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { tenantId } = useContext(TenantContext);
  const { isRESTVersion } = useApiVersion();
  const ws = React.useRef<WebSocket | undefined>(undefined);
  const lastIdentifierToSubscribe = useRef<string | undefined>(undefined);

  const { isPageVisible } = usePageVisibility();
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (!ws.current && isPageVisible && isRESTVersion) {
      ws.current = new WebSocket(getWsUrl(tenantId));
      ws.current.onopen = () => {
        setInitialised(true);
      };
    }
    return () => {
      ws.current?.close(1000);
      ws.current = undefined;
      lastIdentifierToSubscribe.current = undefined;
      setInitialised(false);
    };
  }, [ws, tenantId, isPageVisible, isRESTVersion]);

  return (
    <WebSocketContext.Provider value={{ ws, initialised, lastIdentifierToSubscribe }}>
      {children}
    </WebSocketContext.Provider>
  );
};
