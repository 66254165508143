import { useShopCmsTranslation } from "../../state/shop-cms/use-shop-cms-translation";
import { useSelector } from "react-redux";
import {
  GetShopAvailabilityQuery,
  useGetShopAvailabilityQuery
} from "../../graphql/queries/GetShopAvailability.generated";
import { transformFullPrice } from "../../graphql/transform/transform-reservation";
import { FullPrice } from "../../domain-common/full-price";
import { ShopType } from "../../graphql/generated/graphql";
import { DynamicShopPreviewItem, selectDynamicShopSelectedFilters } from "./dynamic-shop.slice";

export interface DynamicShopItem {
  serviceId: string;
  fullPrice: FullPrice;
  isRent: boolean;
}

type ShopItem = Exclude<
  GetShopAvailabilityQuery["GetAdditionalServicesAvailability"],
  null | undefined
>[number];

const mapShopItem = (arg: ShopItem): DynamicShopItem => ({
  serviceId: arg?.pmsServiceId ?? "",
  fullPrice: transformFullPrice(arg?.basePrice),
  isRent: !!arg?.payAtCheckout
});

interface UseMinibarProps {
  propertyId: string;
  magicId: string;
  shopType: ShopType;
}

export const useDynamicShopItems = ({ propertyId, magicId, shopType }: UseMinibarProps) => {
  const { findItem } = useShopCmsTranslation(propertyId, shopType);
  const selectedFilters = useSelector(selectDynamicShopSelectedFilters);

  const { data } = useGetShopAvailabilityQuery({
    magicId,
    shopTypesIncludeFilter: shopType
  });

  const serviceItems = data?.GetAdditionalServicesAvailability?.map(mapShopItem) || [];

  const allItems: Array<DynamicShopPreviewItem> = serviceItems
    .filter((item) => findItem(item.serviceId))
    .map((item) => {
      const cmsItemData = findItem(item.serviceId);
      return {
        ...item,
        categoryId: cmsItemData?.item_category,
        shopType,
        isRent: item.isRent
      };
    });

  const filteredItems = allItems?.filter(
    (item) =>
      Object.keys(selectedFilters).length === 0 ||
      Object.keys(selectedFilters).find((categoryId) => categoryId === item.categoryId)
  );

  return { allItems, filteredItems };
};
