import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

interface DefaultLoaderProps {
  visible: boolean;
  backdropInvisible?: boolean;
}

export const DefaultLoader: React.FC<React.PropsWithChildren<DefaultLoaderProps>> = ({
  visible,
  backdropInvisible
}) => {
  return (
    <>
      {backdropInvisible ? (
        <CircularProgress size={100} />
      ) : (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={visible}>
          <CircularProgress size={100} />
        </Backdrop>
      )}
    </>
  );
};
