import { Booking as BookingCommon } from "../../domain-common/booking";
import { Reservation } from "../../domain-common/reservation";
import { differenceInDays } from "date-fns";
import { sumPrices } from "../../components/atoms/price-preview/price-preview";
import { toGross } from "../../domain-common/full-price";

export interface GtmECommerce {
  transaction_id?: string;
  items: ReservationItem[];
  currency?: string;
  value: string;
}

export const bookingMagicObjectIntoGTMBooking = (
  magicObject: BookingCommon,
  authenticated: boolean,
  isEndOfDayPms: boolean
): GtmECommerce => {
  const firstReservation = magicObject.reservations[0];
  const currency = firstReservation?.totalPayments?.currency;
  const reservations = magicObject.reservations.map((res) =>
    reservationMagicObjectIntoGTMReservationItem(res, authenticated, isEndOfDayPms)
  );

  return {
    transaction_id: magicObject.id,
    items: reservations,
    currency,
    value: String(
      reservations.reduce((sum, reservation) => {
        return sum + parseFloat(reservation.price);
      }, 0)
    )
  };
};

export interface ReservationItem {
  item_id: string;
  item_category: string;
  location_id: string;
  price: string;
  quantity: number;
  cartUUID?: string;
  reservationId?: string;
  promoCodePMS?: string | null;
  promoCodeMagicPMS?: string | null;
  arrival?: string;
  departure?: string;
  nights?: number;
  adults?: number;
  children?: number[];
  bookedWithUserAccount?: boolean;
}

export const reservationMagicObjectIntoGTMReservationItem = (
  reservation: Reservation,
  authenticated: boolean,
  isEndOfDayPms: boolean
): ReservationItem => {
  const price = isEndOfDayPms
    ? sumPrices(
        reservation.groupedRateBreakdown
          .flatMap((item) => item.breakdownItems)
          .flatMap((item) => item.items)
          .map((item) => item.price)
      )
    : toGross(reservation.totalCharges);
  return {
    item_id: reservation.ratePlanId!,
    item_category: reservation.unitGroup.id,
    location_id: reservation.propertyId,
    price: String(price.amount / 100),
    quantity: 1,
    reservationId: reservation.id,
    arrival: reservation.arrival?.split("T")[0],
    departure: reservation.departure?.split("T")[0],
    nights: differenceInDays(
      new Date(reservation.departure?.split("T")[0]!),
      new Date(reservation.arrival?.split("T")[0]!)
    ),
    adults: reservation.adults,
    children: reservation.childrenAges,
    bookedWithUserAccount: authenticated ?? false
  };
};
