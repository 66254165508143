import React from "react";
import { Tab as MuiTab, TabProps } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

export const Tab = withStyles((theme) => ({
  root: {
    fontSize: 17,
    textTransform: "none",
    "&$selected": {
      color: theme.palette.text.primary
    },
    "&$focus": {
      color: theme.palette.text.primary
    }
  },
  selected: {
    color: theme.palette.text.primary
  }
}))((props: TabProps) => <MuiTab disableRipple {...props} />);
