import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useCallback, useEffect, useMemo } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { useCommonGlobalErrorCmsData } from "../../../state/common-cms/use-common-cms-data";
import { BackArrowIcon } from "../../icons";
import { ErrorWarnSign } from "../../icons/error-warn-sign";
import { RoundedFullHeight } from "../../layouts/rounded-full-height";
import { SubmitButton } from "../button/submit-button";
import { TopNavigation } from "../top-navigation/top-navigation";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";

const useStyles = makeStyles()((theme) => ({
  logo: {
    maxWidth: theme.spacing(25),
    height: theme.spacing(8.75)
  }
}));

interface ErrorFallbackPageProps extends FallbackProps {
  hideBackButton?: boolean;
}

export const ErrorFallbackPage: React.FC<React.PropsWithChildren<ErrorFallbackPageProps>> = ({
  resetErrorBoundary,
  error,
  hideBackButton
}) => {
  useEffect(() => {
    console.error({
      ...error,
      message: error.message,
      stack: error.stack,
      name: error.name,
      cause: error.cause
    });
  }, [error]);

  const { classes } = useStyles();
  const globalErrorCmsData = useCommonGlobalErrorCmsData();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const backAction = useCallback(() => {
    setTimeout(() => {
      resetErrorBoundary();
    }, 50);
    navigate(-1);
  }, [navigate, resetErrorBoundary]);

  const backButton = useMemo(
    () => (
      <IconButton key="back_button" onClick={backAction} size="large">
        <BackArrowIcon />
        {!isMobile && (
          <Paragraph ml={1} fontSize={20}>
            {t("buttons__back")}
          </Paragraph>
        )}
      </IconButton>
    ),
    [backAction, t, isMobile]
  );

  return (
    <>
      <TopNavigation leftButtons={!hideBackButton ? [backButton] : []} />
      <RoundedFullHeight px={2.5}>
        <Box display="flex" flexDirection="row" justifyContent="center" pt={3} pb={2.5}>
          <img
            src={globalErrorCmsData?.["global_error__logo"]?.url}
            alt="logo"
            className={classes.logo}
          />
        </Box>
        <Box my={10}>
          <Paragraph
            variant="subtitle1"
            align="center"
            color="textSecondary"
            style={{ fontSize: 130, marginBottom: 80 }}
          >
            <ErrorWarnSign fontSize="inherit" />
          </Paragraph>
        </Box>
        <Box mt={4}>
          <Paragraph variant="subtitle1" align="center" color="textSecondary">
            {globalErrorCmsData?.["global_error__description"]}
          </Paragraph>
        </Box>
      </RoundedFullHeight>
      <SubmitButton
        label={"Reload"}
        onClick={() => {
          // @ts-ignore
          window.location.reload(true);
        }}
      />
    </>
  );
};

export const ErrorHandler: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <ErrorBoundary FallbackComponent={ErrorFallbackPage}>{children}</ErrorBoundary>;
};
