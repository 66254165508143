import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { additionalServiceCartSlice } from "../features/additional-services/service-selection/additional-services-cart.slice";
import { accountsSlice } from "../slices/accounts.slice";
import { checkinSlice } from "../features/checkin-flow/checkin-flow.slice";
import { gtmSlice } from "../features/gtm/gtm.slice";
import { reservationSlice } from "../features/reservation-provider/reservation.slice";
import { additionalServicesCartStoreListener } from "../features/additional-services/service-selection/additional-services-cart-store-listener";
import { magicObjectSlice } from "../features/magic/magic-object.slice";
import { myStayEventsSlice } from "../features/my-stay-events/my-stay-events.slice";
import { myStayTipsSlice } from "../features/my-stay-tips/my-stay-tips.slice";
import { paymentReducer, paymentSlice } from "../features/payment/payment.slice";
import { portalSlice } from "../features/portal/portal.slice";
import { propertiesSliceByCity } from "../features/property/properties-by-city";
import { servicesSlice } from "../features/services/services.slice";
import { cartSlice } from "../features/shop/store/cart.slice";
import { shopDisplaySlice } from "../features/shop/store/shop-display.slice";
import { cmsPerPropertyReducer } from "./cms/cms-per-property.slice";
import { cmsReducer } from "./cms/cms.slice";
import { checkoutSlice } from "../features/checkout-flow/checkout-flow.slice";
import { bannerSlice } from "../features/banner/banner.slice";
import { loaderSlice } from "../features/loaders/loader.slice";
import { paymentListener } from "../features/payment/payment-store-listener";
import { pwaSlice } from "../features/pwa/pwa.slice";
import { userProfileSlice } from "../features/user-profile/user-profile.slice";
import { extendStaySlice } from "../features/extend-stay/extend-stay.slice";
import { keyAssignSlice } from "../features/key-assign/key-assign.slice";
import { restoreMagicContextSlice } from "../features/restore-magic-context/restore-magic-context.slice";
import { bookingOverviewSlice } from "../features/booking-overview/state/booking-overview.slice";
import { findReservationSlice } from "../features/find-reservation/find-reservation.slice";
import { searchAvailablePropertiesSlice } from "../features/search-for-bookings/search-available-properties.slice";
import { searchAvailableUnitGroupsSlice } from "../features/search-for-bookings/search-available-unit-groups.slice";
import { bookingCartSlice } from "../features/search-for-bookings/booking-cart.slice";
import { persistReducer, persistStore } from "redux-persist";
import { keycloakSlice } from "../slices/keycloak.slice";
import { initialMagicInfoSlice } from "../features/initial-magic-info/initial-magic-id.slice";
import {
  bookingCartConfig,
  dynamicShopCartConfig,
  persistConfig,
  restoreMagicContextConfig
} from "../config/redux-persist.config";
import { cookieSlice } from "../features/cookie-popup/cookie.slice";
import { inactiveBookingOverviewSlice } from "../features/booking-overview/state/inactive-booking-overview.slice";
import { additionalServicesSlice } from "../features/services/additional-services.slice";
import { serviceDetailSlice } from "../features/additional-services/service-details-cms.slice";
import { graphqlApi } from "../graphql/graphql.api";
import { boxShopSlice } from "../features/box-shop/box-shop.slice";
import { rentSlice } from "../features/box-shop/box-rent-items.slice";
import { selfPouringStationShopSlice } from "../features/self-pouring-station-shop/self-pouring-station-shop.slice";
import { secondScreenHomeSlice } from "../features/second-screen-home-page/second-screen-home.slice";
import { promoCodeSlice } from "../features/search-for-bookings/promocode.slice";
import { dynamicShopCartSlice } from "../features/dynamic-shop/dynamic-shop.slice";

export const rootReducers = combineReducers({
  [magicObjectSlice.name]: magicObjectSlice.reducer,
  [paymentSlice.name]: paymentReducer,
  [propertiesSliceByCity.name]: propertiesSliceByCity.reducer,
  [servicesSlice.name]: servicesSlice.reducer,
  [additionalServicesSlice.name]: additionalServicesSlice.reducer,
  [reservationSlice.name]: reservationSlice.reducer,
  [portalSlice.name]: portalSlice.reducer,
  [myStayEventsSlice.name]: myStayEventsSlice.reducer,
  [myStayTipsSlice.name]: myStayTipsSlice.reducer,
  [serviceDetailSlice.name]: serviceDetailSlice.reducer,
  [checkinSlice.name]: checkinSlice.reducer,
  [checkoutSlice.name]: checkoutSlice.reducer,
  [shopDisplaySlice.name]: shopDisplaySlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [extendStaySlice.name]: extendStaySlice.reducer,
  [bannerSlice.name]: bannerSlice.reducer,
  [gtmSlice.name]: gtmSlice.reducer,
  [loaderSlice.name]: loaderSlice.reducer,
  [additionalServiceCartSlice.name]: additionalServiceCartSlice.reducer,
  [pwaSlice.name]: pwaSlice.reducer,
  [accountsSlice.name]: accountsSlice.reducer,
  [keycloakSlice.name]: keycloakSlice.reducer,
  [userProfileSlice.name]: userProfileSlice.reducer,
  [keyAssignSlice.name]: keyAssignSlice.reducer,
  [restoreMagicContextSlice.name]: persistReducer(
    restoreMagicContextConfig,
    restoreMagicContextSlice.reducer
  ),
  [bookingOverviewSlice.name]: bookingOverviewSlice.reducer,
  [inactiveBookingOverviewSlice.name]: inactiveBookingOverviewSlice.reducer,
  [findReservationSlice.name]: findReservationSlice.reducer,
  [searchAvailablePropertiesSlice.name]: searchAvailablePropertiesSlice.reducer,
  [searchAvailableUnitGroupsSlice.name]: searchAvailableUnitGroupsSlice.reducer,
  [cookieSlice.name]: cookieSlice.reducer,
  [boxShopSlice.name]: boxShopSlice.reducer,
  [selfPouringStationShopSlice.name]: selfPouringStationShopSlice.reducer,
  [bookingCartSlice.name]: persistReducer(bookingCartConfig, bookingCartSlice.reducer),
  [dynamicShopCartSlice.name]: persistReducer(dynamicShopCartConfig, dynamicShopCartSlice.reducer),
  [initialMagicInfoSlice.name]: initialMagicInfoSlice.reducer,
  [rentSlice.name]: rentSlice.reducer,
  [secondScreenHomeSlice.name]: secondScreenHomeSlice.reducer,
  [promoCodeSlice.name]: promoCodeSlice.reducer,
  cms: cmsReducer,
  cmsPerProperty: cmsPerPropertyReducer,
  [graphqlApi.reducerPath]: graphqlApi.reducer
});

const _persistedReducer = persistReducer(persistConfig, rootReducers);
export interface ThunkExtraArguments {
  apiKey: string;
  tenantId: string;
  keycloak: any;
}

export const setupStore = (
  extraArgument: ThunkExtraArguments,
  initialState?: Partial<typeof rootReducers>
) => {
  const extra = extraArgument as ThunkExtraArguments;
  const store = configureStore({
    reducer: _persistedReducer,
    preloadedState: { ...initialState },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: extra
        },
        serializableCheck: false
      }).concat(graphqlApi.middleware)
  });
  store.subscribe(() => {
    storeListeners.forEach((listener) => {
      listener(store);
    });
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export type RootStore = ReturnType<typeof setupStore>["store"];

export type RootState = ReturnType<RootStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = RootStore["dispatch"];
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

const storeListeners: Array<(storeArg: RootStore) => void> = [
  additionalServicesCartStoreListener,
  paymentListener
];
