import { Button, Paragraph, Subtitle, useAuth } from "@likemagic-tech/sv-magic-library";
import { Box, CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRangePicker } from "../../../components/molecules/range-date-picker/date-range-picker";
import Yup from "../../../config/yup.config";
import { useAppDispatch } from "../../../state/store";
import { generateMagicLink, getLocationOrigin } from "../../../util/routing";
import { useFindReservation, UseFindReservationState } from "../use-find-reservation";
import { openFindReservationModal } from "../find-reservation.slice";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { getKioskPropertyId, isKioskMode } from "../../../util/kiosk-mode";
import { useFeatureFlags, useKioskConfig } from "../../../util/hooks/use-configuration";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";
import { SearchBookingPropertyFilter } from "../../search-for-bookings/components/filters/search-booking-property-filter";
import { useProperties } from "../../property/use-property-by-id";
import { listOfProperties } from "../../property/properties-by-city";
import { openBanner } from "../../banner/banner.slice";
import { WrappedInput } from "../../../components/atoms/input/wrapped-input";

let findReservationValidationSchema = (labels: { required: string }) =>
  Yup.object({
    firstName: Yup.string().required(labels.required),
    lastName: Yup.string().required(labels.required),
    arrival: Yup.date().required(labels.required),
    departure: Yup.date().required(labels.required)
  });

const useStyles = makeStyles()(({ spacing }) => ({
  logo: {
    maxWidth: spacing(35),
    marginBottom: spacing(1.5)
  }
}));

export const FindReservation = () => {
  const { login, getIsAuthenticated } = useAuth();
  const dispatch = useAppDispatch();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const { classes } = useStyles();
  const { authEnabled, tfeFeatureEnabled } = useFeatureFlags();
  const { search } = useLocation();
  const redirectTo = new URLSearchParams(search).get("redirectTo") || "";
  const { data: properties } = useProperties();
  const allProperties = listOfProperties(properties);

  const kioskPropertyIdOld = getKioskPropertyId();
  const { propertyId: kioskPropertyId } = useKioskConfig();

  const logoUrl = useLogoUrl();
  const { initialValues, findReservation } = useFindReservation(allProperties);

  const navigate = useNavigate();
  const setArrivalAndDepartureDates = useCallback(
    (arrival: string, to: string, formik: FormikProps<any>) => {
      formik.setFieldValue("arrival", arrival);
      formik.setFieldValue("departure", to);
    },
    []
  );

  const onSubmit = useCallback(
    async (values: UseFindReservationState) => {
      if (kioskPropertyId || kioskPropertyIdOld) {
        values.propertyId = kioskPropertyId || kioskPropertyIdOld;
      }
      const reservations = await findReservation(values);

      if (reservations && reservations.length === 1) {
        navigate(generateMagicLink(reservations[0].magicId));
      } else if (reservations && reservations.length > 1) {
        dispatch(
          openFindReservationModal({
            reservations,
            propertyId: values.propertyId
          })
        );
      } else {
        dispatch(
          openBanner({
            type: "error",
            title: t("validations__no_reservations")
          })
        );
      }
    },
    [findReservation, navigate, dispatch, kioskPropertyId, kioskPropertyIdOld, t]
  );

  const validationSchema = useMemo(
    () =>
      findReservationValidationSchema({
        required: t("validation__required")
      }),
    [t]
  );

  return (
    <Box maxWidth={"450px"}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" py={1}>
        {!isKioskMode() && <img src={logoUrl} alt="logo" className={classes.logo} />}
        <Subtitle align="center">{t("title__find_reservation")}</Subtitle>
      </Box>
      <Formik
        // needed for async loading of properties
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {!kioskPropertyId && !kioskPropertyIdOld && allProperties.length > 1 && (
                    <SearchBookingPropertyFilter
                      properties={properties}
                      value={formik.values.propertyId}
                      onChange={formik.handleChange}
                      disabled={formik.isSubmitting}
                      showOnlyProperties
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <WrappedInput
                    id={"firstName"}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && formik.errors.firstName}
                    label={t("labels__first_name")}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item xs={6}>
                  <WrappedInput
                    id={"lastName"}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && formik.errors.lastName}
                    label={t("labels__last_name")}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateRangePicker
                    arrival={formik.values.arrival}
                    departure={formik.values.departure}
                    error={
                      (formik.touched.arrival && formik.errors.arrival) ||
                      (formik.touched.departure && formik.errors.departure)
                    }
                    onSubmit={(from, to) => setArrivalAndDepartureDates(from, to, formik)}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Box mt={4} width="100%">
                    <Button
                      variant="primary"
                      type="submit"
                      data-testid="find-reservation"
                      disabled={formik.isSubmitting}
                      fullWidth
                    >
                      {formik.isSubmitting ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        t("buttons__find_reservation")
                      )}
                    </Button>
                  </Box>
                  {authEnabled && !getIsAuthenticated() && !isKioskMode() && !tfeFeatureEnabled && (
                    <>
                      <Box mt={4}>
                        <Paragraph align="center" sx={{ textTransform: "uppercase" }}>
                          {t("labels__or")}
                        </Paragraph>
                      </Box>
                      <Box mt={4} width="100%">
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={async () =>
                            await login({
                              // if present, using the redirectTo query param to construct redirectUri
                              redirectUri: redirectTo
                                ? getLocationOrigin() + `/?redirectTo=${redirectTo}`
                                : ""
                            })
                          }
                          disabled={formik.isSubmitting}
                          fullWidth
                        >
                          {formik.isSubmitting ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            t("buttons__login")
                          )}
                        </Button>
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
