import React, { ChangeEvent, FC } from "react";
import { Box, Grid } from "@mui/material";
import { Caption, Checkbox, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { ReservationExtras } from "../../../domain-common/reservation-extras";
import { useFormikContext } from "formik";

interface MarketingConsentOptinProps {
  value: ReservationExtras;
  onChange: (e: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  label: string;
}

export const MarketingConsentOptin: FC<MarketingConsentOptinProps> = ({
  value,
  onChange,
  label
}) => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { setFieldValue } = useFormikContext();

  const onChangeSomething = (e: ChangeEvent<HTMLInputElement>, flagValue: boolean) => {
    if (!flagValue) {
      setFieldValue("extras.tcMarketingConsentDoubleOptin", flagValue);
    }
    onChange(e, flagValue);
  };

  return (
    <Grid container direction="row" alignItems="start" mt={2} sx={{ flexWrap: "nowrap" }}>
      <Checkbox
        name="extras.tcMarketingConsent"
        id="extras.tcMarketingConsent"
        checked={value.tcMarketingConsent}
        onChange={onChangeSomething}
        sx={{ paddingLeft: 0, paddingTop: 0 }}
      />
      <Paragraph sx={{ paddingRight: 2 }}>{label}</Paragraph>
      {value.tcMarketingConsentDoubleOptin && (
        <Box
          sx={{
            borderRadius: 1.5,
            backgroundColor: "success.light",
            p: 1
          }}
        >
          <Caption
            sx={{
              color: "success.dark"
            }}
          >
            {tCommon("labels__marketing_consent_double_optin_confirmation")}
          </Caption>
        </Box>
      )}
    </Grid>
  );
};
