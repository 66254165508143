import React, { FC, useCallback, useMemo, useState } from "react";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { generateBookingOverviewPagesUrl } from "../booking-overview-navigation";
import { BookingOverviewPages } from "../booking-overview-pages";
import { useAppDispatch } from "../../../state/store";
import { Actor } from "../../../domain-common/booking-overview";
import { isTravelBuddy } from "../../../util/flow";
import { Box } from "@mui/material";
import { DesktopCard, DisplayCmsSvg, Notification } from "../../../components";
import { FieldArray, Form, Formik } from "formik";
import { BookerGuestForm } from "../../../components/molecules/personal-form/booker-guest-form";
import { PersonForm } from "../../../components/molecules/personal-form/person-form";
import { GuestFormFormik } from "../../../components/molecules/guest-form-formik";
import { PersonalDataSubmitButtons } from "../components/personal-data-submit-buttons";
import {
  PersonalFormData,
  usePersonalPageFormHandler
} from "../hooks/use-personal-page-form-handler";
import { ConfirmationDialogLabels } from "../../../components/atoms/dialog/confirmation-dialog";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { Editability, useGuestDataEditable } from "../hooks/use-guest-data-editable";
import { EditableNotification } from "../../../components/atoms/notification/editable-notification";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useNavigate } from "react-router-dom";
import { useFetchAllBookerOverview } from "../hooks/use-fetch-all-booker-overview";
import { openBanner } from "../../banner/banner.slice";
import {
  useCommonErrorsMapperCmsData,
  useCommonValidationCmsData
} from "../../../state/common-cms/use-common-cms-data";
import { getErrorEntry } from "../../../state/common-cms/use-common-error-translation";
import { useUpdateReservation } from "../../reservation-provider/use-update-reservation";
import {
  useAdditionalTravelBuddyFieldsConfig,
  useFeatureFlags
} from "../../../util/hooks/use-configuration";
import { useGetBookingOverviewMagicId } from "../booking-overview-subscription-provider";
import { MarketingConsent } from "../../../components/molecules/personal-form/marketing-consent";
import { MarketingConsentOptin } from "../../../components/molecules/personal-form/marketing-consent-optin";
import { additionalTravelBuddyFieldValidation } from "../../../util/additional-travel-buddy-fields-validation";
import { TravelBuddyTermsAndConditions } from "../../../components/molecules/personal-form/travel-buddy-terms-and-conditions";
import { useTheme } from "@mui/styles";
import { useHasMinors } from "../../../util/hooks/use-has-minors";

export const BookingOverviewPersonalDataPage: FC<React.PropsWithChildren<{}>> = () => {
  const { magicId: magicIdFromUrl, reservationMagicId, bookingOverviewItemId } = useMagicIdParams();

  const magicId = useGetBookingOverviewMagicId({
    magicIdFromUrl: magicIdFromUrl
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    diverseGenderEnabled,
    genderDisplayDisabled,
    marketingConsentEnabled,
    marketingConsentDoubleOptinEnabled,
    travelBuddyIsMinorValidationEnabled
  } = useFeatureFlags();
  const { personalDataStepValidation, tcMinorsValidation } = useFormValidations();
  const goBack = useCallback(
    () =>
      navigate(
        generateBookingOverviewPagesUrl(
          BookingOverviewPages.BOOKING_RESERVATION_DETAILS,
          magicId,
          bookingOverviewItemId,
          reservationMagicId
        )
      ),
    [magicId, bookingOverviewItemId, reservationMagicId, navigate]
  );
  const cmsErrorData = useCommonErrorsMapperCmsData();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const [redirectToGuestFlow, setRedirectToGuestFlow] = useState<boolean | undefined>(undefined);
  const { getBookingOverviewByBookingOverviewItemId, getReservationByReservationId } =
    useFetchAllBookerOverview({ magicId });
  const { spacing } = useTheme();
  const { hasMinors } = useHasMinors();

  const bookingOverview = getBookingOverviewByBookingOverviewItemId(bookingOverviewItemId);
  const reservation = getReservationByReservationId(reservationMagicId);
  const { patchGuestFlowStep } = useUpdateReservation();

  const validationCmsData = useCommonValidationCmsData();
  const additionalTravelBuddyFields = useAdditionalTravelBuddyFieldsConfig();
  const dynamicSchema = additionalTravelBuddyFieldValidation(
    additionalTravelBuddyFields,
    validationCmsData,
    false
  );
  const newPersonalDataStepValidationSchema = travelBuddyIsMinorValidationEnabled
    ? personalDataStepValidation.concat(tcMinorsValidation).concat(dynamicSchema)
    : personalDataStepValidation.concat(dynamicSchema);

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const { handleSubmit, initialValues } = usePersonalPageFormHandler({
    reservation,
    currentMagicId: magicId,
    actor: bookingOverview?.actor,
    patchGuestFlowStep,
    bookingOverview,
    bookingOverviewItemId
  });

  const handleError = useCallback(() => {
    const prismicSameDataEntry = getErrorEntry(
      cmsErrorData,
      "errors__travel_buddy_same_contact_data"
    );
    dispatch(
      openBanner({
        type: "error",
        title: prismicSameDataEntry?.text || cmsErrorData?.["errors__default_label"]
      })
    );
  }, [dispatch, cmsErrorData]);

  const handleSubmitWithInitGuestFlow = useCallback(
    async (values: PersonalFormData) => {
      const sameData = values.additionalGuests?.find(
        (guest) =>
          (guest.email && guest.email === values.person.email) ||
          (guest.phone && guest.phone === values.person.phone)
      );
      if (sameData) {
        handleError();
        return null;
      }
      if (reservation) {
        return handleSubmit(values, !reservation.flowState.notificationSent, redirectToGuestFlow);
      }
    },
    [handleSubmit, reservation, redirectToGuestFlow, handleError]
  );

  const labels = useMemo(
    () => ({
      firstName: tCommon("labels__first_name"),
      lastName: tCommon("labels__last_name"),
      genderFemale: tCommon("labels__gender_female"),
      genderMale: tCommon("labels__gender_male"),
      genderOther: tCommon("labels__gender_other"),
      phone: tCommon("labels__phone"),
      email: tCommon("labels__email")
    }),
    [tCommon]
  );

  const guestFormLabels = React.useMemo(
    () => ({
      firstName: tCommon("labels__first_name"),
      lastName: tCommon("labels__last_name"),
      genderFemale: tCommon("labels__gender_female"),
      genderMale: tCommon("labels__gender_male"),
      genderOther: tCommon("labels__gender_other"),
      phone: tCommon("labels__phone"),
      email: tCommon("labels__email"),
      ownerLabel: tCommon("labels__reservation_owner"),
      othersLabel: tCommon("labels__reservation_others"),
      birthdate: tCommon("labels__birthdate"),
      nationalityCountryCode: tCommon("labels__nationality"),
      postalCode: tCommon("labels__postal_code"),
      address: tCommon("labels__address"),
      countryCode: tCommon("labels__country"),
      city: tCommon("labels__city")
    }),
    [tCommon]
  );

  const dialogLabels: ConfirmationDialogLabels = React.useMemo(
    () => ({
      title: tCommon("modals__delete_buddy_dialog_title"),
      text: tCommon("modals__delete_buddy_dialog_text"),
      confirm: tCommon("buttons__yes"),
      deny: tCommon("buttons__no")
    }),
    [tCommon]
  );
  const controlLabels = React.useMemo(
    () => ({
      heading: tCommon("labels__travel_buddy"),
      delete: tCommon("buttons__remove"),
      add: tCommon("buttons__add_buddy")
    }),
    [tCommon]
  );

  const { editable } = useGuestDataEditable({
    uneditableStatus: [ReservationStatus.CHECKED_OUT],
    reservation
  });
  if (editable === undefined) {
    return null;
  }

  if (!reservation) {
    return null;
  }

  return (
    <FlowTemplate handleBack={goBack}>
      <HelmetTitle suffix="Editing data" />
      <DesktopCard>
        <Box p={2.5}>
          <PageHeadingInfo
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
            title={tCommon("title__personal_data_page")}
          />

          <EditableNotification editabilities={[editable]} />
          {initialValues && (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmitWithInitGuestFlow}
              validationSchema={newPersonalDataStepValidationSchema}
            >
              {(formik) => (
                <Form noValidate>
                  {bookingOverview?.actor === Actor.BOOKER && (
                    <BookerGuestForm
                      disabled={editable !== Editability.EDITABLE}
                      objectPathPrefix="extras."
                      onChange={formik.handleChange}
                      labels={{
                        booker: guestFormLabels.ownerLabel,
                        guest: guestFormLabels.othersLabel
                      }}
                      value={formik.values.extras?.bookingOnBehalfOf}
                    />
                  )}
                  <PersonForm
                    objectPathPrefix="person."
                    values={formik.values.person}
                    onChange={formik.handleChange}
                    labels={labels}
                    nameEditable={editable !== Editability.EDITABLE || formik.isSubmitting}
                    contactDataEditable={editable !== Editability.EDITABLE || formik.isSubmitting}
                    touched={formik.touched.person}
                    errors={formik.errors.person}
                    diverseGenderEnabled={diverseGenderEnabled}
                    genderDisplayDisabled={genderDisplayDisabled}
                  />
                  {marketingConsentEnabled && !marketingConsentDoubleOptinEnabled && (
                    <MarketingConsent
                      value={formik.values.extras?.tcMarketingConsent}
                      onChange={formik.handleChange}
                      label={tCommon("labels__marketing_consent")}
                    />
                  )}
                  {marketingConsentEnabled && marketingConsentDoubleOptinEnabled && (
                    <MarketingConsentOptin
                      value={formik.values.extras}
                      onChange={formik.handleChange}
                      label={tCommon("labels__marketing_consent_with_double_optin")}
                    />
                  )}
                  {!isTravelBuddy(bookingOverview) ? (
                    <>
                      {hasMinors(formik?.values?.additionalGuests) &&
                        travelBuddyIsMinorValidationEnabled && (
                          <Box my={spacing(4)}>
                            <Notification
                              type="info"
                              title={tCommon("labels__additional_travel_buddy_under_age_info")}
                            />
                          </Box>
                        )}
                      <FieldArray
                        name="additionalGuests"
                        render={(props) => (
                          <GuestFormFormik
                            {...props}
                            disabled={editable !== Editability.EDITABLE || formik.isSubmitting}
                            dialogLabels={dialogLabels}
                            guestFormLabels={guestFormLabels}
                            controlLabels={controlLabels}
                            onChange={formik.handleChange}
                          />
                        )}
                      />
                      {travelBuddyIsMinorValidationEnabled &&
                        formik.values?.additionalGuests?.length !== 0 && (
                          <TravelBuddyTermsAndConditions
                            value={formik.values.extras.tcMinors}
                            onChange={formik.handleChange}
                            touched={formik.touched?.extras?.tcMinors ?? false}
                            errors={formik.errors?.extras?.tcMinors ?? ""}
                          />
                        )}
                    </>
                  ) : null}
                  {editable === Editability.EDITABLE && (
                    <PersonalDataSubmitButtons
                      disabled={formik.isSubmitting}
                      isFormDirty={formik.dirty}
                      bookingOnBehalfOf={formik.values.extras.bookingOnBehalfOf}
                      reservation={reservation}
                      setShouldUseGuestFlow={setRedirectToGuestFlow}
                    />
                  )}
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </DesktopCard>
    </FlowTemplate>
  );
};
