import React, { FC } from "react";
import { useUnitGroupCmsData } from "../../../../state/property-cms/use-unit-group-cms-data";
import { FeaturesList } from "../../../../components/molecules/features-preview/features-list";
import { SizeEnum } from "../../../../domain-common/size-enum";

export const UnitGroupFeatures: FC<
  React.PropsWithChildren<{
    propertyId: string;
    unitGroupId: string;
  }>
> = ({ unitGroupId, propertyId }) => {
  const { unitGroupFeatures } = useUnitGroupCmsData(propertyId, unitGroupId);

  return <FeaturesList items={unitGroupFeatures} itemSize={SizeEnum.SMALL} />;
};
