import { useCmsPerPropertyData } from "../cms/use-cms-per-property-data";
import { fetchPromoCodesCMS, selectPromoCodesCMSById } from "./promo-codes-cms.slice";

export const usePromoCodeCmsTranslation = (propertyId: string, promoCode: string): string => {
  const cmsData = useCmsPerPropertyData({
    propertyId: propertyId || "",
    selector: selectPromoCodesCMSById,
    fetchAction: fetchPromoCodesCMS
  });

  if (cmsData?.data && cmsData?.data["main__promo_codes"]) {
    let cmsEntry = (cmsData?.data["main__promo_codes"] as Array<any>).find(
      (elem) => elem.code === promoCode
    );

    return cmsEntry?.translation || promoCode;
  }

  return promoCode;
};
