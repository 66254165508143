import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { Reservation } from "../../../domain-common/reservation";
import {
  MyStayNavigationOptions,
  useConfigurationCmsData
} from "../../../state/configuration-cms/use-configuration-cms";
import { NavigationKeys } from "./my-stay-navigation-items/navigation-keys";
import { NavigationInvoice } from "./my-stay-navigation-items/navigation-invoices";
import { NavigationTravelBuddy } from "./my-stay-navigation-items/navigation-travel-buddy";
import { NavigationServices } from "./my-stay-navigation-items/navigation-services";
import { NavigationShop } from "./my-stay-navigation-items/navigation-shop";
import { NavigationFAQ } from "./my-stay-navigation-items/navigation-faq";
import { NavigationSelfPouringStation } from "./my-stay-navigation-items/navigation-self-pouring-station";
import { NavigationBox } from "./my-stay-navigation-items/navigation-box";
import { NavigationRoomControl } from "./my-stay-navigation-items/navigation-room-control";
import { NavigationExtendStay } from "./my-stay-navigation-items/navigation-extend-stay";
import { NavigationExternal } from "./my-stay-navigation-items/navigation-external";
import { NavigationDynamicShop } from "./my-stay-navigation-items/navigation-dynamic-shop";
import { DynamicWidget } from "./my-stay-navigation-items/dynamic-widget";

interface MyStayNavigationProps {
  reservation: Reservation;
}

export const MyStayNavigation: FC<MyStayNavigationProps> = ({ reservation }) => {
  const { myStayNavigationItemsFromCms } = useConfigurationCmsData(reservation.propertyId);

  if (myStayNavigationItemsFromCms && myStayNavigationItemsFromCms.length > 0) {
    return (
      <Box px={2.5} pt={2}>
        <Grid container spacing={2}>
          {myStayNavigationItemsFromCms.map((item, index) => {
            const itemType = item.link_type;
            const key = `${itemType}-${index}`;

            switch (itemType) {
              case MyStayNavigationOptions.KEYS:
                return (
                  <NavigationKeys
                    title={item.title}
                    iconUrl={item.icon.url}
                    reservation={reservation}
                    key={key}
                  />
                );
              case MyStayNavigationOptions.INVOICE:
                return (
                  <NavigationInvoice
                    title={item.title}
                    iconUrl={item.icon.url}
                    reservation={reservation}
                    key={key}
                  />
                );
              case MyStayNavigationOptions.TRAVEL_BUDDY:
                return (
                  <NavigationTravelBuddy
                    title={item.title}
                    iconUrl={item.icon.url}
                    reservation={reservation}
                    key={key}
                  />
                );
              case MyStayNavigationOptions.SERVICES:
                return (
                  <NavigationServices
                    title={item.title}
                    iconUrl={item.icon.url}
                    reservation={reservation}
                    key={key}
                  />
                );
              case MyStayNavigationOptions.SHOP:
                return (
                  <NavigationShop
                    title={item.title}
                    iconUrl={item.icon.url}
                    reservation={reservation}
                    key={key}
                  />
                );
              case MyStayNavigationOptions.FAQ:
                return (
                  <NavigationFAQ
                    title={item.title}
                    iconUrl={item.icon.url}
                    reservation={reservation}
                    key={key}
                  />
                );
              case MyStayNavigationOptions.SELF_POURING_STATION:
                return (
                  <NavigationSelfPouringStation
                    title={item.title}
                    iconUrl={item.icon.url}
                    reservation={reservation}
                    key={key}
                  />
                );
              case MyStayNavigationOptions.BOX:
                return (
                  <NavigationBox
                    reservation={reservation}
                    key={key}
                    title={item.title}
                    iconUrl={item.icon.url}
                  />
                );
              case MyStayNavigationOptions.ROOM_CONTROL:
                return (
                  <NavigationRoomControl
                    reservation={reservation}
                    key={key}
                    title={item.title}
                    iconUrl={item.icon.url}
                  />
                );
              case MyStayNavigationOptions.EXTEND_STAY:
                return (
                  <NavigationExtendStay
                    reservation={reservation}
                    key={key}
                    title={item.title}
                    iconUrl={item.icon.url}
                  />
                );
              case MyStayNavigationOptions.MINIBAR:
              case MyStayNavigationOptions.AMENITIES:
                return (
                  <NavigationDynamicShop
                    title={item.title}
                    iconUrl={item.icon.url}
                    linkType={itemType}
                    reservation={reservation}
                    key={key}
                  />
                );
              case MyStayNavigationOptions.EXTERNAL:
                return (
                  <NavigationExternal
                    title={item.title}
                    link={item.link}
                    iconUrl={item.icon.url}
                    key={key}
                  />
                );
              case MyStayNavigationOptions.EXTERNAL_WIDGET:
                return (
                  <DynamicWidget
                    person={reservation.primaryGuest}
                    title={item.title}
                    link={item.link}
                    iconUrl={item.icon.url}
                    key={key}
                  />
                );
              default:
                console.warn(`The type ${itemType} is not handeled.`);
                return null;
            }
          })}
        </Grid>
      </Box>
    );
  }

  return (
    <Box px={2.5} pt={2}>
      <Grid container spacing={2}>
        <NavigationKeys reservation={reservation} />
        <NavigationInvoice reservation={reservation} />
        <NavigationTravelBuddy reservation={reservation} />
        <NavigationServices reservation={reservation} />
        <NavigationShop reservation={reservation} />
        <NavigationFAQ reservation={reservation} />
        <NavigationSelfPouringStation reservation={reservation} />
        <NavigationBox reservation={reservation} />
        <NavigationRoomControl reservation={reservation} />
        <NavigationExtendStay reservation={reservation} />
      </Grid>
    </Box>
  );
};
