import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function ProfileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M18.1448 19.8899C17.3397 19.0797 16.3823 18.4367 15.3277 17.998C14.2731 17.5592 13.1421 17.3333 11.9999 17.3333C10.8577 17.3333 9.72677 17.5592 8.67218 17.998C7.61759 18.4367 6.66018 19.0797 5.85504 19.8899M7.33331 9.99992C7.33331 11.2376 7.82498 12.4246 8.70015 13.2998C9.57532 14.1749 10.7623 14.6666 12 14.6666C13.2377 14.6666 14.4246 14.1749 15.2998 13.2998C16.175 12.4246 16.6666 11.2376 16.6666 9.99992C16.6666 8.76224 16.175 7.57526 15.2998 6.70009C14.4246 5.82492 13.2377 5.33325 12 5.33325C10.7623 5.33325 9.57532 5.82492 8.70015 6.70009C7.82498 7.57526 7.33331 8.76224 7.33331 9.99992ZM2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
