import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function MessagingWhatsappIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M14.26,18.51l.43,0a4.23,4.23,0,0,0,3.87-2.17,2.11,2.11,0,0,0,.15-2.08h0a1.53,1.53,0,0,0-.73-.55l-.14-.07s-1.73-.85-2.09-1a1.2,1.2,0,0,0-1.54.43c-.17.25-.35.51-.48.68a8.27,8.27,0,0,1-3.57-3l.23-.21A1.52,1.52,0,0,0,10.8,10a1.26,1.26,0,0,0,0-1.19c-.06-.12-.3-.72-.54-1.28l-.35-.84a1.65,1.65,0,0,0-1.29-1,2.18,2.18,0,0,0-2,.58,5.14,5.14,0,0,0-1,5.67A11.19,11.19,0,0,0,14.26,18.51ZM7.74,7.36a.68.68,0,0,1,.45-.14h0a.64.64,0,0,1,.32.07l.35.84c.21.51.43,1,.54,1.28l0,0L9,9.8a1.14,1.14,0,0,0-.25,1.51,9.76,9.76,0,0,0,4.45,3.88,1.18,1.18,0,0,0,1.53-.25c.18-.21.45-.58.61-.82.33.14,1.26.59,1.84.88l.16.08.09,0a1.19,1.19,0,0,1-.17.5A2.81,2.81,0,0,1,14.37,17a9.67,9.67,0,0,1-7.31-5.55A3.63,3.63,0,0,1,7.74,7.36Z" />
        <path d="M.77,22.36a.75.75,0,0,0,.94,1L5.7,22A12,12,0,0,0,24,11.91a12,12,0,0,0-24,0,11.75,11.75,0,0,0,2.06,6.67Zm.72-10.45A10.5,10.5,0,1,1,12,22.32,10.5,10.5,0,0,1,6.22,20.6a.75.75,0,0,0-.64-.09l-2.91.92L3.6,18.7A.75.75,0,0,0,3.49,18,10.27,10.27,0,0,1,1.49,11.91Z" />
      </g>
    </SvgIcon>
  );
}
