import { TipThumbnailCard } from "../tip-cards/tip-thumbnail-card";
import { MyStayTipContentType } from "../../../../domain-common/my-stay-tip-content-type";
import { TipType } from "../../../../domain-common/my-stay-tip-card";
import React, { FC } from "react";
import { usePwa } from "../../../pwa/use-pwa";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../../state/common-cms/common-cms.slice";
import { useIsMobile } from "../../../../components/layouts/hooks/use-is-mobile";
import { useIsNative } from "@likemagic-tech/sv-magic-library";
import { Grid } from "@mui/material";
import { useFeatureFlags } from "../../../../util/hooks/use-configuration";

export const InstallAppTipItem: FC = () => {
  const { installApp, showInstallAppButton } = usePwa();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const isMobile = useIsMobile();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const isNative = useIsNative();
  const { addToHomeScreenHidden } = useFeatureFlags();

  return showInstallAppButton && isMobile && !isNative && !addToHomeScreenHidden ? (
    <Grid item xs={6} md={3}>
      <TipThumbnailCard
        tip={{
          id: "installApp",
          contentType: MyStayTipContentType.THUMBNAIL_CARD,
          type: TipType.ARTICLE,
          title: t("application__install_button_title"),
          subtitle: t("application__install_button_description"),
          coverImage: cmsData?.data["application__install_button_image"]?.url,
          onClick: installApp
        }}
      />
    </Grid>
  ) : null;
};
