import { Box, Grid } from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AdditionalServiceButton, DisplayCmsSvg, RoundBox } from "../../components";
import { useAppDispatch } from "../../state/store";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { generatePortalMyStayUrl } from "../../util/routing";
import { HelmetTitle } from "../gtm/helmet-title";
import { useSetupSubpageNavigation } from "../portal/hooks/use-setup-subpage-navigation";
import { useServicesCmsData } from "../services/use-services-cms-data";
import { AddToCartDialog } from "./components/add-to-cart-dialog";
import { CartFab } from "./components/cart-fab";
import { ShopFilter } from "./components/shop-filter";
import { useCartPage } from "./hooks/use-cart-page";
import { mapCmsLabelsToServiceGroups } from "./shop-page-service";
import { updateCart } from "./store/cart.slice";
import {
  clearFilter,
  closeAddToCartDialog,
  openAddToCartDialog,
  toggleFilter
} from "./store/shop-display.slice";
import { PageHeadingInfo } from "../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { useCMSData } from "../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../state/common-cms/common-cms.slice";
import { useIsMobile } from "../../components/layouts/hooks/use-is-mobile";
import { useReservationContext } from "../reservation-provider/reservation-provider";
import { GroupNames, useFetchShopProducts } from "./hooks/use-fetch-shop-products";
import { useSelectedProducts } from "./hooks/use-selected-products";
import { useMagicIdParams } from "../magic/use-magic-id-params";

export const ShopPage: FC = () => {
  const dispatch = useAppDispatch();
  const { magicId } = useMagicIdParams();
  const { reservation } = useReservationContext();
  const { products, availableFilters } = useFetchShopProducts(reservation, [
    GroupNames.Beverage,
    GroupNames.Food
  ]);
  useEffect(
    () => () => {
      dispatch(clearFilter());
    },
    [dispatch]
  );
  const isMobile = useIsMobile();
  const { findCartLineItem } = useCartPage();
  const { selectedProduct, selectedFilters } = useSelectedProducts();
  const itemFromCart = findCartLineItem(selectedProduct?.serviceId);

  const filteredProducts = useMemo(
    () =>
      products.filter(
        (item) =>
          Object.keys(selectedFilters).length === 0 ||
          (item?.serviceGroup?.name && selectedFilters[item?.serviceGroup?.name])
      ),
    [selectedFilters, products]
  );
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const {
    cmsData: servicesCmsData,
    serviceImages: productImagesByServiceId,
    serviceTitles: productTitlesByServiceId,
    fallbackImage: productFallbackImage
  } = useServicesCmsData(reservation.propertyId);
  const cmsServiceGroups = servicesCmsData?.data?.["service_groups"];

  const query = new URLSearchParams(useLocation().search);
  const filterValue = query.get("filter");

  useEffect(() => {
    if (filterValue && availableFilters?.length) {
      const filter = availableFilters.find((f) => f.name === filterValue);
      if (filter) {
        dispatch(toggleFilter({ filterName: filter.name }));
      }

      return () => {
        dispatch(toggleFilter(undefined));
      };
    }
  }, [filterValue, availableFilters, dispatch]);

  const allFilters = useMemo(
    () => mapCmsLabelsToServiceGroups(availableFilters, cmsServiceGroups),
    [cmsServiceGroups, availableFilters]
  );

  useSetupSubpageNavigation(generatePortalMyStayUrl);

  const handleAddToCartClose = useCallback(
    (arg?: { count: number }) => {
      if (selectedProduct) {
        dispatch(
          updateCart({
            item: {
              quantity: arg?.count ?? 0,
              serviceId: selectedProduct.serviceId
            },
            magicId
          })
        );
      }

      dispatch(closeAddToCartDialog());
    },
    [dispatch, selectedProduct, magicId]
  );

  return (
    <>
      <HelmetTitle suffix="Shop item list" />
      <RoundBox hasShadow py={3} px={2}>
        <PageHeadingInfo
          title={tCommon("title__shop_page")}
          subtitle={tCommon("subtitle__shop_page")}
          icon={<DisplayCmsSvg url={cmsData?.data?.icon__cart_icon?.url} />}
        />
      </RoundBox>
      <ShopFilter
        filters={selectedFilters}
        availableFilters={allFilters}
        onFilterClick={(f) => {
          dispatch(toggleFilter(f ? { filterName: f.name } : undefined));
        }}
      />
      <Box px={2.5} py={2.5}>
        <Grid container spacing={2}>
          {filteredProducts.map((product) => (
            <Grid key={product.serviceId} item md={6} xs={12}>
              <AdditionalServiceButton
                useWhiteBackground
                serviceName={productTitlesByServiceId[product.serviceId] || product.serviceId}
                icon={
                  <Box textAlign="center">
                    {productImagesByServiceId[product.serviceId] ? (
                      <img
                        height="64px"
                        src={productImagesByServiceId[product.serviceId]}
                        alt={product.serviceId}
                      />
                    ) : undefined}
                  </Box>
                }
                price={product.fullPrice}
                onClick={() => dispatch(openAddToCartDialog({ product }))}
                quantity={findCartLineItem(product.serviceId)?.quantity ?? 0}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {selectedProduct && (
        <AddToCartDialog
          product={
            selectedProduct
              ? { id: selectedProduct?.serviceId, fullPrice: selectedProduct.fullPrice }
              : null
          }
          onClose={handleAddToCartClose}
          primaryButtonLabel={
            itemFromCart?.quantity ? tCommon("buttons__update") : tCommon("buttons__add")
          }
          secondaryButtonLabel={itemFromCart?.quantity ? tCommon("buttons__remove") : undefined}
          image={selectedProduct ? productImagesByServiceId[selectedProduct.serviceId] : undefined}
          fallbackImage={productFallbackImage}
          title={selectedProduct ? productTitlesByServiceId[selectedProduct.serviceId] : ""}
          initialCounter={itemFromCart?.quantity ? itemFromCart?.quantity : 1}
        />
      )}
      {isMobile && <CartFab />}
    </>
  );
};
