import { useSelector } from "react-redux";
import { fetchRentItems, selectRentedItems } from "../box-rent-items.slice";
import { useEffect } from "react";
import { useAppDispatch } from "../../../state/store";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

export const useRentedProducts = ({
  magicId,
  magicToken,
  isTravelBuddy
}: {
  magicId: string;
  magicToken: string;
  isTravelBuddy: boolean;
}) => {
  const { boxEnabled } = useFeatureFlags();
  const rentedItems = useSelector(selectRentedItems);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (boxEnabled && !isTravelBuddy) {
      const promise = dispatch(fetchRentItems({ magicId, magicToken }));
      return () => {
        promise.abort();
      };
    }
  }, [dispatch, magicId, magicToken, boxEnabled, isTravelBuddy]);

  return { rentedItems };
};
