import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetLatestMagicObjectQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetLatestMagicObjectQuery = {
  __typename?: "Query";
  GetLatestMagicObject?:
    | { __typename?: "Booking"; magicId: string }
    | { __typename?: "Reservation"; magicId: string }
    | { __typename?: "TravelBuddy"; magicId: string }
    | null;
};

export const GetLatestMagicObjectDocument = `
    query GetLatestMagicObject {
  GetLatestMagicObject {
    ... on Booking {
      magicId
    }
    ... on Reservation {
      magicId
    }
    ... on TravelBuddy {
      magicId
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetLatestMagicObject: build.query<
      GetLatestMagicObjectQuery,
      GetLatestMagicObjectQueryVariables | void
    >({
      query: (variables) => ({ document: GetLatestMagicObjectDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetLatestMagicObjectQuery, useLazyGetLatestMagicObjectQuery } = injectedRtkApi;
