import { useCallback, useEffect } from "react";
import { selectIsCheckedOut, startCheckout } from "./checkout-flow.slice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../state/store";
import { useSelector } from "react-redux";
import { useApiVersion, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { getYesNoModalArg } from "../global-modal/global-modal-util";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { generatePortalCheckoutSuccessUrl } from "../../util/routing";
import { useCheckoutMutation } from "../../graphql/mutations/checkout.generated";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { Reservation } from "../../domain-common/reservation";

export const useCheckout = ({ reservation }: { reservation: Reservation }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { open: openModal } = useGlobalModal();
  const isCheckedOut = useSelector(selectIsCheckedOut);

  const { isRESTVersion } = useApiVersion();

  const [checkOutMutationV2, { status }] = useCheckoutMutation();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const checkoutAction = useCallback(
    () =>
      isRESTVersion
        ? dispatch(
            startCheckout({
              magicId: reservation.magicId,
              magicToken: reservation.magicToken
            })
          )
        : checkOutMutationV2({
            magicId: reservation.magicId,
            magicToken: reservation.magicToken
          }),
    [checkOutMutationV2, isRESTVersion, dispatch, reservation.magicId, reservation.magicToken]
  );

  const openCheckoutModal = useCallback(async () => {
    const result = await openModal(
      getYesNoModalArg(
        t("modals__checkout_title"),
        t("modals__checkout_content"),
        t("buttons__yes"),
        t("buttons__no")
      )
    );

    if (!!result) {
      checkoutAction();
    }
  }, [openModal, t, checkoutAction]);

  useEffect(() => {
    if (isCheckedOut || status === QueryStatus.fulfilled) {
      navigate(`${generatePortalCheckoutSuccessUrl(reservation?.magicId)}`);
    }
  }, [isCheckedOut, navigate, reservation?.magicId, status]);

  return { openCheckoutModal, checkoutAction };
};
