import { FC } from "react";
import { Box, Collapse } from "@mui/material";
import { useBanner } from "../../../features/banner/use-banner";
import { Notification } from "./notification";
import { makeStyles } from "tss-react/mui";
import { responsiveVariables } from "../../layouts/hooks/use-is-mobile";

export interface NotificationBannerWrapperProps {
  margin?: number;
  marginInline?: number;
}

const useStyles = makeStyles()(({ breakpoints, spacing, palette }) => ({
  desktopPosition: {
    background: palette.background.paper,
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      background: "transparent",
      top: spacing(8),
      zIndex: 2,
      width: "100%",
      left: 0,
      margin: "auto"
    }
  }
}));

export const NotificationBannerWrapper: FC<
  React.PropsWithChildren<NotificationBannerWrapperProps>
> = ({ children, margin, marginInline }) => {
  const { bannerState, onClose } = useBanner();
  const { classes } = useStyles();
  return (
    <Box>
      <Box className={classes.desktopPosition}>
        <Collapse in={bannerState.isOpen}>
          <Box py={1} margin={margin} mx={marginInline}>
            <Notification
              type={bannerState.type}
              title={bannerState.title}
              subtitle={bannerState.subtitle}
              errorId={bannerState.errorId}
              onClose={onClose}
            />
          </Box>
        </Collapse>
      </Box>
      {children}
    </Box>
  );
};
