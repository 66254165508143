import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { useEffect } from "react";

interface GuestFLowNavigationProps {
  showTopNavBar: boolean;
  showBottomBar: boolean;
}

export const useSetupGuestFlowNavigation = ({
  showBottomBar,
  showTopNavBar
}: GuestFLowNavigationProps) => {
  const { setDisplayNavbar } = useNavbar();
  const { setDisplayBottomNavbar } = useBottomNavbar();
  useEffect(() => {
    setDisplayNavbar(showTopNavBar);
    setDisplayBottomNavbar(showBottomBar);
  }, [setDisplayNavbar, setDisplayBottomNavbar, showTopNavBar, showBottomBar]);
};
