import React, { FC, useCallback, useMemo } from "react";
import { Reservation } from "../../../domain-common/reservation";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { useMagicIdParams } from "../../../features/magic/use-magic-id-params";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import {
  generateCheckoutFlowCheckoutDepartureTimePageUrl,
  generatePortalMyStayBillsUrl,
  generatePortalMyStayOpenBalanceUrl
} from "../../../util/routing";
import { CallToActionButton } from "./call-to-action-button";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useCheckout } from "../../../features/checkout-flow/use-checkout";
import { useNavigate } from "react-router-dom";
import {
  useEarliestCheckinOffsetConfig,
  useFeatureFlags,
  useHidePaymentConfig
} from "../../../util/hooks/use-configuration";
import { MagicFileType } from "../../../domain-common/magic-file-type";
import { useCheckIn } from "../../../features/guest-flow/hooks/use-checkin";
import { isReservation } from "../../../util/flow";
import { IdCheckStatus } from "../../../domain-common/id-check-status";
import { CallToActionIdCheckStatus } from "./call-to-action-id-check-status";
import {
  CONFIRMATION_TYPE_KEY,
  ConfirmationType
} from "../../molecules/confirmation/confirmation-type";
import { daysBeforeNow } from "@likemagic-tech/sv-magic-library";
import { CheckoutFlowPages } from "../../../features/checkout-flow/checkout-flow-pages";

interface CallToActionGuestFlowFinishedButtonProps {
  reservation: Reservation;
  payAtCheckoutEnabled: boolean;
  checkoutFlowEnabled: boolean;
}

export const CallToActionGuestFlowFinishedButton: FC<CallToActionGuestFlowFinishedButtonProps> = ({
  reservation,
  payAtCheckoutEnabled,
  checkoutFlowEnabled
}) => {
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const { openCheckoutModal } = useCheckout({ reservation });
  const earliestCheckinOffset = useEarliestCheckinOffsetConfig();
  const hidePrepaymentConfig = useHidePaymentConfig();
  const { guestCheckInIntoPMSDisabled } = useFeatureFlags();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const isCheckoutTodayOrLater = useMemo(
    () => daysBeforeNow(reservation.departure) === 0,
    [reservation.departure]
  );

  const hideOpenBalanceButtonForPayAtCheckout = payAtCheckoutEnabled && !isCheckoutTodayOrLater;

  const isOpenBalance = useMemo(() => {
    return reservation.totalAllowedPayment?.amount > 0;
  }, [reservation.totalAllowedPayment]);

  const isEarliestCheckinDateInThePast = useMemo(() => {
    if (reservation.arrival) {
      let earliestCheckinDate = new Date(reservation.arrival);
      earliestCheckinDate.setHours(earliestCheckinDate.getHours() - earliestCheckinOffset);

      let currentDate = new Date();

      return currentDate.getTime() > earliestCheckinDate.getTime();
    }

    return false;
  }, [reservation.arrival, earliestCheckinOffset]);

  const isCheckoutTomorrow = useMemo(
    () => daysBeforeNow(reservation.departure) === 1,
    [reservation.departure]
  );

  const isCheckoutFlowReady = useMemo(
    () =>
      checkoutFlowEnabled &&
      //TODO should have some configuration when to display a button
      (isCheckoutTomorrow || isCheckoutTodayOrLater) &&
      //TODO replace logic with some boolean value
      reservation?.flowState.context?.lastConfirmedCheckoutPage !== CheckoutFlowPages.CONFIRMATION,
    [
      reservation?.flowState.context?.lastConfirmedCheckoutPage,
      checkoutFlowEnabled,
      isCheckoutTomorrow,
      isCheckoutTodayOrLater
    ]
  );

  const checkin = useCheckIn({ reservation });

  const showBill = useCallback(() => {
    navigate(`${generatePortalMyStayBillsUrl(magicId)}`);
  }, [magicId, navigate]);

  const goToCheckoutFlow = useCallback(() => {
    navigate(`${generateCheckoutFlowCheckoutDepartureTimePageUrl(magicId)}`);
  }, [magicId, navigate]);

  if (
    (isOpenBalance && !payAtCheckoutEnabled && !isCheckoutFlowReady) ||
    (isOpenBalance && reservation.status === ReservationStatus.CHECKED_OUT)
  ) {
    return (
      <CallToActionButton
        onClick={() => navigate(generatePortalMyStayOpenBalanceUrl(magicId))}
        text={t("buttons__open_balance")}
      />
    );
  }

  if (isOpenBalance && !hideOpenBalanceButtonForPayAtCheckout && !isCheckoutFlowReady) {
    return (
      <CallToActionButton
        onClick={() =>
          navigate(
            generatePortalMyStayOpenBalanceUrl(magicId, {
              [CONFIRMATION_TYPE_KEY]: ConfirmationType.OPEN_BALANCE_WITH_CHECKOUT
            })
          )
        }
        text={t("buttons__checkout")}
      />
    );
  }

  if (
    reservation.status === ReservationStatus.CONFIRMED &&
    isEarliestCheckinDateInThePast &&
    !guestCheckInIntoPMSDisabled
  ) {
    return <CallToActionButton onClick={checkin} text={t("buttons__access_button")} />;
  }

  if (reservation.status === ReservationStatus.IN_HOUSE) {
    if (isCheckoutFlowReady) {
      return (
        <CallToActionButton onClick={goToCheckoutFlow} text={t("buttons__express_checkout")} />
      );
    }

    if (isCheckoutTodayOrLater) {
      return <CallToActionButton onClick={openCheckoutModal} text={t("buttons__checkout")} />;
    }

    if (isCheckoutTomorrow && !reservation?.extras?.billConfirmed) {
      return <CallToActionButton onClick={showBill} text={t("buttons__show_bill")} />;
    }
  }

  if (reservation.status === ReservationStatus.CHECKED_OUT) {
    if (
      hidePrepaymentConfig &&
      reservation.folios.every((folio) => hidePrepaymentConfig[folio.prepaymentType])
    ) {
      return (
        <CallToActionButton
          onClick={() => navigate(`${generatePortalMyStayBillsUrl(magicId)}`)}
          text={t("buttons__see_invoice")}
        />
      );
    }

    // check that there are actually files to download...
    if (
      reservation.folios.some((folio) =>
        reservation.files.find(
          (file) =>
            file.metaData.magicFileType === MagicFileType.INVOICE &&
            file.fileName === `${reservation.id}/${folio.id}.pdf`
        )
      )
    ) {
      return (
        <CallToActionButton
          onClick={() => navigate(`${generatePortalMyStayBillsUrl(magicId)}`)}
          text={t("buttons__download_invoice")}
        />
      );
    }
    return (
      <CallToActionButton
        onClick={() => navigate(`${generatePortalMyStayBillsUrl(magicId)}`)}
        text={t("buttons__see_invoice")}
      />
    );
  }

  if (isReservation(reservation) && reservation.idCheckStatus === IdCheckStatus.DECLINED) {
    return <CallToActionIdCheckStatus magicId={reservation.magicId} />;
  }

  return null;
};
