import React, { FC, useEffect, useMemo } from "react";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import {
  CmsSingleDocumentPerPropertyType,
  singleDocumentWithPropertyNamespace
} from "../../../state/cms/cms-single-document-per-property-type";
import { ArticleLayout } from "../components/article-layout";
import { Box } from "@mui/material";
import { Heading2 } from "@likemagic-tech/sv-magic-library";

import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";
import { makeStyles } from "tss-react/mui";
import { useCmsPropertySpecificData } from "../../../state/cms/use-cms-per-property-data";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { CopyToClipboard } from "../../../components/atoms/copy-to-clipboard/copy-to-clipboard";
import { responsiveVariables, useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { DesktopCard } from "../../../components";
import { BackButton } from "../../../components/atoms/back-button/back-button";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { useNavigate } from "react-router-dom";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import QRCode from "react-qr-code";
import { ImageLimitedHeight } from "../../../domain-common/image-style";

const useStyles = makeStyles()(({ breakpoints, spacing, shape }) => ({
  coverImage: {
    objectFit: "cover",
    display: "block",
    width: "100%",
    maxWidth: 900,
    height: 200,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "fixed",
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      position: "relative",
      borderRadius: shape.borderRadius * 2.5,
      maxWidth: `calc(100% - ${20 * 2}px)`,
      marginLeft: spacing(2.5),
      marginRight: spacing(2.5),
      height: "100%"
    }
  }
}));

export const WiFiCredentialsArticle: FC<React.PropsWithChildren<unknown>> = () => {
  const { reservation } = useReservationContext();
  const { setDisplayNavbar, setLeftButtons } = useNavbar();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { t } = useTranslateWrapper({
    namespace: [
      singleDocumentWithPropertyNamespace(
        CmsSingleDocumentPerPropertyType.propertySpecfic,
        reservation.propertyId
      )
    ]
  });
  const cmsData = useCmsPropertySpecificData(reservation.propertyId);
  const isMobile = useIsMobile();
  const wiFiLink = useMemo(() => {
    return `WIFI:T:WPA;S:${reservation.extras?.wiFiCredentials?.ssid};P:${reservation.extras?.wiFiCredentials?.key};;`;
  }, [reservation.extras?.wiFiCredentials]);

  useEffect(() => {
    if (isMobile) {
      setDisplayNavbar(false);
    } else {
      setDisplayNavbar(true);
    }
  }, [setDisplayNavbar, isMobile]);

  const content = useMemo(() => {
    return (
      <Box>
        <Box mb={2.5} textAlign="center">
          {t("articles__intro_wifi_credentials")}
        </Box>

        <Box textAlign="center">
          <QRCode value={wiFiLink} size={220} />
        </Box>

        <Box m={2} textAlign="center">
          <Heading2>{t("articles__ssid_wifi_credentials")}</Heading2>
          <CopyToClipboard
            textToCopy={reservation.extras?.wiFiCredentials?.ssid!}
            tooltipText={tCommon("labels__copy_successfully")}
          >
            {reservation.extras?.wiFiCredentials?.ssid}
          </CopyToClipboard>
          <Heading2>{t("articles__password_wifi_credentials")}</Heading2>
          <CopyToClipboard
            textToCopy={reservation.extras?.wiFiCredentials?.key!}
            tooltipText={tCommon("labels__copy_successfully")}
          >
            {reservation.extras?.wiFiCredentials?.key}
          </CopyToClipboard>
        </Box>
      </Box>
    );
  }, [reservation.extras?.wiFiCredentials, wiFiLink, tCommon, t]);

  const backButton = useMemo(() => {
    return (
      <BackButton
        key="back_button"
        onClick={() => navigate(generatePortalMyStayUrl(reservation.magicId))}
      />
    );
  }, [navigate, reservation.magicId]);

  useEffect(() => {
    setLeftButtons([backButton]);
  }, [setLeftButtons, backButton]);

  const coverImageElement = useMemo(() => {
    return (
      <ImageLimitedHeight
        src={cmsData?.data?.articles__cover_image_wifi_credentials?.url}
        alt={"cover"}
        className={classes.coverImage}
      />
    );
  }, [classes, cmsData?.data?.articles__cover_image_wifi_credentials]);

  return (
    <>
      <HelmetTitle suffix="Article details" />

      <DesktopCard>
        <ArticleLayout
          coverImage={coverImageElement}
          title={t("articles__title_wifi_credentials")}
          subtitle={t("articles__subtitle_wifi_credentials")}
          content={content}
        />
      </DesktopCard>
    </>
  );
};
