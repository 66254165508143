import { ConfirmationComponent, DesktopCard } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useNavigate, useParams } from "react-router-dom";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useAppDispatch } from "../../../state/store";
import { clearInitialMagicId } from "../../initial-magic-info/initial-magic-id.slice";
import { generateMagicLink } from "../../../util/routing";
import { GtmEvent, useGtm } from "../../gtm/use-gtm";
import { bookingMagicObjectIntoGTMBooking } from "../../gtm/gtm-domain";
import { isBooking } from "../../../util/flow";
import { Paragraph, useAuth } from "@likemagic-tech/sv-magic-library";
import { useFetchMagicObject } from "../../magic/use-fetch-magic-object";
import { isKioskMode } from "../../../util/kiosk-mode";
import { generateGuestFlowUrlFromCheckpoint } from "../../guest-flow/guest-flow-sequence";
import { GuestFlowCheckpoint } from "../../guest-flow/checkpoint/guest-flow-checkpoint";
import QRCode from "react-qr-code";
import { Box } from "@mui/material";
import { useSearchBookNavigation } from "../hooks/use-search-book-navigation";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { generateInitSearchBookingUrl } from "../search-booking-navigation";
import { usePms } from "../../../util/hooks/use-pms";

export const SearchConfirmationPage = () => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { newMagicId: newBookingMagicId } = useParams<{ newMagicId: string }>();
  const { pushToGtm } = useGtm();
  const { getIsAuthenticated } = useAuth();
  const { availableButtons } = useSearchBookNavigation();
  const { ibeOnly } = useFeatureFlags();

  const { magicObject } = useFetchMagicObject({
    magicId: newBookingMagicId,
    noOfRetries: 3,
    skipBannerError: true
  });
  const { isEndOfDayPms } = usePms();

  useEffect(() => {
    if (magicObject && isBooking(magicObject)) {
      pushToGtm({
        event: GtmEvent.PURCHASE,
        ecommerce: bookingMagicObjectIntoGTMBooking(
          magicObject,
          getIsAuthenticated(),
          isEndOfDayPms
        )
      });
    }
  }, [magicObject, pushToGtm, getIsAuthenticated, isEndOfDayPms]);

  const onSubmit = useCallback(async () => {
    if (ibeOnly) {
      dispatch(clearInitialMagicId());
      navigate(generateInitSearchBookingUrl());
    } else if (isKioskMode() && isBooking(magicObject) && magicObject.reservations?.[0]?.magicId) {
      dispatch(clearInitialMagicId());
      //Skip calculating last page go straight to personal data
      navigate(
        generateGuestFlowUrlFromCheckpoint(
          GuestFlowCheckpoint.PERSONAL_DATA,
          magicObject.reservations?.[0]
        )
      );
    } else if (newBookingMagicId) {
      dispatch(clearInitialMagicId());
      navigate(generateMagicLink(newBookingMagicId));
    } else {
      console.warn("Booking magic id is not provided.");
    }
  }, [newBookingMagicId, navigate, dispatch, magicObject, ibeOnly]);

  const subtitle = useMemo(() => {
    if (ibeOnly) {
      return tCommon("labels__booking_confirmation_ibe_only");
    }
    return isKioskMode()
      ? tCommon("labels__booking_confirmation_kiosk_mode")
      : tCommon("labels__booking_confirmation");
  }, [tCommon, ibeOnly]);

  return (
    <FlowTemplate icons={availableButtons}>
      <HelmetTitle suffix="Booking created" />
      <DesktopCard>
        <ConfirmationComponent
          subtitleInfo={subtitle}
          onConfirm={onSubmit}
          submitButtonLabel={
            ibeOnly ? tCommon("buttons__go_to_search") : tCommon("buttons__go_to_booking")
          }
        >
          {isKioskMode() && isBooking(magicObject) && (
            <Box textAlign="center" my={2}>
              <QRCode
                size={150}
                value={`${window.location.origin}${generateGuestFlowUrlFromCheckpoint(
                  GuestFlowCheckpoint.PERSONAL_DATA,
                  magicObject.reservations?.[0]
                )}`}
              />
              <Paragraph mt={2} color="text.primary" sx={{ textTransform: "uppercase" }}>
                {tCommon("labels__or")}
              </Paragraph>
            </Box>
          )}
        </ConfirmationComponent>
      </DesktopCard>
    </FlowTemplate>
  );
};
