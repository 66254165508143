import { DoorProvider, useDoorProviderConfig, useIsNative } from "@likemagic-tech/sv-magic-library";
import { useSelector } from "react-redux";
import {
  acceptMobileSDKInvitationCode,
  getMobileSDKInvitationCodeAction,
  resetMobileSDKInvitationCode,
  selectMobileSDKInvitationCode
} from "../portal/portal.slice";
import { useAppDispatch } from "../../state/store";
import { useEffect } from "react";
import { Status } from "../../api/dto/mobilesdkinvitationcode-response";
import SeosMobileSDK from "../../capacitor-plugins/seos-mobile-sdk-plugin";
import { Reservation } from "../../domain-common/reservation";

export const useAaDoorSetup = ({ reservation }: { reservation: Reservation }) => {
  const config = useDoorProviderConfig(reservation.propertyId);
  const isNative = useIsNative();

  //only needed for ASSA ABLOY
  const mobileSDKInvitationCode = useSelector(selectMobileSDKInvitationCode);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      (config?.doorProvider === DoorProvider.AA_VISIONLINE ||
        config?.doorProvider === DoorProvider.AA_VOSTIO) &&
      isNative
    ) {
      dispatch(getMobileSDKInvitationCodeAction({ magicId: reservation.magicId }));
    }
  }, [dispatch, config?.doorProvider, reservation.magicId, isNative]);

  useEffect(() => {
    if (
      mobileSDKInvitationCode &&
      !!mobileSDKInvitationCode?.invitationCode &&
      (!mobileSDKInvitationCode?.status ||
        Status.INVITATION_PENDING === mobileSDKInvitationCode?.status)
    ) {
      //call sdk
      SeosMobileSDK.setupEndpoint({
        invitationCode: mobileSDKInvitationCode?.invitationCode!
      }).then(() => {
        dispatch(
          acceptMobileSDKInvitationCode({
            magicId: reservation.magicId,
            magicToken: reservation.magicToken
          })
        );
      });
    }
  }, [mobileSDKInvitationCode, dispatch, reservation.magicId, reservation.magicToken]);

  useEffect(() => {
    return () => {
      dispatch(resetMobileSDKInvitationCode());
    };
  }, [dispatch]);
};
