import { FC } from "react";
import { PhoneInput, PhoneInputProps } from "@likemagic-tech/sv-magic-library";
import { useThemeOverrideConfig } from "../../../util/hooks/use-configuration";

export const WrappedPhoneInput: FC<PhoneInputProps> = ({ children, ...props }) => {
  const themeOverrideConfig = useThemeOverrideConfig();

  return (
    <PhoneInput {...props} variant={themeOverrideConfig?.input?.variant ?? "filled"}>
      {children}
    </PhoneInput>
  );
};
