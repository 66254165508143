import React, { FC, PropsWithChildren } from "react";
import { Box, ButtonBase, Grid, Paper } from "@mui/material";
import { DisplayCmsSvg } from "../../../components";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "tss-react/mui";
import { Heading4, Paragraph } from "@likemagic-tech/sv-magic-library";
import { getDisplayServiceDetails, useServicesCmsData } from "../../services/use-services-cms-data";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { Price } from "../../../domain-common/price";

interface BookedServiceButtonProps {
  isNextPageAvailable: boolean;
  onClick: () => void;
  service: {
    code: string;
    id: string;
  };
  amount: Price;
  propertyId: string;
  additionalInfo?: string;
}

const useStyles = makeStyles()((theme) => ({
  icon: {
    color: theme.palette.secondary.main,
    width: 30,
    height: 30
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2.5,
    border: "3px solid",
    borderColor: "transparent",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    transition: theme.transitions.create("border-color")
  },
  twoRowElilipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
  }
}));

export const BookedServiceButton: FC<PropsWithChildren<BookedServiceButtonProps>> = ({
  children,
  onClick,
  isNextPageAvailable,
  amount,
  propertyId,
  service,
  additionalInfo
}) => {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const { fallbackIcon, serviceIcons, serviceTitles } = useServicesCmsData(propertyId);
  const iconUrl = getDisplayServiceDetails({ service }, serviceIcons, fallbackIcon);

  return (
    <Box width="100%">
      <ButtonBase
        focusRipple
        onClick={onClick}
        disabled={!isNextPageAvailable}
        sx={{ my: 0.5, width: "100%" }}
      >
        <Paper elevation={0} classes={{ root: classes.paper }}>
          <Grid container alignItems="center" textAlign="left" flexWrap="nowrap">
            <Grid item pr={1}>
              {iconUrl &&
                React.cloneElement(<DisplayCmsSvg url={iconUrl} />, {
                  className: classes.icon
                })}
            </Grid>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item pr={0.5} xs={12} md="auto" style={{ maxWidth: isMobile ? "none" : 400 }}>
                <Heading4 align="left" className={classes.twoRowElilipsis}>
                  {getDisplayServiceDetails({ service }, serviceTitles, service.code ?? service.id)}
                  {additionalInfo && <span style={{ marginLeft: 4 }}>{additionalInfo}</span>}
                </Heading4>
              </Grid>

              <Grid item sx={{ flex: 1 }}>
                <Grid container direction={isMobile ? "column" : "row"} spacing={1}>
                  <Grid item xs={9} md={10}>
                    {children}
                  </Grid>

                  <Grid
                    xs={3}
                    md={2}
                    item
                    container
                    justifyContent={isMobile ? "flex-start" : "flex-end"}
                    alignSelf={isMobile ? "flex-end" : "center"}
                  >
                    <Paragraph textAlign="right" sx={{ wordBreak: "break-word" }}>
                      {formatPriceToString(amount)}
                    </Paragraph>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item pl={1}>
              {isNextPageAvailable ? <ArrowForwardIos /> : <Box width={24} height={24} />}
            </Grid>
          </Grid>
        </Paper>
      </ButtonBase>
    </Box>
  );
};
