import { Box, Link } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React from "react";
import { MapLocation } from "../../../domain-common/map-location";
import { makeStyles } from "tss-react/mui";

interface MapLocationLinkProps {
  location: MapLocation;
}

function formatLocationText(location: MapLocation) {
  return [
    location.addressLine1,
    location.addressLine2,
    `${location.zip} ${location.city}`,
    location.country
  ]
    .filter(Boolean)
    .join(", ");
}

const useStyles = makeStyles()(({ palette }) => ({
  container: {
    borderBottom: `2px solid ${palette.secondary.main}`,
    fontSize: 17,
    display: "flex",
    justifyContent: "center"
  }
}));

export const MapLocationLink: React.FC<React.PropsWithChildren<MapLocationLinkProps>> = ({
  location
}) => {
  const { classes } = useStyles();
  return (
    <Box p={1} classes={classes.container}>
      <LocationOnIcon fontSize="inherit" />
      <Link
        rel="noreferrer"
        target="_blank"
        href={location.href}
        sx={{ marginLeft: 8, color: "text.primary" }}
      >
        {formatLocationText(location)}
      </Link>
    </Box>
  );
};
