import React from "react";
import { Price } from "../../../domain-common/price";
import { Grid } from "@mui/material";
import { Heading3, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import { PromoCodeMagicDiscountType } from "../../../domain-common/search-unit-group";
import { useTheme } from "@mui/styles";
import { formatPriceToString } from "../price-preview/price-preview";

interface PriceWithAdditionalProps {
  pricePerNight: Price;
  suffixPerNight?: string;
  priceTotal: Price;
  suffixTotal?: string;
  prefixTotal?: string;
  prefixPerNight?: string;
  promoCodeMagicDiscountType?: PromoCodeMagicDiscountType;
  pricePerNightBeforeDiscount?: Price;
  priceTotalBeforeDiscount?: Price;
  numberOfNights: number;
  hiddenDiscount?: boolean;
}

export const PriceWithAdditional: React.FC<React.PropsWithChildren<PriceWithAdditionalProps>> = ({
  pricePerNight,
  priceTotal,
  suffixTotal,
  suffixPerNight,
  prefixTotal,
  prefixPerNight,
  promoCodeMagicDiscountType,
  pricePerNightBeforeDiscount,
  priceTotalBeforeDiscount,
  numberOfNights,
  hiddenDiscount
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <Grid container direction="column">
      {numberOfNights > 1 && (
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            {prefixPerNight && (
              <Grid item>
                <Paragraph>{prefixPerNight}</Paragraph>
              </Grid>
            )}
            {promoCodeMagicDiscountType && pricePerNightBeforeDiscount && !hiddenDiscount && (
              <Grid item>
                <Paragraph sx={{ textDecoration: "line-through", color: theme.palette.grey[500] }}>
                  {formatPriceToString(pricePerNightBeforeDiscount)}
                </Paragraph>
              </Grid>
            )}
            <Grid item>
              <Paragraph>
                {formatPriceToString(pricePerNight)}
                {suffixPerNight}
              </Paragraph>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent={isMobile ? "flex-start" : "flex-end"}
        >
          {prefixTotal && (
            <Grid item>
              <Paragraph>{prefixTotal}</Paragraph>
            </Grid>
          )}
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={1}
              justifyContent={isMobile ? "flex-start" : "flex-end"}
              alignItems="end"
            >
              {promoCodeMagicDiscountType && priceTotalBeforeDiscount && !hiddenDiscount && (
                <Grid item>
                  <Heading3 sx={{ textDecoration: "line-through", color: theme.palette.grey[500] }}>
                    {formatPriceToString(priceTotalBeforeDiscount)}
                  </Heading3>
                </Grid>
              )}
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Heading3
                    sx={{
                      color:
                        promoCodeMagicDiscountType && !hiddenDiscount
                          ? theme.palette.error.main
                          : ""
                    }}
                  >
                    {formatPriceToString(priceTotal)}
                  </Heading3>
                  <Paragraph>{numberOfNights > 1 ? `/${suffixTotal}` : suffixPerNight}</Paragraph>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
