import React, { FC, useCallback, useMemo } from "react";
import { Reservation } from "../../../domain-common/reservation";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { MagicApi } from "../../../api/magic.api";
import { saveAs } from "file-saver";
import { Folio, PrepaymentType } from "../../../domain-common/folio";
import { MagicFileType } from "../../../domain-common/magic-file-type";
import { Box, Divider, Grid } from "@mui/material";
import { Notification } from "../../atoms";
import { sortCharges } from "../../../util/reservation";
import { Button, Heading4, useApiVersion, useIsNative } from "@likemagic-tech/sv-magic-library";
import { ChargeItem } from "../../../features/guest-flow/components/charge-item";
import { TotalPrice } from "../../molecules/total-price";
import { formatPriceToString, sumFullPrices } from "../../atoms/price-preview/price-preview";
import { Field, FieldProps } from "formik";
import { BillFolio } from "../../../features/portal/pages/my-stay-bills.page";
import { Charge } from "../../../domain-common/payment";
import { FullPrice, toGross, toVat } from "../../../domain-common/full-price";
import { FileOpener } from "@capacitor-community/file-opener";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { WrappedNativeSelect } from "../../atoms/input/wrapped-native-select";
import { getFolioPreviewTitle } from "../../../util/migrate-to-v2/get-folio-preview-title";
import { useFeatureFlags, useHidePaymentConfig } from "../../../util/hooks/use-configuration";

function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

interface FolioPreviewProps {
  folio: BillFolio;
  reservation: Reservation;
  index: number;
  additionalCharges?: Charge[];
  readOnly?: boolean;
  overridePropertyId?: string;
}

export const FolioPreview: FC<FolioPreviewProps> = ({
  folio,
  reservation,
  index,
  additionalCharges,
  readOnly,
  overridePropertyId
}) => {
  const { t: tCommon, exists } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const hidePrepaymentConfig = useHidePaymentConfig(overridePropertyId);
  const isNative = useIsNative();
  const { showVat } = useFeatureFlags();

  const isGuestCheckedOut = () => {
    return reservation.status === ReservationStatus.CHECKED_OUT;
  };
  const additionalChargesTotal: FullPrice | undefined = useMemo(() => {
    return additionalCharges && additionalCharges.length
      ? sumFullPrices(additionalCharges.map((charge) => charge.total))
      : undefined;
  }, [additionalCharges]);

  const { isRESTVersion } = useApiVersion();

  const totalCharges: FullPrice = useMemo(() => {
    return additionalChargesTotal
      ? sumFullPrices([folio.totalCharges, additionalChargesTotal])
      : folio.totalCharges;
  }, [additionalChargesTotal, folio]);

  const downloadInvoice = useCallback(
    async (filename: string) => {
      const blob = await MagicApi.downloadFile({
        reservation: reservation,
        filename
      });

      const fileName = encodeURIComponent(filename);
      if (isNative) {
        blobToBase64(blob).then((base64: any) =>
          Filesystem.writeFile({
            path: fileName,
            data: base64,
            directory: Directory.External
          })
            .then((writeFileResult) =>
              FileOpener.open({
                filePath: writeFileResult.uri,
                contentType: "application/pdf",
                openWithDefault: true
              })
            )
            .catch((e) => console.error(`Failed to download invoice ${fileName} on native app `, e))
        );
      } else {
        const splits = filename.split("/");
        const file = new Blob([blob], {
          type: "application/octet-stream",
          endings: "native"
        });
        saveAs(file, splits[splits.length - 1]);
      }
    },
    [reservation, isNative]
  );

  const InvoiceDownloadButton = useCallback(
    (folio: Folio) => {
      const invoiceFile = reservation.files.find(
        (file) =>
          file.metaData.magicFileType === MagicFileType.INVOICE &&
          file.fileName === `${reservation.id}/${folio.id}.pdf`
      );
      if (
        invoiceFile &&
        reservation.status === ReservationStatus.CHECKED_OUT &&
        !(hidePrepaymentConfig && hidePrepaymentConfig[folio.prepaymentType])
      ) {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Box my={1.5}>
                <Button
                  variant="primary"
                  type="button"
                  fullWidth={true}
                  onClick={() => downloadInvoice(invoiceFile.fileName)}
                >
                  {tCommon("buttons__download_invoice")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        );
      }
    },
    [
      downloadInvoice,
      reservation.files,
      reservation.id,
      tCommon,
      reservation.status,
      hidePrepaymentConfig
    ]
  );
  const getChargesForFolio = useCallback(
    (folioId: string) =>
      additionalCharges
        ? reservation.groupedCharges
            .filter((value) => value.folioId === folioId)
            .sort(sortCharges)
            .concat(additionalCharges)
        : reservation.groupedCharges.filter((value) => value.folioId === folioId).sort(sortCharges),

    [reservation.groupedCharges, additionalCharges]
  );
  return folio.prepaymentType !== PrepaymentType.NOT_DETERMINABLE ? (
    <Box my={!readOnly ? 6 : 1} key={folio.magicId}>
      {getFolioPreviewTitle({
        isRESTVersion,
        tCommon,
        isMainFolio: folio.isMainFolio,
        number: folio.number ?? ""
      })}

      {folio.prepaymentType !== PrepaymentType.NONE &&
        exists("labels__folio_prepayment_" + folio.prepaymentType) && (
          <Box pt={2}>
            <Notification
              type="warning"
              title={tCommon("labels__folio_prepayment_" + folio.prepaymentType)}
            />
          </Box>
        )}

      {folio.hasPendingPaymentLink && exists("labels__folio_pending_payment_link") && (
        <Box pt={2}>
          <Notification type="warning" title={tCommon("labels__folio_pending_payment_link")} />
        </Box>
      )}

      <Box mt={1.5}>
        {getChargesForFolio(folio.id).map((charge) => (
          <ChargeItem
            showVat={showVat}
            key={`key-${folio.magicId}-${charge.name}`}
            charge={charge}
            hide={
              hidePrepaymentConfig &&
              hidePrepaymentConfig[folio.prepaymentType] &&
              charge.prepayable
            }
            rentLabels={{
              rent: tCommon("labels__box_shop_for_rent"),
              chargedIfDamaged: tCommon("labels__rent_charge_explanation", {
                price: formatPriceToString(toGross(charge.total))
              })
            }}
          />
        ))}
        <Box my={1.5}>
          <Divider />
        </Box>
        {showVat && (
          <>
            <TotalPrice
              price={toVat(totalCharges)}
              title={tCommon("labels__vat_amount")}
              titleGray={true}
              priceBold={false}
              priceGray={true}
            />
            <Box mb={2} />
          </>
        )}
      </Box>
      <TotalPrice
        price={toGross(totalCharges)}
        title={tCommon("labels__total_amount")}
        titleGray={true}
        priceBold={false}
        priceGray={true}
      />

      <Box my={1.5}>
        <Divider />
      </Box>
      <TotalPrice
        price={folio.totalPayments}
        title={tCommon("labels__total_paid")}
        titleGray={true}
        priceBold={true}
        priceGray={true}
      />
      {!readOnly && (
        <>
          <Box my={1.5}>
            <Divider />
          </Box>
          {InvoiceDownloadButton(folio)}

          {folio.formDebitors.length ? (
            <Box>
              <Field name={`folios[${index}].selectedDebitorKey`}>
                {({ form }: FieldProps) => (
                  <WrappedNativeSelect
                    id={folio.magicId}
                    name={`folios[${index}].selectedDebitorKey`}
                    value={folio.selectedDebitorKey}
                    onChange={form.handleChange}
                    items={folio.formDebitors.map((formDebitor) => {
                      return {
                        value: formDebitor.key,
                        label: formDebitor.label
                      };
                    })}
                    label=""
                    disableEmpty
                    disabled={isGuestCheckedOut() || !!folio.company}
                  />
                )}
              </Field>
            </Box>
          ) : null}
        </>
      )}
    </Box>
  ) : (
    <Heading4 my={3} textAlign="center">
      {tCommon("labels__folio_not_determined")}
    </Heading4>
  );
};
