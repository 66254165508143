export enum MagicServiceCodeEnum {
  FREE_CLEANING = "FREE_CLEANING",
  PAID_CLEANING = "PAID_CLEANING",
  FREE_BREAKFAST = "FREE_BREAKFAST",
  DONATE_BREAKFAST = "DONATE_BREAKFAST",
  EXTERNAL_BREAKFAST1 = "EXTERNAL_BREAKFAST1",
  EXTERNAL_BREAKFAST2 = "EXTERNAL_BREAKFAST2",
  EXTERNAL_BREAKFAST3 = "EXTERNAL_BREAKFAST3",
  EXTERNAL_BREAKFAST4 = "EXTERNAL_BREAKFAST4",
  BREAKFAST = "BREAKFAST",
  LATE_CHECKOUT = "LATE_CHECKOUT",
  EARLY_CHECKIN = "EARLY_CHECKIN",
  BABY_BED = "BABY_BED",
  STORAGE = "STORAGE",
  PARKING_PER_MONTH = "PARKING_PER_MONTH"
}
