import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type LogDoorAccessMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
  doorId: Types.Scalars["String"];
  doorAccesStatus: Types.DoorAccessStatus;
  doorLog?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type LogDoorAccessMutation = {
  __typename?: "Mutation";
  LogDoorAccess?:
    | { __typename: "Booking" }
    | { __typename: "Reservation" }
    | { __typename: "TravelBuddy" }
    | null;
};

export const LogDoorAccessDocument = `
    mutation LogDoorAccess($magicId: String!, $magicToken: String!, $doorId: String!, $doorAccesStatus: DoorAccessStatus!, $doorLog: String) {
  LogDoorAccess(
    magicToken: $magicToken
    magicId: $magicId
    doorId: $doorId
    doorAccessStatus: $doorAccesStatus
    doorLog: $doorLog
  ) {
    __typename
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    LogDoorAccess: build.mutation<LogDoorAccessMutation, LogDoorAccessMutationVariables>({
      query: (variables) => ({ document: LogDoorAccessDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useLogDoorAccessMutation } = injectedRtkApi;
