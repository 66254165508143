import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchMagicObject } from "../magic/magic-object.slice";
import { RootState } from "../../state/store";

type RestoreMagicContextSliceState = {
  lastMagicId: string | null;
  lastPropertyId: string | null;
  lastLanguage: string | null;
};

const initialState: RestoreMagicContextSliceState = {
  lastMagicId: null,
  lastPropertyId: null,
  lastLanguage: null
};

/**
 * Magic context about last magic id that was loaded
 */
export const restoreMagicContextSlice = createSlice({
  name: "restoreMagicContext",
  initialState,
  reducers: {
    setLastMagicId: (state, action: PayloadAction<string | null>) => {
      state.lastMagicId = action.payload;
    },
    setLastPropertyId: (state, action: PayloadAction<string | null>) => {
      state.lastPropertyId = action.payload;
    },
    setLastLanguage: (state, action: PayloadAction<string | null>) => {
      state.lastLanguage = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMagicObject.rejected, (state) => {
      state.lastMagicId = null;
    });
  }
});

export const { setLastMagicId, setLastPropertyId, setLastLanguage } =
  restoreMagicContextSlice.actions;

export const selectRestoreMagicContextSlice = (state: RootState) =>
  state[restoreMagicContextSlice.name];

export const selectLastMagicId = createSelector(
  selectRestoreMagicContextSlice,
  (res) => res.lastMagicId ?? undefined
);

export const selectLastPropertyId = createSelector(
  selectRestoreMagicContextSlice,
  (res) => res.lastPropertyId ?? undefined
);

export const selectLastLanguage = createSelector(
  selectRestoreMagicContextSlice,
  (res) => res.lastLanguage
);
