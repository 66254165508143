import { Box, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { FC, useCallback } from "react";
import { Paragraph, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { DisplayCmsSvg } from "../../atoms";
import { FeatureItem } from "./feature-item";
import { SizeEnum } from "../../../domain-common/size-enum";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { getOkModalArg } from "../../../features/global-modal/global-modal-util";

interface StudioFacilitiesItemProp {
  label: string;
  iconUrl: string;
  dialogTitle: string;
  dialogText: string;
  size?: {
    width: number;
    height: number;
  };
}

interface StudioFacilitiesProps {
  items: Array<StudioFacilitiesItemProp>;
  itemSize: SizeEnum;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    overflow: "auto",
    whiteSpace: "nowrap",
    "& svg *": {
      stroke: "inherit"
    }
  }
}));

const itemDisplayInfo: {
  [key: number]: {
    margin: number;
    size: "large" | "medium" | "small" | "inherit";
  };
} = {
  [SizeEnum.LARGE]: {
    size: "large",
    margin: 3
  },
  [SizeEnum.MEDIUM]: {
    size: "medium",
    margin: 1.5
  },
  [SizeEnum.SMALL]: {
    size: "small",
    margin: 1
  },
  [SizeEnum.CUSTOM]: {
    size: "inherit",
    margin: 2
  }
};

export const FeaturesList: FC<React.PropsWithChildren<StudioFacilitiesProps>> = ({
  items,
  itemSize
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { open: openModal } = useGlobalModal();
  const { classes } = useStyles();
  const { palette } = useTheme();
  const onClick = useCallback(
    (title: string, text: string) => () => {
      const content = text
        .split("\n")
        .map((item, index) => (
          <Paragraph key={`feature-${title}-paragraph-${index}`}>{item}</Paragraph>
        ));
      openModal(getOkModalArg(title, <>{content}</>, t("buttons__ok")));
    },
    [openModal, t]
  );
  return (
    <Box className={classes.wrapper}>
      {items &&
        items.map((item) => (
          <FeatureItem
            key={`${item.label}-${item.iconUrl}`}
            icon={
              <DisplayCmsSvg
                fontSize={itemDisplayInfo[itemSize].size}
                url={item.iconUrl}
                size={SizeEnum.CUSTOM === itemSize ? item.size : undefined}
                stroke={palette.secondary.main}
              />
            }
            title={item.label}
            onClick={onClick(item.dialogTitle, item.dialogText || "")}
            margin={itemDisplayInfo[itemSize].margin}
          />
        ))}
    </Box>
  );
};
