import React, { FC, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../state/store";
import { MyStayArticleDetails } from "../../../domain-common/my-stay-article-details";
import {
  fetchMyStayTipById,
  selectMyStayArticleDetailsById
} from "../../my-stay-tips/my-stay-tips.slice";
import { HelmetTitle } from "../../gtm/helmet-title";
import { DesktopCard } from "../../../components";
import { ContentDetails, ContentDetailsCover } from "../../portal/components/content-details";
import { MyStayTipContentType } from "../../../domain-common/my-stay-tip-content-type";
import { useCmsClient } from "../../../api/cms-client/use-cms-client";
import { BackButton } from "../../../components/atoms/back-button/back-button";
import { useNavigate } from "react-router";
import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { generateInfoStayUrl } from "../../../util/routing";
import { useGetSearchParamPropertyId } from "../use-get-search-param-propertyId";

export const InfoStayArticlePage: FC = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const dispatch = useAppDispatch();
  const articleDetails = useSelector<RootState, MyStayArticleDetails | null>((state) =>
    selectMyStayArticleDetailsById(state, articleId || "")
  );
  const { setLeftButtons, setDisplayNavbar } = useNavbar();
  const navigate = useNavigate();
  const prismic = useCmsClient();
  const propertyId = useGetSearchParamPropertyId();

  const backButton = useMemo(() => {
    const propertyIdWithFallback = propertyId ?? articleDetails?.propertyId;
    return (
      <BackButton
        key="back_button"
        onClick={() =>
          propertyIdWithFallback
            ? navigate(generateInfoStayUrl(propertyIdWithFallback))
            : navigate(-1)
        }
      />
    );
  }, [navigate, propertyId, articleDetails?.propertyId]);

  const isMobile = useIsMobile();

  useEffect(() => {
    setDisplayNavbar(!isMobile);
  }, [setDisplayNavbar, isMobile]);

  useEffect(() => {
    setLeftButtons([backButton]);
  }, [setLeftButtons, backButton]);

  useEffect(() => {
    const promise = dispatch(fetchMyStayTipById({ tipId: articleId, prismic: prismic }));
    return () => {
      promise.abort();
    };
  }, [dispatch, articleId, prismic]);

  if (!articleDetails) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Article details" />

      <DesktopCard>
        <ContentDetails
          title={articleDetails.title}
          subtitle={articleDetails.subtitle}
          coverImage={articleDetails.coverImage}
          description={articleDetails.description}
          type={
            articleDetails.contentType === MyStayTipContentType.ICON_CARD
              ? ContentDetailsCover.SVG
              : ContentDetailsCover.IMAGE
          }
        />
      </DesktopCard>
    </>
  );
};
