import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DisplayCmsSvg, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { AddressForm } from "../../../components/molecules/address-form/address-form";
import { AddressFormData } from "../../../components/molecules/address-form/address-from-data";
import { useAddressForm } from "../../../components/molecules/address-form/use-address-form";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";

import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import {
  Editability,
  useGuestDataEditable
} from "../../booking-overview/hooks/use-guest-data-editable";
import { EditableNotification } from "../../../components/atoms/notification/editable-notification";
import { ProfileTemplate } from "../components/profile-template";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { generatePortalProfileUrl } from "../../../util/routing";
import { generateFastGuestFlowLink } from "../../guest-flow/pages/fast-guest-flow.page";

interface ProfileAddressPageProps {}

export const ProfileAddressPage: React.FC<
  React.PropsWithChildren<ProfileAddressPageProps>
> = () => {
  const navigate = useNavigate();
  const { reservation } = useReservationContext();

  const { patchGuestFlowStep } = useGuestFlow();
  const { addressFormValidation } = useFormValidations();
  const location = useLocation();

  useSetupSubpageNavigation(
    location.state?.fastCheckin ? generateFastGuestFlowLink : generatePortalProfileUrl
  );

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const { initialValues, getReservationValuesToPatch, isMainFolioExists } =
    useAddressForm(reservation);
  const handleSubmit = React.useCallback(
    async (values: AddressFormData) => {
      const valuesToPatch = getReservationValuesToPatch(reservation, values);
      try {
        await patchGuestFlowStep({
          reservationValues: valuesToPatch,
          subUpdate: SubUpdate.ADDRESS
        });
        navigate(-1);
      } catch (e) {
        console.error("An error occurred, profile address", e);
      }
    },
    [patchGuestFlowStep, navigate, reservation, getReservationValuesToPatch]
  );

  const labels = useMemo(
    () => ({
      addressLine1: tCommon("labels__address"),
      postalCode: tCommon("labels__postal_code"),
      city: tCommon("labels__city"),
      countryCode: tCommon("labels__country"),
      sameAddress: tCommon("labels__billing_address_same_as_main_address"),
      firstName: tCommon("labels__first_name"),
      lastName: tCommon("labels__last_name"),
      companyName: tCommon("labels__company_name"),
      companyTaxId: tCommon("labels__company_tax_id"),
      email: tCommon("labels__email"),
      reference: tCommon("labels__reference")
    }),
    [tCommon]
  );

  const { editable } = useGuestDataEditable({
    uneditableStatus: [ReservationStatus.CHECKED_OUT],
    reservation
  });
  if (editable === undefined) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Address" />
      <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
        <ProfileTemplate>
          <PageHeadingInfo
            title={tCommon("title__address_page")}
            subtitle={tCommon("subtitle__address_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__address_icon?.url} />}
          />
          <EditableNotification editabilities={[editable]} />
          {initialValues && (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={addressFormValidation}
            >
              {(formik) => (
                <Form>
                  <AddressForm
                    values={formik.values}
                    handleChange={formik.handleChange}
                    errors={formik.errors}
                    touched={formik.touched}
                    isSubmitting={editable !== Editability.EDITABLE || formik.isSubmitting}
                    labels={labels}
                    isMainFolioExists={isMainFolioExists}
                  />
                  {formik.dirty && (
                    <SubmitButton
                      label={tCommon("buttons__save")}
                      disabled={formik.isSubmitting}
                      hasBottomNavigation
                      hasWhiteBackground
                    />
                  )}
                </Form>
              )}
            </Formik>
          )}
        </ProfileTemplate>
      </RoundedFullHeight>
    </>
  );
};
