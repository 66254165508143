import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BookingOnBehalfOfOthersIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7197 19.7197C11.0126 19.4268 11.4874 19.4268 11.7803 19.7197L14.25 22.1893L16.7197 19.7197C17.0126 19.4268 17.4874 19.4268 17.7803 19.7197C18.0732 20.0126 18.0732 20.4874 17.7803 20.7803L14.7803 23.7803C14.4874 24.0732 14.0126 24.0732 13.7197 23.7803L10.7197 20.7803C10.4268 20.4874 10.4268 20.0126 10.7197 19.7197Z"
        fill="#14263D"
      />
      <path
        d="M14.25 13.5C14.6642 13.5 15 13.8358 15 14.25V23.25C15 23.6642 14.6642 24 14.25 24C13.8358 24 13.5 23.6642 13.5 23.25V14.25C13.5 13.8358 13.8358 13.5 14.25 13.5Z"
        fill="#14263D"
      />
      <path
        d="M19.7197 13.7197C20.0126 13.4268 20.4874 13.4268 20.7803 13.7197L23.7803 16.7197C24.0732 17.0126 24.0732 17.4874 23.7803 17.7803C23.4874 18.0732 23.0126 18.0732 22.7197 17.7803L20.25 15.3107L17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803C16.4268 17.4874 16.4268 17.0126 16.7197 16.7197L19.7197 13.7197Z"
        fill="#14263D"
      />
      <path
        d="M20.25 13.5C20.6642 13.5 21 13.8358 21 14.25V23.25C21 23.6642 20.6642 24 20.25 24C19.8358 24 19.5 23.6642 19.5 23.25V14.25C19.5 13.8358 19.8358 13.5 20.25 13.5Z"
        fill="#14263D"
      />
      <path
        d="M6 1.5C4.75736 1.5 3.75 2.50736 3.75 3.75C3.75 4.99264 4.75736 6 6 6C7.24264 6 8.25 4.99264 8.25 3.75C8.25 2.50736 7.24264 1.5 6 1.5ZM2.25 3.75C2.25 1.67893 3.92893 0 6 0C8.07107 0 9.75 1.67893 9.75 3.75C9.75 5.82107 8.07107 7.5 6 7.5C3.92893 7.5 2.25 5.82107 2.25 3.75Z"
        fill="#14263D"
      />
      <path
        d="M1.75736 9.25736C2.88258 8.13214 4.4087 7.5 6 7.5C7.5913 7.5 9.11742 8.13214 10.2426 9.25736C11.3679 10.3826 12 11.9087 12 13.5V15.75C12 16.1642 11.6642 16.5 11.25 16.5H9.67874L8.99628 23.3246C8.95794 23.708 8.63531 24 8.25 24H3.75C3.36469 24 3.04206 23.708 3.00372 23.3246L2.32126 16.5H0.75C0.335786 16.5 0 16.1642 0 15.75V13.5C0 11.9087 0.632141 10.3826 1.75736 9.25736ZM6 9C4.80653 9 3.66193 9.47411 2.81802 10.318C1.97411 11.1619 1.5 12.3065 1.5 13.5V15H3C3.38531 15 3.70794 15.292 3.74628 15.6754L4.42874 22.5H7.57126L8.25372 15.6754C8.29206 15.292 8.61469 15 9 15H10.5V13.5C10.5 12.3065 10.0259 11.1619 9.18198 10.318C8.33807 9.47411 7.19347 9 6 9Z"
        fill="#14263D"
      />
    </svg>
  </SvgIcon>
);
