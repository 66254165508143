import { Booking } from "../domain-common/booking";
import { Reservation } from "../domain-common/reservation";
import { MagicType } from "../domain-common/magic-object";

export function isBookerOverview(arg: any): arg is Booking {
  return arg && arg.magicType === MagicType.BOOKER_OVERVIEW;
}

export function isBooking(arg: any): arg is Booking {
  return arg && arg.magicType === MagicType.BOOKING;
}

export function isReservation(arg: any): arg is Reservation {
  return arg && arg.magicType === MagicType.RESERVATION;
}

export function isTravelBuddy(arg: any): arg is Reservation {
  return arg && arg.magicType === MagicType.TRAVEL_BUDDY;
}

export function isMinorTravelBuddy(arg: any): arg is Reservation {
  return arg && arg.magicType === MagicType.TRAVEL_BUDDY && arg.travelBuddy?.minor;
}
