import React, { FC } from "react";
import { Reservation } from "../../domain-common/reservation";
import { Route, Routes } from "react-router-dom";
import { MyStayPage } from "./pages/my-stay.page";
import { MyStayPagesEnum, MyStayPageToPath } from "./my-stay-page-to-path";
import { isTravelBuddy } from "../../util/flow";
import { NotFoundPage } from "../../components/organism/not-found.page";
import { OpenBalancePage } from "./pages/open-balance.page";
import { MyStayBillsPage } from "./pages/my-stay-bills.page";
import { MyStayKeysPage } from "./pages/my-stay-keys.page";
import { MyStayBuddyPage } from "./pages/my-stay-buddy.page";
import { ServiceRouterPage } from "../additional-services/service-router.page";
import { ExtendStayBillPage } from "./pages/extend-stay-bill.page";
import { ShopPage } from "../shop/shop.page";
import { BoxShopPage } from "../box-shop/pages/box-shop.page";
import { BoxRentPage } from "../box-shop/pages/box-rent.page";
import { BoxItemRentPage } from "../box-shop/pages/box-item-rent.page";
import { BoxShopPaymentSuccessPage } from "../box-shop/pages/box-shop-payment-success.page";
import { CartPage } from "../shop/cart.page";
import { PublicTransportationTicketArticle } from "./pages/public-transportation-ticket-article";

import { ArticleDetailsPage } from "./pages/article-details.page";
import { MyStayBuddyEditPage } from "./pages/my-stay-buddy-edit.page";
import { ProtectedRoute } from "../../util/protected-route";
import { WiFiCredentialsArticle } from "./pages/wifi-credentials-article";
import { RoomControlPage } from "../room-control/room-control.page";
import { MyStayBuddyAddPage } from "./pages/my-stay-buddy-add.page";
import { SelfPouringStationPage } from "../self-pouring-station-shop/pages/self-pouring-station.page";
import { SelfPouringStationShopLastStepPage } from "../self-pouring-station-shop/pages/self-pouring-station-shop-last-step.page";
import { DynamicPageRouter } from "../dynamic-shop/pages/dynamic-page-router";

export const MyStayRoutes: FC<React.PropsWithChildren<{ reservation: Reservation }>> = ({
  reservation
}) => (
  <Routes>
    <Route path="/" element={<MyStayPage />} />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.OPEN_BALANCE]}`}
      element={
        <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
          <OpenBalancePage />
        </ProtectedRoute>
      }
    />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.BILLS]}`}
      element={
        <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
          <MyStayBillsPage />
        </ProtectedRoute>
      }
    />
    <Route path={`${MyStayPageToPath[MyStayPagesEnum.KEYS]}`} element={<MyStayKeysPage />} />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.BUDDIES]}`}
      element={
        <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
          <MyStayBuddyPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.SERVICES]}/*`}
      element={
        <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
          <ServiceRouterPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.EXTEND_STAY_BILL]}`}
      element={
        <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
          <ExtendStayBillPage />
        </ProtectedRoute>
      }
    />
    <Route path={`${MyStayPageToPath[MyStayPagesEnum.SHOP]}`} element={<ShopPage />} />

    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.DYNAMIC_SHOP]}/*`}
      element={<DynamicPageRouter />}
    />

    <Route path={`${MyStayPageToPath[MyStayPagesEnum.BOX_SHOP]}`} element={<BoxShopPage />} />
    <Route path={`${MyStayPageToPath[MyStayPagesEnum.BOX_RENT]}`} element={<BoxRentPage />} />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.SELF_POURING_STATION_SHOP]}`}
      element={<SelfPouringStationPage />}
    />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.ROOM_CONTROL]}`}
      element={<RoomControlPage />}
    />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.BOX_RENT_ITEM]}`}
      element={<BoxItemRentPage />}
    />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.BOX_SHOP_PAYMENT_SUCCESS]}`}
      element={<BoxShopPaymentSuccessPage />}
    />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.SELF_POURING_STATION_SHOP_PAYMENT_SUCCESS]}`}
      element={<SelfPouringStationShopLastStepPage />}
    />
    <Route path={`${MyStayPageToPath[MyStayPagesEnum.CART]}`} element={<CartPage />} />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.ARTICLES]}/public-transport-tickets`}
      element={<PublicTransportationTicketArticle />}
    />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.ARTICLES]}/wifi-credentials`}
      element={<WiFiCredentialsArticle />}
    />
    <Route
      path={`${MyStayPageToPath[MyStayPagesEnum.ARTICLES]}/:articleId`}
      element={<ArticleDetailsPage />}
    />
    <Route
      path="/buddy/:buddyIndex/edit"
      element={
        <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
          <MyStayBuddyEditPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/buddy/add"
      element={
        <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
          <MyStayBuddyAddPage />
        </ProtectedRoute>
      }
    />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
