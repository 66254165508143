import i18n from "i18next";

//This will be used just in case that we do not have a list of allowedLanguages configured
export const defaultLanguageWithLocale = "en-us";

//Always use this method instead getting i18n.language directly.
export const getI18nSelectedLanguage = () =>
  i18n.language?.toLowerCase() ?? defaultLanguageWithLocale; //If i18n is not ready use default

export const getLanguageWithDefault = (selectedLang?: string, allowedLocales?: string[]) => {
  return (
    allowedLocales?.find((lang) => lang === selectedLang) ||
    allowedLocales?.find((lang) =>
      lang.startsWith(selectedLang ? selectedLang.substring(0, 2) : "")
    ) ||
    allowedLocales?.[0] ||
    defaultLanguageWithLocale
  );
};
