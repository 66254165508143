import { useCmsPropertySpecificData } from "../cms/use-cms-per-property-data";
import { useListCmsData } from "./use-list-cms-data";

export const useDoorCmsData = (propertyId?: string) => {
  const cmsData = useCmsPropertySpecificData(propertyId);
  const doorHelpModalContent = cmsData?.data?.door_help_modal_content;
  const doorHelpModalTitle = cmsData?.data?.door_help_modal_title;
  return {
    ...useListCmsData("doors_names", cmsData),
    doorHelpModalContent,
    doorHelpModalTitle
  };
};
