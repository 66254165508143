import React, { FC, MutableRefObject, useCallback, useState } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import {
  PageHeadingInfo,
  PageHeadingInfoProps
} from "../../../components/molecules/page-heading-info";
import { Box, Grid, useTheme } from "@mui/material";
import { KeySlider, Paragraph, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { openBanner } from "../../banner/banner.slice";
import { useAppDispatch } from "../../../state/store";
import { BoxAvailability } from "../../../domain-v1/box-availability";
interface BoxStatusIdleProps extends PageHeadingInfoProps {
  magicId: string;
  boxDetails: BoxAvailability;
  openSSE: (onSSEInit: () => void, handleError: () => void) => Promise<void>;
  refKeySlider: MutableRefObject<any>;
  productIcon?: string;
}
export const BoxStatusIdle: FC<BoxStatusIdleProps> = ({
  openSSE,
  title,
  icon,
  productIcon,
  refKeySlider,
  boxDetails
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const dispatch = useAppDispatch();
  const [sseIsInitialised, setSseIsInitialised] = useState(false);

  const onSSEInit = useCallback(() => {
    setSseIsInitialised(true);
  }, []);

  const { palette } = useTheme();

  const handleError = useCallback(() => {
    dispatch(
      openBanner({
        type: "error",
        title: t("errors__default_label")
      })
    );
  }, [t, dispatch]);

  const wrapOpenSSE = useCallback(async () => {
    await openSSE(onSSEInit, handleError);
    setSseIsInitialised(false);
  }, [onSSEInit, handleError, openSSE]);

  return (
    <>
      <PageHeadingInfo title={title} icon={icon} />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Box
            minHeight={75}
            minWidth={75}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: palette.primary.main
            }}
          >
            <ParagraphBold sx={{ color: palette.background.paper, p: 1 }}>
              {/*locationType should be dynamic*/}
              {boxDetails?.boxName}
            </ParagraphBold>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="column" paddingTop={4}>
        <Grid item>
          <KeySlider
            ref={refKeySlider}
            unlocked={sseIsInitialised}
            onSuccess={wrapOpenSSE}
            labels={{
              slideToUnlock: t("labels__keys_slide_to_unlock"),
              unlocked: t("labels__keys_unlocked"),
              error: t("validations__keys_error")
            }}
          />
        </Grid>
        <Grid item paddingTop={1}>
          <Paragraph textAlign="center">{t("labels__box_shop_slider_hint")}</Paragraph>
        </Grid>
      </Grid>
    </>
  );
};
