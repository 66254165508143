import { FastField, FastFieldProps } from "formik";
import React from "react";
import { InputProps } from "@likemagic-tech/sv-magic-library";
import { WrappedInput } from "../atoms/input/wrapped-input";

type FastInputProps = Omit<InputProps, "onChange"> & { name: string };

export const FastInput: React.FC<React.PropsWithChildren<FastInputProps>> = (props) => {
  return (
    <FastField name={props.name}>
      {({ form }: FastFieldProps) => <WrappedInput {...props} onChange={form.handleChange} />}
    </FastField>
  );
};
