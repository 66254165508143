import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
  Selector
} from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { BookingCartTotalPrice } from "../../domain-common/booking-cart-total-price";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { handleSliceError } from "../../util/error-handling";
import { BookingCreationApi } from "../../api/booking-creation.api";
import {
  CreateBookingPriceRequest,
  ReservationOfferDataWithUUID
} from "../../domain-common/create-booking-payment";
import { getTenantHeaders } from "../../api/custom-headers";

export const bookingCartAdapter = createEntityAdapter<ReservationOfferDataWithUUID>({
  selectId: (reservationOfferData: ReservationOfferDataWithUUID) => reservationOfferData.uuid
});

interface BookingCartSliceState {
  cartTotalPrice?: BookingCartTotalPrice;
  cartTotalPriceStatus: EntityStateStatus;
  booker: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export const initialState = bookingCartAdapter.getInitialState<BookingCartSliceState>({
  cartTotalPrice: undefined,
  cartTotalPriceStatus: EntityStateStatus.IDLE,
  booker: {
    firstName: "",
    lastName: "",
    email: ""
  }
});

export const getBookingCartTotalPrice = createAsyncThunk<
  BookingCartTotalPrice,
  CreateBookingPriceRequest,
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("bookingCart/totalPrice", async (arg, thunkAPI) => {
  try {
    return await BookingCreationApi.fetchTotalPrice(arg, {
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const bookingCartSlice = createSlice({
  name: "bookingCart",
  initialState,
  reducers: {
    addToBookingCart: (state, action: PayloadAction<ReservationOfferDataWithUUID>) => {
      bookingCartAdapter.upsertOne(state, {
        ...action.payload
      });
    },
    removeFromBookingCart: (state, action: PayloadAction<{ uuid: string }>) => {
      bookingCartAdapter.removeOne(state, action.payload.uuid);
    },
    setBooker: (
      state,
      action: PayloadAction<{ firstName: string; lastName: string; email: string }>
    ) => {
      state.booker.firstName = action.payload.firstName;
      state.booker.lastName = action.payload.lastName;
      state.booker.email = action.payload.email;
    },
    clearBookingCart: (state) => {
      state.cartTotalPriceStatus = initialState.cartTotalPriceStatus;
      state.cartTotalPrice = initialState.cartTotalPrice;
      state.entities = initialState.entities;
      state.ids = initialState.ids;
      state.booker = initialState.booker;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingCartTotalPrice.pending, (state) => {
        state.cartTotalPriceStatus = EntityStateStatus.LOADING;
      })
      .addCase(getBookingCartTotalPrice.fulfilled, (state, action) => {
        state.cartTotalPrice = action.payload;
        state.cartTotalPriceStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(getBookingCartTotalPrice.rejected, (state) => {
        state.cartTotalPriceStatus = EntityStateStatus.FAILED;
      });
  }
});

export const { setBooker, addToBookingCart, clearBookingCart, removeFromBookingCart } =
  bookingCartSlice.actions;

export const { selectAll: selectAllFromBookingCart, selectTotal: selectBookingCartLength } =
  bookingCartAdapter.getSelectors<RootState>((state) => state[bookingCartSlice.name]);

const selectSelf: Selector<RootState, BookingCartSliceState> = (state: RootState) =>
  state[bookingCartSlice.name];

export const selectIsCartTotalPriceLoading = createSelector(
  selectSelf,
  (res) => res.cartTotalPriceStatus === EntityStateStatus.LOADING
);

export const selectCartTotalPrice = createSelector(selectSelf, (res) => res.cartTotalPrice);
export const selectBooker = createSelector(selectSelf, (res) => res.booker);

export const selectCartReservations = createSelector(selectAllFromBookingCart, (res) =>
  res.map((item) => ({
    arrival: item.arrival,
    departure: item.departure,
    adults: item.adults,
    childrenAges: item.childrenAges,
    promoCodePMS: item.promoCodePMS,
    ratePlanId: item.ratePlanId,
    propertyId: item.propertyId,
    unitGroupId: item.unitGroupId
  }))
);
