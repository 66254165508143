import React, { FC, useCallback, useEffect, useMemo } from "react";
import { Box, Divider, Grid } from "@mui/material";
import {
  ButtonProps,
  formatIsoStringToIsoDate,
  Heading2,
  Subtitle,
  useGlobalModal
} from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/store";
import { SearchOfferParamsEnum, useSearchParams } from "../hooks/use-search-offer-params";
import { SearchBookingFilters } from "../components/filters/search-booking-filters";
import { UnitGroupCard } from "../components/unit-group-card";
import { generateSearchBookingPagesUrl } from "../search-booking-navigation";
import { useNavigate } from "react-router-dom";
import { DesktopCard } from "../../../components";
import { SearchBookingPages } from "../search-booking-pages";
import { useSearchBookNavigation } from "../hooks/use-search-book-navigation";
import {
  addToBookingCart,
  clearBookingCart,
  selectAllFromBookingCart
} from "../booking-cart.slice";
import { SearchBookingSpecialRateModal } from "../components/filters/search-booking-special-rate-modal";
import { NoOffersAvailable } from "../components/no-offers-available";
import { makeStyles } from "tss-react/mui";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { useStylesForSearchFiltersWrapper } from "../components/use-dynamic-style-for-search-filter";
import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";
import { useLocation } from "react-router";
import {
  useConfigBasedOnlyOnBaseUrl,
  useFeatureFlags
} from "../../../util/hooks/use-configuration";
import { useSearchAvailableUnitGroup } from "../hooks/use-search-available-unit-group";
import { GtmEvent, useGtm } from "../../gtm/use-gtm";
import { Price } from "../../../domain-common/price";
import { differenceInCalendarDays } from "date-fns";
import { generateSmallUUID } from "../../../util/data";
import { openBanner } from "../../banner/banner.slice";
import { isKioskMode } from "../../../util/kiosk-mode";
import { NotificationBannerWrapper } from "../../../components/atoms/notification/notification-banner-wrapper";
import {
  clearSearchAvailableUnitGroups,
  selectIsSearchAvailableUnitGroupsLoading
} from "../search-available-unit-groups.slice";
import { SearchPropertyDetail } from "../components/search-property.detail";
import { BackButton } from "../../../components/atoms/back-button/back-button";
import { ConfirmationIcon } from "../../../components/atoms/confirmation-icon/confirmation-icon";
import { useProperties } from "../../property/use-property-by-id";
import { PromoCodeType, resetPromoCode } from "../promocode.slice";
import { selectSearchBookingFilters } from "../search-available-properties.slice";
import { useValidPromoCodeResponse } from "../hooks/use-valid-promocode-response";

const useStyles = makeStyles()(({ spacing }) => ({
  paddingForMiddleItems: {
    paddingBottom: spacing(2.5),
    "&:last-child": {
      paddingBottom: 0
    }
  }
}));

export const SearchUnitGroupsPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const isMobile = useIsMobile();
  const { classes: paddingForMiddleItemsClasses } = useStyles();
  const { classes } = useStylesForSearchFiltersWrapper();
  const { availableButtons } = useSearchBookNavigation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { searchOfferParamsDTO, setSearchOfferParamsDTO, allRequiredFiltersAreAvailable } =
    useSearchParams();
  const { specialRateModalOpen } = useSelector(selectSearchBookingFilters);

  const searchValues = useMemo(() => {
    return {
      arrival: searchOfferParamsDTO.arrival,
      departure: searchOfferParamsDTO.departure,
      adults: searchOfferParamsDTO.adults,
      childrenAges: searchOfferParamsDTO.childrenAges,
      ...(searchOfferParamsDTO.promoCodePMS && { promoCodePMS: searchOfferParamsDTO.promoCodePMS }),
      ...(searchOfferParamsDTO.promoCodeMagic && {
        promoCodeMagic: searchOfferParamsDTO.promoCodeMagic
      }),
      propertyIds: searchOfferParamsDTO.propertyIds
    };
  }, [searchOfferParamsDTO]);

  const { availableUnitGroups } = useSearchAvailableUnitGroup({
    searchValues
  });

  const { open: openModal } = useGlobalModal();
  const { setRightButtons, setDisplayNavbar, setLeftButtons } = useNavbar();
  const { search } = useLocation();
  const isSearchAvailableUnitGroupsLoading = useSelector(selectIsSearchAvailableUnitGroupsLoading);
  const { data: properties } = useProperties();
  const propertiesLength = Object.values(properties).length;

  const singleUnitGroupId = useMemo(
    () => new URLSearchParams(search).get(SearchOfferParamsEnum.UNIT_GROUP_ID),
    [search]
  );

  const selectedSingleUnitGroup = useMemo(
    () => availableUnitGroups.find((item) => item.unitGroupId === singleUnitGroupId),
    [availableUnitGroups, singleUnitGroupId]
  );
  const backButton = useMemo(
    () => (
      <Box height={48} key="back_button">
        <BackButton
          sx={isMobile ? {} : { position: "absolute", mt: 14 }}
          onClick={() => navigate(-1)}
        />
      </Box>
    ),
    [isMobile, navigate]
  );

  useEffect(() => {
    setRightButtons(availableButtons);
    if (propertiesLength > 1) {
      setLeftButtons([backButton]);
    }
  }, [availableButtons, backButton, setRightButtons, setLeftButtons, propertiesLength]);

  useEffect(() => {
    setDisplayNavbar(true);
  }, [setDisplayNavbar]);

  useEffect(() => {
    if (isKioskMode()) {
      dispatch(
        openBanner({
          type: "warning",
          title: t("labels__search_unit_groups_kiosk_warning")
        })
      );
    }
  }, [dispatch, t]);

  const resetPromoCodeAction = useCallback(() => {
    dispatch(resetPromoCode());
    setSearchOfferParamsDTO({
      ...searchOfferParamsDTO,
      promoCodePMS: undefined,
      promoCodeMagic: undefined
    });
  }, [dispatch, searchOfferParamsDTO, setSearchOfferParamsDTO]);

  const modalHandler = useCallback(
    async (
      subtitleLabel: string,
      goToCartButton?: boolean,
      confirmIconAnimation?: boolean,
      secondaryButtonLabel?: string
    ) => {
      const okButtonLabel = secondaryButtonLabel || "buttons__ok";
      let modalActions: {
        label: string;
        variant: ButtonProps["variant"];
        result: boolean;
      }[] = [];
      if (goToCartButton) {
        modalActions = [
          {
            label: t("buttons__go_to_cart"),
            variant: "primary",
            result: true
          },
          {
            label: t(okButtonLabel),
            variant: "secondary",
            result: false
          }
        ];
      } else {
        modalActions = [
          {
            label: t(okButtonLabel),
            variant: "primary",
            result: false
          }
        ];
      }

      const modalResult: boolean | null = await openModal({
        modalProps: {
          title: "",
          content: (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              {confirmIconAnimation && <ConfirmationIcon />}
              <Subtitle>{t(subtitleLabel)}</Subtitle>
            </Grid>
          )
        },
        modalActions
      });

      if (modalResult) {
        navigate(generateSearchBookingPagesUrl(SearchBookingPages.CART_BOOKINGS));
      }
    },
    [navigate, openModal, t]
  );

  const cartItems = useSelector(selectAllFromBookingCart);
  const { multiPropertyBookingEnabled, reverseDisplaySearchUnitGroupPageEnabled } =
    useFeatureFlags();
  const { search: searchConfig } = useConfigBasedOnlyOnBaseUrl();
  const { pushToGtm } = useGtm();
  const addToCartAdnOpenModal = useCallback(
    async (data: {
      ratePlanId: string;
      unitGroupId: string;
      propertyId: string;
      priceTotal: Price;
    }) => {
      const alreadyBookedUnits = cartItems.filter(
        (item) =>
          item.unitGroupId === data.unitGroupId &&
          formatIsoStringToIsoDate(item.arrival) ===
            formatIsoStringToIsoDate(searchOfferParamsDTO.arrival) &&
          formatIsoStringToIsoDate(item.departure) ===
            formatIsoStringToIsoDate(searchOfferParamsDTO.departure)
      ).length;
      const selectedOffer = availableUnitGroups.find(
        (availableUnitGroup) =>
          availableUnitGroup.unitGroupId === data.unitGroupId &&
          availableUnitGroup.offers.some((offer) => offer.ratePlanId === data.ratePlanId)
      );
      const availableUnits = selectedOffer?.availableUnits ?? 0;

      if (isKioskMode()) {
        const cancellationFee = availableUnitGroups
          .flatMap((availableUnitGroup) => availableUnitGroup.offers)
          .find((offer) => offer.ratePlanId === data.ratePlanId)?.cancellationFee;

        if (allRequiredFiltersAreAvailable && data && cancellationFee && selectedOffer) {
          await dispatch(clearBookingCart());
          const cartUUID = generateSmallUUID();
          const cartData = {
            uuid: cartUUID,
            adults: searchOfferParamsDTO.adults || 1,
            childrenAges: searchOfferParamsDTO.childrenAges || [],
            ratePlanId: data.ratePlanId,
            arrival: selectedOffer.arrival,
            departure: selectedOffer.departure,
            propertyId: searchOfferParamsDTO.propertyIds,
            unitGroupId: data.unitGroupId,
            promoCodePMS: searchOfferParamsDTO.promoCodePMS,
            cancellationFee,
            priceTotal: data.priceTotal
          };
          // @ts-ignore TS is not aware that allFiltersAreAvailable flag is checking that all filters are properly set
          dispatch(addToBookingCart(cartData));

          navigate(generateSearchBookingPagesUrl(SearchBookingPages.CART_BOOKINGS));
        }
      } else if (
        searchConfig?.maxReservationsPerBooking &&
        cartItems.length >= searchConfig.maxReservationsPerBooking
      ) {
        modalHandler("labels__maximum_reservations_per_booking_reached_modal_title", true);
      } else if (alreadyBookedUnits >= availableUnits) {
        modalHandler(
          "labels__no_more_availability_for_this_arrival_departure_unitgroup_modal_title"
        );
      } else if (
        !multiPropertyBookingEnabled &&
        cartItems.length > 0 &&
        cartItems.filter((item) => item.propertyId === searchOfferParamsDTO.propertyIds).length ===
          0
      ) {
        modalHandler("labels__info_multiprop_not_supported");
      } else {
        const cancellationFee = availableUnitGroups
          .flatMap((availableUnitGroup) => availableUnitGroup.offers)
          .find((offer) => offer.ratePlanId === data.ratePlanId)?.cancellationFee;

        if (allRequiredFiltersAreAvailable && data && cancellationFee && selectedOffer) {
          const cartUUID = generateSmallUUID();
          const cartData = {
            uuid: cartUUID,
            adults: searchOfferParamsDTO.adults || 1,
            childrenAges: searchOfferParamsDTO.childrenAges || [],
            ratePlanId: data.ratePlanId,
            arrival: selectedOffer.arrival,
            departure: selectedOffer.departure,
            propertyId: searchOfferParamsDTO.propertyIds,
            unitGroupId: data.unitGroupId,
            promoCodePMS: searchOfferParamsDTO.promoCodePMS,
            cancellationFee,
            priceTotal: data.priceTotal
          };
          // @ts-ignore TS is not aware that allFiltersAreAvailable flag is checking that all filters are properly set
          dispatch(addToBookingCart(cartData));

          pushToGtm({
            event: GtmEvent.ADD_TO_CART,
            ecommerce: {
              items: [
                {
                  item_id: data.ratePlanId,
                  item_category: data.unitGroupId,
                  location_id: data.propertyId,
                  price: String(data.priceTotal.amount / 100),
                  quantity: 1,
                  cartUUID: cartUUID,
                  arrival: searchOfferParamsDTO.arrival,
                  departure: searchOfferParamsDTO.departure,
                  nights: differenceInCalendarDays(
                    new Date(searchOfferParamsDTO.departure),
                    new Date(searchOfferParamsDTO.arrival)
                  ),
                  adults: searchOfferParamsDTO.adults || 1,
                  children: searchOfferParamsDTO.childrenAges || null,
                  promoCodePMS: searchOfferParamsDTO.promoCodePMS || null
                }
              ],
              currency: data.priceTotal.currency,
              value: String(data.priceTotal.amount / 100)
            }
          });

          modalHandler("labels__added_to_cart", true, true, "buttons__add_another_studio");
        } else {
          console.warn("Can't add it to the cart, some data is missing. ", data);
        }
      }
    },
    [
      searchOfferParamsDTO,
      allRequiredFiltersAreAvailable,
      dispatch,
      availableUnitGroups,
      modalHandler,
      cartItems,
      multiPropertyBookingEnabled,
      pushToGtm,
      searchConfig?.maxReservationsPerBooking,
      navigate
    ]
  );

  useEffect(() => {
    return () => {
      dispatch(clearSearchAvailableUnitGroups());
    };
  }, [dispatch]);

  const onChangeRateModal = useCallback(
    (promoCode?: string, promoCodeType?: PromoCodeType) => {
      if (promoCode) {
        setSearchOfferParamsDTO({
          ...searchOfferParamsDTO,
          ...(promoCodeType === PromoCodeType.PMS
            ? { promoCodePMS: promoCode, promoCodeMagic: undefined }
            : { promoCodeMagic: promoCode, promoCodePMS: undefined })
        });
      } else {
        return;
      }
    },
    [setSearchOfferParamsDTO, searchOfferParamsDTO]
  );

  useValidPromoCodeResponse({
    promoCode: searchValues.promoCodeMagic ?? searchValues.promoCodePMS
  });

  return (
    <>
      <HelmetTitle suffix="Available unit groups" />

      <Box minHeight={126}>
        <Grid container p={2.5} direction="row" className={classes.searchFiltersWrapper}>
          <Grid item xs={12} md={2}>
            <Grid container alignItems="center" direction="column" spacing={0} mb={2.5}>
              <Grid item>
                <Box>
                  <Heading2 align="center" gutterBottom>
                    {t("title__search_unit_groups")}
                  </Heading2>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10}>
            {allRequiredFiltersAreAvailable && (
              <SearchBookingFilters
                // @ts-ignore TS is not aware that allFiltersAreAvailable flag is checking that all filters are properly set
                values={searchOfferParamsDTO}
                onChange={setSearchOfferParamsDTO}
                disablePropertyInput
                resetPromoCode={resetPromoCodeAction}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <NotificationBannerWrapper />
      {isMobile && <Divider />}
      {specialRateModalOpen && (
        <SearchBookingSpecialRateModal
          onChange={(promoCode, promoCodeType) => onChangeRateModal(promoCode, promoCodeType)}
        />
      )}
      {singleUnitGroupId && (
        <Box>
          {selectedSingleUnitGroup && (
            <UnitGroupCard unitGroup={selectedSingleUnitGroup} openModal={addToCartAdnOpenModal} />
          )}
          {!selectedSingleUnitGroup && !isSearchAvailableUnitGroupsLoading && (
            <NoOffersAvailable
              additionalText={
                availableUnitGroups.length
                  ? t("label__no_offers_but_check_other_proposals")
                  : undefined
              }
            />
          )}
        </Box>
      )}
      <Box
        mt={isMobile ? 0 : 2.5}
        display="flex"
        flexDirection={reverseDisplaySearchUnitGroupPageEnabled ? "column-reverse" : "column"}
      >
        <DesktopCard>
          <SearchPropertyDetail propertyId={searchOfferParamsDTO.propertyIds || ""} />
        </DesktopCard>
        <Grid sx={{ backgroundColor: "background.default" }}>
          <Box py={2.5} px={isMobile ? 2.5 : 0}>
            {singleUnitGroupId && !selectedSingleUnitGroup && availableUnitGroups.length ? (
              <Heading2 mb={2}>{t("labels__other_proposal_offers")}</Heading2>
            ) : null}

            {(!availableUnitGroups || availableUnitGroups.length === 0) &&
            !singleUnitGroupId &&
            !isSearchAvailableUnitGroupsLoading ? (
              <Box p={isMobile ? 2 : 0}>
                <NoOffersAvailable />
              </Box>
            ) : null}

            {availableUnitGroups &&
              !selectedSingleUnitGroup &&
              availableUnitGroups.map((availableUnitGroup) => (
                <Box
                  className={paddingForMiddleItemsClasses.paddingForMiddleItems}
                  key={availableUnitGroup.unitGroupId}
                >
                  <UnitGroupCard
                    unitGroup={availableUnitGroup}
                    openModal={addToCartAdnOpenModal}
                    key={availableUnitGroup.unitGroupId}
                  />
                </Box>
              ))}
          </Box>
        </Grid>
      </Box>
    </>
  );
};
