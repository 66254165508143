export function createAnimation(callback: (progress: number) => void, timeout: number) {
  let shouldRun = true;
  let rafID: number | null = null;
  return {
    start: () => {
      const time = {
        start: performance.now(),
        total: timeout,
        elapsed: 0
      };

      const tick = (now: number) => {
        if (!shouldRun) {
          return;
        }
        time.elapsed = now - time.start;
        const progress = Math.min(time.elapsed / time.total, 1);
        callback(progress);
        if (progress < 1) {
          rafID = requestAnimationFrame(tick);
        }
      };

      rafID = requestAnimationFrame(tick);
    },
    stop: () => {
      shouldRun = false;
      if (rafID) {
        window.cancelAnimationFrame(rafID);
        rafID = null;
      }
    }
  };
}
