import { FC, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import i18n from "i18next";
import { computeLanguage } from "../../util/hooks/use-language";
import { useAppDispatch } from "../../state/store";
import { useAllowedLocales } from "../../util/hooks/use-configuration";
import { getI18nSelectedLanguage, getLanguageWithDefault } from "../../util/lang-utils";
import { useFetchMagicObject } from "../../features/magic/use-fetch-magic-object";
import { useUserProfile } from "../../features/user-profile/use-user-profile";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import {
  selectLastLanguage,
  setLastLanguage
} from "../../features/restore-magic-context/restore-magic-context.slice";
import { matchPath, useLocation } from "react-router-dom";

export const LanguageSelector: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const allowedLocales = useAllowedLocales();
  const location = useLocation();
  const magicId = matchPath("/magic/:magicId/*", location.pathname)?.params?.magicId;

  const { magicObject } = useFetchMagicObject({ magicId });
  const initialMagicObjectLang = useSelector(selectLastLanguage);
  const { getIsAuthenticated } = useAuth();
  const { userLanguage, userKeycloakUUID: authUsersKeycloakUUID } = useUserProfile();
  const dispatch = useAppDispatch();
  const queryLanguage = new URLSearchParams(window.location.search).get("lng") ?? undefined;

  const browserOrFallbackLanguage = useCallback(
    (selectedLang = navigator.language.toLowerCase()) => {
      document.documentElement.lang = selectedLang;
      return getLanguageWithDefault(selectedLang, allowedLocales);
    },
    [allowedLocales]
  );
  const isAuthUserOwnerOfMagicObject = useMemo(
    () => magicObject?.keycloakUUID === authUsersKeycloakUUID,
    [magicObject?.keycloakUUID, authUsersKeycloakUUID]
  );

  const changeLanguage = useCallback((newLang: string) => {
    if (getI18nSelectedLanguage() !== newLang.toLowerCase()) {
      document.documentElement.setAttribute("lang", newLang.toLowerCase());
      i18n.changeLanguage(newLang.toLowerCase());
    }
  }, []);

  //Authenticated
  useEffect(() => {
    if (getIsAuthenticated() && userLanguage && isAuthUserOwnerOfMagicObject) {
      changeLanguage(userLanguage);
    } else {
      changeLanguage(browserOrFallbackLanguage(initialMagicObjectLang ?? undefined));
    }
  }, [
    getIsAuthenticated,
    userLanguage,
    browserOrFallbackLanguage,
    initialMagicObjectLang,
    isAuthUserOwnerOfMagicObject,
    changeLanguage
  ]);

  //Set initialLanguage from magic object
  useEffect(() => {
    if (computeLanguage(magicObject)) {
      dispatch(setLastLanguage(browserOrFallbackLanguage(computeLanguage(magicObject))));
    } else if (queryLanguage) {
      dispatch(setLastLanguage(browserOrFallbackLanguage(queryLanguage)));
    }
  }, [dispatch, magicObject, browserOrFallbackLanguage, queryLanguage]);

  return <>{children}</>;
};
