import React from "react";
import { Fab as MuiFab, FabProps as MuiFabProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export type FabProps = Omit<
  MuiFabProps,
  | "color"
  | "size"
  | "disableFocusRipple"
  | "tabIndex"
  | "href"
  | "variant"
  | "disableRipple"
  | "disableTouchRipple"
  | "centerRipple"
  | "focusRipple"
  | "focusVisibleClassName"
  | "onFocusVisible"
  | "TouchRippleProps"
>;

const useFabOverrideStyles = makeStyles()((theme) => ({
  root: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    boxShadow: "0px 6px 15px rgba(65, 60, 54, 0.15)",
    borderRadius: theme.shape.borderRadius * 2.5,
    fontSize: "32px",
    "&:hover": {
      backgroundColor: theme.palette.common.black
    }
  },
  disabled: {},
  label: {},
  primary: {},
  colorInherit: {},
  extended: {},
  focusVisible: {},
  secondary: {},
  sizeMedium: {},
  sizeSmall: {}
}));

export const Fab: React.FC<React.PropsWithChildren<FabProps>> = (props) => {
  const classes = useFabOverrideStyles();
  return <MuiFab classes={classes} disableFocusRipple {...props} />;
};
