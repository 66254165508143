import { SubscriptionUseCase, useMagicWebSocket } from "./use-magic-web-socket";
import { useContext, useEffect } from "react";
import { TenantContext, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useAppDispatch } from "../../state/store";
import { customSubscribeApi } from "../../graphql/subscriptions/custom-subscribe";

export const useSubscribeToUseCase = ({
  magicId,
  magicToken,
  topic,
  forceSkipSubscribe
}: {
  magicId?: string;
  magicToken?: string;
  topic: SubscriptionUseCase;
  forceSkipSubscribe?: boolean;
}) => {
  const { subscribe, initialised } = useMagicWebSocket();
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();
  const { tenantId, apiKey } = useContext(TenantContext);

  useEffect(() => {
    if (magicId && magicToken && initialised && !forceSkipSubscribe && isRESTVersion) {
      subscribe(magicId, magicToken, topic);
    }
  }, [magicId, magicToken, topic, subscribe, initialised, forceSkipSubscribe, isRESTVersion]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (magicId && magicToken && isGraphQlVersion && topic === SubscriptionUseCase.PORTAL) {
      // TODO - Uncomment this once subscribe is fixes
      const promise = dispatch(
        customSubscribeApi.endpoints.SubscribeToMagicObject.initiate({
          magicId,
          magicToken,
          tenantId,
          apiKey
        })
      );
      return () => {
        dispatch(
          customSubscribeApi.internalActions.removeQueryResult({
            // @ts-ignore
            queryCacheKey: promise.queryCacheKey
          })
        );
      };
    } else if (magicId && magicToken && isGraphQlVersion && topic === SubscriptionUseCase.BOOKING) {
      const promise = dispatch(
        customSubscribeApi.endpoints.SubscribeToBookingOverview.initiate({
          magicId,
          magicToken,
          tenantId,
          apiKey
        })
      );

      return () => {
        dispatch(
          customSubscribeApi.internalActions.removeQueryResult({
            // @ts-ignore
            queryCacheKey: promise.queryCacheKey
          })
        );
      };
    }
  }, [isGraphQlVersion, dispatch, magicId, tenantId, apiKey, topic, magicToken]);
};
