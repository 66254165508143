import { Heading2, Heading4 } from "@likemagic-tech/sv-magic-library";
import { Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { getI18nSelectedLanguage } from "../../util/lang-utils";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(1),
    boxShadow: "0px 6px 15px rgba(65, 60, 54, 0.15)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  date: {
    fontSize: 24,
    lineHeight: "24px"
  },
  month: {
    fontSize: 16,
    lineHeight: "16px"
  }
}));

function getMonth(date: Date) {
  return date.toLocaleString(getI18nSelectedLanguage(), { month: "short" }).toUpperCase();
}

export const EventCardDateView: React.FC<
  React.PropsWithChildren<{
    date: Date;
    classes?: { root?: string };
  }>
> = ({ date, classes: classesProp }) => {
  const { classes } = useStyles();
  const day = React.useMemo(() => date.getDate(), [date]);
  const month = React.useMemo(() => getMonth(date), [date]);
  return (
    <Paper elevation={2} className={[classes.root, classesProp?.root].join(" ")}>
      <Heading2 align="center" className={classes.date}>
        {day}
      </Heading2>
      <Heading4 fontSize={16} align="center" className={classes.month}>
        {month}
      </Heading4>
    </Paper>
  );
};
