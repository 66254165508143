import React, { FC, lazy, useMemo } from "react";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { useGuestFlow } from "../guest-flow/use-guest-flow";
import { useSetupGuestFlowNavigation } from "../guest-flow/hooks/use-setup-guest-flow-navigation";
import { useHandlePaymentOption } from "./use-handle-payment-option";
import { useAdyenConfig, useFeatureFlags } from "../../util/hooks/use-configuration";
import { useSearchParams } from "../additional-services/use-search-params";
import { PaymentSelectComponent } from "./payment-select-component";
import { TerminalPay } from "./terminal-pay/terminal-pay";
import { TerminalPayType } from "./terminal-pay/terminal-pay-type";
import {
  PaymentOption,
  paymentOptionsNotJustApiCalls,
  SELECTED_PAYMENT_OPTION
} from "./payment-constants";
import { daysBeforeNow } from "@likemagic-tech/sv-magic-library";
import { ReservationStatus } from "../../domain-common/reservation-status";
import { errorHandleForComponentLazyLoad } from "../../util/code-split.util";

const PaymentAdyenWidgetComponent = lazy(() =>
  import("./adyen/payment-adyen-widget-component")
    .then(({ PaymentAdyenWidgetComponent }) => ({
      default: PaymentAdyenWidgetComponent
    }))
    .catch(errorHandleForComponentLazyLoad)
);

interface PaymentSuperComponentProps {
  onSuccessPayment: () => void;
  returnUrl: string;
}
export const PaymentComponent: FC<React.PropsWithChildren<PaymentSuperComponentProps>> = ({
  onSuccessPayment,
  returnUrl
}) => {
  const adyenEnvConfig = useAdyenConfig();
  const { reservation } = useGuestFlow();
  const { payAtCheckoutEnabled, payAtCheckinEnabled } = useFeatureFlags();

  const isCheckoutTodayOrLater = useMemo(
    () => daysBeforeNow(reservation.departure) === 0,
    [reservation.departure]
  );

  const skipPaymentType = useMemo(() => {
    if (reservation) {
      if (payAtCheckinEnabled && reservation?.status === ReservationStatus.CONFIRMED) {
        return PaymentOption.PAY_AT_CHECKIN;
      } else if (payAtCheckoutEnabled && !isCheckoutTodayOrLater) {
        return PaymentOption.PAY_AT_CHECKOUT;
      }
    }
  }, [payAtCheckinEnabled, payAtCheckoutEnabled, isCheckoutTodayOrLater, reservation]);

  const { handlePaymentOption, paymentOptions } = useHandlePaymentOption({
    magicId: reservation.magicId,
    magicToken: reservation.magicToken,
    paymentAccount: reservation.paymentAccount,
    returnUrl,
    onSuccessPayment,
    skipPaymentType
  });

  const { params } = useSearchParams({
    initObject: {
      [SELECTED_PAYMENT_OPTION]:
        paymentOptionsNotJustApiCalls.includes(paymentOptions[0]) && paymentOptions.length === 1
          ? paymentOptions[0]
          : ""
    },
    replacePath: true
  });

  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const selectedPaymentOption = useMemo(
    () => params[SELECTED_PAYMENT_OPTION] as PaymentOption,
    [params]
  );

  if (
    (paymentOptions.length === 1 && paymentOptions[0] === PaymentOption.ADYEN_WIDGET) ||
    selectedPaymentOption === PaymentOption.ADYEN_WIDGET
  ) {
    return (
      <PaymentAdyenWidgetComponent
        onSuccessPayment={onSuccessPayment}
        returnUrl={returnUrl.concat(`?${SELECTED_PAYMENT_OPTION}=${PaymentOption.ADYEN_WIDGET}`)}
        magicId={reservation.magicId}
        envConfig={adyenEnvConfig!}
        labels={{
          payButtonLabel: `${t("buttons__pay")}`
        }}
      />
    );
  } else if (selectedPaymentOption === PaymentOption.TERMINAL_PAYMENT) {
    return (
      <TerminalPay
        magicId={reservation.magicId}
        onSuccess={onSuccessPayment}
        returnUrl={returnUrl.concat(
          `?${SELECTED_PAYMENT_OPTION}=${PaymentOption.TERMINAL_PAYMENT}`
        )}
        payType={TerminalPayType.PAYMENT}
      />
    );
  } else {
    return (
      <PaymentSelectComponent
        handlePaymentOption={handlePaymentOption}
        paymentOptions={paymentOptions}
        paymentAccount={reservation.paymentAccount}
        selectedPaymentOption={selectedPaymentOption}
      />
    );
  }
};
