import * as prismic from "@prismicio/client";
import { Client } from "@prismicio/client";
import { usePrismicContext } from "@prismicio/react";
import { useContext, useMemo } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";

export interface PrismicData {
  prismicApiClient: Client;
  refOptions:
    | {
        ref: string;
      }
    | {};
}

export const useCmsClient = (): PrismicData => {
  const { client } = usePrismicContext();

  return useMemo(() => {
    return {
      prismicApiClient: client as Client,
      refOptions: {
        ref: ""
      }
    };
  }, [client]);
};
export const useCmsCommonClient = (): PrismicData => {
  const { commonPrismic: tenantPrismicConfig } = useContext(TenantContext);

  const client: Client = useMemo(() => {
    const apiEndpoint = tenantPrismicConfig?.apiEndpoint;
    const accessToken = tenantPrismicConfig?.accessToken;
    if (apiEndpoint) {
      return prismic.createClient(apiEndpoint, { accessToken });
    }
    throw new Error("Prismic has not been configured properly, apiEndpoint is missing");
  }, [tenantPrismicConfig?.apiEndpoint, tenantPrismicConfig?.accessToken]);

  return useMemo(() => {
    return {
      prismicApiClient: client as Client,
      refOptions: {
        ref: ""
      }
    };
  }, [client]);
};
