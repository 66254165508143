import { Box, Grid, Slider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { add, formatISO } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { Dialog } from "../../../components";
import { formatTime, formatToIsoDate, Paragraph } from "@likemagic-tech/sv-magic-library";
import { PricePreview } from "../../../components/atoms/price-preview/price-preview";
import { usePropertyById } from "../../property/use-property-by-id";
import { ServiceModalHandlerProps } from "../service-selection/additional-service-modal-handler";
import { useCmsPropertySpecificData } from "../../../state/cms/use-cms-per-property-data";
import { useLateCheckoutModalCmsData } from "../../../state/property-cms/use-service-modal-cms-data";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { multiply, toGross } from "../../../domain-common/full-price";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

const useStyles = makeStyles()((theme) => ({
  dialogContentContainer: {
    alignSelf: "center",
    width: "100%"
  },
  spaceNumberSlider: {
    paddingLeft: theme.spacing(6)
  }
}));

export const LateCheckoutPickerDialog: React.FC<
  React.PropsWithChildren<ServiceModalHandlerProps>
> = ({ cartValue, onClose, onChange, reservation, price, service }) => {
  const [hours, setHours] = useState(cartValue?.dates?.[0]?.count || 0);

  const { classes } = useStyles();
  const property = usePropertyById(reservation?.propertyId);
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const cmsPropertySpecificData = useCmsPropertySpecificData(reservation.propertyId);

  const { translatedKeys: lateCheckoutPickerTranslatedKeys } =
    useLateCheckoutModalCmsData(cmsPropertySpecificData);

  const labels = useMemo(
    () => ({
      buttonLabel: lateCheckoutPickerTranslatedKeys[service.serviceId]?.button,
      descriptionLabel: lateCheckoutPickerTranslatedKeys[service.serviceId]?.description,
      title: lateCheckoutPickerTranslatedKeys[service.serviceId]?.title,
      subtitle: lateCheckoutPickerTranslatedKeys[service.serviceId]?.subtitle,
      currentDeparture: lateCheckoutPickerTranslatedKeys[service.serviceId]?.current_departure,
      newDeparture: lateCheckoutPickerTranslatedKeys[service.serviceId]?.new_departure,
      confirmButton: t("buttons__confirm")
    }),
    [lateCheckoutPickerTranslatedKeys, service.serviceId, t]
  );

  const updatedDepartureTime = useMemo(() => {
    if (reservation.departure) {
      const newDeparture = add(new Date(reservation?.departure), { hours });
      return formatISO(newDeparture);
    }
  }, [reservation.departure, hours]);

  const handleChangeHours = useCallback(() => {
    const values = [
      {
        date: formatToIsoDate(new Date(reservation.departure!)),
        count: hours
      }
    ].filter((item) => item.count);
    onChange({ values });
  }, [hours, onChange, reservation.departure]);

  return (
    <Dialog
      title={labels.title}
      open
      onConfirm={handleChangeHours}
      onDismiss={onClose}
      content={
        <Box>
          <Box m={2}>
            <Grid container direction="row" mt={3}>
              <Grid xs={6} item>
                <Paragraph color="text.secondary">{labels.currentDeparture}</Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Paragraph sx={{ textAlign: "right" }}>
                  {formatTime(reservation?.departure, getI18nSelectedLanguage(), property.timeZone)}
                </Paragraph>
              </Grid>
            </Grid>
            <Grid container direction="row" mt={1} mb={2}>
              <Grid xs={6} item>
                <Paragraph color="text.secondary">{labels.newDeparture}</Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Paragraph sx={{ textAlign: "right" }}>
                  {formatTime(updatedDepartureTime, getI18nSelectedLanguage(), property.timeZone)}
                </Paragraph>
              </Grid>
            </Grid>
          </Box>
          <Grid container flexWrap="nowrap" alignItems="center" px={5}>
            <Slider
              value={hours}
              onChange={(event, newValue) => setHours(newValue as number)}
              max={service.maximumHours}
              track={false}
              min={0}
              aria-labelledby="input-slider"
            />
            <Paragraph className={classes.spaceNumberSlider}>{hours}</Paragraph>
          </Grid>
          <Box display="flex" alignItems="baseline" justifyContent="center" px={2.5} py={2}>
            <PricePreview price={toGross(multiply(price, hours))} />
          </Box>
        </Box>
      }
      buttonLabel={labels.confirmButton}
    />
  );
};
