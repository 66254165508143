import { FC, useMemo } from "react";
import { SvgIconProps } from "@mui/material";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { SvgUrlIcon } from "./svg-url-icon";

export interface SvgUrlIconProps extends SvgIconProps {
  url: string;
  size?: {
    width: number;
    height: number;
  };
}

export const DisplayCmsSvg: FC<SvgUrlIconProps> = (props) => {
  const { disableSVGThemeOverriding } = useFeatureFlags();

  const { url, size: originalSize, ...rest } = props;

  const size = useMemo(() => {
    switch (rest.fontSize) {
      case "small":
        return 24;
      case "large":
        return 36;
      default:
        return 24;
    }
  }, [rest.fontSize]);

  const image = useMemo(
    () =>
      url ? (
        <img
          src={url}
          alt={url}
          className={rest.className}
          style={rest.style}
          width={originalSize?.width ?? size}
          height={originalSize?.height ?? size}
        />
      ) : null,
    [url, size, rest, originalSize?.width, originalSize?.height]
  );

  return disableSVGThemeOverriding ? image : <SvgUrlIcon {...props} sx={{ ...originalSize }} />;
};
