import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type OpenDoorMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
  doorId: Types.Scalars["String"];
}>;

export type OpenDoorMutation = {
  __typename?: "Mutation";
  OpenDoor?:
    | { __typename: "Booking" }
    | { __typename: "Reservation" }
    | { __typename: "TravelBuddy" }
    | null;
};

export const OpenDoorDocument = `
    mutation OpenDoor($magicId: String!, $magicToken: String!, $doorId: String!) {
  OpenDoor(magicId: $magicId, magicToken: $magicToken, doorId: $doorId) {
    __typename
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    OpenDoor: build.mutation<OpenDoorMutation, OpenDoorMutationVariables>({
      query: (variables) => ({ document: OpenDoorDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useOpenDoorMutation } = injectedRtkApi;
