import * as Types from "../../generated/graphql";

export type GroupedRateBreakdownFragmentFragment = {
  __typename?: "GroupedRateBreakdown";
  rateSpliceTypeTuples?: Array<{
    __typename?: "RateSpliceTypeEnumTuple";
    type?: Types.RateSpliceTypeEnumContract | null;
    totalCharges?: {
      __typename?: "GrossPrice";
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      currency?: string | null;
    } | null;
    pmsIdToRateSplicesTuples?: Array<{
      __typename?: "PmsIdToRateSplicesTuple";
      pmsId?: string | null;
      rateSplices?: Array<{
        __typename?: "RateSplice";
        displayName?: string | null;
        quantity?: number | null;
        type?: Types.RateSpliceTypeEnumContract | null;
        prepaymentType?: Types.PrepaymentType | null;
        hide?: boolean | null;
        prepaid?: boolean | null;
        grossPrice?: {
          __typename?: "GrossPrice";
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
          currency?: string | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export const GroupedRateBreakdownFragmentFragmentDoc = `
    fragment GroupedRateBreakdownFragment on GroupedRateBreakdown {
  rateSpliceTypeTuples {
    type
    totalCharges {
      grossPrice
      grossPriceInCents
      currency
    }
    pmsIdToRateSplicesTuples {
      pmsId
      rateSplices {
        grossPrice {
          grossPrice
          grossPriceInCents
          currency
        }
        displayName
        quantity
        type
        prepaymentType
        hide
        prepaid
      }
    }
  }
}
    `;
