import { createSelector, createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { RootState } from "../../state/store";
import { Reservation } from "../../domain-common/reservation";

export interface SecondScreenHomeSliceState {
  reservation?: Reservation;
}

const initialState: SecondScreenHomeSliceState = {
  reservation: undefined
};

export const secondScreenHomeSlice = createSlice({
  name: "second-screen-home",
  initialState,
  reducers: {
    setReservation: (state, action: PayloadAction<SecondScreenHomeSliceState>) => {
      state.reservation = action.payload.reservation;
    },
    resetReservation: () => initialState
  }
});

export const { setReservation, resetReservation } = secondScreenHomeSlice.actions;

const selectSelf: Selector<RootState, SecondScreenHomeSliceState> = (state: RootState) =>
  state[secondScreenHomeSlice.name];

export const selectSecondScreenHomeReservation = createSelector(
  selectSelf,
  (state) => state.reservation
);
