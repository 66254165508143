import { BaseApiClient, JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { UserProfile } from "../domain-common/user-profile";
import { BookingOverview } from "../domain-common/booking-overview";

const BASE_URL = "/api/guest-journey-service/user-profile";

class UserProfileApiClient extends BaseApiClient {
  async fetchUserProfile(init?: RequestInit) {
    const response = await this.fetchApi(BASE_URL, init);
    return new JSONApiResponse<UserProfile>(response).value();
  }

  async fetchTheLatestMagicId(init?: RequestInit) {
    const response = await this.fetchApi(`${BASE_URL}/latest-magic-id`, init);
    return new JSONApiResponse<{ latestMagicId: string }>(response).value();
  }

  async fetchBookerViewInactive(init: { signal: AbortSignal }) {
    const url = BASE_URL + `/booking-overview/inactive`;
    const response = await this.fetchApi(url, init);
    return new JSONApiResponse<Array<BookingOverview>>(response).value();
  }

  async unlinkAccount(params: { providerName: string }, init?: RequestInit) {
    const url = BASE_URL + `/linked-accounts/${params.providerName}`;
    const response = await this.fetchApi(url, {
      ...init,
      method: "DELETE"
    });

    return new VoidApiResponse(response).value();
  }

  async deleteAccount(id: string, init?: RequestInit) {
    const url = BASE_URL + `/${id}/remove-user-account`;
    const response = await this.fetchApi(url, {
      ...init,
      method: "DELETE"
    });

    return new VoidApiResponse(response).value();
  }
}

export const UserProfileApi = new UserProfileApiClient();
