import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type DeleteUserAccountMutationVariables = Types.Exact<{
  userAccountUuid?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type DeleteUserAccountMutation = { __typename?: "Mutation"; DeleteUserAccount: boolean };

export const DeleteUserAccountDocument = `
    mutation DeleteUserAccount($userAccountUuid: String) {
  DeleteUserAccount(userAccountUuid: $userAccountUuid)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    DeleteUserAccount: build.mutation<
      DeleteUserAccountMutation,
      DeleteUserAccountMutationVariables | void
    >({
      query: (variables) => ({ document: DeleteUserAccountDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useDeleteUserAccountMutation } = injectedRtkApi;
