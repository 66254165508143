import { Box, Grid, useTheme, Zoom } from "@mui/material";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { ScrollScalingImage } from "../../../components";
import { responsiveVariables } from "../../../components/layouts/hooks/use-is-mobile";
import { MyStayHeaderImageRectangle } from "./my-stay-header-image-rectangle";
import { PrismicImage } from "../../../state/property-cms/use-unit-group-cms-data";

interface MyStayHeaderImageProps {
  items: PrismicImage[];
}

const useStyles = makeStyles()(({ breakpoints, shape, spacing }) => ({
  image: {
    marginTop: spacing(-3.5),
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      marginTop: spacing(-16)
    },
    display: "flex",
    justifyContent: "center",
    marginBottom: spacing(1)
  },
  imageGridItem: {
    zIndex: shape.borderRadius === 0 ? 1 : 1100,
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      zIndex: 1,
      justifyContent: "center"
    }
  }
}));

export const MyStayHeaderImage: FC<MyStayHeaderImageProps> = ({ items }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const transitionDelay = { transitionDelay: "100ms" };
  return (
    <Grid item className={classes.imageGridItem} xs={12}>
      {theme.shape.borderRadius === 0 ? (
        <Box>
          <MyStayHeaderImageRectangle items={items} />
        </Box>
      ) : (
        <Zoom in={true} style={transitionDelay}>
          <Box className={classes.image}>
            <ScrollScalingImage src={items[0]?.url || ""} />
          </Box>
        </Zoom>
      )}
    </Grid>
  );
};
