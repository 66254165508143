import { ReservationStatus } from "../../../domain-common/reservation-status";
import { BookingOnBehalfOf } from "../../../domain-common/person";
import { Actor } from "../../../domain-common/booking-overview";
// If we import NotificationChipType directly from components
// jest test will have NotificationChipType as undefined and throw an error
// https://github.com/kulshekhar/ts-jest/issues/281#issuecomment-1028449872
import { NotificationChipType } from "../../../components/atoms/notification-chip/chip-type";

const generateResponse = (
  cancelButton: boolean,
  extendButton: boolean,
  chipValue: string,
  primaryButtonLabel?: string,
  chipType: NotificationChipType = NotificationChipType.Success
) => ({
  cancelButton,
  extendButton,
  primaryButtonLabel,
  chipValue,
  chipType
});

const undefinedResponse = generateResponse(false, false, "", "", NotificationChipType.Error);

interface UseChipsAndButtonsBookingOverviewProps {
  actor: Actor;
  bookingOnBehalfOf?: BookingOnBehalfOf;
  reservationStatus: ReservationStatus;
  notificationSent?: boolean;
  guestFlowCompleted: boolean;
  totalAllowedPaymentAmount: number;
}

const handleBooker = ({
  reservationStatus,
  bookingOnBehalfOf,
  notificationSent,
  guestFlowCompleted,
  totalAllowedPaymentAmount
}: UseChipsAndButtonsBookingOverviewProps) => {
  if (!bookingOnBehalfOf && reservationStatus === ReservationStatus.CONFIRMED) {
    return generateResponse(
      true,
      true,
      "chip__guest_data_missing",
      "buttons__edit_guest",
      NotificationChipType.Error
    );
  }
  if (
    bookingOnBehalfOf === BookingOnBehalfOf.MYSELF &&
    reservationStatus === ReservationStatus.CONFIRMED &&
    !guestFlowCompleted
  ) {
    return generateResponse(true, true, "chip__confirmed", "buttons__continue_checkin");
  }

  if (
    bookingOnBehalfOf === BookingOnBehalfOf.OTHERS &&
    reservationStatus === ReservationStatus.CONFIRMED &&
    !notificationSent &&
    !guestFlowCompleted
  ) {
    return generateResponse(
      true,
      true,
      "chip__invite_due",
      "buttons__edit_guest",
      NotificationChipType.Error
    );
  }

  if (
    bookingOnBehalfOf === BookingOnBehalfOf.OTHERS &&
    reservationStatus === ReservationStatus.CONFIRMED &&
    notificationSent &&
    !guestFlowCompleted
  ) {
    return generateResponse(true, true, "chip__invite_sent", "buttons__edit_guest");
  }

  if (
    bookingOnBehalfOf === BookingOnBehalfOf.MYSELF &&
    reservationStatus === ReservationStatus.CONFIRMED &&
    guestFlowCompleted
  ) {
    return generateResponse(true, true, "chip__checked_in", "buttons__my_stay");
  }
  if (
    bookingOnBehalfOf === BookingOnBehalfOf.OTHERS &&
    reservationStatus === ReservationStatus.CONFIRMED &&
    (notificationSent || guestFlowCompleted) &&
    guestFlowCompleted
  ) {
    return generateResponse(true, true, "chip__checked_in", "buttons__edit_guest");
  }

  if (
    reservationStatus === ReservationStatus.IN_HOUSE &&
    guestFlowCompleted &&
    totalAllowedPaymentAmount > 0
  ) {
    return generateResponse(
      false,
      true,
      "chip__open_balance",
      "buttons__my_stay",
      NotificationChipType.Error
    );
  }

  if (
    (bookingOnBehalfOf === BookingOnBehalfOf.MYSELF || !bookingOnBehalfOf) &&
    reservationStatus === ReservationStatus.IN_HOUSE &&
    guestFlowCompleted
  ) {
    return generateResponse(false, true, "chip__in_house", "buttons__my_stay");
  }

  if (
    bookingOnBehalfOf === BookingOnBehalfOf.OTHERS &&
    reservationStatus === ReservationStatus.IN_HOUSE &&
    (notificationSent || guestFlowCompleted) &&
    guestFlowCompleted
  ) {
    return generateResponse(false, true, "chip__in_house");
  }
  if (
    bookingOnBehalfOf === BookingOnBehalfOf.OTHERS &&
    reservationStatus === ReservationStatus.CHECKED_OUT &&
    guestFlowCompleted
  ) {
    return generateResponse(false, false, "chip__checked_out");
  }
  if (reservationStatus === ReservationStatus.CHECKED_OUT && guestFlowCompleted) {
    return generateResponse(false, false, "chip__checked_out", "buttons__my_stay");
  }

  return undefinedResponse;
};

export const handlePrimaryGuestAndTravelBuddy = ({
  actor,
  reservationStatus,
  notificationSent,
  guestFlowCompleted,
  totalAllowedPaymentAmount
}: UseChipsAndButtonsBookingOverviewProps) => {
  if (
    reservationStatus === ReservationStatus.CONFIRMED &&
    !notificationSent &&
    !guestFlowCompleted
  ) {
    return generateResponse(
      actor !== Actor.TRAVEL_BUDDY,
      actor !== Actor.TRAVEL_BUDDY,
      "chip__confirmed",
      "buttons__start_checkin"
    );
  }
  if (
    reservationStatus === ReservationStatus.CONFIRMED &&
    notificationSent &&
    !guestFlowCompleted
  ) {
    return generateResponse(
      actor !== Actor.TRAVEL_BUDDY,
      actor !== Actor.TRAVEL_BUDDY,
      "chip__confirmed",
      "buttons__continue_checkin"
    );
  }
  if (
    reservationStatus === ReservationStatus.CONFIRMED &&
    (notificationSent || guestFlowCompleted) &&
    guestFlowCompleted
  ) {
    return generateResponse(
      actor !== Actor.TRAVEL_BUDDY,
      actor !== Actor.TRAVEL_BUDDY,
      "chip__checked_in",
      "buttons__my_stay"
    );
  }

  if (
    reservationStatus === ReservationStatus.IN_HOUSE &&
    guestFlowCompleted &&
    totalAllowedPaymentAmount > 0 &&
    actor !== Actor.TRAVEL_BUDDY
  ) {
    return generateResponse(
      false,
      true,
      "chip__open_balance",
      "buttons__my_stay",
      NotificationChipType.Error
    );
  }

  if (
    reservationStatus === ReservationStatus.IN_HOUSE &&
    (notificationSent || guestFlowCompleted)
  ) {
    return generateResponse(
      false,
      actor !== Actor.TRAVEL_BUDDY,
      "chip__in_house",
      "buttons__my_stay"
    );
  }

  if (
    reservationStatus === ReservationStatus.CHECKED_OUT &&
    (notificationSent || guestFlowCompleted) &&
    guestFlowCompleted
  ) {
    return generateResponse(false, false, "chip__checked_out", "buttons__my_stay");
  }
  return undefinedResponse;
};

export const useChipsAndButtonsBookingOverview: (props: UseChipsAndButtonsBookingOverviewProps) => {
  cancelButton: boolean;
  extendButton: boolean;
  primaryButtonLabel?: string;
  chipValue: string;
  chipType: NotificationChipType;
} = (props) => {
  switch (props.actor) {
    case Actor.BOOKER:
      return handleBooker(props);
    case Actor.PRIMARY_GUEST:
      return handlePrimaryGuestAndTravelBuddy(props);
    case Actor.TRAVEL_BUDDY:
      return handlePrimaryGuestAndTravelBuddy(props);
  }
  return undefinedResponse;
};
