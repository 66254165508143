import React, { FC, useCallback, useState } from "react";
import { Box, Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { Paragraph, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { PricePreview } from "../../../components/atoms/price-preview/price-preview";
import { ServiceBoxItem } from "../pages/box-shop.page";
import { toGross } from "../../../domain-v1/full-price";
import { makeStyles } from "tss-react/mui";
import { BoxRentType, BoxType } from "../../../domain-v1/box-availability";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useServicesCmsData } from "../../services/use-services-cms-data";
import { FullPrice } from "../../../domain-common/full-price";
import { Dialog } from "../../../components/atoms";
import { BoxItemModal } from "./box-item-modal";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useNavigate } from "react-router-dom";
import { usePreparePayment } from "../../payment/use-prepare-payment";
import {
  generatePortalBoxShopPaymentSuccessUrl,
  generatePortalPaymentNavigate
} from "../../../util/routing";
import {
  CONFIRMATION_TYPE_KEY,
  ConfirmationType
} from "../../../components/molecules/confirmation/confirmation-type";
import { reserveBox } from "../box-shop.slice";
import { useAppDispatch } from "../../../state/store";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { unwrapResult } from "@reduxjs/toolkit";
import { useBookFreeBoxShopItem } from "../hooks/use-book-free-box-shop-item";

interface BoxCardItemProps {
  serviceBoxItem: ServiceBoxItem;
  propertyId: string;
}

const boxShopPriceLabel = (
  type: BoxType,
  price: FullPrice,
  translation: (
    key: string,
    additionalInterpolationMap?: Record<string, string> | undefined
  ) => string,
  rentType: BoxRentType | undefined
) => {
  if (price) {
    switch (type) {
      case BoxType.BUY:
        return <PricePreview price={toGross(price)} />;
      case BoxType.RENT:
        return (
          <PricePreview
            price={toGross(price)}
            additionalInfo={translation(`labels__box_shop_${rentType?.toLowerCase()}`)}
          />
        );
      case BoxType.BUY_CONSUMABLES:
        return (
          <PricePreview
            price={toGross(price)}
            additionalInfo={translation("labels__box_shop_item")}
          />
        );
      case BoxType.BUY_FREE:
        return translation("labels__box_shop_for_buy_free");
    }
  }
};

const useStyles = makeStyles()((theme) => ({
  actionArea: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  media: {
    height: 140,
    width: "100%",
    backgroundSize: "contain",
    marginTop: theme.spacing(4)
  },
  cardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  }
}));

export const BoxCardItem: FC<BoxCardItemProps> = ({ serviceBoxItem, propertyId }) => {
  const { classes } = useStyles();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { preparePayment } = usePreparePayment(magicId);
  const { bookFreeBoxShopItem } = useBookFreeBoxShopItem(magicId);
  const { reservation } = useReservationContext();
  const {
    serviceTitles: productServiceTitles,
    serviceImages: productImagesByServiceId,
    fallbackImage: productFallbackImage
  } = useServicesCmsData(propertyId);

  const [amount, setAmount] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const imgSrc = productImagesByServiceId[serviceBoxItem.serviceId] ?? productFallbackImage;

  const shopItemQuantity = useCallback(
    (type: BoxType) => {
      switch (type) {
        case BoxType.BUY:
        case BoxType.BUY_FREE:
          return 1;
        case BoxType.RENT:
        case BoxType.BUY_CONSUMABLES:
          return amount;
      }
    },
    [amount]
  );

  const onModalConfirm = useCallback(
    async (serviceBoxItem: ServiceBoxItem) => {
      await dispatch(
        reserveBox({
          magicId: reservation.magicId,
          magicToken: reservation.magicToken,
          boxId: serviceBoxItem.boxId
        })
      ).then(unwrapResult);
      const shopItems = [
        {
          serviceId: serviceBoxItem.serviceId,
          quantity: shopItemQuantity(serviceBoxItem.type)
        }
      ];
      if (serviceBoxItem.type !== BoxType.BUY_FREE) {
        await preparePayment({
          shopItems
        });
        navigate(
          generatePortalPaymentNavigate(magicId, {
            [CONFIRMATION_TYPE_KEY]: ConfirmationType.BOX_SHOP,
            deviceId: serviceBoxItem.boxId
          })
        );
      } else {
        bookFreeBoxShopItem(shopItems);
        navigate(generatePortalBoxShopPaymentSuccessUrl(magicId, serviceBoxItem.boxId));
      }
    },
    [
      dispatch,
      magicId,
      navigate,
      shopItemQuantity,
      preparePayment,
      bookFreeBoxShopItem,
      reservation.magicId,
      reservation.magicToken
    ]
  );

  const onModalClose = useCallback(() => {
    setIsOpenModal(false);
    setAmount(1);
  }, []);

  const boxShopLabel = (serviceType: BoxType) => {
    switch (serviceType) {
      case BoxType.BUY_FREE:
        return t("button__book_box_shop");
      case BoxType.BUY:
      case BoxType.BUY_CONSUMABLES:
        return t("buttons__buy");
      case BoxType.RENT:
        return t("buttons__rent");
    }
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardActionArea onClick={() => setIsOpenModal(true)} classes={{ root: classes.actionArea }}>
        <CardMedia
          image={imgSrc}
          className={classes.media}
          title={productServiceTitles[serviceBoxItem.serviceId]}
        />
        <CardContent className={classes.cardContent}>
          <Box flexGrow={1}>
            <Paragraph gutterBottom align="center">
              {productServiceTitles[serviceBoxItem.serviceId]}
            </Paragraph>
            {(serviceBoxItem.boxCollection || serviceBoxItem.unitId) && (
              <ParagraphBold gutterBottom align="center" color="text.secondary">
                {/* TODO move it to Prismic */}
                {serviceBoxItem.boxCollection
                  ? serviceBoxItem.boxCollection
                  : t("labels__unit") + " " + reservation.unit?.name}
              </ParagraphBold>
            )}
          </Box>
          <Box display="flex" alignItems="baseline" justifyContent="center">
            <Paragraph>
              {boxShopPriceLabel(
                serviceBoxItem.type,
                serviceBoxItem.fullPrice,
                t,
                serviceBoxItem.boxRentType
              )}
            </Paragraph>
          </Box>
        </CardContent>
      </CardActionArea>
      <Dialog
        open={isOpenModal}
        onConfirm={() => onModalConfirm(serviceBoxItem)}
        onDismiss={onModalClose}
        content={<BoxItemModal serviceBox={serviceBoxItem} amount={amount} setAmount={setAmount} />}
        buttonLabel={boxShopLabel(serviceBoxItem.type)}
      />
    </Card>
  );
};
