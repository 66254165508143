import React, { useMemo } from "react";
import { Heading2, Heading4, Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box, Card, CardContent, Grid, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { usePropertyCmsData } from "../../../state/property-cms/use-property-cms-data";
import { DisplayCmsSvg } from "../../../components";
import { emptyPrice, Price } from "../../../domain-common/price";
import { CarouselGallery } from "../../../components/molecules/carousel-gallery/carousel-gallery";
import { responsiveVariables, useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { useUnitGroupCmsData } from "../../../state/property-cms/use-unit-group-cms-data";
import { SizeEnum } from "../../../domain-common/size-enum";
import { FeaturesList } from "../../../components/molecules/features-preview/features-list";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { PriceWithAdditional } from "../../../components/atoms/price-with-additional/price-with-additional";
import { PromoCodeMagicDiscountType } from "../../../domain-common/search-unit-group";

interface SearchUnitGroupCardProps {
  propertyId: string;
  unitGroupId: string;
  maxPersons: number;
  pricePerNight?: Price;
  priceTotal?: Price;
  pricePerNightBeforeDiscount?: Price;
  priceTotalBeforeDiscount?: Price;
  promoCodeMagicDiscountType?: PromoCodeMagicDiscountType;
  numberOfNights: number;
}

const useStyles = makeStyles()(({ spacing, palette, typography, breakpoints }: Theme) => ({
  paragraph: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "left",
    paddingBottom: spacing(1)
  },
  icon: {
    width: 30,
    paddingRight: spacing(0.5)
  },
  rate: {
    height: "70px",
    fontSize: "20px",
    backgroundColor: palette.grey[300]
  },
  ratePrice: {
    fontWeight: typography.fontWeightBold
  },
  imageContainer: {
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      background: `linear-gradient(to top, ${palette.grey[300]} 80%, ${palette.background.paper}  20%)`
    }
  }
}));

export const SearchUnitGroupCard: React.FC<React.PropsWithChildren<SearchUnitGroupCardProps>> = ({
  propertyId,
  unitGroupId,
  pricePerNight,
  priceTotal,
  maxPersons,
  pricePerNightBeforeDiscount,
  priceTotalBeforeDiscount,
  promoCodeMagicDiscountType,
  numberOfNights
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const { propertyLocationIcon, propertyName, propertyAddress } = usePropertyCmsData(propertyId);
  const { unitGroupImages, unitGroupFeatures, unitGroupLabel } = useUnitGroupCmsData(
    propertyId,
    unitGroupId
  );

  const maxPersonsLabel = useMemo(() => {
    if (maxPersons === 1) {
      return `1 ${t("labels__person")}`;
    } else {
      return `1 - ${maxPersons} ${t("labels__people")}`;
    }
  }, [maxPersons, t]);

  const unitGroupPropertyLabel = useMemo(() => {
    const prepositionLabel = t("labels__preposition_unitgroup");
    if (prepositionLabel !== null && prepositionLabel.length > 0) {
      return `${unitGroupLabel} ${prepositionLabel} ${propertyName}`;
    } else {
      return `${unitGroupLabel} ${propertyName}`;
    }
  }, [unitGroupLabel, propertyName, t]);

  return (
    <Card>
      <Grid container flexDirection="row">
        <Grid item xs={12} md={5} className={classes.imageContainer}>
          {unitGroupImages && <CarouselGallery items={unitGroupImages} />}
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            minHeight={isMobile ? 0 : 300}
          >
            <Grid item xs={12}>
              <CardContent>
                <Heading2 mb={5} ml={1}>
                  {unitGroupPropertyLabel}
                </Heading2>

                <Heading4 mb={1} ml={1}>
                  {maxPersonsLabel}
                </Heading4>
                <Paragraph className={classes.paragraph}>
                  <DisplayCmsSvg url={propertyLocationIcon?.url} className={classes.icon} />
                  {propertyAddress}
                </Paragraph>
                <Box>
                  <FeaturesList items={unitGroupFeatures} itemSize={SizeEnum.SMALL} />
                </Box>
              </CardContent>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={1}
                className={classes.rate}
              >
                {pricePerNight && (
                  <PriceWithAdditional
                    pricePerNight={pricePerNight}
                    suffixPerNight={t("labels__price_suffix")}
                    priceTotal={priceTotal || emptyPrice()}
                    prefixTotal={t("labels__price_prefix")}
                    suffixTotal={t("labels__total_price_suffix")}
                    pricePerNightBeforeDiscount={pricePerNightBeforeDiscount}
                    priceTotalBeforeDiscount={priceTotalBeforeDiscount}
                    promoCodeMagicDiscountType={promoCodeMagicDiscountType}
                    numberOfNights={numberOfNights}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
