import { PrismicDocument } from "@prismicio/client";

import { MyStayArticleDetails } from "../domain-common/my-stay-article-details";

export function PrismicDocumentToMyStayArticleDetailsMapper(
  doc: PrismicDocument
): MyStayArticleDetails {
  return {
    id: doc.id,
    title: doc.data["main__title"],
    subtitle: doc.data["details__subtitle"],
    coverImage: doc.data["main__cover_image"]?.["url"],
    description: doc.data["details__description"],
    contentType: doc.data["main__content_type"],
    propertyId: doc.data["main__property_id"]
  };
}
