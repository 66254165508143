import React, { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationComponent, DesktopCard, PaperButton } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useAppDispatch } from "../../../state/store";
import { isReservation } from "../../../util/flow";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { getKioskId, hideAssignKeyForKioskMode, isKioskMode } from "../../../util/kiosk-mode";
import { HelmetTitle } from "../../gtm/helmet-title";
import { clearPaymentState } from "../../payment/payment.slice";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { useGuestFlow } from "../use-guest-flow";
import { Box, Grid, Paper, Theme, Typography } from "@mui/material";
import { getNextFlowUrl } from "../guest-flow-sequence";
import { Button, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";
import { useSetupGuestFlowNavigation } from "../hooks/use-setup-guest-flow-navigation";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { responsiveVariables, useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { isKeyAssignAvailable } from "../../keys-handlers/door-utils";
import { roomNotAssignedOrClean } from "../../../util/room-state-util";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { useFeatureFlags, useKioskConfig } from "../../../util/hooks/use-configuration";
import { KioskOptionType } from "../../find-reservation/find-reservation/kiosk-option";
import { useConfigurationCmsData } from "../../../state/configuration-cms/use-configuration-cms";

interface ConfirmationPageProps {}

const useStyles = makeStyles()(({ spacing, breakpoints, shape, palette }: Theme) => ({
  assignKeyButton: {
    position: "fixed",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: spacing(18),
    width: `calc(100% - ${spacing(5)})`,
    maxWidth: `calc(1024px - ${spacing(5)})`,
    textAlign: "center",

    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      position: "relative",
      transform: "none",
      left: 0,
      bottom: 0,
      width: "inherit",
      paddingTop: spacing(2),
      maxWidth: "initial"
    }
  },
  buttonWrapper: {
    border: `1px solid ${palette.grey[300]}`,
    borderRadius: shape.borderRadius
  },
  paper: {
    background: palette.common.white
  },
  logo: {
    maxWidth: spacing(25),
    height: spacing(8.75)
  }
}));

let CURRENT_PAGE = GuestFlowCheckpoint.CONFIRMATION;

export const ConfirmationPage: FC<ConfirmationPageProps> = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { tfeFeatureEnabled } = useFeatureFlags();

  const { reservation, patchGuestFlowStep, contactButton, skipButton } = useGuestFlow();
  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });

  const { successScreensButtonsFromCms } = useConfigurationCmsData(reservation.propertyId);

  const { t: tCommon, exists } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const logoUrl = useLogoUrl();
  const dispatch = useAppDispatch();
  const { availableOptions: newAvailableOptions } = useKioskConfig();
  const config = useDoorProviderConfig(reservation.propertyId);
  const isMobile = useIsMobile();

  const hideAssignKeyButton = useMemo(() => {
    const isNewKioskConfig = !!getKioskId();
    if (isNewKioskConfig) {
      return !newAvailableOptions.includes(KioskOptionType.CREATE_KEY);
    } else {
      return hideAssignKeyForKioskMode();
    }
  }, [newAvailableOptions]);

  const isAssignKeyVisible = useMemo(
    () =>
      isKioskMode() &&
      !hideAssignKeyButton &&
      isKeyAssignAvailable(config?.doorProvider) &&
      roomNotAssignedOrClean(reservation),
    [config?.doorProvider, reservation, hideAssignKeyButton]
  );

  const onConfirm = React.useCallback(async () => {
    await patchGuestFlowStep({
      reservationValues: reservation,
      checkpoint: CURRENT_PAGE,
      subUpdate: SubUpdate.FLOW_STATE
    });
    dispatch(clearPaymentState());
    if (isKioskMode()) {
      navigate(`/logout`);
    } else {
      navigate(generatePortalMyStayUrl(reservation.magicId));
    }
  }, [navigate, reservation, patchGuestFlowStep, dispatch]);

  const text = useMemo(() => {
    if (isKioskMode()) {
      if (!roomNotAssignedOrClean(reservation)) {
        return "confirmations__room_dirty";
      }
      return "confirmations__kiosk_mode";
    } else {
      return isReservation(reservation)
        ? "confirmations__guest_flow"
        : "confirmations__travel_buddy";
    }
  }, [reservation]);

  const submitButton = useMemo(
    () => (isKioskMode() ? tCommon("buttons__logout") : tCommon("buttons__go_to_my_stay")),
    [tCommon]
  );

  const onCreateKey = useCallback(async () => {
    await patchGuestFlowStep({
      reservationValues: reservation,
      checkpoint: CURRENT_PAGE,
      subUpdate: SubUpdate.FLOW_STATE
    });
    navigate(getNextFlowUrl(CURRENT_PAGE, reservation));
  }, [navigate, reservation, patchGuestFlowStep]);

  const topBarIcons = useMemo(() => {
    if (isReservation(reservation)) {
      if (isKioskMode()) {
        return [skipButton];
      }
      return [contactButton];
    }
    return [];
  }, [reservation, contactButton, skipButton]);

  return (
    <>
      <HelmetTitle suffix="Guest flow finished" />
      <FlowTemplate icons={topBarIcons}>
        <GuestFlowBackgroundBox progressBarIsHidden={true}>
          <Box p={2} pt={0}>
            <DesktopCard>
              <ConfirmationComponent
                onConfirm={onConfirm}
                submitButtonLabel={submitButton}
                subtitleInfo={tCommon(text)}
                logoSrc={logoUrl}
                unit={isKioskMode() ? reservation.unit : undefined}
              >
                <>
                  <Box
                    sx={{
                      mx: isMobile ? 2.5 : "auto",
                      mt: 4,
                      width: isMobile ? "auto" : "calc(50% - 20px)"
                    }}
                  >
                    {exists("confirmations__checkin_flow_additional_text") ? (
                      <Paper
                        sx={{ p: 2.5, mt: 4, border: "none" }}
                        elevation={3}
                        className={classes.paper}
                      >
                        {tfeFeatureEnabled && (
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            pt={3}
                            pb={2.5}
                          >
                            {logoUrl && <img src={logoUrl} alt="logo" className={classes.logo} />}
                          </Box>
                        )}
                        <Typography align="center" variant="body1">
                          {tCommon("confirmations__checkin_flow_additional_text")}
                        </Typography>
                      </Paper>
                    ) : undefined}
                    <Box pt={2}>
                      <Grid container spacing={2}>
                        {successScreensButtonsFromCms.map((item, index) => {
                          return (
                            <Grid item xs={6}>
                              <PaperButton
                                key={`${index}-${item.title}`}
                                title={item.title}
                                small
                                onClick={() => window.open(item.link, "_blank")}
                                iconUrl={item.icon.url ?? ""}
                                className={classes.buttonWrapper}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                  {isAssignKeyVisible && (
                    <Box className={classes.assignKeyButton}>
                      <Button
                        sx={{ width: "50%" }}
                        variant="secondary"
                        onClick={onCreateKey}
                        fullWidth
                      >
                        {tCommon("buttons__assign_key")}
                      </Button>
                    </Box>
                  )}
                </>
              </ConfirmationComponent>
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
