import React, { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { SearchUnitGroup } from "../../../domain-common/search-unit-group";
import { SearchUnitGroupCard } from "./search-unit-group-card";
import { generateURLSearchParams } from "../hooks/use-search-offer-params";
import { generateSearchBookingPagesUrl } from "../search-booking-navigation";
import { SearchBookingPages } from "../search-booking-pages";
import { useNavigate } from "react-router-dom";
import { FixMapWrapper, MapPreview } from "../../../components/molecules/map-preview";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { selectIsAnyPropertyLoading } from "../../../state/property-cms/property-cms.slice";
import { NoOffersAvailable } from "./no-offers-available";
import { SearchBookingFiltersValues } from "./filters/search-booking-filters";
import { useSearchAvailableUnitGroup } from "../hooks/use-search-available-unit-group";
import { selectIsSearchAvailableUnitGroupsLoading } from "../search-available-unit-groups.slice";

interface SearchBookingCitiesProps {
  searchValues: SearchBookingFiltersValues;
}

export const SearchBookingCities: FC<React.PropsWithChildren<SearchBookingCitiesProps>> = ({
  searchValues
}) => {
  const navigate = useNavigate();
  const { arrival, departure, adults, childrenAges, promoCodePMS, promoCodeMagic } = searchValues;
  const isMobile = useIsMobile();
  const cmsPropertySpecificDocumentsIsLoading = useSelector(selectIsAnyPropertyLoading);
  const isSearchAvailableUnitGroupsLoading = useSelector(selectIsSearchAvailableUnitGroupsLoading);
  const cheapestOffer = useCallback((availableUnitGroup: SearchUnitGroup) => {
    const offers = availableUnitGroup.offers
      .map((item) => item)
      .sort((a, b) => a.priceTotal.amount - b.priceTotal.amount);

    return offers.length ? offers[0] : undefined;
  }, []);

  const { availableUnitGroups } = useSearchAvailableUnitGroup({ searchValues });
  const goToUnitPage = useCallback(
    (propertyId: string, unitGroupId?: string) => () => {
      const searchParams = generateURLSearchParams({
        arrival,
        departure,
        adults,
        childrenAges,
        promoCodePMS,
        promoCodeMagic,
        propertyIds: propertyId, //This is a list of one property
        unitGroupId
      });

      navigate({
        pathname: generateSearchBookingPagesUrl(SearchBookingPages.SEARCH_AVAILABLE_UNIT_GROUPS),
        search: searchParams.toString()
      });
    },
    [arrival, departure, adults, childrenAges, promoCodePMS, navigate, promoCodeMagic]
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        {(!availableUnitGroups || availableUnitGroups.length === 0) &&
          !isSearchAvailableUnitGroupsLoading && (
            <Box mt={5}>
              <NoOffersAvailable />
            </Box>
          )}

        {availableUnitGroups.map((availableUnitGroup) => (
          <Box py={1} key={availableUnitGroup.unitGroupId}>
            <div
              style={{ cursor: "pointer" }}
              onClick={goToUnitPage(availableUnitGroup.propertyId, availableUnitGroup.unitGroupId)}
            >
              <SearchUnitGroupCard
                propertyId={availableUnitGroup.propertyId}
                unitGroupId={availableUnitGroup.unitGroupId}
                pricePerNight={cheapestOffer(availableUnitGroup)?.pricePerNight}
                priceTotal={cheapestOffer(availableUnitGroup)?.priceTotal}
                pricePerNightBeforeDiscount={
                  cheapestOffer(availableUnitGroup)?.pricePerNightBeforeDiscount
                }
                priceTotalBeforeDiscount={
                  cheapestOffer(availableUnitGroup)?.priceTotalBeforeDiscount
                }
                promoCodeMagicDiscountType={
                  cheapestOffer(availableUnitGroup)?.promoCodeMagicDiscountType
                }
                maxPersons={availableUnitGroup.maxPersons}
                numberOfNights={availableUnitGroup.numberOfNights}
              />
            </div>
          </Box>
        ))}
      </Grid>
      {!isMobile && !cmsPropertySpecificDocumentsIsLoading && (
        <Grid item md={6} p={1}>
          <FixMapWrapper>
            <MapPreview selectedPropertyId={searchValues.propertyIds} onPinClick={goToUnitPage} />
          </FixMapWrapper>
        </Grid>
      )}
    </Grid>
  );
};
