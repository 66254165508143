import { useContext, useEffect, useState } from "react";
import { MagicApi } from "../../api/magic.api";
import { AdditionalServicesPaymentDTO, FolioPaymentDTO, ShopItemsPaymentDTO } from "./payment-dto";
import { FullPrice } from "../../domain-common/full-price";
import { TenantContext, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { getTenantHeaders } from "../../api/custom-headers";
import { useGetTotalUnpaidAmountQuery } from "../../graphql/queries/GetTotalUnpaidAmount.generated";
import { transformGrossPriceToFullPrice } from "../../graphql/transform/transform-reservation";
import {
  prepareServicesForPaymentV2,
  prepareShopItemsForPaymentV2
} from "../../graphql/transform/transform-additional-services-availability";

export const useTotalPrice = (
  magicId: string,
  shopItems?: ShopItemsPaymentDTO,
  additionalServices?: AdditionalServicesPaymentDTO,
  foliosToBePaid?: FolioPaymentDTO
): FullPrice | null => {
  const [totalPrice, setTotalPrice] = useState<FullPrice | null>(null);
  const { isRESTVersion } = useApiVersion();
  const { apiKey, tenantId, keycloak } = useContext(TenantContext);

  const { data } = useGetTotalUnpaidAmountQuery(
    {
      magicId,
      services: [
        ...(additionalServices?.map(prepareServicesForPaymentV2) ?? []),
        ...(shopItems?.map(prepareShopItemsForPaymentV2) ?? [])
      ]
    },
    { refetchOnMountOrArgChange: true, skip: isRESTVersion }
  );

  useEffect(() => {
    let abortController: AbortController;

    async function getTotalToBePaid() {
      if (!magicId || !isRESTVersion) {
        return;
      }
      abortController = new AbortController();
      try {
        const result = await MagicApi.getTotalPrice(
          {
            magicId,
            foliosToBePaid,
            shopItems,
            additionalServices
          },
          {
            signal: abortController.signal,
            ...(await getTenantHeaders({
              apiKey: apiKey ?? "",
              tenantId,
              keycloak
            }))
          }
        );
        setTotalPrice(result);
      } catch (e) {}
    }

    getTotalToBePaid();

    return () => {
      abortController?.abort();
    };
  }, [
    magicId,
    foliosToBePaid,
    shopItems,
    additionalServices,
    isRESTVersion,
    apiKey,
    tenantId,
    keycloak
  ]);

  return isRESTVersion ? totalPrice : transformGrossPriceToFullPrice(data?.GetTotalUnpaidAmount);
};
