import { Button, styled } from "@mui/material";

export const FakeInputBox = styled(Button, {
  shouldForwardProp: (prop) => prop !== "error"
})<{ error?: boolean }>(({ theme, error }) => ({
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  borderBottom: `${error ? 2 : 1}px solid ${
    error ? theme.palette.error.main : theme.palette.common.black
  }`,
  height: theme.spacing(7),
  color: error ? theme.palette.error.main : theme.palette.text.primary,
  padding: theme.spacing(0, 2),
  textAlign: "left",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.09)"
  }
}));
