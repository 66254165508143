import { MagicObject } from "../../domain-common/magic-object";
import {
  CmsSingleDocumentPerPropertyNamespace,
  GuestFlowAndPortalListPerProperty
} from "../../state/cms/cms-single-document-per-property-type";
import { GuestFlowAndPortalList } from "../../state/cms/cms-single-document-types";
import { isReservation } from "../../util/flow";
import { TranslateWrapperNamespace, useTranslateWrapper } from "../../util/i18n-wrapper";

export const usePreloaderForPrismic = (magicObject: MagicObject | null) => {
  let namespacesToBePreload: TranslateWrapperNamespace[] = GuestFlowAndPortalList;

  if (isReservation(magicObject)) {
    const perPropertyNamespaces = GuestFlowAndPortalListPerProperty.map(
      (ns) => `${ns}#${magicObject.propertyId}`
    ) as CmsSingleDocumentPerPropertyNamespace[];
    namespacesToBePreload = [...namespacesToBePreload, ...perPropertyNamespaces];
  }

  useTranslateWrapper({ namespace: namespacesToBePreload });
};
