import { BaseApiClient, JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { PaymentMethodsResponse } from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types";
import { BookingCartTotalPrice } from "../domain-common/booking-cart-total-price";
import {
  BookingCreationDTO,
  CreateBookingPriceRequest,
  CreateBookingRequest
} from "../domain-common/create-booking-payment";
import { PaymentDTO, PaymentServicePaymentPayload } from "../features/payment/payment-dto";
import { ExtendedAdyenResponse } from "../domain-common/adyen-responses";

const BASE_URL = "/api/guest-journey-service/booking-creation";

const waitTimeAfterCreatingNewBooking = 5000;

class BookingCreationApiClient extends BaseApiClient {
  async fetchTotalPrice(data: CreateBookingPriceRequest, init?: RequestInit) {
    const response = await this.fetchApi(BASE_URL.concat("/price"), {
      ...init,
      method: "POST",
      body: JSON.stringify(data)
    });

    return new JSONApiResponse<BookingCartTotalPrice>(response).value();
  }

  async fetchPaymentMethods(
    bookingCreationDTO: BookingCreationDTO,
    init?: RequestInit
  ): Promise<PaymentMethodsResponse> {
    const url = `${BASE_URL}/payment-methods`;

    const response: any = await this.fetchApi(url, {
      ...init,
      method: "POST",
      body: JSON.stringify(bookingCreationDTO)
    });

    return new JSONApiResponse<PaymentMethodsResponse>(response).value();
  }

  async payment(
    data: {
      paymentServicePayload?: PaymentServicePaymentPayload;
      paymentDTO: PaymentDTO;
    },
    init?: RequestInit
  ): Promise<ExtendedAdyenResponse | void> {
    const url = `${BASE_URL}/payment`;

    const response = await this.fetchApi(url, {
      ...init,
      method: "POST",
      body: JSON.stringify(data)
    });

    await new Promise<void>((resolve) =>
      setTimeout(() => resolve(), waitTimeAfterCreatingNewBooking)
    );

    if (response.status === 204) {
      return new VoidApiResponse(response).value();
    }
    return new JSONApiResponse<ExtendedAdyenResponse>(response).value();
  }

  async paymentDetails(
    data: CreateBookingRequest,
    init?: RequestInit
  ): Promise<ExtendedAdyenResponse | void> {
    const url = `${BASE_URL}/payment-details`;

    const response = await this.fetchApi(url, {
      ...init,
      method: "POST",
      body: JSON.stringify(data)
    });

    await new Promise<void>((resolve) =>
      setTimeout(() => resolve(), waitTimeAfterCreatingNewBooking)
    );

    if (response.status === 204) {
      return new VoidApiResponse(response).value();
    }
    return new JSONApiResponse<ExtendedAdyenResponse>(response).value();
  }
  async createBookingWithoutPayment(
    data: BookingCreationDTO,
    init?: RequestInit
  ): Promise<ExtendedAdyenResponse | void> {
    const url = `${BASE_URL}/withoutPayment`;

    const response = await this.fetchApi(url, {
      ...init,
      method: "POST",
      body: JSON.stringify(data)
    });

    await new Promise<void>((resolve) =>
      setTimeout(() => resolve(), waitTimeAfterCreatingNewBooking)
    );

    if (response.status === 204) {
      return new VoidApiResponse(response).value();
    }

    return new JSONApiResponse<ExtendedAdyenResponse>(response).value();
  }
}

export const BookingCreationApi = new BookingCreationApiClient();
