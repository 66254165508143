import React, { FC, useCallback } from "react";
import { Backdrop, Box } from "@mui/material";
import { KeysMobile } from "../keys-mobile";
import { Reservation } from "../../../domain-common/reservation";
import { Paragraph, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { AddIcon, KeyIcon } from "../../../components/icons";
import { useKeysSpeedDialStyles } from "./use-keys-speed-dial-styles";
import { use100vh } from "react-div-100vh";
import { useSelector } from "react-redux";
import { selectKeysError } from "../../portal/portal.slice";
import { isReservationReadyToRequestPrivateKeys } from "../../../util/reservation";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { Fab } from "../../../components/atoms/fab/fab";
import { useCheckIn } from "../../guest-flow/hooks/use-checkin";
import { getOkModalArg } from "../../global-modal/global-modal-util";
import { useIsRequestKeyAvailableWithOpenBalance } from "../../../util/hooks/use-is-request-key-available-with-open-balance";
import { isTravelBuddy } from "../../../util/flow";

let speedDialOpenIcon = <AddIcon sx={{ color: "common.white", transform: "rotate(45deg)" }} />;
let speedDialIcon = <KeyIcon sx={{ color: "common.white" }} />;
export const KeySpeedDialMobile: FC<React.PropsWithChildren<{ reservation: Reservation }>> = ({
  reservation
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const accessWithOpenBalance = useIsRequestKeyAvailableWithOpenBalance({ reservation });

  const error = useSelector(selectKeysError);

  const height100vh = use100vh();
  const { classes } = useKeysSpeedDialStyles({
    error,
    height100vh
  });

  const handleClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  const { open: openModal } = useGlobalModal();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const checkin = useCheckIn({ reservation });

  const handleOpen = useCallback(async () => {
    if (isReservationReadyToRequestPrivateKeys(reservation) && !isOpen) {
      if (!accessWithOpenBalance && !isTravelBuddy(reservation)) {
        await openModal(
          getOkModalArg(
            t("modals__keys_not_yet_available_title"),
            t("modals__keys_not_available_because_open_balance"),
            t("buttons__ok")
          )
        );
        return; //Skip redirection to keys page
      }
      const requestKey = await openModal({
        modalProps: {
          title: t("modals__request_private_key_title"),
          content: <Box>{t("modals__request_private_key_content")}</Box>
        },
        modalActions: [
          {
            variant: "primary",
            label: t("buttons__request_access"),
            result: true
          },
          {
            variant: "secondary",
            label: t("buttons__not_yet"),
            result: false
          }
        ]
      });
      if (requestKey) {
        await checkin();
      }
    }
    if (!isOpen) {
      setIsOpen(true);
    }
  }, [isOpen, checkin, openModal, reservation, t, accessWithOpenBalance]);

  if (!reservation?.accessibleDoors?.length) {
    return null;
  }

  return (
    <>
      <Fab
        className={classes.speedDial}
        sx={{
          borderRadius: 5,
          backgroundColor: "common.black",
          "&:hover": {
            backgroundColor: "common.black"
          },
          width: 64,
          height: 64
        }}
        onClick={isOpen ? handleClose : handleOpen}
      >
        {isOpen ? speedDialOpenIcon : speedDialIcon}
      </Fab>
      {isOpen && ( //Needs to be conditionally render because of key interaction (especially mobile)
        <Backdrop
          open={isOpen}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: "rgba(256,256,256,0.8)"
          }}
        >
          {reservation.accessibleDoors?.length ? (
            <KeysMobile reservation={reservation} door={reservation.accessibleDoors[0]} />
          ) : (
            <Paragraph>Door is not available</Paragraph>
          )}
        </Backdrop>
      )}
    </>
  );
};
