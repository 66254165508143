import * as Types from "../../generated/graphql";

import { TravelBuddyPersonFragmentFragmentDoc } from "./TravelBuddyPersonFragment.generated";
export type TravelBuddyFragmentFragment = {
  __typename: "TravelBuddy";
  pmsPropertyId: string;
  pmsReservationId: string;
  magicId: string;
  magicToken: string;
  actor: Types.Actor;
  pmsBookingId: string;
  bookingMagicId: string;
  bookingOverviewItemId: string;
  status: Types.ReservationStatus;
  arrival: any;
  departure: any;
  checkinTime?: any | null;
  checkoutTime?: any | null;
  reservationId: number;
  reservationMagicId: string;
  unitCleanOnCheckin: boolean;
  created: any;
  accessibleDoors?: Array<{
    __typename?: "AccessibleDoor";
    id?: string | null;
    isGeneral?: boolean | null;
    pin?: string | null;
    privacyMode?: boolean | null;
    privacyModeLastChangeTimestamp?: any | null;
    title?: string | null;
  } | null> | null;
  unit?: {
    __typename?: "Unit";
    pmsId?: string | null;
    name?: string | null;
    state?: Types.UnitState | null;
    floor?: string | null;
  } | null;
  unitGroup?: { __typename?: "UnitGroup"; pmsId?: string | null; capacity?: number | null } | null;
  flowState: {
    __typename?: "FlowState";
    completed: boolean;
    context: any;
    fastCheckinAvailable: boolean;
    notificationPending: boolean;
    notificationSent: boolean;
    performedActions: Array<string>;
    sentNotifications: Array<string>;
    wasOrIsCompleted: boolean;
  };
  person: {
    __typename?: "Person";
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    gender?: Types.Gender | null;
    birthdate?: any | null;
    termsAndConditionsMarketingConsent: boolean;
    termsAndConditionsMarketingConsentDoubleOptin: boolean;
    termsAndConditionsGeneral: boolean;
    termsAndConditionsOnline: boolean;
    preferredCommunicationChannel?: Types.CommunicationChannel | null;
    identificationDocumentNumber?: string | null;
    pmsId: string;
    preferredLanguage?: string | null;
    emergencyEvacuationHelpNeeded: boolean;
    enrolledInLoyaltyProgram: boolean;
    address?: {
      __typename?: "Address";
      addressLine1?: string | null;
      addressLine2?: string | null;
      postalCode?: string | null;
      city?: string | null;
      countryCode?: string | null;
    } | null;
  };
};

export const TravelBuddyFragmentFragmentDoc = `
    fragment TravelBuddyFragment on TravelBuddy {
  __typename
  accessibleDoors {
    id
    isGeneral
    pin
    privacyMode
    privacyModeLastChangeTimestamp
    title
  }
  created: createdAt
  pmsPropertyId
  pmsReservationId
  magicId
  magicToken
  actor
  pmsBookingId
  bookingMagicId
  bookingOverviewItemId
  status
  arrival
  departure
  checkinTime
  checkoutTime
  reservationId
  unit {
    pmsId
    name
    state
    floor
  }
  unitGroup {
    pmsId
    capacity
  }
  flowState {
    completed
    context
    fastCheckinAvailable
    notificationPending
    notificationSent
    performedActions
    sentNotifications
    wasOrIsCompleted
  }
  pmsBookingId
  reservationMagicId
  unitCleanOnCheckin
  person {
    ...TravelBuddyPersonFragment
  }
}
    `;
