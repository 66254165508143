import { makeStyles } from "tss-react/mui";
import { responsiveVariables } from "../../../components/layouts/hooks/use-is-mobile";

export const useStylesForSearchFiltersWrapper = makeStyles()(
  ({ breakpoints, palette, mixins }) => ({
    searchFiltersWrapper: {
      [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
        backgroundColor: palette.background.paper,
        position: "fixed",
        top: mixins.toolbar.minHeight,
        left: 0,
        right: 0,
        zIndex: 1001,
        boxShadow: "0px 0px 13px rgba(60, 60, 60, 0.05)"
      }
    }
  })
);
