import React, { FC, FormEvent, useEffect } from "react";
import { Form, Formik } from "formik";
import { Box, Grid, RadioGroup } from "@mui/material";
import { SubmitButton } from "../../components";
import { PaymentOptionDisplay } from "./payment-option-display";
import { PaymentOption, paymentOptionsNotJustApiCalls } from "./payment-constants";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { PaymentAccount } from "../../domain-common/payment-account";
import { SmallPaperRadio } from "@likemagic-tech/sv-magic-library";

const AutoSubmitFormik: FC<{
  triggerSubmit: boolean;
  handleSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ handleSubmit, triggerSubmit }) => {
  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit();
    }
  }, [handleSubmit, triggerSubmit]);

  return null;
};
export const PaymentSelectComponent: FC<{
  selectedPaymentOption: PaymentOption;
  handlePaymentOption: ({ paymentOption }: { paymentOption: PaymentOption }) => void;
  paymentOptions: PaymentOption[];
  paymentAccount?: PaymentAccount;
}> = ({ selectedPaymentOption, handlePaymentOption, paymentOptions, paymentAccount }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  if (paymentOptions.length === 0) {
    return null;
  }

  return (
    <Box sx={{ px: 2 }}>
      <Formik
        initialValues={{
          paymentOption: PaymentOption[selectedPaymentOption] ?? paymentOptions[0]
        }}
        onSubmit={handlePaymentOption}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <AutoSubmitFormik
              handleSubmit={formik.handleSubmit}
              triggerSubmit={
                selectedPaymentOption &&
                PaymentOption[selectedPaymentOption] &&
                !paymentOptionsNotJustApiCalls.includes(selectedPaymentOption)
              }
            />
            <RadioGroup
              aria-label="paymentOption"
              name="paymentOption"
              value={formik.values.paymentOption}
              onChange={formik.handleChange}
              defaultValue={paymentOptions[0]}
            >
              <Box>
                <Grid container spacing={2}>
                  {paymentOptions?.map((paymentOption) => (
                    <Grid item xs={12} container direction="column" key={paymentOption}>
                      <SmallPaperRadio
                        content={
                          <PaymentOptionDisplay
                            paymentAccount={paymentAccount}
                            paymentOption={paymentOption}
                          />
                        }
                        value={paymentOption}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </RadioGroup>

            <SubmitButton
              label={t("buttons__choose_payment_method")}
              disabled={formik.isSubmitting}
              hasBottomNavigation
              hasWhiteBackground
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};
