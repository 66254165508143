import { useEffect, useState } from "react";
import * as allLocales from "date-fns/locale";

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const useDynamicLocale = () => {
  const [locale, setLocale] = useState(allLocales.enGB);

  useEffect(() => {
    const importLocaleFile = async () => {
      const lcl = navigator.language.split("-")?.[1];
      const langWithLocale = navigator.language
        .split("-")[0]
        .concat(lcl ? capitalizeFirstLetter(lcl) : "");
      try {
        // @ts-ignore
        const localeToSet = allLocales[langWithLocale];
        setLocale(localeToSet);
      } catch (e) {
        console.error("Error", e);
      }
    };

    importLocaleFile();
  }, []);

  return locale;
};

export default useDynamicLocale;
