import React, {
  ChangeEvent,
  FC,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { KeyAssignHandlerProps } from "./key-assign-handler";
import { Box } from "@mui/material";
import {
  AssignKeyContent,
  Heading3,
  useApiVersion,
  useDoorProviderConfig,
  useKeyAssign
} from "@likemagic-tech/sv-magic-library";
import { SubmitButton } from "../../components";
import { usePageVisibility } from "../../util/hooks/use-page-visibility";
import { WebSocketContext } from "../web-socket/web-socket-provider";
import { closeBanner, openBanner } from "../banner/banner.slice";
import { hideRootSuspense, showRootSuspense } from "../loaders/loader.slice";
import { useAppDispatch } from "../../state/store";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import {
  assignTagStatusWithWSFailed,
  assignTagStatusWithWSLoading,
  assignTagStatusWithWSSucceeded,
  selectAssignKeyWithWSStatus
} from "./key-assign.slice";
import { useSelector } from "react-redux";
import {
  EntityStateStatus,
  isStatusIdle,
  mapQueryStatusToEntityStateStatus
} from "../../state/EntityStateStatus";
import { useEncodeKeyMutation } from "../../graphql/mutations/encode-key.generated";
import { errorHandleForComponentLazyLoad } from "../../util/code-split.util";

const AssignKeyAnimation = lazy(() => {
  return import("../guest-flow/components/assign-key-animation")
    .then(({ AssignKeyAnimation }) => ({
      default: AssignKeyAnimation
    }))
    .catch(errorHandleForComponentLazyLoad);
});
const AssignKeyAnimationRunning = lazy(() =>
  import("../guest-flow/components/assign-key-animation")
    .then(({ AssignKeyAnimationRunning }) => ({
      default: AssignKeyAnimationRunning
    }))
    .catch(errorHandleForComponentLazyLoad)
);
const useStyles = makeStyles()((theme: Theme) => ({
  text: {
    textAlign: "center"
  },
  logo: {
    maxWidth: theme.spacing(25),
    height: theme.spacing(8.75)
  }
}));
export const KeyAssignWs: FC<React.PropsWithChildren<KeyAssignHandlerProps>> = ({
  encoderId,
  reservation,
  successComponent,
  logout
}) => {
  const { isPageVisible } = usePageVisibility();
  const { ws: magicWs } = useContext(WebSocketContext);
  const [cardNumber, setCardNumber] = useState("");
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const config = useDoorProviderConfig(reservation.propertyId);
  const assigningKeyStatus = useSelector(selectAssignKeyWithWSStatus);
  const { isRESTVersion } = useApiVersion();
  const [encodeKeyV2, { status: statusEncodeKeyV2 }] = useEncodeKeyMutation();

  const onAssignedKey = useCallback(() => {
    dispatch(assignTagStatusWithWSSucceeded());
    magicWs.current?.close(1000);
  }, [dispatch, magicWs]);

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const {
    initializeKey,
    assignCardCallback,
    isKeyAssigned,
    isConnecting,
    isWaiting,
    isError,
    tagNumber,
    onAssignKeySuccess,
    onAssignKeyError
  } = useKeyAssign({
    reservation,
    tagReaderId: encoderId,
    closeBanner: () => dispatch(closeBanner()),
    openBanner: (payload) => dispatch(openBanner(payload)),
    timeoutError: t("validation_assign_key_timeout_error"),
    isPageVisible,
    onAssignedKey
  });

  const labels = useMemo(
    () => ({
      unit: t("labels__unit"),
      tag: t("labels__tag"),
      cardNumber: t("labels__card_number"),
      cardNumberDescription: t("labels__card_number_description")
    }),
    [t]
  );

  useEffect(() => {
    !isRESTVersion &&
      tagNumber &&
      encodeKeyV2({
        magicId: reservation.magicId,
        magicToken: reservation.magicToken,
        encoderId: `${tagNumber}`
      })
        .then(onAssignKeySuccess)
        .catch(onAssignKeyError);
  }, [
    isRESTVersion,
    tagNumber,
    encodeKeyV2,
    reservation.id,
    onAssignKeySuccess,
    onAssignKeyError,
    reservation.magicId,
    reservation.magicToken,
    encoderId
  ]);

  useEffect(() => {
    if (isStatusIdle(assigningKeyStatus)) {
      dispatch(assignTagStatusWithWSLoading());
      initializeKey();
    }
  }, [initializeKey, assigningKeyStatus, dispatch]);

  useEffect(() => {
    if (isError) {
      dispatch(assignTagStatusWithWSFailed());
    }
  }, [isError, dispatch]);

  const assignCardFallback = useCallback(async () => {
    try {
      dispatch(showRootSuspense());
      if (isRESTVersion) {
        await assignCardCallback(reservation, cardNumber);
      } else {
        await encodeKeyV2({
          magicId: reservation.magicId,
          magicToken: reservation.magicToken,
          encoderId: `${cardNumber}`
        })
          .then(onAssignKeySuccess)
          .catch(onAssignKeyError);
      }
      dispatch(assignTagStatusWithWSSucceeded());
      dispatch(closeBanner());
      dispatch(hideRootSuspense());
    } catch (e) {
      dispatch(hideRootSuspense());
      dispatch(assignTagStatusWithWSFailed());
      dispatch(
        openBanner({
          type: "error",
          title: t("validation__card_assign_error")
        })
      );
    }
  }, [
    reservation,
    cardNumber,
    assignCardCallback,
    dispatch,
    t,
    isRESTVersion,
    encodeKeyV2,
    onAssignKeyError,
    onAssignKeySuccess
  ]);

  const assignV2Status = useMemo(() => {
    return mapQueryStatusToEntityStateStatus(statusEncodeKeyV2);
  }, [statusEncodeKeyV2]);

  return (
    <>
      {(isConnecting || isWaiting) && (
        <Box className={classes.text} pt={7} mx={6}>
          <Heading3>
            {isConnecting && t("labels__assign_key_prepare")}
            {isWaiting && t("labels__assign_key_instruction")}
          </Heading3>
        </Box>
      )}
      <Box pt={7} px={3}>
        <AssignKeyContent
          isKeyAssigned={isKeyAssigned && !isError && assignV2Status !== EntityStateStatus.FAILED}
          isConnecting={isConnecting}
          isWaiting={isWaiting}
          unitName={reservation?.unit?.name ?? ""}
          labels={labels}
          assignKeyAnimation={<AssignKeyAnimation />}
          assignKeyAnimationRunning={
            <AssignKeyAnimationRunning doorProvider={config?.doorProvider} />
          }
          isError={isError || assignV2Status === EntityStateStatus.FAILED}
          cardNumberValue={cardNumber}
          onChangeCardNumber={(e: ChangeEvent<HTMLInputElement>) => {
            setCardNumber(e.target.value);
          }}
        />
      </Box>
      {isKeyAssigned && !isError && assignV2Status !== EntityStateStatus.FAILED && successComponent}
      {(isError || assignV2Status === EntityStateStatus.FAILED) && (
        <SubmitButton
          label={t("buttons__assign_card")}
          onClick={assignCardFallback}
          hasWhiteBackground
        />
      )}

      {isKeyAssigned && (
        <SubmitButton label={t("buttons__logout")} onClick={logout} hasWhiteBackground />
      )}
    </>
  );
};
