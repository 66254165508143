import { useEffect } from "react";
import { useAppDispatch } from "../../../state/store";
import { useSelector } from "react-redux";
import {
  fetchSelfPouringStationAvailability,
  selectSelfPouringStationShopAvailability
} from "../self-pouring-station-shop.slice";

export const useFetchSelfPouringStationAvailability = ({
  magicId,
  magicToken
}: {
  magicId: string;
  magicToken: string;
}) => {
  const dispatch = useAppDispatch();
  const boxAvailabilities = useSelector(selectSelfPouringStationShopAvailability);

  useEffect(() => {
    dispatch(
      fetchSelfPouringStationAvailability({
        magicId: magicId,
        magicToken: magicToken
      })
    );
  }, [dispatch, magicId, magicToken]);

  return boxAvailabilities;
};
