import React, { FC, useCallback, useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import ContentCopy from "@mui/icons-material/ContentCopy";

interface CopyToClipboardProps {
  textToCopy: string;
  tooltipText: string;
}

export const CopyToClipboard: FC<React.PropsWithChildren<CopyToClipboardProps>> = ({
  textToCopy,
  tooltipText,
  children
}) => {
  const [open, setOpen] = useState(false);

  const openTooltip = useCallback(() => {
    setOpen(true);
    setTimeout(() => setOpen(false), 800);
  }, []);
  const copyToClipboardCallback = useCallback(async () => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(textToCopy);
      openTooltip();
    } else {
      console.warn("Clipboard is only available for https urls.");
    }
  }, [textToCopy, openTooltip]);

  return (
    <Tooltip title={tooltipText} open={open}>
      <Grid
        container
        direction="row"
        sx={{ cursor: "pointer" }}
        onClick={copyToClipboardCallback}
        justifyContent="center"
        alignItems="center"
      >
        <Grid mr={1}>{children}</Grid>
        <ContentCopy />
      </Grid>
    </Tooltip>
  );
};
