import { openDoor } from "../portal/portal.slice";
import { useAppDispatch } from "../../state/store";
import { Reservation } from "../../domain-common/reservation";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { api as openDoorApi } from "../../graphql/mutations/open-door.generated";

interface OpenDoorProps {
  doorId: string;
  reservation: Reservation;
}
export const useOpenDoor = () => {
  const { isRESTVersion } = useApiVersion();
  const dispatch = useAppDispatch();

  return ({ doorId, reservation }: OpenDoorProps) =>
    isRESTVersion
      ? dispatch(openDoor({ doorId, reservation })).unwrap()
      : dispatch(
          openDoorApi.endpoints.OpenDoor.initiate({
            magicId: reservation.magicId,
            magicToken: reservation.magicToken,
            doorId
          })
        ).unwrap();
};
