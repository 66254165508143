export enum ConfirmationType {
  OPEN_BALANCE = "OPEN_BALANCE",
  OPEN_BALANCE_WITH_CHECKOUT = "OPEN_BALANCE_WITH_CHECKOUT",
  ADDITIONAL_SERVICES = "ADDITIONAL_SERVICES",
  SHOP = "SHOP",
  EXTEND_STAY = "EXTEND_STAY",
  BOX_SHOP = "BOX_SHOP",
  SELF_POURING_STATION_SHOP = "SELF_POURING_STATION_SHOP"
}
export const CONFIRMATION_TYPE_KEY = "confirmationType";
export const parseConfirmationTypeFromQueryParam = (searchUrlString: string) => {
  const params = new URLSearchParams(searchUrlString);
  return params.get(CONFIRMATION_TYPE_KEY) as ConfirmationType;
};
