import React, { FC } from "react";
import { Paragraph } from "@likemagic-tech/sv-magic-library";

interface TitleProps {
  title?: string;
}

export const Title: FC<TitleProps> = ({ title }) => (
  <Paragraph
    align="center"
    sx={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      mb: 0.5
    }}
  >
    {title}
  </Paragraph>
);
