import { Theme, ThemeOptions } from "@mui/material";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

export const overrideComponents: (themeName: string) => ThemeOptions = () => {
  return {
    components: {
      MuiTab: {
        styleOverrides: {
          root: ({ theme }) => {
            return {
              fontSize: 16,
              textTransform: "capitalize",
              color: theme.palette.common.black,
              "&.Mui-selected": {
                color: theme.palette.common.black,
                fontWeight: 700
              },
              [theme.breakpoints.up("md")]: {
                paddingInline: theme.spacing(2.5)
              }
            };
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: ({ theme }) => {
            return {
              backgroundColor: theme.palette.common.black
            };
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            border: "none",
            height: 1
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: { root: { backgroundColor: "rgba(0, 0, 0, 0.6)" } }
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            marginBottom: 20,
            padding: 16
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius * 2.5,
            boxShadow: "0px 4px 16px rgba(197, 185, 172, 0.06)",
            // Ios problem fix
            // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
            position: "relative",
            zIndex: 1
          })
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: ({ theme }) => ({
            margin: 0,
            borderRadius: theme.shape.borderRadius * 2.5,
            boxShadow: "0px 4px 16px rgba(197, 185, 172, 0.06)"
          })
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 20
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 20
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.common.black
          })
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: ({ theme }) => ({
            "&:last-of-type": {
              borderBottomLeftRadius: theme.shape.borderRadius * 2.5,
              borderBottomRightRadius: theme.shape.borderRadius * 2.5
            },
            "&:first-of-type": {
              borderTopLeftRadius: theme.shape.borderRadius * 2.5,
              borderTopRightRadius: theme.shape.borderRadius * 2.5
            }
          })
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: ({ theme }) => ({
            color: theme.palette.text.primary
          }),
          outlined: {
            border: "none!important"
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            "&:last-child": {
              padding: 16
            }
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius * 2
          }),
          standardSuccess: ({ theme }) => ({
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.main
          }),
          standardError: ({ theme }) => ({
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main
          }),
          standardWarning: {
            backgroundColor: "orange",
            color: "white"
          },
          standardInfo: ({ theme }) => ({
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.warning.main
          })
        }
      }
    }
  };
};
