export enum PaymentMethodType {
  DISCOVER = "DISCOVER",
  DINERS = "DINERS",
  MAESTRO = "MAESTRO",
  VISA = "VISA",
  MC = "MC",
  AMEX = "AMEX",
  UNKNOWN = "UNKNOWN"
}

export interface PaymentAccount {
  expiryYear: string;
  expiryMonth: string;
  accountNumber: string;
  paymentMethod: PaymentMethodType;
  accountHolder: string;
}

export const PaymentAccountFromJSON = (json: any): PaymentAccount => {
  if (!json) {
    return json;
  }

  return {
    expiryYear: json["expiryYear"],
    expiryMonth: json["expiryMonth"],
    accountNumber: json["accountNumber"],
    paymentMethod: json["paymentMethod"],
    accountHolder: json["accountHolder"]
  };
};
