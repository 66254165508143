import { FC, ReactElement } from "react";
import { DoorProvider, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";
import { Reservation } from "../../domain-common/reservation";
import { KeyAssignWs } from "./key-assign-ws";
import { KeyAssignApi } from "./key-assign-api";

export interface KeyAssignHandlerProps {
  reservation: Reservation;
  encoderId: string;
  successComponent: ReactElement;
  logout: () => void;
}
export const KeyAssignHandler: FC<React.PropsWithChildren<KeyAssignHandlerProps>> = (props) => {
  const config = useDoorProviderConfig(props.reservation.propertyId);

  //At the moment just DoorProvider.SALTO uses WS approach
  return config?.doorProvider === DoorProvider.SALTO ? (
    <KeyAssignWs {...props} />
  ) : (
    <KeyAssignApi {...props} />
  );
};
