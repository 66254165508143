import { FC, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { useAppDispatch } from "../../state/store";
import { updateReservationLanguage } from "../../features/reservation-provider/reservation.slice";
import { Actor } from "../../domain-common/booking-overview";
import { useAllowedLocales } from "../../util/hooks/use-configuration";
import { getI18nSelectedLanguage } from "../../util/lang-utils";
import i18n from "../../config/i18n/i18n-next-config";
import { WrappedSelect } from "../atoms/input/wrapped-select";

export const LanguageSelector: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const allowedLocales = useAllowedLocales();
  const dispatch = useAppDispatch();
  const langOptions = useMemo(
    () =>
      allowedLocales?.map((lang) => ({
        value: lang,
        label: t(`labels__language_${lang}`)
      })) || [],
    [t, allowedLocales]
  );

  const onLangChange = useCallback(
    async (newLang: string) => {
      i18n.changeLanguage(newLang.toLowerCase());
      dispatch(
        updateReservationLanguage({
          newLang,
          actor: Actor.PRIMARY_GUEST
        })
      );
    },
    [dispatch]
  );

  return (
    <Box p={1}>
      <WrappedSelect
        variant="standard"
        onChange={(e) => onLangChange(e.target.value as string)}
        value={getI18nSelectedLanguage()}
        options={langOptions}
        label={t("labels__select_language")}
      />
    </Box>
  );
};
