import { MagicObject, MagicObjectFromJSON } from "./magic-object";
import { GuestFlowContext } from "./guest-flow-checkpoint-and-args";
import { HasFlowState } from "./flow-state";
import { ReservationExtras, ReservationExtrasFromJSON } from "./reservation-extras";
import { Person, PersonFromJSON } from "./person";
import { ReservationStatus } from "./reservation-status";
import { Unit } from "./unit";
import { Reservation } from "./reservation";

export interface TravelBuddy extends MagicObject<GuestFlowContext>, HasFlowState<GuestFlowContext> {
  reservationId: string;
  travelBuddyIndex: number;
  propertyId: string;
  currency: string;
  status: ReservationStatus;
  arrival?: string;
  departure?: string;
  travelBuddy: Person;
  unit?: Unit;
  unitGroup: {
    id: string;
    maxPersons: number;
  };
  extras: ReservationExtras | null;
}

export const TravelBuddyFromJSON = (json: any): TravelBuddy => {
  if (!json) {
    return json;
  }

  const magicObject = MagicObjectFromJSON<GuestFlowContext, Reservation>(json);

  return {
    ...magicObject,
    reservationId: json["reservationId"],
    travelBuddyIndex: json["travelBuddyIndex"],
    propertyId: json["propertyId"],
    currency: json["currency"],
    status: json["status"],
    arrival: json["arrival"],
    departure: json["departure"],
    travelBuddy: PersonFromJSON(json["travelBuddy"]),
    unit: json["unit"],
    unitGroup: json["unitGroup"],
    extras: ReservationExtrasFromJSON(json["extras"])
  };
};
