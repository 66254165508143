import { useContext, useMemo } from "react";
import { PmsType, TenantContext } from "@likemagic-tech/sv-magic-library";

export const usePms = () => {
  const { pms } = useContext(TenantContext);

  const isEndOfDayPms = useMemo(() => pms === PmsType.OHIP, [pms]);

  return { pms, isEndOfDayPms };
};
