import { Helmet } from "react-helmet-async";
import { generateInitSearchBookingUrl } from "../search-for-bookings/search-booking-navigation";
import { generateInitBookingOverviewUrl } from "../booking-overview/booking-overview-navigation";
import { generatePortalMyStayUrl, generatePortalProfileUrl } from "../../util/routing";
import React from "react";
import { useLocation } from "react-router-dom";

export type HelmetTitleProps = {
  suffix: string;
};

const bottomNavUrls = {
  [generateInitSearchBookingUrl()]: "Search",
  [generateInitBookingOverviewUrl(":magicId")]: "Booking",
  [generatePortalMyStayUrl(":magicId")]: "My Stay",
  [generatePortalProfileUrl(":magicId")]: "Profile"
};

export const HelmetTitle: React.FC<React.PropsWithChildren<HelmetTitleProps>> = ({ suffix }) => {
  const { pathname: path } = useLocation();
  const displayPrefixUrl = Object.keys(bottomNavUrls).find((value) => path.indexOf(value) > -1);
  return (
    // @ts-ignore
    <Helmet>
      <title>
        {displayPrefixUrl ? bottomNavUrls[displayPrefixUrl].concat(" - ") : ""}
        {suffix}
      </title>
    </Helmet>
  );
};
