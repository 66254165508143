import { useEffect } from "react";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import { useAppDispatch } from "../../../state/store";
import {
  fetchUserInfo,
  selectKeycloakSliceStatus,
  selectKeycloakSliceUserInfo
} from "../../../slices/keycloak.slice";
import { useSelector } from "react-redux";

export const useUserInfo = () => {
  const { getIsAuthenticated, loadUserInfo } = useAuth();
  const dispatch = useAppDispatch();

  const userInfo = useSelector(selectKeycloakSliceUserInfo);
  const fetchUserInfoLoaded = useSelector(selectKeycloakSliceStatus);
  const loaded = getIsAuthenticated() ? fetchUserInfoLoaded : true;

  useEffect(() => {
    if (getIsAuthenticated()) {
      dispatch(fetchUserInfo({ loadUserInfo }));
    }
  }, [dispatch, getIsAuthenticated, loadUserInfo]);

  return {
    userInfo,
    loaded
  };
};
