export enum DynamicShopFlowPages {
  DYNAMIC_SHOP = "DYNAMIC_SHOP",
  DYNAMIC_SHOP_BILL_OVERVIEW = "DYNAMIC_SHOP_BILL_OVERVIEW",
  DYNAMIC_SHOP_PAYMENT = "DYNAMIC_SHOP_DYNAMIC_SHOP_PAYMENT",
  DYNAMIC_SHOP_CONFIRMATION = "DYNAMIC_SHOP_CONFIRMATION"
}

export const dynamicShopFlowList: Array<DynamicShopFlowPages> = [
  DynamicShopFlowPages.DYNAMIC_SHOP,
  DynamicShopFlowPages.DYNAMIC_SHOP_BILL_OVERVIEW,
  DynamicShopFlowPages.DYNAMIC_SHOP_PAYMENT,
  DynamicShopFlowPages.DYNAMIC_SHOP_CONFIRMATION
];

export const getDynamicShopFlowProgress = (page: DynamicShopFlowPages) => {
  return (
    ((dynamicShopFlowList.findIndex((item) => item === page) + 1) * 100) /
    dynamicShopFlowList.length
  );
};
