export interface BaseEntity {
  createdAt: string;
  updatedAt: string;
}

export const BaseEntityFromJSON = (json: any): BaseEntity => {
  if (!json) {
    return json;
  }

  return {
    createdAt: json["createdAt"],
    updatedAt: json["updatedAt"]
  };
};
