import { ServiceGroup } from "../../domain-common/service";

export function mapCmsLabelsToServiceGroups(
  availableFilters: ServiceGroup[],
  cmsServiceGroups?: Array<{ service_group_name: string; label: string }>
) {
  if (!cmsServiceGroups) {
    return availableFilters.map((af) => ({
      id: af.id?.toString() ?? "",
      name: af.name
    }));
  }

  return availableFilters.map((af) => {
    const translated = cmsServiceGroups.find((csg: any) => csg["service_group_name"] === af.name)
      ?.label;

    return {
      id: af.id?.toString() ?? "",
      name: translated ?? af.name
    };
  });
}
