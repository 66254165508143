import { createSelector, createSlice, Selector } from "@reduxjs/toolkit";
import { RootState } from "../../state/store";

type CookieState = {
  isCookiePopupOpen: boolean;
};

const initialState: CookieState = {
  isCookiePopupOpen: false
};

export const cookieSlice = createSlice({
  name: "cookies",
  initialState,
  reducers: {
    toggleCookiesPopup: (state) => {
      state.isCookiePopupOpen = !state.isCookiePopupOpen;
    }
  }
});

export const { toggleCookiesPopup } = cookieSlice.actions;
export const { reducer: cookieSliceReducer } = cookieSlice;

const selectSelf: Selector<RootState, CookieState> = (state: RootState) => state[cookieSlice.name];

export const selectIsDialogOpen = createSelector(selectSelf, (state) => state.isCookiePopupOpen);
