import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function KeyOtherIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>{`.a{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}`}</style>
      </defs>
      <title>login-keys</title>
      <path
        className="a"
        d="M12.75,9.807a6,6,0,1,0-7.5,5.811v6.136a1.5,1.5,0,0,0,3,0V15.618A6,6,0,0,0,12.75,9.807Z"
      />
      <path
        className="a"
        d="M13.563,15.75a5.972,5.972,0,0,0,2.219-.773l4.907,4.906a1.5,1.5,0,0,0,2.121-2.121L17.9,12.856A6,6,0,0,0,9.741,4.605"
      />
      <circle className="a" cx="6.75" cy="9.057" r="1.5" />
      <path className="a" d="M6.75,7.557V3a2.25,2.25,0,0,1,4.5,0v.989" />
    </SvgIcon>
  );
}
