import React, { FC, useCallback, useEffect, useRef } from "react";
import { useAppDispatch } from "../../state/store";
import { useSelector } from "react-redux";
import { selectCurrentBoxStatus, setCurrentBoxStatus } from "./box-shop.slice";
import { BoxEvent, BoxOpenMode, initFetchEventSource } from "./box-shop-events";
import { useReservationContext } from "../reservation-provider/reservation-provider";
import { useCommonErrorsMapperCmsData } from "../../state/common-cms/use-common-cms-data";
import { getErrorEntry } from "../../state/common-cms/use-common-error-translation";
import { closeBanner, openBanner } from "../banner/banner.slice";
import { Box } from "@mui/material";
import { BoxStatusIdle } from "./components/box-status-idle";
import { BoxStatusSuccessfullyClosed } from "./components/box-status-succesfully-closed";
import { BoxStatusSuccessfullyOpened } from "./components/box-status-successfully-opened";
import { PageHeadingInfoProps } from "../../components/molecules/page-heading-info";
import { useServicesCmsData } from "../services/use-services-cms-data";
import { BoxAvailability } from "../../domain-v1/box-availability";

interface BoxShopEventHandlerProps {
  boxDetails: BoxAvailability;
  mode: BoxOpenMode;
  idleInfos: PageHeadingInfoProps;
  successOpenedInfos: PageHeadingInfoProps;
  successClosedInfos: { text: string };
}

export const BoxShopEventHandler: FC<BoxShopEventHandlerProps> = ({
  boxDetails,
  mode,
  idleInfos,
  successClosedInfos,
  successOpenedInfos
}) => {
  const dispatch = useAppDispatch();

  const boxDoorStatus = useSelector(selectCurrentBoxStatus);
  const setBoxDoorStatus = useCallback(
    (event: BoxEvent) => {
      dispatch(setCurrentBoxStatus(event));
    },
    [dispatch]
  );
  const { reservation } = useReservationContext();

  const { serviceIcons: productIconsByServiceId, fallbackIcon: productFallbackIcon } =
    useServicesCmsData(reservation.propertyId);
  const abortSSESignal = useRef<AbortController | undefined>(undefined);
  const refKeySlider = useRef<any | undefined>(undefined);

  const cmsErrorData = useCommonErrorsMapperCmsData();

  const openSSE = useCallback(
    async (onSSEInit: () => void, handleError: () => void) => {
      setBoxDoorStatus(BoxEvent.INIT);
      abortSSESignal.current = new AbortController();
      await initFetchEventSource(
        mode,
        {
          magicId: reservation.magicId,
          boxId: boxDetails.boxId
        },
        setBoxDoorStatus,
        abortSSESignal.current,
        onSSEInit,
        handleError
      );
    },
    [reservation.magicId, setBoxDoorStatus, mode, boxDetails.boxId]
  );

  useEffect(
    () => () => {
      //clean up
      abortSSESignal.current?.abort();
      setBoxDoorStatus(BoxEvent.INIT);
    },
    [setBoxDoorStatus]
  );

  useEffect(() => {
    switch (boxDoorStatus) {
      case BoxEvent.BOX_NOT_CLOSED:
        const prismicNotClosedEntry = getErrorEntry(cmsErrorData, "errors__box_door_not_closed");

        dispatch(
          openBanner({
            type: "error",
            title: prismicNotClosedEntry?.text || cmsErrorData?.["errors__default_label"]
          })
        );
        break;
      case BoxEvent.BOX_NOT_OPENED:
        abortSSESignal.current?.abort();
        refKeySlider.current?.reset();
        const prismicNotOpenedEntry = getErrorEntry(cmsErrorData, "errors__box_door_not_opened");

        dispatch(
          openBanner({
            type: "error",
            title: prismicNotOpenedEntry?.text || cmsErrorData?.["errors__default_label"]
          })
        );
        break;
      case BoxEvent.BOX_CLOSED:
      case BoxEvent.BOX_OPENED:
        dispatch(closeBanner());
    }
  }, [dispatch, boxDoorStatus, cmsErrorData, setBoxDoorStatus]);

  return (
    <Box maxWidth={822} margin="auto">
      {[BoxEvent.INIT, BoxEvent.BOX_NOT_OPENED].includes(boxDoorStatus) && (
        <BoxStatusIdle
          refKeySlider={refKeySlider}
          magicId={reservation.magicId}
          openSSE={openSSE}
          title={idleInfos.title}
          icon={idleInfos.icon}
          boxDetails={boxDetails}
          productIcon={productIconsByServiceId[boxDetails.serviceId] ?? productFallbackIcon}
        />
      )}
      {boxDoorStatus === BoxEvent.BOX_CLOSED && (
        <BoxStatusSuccessfullyClosed text={successClosedInfos.text} />
      )}
      {[BoxEvent.BOX_OPENED, BoxEvent.BOX_NOT_CLOSED].includes(
        // @ts-ignore
        boxDoorStatus
      ) && <BoxStatusSuccessfullyOpened {...successOpenedInfos} />}
    </Box>
  );
};
