import { useCallback, useMemo } from "react";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";
import { useLocation, useNavigate } from "react-router";
import { add } from "date-fns";
import { useConfigBasedOnlyOnBaseUrl } from "../../../util/hooks/use-configuration";
import { formatToIsoDate, parseIsoDateString } from "@likemagic-tech/sv-magic-library";

export enum SearchOfferParamsEnum {
  ARRIVAL = "ARRIVAL",
  DEPARTURE = "DEPARTURE",
  PROMO_CODE = "PROMO_CODE",
  MAGIC_PROMO_CODE = "MAGIC_PROMO_CODE",
  PROPERTY_IDS = "PROPERTY_IDS",
  /**
   * @deprecated  //Backwards compatibility
   */
  PROPERTY_ID = "PROPERTY_ID",
  ADULTS = "ADULTS",
  CHILDREN = "CHILDREN",
  UNIT_GROUP_ID = "UNIT_GROUP_ID"
}

export interface SearchOfferParamsDTO {
  arrival: string;
  departure: string;
  adults: number;
  childrenAges: number[];
  propertyIds?: string; // List with , delimiter
  promoCodePMS?: string;
  promoCodeMagic?: string;
  language?: string;
  unitGroupId?: string;
}

export const generateAnyURLSearchParams = (data: any) => {
  const newParam = new URLSearchParams();
  Object.keys(data).forEach((key) => {
    if (key && data[key]) {
      newParam.set(key, data[key]);
    }
  });
  return newParam;
};

export const generateURLSearchParams = (newSearchOfferParamsDTO: SearchOfferParamsDTO) => {
  const newParam = new URLSearchParams();
  if (newSearchOfferParamsDTO.promoCodePMS) {
    newParam.set(SearchOfferParamsEnum.PROMO_CODE, newSearchOfferParamsDTO.promoCodePMS);
  }
  if (newSearchOfferParamsDTO.promoCodeMagic) {
    newParam.set(SearchOfferParamsEnum.MAGIC_PROMO_CODE, newSearchOfferParamsDTO.promoCodeMagic);
  }
  if (newSearchOfferParamsDTO.adults) {
    newParam.set(SearchOfferParamsEnum.ADULTS, newSearchOfferParamsDTO.adults.toString());
  }
  if (newSearchOfferParamsDTO.childrenAges) {
    newParam.set(SearchOfferParamsEnum.CHILDREN, newSearchOfferParamsDTO.childrenAges.join(","));
  }
  if (newSearchOfferParamsDTO.arrival) {
    newParam.set(SearchOfferParamsEnum.ARRIVAL, newSearchOfferParamsDTO.arrival);
  }
  if (newSearchOfferParamsDTO.departure) {
    newParam.set(SearchOfferParamsEnum.DEPARTURE, newSearchOfferParamsDTO.departure);
  }
  if (newSearchOfferParamsDTO.propertyIds) {
    newParam.set(SearchOfferParamsEnum.PROPERTY_IDS, newSearchOfferParamsDTO.propertyIds);
  }
  if (newSearchOfferParamsDTO.unitGroupId) {
    newParam.set(SearchOfferParamsEnum.UNIT_GROUP_ID, newSearchOfferParamsDTO.unitGroupId);
  }

  return newParam;
};

export const useSearchParams = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const language = getI18nSelectedLanguage();
  const { search: searchConfig } = useConfigBasedOnlyOnBaseUrl();

  const searchOfferParamsDTO: SearchOfferParamsDTO = useMemo(() => {
    const arrivalDate = add(new Date(), {
      days: searchConfig?.defaultArrivalDateDelta ?? 0
    });
    const searchParamState = new URLSearchParams(search);
    const arrival =
      searchParamState.get(SearchOfferParamsEnum.ARRIVAL) || formatToIsoDate(arrivalDate);

    const adultString = searchParamState.get(SearchOfferParamsEnum.ADULTS);
    const childrenString = searchParamState.get(SearchOfferParamsEnum.CHILDREN);

    return {
      promoCodePMS: searchParamState.get(SearchOfferParamsEnum.PROMO_CODE) || undefined,
      arrival,
      promoCodeMagic: searchParamState.get(SearchOfferParamsEnum.MAGIC_PROMO_CODE) || undefined,
      departure:
        searchParamState.get(SearchOfferParamsEnum.DEPARTURE) ||
        formatToIsoDate(
          add(arrival ? parseIsoDateString(arrival) : arrivalDate, {
            days: searchConfig?.defaultStayLength ?? 1
          })
        ),
      adults: adultString ? parseInt(adultString) : searchConfig?.defaultAdults || 1,
      childrenAges:
        childrenString === ""
          ? []
          : childrenString
            ? childrenString
                .replace(/,\s*$/, "")
                .split(",")
                .map((value) => parseInt(value))
            : searchConfig?.defaultChildrenAges || [],

      propertyIds:
        searchParamState.get(SearchOfferParamsEnum.PROPERTY_IDS) ||
        searchParamState.get(SearchOfferParamsEnum.PROPERTY_ID) ||
        undefined,
      language: language,
      unitGroupId: searchParamState.get(SearchOfferParamsEnum.UNIT_GROUP_ID) || undefined
    };
  }, [search, language, searchConfig]);

  const setSearchOfferParamsDTO = useCallback(
    (newSearchOfferParamsDTO: SearchOfferParamsDTO) => {
      const newSearchParams = generateURLSearchParams(newSearchOfferParamsDTO);
      if (search !== "?".concat(newSearchParams.toString())) {
        const newSearch = { search: newSearchParams.toString() };
        navigate(newSearch);
      }
    },
    [navigate, search]
  );

  const allRequiredFiltersAreAvailable = useMemo(
    () =>
      searchOfferParamsDTO.arrival &&
      searchOfferParamsDTO.departure &&
      searchOfferParamsDTO.adults &&
      searchOfferParamsDTO.childrenAges &&
      searchOfferParamsDTO.propertyIds,
    [searchOfferParamsDTO]
  );

  return {
    searchOfferParamsDTO,
    setSearchOfferParamsDTO,
    allRequiredFiltersAreAvailable
  };
};
