import { Debitor, DebitorFromJSON } from "./debitor";
import { Price } from "./price";
import { Company } from "./company";
import { FullPrice } from "./full-price";

export interface Folio {
  id: string;
  prepaymentType: PrepaymentType;
  hasPendingPaymentLink?: boolean;
  debitor: Debitor;
  isMainFolio: boolean;
  number?: string;
  magicId: string;
  totalPayments: Price;
  totalCharges: FullPrice;
  company: Company;
}

export enum PrepaymentType {
  CHECKOUT_ON_AR = "CHECKOUT_ON_AR",
  CHECKOUT_ON_AR_CUSTOMER = "CHECKOUT_ON_AR_CUSTOMER",
  EXPEDIA = "EXPEDIA",
  AIRBNB = "AIRBNB",
  PREPAID = "PREPAID",
  AGODA_VCC = "AGODA_VCC",
  BOOKING_DOT_COM = "BOOKING_DOT_COM",
  BOOKING_DOT_COM_VCC_PREPAYMENT = "BOOKING_DOT_COM_VCC_PREPAYMENT",
  BOOKING_DOT_COM_VCC_CREDIT_CARD = "BOOKING_DOT_COM_VCC_CREDIT_CARD",
  BOOKING_DOT_COM_BANK_TRANSFER_PREPAYMENT = "BOOKING_DOT_COM_BANK_TRANSFER_PREPAYMENT",
  NOT_DETERMINABLE = "NOT_DETERMINABLE",
  CTRIP = "CTRIP",
  GENERIC_OTA = "GENERIC_OTA",
  HOTELBEDS = "HOTELBEDS",
  NONE = "NONE"
}

export const FolioFromJSON = (json: any): Folio => {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    prepaymentType: json["prepaymentType"],
    hasPendingPaymentLink: json["hasPendingPaymentLink"],
    debitor: DebitorFromJSON(json["debitor"]),
    isMainFolio: json["isMainFolio"],
    magicId: json["magicId"],
    totalPayments: json["totalPayments"],
    totalCharges: json["totalCharges"],
    company: json["company"],
    number: json["number"]
  };
};
