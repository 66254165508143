import { TravelBuddy } from "../../domain-common/travel-buddy";
import { GetMagicObjectQuery } from "../queries/GetMagicObject.generated";
import {
  transformActorV2ToCommon,
  transformPersonV2ToCommonPerson,
  transformStatusV2ToCommon
} from "./transform-utils";
import { MagicType } from "../../domain-common/magic-object";
import { unitStateV2ToUnitCondition } from "./transform-reservation";

type MagicObjectInputV2 = GetMagicObjectQuery["GetMagicObject"];
type TravelBuddyInputV2 = Extract<MagicObjectInputV2, { __typename: "TravelBuddy" }>;
export const transformTravelBuddyV2ToCommon = (
  travelBuddyV2: TravelBuddyInputV2,
  index: number
): TravelBuddy => {
  return {
    arrival: travelBuddyV2.arrival,
    departure: travelBuddyV2.departure,
    // @ts-ignore
    checkInTime: travelBuddyV2?.checkinTime ?? "",
    checkOutTime: travelBuddyV2?.checkoutTime ?? "",
    created: travelBuddyV2?.created ?? "",
    accessibleDoors:
      travelBuddyV2?.accessibleDoors?.map((itemV2) => ({
        id: itemV2?.id ?? "",
        title: itemV2?.title ?? "",
        pin: itemV2?.pin ?? "",
        is_general: itemV2?.isGeneral ?? true
      })) ?? [],
    actor: transformActorV2ToCommon(travelBuddyV2.actor),
    person: {},
    extras: {
      preferredCommunicationChannel: travelBuddyV2.person?.preferredCommunicationChannel ?? null,
      dirtyUnitDialogSeen: true,
      cleanUnitDialogSeen: true,
      billConfirmed: false,
      tcGeneral: travelBuddyV2.person?.termsAndConditionsGeneral ?? false,
      tcOnline: travelBuddyV2.person?.termsAndConditionsOnline ?? false,
      tcMarketingConsent: travelBuddyV2.person?.termsAndConditionsMarketingConsent ?? false,
      tcMarketingConsentDoubleOptin:
        travelBuddyV2.person?.termsAndConditionsMarketingConsentDoubleOptin ?? false,
      bookingOnBehalfOf: undefined,
      tcMinors: false
    },
    flowState: {
      wasOrIsCompleted: travelBuddyV2.flowState.wasOrIsCompleted,
      completed: travelBuddyV2.flowState.completed,
      context: travelBuddyV2.flowState.context,
      notificationPending: travelBuddyV2.flowState.notificationPending,
      notificationSent: travelBuddyV2.flowState.notificationSent,
      sentNotifications: travelBuddyV2.flowState.sentNotifications,
      performedActions: travelBuddyV2.flowState.performedActions
    },
    id: "",
    keycloakUUID: null,
    magicId: travelBuddyV2.magicId,
    magicLink: "", //TODO: travelBuddyV2.magicLink,
    magicType: MagicType.TRAVEL_BUDDY,
    magicToken: travelBuddyV2.magicToken,
    propertyId: travelBuddyV2.pmsPropertyId,
    reservationId: travelBuddyV2.pmsReservationId,
    status: transformStatusV2ToCommon(travelBuddyV2.status),
    travelBuddy: transformPersonV2ToCommonPerson(travelBuddyV2?.person),
    travelBuddyIndex: index,
    unit: {
      id: travelBuddyV2?.unit?.pmsId ?? "",
      name: travelBuddyV2?.unit?.name ?? "",
      unitGroupId: travelBuddyV2?.unitGroup?.pmsId ?? "",
      description: "",
      privacyMode: false,
      status: {
        isOccupied: false,
        condition: unitStateV2ToUnitCondition(travelBuddyV2?.unit?.state)
      }
    },
    unitGroup: {
      id: travelBuddyV2?.unitGroup?.pmsId ?? "",
      maxPersons: travelBuddyV2?.unitGroup?.capacity ?? 0
    }
  };
};
