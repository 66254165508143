import React, { FC, useCallback, useEffect, useMemo } from "react";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import {
  CmsSingleDocumentPerPropertyType,
  singleDocumentWithPropertyNamespace
} from "../../../state/cms/cms-single-document-per-property-type";
import { ArticleLayout } from "../components/article-layout";
import { Box, Grid, Link } from "@mui/material";
import {
  Button,
  formatDate,
  Heading2,
  Heading3,
  Paragraph
} from "@likemagic-tech/sv-magic-library";

import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";
import { TrainTicket } from "../../../components/icons/train-ticket";
import { FlimsLaax } from "../../../components/icons/flims-laax";
import { makeStyles } from "tss-react/mui";
import { useCmsPropertySpecificData } from "../../../state/cms/use-cms-per-property-data";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { isAndroid, isIOS } from "react-device-detect";
import { CopyToClipboard } from "../../../components/atoms/copy-to-clipboard/copy-to-clipboard";
import { responsiveVariables, useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { usePropertyById } from "../../property/use-property-by-id";
import { DesktopCard, PrismicRichTextWrapper } from "../../../components";
import { AppStoreIcon, PlayStoreIcon } from "../../../components/icons";
import { BackButton } from "../../../components/atoms/back-button/back-button";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { useNavigate } from "react-router-dom";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

export enum StoreType {
  APPLE = "ios",
  GOOGLE = "android"
}

const useStyles = makeStyles()(({ breakpoints, spacing, shape }) => ({
  icon: {
    fontSize: 64
  },
  image: {
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      position: "relative",
      borderRadius: shape.borderRadius * 2.5,
      maxWidth: `calc(100% - ${20 * 2}px)`,
      marginLeft: spacing(2.5),
      marginRight: spacing(2.5)
    }
  },
  storeButtons: {
    width: 175,
    height: 55,
    padding: 0
  }
}));

export const PublicTransportationTicketArticle: FC<React.PropsWithChildren<unknown>> = () => {
  const { reservation } = useReservationContext();
  const { setDisplayNavbar, setLeftButtons } = useNavbar();
  const { classes } = useStyles();
  const property = usePropertyById(reservation?.propertyId);
  const navigate = useNavigate();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { t } = useTranslateWrapper({
    namespace: [
      singleDocumentWithPropertyNamespace(
        CmsSingleDocumentPerPropertyType.propertySpecfic,
        reservation.propertyId
      )
    ]
  });
  const cmsData = useCmsPropertySpecificData(reservation.propertyId);
  const isMobile = useIsMobile();
  const onUse = useCallback(
    (storeType: StoreType) => () => {
      let url = "";
      if (storeType === StoreType.APPLE) {
        url = t("articles__ios_app_url_public_transportation_tickets");
      }
      if (storeType === StoreType.GOOGLE) {
        url = t("articles__android_app_url_public_transportation_tickets");
      }

      if (url) {
        window.open(url, "_blank");
      } else {
        console.warn("This device is not supported");
      }
    },
    [t]
  );

  useEffect(() => {
    if (isMobile) {
      setDisplayNavbar(false);
    } else {
      setDisplayNavbar(true);
    }
  }, [setDisplayNavbar, isMobile]);

  const content = useMemo(() => {
    return (
      <Box>
        <Box mb={2.5} textAlign="center">
          <PrismicRichTextWrapper
            render={cmsData?.data?.["articles__intro_public_transportation_tickets"]}
          />
        </Box>

        <Box textAlign="center">
          {reservation.extras?.publicTransportationTickets?.[0].link && (
            <FlimsLaax className={classes.icon} />
          )}
          {!reservation.extras?.publicTransportationTickets?.[0].link && (
            <TrainTicket className={classes.icon} />
          )}
        </Box>

        {reservation.extras?.publicTransportationTickets?.map((ticket) => (
          <Box m={2} key={ticket.code}>
            <Paragraph textAlign="center"> {ticket.name}</Paragraph>
            <Heading2 textAlign="center" gutterBottom>
              <CopyToClipboard
                textToCopy={ticket.code}
                tooltipText={tCommon("labels__copy_successfully")}
              >
                {ticket.code}
              </CopyToClipboard>
            </Heading2>
            {ticket.link && (isAndroid || isIOS) && (
              <Heading3 textAlign="center">
                <Link
                  rel="noreferrer"
                  target="_blank"
                  href={ticket.link}
                  sx={{ color: "text.primary" }}
                >
                  {t("articles__deep_link_public_transportation_tickets")}
                </Link>
              </Heading3>
            )}
          </Box>
        ))}

        <Grid container justifyContent="center">
          {(isAndroid || !isIOS) && (
            <Box mx={1}>
              <Button
                fullWidth
                variant="ghost"
                onClick={onUse(StoreType.GOOGLE)}
                className={classes.storeButtons}
              >
                <PlayStoreIcon />
              </Button>
            </Box>
          )}
          {(isIOS || !isAndroid) && (
            <Box mx={1}>
              <Box mx={1}>
                <Button
                  fullWidth
                  variant="ghost"
                  onClick={onUse(StoreType.APPLE)}
                  className={classes.storeButtons}
                >
                  <AppStoreIcon />
                </Button>
              </Box>
            </Box>
          )}
        </Grid>

        <Box mb={2.5} textAlign="center">
          <PrismicRichTextWrapper
            render={cmsData?.data?.["articles__outro_public_transportation_tickets"]}
          />
        </Box>
      </Box>
    );
  }, [
    reservation.extras?.publicTransportationTickets,
    classes.icon,
    tCommon,
    cmsData?.data,
    onUse,
    classes.storeButtons,
    t
  ]);

  const backButton = useMemo(() => {
    return (
      <BackButton
        key="back_button"
        onClick={() => navigate(generatePortalMyStayUrl(reservation.magicId))}
      />
    );
  }, [navigate, reservation.magicId]);

  useEffect(() => {
    setLeftButtons([backButton]);
  }, [setLeftButtons, backButton]);

  return (
    <>
      <HelmetTitle suffix="Article details" />

      <DesktopCard>
        <ArticleLayout
          coverImage={
            <img
              width="100%"
              src={cmsData?.data?.articles_public_transportation_tickets_cover_image?.url}
              alt="Some cover"
              className={classes.image}
            />
          }
          title={t("articles__title_public_transportation_tickets")}
          subtitle={t("articles__subtitle_public_transportation_tickets", {
            arrival:
              formatDate(reservation.arrival, getI18nSelectedLanguage(), property.timeZone) || "",
            departure:
              formatDate(reservation.departure, getI18nSelectedLanguage(), property.timeZone) || ""
          })}
          content={content}
        />
      </DesktopCard>
    </>
  );
};
