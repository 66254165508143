import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { UserProfileApi } from "../../api/user-profile.api";
import { EntityStateStatus, isStatusLoading } from "../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { handleSliceError } from "../../util/error-handling";
import { UserProfile } from "../../domain-common/user-profile";
import { getTenantHeaders } from "../../api/custom-headers";

export type UserProfileSliceState = {
  statusLatestMagicId: EntityStateStatus;
  statusUserProfile: EntityStateStatus;
  deleteUserProfileStatus: EntityStateStatus;
  lastMagicId: string | undefined | null;
  userProfile?: UserProfile;
};

const initialState: UserProfileSliceState = {
  statusLatestMagicId: EntityStateStatus.IDLE,
  statusUserProfile: EntityStateStatus.IDLE,
  deleteUserProfileStatus: EntityStateStatus.IDLE,
  lastMagicId: undefined,
  userProfile: undefined
};

export const fetchTheLatestMagicId = createAsyncThunk<
  { latestMagicId: string },
  undefined,
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("userProfile/fetchLastMagicId", async (arg, thunkAPI) => {
  try {
    return await UserProfileApi.fetchTheLatestMagicId({
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const deleteAccount = createAsyncThunk<
  { success: boolean },
  { id: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("userProfile/delete", async (arg, thunkAPI) => {
  try {
    return await UserProfileApi.deleteAccount(arg.id, {
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const fetchUserProfile = createAsyncThunk<
  UserProfile,
  undefined | { force: boolean },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>(
  "userProfile/fetchUserProfile",
  async (arg, thunkAPI) => {
    try {
      return await UserProfileApi.fetchUserProfile({
        signal: thunkAPI.signal,
        ...(await getTenantHeaders(thunkAPI.extra))
      });
    } catch (e) {
      return handleSliceError(e, thunkAPI.rejectWithValue);
    }
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().userProfile.statusUserProfile;

      //Force new fetch
      if (arg?.force) {
        return arg?.force;
      }

      // don't load already loaded user profile
      if (status === EntityStateStatus.LOADING || status === EntityStateStatus.SUCCEEDED) {
        return false;
      }
    }
  }
);

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    clearUserProfile: (state) => {
      state.userProfile = initialState.userProfile;
      state.statusUserProfile = initialState.statusUserProfile;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTheLatestMagicId.pending, (state) => {
        state.statusLatestMagicId = EntityStateStatus.LOADING;
      })
      .addCase(fetchTheLatestMagicId.fulfilled, (state, action) => {
        state.lastMagicId = action.payload.latestMagicId;
        state.statusLatestMagicId = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchTheLatestMagicId.rejected, (state) => {
        state.statusLatestMagicId = EntityStateStatus.FAILED;
      })
      .addCase(fetchUserProfile.pending, (state) => {
        state.statusUserProfile = EntityStateStatus.LOADING;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload;
        state.statusUserProfile = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.statusUserProfile = EntityStateStatus.FAILED;
      })
      .addCase(deleteAccount.pending, (state) => {
        state.deleteUserProfileStatus = EntityStateStatus.LOADING;
      })
      .addCase(deleteAccount.fulfilled, (state) => {
        state.deleteUserProfileStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(deleteAccount.rejected, (state) => {
        state.deleteUserProfileStatus = EntityStateStatus.FAILED;
      });
  }
});

export const { clearUserProfile } = userProfileSlice.actions;

const selectUserProfileSlice = (state: RootState) => state[userProfileSlice.name];

export const selectIsLoadingLastMagicId = createSelector(selectUserProfileSlice, (res) =>
  isStatusLoading(res.statusLatestMagicId)
);

export const selectUserProfileLastMagicId = createSelector(
  selectUserProfileSlice,
  (res) => res.lastMagicId
);
export const selectStatusUserProfileLastMagicId = createSelector(
  selectUserProfileSlice,
  (res) => res.statusLatestMagicId
);

/* start User profile section */
export const selectIsLoadingUserProfile = createSelector(
  selectUserProfileSlice,
  (res) => res.statusUserProfile === EntityStateStatus.LOADING
);

export const selectUserLanguage = createSelector(
  selectUserProfileSlice,
  (res) => res.userProfile?.person?.preferredLocale?.toLowerCase()
);

export const selectUserKeycloakUUID = createSelector(
  selectUserProfileSlice,
  (res) => res.userProfile?.keycloakUUID
);
export const selectUserProfileID = createSelector(
  selectUserProfileSlice,
  (res) => res.userProfile?.id
);
/* end User profile section */

export const selectIsUserProfileDeleting = createSelector(
  selectUserProfileSlice,
  (res) => res.deleteUserProfileStatus === EntityStateStatus.LOADING
);

export const selectUserProfileIsDeleted = createSelector(
  selectUserProfileSlice,
  (res) => res.deleteUserProfileStatus === EntityStateStatus.SUCCEEDED
);
