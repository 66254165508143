import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type PayDetailsAndCreateBookingMutationVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"];
  payment: Types.WidgetPaymentDetailsRequest;
  booking: Types.PotentialBooking;
}>;

export type PayDetailsAndCreateBookingMutation = {
  __typename?: "Mutation";
  PayDetailsAndCreateBooking: {
    __typename?: "Booking";
    magicId: string;
    reservations?: Array<{ __typename?: "Reservation"; magicId: string } | null> | null;
  };
};

export const PayDetailsAndCreateBookingDocument = `
    mutation PayDetailsAndCreateBooking($pmsPropertyId: String!, $payment: WidgetPaymentDetailsRequest!, $booking: PotentialBooking!) {
  PayDetailsAndCreateBooking(
    pmsPropertyId: $pmsPropertyId
    payment: $payment
    booking: $booking
  ) {
    magicId
    reservations {
      magicId
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    PayDetailsAndCreateBooking: build.mutation<
      PayDetailsAndCreateBookingMutation,
      PayDetailsAndCreateBookingMutationVariables
    >({
      query: (variables) => ({ document: PayDetailsAndCreateBookingDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { usePayDetailsAndCreateBookingMutation } = injectedRtkApi;
