import { Price } from "./price";
import { CancellationFee } from "./cancellation-fee";

interface Amount {
  currency: string;
  grossAmount: number;
  netAmount: number;
  vatPercent: number;
  vatType: string;
}

interface Service {
  id: string;
  code: string;
  description: string;
  name: string;
}

export interface IncludedService {
  grossPrice: Price;
  pricingMode: "Included" | "Additional";
  service: Service;
}

export interface MagicIncludedService {
  serviceCode: string;
  serviceId: string;
  quantity: number;
}

export interface PromoCodeMagicDiscount {
  amount: number;
  currency: string;
}

export enum PromoCodeMagicDiscountType {
  ABSOLUTE = "ABSOLUTE",
  PERCENTAGE = "PERCENTAGE"
}

export type MinGuaranteeType = "Prepayment" | "CreditCard" | "PM6Hold" | "Company";

export interface Offer {
  ratePlanId: string;
  // hotel partner rate info
  ratePlanTitle?: string;
  ratePlanDescription?: string;
  // end
  minGuaranteeType: MinGuaranteeType;
  pricePerNight: Price;
  priceTotal: Price;
  includedServices: Array<IncludedService>;
  magicIncludedServices: Array<MagicIncludedService>;
  promoCode?: string;
  promoCodeMagicDiscount?: PromoCodeMagicDiscount;
  cancellationFee: CancellationFee;
  promoCodeMagicDiscountHidden?: boolean;
  promoCodeMagicDiscountType?: PromoCodeMagicDiscountType;
  pricePerNightBeforeDiscount: Price;
  priceTotalBeforeDiscount: Price;
  promoCodeMagicDiscountPercentage: number;
  services: Array<{
    count: 1;
    dates: Array<{
      amount: Amount;
      isDefaultDate: boolean;
      isMandatory: boolean;
      serviceDate: string;
    }>;
    fees: any;
    prePaymentAmount: Price;
    service: {
      code: string;
      defaultGrossPrice: Price;
      description: string;
      id: string;
      name: string;
    };
    totalAmount: Amount;
  }>;
}

export interface SearchUnitGroup {
  propertyId: string;
  unitGroupId: string;
  maxPersons: number;
  arrival: string;
  departure: string;
  availableUnits: number;
  offers: Array<Offer>;
  availableUnitsThreshold: number;
  numberOfNights: number;
}
