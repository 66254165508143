import { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { selectPaymentSlice } from "../payment.slice";
import { PaymentServicePaymentPayload } from "../payment-dto";
import { getLocationOrigin } from "../../../util/routing";
import { generateUUID } from "../../../util/data";
import UIElement from "@adyen/adyen-web/dist/types/components/UIElement";
import { useInitiatePayment } from "./use-initiate-payment";
import { usePaymentDetails } from "./use-payment-details";

export const usePayment = ({
  magicId,
  onSuccessPayment,
  returnUrl,
  prePaymentSubmit
}: {
  magicId?: string;
  onSuccessPayment: (bookingMagicId?: string) => void;
  returnUrl?: string;
  prePaymentSubmit?: () => void;
}) => {
  const paymentComponent = useRef<UIElement | null>(null);
  const { initiatePayment, paymentResponse, paymentStatus } = useInitiatePayment();
  const { initiatePaymentDetails, paymentDetailsResponse, paymentDetailsStatus } =
    usePaymentDetails();

  const { paymentDTO, referenceId } = useSelector(selectPaymentSlice);

  const onPaymentSubmit = useCallback(
    (state: any, component: any) => {
      if (state.isValid) {
        if (prePaymentSubmit) {
          prePaymentSubmit();
        }

        const paymentServicePayload: PaymentServicePaymentPayload = {
          dropinPayload: state.data,
          additionalDetailsDTO: {
            returnUrl: returnUrl!,
            origin: getLocationOrigin()
          }
        };
        //Generate new reference id
        initiatePayment({
          data: {
            paymentServicePayload,
            paymentDTO,
            referenceId: referenceId ?? generateUUID()
          },
          magicId
        });

        paymentComponent.current = component;
      }
    },
    [returnUrl, initiatePayment, magicId, paymentDTO, prePaymentSubmit, referenceId]
  );

  function redirectWithPost(url: string) {
    // Create a form element
    var form = document.createElement("form");
    form.method = "post";
    form.action = url;

    // Append the form to the document body and submit it
    document.body.appendChild(form);
    form.submit();
  }

  const onPaymentDetailsSubmit = useCallback(
    (state: any, component: any) => {
      const paymentServicePayload = {
        dropinPayload: state.data
      };

      const promise = initiatePaymentDetails({
        data: {
          frontendPayload: paymentServicePayload,
          // use one that has been generated on initiate payment
          paymentDTO,
          referenceId
        },
        magicId
      });

      if (component) {
        paymentComponent.current = component;
      }

      return promise;
    },
    [initiatePaymentDetails, paymentDTO, referenceId, magicId]
  );

  const processPaymentResponse = useCallback(
    async (paymentRes: any) => {
      // TODO align paymentRes top be ExtendedAdyenResponse
      // extend-stay/payment and booking-creation/payment wraps result in 'action' topLevel; shop doesn't;
      // v2 wrap it with InitiatePayment.checkoutPaymentsAction
      const response = paymentRes.InitiatePayment
        ? paymentRes.InitiatePayment.checkoutPaymentsAction
        : paymentRes;
      if (paymentComponent.current !== null && response && (response.action || response.type)) {
        const type = response.action ? response.action.type : response.type;
        switch (type) {
          // see https://docs.adyen.com/online-payments/drop-in-web#step-4-additional-front-end for the cases
          case "voucher":
            break;
          case "redirect":
          case "qrCode":
          case "await":
          case "sdk":
          case "threeDS2":
            // For giropay, letting adyen plugin handle it somehow doesn't do anything => we handle the redirect manually
            // Issue is and old version of the adyen plugin
            if (response.transitoryPaymentDTO?.paymentMethod === "giropay") {
              redirectWithPost(response.action.url);
            } else {
              //3D security handler
              const uiElement = paymentComponent.current?.handleAction(
                response.action ? response.action : response
              );

              if (response.action.url || response.url)
                setTimeout(() => {
                  console.error(
                    "Handle action didn't redirect [redirect flow] ",
                    response,
                    uiElement
                  );
                  //Force update of widget if redirect does not happen
                  paymentComponent.current?.updateParent({ locale: getLocationOrigin() });
                }, 1500);
            }
            break;
          default:
            console.error("Unknown additional details action type " + type);
        }
      } else {
        onSuccessPayment(response?.bookingMagicId);
      }
    },
    [onSuccessPayment]
  );

  const processPaymentDetailsResponse = useCallback(
    (paymentDetailsResponse: any) => {
      onSuccessPayment(paymentDetailsResponse?.bookingMagicId);
    },
    [onSuccessPayment]
  );

  return {
    paymentResponse,
    paymentDetailsResponse,
    onPaymentSubmit,
    onPaymentDetailsSubmit,
    processPaymentResponse,
    processPaymentDetailsResponse,
    paymentStatus,
    paymentDetailsStatus,
    paymentDTO
  };
};
