import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector
} from "@reduxjs/toolkit";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../state/store";

import {
  ExtendStayApi,
  ExtendStayPriceSummaryDTO,
  ExtendStayRequestDTO
} from "../../api/extend-stay.api";
import { handleSliceError } from "../../util/error-handling";
import { getTenantHeaders } from "../../api/custom-headers";

export interface ExtendStayState {
  price?: ExtendStayPriceSummaryDTO;
  priceStatus: EntityStateStatus;
  bookStatus: EntityStateStatus;
  requestDTO?: ExtendStayRequestDTO;
}

export const defaultState: ExtendStayState = {
  priceStatus: EntityStateStatus.IDLE,
  bookStatus: EntityStateStatus.IDLE
};

export const extendStayPriceAction = createAsyncThunk<
  ExtendStayPriceSummaryDTO,
  {
    magicId: string;
    extendStayRequestDTO: ExtendStayRequestDTO;
    skipBannerError?: boolean;
  },
  { state: RootState; extra: ThunkExtraArguments }
>("extendStay/price", async (arg, thunkAPI) => {
  try {
    return await ExtendStayApi.price(arg);
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});
export const book = createAsyncThunk<
  any,
  {
    magicId: string;
    extendStayRequestDTO: ExtendStayRequestDTO;
  },
  { state: RootState; extra: ThunkExtraArguments }
>("extendStay/payment", async (arg, thunkAPI) => {
  try {
    return await ExtendStayApi.book(arg, {
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const extendStaySlice = createSlice({
  name: "extendStay",
  initialState: defaultState,
  reducers: {
    setExtendStayRequestDTO: (state, action: PayloadAction<ExtendStayRequestDTO>) => {
      state.requestDTO = action.payload;
    },
    clearExtendStay: (state) => {
      state.requestDTO = undefined;
      state.price = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(extendStayPriceAction.fulfilled, (state, action) => {
        state.priceStatus = EntityStateStatus.SUCCEEDED;
        state.price = action.payload;
      })
      .addCase(extendStayPriceAction.pending, (state) => {
        state.priceStatus = EntityStateStatus.LOADING;
      })
      .addCase(extendStayPriceAction.rejected, (state) => {
        state.priceStatus = EntityStateStatus.FAILED;
      })
      .addCase(book.fulfilled, (state) => {
        state.bookStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(book.pending, (state) => {
        state.bookStatus = EntityStateStatus.LOADING;
      })
      .addCase(book.rejected, (state) => {
        state.bookStatus = EntityStateStatus.FAILED;
      });
  }
});

export const { setExtendStayRequestDTO, clearExtendStay } = extendStaySlice.actions;

const selectSelf: Selector<RootState, ExtendStayState> = (state: RootState) =>
  state[extendStaySlice.name];

export const selectExtendStayPriceSummary = createSelector(selectSelf, (state) => state.price);
export const selectExtendStayRequestDTO = createSelector(selectSelf, (state) => state.requestDTO);

export const selectExtendStayPriceStatus = createSelector(selectSelf, (state) => state.priceStatus);
export const selectExtendStayPriceIsLoading = createSelector(
  selectSelf,
  (state) => state.priceStatus === EntityStateStatus.LOADING
);
export const selectExtendStayBookIsLoading = createSelector(
  selectSelf,
  (state) => state.bookStatus === EntityStateStatus.LOADING
);
