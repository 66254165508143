import { PrismicRichText } from "@prismicio/react";
import React, { HTMLAttributeAnchorTarget } from "react";
import { Box, Link, useTheme } from "@mui/material";
import { RichTextField } from "@prismicio/client";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Paragraph
} from "@likemagic-tech/sv-magic-library";

export const PrismicRichTextWrapper: React.FC<
  React.PropsWithChildren<{ render: RichTextField; linksTarget?: HTMLAttributeAnchorTarget }>
> = ({ render, linksTarget = "_blank" }) => {
  const theme = useTheme();

  return (
    <PrismicRichText
      field={render}
      components={{
        heading1: Heading1,
        heading2: Heading2,
        heading3: Heading3,
        heading4: Heading4,
        paragraph: ({ children }) => (
          <Paragraph sx={{ minHeight: theme.typography.body1.lineHeight }}>{children}</Paragraph>
        ),
        image: ({ node }) => (
          <Box>
            <img
              alt={node.alt || ""}
              src={node.url}
              style={{
                maxWidth: "100%"
              }}
            />
          </Box>
        ),
        hyperlink: ({ text, node }) => {
          return (
            <Link underline="always" href={node.data.url} target={linksTarget}>
              {text}
            </Link>
          );
        }
      }}
    />
  );
};
