import React, { FC, useEffect } from "react";
import { SubmitButton } from "../../../components";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { PaymentType } from "../../../domain-common/booking-cart-total-price";
import { useCreateBookingWithoutPayment } from "../hooks/use-create-booking-without-payment";
import { Box } from "@mui/material";
import { EntityStateStatus } from "../../../state/EntityStateStatus";

interface SearchBookingWithoutPaymentCreateButtonProps {
  disablePayment: boolean;
  guaranteeType: PaymentType;
  onSuccessPayment: (magicId?: string) => void;
}
export const SearchBookingWithoutPaymentCreateButton: FC<
  React.PropsWithChildren<SearchBookingWithoutPaymentCreateButtonProps>
> = ({ disablePayment, guaranteeType, onSuccessPayment }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { isBottomNavbarActive } = useBottomNavbar();

  const {
    createBooking: createBookingWithoutPayment,
    createBookingStatus,
    createBookingResponse
  } = useCreateBookingWithoutPayment();

  useEffect(() => {
    if (createBookingStatus === EntityStateStatus.SUCCEEDED) {
      onSuccessPayment(createBookingResponse.bookingMagicId);
    }
  }, [createBookingStatus, onSuccessPayment, createBookingResponse]);

  return (
    <Box p={2.5}>
      <SubmitButton
        disabled={disablePayment}
        hasBottomNavigation={isBottomNavbarActive}
        hasWhiteBackground
        label={t("buttons__create_booking_" + guaranteeType.toLowerCase())}
        onClick={createBookingWithoutPayment}
      />
    </Box>
  );
};
