import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ShoppingCartIcon } from "../icons";
import { generateSearchBookingPagesUrl } from "../../features/search-for-bookings/search-booking-navigation";
import { SearchBookingPages } from "../../features/search-for-bookings/search-booking-pages";
import { useIsMobile } from "../layouts/hooks/use-is-mobile";
import { CartBadge } from "../../features/search-for-bookings/components/cart/cart-badge";

export const SearchBookingCartButtonNavigation = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  return (
    <IconButton
      sx={{ color: "background.paper" }}
      onClick={() => navigate(generateSearchBookingPagesUrl(SearchBookingPages.CART_BOOKINGS))}
      size="large"
    >
      <CartBadge placement="header">
        <ShoppingCartIcon
          sx={{
            color: isMobile ? "background.paper" : "common.black",
            fontSize: 16
          }}
        />
      </CartBadge>
    </IconButton>
  );
};
