import smoothscroll from "smoothscroll-polyfill";
import "./config/i18n/i18n-next-config";
import "./index.css";
import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";

window.global ||= window;
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// kick off the polyfill!
smoothscroll.polyfill();
