import { Badge } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { useSelector } from "react-redux";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { selectCartNumberOfItems } from "../store/cart.slice";

interface CartBadgeProps {
  placement: "header" | "fab";
  boldFont?: string;
}

const useStyles = makeStyles<{ isMobile: boolean; placement: string }>()((theme, props) => ({
  root: {
    backgroundColor:
      props.placement === "header" && props?.isMobile
        ? theme.palette.common.black
        : theme.palette.primary.main,
    fontWeight: 700
  }
}));

export const CartBadge: React.FC<React.PropsWithChildren<CartBadgeProps>> = (props) => {
  const badgeContent = useSelector(selectCartNumberOfItems);
  const isMobile = useIsMobile();
  const { classes } = useStyles({
    ...props,
    isMobile
  });
  return (
    <Badge badgeContent={badgeContent} classes={{ badge: classes.root }}>
      {props.children}
    </Badge>
  );
};
