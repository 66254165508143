import { getLocalStorageObject } from "../state/local-storage-state";

const LOCAL_STORAGE_KIOSK_MODE = "kiosk_mode";
const LOCAL_STORAGE_KIOSK_ID = "kiosk_id";
const LOCAL_STORAGE_ENFORCE_SCAN_UI = "enforce_scan_ui";
const LOCAL_STORAGE_PROPERTY_ID = "kiosk_property_id";
const LOCAL_STORAGE_TERMINAL_ID = "kiosk_terminal_id";
const LOCAL_STORAGE_TERMINAL_ONLY = "kiosk_terminal_only";
const LOCAL_STORAGE_SELF_SERVICE_KEY_ENABLED = "kiosk_hide_self_service_key";

export const isKioskMode = () => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_KIOSK_MODE);

  return (
    (Object.keys(storageValue).length !== 0 && storageValue.isEnabled === "true") || !!getKioskId()
  );
};

export const isEnforceScanUIMode = () => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_ENFORCE_SCAN_UI);
  return Object.keys(storageValue).length !== 0 && storageValue.isEnabled === "true";
};

export const hideAssignKeyForKioskMode = () => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_SELF_SERVICE_KEY_ENABLED);
  return Object.keys(storageValue).length !== 0 && storageValue.isEnabled === "true";
};

export const getKioskPropertyId = (): string => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_PROPERTY_ID);
  return (Object.keys(storageValue).length !== 0 ? storageValue.propertyId : undefined) as string;
};

export const getKioskTerminalId = () => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_TERMINAL_ID);
  return (Object.keys(storageValue).length !== 0 ? storageValue.terminalId : undefined) as string;
};

export const isTerminalOnly = () => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_TERMINAL_ONLY);
  return Object.keys(storageValue).length !== 0 && storageValue.terminalOnly === "true";
};

export const setKioskMode = (isEnabled: string | null) => {
  try {
    if (isEnabled) {
      localStorage.setItem(LOCAL_STORAGE_KIOSK_MODE, JSON.stringify({ isEnabled }));
    }
  } catch (e) {
    console.error("Failed to set kiosk mode in local storage", e);
  }
};

export const setHideAssignKeyForKioskMode = (isEnabled: string | null) => {
  try {
    if (isEnabled) {
      localStorage.setItem(LOCAL_STORAGE_SELF_SERVICE_KEY_ENABLED, JSON.stringify({ isEnabled }));
    }
  } catch (e) {
    console.error("Failed to set kiosk mode in local storage", e);
  }
};

export const setEnfroceScanUI = (isEnabled: string | null) => {
  try {
    if (isEnabled) {
      localStorage.setItem(LOCAL_STORAGE_ENFORCE_SCAN_UI, JSON.stringify({ isEnabled }));
    }
  } catch (e) {
    console.error("Failed to set EnfroceScanUI in local storage", e);
  }
};

export const setKioskPropertyId = (propertyId: string | null) => {
  try {
    if (propertyId) {
      localStorage.setItem(LOCAL_STORAGE_PROPERTY_ID, JSON.stringify({ propertyId }));
    }
  } catch (e) {
    console.error("Failed to set KioskPropertyId in local storage", e);
  }
};

export const setKioskTerminalId = (terminalId: string | null) => {
  try {
    if (terminalId) {
      localStorage.setItem(LOCAL_STORAGE_TERMINAL_ID, JSON.stringify({ terminalId }));
    }
  } catch (e) {
    console.error("Failed to set KioskTerminalId in local storage", e);
  }
};

export const setKioskTerminalOnly = (terminalOnly: string | null) => {
  try {
    if (terminalOnly) {
      localStorage.setItem(LOCAL_STORAGE_TERMINAL_ONLY, JSON.stringify({ terminalOnly }));
    }
  } catch (e) {
    console.error("Failed to set KioskTerminalOnly in local storage", e);
  }
};

/* new kiosk param starts */

export const getKioskId = () => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_KIOSK_ID);
  return (Object.keys(storageValue).length !== 0 ? storageValue.kioskId : undefined) as string;
};
export const setKioskId = (kioskId: string | null) => {
  try {
    if (kioskId) {
      localStorage.setItem(LOCAL_STORAGE_KIOSK_ID, JSON.stringify({ kioskId }));
    }
  } catch (e) {
    console.error("Failed to set kiosk mode in local storage", e);
  }
};

/* new kiosk param ends */
