import { Grid, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { AdditionalServiceButton, DisplayCmsSvg } from "../../../components";
import { InfoIcon } from "../../../components/icons";
import { Reservation } from "../../../domain-common/reservation";
import { RootState } from "../../../state/store";
import { getServiceDetails, useServicesCmsData } from "../../services/use-services-cms-data";
import { selectAdditionalServiceById } from "./additional-services-cart.slice";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { getOkModalArg } from "../../global-modal/global-modal-util";
import { AdditionalServicesAvailabilityResponse } from "../../../api/dto/additional-services-availability-response";
import { useCmsPropertySpecificData } from "../../../state/cms/use-cms-per-property-data";
import {
  useLateCheckoutModalCmsData,
  useServiceModalCmsData
} from "../../../state/property-cms/use-service-modal-cms-data";

interface AdditionalServiceButtonRowProps {
  reservation: Reservation;
  service: AdditionalServicesAvailabilityResponse;
  onSelectService: (service: AdditionalServicesAvailabilityResponse) => void;
}

const useStyles = makeStyles()(() => ({
  infoButtonContainer: {
    display: "flex",
    alignItems: "center"
  }
}));

export const AdditionalServiceButtonRow: React.FC<
  React.PropsWithChildren<AdditionalServiceButtonRowProps>
> = ({ reservation, service, onSelectService }) => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const additionalServiceInCart = useSelector((state: RootState) =>
    selectAdditionalServiceById(state, service.serviceId)
  );
  const cmsPropertySpecificData = useCmsPropertySpecificData(reservation.propertyId);
  const { translatedKeys: datePickerTranslatedKeys } =
    useServiceModalCmsData(cmsPropertySpecificData);
  const { translatedKeys: lateCheckoutPickerTranslatedKeys } =
    useLateCheckoutModalCmsData(cmsPropertySpecificData);

  const datePickerLabels = useMemo(
    () => ({
      buttonLabel: datePickerTranslatedKeys[service.serviceId]?.button,
      descriptionLabel: datePickerTranslatedKeys[service.serviceId]?.description
    }),
    [datePickerTranslatedKeys, service.serviceId]
  );
  const lateCheckoutPickerLabels = useMemo(
    () => ({
      buttonLabel: lateCheckoutPickerTranslatedKeys[service.serviceId]?.button,
      descriptionLabel: lateCheckoutPickerTranslatedKeys[service.serviceId]?.description
    }),
    [lateCheckoutPickerTranslatedKeys, service.serviceId]
  );

  const { fallbackImage, serviceIcons, serviceTitles } = useServicesCmsData(reservation.propertyId);

  const iconUrl = getServiceDetails(service, serviceIcons, fallbackImage);

  const { open: openModal } = useGlobalModal();

  const handleInformationOpen = useCallback(() => {
    openModal(
      getOkModalArg(
        datePickerLabels.buttonLabel || lateCheckoutPickerLabels.buttonLabel,
        datePickerLabels.descriptionLabel || lateCheckoutPickerLabels.descriptionLabel,
        tCommon("buttons__ok")
      )
    );
  }, [datePickerLabels, lateCheckoutPickerLabels, tCommon, openModal]);

  const { classes } = useStyles();

  return (
    <>
      <Grid item container>
        <Grid item xs container direction="column">
          <AdditionalServiceButton
            serviceName={getServiceDetails(service, serviceTitles, service.code)}
            price={service.basePrice}
            icon={iconUrl ? <DisplayCmsSvg url={iconUrl} /> : undefined}
            onClick={() => onSelectService(service)}
            quantity={additionalServiceInCart?.dates?.length ?? 0}
          />
        </Grid>
        <Grid item className={classes.infoButtonContainer}>
          <IconButton onClick={handleInformationOpen} size="large">
            <InfoIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};
