import React, { FC, useCallback } from "react";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useNavigate } from "react-router";
import { generateCheckoutFlowBillPageUrl } from "../../../util/routing";
import { CheckoutFlowPages, getCheckoutFlowProgress } from "../checkout-flow-pages";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { GuestFlowBackgroundBox } from "../../guest-flow/components/guest-flow-background-box";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useUpdateFlowStateMutation } from "../../../graphql/mutations/update-flow-state.generated";
import { DynamicShop } from "../../dynamic-shop/dynamic-shop";
import { ShopType } from "../../../graphql/generated/graphql";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { DesktopCard, DisplayCmsSvg } from "../../../components";
import { Box } from "@mui/material";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";

const currentPage = CheckoutFlowPages.MINIBAR;

export const CheckoutMinibarPage: FC = () => {
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const [updateFlowState] = useUpdateFlowStateMutation();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const { reservation } = useReservationContext();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const onNextPage = useCallback(async () => {
    await updateFlowState({
      magicId,
      magicToken: reservation.magicToken,
      flowState: {
        context: {
          ...reservation.flowState.context,
          lastConfirmedCheckoutPage: currentPage
        }
      }
    });
    navigate(generateCheckoutFlowBillPageUrl(magicId));
  }, [navigate, updateFlowState, magicId, reservation.flowState.context, reservation.magicToken]);

  return (
    <FlowTemplate handleBack={goBack} icons={[]} progress={getCheckoutFlowProgress(currentPage)}>
      <GuestFlowBackgroundBox>
        <Box p={2}>
          <DesktopCard>
            <PageHeadingInfo
              icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
              title={t("title__checkout_flow_mini_bar_page")}
            />
            <DynamicShop
              reservation={reservation}
              shopType={ShopType.MinibarCheckoutFlow}
              onNext={onNextPage}
            />
          </DesktopCard>
        </Box>
      </GuestFlowBackgroundBox>
    </FlowTemplate>
  );
};
