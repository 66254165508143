import { BookingCartTotalPrice, PaymentType } from "../../domain-common/booking-cart-total-price";
import { emptyPrice } from "../../domain-common/price";
import { emptyFullPrice } from "../../domain-common/full-price";
import { GetPotentialBookingPriceQuery } from "../queries/GetBookingTotalPrice.generated";
import { transformFullPrice } from "./transform-reservation";
import { GuaranteeType } from "../generated/graphql";

type BookingPriceInput = GetPotentialBookingPriceQuery["GetPotentialBookingPrice"];
export const transformBookingTotalPrice = (
  totalPriceV2?: BookingPriceInput | null
): BookingCartTotalPrice => {
  return {
    accommodation: {
      ...(totalPriceV2?.accommodation
        ? transformFullPrice(totalPriceV2?.accommodation)
        : emptyFullPrice())
    },
    cityTaxes: emptyFullPrice(),
    discount: emptyPrice(),
    paymentType: transformGuaranteeType(totalPriceV2?.prepayment),
    prepaid: true,
    services: emptyFullPrice(),
    total: {
      ...(totalPriceV2?.total ? transformFullPrice(totalPriceV2.total) : emptyFullPrice())
    }
  };
};

const transformGuaranteeType = (guaranteeType: GuaranteeType | null | undefined): PaymentType => {
  switch (guaranteeType) {
    case GuaranteeType.Prepayment:
      return PaymentType.PREPAYMENT;
    case GuaranteeType.CreditCard:
      return PaymentType.GUARANTEE;
    case GuaranteeType.Pm6Hold:
      return PaymentType.PM6HOLD;
    case GuaranteeType.Company:
      return PaymentType.GUARANTEED_BY_COMPANY;
    default:
      return PaymentType.PREPAYMENT;
  }
};
