import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function BedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M22 17.3333V13.1111C22 12.6396 21.8127 12.1874 21.4793 11.854C21.1459 11.5206 20.6937 11.3333 20.2222 11.3333H3.77778C3.30628 11.3333 2.8541 11.5206 2.5207 11.854C2.1873 12.1874 2 12.6396 2 13.1111V17.3333M22 17.3333H2M22 17.3333V20M2 17.3333V20M20 11.3333V5.33333C20 4.97971 19.8595 4.64057 19.6095 4.39052C19.3594 4.14048 19.0203 4 18.6667 4H5.33333C4.97971 4 4.64057 4.14048 4.39052 4.39052C4.14048 4.64057 4 4.97971 4 5.33333V11.3333M8.88889 8H15.1111C15.3469 8 15.573 8.09365 15.7397 8.26035C15.9064 8.42705 16 8.65314 16 8.88889V11.3333H8V8.88889C8 8.65314 8.09365 8.42705 8.26035 8.26035C8.42705 8.09365 8.65314 8 8.88889 8Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
