import { useSelector } from "react-redux";
import {
  searchAvailableUnitGroups,
  searchAvailableUnitGroupsAction
} from "../search-available-unit-groups.slice";
import { useEffect } from "react";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";
import { useAppDispatch } from "../../../state/store";
import { SearchOfferParamsDTO } from "./use-search-offer-params";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useGetAvailableUnitGroupsQuery } from "../../../graphql/queries/GetAvailableUnitGroups.generated";
import { transformSearchAvailableUnitGroups } from "../../../graphql/transform/transform-search-available-unit-groups";

export const useSearchAvailableUnitGroup = ({
  searchValues
}: {
  searchValues: SearchOfferParamsDTO;
}) => {
  const language = getI18nSelectedLanguage();
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();

  const { data } = useGetAvailableUnitGroupsQuery(
    {
      filter: {
        adultsAmount: searchValues.adults,
        arrival: searchValues.arrival,
        childrenAmount: searchValues.childrenAges.length,
        departure: searchValues.departure,
        pmsPropertyId: searchValues.propertyIds
      }
    },
    {
      skip: isRESTVersion
    }
  );
  const availableUnitGroupsApi = useSelector(searchAvailableUnitGroups);
  useEffect(() => {
    if (
      isRESTVersion &&
      searchValues.arrival &&
      searchValues.departure &&
      searchValues.adults &&
      searchValues.childrenAges &&
      searchValues.propertyIds
    ) {
      const promise = dispatch(
        // @ts-ignore TS is not aware that allFiltersAreAvailable flag is checking that all filters are properly set
        searchAvailableUnitGroupsAction({
          ...searchValues,
          language
        })
      );
      return () => {
        promise.abort();
      };
    }
  }, [language, searchValues, dispatch, isRESTVersion]);

  const availableUnitGroups = isRESTVersion
    ? availableUnitGroupsApi
    : data?.GetAvailableUnitGroups?.map((item) => transformSearchAvailableUnitGroups(item)) ?? [];

  return { availableUnitGroups };
};
