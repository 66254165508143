import React, { FC } from "react";
import { KeysHandlerProps } from "./keys-handler";
import { Subtitle } from "@likemagic-tech/sv-magic-library";
import { Grid } from "@mui/material";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import Dialpad from "@mui/icons-material/Dialpad";

export const KeysOffline: FC<React.PropsWithChildren<KeysHandlerProps>> = ({ door }) => {
  return (
    <>
      <Grid py={2} container alignItems="center" justifyContent="center">
        <DoorFrontOutlinedIcon fontSize="large" />
        <Subtitle ml={2}>{door.title}</Subtitle>
      </Grid>
      <Grid py={2} container alignItems="center" justifyContent="center">
        <Dialpad />
        <Subtitle ml={2}>{door.pin}</Subtitle>
      </Grid>
    </>
  );
};
