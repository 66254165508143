import React, { FC } from "react";
import { Grid } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { NullableString, Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { FakeInputBox } from "../../atoms";
import { DateRangeIcon } from "../../icons/date-range.icon";

export const DateRangePickerPreview: FC<{
  fromLabel: string;
  toLabel: string;
  from: NullableString;
  to: NullableString;
  error?: string | boolean;
  onClick: () => void;
}> = ({ from, to, onClick, fromLabel, toLabel, error }) => {
  const LabelComponent = (haveValue: boolean) => (haveValue ? ParagraphSmall : Paragraph);
  return (
    <>
      <FakeInputBox
        onClick={onClick}
        fullWidth
        error={!!error}
        className="FakeInputBox"
        variant="standard"
      >
        <Grid container alignItems="center" height="100%">
          <Grid item xs={5}>
            {React.createElement(LabelComponent(!!from), {}, <>{fromLabel}</>)}
            <Paragraph sx={{ fontVariant: "none" }}>{from}</Paragraph>
          </Grid>
          <Grid item xs={1}>
            <Grid container alignItems="center" height="100%">
              <ArrowForward />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            {React.createElement(LabelComponent(!!to), {}, <>{toLabel}</>)}
            <Paragraph sx={{ fontVariant: "none" }}>{to}</Paragraph>
          </Grid>
          <Grid item xs={1} textAlign="right">
            <DateRangeIcon />
          </Grid>
        </Grid>
      </FakeInputBox>
      {error && (
        <ParagraphSmall ml={2} mt={0.5} sx={{ color: "error.main" }}>
          {error}
        </ParagraphSmall>
      )}
    </>
  );
};
