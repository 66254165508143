import { Fab } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ShoppingCart1Icon } from "../../../components/icons";
import { generatePortalMyStayCartUrl } from "../../../util/routing";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { CartBadge } from "./cart-badge";

interface CartFabProps {}

const useStyles = makeStyles()((theme) => ({
  fabContainer: {
    position: "fixed",
    right: theme.spacing(2),
    zIndex: 1150,
    userSelect: "none",
    bottom: `calc(${theme.spacing(9)} + env(safe-area-inset-bottom))`,
    [theme.breakpoints.up("lg")]: {
      right: `calc(50% - ${theme.breakpoints.values.lg / 2}px + ${theme.spacing(2)})`
    }
  },
  fab: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    boxShadow: "0px 6px 15px rgba(65, 60, 54, 0.15)",
    borderRadius: theme.shape.borderRadius * 2.5,

    "&:hover": {
      backgroundColor: theme.palette.common.black
    }
  }
}));

export const CartFab: React.FC<React.PropsWithChildren<CartFabProps>> = () => {
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.fabContainer}>
        <Fab className={classes.fab} onClick={() => navigate(generatePortalMyStayCartUrl(magicId))}>
          <CartBadge placement="fab">
            <ShoppingCart1Icon />
          </CartBadge>
        </Fab>
      </div>
      <div style={{ height: 64 }} />
    </>
  );
};
