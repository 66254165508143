import { FastField, FastFieldProps } from "formik";
import React from "react";
import { RadioGroupProps } from "@mui/material";
import { RadioButtonGroup } from "@likemagic-tech/sv-magic-library";

type FastRadioButtonGroupProps = Omit<RadioGroupProps, "onChange"> & {
  name: string;
};

export const FastRadioButtonGroup: React.FC<React.PropsWithChildren<FastRadioButtonGroupProps>> = (
  props
) => {
  return (
    <FastField name={props.name}>
      {({ form }: FastFieldProps) => <RadioButtonGroup {...props} onChange={form.handleChange} />}
    </FastField>
  );
};
