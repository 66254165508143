import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetAllPropertiesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAllPropertiesQuery = {
  __typename?: "Query";
  GetPropertiesByCity?: {
    __typename?: "PropertiesByCityAggregate";
    grouped?: Array<{
      __typename?: "PropertyGroup";
      key?: string | null;
      value?: Array<{
        __typename?: "Property";
        pmsId: string;
        timezone?: string | null;
        name?: string | null;
        defaultCurrency?: string | null;
        address?: {
          __typename?: "Address";
          addressLine1?: string | null;
          postalCode?: string | null;
          city?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export const GetAllPropertiesDocument = `
    query GetAllProperties {
  GetPropertiesByCity(filter: {}) {
    grouped {
      key
      value {
        pmsId
        timezone
        name
        defaultCurrency
        address {
          addressLine1
          postalCode
          city
        }
      }
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetAllProperties: build.query<GetAllPropertiesQuery, GetAllPropertiesQueryVariables | void>({
      query: (variables) => ({ document: GetAllPropertiesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetAllPropertiesQuery, useLazyGetAllPropertiesQuery } = injectedRtkApi;
