import { BaseApiClient, JSONApiResponse } from "@likemagic-tech/sv-magic-library";
import { PropertiesByCity } from "../domain-common/properties-by-city";

class PropertyApiClient extends BaseApiClient {
  async fetchPropertiesGroupByCity(init?: RequestInit): Promise<PropertiesByCity> {
    const response = await this.fetchApi(
      "/api/guest-journey-service/properties/grouped-by-city",
      init
    );
    return new JSONApiResponse<PropertiesByCity>(response).value();
  }
}

export const PropertyApi = new PropertyApiClient();
