import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { PrismicData } from "../../api/cms-client/use-cms-client";
import { PrismicDocumentToMyStayArticleDetailsMapper } from "../../mapper/prismic-document-to-my-stay-article-details";
import { PrismicDocumentToMyStayTipCardMapper } from "../../mapper/prismic-document-to-my-stay-tip-card";
import { RootState } from "../../state/store";
import { getI18nSelectedLanguage } from "../../util/lang-utils";
import { filter, PrismicDocument } from "@prismicio/client";
import { selectLastMagicId } from "../restore-magic-context/restore-magic-context.slice";

const myStayTipsAdapter = createEntityAdapter<PrismicDocument>({
  selectId: (model) => model.id,
  sortComparer: (a, b) => {
    // equal items sort equally
    if (a === b) {
      return 0;
    }
    // nulls sort after anything else
    else if (a?.first_publication_date === null) {
      return 1;
    } else if (b?.first_publication_date === null) {
      return -1;
    }
    return a.first_publication_date < b.first_publication_date ? 1 : -1;
  }
});

const initialState = myStayTipsAdapter.getInitialState();

export const fetchMyStayTips = createAsyncThunk<
  PrismicDocument[],
  { prismic: PrismicData; lang: string }
>("myStayTips/fetchMyStayTips", async (arg) => {
  const articlesResponse = await arg.prismic.prismicApiClient.get({
    filters: [filter.any("document.type", ["article", "link"])],
    orderings: { field: "document.first_publication_date", direction: "desc" },
    lang: arg.lang,
    // max https://prismic.io/docs/technologies/pagination-for-results-reactjs
    pageSize: 100,
    ...arg.prismic.refOptions
  });

  if (articlesResponse.total_pages < 2) {
    return articlesResponse.results;
  } else {
    return Promise.all(
      // - 1 because we already have first page
      Array.from(Array(articlesResponse.total_pages - 1).keys()).map(async (pageNo) => {
        return await arg.prismic.prismicApiClient.get({
          filters: [filter.any("document.type", ["article", "link"])],
          orderings: { field: "document.first_publication_date", direction: "desc" },
          lang: arg.lang,
          // max https://prismic.io/docs/technologies/pagination-for-results-reactjs
          pageSize: 100,
          page: pageNo + 2, //+1 because prismic does not start from 0 & +1 because we have first page
          ...arg.prismic.refOptions
        });
      })
    ).then((items) => {
      return items.reduce(
        (acc, item) => {
          acc = [...acc, ...item.results];
          return acc;
        },
        [...articlesResponse.results]
      );
    });
  }
});

export const fetchMyStayTipById = createAsyncThunk<
  PrismicDocument,
  { tipId?: string; prismic: PrismicData }
>("myStayTips/fetchMyStayTipById", async (arg) => {
  if (!arg.tipId) {
    throw new Error("`tipId` should be defined");
  }
  const idResponse = await arg.prismic.prismicApiClient.get({
    filters: [filter.at("document.id", arg.tipId)],
    lang: getI18nSelectedLanguage(),
    ...arg.prismic.refOptions
  });
  if (idResponse.results.length >= 1) {
    return idResponse.results[0];
  }
  const aliasResponse = await arg.prismic.prismicApiClient.get({
    filters: [filter.at("my.article.main__alias", arg.tipId)],
    lang: getI18nSelectedLanguage(),
    ...arg.prismic.refOptions
  });

  if (aliasResponse.results.length >= 1) {
    return aliasResponse.results[0];
  }
  console.error("document by id/alias " + arg.tipId + " not found on prismic");
  return Promise.reject("document by id/alias " + arg.tipId + " not found on prismic");
});

export const myStayTipsSlice = createSlice({
  name: "myStayTips",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyStayTips.fulfilled, (state, action) => {
        myStayTipsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchMyStayTipById.fulfilled, (state, action) => {
        myStayTipsAdapter.addOne(state, action.payload);
      });
  }
});

const selectSelf = (state: RootState) => state[myStayTipsSlice.name];

const { selectAll: selectAllMyStayTips } = myStayTipsAdapter.getSelectors(selectSelf);

export const selectAllMyStayTipCards = createSelector(
  selectAllMyStayTips,
  selectLastMagicId,
  (_: any, propertyId?: string) => propertyId,
  (tips, magicId, propertyId) => tips.map(PrismicDocumentToMyStayTipCardMapper(magicId, propertyId))
);

const selectMyStayTipById = createSelector(
  selectAllMyStayTips,
  (_: any, id: string) => id,
  (res, id: string) =>
    res.filter((document) => document.id === id || document.data.main__alias === id)[0]
);

export const selectMyStayArticleDetailsById = createSelector(selectMyStayTipById, (res) =>
  res ? PrismicDocumentToMyStayArticleDetailsMapper(res) : null
);
