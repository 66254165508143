import React, { FC, useCallback, useMemo } from "react";
import { DesktopCard, DisplayCmsSvg } from "../../../components";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { PaymentComponent } from "../../payment/payment-component";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import {
  clearPaymentResponses,
  clearPaymentState,
  selectPaymentSlice
} from "../../payment/payment.slice";
import {
  generatePortalDynamicShopConfirmationUrl,
  generatePortalPaymentRedirectUrl
} from "../../../util/routing";
import { useNavigate } from "react-router";
import { clearAdditionalServicesCart } from "../../additional-services/service-selection/additional-services-cart.slice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/store";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { GuestFlowBackgroundBox } from "../../guest-flow/components/guest-flow-background-box";
import { Box } from "@mui/material";
import { DynamicShopFlowPages, getDynamicShopFlowProgress } from "../dynamic-shop-pages";
import { useGetDynamicShopType } from "../use-get-dynamic-shop-type";
import { clearDynamicShopCart } from "../dynamic-shop.slice";

const currentPage = DynamicShopFlowPages.DYNAMIC_SHOP_PAYMENT;

export const DynamicShopPaymentPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const dispatch = useAppDispatch();
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { paymentDTO } = useSelector(selectPaymentSlice);
  const shopType = useGetDynamicShopType();

  const clearAfterPayment = useCallback(() => {
    if (paymentDTO.additionalServices) {
      dispatch(clearAdditionalServicesCart());
    }
    dispatch(clearPaymentResponses());
  }, [dispatch, paymentDTO]);

  const onSuccessPayment = useCallback(async () => {
    clearAfterPayment();
    dispatch(clearPaymentState());
    dispatch(clearDynamicShopCart());
    navigate(generatePortalDynamicShopConfirmationUrl(magicId, shopType));
  }, [shopType, navigate, dispatch, clearAfterPayment, magicId]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const returnUrl = useMemo(() => generatePortalPaymentRedirectUrl(magicId), [magicId]);

  return (
    <FlowTemplate handleBack={goBack} icons={[]} progress={getDynamicShopFlowProgress(currentPage)}>
      <GuestFlowBackgroundBox>
        <Box p={2}>
          <DesktopCard>
            <PageHeadingInfo
              title={t("title__dynamic_shop_payment_page")}
              icon={<DisplayCmsSvg url={cmsData?.data?.icon__bill_icon?.url} />}
            />
            <PaymentComponent onSuccessPayment={onSuccessPayment} returnUrl={returnUrl} />
          </DesktopCard>
        </Box>
      </GuestFlowBackgroundBox>
    </FlowTemplate>
  );
};
