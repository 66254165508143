import { ExtraHeading1, Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { ReactElement } from "react";

const useStyles = makeStyles()((theme) => ({
  passportDeletedBox: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  icon: {
    fontSize: theme.spacing(7.5)
  }
}));

export const DocumentPreview: React.FC<
  React.PropsWithChildren<{
    icon?: ReactElement;
    text?: string;
  }>
> = ({ icon, text }) => {
  const { classes } = useStyles();

  return (
    <Box p={2} className={classes.passportDeletedBox}>
      <Box p={1}>
        <ExtraHeading1 variant="subtitle1">
          {icon && React.cloneElement(icon, { className: classes.icon })}
        </ExtraHeading1>
      </Box>
      <Box p={1}>
        <Paragraph color="textSecondary"> {text} </Paragraph>
      </Box>
    </Box>
  );
};
