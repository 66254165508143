export enum SubUpdate {
  PERSONAL_DATA = "PERSONAL_DATA",
  PERSONAL_DATA_WITH_SECONDARY_GUESTS = "PERSONAL_DATA_WITH_SECONDARY_GUESTS",
  PERSONAL_DATA_FOR_SECONDARY_GUESTS = "PERSONAL_DATA_FOR_SECONDARY_GUESTS",
  ADDRESS = "ADDRESS",
  PREFERRED_CHANNEL = "PREFERRED_CHANNEL",
  LEGAL = "LEGAL",
  SERVICES = "SERVICES",
  PAYMENT = "PAYMENT",
  FLOW_STATE = "FLOW_STATE",
  SECONDARY_SCREEN = "SECONDARY_SCREEN"
}
