import React from "react";
import { emptyPrice, Price } from "../../../domain-common/price";
import { emptyFullPrice, FullPrice } from "../../../domain-common/full-price";

interface PricePreviewProps {
  price: Price;
  additionalInfo?: string;
}

export const formatPriceToString = (price: Price) => {
  try {
    return new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: price.currency
    }).format(price.amount / 100);
  } catch (e) {
    return "N/A";
  }
};

export const PricePreview: React.FC<React.PropsWithChildren<PricePreviewProps>> = ({
  price,
  additionalInfo
}) => {
  const formattedPrice = formatPriceToString(price);
  return <span>{additionalInfo ? formattedPrice + ` / ${additionalInfo}` : formattedPrice}</span>;
};

export const multiply = (price: Price, multiplier: number): Price => {
  return {
    currency: price.currency,
    amount: price.amount * multiplier
  };
};

export const sumPrices = (prices?: Price[]): Price => {
  if (!prices) {
    return emptyPrice();
  }

  return prices.reduce(
    (acc, currentPrice) => {
      if (acc.currency === "") {
        return currentPrice;
      }
      if (acc.currency !== currentPrice.currency) {
        console.error("Different currency inside balance");
        return emptyPrice();
      } else {
        const newPrice: Price = { ...acc };
        newPrice.amount = newPrice.amount + currentPrice.amount;
        return newPrice;
      }
    },
    { amount: 0, currency: "" }
  ); // todo
};

export const sumFullPrices = (prices?: FullPrice[]): FullPrice => {
  if (!prices) {
    return emptyFullPrice();
  }

  return prices.reduce((acc, currentPrice) => {
    if (acc.currency === "") {
      return currentPrice;
    }
    if (acc.currency !== currentPrice.currency) {
      console.error("Different currency inside balance");
      return emptyFullPrice();
    } else {
      const newPrice: FullPrice = { ...acc };
      newPrice.grossAmount = newPrice.grossAmount + currentPrice.grossAmount;
      newPrice.netAmount = newPrice.netAmount + currentPrice.netAmount;
      return newPrice;
    }
  }, emptyFullPrice("")); // todo
};
