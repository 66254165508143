import { Box } from "@mui/material";
import { RoundedFullHeight } from "../../components/layouts/rounded-full-height";
import { HelmetTitle } from "../gtm/helmet-title";
import { DesktopCard, PrismicRichTextWrapper } from "../../components";
import { useCMSData } from "../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../state/common-cms/common-cms.slice";
import { FlowTemplate } from "../../components/layouts/flow-template";
import { useNavigate } from "react-router-dom";
import { useBottomNavbar } from "../../components/organism/bottom-navbar/bottom-navbar-context";
import React, { useEffect } from "react";

export const PrivacyPolicyPage = () => {
  const navigate = useNavigate();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const { setDisplayBottomNavbar } = useBottomNavbar();

  useEffect(() => {
    setDisplayBottomNavbar(true);
  }, [setDisplayBottomNavbar]);

  return (
    <FlowTemplate handleBack={() => navigate(-1)} icons={[]}>
      <HelmetTitle suffix="Privacy policy" />
      <RoundedFullHeight>
        <DesktopCard>
          <Box ml={2.5} mr={2.5} pb={2}>
            <PrismicRichTextWrapper render={cmsData?.data?.["modals__tc_online"]} />
          </Box>
        </DesktopCard>
      </RoundedFullHeight>
    </FlowTemplate>
  );
};
