import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmationComponent, DesktopCard } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { useAppDispatch } from "../../../state/store";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { clearPaymentState } from "../../payment/payment.slice";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";
import { useCheckout } from "../../checkout-flow/use-checkout";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import {
  ConfirmationType,
  parseConfirmationTypeFromQueryParam
} from "../../../components/molecules/confirmation/confirmation-type";

interface PortalPaymentSuccessPageProps {}

export const PortalPaymentSuccessPage: React.FC<
  React.PropsWithChildren<PortalPaymentSuccessPageProps>
> = () => {
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const logoUrl = useLogoUrl();
  const { reservation } = useReservationContext();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const onConfirm = useCallback(() => {
    navigate(generatePortalMyStayUrl(magicId));
    dispatch(clearPaymentState());
  }, [dispatch, navigate, magicId]);

  const confirmationType = useMemo(() => parseConfirmationTypeFromQueryParam(search), [search]);

  const { checkoutAction } = useCheckout({ reservation });

  useEffect(() => {
    if (confirmationType === ConfirmationType.OPEN_BALANCE_WITH_CHECKOUT) {
      checkoutAction();
    }
  }, [checkoutAction, confirmationType]);

  const subtitleInfo = useMemo(() => {
    switch (confirmationType) {
      case ConfirmationType.OPEN_BALANCE:
        return tCommon("confirmations__settled_open_balance");
      case ConfirmationType.OPEN_BALANCE_WITH_CHECKOUT:
        return tCommon("confirmations__settled_open_balance_and_checkout");
      case ConfirmationType.EXTEND_STAY:
        return tCommon("confirmations__extend_stay");
      case ConfirmationType.ADDITIONAL_SERVICES:
        return tCommon("confirmations__additional_service");
      case ConfirmationType.SHOP:
        return tCommon("confirmations__shop");
    }
    console.warn(
      "Using default confirmations__shop label due to missing state.confirmationType mapping"
    );
    return tCommon("confirmations__shop");
  }, [tCommon, confirmationType]);

  return (
    <>
      <HelmetTitle suffix="Portal payment success" />
      <FlowTemplate>
        <RoundedFullHeight px={2.5}>
          <DesktopCard>
            <ConfirmationComponent
              onConfirm={onConfirm}
              subtitleInfo={subtitleInfo}
              submitButtonLabel={tCommon("buttons__go_to_my_stay")}
              logoSrc={logoUrl}
            />
          </DesktopCard>
        </RoundedFullHeight>
      </FlowTemplate>
    </>
  );
};
