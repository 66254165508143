import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
  Selector
} from "@reduxjs/toolkit";
import { RootState } from "../../state/store";
import { FullPrice } from "../../domain-common/full-price";
import { ShopType } from "../../graphql/generated/graphql";

export interface DynamicShopPreviewItem {
  serviceId: string;
  fullPrice: FullPrice;
  shopType: ShopType;
  isRent: boolean;
  categoryId?: string;
}
export interface DynamicShopCartItem extends DynamicShopPreviewItem {
  quantity: number;
}

export const getDynamicShopCartItemId = (item: DynamicShopCartItem) =>
  [item.serviceId, item.shopType].join(",");

const dynamicShopCartAdapter = createEntityAdapter<DynamicShopCartItem>({
  selectId: getDynamicShopCartItemId
});

interface BookingCartSliceState {
  selectedFilters: Record<string, boolean>;
}

export const initialState = dynamicShopCartAdapter.getInitialState<BookingCartSliceState>({
  selectedFilters: {}
});

export const dynamicShopCartSlice = createSlice({
  name: "dynamicShop",
  initialState,
  reducers: {
    addToDynamicShopCart: (state, action: PayloadAction<DynamicShopCartItem>) => {
      dynamicShopCartAdapter.upsertOne(state, {
        ...action.payload
      });
    },
    removeFromDynamicShopCart: (state, action: PayloadAction<string>) => {
      dynamicShopCartAdapter.removeOne(state, action.payload);
    },
    clearDynamicShopCart: (state) => {
      state.entities = initialState.entities;
      state.ids = initialState.ids;
    },
    toggleDynamicShopFilter: (state, action: PayloadAction<{ filterId: string } | undefined>) => {
      if (!action.payload) {
        state.selectedFilters = {};
        return;
      }

      if (state.selectedFilters[action.payload.filterId]) {
        delete state.selectedFilters[action.payload.filterId];
      } else {
        state.selectedFilters[action.payload.filterId] = true;
      }
    },
    clearDynamicShopFilter: (state) => {
      state.selectedFilters = {};
    }
  },
  extraReducers: () => {}
});

export const { toggleDynamicShopFilter, clearDynamicShopFilter } = dynamicShopCartSlice.actions;

export const { addToDynamicShopCart, removeFromDynamicShopCart, clearDynamicShopCart } =
  dynamicShopCartSlice.actions;

export const {
  selectAll: selectAllFromDynamicShopCart,
  selectById: selectByIdFromDynamicShopCart
} = dynamicShopCartAdapter.getSelectors<RootState>((state) => state[dynamicShopCartSlice.name]);

const selectSelf: Selector<RootState, BookingCartSliceState> = (state: RootState) =>
  state[dynamicShopCartSlice.name];

export const selectDynamicShopSelectedFilters = createSelector(
  selectSelf,
  (s) => s.selectedFilters
);
export const selectAllShopCartPerType = createSelector(
  selectAllFromDynamicShopCart,
  (_: any, shopType: string) => shopType,
  (s, shopType) => s.filter((item) => item.shopType === shopType)
);
