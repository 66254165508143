import { MyStayTipContentType } from "./my-stay-tip-content-type";

export enum TipType {
  ARTICLE = "article",
  LINK = "link"
}

export interface MyStayTipCard {
  id: string;
  propertyId?: string;
  title: string;
  subtitle?: string;
  coverImage?: string;
  contentType: MyStayTipContentType;
  type: TipType;
  link?: string;
  onClick?: () => void;
  tags?: string[];
}
