import { Grid } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import React from "react";
import { FastInput } from "../../../components/molecules/fast-input";
import { WrappedNativeSelect } from "../../../components/atoms/input/wrapped-native-select";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { MainGuestFormData } from "../../../domain-common/second-screen-checkin";
import { useGenderOptions } from "../../../util/hooks/use-gender-options";
import { useCountries } from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { FastPhoneInput } from "../../../components/molecules/fast-phone-input";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

export interface SecondScreenMainGuestFormProps {
  objectPathPrefix?: string;
  values: MainGuestFormData;
  onChange: (person: any) => void;
  touched?: FormikTouched<MainGuestFormData>;
  errors?: FormikErrors<MainGuestFormData>;
  setFieldValue: (field: string, value: any) => void;
}

export const SecondScreenMainGuestForm: React.FC<
  React.PropsWithChildren<SecondScreenMainGuestFormProps>
> = ({ objectPathPrefix, values, onChange, touched, errors, setFieldValue }) => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { genderDisplayDisabled } = useFeatureFlags();
  const { genderOptions } = useGenderOptions();
  const { reservation } = useReservationContext();
  const { sortedCountries, preferredCountries } = useCountries({
    propertyId: reservation?.propertyId
  });

  return (
    <Grid container spacing={2}>
      {!genderDisplayDisabled && (
        <Grid item xs={4} md={2}>
          <WrappedNativeSelect
            items={genderOptions}
            id={[objectPathPrefix, `gender`].join("")}
            name={[objectPathPrefix, `gender`].join("")}
            value={values.gender}
            label={tCommon("labels__gender")}
            onChange={onChange}
          />
        </Grid>
      )}
      <Grid item xs={8} md={4}>
        <FastInput
          id={[objectPathPrefix, `firstName`].join("")}
          name={[objectPathPrefix, `firstName`].join("")}
          value={values.firstName || ""}
          label={tCommon("labels__first_name")}
          error={touched?.firstName && errors?.firstName}
          autoComplete="nope"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastInput
          id={[objectPathPrefix, `lastName`].join("")}
          name={[objectPathPrefix, `lastName`].join("")}
          value={values.lastName || ""}
          label={tCommon("labels__last_name")}
          error={touched?.lastName && errors?.lastName}
          autoComplete="nope"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastInput
          id={[objectPathPrefix, `email`].join("")}
          name={[objectPathPrefix, `email`].join("")}
          value={values.email || ""}
          type="email"
          label={tCommon("labels__email")}
          error={touched?.email && errors?.email}
          autoComplete="nope"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastPhoneInput
          id={[objectPathPrefix, `phone`].join("")}
          name={[objectPathPrefix, `phone`].join("")}
          value={values.phone || ""}
          label={tCommon("labels__phone")}
          onChange={(newValue: string) => setFieldValue("mainGuest.phone", newValue)}
          error={(touched?.phone && !!errors?.phone) || undefined}
          fullWidth
          helperText={touched?.phone && errors?.phone}
          placeholder={tCommon("labels__phone")}
          autoComplete="nope"
          preferredCountries={preferredCountries}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastInput
          id={[objectPathPrefix, `addressLine1`].join("")}
          name={[objectPathPrefix, `addressLine1`].join("")}
          value={values.addressLine1 || ""}
          type="address"
          label={tCommon("labels__address_line_1")}
          error={touched?.addressLine1 && errors?.addressLine1}
          autoComplete="nope"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastInput
          id={[objectPathPrefix, `addressLine2`].join("")}
          name={[objectPathPrefix, `addressLine2`].join("")}
          value={values.addressLine2 || ""}
          type="address"
          label={tCommon("labels__address_line_2")}
          error={touched?.addressLine2 && errors?.addressLine2}
          autoComplete="nope"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastInput
          id={[objectPathPrefix, `city`].join("")}
          name={[objectPathPrefix, `city`].join("")}
          value={values.city || ""}
          label={tCommon("labels__city")}
          error={touched?.city && errors?.city}
          autoComplete="nope"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastInput
          id={[objectPathPrefix, `region`].join("")}
          name={[objectPathPrefix, `region`].join("")}
          value={values.region || ""}
          label={tCommon("labels__region")}
          error={touched?.region && errors?.region}
          autoComplete="nope"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastInput
          id={[objectPathPrefix, `postcode`].join("")}
          name={[objectPathPrefix, `postcode`].join("")}
          value={values.postcode || ""}
          type="number"
          label={tCommon("labels__postcode")}
          error={touched?.postcode && errors?.postcode}
          autoComplete="nope"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WrappedNativeSelect
          items={sortedCountries(getI18nSelectedLanguage())}
          id={[objectPathPrefix, `country`].join("")}
          name={[objectPathPrefix, `country`].join("")}
          value={values.country || ""}
          label={tCommon("labels__country")}
          error={touched?.country && errors?.country}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastInput
          id={[objectPathPrefix, `frequentFlyerProgram`].join("")}
          name={[objectPathPrefix, `frequentFlyerProgram`].join("")}
          value={values.frequentFlyerProgram || ""}
          label={tCommon("labels__frequent_flyer_program")}
          error={touched?.frequentFlyerProgram && errors?.frequentFlyerProgram}
          autoComplete="nope"
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <FastInput
          id={[objectPathPrefix, `membershipNumber`].join("")}
          name={[objectPathPrefix, `membershipNumber`].join("")}
          value={values.membershipNumber || ""}
          label={tCommon("labels__membership_number")}
          error={touched?.membershipNumber && errors?.membershipNumber}
          autoComplete="nope"
        />
      </Grid>
    </Grid>
  );
};
