import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function Error404Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          d="M3.01,22.503c-1.241,0-2.25-1.009-2.25-2.25v-16.5c0-1.241,1.009-2.25,2.25-2.25h18c1.241,0,2.25,1.009,2.25,2.25v16.5
		c0,1.241-1.009,2.25-2.25,2.25H3.01z M2.26,20.253c0,0.414,0.336,0.75,0.75,0.75h18c0.414,0,0.75-0.336,0.75-0.75V7.503H2.26
		V20.253z M21.76,6.003v-2.25c0-0.414-0.336-0.75-0.75-0.75h-18c-0.414,0-0.75,0.336-0.75,0.75v2.25H21.76z"
        />
        <path
          d="M7.51,18.003c-0.414,0-0.75-0.336-0.75-0.75v-0.75H4.51c-0.414,0-0.75-0.336-0.75-0.75v-1.5c0-2.068,1.682-3.75,3.75-3.75
		c0.414,0,0.75,0.336,0.75,0.75v6C8.26,17.667,7.923,18.003,7.51,18.003z M6.76,15.003v-2.871c-0.879,0.313-1.5,1.159-1.5,2.121
		v0.75H6.76z"
        />
        <path
          d="M19.51,18.003c-0.414,0-0.75-0.336-0.75-0.75v-0.75h-2.25c-0.414,0-0.75-0.336-0.75-0.75v-1.5
		c0-2.068,1.682-3.75,3.75-3.75c0.414,0,0.75,0.336,0.75,0.75v6C20.26,17.667,19.923,18.003,19.51,18.003z M18.76,15.003v-2.871
		c-0.879,0.313-1.5,1.159-1.5,2.121L18.76,15.003z"
        />
        <path
          d="M12.01,18.003c-1.241,0-2.25-1.009-2.25-2.25v-3c0-1.241,1.009-2.25,2.25-2.25c1.241,0,2.25,1.009,2.25,2.25v3
		C14.26,16.994,13.25,18.003,12.01,18.003z M12.01,12.003c-0.414,0-0.75,0.336-0.75,0.75v3c0,0.414,0.336,0.75,0.75,0.75
		s0.75-0.336,0.75-0.75v-3C12.76,12.339,12.423,12.003,12.01,12.003z"
        />
      </g>
    </SvgIcon>
  );
}
