import React, { FC, lazy, ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { openDoor, stopScanning } from "./door-utils";
import { KeysHandlerProps } from "./keys-handler";
import { ErrorCircle } from "../../components/icons/error-circle";
import { SuccessCircle } from "../../components/icons/success-circle";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { SubmitButton } from "../../components";
import {
  Heading3,
  KeyCard,
  TenantContext,
  useDoorProviderConfig
} from "@likemagic-tech/sv-magic-library";
import { useAppDispatch } from "../../state/store";
import { useLogoUrl } from "../../util/hooks/use-logo-url";
import { useCmsPropertySpecificData } from "../../state/cms/use-cms-per-property-data";
import { useLogDoorStatus } from "./use-log-door-status";
import { errorHandleForComponentLazyLoad } from "../../util/code-split.util";

enum MobileKeyEnum {
  IDLE = "IDLE",
  OPENING = "OPENING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  BLUETOOTH_ERROR = "BLUETOOTH_ERROR",
  NO_MOBILE_KEY_ERROR = "NO_MOBILE_KEY_ERROR",
  TIMEOUT = "TIMEOUT"
}

const KeyMobileAnimation = lazy(() =>
  import("./key-mobile-animation")
    .then(({ KeyMobileAnimation }) => ({
      default: KeyMobileAnimation
    }))
    .catch(errorHandleForComponentLazyLoad)
);
const displayMobileKeyStatus = (
  keyStatus: MobileKeyEnum,
  theme: string
): { component: ReactElement | null; text: string } => {
  if (keyStatus === MobileKeyEnum.OPENING) {
    return {
      component: (
        <Box mt={2} mb={-8}>
          <KeyMobileAnimation theme={theme} />
        </Box>
      ),
      text: "labels__mobile_key_opening"
    };
  } else if (keyStatus === MobileKeyEnum.SUCCESS) {
    return {
      component: <SuccessCircle fontSize="inherit" />,
      text: "labels__mobile_key_opening_success"
    };
  } else if (keyStatus === MobileKeyEnum.ERROR) {
    return {
      component: <ErrorCircle fontSize="inherit" />,
      text: "labels__mobile_key_opening_failed"
    };
  } else if (keyStatus === MobileKeyEnum.TIMEOUT) {
    return {
      component: <ErrorCircle fontSize="inherit" />,
      text: "labels__mobile_key_timeout"
    };
  } else if (keyStatus === MobileKeyEnum.BLUETOOTH_ERROR) {
    return {
      component: <ErrorCircle fontSize="inherit" />,
      text: "labels__mobile_key_bluetooth"
    };
  } else if (keyStatus === MobileKeyEnum.NO_MOBILE_KEY_ERROR) {
    return {
      component: <ErrorCircle fontSize="inherit" />,
      text: "labels__mobile_key_no_error"
    };
  }
  return {
    component: null,
    text: ""
  };
};

const useStyles = makeStyles()(({ spacing }) => ({
  logo: {
    height: spacing(7),
    maxWidth: "100%"
  }
}));

const delay = (t: number) => {
  return new Promise((resolve) => setTimeout(resolve, t));
};

export const OpenDoorWithApp: FC<React.PropsWithChildren<KeysHandlerProps>> = ({
  door,
  reservation
}) => {
  const dispatch = useAppDispatch();
  const [keyStatus, setKeyStatus] = useState(MobileKeyEnum.IDLE);
  const { theme } = useContext(TenantContext);
  const [keyData, setKeyData] = useState<string | undefined>();
  const logoUrl = useLogoUrl();
  const cmsPerPropertyData = useCmsPropertySpecificData(reservation.propertyId);
  const postLogDoorStatus = useLogDoorStatus();
  const { classes } = useStyles();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const config = useDoorProviderConfig(reservation.propertyId)!;

  const openTheDoor = useCallback(() => {
    setKeyStatus(MobileKeyEnum.OPENING);
    setKeyData(undefined);
    openDoor(config.doorProvider!, door.id)
      .then(({ value }) => {
        setKeyData(value);
        setKeyStatus(MobileKeyEnum.SUCCESS);
      })
      .then(() => delay(3000))
      .then(() => openTheDoor())
      .catch((error) => {
        setKeyData(error.message);
        //if bluetooth is disabled we have no control over the error message
        const errorMessage = error.message.toLowerCase();
        if (errorMessage.includes("bluetooth")) {
          setKeyStatus(MobileKeyEnum.BLUETOOTH_ERROR);
        } else if (errorMessage.includes("timeout")) {
          setKeyStatus(MobileKeyEnum.TIMEOUT);
        } else if (errorMessage.includes("no mobile key")) {
          setKeyStatus(MobileKeyEnum.NO_MOBILE_KEY_ERROR);
        } else {
          setKeyStatus(MobileKeyEnum.ERROR);
        }
      });
  }, [door.id, config.doorProvider]);

  useEffect(() => {
    openTheDoor();
    return () => {
      setKeyStatus(MobileKeyEnum.IDLE);
      setKeyData(undefined);
      //Do a cleanup if needed
    };
  }, [openTheDoor]);

  useEffect(() => {
    if (
      keyData &&
      [
        MobileKeyEnum.BLUETOOTH_ERROR,
        MobileKeyEnum.NO_MOBILE_KEY_ERROR,
        MobileKeyEnum.TIMEOUT,
        MobileKeyEnum.ERROR,
        MobileKeyEnum.SUCCESS
      ].includes(keyStatus)
    ) {
      postLogDoorStatus({
        magicId: reservation.magicId,
        magicToken: reservation.magicToken,
        doorId: door.id,
        data: keyData,
        status: keyStatus === MobileKeyEnum.SUCCESS
      });
    }
  }, [
    keyData,
    keyStatus,
    dispatch,
    reservation.magicId,
    reservation.magicToken,
    door.id,
    postLogDoorStatus
  ]);

  useEffect(() => {
    return () => {
      stopScanning(config.doorProvider!);
    };
  }, [config.doorProvider]);

  const { component: statusComponent, text: statusText } = displayMobileKeyStatus(keyStatus, theme);
  return (
    <Grid container alignItems="center" direction="column">
      <KeyCard
        unitName={reservation.unit?.name ?? ""}
        labels={{ unit: t("labels__unit"), tag: "" }}
        PropertyLogo={<img src={logoUrl} alt="logo" className={classes.logo} />}
        KeyProvideLogo={
          cmsPerPropertyData?.data?.doors__provider_logo &&
          Object.keys(cmsPerPropertyData?.data?.doors__provider_logo).length ? (
            <img
              src={cmsPerPropertyData?.data?.doors__provider_logo?.url}
              alt="logo"
              className={classes.logo}
            />
          ) : (
            <> </>
          )
        }
      />

      <Grid container justifyContent="center" alignContent="center" minHeight={200} fontSize={100}>
        {statusComponent}
      </Grid>
      <Heading3 textAlign="center">{t(statusText)}</Heading3>
      {(keyStatus === MobileKeyEnum.ERROR || keyStatus === MobileKeyEnum.TIMEOUT) && (
        <SubmitButton
          hasWhiteBackground
          hasBottomNavigation
          label={t("buttons__try_again")}
          onClick={openTheDoor}
        />
      )}
    </Grid>
  );
};
