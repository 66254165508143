import { Backdrop, Box, Fade, SpeedDialIcon } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { use100vh } from "react-div-100vh";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LongPressDetectEvents, LongPressOptions, useLongPress } from "use-long-press";
import { AccessibleDoor } from "../../../domain-common/reservation";
import {
  clearError,
  selectKeysError,
  selectKeysOpen,
  setUnlocked
} from "../../portal/portal.slice";
import { useAppDispatch } from "../../../state/store";
import { createAnimation } from "../../../util/animations";
import { generatePortalMyStayKeysUrl } from "../../../util/routing";
import {
  Button,
  Heading2,
  useDoorProviderConfig,
  useGlobalModal
} from "@likemagic-tech/sv-magic-library";
import { SpeedDial, SpeedDialAction, UnlockAnimation } from "../../../components";
import {
  AddIcon,
  AlertCircleIcon,
  KeyIcon,
  KeyMainDoorIcon,
  KeyOtherIcon,
  KeyRoomIcon
} from "../../../components/icons";
import { getMainDoor, getRoomDoor } from "./helpers";
import { useKeysSpeedDialStyles } from "./use-keys-speed-dial-styles";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { useBanner } from "../../banner/use-banner";
import { useCommonErrorTranslation } from "../../../state/common-cms/use-common-error-translation";
import { isReservationReadyToRequestPrivateKeys } from "../../../util/reservation";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useCheckIn } from "../../guest-flow/hooks/use-checkin";
import { useOpenDoor } from "../use-open-door";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { getOkModalArg } from "../../global-modal/global-modal-util";
import { useIsRequestKeyAvailableWithOpenBalance } from "../../../util/hooks/use-is-request-key-available-with-open-balance";
import { isTravelBuddy } from "../../../util/flow";

const threshold = 2000;

let speedDialOpenIcon = <AddIcon style={{ transform: "rotate(45deg)" }} />;
let speedDialIcon = <KeyIcon />;

export const KeysSpeedDialApi: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { reservation } = useReservationContext();
  const unlocked = useSelector(selectKeysOpen);
  const error = useSelector(selectKeysError);
  const { bannerState } = useBanner();
  const translatedError = useCommonErrorTranslation(bannerState.errorId);
  const navigate = useNavigate();
  const config = useDoorProviderConfig(reservation.propertyId);
  const [open, setOpen] = React.useState(false);
  const [lockShankOpen, setLockShankOpen] = React.useState(false);
  const timer = React.useRef<number>(0);
  const { open: openModal } = useGlobalModal();
  const { privateDoorsDisabled } = useFeatureFlags();

  const accessWithOpenBalance = useIsRequestKeyAvailableWithOpenBalance({ reservation });

  const mainDoorPrefixes = useMemo(
    () => config?.mainDoorPrefixes || [],
    [config?.mainDoorPrefixes]
  );
  const unlockAnimationRef = React.useRef<{
    setPercent: (percent: number) => void;
    getPercent: () => number;
  }>(null);
  const animationRef = React.useRef<{
    start: () => void;
    stop: () => void;
  } | null>(null);

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const close = useCallback(() => {
    setOpen(false);
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
    window.setTimeout(() => {
      setLockShankOpen(false);
      unlockAnimationRef.current?.setPercent(0);
      dispatch(setUnlocked({ unlocked: false }));
      dispatch(clearError());
    }, 200);
  }, [dispatch, unlockAnimationRef]);
  const checkin = useCheckIn({ reservation });

  const handleOpen = useCallback(
    async (_e: any, reason: string) => {
      if (reason === "toggle") {
        if (isReservationReadyToRequestPrivateKeys(reservation)) {
          if (!accessWithOpenBalance && !isTravelBuddy(reservation)) {
            await openModal(
              getOkModalArg(
                t("modals__keys_not_yet_available_title"),
                t("modals__keys_not_available_because_open_balance"),
                t("buttons__ok")
              )
            );
            return; //Skip redirection to keys page
          }
          const requestKey = await openModal({
            modalProps: {
              title: t("modals__request_private_key_title"),
              content: <Box>{t("modals__request_private_key_content")}</Box>
            },
            modalActions: [
              {
                variant: "primary",
                label: t("buttons__request_access"),
                result: true
              },
              {
                variant: "secondary",
                label: t("buttons__not_yet"),
                result: false
              }
            ]
          });
          if (requestKey) {
            await checkin();
          }
        }
        setOpen(true);
      }
    },
    [checkin, openModal, reservation, t, accessWithOpenBalance]
  );

  const handleClose = (_e: any, reason: string) => {
    if (reason === "toggle") {
      close();
    }
  };

  const animateUnlocking = useCallback(() => {
    animationRef.current?.stop();
    animationRef.current = createAnimation((progress) => {
      unlockAnimationRef.current?.setPercent(progress * 100);
    }, threshold);
    animationRef.current?.start();
  }, [animationRef, unlockAnimationRef]);
  const openDoorAction = useOpenDoor();

  const animateLocking = useCallback(() => {
    const currentAnimationPercent = unlockAnimationRef.current?.getPercent();
    animationRef.current?.stop();
    animationRef.current = createAnimation((progress) => {
      if (currentAnimationPercent) {
        unlockAnimationRef.current?.setPercent(
          currentAnimationPercent - progress * currentAnimationPercent
        );
      }
    }, 300);
    animationRef.current?.start();
  }, [animationRef, unlockAnimationRef]);

  let longPressOptions: LongPressOptions = useMemo(
    () => ({
      onStart: () => {
        dispatch(clearError());
        dispatch(setUnlocked({ unlocked: false }));
        if (timer.current) {
          window.clearTimeout(timer.current);
        }
        animateUnlocking();
      },
      onCancel: () => {
        dispatch(setUnlocked({ unlocked: false }));
        dispatch(clearError());
        animateLocking();
      },
      threshold,
      captureEvent: true,
      detect: LongPressDetectEvents.BOTH
    }),
    [dispatch, animateLocking, animateUnlocking]
  );

  const handleDoorLongPressCallback = useCallback(
    (door: AccessibleDoor | undefined) => async () => {
      if (!reservation || !reservation.accessibleDoors) {
        return [];
      }
      if (!door) {
        return;
      }
      let isErrorOccurred = false;
      try {
        await openDoorAction({ doorId: door.id, reservation });
        setLockShankOpen(true);
      } catch (e) {
        animateLocking();
        dispatch(setUnlocked({ unlocked: false }));
        isErrorOccurred = true;
      } finally {
        timer.current = window.setTimeout(() => {
          animateLocking();
          setLockShankOpen(false);
          if (!isErrorOccurred) {
            close();
          }

          dispatch(setUnlocked({ unlocked: false }));
          dispatch(clearError());
        }, 5000);
      }
    },
    [animateLocking, close, dispatch, reservation, openDoorAction]
  );

  const keyMainDoorLongPress = useLongPress(
    handleDoorLongPressCallback(getMainDoor(reservation, mainDoorPrefixes)),
    longPressOptions
  );
  const keyRoomLongPress = useLongPress(
    handleDoorLongPressCallback(getRoomDoor(reservation)),
    longPressOptions
  );

  const actions = useMemo(() => {
    if (!reservation || !reservation.accessibleDoors) {
      return [];
    }

    const roomDoor = privateDoorsDisabled ? undefined : getRoomDoor(reservation);
    const mainDoor = getMainDoor(reservation, mainDoorPrefixes);

    const tmp = [];
    if (roomDoor) {
      tmp.push({
        icon: <KeyRoomIcon />,
        name: t("buttons__private_door"),
        longPress: keyRoomLongPress
      });
    }
    if (mainDoor) {
      tmp.push({
        icon: <KeyMainDoorIcon />,
        name: t("buttons__main_door"),
        longPress: keyMainDoorLongPress
      });
    }
    if (reservation.accessibleDoors.length > 2) {
      tmp.push({
        icon: <KeyOtherIcon />,
        name: t("buttons__other_door"),
        handleAction: () => {
          setOpen(false);
          // wait for animation to end
          window.setTimeout(() => {
            navigate(generatePortalMyStayKeysUrl(reservation.magicId));
          }, 200);
        }
      });
    }

    return tmp;
  }, [
    t,
    reservation,
    navigate,
    keyRoomLongPress,
    keyMainDoorLongPress,
    mainDoorPrefixes,
    privateDoorsDisabled
  ]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      // wait for animation to end
      timer.current = window.setTimeout(() => {
        unlockAnimationRef.current?.setPercent(0);
        dispatch(setUnlocked({ unlocked: false }));
      }, 250);
    }
  }, [open, dispatch, timer]);

  useEffect(
    () => () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    },
    [timer]
  );

  const height100vh = use100vh();
  const { classes } = useKeysSpeedDialStyles({
    error,
    height100vh
  });

  const helpText = useMemo(() => {
    if (error) {
      return translatedError ? translatedError.text : t("validations__keys_error");
    }
    return unlocked ? t("labels__door_open_success") : t("title__keys_speed_dial_description");
  }, [error, translatedError, unlocked, t]);

  if (!reservation?.accessibleDoors?.length) {
    return null;
  }
  return (
    <>
      <Fade in={open}>
        <div className={classes.help}>
          <div className={classes.helpIcon}>
            {error ? (
              <AlertCircleIcon classes={{ root: classes.alertIcon }} />
            ) : (
              <UnlockAnimation lockShankOpen={lockShankOpen} ref={unlockAnimationRef} />
            )}
          </div>
          <div className={classes.helpText}>
            <Heading2 align={"center"} style={{ marginTop: "16px" }}>
              {helpText}
            </Heading2>
          </div>
          {error && translatedError?.onClick && (
            <Button variant="primary" onClick={translatedError.onClick} size="small">
              {translatedError.buttonText}
            </Button>
          )}
        </div>
      </Fade>
      <Backdrop open={open} onClick={close} className={classes.backdrop} />
      <SpeedDial
        className={classes.speedDial}
        icon={<SpeedDialIcon icon={speedDialIcon} openIcon={speedDialOpenIcon} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            title={action.name}
            onClick={action.handleAction}
            FabProps={{ ...action.longPress }}
            TooltipClasses={{ popper: classes.tooltipPopper }}
          />
        ))}
      </SpeedDial>
    </>
  );
};
