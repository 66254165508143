import { createSelector, createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { RootState } from "../../state/store";
import { fetchMagicObject } from "../magic/magic-object.slice";

type InitialMagicIdState = {
  initialMagicId?: string;
};

const initialState: InitialMagicIdState = {
  initialMagicId: undefined
};

/**
 * Magic context about initial magic info
 * (mainly used not to lose booking magicId if you initially loaded app with booking id)
 */
export const initialMagicInfoSlice = createSlice({
  name: "initialMagicInfo",
  initialState,
  reducers: {
    setInitialMagicId: (state, action: PayloadAction<{ initialMagicId: string }>) => {
      state.initialMagicId = action.payload.initialMagicId;
    },

    clearInitialMagicId: (state) => {
      state.initialMagicId = initialState.initialMagicId;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMagicObject.rejected, (state) => {
      state.initialMagicId = undefined;
    });
  }
});

export const { setInitialMagicId, clearInitialMagicId } = initialMagicInfoSlice.actions;

export const selectSelf: Selector<RootState, InitialMagicIdState> = (state: RootState) =>
  state[initialMagicInfoSlice.name];

export const selectInitialMagicId = createSelector(selectSelf, (state) => state.initialMagicId);
