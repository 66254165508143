import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetPaymentMethodsQueryVariables = Types.Exact<{
  magicId?: Types.InputMaybe<Types.Scalars["String"]>;
  shopperReference: Types.Scalars["String"];
}>;

export type GetPaymentMethodsQuery = {
  __typename?: "Query";
  GetPaymentMethodsMagicId: Array<
    | {
        __typename: "CardPaymentMethod";
        type: Types.PaymentMethodType;
        paymentMethods?: Array<{
          __typename?: "PossiblePaymentMethod";
          brand?: string | null;
          brands?: Array<string | null> | null;
          configuration?: any | null;
          fundingSource?: string | null;
          group?: any | null;
          inputDetails?: string | null;
          issuers?: string | null;
          name?: string | null;
          type?: string | null;
        } | null> | null;
      }
    | { __typename: "CashPaymentMethod"; type: Types.PaymentMethodType }
    | { __typename: "MewsPaymentMethod"; type: Types.PaymentMethodType }
    | { __typename: "PayAtCheckinPaymentMethod"; type: Types.PaymentMethodType }
    | { __typename: "PayAtCheckoutPaymentMethod"; type: Types.PaymentMethodType }
    | { __typename: "PaymentLinkPaymentMethod"; type: Types.PaymentMethodType }
    | { __typename: "PreAuthorizationPaymentMethod"; type: Types.PaymentMethodType }
    | {
        __typename: "StoredPaymentMethod";
        type: Types.PaymentMethodType;
        config?: {
          __typename?: "StoredPaymentConfig";
          brand?: string | null;
          expiryMonth?: string | null;
          expiryYear?: string | null;
          holderName?: string | null;
          iban?: string | null;
          id?: string | null;
          lastFour?: string | null;
          name?: string | null;
          ownerName?: string | null;
          shopperEmail?: string | null;
          supportedShopperInteractions?: Array<string | null> | null;
          type?: string | null;
        } | null;
      }
    | {
        __typename: "TerminalPaymentMethod";
        type: Types.PaymentMethodType;
        availableTerminals?: Array<{
          __typename?: "AvailableTerminal";
          id?: string | null;
          name?: string | null;
        } | null> | null;
      }
  >;
};

export const GetPaymentMethodsDocument = `
    query GetPaymentMethods($magicId: String, $shopperReference: String!) {
  GetPaymentMethodsMagicId(magicId: $magicId, shopperReference: $shopperReference) {
    __typename
    ... on PaymentLinkPaymentMethod {
      type
    }
    ... on CardPaymentMethod {
      paymentMethods {
        brand
        brands
        configuration
        fundingSource
        group
        inputDetails
        issuers
        name
        type
      }
      type
    }
    ... on CashPaymentMethod {
      type
    }
    ... on MewsPaymentMethod {
      type
    }
    ... on PayAtCheckoutPaymentMethod {
      type
    }
    ... on PayAtCheckinPaymentMethod {
      type
    }
    ... on PreAuthorizationPaymentMethod {
      type
    }
    ... on StoredPaymentMethod {
      config {
        brand
        expiryMonth
        expiryYear
        holderName
        iban
        id
        lastFour
        name
        ownerName
        shopperEmail
        supportedShopperInteractions
        type
      }
      type
    }
    ... on PaymentLinkPaymentMethod {
      type
    }
    ... on TerminalPaymentMethod {
      type
      availableTerminals {
        id
        name
      }
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetPaymentMethods: build.query<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>({
      query: (variables) => ({ document: GetPaymentMethodsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetPaymentMethodsQuery, useLazyGetPaymentMethodsQuery } = injectedRtkApi;
