import { EntityStateStatus, isStatusLoading } from "../../state/EntityStateStatus";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { handleSliceError } from "../../util/error-handling";
import { BoxShopApi } from "../../api/box-shop.api";
import { BoxEvent } from "./box-shop-events";
import { BoxAvailability } from "../../domain-v1/box-availability";

interface BoxShopSlice {
  status: EntityStateStatus;
  currentBoxStatus: BoxEvent;
}

interface ChangeBoxArgs {
  boxId: string;
  magicId: string;
  magicToken: string;
}

const boxAvailabilityEntityAdapter = createEntityAdapter<BoxAvailability>({
  selectId: (model) => model.boxId
});

const initialState = boxAvailabilityEntityAdapter.getInitialState<BoxShopSlice>({
  status: EntityStateStatus.IDLE,
  currentBoxStatus: BoxEvent.INIT
});

export const reserveBox = createAsyncThunk<
  void,
  ChangeBoxArgs,
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("boxShop/reserveBox", async (arg, thunkAPI) => {
  try {
    return await BoxShopApi.reserveBox(arg, { signal: thunkAPI.signal });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const fetchBoxAvailability = createAsyncThunk<
  Array<BoxAvailability>,
  { magicId: string; magicToken: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("boxShop/fetchAvailability", async (arg, thunkAPI) => {
  try {
    return await BoxShopApi.fetchBoxAvailability(arg, {
      signal: thunkAPI.signal
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});
export const fetchBoxDetails = createAsyncThunk<
  BoxAvailability,
  { magicId: string; magicToken: string; boxId: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("boxShop/fetchBoxDetails", async (arg, thunkAPI) => {
  try {
    return await BoxShopApi.fetchBoxDetails(arg, {
      signal: thunkAPI.signal
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const boxShopSlice = createSlice({
  name: "boxShop",
  initialState,
  reducers: {
    setCurrentBoxStatus: (state, action: PayloadAction<BoxEvent>) => {
      state.currentBoxStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoxAvailability.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchBoxAvailability.fulfilled, (state, action) => {
        boxAvailabilityEntityAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchBoxAvailability.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      })
      .addCase(fetchBoxDetails.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchBoxDetails.fulfilled, (state, action) => {
        boxAvailabilityEntityAdapter.addOne(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchBoxDetails.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      })
      .addCase(reserveBox.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(reserveBox.fulfilled, (state) => {
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(reserveBox.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer, actions } = boxShopSlice;

export const { setCurrentBoxStatus } = actions;

const selectSelf = (state: RootState) => state[boxShopSlice.name];

export const { selectAll: selectBoxAvailability, selectById: selectBoxByBoxId } =
  boxAvailabilityEntityAdapter.getSelectors<RootState>(selectSelf);

export const selectIsBoxReserving = createSelector(selectSelf, (s) => isStatusLoading(s.status));
export const selectCurrentBoxStatus = createSelector(selectSelf, (s) => s.currentBoxStatus);
