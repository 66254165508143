import { Avatar, Box, CardContent } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { FC } from "react";
import { MyStayTipCard } from "../../../../domain-common/my-stay-tip-card";
import { CardWrapper } from "./card-wrapper";
import { Title } from "./title";
import { Subtitle } from "./subtitle";

interface TipAvatarCardProps {
  tip: MyStayTipCard;
  onClick?: () => void;
}

const useStyles = makeStyles()((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    zIndex: 1,
    border: `1px solid ${theme.palette.grey[400]}`,
    boxShadow:
      "0px 2.222px 4.444px -1.111px rgba(0, 0, 0, 0.20), 0px 4.444px 5.556px 0px rgba(0, 0, 0, 0.14), 0px 1.111px 11.111px 0px rgba(0, 0, 0, 0.12)"
  }
}));

export const TipAvatarCard: FC<TipAvatarCardProps> = ({ tip }) => {
  const { classes } = useStyles();
  const showSubtitle = Boolean(tip.subtitle);
  return (
    <CardWrapper tip={tip}>
      <CardContent>
        {tip.coverImage && (
          <Box
            pb={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Avatar src={tip.coverImage} alt="cover" classes={{ root: classes.avatar }} />
          </Box>
        )}
        <Title title={tip.title} />
        {showSubtitle && <Subtitle subtitle={tip.subtitle} />}
      </CardContent>
    </CardWrapper>
  );
};
