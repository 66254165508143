import { combineReducers } from "@reduxjs/toolkit";
import { PrismicDocumentPerProperty } from "../../domain-common/prismic-document";
import { promoCodesCMSSlice } from "../promo-codes-cms/promo-codes-cms.slice";
import { propertyCMSSlice } from "../property-cms/property-cms.slice";
import { CmsSingleDocumentPerPropertyType } from "./cms-single-document-per-property-type";
import { CMSPagePerPropertyState } from "./cms-single-page-per-property-factory";
import { shopCmsSlice } from "../shop-cms/shop-cms.slice";
import { configurationCMSSlice } from "../configuration-cms/configuration-cms.slice";

type Id = string;

export type CMSSinglePerPropertyState = Record<Id, PrismicDocumentPerProperty | null>;

export type CMSState = Record<string, CMSSinglePerPropertyState>;

export const cmsPerPropertyReducer = combineReducers<
  Record<CmsSingleDocumentPerPropertyType, CMSPagePerPropertyState>
>({
  [CmsSingleDocumentPerPropertyType.propertySpecfic]: propertyCMSSlice.reducer,
  [CmsSingleDocumentPerPropertyType.promoCodes]: promoCodesCMSSlice.reducer,
  [CmsSingleDocumentPerPropertyType.shop]: shopCmsSlice.reducer,
  [CmsSingleDocumentPerPropertyType.configuration]: configurationCMSSlice.reducer
});
