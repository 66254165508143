import React, { FC, PropsWithChildren } from "react";
import { useSubscribeToUseCase } from "../web-socket/use-subscribe-to-use-case";
import { SubscriptionUseCase } from "../web-socket/use-magic-web-socket";
import { useFetchAllBookerOverview } from "./hooks/use-fetch-all-booker-overview";
import { useMagicIdParams } from "../magic/use-magic-id-params";
import { useSelector } from "react-redux";
import { selectInitialMagicId } from "../initial-magic-info/initial-magic-id.slice";

export const useGetBookingOverviewMagicId = ({ magicIdFromUrl }: { magicIdFromUrl: string }) => {
  const initialMagicId = useSelector(selectInitialMagicId);
  // use initial magic id if it's not undefined
  return initialMagicId ? initialMagicId : magicIdFromUrl;
};

export const BookingOverviewSubscriptionProvider: FC<
  PropsWithChildren<{
    bookingOverviewItemId: string;
  }>
> = ({ bookingOverviewItemId, children }) => {
  const { magicId: magicIdFromUrl } = useMagicIdParams();
  const magicId = useGetBookingOverviewMagicId({ magicIdFromUrl });

  const { getBookingOverviewByBookingOverviewItemId } = useFetchAllBookerOverview({
    magicId
  });

  const bookingOverview = getBookingOverviewByBookingOverviewItemId(bookingOverviewItemId);

  useSubscribeToUseCase({
    magicId: bookingOverview?.magicId,
    magicToken: bookingOverview?.magicToken,
    topic: SubscriptionUseCase.BOOKING,
    forceSkipSubscribe: false //TODO check if this breaks something
  });

  return <>{children}</>;
};
