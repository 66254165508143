import DoneIcon from "@mui/icons-material/Done";
import React, { FC } from "react";
import { ShopFilter as ShopFilterModel } from "../store/shop-display.slice";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { WrappedChip } from "../../../components/atoms/chip/wrapped-chip";
import { Box, useTheme } from "@mui/material";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";

interface ShopFilterProps {
  availableFilters: ShopFilterModel[];
  filters: Record<string, boolean>;
  onFilterClick: (filter?: ShopFilterModel) => void;
}

export const ShopFilter: FC<ShopFilterProps> = ({ filters, availableFilters, onFilterClick }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { spacing } = useTheme();
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        overflowX: "scroll",
        overflowY: "hidden",
        whiteSpace: isMobile ? "nowrap" : "normal",
        display: isMobile ? "block" : "flex",
        flexWrap: "wrap",
        gap: spacing(1),
        "&::-webkit-scrollbar": {
          display: "none"
        }
      }}
    >
      <Box display="inline-block">
        <WrappedChip
          label={t("buttons__all")}
          onClick={() => onFilterClick()}
          rightIcon={Object.keys(filters).length === 0 ? <DoneIcon /> : undefined}
          onRightIconClick={
            Object.keys(filters).length === 0
              ? () => {
                  onFilterClick();
                }
              : undefined
          }
          variant="outlined"
          color={Object.keys(filters).length === 0 ? "primary" : "default"}
        />
      </Box>
      {availableFilters.map((f) => (
        <Box display="inline-block" key={f.id} ml={isMobile ? 1 : 0}>
          <WrappedChip
            label={f.name}
            onClick={() => onFilterClick(f)}
            rightIcon={filters[f.name] || filters[f.id] ? <DoneIcon /> : undefined}
            onRightIconClick={
              filters[f.name] || filters[f.id]
                ? () => {
                    onFilterClick(f);
                  }
                : undefined
            }
            variant="outlined"
            color={filters[f.name] || filters[f.id] ? "primary" : "default"}
          />
        </Box>
      ))}
    </Box>
  );
};
