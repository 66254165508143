import { useFetchActiveBookerOverview } from "./use-fetch-active-booker-overview";
import { useFetchInactiveBookerOverview } from "./use-fetch-inactive-booker-overview";
import { BookingOverview, BookingOverviewFromJSON } from "../../../domain-common/booking-overview";

const combineActiveAndInactiveBookingOverview = (
  active: BookingOverview[],
  inactive: BookingOverview[]
) => active.concat(inactive).map((item) => BookingOverviewFromJSON(item));

export const useFetchAllBookerOverview = (args: { magicId: string }) => {
  const { bookerOverviewsActive, bookingOverviewActiveStatus } = useFetchActiveBookerOverview(args);
  const { bookerOverviewsInactive } = useFetchInactiveBookerOverview();

  const getBookingOverviewItemById = (bookingOverviewItemId: string) =>
    combineActiveAndInactiveBookingOverview(bookerOverviewsActive, bookerOverviewsInactive)
      .flatMap((bo) => bo.items)
      .find((item) => item.id === bookingOverviewItemId);

  const getBookingOverviewByBookingOverviewItemId = (bookingOverviewItemId: string) =>
    combineActiveAndInactiveBookingOverview(bookerOverviewsActive, bookerOverviewsInactive).find(
      (bookingOverview) =>
        bookingOverview.items.map((item) => item.id).indexOf(bookingOverviewItemId) > -1
    );

  const getReservationByReservationId = (reservationId: string) =>
    combineActiveAndInactiveBookingOverview(bookerOverviewsActive, bookerOverviewsInactive)
      .flatMap((item) => [
        ...item.items.flatMap((item1) => item1.reservations),
        ...item.items.flatMap((item1) => item1.travelBuddies)
      ])
      .find((reservation) => reservation.magicId === reservationId);

  return {
    getBookingOverviewItemById,
    getBookingOverviewByBookingOverviewItemId,
    getReservationByReservationId,
    bookerOverviewsActive,
    bookingOverviewActiveStatus,
    bookerOverviewsInactive
  };
};
