import { AppBar, Box, Toolbar } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { ReactElement, useCallback } from "react";
import { useOnScroll } from "../../../util/hooks/use-on-scroll";
import { ProgressBar } from "../progress-bar/progress-bar";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import { TopNavigationItems } from "./top-navigation-items";
import { useNavigate } from "react-router-dom";
import { Button, useIsNative } from "@likemagic-tech/sv-magic-library";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { generateSearchPageUrl } from "../../../util/routing";
import { isKioskMode } from "../../../util/kiosk-mode";
import { isSecondScreenMode } from "../../../util/second-screen-mode";
import { useSecondScreenReservation } from "../../../features/second-screen-home-page/hooks/useSecondScreenReservation";

export interface TopNavigationProps {
  leftButtons?: ReactElement[];
  rightButtons?: ReactElement[];
  progress?: number;
}

const useStyles = makeStyles<{ isNativePlatform: boolean }>()(
  ({ breakpoints, palette, spacing }, { isNativePlatform }) => ({
    grow: {
      flexGrow: 1
    },
    navBar: {
      paddingTop: isNativePlatform ? "env(safe-area-inset-bottom)" : 0
    },
    logo: {
      height: spacing(7),
      maxWidth: spacing(25)
    },
    desktopLeftButtons: {
      position: "relative",
      top: spacing(2.5),
      marginLeft: spacing(1),
      zIndex: 1,
      [breakpoints.up("lg")]: {
        width: `calc(${breakpoints.values.lg}px - ${spacing(2)})`,
        margin: "auto"
      }
    }
  })
);

export const TopNavigation: React.FC<React.PropsWithChildren<TopNavigationProps>> = ({
  leftButtons,
  rightButtons,
  progress
}) => {
  const appBarRef = React.useRef<HTMLDivElement | null>(null);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { searchEnabled } = useFeatureFlags();
  const { resetSecondScreenReservation } = useSecondScreenReservation();

  const updateAppBarStyle = useCallback(
    (scrollPos: number) => {
      if (appBarRef.current) {
        appBarRef.current.style.position = scrollPos > 0 ? "fixed" : "absolute";
      }
    },
    [appBarRef]
  );

  useOnScroll(updateAppBarStyle);
  const logoUrl = useLogoUrl();
  const isNative = useIsNative();
  const { classes } = useStyles({ isNativePlatform: isNative });

  const onLogoClick = () => {
    if (isSecondScreenMode()) {
      navigate("/");
      resetSecondScreenReservation();
    } else if (searchEnabled && !isKioskMode()) {
      navigate(generateSearchPageUrl());
    } else {
      navigate("/logout");
    }
  };

  return (
    <>
      <AppBar elevation={0} ref={appBarRef} className={classes.navBar}>
        <Toolbar>
          {leftButtons && isMobile && (
            // add `edge="start"` to first element to remove margins
            <Box>
              {leftButtons.map((btn, idx) =>
                idx === 0 && btn ? React.cloneElement(btn, { edge: "start" }) : btn
              )}
            </Box>
          )}
          {!isMobile && (
            <Button
              disableRipple
              sx={{
                p: 0,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                }
              }}
              variant="ghost"
              onClick={onLogoClick}
            >
              <img src={logoUrl} alt="logo" className={classes.logo} />
            </Button>
          )}
          <div className={classes.grow} />
          <TopNavigationItems searchEnabled={searchEnabled} />
          {rightButtons}
        </Toolbar>
      </AppBar>
      <Toolbar />
      {!!progress && <ProgressBar value={progress} />}
      {!isMobile && <Box className={classes.desktopLeftButtons}>{leftButtons}</Box>}
    </>
  );
};
