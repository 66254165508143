import React, { FC } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  lottiePlayer: {
    height: "100%",
    "& div": {
      height: "100%"
    }
  }
});
export const LottieAnimationPlayer: FC<{ url: string; loop?: boolean }> = ({ url, loop }) => {
  const { classes } = useStyles();
  return url ? (
    <Player
      className={classes.lottiePlayer}
      style={{ height: "100%" }}
      src={url}
      autoplay={true}
      loop={loop}
      keepLastFrame={!loop}
    />
  ) : (
    <> </>
  );
};
