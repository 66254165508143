import { useMemo } from "react";
import { Reservation } from "../../domain-common/reservation";
import { isRequestKeyAccessibleWithOpenBalance } from "../reservation";
import { useFeatureFlags } from "./use-configuration";

export const useIsRequestKeyAvailableWithOpenBalance = ({
  reservation
}: {
  reservation: Reservation;
}) => {
  const isOpenBalance = useMemo(() => {
    return reservation.totalAllowedPayment?.amount > 0;
  }, [reservation.totalAllowedPayment]);
  const { payAtCheckoutEnabled } = useFeatureFlags();

  return isRequestKeyAccessibleWithOpenBalance(
    isOpenBalance,
    payAtCheckoutEnabled,
    reservation.status
  );
};
