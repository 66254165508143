import { FC, useEffect, useMemo } from "react";
import { useTranslateWrapper } from "./i18n-wrapper";
import { CMSSingleDocumentTypes } from "../state/cms/cms-single-document-types";
import { useTheme } from "@mui/material";
import { usePwaConfig } from "./hooks/use-configuration";
import { getLocationOrigin } from "./routing";

export const MetaUpdate: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const pwa = usePwaConfig();
  const { palette } = useTheme();

  const pageDetails = useMemo(
    () => ({
      name: t("meta_tags__app_title"),
      description: t("meta_tags__app_description"),
      image: `${getLocationOrigin()}/${pwa?.icons.logoMedium}`,
      appName: t("meta_tags__app_name"),
      themeColor: palette.primary.main,
      title: t("meta_tags__app_title")
    }),
    [palette.primary.main, t, pwa?.icons.logoMedium]
  );

  useEffect(() => {
    const ogTitle = document.createElement("meta");
    const ogImage = document.createElement("meta");
    const ogDescription = document.createElement("meta");
    const description = document.createElement("meta");
    const themeColor = document.createElement("meta");
    const appName = document.createElement("meta");

    if (pageDetails?.name) {
      ogTitle.setAttribute("property", "og:title");
      ogTitle.content = pageDetails?.name;
      document.getElementsByTagName("head")[0].appendChild(ogTitle);
    }

    if (pageDetails?.image) {
      ogImage.setAttribute("property", "og:image");
      ogImage.content = pageDetails?.image;
      document.getElementsByTagName("head")[0].appendChild(ogImage);
    }

    if (pageDetails?.description) {
      ogDescription.setAttribute("property", "og:description");
      ogDescription.content = pageDetails?.description;
      document.getElementsByTagName("head")[0].appendChild(ogDescription);
    }

    if (pageDetails?.description) {
      description.setAttribute("property", "description");
      description.content = pageDetails?.description;
      document.getElementsByTagName("head")[0].appendChild(description);
    }

    if (pageDetails?.themeColor) {
      themeColor.setAttribute("property", "theme-color");
      themeColor.content = pageDetails?.themeColor;
      document.getElementsByTagName("head")[0].appendChild(themeColor);
    }

    if (pageDetails?.appName) {
      appName.setAttribute("property", "application-name");
      appName.content = pageDetails?.appName;
      document.getElementsByTagName("head")[0].appendChild(appName);
    }
    return () => {
      for (const metaElement of [
        ogTitle,
        ogImage,
        ogDescription,
        themeColor,
        appName,
        description
      ]) {
        metaElement?.parentNode?.removeChild(metaElement);
      }
    };
  }, [pageDetails]);

  return <> </>;
};
