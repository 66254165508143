import { Visibility } from "../api/dto/additional-services-availability-response";

export enum BoxType {
  RENT = "RENT",
  BUY_FREE = "BUY_FREE",
  BUY = "BUY",
  BUY_CONSUMABLES = "BUY_CONSUMABLES"
}

export enum BoxRentType {
  HOURLY = "HOURLY",
  DAILY = "DAILY"
}

export interface BoxAvailability {
  id: number;
  boxId: string;
  boxName: string;
  serviceId: string;
  visibilities: Array<Visibility>;
  unavailabilityReason: string;
  boxRentType?: BoxRentType;
  maximum?: number;
  available: boolean;
  type: BoxType;
  boxCollection: string;
  unitId: string;
}
