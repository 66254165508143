import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useAppDispatch } from "../../../state/store";
import { ShopItemsPaymentDTO } from "../../payment/payment-dto";
import { clearPaymentState, initiatePayment } from "../../payment/payment.slice";
import { usePreparePayment } from "../../payment/use-prepare-payment";

export const useBookFreeBoxShopItem = (magicId: string) => {
  const dispatch = useAppDispatch();
  const { preparePayment } = usePreparePayment(magicId);

  const bookFreeBoxShopItem = useCallback(
    async (freeShopItems: ShopItemsPaymentDTO) => {
      const paymentDTO = await preparePayment({
        shopItems: freeShopItems
      });
      unwrapResult(await dispatch(initiatePayment({ magicId, data: { paymentDTO } })));
      dispatch(clearPaymentState());
    },
    [dispatch, preparePayment, magicId]
  );
  return { bookFreeBoxShopItem };
};
