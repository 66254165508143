import { FC } from "react";
import { AccessibleDoor, Reservation } from "../../domain-common/reservation";
import { DoorProvider } from "@likemagic-tech/sv-magic-library";
import { KeysMobile } from "./keys-mobile";
import { KeysApi } from "./keys-api";
import { KeysOffline } from "./keys-offline";

export interface KeysHandlerProps {
  door: AccessibleDoor;
  reservation: Reservation;
  doorConfig?: DoorProvider;
}

export const KeysHandler: FC<React.PropsWithChildren<KeysHandlerProps>> = (props) => {
  switch (props?.doorConfig) {
    case DoorProvider.SALTO_SPACE_MOBILE_KEY:
    case DoorProvider.AA_VISIONLINE:
    case DoorProvider.AA_VOSTIO:
      return <KeysMobile {...props} />;
    case DoorProvider.OFFLINE_PIN:
    case DoorProvider.BURGWACHTER_LEGACY:
      return <KeysOffline {...props} />;
    default:
      return <KeysApi {...props} />;
  }
};
