import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";
import { ConfirmationComponent, DesktopCard } from "../../../components";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { GuestFlowBackgroundBox } from "../../guest-flow/components/guest-flow-background-box";
import { Box } from "@mui/material";
import { DynamicShopFlowPages, getDynamicShopFlowProgress } from "../dynamic-shop-pages";

const currentPage = DynamicShopFlowPages.DYNAMIC_SHOP_CONFIRMATION;

export const DynamicShopConfirmationPage: FC = () => {
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const logoUrl = useLogoUrl();

  return (
    <FlowTemplate icons={[]} progress={getDynamicShopFlowProgress(currentPage)}>
      <GuestFlowBackgroundBox>
        <Box p={2}>
          <DesktopCard>
            <ConfirmationComponent
              onConfirm={() => navigate(generatePortalMyStayUrl(magicId))}
              subtitleInfo={t("confirmations__dynamic_shop_flow_text")}
              submitButtonLabel={t("buttons__go_to_my_stay")}
              logoSrc={logoUrl}
            />
          </DesktopCard>
        </Box>
      </GuestFlowBackgroundBox>
    </FlowTemplate>
  );
};
