import { patchReservation as patchGuestFlowStepAction } from "./reservation.slice";
import { useCallback } from "react";
import { Reservation } from "../../domain-common/reservation";
import { GuestFlowCheckpoint } from "../guest-flow/checkpoint/guest-flow-checkpoint";
import { MagicFileUploadDto } from "../guest-flow/magic-file-upload-dto";
import { Actor } from "../../domain-common/booking-overview";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../state/store";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { api as updatePreferredChannelApi } from "../../graphql/mutations/update-preffered-channel.generated";
import {
  transformBookingOnBehalfOfToV2,
  transformCommonPersonToPersonV2,
  transformCommunicationCommonToChannelV2,
  transformGenderCommonToGenderV2
} from "../../graphql/transform/transform-utils";
import { api as updatePrimaryAndSecondaryGuestsApi } from "../../graphql/mutations/update-primary-and-secondary-guests.generated";
import { api as updateSecondaryGuestsApi } from "../../graphql/mutations/update-secondary-guests.generated";
import { api as updatePrimaryGuestApi } from "../../graphql/mutations/update-primary-guest.generated";
import { api as updateFlowStateApi } from "../../graphql/mutations/update-flow-state.generated";
import { GuestServiceApi } from "../../api/guest-service.apiV2";
import { SubUpdate } from "./sub-update";
import { FlowStateUpdateInput } from "../../graphql/generated/graphql";

export interface PatchGuestFlowStepProps {
  reservationValues: Reservation;
  checkpoint?: GuestFlowCheckpoint;
  files?: MagicFileUploadDto[];
  sendNotification?: boolean;
  actor?: Actor;
  subUpdate?: SubUpdate;
  initialReservationValues?: Reservation;
}

export const useUpdateReservation = () => {
  const dispatch = useAppDispatch();
  const { isGraphQlVersion } = useApiVersion();

  const patchGuestFlowStepV1 = useCallback(
    async (
      reservationValues: Reservation,
      checkpoint?: GuestFlowCheckpoint,
      files?: MagicFileUploadDto[],
      sendNotification?: boolean,
      actor?: Actor,
      //It's required because in case of updating reservation outside of reservation context
      //we do not have initial reservation value in reservation slice
      initialReservationValues?: Reservation
    ) => {
      return dispatch(
        patchGuestFlowStepAction({
          initialReservationValues,
          reservationValues,
          checkpoint,
          files,
          sendNotification,
          actor: actor || Actor.PRIMARY_GUEST //TODO could actor be null ?
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const patchGuestFlowStepV2 = useCallback(
    (
      reservationValues: Reservation,
      subUpdate?: SubUpdate,
      checkpoint?: GuestFlowCheckpoint,
      files?: MagicFileUploadDto[],
      sendNotification?: boolean
    ) => {
      const primaryGuest = reservationValues.primaryGuest || reservationValues.travelBuddy;
      const flowState: FlowStateUpdateInput = {
        context: {
          lastConfirmedPage: checkpoint ?? reservationValues.flowState.context?.lastConfirmedPage,
          lastConfirmedCheckoutPage: reservationValues.flowState.context?.lastConfirmedCheckoutPage
        },
        notificationPending: sendNotification,
        bookingOnBehalfOf: transformBookingOnBehalfOfToV2(
          reservationValues.extras?.bookingOnBehalfOf
        )
      };
      switch (subUpdate) {
        case SubUpdate.PREFERRED_CHANNEL:
          return dispatch(
            updatePreferredChannelApi.endpoints.UpdatePreferredCommunicationChannel.initiate({
              magicId: reservationValues.magicId,
              magicToken: reservationValues.magicToken,
              channel: transformCommunicationCommonToChannelV2(
                reservationValues.extras?.preferredCommunicationChannel
              ),
              person: {
                email: primaryGuest.email,
                phone: primaryGuest.phone
              },
              flowState
            })
          ).unwrap();

        case SubUpdate.PERSONAL_DATA_WITH_SECONDARY_GUESTS:
          return dispatch(
            updatePrimaryAndSecondaryGuestsApi.endpoints.UpdatePrimaryAndSecondaryGuestDetails.initiate(
              {
                magicId: reservationValues.magicId,
                magicToken: reservationValues.magicToken,
                persons: reservationValues.additionalGuests.map((item) => ({
                  firstName: item.firstName,
                  lastName: item.lastName,
                  email: item.email,
                  phone: item.phone,
                  gender: transformGenderCommonToGenderV2(item.gender),
                  pmsId: item.id
                })),
                primaryGuest: {
                  ...transformCommonPersonToPersonV2(primaryGuest),
                  termsAndConditionsOnline: reservationValues.extras?.tcOnline,
                  termsAndConditionsGeneral: reservationValues.extras?.tcGeneral,
                  termsAndConditionsMarketingConsent: reservationValues.extras?.tcMarketingConsent,
                  emergencyEvacuationHelpNeeded:
                    reservationValues.extras?.emergencyEvacuationHelpNeeded,
                  enrolledInLoyaltyProgram: reservationValues.extras?.enrolledInLoyaltyProgram
                },
                flowState
              }
            )
          ).unwrap();

        case SubUpdate.LEGAL:
          const uploadFilesPromises =
            files?.map((f) =>
              GuestServiceApi.uploadFileToReservation({
                ...f,
                reservation: reservationValues
              })
            ) ?? [];
          const updateLegalFieldsPromises = dispatch(
            updatePrimaryGuestApi.endpoints.UpdatePrimaryGuest.initiate({
              magicId: reservationValues.magicId,
              magicToken: reservationValues.magicToken,
              person: {
                identificationDocumentNumber: reservationValues.primaryGuest.identificationNumber,
                nationality: reservationValues.primaryGuest.nationalityCountryCode,
                birthdate: reservationValues.primaryGuest.birthdate,
                termsAndConditionsOnline: reservationValues.extras?.tcOnline,
                termsAndConditionsGeneral: reservationValues.extras?.tcGeneral,
                termsAndConditionsMarketingConsent: reservationValues.extras?.tcMarketingConsent
              },
              flowState
            })
          ).unwrap();

          return Promise.all([updateLegalFieldsPromises, ...uploadFilesPromises]);

        case SubUpdate.SECONDARY_SCREEN:
          const uploadSecondaryScreenFilesPromises =
            files?.map((f) =>
              GuestServiceApi.uploadFileToReservation({
                ...f,
                reservation: reservationValues
              })
            ) ?? [];
          const updateSecondaryScreenFieldsPromises = dispatch(
            updatePrimaryAndSecondaryGuestsApi.endpoints.UpdatePrimaryAndSecondaryGuestDetails.initiate(
              {
                magicId: reservationValues.magicId,
                magicToken: reservationValues.magicToken,
                persons: reservationValues.additionalGuests.map((item) => ({
                  firstName: item.firstName,
                  lastName: item.lastName,
                  email: item.email,
                  phone: item.phone,
                  gender: transformGenderCommonToGenderV2(item.gender),
                  pmsId: item.id
                })),
                primaryGuest: {
                  ...transformCommonPersonToPersonV2(primaryGuest),
                  address: reservationValues.primaryGuest.address,
                  termsAndConditionsOnline: reservationValues.extras?.tcOnline,
                  termsAndConditionsGeneral: reservationValues.extras?.tcGeneral,
                  enrolledInLoyaltyProgram: reservationValues.extras?.enrolledInLoyaltyProgram,
                  emergencyEvacuationHelpNeeded:
                    reservationValues.extras?.emergencyEvacuationHelpNeeded
                },

                flowState
              }
            )
          ).unwrap();

          return Promise.all([
            updateSecondaryScreenFieldsPromises,
            ...uploadSecondaryScreenFilesPromises
          ]);

        case SubUpdate.ADDRESS:
          return dispatch(
            updatePrimaryGuestApi.endpoints.UpdatePrimaryGuest.initiate({
              magicId: reservationValues.magicId,
              magicToken: reservationValues.magicToken,
              person: {
                pmsId: primaryGuest.id,
                address: reservationValues.primaryGuest.address,
                termsAndConditionsOnline: reservationValues.extras?.tcOnline,
                termsAndConditionsGeneral: reservationValues.extras?.tcGeneral,
                termsAndConditionsMarketingConsent: reservationValues.extras?.tcMarketingConsent
              },
              flowState
            })
          ).unwrap();

        case SubUpdate.PERSONAL_DATA:
          return dispatch(
            updatePrimaryGuestApi.endpoints.UpdatePrimaryGuest.initiate({
              magicId: reservationValues.magicId,
              magicToken: reservationValues.magicToken,
              person: {
                ...transformCommonPersonToPersonV2(primaryGuest),
                preferredLanguage: primaryGuest.preferredLocale,
                termsAndConditionsOnline: reservationValues.extras?.tcOnline,
                termsAndConditionsGeneral: reservationValues.extras?.tcGeneral,
                termsAndConditionsMarketingConsent: reservationValues.extras?.tcMarketingConsent,
                termsAndConditionsMarketingConsentDoubleOptin:
                  reservationValues.extras?.tcMarketingConsentDoubleOptin,
                emergencyEvacuationHelpNeeded:
                  reservationValues.extras?.emergencyEvacuationHelpNeeded,
                enrolledInLoyaltyProgram: reservationValues.extras?.enrolledInLoyaltyProgram
              },
              flowState: {
                ...flowState,
                bookingOnBehalfOf: transformBookingOnBehalfOfToV2(
                  reservationValues.extras?.bookingOnBehalfOf
                )
              }
            })
          ).unwrap();

        case SubUpdate.FLOW_STATE:
        case SubUpdate.PAYMENT:
          return dispatch(
            updateFlowStateApi.endpoints.UpdateFlowState.initiate({
              magicId: reservationValues.magicId,
              magicToken: reservationValues.magicToken,
              flowState: {
                ...flowState,
                billConfirmed: reservationValues.extras?.billConfirmed,
                cleanUnitDialogSeen: reservationValues.extras?.cleanUnitDialogSeen,
                dirtyUnitDialogSeen: reservationValues.extras?.dirtyUnitDialogSeen
              }
            })
          ).unwrap();
        case SubUpdate.SERVICES:
          return dispatch(
            updateFlowStateApi.endpoints.UpdateFlowState.initiate({
              magicId: reservationValues.magicId,
              magicToken: reservationValues.magicToken,
              flowState: {
                ...flowState
              }
            })
          ).unwrap();
        case SubUpdate.PERSONAL_DATA_FOR_SECONDARY_GUESTS:
          return dispatch(
            updateSecondaryGuestsApi.endpoints.UpdateSecondaryGuestDetails.initiate({
              magicId: reservationValues.magicId,
              magicToken: reservationValues.magicToken,
              persons: reservationValues.additionalGuests.map((item) => ({
                firstName: item.firstName,
                lastName: item.lastName,
                phone: item.phone,
                email: item.email,
                gender: transformGenderCommonToGenderV2(item.gender),
                pmsId: item.id
              }))
            })
          ).unwrap();
        default:
          console.error(
            `Sub update type is not ${subUpdate ? "implemented on" : "provided for"} the backend.`
          );
          return Promise.reject();
      }
    },
    [dispatch]
  );

  const patchGuestFlowStep = ({
    reservationValues,
    checkpoint,
    files,
    sendNotification,
    actor,
    subUpdate,
    initialReservationValues
  }: PatchGuestFlowStepProps) =>
    isGraphQlVersion
      ? patchGuestFlowStepV2(reservationValues, subUpdate, checkpoint, files)
      : patchGuestFlowStepV1(
          reservationValues,
          checkpoint,
          files,
          sendNotification,
          actor,
          initialReservationValues
        );
  return { patchGuestFlowStep };
};
