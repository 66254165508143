import { FC } from "react";
import { Box } from "@mui/material";
import { Button, Heading2 } from "@likemagic-tech/sv-magic-library";
import { useNavigate } from "react-router";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";

export const LoginSuccessPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  return (
    <Box p={2} textAlign="center">
      <Heading2 my={5}>{t("labels__login_is_successful")}</Heading2>

      <Button
        onClick={() => {
          navigate("/");
        }}
      >
        {t("buttons__go_to_my_stay")}
      </Button>
    </Box>
  );
};
