import { Box, Grid, useTheme } from "@mui/material";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { RoundedFullHeight } from "../../components/layouts/rounded-full-height";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { HelmetTitle } from "../gtm/helmet-title";
import { ContactButton } from "../portal/components/contact-button";
import { DesktopCard, DisplayCmsSvg } from "../../components";
import { useCMSData } from "../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../state/common-cms/common-cms.slice";
import { PageHeadingInfo } from "../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { FlowTemplate } from "../../components/layouts/flow-template";
import { useLocation, useNavigate } from "react-router-dom";
import { useBottomNavbar } from "../../components/organism/bottom-navbar/bottom-navbar-context";
import { useCmsPropertySpecificData } from "../../state/cms/use-cms-per-property-data";

export const ContactPage = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const navigate = useNavigate();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { palette } = useTheme();
  const cmsDataPerProperty = useCmsPropertySpecificData(params.get("propertyId") || undefined);

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { setDisplayBottomNavbar } = useBottomNavbar();
  setDisplayBottomNavbar(true);
  const getContactData = (field: string): string =>
    cmsDataPerProperty?.data[field] || cmsData?.data[field];
  const formattedPhone = formatPhoneNumberIntl(getContactData("labels__phone_number"));
  const phoneNumber = formattedPhone.replace(/\s/g, "");
  const whatsAppNumber = (getContactData("labels__whatsapp_number") || "")
    .replace(/\+/g, "")
    .replace(/\s/g, "");
  const formattedWhatsAppNumber = formatPhoneNumberIntl(getContactData("labels__whatsapp_number"));
  const email = getContactData("labels__email_address");

  const formattedSmsNumber = formatPhoneNumberIntl(getContactData("labels__sms_number"));
  const smsNumber = formattedSmsNumber.replace(/\s/g, "");

  return (
    <FlowTemplate handleBack={() => navigate(-1)} icons={[]}>
      <HelmetTitle suffix="Contact us" />
      <RoundedFullHeight>
        <DesktopCard>
          <Box mb={5} ml={10} mr={10}>
            <PageHeadingInfo
              title={t("title__contact")}
              icon={<DisplayCmsSvg url={cmsData?.data?.icons__contact?.url} />}
            />
          </Box>

          <Box ml={2.5} mr={2.5} pb={2}>
            <Grid container alignItems="stretch" direction="column" spacing={2}>
              {/*phone*/}
              {phoneNumber && (
                <Grid item xs={12}>
                  <ContactButton
                    icon={
                      <DisplayCmsSvg
                        url={cmsData?.data["icons__phone"].url}
                        stroke={palette.secondary.main}
                      />
                    }
                    line1={t("labels__contact_phone")}
                    line2={formattedPhone}
                    href={`tel:${phoneNumber}`}
                  />
                </Grid>
              )}

              {/*whatsapp*/}
              {whatsAppNumber && (
                <Grid item xs={12}>
                  <ContactButton
                    icon={
                      <DisplayCmsSvg
                        url={cmsData?.data["icons__whatsapp"].url}
                        stroke={palette.secondary.main}
                      />
                    }
                    line1={t("labels__whatsapp")}
                    line2={formattedWhatsAppNumber}
                    href={`https://wa.me/${whatsAppNumber}`}
                  />
                </Grid>
              )}

              {/*email*/}
              {email && (
                <Grid item xs={12}>
                  <ContactButton
                    icon={
                      <DisplayCmsSvg
                        url={cmsData?.data["icons__email"].url}
                        stroke={palette.secondary.main}
                      />
                    }
                    line1={t("labels__email")}
                    line2={email}
                    href={`mailto:${email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </Grid>
              )}

              {/*sms*/}
              {smsNumber && (
                <Grid item xs={12}>
                  <ContactButton
                    icon={
                      <DisplayCmsSvg
                        url={cmsData?.data["icons__sms"].url}
                        stroke={palette.secondary.main}
                      />
                    }
                    line1={t("labels__sms")}
                    line2={formattedSmsNumber}
                    href={`sms:${smsNumber}`}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </DesktopCard>
      </RoundedFullHeight>
    </FlowTemplate>
  );
};
