import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function TaskListEditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>{`.a{fill:none; stroke:currentColor; strokeMiterlimit:10;}`}</style>
      </defs>
      <path
        className="a"
        d="M22.63 14.8792L15 22.5092L11.25 23.2592L12 19.5092L19.631 11.8792C20.0276 11.4826 20.5656 11.2598 21.1265 11.2598C21.6874 11.2598 22.2254 11.4826 22.622 11.8792L22.631 11.8882C23.0275 12.285 23.2501 12.823 23.2499 13.3839C23.2498 13.9448 23.0268 14.4827 22.63 14.8792V14.8792Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="a"
        d="M5.24899 10.5098H14.249"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="a"
        d="M5.24899 14.2598H11.249"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="a"
        d="M5.24899 18.0098H8.24899"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="a"
        d="M8.24899 23.2598H2.24899C1.85117 23.2598 1.46964 23.1017 1.18833 22.8204C0.907028 22.5391 0.748993 22.1576 0.748993 21.7598V6.00977C0.748993 5.61194 0.907028 5.23041 1.18833 4.94911C1.46964 4.6678 1.85117 4.50977 2.24899 4.50977H5.99999C5.99999 3.5152 6.39508 2.56138 7.09834 1.85812C7.8016 1.15485 8.75543 0.759766 9.74999 0.759766C10.7446 0.759766 11.6984 1.15485 12.4016 1.85812C13.1049 2.56138 13.5 3.5152 13.5 4.50977H17.25C17.6478 4.50977 18.0293 4.6678 18.3107 4.94911C18.592 5.23041 18.75 5.61194 18.75 6.00977V8.25977"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="a"
        d="M9.74899 3.75977C9.82316 3.75977 9.89567 3.78176 9.95733 3.82297C10.019 3.86417 10.0671 3.92274 10.0954 3.99126C10.1238 4.05978 10.1313 4.13518 10.1168 4.20793C10.1023 4.28067 10.0666 4.34749 10.0142 4.39993C9.96171 4.45238 9.8949 4.48809 9.82215 4.50256C9.74941 4.51703 9.67401 4.5096 9.60549 4.48122C9.53697 4.45284 9.4784 4.40477 9.43719 4.34311C9.39599 4.28144 9.37399 4.20894 9.37399 4.13477C9.37386 4.08548 9.38347 4.03666 9.40227 3.9911C9.42107 3.94555 9.44869 3.90416 9.48354 3.86931C9.51838 3.83446 9.55978 3.80684 9.60533 3.78804C9.65089 3.76924 9.69971 3.75964 9.74899 3.75977"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
