import { Box } from "@mui/material";
import { RoundBox } from "../../../components";
import { MyStayHeaderImage } from "./my-stay-header-image";
import React, { FC, PropsWithChildren } from "react";
import { PrismicImage } from "../../../state/property-cms/use-unit-group-cms-data";
import { responsiveVariables, useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ breakpoints, shape, palette, spacing }) => ({
  spaceForImage: {
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      marginTop: shape.borderRadius === 0 ? 0 : 100
    }
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0
  },
  chipBox: {
    textAlign: "center"
  },
  error: {
    width: "100%"
  },
  heroCard: {
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      background: palette.background.paper,
      borderRadius: shape.borderRadius * 3,
      padding: spacing(3),
      boxShadow: "0px 4px 8px rgba(197, 185, 172, 0.06)"
    }
  }
}));

interface MyStayHeroProps {
  images: Array<PrismicImage>;
}
export const MyStayHero: FC<PropsWithChildren<MyStayHeroProps>> = ({ children, images }) => {
  const { classes } = useStyles();
  const isMobile = useIsMobile();

  return (
    <RoundBox hasShadow>
      <Box p={isMobile ? 0 : 2.5}>
        <Box className={classes.spaceForImage}>
          <Box className="MyStayHero-root">
            <Box className={`${classes.heroCard} HeroCard`}>
              <MyStayHeaderImage items={images} />
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </RoundBox>
  );
};
