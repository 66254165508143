import { TenantContext, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { useContext } from "react";
import { getLocationOrigin } from "../routing";
import { PropertyFrontendConfig } from "@likemagic-tech/sv-magic-library/dist/api";
import { getKioskId } from "../kiosk-mode";
import { useReservationContext } from "../../features/reservation-provider/reservation-provider";

/**
 * /**
 *  * Returns proper configuration by priority
 *  *
 *  * 1. From properties by propertyId if reservation context is available
 *  *    or overridePropertyId (if exists) overrides propertyId - should be used only in case when reservation context is not available
 *  * 2. From properties by origin (property has configured baseUrl)
 *  * 3. From tenant config
 *
 * @param {string} overridePropertyId - Override property ID to be used in the configuration (optional). Should be used carefully
 *
 * @returns {PropertyFrontendConfig} The frontend configuration for the property.
 */
const useConfiguration: (overridePropertyId?: string) => PropertyFrontendConfig = (
  overridePropertyId
) => {
  const { reservation } = useReservationContext();

  const origin = getLocationOrigin();

  return usePropertyConfig({ propertyId: overridePropertyId ?? reservation?.propertyId, origin });
};

/**
 * To be used in cases when we must not reload config based on reservation
 * Should be use on some sensitive init fn or when we should avoid renders base on reservation context
 *
 * Returns proper configuration by priority
 * 1. From properties by origin (property has configured baseUrl)
 * 2. From tenant config
 */
export const useConfigBasedOnlyOnBaseUrl = () => {
  const origin = getLocationOrigin();

  return usePropertyConfig({ origin });
};

export const useAdditionalTravelBuddyFieldsConfig = () => {
  const config = useConfiguration();

  return config.additionalTravelBuddyFields!;
};

export const useFeatureFlags = () => {
  const config = useConfiguration();
  const { features } = useContext(TenantContext);

  return config?.features! || features;
};

export const usePwaConfig = () => {
  const config = useConfiguration();
  return config.pwa!;
};

export const useAdyenConfig = () => {
  const { adyen } = useContext(TenantContext);
  return adyen!;
};

export const useEarliestCheckinOffsetConfig = () => {
  const config = useConfiguration();
  return config.earliestCheckinOffset!;
};

export const useThemeConfig = () => {
  const config = useConfiguration();
  return config.theme!;
};

export const useThemeOverrideConfig = () => {
  const config = useConfiguration();
  return config?.themeOverride ?? undefined;
};

export const useAllowedLocales = () => {
  const { allowedLocales } = useContext(TenantContext);
  return allowedLocales;
};

export const useDisabledCountriesForIdentification = () => {
  const config = useConfiguration();
  return config?.disabledCountriesForIdentification || [];
};

export const useDisabledCountriesForPassportUpload = () => {
  const config = useConfiguration();
  return config?.disabledCountriesForPassportUpload || [];
};

export const useKioskConfig = () => {
  const { kiosk } = useContext(TenantContext);

  return kiosk[getKioskId()] || { availableOptions: [] };
};

export const useHidePaymentConfig = (overridePropertyId?: string) => {
  const { hidePrepaymentConfig } = useConfiguration(overridePropertyId);
  return hidePrepaymentConfig;
};
