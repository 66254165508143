import { Avatar, AvatarGroup, Box, Grid, Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { FC } from "react";
import { Heading3, Paragraph } from "@likemagic-tech/sv-magic-library";
import { EventCardDateView } from "./event-card-date-view";

interface Attendee {
  name: string;
  image: string;
}

export interface EventCardProps {
  title: string;
  description: string;
  coverImage: string | null;
  date: Date | null;
  attendees?: Attendee[];
  onActionClick?: () => void;
  classes?: { root?: string };
}

const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2.5,
    overflow: "hidden",
    boxShadow: "0px 4px 8px rgba(197, 185, 172, 0.06)",
    cursor: "pointer"
  },
  image: {
    objectFit: "cover",
    display: "block",
    width: "100%",
    height: theme.spacing(25),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  content: {
    position: "relative"
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  avatarsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  avatarGroup: {
    marginRight: theme.spacing(1)
  },
  actions: {
    alignSelf: "flex-end"
  },
  dateView: {
    position: "absolute",
    left: theme.spacing(2),
    top: theme.spacing(-6)
  },
  title: {
    fontSize: 20,
    lineHeight: "20px"
  }
}));

export const EventCard: FC<EventCardProps> = ({
  title,
  description,
  coverImage,
  date,
  attendees,
  onActionClick = () => {},
  classes: classesProp
}) => {
  const { classes } = useStyles();

  return (
    <div className={classesProp?.root}>
      <Paper elevation={0} className={classes.root} onClick={onActionClick}>
        {coverImage && <img src={coverImage} alt="" className={classes.image} />}

        <Box p={2} pt={4} className={`${classes.content} EventCardContent`}>
          {date && <EventCardDateView date={date} classes={{ root: classes.dateView }} />}

          <Grid container spacing={1} direction={"column"}>
            <Grid item>
              <Heading3 className={classes.title}>{title}</Heading3>
            </Grid>
            <Grid item>
              <Paragraph gutterBottom>{description}</Paragraph>
            </Grid>
            {attendees && (
              <Grid item className={classes.avatarsContainer}>
                <AvatarGroup max={3} className={classes.avatarGroup}>
                  {attendees.map((attendee) => (
                    <Avatar
                      key={attendee.name}
                      src={attendee.image}
                      className={classes.smallAvatar}
                    />
                  ))}
                </AvatarGroup>
                <Paragraph display="inline">{attendees.map((a) => a.name).join(", ")}</Paragraph>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};
