import { getLocalStorageObject } from "../../state/local-storage-state";

export const LOCAL_STORAGE_ADDITIONAL_SERVICES_KEY = "additional_services_cart";

export function getLocalStorageItems<T>(magicId: string): T {
  const localStorageCarts = getLocalStorageObject(LOCAL_STORAGE_ADDITIONAL_SERVICES_KEY);
  const result = localStorageCarts[magicId];
  return result as T;
}

export function persistItemsToLocalStorage<T>(magicId: string, value: T) {
  const storedValue = getLocalStorageObject(LOCAL_STORAGE_ADDITIONAL_SERVICES_KEY);
  storedValue[magicId] = value;

  try {
    localStorage.setItem(LOCAL_STORAGE_ADDITIONAL_SERVICES_KEY, JSON.stringify(storedValue));
  } catch (e) {}
}
