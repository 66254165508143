import React, { FC } from "react";
import { isTravelBuddy } from "../../../../util/flow";
import { Grid } from "@mui/material";
import { PaperButton } from "../../../../components";
import { Reservation } from "../../../../domain-common/reservation";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../../state/common-cms/common-cms.slice";
import { DatePickerExtendStay } from "../../../../components/molecules/date-picker-extend-stay";
import { useExtendStayModal } from "../../../extend-stay/use-extend-stay-modal";
import { useExtendStayAction } from "../../../extend-stay/use-extend-stay-action";
import { useFeatureFlags } from "../../../../util/hooks/use-configuration";

interface NavigationExtendStayProps {
  reservation: Reservation;
  title?: string;
  iconUrl?: string;
}

export const NavigationExtendStay: FC<NavigationExtendStayProps> = ({
  reservation,
  title,
  iconUrl
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const { enableExtendStay } = useFeatureFlags();

  const {
    onClick,
    unavailable,
    setUnavailable,
    extendModalOpen,
    setExtendModalOpen,
    extendStayActionDisabled
  } = useExtendStayModal({ reservation });
  const { onDatePickerSubmit } = useExtendStayAction({
    magicId: reservation.magicId,
    setUnavailable
  });

  if (enableExtendStay && !extendStayActionDisabled && !isTravelBuddy(reservation)) {
    return (
      <Grid item xs={6}>
        <PaperButton
          title={title ?? t("buttons__extend_stay")}
          small
          onClick={onClick}
          iconUrl={iconUrl ?? cmsData?.data?.icons__my_stay_navigation_extend_stay?.url}
        />

        <DatePickerExtendStay
          reservation={reservation}
          labels={{
            dialogTitle: t("labels__date_picker"),
            dialogButton: t("buttons__extend_stay"),
            unavailableDialogText: t("modals__extend_stay_dialog_unavailable_text")
          }}
          onSubmit={onDatePickerSubmit}
          unavailable={unavailable}
          open={extendModalOpen}
          setOpen={setExtendModalOpen}
        />
      </Grid>
    );
  } else {
    return null;
  }
};
