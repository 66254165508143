import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type SearchReservationsQueryVariables = Types.Exact<{
  searchData: Types.ReservationSearchFilter;
}>;

export type SearchReservationsQuery = {
  __typename?: "Query";
  SearchReservations: Array<{
    __typename?: "ReservationSearchResult";
    magicId: string;
    adultsAmount?: number | null;
    childrenAmount?: number | null;
    unit?: { __typename?: "ReservationSearchResultUnit"; name?: string | null } | null;
    unitGroup?: { __typename?: "ReservationSearchResultUnitGroup"; id?: number | null } | null;
  }>;
};

export const SearchReservationsDocument = `
    query SearchReservations($searchData: ReservationSearchFilter!) {
  SearchReservations(filter: $searchData) {
    magicId
    adultsAmount
    childrenAmount
    unit {
      name
    }
    unitGroup {
      id
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SearchReservations: build.query<SearchReservationsQuery, SearchReservationsQueryVariables>({
      query: (variables) => ({ document: SearchReservationsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useSearchReservationsQuery, useLazySearchReservationsQuery } = injectedRtkApi;
