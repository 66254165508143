export enum TerminalPayEvents {
  IDLE = "IDLE",
  TERMINAL_INTERACTION_SUCCESSFUL = "TERMINAL_INTERACTION_SUCCESSFUL",
  PAYMENT_CONFIRMED = "PAYMENT_CONFIRMED",
  APALEO_INTERACTION_SUCCESSFUL = "APALEO_INTERACTION_SUCCESSFUL",
  /*Errors*/
  PAY_BY_TERMINAL_MAGIC_EXCEPTION = "PAY_BY_TERMINAL_MAGIC_EXCEPTION",
  NOT_ENOUGH_BALANCE = "NOT_ENOUGH_BALANCE",
  PAY_BY_TERMINAL_UNSPECIFIED_ERROR = "PAY_BY_TERMINAL_UNSPECIFIED_ERROR",
  PAY_BY_TERMINAL_APALEO_TRANSACTIONS_MAX_RETRIES_EXHAUSTED = "PAY_BY_TERMINAL_APALEO_TRANSACTIONS_MAX_RETRIES_EXHAUSTED",
  PAY_BY_TERMINAL_UNKNOWN_TERMINAL = "PAY_BY_TERMINAL_UNKNOWN_TERMINAL",
  PAY_BY_TERMINAL_TRANSACTION_CANCELLED = "PAY_BY_TERMINAL_TRANSACTION_CANCELLED",
  PAY_BY_TERMINAL_INVALID_CARD = "PAY_BY_TERMINAL_INVALID_CARD",
  PAY_BY_TERMINAL_VALIDATION_FAILED = "PAY_BY_TERMINAL_VALIDATION_FAILED",
  PAY_BY_TERMINAL_TERMINAL_IS_BUSY = "PAY_BY_TERMINAL_TERMINAL_IS_BUSY",
  PAY_BY_TERMINAL_SERVICE_DIALOG_IS_IN_PROGRESS = "PAY_BY_TERMINAL_SERVICE_DIALOG_IS_IN_PROGRESS",
  PAY_BY_TERMINAL_WRONG_PIN = "PAY_BY_TERMINAL_WRONG_PIN",
  UNKNOWN = "UNKNOWN"
}
