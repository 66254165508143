import { styled } from "@mui/material/styles";
import { responsiveVariables } from "../components/layouts/hooks/use-is-mobile";

export const ImageLimitedHeightWrapper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

export const ImageLimitedHeight = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  width: "100%",
  objectFit: "cover",
  objectPosition: "center",
  maxHeight: theme.spacing(31.25),
  [theme.breakpoints.down(responsiveVariables.firstDesktopSize)]: {
    maxHeight: theme.spacing(22.5)
  }
}));
