import { useCmsPerPropertyData } from "../cms/use-cms-per-property-data";

import { fetchConfigurationCMS, selectConfigurationCMSById } from "./configuration-cms.slice";
import { useMemo } from "react";
import { PrismicImage } from "../property-cms/use-unit-group-cms-data";

export enum MyStayNavigationOptions {
  KEYS = "KEYS",
  INVOICE = "INVOICE",
  TRAVEL_BUDDY = "TRAVEL_BUDDY",
  SERVICES = "SERVICES",
  SHOP = "SHOP",
  FAQ = "FAQ",
  SELF_POURING_STATION = "SELF_POURING_STATION",
  BOX = "BOX",
  ROOM_CONTROL = "ROOM_CONTROL",
  EXTEND_STAY = "EXTEND_STAY",
  EXTERNAL = "EXTERNAL",
  MINIBAR = "MINIBAR",
  AMENITIES = "AMENITIES",
  EXTERNAL_WIDGET = "EXTERNAL_WIDGET"
}

interface MyStayNavigationItem {
  link: string;
  title: string;
  link_type: string;
  icon: PrismicImage;
}

interface SuccessScreensButton {
  link: string;
  title: string;
  link_type: MyStayNavigationOptions;
  icon: PrismicImage;
}

export const useConfigurationCmsData = (propertyId?: string) => {
  const cmsData = useCmsPerPropertyData({
    propertyId: propertyId ?? "",
    selector: selectConfigurationCMSById,
    fetchAction: fetchConfigurationCMS
  });

  const configuration = useMemo(() => cmsData?.data, [cmsData?.data]);

  const myStayNavigationItemsFromCms: MyStayNavigationItem[] = useMemo(() => {
    return configuration?.["my_stay__navigation_items"] ?? [];
  }, [configuration]);

  const successScreensButtonsFromCms: SuccessScreensButton[] = useMemo(() => {
    return configuration?.["success_screens__buttons"] ?? [];
  }, [configuration]);

  return {
    myStayNavigationItemsFromCms,
    successScreensButtonsFromCms
  };
};
