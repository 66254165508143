import { Actor } from "./booking-overview";
import { HasFlowState, HasFlowStateFromJSON } from "./flow-state";
import { GuestFlowContext } from "./guest-flow-checkpoint-and-args";

export enum MagicType {
  BOOKER_OVERVIEW = "BOOKER_OVERVIEW",
  BOOKING = "BOOKING",
  RESERVATION = "RESERVATION",
  TRAVEL_BUDDY = "TRAVEL_BUDDY"
}

export interface MagicObject<D extends GuestFlowContext = GuestFlowContext>
  extends HasFlowState<D> {
  magicToken: string;
  id: string;
  magicLink: string;
  magicId: string;
  magicType: MagicType;
  keycloakUUID: string | null;
  actor: Actor;
  bookingOverviewItemId?: string;
}

export const MagicObjectFromJSON = <D extends GuestFlowContext, T extends MagicObject<D>>(
  json: any
): T => {
  if (!json) {
    return json;
  }

  const hasFlowState = HasFlowStateFromJSON(json);

  return {
    ...json,
    ...hasFlowState,
    magicToken: json["magicToken"],
    id: json["id"],
    magicId: json["magicId"],
    magicType: json["magicType"],
    magicLink: json["magicLink"]
  };
};
