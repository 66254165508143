import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector
} from "@reduxjs/toolkit";
import { Operation } from "fast-json-patch";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { MagicApi, UploadFileResponse } from "../../api/magic.api";
import { Reservation, ReservationFromJSON } from "../../domain-common/reservation";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { isReservation, isTravelBuddy } from "../../util/flow";
import { getPatches } from "../../util/jsonpatch.util";
import { fetchMagicObject, nextMagicObject } from "../magic/magic-object.slice";
import { filterFieldsForTravelBuddy } from "../portal/travel-buddy-utils";
import { GuestFlowCheckpoint } from "../guest-flow/checkpoint/guest-flow-checkpoint";
import { isANewerCheckpoint } from "../guest-flow/guest-flow-sequence";
import { MagicFileUploadDto } from "../guest-flow/magic-file-upload-dto";
import { Actor } from "../../domain-common/booking-overview";
import { EntityStateStatus, isStatusLoading } from "../../state/EntityStateStatus";
import { handleSliceError } from "../../util/error-handling";
import { getTenantHeaders } from "../../api/custom-headers";

type ReservationSliceState = {
  data?: Reservation;
  reservationUpdateStatus: EntityStateStatus;
};

const initialState: ReservationSliceState = {
  reservationUpdateStatus: EntityStateStatus.IDLE
};

export const updateReservationLanguage = createAsyncThunk<
  void,
  {
    newLang: string;
    actor: Actor;
  },
  { state: RootState; extra: ThunkExtraArguments }
>("reservation/updateLanguage", async ({ newLang, actor }, thunkAPI) => {
  const reservation = thunkAPI.getState()["reservationSlice"].data;
  if (reservation) {
    const patches = getPatches(reservation, {
      ...reservation!,
      primaryGuest: {
        ...reservation!.primaryGuest,
        preferredLocale: newLang
      }
    });

    if (patches.length) {
      await MagicApi.patchReservation(
        { patches, reservation, sendNotification: false, actor },
        {
          signal: thunkAPI.signal,
          ...(await getTenantHeaders(thunkAPI.extra))
        }
      );
    }
  }
  return Promise.resolve();
});

export const patchReservation = createAsyncThunk<
  { filesResults?: UploadFileResponse[]; patches: Operation[] },
  {
    reservationValues: Reservation;
    initialReservationValues?: Reservation;
    checkpoint?: GuestFlowCheckpoint;
    files?: MagicFileUploadDto[];
    sendNotification?: boolean;
    actor?: Actor;
  },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>(
  "reservation/patch",
  async (
    { reservationValues, initialReservationValues, checkpoint, files, sendNotification, actor },
    thunkApi
  ) => {
    try {
      const reservation =
        initialReservationValues || ReservationFromJSON(thunkApi.getState().reservationSlice.data);
      if (!reservation) {
        throw new Error("Reservation is not loaded in the state");
      }

      let filesResults;
      if (files) {
        const fileTypes = files.map((f) => f.fileType);
        const filesToDelete = reservation.files.filter((f) =>
          fileTypes.some((ft) => ft === f.metaData.magicFileType)
        );

        await Promise.all(
          filesToDelete.map(async (f) =>
            MagicApi.deleteFile(
              {
                fileName: f.fileName,
                magicToken: reservation.magicToken,
                magicId: reservation.magicId
              },
              {
                signal: thunkApi.signal,
                ...(await getTenantHeaders(thunkApi.extra))
              }
            )
          )
        );

        filesResults = await Promise.all(
          files.map(async (f) =>
            MagicApi.uploadFileToReservation(
              { ...f, reservation: reservationValues },
              {
                signal: thunkApi.signal,
                ...(await getTenantHeaders(thunkApi.extra))
              }
            )
          )
        );
      }

      if (checkpoint && isANewerCheckpoint(checkpoint, reservation)) {
        reservationValues = {
          ...reservationValues,
          flowState: {
            ...reservationValues.flowState,
            context: {
              ...reservationValues.flowState.context,
              lastConfirmedPage: checkpoint
            }
          }
        };
      }

      let patches = getPatches(reservation, reservationValues)
        .map((patch) => {
          //Remap patch object for adding new item into array must be without index
          const splitPatch = patch.path.split("/");
          if (!isNaN(parseInt(splitPatch[splitPatch.length - 1])) && patch.op === "add") {
            splitPatch[splitPatch.length - 1] = "-";
            patch.path = splitPatch.join("/");
          }
          return patch;
        })
        .filter((value) => value.path.indexOf("objectId") === -1)
        .filter((value) => value.path.indexOf("travelBuddies") === -1)
        .filter((value) =>
          isTravelBuddy(reservation) ? filterFieldsForTravelBuddy(value.path) : true
        );

      if (patches.length) {
        await MagicApi.patchReservation(
          { patches, reservation, sendNotification, actor },
          {
            signal: thunkApi.signal,
            ...(await getTenantHeaders(thunkApi.extra))
          }
        );
      }

      return { filesResults, patches };
    } catch (e) {
      return handleSliceError(e, thunkApi.rejectWithValue);
    }
  }
);

export const reservationSlice = createSlice({
  name: "reservationSlice",
  initialState,
  reducers: {
    initReservationSlice: () => initialState,
    setReservation: (state, action: PayloadAction<{ reservation: Reservation }>) => {
      state.data = action.payload.reservation;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMagicObject.fulfilled, (state, action) => {
        if (isReservation(action.payload) || isTravelBuddy(action.payload)) {
          state.data = action.payload;
        }
      })
      .addCase(nextMagicObject, (state, action) => {
        if (isReservation(action.payload) || isTravelBuddy(action.payload)) {
          state.data = ReservationFromJSON(action.payload);
        }
      })
      .addCase(patchReservation.pending, (state) => {
        state.reservationUpdateStatus = EntityStateStatus.LOADING;
      })
      .addCase(patchReservation.fulfilled, (state) => {
        state.reservationUpdateStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(patchReservation.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.reservationUpdateStatus === EntityStateStatus.LOADING) {
            state.reservationUpdateStatus = EntityStateStatus.IDLE;
          }
          return;
        }

        if (action?.payload?.status === 401) {
          state.reservationUpdateStatus = EntityStateStatus.UNAUTHORIZED;
          return;
        }

        if (action?.payload?.status === 403) {
          state.reservationUpdateStatus = EntityStateStatus.FORBIDDEN;
          return;
        }

        state.reservationUpdateStatus = EntityStateStatus.FAILED;
      })
      .addCase(updateReservationLanguage.pending, (state) => {
        state.reservationUpdateStatus = EntityStateStatus.LOADING;
      })
      .addCase(updateReservationLanguage.fulfilled, (state) => {
        state.reservationUpdateStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(updateReservationLanguage.rejected, (state) => {
        state.reservationUpdateStatus = EntityStateStatus.FAILED;
      });
  }
});

export const { initReservationSlice } = reservationSlice.actions;

export const selectSelf: Selector<RootState, ReservationSliceState> = (state: RootState) =>
  state[reservationSlice.name];

/**
 * @deprecated The method should not be used, please use useReservationContext
 */
export const selectReservation = createSelector(selectSelf, (s) => ReservationFromJSON(s.data));

/**
 * @deprecated The method should not be used, please use useReservationContext
 * Reason to have this as a separate selector is that propertyId will be memorised by selector because it's a primitive type (is not compared by ref)
 */
export const selectReservationPropertyId = createSelector(
  selectSelf,
  (s) => ReservationFromJSON(s.data)?.propertyId
);

export const selectIsReservationUpdating = createSelector(selectSelf, (s) =>
  isStatusLoading(s.reservationUpdateStatus)
);

export const selectUpdateReservationStatus = createSelector(
  selectSelf,
  (s) => s.reservationUpdateStatus
);
