import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useAppDispatch } from "../../../state/store";
import { deleteAccount } from "../../user-profile/user-profile.slice";
import { useDeleteUserAccountMutation } from "../../../graphql/mutations/account-deletion.generated";

interface AccountDeletionInterface {
  userProfileId: string;
}

export const useAccountDeletion = () => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();
  const [deleteAccountMutation] = useDeleteUserAccountMutation();

  const deleteAccountV1 = ({ userProfileId }: AccountDeletionInterface) => {
    return dispatch(deleteAccount({ id: userProfileId.toString() }));
  };

  const deleteAccountV2 = ({ userProfileId }: AccountDeletionInterface) => {
    return deleteAccountMutation({ userAccountUuid: userProfileId });
  };

  const deleteAccountFunction = isRESTVersion ? deleteAccountV1 : deleteAccountV2;

  return { deleteAccountFunction };
};
