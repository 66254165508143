import { FC, useEffect, useMemo } from "react";
import { PaymentRedirectProps } from "../payment-redirect";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useLocation } from "react-router-dom";
import { useSyncPaymentRequestMutation } from "../../../graphql/mutations/sync-payment-request.generated";
import { QueryStatus } from "@reduxjs/toolkit/query";

export const PaymentMewsPayRedirect: FC<PaymentRedirectProps> = ({ onSuccess, onFailed }) => {
  const [syncPaymentRequestAction, { status }] = useSyncPaymentRequestMutation();

  const { magicId } = useMagicIdParams(); //Comes from magic page context
  const { search } = useLocation();
  //Comes from url param
  const bookingMagicId = useMemo(() => {
    const searchParamState = new URLSearchParams(search);
    return searchParamState.get("magicId") || undefined;
  }, [search]);

  const commonMagicId = useMemo(() => magicId || bookingMagicId, [magicId, bookingMagicId]);

  useEffect(() => {
    if (commonMagicId && status === QueryStatus.uninitialized) {
      syncPaymentRequestAction({
        magicId: commonMagicId
      }).then((result) => {
        if ("data" in result && result.data.SyncPaymentRequest.state === "COMPLETED") {
          onSuccess(commonMagicId);
        } else {
          onFailed(commonMagicId);
        }
      });
    }
  }, [commonMagicId, onFailed, onSuccess, syncPaymentRequestAction, status]);

  return <></>;
};
