import React, { ChangeEvent, FC } from "react";
import { FormHelperText, Grid } from "@mui/material";
import { Checkbox } from "@likemagic-tech/sv-magic-library";
import { PrismicRichTextWrapper } from "../../atoms";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";

export const TravelBuddyTermsAndConditions: FC<{
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  touched?: boolean;
  errors?: string;
}> = ({ value, onChange, touched, errors }) => {
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  return (
    <>
      <Grid container direction="row" alignItems="start" mt={2} sx={{ flexWrap: "nowrap" }}>
        <Checkbox
          name="extras.tcMinors"
          id="extras.tcMinors"
          checked={value}
          onChange={onChange}
          sx={{ paddingLeft: 0, paddingTop: 0 }}
        />
        <PrismicRichTextWrapper render={cmsData?.data?.labels__travel_buddy_terms_and_conditions} />
      </Grid>
      <FormHelperText style={{ color: "red" }}>{touched && errors}</FormHelperText>
    </>
  );
};
