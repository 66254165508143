import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { SubmitButton, NotificationChip, NotificationChipType } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Reservation } from "../../../domain-common/reservation";
import { Box, Divider, Grid, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Checkbox, Heading2 } from "@likemagic-tech/sv-magic-library";
import {
  generateBookingOverviewPagesUrl,
  generateBookingOverviewReservationsURL
} from "../booking-overview-navigation";
import { BookingOverviewPages } from "../booking-overview-pages";
import { PricePreview } from "../../../components/atoms/price-preview/price-preview";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/store";
import {
  clearTotalPrice,
  fetchTotalPrice,
  selectTotalPrice
} from "../state/booking-overview.slice";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { usePropertyById } from "../../property/use-property-by-id";
import { isReservationPayable } from "../../../util/reservation";
import { ReservationItemBody } from "../components/reservation-item-body";
import { preparePayment, setReferenceId } from "../../payment/payment.slice";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { BookingOverviewItemWrapper } from "../components/booking-overview-item-wrapper";
import { ArrowRight1Icon } from "../../../components/icons";

import { HelmetTitle } from "../../gtm/helmet-title";
import { toGross } from "../../../domain-common/full-price";
import { useFetchAllBookerOverview } from "../hooks/use-fetch-all-booker-overview";
import { useGetBookingOverviewMagicId } from "../booking-overview-subscription-provider";
import { generateUUID } from "../../../util/data";

const useStyles = makeStyles()((theme: Theme) => ({
  bottomDivider: {
    opacity: "0.5",
    borderTop: `3px solid ${theme.palette.secondary.main}`
  },
  paymentLabel: {
    fontSize: 17,
    fontWeight: "bold"
  },
  paymentAmmount: {
    float: "right"
  }
}));

export const BookingOverviewCheckoutPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { magicId: magicIdFromUrl, bookingOverviewItemId } = useMagicIdParams();
  const magicId = useGetBookingOverviewMagicId({
    magicIdFromUrl: magicIdFromUrl
  });

  const dispatch = useAppDispatch();
  const totalFullPrice = useSelector(selectTotalPrice);

  const totalPrice = useMemo(() => toGross(totalFullPrice), [totalFullPrice]);

  const { getBookingOverviewByBookingOverviewItemId, getBookingOverviewItemById } =
    useFetchAllBookerOverview({ magicId });
  const bookingOverviewItem = getBookingOverviewItemById(bookingOverviewItemId);
  const bookingOverview = getBookingOverviewByBookingOverviewItemId(bookingOverviewItemId);

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const [payingForReservationIds, setPayingForReservationIds] = useState<string[]>([]);

  const handleBack = useCallback(() => {
    navigate(generateBookingOverviewReservationsURL(magicId, bookingOverviewItemId));
  }, [navigate, magicId, bookingOverviewItemId]);

  const findPayingForReservation = useCallback(
    (reservationId: string) => {
      return payingForReservationIds.includes(reservationId);
    },
    [payingForReservationIds]
  );

  const togglePayingForReservation = useCallback(
    (reservationId: string) => {
      const newArrayToBePaid = !payingForReservationIds.includes(reservationId)
        ? [...payingForReservationIds, reservationId]
        : payingForReservationIds.filter((item) => item !== reservationId);
      const foliosToBePaid = bookingOverviewItem?.reservations
        .filter((value) => newArrayToBePaid.indexOf(value.magicId) > -1)
        .flatMap((r) => r.foliosToBePaid)
        .map((folio) => folio.folioId);

      if (bookingOverview?.magicId) {
        dispatch(
          fetchTotalPrice({
            magicId: bookingOverview?.magicId || "",
            shopItems: undefined,
            additionalServices: undefined,
            foliosToBePaid
          })
        );
        setPayingForReservationIds(newArrayToBePaid);
      }
    },
    [payingForReservationIds, bookingOverview?.magicId, bookingOverviewItem?.reservations, dispatch]
  );

  useEffect(() => {
    dispatch(clearTotalPrice());
  }, [dispatch]);

  const onSubmit = useCallback(async () => {
    if (bookingOverviewItem && bookingOverview) {
      const reservationsPayingFor: Array<Reservation> = bookingOverviewItem.reservations.filter(
        (reservation) => payingForReservationIds.indexOf(reservation.magicId) > -1
      );

      const foliosToBePaid = reservationsPayingFor
        .flatMap((value) => value.foliosToBePaid)
        .map((f) => f.folioId);

      if (totalPrice.amount > 0) {
        await dispatch(
          preparePayment({
            adyenPrice: totalPrice,
            foliosToBePaid
          })
        );
        dispatch(setReferenceId(generateUUID()));
      }

      navigate(
        generateBookingOverviewPagesUrl(
          BookingOverviewPages.PAYMENT,
          magicId,
          bookingOverviewItemId
        )
      );
    }
  }, [
    dispatch,
    magicId,
    navigate,
    totalPrice,
    bookingOverview,
    bookingOverviewItem,
    payingForReservationIds,
    bookingOverviewItemId
  ]);

  const property = usePropertyById(bookingOverviewItem?.propertyId);

  const openReservation = useCallback(
    (reservationId: string) =>
      generateBookingOverviewPagesUrl(
        BookingOverviewPages.BOOKING_RESERVATION_DETAILS,
        magicId,
        bookingOverviewItemId,
        reservationId
      ),
    [bookingOverviewItemId, magicId]
  );

  return (
    <FlowTemplate handleBack={handleBack}>
      <HelmetTitle suffix="Checkout" />
      <Box mx={3}>
        <Heading2>{property.name}</Heading2>

        <Box mt={2}>
          {bookingOverviewItem?.reservations.map((reservation) => (
            <BookingOverviewItemWrapper
              key={`${reservation.magicId}`}
              onClick={
                isReservationPayable(reservation)
                  ? () => togglePayingForReservation(reservation.magicId)
                  : () => navigate(openReservation(reservation.magicId))
              }
              highlighted={reservation.magicId === magicId}
              icon={
                isReservationPayable(reservation) ? (
                  <Checkbox
                    id="isReservationPayable"
                    color="primary"
                    sx={{ p: 0 }}
                    checked={findPayingForReservation(reservation.magicId)}
                    onChange={() => {}}
                  />
                ) : (
                  <ArrowRight1Icon fontSize="inherit" />
                )
              }
            >
              <ReservationItemBody reservation={reservation}>
                {!isReservationPayable(reservation) ? (
                  <NotificationChip
                    type={NotificationChipType.Success}
                    label={tCommon("chip__reservation_paid")}
                  />
                ) : (
                  <Box mt={1}>
                    <PricePreview price={reservation.totalAllowedPayment} />
                  </Box>
                )}
              </ReservationItemBody>
            </BookingOverviewItemWrapper>
          ))}
        </Box>

        {totalPrice && totalPrice?.amount !== 0 && (
          <Grid container direction="column">
            <Box my={1.5}>
              <Divider />
            </Box>
            <Box className={classes.paymentLabel}>
              {tCommon("labels__total_to_be_paid")}
              <span className={classes.paymentAmmount}>
                <PricePreview price={totalPrice} />
              </span>
            </Box>
            <Box my={1.5}>
              <Divider className={classes.bottomDivider} />
            </Box>
          </Grid>
        )}
      </Box>

      {totalPrice && totalPrice?.amount !== 0 && (
        <SubmitButton label={tCommon("buttons__pay")} onClick={onSubmit} hasBottomNavigation />
      )}
    </FlowTemplate>
  );
};
