import React, { FC, useMemo } from "react";
import { isTravelBuddy } from "../../../../util/flow";
import { Grid } from "@mui/material";
import { PaperButton } from "../../../../components";
import { generatePortalBoxRentUrl, generatePortalBoxShopUrl } from "../../../../util/routing";
import { Reservation } from "../../../../domain-common/reservation";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useNavigate } from "react-router-dom";
import { useCMSData } from "../../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../../state/common-cms/common-cms.slice";
import { useFeatureFlags } from "../../../../util/hooks/use-configuration";
import { useRentedProducts } from "../../../box-shop/hooks/use-rented-products";

interface NavigationBoxProps {
  reservation: Reservation;
  title?: string;
  iconUrl?: string;
}

export const NavigationBox: FC<NavigationBoxProps> = ({ reservation, title, iconUrl }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const magicId = useMemo(() => reservation.magicId, [reservation.magicId]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { boxEnabled } = useFeatureFlags();

  const { rentedItems } = useRentedProducts({
    magicId: reservation.magicId,
    magicToken: reservation.magicToken,
    isTravelBuddy: isTravelBuddy(reservation)
  });

  if (boxEnabled && !isTravelBuddy(reservation)) {
    return (
      <>
        <Grid item xs={6}>
          <PaperButton
            title={t("navigations__box_shop")}
            small
            onClick={() => {
              navigate(generatePortalBoxShopUrl(magicId));
            }}
            iconUrl={cmsData?.data?.icons__my_stay_navigation_box_shop?.url}
          />
        </Grid>
        {rentedItems.length > 0 && (
          <Grid item xs={6}>
            <PaperButton
              sx={{
                color: "common.white",
                backgroundColor: "primary.main"
              }}
              title={title ?? t("navigations__box_rent")}
              small
              onClick={() => {
                navigate(generatePortalBoxRentUrl(magicId));
              }}
              iconUrl={iconUrl ?? cmsData?.data?.icons__my_stay_navigation_box_rent?.url}
            />
          </Grid>
        )}
      </>
    );
  } else {
    return null;
  }
};
