import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";
import { ConfirmationComponent, DesktopCard, SubmitButton } from "../../../components";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { CheckoutFlowPages, getCheckoutFlowProgress } from "../checkout-flow-pages";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { GuestFlowBackgroundBox } from "../../guest-flow/components/guest-flow-background-box";
import { Box, Paper } from "@mui/material";
import { useUpdateFlowStateMutation } from "../../../graphql/mutations/update-flow-state.generated";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { daysBeforeNow, formatDateTime } from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";
import { usePropertyById } from "../../property/use-property-by-id";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { useCheckout } from "../use-checkout";
import { useTheme } from "@mui/styles";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";

const currentPage = CheckoutFlowPages.CONFIRMATION;

export const CheckoutConfirmationPage: FC = () => {
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const { t, exists } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const logoUrl = useLogoUrl();
  const [updateFlowState] = useUpdateFlowStateMutation();
  const { reservation } = useReservationContext();
  const property = usePropertyById(reservation?.propertyId);
  const isMobile = useIsMobile();

  useEffect(() => {
    updateFlowState({
      magicId,
      magicToken: reservation.magicToken,
      flowState: {
        context: {
          ...reservation.flowState.context,
          lastConfirmedCheckoutPage: currentPage
        }
      }
    });
  }, [magicId, updateFlowState, reservation.magicToken, reservation.flowState.context]);

  const onConfirm = useCallback(async () => {
    navigate(generatePortalMyStayUrl(magicId));
  }, [navigate, magicId]);
  const isCheckoutToday = useMemo(
    () => daysBeforeNow(reservation.departure) === 0,
    [reservation.departure]
  );
  const { openCheckoutModal } = useCheckout({ reservation });
  const theme = useTheme();

  return (
    <FlowTemplate icons={[]} progress={getCheckoutFlowProgress(currentPage)}>
      <GuestFlowBackgroundBox>
        <Box p={2}>
          <DesktopCard>
            <ConfirmationComponent
              onConfirm={onConfirm}
              subtitleInfo={t("confirmations__checkout_flow_text")}
              submitButtonLabel={t("buttons__go_to_my_stay")}
              logoSrc={logoUrl}
            >
              <Box
                sx={{
                  mx: isMobile ? 2.5 : "auto",
                  mt: 4,
                  width: isMobile ? "auto" : "calc(50% - 20px)"
                }}
              >
                {exists("confirmations_checkout_flow_additional_text") ? (
                  <Paper
                    sx={{
                      p: 2.5,
                      mt: 4,
                      background: theme.palette.common.white,
                      border: "none",
                      display: "block",
                      whiteSpace: "break-spaces"
                    }}
                    elevation={3}
                  >
                    {t("confirmations_checkout_flow_additional_text", {
                      propertyName: property.name,
                      checkoutTime:
                        formatDateTime(
                          reservation.departure,
                          getI18nSelectedLanguage(),
                          property.timeZone
                        ) ?? ""
                    })}
                  </Paper>
                ) : undefined}
              </Box>

              {reservation.status === ReservationStatus.IN_HOUSE && isCheckoutToday ? (
                <Box mt={3} mx={2.5} textAlign="center">
                  <SubmitButton
                    variant="secondary"
                    onClick={openCheckoutModal}
                    label={t("buttons__checkout")}
                  />
                </Box>
              ) : undefined}
            </ConfirmationComponent>
          </DesktopCard>
        </Box>
      </GuestFlowBackgroundBox>
    </FlowTemplate>
  );
};
