import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetTotalUnpaidAmountQueryVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  services?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.ServiceOrderRequest>> | Types.InputMaybe<Types.ServiceOrderRequest>
  >;
}>;

export type GetTotalUnpaidAmountQuery = {
  __typename?: "Query";
  GetTotalUnpaidAmount?: {
    __typename?: "GrossPrice";
    grossPriceInCents?: number | null;
    grossPrice?: any | null;
    currency?: string | null;
  } | null;
};

export const GetTotalUnpaidAmountDocument = `
    query GetTotalUnpaidAmount($magicId: String!, $services: [ServiceOrderRequest]) {
  GetTotalUnpaidAmount(magicId: $magicId, services: $services) {
    grossPriceInCents
    grossPrice
    currency
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetTotalUnpaidAmount: build.query<
      GetTotalUnpaidAmountQuery,
      GetTotalUnpaidAmountQueryVariables
    >({
      query: (variables) => ({ document: GetTotalUnpaidAmountDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetTotalUnpaidAmountQuery, useLazyGetTotalUnpaidAmountQuery } = injectedRtkApi;
