import React, { FC, useCallback, useMemo } from "react";
import { PaymentRedirect } from "../../payment/payment-redirect";
import { useLocation, useNavigate } from "react-router-dom";
import { clearPaymentResponses, clearPaymentState } from "../../payment/payment.slice";
import { useAppDispatch } from "../../../state/store";

export const BookingOverviewPaymentRedirectPage: FC<
  React.PropsWithChildren<{ path: string }>
> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const cleanedURLSearchParams: URLSearchParams = useMemo(() => {
    const cleanedURLSearchParams = new URLSearchParams(search);
    cleanedURLSearchParams.delete("redirectResult");
    return cleanedURLSearchParams;
  }, [search]);

  const onSuccess = useCallback(() => {
    dispatch(clearPaymentResponses());
    dispatch(clearPaymentState());
    navigate({
      pathname: pathname.replace("payment-redirect", "checkout"),
      search: cleanedURLSearchParams.toString()
    });
  }, [dispatch, navigate, pathname, cleanedURLSearchParams]);

  const onFailed = () => {
    navigate({
      pathname: pathname.replace("payment-redirect", "payment"),
      search: cleanedURLSearchParams.toString()
    });
  };

  return <PaymentRedirect onSuccess={onSuccess} onFailed={onFailed} />;
};
