import { Box, LinearProgress, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { responsiveVariables } from "../../layouts/hooks/use-is-mobile";

const useStyles = makeStyles()(({ palette, breakpoints }: Theme) => ({
  root: {
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      height: 6,
      position: "fixed",
      left: 0,
      right: 0,
      zIndex: 1
    }
  },
  barColorPrimary: {
    backgroundColor: palette.primary.dark
  },
  colorPrimary: {
    backgroundColor: palette.grey[300]
  }
}));

export interface ProgressBarProps {
  value: number;
}

export const ProgressBar: React.FC<React.PropsWithChildren<ProgressBarProps>> = ({ value }) => {
  const { classes } = useStyles();
  return (
    <Box>
      <LinearProgress
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary
        }}
        variant="determinate"
        value={value}
      />
    </Box>
  );
};
