import React, { FC } from "react";
import { Grid } from "@mui/material";
import { PaperButton } from "../../../../components";

interface NavigationExternalProps {
  title: string;
  link: string;
  iconUrl: string;
}

export const NavigationExternal: FC<NavigationExternalProps> = ({ title, link, iconUrl }) => {
  return (
    <Grid item xs={6}>
      <PaperButton
        title={title}
        small
        onClick={() => {
          window.open(link, "_blank");
        }}
        iconUrl={iconUrl}
      />
    </Grid>
  );
};
