import { QueryStatus } from "@reduxjs/toolkit/query";
import { ApiError } from "@likemagic-tech/sv-magic-library";

export enum EntityStateStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
  UNAUTHORIZED = "unauthorized",
  FORBIDDEN = "forbidden"
}

export const mapQueryStatusToEntityStateStatus = (queryStatus: QueryStatus, error?: ApiError) => {
  switch (queryStatus) {
    case QueryStatus.fulfilled:
      return EntityStateStatus.SUCCEEDED;
    case QueryStatus.rejected:
      if (error?.status === 401) {
        return EntityStateStatus.UNAUTHORIZED;
      } else if (error?.status === 403) {
        return EntityStateStatus.FORBIDDEN;
      }
      return EntityStateStatus.FAILED;

    case QueryStatus.pending:
      return EntityStateStatus.LOADING;
    default:
      return EntityStateStatus.IDLE;
  }
};

export const isStatusSuccess = (status: EntityStateStatus) =>
  status === EntityStateStatus.SUCCEEDED;
export const isStatusLoading = (status: EntityStateStatus) => status === EntityStateStatus.LOADING;
export const isStatusFailed = (status: EntityStateStatus) => status === EntityStateStatus.FAILED;
export const isStatusIdle = (status: EntityStateStatus) => status === EntityStateStatus.IDLE;
