import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetPotentialBookingPriceQueryVariables = Types.Exact<{
  booking: Types.PotentialBooking;
}>;

export type GetPotentialBookingPriceQuery = {
  __typename?: "Query";
  GetPotentialBookingPrice?: {
    __typename?: "BookingPrice";
    prepayment?: Types.GuaranteeType | null;
    prepaid?: boolean | null;
    total?: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
    } | null;
    accommodation?: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
    } | null;
  } | null;
};

export const GetPotentialBookingPriceDocument = `
    query GetPotentialBookingPrice($booking: PotentialBooking!) {
  GetPotentialBookingPrice(booking: $booking) {
    total {
      currency
      grossPrice
      grossPriceInCents
      netPrice
      netPriceInCents
    }
    accommodation {
      currency
      grossPrice
      grossPriceInCents
      netPrice
      netPriceInCents
    }
    prepayment
    prepaid
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetPotentialBookingPrice: build.query<
      GetPotentialBookingPriceQuery,
      GetPotentialBookingPriceQueryVariables
    >({
      query: (variables) => ({ document: GetPotentialBookingPriceDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetPotentialBookingPriceQuery, useLazyGetPotentialBookingPriceQuery } =
  injectedRtkApi;
