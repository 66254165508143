import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { Capacitor } from "@capacitor/core";

export const NativeAppSuccessLogin: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      window.reloadCapacitorOnLogin = () => {
        navigate("/login-success");
      };
    }
  }, [navigate]);
  return null;
};
