import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { Grid, useTheme } from "@mui/material";
import { getKioskTerminalId } from "../../../util/kiosk-mode";
import { useSelector } from "react-redux";
import { selectPaymentSlice } from "../payment.slice";
import { openBanner } from "../../banner/banner.slice";
import { useAppDispatch } from "../../../state/store";
import { TerminalPayEvents } from "./terminal-pay-events";
import { initTerminalPaymentFetchEventSource } from "./use-pay-with-terminal";
import { DisplayCmsSvg } from "../../../components";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { Loader } from "../../../components/atoms/loaders/loader";
import { getErrorEntry } from "../../../state/common-cms/use-common-error-translation";
import { useCommonErrorsMapperCmsData } from "../../../state/common-cms/use-common-cms-data";
import { TerminalPayType } from "./terminal-pay-type";
import { useKioskConfig } from "../../../util/hooks/use-configuration";
import { useNavigate } from "react-router-dom";

interface TerminalPayProps {
  magicId?: string;
  onSuccess: (magicId?: string) => void;
  returnUrl: string;
  payType: TerminalPayType;
}

const terminalPaymentLabels = (state: TerminalPayEvents): string => {
  // guest has to interact with terminal
  if (TerminalPayEvents.IDLE === state) {
    return "labels__payment_terminal_instructions";
  }

  // interaction with terminal successful, payment processor processes payment
  if (TerminalPayEvents.TERMINAL_INTERACTION_SUCCESSFUL === state) {
    return "labels__payment_terminal_processing";
  }

  // payment was received & confirmed by the PMS
  if (TerminalPayEvents.PAYMENT_CONFIRMED === state) {
    return "labels__payment_terminal_confirmed";
  }

  //should not happen
  return `labels__payment_terminal_${state}`;
};

export const TerminalPay: FC<PropsWithChildren<TerminalPayProps>> = ({
  magicId,
  onSuccess,
  payType
}) => {
  const { paymentDTO } = useSelector(selectPaymentSlice);
  const dispatch = useAppDispatch();
  const abortSSESignal = useRef<AbortController | undefined>(undefined);
  const navigate = useNavigate();
  const [state, setState] = useState<TerminalPayEvents>(TerminalPayEvents.IDLE);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const theme = useTheme();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { paymentByTerminalId } = useKioskConfig();

  const init = useRef(false);
  const cmsErrorData = useCommonErrorsMapperCmsData();
  const onOnError = useCallback(
    (reason?: string) => {
      const prismicNotClosedEntry = getErrorEntry(cmsErrorData, `errors__${reason?.toLowerCase()}`);

      dispatch(
        openBanner({
          errorId: reason,
          type: "error",
          title: prismicNotClosedEntry?.text || cmsErrorData?.["errors__default_label"]
        })
      );

      abortSSESignal.current?.abort();
      navigate(-1);
    },
    [dispatch, navigate, cmsErrorData]
  );

  const handleNewPaymentEvent = useCallback(
    (event: TerminalPayEvents, bookingMagicId?: string) => {
      setState(event);
      if (event === TerminalPayEvents.APALEO_INTERACTION_SUCCESSFUL) {
        abortSSESignal.current?.abort();
        onSuccess(bookingMagicId);
      }
    },
    [setState, onSuccess]
  );

  useEffect(() => {
    if (!init.current) {
      initTerminalPaymentFetchEventSource({
        paymentDTO,
        magicId: magicId,
        terminalId: paymentByTerminalId || getKioskTerminalId(),
        handleNewEvent: handleNewPaymentEvent,
        onOnError
      });
      init.current = true;
    }
  }, [paymentByTerminalId, paymentDTO, magicId, handleNewPaymentEvent, onOnError, payType]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      alignContent="center"
    >
      <Grid item paddingBottom={8}>
        <PageHeadingInfo
          title={tCommon("title__payment_option_terminal_payment")}
          icon={<DisplayCmsSvg url={cmsData?.data?.icon__terminal_payment?.url} />}
        />
      </Grid>
      <Grid item paddingBottom={2}>
        <Loader visible={true} backdropInvisible={true} />
      </Grid>
      <Grid item>
        <ParagraphBold color={theme.palette.text.primary}>
          {tCommon(terminalPaymentLabels(state))}
        </ParagraphBold>
      </Grid>
    </Grid>
  );
};
