import { useEffect, useRef } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { MagicObject } from "../../domain-common/magic-object";
import { isBooking, isReservation, isTravelBuddy } from "../../util/flow";
import { useGuestFlowCheckpoint } from "../guest-flow/checkpoint/use-guest-flow-checkpoint";
import { generateGuestFlowUrl } from "../guest-flow/guest-flow-sequence";
import { isKioskMode } from "../../util/kiosk-mode";
import {
  generateBookingOverviewPagesUrl,
  generateInitBookingOverviewUrl
} from "../booking-overview/booking-overview-navigation";
import { Reservation } from "../../domain-common/reservation";
import { Actor } from "../../domain-common/booking-overview";
import { BookingOverviewPages } from "../booking-overview/booking-overview-pages";
import { generateFastGuestFlowLink } from "../guest-flow/pages/fast-guest-flow.page";
import { generatePortalMyStayUrl } from "../../util/routing";
import { useBaseUrlsFromPropertyId } from "@likemagic-tech/sv-magic-library";
import { ReservationStatus } from "../../domain-common/reservation-status";

const skipResolvingPaths = ["payment-success", "confirmation", "payment-redirect"];

export const useMagicObjectSubpathResolver = (magicObject: MagicObject | null) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const match = matchPath("/magic/:magicId", pathname);
  const initializedMagicObjects = useRef<{ [magicId: string]: boolean }>({});
  const { restore: restoreGuestFlow } = useGuestFlowCheckpoint();

  //will baseUrls return only for reservations
  const baseUrls = useBaseUrlsFromPropertyId((magicObject as Reservation)?.propertyId || "");

  useEffect(() => {
    performSubpathResolver(
      magicObject,
      navigate,
      pathname,
      restoreGuestFlow,
      initializedMagicObjects,
      !!match
    );
  }, [magicObject, navigate, initializedMagicObjects, pathname, restoreGuestFlow, match, baseUrls]);
};

export async function performSubpathResolver(
  magicObject: MagicObject | null,
  navigate: (path: string, state?: any) => void,
  pathname: string,
  restoreGuestFlow: (reservation: Reservation) => Promise<string>,
  initializedMagicObjects: any,
  urlMatch: boolean
) {
  if (magicObject && !initializedMagicObjects.current[magicObject.magicId]) {
    initializedMagicObjects.current = { [magicObject.magicId]: true };

    if (skipResolvingPaths.some((path) => pathname.indexOf(path) > -1)) {
      return;
      //skip resolving path for specific pages.
    }

    // Handle only urls that are landing on magic page directly
    if ((isReservation(magicObject) || isTravelBuddy(magicObject)) && urlMatch) {
      // Handle default magicId /magic/:magicId
      if (isKioskMode()) {
        return navigate(await handleGuestFlow(pathname, magicObject, restoreGuestFlow));
      } else {
        if (magicObject.flowState.completed) {
          return navigate(`${pathname}/portal/my-stay`);
        }

        if (!magicObject.extras?.bookingOnBehalfOf && magicObject.actor === Actor.BOOKER) {
          // switch to booking context & go to the decision page
          return navigate(
            generateBookingOverviewPagesUrl(
              BookingOverviewPages.PERSONAL_DATA,
              magicObject.magicId,
              magicObject.bookingOverviewItemId,
              magicObject.magicId
            )
          );
        }

        return navigate(await handleGuestFlow(pathname, magicObject, restoreGuestFlow));
      }
    } else if (isBooking(magicObject) && urlMatch) {
      if (magicObject.reservations?.length === 1) {
        const onlyReservationInBooking = magicObject.reservations[0];

        if (onlyReservationInBooking.flowState.completed) {
          return navigate(generatePortalMyStayUrl(onlyReservationInBooking.magicId));
        } else {
          return navigate(
            generateBookingOverviewPagesUrl(
              BookingOverviewPages.PERSONAL_DATA,
              magicObject.magicId,
              onlyReservationInBooking.bookingOverviewItemId,
              onlyReservationInBooking.magicId
            )
          );
        }
      }
      return navigate(generateInitBookingOverviewUrl(magicObject.magicId));
    }
  }
}

export const handleGuestFlow = async (
  url: string,
  magicObject: Reservation,
  restoreGuestFlow: (reservation: Reservation) => Promise<string>
) => {
  //Handle guest flow
  if (
    (magicObject.flowState.completed ||
      magicObject.status === ReservationStatus.IN_HOUSE ||
      magicObject.status === ReservationStatus.CHECKED_OUT) &&
    !isKioskMode()
  ) {
    return `${url}/portal/my-stay`;
  }
  if (magicObject.flowState.completed && isKioskMode()) {
    return `${url}/booking/guest/confirmation`;
  }
  if (magicObject.flowState.fastCheckinAvailable && !magicObject.flowState.completed) {
    return generateFastGuestFlowLink(magicObject.magicId);
  }

  const suffix = await restoreGuestFlow(magicObject);
  return generateGuestFlowUrl(suffix, magicObject.magicId);
};
