import { Box, Grid } from "@mui/material";
import React, { ReactElement } from "react";
import { MyStayTipCard } from "../../../domain-common/my-stay-tip-card";
import { MyStayTipContentType } from "../../../domain-common/my-stay-tip-content-type";
import { TipAvatarCard } from "./tip-cards/tip-avatar-card";
import { TipIconCard } from "./tip-cards/tip-icon-card";
import { TipQuoteCard } from "./tip-cards/tip-quote-card";
import { TipThumbnailCard } from "./tip-cards/tip-thumbnail-card";

interface MyStayTipListProps {
  tips: MyStayTipCard[];
  onTipClick?: (tip: MyStayTipCard) => void;
  fixedTips: ReactElement[];
}

export const MyStayTipList: React.FC<React.PropsWithChildren<MyStayTipListProps>> = ({
  tips,
  fixedTips
}) => {
  return (
    <Box p={2}>
      <Grid container spacing={2} px={0.5}>
        {fixedTips}
        {tips.map((tip) => (
          <Grid item key={tip.id} xs={6} md={3}>
            {tip.contentType === MyStayTipContentType.ICON_CARD && <TipIconCard tip={tip} />}
            {tip.contentType === MyStayTipContentType.AVATAR_CARD && <TipAvatarCard tip={tip} />}
            {tip.contentType === MyStayTipContentType.THUMBNAIL_CARD && (
              <TipThumbnailCard tip={tip} />
            )}
            {tip.contentType === MyStayTipContentType.QUOTE && <TipQuoteCard tip={tip} />}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
