import React, { FC } from "react";
import { NavbarProvider } from "../organism/top-navbar/navbar-context";
import { BottomNavbarProvider } from "../organism/bottom-navbar/bottom-navbar-context";
import { SearchBookingPages } from "../../features/search-for-bookings/search-booking-pages";
import { BookingOverviewPages } from "../../features/booking-overview/booking-overview-pages";
import { PortalPagesEnum } from "../../features/portal/portal-page-to-path";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SocialFooter } from "../molecules/social-footer/social-footer";
import { responsiveVariables } from "./hooks/use-is-mobile";
import { isSecondScreenMode } from "../../util/second-screen-mode";

const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  mainContainer: {
    maxWidth: breakpoints.values.lg,
    margin: "auto",
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      marginTop: spacing(1.5),
      paddingBottom: spacing(5)
    }
  }
}));

export const LayoutWithBottomAndTopNavbar: FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { classes } = useStyles();
  return (
    <NavbarProvider>
      <BottomNavbarProvider
        labels={{
          [SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES]: t("main__bottom_nav_search_label"),
          [BookingOverviewPages.PROPERTIES]: t("main__bottom_nav_booking_label"),
          [PortalPagesEnum.PROFILE]: t("main__bottom_nav_profile_label"),
          [PortalPagesEnum.MY_STAY]: t("main__bottom_nav_my_stay_label")
        }}
      >
        <Box className={isSecondScreenMode() ? "" : classes.mainContainer}>{children}</Box>
        <SocialFooter />
      </BottomNavbarProvider>
    </NavbarProvider>
  );
};
