import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { PricePreview } from "../atoms/price-preview/price-preview";
import { Price } from "../../domain-common/price";

interface StyleProps {
  priceBold?: boolean;
  priceGray?: boolean;
  titleGray?: boolean;
}

export interface TotalPriceProps extends StyleProps {
  title: string;
  price: Price;
  priceBold?: boolean;
  priceGray?: boolean;
  titleGray?: boolean;
}

const useStyles = makeStyles<StyleProps>()(({ palette }: Theme, props) => ({
  secondaryText: {
    color: props.titleGray ? palette.text.secondary : palette.text.primary
  },
  price: {
    fontWeight: props.priceBold ? 700 : 400,
    color: props.priceGray ? palette.text.secondary : palette.text.primary
  },
  divider: {
    height: 3
  }
}));

export const TotalPrice: React.FC<React.PropsWithChildren<TotalPriceProps>> = ({
  price,
  title,
  priceBold = true,
  priceGray = false,
  titleGray = false
}) => {
  const { classes } = useStyles({
    priceBold,
    titleGray,
    priceGray
  });
  return (
    <Grid container>
      <Grid item xs={6}>
        <Paragraph className={classes.secondaryText}>{title}</Paragraph>
      </Grid>
      <Grid item xs={6} container alignItems="flex-start" justifyContent="flex-end">
        <Paragraph className={classes.price}>
          <PricePreview price={price} />
        </Paragraph>
      </Grid>
    </Grid>
  );
};
