import React, { FC, useCallback, useMemo } from "react";
import {
  EarlyCheckinPickerDialog,
  LateCheckoutPickerDialog,
  ServiceDatesPickerDialog
} from "../modals";
import { AdditionalServicesAvailabilityResponse } from "../../../api/dto/additional-services-availability-response";
import {
  addToCart,
  removeFromCart,
  selectAdditionalServiceById
} from "./additional-services-cart.slice";
import { Reservation } from "../../../domain-common/reservation";
import { RootState, useAppDispatch } from "../../../state/store";
import { useSelector } from "react-redux";
import { InteractionType } from "./aditional-interaction-type";
import { calculateInteractionType } from "./additional-services.util";
import { AdditionalServiceCartItem } from "../../../domain-common/additional-service-cart-item";
import { FullPrice } from "../../../domain-common/full-price";
import { CounterPickerDialog } from "../modals/counter-picker-dialog";

interface AdditionalServiceModalHandlerProps {
  reservation: Reservation;
  selectedService: AdditionalServicesAvailabilityResponse;
  clearSelectedService: () => void;
}

interface OnChangeDateServiceArgs {
  values: Array<{ date: string; count: number }>;
}

export interface ServiceModalHandlerProps {
  cartValue?: AdditionalServiceCartItem;
  onClose: () => void;
  onChange: (arg: OnChangeDateServiceArgs) => void;
  reservation: Reservation;
  price: FullPrice;
  service: AdditionalServicesAvailabilityResponse;
}

export const AdditionalServiceModalHandler: FC<
  React.PropsWithChildren<AdditionalServiceModalHandlerProps>
> = ({ reservation, selectedService, clearSelectedService }) => {
  const dispatch = useAppDispatch();
  const additionalServiceInCart = useSelector((state: RootState) =>
    selectAdditionalServiceById(state, selectedService.serviceId)
  );

  const onChangeDates = useCallback(
    (changed: OnChangeDateServiceArgs) => {
      const datesWithCount = changed.values;
      if (datesWithCount.length) {
        dispatch(
          addToCart({
            serviceId: selectedService.serviceId,
            code: selectedService.code,
            dates: changed.values
          })
        );
      } else {
        dispatch(removeFromCart({ serviceId: selectedService.serviceId }));
      }
      clearSelectedService();
    },
    [dispatch, selectedService, clearSelectedService]
  );

  const interactionType = useMemo(
    () => calculateInteractionType(selectedService),
    [selectedService]
  );

  if (interactionType === InteractionType.LATE_CHECKOUT_DIALOG) {
    return (
      <LateCheckoutPickerDialog
        cartValue={additionalServiceInCart}
        service={selectedService}
        reservation={reservation}
        onClose={clearSelectedService}
        onChange={onChangeDates}
        price={selectedService.basePrice}
      />
    );
  } else if (interactionType === InteractionType.EARLY_CHECKIN_DIALOG) {
    return (
      <EarlyCheckinPickerDialog
        cartValue={additionalServiceInCart}
        service={selectedService}
        reservation={reservation}
        onClose={clearSelectedService}
        onChange={onChangeDates}
        price={selectedService.basePrice}
      />
    );
  } else if (interactionType === InteractionType.DATE_PICKER_DIALOG) {
    return (
      <ServiceDatesPickerDialog
        cartValue={additionalServiceInCart}
        service={selectedService}
        reservation={reservation}
        onClose={clearSelectedService}
        onChange={onChangeDates}
        price={selectedService.basePrice}
      />
    );
  } else if (interactionType === InteractionType.COUNTER) {
    return (
      <CounterPickerDialog
        cartValue={additionalServiceInCart}
        service={selectedService}
        reservation={reservation}
        onClose={clearSelectedService}
        onChange={onChangeDates}
        price={selectedService.basePrice}
      />
    );
  } else {
    return <></>;
  }
};
