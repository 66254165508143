import { FormControlLabel, Grid } from "@mui/material";
import React, { FC } from "react";
import { Checkbox } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";

export type SecondScreenCheckboxesFormData = {
  emergencyEvacuationHelpNeeded: boolean;
  enrolledInLoyaltyProgram: boolean;
};

export interface SecondScreenCheckboxesFormProps {
  values: SecondScreenCheckboxesFormData;
  onChange: (person: any) => void;
}

export const SecondScreenCheckboxesForm: FC<SecondScreenCheckboxesFormProps> = ({
  values,
  onChange
}) => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  return (
    <Grid container spacing={0.75} mt={4}>
      <Grid container direction="row" alignItems="center" sx={{ flexWrap: "nowrap" }}>
        <FormControlLabel
          sx={{ m: 0 }}
          control={
            <Checkbox
              name="emergencyEvacuationHelpNeeded"
              id="emergencyEvacuationHelpNeeded"
              checked={values.emergencyEvacuationHelpNeeded}
              onChange={onChange}
            />
          }
          label={tCommon("labels__i_require_special_assistance")}
        />
      </Grid>
      <Grid container direction="row" alignItems="center" sx={{ flexWrap: "nowrap" }}>
        <FormControlLabel
          sx={{ m: 0 }}
          control={
            <Checkbox
              name="enrolledInLoyaltyProgram"
              id="enrolledInLoyaltyProgram"
              checked={values.enrolledInLoyaltyProgram}
              onChange={onChange}
            />
          }
          label={tCommon("labels__sign_up_to_receive_exclusive_rates_and_offers_from_eclub")}
        />
      </Grid>
    </Grid>
  );
};
