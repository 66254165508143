import React, { useCallback, useEffect } from "react";
// @ts-ignore
import PWAPrompt from "react-ios-pwa-prompt";
import { useSelector } from "react-redux";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { useAppDispatch } from "../../state/store";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import {
  hideInstallButton,
  hideIosInstallPrompt,
  selectServiceWorkerSlice,
  showInstallButton,
  showIosInstallPrompt
} from "./pwa.slice";
import { ServiceWorkerController } from "./service-worker-controller";
import { isIOS } from "react-device-detect";

export const serviceWorkerController = new ServiceWorkerController();

export const PwaContext = React.createContext<{
  serviceWorkerController: ServiceWorkerController;
  installApp: () => void;
}>({
  serviceWorkerController,
  installApp: () => {}
});

// Initialize deferredPrompt for use later to show browser install prompt.
let deferredPrompt: {
  prompt?: any;
  userChoice?: any;
  preventDefault?: () => void;
} | null;

export const PwaProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { showIosInstallAppPrompt } = useSelector(selectServiceWorkerSlice);
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  useEffect(() => {
    // remove data from lib since we control when to show promotion
    localStorage.removeItem("iosPwaPrompt");
  }, []);

  useEffect(() => {
    let listener = (e: { preventDefault: () => void }) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      dispatch(showInstallButton());
    };

    window.addEventListener("beforeinstallprompt", listener);

    return () => {
      window.removeEventListener("beforeinstallprompt", listener);
    };
  }, [dispatch]);

  const installApp = useCallback(async () => {
    if (isIOS) {
      dispatch(showIosInstallPrompt());
      return;
    }
    if (!deferredPrompt) {
      return;
    }
    // Show the installation prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice;
    // We've used the prompt, and can't use it again, throw it away
    if (outcome === "accepted") {
      // Hide the app provided install promotion
      dispatch(hideInstallButton());
    }
    deferredPrompt = null;
  }, [dispatch]);

  return (
    <PwaContext.Provider value={{ serviceWorkerController, installApp }}>
      {children}

      {showIosInstallAppPrompt && (
        <PWAPrompt
          timesToShow={Number.MAX_SAFE_INTEGER - 1}
          onClose={() => {
            dispatch(hideIosInstallPrompt());
          }}
          permanentlyHideOnDismiss={false}
          delay={250}
          copyTitle={t("application__ios_prompt_title")}
          copyBody={t("application__ios_prompt_description")}
          copyShareButtonLabel={t("application__ios_prompt_share_button_label")}
          copyAddHomeButtonLabel={t("application__ios_prompt_add_home_button_label")}
          copyClosePrompt={t("application__ios_prompt_close_button_label")}
        />
      )}
    </PwaContext.Provider>
  );
};
