import { generatePath } from "react-router-dom";
import { GuestFlowContext } from "../../domain-common/guest-flow-checkpoint-and-args";

import { MagicObject } from "../../domain-common/magic-object";
import { GuestFlowCheckpoint } from "../guest-flow/checkpoint/guest-flow-checkpoint";
import { CheckpointService } from "./checkpoint-service";

interface SaveOpts<T extends GuestFlowContext> {
  magicObject: MagicObject<T>;
  checkpoint: GuestFlowCheckpoint;
  checkpointArgs?: any;
}

interface RestoreOpts<T extends GuestFlowContext> extends MagicObject<T> {}

// checkpoint is based on the storage content (browser session/local storage)
export class FlowSessionCheckpointService<
  M extends { [key: string]: string },
  T extends GuestFlowContext
> implements CheckpointService<SaveOpts<T>, RestoreOpts<T>>
{
  private readonly _storageKey = "magic_checkpoint";

  constructor(
    private storage: Storage,
    private pathMapper: M
  ) {}

  save(saveOptions: SaveOpts<T>) {
    const magicId = saveOptions.magicObject.magicId;
    const item = this.assignCheckpointToStorageItem(
      magicId,
      saveOptions.checkpoint,
      saveOptions.checkpointArgs
    );
    this.storage.setItem(this._storageKey, JSON.stringify(item));
  }

  async restore(restoreOpts: RestoreOpts<T>): Promise<string | null> {
    const storageItem = this.getStorageItem();
    if (storageItem?.[restoreOpts.magicId]) {
      return generatePath(
        this.pathMapper[storageItem[restoreOpts.magicId].checkpoint],
        storageItem[restoreOpts.magicId].checkpointArgs
      );
    }
    return null;
  }

  private assignCheckpointToStorageItem(
    magicId: string,
    checkpoint: GuestFlowCheckpoint,
    checkpointArgs: any
  ) {
    const item = this.getStorageItem() || {};
    item[magicId] = item[magicId] || {};
    item[magicId].checkpoint = checkpoint;
    item[magicId].checkpointArgs = checkpointArgs;
    return item;
  }

  private getStorageItem() {
    let storageItem;
    try {
      let item = this.storage.getItem(this._storageKey);
      if (item) {
        storageItem = JSON.parse(item);
      }
    } catch (e) {}
    return storageItem;
  }
}
