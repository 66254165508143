import { ReservationOfferDataWithUUID } from "../../../domain-common/create-booking-payment";
import { useEffect, useMemo } from "react";
import { getBookingCartTotalPrice, selectCartTotalPrice } from "../booking-cart.slice";
import { useAppDispatch } from "../../../state/store";
import { useSelector } from "react-redux";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useGetPotentialBookingPriceQuery } from "../../../graphql/queries/GetBookingTotalPrice.generated";
import { transformBookingTotalPrice } from "../../../graphql/transform/transform-booking-total-price";

export const useBookingTotalPrice = (
  reservations: Array<ReservationOfferDataWithUUID>,
  promoCodeMagic?: string
) => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();

  const { data } = useGetPotentialBookingPriceQuery(
    {
      booking: {
        booker: {},
        reservations: reservations.map((reservation) => ({
          pmsPropertyId: reservation.propertyId,
          pmsUnitGroupId: reservation.unitGroupId,
          pmsRatePlanId: reservation.ratePlanId,
          arrival: reservation.arrival,
          departure: reservation.departure,
          adultsAmount: reservation.adults,
          childrenAmount: reservation.childrenAges.length
        }))
      }
    },
    {
      skip: isRESTVersion || reservations.length === 0
    }
  );

  const potentialReservationsV1 = useMemo(
    () => reservations.map(({ cancellationFee, uuid, ...importantInfo }) => importantInfo),
    [reservations]
  );

  useEffect(() => {
    if (isRESTVersion && potentialReservationsV1.length) {
      dispatch(
        getBookingCartTotalPrice({
          reservations: potentialReservationsV1,
          ...(promoCodeMagic && { promoCodeMagic })
        })
      );
    }
  }, [dispatch, potentialReservationsV1, promoCodeMagic, isRESTVersion]);
  const totalBillV1 = useSelector(selectCartTotalPrice);

  const totalBill = isRESTVersion
    ? totalBillV1
    : transformBookingTotalPrice(data?.GetPotentialBookingPrice);

  return { totalBill };
};
