import { AdditionalServiceCartItem } from "../../../domain-common/additional-service-cart-item";
import { Reservation } from "../../../domain-common/reservation";
import { AdditionalServicesPaymentDTO } from "../../payment/payment-dto";
import {
  AdditionalServicesAvailabilityResponse,
  Granularity
} from "../../../api/dto/additional-services-availability-response";
import { InteractionType } from "./aditional-interaction-type";
import { Dictionary } from "@reduxjs/toolkit/src/entities/models";

export function additionalServiceCartItemsToAdditionalServicesPaymentDTO(
  items: AdditionalServiceCartItem[]
): AdditionalServicesPaymentDTO {
  return items.map((item) => ({
    serviceId: item.serviceId,
    code: item.code,
    dates: item.dates
  }));
}

export function getAllPaidServices(reservation: Reservation) {
  return reservation.servicesOverview?.map((service) => service.service.id) || null;
}

export const calculateInteractionType = (serviceConfig: AdditionalServicesAvailabilityResponse) => {
  switch (serviceConfig.granularity) {
    case Granularity.WHOLE_STAY:
    case Granularity.WHOLE_STAY_PAY_ONCE:
    case Granularity.DEPARTURE_DAY:
    case Granularity.ARRIVAL_DAY:
      if (serviceConfig.canBookMultiple) {
        return InteractionType.COUNTER;
      }
      return InteractionType.BOOLEAN;
    case Granularity.ANY_DAY:
    case Granularity.ANY_DAY_EXCEPT_ARRIVAL_DAY:
      return InteractionType.DATE_PICKER_DIALOG;
    case Granularity.LATE_CHECKOUT:
      return InteractionType.LATE_CHECKOUT_DIALOG;
    case Granularity.EARLY_CHECKIN:
      return InteractionType.EARLY_CHECKIN_DIALOG;
    default:
      console.warn("");
      return undefined;
  }
};

/**
 * Filter out stuff that are in the cart but not available any more
 * @param storage Cart items stored in the local storage
 * @param availability new info about status of the services
 */
export const invalidateCart = (
  storage: Dictionary<AdditionalServiceCartItem>,
  availability: Array<AdditionalServicesAvailabilityResponse>
) => {
  return availability.reduce((accumulator, item) => {
    const key: string | undefined = item?.serviceId;
    const isAvailable: boolean | undefined = storage[key] && item?.available;
    // Dates that are bookable for specific service.
    const bookable = item?.bookableDates?.reduce((accumulator: string[], c) => {
      accumulator.push(c.date);
      return accumulator;
    }, []);

    // Filtering of the dates based on above
    const dates = storage[key]?.dates?.filter(
      (date) => !bookable?.length || bookable?.includes(date.date)
    );

    // If dates don't exist, or we didn't filter all of them
    const datesAvailable: number | undefined | boolean = !storage[key]?.dates || dates?.length;

    return isAvailable && datesAvailable
      ? {
          ...accumulator,
          [key]: {
            ...storage[key],
            dates: dates
          }
        }
      : accumulator;
  }, {});
};
