import React, { FC, useMemo } from "react";
import { Box, Divider, Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Heading1, Heading3, Paragraph, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { PricePreview } from "../../../../components/atoms/price-preview/price-preview";
import { BookingCartTotalPrice } from "../../../../domain-common/booking-cart-total-price";
import { responsiveVariables } from "../../../../components/layouts/hooks/use-is-mobile";
import { toGross, toNet, toVat } from "../../../../domain-common/full-price";
import { useSelector } from "react-redux";
import { PromoCodeType } from "../../promocode.slice";
import { DisplayPromocodeDiscountAmount } from "../display-promocode-discount-amount";
import { usePromoCodeCmsTranslation } from "../../../../state/promo-codes-cms/use-promo-code-cms-translation";
import { selectCartReservations } from "../../booking-cart.slice";
import { useFeatureFlags } from "../../../../util/hooks/use-configuration";

const useStyles = makeStyles()(({ spacing, breakpoints }) => ({
  root: {
    marginTop: spacing(2)
  },
  divider: {
    height: 2,
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      margin: `${spacing(2)} 0`
    }
  }
}));

interface SearchCartTotalProps {
  totalBill: BookingCartTotalPrice;
  showVat: boolean;
  promoCodeData?: { promoCode?: string; type?: string };
}

export const SearchCartTotal: FC<React.PropsWithChildren<SearchCartTotalProps>> = ({
  totalBill,
  showVat,
  promoCodeData
}) => {
  const { classes } = useStyles();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { multiPropertyBookingEnabled } = useFeatureFlags();
  const cartReservations = useSelector(selectCartReservations);

  const propertyIdToUseInTranslation = useMemo(
    () => (multiPropertyBookingEnabled ? "" : cartReservations[0].propertyId),
    [multiPropertyBookingEnabled, cartReservations]
  );

  const promoCodesTranslation = usePromoCodeCmsTranslation(
    propertyIdToUseInTranslation,
    promoCodeData?.promoCode || ""
  );

  const theme = useTheme();

  return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={1}>
          {promoCodeData?.promoCode && !totalBill.promoCodeMagicDiscountHidden && (
            <>
              <Grid item xs={6}>
                <Paragraph>{t("labels__promocode_special_rate_code")}</Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row" justifyContent="end" spacing={2}>
                  <Grid item>
                    <ParagraphBold color="textSecondary">
                      {t("labels__promocode_code")}
                      {promoCodesTranslation}
                    </ParagraphBold>
                  </Grid>
                  {promoCodeData?.type === PromoCodeType.MAGIC && (
                    <DisplayPromocodeDiscountAmount
                      promoCodeMagicDiscountPercentage={
                        totalBill?.promoCodeMagicDiscountPercentage ?? 0
                      }
                      promoCodeMagicDiscountType={totalBill.promoCodeMagicDiscountType}
                      promoCodeMagicDiscount={totalBill.promoCodeMagicDiscount}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Paragraph>{t("labels__accommodations")}</Paragraph>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="row" justifyContent="end" spacing={2}>
              {promoCodeData?.promoCode &&
                promoCodeData?.type === PromoCodeType.MAGIC &&
                totalBill?.accommodationBeforeDiscount &&
                !totalBill.promoCodeMagicDiscountHidden && (
                  <Grid item>
                    <ParagraphBold
                      sx={{ textDecoration: "line-through", color: theme.palette.grey[500] }}
                    >
                      <PricePreview
                        price={
                          showVat
                            ? toNet(totalBill?.accommodationBeforeDiscount)
                            : toGross(totalBill?.accommodationBeforeDiscount)
                        }
                      />
                    </ParagraphBold>
                  </Grid>
                )}
              <Grid item>
                <ParagraphBold
                  align="right"
                  color={
                    promoCodeData?.promoCode &&
                    promoCodeData?.type === PromoCodeType.MAGIC &&
                    !totalBill.promoCodeMagicDiscountHidden
                      ? theme.palette.error.main
                      : "textSecondary"
                  }
                >
                  <PricePreview
                    price={
                      showVat ? toNet(totalBill.accommodation) : toGross(totalBill.accommodation)
                    }
                  />
                </ParagraphBold>
              </Grid>
            </Grid>
          </Grid>

          {totalBill.services.grossAmount > 0 && (
            <>
              <Grid item xs={6}>
                <Paragraph color="textSecondary">{t("labels__services")}</Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Heading3 align="right">
                  <PricePreview
                    price={showVat ? toNet(totalBill.services) : toGross(totalBill.services)}
                  />
                </Heading3>
              </Grid>
            </>
          )}

          {totalBill.cityTaxes.grossAmount > 0 && (
            <>
              <Grid item xs={6}>
                <Paragraph color="textSecondary">{t("labels__city_tax")}</Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Heading3 align="right">
                  <PricePreview
                    price={showVat ? toNet(totalBill.cityTaxes) : toGross(totalBill.cityTaxes)}
                  />
                </Heading3>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          {showVat && (
            <>
              <Grid item xs={6} pb={2}>
                <Heading3 color="textSecondary">{t("labels__vat_amount")}</Heading3>
              </Grid>
              <Grid item xs={6}>
                <Heading3 align="right" color="textSecondary">
                  <PricePreview price={toVat(totalBill.total)} />
                </Heading3>
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <Heading1>{t("labels__total_amount")}</Heading1>
          </Grid>

          <Grid item xs={6}>
            <Heading1 align="right">
              <PricePreview price={toGross(totalBill.total)} />
            </Heading1>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
