import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type SetPasswordMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
  password: Types.Scalars["String"];
}>;

export type SetPasswordMutation = {
  __typename?: "Mutation";
  SetPassword: { __typename?: "UserProfile"; id: number; userAccountUuid?: string | null };
};

export const SetPasswordDocument = `
    mutation SetPassword($magicId: String!, $magicToken: String!, $password: String!) {
  SetPassword(magicId: $magicId, magicToken: $magicToken, password: $password) {
    id
    userAccountUuid
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SetPassword: build.mutation<SetPasswordMutation, SetPasswordMutationVariables>({
      query: (variables) => ({ document: SetPasswordDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useSetPasswordMutation } = injectedRtkApi;
