import { Box, Divider } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { EntityStateStatus } from "../../../state/EntityStateStatus";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import {
  selectAdditionalServicesCart,
  selectAllAdditionalServicesCartItems
} from "../../additional-services/service-selection/additional-services-cart.slice";
import { additionalServiceCartItemsToAdditionalServicesPaymentDTO } from "../../additional-services/service-selection/additional-services.util";
import { useBookFreeAdditionalServices } from "../../additional-services/service-selection/use-book-free-additional-services";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { usePreparePayment } from "../../payment/use-prepare-payment";
import { useTotalPrice } from "../../payment/use-total-price";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { getNextFlowUrl, getPrevFlowUrl } from "../guest-flow-sequence";
import { useGuestFlow } from "../use-guest-flow";
import { useSetupGuestFlowNavigation } from "../hooks/use-setup-guest-flow-navigation";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { isReservation, isTravelBuddy } from "../../../util/flow";
import { isKioskMode } from "../../../util/kiosk-mode";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { useFetchAdditionalServices } from "../../services/use-fetch-additional-services";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { openBanner } from "../../banner/banner.slice";
import { useCommonErrorTranslation } from "../../../state/common-cms/use-common-error-translation";
import { TotalPrice } from "../../../components/molecules/total-price";
import { Visibility } from "../../../api/dto/additional-services-availability-response";
import { toGross } from "../../../domain-common/full-price";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { Surcharges } from "../../../components/organism/surcharges/surcharges";
import { DisplayBill } from "../../../components/organism/display-bill/display-bill";
import { PrepaymentType } from "../../../domain-common/folio";

interface OverviewPageProps {}

const CURRENT_PAGE = GuestFlowCheckpoint.OVERVIEW;
const SERVICE_PAGE = GuestFlowCheckpoint.SERVICES;

export const OverviewPage: React.FC<React.PropsWithChildren<OverviewPageProps>> = () => {
  const navigate = useNavigate();
  const { totalPriceStatus: additionalServicesCartPriceStatus } = useSelector(
    selectAdditionalServicesCart
  );

  const additionalServicesCartItems = useSelector(selectAllAdditionalServicesCartItems);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { reservation, contactButton, patchGuestFlowStep, skipButton } = useGuestFlow();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const isNextStepAvailable = useMemo(() => {
    const isSomeFolioNotDetermined = reservation.folios.some(
      (item) => item.prepaymentType === PrepaymentType.NOT_DETERMINABLE
    );
    const isSomeRateBreakDownNotDetermined = reservation.groupedRateBreakdown.some((item) =>
      item.breakdownItems.some((splice) =>
        splice.items.some((i) => i.prepaymentType === PrepaymentType.NOT_DETERMINABLE)
      )
    );

    return !isSomeFolioNotDetermined && !isSomeRateBreakDownNotDetermined;
  }, [reservation.folios, reservation.groupedRateBreakdown]);

  const { magicId } = useMagicIdParams();
  const { preparePayment } = usePreparePayment(magicId);
  const { bookFreeAdditionalServices } = useBookFreeAdditionalServices(
    magicId,
    reservation.magicToken
  );

  // the fetch is needed to calculate the steps in case we need to skip the additional service page.
  const { selectableAdditionalServices } = useFetchAdditionalServices(
    reservation.magicId,
    Visibility.GUEST_FLOW,
    isTravelBuddy(reservation)
  );

  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });

  const foliosToBePaid = useMemo(
    () => reservation.foliosToBePaid.map((f) => f.folioId),
    [reservation.foliosToBePaid]
  );
  const totalToBePaid = useTotalPrice(
    reservation.magicId,
    undefined,
    additionalServicesCartItems,
    foliosToBePaid
  );

  const skipPayment = totalToBePaid?.grossAmount === 0;

  const onSubmit = useCallback(async () => {
    if (totalToBePaid === null) {
      console.error("totalToBePaid === null during onSubmit");
      return;
    }
    if (totalToBePaid.grossAmount > 0) {
      await preparePayment({
        foliosToBePaid,
        additionalServices: additionalServiceCartItemsToAdditionalServicesPaymentDTO(
          additionalServicesCartItems
        )
      });
      // Note: we don't need the patchGuestFlowStep here because it's handled by onSuccessPayment in payment.page.tsx
      navigate(getNextFlowUrl(CURRENT_PAGE, reservation));
    } else {
      // we booked some free services
      if (additionalServicesCartItems.length > 0) {
        await bookFreeAdditionalServices(
          additionalServiceCartItemsToAdditionalServicesPaymentDTO(additionalServicesCartItems)
        );
      }
      await patchGuestFlowStep({
        reservationValues: reservation,
        checkpoint: GuestFlowCheckpoint.PAYMENT,
        subUpdate: SubUpdate.PAYMENT
      });
      navigate(getNextFlowUrl(GuestFlowCheckpoint.PAYMENT, reservation));
    }
  }, [
    navigate,
    reservation,
    patchGuestFlowStep,
    additionalServicesCartItems,
    preparePayment,
    foliosToBePaid,
    bookFreeAdditionalServices,
    totalToBePaid
  ]);

  const errorData = useCommonErrorTranslation(state?.errorId);

  useEffect(() => {
    if (state?.errorId) {
      dispatch(
        openBanner({
          type: "error",
          title: errorData?.text || ""
        })
      );
    }
  }, [state?.errorId, dispatch, errorData?.text]);

  const onBack = useCallback(() => {
    if (reservation.flowState.wasOrIsCompleted) {
      navigate(generatePortalMyStayUrl(reservation.magicId));
    } else {
      const currentPage = selectableAdditionalServices.length > 0 ? CURRENT_PAGE : SERVICE_PAGE;
      navigate(getPrevFlowUrl(currentPage, reservation));
    }
  }, [navigate, reservation, selectableAdditionalServices]);

  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const topBarIcons = useMemo(() => {
    if (isReservation(reservation)) {
      if (isKioskMode()) {
        return [skipButton];
      }
      return [contactButton];
    }
    return [];
  }, [reservation, contactButton, skipButton]);

  const groupedRateBreakdownNotDeterminable = useMemo(() => {
    return reservation?.groupedRateBreakdown?.every((groupedRateBreakdown) =>
      groupedRateBreakdown.breakdownItems
        .flatMap((item) => item.items)
        .every((item) => item.prepaymentType === PrepaymentType.NOT_DETERMINABLE)
    );
  }, [reservation]);

  return (
    <>
      <HelmetTitle suffix="Guest flow bill overview" />

      <FlowTemplate handleBack={onBack} icons={topBarIcons}>
        <GuestFlowBackgroundBox>
          <Box p={2} pt={0}>
            <DesktopCard>
              {!groupedRateBreakdownNotDeterminable && (
                <PageHeadingInfo
                  title={tCommon("title__overview_page")}
                  icon={<DisplayCmsSvg url={cmsData?.data?.icon__bill_icon?.url} />}
                />
              )}

              <DisplayBill
                reservation={reservation}
                notDeterminable={groupedRateBreakdownNotDeterminable}
              />

              {isNextStepAvailable && (
                <>
                  <Box my={1.5}>
                    <Divider />
                  </Box>
                  {totalToBePaid && (
                    <TotalPrice
                      price={toGross(totalToBePaid)}
                      title={tCommon("labels__total_to_be_paid")}
                    />
                  )}

                  <Box py={1.5}>
                    <Divider />
                  </Box>
                </>
              )}
              {skipPayment !== null && totalToBePaid !== null && isNextStepAvailable && (
                <SubmitButton
                  variant="primary"
                  onClick={() => onSubmit()}
                  disabled={
                    additionalServicesCartPriceStatus === EntityStateStatus.LOADING ||
                    reservation.folios.some((folio) => folio.hasPendingPaymentLink)
                  }
                  label={
                    skipPayment
                      ? tCommon("buttons__next")
                      : `${tCommon("buttons__pay")} ${formatPriceToString(toGross(totalToBePaid))}`
                  }
                />
              )}

              {!groupedRateBreakdownNotDeterminable && (
                <Box pt={2}>
                  <Surcharges />
                </Box>
              )}
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
