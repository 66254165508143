import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  Selector
} from "@reduxjs/toolkit";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { MagicApi } from "../../api/magic.api";
import { GuestFlowContext } from "../../domain-common/guest-flow-checkpoint-and-args";
import { MagicObject } from "../../domain-common/magic-object";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { getTenantHeaders } from "../../api/custom-headers";
import promiseRetry from "promise-retry";
import { handleSliceError } from "../../util/error-handling";

interface MagicObjectState<T extends GuestFlowContext> {
  status: EntityStateStatus;
  magicObject: MagicObject<T> | null;
}

const initialState: MagicObjectState<GuestFlowContext> = {
  status: EntityStateStatus.IDLE,
  magicObject: null
};

export const nextMagicObject = createAction<any>("magicObject/nextMagicObject");

export const fetchMagicObject = createAsyncThunk<
  MagicObject,
  { magicId: string; noOfRetries?: number },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>(
  "magicObject/fetchMagicObject",
  async (arg: { magicId: string; noOfRetries?: number }, thunkApi) => {
    const fetchAction = async () => {
      try {
        return await MagicApi.getMagicData(arg.magicId, {
          signal: thunkApi.signal,
          ...(await getTenantHeaders(thunkApi.extra))
        });
      } catch (e) {
        throw e;
      }
    };
    return arg.noOfRetries
      ? promiseRetry(
          function (retry) {
            return fetchAction().catch(retry);
          },
          { retries: arg.noOfRetries }
        ).then(
          function (value: any) {
            return value;
          },
          (err: any) => {
            return handleSliceError(err, thunkApi.rejectWithValue);
          }
        )
      : fetchAction().catch((err) => handleSliceError(err, thunkApi.rejectWithValue));
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().magicObject.status;
      const magicId = thunkAPI.getState().magicObject.magicObject?.magicId;
      if (
        status === EntityStateStatus.LOADING ||
        (magicId === arg.magicId &&
          (status === EntityStateStatus.FAILED || status === EntityStateStatus.SUCCEEDED))
      ) {
        return false;
      }
    }
  }
);

export const magicObjectSlice = createSlice({
  name: "magicObject",
  initialState,
  reducers: {
    initMagicObject: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMagicObject.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchMagicObject.fulfilled, (state, action) => {
        state.magicObject = action.payload;
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchMagicObject.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }

        if (action?.payload?.status === 401) {
          state.status = EntityStateStatus.UNAUTHORIZED;
          return;
        }

        if (action?.payload?.status === 403) {
          state.status = EntityStateStatus.FORBIDDEN;
          return;
        }

        state.status = EntityStateStatus.FAILED;
      })
      .addCase(nextMagicObject, (state, action) => {
        state.magicObject = action.payload;
      });
  }
});

export const { initMagicObject } = magicObjectSlice.actions;

const selectSelf: Selector<RootState, MagicObjectState<GuestFlowContext>> = (state: RootState) =>
  state.magicObject;

export const selectMagicObject = createSelector(selectSelf, (selfState) => selfState.magicObject);

export const selectMagicObjectStatus = createSelector(selectSelf, (res) => res.status);

export const selectIsMagicObjectLoading = createSelector(
  selectSelf,
  (state) => state.status === EntityStateStatus.LOADING
);
