import React, { FC, useMemo } from "react";
import { Grid } from "@mui/material";
import { PaperButton } from "../../../../components";
import { generatePortalRoomControlUrl } from "../../../../util/routing";
import { Reservation } from "../../../../domain-common/reservation";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useNavigate } from "react-router-dom";
import { useCMSData } from "../../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../../state/common-cms/common-cms.slice";

interface NavigationRoomControlProps {
  reservation: Reservation;
  title?: string;
  iconUrl?: string;
}

export const NavigationRoomControl: FC<NavigationRoomControlProps> = ({
  reservation,
  title,
  iconUrl
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const magicId = useMemo(() => reservation.magicId, [reservation.magicId]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  if (reservation.extras?.wiFiCredentials) {
    return (
      <Grid item xs={6}>
        <PaperButton
          title={title ?? t("navigations__room_control")}
          small
          onClick={() => {
            navigate(generatePortalRoomControlUrl(magicId));
          }}
          iconUrl={iconUrl ?? cmsData?.data?.icons__my_stay_navigation_box_room_control?.url}
        />
      </Grid>
    );
  } else {
    return null;
  }
};
