import React, { FC, useCallback, useEffect, useMemo } from "react";
import { FlowTemplate } from "../../../components/layouts/flow-template"; // If we import NotificationChipType directly from components
// jest test will have NotificationChipType as undefined and throw an error
// https://github.com/kulshekhar/ts-jest/issues/281#issuecomment-1028449872
import { NotificationChip } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Grid } from "@mui/material";
import { useAppDispatch } from "../../../state/store";
import { Actor } from "../../../domain-common/booking-overview";
import { BookingOverviewPages } from "../booking-overview-pages";
import { makeStyles } from "tss-react/mui";
import {
  Button,
  formatDateTime,
  Heading2,
  Paragraph,
  ParagraphBold,
  useCountries
} from "@likemagic-tech/sv-magic-library";
import { usePropertyById } from "../../property/use-property-by-id";
import {
  generateBookingOverviewPagesUrl,
  generateBookingOverviewReservationsURL
} from "../booking-overview-navigation";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { CancellationReservationDialog } from "../../../components/molecules/cancellation-policy/cancellation-reservation-dialog";
import { usePropertyTextCmsData } from "../../../state/property-cms/use-property-text-cms-data";
import { useUnitGroupCmsData } from "../../../state/property-cms/use-unit-group-cms-data";
import { useChipsAndButtonsBookingOverview } from "../hooks/use-chips-and-buttons-booking-overview";
import { generateGuestFlowUrl, getNextFlowCheckpoint } from "../../guest-flow/guest-flow-sequence";
import { GuestFlowCheckpointToPath } from "../../guest-flow/checkpoint/guest-flow-checkpoint-to-path";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { isTravelBuddy } from "../../../util/flow";
import { useCommonChannelCodeTranslation } from "../../../state/common-cms/use-common-channel-code-translation";
import { HelmetTitle } from "../../gtm/helmet-title";
import { responsiveVariables, useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { useFetchAllBookerOverview } from "../hooks/use-fetch-all-booker-overview";

import { ImageLimitedHeight, ImageLimitedHeightWrapper } from "../../../domain-common/image-style";
import { BookingOverviewAccordionInfo } from "../components/booking-overview-accordion-info";
import { BookedServiceList } from "../../additional-services/booked-services/booked-service-list";
import { FolioPreview } from "../../../components/organism/folio/folio-preview";
import { mapFolio } from "../../../components/organism/folio/folio-preview.util";
import { NotFoundPage } from "../../../components/organism/not-found.page";
import { isStatusSuccess } from "../../../state/EntityStateStatus";
import { useCancelReservation } from "../hooks/use-cancel-reservation";
import { useGetBookingOverviewMagicId } from "../booking-overview-subscription-provider";
import { DatePickerExtendStay } from "../../../components/molecules/date-picker-extend-stay";
import { useExtendStayModal } from "../../extend-stay/use-extend-stay-modal";
import { useExtendStayAction } from "../../extend-stay/use-extend-stay-action";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";
import { Surcharges } from "../../../components/organism/surcharges/surcharges";

const useStyles = makeStyles()(({ spacing, breakpoints, shape, palette }) => ({
  root: {
    marginTop: spacing(-3),
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      marginTop: 0
    }
  },
  image: {
    borderTopLeftRadius: shape.borderRadius * 3,
    borderTopRightRadius: shape.borderRadius * 3,
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      borderRadius: shape.borderRadius * 3,
      marginBottom: spacing(2)
    },
    width: "100%"
  },
  buttonsContainer: {
    flexDirection: "column",
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2.5),
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      flexDirection: "row",
      paddingLeft: spacing(0),
      paddingRight: spacing(0)
    }
  },
  detailsCard: {
    padding: spacing(3),
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      background: palette.common.white,
      borderRadius: shape.borderRadius * 3,
      padding: spacing(5),
      boxShadow: "0px 4px 8px rgba(197, 185, 172, 0.06)"
    }
  }
}));

export const BookingOverviewReservationDetailsPage: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { magicId: magicIdFromUrl, bookingOverviewItemId, reservationMagicId } = useMagicIdParams();
  const magicId = useGetBookingOverviewMagicId({
    magicIdFromUrl: magicIdFromUrl
  });
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const {
    getBookingOverviewByBookingOverviewItemId,
    getReservationByReservationId,
    bookingOverviewActiveStatus
  } = useFetchAllBookerOverview({ magicId });

  const bookingOverview = getBookingOverviewByBookingOverviewItemId(bookingOverviewItemId);
  const reservation = getReservationByReservationId(reservationMagicId);
  const { isReservationCanceled, cancelAction: cancelReservation } = useCancelReservation({
    reservation
  });
  const { getCountryNameByCode } = useCountries({ propertyId: reservation?.propertyId });

  const { enableExtendStay } = useFeatureFlags();
  const {
    onClick,
    unavailable,
    setUnavailable,
    extendModalOpen,
    setExtendModalOpen,
    extendStayActionDisabled
  } = useExtendStayModal({ reservation });
  const { onDatePickerSubmit } = useExtendStayAction({
    magicId: reservation?.magicId ?? "",
    setUnavailable
  });

  const { cancelButton, extendButton, primaryButtonLabel, chipValue, chipType } =
    useChipsAndButtonsBookingOverview({
      actor: bookingOverview?.actor || Actor.TRAVEL_BUDDY,
      bookingOnBehalfOf: reservation?.extras?.bookingOnBehalfOf,
      reservationStatus: reservation?.status || ReservationStatus.NO_SHOW,
      notificationSent: reservation?.flowState?.notificationSent || false,
      guestFlowCompleted: reservation?.flowState.wasOrIsCompleted || false,
      totalAllowedPaymentAmount: reservation?.totalAllowedPayment?.amount || 0
    });

  const handleBack = useCallback(() => {
    navigate(generateBookingOverviewReservationsURL(magicId, bookingOverviewItemId));
  }, [navigate, magicId, bookingOverviewItemId]);

  useEffect(() => {
    if (isReservationCanceled) {
      navigate("/reservation-cancel-confirmation");
    }
  }, [dispatch, navigate, isReservationCanceled]);

  const property = usePropertyById(reservation?.propertyId);

  const { firstStudioImage, unitGroupLabel } = useUnitGroupCmsData(
    reservation?.propertyId,
    reservation?.unit?.unitGroupId || reservation?.unitGroup.id
  );

  const externalCancellationPolicyPrismicKey = "cancellation_policies__external_dialog";
  const { textOutput: dialogTextCancellationPolicy } = usePropertyTextCmsData(
    "cancellation_policies__display_labels",
    property?.propertyId,
    reservation?.cancellationFee?.code,
    reservation?.cancellationFee?.description
  );
  const { textOutput: dialogTextExternalCancellationPolicy } = usePropertyTextCmsData(
    externalCancellationPolicyPrismicKey,
    property?.propertyId,
    reservation?.channelCode
  );

  const primaryButtonOnClick = useCallback(() => {
    if (reservation?.flowState?.wasOrIsCompleted) {
      navigate(generatePortalMyStayUrl(reservationMagicId));
    } else if (bookingOverview?.actor === Actor.BOOKER) {
      navigate(
        generateBookingOverviewPagesUrl(
          BookingOverviewPages.PERSONAL_DATA,
          magicId,
          bookingOverviewItemId,
          reservationMagicId
        )
      );
    } else if (reservation) {
      navigate(
        generateGuestFlowUrl(
          GuestFlowCheckpointToPath[
            getNextFlowCheckpoint(reservation.flowState.context?.lastConfirmedPage, reservation)
          ],
          reservation.magicId
        )
      );
    }
  }, [
    magicId,
    bookingOverviewItemId,
    reservationMagicId,
    navigate,
    reservation,
    bookingOverview?.actor
  ]);

  const person = useMemo(
    () => reservation?.primaryGuest || reservation?.travelBuddy,
    [reservation]
  );

  const primaryGuestAndNumberOfGuestsLabel = useMemo(() => {
    const adults = `${reservation?.adults} ${
      reservation?.adults === 1
        ? tCommon("labels__single_adult")
        : tCommon("labels__multiple_adult")
    }`;
    if (!reservation?.adults) {
      return `${person?.firstName} ${person?.lastName}`;
    }

    if (!reservation?.childrenAges || reservation.childrenAges.length === 0) {
      return `${person?.firstName} ${person?.lastName} [${adults}]`;
    }

    const children = `${reservation.childrenAges.length} ${
      reservation?.childrenAges?.length === 1
        ? tCommon("labels__single_child")
        : tCommon("labels__multiple_child")
    }`;

    return `${person?.firstName} ${person?.lastName} [${adults} ${children}]`;
  }, [
    person?.firstName,
    person?.lastName,
    reservation?.adults,
    reservation?.childrenAges,
    tCommon
  ]);

  const translatedChannelCode = useCommonChannelCodeTranslation(reservation?.channelCode);
  const channelCodeText = useMemo(() => {
    if (reservation?.channelCode) {
      return " (" + (translatedChannelCode || reservation?.channelCode) + ")";
    }
  }, [reservation, translatedChannelCode]);

  if (!isStatusSuccess(bookingOverviewActiveStatus)) {
    return <></>;
  }

  if (!reservation) {
    return <NotFoundPage />;
  }

  return (
    <Box className={classes.root}>
      <HelmetTitle suffix="Reservation details" />
      <FlowTemplate handleBack={handleBack}>
        <Box sx={ImageLimitedHeightWrapper}>
          <ImageLimitedHeight
            src={firstStudioImage?.url}
            alt={firstStudioImage?.alt}
            className={classes.image}
          />
        </Box>
        <Box py={1}>
          <Box className={classes.detailsCard}>
            <Heading2>
              {reservation?.unit?.name
                ? [unitGroupLabel, reservation?.unit?.name].join(" ")
                : unitGroupLabel}
            </Heading2>
            <Box my={1}>
              <NotificationChip label={tCommon(chipValue)} type={chipType} />
            </Box>
            <Box my={4}>
              <Grid container direction="row">
                <Grid xs={6} item>
                  <ParagraphBold>{tCommon("labels__checkin")}</ParagraphBold>
                </Grid>
                <Grid item xs={6}>
                  <Paragraph color="text.secondary">
                    {formatDateTime(
                      reservation?.checkInTime || reservation?.arrival,
                      getI18nSelectedLanguage(),
                      property.timeZone
                    )}
                  </Paragraph>
                </Grid>
              </Grid>
              <Grid container direction="row" mt={2.5}>
                <Grid item xs={6}>
                  <ParagraphBold>{tCommon("labels__checkout")}</ParagraphBold>
                </Grid>
                <Grid item xs={6}>
                  <Paragraph color="text.secondary">
                    {formatDateTime(
                      reservation?.checkOutTime || reservation?.departure,
                      getI18nSelectedLanguage(),
                      property.timeZone
                    )}
                  </Paragraph>
                </Grid>
              </Grid>
              <Grid container direction="row" mt={2.5}>
                <Grid item xs={6}>
                  <ParagraphBold>{tCommon("labels__booked")}</ParagraphBold>
                </Grid>
                <Grid item xs={6}>
                  <Paragraph color="text.secondary">
                    {formatDateTime(
                      reservation?.created,
                      getI18nSelectedLanguage(),
                      property.timeZone
                    )}
                    {channelCodeText}
                  </Paragraph>
                </Grid>
              </Grid>
            </Box>

            <Divider />
            <Box my={3}>
              <Grid container direction="row" mt={2.5}>
                <Grid item xs={6}>
                  <ParagraphBold>{tCommon("labels__guest")}</ParagraphBold>
                </Grid>
                <Grid item xs={6}>
                  <Paragraph color="text.secondary">{primaryGuestAndNumberOfGuestsLabel}</Paragraph>
                </Grid>
              </Grid>
              {!isTravelBuddy(reservation) && (
                <Grid container direction="row" mt={2.5}>
                  <Grid item xs={6}>
                    <ParagraphBold>{tCommon("labels__address")}</ParagraphBold>
                  </Grid>
                  <Grid item xs={6}>
                    <Paragraph color="text.secondary">{person?.address?.addressLine1}</Paragraph>
                    <Paragraph color="text.secondary">{person?.address?.addressLine2}</Paragraph>
                    <Paragraph color="text.secondary">
                      {person?.address?.postalCode && <span>{person?.address?.postalCode}</span>}
                      {person?.address?.city && (
                        <Box component="span" ml={1}>
                          {person?.address?.city}
                        </Box>
                      )}
                    </Paragraph>
                    <Paragraph color="text.secondary">
                      {person?.address?.countryCode &&
                        getCountryNameByCode(
                          getI18nSelectedLanguage(),
                          person?.address?.countryCode
                        )}
                    </Paragraph>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </Box>
        {isMobile && <Divider sx={{ m: 2.5 }} />}
        <Box py={1} px={isMobile ? 2.5 : 0}>
          <BookingOverviewAccordionInfo
            title={tCommon("title__booking_management_cancellation_policy")}
            subtitle={dialogTextCancellationPolicy?.text}
            content={
              <Paragraph color="black">
                {reservation.cancellationFee?.code
                  ? dialogTextCancellationPolicy?.details
                  : tCommon("labels__no_cancellation_police_provided")}
              </Paragraph>
            }
          />
        </Box>
        {isMobile && !!reservation?.servicesOverview?.length && <Divider sx={{ m: 2.5 }} />}
        {!!reservation?.servicesOverview?.length && (
          <Box py={1} px={isMobile ? 2.5 : 0}>
            <BookingOverviewAccordionInfo
              title={`${tCommon("title__booking_management_services")} ${
                "[" + reservation?.servicesOverview?.length + "]"
              }`}
              content={<BookedServiceList reservation={reservation} extensionReadOnly />}
            />
          </Box>
        )}
        {isMobile && <Divider sx={{ m: 2.5 }} />}
        <Box py={1} px={isMobile ? 2.5 : 0}>
          <BookingOverviewAccordionInfo
            title={tCommon("title__booking_management_invoice")}
            content={
              <>
                {reservation?.folios.map((folio, index) => {
                  const folioExists = mapFolio(folio, reservation, tCommon);
                  return folioExists ? (
                    <Grid py={2} key={`folio-${folio.id}`}>
                      <FolioPreview
                        folio={folioExists}
                        index={index}
                        reservation={reservation}
                        readOnly
                        overridePropertyId={reservation.propertyId}
                      />
                    </Grid>
                  ) : undefined;
                })}
                <Surcharges />
              </>
            }
          />
        </Box>
        <Grid mt={2} container spacing={1} className={classes.buttonsContainer}>
          <Grid item xs={12} md={3}>
            {primaryButtonLabel && (
              <Button fullWidth variant="primary" onClick={primaryButtonOnClick}>
                {tCommon(primaryButtonLabel)}
              </Button>
            )}
          </Grid>
          {reservation && cancelButton && !!reservation?.cancellationFee && (
            <Grid item xs={12} md={3}>
              <CancellationReservationDialog
                labels={{
                  buttonLabel: tCommon("buttons__cancel"),
                  dialogTitle: dialogTextCancellationPolicy?.text,
                  dialogTextWithForCancellationPolicy: dialogTextCancellationPolicy?.details || "",
                  dialogConfirmButtonLabel: tCommon("buttons__ok"),
                  dialogTextForExternalReservation: dialogTextExternalCancellationPolicy?.text
                }}
                cancelReservationAction={
                  reservation?.isExternalReservation ? undefined : cancelReservation
                }
              />
            </Grid>
          )}
          {/*!extendStayActionDisabled might be double check*/}
          {extendButton && reservation && enableExtendStay && !extendStayActionDisabled && (
            <Grid item xs={12} md={3}>
              <Button fullWidth variant="secondary" onClick={onClick}>
                {tCommon("buttons__extend_stay")}
              </Button>

              <DatePickerExtendStay
                reservation={reservation}
                labels={{
                  dialogTitle: tCommon("labels__date_picker"),
                  dialogButton: tCommon("buttons__extend_stay"),
                  unavailableDialogText: tCommon("modals__extend_stay_dialog_unavailable_text")
                }}
                onSubmit={onDatePickerSubmit}
                unavailable={unavailable}
                open={extendModalOpen}
                setOpen={setExtendModalOpen}
              />
            </Grid>
          )}
        </Grid>
      </FlowTemplate>
    </Box>
  );
};
