import React from "react";
import { Button } from "@likemagic-tech/sv-magic-library";

interface CallToActionButtonProps {
  onClick: () => void;
  text: string;
}

export const CallToActionButton: React.FC<React.PropsWithChildren<CallToActionButtonProps>> = ({
  onClick,
  text
}) => {
  return (
    <Button fullWidth variant="primary" onClick={onClick}>
      {text}
    </Button>
  );
};
