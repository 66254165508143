import { getLocalStorageObject } from "../state/local-storage-state";

const LOCAL_STORAGE_COOKIES = "cookies";

export const areCookiesAccepted = () => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_COOKIES);
  return Object.keys(storageValue).length !== 0 && storageValue.areAccepted === true;
};

export const isCookiesConsentAsked = () => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_COOKIES);
  return Object.keys(storageValue).length !== 0;
};

export const setCookiesAccepted = (areAccepted: boolean) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_COOKIES, JSON.stringify({ areAccepted }));
  } catch (e) {
    console.error("Failed to store cookies into local storage", e);
  }
};

export const deleteAllCookies = () => {
  var cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookie.split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};
