import { Operation } from "fast-json-patch";
import { Booking } from "../domain-common/booking";
import { GuestFlowContext } from "../domain-common/guest-flow-checkpoint-and-args";
import { ShopCartItem } from "../domain-common/shop-cart-item";
import { MagicFileType } from "../domain-common/magic-file-type";
import { MagicObject } from "../domain-common/magic-object";
import { Reservation } from "../domain-common/reservation";
import { TravelBuddy } from "../domain-common/travel-buddy";
import {
  FindReservationDTO,
  FindReservationResponseDTO
} from "../features/find-reservation/find-reservation.dto";
import { AdditionalServicesPaymentDTO, FolioPaymentDTO } from "../features/payment/payment-dto";
import { BaseApiClient, JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";

import { AdditionalServicesAvailabilityResponse } from "./dto/additional-services-availability-response";
import { Actor, BookingOverview } from "../domain-common/booking-overview";
import { FullPrice } from "../domain-common/full-price";
import { MobileSDKInvitationCodeResponse } from "./dto/mobilesdkinvitationcode-response";

const BASE_URL = "/api/guest-journey-service/magic";

export interface PatchReservationOpts {
  reservation: Reservation;
  patches: Operation[];
  sendNotification?: boolean;
  actor?: Actor;
}

export interface PatchBookingOpts {
  booking: Booking;
  patches: Operation[];
}

export interface PatchTravelBuddyOpts {
  travelBuddy: TravelBuddy;
  patches: Operation[];
  sendNotification?: boolean;
}

export interface UploadFileToReservationOpts {
  reservation: Reservation;
  file: File;
  fileType: MagicFileType;
}

export interface UploadFileResponse {
  createdAt: string;
  updatedAt: string;
  id: number;
  fileName: string;
  contentType: string;
  contentLength: number;
  blobCreatedAt: string;
  metaData: {
    ownerId: string;
    magicFileType: MagicFileType;
  };
  ownerId: string;
}

export interface DownloadFileOpts {
  reservation: Reservation;
  filename: string;
}

class MagicApiClient extends BaseApiClient {
  async findReservation(
    findReservationDTO: FindReservationDTO,
    init?: RequestInit
  ): Promise<Array<FindReservationResponseDTO>> {
    const url = BASE_URL + "/find";

    const response = await this.fetchApi(url, {
      ...init,
      method: "POST",
      body: JSON.stringify({
        ...findReservationDTO,
        arrival: findReservationDTO.arrival?.toISOString(),
        departure: findReservationDTO.departure?.toISOString()
      })
    });
    return new JSONApiResponse<Array<FindReservationResponseDTO>>(response).value();
  }

  async getMagicData<D extends GuestFlowContext, T extends MagicObject<D>>(
    magicId: string,
    init?: RequestInit
  ) {
    const url = BASE_URL + `/${magicId}`;
    const response = await this.fetchApi(url, init);
    return new JSONApiResponse<T>(response).value();
  }

  async patchReservation(
    { reservation, patches, sendNotification, actor }: PatchReservationOpts,
    init?: RequestInit
  ) {
    const response = await this.fetchApi(BASE_URL + `/${reservation.magicId}`, {
      ...init,
      method: "PATCH",
      headers: {
        ...init?.headers,
        "sk-magic-token": reservation.magicToken
      },
      body: JSON.stringify(
        sendNotification ? { patches, sendNotification, actor } : { patches, actor }
      )
    });
    return new VoidApiResponse(response).value();
  }

  async patchBooking({ booking, patches }: PatchBookingOpts, init?: RequestInit) {
    const response = await this.fetchApi(BASE_URL + `/${booking.magicId}`, {
      ...init,
      method: "PATCH",
      headers: {
        ...init?.headers,
        "sk-magic-token": booking.magicToken
      },
      body: JSON.stringify({ patches: patches })
    });
    return new VoidApiResponse(response).value();
  }

  async patchTravelBuddy(
    { travelBuddy, patches, sendNotification }: PatchTravelBuddyOpts,
    init?: RequestInit
  ) {
    const response = await this.fetchApi(BASE_URL + `/${travelBuddy.magicId}`, {
      ...init,
      method: "PATCH",
      headers: {
        ...init?.headers,
        "sk-magic-token": travelBuddy.magicToken
      },
      body: JSON.stringify(sendNotification ? { patches, sendNotification } : { patches })
    });
    return new VoidApiResponse(response).value();
  }

  async uploadFileToReservation(arg: UploadFileToReservationOpts, init?: RequestInit) {
    const { reservation, file, fileType } = arg;
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.fetch(BASE_URL + `/${reservation.magicId}/files/${fileType}`, {
      ...init,
      method: "POST",
      headers: {
        ...init?.headers,
        "sk-magic-token": reservation.magicToken
      },
      body: formData
    });
    return new JSONApiResponse<UploadFileResponse>(response).value();
  }

  async deleteFile(
    arg: {
      fileName: string;
      magicId: string;
      magicToken: string;
    },
    init?: RequestInit
  ) {
    const response = await this.fetch(
      BASE_URL + `/${arg.magicId}/files/${encodeURIComponent(arg.fileName)}`,
      {
        ...init,
        headers: {
          ...init?.headers,
          "sk-magic-token": arg.magicToken
        },
        method: "DELETE"
      }
    );

    return new VoidApiResponse(response).value();
  }

  async downloadFile(arg: DownloadFileOpts, init?: RequestInit) {
    const { reservation, filename } = arg;
    const encodedFilename = encodeURIComponent(filename);
    const response = await this.fetch(
      BASE_URL + `/${reservation.magicId}/files/${encodedFilename}`,
      {
        ...init,
        method: "GET",
        headers: { ...init?.headers, "sk-magic-token": reservation.magicToken }
      }
    );
    return response.blob();
  }

  async openDoor(doorId: string, reservation: Reservation, init?: RequestInit) {
    const response = await this.fetch(BASE_URL + `/${reservation.magicId}/doors/${doorId}/open`, {
      ...init,
      method: "POST",
      headers: { ...init?.headers, "sk-magic-token": reservation.magicToken }
    });
    return new VoidApiResponse(response).value();
  }

  async logDoorStatus(
    doorId: string,
    magicId: string,
    magicToken: string,
    status: boolean,
    data: any,
    init?: RequestInit
  ) {
    const response = await this.fetch(BASE_URL + `/${magicId}/doors/${doorId}/log`, {
      ...init,
      method: "POST",
      headers: {
        ...init?.headers,
        "sk-magic-token": magicToken,
        "Content-type": "application/json"
      },
      body: JSON.stringify({ status: status ? "OK" : "NOT_OK", log: data })
    });
    return new VoidApiResponse(response).value();
  }

  async getMobileSDKInvitationCode(
    param: {
      magicId: string;
    },
    init?: RequestInit
  ) {
    const response = await this.fetchApi(
      `${BASE_URL}/${param.magicId}/doors/mobilesdkinvitationcode`,
      {
        ...init
      }
    );
    return new JSONApiResponse<MobileSDKInvitationCodeResponse>(response).value();
  }

  async acceptMobileSDKInvitationCode(magicId: string, magicToken: string, init?: RequestInit) {
    const response = await this.fetchApi(
      `${BASE_URL}/${magicId}/doors/mobilesdkinvitationcode/accept`,
      {
        ...init,
        method: "POST",
        headers: { ...init?.headers, "sk-magic-token": magicToken }
      }
    );
    return new VoidApiResponse(response).value();
  }

  async checkin(reservation: Reservation, init?: RequestInit) {
    const response = await this.fetch(BASE_URL + `/${reservation.magicId}/checkin`, {
      ...init,
      method: "POST",
      headers: { ...init?.headers, "sk-magic-token": reservation.magicToken }
    });
    return new VoidApiResponse(response).value();
  }

  async checkout(arg: { magicId: string; magicToken: string }, init?: RequestInit) {
    const response = await this.fetch(BASE_URL + `/${arg.magicId}/checkout`, {
      ...init,
      method: "POST",
      headers: {
        ...init?.headers,
        "sk-magic-token": arg.magicToken
      }
    });
    return new VoidApiResponse(response).value();
  }

  async getTotalPrice(
    param: {
      magicId: string;
      shopItems?: ShopCartItem[];
      additionalServices?: AdditionalServicesPaymentDTO;
      foliosToBePaid?: FolioPaymentDTO;
    },
    init?: RequestInit
  ) {
    const response = await this.fetchApi(`${BASE_URL}/${param.magicId}/shop/price`, {
      ...init,
      method: "POST",
      body: JSON.stringify({
        shopItems: param.shopItems,
        additionalServices: param.additionalServices,
        foliosToBePaid: param.foliosToBePaid
      })
    });
    return new JSONApiResponse<FullPrice>(response).value();
  }

  async additionalServicesAvailability(
    param: {
      magicId: string;
    },
    init?: RequestInit
  ) {
    const response = await this.fetchApi(
      `${BASE_URL}/${param.magicId}/additional-services-availability`,
      {
        ...init
      }
    );
    return new JSONApiResponse<Array<AdditionalServicesAvailabilityResponse>>(response).value();
  }

  async requestAccess(
    param: {
      magicId: string;
    },
    init?: RequestInit
  ): Promise<void> {
    const url = BASE_URL + `/${param.magicId}/request-access`;

    const response = await this.fetch(url, {
      ...init,
      method: "POST"
    });
    return new VoidApiResponse(response).value();
  }

  async cancelReservation(reservation: Reservation, init?: RequestInit) {
    const response = await this.fetch(BASE_URL + `/${reservation.magicId}/cancel`, {
      ...init,
      method: "POST",
      headers: {
        ...init?.headers,
        "sk-magic-token": reservation.magicToken
      }
    });
    return new VoidApiResponse(response).value();
  }

  async assignTagToReservation(reservation: Reservation, tagReaderId: string, init?: RequestInit) {
    const response = await this.fetch(
      BASE_URL + `/${reservation.magicId}/assign-tag/${tagReaderId}`,
      {
        ...init,
        method: "POST",
        headers: {
          ...init?.headers,
          "sk-magic-token": reservation.magicToken
        }
      }
    );
    return new VoidApiResponse(response).value();
  }

  async encodeKey(reservation: Reservation, encoderId: string, init?: RequestInit) {
    const response = await this.fetch(
      BASE_URL + `/${reservation.magicId}/encode-key/${encoderId}`,
      {
        ...init,
        method: "POST",
        headers: {
          ...init?.headers,
          "sk-magic-token": reservation.magicToken
        }
      }
    );
    return new VoidApiResponse(response).value();
  }

  async fetchBookerView(magicId: string, init: { signal: AbortSignal }) {
    const url = BASE_URL + `/${magicId}/booking-overview`;
    const response = await this.fetchApi(url, init);
    return new JSONApiResponse<Array<BookingOverview>>(response).value();
  }
}

export const MagicApi = new MagicApiClient();
