import { PaymentDTO } from "../payment-dto";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { TerminalPayEvents } from "./terminal-pay-events";

interface UsePayWithTerminalArgs {
  abort?: AbortController;
  paymentDTO: PaymentDTO;
  magicId?: string;
  terminalId: string;
  handleNewEvent: (event: TerminalPayEvents, bookingMagicId?: string) => void;
  bookingCreatedEvent?: (magicId: string) => void;
  onInit?: () => void;
  onOnError?: (reason?: string) => void;
}

export const initTerminalPaymentFetchEventSource = async ({
  magicId,
  terminalId,
  handleNewEvent,
  onInit,
  onOnError,
  paymentDTO,
  abort
}: UsePayWithTerminalArgs) =>
  await fetchEventSource(
    magicId
      ? `/api/guest-journey-service/magic/${magicId}/shop/payment-by-terminal/${terminalId}`
      : `/api/guest-journey-service/booking-creation/payment-by-terminal/${terminalId}`,
    {
      method: "POST",
      body: magicId ? JSON.stringify(paymentDTO) : JSON.stringify(paymentDTO?.bookingCreation),
      headers: {
        "Content-type": "application/json"
      },
      onopen(res) {
        if (res.ok && res.status === 200) {
          onInit?.();
          return Promise.resolve();
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          onOnError?.();
          console.log("Client side error ", res);
        }
        return Promise.resolve();
      },
      onmessage(event) {
        switch (event.event) {
          case "pay-by-terminal-event":
            const data = JSON.parse(event.data);
            console.log(event);
            handleNewEvent(data.state as TerminalPayEvents, data?.data?.bookingMagicId);
            break;
          case "pay-by-terminal-error":
            console.warn(event);
            onOnError?.(event.data);
            break;
          case "keep-alive":
            break;
        }
      },
      onclose() {
        console.log("Connection closed by the server");
      },
      onerror(err: any) {
        console.log("There was an error from server", err);
      },
      signal: abort?.signal,
      openWhenHidden: true
    }
  );
