import React, { FC } from "react";
import { Box, Card, CardActionArea, CardContent, CardMedia, useTheme } from "@mui/material";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { toGross } from "../../../domain-v1/full-price";
import { makeStyles } from "tss-react/mui";
import { useServicesCmsData } from "../../services/use-services-cms-data";
import { PricePreview } from "../../../components/atoms/price-preview/price-preview";
import { SelfPouringStationItem } from "../self-poruing-station.types";

export interface SelfPouringStationItemProps {
  onClick: () => void;
  selfPouringStationItem: SelfPouringStationItem;
  propertyId: string;
}

const useStyles = makeStyles()((theme) => ({
  actionArea: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(6, 0)
  },
  media: {
    height: "80px",
    width: "80px",
    backgroundSize: "contain"
  },
  cardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  }
}));

export const SelfPouringStationItemCard: FC<SelfPouringStationItemProps> = ({
  onClick,
  selfPouringStationItem,
  propertyId
}) => {
  const { classes } = useStyles();
  const { palette } = useTheme();

  const {
    serviceTitles: productServiceTitles,
    serviceImages: productImagesByServiceId,
    fallbackImage: productFallbackImage
  } = useServicesCmsData(propertyId);

  return (
    <Card>
      <CardActionArea onClick={onClick} classes={{ root: classes.actionArea }}>
        <CardMedia
          image={productImagesByServiceId[selfPouringStationItem.serviceId] ?? productFallbackImage}
          className={classes.media}
          title={productServiceTitles[selfPouringStationItem.serviceId]}
        />
        <CardContent className={classes.cardContent}>
          <Box flexGrow={1}>
            <Paragraph gutterBottom align="center">
              {productServiceTitles[selfPouringStationItem.serviceId]}
            </Paragraph>
          </Box>
          <Box flexGrow={1}>
            <Paragraph gutterBottom align="center" sx={{ color: palette.text.secondary }}>
              {selfPouringStationItem.unit}L
            </Paragraph>
          </Box>
          <Box display="flex" alignItems="baseline" justifyContent="center">
            <Paragraph>
              {<PricePreview price={toGross(selfPouringStationItem.fullPrice)} />}
            </Paragraph>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
