import { FlowStateEnum } from "../../domain-common/flow-state";
import { Reservation } from "../../domain-common/reservation";
import { generateMagicLink } from "../../util/routing";
import { magicObjectStateToPathMapper } from "../magic/magic-object-state-to-path-mapper";
import { GuestFlowCheckpoint } from "./checkpoint/guest-flow-checkpoint";
import { GuestFlowCheckpointToPath } from "./checkpoint/guest-flow-checkpoint-to-path";
import { isReservation, isTravelBuddy } from "../../util/flow";
import { isKioskMode } from "../../util/kiosk-mode";

export const guestFlowPages = [
  GuestFlowCheckpoint.PERSONAL_DATA,
  GuestFlowCheckpoint.PREFERRED_CHANNEL,
  GuestFlowCheckpoint.LEGAL,
  GuestFlowCheckpoint.ADDRESS,
  GuestFlowCheckpoint.SERVICES,
  GuestFlowCheckpoint.OVERVIEW,
  GuestFlowCheckpoint.PAYMENT,
  GuestFlowCheckpoint.CONFIRMATION
];

export const travelBuddyFlowPages = [
  GuestFlowCheckpoint.PERSONAL_DATA,
  GuestFlowCheckpoint.PREFERRED_CHANNEL,
  GuestFlowCheckpoint.CONFIRMATION
];

export const kioskGuestFlowPages = [
  GuestFlowCheckpoint.PERSONAL_DATA,
  GuestFlowCheckpoint.PREFERRED_CHANNEL,
  GuestFlowCheckpoint.LEGAL,
  GuestFlowCheckpoint.ADDRESS,
  GuestFlowCheckpoint.SERVICES,
  GuestFlowCheckpoint.OVERVIEW,
  GuestFlowCheckpoint.PAYMENT,
  GuestFlowCheckpoint.ASSIGN_STUDIO,
  GuestFlowCheckpoint.CONFIRMATION,
  GuestFlowCheckpoint.ASSIGN_KEY
];

export const getFlowPages = (reservation: Reservation) => {
  if (isKioskMode()) {
    return kioskGuestFlowPages;
  } else {
    return isReservation(reservation) ? guestFlowPages : travelBuddyFlowPages;
  }
};

export const generateGuestFlowUrlFromCheckpoint = (
  checkpoint: GuestFlowCheckpoint,
  reservation: Reservation
) => generateGuestFlowUrl(GuestFlowCheckpointToPath[checkpoint], reservation.magicId);

export const generateGuestFlowUrl = (suffix: string, magicId: string) =>
  `${generateMagicLink(magicId)}${
    magicObjectStateToPathMapper[FlowStateEnum.RESERVATION_INIT]
  }${suffix}`;

export const getNextFlowPage = (
  curr: GuestFlowCheckpoint | undefined,
  reservation: Reservation
): string => GuestFlowCheckpointToPath[getNextFlowCheckpoint(curr, reservation)];

export const getNextFlowUrl = (
  curr: GuestFlowCheckpoint | undefined,
  reservation: Reservation
): string =>
  generateGuestFlowUrlFromCheckpoint(getNextFlowCheckpoint(curr, reservation), reservation);

export const getPrevFlowUrl = (
  curr: GuestFlowCheckpoint | undefined,
  reservation: Reservation
): string =>
  generateGuestFlowUrlFromCheckpoint(getPrevFlowCheckpoint(curr, reservation), reservation);

export const getNextFlowCheckpoint = (
  curr: GuestFlowCheckpoint | undefined,
  reservation: Reservation
): GuestFlowCheckpoint => {
  if (!curr) {
    return GuestFlowCheckpoint.PERSONAL_DATA;
  }
  const idx = getFlowPages(reservation).findIndex((x) => curr === x);

  if (idx === -1) {
    return GuestFlowCheckpoint.PERSONAL_DATA;
  }

  if (idx + 1 >= getFlowPages(reservation).length) {
    if (isReservation(reservation)) {
      return GuestFlowCheckpoint.OVERVIEW;
    }
    if (isTravelBuddy(reservation)) {
      return GuestFlowCheckpoint.CONFIRMATION;
    }
  }
  return getFlowPages(reservation)[idx + 1];
};

export const getPrevFlowCheckpoint = (
  curr: GuestFlowCheckpoint | undefined,
  reservation: Reservation
): GuestFlowCheckpoint => {
  if (!curr) {
    return GuestFlowCheckpoint.PERSONAL_DATA;
  }
  const idx = getFlowPages(reservation).findIndex((x) => curr === x);
  if (idx === 0) {
    return curr;
  }
  if (idx === -1) {
    return GuestFlowCheckpoint.PERSONAL_DATA;
  }

  return getFlowPages(reservation)[idx - 1];
};

// returns true if and only if the newCheckpoint is strictly after the checkpoint stored in the reservation
export const isANewerCheckpoint = (
  newCheckpoint: GuestFlowCheckpoint,
  reservation: Reservation
): boolean => {
  const previousCheckpoint = reservation?.flowState?.context?.lastConfirmedPage;
  if (!previousCheckpoint) {
    return true;
  }
  const newIdx = getFlowPages(reservation).findIndex((x) => newCheckpoint === x);
  const oldIx = getFlowPages(reservation).findIndex((x) => previousCheckpoint === x);
  return newIdx > oldIx;
};

export const isPagePartOfTheFlow = (pageName?: string) => {
  if (!pageName) {
    return false;
  }
  return [...guestFlowPages, ...kioskGuestFlowPages, ...travelBuddyFlowPages]
    .map((item) => GuestFlowCheckpointToPath[item])
    .filter((value, index, self) => self.indexOf(value) === index)
    .some((item) => item.indexOf(pageName) > -1);
};
