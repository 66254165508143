import { FC, PropsWithChildren, useContext } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { GraphqlClient } from "./graphql.api";
import { getSecondScreenId } from "../util/second-screen-mode";

export const GraphqlTenantHeadersSetup: FC<PropsWithChildren> = ({ children }) => {
  const { apiKey, tenantId } = useContext(TenantContext);

  const deviceId = getSecondScreenId();

  GraphqlClient.setHeaders({
    "tenant-id": tenantId,
    "sk-magic-api-key": apiKey || "",
    ...(deviceId ? { "sk-magic-second-screen-id": deviceId } : {})
  });

  return <>{children}</>;
};
