import React, { FC, useCallback, useMemo } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import { PrismicRichTextWrapper } from "../../../components";
import { MagicIncludedService, Offer } from "../../../domain-common/search-unit-group";
import {
  Heading3,
  Paragraph,
  ParagraphSmall,
  RadioButtonGroup,
  SmallPaperRadio,
  useGlobalModal
} from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { usePropertyTextCmsData } from "../../../state/property-cms/use-property-text-cms-data";
import { PriceWithAdditional } from "../../../components/atoms/price-with-additional/price-with-additional";
import { getServiceDetails, useServicesCmsData } from "../../services/use-services-cms-data";
import { useRatePlan } from "../../../state/property-cms/use-rate-plan";
import { InfoIcon } from "../../../components/icons";
import { getOkModalArg } from "../../global-modal/global-modal-util";
import { makeStyles } from "tss-react/mui";
import { DisplayPromocodeDiscountAmount } from "./display-promocode-discount-amount";

interface UnitGroupRatesProps {
  unitGroupId: string;
  propertyId: string;
  offers: Array<Offer>;
  value?: string;
  onChange: (value: string) => void;
  numberOfNights: number;
}

const RatePlanServiceItem: FC<
  React.PropsWithChildren<{
    serviceId: string;
    serviceCode: string;
    propertyId: string;
    quantity: number;
    suffix: string;
  }>
> = ({ serviceId, propertyId, quantity, suffix, serviceCode }) => {
  const { serviceTitles } = useServicesCmsData(propertyId);
  return (
    <li>
      {quantity > 0 && <span> {quantity} </span>}
      <span>{getServiceDetails({ serviceId, code: serviceCode }, serviceTitles, serviceId)}</span>
      <span> {suffix} </span>
    </li>
  );
};

export const UnitGroupRates: FC<React.PropsWithChildren<UnitGroupRatesProps>> = ({
  offers,
  unitGroupId,
  propertyId,
  onChange,
  value,
  numberOfNights
}) => {
  const { getRatePlanData } = useRatePlan(propertyId);
  const { open } = useGlobalModal();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { findTextOutput } = usePropertyTextCmsData(
    "cancellation_policies__display_labels",
    propertyId
  );
  const openDescription = useCallback(
    (offer: Offer) => () => {
      const ratePlanData = getRatePlanData(
        offer.ratePlanId,
        offer.ratePlanTitle,
        offer.ratePlanDescription
      );
      open(
        getOkModalArg(
          ratePlanData.title,
          <Box minWidth={200}>
            {ratePlanData?.description ? (
              <PrismicRichTextWrapper render={ratePlanData.description!} />
            ) : (
              <Paragraph>{ratePlanData.descriptionString}</Paragraph>
            )}
            <Paragraph>
              {offer.cancellationFee.code
                ? findTextOutput(offer.cancellationFee.code).text
                : offer.cancellationFee.description}
            </Paragraph>
          </Box>,
          t("buttons__ok")
        )
      );
    },
    [findTextOutput, getRatePlanData, open, t]
  );

  return (
    <RadioButtonGroup
      sx={{ flexWrap: "wrap" }}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
    >
      {offers.map((offer) => (
        <Grid container key={offer.ratePlanId} alignItems="center">
          <Grid item xs={11}>
            <SmallPaperRadio
              content={
                <Box>
                  <RatePlanItem
                    offer={offer}
                    unitGroupId={unitGroupId}
                    propertyId={propertyId}
                    numberOfNights={numberOfNights}
                  />
                </Box>
              }
              value={offer.ratePlanId}
              hiddenCheckbox
            />
          </Grid>

          <Grid item xs={1}>
            <IconButton onClick={openDescription(offer)}>
              <InfoIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </RadioButtonGroup>
  );
};

const useStyles = makeStyles()({
  twoRowElilipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
  }
});

const RatePlanItem: FC<
  React.PropsWithChildren<{
    offer: Offer;
    unitGroupId: string;
    propertyId: string;
    numberOfNights: number;
  }>
> = ({ offer, propertyId, numberOfNights }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { classes } = useStyles();
  const { getRatePlanData } = useRatePlan(propertyId);

  const mergedServices = useMemo(() => {
    return [
      ...(offer.services ?? []).map(
        (item) =>
          ({
            serviceId: item.service.id,
            serviceCode: item.service.code,
            quantity: 0
          }) as MagicIncludedService
      ),
      ...(offer.includedServices ?? []).map(
        (item) =>
          ({
            serviceId: item.service.id,
            serviceCode: item.service.code,
            quantity: 0
          }) as MagicIncludedService
      ),
      ...(offer.magicIncludedServices ?? [])
    ];
  }, [offer.services, offer.includedServices, offer.magicIncludedServices]);

  const includedServices = useMemo(() => {
    return mergedServices.map((service) => (
      <RatePlanServiceItem
        key={service.serviceId}
        serviceId={service.serviceId}
        serviceCode={service.serviceCode}
        quantity={service.quantity}
        propertyId={propertyId}
        suffix={t("labels__included")}
      />
    ));
  }, [mergedServices, propertyId, t]);

  const guaranteeType = useMemo(() => {
    switch (offer.minGuaranteeType) {
      case "Prepayment":
        return t("labels__prepayment");
      case "CreditCard":
        return t("labels__credit_card");
      case "PM6Hold":
        return t("labels__6pm_hold");
      case "Company":
        return t("labels__company");
    }
  }, [t, offer.minGuaranteeType]);

  const { textOutput: cancellationPolicyLabel } = usePropertyTextCmsData(
    "cancellation_policies__display_labels",
    propertyId,
    offer.cancellationFee.code
  );

  const cancellationDescription = useMemo(
    () =>
      offer.cancellationFee.code
        ? cancellationPolicyLabel?.text
        : offer.cancellationFee.description,
    [offer.cancellationFee, cancellationPolicyLabel]
  );

  const ratePlanData = getRatePlanData(
    offer.ratePlanId,
    offer.ratePlanTitle,
    offer.ratePlanDescription
  );

  const suffixTotalLabel =
    numberOfNights > 1 ? `${numberOfNights} ${t("labels__total_price_suffix")}` : undefined;

  return (
    <Grid container width="100%">
      <Grid item xs={12} mb={1}>
        <Grid container direction="row" alignItems="center">
          {ratePlanData.title && (
            <Grid item mr={1}>
              <Heading3 textAlign="left" className={classes.twoRowElilipsis}>
                {ratePlanData.title}
              </Heading3>
            </Grid>
          )}
          {offer.promoCodeMagicDiscount &&
          offer.promoCodeMagicDiscount?.amount &&
          !offer.promoCodeMagicDiscountHidden ? (
            <DisplayPromocodeDiscountAmount
              promoCodeMagicDiscount={offer.promoCodeMagicDiscount}
              promoCodeMagicDiscountPercentage={offer.promoCodeMagicDiscountPercentage}
              promoCodeMagicDiscountType={offer.promoCodeMagicDiscountType}
            />
          ) : undefined}
        </Grid>
      </Grid>
      {!!includedServices.length && (
        <Grid item xs={12} mb={2}>
          <ParagraphSmall textAlign="left" color="textSecondary">
            <span> {includedServices}</span>
          </ParagraphSmall>
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <Grid container height="100%">
          <Grid item alignSelf="flex-end" mb={1}>
            <ParagraphSmall textAlign="left" className={classes.twoRowElilipsis}>
              {`${guaranteeType} / ${cancellationDescription}`}
            </ParagraphSmall>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12} mb={1}>
        <PriceWithAdditional
          pricePerNight={offer.pricePerNight}
          priceTotal={offer.priceTotal}
          numberOfNights={numberOfNights}
          suffixPerNight={t("labels__price_suffix")}
          prefixTotal={t("labels__price_suffix_total")}
          suffixTotal={suffixTotalLabel}
          pricePerNightBeforeDiscount={offer.pricePerNightBeforeDiscount}
          priceTotalBeforeDiscount={offer.priceTotalBeforeDiscount}
          promoCodeMagicDiscountType={offer.promoCodeMagicDiscountType}
          hiddenDiscount={offer.promoCodeMagicDiscountHidden}
        />
      </Grid>
    </Grid>
  );
};
