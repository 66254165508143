import { CheckoutFlowPages } from "./checkout-flow-pages";

export const CheckoutFlowCheckpointToPath = {
  [CheckoutFlowPages.DEPARTURE_TIME]: "/departure-confirmation",
  [CheckoutFlowPages.MINIBAR]: "/minibar",
  [CheckoutFlowPages.BILL]: "/bill",
  [CheckoutFlowPages.PAYMENT]: "/payment",
  [CheckoutFlowPages.PAYMENT_REDIRECT]: "/payment-redirect",
  [CheckoutFlowPages.CONFIRMATION]: "/confirmation"
};
