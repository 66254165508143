import { EntityStateStatus, isStatusLoading } from "../../state/EntityStateStatus";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { handleSliceError } from "../../util/error-handling";
import { BoxShopApi } from "../../api/box-shop.api";
import { BoxRentedItem } from "../../domain-v1/box-rented-item";
import { getTenantHeaders } from "../../api/custom-headers";

interface RentSlice {
  status: EntityStateStatus;
}

const rentedItemEntityAdapter = createEntityAdapter<BoxRentedItem>({
  selectId: (model) => `${model.boxId}-${model.service.id}`
});

const initialState = rentedItemEntityAdapter.getInitialState<RentSlice>({
  status: EntityStateStatus.IDLE
});

export const fetchRentItems = createAsyncThunk<
  Array<BoxRentedItem>,
  { magicId: string; magicToken: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("rent/fetchItems", async (arg, thunkAPI) => {
  try {
    return await BoxShopApi.fetchRentedItems(arg, {
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const rentSlice = createSlice({
  name: "rent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRentItems.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchRentItems.fulfilled, (state, action) => {
        rentedItemEntityAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchRentItems.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer, actions } = rentSlice;

const selectSelf = (state: RootState) => state[rentSlice.name];

export const { selectAll: selectRentedItems } =
  rentedItemEntityAdapter.getSelectors<RootState>(selectSelf);

export const selectIsBoxReserving = createSelector(selectSelf, (s) => isStatusLoading(s.status));
