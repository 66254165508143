import { usePropertyIdFromBaseUrl } from "@likemagic-tech/sv-magic-library";
import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { generateContactPageUrl, getLocationOrigin } from "../../util/routing";
import { PhoneFilledIcon } from "../icons";
import { useIsMobile } from "../layouts/hooks/use-is-mobile";

export const ContactButtonNavigation: React.FC<
  React.PropsWithChildren<{ propertyId?: string }>
> = ({ propertyId }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const propertyIdDefault = usePropertyIdFromBaseUrl(getLocationOrigin());

  return (
    <IconButton
      sx={{
        color: isMobile ? "background.paper" : "common.black"
      }}
      onClick={() =>
        navigate(`${generateContactPageUrl(propertyId ? propertyId : propertyIdDefault)}`)
      }
      edge="end"
      size="large"
    >
      <PhoneFilledIcon sx={{ fontSize: 16 }} />
    </IconButton>
  );
};
