import React, { FC, SyntheticEvent, useRef } from "react";
import { PrismicImage } from "../../../state/property-cms/use-unit-group-cms-data";
import Carousel from "react-multi-carousel";
import { makeStyles } from "tss-react/mui";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    partialVisibilityGutter: 0
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 0
  }
};

const useStyles = makeStyles()(({ spacing, shape }) => ({
  imageContainer: {
    width: "100%",
    height: 300
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: shape.borderRadius * 2.5,
    objectFit: "cover",
    objectPosition: "50% 50%"
  },
  carouselContainer: {
    padding: spacing(2)
  },
  innerContainer: {
    borderTopRightRadius: shape.borderRadius * 2.5,
    borderTopLeftRadius: shape.borderRadius * 2.5
  },
  navButton: {
    position: "absolute",
    zIndex: 4,
    top: "50%",
    transform: "translateY(-50%)",
    margin: spacing(1)
  },
  nextNavButton: {
    right: 0
  },
  prevNavButton: {
    left: 0
  },
  dotList: {
    marginBottom: "10px !important"
  }
}));

interface CarouselGalleryProps {
  items: PrismicImage[];
  autoplay?: boolean;
  infinite?: boolean;
}

const CustomArrow: FC<
  React.PropsWithChildren<{
    direction: "left" | "right";
    onClick: (direction: "left" | "right") => void;
  }>
> = ({ direction, onClick }) => {
  const styleName = `react-multiple-carousel__arrow react-multiple-carousel__arrow--${direction}`;
  return (
    <button
      className={styleName}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
        onClick(direction);
      }}
    />
  );
};

export const CarouselGallery: FC<CarouselGalleryProps> = ({
  items,
  autoplay = false,
  infinite = false
}) => {
  const isMobile = useIsMobile();

  const { classes } = useStyles();

  const carousel = useRef<Carousel>(null);

  const onArrowClick = () => (direction: "left" | "right") => {
    carousel?.current?.goToSlide(
      carousel?.current?.state.currentSlide + (direction === "left" ? -1 : +1)
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <Carousel
        ref={carousel}
        responsive={responsive}
        autoPlay={autoplay}
        infinite={infinite}
        autoPlaySpeed={4000}
        arrows={!isMobile}
        showDots={true}
        customLeftArrow={<CustomArrow direction="left" onClick={onArrowClick} />}
        customRightArrow={<CustomArrow direction="right" onClick={onArrowClick} />}
        containerClass={classes.innerContainer}
        dotListClass={classes.dotList}
      >
        {items.map((item, index) => (
          <div className={classes.imageContainer} key={`${item.url}_${index}`}>
            <img className={classes.image} src={item.url} alt={item.alt} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
