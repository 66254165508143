import React from "react";
import { FallbackProps } from "react-error-boundary";

//This error handler should catch all errors that happen outside the app-provider-error-handler.tsx
export const AppProviderErrorHandler: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div>
      <p>Something went wrong.</p>
      <p>
        <strong>Error</strong>:
      </p>
      <p>{JSON.stringify(error)}</p>

      <button
        onClick={() => {
          // @ts-ignore
          window.location.reload(true);
          resetErrorBoundary();
        }}
      >
        Reload
      </button>
    </div>
  );
};
