import { useCallback, useMemo } from "react";
import yup from "../../config/yup.config";
import { MagicFile } from "../../domain-common/magic-file";
import { MagicFileType } from "../../domain-common/magic-file-type";
import { useCommonValidationCmsData } from "../../state/common-cms/use-common-cms-data";
import { parseDateString, useAuth } from "@likemagic-tech/sv-magic-library";
import { setLocale } from "yup";
import {
  useDisabledCountriesForIdentification,
  useDisabledCountriesForPassportUpload,
  useFeatureFlags
} from "./use-configuration";
import { ALL_COUNTRIES } from "../countries";
import { Person } from "../../domain-common/person";

export const useFormValidations = () => {
  const validationCmsData = useCommonValidationCmsData();
  const { getIsAuthenticated } = useAuth();
  const { documentsUploadEnabled, tfeFeatureEnabled } = useFeatureFlags();

  //Can't be in useEffect because it will be executed before useMemo
  //As well this method does not mutate yup object and does not trigger additional re-renders
  setLocale({
    mixed: {
      required: validationCmsData?.validation__required
    },
    string: {
      email: validationCmsData?.validation__email_format
    }
  });

  const personValidationFields = useMemo(
    () => ({
      firstName: yup
        .string()
        .required(validationCmsData?.validation__first_name_required || undefined),
      lastName: yup
        .string()
        .required(validationCmsData?.validation__last_name_required || undefined),
      email: yup
        .string()
        .required(validationCmsData?.validation__email_required || undefined)
        .notBlacklistedEmail(validationCmsData?.validation__email_blacklisted || undefined)
        .email(validationCmsData?.validation__email_format || undefined),
      phone: yup
        .string()
        .nullable()
        .phone(validationCmsData?.validation__phone_format || undefined)
    }),
    [validationCmsData]
  );

  const secondScreenMainGuestValidationFields = useMemo(
    () => ({
      firstName: yup
        .string()
        .required(validationCmsData?.validation__first_name_required || undefined),
      lastName: yup
        .string()
        .required(validationCmsData?.validation__last_name_required || undefined),
      email: yup
        .string()
        .required(validationCmsData?.validation__email_required || undefined)
        .notBlacklistedEmail(validationCmsData?.validation__email_blacklisted || undefined)
        .email(validationCmsData?.validation__email_format || undefined),
      phone: yup
        .string()
        .nullable()
        .phone(validationCmsData?.validation__phone_format || undefined)
    }),
    [validationCmsData]
  );

  const travelBuddyValidationFields = useMemo(
    () => ({
      firstName: yup
        .string()
        .required(validationCmsData?.validation__first_name_required || undefined),
      lastName: yup
        .string()
        .required(validationCmsData?.validation__last_name_required || undefined),
      email: yup
        .string()
        .email(validationCmsData?.validation__email_format)
        .when("phone", {
          is: (phone: string) => !phone || phone.length === 0,
          then: yup
            .string()
            .nullable()
            .email(validationCmsData?.validation__email_format),
          otherwise: yup
            .string()
            .nullable()
            .email(validationCmsData?.validation__email_format)
        }),
      phone: yup
        .string()
        .phone()
        .when("email", {
          is: (email: string) => !email || email.length === 0,
          then: yup
            .string()
            .nullable()
            .phone(validationCmsData?.validation__phone_format),
          otherwise: yup
            .string()
            .nullable()
            .phone(validationCmsData?.validation__phone_format)
        })
    }),
    [validationCmsData]
  );

  const createBookingPersonValidationFields = useMemo(
    () =>
      yup
        .object()
        .shape({
          firstName: yup
            .string()
            .required(validationCmsData?.validation__first_name_required || undefined),
          lastName: yup
            .string()
            .required(validationCmsData?.validation__last_name_required || undefined),
          email: yup
            .string()
            .required(validationCmsData?.validation__email_required || undefined)
            .notBlacklistedEmail(validationCmsData?.validation__email_blacklisted || undefined)
            .email(validationCmsData?.validation__email_format || undefined),
          termsAccepted: yup
            .boolean()
            .required(validationCmsData?.validation__email_required || undefined)
        })
        .required(),
    [validationCmsData]
  );

  const addressValidation = useMemo(
    () =>
      yup
        .object()
        .shape({
          addressLine1: yup
            .string()
            .default("")
            .max(200, validationCmsData?.validation__address_max || undefined)
            .required(validationCmsData?.validation__address_required || undefined),
          postalCode: yup
            .string()
            .default("")
            .required(validationCmsData?.validation__postal_code_required || undefined)
            .max(20, validationCmsData?.validation__postal_code_max || undefined),
          city: yup
            .string()
            .default("")
            .required(validationCmsData?.validation__city_required || undefined),
          countryCode: yup
            .string()
            .default("")
            .required(validationCmsData?.validation__country_required || undefined)
        })
        .required(),
    [validationCmsData]
  );

  const disabledCountriesForIdentification = useDisabledCountriesForIdentification();

  const disabledCountriesForPassportUpload = useDisabledCountriesForPassportUpload();

  const legalDataStepValidation = useMemo(
    () =>
      yup.object().shape({
        primaryGuest: yup
          .object()
          .shape({
            birthdate: yup
              .date()
              .nullable()
              .transform((_, originalValue: Date) => parseDateString(originalValue))
              .min("1900-01-01", validationCmsData?.validation__invalid_format || undefined)
              .max("2100-01-01", validationCmsData?.validation__invalid_format || undefined)
              .when([], (schema) =>
                tfeFeatureEnabled
                  ? schema
                  : schema.required(validationCmsData?.validation__birthdate_required || undefined)
              )
              .typeError(validationCmsData?.validation__invalid_format || undefined),
            nationalityCountryCode: yup
              .string()
              .when([], (schema) =>
                tfeFeatureEnabled
                  ? schema
                  : schema.required(validationCmsData?.validation__country_required || undefined)
              ),
            identificationNumber: yup.string().when("nationalityCountryCode", {
              is: (nationalityCountryCode: string) =>
                disabledCountriesForIdentification?.includes(nationalityCountryCode) ||
                disabledCountriesForIdentification?.includes(ALL_COUNTRIES),
              then: yup.string().nullable(),
              otherwise: yup
                .string()
                .required(validationCmsData?.validation__id_number_required || undefined)
            })
          })
          .required(),
        files: yup.array(),
        signatureImage: yup.string().when("files", {
          is: (val: MagicFile[]) =>
            !val.find((f) => f.metaData.magicFileType === MagicFileType.SIGNATURE_DOCUMENT),
          then: yup
            .string()
            .required(validationCmsData?.validation__signature_required || undefined),
          otherwise: getIsAuthenticated()
            ? yup.string().required(validationCmsData?.validation__signature_required || undefined)
            : yup.string().nullable().optional()
        }),
        identificationImage: yup.string().when(["files", "primaryGuest"], {
          is: (files: MagicFile[], primaryGuest: Partial<Person>) => {
            return (
              ((!files.find(
                (f) => f.metaData.magicFileType === MagicFileType.IDENTIFICATION_DOCUMENT
              ) &&
                documentsUploadEnabled) ||
                (files.find(
                  (f) => f.metaData.magicFileType === MagicFileType.IDENTIFICATION_DOCUMENT
                ) &&
                  getIsAuthenticated())) &&
              !(
                disabledCountriesForPassportUpload?.includes(
                  primaryGuest.nationalityCountryCode ?? ""
                ) || disabledCountriesForPassportUpload?.includes(ALL_COUNTRIES)
              )
            );
          },
          then: yup
            .string()
            .required(validationCmsData?.validation__id_document_required || undefined),
          otherwise: yup.string().nullable().optional()
        }),
        acceptedTerms: yup
          .boolean()
          .default(false)
          .required()
          .oneOf([true], validationCmsData?.validation__t_c_online_required || undefined)
      }),
    [
      validationCmsData,
      getIsAuthenticated,
      tfeFeatureEnabled,
      disabledCountriesForIdentification,
      documentsUploadEnabled,
      disabledCountriesForPassportUpload
    ]
  );

  const homeAddressValidation = useMemo(
    () =>
      yup
        .object()
        .shape({
          primaryGuest: yup
            .object()
            .shape({
              address: addressValidation
            })
            .required()
        })
        .required(),
    [addressValidation]
  );

  const addressFormValidation = useMemo(
    () =>
      yup
        .object()
        .shape({
          mainAddress: addressValidation,
          isSameAddress: yup.boolean().default(true).required(),
          billingAddress: yup
            .object()
            .when("isSameAddress", {
              is: false,
              then: yup
                .object()
                .shape({
                  addressLine1: yup
                    .string()
                    .default("")
                    .max(200, validationCmsData?.validation__address_max || undefined)
                    .required(validationCmsData?.validation__address_required || undefined),
                  postalCode: yup
                    .string()
                    .default("")
                    .required(validationCmsData?.validation__postal_code_required || undefined)
                    .max(20, validationCmsData?.validation__postal_code_max || undefined),
                  reference: yup
                    .string()
                    .default("")
                    .max(80, validationCmsData?.validation__reference_max || undefined),
                  city: yup
                    .string()
                    .default("")
                    .required(validationCmsData?.validation__city_required || undefined),
                  countryCode: yup
                    .string()
                    .default("")
                    .required(validationCmsData?.validation__country_required || undefined),
                  email: yup
                    .string()
                    .notBlacklistedEmail(
                      validationCmsData?.validation__email_blacklisted || undefined
                    )
                    .email(validationCmsData?.validation__email_format || undefined),
                  companyName: yup.string().when("companyTaxId", {
                    is: (val: string) => !!val,
                    then: yup
                      .string()
                      .required(validationCmsData?.validation__companyName_required || undefined),
                    otherwise: yup.string().nullable().optional()
                  })
                })
                .required()
            })
            .when("areNamesRequired", {
              is: true,
              then: yup
                .object()
                .shape({
                  firstName: yup
                    .string()
                    .default("")
                    .required(validationCmsData?.validation__first_name_required || undefined),
                  lastName: yup
                    .string()
                    .default("")
                    .required(validationCmsData?.validation__last_name_required || undefined)
                })
                .required()
            })
            .optional()
        })
        .required(),

    [addressValidation, validationCmsData]
  );

  const preferredChannelStepValidation = useMemo(
    () =>
      yup.object().shape({
        extras: yup.object().shape({
          preferredCommunicationChannel: yup
            .string()
            .required(validationCmsData?.validation__preferred_communication_channel_required)
        })
      }),
    [validationCmsData]
  );

  const buddyValidation = useMemo(
    () => yup.object().shape(travelBuddyValidationFields, [["email", "phone"]]),
    [travelBuddyValidationFields]
  );

  const personalDataStepValidation = useMemo(
    () =>
      yup.object().shape({
        person: yup
          .object()
          .shape(personValidationFields, [["email", "phone"]])
          .required(),
        additionalGuests: yup.array().of(buddyValidation)
      }),
    [personValidationFields, buddyValidation]
  );

  const secondScreenCheckinValidation = useMemo(
    () =>
      yup.object().shape({
        mainGuest: yup.object().shape(secondScreenMainGuestValidationFields).required()
      }),
    [secondScreenMainGuestValidationFields]
  );

  const profilePersonalDataValidation = useMemo(
    () =>
      yup.object().shape({
        primaryGuest: yup
          .object()
          .shape(personValidationFields, [["email", "phone"]])
          .required()
      }),
    [personValidationFields]
  );

  const travelBuddyDataStepValidation = useCallback(
    (fieldName: string) =>
      yup.object().shape({
        [fieldName]: yup
          .object()
          .shape(personValidationFields, [["email", "phone"]])
          .required(),
        acceptedTerms: yup
          .boolean()
          .default(false)
          .required()
          .oneOf([true], validationCmsData?.validation__t_c_online_required || undefined)
      }),
    [personValidationFields, validationCmsData?.validation__t_c_online_required]
  );

  const additionalGuestsValidation = useMemo(
    () =>
      yup.object().shape({
        additionalGuests: yup.array().of(buddyValidation)
      }),
    [buddyValidation]
  );

  /**
   * Should be used only on Reservation pages when adding/editing travel buddies
   */
  const travelBuddyValidation = useMemo(
    () =>
      yup.object().shape({
        travelBuddy: buddyValidation
      }),
    [buddyValidation]
  );

  const tcMinorsValidation = useMemo(
    () =>
      yup.object().shape({
        extras: yup.object().shape({
          tcMinors: yup.boolean().when(["$additionalGuests", "$travelBuddy"], {
            is: (additionalGuests: any, travelBuddy: any) => {
              const hasGuests = additionalGuests && additionalGuests.length > 0;
              const hasTravelBuddyData = !!travelBuddy;
              return hasGuests || hasTravelBuddyData;
            },
            then: yup
              .boolean()
              .required()
              .oneOf([true], validationCmsData?.validation__tcminors_required || undefined),
            otherwise: yup.boolean()
          })
        })
      }),
    [validationCmsData]
  );

  const promoCodeValidation = useMemo(
    () =>
      yup.object().shape({
        promoCode: yup
          .string()
          .required(
            validationCmsData?.validation__promocode_missing || "validation__promocode_missing"
          )
      }),
    [validationCmsData?.validation__promocode_missing]
  );

  const checkoutTimeValidation = useMemo(
    () =>
      yup.object().shape({
        time: yup
          .date()
          .required(validationCmsData?.checkout_time_before_11am || "validation__checkout_time")
          .test(
            "is-before-11am",
            validationCmsData?.checkout_time_before_11am || "Time must be before 11 AM",
            (value) => {
              if (!value) return false;
              const hours = value.getHours();
              return hours <= 11;
            }
          )
      }),
    [validationCmsData?.checkout_time_before_11am]
  );

  return {
    additionalGuestsValidation,
    homeAddressValidation,
    legalDataStepValidation,
    secondScreenCheckinValidation,
    personalDataStepValidation,
    travelBuddyDataStepValidation,
    preferredChannelStepValidation,
    profilePersonalDataValidation,
    addressFormValidation,
    travelBuddyValidation,
    createBookingPersonValidationFields,
    promoCodeValidation,
    tcMinorsValidation,
    checkoutTimeValidation
  };
};
