import { createAsyncThunk, createSelector, createSlice, Selector } from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { Reservation } from "../../domain-common/reservation";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { MagicApi } from "../../api/magic.api";
import { handleSliceError } from "../../util/error-handling";
import { EntityStateStatus, isStatusLoading } from "../../state/EntityStateStatus";
import { getTenantHeaders } from "../../api/custom-headers";

type KeyAssignState = {
  assignTagStatusWithAPI: EntityStateStatus;
  assignTagStatusWithWS: EntityStateStatus;
};

const initialState: KeyAssignState = {
  assignTagStatusWithAPI: EntityStateStatus.IDLE,
  assignTagStatusWithWS: EntityStateStatus.IDLE
};

export const assignTagToReservation = createAsyncThunk<
  any,
  { reservation: Reservation; tagNumber: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("checkin/assignTagToReservation", async (arg, thunkAPI) => {
  try {
    return await MagicApi.assignTagToReservation(arg.reservation, arg.tagNumber, {
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const encodeKeyWithAPI = createAsyncThunk<
  any,
  { reservation: Reservation; encoderId: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("checkin/encodeKeyWithAPI", async (arg, thunkAPI) => {
  try {
    return await MagicApi.encodeKey(arg.reservation, arg.encoderId, {
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const keyAssignSlice = createSlice({
  name: "keyAssignSlice",
  initialState,
  reducers: {
    resetKeyAssignSlice: (state) => {
      state.assignTagStatusWithAPI = initialState.assignTagStatusWithAPI;
      state.assignTagStatusWithWS = initialState.assignTagStatusWithWS;
    },
    assignTagStatusWithWSSucceeded: (state) => {
      state.assignTagStatusWithWS = EntityStateStatus.SUCCEEDED;
    },
    assignTagStatusWithWSFailed: (state) => {
      state.assignTagStatusWithWS = EntityStateStatus.FAILED;
    },
    assignTagStatusWithWSLoading: (state) => {
      state.assignTagStatusWithWS = EntityStateStatus.LOADING;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(assignTagToReservation.pending, (state) => {
      state.assignTagStatusWithAPI = EntityStateStatus.LOADING;
    });
    builder.addCase(assignTagToReservation.fulfilled, (state) => {
      state.assignTagStatusWithAPI = EntityStateStatus.SUCCEEDED;
    });
    builder.addCase(assignTagToReservation.rejected, (state) => {
      state.assignTagStatusWithAPI = EntityStateStatus.FAILED;
    });
    builder.addCase(encodeKeyWithAPI.pending, (state) => {
      state.assignTagStatusWithAPI = EntityStateStatus.LOADING;
    });
    builder.addCase(encodeKeyWithAPI.fulfilled, (state) => {
      state.assignTagStatusWithAPI = EntityStateStatus.SUCCEEDED;
    });
    builder.addCase(encodeKeyWithAPI.rejected, (state) => {
      state.assignTagStatusWithAPI = EntityStateStatus.FAILED;
    });
  }
});

export const {
  resetKeyAssignSlice,
  assignTagStatusWithWSSucceeded,
  assignTagStatusWithWSFailed,
  assignTagStatusWithWSLoading
} = keyAssignSlice.actions;

export const selectKeyAssignSlice: Selector<RootState, KeyAssignState> = (state: RootState) =>
  state[keyAssignSlice.name];

export const selectAssignKeyIsLoading = createSelector(selectKeyAssignSlice, (state) =>
  isStatusLoading(state.assignTagStatusWithAPI)
);

export const selectAssignKeyWithAPIStatus = createSelector(
  selectKeyAssignSlice,
  (state) => state.assignTagStatusWithAPI
);

export const selectAssignKeyWithWSStatus = createSelector(
  selectKeyAssignSlice,
  (state) => state.assignTagStatusWithWS
);
