import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { GtmEvent, useGtm } from "./use-gtm";
import { useActor } from "./use-actor";
import { useFetchMagicObject } from "../magic/use-fetch-magic-object";

interface GtmPathnameChangeListenerProps {}

export const GtmPathnameChangeListener: React.FC<
  React.PropsWithChildren<GtmPathnameChangeListenerProps>
> = () => {
  const { pushToGtm } = useGtm();
  const { pathname } = useLocation();
  const magicId = matchPath("/magic/:magicId/*", pathname)?.params?.magicId;
  const { magicObject } = useFetchMagicObject({ magicId });

  const { actor } = useActor({ magicObject });

  const pageId = pathname.split("/").pop();

  React.useEffect(() => {
    pushToGtm({
      event: GtmEvent.PATHNAME_CHANGE,
      pathname,
      id: magicObject?.id,
      pageId: pageId,
      actor: actor
    });
  }, [pushToGtm, pathname, magicObject, actor, pageId]);

  return null;
};
