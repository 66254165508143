import { Avatar, Box, Divider, Grid, useTheme } from "@mui/material";
import { Heading1, Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { DisplayCmsSvg, PrismicRichTextWrapper } from "../../../components";
import { FeaturesList } from "../../../components/molecules/features-preview/features-list";
import { usePropertyCmsData } from "../../../state/property-cms/use-property-cms-data";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { MapPreview } from "../../../components/molecules/map-preview";
import { SizeEnum } from "../../../domain-common/size-enum";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    partialVisibilityGutter: 40
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    partialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    partialVisibilityGutter: 40
  }
};

const useStyles = makeStyles()(({ spacing, palette, shape }) => ({
  avatar: {
    width: spacing(11),
    height: spacing(11),
    border: `3px solid ${palette.secondary.main}`,
    borderRadius: "50%",
    padding: spacing(0.5),
    "& > img": {
      borderRadius: "50%"
    }
  },
  imageContainer: {
    width: "95%",
    height: "100%"
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: shape.borderRadius,
    objectFit: "cover",
    objectPosition: "50% 50%"
  },
  carouselContainer: {
    padding: spacing(2)
  },
  locationFieldContainer: {
    "& svg *": {
      stroke: "inherit"
    }
  }
}));

interface LocationFieldProps {
  url: string;
  description: string;
}

const LocationField: FC<React.PropsWithChildren<LocationFieldProps>> = ({ description, url }) => {
  const { classes } = useStyles();
  const { palette } = useTheme();

  return (
    <Grid
      className={classes.locationFieldContainer}
      mb={1}
      container
      flexWrap="nowrap"
      flexDirection="row"
    >
      <DisplayCmsSvg url={url} sx={{ marginRight: 1 }} stroke={palette.secondary.main} />
      <Paragraph>{description}</Paragraph>
    </Grid>
  );
};

export const SearchPropertyDetail: FC<{ propertyId: string }> = ({ propertyId }) => {
  const { classes } = useStyles();
  const { propertyName, propertyLocation } = usePropertyCmsData(propertyId);
  const { originalImageSizeForPropertyFeaturesEnabled } = useFeatureFlags();

  const { propertyFeatures, host, propertyImages, address, propertyDescription } =
    usePropertyCmsData(propertyId);

  return (
    <>
      <Grid container flexDirection="column" justifyContent="center" alignItems="center" p={3}>
        <Heading1 textAlign="center">{propertyName}</Heading1>
        <Heading2 color="text.secondary">{propertyLocation}</Heading2>
      </Grid>
      <Divider />
      <FeaturesList
        items={propertyFeatures}
        itemSize={originalImageSizeForPropertyFeaturesEnabled ? SizeEnum.CUSTOM : SizeEnum.LARGE}
      />
      <Divider />
      <Grid container flexDirection="row" flexWrap="nowrap" alignItems="center" p={2}>
        {host?.picture?.url && (
          <Box mr={2.5}>
            <Avatar
              alt="Host"
              className={classes.avatar}
              // @ts-ignore
              src={host?.picture?.url}
            />
          </Box>
        )}
        <Grid container flexDirection="column">
          {host?.description && <PrismicRichTextWrapper render={host?.description} />}
        </Grid>
      </Grid>
      <Divider />
      <Carousel
        responsive={responsive}
        containerClass="carousel-container"
        className={classes.carouselContainer}
        autoPlay={false}
      >
        {propertyImages.map((item, index) => (
          <div className={classes.imageContainer} key={`${item.url}-${index}`}>
            <img className={classes.image} src={item.url} alt={item.alt} />
          </div>
        ))}
      </Carousel>

      <Box height={300} px={2}>
        <MapPreview selectedPropertyId={propertyId} />
      </Box>
      <Grid p={3}>
        {address?.locationsList?.map((loc) => (
          <LocationField key={loc.icon.url} url={loc.icon.url} description={loc.description} />
        ))}
      </Grid>
      <Divider />

      <Grid px={3} py={3}>
        {propertyDescription && <PrismicRichTextWrapper render={propertyDescription} />}
      </Grid>
    </>
  );
};
