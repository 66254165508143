import { useSelector } from "react-redux";
import { initiatePaymentDetails, selectPaymentSlice } from "../payment.slice";
import { useAppDispatch } from "../../../state/store";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useCallback, useMemo } from "react";
import { useInitiatePaymentDetailsMutation } from "../../../graphql/mutations/initiate-payment-details.generated";
import { usePayDetailsAndCreateBookingMutation } from "../../../graphql/mutations/pay-details-and-create-booking.generated";
import {
  EntityStateStatus,
  mapQueryStatusToEntityStateStatus
} from "../../../state/EntityStateStatus";
import { transformToGrossPrice } from "../../../graphql/transform/transform-payment";
import { prepareServicesForPaymentV2 } from "../../../graphql/transform/transform-additional-services-availability";

interface UsePaymentDetailsProps {
  magicId?: string;
  data: any;
}

export const usePaymentDetails = () => {
  const {
    paymentDetailsResponse: paymentDetailsResponseV1,
    paymentDetailsStatus: paymentDetailsStatusV1,
    referenceId
  } = useSelector(selectPaymentSlice);

  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();

  const [
    initiatePaymentDetailsV2,
    { data: initiatePaymentDetailsResponseV2, status: initiatePaymentDetailsStatusV2 }
  ] = useInitiatePaymentDetailsMutation();
  const [
    payDetailsAndCreateBookingMutation,
    { data: paymentDetailsResponseV2, status: paymentDetailsStatusV2 }
  ] = usePayDetailsAndCreateBookingMutation();

  const initiatePaymentDetailsActionV2 = useCallback(
    (arg: UsePaymentDetailsProps) => {
      if (arg.magicId) {
        return initiatePaymentDetailsV2({
          magicId: arg.magicId,
          paymentDetailsRequest: {
            amount: transformToGrossPrice(arg.data.paymentDTO.adyenPrice),
            frontendPayload: arg.data.frontendPayload,
            reference: referenceId,
            services: arg.data.paymentDTO.additionalServices?.map(prepareServicesForPaymentV2) ?? []
          }
        });
      } else {
        return payDetailsAndCreateBookingMutation({
          pmsPropertyId:
            arg.data.paymentDTO.bookingCreation?.reservations?.find(
              (reservation: any) => reservation.propertyId
            )?.propertyId ?? "",
          payment: {
            frontendPayload: arg.data.frontendPayload,
            amount: transformToGrossPrice(arg.data.paymentDTO.adyenPrice),
            reference: arg.data.referenceId
          },
          booking: {
            booker: arg.data.paymentDTO.bookingCreation!.booker,
            reservations: arg.data.paymentDTO.bookingCreation!.reservations!.map((item: any) => ({
              adultsAmount: item.adults,
              arrival: item.arrival,
              childrenAmount: item.childrenAges.length,
              departure: item.departure,
              pmsPropertyId: item.propertyId,
              pmsRatePlanId: item.ratePlanId,
              pmsUnitGroupId: item.unitGroupId
            }))
          }
        });
      }
    },
    [initiatePaymentDetailsV2, payDetailsAndCreateBookingMutation, referenceId]
  );

  const initiatePaymentDetailsAction = useCallback(
    (arg: UsePaymentDetailsProps) =>
      isRESTVersion ? dispatch(initiatePaymentDetails(arg)) : initiatePaymentDetailsActionV2(arg),
    [isRESTVersion, dispatch, initiatePaymentDetailsActionV2]
  );

  const paymentDetailsStatus = isRESTVersion
    ? paymentDetailsStatusV1
    : mapQueryStatusToEntityStateStatus(
        paymentDetailsStatusV2 === "uninitialized"
          ? initiatePaymentDetailsStatusV2
          : paymentDetailsStatusV2
      );

  const paymentDetailsResponse = useMemo(() => {
    if (isRESTVersion) {
      return paymentDetailsResponseV1;
    }
    if (paymentDetailsStatus === EntityStateStatus.SUCCEEDED) {
      return {
        bookingMagicId:
          paymentDetailsStatusV2 === "uninitialized"
            ? initiatePaymentDetailsResponseV2?.PaymentDetails
            : paymentDetailsResponseV2?.PayDetailsAndCreateBooking.magicId
      };
    }
    return null;
  }, [
    isRESTVersion,
    paymentDetailsStatus,
    paymentDetailsResponseV1,
    paymentDetailsStatusV2,
    initiatePaymentDetailsResponseV2,
    paymentDetailsResponseV2
  ]);

  return {
    initiatePaymentDetails: initiatePaymentDetailsAction,
    paymentDetailsResponse,
    paymentDetailsStatus
  };
};
