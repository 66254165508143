import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { generateMagicLink, generatePostLoginRedirectUrl } from "../../util/routing";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import { FindReservationPage } from "../find-reservation/find-reservation.page";
import { selectLastMagicId } from "../restore-magic-context/restore-magic-context.slice";
import { useUserProfileLastMagicId } from "../user-profile/use-user-profile-last-magic-id";
import { useFeatureFlags } from "../../util/hooks/use-configuration";

export const MainPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { search } = useLocation();
  const { getIsAuthenticated } = useAuth();
  const { ibeOnly } = useFeatureFlags();

  const { userProfileLastMagicId } = useUserProfileLastMagicId();
  const lastVisitedMagicId = useSelector(selectLastMagicId);

  const navigate = useNavigate();

  const resolveRedirection = useCallback(
    (magicId: string) => {
      const redirectTo = new URLSearchParams(search).get("redirectTo") || "";
      if (getIsAuthenticated() && magicId && redirectTo) {
        navigate(generatePostLoginRedirectUrl(redirectTo, magicId));
        return;
      }

      // kiosk will be handled in here
      if (getIsAuthenticated() && magicId) {
        navigate(generateMagicLink(magicId));
      }
    },
    [getIsAuthenticated, navigate, search]
  );

  useEffect(() => {
    if (getIsAuthenticated() && userProfileLastMagicId) {
      resolveRedirection(userProfileLastMagicId);
    }
  }, [getIsAuthenticated, resolveRedirection, userProfileLastMagicId]);

  useEffect(() => {
    if (!getIsAuthenticated() && lastVisitedMagicId) {
      navigate(generateMagicLink(lastVisitedMagicId));
    }
  }, [getIsAuthenticated, lastVisitedMagicId, navigate]);

  useEffect(() => {
    if (ibeOnly) {
      navigate("/search");
    }
  }, [navigate, ibeOnly]);

  return <>{!(getIsAuthenticated() && userProfileLastMagicId) && <FindReservationPage />}</>;
};
