import React from "react";
import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export function ErrorWarnSign(props: SvgIconProps) {
  const { palette } = useTheme();
  return (
    <SvgIcon width="140" height="140" viewBox="0 0 140 140" fill="none" {...props}>
      <path
        d="M70 96.25C69.5674 96.25 69.1444 96.3783 68.7847 96.6187C68.425 96.859 68.1446 97.2007 67.979 97.6004C67.8134 98.0001 67.7701 98.4399 67.8545 98.8643C67.9389 99.2886 68.1473 99.6784 68.4532 99.9843C68.7591 100.29 69.1489 100.499 69.5732 100.583C69.9976 100.667 70.4374 100.624 70.8371 100.458C71.2368 100.293 71.5785 100.013 71.8188 99.6528C72.0592 99.2931 72.1875 98.8701 72.1875 98.4375C72.1875 97.8573 71.957 97.3009 71.5468 96.8907C71.1366 96.4805 70.5802 96.25 70 96.25Z"
        stroke={palette.secondary.main}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70 78.75V30.625"
        stroke={palette.secondary.main}
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M70 135.625C106.244 135.625 135.625 106.244 135.625 70C135.625 33.7563 106.244 4.375 70 4.375C33.7563 4.375 4.375 33.7563 4.375 70C4.375 106.244 33.7563 135.625 70 135.625Z"
        stroke={palette.secondary.main}
        strokeWidth="6"
        strokeMiterlimit="10"
        fill="none"
      />
    </SvgIcon>
  );
}
