import React, { useEffect, useMemo } from "react";
import { MagicFileType } from "../../../domain-common/magic-file-type";
import { Reservation } from "../../../domain-common/reservation";
import { isReservation } from "../../../util/flow";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { ProfilePagesEnum } from "../profile-page-to-path";
import { IdCheckStatus } from "../../../domain-common/id-check-status";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

export const useProfilePage = (reservation: Reservation) => {
  const [validations, setValidations] = React.useState({
    personal: true,
    communication: true,
    legal: true,
    address: true
  });

  const { tfeFeatureEnabled } = useFeatureFlags();

  const {
    homeAddressValidation,
    legalDataStepValidation,
    profilePersonalDataValidation,
    preferredChannelStepValidation,
    travelBuddyDataStepValidation
  } = useFormValidations();

  useEffect(() => {
    //Travel buddy needs to have acceptedTerms
    const travelBuddyPersonalObject = {
      ...reservation,
      acceptedTerms: reservation.extras?.tcGeneral && reservation.extras.tcOnline
    };
    async function performValidation() {
      const [personal, communication, legal, address]: boolean[] = await Promise.all([
        isReservation(reservation)
          ? profilePersonalDataValidation.isValid(reservation)
          : travelBuddyDataStepValidation("travelBuddy").isValid(travelBuddyPersonalObject),
        preferredChannelStepValidation.isValid(reservation),
        legalDataStepValidation.isValid({
          ...reservation,
          signatureImage: reservation.files.find(
            (f) => f.metaData.magicFileType === MagicFileType.SIGNATURE_DOCUMENT
          )?.fileName,
          identificationImage: reservation.files.find(
            (f) => f.metaData.magicFileType === MagicFileType.IDENTIFICATION_DOCUMENT
          )?.fileName,
          acceptedTerms: reservation.extras?.tcGeneral && reservation.extras.tcOnline
        }),
        homeAddressValidation.isValid(reservation)
      ]);

      setValidations({
        personal,
        communication,
        legal,
        address
      });
    }

    performValidation();
  }, [
    reservation,
    homeAddressValidation,
    legalDataStepValidation,
    profilePersonalDataValidation,
    preferredChannelStepValidation,
    travelBuddyDataStepValidation
  ]);

  const infoWarningLabelForIdCheck = useMemo(() => {
    if (reservation.idCheckStatus === IdCheckStatus.DECLINED) {
      return "validations__information_id_is_declined";
    } else if (reservation.idCheckStatus === IdCheckStatus.REUPLOADED) {
      return "validations__information_id_is_reuploaded";
    }
    return undefined;
  }, [reservation.idCheckStatus]);

  const errorsPerPage: Partial<Record<ProfilePagesEnum, string>> = {
    [ProfilePagesEnum.PERSONAL]: !validations.personal
      ? "validations__information_missing_message"
      : undefined,
    [ProfilePagesEnum.COMMUNICATION]: !validations.communication
      ? "validations__information_missing_message"
      : undefined,
    [ProfilePagesEnum.ADDRESS]:
      !validations.address && !tfeFeatureEnabled
        ? "validations__information_missing_message"
        : undefined,
    [ProfilePagesEnum.LEGAL]: infoWarningLabelForIdCheck
      ? infoWarningLabelForIdCheck
      : !validations.legal && !tfeFeatureEnabled
        ? "validations__information_missing_message"
        : undefined,
    [ProfilePagesEnum.LANGUAGE]: undefined,
    [ProfilePagesEnum.ACCOUNT]: undefined
  };
  return {
    errorsPerPage
  };
};
