import { Grid, Tab } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CONFIRMATION_TYPE_KEY,
  ConfirmationType,
  DesktopCard,
  DisplayCmsSvg,
  SubmitButton
} from "../../../components";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import {
  confirmationTypeURLParams,
  generatePortalMyStayUrl,
  generatePortalPaymentNavigate,
  generatePortalPaymentSuccessUrl
} from "../../../util/routing";
import { selectAllAdditionalServicesCartItems } from "../../additional-services/service-selection/additional-services-cart.slice";
import { additionalServiceCartItemsToAdditionalServicesPaymentDTO } from "../../additional-services/service-selection/additional-services.util";
import { useBookFreeAdditionalServices } from "../../additional-services/service-selection/use-book-free-additional-services";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { usePreparePayment } from "../../payment/use-prepare-payment";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { Visibility } from "../../../api/dto/additional-services-availability-response";
import { ServiceSelectionComponent } from "../../additional-services/service-selection/service-selection-component";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BookedServiceList } from "../../additional-services/booked-services/booked-service-list";
import { makeStyles } from "tss-react/mui";
import { useSearchParams } from "../../additional-services/use-search-params";
import { responsiveVariables } from "../../../components/layouts/hooks/use-is-mobile";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { FullPrice, toGross } from "../../../domain-common/full-price";
import { useAdditionalServicesTotalPrice } from "../../services/use-additional-services-total-price";

enum ServiceTabEnum {
  SELECTION = "SELECTION",
  BOOKED = "BOOKED"
}

const useStyles = makeStyles()(({ spacing }) => ({
  tabPanel: {
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2.5)
  }
}));

const TAB_SEARCH_PARAM_KEY = "TAB";

interface MyStayServicesPageProps {}

export const MyStayServicesPage: React.FC<
  React.PropsWithChildren<MyStayServicesPageProps>
> = () => {
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const { reservation } = useReservationContext();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { classes } = useStyles();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const additionalServicesInCart = useSelector(selectAllAdditionalServicesCartItems);

  const [totalPrice, setTotalPrice] = useState<null | FullPrice>(null);
  const getTotalPrice = useAdditionalServicesTotalPrice({ magicId });

  useEffect(() => {
    getTotalPrice({
      serviceCartItems: additionalServicesInCart
    }).then((fullPrice) => setTotalPrice(fullPrice));
  }, [getTotalPrice, additionalServicesInCart]);

  const { preparePayment } = usePreparePayment(reservation.magicId);
  const { bookFreeAdditionalServices } = useBookFreeAdditionalServices(
    reservation.magicId,
    reservation.magicToken
  );

  const freeAdditionalServicePresent = useMemo(() => totalPrice?.grossAmount === 0, [totalPrice]);

  useSetupSubpageNavigation(generatePortalMyStayUrl);

  const preparePaymentForServices = useCallback(async () => {
    await preparePayment({
      additionalServices:
        additionalServiceCartItemsToAdditionalServicesPaymentDTO(additionalServicesInCart)
    });
  }, [preparePayment, additionalServicesInCart]);

  const buttonLabel = useMemo(() => {
    if (freeAdditionalServicePresent) {
      return tCommon("buttons__book_free_additional_services");
    }
    return `${tCommon("buttons__pay")} ${
      totalPrice ? formatPriceToString(toGross(totalPrice)) : ""
    }`;
  }, [freeAdditionalServicePresent, tCommon, totalPrice]);

  const handleSubmit = useCallback(async () => {
    try {
      if (additionalServicesInCart.length === 0) {
        console.error("additionalServicesInCart.length === 0 during onSubmit");
        return;
      }
      if (freeAdditionalServicePresent) {
        await bookFreeAdditionalServices(additionalServicesInCart);
        navigate({
          pathname: generatePortalPaymentSuccessUrl(magicId),
          search: confirmationTypeURLParams(
            new URLSearchParams({
              [CONFIRMATION_TYPE_KEY]: ConfirmationType.ADDITIONAL_SERVICES
            })
          )
        });
        return;
      }
      preparePaymentForServices();

      navigate(
        generatePortalPaymentNavigate(magicId, {
          [CONFIRMATION_TYPE_KEY]: ConfirmationType.ADDITIONAL_SERVICES
        })
      );
    } catch (e) {
      console.log(e);
    }
  }, [
    additionalServicesInCart,
    navigate,
    magicId,
    preparePaymentForServices,
    bookFreeAdditionalServices,
    freeAdditionalServicePresent
  ]);
  const { bookedServicesEnabled } = useFeatureFlags();

  const { params: tabValue, setParams: setTabValue } = useSearchParams({
    initObject: {
      [TAB_SEARCH_PARAM_KEY]:
        reservation.servicesOverview?.length && bookedServicesEnabled
          ? ServiceTabEnum.BOOKED
          : ServiceTabEnum.SELECTION
    },
    replacePath: false
  });

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newTabValue: ServiceTabEnum) => {
      setTabValue({ [TAB_SEARCH_PARAM_KEY]: newTabValue });
    },
    [setTabValue]
  );

  return (
    <>
      <HelmetTitle suffix="Services" />
      <RoundedFullHeight>
        <DesktopCard>
          <FlowTemplate>
            <TabContext value={tabValue[TAB_SEARCH_PARAM_KEY]}>
              <Grid container justifyContent="center">
                <TabList variant="fullWidth" onChange={handleChange}>
                  <Tab
                    sx={{
                      whiteSpace: {
                        xs: "normal",
                        [responsiveVariables.firstDesktopSize]: "nowrap"
                      }
                    }}
                    label={tCommon("labels__service_selection")}
                    value={ServiceTabEnum.SELECTION}
                  />
                  {bookedServicesEnabled && (
                    <Tab
                      sx={{
                        whiteSpace: {
                          xs: "normal",
                          [responsiveVariables.firstDesktopSize]: "nowrap"
                        }
                      }}
                      label={tCommon("labels__booked_service")}
                      value={ServiceTabEnum.BOOKED}
                    />
                  )}
                </TabList>
              </Grid>
              <TabPanel value={ServiceTabEnum.SELECTION} className={classes.tabPanel}>
                <PageHeadingInfo
                  title={tCommon("title__services_page")}
                  icon={<DisplayCmsSvg url={cmsData?.data?.icon__services_icon?.url} />}
                />

                <ServiceSelectionComponent
                  reservation={reservation}
                  visibility={Visibility.MY_STAY}
                />

                {additionalServicesInCart.length > 0 && (
                  <SubmitButton label={buttonLabel} variant="primary" onClick={handleSubmit} />
                )}
              </TabPanel>
              <TabPanel value={ServiceTabEnum.BOOKED} className={classes.tabPanel}>
                <PageHeadingInfo
                  title={tCommon("title__booked_services_page")}
                  icon={<DisplayCmsSvg url={cmsData?.data?.icon__services_icon?.url} />}
                />
                <BookedServiceList reservation={reservation} />
              </TabPanel>
            </TabContext>
          </FlowTemplate>
        </DesktopCard>
      </RoundedFullHeight>
    </>
  );
};
