import React, { FC, ReactElement } from "react";
import { Box, Fab } from "@mui/material";
import { ArrowLeftIcon } from "../../../components/icons";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { Heading2, Subtitle } from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";
import { useNavigate } from "react-router-dom";
import { responsiveVariables } from "../../../components/layouts/hooks/use-is-mobile";
import { ImageLimitedHeightWrapper } from "../../../domain-common/image-style";

const useStyles = makeStyles()(({ palette, breakpoints, spacing }) => ({
  imageWrapper: {
    width: "100%",
    height: 180,
    zIndex: -1,
    ...ImageLimitedHeightWrapper,

    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      marginBottom: spacing(5),
      overflow: "hidden",
      height: "100%"
    }
  },
  backBox: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 2,
    padding: spacing(3),
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      display: "none"
    }
  },
  backIcon: {
    background: palette.background.paper,
    color: palette.text.primary,
    boxShadow: "0px 4px 8px rgba(197, 185, 172, 0.06)",
    "&:hover": {
      background: palette.background.paper
    }
  },
  dateAndTimeContainer: {
    borderTop: `1px solid ${palette.secondary.main}`,
    borderBottom: `1px solid ${palette.secondary.main}`,

    "& > *:first-of-type": {
      borderRight: `1px solid ${palette.secondary.main}`
    }
  },
  dateAndTimeParagraph: {
    padding: spacing(2),
    display: "flex"
  },
  dateAndTimeIcon: {
    marginRight: spacing(1.5),
    color: palette.text.secondary
  },
  wrapper: {
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      marginTop: spacing(5)
    }
  }
}));

export const ArticleLayout: FC<
  React.PropsWithChildren<{
    coverImage: ReactElement;
    title: string;
    subtitle?: string;
    content: ReactElement;
  }>
> = ({ coverImage, title, subtitle, content }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.imageWrapper}>
        <Box className={classes.backBox}>
          <Fab
            size="medium"
            aria-label="back"
            className={classes.backIcon}
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon />
          </Fab>
        </Box>
        {coverImage}
      </Box>

      <RoundedFullHeight
        paddingLeft={2.5}
        paddingRight={2.5}
        paddingBottom={0.5}
        edgesColor="transparent"
        subtrahend={15.5}
      >
        <>
          <Box mb={3}>
            <Heading2 textAlign="left" gutterBottom>
              {title}
            </Heading2>
            {subtitle && (
              <Subtitle textAlign="center" color="text.secondary">
                {subtitle}
              </Subtitle>
            )}
          </Box>

          {content}
        </>
      </RoundedFullHeight>
    </Box>
  );
};
