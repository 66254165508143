import { FileError } from "react-dropzone";
import React, { FC, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Box, FormHelperText, Grid, IconButton, InputAdornment } from "@mui/material";
import { DocumentPreview } from "../../molecules/document-preview";
import { PassportDeletedIcon } from "../../icons/passport-deleted.icon";
import { makeStyles } from "tss-react/mui";
import { assignPreviewToFile } from "../../../util/file-util";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { IdUploadComponent } from "./id-upload-component";
import { IdCamera } from "./id-camera/id-camera";
import { BackArrowIcon } from "../../icons";
import { useMobileOrientation } from "react-device-detect";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import { isEnforceScanUIMode } from "../../../util/kiosk-mode";
import { Document, Page, pdfjs } from "react-pdf";
import { useKioskConfig } from "../../../util/hooks/use-configuration";
import { Button } from "@likemagic-tech/sv-magic-library";
import { WrappedInput } from "../../atoms/input/wrapped-input";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type IdDocumentComponentErrorLabels = Record<FileError["code"], string>;
export type IdDocumentComponentLabels = {
  uploadButton: string;
  deleted: string;
  required: string;
  uploadFromPhone: string;
  uploadButtonMobile: string;
};

interface IdDocumentComponentProps {
  value: File | null;
  onChange: (image: File | null) => void;
  disabled?: boolean;
  error?: string | boolean;
  identificationUploadErrorLabels: IdDocumentComponentErrorLabels;
  identificationUploadLabels: IdDocumentComponentLabels;
  showPreview: boolean;
}

const useStyles = makeStyles()((theme) => ({
  dropzoneContainer: {
    padding: "14px 22px",
    border: `2px solid ${theme.palette.common.black}`,
    borderRadius: theme.shape.borderRadius * 1.5,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",

    "&$imageSelected": {
      border: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
      justifyContent: "flex-start",
      paddingTop: theme.spacing(3)
    }
  },
  imageSelected: {},
  preview: {
    position: "relative"
  },
  previewImage: {
    maxHeight: "90px",
    borderRadius: theme.shape.borderRadius
  },
  previewClose: {
    position: "absolute",
    top: "-15px",
    right: "-15px",
    width: "30px",
    height: "30px",
    backgroundColor: "black",
    color: "white",
    borderRadius: "50%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    fontSize: "25px",
    fontWeight: "bold",
    padding: "5px"
  },
  buttonError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  },
  cameraWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1100
  },
  closeCameraButton: {
    background: "black", //Must always BE black
    padding: theme.spacing(1.5)
  }
}));

const isPdf = (file: File | null) => {
  return file ? file?.type?.indexOf("pdf") > -1 : undefined;
};

const PAGE_NUMBER = 1;

const IdDocumentComponent: FC<React.PropsWithChildren<IdDocumentComponentProps>> = ({
  value,
  onChange,
  disabled,
  error,
  identificationUploadErrorLabels,
  identificationUploadLabels,
  showPreview
}) => {
  const [internalError, setInternalError] = React.useState<string | null>(null);
  const removeFileWithPreview = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      onChange(null);
      e.stopPropagation();
    },
    [onChange]
  );

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const isMobile = useIsMobile();
  const isEnforceScanUI = isEnforceScanUIMode();
  const { enforceScanUi } = useKioskConfig();
  const { classes } = useStyles();
  const { isPortrait } = useMobileOrientation();

  const camera = useMemo(
    () =>
      isCameraOpen ? (
        <IdCamera onChange={onChange} closeCamera={() => setIsCameraOpen(false)} />
      ) : null,
    [isCameraOpen, onChange]
  );

  const pdfPreview = useMemo(
    () => (
      <Document file={assignPreviewToFile(value)?.preview}>
        <Page pageNumber={PAGE_NUMBER} size="A10" />
      </Document>
    ),
    [value]
  );

  return (
    <Box mt={2} display="flex" flexDirection="column">
      {!showPreview && !assignPreviewToFile(value) && (
        <>
          {(isMobile || isEnforceScanUI || enforceScanUi) && (
            <>
              <WrappedInput
                id={`primaryGuest.identificationImage`}
                label={identificationUploadLabels.uploadFromPhone}
                onClick={() => !disabled && setIsCameraOpen(true)}
                disabled={disabled}
                error={!!error}
                readOnly={true}
                endAdornment={
                  <InputAdornment position="end">
                    <PhotoCameraIcon />
                  </InputAdornment>
                }
              />
              <Box className={classes.cameraWrapper} display={isCameraOpen ? "block" : "none"}>
                <Grid container direction={isPortrait ? "column" : "row"}>
                  <Grid item xs={isPortrait ? 12 : 1} className={classes.closeCameraButton}>
                    <IconButton onClick={() => setIsCameraOpen(false)}>
                      <BackArrowIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={isPortrait ? 12 : 11}>
                    {camera}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {!isEnforceScanUI && !enforceScanUi && (
            <Box textAlign="right">
              <IdUploadComponent
                disabled={disabled}
                value={value}
                onChange={onChange}
                identificationUploadErrorLabels={identificationUploadErrorLabels}
                setInternalError={setInternalError}
                showPreview={showPreview}
                inputComponent={
                  <Button
                    disabled={disabled}
                    variant={isMobile ? "ghost" : "secondary"}
                    fullWidth={!isMobile}
                    className={`${error ? classes.buttonError : ""}`}
                  >
                    {isMobile
                      ? identificationUploadLabels.uploadButtonMobile
                      : identificationUploadLabels.uploadButton}
                  </Button>
                }
              />
            </Box>
          )}
        </>
      )}

      {assignPreviewToFile(value) && !showPreview && (
        <section
          className={`${classes.dropzoneContainer} ${classes.imageSelected} ${
            error ? classes.buttonError : ""
          } `}
        >
          <div className={classes.preview}>
            {isPdf(value) ? (
              pdfPreview
            ) : (
              <img
                src={assignPreviewToFile(value)?.preview}
                className={classes.previewImage}
                alt="preview"
              />
            )}
            {!disabled && (
              <span className={classes.previewClose} onClick={removeFileWithPreview}>
                &times;
              </span>
            )}
          </div>
        </section>
      )}

      {showPreview && (
        <DocumentPreview icon={<PassportDeletedIcon />} text={identificationUploadLabels.deleted} />
      )}

      {error && (
        <FormHelperText error required>
          {identificationUploadLabels.required}
        </FormHelperText>
      )}
      {internalError && (
        <FormHelperText error required>
          {internalError}
        </FormHelperText>
      )}
    </Box>
  );
};

export default IdDocumentComponent;
