import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "../../../domain-common/service";
import { RootState } from "../../../state/store";

export type ShopProduct = Service;
export type ShopFilter = {
  id: string;
  name: string;
};

export type ShopDisplayState = {
  selectedProduct: ShopProduct | null;
  selectedFilters: Record<string, boolean>;
};

const initialState: ShopDisplayState = {
  selectedFilters: {},
  selectedProduct: null
};

export const shopDisplaySlice = createSlice({
  name: "shopDisplay",
  initialState,
  reducers: {
    openAddToCartDialog: (state, action: PayloadAction<{ product: ShopProduct }>) => {
      state.selectedProduct = action.payload.product;
    },
    closeAddToCartDialog: (state) => {
      state.selectedProduct = null;
    },
    toggleFilter: (state, action: PayloadAction<{ filterName: string } | undefined>) => {
      if (!action.payload) {
        state.selectedFilters = {};
        return;
      }

      if (state.selectedFilters[action.payload.filterName]) {
        delete state.selectedFilters[action.payload.filterName];
      } else {
        state.selectedFilters[action.payload.filterName] = true;
      }
    },
    toggleOnlyOneFilter: (state, action: PayloadAction<{ filterName: string } | undefined>) => {
      state.selectedFilters = {};
      if (!action.payload) {
        return;
      }
      state.selectedFilters[action.payload.filterName] = true;
    },
    clearFilter: (state) => {
      state.selectedFilters = {};
    }
  }
});

export const selectShopDisplaySlice = (state: RootState) => state[shopDisplaySlice.name];

export const {
  openAddToCartDialog,
  closeAddToCartDialog,
  toggleFilter,
  toggleOnlyOneFilter,
  clearFilter
} = shopDisplaySlice.actions;
