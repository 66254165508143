import { useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CSSProperties } from "react";
import { use100vh } from "react-div-100vh";
import { responsiveVariables } from "../../../components/layouts/hooks/use-is-mobile";

interface UseFlowTemplateStylesProps {
  progress?: boolean;
  fixedButtonNotExist?: boolean;
  bottomNavbarHidden?: boolean;
  topNavbarHidden?: boolean;
}

const bottomNavbarHeight = 56;
const useStyles = makeStyles<{
  height: number | null;
  topNavigationHeight: number;
  spaceForProgress: number;
  bottomNavbarHidden?: boolean;
  topNavbarHidden?: boolean;
}>()(
  (
    { palette, breakpoints },
    { height, topNavigationHeight, spaceForProgress, bottomNavbarHidden, topNavbarHidden }
  ) => ({
    box: {
      [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
        backgroundColor: palette.background.paper,
        minHeight: !height
          ? "100vh"
          : height -
            (topNavbarHidden ? 0 : topNavigationHeight) -
            spaceForProgress -
            (bottomNavbarHidden ? 0 : bottomNavbarHeight)
      }
    }
  })
);

export const useFlowTemplateStyles = ({
  progress,
  bottomNavbarHidden,
  topNavbarHidden
}: UseFlowTemplateStylesProps) => {
  const height = use100vh();
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:600px)");
  const topNavigationHeight = matches
    ? ((theme?.mixins?.toolbar?.[`@media (min-width:600px)`] as CSSProperties).minHeight as number)
    : (theme?.mixins?.toolbar?.minHeight as number);

  const spaceForProgress = !progress ? 0 : 4;

  return useStyles({
    height,
    topNavigationHeight,
    spaceForProgress,
    bottomNavbarHidden,
    topNavbarHidden
  });
};
