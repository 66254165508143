import { Button, Heading4, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { ChangeEvent, useCallback } from "react";
import { emptyGuest, Guest } from "../../domain-common/guest";
import { ConfirmationDialogLabels } from "../atoms/dialog/confirmation-dialog";
import { AddIcon } from "../icons";
import { GuestForm, GuestFormLabels } from "./guest-form";
import { getYesNoModalArg } from "../../features/global-modal/global-modal-util";
import {
  useAdditionalTravelBuddyFieldsConfig,
  useFeatureFlags
} from "../../util/hooks/use-configuration";

const useStyles = makeStyles()({
  textButton: {
    paddingLeft: 0,
    textDecoration: "none"
  },
  addIcon: {
    fontSize: 14,
    fontWeight: "bold"
  },
  deleteIcon: {
    fontSize: 14,
    fontWeight: "bold",
    transform: "rotate(45deg)"
  }
});

const empty = {};

export type ControlLabels = {
  heading: string;
  add: string;
  delete: string;
};

export const GuestFormFormik: React.FC<React.PropsWithChildren<any>> = (props) => {
  const { classes } = useStyles();
  const { form, push, remove } = props;
  const {
    dialogLabels,
    guestFormLabels,
    controlLabels,
    disabled,
    onChange
  }: {
    dialogLabels: ConfirmationDialogLabels;
    guestFormLabels: GuestFormLabels;
    controlLabels: ControlLabels;
    disabled?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  } = props;
  const addGuest = useCallback(() => push(emptyGuest()), [push]);
  const { open: openModal } = useGlobalModal();
  const { diverseGenderEnabled, genderDisplayDisabled } = useFeatureFlags();
  const deleteTravelBuddy = useCallback(
    (buddyIndexToBeDeleted: number) => async () => {
      const modalResult: boolean | null = await openModal(
        getYesNoModalArg(
          dialogLabels.title,
          dialogLabels.text,
          dialogLabels.confirm,
          dialogLabels.deny
        )
      );
      if (modalResult) {
        await remove(buddyIndexToBeDeleted);
      }
    },
    [openModal, dialogLabels, remove]
  );
  const additionalTravelBuddyFields = useAdditionalTravelBuddyFieldsConfig();

  return (
    <Box>
      {form.values.additionalGuests.map((guest: Guest, index: number) => {
        if (form.values.ignoreUntilIdx && index < form.values.ignoreUntilIdx) {
          return <React.Fragment key={`empty-row-${index}`} />;
        }
        return (
          <Box key={`guest-${guest.objectId}`}>
            <Box mt={5} mb={2}>
              <Grid container direction="row">
                <Grid item xs={6} container alignItems="center">
                  <Heading4>{controlLabels.heading.concat(` ${index + 1}`)}</Heading4>
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
                  <Button
                    variant="ghost"
                    className={classes.textButton}
                    disabled={disabled}
                    onClick={deleteTravelBuddy(index)}
                  >
                    <AddIcon className={classes.deleteIcon} />
                    <Box ml={1.5}>{controlLabels.delete}</Box>
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <GuestForm
              objectPathPrefix={`additionalGuests[${index}]`}
              values={form.values.additionalGuests[index]}
              labels={guestFormLabels}
              disabled={disabled || form.isSubmitting}
              touched={
                form.touched?.additionalGuests && form.touched?.additionalGuests.length
                  ? form.touched?.additionalGuests[index]
                  : empty
              }
              errors={
                form.errors?.additionalGuests && form.errors?.additionalGuests.length
                  ? form.errors?.additionalGuests[index]
                  : empty
              }
              diverseGenderEnabled={diverseGenderEnabled}
              genderDisplayDisabled={genderDisplayDisabled}
              additionalFields={additionalTravelBuddyFields}
              onChange={onChange}
            />
          </Box>
        );
      })}

      {form.values.maxCompanions > form.values.additionalGuests.length && (
        <Box mt={2}>
          <Button
            variant="ghost"
            className={classes.textButton}
            disabled={disabled}
            onClick={addGuest}
          >
            <AddIcon className={classes.addIcon} />
            <Box ml={1.5}>{controlLabels.add}</Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};
