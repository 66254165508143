import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/store";
import { useApiVersion, useAuth } from "@likemagic-tech/sv-magic-library";
import {
  fetchLinkedAccounts,
  selectAccountLinkingHrefs,
  selectAccountsSlice,
  unlinkAccount as unlinkAccountAction
} from "../../../slices/accounts.slice";
import { useUnlinkIdpMutation } from "../../../graphql/mutations/unlink-idp.generated";

export const useProfileAccountPage = () => {
  const dispatch = useAppDispatch();
  const { authenticated, keycloak } = useAuth();
  const accountLinkingHrefs = useSelector(selectAccountLinkingHrefs);
  const { loadingLinkedAccounts, linkedAccounts } = useSelector(selectAccountsSlice);
  const [unlinkIdpMutation] = useUnlinkIdpMutation();
  const { isRESTVersion } = useApiVersion();

  const unlinkAccount = React.useCallback(
    (providerName: string, userAccountUuid: string) => {
      if (isRESTVersion) {
        return dispatch(
          unlinkAccountAction({
            providerName: providerName
          })
        );
      } else {
        return unlinkIdpMutation({ idp: providerName, userAccountUuid: userAccountUuid });
      }
    },
    [dispatch, isRESTVersion, unlinkIdpMutation]
  );

  const loadLinkedAccounts = useCallback(
    () =>
      dispatch(
        fetchLinkedAccounts({
          realm: keycloak.realm || "",
          authServerUrl: keycloak.authServerUrl || ""
        })
      ),
    [dispatch, keycloak.realm, keycloak.authServerUrl]
  );

  useEffect(() => {
    if (authenticated) {
      loadLinkedAccounts();
    }
  }, [authenticated, loadLinkedAccounts]);

  return {
    linkedAccounts,
    unlinkAccount,
    accountLinkingHrefs,
    loadingLinkedAccounts,
    loadLinkedAccounts
  };
};
