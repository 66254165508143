/**
 * When changing something here, update docs in Confluence
 * https://3apjira.atlassian.net/wiki/spaces/SK/pages/2547908795/Guest+Journey+FE+-+Prismic+Principles+Conventions
 */
export interface TranslationContext {
  booking: {
    booker: {
      firstName: string;
      lastName: string;
      birthdate: string;
      email: string;
      phone: string;
      title: string;
      gender: "Male" | "Female" | "";
      preferredLocale: string;
      identificationType: string;
      identificationNumber: string;
      nationalityCountryCode: string;
      address: {
        addressLine1: string;
        addressLine2: string;
        postalCode: string;
        city: string;
        countryCode: string;
      };
      company: any;
      guestIdentityId: string;
    };
  };
  reservation: {
    guest: {
      firstName: string;
      lastName: string;
      birthdate: string;
      email: string;
      phone: string;
      title: string;
      gender: "Male" | "Female" | "";
      preferredLocale: string;
      identificationType: string;
      identificationNumber: string;
      nationalityCountryCode: string;
      address: {
        addressLine1: string;
        addressLine2: string;
        postalCode: string;
        city: string;
        countryCode: string;
      };
      company: any;
      guestIdentityId: string;
    };
    propertyId: string;
    status: string;
    arrival: string;
    departure: string;
    unit: {
      name: string;
      unitGroupId: string;
    };
    unitGroup: {
      id: string;
      maxPersons: number;
    };
    extras: {
      preferredCommunicationChannel: string;
      tcGeneral: boolean;
      tcOnline: boolean;
      tcMarketingConsent: boolean;
    };
    maxCompanions: number;
  };
  computed: {
    stayRange: string;
    checkinCountdownDays: number;
    departureTime: string;
    arrivalTime: string;
    arrivalDate: string;
  };
}

export const emptyTranslationContext: TranslationContext = {
  booking: {
    booker: {
      firstName: "",
      lastName: "",
      birthdate: "",
      email: "",
      phone: "",
      title: "",
      gender: "",
      preferredLocale: "",
      identificationType: "",
      identificationNumber: "",
      nationalityCountryCode: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        postalCode: "",
        city: "",
        countryCode: ""
      },
      company: "",
      guestIdentityId: ""
    }
  },
  reservation: {
    guest: {
      firstName: "",
      lastName: "",
      birthdate: "",
      email: "",
      phone: "",
      title: "",
      gender: "",
      preferredLocale: "",
      identificationType: "",
      identificationNumber: "",
      nationalityCountryCode: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        postalCode: "",
        city: "",
        countryCode: ""
      },
      company: "",
      guestIdentityId: ""
    },
    propertyId: "",
    status: "",
    arrival: "",
    departure: "",
    unit: {
      name: "",
      unitGroupId: ""
    },
    unitGroup: {
      id: "",
      maxPersons: 0
    },
    extras: {
      preferredCommunicationChannel: "",
      tcGeneral: false,
      tcOnline: false,
      tcMarketingConsent: false
    },
    maxCompanions: 0
  },
  computed: {
    stayRange: "",
    checkinCountdownDays: 0,
    departureTime: "",
    arrivalTime: "",
    arrivalDate: ""
  }
};
