import { Box, Collapse, Divider, FormControlLabel } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useCallback, useMemo, useState } from "react";
import { Dialog } from "../../../components";
import {
  Checkbox,
  formatRange,
  formatToIsoDate,
  Heading3,
  isSameDaysInArray,
  MultiCalendar,
  Paragraph,
  parseIsoDateString
} from "@likemagic-tech/sv-magic-library";
import { PricePreview } from "../../../components/atoms/price-preview/price-preview";
import { usePropertyById } from "../../property/use-property-by-id";
import {
  DateAndCount,
  DefaultDatePickerSelection,
  DefaultTargetAudience
} from "../../../api/dto/additional-services-availability-response";
import { ServiceModalHandlerProps } from "../service-selection/additional-service-modal-handler";
import { Counter } from "../../../components/molecules/counter";
import { useCmsPropertySpecificData } from "../../../state/cms/use-cms-per-property-data";
import { useServiceModalCmsData } from "../../../state/property-cms/use-service-modal-cms-data";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { multiply, toGross } from "../../../domain-common/full-price";
import startOfDay from "date-fns/startOfDay";
import { LegendToolbar } from "./service-legend-toolbar";
import { isSameDay } from "date-fns";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

const useStyles = makeStyles()(() => ({
  dialogContentContainer: {
    alignSelf: "center"
  },
  counter: {
    "& div": {
      "& p": {
        fontSize: 28
      }
    },
    "& button": {
      padding: 0,
      borderRadius: 40,
      height: 40,
      width: 40,
      "& svg": {
        width: "100%",
        height: "100%"
      }
    }
  }
}));

export const ServiceDatesPickerDialog: React.FC<
  React.PropsWithChildren<ServiceModalHandlerProps>
> = ({ onClose, onChange, reservation, price, service, cartValue }) => {
  const initialDates: Date[] = useMemo(() => {
    if (cartValue?.dates) {
      return cartValue.dates.map((dateAndCount: DateAndCount) =>
        startOfDay(new Date(dateAndCount.date))
      );
    }
    if (service?.defaultDatePickerSelection === DefaultDatePickerSelection.ALL) {
      return (service.bookableDates || []).map(
        (dateAndCount: DateAndCount) => new Date(dateAndCount.date)
      );
    }
    return [];
  }, [cartValue, service]);

  const initialCount = useMemo(() => {
    if (!service?.canBookMultiple) {
      return 1;
    }

    if (cartValue?.dates?.[0]?.count) {
      return cartValue?.dates?.[0]?.count;
    }

    switch (service?.defaultTargetAudience) {
      case DefaultTargetAudience.ADULT:
        return reservation.adults;
      case DefaultTargetAudience.CHILD:
        return reservation.childrenAges.length || 1;
      case DefaultTargetAudience.ONE:
        return 1;
      case DefaultTargetAudience.TWO:
        return 2;
      case DefaultTargetAudience.THREE:
        return 3;
      case DefaultTargetAudience.FOUR:
        return 4;
      case DefaultTargetAudience.FIVE:
        return 5;
      case DefaultTargetAudience.ALL:
      default:
        return reservation.adults + reservation.childrenAges.length;
    }
  }, [cartValue, reservation.adults, reservation.childrenAges, service]);
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const [dates, setDates] = useState<Date[]>(initialDates);
  const [count, setCount] = useState<number>(initialCount);

  const cmsPropertySpecificData = useCmsPropertySpecificData(reservation.propertyId);
  const { translatedKeys: datePickerTranslatedKeys } =
    useServiceModalCmsData(cmsPropertySpecificData);

  const labels = useMemo(
    () => ({
      buttonLabel: datePickerTranslatedKeys[service.serviceId]?.button,
      descriptionLabel: datePickerTranslatedKeys[service.serviceId]?.description,
      datePickerTitle: datePickerTranslatedKeys[service.serviceId]?.title,
      datePickerSelectAll: datePickerTranslatedKeys[service.serviceId]?.select_all,
      datePickerConfirmButton: t("buttons__confirm"),
      datePickerLegendHighlighted: datePickerTranslatedKeys[service.serviceId]?.legend_highlighted,
      datePickerLegendSelected: datePickerTranslatedKeys[service.serviceId]?.legend_selected,
      counterLabel: datePickerTranslatedKeys[service.serviceId]?.counter_label
    }),
    [datePickerTranslatedKeys, service.serviceId, t]
  );

  const property = usePropertyById(reservation?.propertyId);
  const bookedDates: Date[] = (service.bookedDates || []).map(
    (dateAndCount: DateAndCount) => new Date(dateAndCount.date) // TODO LM-374 use proper object
  );

  const availableDays: Date[] = (service.bookableDates || []).map(
    (dateAndCount: DateAndCount) => new Date(dateAndCount.date) // TODO LM-374 use proper object
  );

  const isDayDisabled = (day: Date) =>
    !availableDays.some((availableDay) => isSameDay(new Date(availableDay), day));

  const isDayHighlighted = useCallback(
    (day: Date) => bookedDates.some((availableDay) => isSameDay(new Date(availableDay), day)),
    [bookedDates]
  );

  const isAllSelected = useMemo(
    () => isSameDaysInArray(dates, availableDays),
    [dates, availableDays]
  );
  const { classes } = useStyles();

  const handleChange = useCallback(() => {
    const values = dates.map((date) => ({
      date: formatToIsoDate(date),
      count: count
    }));
    onChange({ values });
  }, [dates, onChange, count]);

  const defaultCalendarMonth = useMemo(() => {
    const today = new Date();
    return reservation.arrival && new Date(reservation?.arrival) > today
      ? parseIsoDateString(reservation?.arrival)
      : today;
  }, [reservation.arrival]);

  return (
    <Dialog
      open={true}
      onConfirm={handleChange}
      onDismiss={onClose}
      title={labels.datePickerTitle}
      content={
        <Box className={classes.dialogContentContainer}>
          <Heading3 align="center" gutterBottom>
            {formatRange(
              property.timeZone,
              getI18nSelectedLanguage(),
              reservation?.arrival,
              reservation?.departure
            )}
          </Heading3>

          <Box mx={2}>
            <LegendToolbar
              legendLabels={{
                highlighted: labels.datePickerLegendHighlighted,
                selected: labels.datePickerLegendSelected
              }}
            />
          </Box>

          <Box m={2} mt={0}>
            <MultiCalendar
              values={dates}
              defaultCalendarMonth={reservation?.arrival ? defaultCalendarMonth : undefined}
              onChange={setDates}
              isDayHighlighted={isDayHighlighted}
              isDayDisabled={isDayDisabled}
            />
          </Box>

          <Box px={2.5}>
            <FormControlLabel
              control={
                <Checkbox
                  id="servicesDatePickerDialog"
                  color="primary"
                  onChange={(event) => {
                    if ((event.target as any).checked) {
                      setDates(availableDays);
                    } else {
                      setDates([]);
                    }
                  }}
                  checked={isAllSelected}
                />
              }
              label={labels.datePickerSelectAll}
            />
          </Box>

          <Collapse in={!!(dates.length && service.canBookMultiple)}>
            <Box py={2}>
              <Paragraph textAlign={"center"} pb={1}>
                {labels.counterLabel}
              </Paragraph>
              <Counter
                classes={{ root: classes.counter }}
                initialCount={initialCount}
                minValue={1}
                maxValue={service.maximum}
                onCountChange={setCount}
              />
            </Box>
            <Divider />
          </Collapse>
          <Box display="flex" alignItems="baseline" justifyContent="center" px={2.5} py={2}>
            <PricePreview price={toGross(multiply(price, dates.length * count))} />
          </Box>
        </Box>
      }
      buttonLabel={labels.datePickerConfirmButton}
    />
  );
};
