import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DisplayCmsSvg, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { PersonForm } from "../../../components/molecules/personal-form/person-form";
import { Person } from "../../../domain-common/person";
import { Reservation } from "../../../domain-common/reservation";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { isReservation, isTravelBuddy } from "../../../util/flow";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { useAuth, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { getYesNoModalArg } from "../../global-modal/global-modal-util";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { useFormValidations } from "../../../util/hooks/use-form-validations";

import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";

import { getPersonMagicPerson } from "../travel-buddy-utils";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import {
  Editability,
  useGuestDataEditable
} from "../../booking-overview/hooks/use-guest-data-editable";
import { EditableNotification } from "../../../components/atoms/notification/editable-notification";
import { ProfileTemplate } from "../components/profile-template";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { CheckboxTerms } from "../../../components/molecules/personal-form/checkbox-terms";
import { ReservationExtras } from "../../../domain-common/reservation-extras";
import { MarketingConsent } from "../../../components/molecules/personal-form/marketing-consent";
import { generatePortalProfileUrl } from "../../../util/routing";
import { generateFastGuestFlowLink } from "../../guest-flow/pages/fast-guest-flow.page";
import { MarketingConsentOptin } from "../../../components/molecules/personal-form/marketing-consent-optin";

interface ProfilePersonalPageProps {}

type FormData = { person: Person; acceptedTerms?: boolean; extras: ReservationExtras };

function shouldShowConfirmDialog(
  authenticated: boolean | undefined,
  values: FormData,
  reservation: Reservation
) {
  return (
    authenticated &&
    isReservation(reservation) &&
    values.person.email !== reservation.primaryGuest.email
  );
}

export const ProfilePersonalPage: React.FC<
  React.PropsWithChildren<ProfilePersonalPageProps>
> = () => {
  const { reservation } = useReservationContext();
  const navigate = useNavigate();
  const { patchGuestFlowStep } = useGuestFlow();
  const {
    diverseGenderEnabled,
    genderDisplayDisabled,
    marketingConsentEnabled,
    marketingConsentDoubleOptinEnabled
  } = useFeatureFlags();
  const { open: openModal } = useGlobalModal();
  const { authenticated } = useAuth();
  const location = useLocation();
  useSetupSubpageNavigation(
    location.state?.fastCheckin ? generateFastGuestFlowLink : generatePortalProfileUrl
  );

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const { personalDataStepValidation } = useFormValidations();

  const personType = useMemo(() => getPersonMagicPerson(reservation), [reservation]);

  const handleSubmit = React.useCallback(
    async (values: FormData) => {
      const confirmModalArg = getYesNoModalArg(
        tCommon("modals__confirm_email_change_title"),
        tCommon("modals__confirm_email_change_content"),
        tCommon("buttons__yes"),
        tCommon("buttons__no")
      );
      let showConfirmDialog = shouldShowConfirmDialog(authenticated, values, reservation);

      try {
        if (showConfirmDialog) {
          const result = await openModal(confirmModalArg);
          if (!result) {
            return;
          }
        }

        const extras = isTravelBuddy(reservation)
          ? {
              ...values.extras,
              ...{
                tcGeneral: !!values.acceptedTerms,
                tcOnline: !!values.acceptedTerms
              }
            }
          : values.extras;

        await patchGuestFlowStep({
          reservationValues: {
            ...reservation,
            [personType]: values.person,
            extras
          },
          subUpdate: SubUpdate.PERSONAL_DATA
        });

        navigate(-1);
      } catch (e) {
        console.error("Update personal data [portal] went wrong", e);
      }
    },
    [navigate, patchGuestFlowStep, reservation, personType, openModal, tCommon, authenticated]
  );

  const initialValues: FormData | null = useMemo(() => {
    const t = reservation
      ? ({
          person: reservation[personType],
          extras: reservation.extras
        } as FormData)
      : null;

    if (isTravelBuddy(reservation) && t) {
      t.acceptedTerms = reservation.extras?.tcGeneral && reservation.extras.tcOnline;
    }
    return t;
  }, [reservation, personType]);

  const labels = useMemo(
    () => ({
      firstName: tCommon("labels__first_name"),
      lastName: tCommon("labels__last_name"),
      genderFemale: tCommon("labels__gender_female"),
      genderMale: tCommon("labels__gender_male"),
      genderOther: tCommon("labels__gender_other"),
      phone: tCommon("labels__phone"),
      email: tCommon("labels__email")
    }),
    [tCommon]
  );

  const { editable: nameEditable } = useGuestDataEditable({
    uneditableStatus: [ReservationStatus.CHECKED_OUT, ReservationStatus.IN_HOUSE],
    reservation
  });
  const { editable: contactDataEditable } = useGuestDataEditable({
    uneditableStatus: [ReservationStatus.CHECKED_OUT],
    reservation
  });
  if (nameEditable === undefined || contactDataEditable === undefined) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Personal data" />
      <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
        <ProfileTemplate>
          <PageHeadingInfo
            title={tCommon("title__personal_data_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
          />
          <EditableNotification editabilities={[contactDataEditable, nameEditable]} />
          {initialValues && (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={personalDataStepValidation}
            >
              {(formik) => (
                <Form noValidate>
                  <PersonForm
                    objectPathPrefix="person."
                    values={formik.values.person}
                    onChange={formik.handleChange}
                    labels={labels}
                    contactDataEditable={
                      contactDataEditable !== Editability.EDITABLE || formik.isSubmitting
                    }
                    nameEditable={nameEditable !== Editability.EDITABLE || formik.isSubmitting}
                    touched={formik.touched.person}
                    errors={formik.errors.person}
                    diverseGenderEnabled={diverseGenderEnabled}
                    genderDisplayDisabled={genderDisplayDisabled}
                  />
                  {marketingConsentEnabled && !marketingConsentDoubleOptinEnabled && (
                    <MarketingConsent
                      value={formik.values.extras?.tcMarketingConsent}
                      onChange={formik.handleChange}
                      label={tCommon("labels__marketing_consent")}
                    />
                  )}
                  {marketingConsentEnabled && marketingConsentDoubleOptinEnabled && (
                    <MarketingConsentOptin
                      value={formik.values.extras}
                      onChange={formik.handleChange}
                      label={tCommon("labels__marketing_consent_with_double_optin")}
                    />
                  )}
                  {isTravelBuddy(reservation) && (
                    <CheckboxTerms
                      acceptedTerms={!!formik.values.acceptedTerms}
                      touched={!!formik.touched?.acceptedTerms}
                      errors={formik.errors?.acceptedTerms ?? ""}
                      onChange={formik.handleChange}
                    />
                  )}
                  {formik.dirty && (
                    <SubmitButton
                      label={tCommon("buttons__save")}
                      disabled={formik.isSubmitting}
                      hasBottomNavigation
                      hasWhiteBackground
                    />
                  )}
                </Form>
              )}
            </Formik>
          )}
        </ProfileTemplate>
      </RoundedFullHeight>
    </>
  );
};
