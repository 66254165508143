import { GuestFlowCheckpoint } from "./guest-flow-checkpoint";

export const GuestFlowCheckpointToPath = {
  [GuestFlowCheckpoint.PERSONAL_DATA]: "/personal",
  [GuestFlowCheckpoint.PREFERRED_CHANNEL]: "/preferred-channel",
  [GuestFlowCheckpoint.LEGAL]: "/legal",
  [GuestFlowCheckpoint.ADDRESS]: "/address",
  [GuestFlowCheckpoint.SERVICES]: "/services",
  [GuestFlowCheckpoint.OVERVIEW]: "/overview",
  [GuestFlowCheckpoint.PAYMENT]: "/payment",
  [GuestFlowCheckpoint.PAYMENT_REDIRECT]: "/payment-redirect",
  [GuestFlowCheckpoint.CONFIRMATION]: "/confirmation",
  [GuestFlowCheckpoint.ASSIGN_STUDIO]: "/assign-studio",
  [GuestFlowCheckpoint.ASSIGN_KEY]: "/assign-key"
};
