import { Box, Divider, Grid, List } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { Heading2, Heading4, Paragraph, useCountries } from "@likemagic-tech/sv-magic-library";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { ReservationChip } from "../../../components/molecules/reservation-chip";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { isReservation, isTravelBuddy } from "../../../util/flow";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useProfilePage } from "../hooks/use-profile-page";
import { useSetupMagicPageTopNavButtons } from "../hooks/use-setup-magic-page-top-nav-buttons";

import { ProfilePagesEnum, ProfilePageToPath } from "../profile-page-to-path";
import { filterPagesForTravelBuddy } from "../travel-buddy-utils";
import { generatePortalProfilePagesUrl } from "../../../util/routing";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { DisplayCmsSvg, SubmitButton } from "../../../components";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import {
  getItemsTitle,
  getStepDetails,
  getStepIcon
} from "../../../components/molecules/step-preview-item/step-preview-items-util";
import { StepPreviewItem } from "../../../components/molecules/step-preview-item/step-preview-item";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import {
  useAllowedLocales,
  useDisabledCountriesForIdentification,
  useFeatureFlags
} from "../../../util/hooks/use-configuration";
import { usePropertyById } from "../../property/use-property-by-id";
import { useNavigate } from "react-router-dom";
import { isDisabledCountry } from "../../../util/countries";
import { restrictiveIdStatuses } from "../../../domain-common/id-check-status";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

const profilePageItems = [
  ProfilePagesEnum.PERSONAL,
  ProfilePagesEnum.COMMUNICATION,
  ProfilePagesEnum.LEGAL,
  ProfilePagesEnum.ADDRESS,
  ProfilePagesEnum.LANGUAGE,
  ProfilePagesEnum.ACCOUNT
];

export const ProfilePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { reservation } = useReservationContext();

  const reservationWithRestriction = useMemo(() => {
    const isLegalDataRestricted = restrictiveIdStatuses.includes(reservation.idCheckStatus);
    return isLegalDataRestricted
      ? {
          ...reservation,
          primaryGuest: {
            ...reservation.primaryGuest,
            birthdate: undefined,
            identificationNumber: "",
            nationalityCountryCode: ""
          }
        }
      : reservation;
  }, [reservation]);

  const { authEnabled, payAtCheckoutEnabled, checkoutFlowEnabled, tfeFeatureEnabled } =
    useFeatureFlags();
  const isMobile = useIsMobile();
  const { magicId } = useMagicIdParams();
  const allowedLocales = useAllowedLocales();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const { getCountryNameByCode } = useCountries({ propertyId: reservation?.propertyId });

  const { errorsPerPage } = useProfilePage(reservationWithRestriction);

  const property = usePropertyById(reservationWithRestriction?.propertyId);

  const disabledCountriesForIdentification = useDisabledCountriesForIdentification();

  const isDisabledIdentification = isDisabledCountry(
    disabledCountriesForIdentification,
    reservationWithRestriction.primaryGuest?.nationalityCountryCode
  );

  useSetupMagicPageTopNavButtons({
    magicId,
    propertyId: reservationWithRestriction.propertyId
  });

  const logoutAction = useCallback(async () => {
    navigate("/logout");
  }, [navigate]);

  const getCountryNameByCodeLocal = useCallback(
    (code: string) => {
      return getCountryNameByCode(getI18nSelectedLanguage(), code);
    },
    [getCountryNameByCode]
  );

  const links = React.useMemo(() => {
    const items = profilePageItems
      .filter((page) =>
        isTravelBuddy(reservationWithRestriction) ? filterPagesForTravelBuddy(page) : true
      )
      .map((pageId) => {
        return {
          to: generatePortalProfilePagesUrl(magicId, pageId),
          label: t(getItemsTitle(pageId)),
          details: (
            <>
              {getStepDetails(
                reservationWithRestriction,
                pageId,
                t,
                allowedLocales,
                property.timeZone,
                getCountryNameByCodeLocal,
                isDisabledIdentification
              ).map(
                (item, index) =>
                  item && <Paragraph key={`${pageId}-item-${index}`}>{item}</Paragraph>
              )}
            </>
          ),
          icon: getStepIcon(pageId) ? (
            <DisplayCmsSvg color="secondary" url={cmsData?.data[getStepIcon(pageId)]?.url} />
          ) : undefined,
          infoWarningLabel: errorsPerPage[pageId]
        };
      });

    if (!authEnabled || tfeFeatureEnabled) {
      return items.filter(
        (link) => link.to.indexOf(ProfilePageToPath[ProfilePagesEnum.ACCOUNT]) < 0
      );
    }
    return items;
  }, [
    getCountryNameByCodeLocal,
    magicId,
    errorsPerPage,
    t,
    reservationWithRestriction,
    authEnabled,
    tfeFeatureEnabled,
    allowedLocales,
    cmsData?.data,
    property.timeZone,
    isDisabledIdentification
  ]);

  return (
    <>
      <HelmetTitle suffix="Menu" />
      <RoundedFullHeight subtrahend={0}>
        {isMobile && (
          <Box mb={3}>
            <Grid container alignItems="center" direction="column" spacing={0}>
              <Grid item>
                <Box>
                  <Heading2 align={"center"} gutterBottom>
                    {t("title_profile")}
                  </Heading2>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Heading4 align={"center"} gutterBottom>
                    {isReservation(reservation)
                      ? t("subtitle__profile")
                      : t("subtitle__profile_for_travel_buddy")}
                  </Heading4>
                </Box>
              </Grid>
              <Grid item>
                <ReservationChip
                  reservation={reservation}
                  timeZone={property.timeZone}
                  payAtCheckoutEnabled={payAtCheckoutEnabled}
                  checkoutFlowEnabled={checkoutFlowEnabled}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <List component="nav">
          {isMobile && <Divider />}
          {links.map((l) => (
            <React.Fragment key={l.to}>
              <StepPreviewItem
                to={l.to}
                label={l.label}
                icon={l.icon}
                details={l.details}
                infoWarningLabel={l.infoWarningLabel}
              />
              {isMobile && <Divider />}
            </React.Fragment>
          ))}
        </List>
        <Box p={2}>
          <SubmitButton
            label={t("buttons__logout")}
            variant="secondary"
            onClick={logoutAction}
            hasBottomNavigation
            hasWhiteBackground
          />
        </Box>
      </RoundedFullHeight>
    </>
  );
};
