import { useNavbar } from "../../components/organism/top-navbar/navbar-context";
import { useBottomNavbar } from "../../components/organism/bottom-navbar/bottom-navbar-context";
import { useEffect } from "react";
import { ContactButtonNavigation } from "../../components/molecules/contact-button-navigation";

export const useSetupInfoStayPageTopNav = ({ propertyId }: { propertyId?: string }) => {
  const { setLeftButtons, setRightButtons, setDisplayNavbar } = useNavbar();
  const { setDisplayBottomNavbar } = useBottomNavbar();

  useEffect(() => {
    setDisplayNavbar(true);

    setLeftButtons([]);
    setDisplayBottomNavbar(true);
    const buttons = [<ContactButtonNavigation key="contact_button" propertyId={propertyId} />];

    setRightButtons(buttons);
  }, [setDisplayNavbar, setLeftButtons, setRightButtons, setDisplayBottomNavbar, propertyId]);
};
