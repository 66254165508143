import { EntityStateStatus, isStatusFailed, isStatusLoading } from "../../state/EntityStateStatus";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { handleSliceError } from "../../util/error-handling";
import { SelfPouringStationShopApi } from "../../api/self-pouring-station.api";
import { SelfPouringStationAvailability } from "../../domain-v1/self-pouring-station-availability";

interface SelfPouringStationShopSlice {
  status: EntityStateStatus;
  statusPour: EntityStateStatus;
}

interface SelfPouringStationArgs {
  drinkId: string;
  magicId: string;
  magicToken: string;
}

const selfPouringStationAvailabilityEntityAdapter =
  createEntityAdapter<SelfPouringStationAvailability>({
    selectId: (model) => model.drinkId
  });

const initialState =
  selfPouringStationAvailabilityEntityAdapter.getInitialState<SelfPouringStationShopSlice>({
    status: EntityStateStatus.IDLE,
    statusPour: EntityStateStatus.IDLE
  });

export const fetchSelfPouringStationAvailability = createAsyncThunk<
  Array<SelfPouringStationAvailability>,
  { magicId: string; magicToken: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("selfPouringStationShop/fetchAvailability", async (arg, thunkAPI) => {
  try {
    return await SelfPouringStationShopApi.fetchSelfPouringStationAvailability(arg, {
      signal: thunkAPI.signal
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const reserveSelfPouringStation = createAsyncThunk<
  void,
  SelfPouringStationArgs,
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("selfPouringStationShop/reserveSelfPouringStation", async (arg, thunkAPI) => {
  try {
    return await SelfPouringStationShopApi.reserveSelfPouringStation(arg, {
      signal: thunkAPI.signal
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const pourSelfPouringStation = createAsyncThunk<
  void,
  SelfPouringStationArgs,
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("selfPouringStationShop/pourSelfPouringStation", async (arg, thunkAPI) => {
  try {
    return await SelfPouringStationShopApi.pourSelfPouringStation(arg, {
      signal: thunkAPI.signal
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const selfPouringStationShopSlice = createSlice({
  name: "selfPouringStationShop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelfPouringStationAvailability.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchSelfPouringStationAvailability.fulfilled, (state, action) => {
        selfPouringStationAvailabilityEntityAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchSelfPouringStationAvailability.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      })
      .addCase(reserveSelfPouringStation.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(reserveSelfPouringStation.fulfilled, (state) => {
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(reserveSelfPouringStation.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      })
      .addCase(pourSelfPouringStation.pending, (state) => {
        state.statusPour = EntityStateStatus.LOADING;
      })
      .addCase(pourSelfPouringStation.fulfilled, (state) => {
        state.statusPour = EntityStateStatus.SUCCEEDED;
      })
      .addCase(pourSelfPouringStation.rejected, (state) => {
        state.statusPour = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer, actions } = selfPouringStationShopSlice;

const selectSelf = (state: RootState) => state[selfPouringStationShopSlice.name];

export const { selectAll: selectSelfPouringStationShopAvailability } =
  selfPouringStationAvailabilityEntityAdapter.getSelectors<RootState>(selectSelf);

export const selectIsSelfPouringStationLoading = createSelector(selectSelf, (s) =>
  isStatusLoading(s.status)
);
export const selectIsSelfPouringStationError = createSelector(selectSelf, (s) =>
  isStatusFailed(s.status)
);

export const selectIsSelfPouringStationPourLoading = createSelector(selectSelf, (s) =>
  isStatusLoading(s.statusPour)
);
export const selectIsSelfPouringStationPourError = createSelector(selectSelf, (s) =>
  isStatusFailed(s.statusPour)
);
