import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UpdateSecondaryGuestDetailsMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  persons: Array<Types.SecondaryPersonInput> | Types.SecondaryPersonInput;
  magicToken: Types.Scalars["String"];
}>;

export type UpdateSecondaryGuestDetailsMutation = {
  __typename?: "Mutation";
  UpdateSecondaryGuestDetails: Array<{ __typename?: "Person"; email?: string | null }>;
};

export const UpdateSecondaryGuestDetailsDocument = `
    mutation UpdateSecondaryGuestDetails($magicId: String!, $persons: [SecondaryPersonInput!]!, $magicToken: String!) {
  UpdateSecondaryGuestDetails(
    magicId: $magicId
    persons: $persons
    magicToken: $magicToken
  ) {
    email
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UpdateSecondaryGuestDetails: build.mutation<
      UpdateSecondaryGuestDetailsMutation,
      UpdateSecondaryGuestDetailsMutationVariables
    >({
      query: (variables) => ({ document: UpdateSecondaryGuestDetailsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdateSecondaryGuestDetailsMutation } = injectedRtkApi;
