import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Selector
} from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { handleSliceError } from "../../util/error-handling";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { SearchAvailableBookingProperty } from "../../domain-common/search-available-booking-property";
import { SearchApi } from "../../api/search.api";
import { getTenantHeaders } from "../../api/custom-headers";

export const searchAvailablePropertiesAdapter = createEntityAdapter<SearchAvailableBookingProperty>(
  {
    selectId: (searchUnitGroup: SearchAvailableBookingProperty) => searchUnitGroup.propertyId
  }
);

type SearchBookingFiltersState = {
  peoplePickerModalOpen: boolean;
  specialRateModalOpen: boolean;
  language?: string;
  searchAvailablePropertyStatus: EntityStateStatus;
};
export const initialState =
  searchAvailablePropertiesAdapter.getInitialState<SearchBookingFiltersState>({
    peoplePickerModalOpen: false,
    specialRateModalOpen: false,
    searchAvailablePropertyStatus: EntityStateStatus.IDLE
  });

export const searchAvailablePropertyAction = createAsyncThunk<
  SearchAvailableBookingProperty[],
  {
    propertyIds: string;
    arrival: string;
    departure: string;
    adults: number;
    childrenAges: number[];
    promoCodePMS?: string;
    promoCodeMagic?: string;
  },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("search/properties", async (arg, thunkAPI) => {
  try {
    return await SearchApi.searchForAvailableProperties(arg, {
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const searchAvailablePropertiesSlice = createSlice({
  name: "searchAvailablePropertiesSlice",
  initialState,
  reducers: {
    togglePeoplePickerModal: (state) => {
      state.peoplePickerModalOpen = !state.peoplePickerModalOpen;
    },
    toggleSpecialRateModal: (state) => {
      state.specialRateModalOpen = !state.specialRateModalOpen;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchAvailablePropertyAction.pending, (state) => {
        state.searchAvailablePropertyStatus = EntityStateStatus.LOADING;
      })
      .addCase(searchAvailablePropertyAction.fulfilled, (state, action) => {
        state.searchAvailablePropertyStatus = EntityStateStatus.SUCCEEDED;
        searchAvailablePropertiesAdapter.removeAll(state);
        searchAvailablePropertiesAdapter.setAll(state, action.payload);
      })
      .addCase(searchAvailablePropertyAction.rejected, (state) => {
        state.searchAvailablePropertyStatus = EntityStateStatus.FAILED;
      });
  }
});

export const { togglePeoplePickerModal, toggleSpecialRateModal } =
  searchAvailablePropertiesSlice.actions;

export const selectSearchBookingFilters: Selector<RootState, SearchBookingFiltersState> = (
  state: RootState
) => state[searchAvailablePropertiesSlice.name];

export const { selectAll: searchAvailableProperties } =
  searchAvailablePropertiesAdapter.getSelectors<RootState>(
    (state) => state.searchAvailablePropertiesSlice
  );

export const selectIsSearchAvailablePropertiesLoading = createSelector(
  selectSearchBookingFilters,
  (res) => res.searchAvailablePropertyStatus === EntityStateStatus.LOADING
);
