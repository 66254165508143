import { FindReservationResponseDTO } from "../../features/find-reservation/find-reservation.dto";
import { SearchReservationsQuery } from "../queries/SearchReservations.generated";

type SearchReservationsInput = SearchReservationsQuery["SearchReservations"];
export const transformSearchReservationData = (
  data?: SearchReservationsInput
): Array<FindReservationResponseDTO> =>
  data
    ? data.map((item) => ({
        magicId: item.magicId,
        studioNumber: item.unit?.name ?? "",
        adults: item.adultsAmount ?? 0,
        children: item.childrenAmount ?? 0,
        unitGroup: item.unitGroup?.id?.toString() ?? "",
        unitGroupId: item.unitGroup?.id?.toString() ?? ""
      }))
    : [];
