import React, { FC } from "react";
import { Grid } from "@mui/material";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";

const useStylesLegend = makeStyles()((theme) => ({
  dot: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
    borderRadius: "50%",
    display: "inline-block",
    marginRight: theme.spacing(0.5)
  },
  highlightedDot: {
    backgroundColor: theme.palette.primary.main
  },
  selectedDot: {
    backgroundColor: theme.palette.grey[700]
  },
  legendContainer: {
    textAlign: "center"
  }
}));

export type LegendLabels = {
  selected: string;
  highlighted: string;
};
export const LegendToolbar: FC<React.PropsWithChildren<{ legendLabels: LegendLabels }>> = ({
  legendLabels
}) => {
  const { classes } = useStylesLegend();

  return (
    <Grid container className={classes.legendContainer}>
      <Grid item xs>
        <span className={`${classes.dot} ${classes.highlightedDot}`} />
        <ParagraphSmall display="inline">{legendLabels.selected}</ParagraphSmall>
      </Grid>
      <Grid item xs>
        <span className={`${classes.dot} ${classes.selectedDot}`} />
        <ParagraphSmall display="inline">{legendLabels.highlighted}</ParagraphSmall>
      </Grid>
    </Grid>
  );
};
