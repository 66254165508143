import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M23.8376 23.0202L12.7307 11.8692L23.5777 0.979416C23.8051 0.750845 23.8051 0.391661 23.5777 0.179416C23.3504 -0.049155 22.9932 -0.049155 22.7821 0.179416L11.935 11.0692L1.10414 0.16309C0.876801 -0.049155 0.519561 -0.049155 0.292227 0.16309C0.064893 0.391661 0.064893 0.750845 0.292227 0.979416L11.1231 11.8692L0.162322 22.8896C-0.0650123 23.1182 -0.0650123 23.4774 0.162322 23.6896C0.275989 23.8039 0.422133 23.8529 0.568276 23.8529C0.714419 23.8529 0.860563 23.8039 0.97423 23.6896L11.935 12.6692L23.0257 23.8202C23.1393 23.9345 23.2855 23.9835 23.4316 23.9835C23.5778 23.9835 23.7239 23.9345 23.8376 23.8202C24.0487 23.608 24.0487 23.2488 23.8376 23.0202Z" />
    </SvgIcon>
  );
}
