import { fetchEventSource } from "@microsoft/fetch-event-source";

export enum BoxEvent {
  INIT = "",
  BOX_OPENED = '"BOX_OPENED"',
  BOX_NOT_OPENED = '"BOX_NOT_OPENED"',
  BOX_CLOSED = '"BOX_CLOSED"',
  BOX_NOT_CLOSED = '"BOX_NOT_CLOSED"'
}

export enum BoxOpenMode {
  GUEST_BOUGHT = "GUEST_BOUGHT",
  GUEST_RETURN = "GUEST_RETURN"
}

interface BoxShopEventsArgs {
  boxId: string;
  magicId: string;
}

export const initFetchEventSource = async (
  mode: BoxOpenMode,
  args: BoxShopEventsArgs,
  handleNewEvent: (event: BoxEvent) => void,
  abort?: AbortController,
  onInit?: () => void,
  onOnError?: () => void
) =>
  await fetchEventSource(
    `/api/guest-journey-service/magic/${args.magicId}/box/${args.boxId}/open?boxOpenMode=${mode}`,
    {
      method: "GET",
      onopen(res) {
        if (res.ok && res.status === 200) {
          onInit?.();
          return Promise.resolve();
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          onOnError?.();
          console.log("Client side error ", res);
        }
        return Promise.resolve();
      },
      onmessage(event) {
        switch (event.event) {
          case "box-state-change":
            handleNewEvent(event.data as BoxEvent);
            if (event.data === BoxEvent.BOX_CLOSED) {
              abort?.abort();
            }
            break;
          case "keep-alive":
            break;
        }
      },
      onclose() {
        console.log("Connection closed by the server");
      },
      onerror(err: any) {
        console.log("There was an error from server", err);
      },
      signal: abort?.signal,
      openWhenHidden: true
    }
  );
