import { Box, FormControlLabel, Grid } from "@mui/material";
import { FormikHandlers } from "formik";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import React from "react";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";
import { AddressFormData } from "./address-from-data";
import { Notification } from "../../atoms";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { Checkbox, useCountries } from "@likemagic-tech/sv-magic-library";
import { WrappedInput } from "../../atoms/input/wrapped-input";
import { WrappedNativeSelect } from "../../atoms/input/wrapped-native-select";
import { useReservationContext } from "../../../features/reservation-provider/reservation-provider";

interface AddressFormProps {
  values: AddressFormData;
  handleChange: FormikHandlers["handleChange"];
  errors: FormikErrors<AddressFormData>;
  touched: FormikTouched<AddressFormData>;
  isSubmitting: boolean;
  isMainFolioExists: boolean;
  labels: {
    addressLine1: string;
    postalCode: string;
    city: string;
    countryCode: string;
    sameAddress: string;
    firstName: string;
    lastName: string;
    companyName: string;
    companyTaxId: string;
    email: string;
    reference: string;
  };
}

export const AddressForm: React.FC<React.PropsWithChildren<AddressFormProps>> = ({
  values,
  handleChange,
  touched,
  errors,
  isSubmitting,
  labels,
  isMainFolioExists
}) => {
  const { t, exists } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const notificationLabelExists = exists("labels__booking_creation_address_not_editable");
  const { reservation } = useReservationContext();
  const { sortedCountries } = useCountries({ propertyId: reservation?.propertyId });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WrappedInput
          id="mainAddress.addressLine1"
          name="mainAddress.addressLine1"
          value={values.mainAddress.addressLine1}
          onChange={handleChange}
          label={labels.addressLine1}
          error={touched.mainAddress?.addressLine1 && errors.mainAddress?.addressLine1}
          disabled={isSubmitting}
        />
      </Grid>
      <Grid item xs={6}>
        <WrappedInput
          id="mainAddress.postalCode"
          name="mainAddress.postalCode"
          value={values.mainAddress.postalCode}
          onChange={handleChange}
          label={labels.postalCode}
          error={touched.mainAddress?.postalCode && errors.mainAddress?.postalCode}
          disabled={isSubmitting}
        />
      </Grid>
      <Grid item xs={6}>
        <WrappedInput
          id="mainAddress.city"
          name="mainAddress.city"
          value={values.mainAddress.city}
          onChange={handleChange}
          label={labels.city}
          error={touched.mainAddress?.city && errors.mainAddress?.city}
          disabled={isSubmitting}
        />
      </Grid>
      <Grid item xs={12}>
        <WrappedNativeSelect
          id="mainAddress.countryCode"
          name="mainAddress.countryCode"
          value={values.mainAddress.countryCode}
          onChange={handleChange}
          label={labels.countryCode}
          error={touched.mainAddress?.countryCode && errors.mainAddress?.countryCode}
          disabled={isSubmitting}
          items={sortedCountries(getI18nSelectedLanguage())}
        />
      </Grid>
      <Grid item xs={12}>
        {!values.isBillingAddressEditable && notificationLabelExists && isMainFolioExists && (
          <Box py={2}>
            <Notification
              type="warning"
              title={t("labels__booking_creation_address_not_editable")}
            />
          </Box>
        )}
        <FormControlLabel
          control={
            <Checkbox
              id={"isSameAddress"}
              name={"isSameAddress"}
              onChange={handleChange}
              color="primary"
              checked={values.isSameAddress}
            />
          }
          label={labels.sameAddress}
          disabled={isSubmitting || !values.isBillingAddressEditable}
        />
      </Grid>
      {!values.isSameAddress && (
        <>
          <Grid item xs={6}>
            <WrappedInput
              id="billingAddress.firstName"
              name="billingAddress.firstName"
              value={values.billingAddress.firstName}
              onChange={handleChange}
              label={labels.firstName}
              error={touched.billingAddress?.firstName && errors.billingAddress?.firstName}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            <WrappedInput
              id="billingAddress.lastName"
              name="billingAddress.lastName"
              value={values.billingAddress.lastName}
              onChange={handleChange}
              label={labels.lastName}
              error={touched.billingAddress?.lastName && errors.billingAddress?.lastName}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <WrappedInput
              id="billingAddress.companyName"
              name="billingAddress.companyName"
              value={values.billingAddress.companyName}
              onChange={handleChange}
              label={labels.companyName}
              error={touched.billingAddress?.companyName && errors.billingAddress?.companyName}
              disabled={isSubmitting || !values.isBillingAddressEditable}
            />
          </Grid>
          <Grid item xs={12}>
            <WrappedInput
              id="billingAddress.companyTaxId"
              name="billingAddress.companyTaxId"
              value={values.billingAddress.companyTaxId}
              onChange={handleChange}
              label={labels.companyTaxId}
              error={touched.billingAddress?.companyTaxId && errors.billingAddress?.companyTaxId}
              disabled={isSubmitting || !values.isBillingAddressEditable}
            />
          </Grid>
          <Grid item xs={12}>
            <WrappedInput
              id="billingAddress.addressLine1"
              name="billingAddress.addressLine1"
              value={values.billingAddress.addressLine1}
              onChange={handleChange}
              label={labels.addressLine1}
              error={touched.billingAddress?.addressLine1 && errors.billingAddress?.addressLine1}
              disabled={isSubmitting || !values.isBillingAddressEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <WrappedInput
              id="billingAddress.postalCode"
              name="billingAddress.postalCode"
              value={values.billingAddress.postalCode}
              onChange={handleChange}
              label={labels.postalCode}
              error={touched.billingAddress?.postalCode && errors.billingAddress?.postalCode}
              disabled={isSubmitting || !values.isBillingAddressEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <WrappedInput
              id="billingAddress.city"
              name="billingAddress.city"
              value={values.billingAddress.city || ""}
              onChange={handleChange}
              label={labels.city}
              error={touched.billingAddress?.city && errors.billingAddress?.city}
              disabled={isSubmitting || !values.isBillingAddressEditable}
            />
          </Grid>
          <Grid item xs={12}>
            <WrappedNativeSelect
              id="billingAddress.countryCode"
              name="billingAddress.countryCode"
              value={values.billingAddress.countryCode}
              onChange={handleChange}
              label={labels.countryCode}
              error={touched.billingAddress?.countryCode && errors.billingAddress?.countryCode}
              disabled={isSubmitting || !values.isBillingAddressEditable}
              items={sortedCountries(getI18nSelectedLanguage())}
            />
          </Grid>
          <Grid item xs={12}>
            <WrappedInput
              id="billingAddress.reference"
              name="billingAddress.reference"
              value={values.billingAddress.reference || ""}
              onChange={handleChange}
              label={labels.reference}
              error={touched.billingAddress?.reference && errors.billingAddress?.reference}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <WrappedInput
              id="billingAddress.email"
              name="billingAddress.email"
              value={values.billingAddress.email || ""}
              onChange={handleChange}
              label={labels.email}
              error={touched.billingAddress?.email && errors.billingAddress?.email}
              disabled={isSubmitting}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
