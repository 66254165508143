import { Grid, useTheme } from "@mui/material";
import { FC, useMemo } from "react";
import { PaymentOption } from "./payment-constants";
import { useIsMobile } from "../../components/layouts/hooks/use-is-mobile";
import { Heading4, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { PaymentAccount, PaymentMethodType } from "../../domain-common/payment-account";
import CreditCard from "@mui/icons-material/CreditCard";

export const PaymentOptionDisplay: FC<{
  paymentAccount?: PaymentAccount;
  paymentOption: PaymentOption;
}> = ({ paymentAccount, paymentOption }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const creditCardIcon = useMemo(() => {
    if (
      !paymentAccount?.paymentMethod ||
      PaymentMethodType.UNKNOWN === paymentAccount?.paymentMethod
    ) {
      return <CreditCard />;
    }
    const cardName = paymentAccount?.paymentMethod?.toLowerCase();
    return (
      <div style={{ display: "flex" }}>
        <img
          width={30}
          src={`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${cardName}.svg`}
          alt={cardName}
        />
      </div>
    );
  }, [paymentAccount?.paymentMethod]);

  const theme = useTheme();

  switch (paymentOption) {
    case PaymentOption.APALEO_TOKENIZED_CARD:
      return (
        <Grid
          sx={{
            borderRadius: theme.shape.borderRadius
          }}
          container
          alignItems="center"
          spacing={isMobile ? 2 : 4}
        >
          <Grid item xs={12} md="auto">
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>{creditCardIcon}</Grid>
              <Grid item>
                <Paragraph ml={1}>{paymentAccount?.accountHolder}</Paragraph>
              </Grid>
            </Grid>
          </Grid>
          {paymentAccount?.paymentMethod !== PaymentMethodType.UNKNOWN && (
            <>
              <Grid
                textAlign="left"
                xs={6}
                md="auto"
                item
              >{`**** **** **** ${paymentAccount?.accountNumber}`}</Grid>
              <Grid item xs={6} md="auto" textAlign={isMobile ? "right" : "center"}>
                {`${paymentAccount?.expiryMonth}/${paymentAccount?.expiryYear}`}
              </Grid>
            </>
          )}
        </Grid>
      );
    default:
      return (
        <Heading4 textAlign="left">
          {t(`labels__payment_option_${paymentOption.toLowerCase()}`)}
        </Heading4>
      );
  }
};
