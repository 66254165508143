import { useSecondScreenActionMutation } from "../../../graphql/mutations/second-screen-action.generated";
import { useCallback } from "react";
import { SecondScreenEventType } from "../../../graphql/generated/graphql";
import { getSecondScreenId } from "../../../util/second-screen-mode";

export const useSecondScreenAction = () => {
  const [secondScreenActionTrigger] = useSecondScreenActionMutation();

  const secondScreenAction = useCallback(
    (eventType: SecondScreenEventType, magicId: string, magicToken: string, payload?: any) =>
      secondScreenActionTrigger({
        eventType,
        magicId,
        magicToken,
        deviceId: getSecondScreenId(),
        payload
      }),
    [secondScreenActionTrigger]
  );

  return { secondScreenAction };
};
