import { FC, PropsWithChildren, useEffect } from "react";
import { isStatusFailed, isStatusSuccess } from "../../state/EntityStateStatus";
import { useProperties } from "../property/use-property-by-id";
import { isSecondScreenMode } from "../../util/second-screen-mode";
import { ErrorFallbackPage } from "../../components/atoms/error-bounadry/error-handler";

export const DataPreloader: FC<PropsWithChildren> = ({ children }) => {
  if (isSecondScreenMode()) {
    return <>{children}</>;
  }

  return <PropertiesDataPreloader>{children}</PropertiesDataPreloader>;
};

export const PropertiesDataPreloader: FC<PropsWithChildren> = ({ children }) => {
  const { status } = useProperties();
  useEffect(() => {
    if (isStatusFailed(status)) {
      console.error("Fetch properties failed.");
    }
  }, [status]);

  if (isStatusSuccess(status)) {
    return <>{children}</>;
  }

  if (isStatusFailed(status)) {
    return (
      <ErrorFallbackPage
        error={{ message: "Fetch properties failed" }}
        resetErrorBoundary={() => {}}
        hideBackButton
      />
    );
  }
  return null;
};
