export interface Price {
  amount: number;
  currency: string;
}

// Good for displaying sane default value.
// Not good as input to `sumPrices` (currencies might not match)
export const emptyPrice = (currency = "CHF"): Price => ({
  amount: 0,
  currency
});

export const PriceFromJSON = (json: any): Price => {
  if (!json) {
    return json;
  }

  return {
    amount: json["amount"],
    currency: json["currency"]
  };
};
