import React from "react";
import "./App.css";
import { AppProviders } from "./config/app-providers";
import "./day-picker.css";
import Router from "./router/router";

function App() {
  return (
    <AppProviders>
      <Router />
    </AppProviders>
  );
}

export default App;
