import { FC } from "react";
import { NotificationBannerWrapper } from "../../../components/atoms/notification/notification-banner-wrapper";
import { DesktopCard } from "../../../components";
import { Box } from "@mui/material";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";

export const ProfileTemplate: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const isMobile = useIsMobile();
  return (
    <NotificationBannerWrapper>
      <Box sx={{ mt: isMobile ? 2 : 0 }}>
        <DesktopCard>{children}</DesktopCard>
      </Box>
    </NotificationBannerWrapper>
  );
};
