import { AccessibleDoor, Reservation } from "../../../domain-common/reservation";
import { DoorProvider } from "@likemagic-tech/sv-magic-library";

export const isRoomDoor = (d: AccessibleDoor) => !d.is_general;

export const getRoomDoor = (reservation: Reservation): AccessibleDoor | undefined =>
  getDisplayableDoors(reservation).find(isRoomDoor);

export const getMainDoor = (
  reservation: Reservation,
  prefixes: string[]
): AccessibleDoor | undefined =>
  getDisplayableDoors(reservation).find((d) =>
    prefixes.find((prefix) => d.title.startsWith(prefix))
  );

//Special case when we need to display key on top of page
export const getDoorApiDoorThatShouldBeDisplayAsPin = (
  doors?: AccessibleDoor[] | null,
  doorProvider?: DoorProvider
): AccessibleDoor | undefined =>
  doors &&
  (doorProvider === DoorProvider.GLUTZ ||
    doorProvider === DoorProvider.BURGWACHTER ||
    doorProvider === DoorProvider.NUKI)
    ? doors.find((d) => !!d.pin)
    : undefined;

export const getDisplayableDoors = (
  reservation: Reservation,
  filterDoorsIds?: string[]
): AccessibleDoor[] => {
  if (reservation?.accessibleDoors === null) {
    return [];
  }

  return filterDoorsIds
    ? reservation.accessibleDoors.filter((d) => !filterDoorsIds.includes(d.id))
    : reservation.accessibleDoors;
};

export const displayDoorName = (door: AccessibleDoor, doorsMap: any) =>
  doorsMap[door.id] ? doorsMap[door.id.toString()] : door.title;
