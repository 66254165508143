import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function MinusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 2">
      <path d="M14 2H8H6H0V0H6H8H14V2Z" fill="black" />
    </SvgIcon>
  );
}
