import { Reservation } from "../../domain-common/reservation";
import { SecondScreenCheckinFormDataType } from "../../domain-common/second-screen-checkin";

export const transformSecondScreenRegistration = (
  values: SecondScreenCheckinFormDataType,
  reservation: Reservation
): Reservation => {
  return {
    ...reservation,
    primaryGuest: {
      ...reservation?.primaryGuest,
      firstName: values.mainGuest.firstName,
      lastName: values.mainGuest.lastName,
      phone: values.mainGuest.phone,
      email: values.mainGuest.email,
      gender: values.mainGuest.gender,
      address: {
        addressLine1: values.mainGuest.addressLine1,
        addressLine2: values.mainGuest.addressLine2,
        city: values.mainGuest.city,
        postalCode: values.mainGuest.postcode,
        countryCode: values.mainGuest.country,
        region: values.mainGuest.region
      },
      id: values.mainGuest.id
    },
    extras: {
      ...reservation.extras!,
      tcGeneral: values.mainGuest.termsAndConditions,
      tcOnline: values.mainGuest.termsAndConditions,
      enrolledInLoyaltyProgram: values.enrolledInLoyaltyProgram,
      emergencyEvacuationHelpNeeded: values.emergencyEvacuationHelpNeeded
    },
    additionalGuests:
      values?.travelBuddies.map((buddy) => ({
        gender: buddy.gender,
        firstName: buddy.firstName || "",
        lastName: buddy.lastName || "",
        phone: buddy.phone || "",
        email: buddy.email || "",
        id: buddy.id || ""
      })) || []
  };
};
