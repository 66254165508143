import { Reservation } from "../domain-common/reservation";
import { UnitCondition } from "../domain-common/unit";
import { ReservationStatus } from "../domain-common/reservation-status";

const NOTIFICATION_CHECKED_IN = "CHECKED_IN";

// Due to business processes, the room might go from Clean -> Dirty/CleanToBeInspected in Apaleo. But from the point of the view of the guest, the room should still accessible
export const userFeelsRoomIsClean = (reservation: Reservation): boolean =>
  !!(
    reservation.status === ReservationStatus.IN_HOUSE &&
    (reservation?.unitCleanOnCheckin ||
      reservation?.unit?.status?.condition === UnitCondition.CLEAN ||
      reservation?.extras?.cleanUnitDialogSeen)
  );

// Due to business processes, the room might go from Clean -> Dirty/CleanToBeInspected in Apaleo. But from the point of the view of the guest, the room should still accessible
export const roomNotAssignedOrClean = (reservation: Reservation): boolean =>
  reservation?.unitCleanOnCheckin ||
  reservation?.flowState?.sentNotifications.indexOf(NOTIFICATION_CHECKED_IN) >= 0;
