import { FC } from "react";
import { Chip } from "@likemagic-tech/sv-magic-library";
import { useThemeOverrideConfig } from "../../../util/hooks/use-configuration";
import { ChipProps } from "@likemagic-tech/sv-magic-library/dist/components/chip/chip.types";

export const WrappedChip: FC<ChipProps> = ({ ...props }) => {
  const themeOverrideConfig = useThemeOverrideConfig();

  return <Chip {...props} variant={themeOverrideConfig?.chip?.variant ?? undefined} />;
};
