import { Route, Routes } from "react-router-dom";
import { SearchAvailablePropertiesPage } from "./pages/search-available-properties.page";
import { SearchBookingPagesToPath } from "./search-booking-navigation";
import { SearchBookingPages } from "./search-booking-pages";
import { SearchConfirmationPage } from "./pages/search-confirmation.page";
import { SearchPaymentAndBookerDataPage } from "./pages/search-payment-and-booker-data.page";
import { SearchCartPage } from "./pages/search-cart.page";
import { RoundedFullHeight } from "../../components/layouts/rounded-full-height";
import { SearchUnitGroupsPage } from "./pages/search-unit-groups.page";

import { NotFoundPage } from "../../components/organism/not-found.page";
import { SearchPaymentRedirectPage } from "./pages/search-payment-redirect.page";
import { useFeatureFlags } from "../../util/hooks/use-configuration";

export const SearchBookingRouterPage = () => {
  const { searchEnabled } = useFeatureFlags();
  if (!searchEnabled) {
    return <NotFoundPage />;
  }

  return (
    <RoundedFullHeight subtrahend={0}>
      <Routes>
        <Route
          path={SearchBookingPagesToPath[SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES]}
          element={<SearchAvailablePropertiesPage />}
        />
        <Route
          path={SearchBookingPagesToPath[SearchBookingPages.SEARCH_AVAILABLE_UNIT_GROUPS]}
          element={<SearchUnitGroupsPage />}
        />
        <Route
          path={SearchBookingPagesToPath[SearchBookingPages.CART_BOOKINGS]}
          element={<SearchCartPage />}
        />
        <Route
          path={SearchBookingPagesToPath[SearchBookingPages.PAYMENT_AND_BOOKER_DATA]}
          element={<SearchPaymentAndBookerDataPage />}
        />
        <Route
          path={SearchBookingPagesToPath[SearchBookingPages.PAYMENT_REDIRECT]}
          element={<SearchPaymentRedirectPage />}
        />
        <Route
          path={SearchBookingPagesToPath[SearchBookingPages.CONFIRMATION]}
          element={<SearchConfirmationPage />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </RoundedFullHeight>
  );
};
