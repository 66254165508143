import { Box, Button, Divider } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import {
  CONFIRMATION_TYPE_KEY,
  ConfirmationType,
  DesktopCard,
  DisplayCmsSvg
} from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { TotalPrice } from "../../../components/molecules/total-price";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generatePortalMyStayUrl, generatePortalPaymentNavigate } from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { ChargeItem } from "../../guest-flow/components/charge-item";

import {
  book,
  clearExtendStay,
  selectExtendStayPriceStatus,
  selectExtendStayPriceSummary,
  selectExtendStayRequestDTO
} from "../../extend-stay/extend-stay.slice";
import { Charge } from "../../../domain-common/payment";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../state/store";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { EntityStateStatus } from "../../../state/EntityStateStatus";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useServicesCmsData } from "../../services/use-services-cms-data";
import { toGross, toVat } from "../../../domain-common/full-price";
import { ExtendStayPriceSummaryDTO } from "../../../api/extend-stay.api";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { useCmsPropertySpecificData } from "../../../state/cms/use-cms-per-property-data";
import { usePreparePayment } from "../../payment/use-prepare-payment";
import { hideRootSuspense, showRootSuspense } from "../../loaders/loader.slice";

interface ExtendStayBillPageProps {}

export const ExtendStayBillPage: React.FC<
  React.PropsWithChildren<ExtendStayBillPageProps>
> = () => {
  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const dispatch = useAppDispatch();
  const { magicId } = useMagicIdParams();
  // TODO we need to get rid of this logic.
  // INFO: using local state so we can await true response from the backend.
  // Sometimes it can happen that open balance is not the newest version, with this we assure it is.
  const [redirect, setRedirect] = useState(false);
  const extendStayRequestDTO = useSelector(selectExtendStayRequestDTO);
  const extendStayPriceStatus: EntityStateStatus = useSelector(selectExtendStayPriceStatus);

  // page was refreshed
  useEffect(() => {
    if (extendStayPriceStatus === EntityStateStatus.IDLE) {
      navigate(generatePortalMyStayUrl(magicId));
    }
  }, [magicId, navigate, extendStayPriceStatus]);

  const handleBack = useCallback(() => {
    dispatch(clearExtendStay());
    navigate(generatePortalMyStayUrl(magicId));
  }, [navigate, dispatch, magicId]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { serviceTitles } = useServicesCmsData(reservation.propertyId);
  const cmsPropertyData = useCmsPropertySpecificData(reservation.propertyId);
  const { preparePayment } = usePreparePayment(magicId);
  const foliosToBePaid = useMemo(
    () => reservation.foliosToBePaid.map((f) => f.folioId),
    [reservation.foliosToBePaid]
  );

  const { showVat } = useFeatureFlags();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const priceSummary: ExtendStayPriceSummaryDTO | undefined = useSelector(
    selectExtendStayPriceSummary
  );
  const charges: Array<Charge> = useMemo(() => {
    if (!priceSummary) {
      return [];
    }
    return [
      priceSummary?.accommodation,
      ...(priceSummary?.cityTaxes || []),
      ...(priceSummary?.services || []),
      ...(priceSummary?.includedServices || [])
    ];
  }, [priceSummary]);

  const onConfirm = useCallback(async () => {
    if (!extendStayRequestDTO) {
      return;
    }
    dispatch(showRootSuspense());
    unwrapResult(
      await dispatch(
        book({
          magicId: reservation.magicId,
          extendStayRequestDTO
        })
      )
    );
    setRedirect(true);
  }, [extendStayRequestDTO, dispatch, reservation]);

  const goToPayment = useCallback(async () => {
    // INFO: We still don't have info from the backend about the correct price, so we are waiting for one second
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
    await preparePayment({ foliosToBePaid });
    setRedirect(false);
    dispatch(hideRootSuspense());
    dispatch(clearExtendStay());
    navigate(
      generatePortalPaymentNavigate(magicId, {
        [CONFIRMATION_TYPE_KEY]: ConfirmationType.EXTEND_STAY
      })
    );
  }, [preparePayment, foliosToBePaid, dispatch, navigate, magicId]);

  useEffect(() => {
    if (foliosToBePaid?.length && redirect) {
      goToPayment().catch(console.error);
    }
  }, [magicId, navigate, extendStayPriceStatus, foliosToBePaid.length, redirect, goToPayment]);

  const displayNameOfItem = useCallback(
    (idToBeTranslated: string) => {
      const unitGroupTranslation = cmsPropertyData?.data?.unit_groups__labels?.find(
        ({ id }: { id: string; text: string }) => id === idToBeTranslated
      )?.text;

      return unitGroupTranslation || serviceTitles[idToBeTranslated] || idToBeTranslated;
    },
    [cmsPropertyData?.data?.unit_groups__labels, serviceTitles]
  );

  if (!priceSummary) {
    return <></>;
  }

  return (
    <>
      <HelmetTitle suffix="Extend stay bill" />
      <FlowTemplate handleBack={handleBack}>
        <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
          <DesktopCard>
            <Box p={2.5}>
              <PageHeadingInfo
                title={tCommon("title__extend_stay")}
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__bill_icon?.url} />}
              />
            </Box>
            <Box my={6}>
              <Heading4>{tCommon("labels__accommodations")}</Heading4>

              <Box mt={1.5}>
                {charges.map((value) => (
                  <ChargeItem
                    showVat={showVat}
                    key={`key-${value.name}`}
                    charge={value}
                    label={displayNameOfItem(value.name)}
                  />
                ))}
              </Box>
              <Box my={1.5}>
                <Divider />
              </Box>
              {showVat && (
                <>
                  <TotalPrice
                    price={toVat(priceSummary?.total)}
                    title={tCommon("labels__vat_amount")}
                    titleGray={true}
                    priceBold={false}
                    priceGray={true}
                  />
                  <Box mb={2} />
                </>
              )}

              <TotalPrice
                price={toGross(priceSummary?.total)}
                title={tCommon("labels__total_amount")}
              />
            </Box>
            <Box py={1.5}>
              <Divider />
            </Box>
            <Box display="flex" flexDirection="column" py={1}>
              <Button onClick={onConfirm} variant="primary">
                {tCommon("buttons__extend_stay")}
              </Button>
            </Box>
          </DesktopCard>
        </RoundedFullHeight>
      </FlowTemplate>
    </>
  );
};
