import { useAuth } from "@likemagic-tech/sv-magic-library";
import { Box, Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { DesktopCard } from "../../components";
import { FlowTemplate } from "../../components/layouts/flow-template";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { useAppDispatch } from "../../state/store";
import { isKioskMode } from "../../util/kiosk-mode";
import { HelmetTitle } from "../gtm/helmet-title";
import { doNotForceHideSpinners, forceHideSpinners } from "../loaders/loader.slice";
import { GuestFlowBackgroundBox } from "../guest-flow/components/guest-flow-background-box";
import { useIsMobile } from "../../components/layouts/hooks/use-is-mobile";
import { FindReservationKioskIntro } from "./find-reservation/find-reservation-kiosk-intro";
import { FindReservation } from "./find-reservation/find-reservation";
import { selectKioskIntro, setKioskIntro } from "./find-reservation.slice";
import { FindReservationModal } from "./find-reservation-modal/find-reservation-modal";
import { useUserProfileLastMagicId } from "../user-profile/use-user-profile-last-magic-id";
import { ContactButtonNavigation } from "../../components/molecules/contact-button-navigation";

export const FindReservationPage: FC = () => {
  const { getIsAuthenticated } = useAuth();
  const isKioskIntro = useSelector(selectKioskIntro);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setKioskIntro({ isKioskIntroVisible: isKioskMode() }));
  }, [dispatch]);

  const { lastMagicIdStatus } = useUserProfileLastMagicId();

  useEffect(() => {
    dispatch(forceHideSpinners());
    return () => {
      dispatch(doNotForceHideSpinners());
    };
  }, [dispatch]);

  const redirect = () => {
    dispatch(setKioskIntro({ isKioskIntroVisible: false }));
  };

  // do NOT show page until reservations are loaded for authenticated user
  if (
    getIsAuthenticated() &&
    [EntityStateStatus.IDLE, EntityStateStatus.LOADING].some((s) => s === lastMagicIdStatus)
  ) {
    return null;
  }

  return (
    <>
      <FindReservationModal />
      <HelmetTitle suffix="Find reservation" />
      <FlowTemplate icons={isKioskMode() ? [] : [<ContactButtonNavigation key="contactButton" />]}>
        <GuestFlowBackgroundBox bottomNavBarIsHidden={isKioskMode()} progressBarIsHidden={true}>
          <Box p={isMobile ? 0 : 2.5}>
            <DesktopCard>
              <Grid container justifyContent="center">
                {isKioskIntro ? (
                  <FindReservationKioskIntro redirect={redirect} />
                ) : (
                  <FindReservation />
                )}
              </Grid>
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
