import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { GetServicesArgs, ServiceApi } from "../../api/service.api";
import { Service } from "../../domain-common/service";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { getTenantHeaders } from "../../api/custom-headers";

export const servicesAdapter = createEntityAdapter<Service>({
  selectId: (model) => `${model.code}_${model.propertyId}`
});

const initialState = servicesAdapter.getInitialState<{
  status: {
    [key: string]: EntityStateStatus;
  };
}>({
  status: {}
});

export const fetchServices = createAsyncThunk<
  Service[],
  GetServicesArgs,
  { state: RootState; extra: ThunkExtraArguments }
>(
  "services/fetchServices",
  async (arg, thunkApi) =>
    ServiceApi.getServices(arg, {
      signal: thunkApi.signal,
      ...(await getTenantHeaders(thunkApi.extra))
    }),
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState()[servicesSlice.name].status;
      // don't load already loaded Services
      if (
        status[arg.magicId] === EntityStateStatus.LOADING ||
        status[arg.magicId] === EntityStateStatus.SUCCEEDED
      ) {
        return false;
      }
    }
  }
);

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state, action) => {
        state.status[action.meta.arg.magicId] = EntityStateStatus.LOADING;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        servicesAdapter.addMany(state, action.payload);
        state.status[action.meta.arg.magicId] = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status[action.meta.arg.magicId] === EntityStateStatus.LOADING) {
            state.status[action.meta.arg.magicId] = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status[action.meta.arg.magicId] = EntityStateStatus.FAILED;
      });
  }
});

const selectSelf = (state: RootState) => state[servicesSlice.name];

export const selectIsServicesLoading = createSelector(selectSelf, (s) => {
  return Object.keys(s.status).some((key) => s.status[key] === EntityStateStatus.LOADING);
});

export const { selectAll: selectServices } = servicesAdapter.getSelectors<RootState>(selectSelf);
