import { HasFlowState } from "./flow-state";
import { MagicObject, MagicObjectFromJSON } from "./magic-object";
import { Reservation, ReservationFromJSON } from "./reservation";
import { GuestFlowContext } from "./guest-flow-checkpoint-and-args";

export enum Actor {
  BOOKER = "BOOKER",
  PRIMARY_GUEST = "PRIMARY_GUEST",
  TRAVEL_BUDDY = "TRAVEL_BUDDY"
}

export interface BookingOverviewItem {
  id: string;
  propertyId: string;
  active: boolean;
  bookingManagementCompleted: boolean;
  arrival: string;
  departure: string;
  reservations: Array<Reservation>;
  travelBuddies: Array<Reservation>;
}

export const BookingOverviewItemFromJSON = (json: any): BookingOverviewItem => {
  if (!json) {
    return json;
  }
  return {
    id: json["id"],
    propertyId: json["propertyId"],
    active: json["active"],
    bookingManagementCompleted: json["bookingManagementCompleted"],
    arrival: json["arrival"],
    departure: json["departure"],
    reservations: Array.isArray(json["reservations"])
      ? json["reservations"].map(ReservationFromJSON)
      : [],
    travelBuddies: Array.isArray(json["travelBuddies"])
      ? json["travelBuddies"].map(ReservationFromJSON)
      : []
  };
};

export interface BookingOverview
  extends MagicObject<GuestFlowContext>,
    HasFlowState<GuestFlowContext> {
  keycloakUUID: string;
  items: Array<BookingOverviewItem>;
  actor: Actor;
  id: string;
}

export const BookingOverviewFromJSON = (json: any): BookingOverview => {
  if (!json) {
    return json;
  }

  const magicObject = MagicObjectFromJSON<GuestFlowContext, BookingOverview>(json);

  return {
    ...magicObject,
    keycloakUUID: json["keycloakUUID"],
    items: Array.isArray(json["items"]) ? json["items"].map(BookingOverviewItemFromJSON) : []
  };
};
