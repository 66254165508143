import { Box, IconButton, Paper, Slide, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { QRCode } from "react-qrcode-logo";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { CloseIcon } from "../components/icons";
import { CMSSingleDocumentTypes } from "../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../util/i18n-wrapper";
import { selectKioskIntro } from "../features/find-reservation/find-reservation.slice";
import { useSelector } from "react-redux";
import { isKioskMode } from "../util/kiosk-mode";
import { isSecondScreenMode } from "../util/second-screen-mode";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "fixed",
    zIndex: 99999,
    bottom: theme.spacing(10),
    right: 0,
    width: theme.spacing(30),
    borderRadius: theme.shape.borderRadius * 1.5,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
}));

const MAGIC_DESKTOP_DEVICE_HINT = "magicDesktopDeviceHint";

export const clearMagicDesktopDeviceHint = () =>
  sessionStorage.removeItem(MAGIC_DESKTOP_DEVICE_HINT);

export const DesktopDeviceHint: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [open, setOpen] = useState(false);
  const isKioskIntro = useSelector(selectKioskIntro);
  const { classes } = useStyles();
  const { palette } = useTheme();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  useEffect(() => {
    // Wait 2s till displaying popup to get user's attention
    const openTimeout = setTimeout(() => {
      const wasAlreadyOpen = sessionStorage.getItem(MAGIC_DESKTOP_DEVICE_HINT);
      const isKioskIntro2 = isKioskMode();
      if (wasAlreadyOpen || (isKioskIntro && isKioskIntro2)) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }, 2000);

    return () => {
      clearTimeout(openTimeout);
    };
  }, [isKioskIntro]);

  const handleClose = useCallback(() => {
    setOpen(false);
    sessionStorage.setItem(MAGIC_DESKTOP_DEVICE_HINT, "true");
  }, []);

  if (isMobile || isSecondScreenMode()) {
    return null;
  }

  return (
    <Slide direction="left" in={open} unmountOnExit>
      <Paper className={classes.root}>
        <div style={{ overflow: "auto" }}>
          <IconButton className={classes.closeButton} onClick={handleClose} size="small">
            <CloseIcon style={{ fontSize: "0.75em" }} />
          </IconButton>
        </div>
        <Box p={2} pt={4}>
          <Paragraph>{t("application__desktop_device_hint")}</Paragraph>
        </Box>
        <Box height={250}>
          <QRCode
            bgColor={palette.background.paper}
            fgColor={palette.text.primary}
            value={
              // Number of chars was taken from https://stackoverflow.com/questions/30796584/qrcode-js-error-code-length-overflow-17161056
              window.location.href.length > 220 || isKioskMode()
                ? window.location.origin
                : window.location.href
            }
            size={220}
          />
        </Box>
      </Paper>
    </Slide>
  );
};
