import { FC, useEffect, useMemo } from "react";
import { DisplayCmsSvg, RoundBox } from "../../../components";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { Box, Grid } from "@mui/material";
import { BoxCardItem } from "../components/box-card-item";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useAppDispatch } from "../../../state/store";
import { useSetupSubpageNavigation } from "../../portal/hooks/use-setup-subpage-navigation";
import { GroupNames, useFetchShopProducts } from "../../shop/hooks/use-fetch-shop-products";
import { Service, ServiceGroup } from "../../../domain-common/service";
import { useSelectedProducts } from "../../shop/hooks/use-selected-products";
import { clearFilter, toggleOnlyOneFilter } from "../../shop/store/shop-display.slice";
import { BoxAvailability, BoxType } from "../../../domain-v1/box-availability";
import { useFetchBoxAvailability } from "../hooks/use-fetch-box-availability";
import { ShopFilter } from "../../shop/components/shop-filter";

export interface ServiceBoxItem extends Service {
  boxId: string;
  boxName: string;
  type: BoxType;
}

export const BoxShopPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const dispatch = useAppDispatch();
  useSetupSubpageNavigation();

  const { reservation } = useReservationContext();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { selectedFilters } = useSelectedProducts();

  useEffect(
    () => () => {
      dispatch(clearFilter());
    },
    [dispatch]
  );

  const boxAvailabilities = useFetchBoxAvailability({
    magicId: reservation.magicId,
    magicToken: reservation.magicToken
  });

  const { products, availableFilters } = useFetchShopProducts(reservation, [
    GroupNames.BoxBuy,
    GroupNames.BoxRent
  ]);

  // @ts-ignore
  const computedProducts: Array<ServiceBoxItem> = useMemo(() => {
    return boxAvailabilities
      .filter((item) => item.available)
      .map((item) => {
        return {
          ...products.find((product) => product.serviceId === item.serviceId),
          boxId: item.boxId,
          boxName: item.boxName,
          type: item.type,
          boxCollection: item.boxCollection,
          boxRentType: item?.boxRentType,
          maximum: item?.maximum,
          unitId: item?.unitId
        };
      })
      .filter(
        (item) =>
          Object.keys(selectedFilters).length === 0 ||
          (item.serviceGroup?.name &&
            selectedFilters[t(`labels__filters_${item.serviceGroup.name.toLowerCase()}`)]) ||
          (item.unitId &&
            reservation.unit?.name &&
            selectedFilters[t("labels__unit") + " " + reservation.unit?.name]) ||
          (item.boxCollection && selectedFilters[item.boxCollection])
      );
  }, [products, boxAvailabilities, selectedFilters, reservation.unit?.name, t]);

  const additionalFiltering: { name: string }[] = useMemo(() => {
    return boxAvailabilities
      .filter((box: BoxAvailability) => box.boxCollection || box.unitId)
      .map((product) => {
        return {
          name:
            product.boxCollection ||
            t("labels__unit") + " " + reservation.unit?.name ||
            product.unitId
        };
      })
      .reduce(
        (accumulator: { name: string }[], value: { name: string }) => {
          const exists = accumulator.some((filter) => filter.name === value.name);

          if (!exists) {
            accumulator.push(value);
          }

          return accumulator;
        },
        [] as { name: string }[]
      );
  }, [boxAvailabilities, reservation.unit?.name, t]);

  const availableFiltersWithLabels = useMemo(() => {
    return availableFilters.map((filter: ServiceGroup) => {
      return {
        name: t(`labels__filters_${filter.name.toLowerCase()}`),
        id: filter.id
      };
    });
  }, [availableFilters, t]);

  const allAvailableFilters: ServiceGroup[] = [
    ...availableFiltersWithLabels,
    ...additionalFiltering
  ];

  return (
    <>
      <RoundBox hasShadow py={3} px={2}>
        <PageHeadingInfo
          title={t("title__box_shop_page")}
          subtitle={t("subtitle__box_shop_page")}
          icon={<DisplayCmsSvg url={cmsData?.data?.icon__cart_icon?.url} />}
        />
      </RoundBox>

      <ShopFilter
        filters={selectedFilters}
        availableFilters={allAvailableFilters as any}
        onFilterClick={(f) => {
          dispatch(toggleOnlyOneFilter(f ? { filterName: f.name } : undefined));
        }}
      />

      <Box px={2.5} py={2.5}>
        <Grid container spacing={2}>
          {products.length > 0 &&
            computedProducts.map((item) => (
              <Grid key={item.boxId} item xs={6} md={3}>
                <BoxCardItem serviceBoxItem={item} propertyId={reservation.propertyId} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};
