import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function ErrorCircle(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 175.7 175.7" {...props}>
      <style type="text/css">
        {`.st0{fill:none;stroke:#020203;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;}`}
      </style>
      <circle id="Circle" className="st0" cx="87.9" cy="87.9" r="85.4" />
      <line className="st0" x1="43.9" y1="43.9" x2="131.9" y2="131.9" />
      <line className="st0" x1="43.9" y1="131.9" x2="131.9" y2="43.9" />
    </SvgIcon>
  );
}
