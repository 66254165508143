import { createClient as createClientWS } from "graphql-ws";

export type Channel = "redux" | "general";

export interface Message {
  id: number;
  channel: Channel;
  userName: string;
  text: string;
}

const getWsGraphQLUrl = () => {
  const protocol = window.location.protocol === "http:" ? "ws:" : "wss:";
  const host = window.location.host;
  return `${protocol}//${host}/api/guest-service/graphql`;
};

export const createClient = (arg: { apiKey?: string; tenantId: string; deviceId?: string }) =>
  createClientWS({
    url: getWsGraphQLUrl(),
    keepAlive: 30000,
    connectionParams: {
      "sk-magic-api-key": arg.apiKey,
      "sk-magic-second-screen-id": arg.deviceId,
      "tenant-id": arg.tenantId,
      ...(arg.deviceId ? { "sk-magic-second-screen-id": arg.deviceId } : {})
    }
  });
