import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { doNotForceHideSpinners, forceHideSpinners } from "../../features/loaders/loader.slice";
import { useMagicIdParams } from "../../features/magic/use-magic-id-params";
import { useCommonNotFoundCmsData } from "../../state/common-cms/use-common-cms-data";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { useAppDispatch } from "../../state/store";
import { generatePortalMyStayUrl } from "../../util/routing";
import { SubmitButton } from "../atoms";
import { RoundedFullHeight } from "../layouts/rounded-full-height";
import { useNavbar } from "./top-navbar/navbar-context";
import { Error404Icon } from "../icons";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { MagicObject } from "../../domain-common/magic-object";

interface NotFoundPageProps {
  magicObjectStatus?: EntityStateStatus;
  magicObject?: MagicObject | null;
}

const useStyles = makeStyles()((theme) => ({
  logo: {
    maxWidth: theme.spacing(25),
    height: theme.spacing(8.75)
  }
}));

export const NotFoundPage: React.FC<React.PropsWithChildren<NotFoundPageProps>> = ({
  magicObjectStatus,
  magicObject
}) => {
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();

  const notFoundCmsData = useCommonNotFoundCmsData();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const isInPortalContext = useMemo(
    () =>
      magicId && magicObjectStatus !== EntityStateStatus.FAILED && magicObject?.magicId === magicId,
    [magicId, magicObjectStatus, magicObject?.magicId]
  );
  const { setDisplayNavbar, setProgress, setLeftButtons, setRightButtons } = useNavbar();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(forceHideSpinners());
    return () => {
      dispatch(doNotForceHideSpinners());
    };
  }, [dispatch]);

  useEffect(() => {
    setDisplayNavbar(true);
    setProgress(0);
  }, [navigate, magicId, setDisplayNavbar, setProgress, setLeftButtons, setRightButtons]);

  const { classes } = useStyles();
  return (
    <>
      <RoundedFullHeight px={2.5}>
        <Box display="flex" flexDirection="row" justifyContent="center" pt={3} pb={2.5}>
          <img
            src={notFoundCmsData?.["404_error__logo"]?.url}
            alt={notFoundCmsData?.["404_error__logo"]?.alt}
            className={classes.logo}
          />
        </Box>
        <Paragraph
          variant="subtitle1"
          align="center"
          color="secondary"
          style={{ fontSize: 130, marginBottom: 80 }}
        >
          <Error404Icon fontSize="inherit" />
        </Paragraph>

        <Paragraph variant="subtitle1" align="center" color="textSecondary">
          {notFoundCmsData?.["404_error__description"]}
        </Paragraph>
        {isInPortalContext && (
          <SubmitButton
            label={tCommon("buttons__go_to_my_stay")}
            hasWhiteBackground
            hasBottomNavigation
            onClick={() => navigate(generatePortalMyStayUrl(magicId))}
          />
        )}
      </RoundedFullHeight>
    </>
  );
};
