import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type CreateBookingMutationVariables = Types.Exact<{
  request: Types.PotentialBooking;
}>;

export type CreateBookingMutation = {
  __typename?: "Mutation";
  CreateBooking: { __typename?: "Booking"; magicId: string; magicToken: string };
};

export const CreateBookingDocument = `
    mutation CreateBooking($request: PotentialBooking!) {
  CreateBooking(booking: $request) {
    magicId
    magicToken
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    CreateBooking: build.mutation<CreateBookingMutation, CreateBookingMutationVariables>({
      query: (variables) => ({ document: CreateBookingDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useCreateBookingMutation } = injectedRtkApi;
