import { FullPrice } from "../../domain-common/full-price";

export interface AdditionalServicesAvailabilityResponse {
  available: boolean;
  serviceId: string;
  code: string; //TODO To be implemented on BE
  granularity: Granularity;
  visibilities: Visibility[];
  bookableDates?: DateAndCount[];
  bookedDates?: DateAndCount[];
  basePrice: FullPrice;
  defaultTargetAudience?: DefaultTargetAudience;
  defaultDatePickerSelection?: DefaultDatePickerSelection;
  canBookMultiple?: boolean;
  maximum?: number;
  // late checkout/early checkin only
  maximumHours: number;
}

export enum DefaultTargetAudience {
  CHILD = "CHILD",
  ADULT = "ADULT",
  ALL = "ALL",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE"
}

export enum DefaultDatePickerSelection {
  NONE = "NONE",
  ALL = "ALL"
}
export enum Granularity {
  WHOLE_STAY = "WHOLE_STAY",
  WHOLE_STAY_PAY_ONCE = "WHOLE_STAY_PAY_ONCE",
  ANY_DAY = "ANY_DAY",
  ANY_DAY_EXCEPT_ARRIVAL_DAY = "ANY_DAY_EXCEPT_ARRIVAL_DAY",
  ARRIVAL_DAY = "ARRIVAL_DAY",
  DEPARTURE_DAY = "DEPARTURE_DAY",
  LATE_CHECKOUT = "LATE_CHECKOUT",
  EARLY_CHECKIN = "EARLY_CHECKIN",
  UNKNOWN = "UNKNOWN"
}
export enum Visibility {
  GUEST_FLOW = "GUEST_FLOW",
  MY_STAY = "MY_STAY",
  BACKOFFICE = "BACKOFFICE"
  // Later: IBE
}
export interface DateAndCount {
  date: string; // TODO LM-374 use a dedicated object for this.
  count: number;
}
