import { BookingOnBehalfOf } from "../../../domain-common/person";
import { Actor } from "../../../domain-common/booking-overview";
import { FC, useMemo } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { SubmitButton } from "../../../components";
import { Reservation } from "../../../domain-common/reservation";

interface PersonalDataSubmitButtonsProps {
  reservation: Reservation;
  disabled: boolean;
  isFormDirty: boolean;
  bookingOnBehalfOf?: BookingOnBehalfOf;
  actor?: Actor;
  setShouldUseGuestFlow: (value: boolean) => void;
}

export const PersonalDataSubmitButtons: FC<
  React.PropsWithChildren<PersonalDataSubmitButtonsProps>
> = ({ bookingOnBehalfOf, disabled, isFormDirty, reservation, setShouldUseGuestFlow }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const guestFormLabels = useMemo(
    () => ({
      checkInButton: t("buttons__checkin"),
      saveButton: t("buttons__save"),
      nextButton: t("buttons__next"),
      inviteGuestsLabel: t("buttons__invite_guest"),
      skipLabel: t("buttons__skip")
    }),
    [t]
  );

  if (bookingOnBehalfOf === BookingOnBehalfOf.MYSELF) {
    return (
      <>
        <SubmitButton
          variant="primary"
          disabled={disabled}
          // This is workaround to know which button did we clicked because can't read value from submit button
          // Could potentially have a race condition against the form onSubmit function
          onClick={() => setShouldUseGuestFlow(true)}
          label={isFormDirty ? guestFormLabels.checkInButton : guestFormLabels.nextButton}
          hasBottomNavigation
        />
        {isFormDirty && (
          <SubmitButton
            variant="secondary"
            disabled={disabled}
            label={guestFormLabels.saveButton}
            hasBottomNavigation
            hasSecondaryFixedButton
          />
        )}
      </>
    );
  } else if (bookingOnBehalfOf === BookingOnBehalfOf.OTHERS) {
    return (
      <>
        {isFormDirty && (
          <SubmitButton
            disabled={disabled}
            label={
              reservation?.flowState.notificationSent
                ? guestFormLabels.saveButton
                : guestFormLabels.inviteGuestsLabel
            }
            hasBottomNavigation
          />
        )}
      </>
    );
  }
  return null;
};
