import { Box, Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import React, { useMemo } from "react";
import {
  DesktopCard,
  DisplayCmsSvg,
  SubmitButton,
  useStylesForSubmitButton
} from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { useAppDispatch } from "../../../state/store";
import { isKioskMode } from "../../../util/kiosk-mode";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { clearPaymentState } from "../../payment/payment.slice";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { getNextFlowUrl } from "../guest-flow-sequence";
import { useGuestFlow } from "../use-guest-flow";
import { isReservation } from "../../../util/flow";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { useNavigate } from "react-router-dom";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";
import { useCheckIn } from "../hooks/use-checkin";
import { Button, Heading2 } from "@likemagic-tech/sv-magic-library";
import { isCheckInAvailable } from "../../../util/reservation";
import { ReservationStatus } from "../../../domain-common/reservation-status";

const useStyles = makeStyles()(({ palette, spacing }: Theme) => ({
  text: {
    textAlign: "center"
  },
  subtitleText: {
    fontSize: 20
  },
  logo: {
    maxWidth: spacing(25),
    height: spacing(8.75)
  },
  checkIcon: {
    fontSize: 200,
    color: palette.secondary.main
  }
}));

let CURRENT_PAGE = GuestFlowCheckpoint.ASSIGN_STUDIO;
export const AssignStudioPage: React.FC<React.PropsWithChildren<{}>> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isBottomNavbarActive } = useBottomNavbar();

  const { classes: classesFixedButton } = useStylesForSubmitButton({
    notFullScreen: isKioskMode(),
    hasBottomNavigation: true,
    hasSecondaryFixedButton: false,
    hasWhiteBackground: true
  });
  const { classes } = useStyles();
  const { reservation, contactButton, skipButton } = useGuestFlow();
  const checkIn = useCheckIn({ reservation });
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const logoSrc = useLogoUrl();

  const onConfirm = React.useCallback(async () => {
    if (reservation.status === ReservationStatus.CONFIRMED) {
      await checkIn();
    }
    navigate(getNextFlowUrl(CURRENT_PAGE, reservation));
  }, [navigate, reservation, checkIn]);

  const assignStudioButtonIsDisabled = useMemo((): boolean => {
    if (reservation.arrival) {
      return isKioskMode() && !isCheckInAvailable(reservation.arrival, new Date().toUTCString());
    }

    return false;
  }, [reservation]);

  const onLogOut = React.useCallback(async () => {
    dispatch(clearPaymentState());

    if (isKioskMode()) {
      navigate(`/logout`);
    } else {
      navigate(generatePortalMyStayUrl(reservation.magicId));
    }
  }, [reservation, dispatch, navigate]);

  const topBarIcons = useMemo(() => {
    if (isReservation(reservation)) {
      if (isKioskMode()) {
        return [skipButton];
      }
      return [contactButton];
    }
    return [];
  }, [reservation, contactButton, skipButton]);

  const title = useMemo(() => {
    if (reservation.status === ReservationStatus.IN_HOUSE) {
      return t("title__assign_studio_already_in_house");
    }
    return assignStudioButtonIsDisabled
      ? t("title__assign_studio_arrival_in_future_message")
      : t("title__assign_studio");
  }, [assignStudioButtonIsDisabled, t, reservation.status]);

  return (
    <>
      <HelmetTitle suffix="Guest flow assign studio" />
      <FlowTemplate icons={topBarIcons}>
        <GuestFlowBackgroundBox progressBarIsHidden bottomNavBarIsHidden={!isBottomNavbarActive}>
          <Box p={2} pt={0}>
            <DesktopCard>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <img src={logoSrc} alt="logo" className={classes.logo} />
              </Box>
              <Box mt={7}>
                <Grid container alignItems="center" justifyContent="center">
                  <DisplayCmsSvg
                    className={classes.checkIcon}
                    url={cmsData?.data?.icon__assign_studio_icon?.url}
                  />
                </Grid>
              </Box>
              <Box className={classes.text} mt={7} mx={3}>
                <Heading2 className={classes.subtitleText} variant="subtitle1">
                  {title}
                </Heading2>
              </Box>
              <SubmitButton
                label={t(
                  reservation.status === ReservationStatus.IN_HOUSE
                    ? "buttons__next"
                    : "buttons__assign_studio"
                )}
                onClick={onConfirm}
                hasWhiteBackground
                hasBottomNavigation
                hasSecondaryFixedButton
                disabled={assignStudioButtonIsDisabled}
              />
              {assignStudioButtonIsDisabled && (
                <Box display="flex" flexDirection="column" className={classesFixedButton.container}>
                  <Button
                    className={classesFixedButton.button}
                    sx={{ width: "50%" }}
                    variant="secondary"
                    onClick={onLogOut}
                    fullWidth
                  >
                    {t("buttons__logout")}
                  </Button>
                </Box>
              )}
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
