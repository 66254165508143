import { Box, Divider } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CONFIRMATION_TYPE_KEY,
  ConfirmationType,
  DesktopCard,
  DisplayCmsSvg,
  parseConfirmationTypeFromQueryParam,
  SubmitButton
} from "../../../components";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { usePreparePayment } from "../../payment/use-prepare-payment";
import { useTotalPrice } from "../../payment/use-total-price";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { generatePortalMyStayUrl, generatePortalPaymentNavigate } from "../../../util/routing";
import { openBanner } from "../../banner/banner.slice";
import { useCommonErrorTranslation } from "../../../state/common-cms/use-common-error-translation";
import { TotalPrice } from "../../../components/molecules/total-price";
import { FolioPreview } from "../../../components/organism/folio/folio-preview";
import { toGross } from "../../../domain-common/full-price";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { NotificationBannerWrapper } from "../../../components/atoms/notification/notification-banner-wrapper";
import { Surcharges } from "../../../components/organism/surcharges/surcharges";

interface OpenBalancePageProps {}

export const OpenBalancePage: React.FC<React.PropsWithChildren<OpenBalancePageProps>> = () => {
  useSetupSubpageNavigation(generatePortalMyStayUrl);
  const { reservation } = useReservationContext();
  const navigate = useNavigate();
  const { isBottomNavbarActive } = useBottomNavbar();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const { magicId } = useMagicIdParams();
  const { preparePayment } = usePreparePayment(magicId);

  const foliosToBePaid = useMemo(
    () => reservation.foliosToBePaid.map((f) => f.folioId),
    [reservation.foliosToBePaid]
  );
  const totalToBePaid = useTotalPrice(reservation.magicId, undefined, undefined, foliosToBePaid);
  const { search } = useLocation();

  const confirmationType = useMemo(() => parseConfirmationTypeFromQueryParam(search), [search]);

  const goToPortalPayment = useCallback(async () => {
    if (totalToBePaid === null) {
      console.error("totalToBePaid === null during goToPortalPayment");
      return;
    }
    if (totalToBePaid.grossAmount === 0) {
      console.error("totalToBePaid.grossAmount === 0 during goToPortalPayment");
      return;
    }
    await preparePayment({
      foliosToBePaid
    });

    navigate(
      generatePortalPaymentNavigate(magicId, {
        [CONFIRMATION_TYPE_KEY]: confirmationType || ConfirmationType.OPEN_BALANCE
      })
    );
  }, [magicId, navigate, preparePayment, foliosToBePaid, totalToBePaid, confirmationType]);
  const goToMyStay = useCallback(async () => {
    navigate(generatePortalMyStayUrl(magicId));
  }, [magicId, navigate]);

  const errorData = useCommonErrorTranslation(state?.errorId);

  useEffect(() => {
    if (state?.errorId) {
      dispatch(
        openBanner({
          type: "error",
          title: errorData?.text || ""
        })
      );
    }
  }, [state?.errorId, dispatch, errorData?.text]);

  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  if (totalToBePaid === null) {
    return null;
  }

  // Note: sometimes, we will access this page while there is no actual open balance.
  // This can be because of
  // * balance was settled outside of our app (=> ideally: just redirect to my stay)
  //   * (similar to above) an old tab was refreshed
  // * BE didn't receive the updated balance *yet* (=> ideally: just wait)
  // Due to the two possible reasons above, we cannot redirect to My stay. So we display slightly different content
  return (
    <>
      <HelmetTitle suffix="Open Balance" />
      <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
        <NotificationBannerWrapper marginInline={2}>
          <DesktopCard>
            <PageHeadingInfo
              title={
                totalToBePaid.grossAmount > 0
                  ? tCommon("title__open_balance_page")
                  : tCommon("title__settled_open_balance_page")
              }
              icon={
                <DisplayCmsSvg
                  url={
                    cmsData?.data?.icon__open_balance_icon?.url ||
                    cmsData?.data?.icon__bill_icon?.url
                  }
                />
              }
            />
            {reservation.folios.map((folio, index) => (
              <FolioPreview
                key={folio.id}
                folio={{ ...folio, formDebitors: [], selectedDebitorKey: "" }}
                reservation={reservation}
                index={index}
              />
            ))}

            <Box my={1.5}>
              <Divider />
            </Box>
            <TotalPrice
              price={toGross(totalToBePaid)}
              title={tCommon("labels__total_to_be_paid")}
            />

            <Box my={1.5}>
              <Divider />
            </Box>
            {totalToBePaid.grossAmount > 0 ? (
              <SubmitButton
                label={`${tCommon("buttons__pay")} ${formatPriceToString(toGross(totalToBePaid))}`}
                onClick={goToPortalPayment}
                hasWhiteBackground
                hasBottomNavigation={isBottomNavbarActive}
              />
            ) : (
              <SubmitButton
                label={tCommon("buttons__go_to_my_stay")}
                onClick={goToMyStay}
                hasWhiteBackground
                hasBottomNavigation={isBottomNavbarActive}
              />
            )}
            <Box pt={1}>
              <Surcharges />
            </Box>
          </DesktopCard>
        </NotificationBannerWrapper>
      </RoundedFullHeight>
    </>
  );
};
