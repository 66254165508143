import { FullPrice } from "./full-price";
import { MagicServiceCodeEnum } from "./magic-service-code-enum";

export interface OverviewService {
  dates: Array<{
    amount: FullPrice;
    serviceDate: string;
    tags: Array<string>;
  }>;
  service: {
    code: string;
    id: string;
    magicServiceCodeEnum: MagicServiceCodeEnum;
    totalAmount: FullPrice;
    tags: Array<ServiceTag>;
  };
}

export enum ServiceTag {
  PURCHASED = "PURCHASED",
  INCLUDED = "INCLUDED",
  MANDATORY = "MANDATORY",
  NOT_OPENED = "NOT_OPENED",
  OPENED = "OPENED",
  KEY = "KEY"
}
