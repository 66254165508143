import { FC } from "react";
import { KeysSpeedDialApi } from "./keys-speed-dial-api";
import { KeySpeedDialMobile } from "./key-speed-dial-mobile";
import { KeySpeedDialOffline } from "./key-speed-dial-offline";
import { Reservation } from "../../../domain-common/reservation";
import { DoorProvider, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";

export const KeysSpeedDial: FC<React.PropsWithChildren<{ reservation: Reservation }>> = ({
  reservation
}) => {
  const config = useDoorProviderConfig(reservation.propertyId);

  switch (config?.doorProvider) {
    case DoorProvider.SALTO_SPACE_MOBILE_KEY:
    case DoorProvider.AA_VISIONLINE:
    case DoorProvider.AA_VOSTIO:
      return <KeySpeedDialMobile reservation={reservation} />;
    case DoorProvider.OFFLINE_PIN:
    case DoorProvider.BURGWACHTER_LEGACY:
      return <KeySpeedDialOffline reservation={reservation} />;
    default:
      return <KeysSpeedDialApi />;
  }
};
