import { Box, Grid, keyframes } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { useCMSData } from "../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../state/common-cms/common-cms.slice";
import { Button, Heading2, Paragraph, TenantContext } from "@likemagic-tech/sv-magic-library";
import React, { useCallback, useContext, useEffect } from "react";
import { deleteAllCookies, isCookiesConsentAsked, setCookiesAccepted } from "../../util/cookies";
import { useSelector } from "react-redux";
import { selectIsDialogOpen, toggleCookiesPopup } from "./cookie.slice";
import { useAppDispatch } from "../../state/store";
import { useSpinners } from "../loaders/use-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import { generatePrivacyPolicyPageUrl } from "../../util/routing";
import { initGTM } from "../gtm/use-gtm";
import { isKioskMode } from "../../util/kiosk-mode";
import { useFeatureFlags } from "../../util/hooks/use-configuration";
import { isSecondScreenMode } from "../../util/second-screen-mode";

const useStyles = makeStyles()((theme) => ({
  cookieContainer: {
    position: "fixed",
    bottom: "-41%",
    background: theme.palette.grey[100],
    borderTopLeftRadius: theme.shape.borderRadius === 0 ? 0 : theme.spacing(2),
    borderTopRightRadius: theme.shape.borderRadius === 0 ? 0 : theme.spacing(2),
    animation: `${keyframes`    
    from {
      bottom: -41%;
    }
    to {
      bottom: 0;
    }`} 1s linear forwards`,
    zIndex: 1200,
    boxShadow: "0px -8px 16px rgba(0, 0, 0, 0.25)",
    minHeight: "40%",
    [`@media (min-width:500px)`]: {
      maxWidth: theme.spacing(50),
      left: `calc(50vw - ${theme.spacing(25)})`
    }
  },
  cookieImage: {
    width: "20%",
    height: "20%",
    "& > img": {
      width: "100%",
      height: "auto"
    }
  },
  privacyPolicy: {
    textDecoration: "underline",
    color: theme.palette.grey[600],
    cursor: "pointer"
  },
  buttonContainer: {
    "& > button": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  }
}));

const PRIVACY_POLICY_PAGE = generatePrivacyPolicyPageUrl();

export const CookiePopup = () => {
  const { classes } = useStyles();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const isPopupOpen = useSelector(selectIsDialogOpen);
  const dispatch = useAppDispatch();
  const { isLoaderVisible } = useSpinners();
  const { gtmId } = useContext(TenantContext);
  const { cookiesEnabled } = useFeatureFlags();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      !isKioskMode() &&
      !isSecondScreenMode() &&
      !isPopupOpen &&
      !isCookiesConsentAsked() &&
      !isLoaderVisible &&
      pathname !== PRIVACY_POLICY_PAGE &&
      cookiesEnabled
    ) {
      dispatch(toggleCookiesPopup());
    }
  }, [dispatch, isPopupOpen, isLoaderVisible, cookiesEnabled, pathname]);

  useEffect(() => {
    if (!isLoaderVisible && gtmId && cookiesEnabled && (isSecondScreenMode() || isKioskMode())) {
      initGTM(gtmId);
    }
  }, [cookiesEnabled, gtmId, isLoaderVisible]);

  const toggleCookieConsent = useCallback(
    (consent: boolean) => {
      setCookiesAccepted(consent);
      if (gtmId && consent) {
        initGTM(gtmId);
      } else if (!consent) {
        deleteAllCookies();
      }
      dispatch(toggleCookiesPopup());
    },
    [dispatch, gtmId]
  );

  const goToPrivacyPage = useCallback(() => {
    dispatch(toggleCookiesPopup());
    navigate(PRIVACY_POLICY_PAGE);
  }, [navigate, dispatch]);

  return isPopupOpen ? (
    <Grid
      container
      className={classes.cookieContainer}
      justifyContent="space-around"
      direction="column"
      alignItems="center"
      p={1}
    >
      <Box className={classes.cookieImage}>
        <img src={cmsData?.data?.icons__cookie?.url} alt={cmsData?.data?.icons__cookie?.alt} />
      </Box>
      <Heading2>{t("title__cookie")}</Heading2>
      <Paragraph textAlign="center">{t("subtitle__cookie")}</Paragraph>
      <Paragraph className={classes.privacyPolicy} onClick={goToPrivacyPage}>
        {t("labels__privacy_policy")}
      </Paragraph>
      <Grid
        container
        justifyContent="space-around"
        flexWrap="nowrap"
        className={classes.buttonContainer}
      >
        <Button variant="secondary" fullWidth onClick={() => toggleCookieConsent(false)}>
          {t("buttons_decline")}
        </Button>
        <Button variant="primary" fullWidth onClick={() => toggleCookieConsent(true)}>
          {t("buttons__accept")}
        </Button>
      </Grid>
    </Grid>
  ) : null;
};
