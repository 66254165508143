import { CssBaseline } from "@mui/material";
import React, { PropsWithChildren, Suspense, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import { DetectOffline } from "../components/molecules/offline-view";
import { AppModeDetect } from "../core/app-mode-detect";
import { InitI18n } from "../core/init-i18n";
import { Manifest } from "../core/manifest";
import ScrollToTop from "../core/scroll-to-top";
import { PwaProvider } from "../features/pwa/pwa-context";
import { ServiceWorkerUpdater } from "../features/pwa/service-worker-updater";
import { GtmPathnameChangeListener } from "../features/gtm/gtm-pathname-change-listener";
import { AppLoaders } from "../features/loaders/app-loaders";
import { SuspenseFallback } from "../features/loaders/suspense-loader";
import { DesktopDeviceHint } from "../core/desktop-device-hint";
import { HelmetProvider } from "react-helmet-async";
import {
  Application,
  AuthProvider,
  CheckKeycloakInstance,
  GlobalModalProvider,
  TenantProvider
} from "@likemagic-tech/sv-magic-library";
import { initGTM } from "../features/gtm/use-gtm";
import { FaviconSetter } from "../components/atoms/favicon/favicon-setter";
import { KioskIdle } from "../features/kiosk-idle/kiosk-idle";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { CookiePopup } from "../features/cookie-popup/cookie-popup";
import { LayoutWithBottomAndTopNavbar } from "../components/layouts/layout-with-bottom-and-top-navbar";
import { LanguageSelector } from "../components/organism/language-selector";
import DeepLinkDetector from "../features/deep-link-detector/deep-link-detector";
import { FontLoader } from "../features/font-loader/font-loader";
import { ErrorHandler } from "../components/atoms/error-bounadry/error-handler";
import { MetaUpdate } from "../util/meta-update";
import { DataPreloader } from "../features/data-preloader/data-preloader";
import { GraphqlTenantHeadersSetup } from "../graphql/graphql-tenant-headers-setup";
import { CmsClientProvider } from "../api/cms-client/cms-client-provider";
import { initLogger } from "../logger/logger-provider";
import { InjectContextTenantIntoRedux } from "../api/inject-context-tenant-into-redux";
import { ThemeProvider } from "./themes/theme-provider";
import { LocalizationProvider } from "@mui/lab";
import { ErrorBoundary } from "react-error-boundary";
import { Capacitor } from "@capacitor/core";
import { NativeAppSuccessLogin } from "../features/native-app/native-app-success-login";
import { AppProviderErrorHandler } from "./app-provider-error-handler";

interface AppProvidersProps {}
export const AppProviders: React.FC<PropsWithChildren<AppProvidersProps>> = ({ children }) => {
  const onLogin = useCallback(() => {
    if (Capacitor.isNativePlatform()) {
      setTimeout(() => {
        window.reloadCapacitorOnLogin?.();
      }, 200);
    }
  }, []);

  return (
    <ErrorBoundary FallbackComponent={AppProviderErrorHandler}>
      <TenantProvider
        callbackFn={({ gtmId, logLevel }) => {
          initGTM(gtmId!);
          initLogger(logLevel);
        }}
        application={Application.GUEST_JOURNEY}
      >
        <AuthProvider onLoginSuccess={onLogin}>
          <CheckKeycloakInstance>
            <CmsClientProvider>
              <GraphqlTenantHeadersSetup />
              <InjectContextTenantIntoRedux>
                <ThemeProvider>
                  <AppModeDetect />
                  {/*// @ts-ignore*/}
                  <HelmetProvider>
                    <BrowserRouter>
                      <FaviconSetter />
                      <CssBaseline />
                      <FontLoader />
                      <InitI18n>
                        <Suspense fallback={<SuspenseFallback />}>
                          <AppLoaders />
                          <LanguageSelector>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <ErrorHandler>
                                <KioskIdle />
                                <MetaUpdate />
                                <PwaProvider>
                                  <CookiePopup />
                                  <GtmPathnameChangeListener />
                                  <Manifest />
                                  <ScrollToTop />
                                  <DetectOffline />
                                  <ServiceWorkerUpdater />
                                  <DesktopDeviceHint />
                                  <DeepLinkDetector />
                                  <GlobalModalProvider>
                                    <DataPreloader>
                                      <LayoutWithBottomAndTopNavbar>
                                        {children}
                                        <NativeAppSuccessLogin />
                                      </LayoutWithBottomAndTopNavbar>
                                    </DataPreloader>
                                  </GlobalModalProvider>
                                </PwaProvider>
                              </ErrorHandler>
                            </LocalizationProvider>
                          </LanguageSelector>
                        </Suspense>
                      </InitI18n>
                    </BrowserRouter>
                  </HelmetProvider>
                </ThemeProvider>
              </InjectContextTenantIntoRedux>
            </CmsClientProvider>
          </CheckKeycloakInstance>
        </AuthProvider>
      </TenantProvider>
    </ErrorBoundary>
  );
};
