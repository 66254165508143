import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { selectServiceWorkerSlice, updateApplication } from "./pwa.slice";
import { useAppDispatch } from "../../state/store";
import { PwaContext } from "./pwa-context";

export const usePwa = () => {
  const dispatch = useAppDispatch();
  const { serviceWorkerController, installApp } = useContext(PwaContext);
  const { isApplicationUpdating, isNewVersionDetected, showInstallAppButton } =
    useSelector(selectServiceWorkerSlice);

  const installNewVersion = useCallback(() => {
    dispatch(updateApplication());
    serviceWorkerController.skipWaiting();
  }, [dispatch, serviceWorkerController]);

  return {
    serviceWorkerController,
    installNewVersion,
    isApplicationUpdating,
    isNewVersionDetected,
    installApp,
    showInstallAppButton
  };
};
