import { useSelector } from "react-redux";
import {
  payByPaymentAccount,
  selectIsPaymentByAccountSuccessFinished,
  selectPaymentSlice
} from "../payment.slice";
import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../../state/store";

export const usePayWithTokenizedCard = ({
  magicId,
  onSuccessPayment
}: {
  magicId: string;
  onSuccessPayment: () => void;
}) => {
  const dispatch = useAppDispatch();

  const { paymentDTO } = useSelector(selectPaymentSlice);
  const isPaidByAccountSuccess = useSelector(selectIsPaymentByAccountSuccessFinished);

  const payByPaymentAccountOnClick = useCallback(async () => {
    dispatch(payByPaymentAccount({ paymentDTO, magicId }));
  }, [dispatch, paymentDTO, magicId]);

  useEffect(() => {
    if (isPaidByAccountSuccess) {
      onSuccessPayment();
    }
  }, [isPaidByAccountSuccess, onSuccessPayment]);

  return { payByPaymentAccountOnClick };
};
