import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useEffect } from "react";
import { doNotForceHideSpinners, forceHideSpinners } from "../../features/loaders/loader.slice";
import { useMagicIdParams } from "../../features/magic/use-magic-id-params";
import { useCommonForbiddenCmsData } from "../../state/common-cms/use-common-cms-data";
import { useAppDispatch } from "../../state/store";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { SubmitButton } from "../atoms";
import { ErrorWarnSign } from "../icons/error-warn-sign";
import { RoundedFullHeight } from "../layouts/rounded-full-height";
import { useNavbar } from "./top-navbar/navbar-context";
import { useNavigate } from "react-router-dom";
import { useRequestAccess } from "./use-request-access";

interface ForbiddenPageProps {}

const useStyles = makeStyles()((theme) => ({
  logo: {
    maxWidth: theme.spacing(25),
    height: theme.spacing(8.75)
  }
}));

export const ForbiddenPage: React.FC<React.PropsWithChildren<ForbiddenPageProps>> = () => {
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const forbiddenCmsData = useCommonForbiddenCmsData();
  const { setDisplayNavbar, setProgress, setLeftButtons, setRightButtons } = useNavbar();

  React.useEffect(() => {
    dispatch(forceHideSpinners());
    return () => {
      dispatch(doNotForceHideSpinners());
    };
  }, [dispatch]);

  useEffect(() => {
    setDisplayNavbar(true);
    setProgress(0);
  }, [navigate, setDisplayNavbar, setProgress, setLeftButtons, setRightButtons]);

  const onClick = useRequestAccess({ magicId });

  return (
    <>
      <RoundedFullHeight px={2.5}>
        <Box display="flex" flexDirection="row" justifyContent="center" pt={3} pb={2.5}>
          <img
            src={forbiddenCmsData?.["403_error__logo"]?.url}
            alt={forbiddenCmsData?.["403_error__logo"]?.alt}
            className={classes.logo}
          />
        </Box>
        <Paragraph
          variant="subtitle1"
          align="center"
          color="textSecondary"
          style={{ fontSize: 130, marginBottom: 80 }}
        >
          <ErrorWarnSign fontSize="inherit" />
        </Paragraph>

        <Paragraph variant="subtitle1" align="center" color="textSecondary">
          {forbiddenCmsData?.["403_error__description"]}
        </Paragraph>

        <SubmitButton
          label={forbiddenCmsData?.["403_error__button_label"]}
          hasWhiteBackground
          hasBottomNavigation
          onClick={onClick}
        />
      </RoundedFullHeight>
    </>
  );
};
