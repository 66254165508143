import { defaultState, PaymentState } from "./payment.slice";
import { PaymentDTO } from "./payment-dto";

const LOCAL_STORAGE_PAYMENT_STATE_KEY = "payment_state";

function getLocalStorageForAllPaymentStates(): { [key: string]: PaymentState } {
  const paymentString = localStorage.getItem(LOCAL_STORAGE_PAYMENT_STATE_KEY);
  if (!paymentString) {
    return {};
  }

  try {
    const parsed = JSON.parse(paymentString);
    if (typeof parsed !== "object") {
      return {};
    }
    return parsed;
  } catch (e) {
    return {};
  }
}

export const getLocalStoragePaymentState = (magicId: string): PaymentState | undefined =>
  getLocalStorageForAllPaymentStates()[magicId];

export function persistToLocalStoragePaymentState(
  magicId: string,
  paymentDTO: PaymentDTO,
  referenceId?: string
) {
  const localStorageState = getLocalStorageForAllPaymentStates();
  localStorageState[magicId] = {
    ...defaultState,
    paymentDTO,
    referenceId
  };

  try {
    localStorage.setItem(LOCAL_STORAGE_PAYMENT_STATE_KEY, JSON.stringify(localStorageState));
  } catch (e) {
    console.error("Persist to localstorage failed");
  }
}
