import { ShopCartItem } from "../../../domain-common/shop-cart-item";
import { getLocalStorageObject } from "../../../state/local-storage-state";

const LOCAL_STORAGE_CART_KEY = "magic_cart";

export const getLocalStorageCartItems = (magicId: string): ShopCartItem[] => {
  const localStorageCarts = getLocalStorageObject(LOCAL_STORAGE_CART_KEY);
  const cartItems = localStorageCarts[magicId];
  if (!cartItems || !Array.isArray(cartItems)) {
    return [];
  }
  return cartItems;
};

export function persistToLocalStorageCartLineItem(magicId: string, cartLineItems: ShopCartItem[]) {
  const carts = getLocalStorageObject(LOCAL_STORAGE_CART_KEY);
  carts[magicId] = cartLineItems;
  try {
    localStorage.setItem(LOCAL_STORAGE_CART_KEY, JSON.stringify(carts));
  } catch (e) {}
}
