import { makeStyles } from "tss-react/mui";

export const useKioskStyle = makeStyles()(({ palette, shape }) => ({
  icon: {
    width: "100px",
    height: "100px"
  },
  container: {
    wordBreak: "break-word"
  },
  kioskOption: {
    background: palette.grey[200],
    borderRadius: shape.borderRadius,
    minHeight: 312
  },
  qrText: {
    overflowWrap: "break-word",
    width: "70%",
    textAlign: "center",
    margin: "0 auto 10px auto"
  }
}));
