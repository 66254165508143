import { Box, Grid, RadioGroup } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DisplayCmsSvg, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { isReservation, isTravelBuddy } from "../../../util/flow";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { computeLanguage } from "../../../util/hooks/use-language";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import {
  Editability,
  useGuestDataEditable
} from "../../booking-overview/hooks/use-guest-data-editable";
import { EditableNotification } from "../../../components/atoms/notification/editable-notification";
import { ProfileTemplate } from "../components/profile-template";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useAllowedLocales } from "../../../util/hooks/use-configuration";
import { SubUpdate } from "../../reservation-provider/sub-update";
import i18n from "i18next";
import { useRefetchUserProfile } from "../../user-profile/use-refetch-user-profile";
import { generatePortalProfileUrl } from "../../../util/routing";
import { generateFastGuestFlowLink } from "../../guest-flow/pages/fast-guest-flow.page";
import { ParagraphBold, SmallPaperRadio } from "@likemagic-tech/sv-magic-library";

interface ProfileLanguagePageProps {}

export const ProfileLanguagePage: React.FC<
  React.PropsWithChildren<ProfileLanguagePageProps>
> = () => {
  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const allowedLocales = useAllowedLocales();
  const refetchUserProfile = useRefetchUserProfile();
  const { patchGuestFlowStep } = useGuestFlow();
  const location = useLocation();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  useSetupSubpageNavigation(
    location.state?.fastCheckin ? generateFastGuestFlowLink : generatePortalProfileUrl
  );

  const handleSubmit = React.useCallback(
    async (values: { language: string }) => {
      try {
        if (isTravelBuddy(reservation)) {
          await patchGuestFlowStep({
            reservationValues: {
              ...reservation,
              travelBuddy: {
                ...reservation.travelBuddy,
                preferredLocale: values.language
              }
            },
            subUpdate: SubUpdate.PERSONAL_DATA
          });
        }
        if (isReservation(reservation)) {
          await patchGuestFlowStep({
            reservationValues: {
              ...reservation!,
              primaryGuest: {
                ...reservation!.primaryGuest,
                preferredLocale: values.language
              }
            },
            subUpdate: SubUpdate.PERSONAL_DATA
          });
        }
        document.documentElement.lang = values.language;
        refetchUserProfile();
        await i18n.changeLanguage(values.language);
        navigate(-1);
      } catch (e) {
        console.error("An error occurred, profile language", e);
      }
    },
    [patchGuestFlowStep, navigate, reservation, refetchUserProfile]
  );
  const language = useMemo(() => computeLanguage(reservation), [reservation]);

  const { editable } = useGuestDataEditable({
    uneditableStatus: [ReservationStatus.CHECKED_OUT],
    reservation
  });
  if (editable === undefined) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Language" />
      <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
        <ProfileTemplate>
          <PageHeadingInfo
            title={tCommon("title__language_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__language_icon?.url} />}
          />
          <EditableNotification editabilities={[editable]} />
          <Box mx={-0.5}>
            <Formik initialValues={{ language: language || "" }} onSubmit={handleSubmit}>
              {(formik) => (
                <Form>
                  <RadioGroup
                    aria-label="language"
                    name="language"
                    value={formik.values.language}
                    onChange={formik.handleChange}
                  >
                    <Box>
                      <Grid container spacing={2}>
                        {allowedLocales?.map((language) => (
                          <Grid item xs={12} container direction="column" key={language}>
                            <SmallPaperRadio
                              content={
                                <ParagraphBold>
                                  {tCommon(`labels__language_${language}`)}
                                </ParagraphBold>
                              }
                              value={language}
                              disabled={editable !== Editability.EDITABLE}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </RadioGroup>

                  {formik.dirty && (
                    <SubmitButton
                      label={tCommon("buttons__save")}
                      disabled={formik.isSubmitting}
                      hasBottomNavigation
                      hasWhiteBackground
                    />
                  )}
                </Form>
              )}
            </Formik>
          </Box>
        </ProfileTemplate>
      </RoundedFullHeight>
    </>
  );
};
