import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Checkbox, Grid } from "@mui/material";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useAppDispatch } from "../../../../state/store";
import { makeStyles } from "tss-react/mui";
import { SearchBookingPeoplePicker } from "./search-booking-people-picker";
import { toggleSpecialRateModal } from "../../search-available-properties.slice";
import { compareDates, Paragraph } from "@likemagic-tech/sv-magic-library";
import { SearchBookingPropertyFilter } from "./search-booking-property-filter";
import { DateRangePicker } from "../../../../components/molecules/range-date-picker/date-range-picker";
import { useProperties } from "../../../property/use-property-by-id";
import { addDays, addSeconds } from "date-fns";
import startOfDay from "date-fns/startOfDay";
import { utcToZonedTime } from "date-fns-tz";
import { useConfigBasedOnlyOnBaseUrl } from "../../../../util/hooks/use-configuration";
import { parse, toSeconds } from "iso8601-duration";

const useStyles = makeStyles()(({ spacing }) => ({
  datePickerInputs: {
    "& > div": {
      width: "49%"
    }
  },
  checkbox: {
    padding: spacing(0),
    marginRight: spacing(1)
  }
}));

export interface SearchBookingFiltersValues {
  arrival: string;
  departure: string;
  childrenAges: number[];
  adults: number;
  propertyIds: string;
  promoCodePMS?: string;
  promoCodeMagic?: string;
}

export const SearchBookingFilters: FC<
  React.PropsWithChildren<{
    values: SearchBookingFiltersValues;
    onChange: ({
      promoCodeMagic,
      promoCodePMS,
      arrival,
      departure,
      propertyIds,
      adults,
      childrenAges
    }: SearchBookingFiltersValues) => void;
    disablePropertyInput?: boolean;
    resetPromoCode: () => void;
    showOnlyCities?: boolean;
  }>
> = ({ values, onChange, disablePropertyInput, resetPromoCode, showOnlyCities }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const { data: properties } = useProperties();
  const { search: searchConfig } = useConfigBasedOnlyOnBaseUrl();

  const disablePropertyDropDown = useMemo(() => {
    const [firstValue] = Object.values(properties);

    return (
      (Object.values(properties).length === 1 && firstValue && firstValue.length === 1) ||
      (showOnlyCities &&
        Object.values(properties).length === 1 &&
        firstValue &&
        firstValue.length > 1)
    );
  }, [properties, showOnlyCities]);

  const { classes } = useStyles();

  const numberOfProperties = useRef(0);

  useEffect(() => {
    numberOfProperties.current = Object.values(properties).flat().length;
  }, [properties]);

  const updateDates = useCallback(
    (from: string, to: string) => {
      onChange({
        ...values,
        arrival: from,
        departure: to
      });
    },
    [onChange, values]
  );

  const openPromoCodeDialog = useCallback(
    (event: any, checked: boolean) => {
      if (checked) {
        dispatch(toggleSpecialRateModal());
      } else {
        resetPromoCode();
      }
    },
    [dispatch, resetPromoCode]
  );

  const onPropertyChange = useCallback(
    (value: string) => {
      return onChange({ ...values, propertyIds: value });
    },
    [values, onChange]
  );

  /**
   * Calculate the time zone of a property.
   *
   * @param {Object} properties - Object containing a list of properties.
   * @param {string} values.propertyIds - Comma-separated string of property IDs.
   *
   * @returns {string} The time zone of the first property in the given list, or the default
   * time zone if no properties are found or the first property has no time zone defined.
   */
  const calculateTimeZoneOfAProperty = useMemo(() => {
    const firstPropertyId = values.propertyIds.split(",")?.[0];

    const listOfProperties = Object.values(properties).flatMap((item) => item);

    return (
      listOfProperties.find((item) => item.propertyId === firstPropertyId)?.timeZone ??
      listOfProperties[0]?.timeZone ??
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  }, [properties, values.propertyIds]);

  const lastSelectableDate = useMemo(() => {
    const propertyDate = utcToZonedTime(new Date(), calculateTimeZoneOfAProperty);

    const seconds = searchConfig?.deltaEndOfDayProcessing
      ? toSeconds(parse(searchConfig?.deltaEndOfDayProcessing))
      : 0;
    const todayMidnightWithOffset = addSeconds(startOfDay(propertyDate), seconds);

    const allowYesterday =
      compareDates(propertyDate.toISOString(), todayMidnightWithOffset.toISOString()) < 0;
    return allowYesterday ? addDays(propertyDate, -1) : propertyDate;
  }, [searchConfig?.deltaEndOfDayProcessing, calculateTimeZoneOfAProperty]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} md={4}>
        <SearchBookingPropertyFilter
          disabled={disablePropertyInput || disablePropertyDropDown}
          properties={properties}
          value={values.propertyIds}
          onChange={(event) => onPropertyChange(event.target.value)}
          showOnlyCities={showOnlyCities}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <DateRangePicker
          arrival={values.arrival}
          departure={values.departure}
          onSubmit={updateDates}
          minDate={lastSelectableDate}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <SearchBookingPeoplePicker
          adults={values.adults}
          childrenAges={values.childrenAges}
          onChange={({ adults, childrenAges }) => {
            onChange({
              ...values,
              adults,
              childrenAges
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container flexDirection="row" alignItems="center">
          <Checkbox
            className={classes.checkbox}
            value={!!(values.promoCodePMS || values.promoCodeMagic)}
            checked={!!(values.promoCodePMS || values.promoCodeMagic)}
            onChange={openPromoCodeDialog}
          />
          <Paragraph>{t("labels__have_special_rate")}</Paragraph>
        </Grid>
      </Grid>
    </Grid>
  );
};
