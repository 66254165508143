import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DisplayCmsSvg, Notification, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { LegalForm, prepareLegalForm } from "../../../components/molecules/legal-form";
import { LegalFormData } from "../../../domain-common/legal-form-data";
import { MagicFileType } from "../../../domain-common/magic-file-type";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useCommonValidationCmsData } from "../../../state/common-cms/use-common-cms-data";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { TenantContext, useApiVersion, useAuth } from "@likemagic-tech/sv-magic-library";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { useAppDispatch } from "../../../state/store";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import {
  Editability,
  useGuestDataEditable
} from "../../booking-overview/hooks/use-guest-data-editable";
import { EditableNotification } from "../../../components/atoms/notification/editable-notification";
import { ProfileTemplate } from "../components/profile-template";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useDisabledCountriesForIdentification } from "../../../util/hooks/use-configuration";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { isDisabledCountry } from "../../../util/countries";
import { IdCheckStatus, restrictiveIdStatuses } from "../../../domain-common/id-check-status";
import {
  emptyReservationExtras,
  ReservationExtras
} from "../../../domain-common/reservation-extras";
import { generatePortalProfileUrl } from "../../../util/routing";
import { generateFastGuestFlowLink } from "../../guest-flow/pages/fast-guest-flow.page";

interface ProfileLegalPageProps {}

export const ProfileLegalPage: React.FC<React.PropsWithChildren<ProfileLegalPageProps>> = () => {
  const { token } = useAuth();
  const validationCmsData = useCommonValidationCmsData();
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();
  const disabledCountriesForIdentification = useDisabledCountriesForIdentification();
  const location = useLocation();

  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const isIdStatusDeclined = useMemo(
    () => reservation.idCheckStatus === IdCheckStatus.DECLINED,
    [reservation.idCheckStatus]
  );

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { apiKey } = useContext(TenantContext);

  const { patchGuestFlowStep, prepareLegalFiles } = useGuestFlow();
  const { legalDataStepValidation } = useFormValidations();

  useSetupSubpageNavigation(
    location.state?.fastCheckin ? generateFastGuestFlowLink : generatePortalProfileUrl
  );

  const [initialValues, setInitialValues] = useState<LegalFormData | null>(null);

  useEffect(() => {
    if (!reservation || !!initialValues) {
      return;
    }
    prepareLegalForm({
      reservation,
      isRESTVersion,
      isIdStatusDeclined,
      apiKey,
      authToken: token
    }).then(setInitialValues);
  }, [
    reservation,
    initialValues,
    token,
    dispatch,
    isRESTVersion,
    disabledCountriesForIdentification,
    isIdStatusDeclined,
    apiKey
  ]);

  const handleSubmit = React.useCallback(
    async (values: LegalFormData) => {
      try {
        const { signatureImage, identificationImage } = values;
        const files = await prepareLegalFiles(signatureImage, identificationImage, initialValues);
        const emptyExtras: ReservationExtras = emptyReservationExtras();

        const valuesToPatch = {
          ...reservation,
          primaryGuest: {
            ...reservation.primaryGuest,
            birthdate: !!values.primaryGuest?.birthdate
              ? values.primaryGuest?.birthdate
              : undefined,
            identificationNumber: isDisabledCountry(
              disabledCountriesForIdentification,
              values.primaryGuest.nationalityCountryCode
            )
              ? ""
              : values.primaryGuest.identificationNumber!,
            nationalityCountryCode: values.primaryGuest.nationalityCountryCode!
          },
          extras: {
            ...emptyExtras,
            ...reservation.extras,
            ...{ tcGeneral: values.acceptedTerms, tcOnline: values.acceptedTerms }
          }
        };

        await patchGuestFlowStep({
          reservationValues: valuesToPatch,
          files: files,
          subUpdate: SubUpdate.LEGAL
        });

        navigate(-1);
      } catch (e) {
        console.error("Update legal data [portal] went wrong", e);
      }
    },
    [
      navigate,
      patchGuestFlowStep,
      prepareLegalFiles,
      reservation,
      initialValues,
      disabledCountriesForIdentification
    ]
  );

  const uploadedIdentificationImage = React.useMemo(
    () =>
      reservation?.files.find(
        (file) => file.metaData.magicFileType === MagicFileType.IDENTIFICATION_DOCUMENT
      ),
    [reservation]
  );

  const uploadedSignatureImage = React.useMemo(
    () =>
      reservation?.files.find(
        (file) => file.metaData.magicFileType === MagicFileType.SIGNATURE_DOCUMENT
      ),
    [reservation]
  );

  const { editable } = useGuestDataEditable({
    uneditableStatus: [ReservationStatus.CHECKED_OUT, ReservationStatus.IN_HOUSE],
    reservation
  });

  const computedEditable = useMemo(() => {
    if (isIdStatusDeclined) {
      return Editability.EDITABLE;
    } else if (reservation.idCheckStatus === IdCheckStatus.REUPLOADED) {
      return Editability.NOT_EDITABLE_REUPLOADED;
    } else if (reservation.idCheckStatus === IdCheckStatus.CONFIRMED) {
      return Editability.NOT_EDITABLE_ID_STATUS_CONFIRMED;
    } else {
      return editable;
    }
  }, [editable, reservation.idCheckStatus, isIdStatusDeclined]);

  if (editable === undefined) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Legal" />
      <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
        <ProfileTemplate>
          <PageHeadingInfo
            title={tCommon("title__legal_page")}
            subtitle={tCommon("subtitle__legal_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__legal_icon?.url} />}
          />
          <EditableNotification editabilities={[computedEditable]} />
          {restrictiveIdStatuses.includes(reservation.idCheckStatus) && (
            <Box py={1}>
              <Notification
                type="warning"
                title={tCommon(
                  IdCheckStatus.DECLINED === reservation.idCheckStatus
                    ? "labels__notification_legal_data_id_check_declined"
                    : "labels__notification_legal_data_id_check_reuploaded"
                )}
              />
            </Box>
          )}
          {initialValues && (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={legalDataStepValidation}
            >
              {(formik) => (
                <Box mt={4}>
                  <Form>
                    <LegalForm
                      values={formik.values}
                      onChange={formik.handleChange}
                      setFieldValue={formik.setFieldValue}
                      touched={formik.touched}
                      errors={formik.errors}
                      uploadedIdentificationImage={uploadedIdentificationImage}
                      uploadedSignatureImage={uploadedSignatureImage}
                      labels={{
                        dateOfBirth: tCommon("labels__birthdate"),
                        nationality: tCommon("labels__nationality"),
                        identificationNumber: tCommon("labels__identification_number")
                      }}
                      identificationUploadErrorLabels={{
                        "file-too-large": tCommon("validation__file_too_large"),
                        "file-invalid-type": tCommon("validation__invalid_file_type"),
                        "too-many-files": tCommon("validation__too_many_files")
                      }}
                      identificationUploadLabels={{
                        required: tCommon("validation__required"),
                        deleted: tCommon("labels__passport_deleted_text"),
                        uploadButton: tCommon("buttons__upload"),
                        uploadFromPhone: tCommon("labels__scan_passport"),
                        uploadButtonMobile: tCommon("labels__upload_from_mobile")
                      }}
                      signatureUploadLabels={{
                        required: validationCmsData?.validation__signature_required,
                        buttonLabel: tCommon("buttons__signature"),
                        deletedText: tCommon("labels__signature_deleted_text"),
                        dialogTitle: tCommon("modals__signature_dialog_title"),
                        dialogReset: tCommon("buttons__reset"),
                        dialogConfirm: tCommon("buttons__confirm")
                      }}
                      disabled={computedEditable !== Editability.EDITABLE || formik.isSubmitting}
                      isLegalDataDeclined={isIdStatusDeclined}
                    />
                    {formik.dirty && (
                      <SubmitButton
                        label={tCommon("buttons__save")}
                        disabled={formik.isSubmitting}
                        hasBottomNavigation
                        hasWhiteBackground
                      />
                    )}
                  </Form>
                </Box>
              )}
            </Formik>
          )}
        </ProfileTemplate>
      </RoundedFullHeight>
    </>
  );
};
