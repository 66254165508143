import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useContext } from "react";
import { MagicApi } from "../../api/magic.api";
import { useAppDispatch } from "../../state/store";
import { AdditionalServicesPaymentDTO, FolioPaymentDTO, ShopItemsPaymentDTO } from "./payment-dto";
import { preparePayment as preparePaymentAction, setReferenceId } from "./payment.slice";
import { toGross } from "../../domain-common/full-price";
import { getTenantHeaders } from "../../api/custom-headers";
import { TenantContext, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { api as apiGetTotalUnpaidAmount } from "../../graphql/queries/GetTotalUnpaidAmount.generated";
import { transformGrossPriceToFullPrice } from "../../graphql/transform/transform-reservation";
import {
  prepareServicesForPaymentV2,
  prepareShopItemsForPaymentV2
} from "../../graphql/transform/transform-additional-services-availability";
import { generateUUID } from "../../util/data";

interface PreparePaymentArgs {
  shopItems?: ShopItemsPaymentDTO;
  additionalServices?: AdditionalServicesPaymentDTO;
  foliosToBePaid?: FolioPaymentDTO;
}
export const usePreparePayment = (magicId: string) => {
  const dispatch = useAppDispatch();
  const { apiKey, tenantId, keycloak } = useContext(TenantContext);
  const { isRESTVersion } = useApiVersion();

  const getTotalPriceToBePaid = useCallback(
    async (args: PreparePaymentArgs) => {
      if (isRESTVersion) {
        return MagicApi.getTotalPrice(
          { magicId, ...args },
          await getTenantHeaders({
            apiKey: apiKey ?? "",
            tenantId,
            keycloak
          })
        );
      } else {
        const shopItems = args.shopItems?.map(prepareShopItemsForPaymentV2);
        const services = args.additionalServices?.map(prepareServicesForPaymentV2);

        return dispatch(
          apiGetTotalUnpaidAmount.endpoints.GetTotalUnpaidAmount.initiate({
            magicId,
            services: [...(services ?? []), ...(shopItems ?? [])]
          })
        ).then(({ data }) => transformGrossPriceToFullPrice(data?.GetTotalUnpaidAmount));
      }
    },
    [dispatch, isRESTVersion, apiKey, tenantId, keycloak, magicId]
  );

  const preparePayment = useCallback(
    async (param: PreparePaymentArgs) => {
      const fullPrice = await getTotalPriceToBePaid(param);
      if (fullPrice) {
        const adyenPrice = toGross(fullPrice);

        dispatch(setReferenceId(generateUUID()));
        return unwrapResult(
          dispatch(
            preparePaymentAction({
              adyenPrice,
              magicId,
              ...param
            })
          )
        );
      }
      return Promise.reject();
    },
    [dispatch, getTotalPriceToBePaid, magicId]
  );
  return {
    preparePayment
  };
};
