import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { combineSearchParams } from "../../util/search-param-util";

export interface SearchParamsObject {
  [key: string]: string;
}
export const generateURLSearchParams = (searchParamsObject: SearchParamsObject) => {
  const newParam = new URLSearchParams();

  Object.keys(searchParamsObject).forEach((key: string) => {
    //Falsy values save as empty string to avoid "null" | "undefined" values
    newParam.set(key, searchParamsObject[key] ? searchParamsObject[key] : "");
  });

  return newParam;
};
export const useSearchParams = ({
  initObject,
  replacePath
}: {
  initObject?: SearchParamsObject;
  replacePath?: boolean;
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const params = useMemo(() => {
    const searchParamState = new URLSearchParams(search);
    const filters: SearchParamsObject = {};
    searchParamState.forEach((value, key) => {
      filters[key] = value;
    });

    return Object.keys(filters).length ? filters : initObject ?? {};
  }, [search, initObject]);

  const setParams = useCallback(
    (newSearchOfferParamsDTO: { [key: string]: string }) => {
      const newSearchParams = generateURLSearchParams(newSearchOfferParamsDTO);
      const oldSearchParam = new URLSearchParams(search);

      if (search !== "?".concat(newSearchParams.toString())) {
        const newSearch = {
          search: "?".concat(combineSearchParams(oldSearchParam, newSearchParams).toString())
        };
        navigate(newSearch, { replace: replacePath });
      }
    },
    [navigate, search, replacePath]
  );

  return {
    params,
    setParams
  };
};
