import { ProfilePagesEnum } from "../../../features/portal/profile-page-to-path";
import { Reservation } from "../../../domain-common/reservation";
import { capitalizeFirstLetter } from "../../../util/strings";
import { GuestFlowCheckpoint } from "../../../features/guest-flow/checkpoint/guest-flow-checkpoint";
import { computeLanguage } from "../../../util/hooks/use-language";
import { Person } from "../../../domain-common/person";
import { getI18nSelectedLanguage, getLanguageWithDefault } from "../../../util/lang-utils";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { formatDate } from "@likemagic-tech/sv-magic-library";

export const getStepIcon = (step: ProfilePagesEnum): string => {
  const iconMapping: Partial<Record<ProfilePagesEnum, string>> = {
    [ProfilePagesEnum.PERSONAL]: "icon__personal_data_icon",
    [ProfilePagesEnum.COMMUNICATION]: "icon__preferred_channel_icon",
    [ProfilePagesEnum.LEGAL]: "icon__legal_icon",
    [ProfilePagesEnum.ADDRESS]: "icon__address_icon",
    [ProfilePagesEnum.LANGUAGE]: "icon__language_icon",
    [ProfilePagesEnum.ACCOUNT]: "icon__personal_data_icon"
  };
  return iconMapping[step] || "";
};

export const getStepDetails = (
  reservation: Reservation,
  step: string,
  t: (val: string) => string,
  allowedLanguages: Array<string> | undefined,
  timeZone: string,
  getCountryNameByCode: (code: string) => string,
  isDisabledIdentification?: boolean
): Array<string | boolean | undefined> => {
  switch (step) {
    case ProfilePagesEnum.PERSONAL:
      const personObject: Person = reservation?.primaryGuest || reservation?.travelBuddy;
      const fullName =
        personObject?.firstName && personObject?.lastName
          ? `${personObject?.firstName} ${personObject?.lastName}`
          : undefined;
      return [
        fullName,
        personObject?.email,
        personObject?.phone ? formatPhoneNumberIntl(personObject?.phone) : undefined
      ];

    case ProfilePagesEnum.COMMUNICATION:
      return [
        capitalizeFirstLetter(reservation?.extras?.preferredCommunicationChannel) || undefined
      ];
    case GuestFlowCheckpoint.LEGAL:
      return [
        reservation?.primaryGuest?.birthdate
          ? formatDate(reservation?.primaryGuest?.birthdate, getI18nSelectedLanguage(), timeZone)!
          : undefined,
        getCountryNameByCode(reservation?.primaryGuest?.nationalityCountryCode),
        isDisabledIdentification ? "" : reservation?.primaryGuest?.identificationNumber,
        reservation.extras?.tcGeneral && reservation.extras.tcOnline
      ];
    case ProfilePagesEnum.ADDRESS:
      const address = reservation?.primaryGuest?.address;
      return [
        address?.addressLine1,
        address?.postalCode && address?.city
          ? `${address?.postalCode} ${address?.city}`
          : undefined,
        getCountryNameByCode(address?.countryCode)
      ];
    case ProfilePagesEnum.LANGUAGE:
      const lang = t(
        "labels__language_" + getLanguageWithDefault(computeLanguage(reservation), allowedLanguages)
      );
      return [lang];
    default:
      return [];
  }
};

export const getItemsTitle = (step: ProfilePagesEnum) => {
  const titleMapping: Partial<Record<ProfilePagesEnum, string>> = {
    [ProfilePagesEnum.PERSONAL]: "navigations__personal_page",
    [ProfilePagesEnum.COMMUNICATION]: "navigations__communication_channel_page",
    [ProfilePagesEnum.LEGAL]: "navigations__legally_required_page",
    [ProfilePagesEnum.ADDRESS]: "navigations__address_page",
    [ProfilePagesEnum.LANGUAGE]: "navigations__language_page",
    [ProfilePagesEnum.ACCOUNT]: "navigations__account_page"
  };

  return titleMapping[step] || "";
};
