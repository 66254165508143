import { BookingOverviewPages } from "./booking-overview-pages";

const bookingReservationsUrl = "/reservations/:bookingOverviewItemId";
const partOfBookingReservationUrl = "/reservation/:reservationMagicId";

export const BookingOverviewPagesToPath = {
  [BookingOverviewPages.PROPERTIES]: "/",
  [BookingOverviewPages.BOOKING_RESERVATIONS]: bookingReservationsUrl,
  [BookingOverviewPages.BOOKING_RESERVATION_DETAILS]: `${partOfBookingReservationUrl}`,
  [BookingOverviewPages.PERSONAL_DATA]: `${partOfBookingReservationUrl}/personal-data`,
  [BookingOverviewPages.CHECKOUT]: `/checkout`,
  [BookingOverviewPages.PAYMENT]: `/payment`,
  [BookingOverviewPages.PAYMENT_REDIRECT]: `/payment-redirect`
};
