import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationComponent, DesktopCard } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generatePortalMyStayUrl } from "../../../util/routing";

import { HelmetTitle } from "../../gtm/helmet-title";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { GuestFlowBackgroundBox } from "../../guest-flow/components/guest-flow-background-box";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";

interface ProfileAccountCreatedPageProps {}

export const ProfileAccountCreatedPage: React.FC<
  React.PropsWithChildren<ProfileAccountCreatedPageProps>
> = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const logoUrl = useLogoUrl();

  const onConfirm = useCallback(() => {
    navigate(generatePortalMyStayUrl(magicId));
  }, [navigate, magicId]);

  return (
    <>
      <HelmetTitle suffix="Account created" />
      <FlowTemplate>
        <DesktopCard>
          <GuestFlowBackgroundBox>
            <ConfirmationComponent
              onConfirm={onConfirm}
              submitButtonLabel={t("buttons__go_to_my_stay")}
              subtitleInfo={t("confirmations__account_created_text")}
              logoSrc={logoUrl}
            />
          </GuestFlowBackgroundBox>
        </DesktopCard>
      </FlowTemplate>
    </>
  );
};
