import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Editability } from "../../../features/booking-overview/hooks/use-guest-data-editable";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { Notification } from "./notification";

export const EditableNotification: React.FC<
  React.PropsWithChildren<{
    editabilities: Editability[];
  }>
> = ({ editabilities }) => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const title = (editabilities: Editability[]) => {
    if ([...new Set(editabilities)].length > 1) {
      return tCommon("labels__guest_data_partially_not_editable");
    }
    // all have the same vales
    switch (editabilities[0]) {
      case Editability.NOT_EDITABLE_NOT_OWNER:
        return tCommon("labels__guest_data_not_editable_protected");
      case Editability.NOT_EDITABLE_NO_SHOW:
        return tCommon("labels__guest_data_not_editable_no_show");
      case Editability.NOT_EDITABLE_CHECKED_OUT:
        return tCommon("labels__guest_data_not_editable_checked_out");
      case Editability.NOT_EDITABLE_IN_HOUSE:
        return tCommon("labels__guest_data_not_editable_in_house");
      case Editability.NOT_EDITABLE_CONFIRMED:
        return tCommon("labels__guest_data_not_editable_confirmed");
      case Editability.NOT_EDITABLE_ID_STATUS_CONFIRMED:
        return tCommon("labels__guest_data_not_editable_id_status_confirmed");

      default:
        //We need to handle default value just in case.
        return tCommon("labels__guest_data_not_editable_confirmed");
    }
  };

  const isEditable = useMemo(
    () =>
      !editabilities ||
      editabilities.length === 0 ||
      editabilities.every((editability) => editability === Editability.EDITABLE),
    [editabilities]
  );

  return !isEditable ? (
    <Box py={1}>
      <Notification type="warning" title={title(editabilities)} />
    </Box>
  ) : (
    <></>
  );
};
