import React, { ChangeEvent, FC } from "react";
import { Grid } from "@mui/material";
import { Checkbox, Paragraph } from "@likemagic-tech/sv-magic-library";

export const LoyaltyProgram: FC<{
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  label: string;
}> = ({ value, onChange, label }) => (
  <Grid container direction="row" alignItems="flex-start" mt={2} sx={{ flexWrap: "nowrap" }}>
    <Checkbox
      name="extras.enrolledInLoyaltyProgram"
      id="extras.enrolledInLoyaltyProgram"
      checked={value}
      onChange={onChange}
      sx={{ paddingLeft: 0, paddingTop: 0 }}
    />
    <Paragraph>{label}</Paragraph>
  </Grid>
);
