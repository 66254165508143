import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UpdatePreferredCommunicationChannelMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  channel?: Types.InputMaybe<Types.CommunicationChannel>;
  flowState: Types.FlowStateUpdateInput;
  person: Types.UpdatePersonInput;
  magicToken: Types.Scalars["String"];
}>;

export type UpdatePreferredCommunicationChannelMutation = {
  __typename?: "Mutation";
  UpdatePreferredCommunicationChannel: { __typename?: "Person"; email?: string | null };
  UpdateFlowState: { __typename: "ReservationFlowState" };
  UpdateMainActor: { __typename: "Person" };
};

export const UpdatePreferredCommunicationChannelDocument = `
    mutation UpdatePreferredCommunicationChannel($magicId: String!, $channel: CommunicationChannel, $flowState: FlowStateUpdateInput!, $person: UpdatePersonInput!, $magicToken: String!) {
  UpdatePreferredCommunicationChannel(
    magicId: $magicId
    channel: $channel
    magicToken: $magicToken
  ) {
    email
  }
  UpdateFlowState(
    flowState: $flowState
    magicId: $magicId
    magicToken: $magicToken
  ) {
    __typename
  }
  UpdateMainActor(magicId: $magicId, person: $person, magicToken: $magicToken) {
    __typename
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UpdatePreferredCommunicationChannel: build.mutation<
      UpdatePreferredCommunicationChannelMutation,
      UpdatePreferredCommunicationChannelMutationVariables
    >({
      query: (variables) => ({ document: UpdatePreferredCommunicationChannelDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdatePreferredCommunicationChannelMutation } = injectedRtkApi;
