export enum GuestFlowCheckpoint {
  PERSONAL_DATA = "PERSONAL_DATA",
  PREFERRED_CHANNEL = "PREFERRED_CHANNEL",
  LEGAL = "LEGAL",
  ADDRESS = "ADDRESS",
  SERVICES = "SERVICES",
  OVERVIEW = "OVERVIEW",
  PAYMENT = "PAYMENT",
  PAYMENT_REDIRECT = "PAYMENT_REDIRECT",
  CONFIRMATION = "CONFIRMATION",
  ASSIGN_STUDIO = "ASSIGN_STUDIO",
  ASSIGN_KEY = "ASSIGN_KEY"
}
