import { FC, useMemo } from "react";
import { DefaultLoader } from "./default-loader";
import { useCommonCmsData } from "../../../state/common-cms/use-common-cms-data";
import { LottieAnimationPlayer } from "../../../features/animations/lottie-animation-player";
import { Backdrop, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLastPropertyId } from "../../../features/restore-magic-context/restore-magic-context.slice";
import { useCmsPropertySpecificData } from "../../../state/cms/use-cms-per-property-data";

export const Loader: FC<
  React.PropsWithChildren<{ visible: boolean; backdropInvisible?: boolean }>
> = ({ visible, backdropInvisible }) => {
  //Animations should have its own document
  const cmsData = useCommonCmsData();
  const lastPropertyId = useSelector(selectLastPropertyId);
  const cmsDataPerProperty = useCmsPropertySpecificData(lastPropertyId);

  const animationUrl = useMemo(() => {
    return (
      cmsDataPerProperty?.data?.animations__spinner?.url ?? cmsData?.data?.animations__spinner?.url
    );
  }, [cmsDataPerProperty?.data?.animations__spinner?.url, cmsData?.data?.animations__spinner?.url]);

  const animationBoxLoader = useMemo(() => {
    return (
      <Box width={backdropInvisible ? "100%" : "60%"} maxWidth={700}>
        <LottieAnimationPlayer url={animationUrl} loop />
      </Box>
    );
  }, [animationUrl, backdropInvisible]);

  if (animationUrl) {
    return (
      <>
        {backdropInvisible ? (
          animationBoxLoader
        ) : (
          <Backdrop
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={visible}
          >
            {animationBoxLoader}
          </Backdrop>
        )}
      </>
    );
  }
  return <DefaultLoader visible={visible} backdropInvisible={backdropInvisible} />;
};
