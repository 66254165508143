import { FC, PropsWithChildren, useContext } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { setupStore } from "../state/store";
import { Provider as ReduxProvider } from "react-redux/es/exports";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorFallbackPage } from "../components/atoms/error-bounadry/error-handler";

export const InjectContextTenantIntoRedux: FC<PropsWithChildren> = ({ children }) => {
  const { apiKey, tenantId, keycloak } = useContext(TenantContext);

  if (apiKey && tenantId && keycloak) {
    const store = setupStore({ apiKey, tenantId, keycloak }, {});
    return (
      <ReduxProvider store={store.store}>
        <PersistGate persistor={store.persistor}>{children}</PersistGate>
      </ReduxProvider>
    );
  } else {
    return (
      <ErrorFallbackPage
        error={{ message: "Fail to init redux." }}
        resetErrorBoundary={() => {}}
        hideBackButton
      />
    );
  }
};
