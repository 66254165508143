import React, { FC, useEffect, useMemo } from "react";
import { useNavbar } from "../../components/organism/top-navbar/navbar-context";
import Carousel from "react-multi-carousel";
import { makeStyles } from "tss-react/mui";
import { useBottomNavbar } from "../../components/organism/bottom-navbar/bottom-navbar-context";
import { useCMSData } from "../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../state/common-cms/common-cms.slice";
import { PrismicImage } from "../../state/property-cms/use-unit-group-cms-data";

const useStyles = makeStyles()(() => ({
  imageContainer: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  innerContainer: {
    width: "100vw",
    height: "100vh"
  },
  item: {
    width: "100vw",
    height: "100vh"
  },
  button: {
    position: "absolute",
    bottom: "10%",
    left: `calc(50% - 250px)`,
    zIndex: "1000",
    width: "500px",
    height: "200px",
    background: "transparent",
    ":hover": {
      background: "transparent"
    }
  },
  buttonTop: {
    position: "absolute",
    top: "10%",
    left: `calc(50% - 250px)`,
    zIndex: "1000",
    width: "500px",
    height: "200px",
    background: "transparent",
    ":hover": {
      background: "transparent"
    }
  }
}));

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export const SecondScreenHomePage: FC = () => {
  const { setDisplaySocialFooter, setDisplayNavbar } = useNavbar();
  const { setDisplayBottomNavbar } = useBottomNavbar();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  useEffect(() => {
    setDisplayNavbar(false);
    setDisplaySocialFooter(false);
    setDisplayBottomNavbar(false);
  }, [setDisplaySocialFooter, setDisplayBottomNavbar, setDisplayNavbar]);

  const { classes } = useStyles();

  const backgroundImages: PrismicImage[] = useMemo(() => {
    return cmsData?.data.second_screen__background_images.map((item: PrismicImage) => {
      return Object.values(item)[0];
    });
  }, [cmsData]);

  return (
    <>
      <Carousel
        responsive={responsive}
        autoPlay={backgroundImages.length !== 1}
        autoPlaySpeed={5000}
        swipeable={false}
        draggable={false}
        keyBoardControl={false}
        infinite={true}
        arrows={false}
        showDots={false}
        containerClass={classes.innerContainer}
        itemClass={classes.item}
      >
        {backgroundImages.map((item, index) => (
          <div className={classes.imageContainer} key={`${item.url}_${index}`}>
            <img className={classes.image} src={item.url} alt={item.alt} />
          </div>
        ))}
      </Carousel>
    </>
  );
};
