import React, { FC, useCallback, useMemo } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { DesktopCard, DisplayCmsSvg } from "../../../components";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { NotFoundPage } from "../../../components/organism/not-found.page";
import { useSetupSubpageNavigation } from "../../portal/hooks/use-setup-subpage-navigation";
import { useServiceDetails } from "../use-service-details";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { BookedServiceButton } from "./booked-service-button";
import { getDisplayServiceDetails, useServicesCmsData } from "../../services/use-services-cms-data";
import { generatePortalMyStayAdditionalBookedServicesDetailsUrl } from "../../../util/routing";
import { Box, Grid } from "@mui/material";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { formatDate, Paragraph } from "@likemagic-tech/sv-magic-library";
import { usePropertyById } from "../../property/use-property-by-id";
import { toGross } from "../../../domain-common/full-price";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { ServiceTag } from "../../../domain-common/overview-service";
import { BookedServiceTag } from "./booked-service-tag";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

export const BookedServiceDatesList: FC<React.PropsWithChildren<unknown>> = () => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  useSetupSubpageNavigation();

  const { serviceId } = useParams<{ serviceId: string }>();
  const isMobile = useIsMobile();
  const { reservation } = useReservationContext();
  const service = useMemo(
    () =>
      reservation?.servicesOverview?.find(
        (service) => service.service.id === serviceId || service.service.code === serviceId
      ),
    [reservation?.servicesOverview, serviceId]
  );
  const { fallbackIcon, serviceIcons } = useServicesCmsData(reservation.propertyId);
  const navigate = useNavigate();

  const property = usePropertyById(reservation?.propertyId);
  //Backwards compatibility
  const { data: dataByServiceId } = useServiceDetails(service?.service.id);
  const { data: dataByServiceCode } = useServiceDetails(service?.service.code);
  const iconUrl = getDisplayServiceDetails(service!, serviceIcons, fallbackIcon);
  const isNextPageAvailable = useMemo(
    () => !!(dataByServiceCode || dataByServiceId),
    [dataByServiceId, dataByServiceCode]
  );
  const onClick = useCallback(
    (serviceDate: string) => () => {
      if (isNextPageAvailable) {
        const id = dataByServiceId ? service?.service?.id : service?.service?.code;
        const isServiceWithKey = service?.service?.tags?.includes(ServiceTag.KEY);

        navigate({
          pathname: generatePortalMyStayAdditionalBookedServicesDetailsUrl(
            reservation.magicId
          ).replace(":serviceId", id!),
          search: isServiceWithKey
            ? createSearchParams({
                serviceDate
              }).toString()
            : ""
        });
      }
    },
    [
      dataByServiceId,
      navigate,
      reservation.magicId,
      service?.service?.id,
      service?.service?.code,
      service?.service?.tags,
      isNextPageAvailable
    ]
  );

  if (!service) {
    return <NotFoundPage />;
  }

  return (
    <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
      <DesktopCard>
        <Box p={3}>
          <PageHeadingInfo
            title={tCommon("title__booked_services_page")}
            icon={<DisplayCmsSvg url={iconUrl} />}
          />
          <Grid container direction="column" display="grid">
            {service.dates.map((item, index) => (
              <BookedServiceButton
                key={`${item.serviceDate}-service-list-button-${index}`}
                isNextPageAvailable={isNextPageAvailable}
                onClick={onClick(item.serviceDate)}
                amount={toGross(item.amount)}
                propertyId={reservation.propertyId}
                service={service.service}
              >
                <Grid container flexWrap="wrap" alignItems="center">
                  <Grid item mr={0.5} xs={12} md="auto">
                    <Paragraph textAlign={isMobile ? "left" : "center"}>
                      {formatDate(item.serviceDate, getI18nSelectedLanguage(), property.timeZone)}
                    </Paragraph>
                  </Grid>

                  <Grid item sx={{ flex: 1 }}>
                    <Grid container spacing={1} alignItems="center">
                      {service.service.tags.map((serviceTag) => (
                        <Grid item key={`${service.service.code}-${serviceTag}`}>
                          <BookedServiceTag tag={serviceTag} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </BookedServiceButton>
            ))}
          </Grid>
        </Box>
      </DesktopCard>
    </RoundedFullHeight>
  );
};
