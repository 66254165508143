import { Box } from "@mui/material";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { responsiveVariables } from "../../../components/layouts/hooks/use-is-mobile";
import { PrismicImage } from "../../../state/property-cms/use-unit-group-cms-data";
import { CarouselGallery } from "../../../components/molecules/carousel-gallery/carousel-gallery";

export interface MyStayHeaderImageRectangleProps {
  items: PrismicImage[];
}

const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  imageWrapper: {
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      margin: spacing(-3),
      marginBottom: 0
    }
  },
  image: {
    width: "100%",
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      width: "100%"
    }
  }
}));

export const MyStayHeaderImageRectangle: FC<MyStayHeaderImageRectangleProps> = ({ items }) => {
  const { classes } = useStyles();

  if (items?.length === 1) {
    return (
      <Box className={classes.imageWrapper}>
        <img alt="unit" className={classes.image} src={items[0].url} />
      </Box>
    );
  } else {
    return (
      <Box className={classes.imageWrapper}>
        <CarouselGallery items={items} autoplay infinite />
      </Box>
    );
  }
};
