import React, { FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { BookingOverviewPagesToPath } from "./booking-overview-checkpoint-to-path";
import { BookingOverviewPages } from "./booking-overview-pages";
import { BookingOverviewReservationsPage } from "./pages/booking-overview-reservations.page";
import { BookingOverviewReservationDetailsPage } from "./pages/booking-overview-reservation-details.page";
import { BookingOverviewCheckoutPage } from "./pages/booking-overview-checkout.page";
import { BookingOverviewPersonalDataPage } from "./pages/booking-overview-personal-data.page";
import { BookingOverviewPaymentPage } from "./pages/booking-overview-payment.page";
import { BookingOverviewPaymentRedirectPage } from "./pages/booking-overview-payment-redirect.page";
import { BookingOverviewSubscriptionProvider } from "./booking-overview-subscription-provider";
import { useMagicIdParams } from "../magic/use-magic-id-params";

export const BookingOverviewItemRouter: FC = () => {
  const { pathname: path } = useLocation();
  const { bookingOverviewItemId } = useMagicIdParams();

  return (
    <BookingOverviewSubscriptionProvider bookingOverviewItemId={bookingOverviewItemId}>
      <Routes>
        <Route path={"/"} element={<BookingOverviewReservationsPage />} />
        <Route
          path={BookingOverviewPagesToPath[BookingOverviewPages.BOOKING_RESERVATION_DETAILS]}
          element={<BookingOverviewReservationDetailsPage />}
        />

        <Route
          path={BookingOverviewPagesToPath[BookingOverviewPages.CHECKOUT]}
          element={<BookingOverviewCheckoutPage />}
        />

        <Route
          path={BookingOverviewPagesToPath[BookingOverviewPages.PERSONAL_DATA]}
          element={<BookingOverviewPersonalDataPage />}
        />
        <Route
          path={BookingOverviewPagesToPath[BookingOverviewPages.PAYMENT]}
          element={<BookingOverviewPaymentPage />}
        />
        <Route
          path={BookingOverviewPagesToPath[BookingOverviewPages.PAYMENT_REDIRECT]}
          element={<BookingOverviewPaymentRedirectPage path={path} />}
        />
      </Routes>
    </BookingOverviewSubscriptionProvider>
  );
};
