import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { AddressForm } from "../../../components/molecules/address-form/address-form";
import { AddressFormData } from "../../../components/molecules/address-form/address-from-data";
import { useAddressForm } from "../../../components/molecules/address-form/use-address-form";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { useSaveGuestFlowSessionCheckpoint } from "../checkpoint/use-save-guest-flow-session-checkpoint";
import { getNextFlowUrl, getPrevFlowUrl } from "../guest-flow-sequence";
import { useGuestFlow } from "../use-guest-flow";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { useSetupGuestFlowNavigation } from "../hooks/use-setup-guest-flow-navigation";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { isReservation } from "../../../util/flow";
import { isKioskMode } from "../../../util/kiosk-mode";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

interface AddressPageProps {}

let CURRENT_PAGE = GuestFlowCheckpoint.ADDRESS;
export const AddressPage: React.FC<React.PropsWithChildren<AddressPageProps>> = () => {
  const navigate = useNavigate();

  const { reservation, patchGuestFlowStep, contactButton, skipButton, getProgress } =
    useGuestFlow();
  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });
  const { isBottomNavbarActive } = useBottomNavbar();
  const { tfeFeatureEnabled } = useFeatureFlags();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  useSaveGuestFlowSessionCheckpoint(reservation!, CURRENT_PAGE);
  const { initialValues, getReservationValuesToPatch, isMainFolioExists } =
    useAddressForm(reservation);

  const handleSubmit = React.useCallback(
    async (values: AddressFormData) => {
      const valuesToPatch = getReservationValuesToPatch(reservation, values);
      try {
        await patchGuestFlowStep({
          reservationValues: valuesToPatch,
          checkpoint: CURRENT_PAGE,
          subUpdate: SubUpdate.ADDRESS
        });

        navigate(getNextFlowUrl(CURRENT_PAGE, valuesToPatch));
      } catch (e) {
        console.error("An error occurred address page, guest flow.", e);
      }
    },
    [patchGuestFlowStep, navigate, reservation, getReservationValuesToPatch]
  );

  const onBack = useCallback(() => {
    navigate(getPrevFlowUrl(CURRENT_PAGE, reservation));
  }, [navigate, reservation]);

  const progress = useMemo(() => getProgress(CURRENT_PAGE), [getProgress]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const { addressFormValidation } = useFormValidations();

  const labels = useMemo(
    () => ({
      addressLine1: tCommon("labels__address"),
      postalCode: tCommon("labels__postal_code"),
      city: tCommon("labels__city"),
      countryCode: tCommon("labels__country"),
      sameAddress: tCommon("labels__billing_address_same_as_main_address"),
      firstName: tCommon("labels__first_name"),
      lastName: tCommon("labels__last_name"),
      companyName: tCommon("labels__company_name"),
      companyTaxId: tCommon("labels__company_tax_id"),
      email: tCommon("labels__email"),
      reference: tCommon("labels__reference")
    }),
    [tCommon]
  );

  const topBarIcons = useMemo(() => {
    if (isReservation(reservation)) {
      if (isKioskMode()) {
        return [skipButton];
      }
      return [contactButton];
    }
    return [];
  }, [reservation, contactButton, skipButton]);

  return (
    <>
      <HelmetTitle suffix="Guest flow address" />

      <FlowTemplate handleBack={onBack} icons={topBarIcons} progress={progress}>
        <GuestFlowBackgroundBox>
          <Box p={2} pt={0}>
            <DesktopCard>
              <PageHeadingInfo
                title={tCommon("title__address_page")}
                subtitle={tCommon("subtitle__address_page")}
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__address_icon?.url} />}
              />
              {initialValues && (
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={!tfeFeatureEnabled ? addressFormValidation : undefined}
                >
                  {(formik) => (
                    <Form>
                      <AddressForm
                        values={formik.values}
                        handleChange={formik.handleChange}
                        errors={formik.errors}
                        touched={formik.touched}
                        isSubmitting={formik.isSubmitting}
                        labels={labels}
                        isMainFolioExists={isMainFolioExists}
                      />
                      <SubmitButton
                        label={formik.dirty ? tCommon("buttons__save") : tCommon("buttons__next")}
                        disabled={formik.isSubmitting}
                        hasWhiteBackground
                        hasBottomNavigation={isBottomNavbarActive}
                      />
                    </Form>
                  )}
                </Formik>
              )}
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
