import { Grid } from "@mui/material";
import React, { FC } from "react";
import { BookingOnBehalfOf } from "../../../domain-common/person";
import { RadioButton, RadioButtonGroup } from "@likemagic-tech/sv-magic-library";

export interface BookerGuestFormProps {
  objectPathPrefix?: string;
  value?: BookingOnBehalfOf;
  disabled: boolean;
  onChange: (bookerType: any) => void;
  labels: {
    booker: string;
    guest: string;
  };
  className?: string;
}

export const BookerGuestForm: FC<React.PropsWithChildren<BookerGuestFormProps>> = ({
  objectPathPrefix,
  value,
  disabled,
  onChange,
  labels,
  className
}) => {
  return (
    <Grid sx={{ mb: 5 }} item xs={12} className={className}>
      <RadioButtonGroup
        name={[objectPathPrefix, `bookingOnBehalfOf`].join("")}
        value={value}
        onChange={onChange}
      >
        <RadioButton value={BookingOnBehalfOf.MYSELF} label={labels?.booker} disabled={disabled} />
        <RadioButton value={BookingOnBehalfOf.OTHERS} label={labels?.guest} disabled={disabled} />
      </RadioButtonGroup>
    </Grid>
  );
};
