import { useEffect } from "react";
import { useAppDispatch } from "../../../state/store";
import { fetchBoxAvailability, selectBoxAvailability } from "../box-shop.slice";
import { useSelector } from "react-redux";

export const useFetchBoxAvailability = ({
  magicId,
  magicToken
}: {
  magicId: string;
  magicToken: string;
}) => {
  const dispatch = useAppDispatch();
  const boxAvailabilities = useSelector(selectBoxAvailability);

  useEffect(() => {
    dispatch(
      fetchBoxAvailability({
        magicId: magicId,
        magicToken: magicToken
      })
    );
  }, [dispatch, magicId, magicToken]);

  return boxAvailabilities;
};
