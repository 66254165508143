import { Box, CircularProgress, Fab } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { EventCard } from "../../components";
import { ArrowRightIcon } from "../../components/icons";
import { RootState, useAppDispatch } from "../../state/store";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import {
  fetchMyStayEvents,
  initDisplayList,
  MyStayEventsState,
  selectAllMyStayEventCards,
  selectSelf as selectMyStayEventsSelf
} from "./my-stay-events.slice";
import { getI18nSelectedLanguage } from "../../util/lang-utils";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { useCmsClient } from "../../api/cms-client/use-cms-client";

interface MyStayEventListProps {
  onEventClick: (eventId: string) => void;
  propertyId: string;
}

const useStyles = makeStyles()((theme) => {
  const space = 2.5;
  const spacePx = theme.spacing(space);
  return {
    list: {
      display: "flex",
      overflowX: "auto",
      scrollSnapType: "x mandatory"
    },
    card: {
      display: "flex",
      paddingLeft: spacePx,
      paddingTop: spacePx,
      paddingBottom: spacePx,
      width: "60%",
      maxWidth: theme.spacing(space + 25),
      flexShrink: 0,
      scrollSnapAlign: "center",

      /* hacky fix for padding at the end of the list */
      "&:last-child": {
        paddingRight: space,
        width: `calc(60% + ${spacePx})`,
        maxWidth: theme.spacing(space + 25)
      }
    },
    nextPageWrapper: {
      padding: theme.spacing(4),
      display: "flex",
      alignItems: "center",
      scrollSnapAlign: "center"
    }
  };
});

export const MyStayEventList: React.FC<React.PropsWithChildren<MyStayEventListProps>> = ({
  onEventClick,
  propertyId
}) => {
  const dispatch = useAppDispatch();
  const events = useSelector(selectAllMyStayEventCards);
  const { pending, page, totalPages } = useSelector<RootState, MyStayEventsState>(
    selectMyStayEventsSelf
  );
  const prismic = useCmsClient();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const hasNextPage = page < totalPages;
  const shouldDisplayNextButton = (pending || hasNextPage) && events.length;

  useEffect(() => {
    dispatch(initDisplayList());
  }, [dispatch]);

  const lang = getI18nSelectedLanguage();

  useEffect(() => {
    const promise = dispatch(
      fetchMyStayEvents({
        prismic: prismic,
        lang,
        propertyId
      })
    );
    return () => {
      promise.abort();
    };
  }, [dispatch, prismic, lang, propertyId]);

  const { classes } = useStyles();

  if (events?.length === 0) {
    return null;
  }

  return (
    <>
      <Box ml={2.5} mt={2.5}>
        <Paragraph color="textSecondary">{t("labels__events")}</Paragraph>
      </Box>
      <div className={classes.list}>
        {events.map((ev) => (
          <EventCard
            key={ev.id}
            title={ev.title}
            description={ev.subtitle}
            coverImage={ev.coverImage}
            date={ev.date}
            classes={{ root: classes.card }}
            onActionClick={() => onEventClick(ev.id)}
          />
        ))}
        {shouldDisplayNextButton && (
          <div className={classes.nextPageWrapper}>
            {pending ? (
              <CircularProgress />
            ) : hasNextPage ? (
              <Fab
                size="medium"
                color="default"
                style={{ backgroundColor: "white" }}
                onClick={() =>
                  dispatch(
                    fetchMyStayEvents({
                      page: page + 1,
                      prismic: prismic,
                      propertyId,
                      lang: getI18nSelectedLanguage()
                    })
                  )
                }
              >
                <ArrowRightIcon />
              </Fab>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};
