import React, { FC, useCallback, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { PaperButton } from "../../../../components";
import { ReservationStatus } from "../../../../domain-common/reservation-status";
import { isCheckInAvailable } from "../../../../util/reservation";
import { Paragraph, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { isTravelBuddy } from "../../../../util/flow";
import { getOkModalArg } from "../../../global-modal/global-modal-util";
import { generatePortalMyStayKeysUrl } from "../../../../util/routing";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { Reservation } from "../../../../domain-common/reservation";
import { useIsRequestKeyAvailableWithOpenBalance } from "../../../../util/hooks/use-is-request-key-available-with-open-balance";
import { useEarliestCheckinOffsetConfig } from "../../../../util/hooks/use-configuration";
import { useNavigate } from "react-router-dom";
import { useCMSData } from "../../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../../state/common-cms/common-cms.slice";
import { useCheckIn } from "../../../guest-flow/hooks/use-checkin";

interface NavigationKeysProps {
  reservation: Reservation;
  title?: string;
  iconUrl?: string;
}

export const NavigationKeys: FC<NavigationKeysProps> = ({ reservation, title, iconUrl }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { open: openModal } = useGlobalModal();
  const accessWithOpenBalance = useIsRequestKeyAvailableWithOpenBalance({ reservation });
  const earliestCheckinOffset = useEarliestCheckinOffsetConfig();
  const navigate = useNavigate();
  const magicId = useMemo(() => reservation.magicId, [reservation.magicId]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const checkin = useCheckIn({ reservation });

  const isEarliestCheckinDateInThePast = useMemo(() => {
    if (reservation.arrival) {
      let earliestCheckinDate = new Date(reservation.arrival);
      earliestCheckinDate.setHours(earliestCheckinDate.getHours() - earliestCheckinOffset);

      let currentDate = new Date();

      return currentDate.getTime() > earliestCheckinDate.getTime();
    }

    return false;
  }, [reservation.arrival, earliestCheckinOffset]);

  const openKeysPage = useCallback(async () => {
    if (
      (reservation.accessibleDoors === null || reservation.accessibleDoors?.length === 0) &&
      reservation.status === ReservationStatus.CONFIRMED &&
      reservation.arrival &&
      !isCheckInAvailable(reservation.arrival, new Date().toUTCString())
    ) {
      openModal({
        modalProps: {
          title: t("modals__keys_not_yet_available_title"),
          content: (
            <Box>
              <Paragraph>
                <span>{t("modals__keys_not_yet_available_content_available_after")}</span>
              </Paragraph>
              <Paragraph>{t("modals__keys_not_yet_available_content_tip")}</Paragraph>
            </Box>
          )
        },
        modalActions: [
          {
            variant: "primary",
            label: t("buttons__ok"),
            result: null
          }
        ]
      });
      return; //Skip redirection to keys page
    } else if (!accessWithOpenBalance && !isTravelBuddy(reservation)) {
      await openModal(
        getOkModalArg(
          t("modals__keys_not_yet_available_title"),
          t("modals__keys_not_available_because_open_balance"),
          t("buttons__ok")
        )
      );
      return; //Skip redirection to keys page
    } else if (
      reservation.status === ReservationStatus.CONFIRMED &&
      isEarliestCheckinDateInThePast &&
      !isTravelBuddy(reservation)
    ) {
      const requestKey = await openModal({
        modalProps: {
          title: t("modals__request_private_key_title"),
          content: <Box>{t("modals__request_private_key_content")}</Box>
        },
        modalActions: [
          {
            variant: "primary",
            label: t("buttons__request_access"),
            result: true
          },
          {
            variant: "secondary",
            label: t("buttons__not_yet"),
            result: false
          }
        ]
      });
      if (requestKey) {
        await checkin();
      }
    }
    navigate(generatePortalMyStayKeysUrl(magicId));
  }, [
    navigate,
    magicId,
    openModal,
    t,
    checkin,
    reservation,
    isEarliestCheckinDateInThePast,
    accessWithOpenBalance
  ]);

  return (
    <Grid item xs={6}>
      <PaperButton
        title={title ?? t("navigations__keys")}
        small
        onClick={openKeysPage}
        iconUrl={iconUrl ?? cmsData?.data?.icons__my_stay_navigation_keys?.url}
      />
    </Grid>
  );
};
