import { BaseApiClient } from "@likemagic-tech/sv-magic-library";
import { MagicFile } from "../domain-common/magic-file";

const BASE_URL = "/api/guest-journey-service/files";

export const convertBlobToBase64 = async (blob: Blob): Promise<string> => {
  // building Promise object by hand since FileReader doesn't support async/Promise syntax
  // "promisify-ing" is not possible as FileReader does not support a callback interface
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("error", reject);
    reader.addEventListener("load", () => {
      resolve(reader.result as string);
    });
    reader.readAsDataURL(blob);
  });
};

class FilesApiClient extends BaseApiClient {
  async getMagicFile(file: MagicFile, apiKey?: string, authToken?: string): Promise<Blob> {
    const encodedFileName = encodeURIComponent(file.fileName);
    const response: Response = await this.fetchApi(`${BASE_URL}/${encodedFileName}`, {
      headers: {
        accept: "*/*",
        "sk-magic-api-key": apiKey ?? "",
        Authorization: `Bearer ${authToken}`
      }
    });
    return await response.blob();
  }

  async getMagicFileBase64(file: MagicFile, apiKey?: string, authToken?: string): Promise<string> {
    const blob = await this.getMagicFile(file, apiKey, authToken);
    return convertBlobToBase64(blob);
  }
}

export const FilesApi = new FilesApiClient();
