import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import fetchIntercept from "fetch-intercept";
import { getSecondScreenId, isSecondScreenMode } from "../../util/second-screen-mode";
import { useIdleTimer } from "react-idle-timer";
import { useLocation, useNavigate } from "react-router-dom";
import { useSecondScreenReservation } from "../second-screen-home-page/hooks/useSecondScreenReservation";
import { SecondScreenEventType } from "../../graphql/generated/graphql";
import { useSubscribeSecondScreenDeviceEvents } from "../web-socket/use-subscribe-second-screen-device-events";
import { useSecondScreenAction } from "../second-screen-checkin/hooks/use-second-screen-action";

const LANDING_PAGE = "/";
const TIMEOUT_TIME = 1000 * 60 * 10; // 10 minutes

export const SecondScreenProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const deviceId = useMemo(() => {
    return getSecondScreenId();
  }, []);
  const navigate = useNavigate();
  const { subscribeAction, unsubscribeAction } = useSubscribeSecondScreenDeviceEvents();

  const { secondScreenAction } = useSecondScreenAction();
  const { setSecondScreenReservation, resetSecondScreenReservation } = useSecondScreenReservation();

  const { getRemainingTime } = useIdleTimer({
    timeout: TIMEOUT_TIME,
    onIdle: () => {
      if (isSecondScreenMode() && getRemainingTime() <= 0 && location.pathname !== LANDING_PAGE) {
        resetSecondScreenReservation();
        navigate("/");
      }
    },
    debounce: 500
  });

  useEffect(() => {
    if (isSecondScreenMode()) {
      fetchIntercept.register({
        request: function (url, config) {
          if (url.indexOf("/") !== 0) {
            return [url, config]; // This is not relative link - reaches outside world
          }
          return [
            url,
            {
              ...config,
              headers: {
                ...config.headers,
                ...(getSecondScreenId() && {
                  "sk-magic-second-screen-id": getSecondScreenId()
                })
              }
            }
          ];
        }
      });
    }
  }, []);

  const handleResponse = useCallback(
    (response: { errors?: Array<{ message: string }>; data: any }) => {
      try {
        if (response.data.StreamSecondScreenDeviceEvents.type === SecondScreenEventType.Canceled) {
          resetSecondScreenReservation();
          navigate("/");
        }

        // VerifyAndSign
        if (
          response.data.StreamSecondScreenDeviceEvents.type ===
            SecondScreenEventType.VerifyAndSign &&
          response.data.StreamSecondScreenDeviceEvents.payload.reservation.magicId &&
          response.data.StreamSecondScreenDeviceEvents.payload.reservation.magicToken
        ) {
          secondScreenAction(
            SecondScreenEventType.VerifyAndSignAck,
            response.data.StreamSecondScreenDeviceEvents.payload.reservation.magicId,
            response.data.StreamSecondScreenDeviceEvents.payload.reservation.magicToken,
            {
              accepted: true,
              reason: "accepted"
            }
          );
          setSecondScreenReservation(
            response.data.StreamSecondScreenDeviceEvents.payload.reservation
          );
          navigate("/checkin");
        }

        // VerifyBill
        if (
          response.data.StreamSecondScreenDeviceEvents.type === SecondScreenEventType.VerifyBill &&
          response.data.StreamSecondScreenDeviceEvents.payload.reservation.magicId &&
          response.data.StreamSecondScreenDeviceEvents.payload.reservation.magicToken
        ) {
          secondScreenAction(
            SecondScreenEventType.VerifyBillAck,
            response.data.StreamSecondScreenDeviceEvents.payload.reservation.magicId,
            response.data.StreamSecondScreenDeviceEvents.payload.reservation.magicToken,
            {
              accepted: true,
              reason: "accepted"
            }
          );
          setSecondScreenReservation(
            response.data.StreamSecondScreenDeviceEvents.payload.reservation
          );
          navigate("/check-bill");
        }

        if (response.data.StreamSecondScreenDeviceEvents.type === SecondScreenEventType.Signed) {
          navigate(`/`);
          resetSecondScreenReservation();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [navigate, secondScreenAction, resetSecondScreenReservation, setSecondScreenReservation]
  );

  useEffect(() => {
    if (isSecondScreenMode() && deviceId) {
      //It on each re-render cache of RTK will save us from additional WS connection
      //Since we need only one connection this mechanism will work just fine.
      subscribeAction({
        deviceId,
        handleResponse
      });
    }
  }, [deviceId, subscribeAction, unsubscribeAction, handleResponse]);

  return <>{children}</>;
};
