import React, { FC, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { generateSearchBookingPagesUrl } from "../search-booking-navigation";
import { SearchBookingPages } from "../search-booking-pages";
import { generateURLSearchParams } from "../hooks/use-search-offer-params";
import { SearchPropertyCard } from "./search-property-card";
import { NoOffersAvailable } from "./no-offers-available";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { FixMapWrapper, MapPreview } from "../../../components/molecules/map-preview";
import { selectIsAnyPropertyLoading } from "../../../state/property-cms/property-cms.slice";
import { useNavigate } from "react-router-dom";
import { listOfProperties } from "../../property/properties-by-city";
import { SearchBookingFiltersValues } from "./filters/search-booking-filters";
import { useSearchAvailableProperties } from "../hooks/use-search-available-properties";
import { useProperties } from "../../property/use-property-by-id";
import { selectIsSearchAvailablePropertiesLoading } from "../search-available-properties.slice";

interface SearchBookingPropertiesProps {
  searchValues: SearchBookingFiltersValues;
}
export const SearchBookingProperties: FC<React.PropsWithChildren<SearchBookingPropertiesProps>> = ({
  searchValues
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { data: properties } = useProperties();
  const allProperties = listOfProperties(properties);

  const cmsPropertySpecificDocumentsIsLoading = useSelector(selectIsAnyPropertyLoading);
  const isSearchAvailablePropertiesLoading = useSelector(selectIsSearchAvailablePropertiesLoading);

  const { properties: filteredProperties } = useSearchAvailableProperties({
    searchValues
  });

  const goToOffer = useCallback(
    (propertyId: string) => {
      const searchParams = generateURLSearchParams({
        arrival: searchValues.arrival,
        departure: searchValues.departure,
        adults: searchValues.adults,
        childrenAges: searchValues.childrenAges,
        promoCodePMS: searchValues.promoCodePMS,
        promoCodeMagic: searchValues.promoCodeMagic,
        propertyIds: propertyId //This is a list of one property
      });

      navigate({
        pathname: generateSearchBookingPagesUrl(SearchBookingPages.SEARCH_AVAILABLE_UNIT_GROUPS),
        search: searchParams.toString()
      });
    },
    [searchValues, navigate]
  );

  useEffect(() => {
    if (allProperties.length === 1) {
      goToOffer(allProperties[0].propertyId);
    }
  }, [allProperties, goToOffer]);

  return (
    <Grid
      py={2}
      container
      flexDirection="row"
      spacing={2}
      justifyContent="flex-start"
      sx={{
        backgroundColor: isMobile ? "background.default" : "transient"
      }}
    >
      <Grid item xs={12} md={6}>
        {(!filteredProperties || filteredProperties.length === 0) &&
          !isSearchAvailablePropertiesLoading && (
            <Box p={2}>
              <NoOffersAvailable />
            </Box>
          )}
        {filteredProperties && filteredProperties.length !== 0 && (
          <Box>
            {filteredProperties.map((property) => (
              <Box key={property.propertyId} pb={2}>
                <Box onClick={() => goToOffer(property.propertyId)} sx={{ cursor: "pointer" }}>
                  <SearchPropertyCard
                    propertyId={property.propertyId}
                    pricePerNight={property.lowestPricePerNight}
                    priceTotal={property.lowestPriceTotal}
                    numberOfNights={property.numberOfNights}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Grid>

      {!isMobile && !cmsPropertySpecificDocumentsIsLoading && (
        <Grid item md={6}>
          <FixMapWrapper>
            <MapPreview selectedPropertyId={searchValues.propertyIds} />
          </FixMapWrapper>
        </Grid>
      )}
    </Grid>
  );
};
