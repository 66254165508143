import React, { FC, ReactElement } from "react";
import { alpha, Box, ListItemButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { responsiveVariables } from "../../../components/layouts/hooks/use-is-mobile";

interface BookingOverviewItemProps {
  onClick: () => void;
  icon: ReactElement;
  highlighted: boolean;
}

const useStyles = makeStyles()(({ breakpoints, palette, spacing, shape }) => ({
  wrapper: {},
  listItem: {
    margin: spacing(2, 0),
    cursor: "pointer",
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      backgroundColor: palette.background.paper,
      borderRadius: shape.borderRadius * 2.5
    }
  },
  highlightedListItem: {
    backgroundColor: alpha(palette.primary.main, 0.1),
    borderRadius: shape.borderRadius * 2.5
  },
  iconContainer: {
    position: "absolute",
    right: spacing(1)
  }
}));

export const BookingOverviewItemWrapper: FC<React.PropsWithChildren<BookingOverviewItemProps>> = ({
  onClick,
  children,
  icon,
  highlighted
}) => {
  const { classes } = useStyles();
  return (
    <ListItemButton onClick={onClick} selected={highlighted} className={classes.listItem}>
      <Box width="95%">{children}</Box>
      <div className={classes.iconContainer}>{icon}</div>
    </ListItemButton>
  );
};
