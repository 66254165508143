import { PersonGender } from "./person";
import { generateSmallUUID } from "../util/data";
import { Address } from "../domain-v1/address";

export interface Guest {
  objectId?: string;
  firstName: string;
  lastName: string;
  birthdate?: Date | null | string;
  phone: string | null;
  gender: PersonGender | null;
  email: string | null;
  id?: string;
  nationalityCountryCode?: string;
  countryOfOrigin?: string;
  address?: Address;
}

export const GuestFromJSON = (json: any): Guest => {
  if (!json) {
    return json;
  }

  return {
    objectId: generateSmallUUID(),
    firstName: json["firstName"],
    lastName: json["lastName"],
    birthdate: json["birthdate"],
    gender: json["gender"],
    phone: json["phone"],
    email: json["email"],
    id: json["id"],
    nationalityCountryCode: json["nationalityCountryCode"],
    address: json["address"]
  };
};

export const emptyGuest = () => ({
  objectId: generateSmallUUID(),
  firstName: "",
  lastName: "",
  birthdate: "",
  gender: null,
  phone: null,
  email: null,
  nationalityCountryCode: undefined,
  address: {
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    city: "",
    countryCode: ""
  }
});

export type GuestFormDataType = {
  objectId?: string;
  firstName: string;
  lastName: string;
  birthdate?: Date | null | string;
  phone: string | null;
  gender: PersonGender | null;
  email: string | null;
  id?: string;
  nationalityCountryCode?: string;
  address: {
    addressLine1: string;
    addressLine2: string | null;
    postalCode: string;
    city: string;
    countryCode: string;
  };
};
