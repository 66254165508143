import React, { FC, lazy } from "react";
import { THEMES } from "@likemagic-tech/sv-magic-library";
import { useThemeConfig } from "../../util/hooks/use-configuration";
import { errorHandleForComponentLazyLoad } from "../../util/code-split.util";

const LeoneckFontLoader = lazy(() =>
  import("./leoneck/leoneck-font-loader")
    .then(({ LeoneckFontLoader }) => ({
      default: LeoneckFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);
const StaykooookFontLoader = lazy(() =>
  import("./staykooook/staykooook-font-loader")
    .then(({ StaykooookFontLoader }) => ({
      default: StaykooookFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);
const FlemlodgeFontLoader = lazy(() =>
  import("./flemlodge/flemlodge-font-loader")
    .then(({ FlemlodgeFontLoader }) => ({
      default: FlemlodgeFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);
const AnstattFontLoader = lazy(() =>
  import("./anstatt/anstatt-font-loader")
    .then(({ AnstattFontLoader }) => ({
      default: AnstattFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const GuesthouseZurzachFontLoader = lazy(() =>
  import("./guesthouse-zurzach/guesthouse-zurzach-font-loader")
    .then(({ GuesthouseZurzachFontLoader }) => ({
      default: GuesthouseZurzachFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const URBMFontLoader = lazy(() =>
  import("./urbm/urbm-font-loader")
    .then(({ URBMFontLoader }) => ({
      default: URBMFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const MMNTFontLoader = lazy(() =>
  import("./mmnt/mmnt-font-loader")
    .then(({ MMNTFontLoader }) => ({
      default: MMNTFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const ContiFontLoader = lazy(() =>
  import("./conti/conti-font-loader")
    .then(({ ContiFontLoader }) => ({
      default: ContiFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const SmarthotelFontLoader = lazy(() =>
  import("./smarthotel/smarthotel-font-loader")
    .then(({ SmarthotelFontLoader }) => ({
      default: SmarthotelFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const StaybrightFontLoader = lazy(() =>
  import("./staybright/staybright-font-loader")
    .then(({ StaybrightFontLoader }) => ({
      default: StaybrightFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const WelcomeHomesFontLoader = lazy(() =>
  import("./welcome-homes/welcome-homes-font-loader")
    .then(({ WelcomeHomesFontLoader }) => ({
      default: WelcomeHomesFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const FredHotelsFontLoader = lazy(() =>
  import("./fredhotels/fredhotels-font-loader")
    .then(({ FredHotelsFontLoader }) => ({
      default: FredHotelsFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const SchaniBioFontLoader = lazy(() =>
  import("./schani-bio/schani-bio-font-loader")
    .then(({ SchaniBioFontLoader }) => ({
      default: SchaniBioFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const RivaFontLoader = lazy(() =>
  import("./riva/riva-font-loader")
    .then(({ RivaFontLoader }) => ({
      default: RivaFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const PrincessFontLoader = lazy(() =>
  import("./princess/princess-font-loader")
    .then(({ PrincessFontLoader }) => ({
      default: PrincessFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const PensionArosaFontLoader = lazy(() =>
  import("./pensionarosa/pensionarosa-font-loader")
    .then(({ PensionArosaFontLoader }) => ({
      default: PensionArosaFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const ZipperFontLoader = lazy(() =>
  import("./zipper/zipper-font-loader")
    .then(({ ZipperFontLoader }) => ({
      default: ZipperFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const GghotelFontLoader = lazy(() =>
  import("./gghotel/gghotel-font-loader")
    .then(({ GghotelFontLoader }) => ({
      default: GghotelFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const ZzzpaceFontLoader = lazy(() =>
  import("./zzzpace/zzzpace-font-loader")
    .then(({ ZzzpaceFontLoader }) => ({
      default: ZzzpaceFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const NshotelFontLoader = lazy(() =>
  import("./nshotel/nshotel-font-loader")
    .then(({ NshotelFontLoader }) => ({
      default: NshotelFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const FlatlightFontLoader = lazy(() =>
  import("./flatlight/flatlight-font-loader")
    .then(({ FlatlightFontLoader }) => ({
      default: FlatlightFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const TfePocFontLoader = lazy(() =>
  import("./tfe-poc/tfe-poc-font-loader")
    .then(({ TfePocFontLoader }) => ({
      default: TfePocFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const AchesahomesFontLoader = lazy(() =>
  import("./achesahomes/achesahomes-font-loader")
    .then(({ AchesahomesFontLoader }) => ({
      default: AchesahomesFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const IbisStylesFontLoader = lazy(() =>
  import("./ibis-styles/ibis-styles-font-loader")
    .then(({ IbisStylesFontLoader }) => ({
      default: IbisStylesFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const McdreamsFontLoader = lazy(() =>
  import("./mcdreams/mcdreams-font-loader")
    .then(({ McdreamsFontLoader }) => ({
      default: McdreamsFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const YarnFontLoader = lazy(() =>
  import("./yarn/yarn-font-loader")
    .then(({ YarnFontLoader }) => ({
      default: YarnFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const DreamcationFontLoader = lazy(() =>
  import("./dreamcation/dreamcation-font-loader")
    .then(({ DreamcationFontLoader }) => ({
      default: DreamcationFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const OpenSansFontLoader = lazy(() =>
  import("./common/open-sans-font-loader")
    .then(({ OpenSansFontLoader }) => ({
      default: OpenSansFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const LikeMagicFontLoader = lazy(() =>
  import("./likemagic/likemagic-font-loader")
    .then(({ LikeMagicFontLoader }) => ({
      default: LikeMagicFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const LifehomeFontLoader = lazy(() =>
  import("./lifehome/lifehome-font-loader")
    .then(({ LifehomeFontLoader }) => ({
      default: LifehomeFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const HopfenhotelFontLoader = lazy(() =>
  import("./hopfenhotel/hopfenhotel-font-loader")
    .then(({ HopfenhotelFontLoader }) => ({
      default: HopfenhotelFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const StaeyFontLoader = lazy(() =>
  import("./staey/staey-font-loader")
    .then(({ StaeyFontLoader }) => ({
      default: StaeyFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const HotelamkreiselFontLoader = lazy(() =>
  import("./hotelamkreisel/hotelamkreisel-font-loader")
    .then(({ HotelamkreiselFontLoader }) => ({
      default: HotelamkreiselFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const HotelviennaFontLoader = lazy(() =>
  import("./hotelvienna/hotelvienna-font-loader")
    .then(({ HotelviennaFontLoader }) => ({
      default: HotelviennaFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const LofthotelFontLoader = lazy(() =>
  import("./lofthotel/lofthotel-font-loader")
    .then(({ LofthotelFontLoader }) => ({
      default: LofthotelFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const PhagenbeckFontLoader = lazy(() =>
  import("./phagenbeck/phagenbeck-font-loader")
    .then(({ PhagenbeckFontLoader }) => ({
      default: PhagenbeckFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const WorkSansFontLoader = lazy(() =>
  import("./common/work-sans-font-loader")
    .then(({ WorkSansFontLoader }) => ({
      default: WorkSansFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const HelveticaNeueLightLoader = lazy(() =>
  import("./common/helvetica-neue-light-font-loader")
    .then(({ HelveticaNeueLightLoader }) => ({
      default: HelveticaNeueLightLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const IlmomentoFontLoader = lazy(() =>
  import("./ilmomento/ilmomento-font-loader")
    .then(({ IlmomentoFontLoader }) => ({
      default: IlmomentoFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const PeakstayFontLoader = lazy(() =>
  import("./peakstay/peakstay-font-loader")
    .then(({ PeakstayFontLoader }) => ({
      default: PeakstayFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const BSmartsFontLoader = lazy(() =>
  import("./b-smarts/bsmart-font-loader")
    .then(({ BSmartsFontLoader }) => ({
      default: BSmartsFontLoader
    }))
    .catch(errorHandleForComponentLazyLoad)
);

const BohnFontLoader = lazy(() =>
  import("./bohn/bohn-font-loader").then(({ BohnFontLoader }) => ({
    default: BohnFontLoader
  }))
);

const BergidyllFontLoader = lazy(() =>
  import("./bergidyll/bergidyll-font-loader").then(({ BergidyllFontLoader }) => ({
    default: BergidyllFontLoader
  }))
);

//ADD here font loader

const fontSelector = {
  [THEMES.STAYKOOOK_THEME]: <StaykooookFontLoader />,
  [THEMES.LEONECK_THEME]: <LeoneckFontLoader />,
  [THEMES.FLEM_LODGE_THEME]: <FlemlodgeFontLoader />,
  [THEMES.AMARIS_THEME]: <StaykooookFontLoader />,
  [THEMES.ANSTATT_THEME]: <AnstattFontLoader />,
  [THEMES.LIKE_MAGIC_THEME]: <LikeMagicFontLoader />,
  [THEMES.GUESTHOUSE_ZURZACH_THEME]: <GuesthouseZurzachFontLoader />,
  [THEMES.URBM_THEME]: <URBMFontLoader />,
  [THEMES.WALHALLA_THEME]: <LeoneckFontLoader />,
  [THEMES.MMNT_THEME]: <MMNTFontLoader />,
  [THEMES.CONTI_THEME]: <ContiFontLoader />,
  [THEMES.SMARTHOTEL_THEME]: <SmarthotelFontLoader />,
  [THEMES.STAYBRIGHT_THEME]: <StaybrightFontLoader />,
  [THEMES.WELCOME_HOMES_THEME]: <WelcomeHomesFontLoader />,
  [THEMES.FREDHOTELS_THEME]: <FredHotelsFontLoader />,
  [THEMES.SCHANI_BIO_THEME]: <SchaniBioFontLoader />,
  [THEMES.SCHANI_GENERAL_THEME]: <SchaniBioFontLoader />,
  [THEMES.SCHANI_SALON_THEME]: <SchaniBioFontLoader />,
  [THEMES.SCHANI_UNO_THEME]: <SchaniBioFontLoader />,
  [THEMES.RIVA_THEME]: <RivaFontLoader />,
  [THEMES.PRINCESS_THEME]: <PrincessFontLoader />,
  [THEMES.PENSIONAROSA_THEME]: <PensionArosaFontLoader />,
  [THEMES.ZIPPER_THEME]: <ZipperFontLoader />,
  [THEMES.GGHOTEL_THEME]: <GghotelFontLoader />,
  [THEMES.ZZZPACE_THEME]: <ZzzpaceFontLoader />,
  [THEMES.NSHOTEL_THEME]: <NshotelFontLoader />,
  [THEMES.FLATLIGHT_THEME]: <FlatlightFontLoader />,
  [THEMES.TFE_POC_THEME]: <TfePocFontLoader />,
  [THEMES.ACHESAHOMES_THEME]: <AchesahomesFontLoader />,
  [THEMES.IBIS_STYLES_THEME]: <IbisStylesFontLoader />,
  [THEMES.PKF_THEME]: <AnstattFontLoader />,
  [THEMES.MCDREAMS_THEME]: <McdreamsFontLoader />,
  [THEMES.YARN_THEME]: <YarnFontLoader />,
  [THEMES.DREAMCATION_THEME]: <DreamcationFontLoader />,
  [THEMES.REGIOHOTEL_THEME]: <OpenSansFontLoader />,
  [THEMES.A_BY_ADINA_THEME]: <TfePocFontLoader />,
  [THEMES.HOTELSWISSSTAR_THEME]: <OpenSansFontLoader />,
  [THEMES.LIFEHOME_THEME]: <LifehomeFontLoader />,
  [THEMES.HOPFENHOTEL_THEME]: <HopfenhotelFontLoader />,
  [THEMES.URBANISLAND_THEME]: <WorkSansFontLoader />,
  [THEMES.SUITE4ME_THEME]: <OpenSansFontLoader />,
  [THEMES.STAEY_THEME]: <StaeyFontLoader />,
  [THEMES.HOTELAMKREISEL_THEME]: <HotelamkreiselFontLoader />,
  [THEMES.HOTELVIENNA_THEME]: <HotelviennaFontLoader />,
  [THEMES.LOFTHOTEL_THEME]: <LofthotelFontLoader />,
  [THEMES.PHAGENBECK_THEME]: <PhagenbeckFontLoader />,
  [THEMES.STAYAWESOME_THEME]: <WorkSansFontLoader />,
  [THEMES.APARTHOTELADELBODEN_THEME]: <HelveticaNeueLightLoader />,
  [THEMES.ILMOMENTO_THEME]: <IlmomentoFontLoader />,
  [THEMES.PEAKSTAY_THEME]: <PeakstayFontLoader />,
  [THEMES.B_SMARTS_THEME]: <BSmartsFontLoader />,
  [THEMES.BOHN_THEME]: <BohnFontLoader />,
  [THEMES.BERGIDYLL_THEME]: <BergidyllFontLoader />
  //ADD here font selector
};
export const FontLoader: FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useThemeConfig();

  return <React.Suspense>{fontSelector[theme] || <StaykooookFontLoader />}</React.Suspense>;
};
