import { FC } from "react";
import { ServiceTag } from "../../../domain-common/overview-service";
import { ServiceLabels } from "./service-labels";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { KeyIcon } from "../../../components/icons";

export const BookedServiceTag: FC<{ tag: ServiceTag }> = ({ tag }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  switch (tag) {
    case ServiceTag.KEY:
      return <KeyIcon sx={{ fontSize: 28 }} />;
    default:
      return <ServiceLabels type={tag} label={t(`labels__service_label_${tag?.toLowerCase()}`)} />;
  }
};
