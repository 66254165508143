import { fetchPromoCodesCMS } from "../promo-codes-cms/promo-codes-cms.slice";
import { fetchPropertySpecificCMS } from "../property-cms/property-cms.slice";
import { CmsSingleDocumentPerPropertyType } from "./cms-single-document-per-property-type";
import { fetchShopCMS } from "../shop-cms/shop-cms.slice";
import { fetchConfigurationCMS } from "../configuration-cms/configuration-cms.slice";
import { AsyncThunk } from "@reduxjs/toolkit";
import { PrismicDocumentPerProperty } from "../../domain-common/prismic-document";
import { PayloadArg } from "./cms-single-page-per-property-factory";

export const documentTypeToFetchSinglePerPropertyAction: Record<
  CmsSingleDocumentPerPropertyType,
  AsyncThunk<PrismicDocumentPerProperty, PayloadArg, any>
> = {
  [CmsSingleDocumentPerPropertyType.propertySpecfic]: fetchPropertySpecificCMS,
  [CmsSingleDocumentPerPropertyType.promoCodes]: fetchPromoCodesCMS,
  [CmsSingleDocumentPerPropertyType.shop]: fetchShopCMS,
  [CmsSingleDocumentPerPropertyType.configuration]: fetchConfigurationCMS
};
