import { FC, PropsWithChildren } from "react";
import { makeStyles } from "tss-react/mui";
import { responsiveVariables, useIsMobile } from "../../layouts/hooks/use-is-mobile";

const useStylesForDesktop = makeStyles()(({ breakpoints, palette, spacing, shape }) => ({
  customCard: {
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      background: palette.background.paper,
      borderRadius: shape.borderRadius * 3,
      padding: spacing(3),
      boxShadow: "0px 4px 8px rgba(197, 185, 172, 0.06)"
    }
  }
}));

export const DesktopCard: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStylesForDesktop();
  const isMobile = useIsMobile();

  return <div className={`${classes.customCard} ${isMobile ? "" : "DesktopCard"}`}>{children}</div>;
};
