import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { DisplayCmsSvg, Notification, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { GuestForm } from "../../../components/molecules/guest-form";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { TravelBuddy } from "../../../domain-common/travel-buddy";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generatePortalMyStayBuddiesUrl } from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import {
  Editability,
  useGuestDataEditable
} from "../../booking-overview/hooks/use-guest-data-editable";
import { useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useNavigate } from "react-router-dom";
import { usePatchTravelBuddy } from "../hooks/use-patch-travel-buddy";
import { openBanner } from "../../banner/banner.slice";
import { ProfileTemplate } from "../components/profile-template";
import { useAppDispatch } from "../../../state/store";
import {
  useAdditionalTravelBuddyFieldsConfig,
  useFeatureFlags
} from "../../../util/hooks/use-configuration";
import { useCommonValidationCmsData } from "../../../state/common-cms/use-common-cms-data";
import { additionalTravelBuddyFieldValidation } from "../../../util/additional-travel-buddy-fields-validation";
import { TravelBuddyTermsAndConditions } from "../../../components/molecules/personal-form/travel-buddy-terms-and-conditions";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { ReservationExtras } from "../../../domain-common/reservation-extras";
import { useIsMinor } from "../../../util/hooks/use-is-minor";
import { useTheme } from "@mui/styles";
import { Person } from "../../../domain-common/person";

interface MyStayBuddyEditPageProps {}

export const MyStayBuddyEditPage: React.FC<
  React.PropsWithChildren<MyStayBuddyEditPageProps>
> = () => {
  const { reservation } = useReservationContext();
  const navigate = useNavigate();
  const { travelBuddyValidation, tcMinorsValidation } = useFormValidations();
  const validationCmsData = useCommonValidationCmsData();
  const { diverseGenderEnabled, travelBuddyIsMinorValidationEnabled, genderDisplayDisabled } =
    useFeatureFlags();
  useSetupSubpageNavigation(generatePortalMyStayBuddiesUrl);
  const { spacing } = useTheme();
  const { isMinor } = useIsMinor();
  const { buddyIndex } = useParams<{ buddyIndex: string }>();
  const buddyIndexNumber = useMemo(() => Number(buddyIndex), [buddyIndex]);
  const dispatch = useAppDispatch();
  const [travelBuddyContext, setTravelBuddyContext] = useState<TravelBuddy | undefined>(undefined);
  const additionalTravelBuddyFields = useAdditionalTravelBuddyFieldsConfig();
  const { open: openModal } = useGlobalModal();
  const patchTravelBuddy = usePatchTravelBuddy({ reservation });
  const { patchGuestFlowStep } = useGuestFlow();

  const dynamicSchema = additionalTravelBuddyFieldValidation(
    additionalTravelBuddyFields,
    validationCmsData,
    true
  );

  const newSchema = travelBuddyIsMinorValidationEnabled
    ? travelBuddyValidation.concat(tcMinorsValidation).concat(dynamicSchema)
    : travelBuddyValidation.concat(dynamicSchema);

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  useEffect(() => {
    setTravelBuddyContext(
      reservation.travelBuddies?.find(
        (travelBuddy) => travelBuddy.travelBuddyIndex === buddyIndexNumber
      )
    );
  }, [reservation.travelBuddies, buddyIndexNumber]);

  const pushData = useCallback(
    async (
      values: { travelBuddy: Person; extras: ReservationExtras | null },
      sendNotification: boolean
    ) => {
      await patchTravelBuddy({
        originalTravelBuddy: travelBuddyContext,
        updatedTravelBuddy: {
          ...travelBuddyContext!,
          travelBuddy: values.travelBuddy
        },
        sendNotification: sendNotification
      });

      await patchGuestFlowStep({
        reservationValues: {
          ...reservation,
          extras: values.extras
        }
      });

      navigate(generatePortalMyStayBuddiesUrl(reservation.magicId));
    },
    [navigate, patchTravelBuddy, patchGuestFlowStep, reservation, travelBuddyContext]
  );

  const onSubmit = useCallback(
    async (values: { travelBuddy: Person; extras: ReservationExtras | null }) => {
      if (
        (values.travelBuddy?.email &&
          values.travelBuddy.email === reservation.primaryGuest.email) ||
        (values.travelBuddy?.phone && values.travelBuddy.phone === reservation.primaryGuest.phone)
      ) {
        return dispatch(
          openBanner({
            errorId: "errors__travel_buddy_same_contact_data",
            type: "error",
            title: ""
          })
        );
      }

      if (
        travelBuddyContext?.flowState.notificationSent &&
        (values.travelBuddy?.email || values.travelBuddy?.phone)
      ) {
        const result = await openModal({
          modalProps: {
            title: tCommon("modals__buddy_send_notification_dialog_title"),
            content: <>{tCommon("modals__buddy_send_notification_dialog_content")}</>
          },
          modalActions: [
            {
              variant: "secondary",
              result: false,
              label: tCommon("buttons__no")
            },
            {
              variant: "primary",
              result: true,
              label: tCommon("buttons__yes")
            }
          ]
        });
        pushData(values, !!result);
      } else {
        pushData(values, false);
      }
    },
    [
      travelBuddyContext,
      openModal,
      pushData,
      tCommon,
      reservation.primaryGuest.email,
      reservation.primaryGuest.phone,
      dispatch
    ]
  );

  const labels = React.useMemo(
    () => ({
      firstName: tCommon("labels__first_name"),
      lastName: tCommon("labels__last_name"),
      genderFemale: tCommon("labels__gender_female"),
      genderMale: tCommon("labels__gender_male"),
      genderOther: tCommon("labels__gender_other"),
      phone: tCommon("labels__phone"),
      email: tCommon("labels__email"),
      birthdate: tCommon("labels__birthdate"),
      nationalityCountryCode: tCommon("labels__nationality"),
      postalCode: tCommon("labels__postal_code"),
      address: tCommon("labels__address"),
      countryCode: tCommon("labels__country"),
      city: tCommon("labels__city")
    }),
    [tCommon]
  );

  const { editable } = useGuestDataEditable({
    uneditableStatus: [ReservationStatus.CHECKED_OUT],
    reservation
  });

  if (editable === undefined) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Edit Travel Buddy" />
      <RoundedFullHeight>
        <ProfileTemplate>
          <PageHeadingInfo
            title={tCommon("title__edit_travel_buddy_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
          />

          {travelBuddyContext && (
            <Box p={2.5}>
              <Formik
                enableReinitialize
                initialValues={{
                  travelBuddy: { ...travelBuddyContext.travelBuddy },
                  extras: { ...reservation }.extras
                }}
                onSubmit={onSubmit}
                validationSchema={newSchema}
              >
                {(formik) => {
                  return (
                    <Form>
                      {isMinor(formik.values.travelBuddy?.birthdate ?? "") &&
                        travelBuddyIsMinorValidationEnabled && (
                          <Box my={spacing(4)}>
                            <Notification
                              type="info"
                              title={tCommon("labels__additional_travel_buddy_under_age_info")}
                            />
                          </Box>
                        )}
                      <GuestForm
                        objectPathPrefix={`travelBuddy`}
                        values={formik.values.travelBuddy}
                        labels={labels}
                        disabled={formik.isSubmitting || editable !== Editability.EDITABLE}
                        touched={formik.touched?.travelBuddy}
                        errors={formik.errors?.travelBuddy}
                        diverseGenderEnabled={diverseGenderEnabled}
                        genderDisplayDisabled={genderDisplayDisabled}
                        onChange={formik.handleChange}
                        additionalFields={additionalTravelBuddyFields}
                      />
                      {travelBuddyIsMinorValidationEnabled && (
                        <TravelBuddyTermsAndConditions
                          value={formik.values.extras?.tcMinors ?? false}
                          onChange={formik.handleChange}
                          // @ts-ignore
                          touched={formik.touched?.extras?.tcMinors ?? false}
                          // @ts-ignore
                          errors={formik.errors?.extras?.tcMinors ?? ""}
                        />
                      )}
                      {editable === Editability.EDITABLE && formik.dirty && (
                        <SubmitButton
                          label={tCommon("buttons__save")}
                          disabled={formik.isSubmitting}
                          hasBottomNavigation
                          hasWhiteBackground
                        />
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          )}
        </ProfileTemplate>
      </RoundedFullHeight>
    </>
  );
};
