import React, { FC, useCallback, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { Dialog } from "../atoms";
import {
  formatIsoStringToIsoDate,
  formatToIsoDate,
  isSameDay,
  LocalizationDatePicker,
  Paragraph,
  parseIsoDateString,
  SelectableRangeDay
} from "@likemagic-tech/sv-magic-library";
import { Reservation } from "../../domain-common/reservation";
import { useIsMobile } from "../layouts/hooks/use-is-mobile";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import startOfDay from "date-fns/startOfDay";
import { differenceInDays, eachDayOfInterval } from "date-fns";
import { PickersDayProps } from "@mui/lab/PickersDay";
import { WrappedInput } from "../atoms/input/wrapped-input";

interface DatePickerExtendStayLabels {
  dialogButton: string;
  dialogTitle: string;
  unavailableDialogText: string;
}

interface DatePickerExtendStayProps {
  reservation: Reservation;
  labels: DatePickerExtendStayLabels;
  onSubmit: (from: string, to: string) => void;
  inputRef?: any;
  open: boolean;
  unavailable: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const DatePickerExtendStay: FC<React.PropsWithChildren<DatePickerExtendStayProps>> = ({
  reservation,
  labels,
  onSubmit,
  open,
  setOpen,
  unavailable
}) => {
  const isMobile = useIsMobile();
  const arrival = reservation.arrival!;
  const departure = reservation.departure!;
  const initValue = eachDayOfInterval({
    start: parseIsoDateString(arrival),
    end: parseIsoDateString(departure)
  });
  const [values, setValues] = useState<Array<Date>>(initValue);

  const isDayDisabled = useCallback(
    (day: Date) =>
      differenceInDays(startOfDay(day), startOfDay(parseIsoDateString(departure))) <= 0,
    [departure]
  );

  const renderPickerDay = useCallback(
    (
      date: Array<Date>,
      selectedDates: Array<Array<Date> | null>,
      pickersDayProps: PickersDayProps<Array<Date>>
    ) => {
      // @ts-ignore
      const props: PickersDayProps<unknown> = pickersDayProps;
      // @ts-ignore
      const day = date as Date;
      const selected = values.some((dateVal) => isSameDay(dateVal, day));

      return (
        <SelectableRangeDay
          {...props}
          values={values}
          disabled={isDayDisabled(day)}
          disableMargin
          selected={selected}
        />
      );
    },
    [values, isDayDisabled]
  );

  const picker = useMemo(() => {
    return (
      <Grid container direction="column">
        <Grid item alignSelf="center">
          <LocalizationDatePicker>
            <StaticDatePicker
              showToolbar={false}
              //@ts-ignore
              defaultCalendarMonth={parseIsoDateString(departure)}
              displayStaticWrapperAs={isMobile ? "mobile" : "desktop"}
              label="Week picker"
              value={values}
              allowSameDateSelection
              onChange={(newValue) => {
                //copying the values array

                if (values[0]) {
                  // @ts-ignore
                  const date = startOfDay(newValue);
                  setValues(eachDayOfInterval({ start: values[0], end: date }));
                }
              }}
              renderDay={renderPickerDay}
              renderInput={(params) => <WrappedInput {...params} label={params.label as string} />}
              showDaysOutsideCurrentMonth
            />
          </LocalizationDatePicker>
        </Grid>
        {unavailable && (
          <Grid item>
            <Paragraph variant="subtitle1" align="center" color="textPrimary">
              {labels.unavailableDialogText}
            </Paragraph>
          </Grid>
        )}
      </Grid>
    );
  }, [departure, isMobile, values, renderPickerDay, unavailable, labels.unavailableDialogText]);
  const closeModal = useCallback(() => {
    setValues(initValue);
    setOpen(false);
  }, [setOpen, initValue]);

  const onSubmitWrapper = useCallback(() => {
    onSubmit(formatToIsoDate(values[0]), formatToIsoDate(values[values.length - 1]));
  }, [onSubmit, values]);

  return (
    <Dialog
      open={open}
      onConfirm={onSubmitWrapper}
      onDismiss={closeModal}
      content={picker}
      title={labels.dialogTitle}
      buttonLabel={labels.dialogButton}
      buttonDisabled={
        formatToIsoDate(values[0]) === formatIsoStringToIsoDate(arrival) &&
        formatToIsoDate(values[values.length - 1]) === formatIsoStringToIsoDate(departure)
      }
    />
  );
};
