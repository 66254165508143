import { Box, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useCallback, useEffect, useState } from "react";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface CounterProps {
  initialCount: number;
  onCountChange?: (count: number) => void;
  classes?: {
    root?: string;
  };
  minValue: number;
  maxValue?: number;
}

const useStyles = makeStyles()(() => ({
  counter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  icon: {
    "&.Mui-disabled": {
      opacity: 0.5
    }
  }
}));

export const Counter: React.FC<React.PropsWithChildren<CounterProps>> = ({
  initialCount,
  classes,
  onCountChange,
  minValue,
  maxValue
}) => {
  const [count, setCount] = useState<number>(initialCount);

  useEffect(() => {
    if (maxValue && initialCount > maxValue) {
      setCount(maxValue);
    } else {
      setCount(initialCount);
    }
  }, [initialCount, maxValue]);

  const minusAction = useCallback(() => {
    setCount((c) => Math.max(minValue, c - 1));
  }, [minValue]);

  const plusAction = useCallback(() => {
    setCount((c) => (maxValue ? Math.min(maxValue, c + 1) : c + 1));
  }, [maxValue]);

  useEffect(() => {
    onCountChange?.(count);
  }, [count, onCountChange]);

  const { classes: customClasses } = useStyles();
  const rootClassName = [customClasses.counter, classes?.root].filter(Boolean).join(" ");
  return (
    <div className={rootClassName}>
      <IconButton
        className={customClasses.icon}
        disabled={count <= minValue}
        size="small"
        onClick={minusAction}
      >
        <RemoveIcon />
      </IconButton>
      <Box px={2}>
        <Paragraph variant="subtitle1">{count}</Paragraph>
      </Box>
      <IconButton
        className={customClasses.icon}
        disabled={!!maxValue && count >= maxValue}
        size="small"
        onClick={plusAction}
      >
        <AddIcon />
      </IconButton>
    </div>
  );
};
