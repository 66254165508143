import { createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { RootState } from "../../state/store";

type GtmState = {
  sessionId?: string;
};

const initialState: GtmState = {};

export const gtmSlice = createSlice({
  name: "gtmSlice",
  initialState,
  reducers: {
    setSessionId: (state, action: PayloadAction<{ sessionId: string }>) => {
      state.sessionId = action.payload.sessionId;
    }
  }
});

export const { setSessionId } = gtmSlice.actions;

export const selectGtmSlice: Selector<RootState, GtmState> = (state: RootState) =>
  state[gtmSlice.name];
