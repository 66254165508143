import { Box } from "@mui/material";
import React, { FC } from "react";
import { MyStayTipCard } from "../../../../domain-common/my-stay-tip-card";
import { CardWrapper } from "./card-wrapper";
import { Title } from "./title";
import { FormatQuoteOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/styles";

interface TipQuoteCardProps {
  tip: MyStayTipCard;
}

export const TipQuoteCard: FC<TipQuoteCardProps> = ({ tip }) => {
  const theme = useTheme();

  return (
    <CardWrapper tip={tip}>
      <FormatQuoteOutlined
        fontSize="large"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 48,
          height: 48,
          color: theme.palette.grey[300]
        }}
      />
      <Box textAlign="center">
        <Title title={tip.title} />
      </Box>
      <FormatQuoteOutlined
        fontSize="large"
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: 48,
          height: 48,
          color: theme.palette.grey[300]
        }}
      />
    </CardWrapper>
  );
};
