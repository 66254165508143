import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Grid } from "@mui/material";
import React, { FC, useCallback } from "react";
import { CartLineItem } from "../../../domain-common/cart-line-item";
import { CartListItemCounter } from "./cart-list-item-counter";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { getServiceDetails, useServicesCmsData } from "../../services/use-services-cms-data";
import { CartListItemImage } from "./cart-list-item-image";

interface CartListItemProps {
  cartListItem: CartLineItem;
  onCountChange: (serviceId: string, count: number) => void;
  onDeleteServiceFromCart: (serviceId: string) => void;
  propertyId: string;
}

export const CartListItem: FC<CartListItemProps> = ({
  propertyId,
  cartListItem,
  onCountChange,
  onDeleteServiceFromCart
}) => {
  const { serviceTitles, serviceImages, fallbackImage } = useServicesCmsData(propertyId);

  const handleCountChange = useCallback(
    (count: number) => {
      onCountChange(cartListItem.product.serviceId, count);
    },
    [cartListItem.product.serviceId, onCountChange]
  );

  const handleDeleteService = useCallback(() => {
    onDeleteServiceFromCart(cartListItem.product.serviceId);
  }, [cartListItem.product.serviceId, onDeleteServiceFromCart]);

  return (
    <Grid container spacing={2}>
      <Grid item>
        <CartListItemImage
          imageUrl={getServiceDetails(cartListItem.product, serviceImages, fallbackImage)}
          onClose={handleDeleteService}
        />
      </Grid>
      <Grid item xs container direction="column-reverse" spacing={1}>
        <Paragraph gutterBottom>{formatPriceToString(cartListItem.price)}</Paragraph>
        <Paragraph gutterBottom>
          {getServiceDetails(cartListItem.product, serviceTitles, cartListItem.product.code)}
        </Paragraph>
      </Grid>
      <Grid item>
        <CartListItemCounter
          initialCount={cartListItem.quantity}
          onCountChange={handleCountChange}
        />
      </Grid>
    </Grid>
  );
};
