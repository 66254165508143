import React, { FC, useEffect, useRef, useState } from "react";
import { Heading4, KeySlider } from "@likemagic-tech/sv-magic-library";
import { displayDoorName } from "./keys-speed-dial/helpers";
import { Box } from "@mui/material";
import { closeBanner } from "../banner/banner.slice";
import { useDoorCmsData } from "../../state/property-cms/use-door-cms-data";
import { useAppDispatch } from "../../state/store";
import { useCommonErrorTranslation } from "../../state/common-cms/use-common-error-translation";
import { makeStyles } from "tss-react/mui";
import { useBanner } from "../banner/use-banner";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { KeysHandlerProps } from "./keys-handler";
import { useOpenDoor } from "./use-open-door";

const useStyles = makeStyles()((theme) => ({
  doorTitle: {
    paddingBottom: theme.spacing(2),
    fontSize: 16,
    lineHeight: "19.2px"
  },
  keySlider: {
    paddingBottom: theme.spacing(8)
  }
}));
export const KeysApi: FC<React.PropsWithChildren<KeysHandlerProps>> = ({ door, reservation }) => {
  const keySliderRefs = useRef<{ [doorId: string]: any }>({});
  const [unlockedKeys, setUnlockedKeys] = useState<Record<string, boolean>>({});
  const [keysErrors, setKeysErrors] = useState<Record<string, boolean>>({});
  const { translatedKeys } = useDoorCmsData(reservation.propertyId);
  const dispatch = useAppDispatch();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const openDoorAction = useOpenDoor();
  const { classes } = useStyles();
  const { bannerState } = useBanner();
  const translatedError = useCommonErrorTranslation(bannerState.errorId);

  const timerRef = React.useRef<number | null>(null);
  useEffect(
    () => () => {
      dispatch(closeBanner());
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    },
    [dispatch, timerRef]
  );

  const handleOpenDoor = async (doorId: string) => {
    dispatch(closeBanner());
    setUnlockedKeys((s) => ({ ...s, [doorId]: false }));
    setKeysErrors((s) => ({ ...s, [doorId]: false }));
    try {
      await openDoorAction({ doorId, reservation });
      setUnlockedKeys((s) => ({ ...s, [doorId]: true }));
    } catch (e) {
      setKeysErrors((s) => ({ ...s, [doorId]: true }));
    } finally {
      timerRef.current = window.setTimeout(function () {
        dispatch(closeBanner());
        keySliderRefs.current?.[doorId]?.reset();
        setUnlockedKeys((s) => ({ ...s, [doorId]: false }));
        setKeysErrors((s) => ({ ...s, [doorId]: false }));
      }, 5000);
    }
  };

  return (
    <Box mb={3} className={classes.keySlider} key={door.id}>
      <Heading4 className={classes.doorTitle}>{displayDoorName(door, translatedKeys)}</Heading4>
      <KeySlider
        ref={(el) => (keySliderRefs.current[door.id] = el)}
        onSuccess={() => handleOpenDoor(door.id)}
        unlocked={unlockedKeys[door.id]}
        error={keysErrors[door.id]}
        labels={{
          slideToUnlock: tCommon("labels__keys_slide_to_unlock"),
          unlocked: tCommon("labels__keys_unlocked"),
          error: translatedError?.keySliderText
            ? translatedError.keySliderText
            : tCommon("validations__keys_error")
        }}
      />
    </Box>
  );
};
