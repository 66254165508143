import React, { FC, useCallback, useMemo } from "react";
import { CONFIRMATION_TYPE_KEY, ConfirmationType, RoundBox } from "../../../components";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { Box, Grid } from "@mui/material";
import { useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { getOkModalArg } from "../../global-modal/global-modal-util";
import { useNavigate } from "react-router-dom";
import { generatePortalPaymentNavigate } from "../../../util/routing";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { usePreparePayment } from "../../payment/use-prepare-payment";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useSetupSubpageNavigation } from "../../portal/hooks/use-setup-subpage-navigation";
import { GroupNames, useFetchShopProducts } from "../../shop/hooks/use-fetch-shop-products";
import { useServicesCmsData } from "../../services/use-services-cms-data";
import { useFetchSelfPouringStationAvailability } from "../hooks/use-fetch-self-pouring-station-availability";
import { SelfPouringStationItem } from "../self-poruing-station.types";
import { SelfPouringStationModalContent } from "../components/self-pouring-station-modal-content";
import { SelfPouringStationItemCard } from "../components/self-pouring-station-item";
import { SelfPouringStationShopResultPage } from "./self-pouring-station-shop-result.page";
import { Service } from "../../../domain-common/service";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import {
  reserveSelfPouringStation,
  selectIsSelfPouringStationLoading
} from "../self-pouring-station-shop.slice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/store";
import { unwrapResult } from "@reduxjs/toolkit";

export const SelfPouringStationPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  useSetupSubpageNavigation();

  const { reservation } = useReservationContext();
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();

  const {
    serviceIcons: productIconsByServiceId,
    fallbackIcon: productFallbackIcon,
    serviceTitles: productServiceTitles
  } = useServicesCmsData(reservation.propertyId);

  const { products } = useFetchShopProducts(reservation, [GroupNames.SelfDrink]);
  const pouringStationsAvailabilities = useFetchSelfPouringStationAvailability(reservation);
  const isSelfPouringStationLoading = useSelector(selectIsSelfPouringStationLoading);
  const dispatch = useAppDispatch();

  const { open: openModal } = useGlobalModal();
  const { preparePayment } = usePreparePayment(magicId);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const onItemClick = useCallback(
    async (product: SelfPouringStationItem) => {
      const result = await openModal(
        getOkModalArg(
          t("modals__title_self_pouring_station"),
          <SelfPouringStationModalContent
            title={productServiceTitles[product.serviceId]}
            serviceSelfPouringStationModalItem={product}
            iconSrc={productIconsByServiceId[product.serviceId] ?? productFallbackIcon}
            unit={product.unit}
          />,
          t("buttons__self_pouring_station_buy")
        )
      );

      if (result === "OK") {
        const shopItems = [
          {
            serviceId: product.serviceId,
            quantity: 1
          }
        ];

        await dispatch(
          reserveSelfPouringStation({
            magicId: reservation.magicId,
            magicToken: reservation.magicToken,
            drinkId: product.drinkId
          })
        ).then(unwrapResult);

        await preparePayment({
          shopItems
        });

        navigate(
          generatePortalPaymentNavigate(magicId, {
            [CONFIRMATION_TYPE_KEY]: ConfirmationType.SELF_POURING_STATION_SHOP,
            deviceId: product.drinkId
          })
        );
      }
    },
    [
      reservation,
      dispatch,
      openModal,
      magicId,
      navigate,
      preparePayment,
      productServiceTitles,
      productIconsByServiceId,
      productFallbackIcon,
      t
    ]
  );

  const computedProducts: SelfPouringStationItem[] = useMemo(() => {
    return pouringStationsAvailabilities.length
      ? pouringStationsAvailabilities
          .map((item) => {
            return {
              ...products.find((product: Service) => product.serviceId === item.serviceId),
              drinkId: item.drinkId,
              available: item.available,
              unit: item.portionVolume / 1000
            } as SelfPouringStationItem;
          })
          .filter((item) => item.serviceId)
      : [];
  }, [products, pouringStationsAvailabilities]);

  const availableComputedProducts = useMemo(
    () => computedProducts.filter((item) => item.available),
    [computedProducts]
  );

  if (isSelfPouringStationLoading) {
    return null;
  }

  if (pouringStationsAvailabilities.length === 0) {
    return (
      <SelfPouringStationShopResultPage
        iconUrl={cmsData?.data?.icon__self_pouring_station_failed?.url}
        title={t("labels__self_pouring_station_limit_reached")}
        subtitle={t("labels__self_pouring_station_purchase_again_after_the_time_expires")}
      />
    );
  }

  if (availableComputedProducts.length === 0) {
    return <SelfPouringStationShopResultPage />;
  }

  return (
    <>
      <RoundBox hasShadow py={3} px={2}>
        <PageHeadingInfo
          title={t("title__self_pouring_station_shop_page")}
          subtitle={t("subtitle__self_pouring_station_shop_page")}
        />
      </RoundBox>

      <Box px={2.5} py={2.5}>
        <Grid container spacing={2}>
          {products.length > 0 &&
            availableComputedProducts.map((item) => (
              <Grid key={item.drinkId} item xs={6} md={3}>
                <SelfPouringStationItemCard
                  onClick={() => onItemClick(item)}
                  selfPouringStationItem={item}
                  propertyId={reservation.propertyId}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};
