import { Reservation } from "../../../domain-common/reservation";
import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { BookedServiceItem } from "./booked-service-item";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { usePropertyById } from "../../property/use-property-by-id";

export interface BookedServiceListProps {
  reservation: Reservation | undefined;
  extensionReadOnly?: boolean;
}

export const BookedServiceList: FC<React.PropsWithChildren<BookedServiceListProps>> = ({
  reservation,
  extensionReadOnly
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const property = usePropertyById(reservation?.propertyId);

  return (
    <Grid container direction="column" spacing={2}>
      {reservation?.servicesOverview?.map((service) => (
        <BookedServiceItem
          key={service.service.id}
          service={service}
          reservation={reservation}
          timeZone={property.timeZone}
          extensionReadOnly={extensionReadOnly}
        />
      ))}

      {reservation?.servicesOverview?.length === 0 && !extensionReadOnly && (
        <Box mt={3} px={5}>
          <Paragraph align="center"> {t("labels__no_services")}</Paragraph>
        </Box>
      )}
    </Grid>
  );
};
