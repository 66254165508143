import React, { FC } from "react";
import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { Box, Grid } from "@mui/material";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { useNavigate } from "react-router-dom";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { Subtitle } from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";

interface SelfPouringStationShopResultPageProps {
  iconUrl?: string;
  title?: string;
  subtitle?: string;
  imageUrl?: string;
  secondaryButtonOnClick?: () => void;
}

const useStyles = makeStyles()(() => ({
  icon: {
    width: 90,
    height: "auto"
  }
}));

export const SelfPouringStationShopResultPage: FC<SelfPouringStationShopResultPageProps> = ({
  title,
  subtitle,
  iconUrl,
  imageUrl,
  secondaryButtonOnClick
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const { classes } = useStyles();

  const goToMyStay = () => {
    navigate(generatePortalMyStayUrl(magicId));
  };

  return (
    <FlowTemplate handleBack={goToMyStay}>
      <RoundedFullHeight px={2.5}>
        <DesktopCard>
          <Grid
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            py={1}
            height="100%"
          >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              {iconUrl && <DisplayCmsSvg url={iconUrl} className={classes.icon} />}
              {title && <Subtitle textAlign="center">{title}</Subtitle>}
              {subtitle && <Subtitle textAlign="center">{subtitle}</Subtitle>}

              {imageUrl && (
                <Box mt={2}>
                  <img src={imageUrl} alt="icon" width="100%" />
                </Box>
              )}
            </Box>
            <Grid item xs={12}>
              <SubmitButton
                hasBottomNavigation
                label={t("buttons__go_to_my_stay")}
                onClick={goToMyStay}
              />
            </Grid>

            {secondaryButtonOnClick && (
              <Grid item xs={12}>
                <SubmitButton
                  variant="secondary"
                  hasBottomNavigation
                  hasSecondaryFixedButton={!!secondaryButtonOnClick}
                  label={t("buttons__try_again")}
                  onClick={secondaryButtonOnClick}
                />
              </Grid>
            )}
          </Grid>
        </DesktopCard>
      </RoundedFullHeight>
    </FlowTemplate>
  );
};
