import { makeStyles } from "tss-react/mui";
import React, { FC, ReactElement } from "react";
import { Box, ButtonBase, Grid, Paper, useTheme } from "@mui/material";
import { Done } from "@mui/icons-material";

interface AdditionalServiceButtonProps {
  icon?: ReactElement;
  selected: boolean;
  onClick?: () => void;
  content?: ReactElement;
  disabled?: boolean;
  useWhiteBackground?: boolean;
}

const useStyles = makeStyles<{ selected?: boolean; useWhiteBackground?: boolean }>()(
  (theme, props) => ({
    root: {
      borderRadius: theme.shape.borderRadius * 2.5
    },
    icon: {
      color: theme.palette.secondary.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      minWidth: "64px"
    },
    paper: {
      // padding: theme.spacing(1, 2, 1, 2),
      borderRadius: theme.shape.borderRadius * 2.5,
      border: "2px solid",
      borderColor: props.selected ? theme.palette.primary.main : "transparent",
      width: "100%",
      height: "100%",
      backgroundColor: props.useWhiteBackground
        ? theme.palette.background.paper
        : "rgba(0, 0, 0, 0.04)",
      transition: theme.transitions.create("border-color")
    }
  })
);

export const PaperRadioButton: FC<AdditionalServiceButtonProps> = ({
  icon,
  onClick,
  selected,
  useWhiteBackground,
  content,
  disabled = false
}) => {
  const { spacing } = useTheme();
  const { classes } = useStyles({ selected, useWhiteBackground });
  return (
    <ButtonBase
      sx={{ width: "100%" }}
      focusRipple
      className={classes.root}
      onClick={onClick}
      disabled={disabled}
    >
      <Paper elevation={0} classes={{ root: classes.paper }}>
        <Box p={1.5} display="inline-flex" width="100%">
          <Grid container wrap="nowrap" sx={{ width: `calc(100% - ${spacing(4)})` }}>
            {icon && (
              <Grid item minWidth={64} className={classes.iconContainer} mr={1.5}>
                {React.cloneElement(icon, { className: classes.icon })}
              </Grid>
            )}
            <Grid item alignSelf="center">
              {content}
            </Grid>
          </Grid>
          {selected && (
            <Grid container alignItems="center" justifyContent="flex-end" width={spacing(4)}>
              <Done />
            </Grid>
          )}
        </Box>
      </Paper>
    </ButtonBase>
  );
};
