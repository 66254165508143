import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function PlusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="black" />
    </SvgIcon>
  );
}
