import { Box, Grid, Slider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { formatISO, subHours } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { formatTime, formatToIsoDate, Paragraph } from "@likemagic-tech/sv-magic-library";
import { PricePreview } from "../../../components/atoms/price-preview/price-preview";
import { usePropertyById } from "../../property/use-property-by-id";
import { useCmsPropertySpecificData } from "../../../state/cms/use-cms-per-property-data";
import { useLateCheckoutModalCmsData } from "../../../state/property-cms/use-service-modal-cms-data";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { ServiceModalHandlerProps } from "../service-selection/additional-service-modal-handler";
import { multiply, toGross } from "../../../domain-common/full-price";
import { Dialog } from "../../../components";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

const useStyles = makeStyles()((theme) => ({
  dialogContentContainer: {
    alignSelf: "center",
    width: "100%",
    minWidth: 350
  },
  spaceNumberSlider: {
    paddingLeft: theme.spacing(6)
  }
}));

export const EarlyCheckinPickerDialog: React.FC<
  React.PropsWithChildren<ServiceModalHandlerProps>
> = ({ cartValue, onClose, onChange, reservation, price, service }) => {
  const countFirstDay = cartValue?.dates?.[0]?.count || 0;
  const [hours, setHours] = useState(service?.maximumHours || 0 - countFirstDay);
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsPropertySpecificData = useCmsPropertySpecificData(reservation.propertyId);

  const { translatedKeys: earlyCheckinPickerTranslatedKeys } =
    useLateCheckoutModalCmsData(cmsPropertySpecificData);

  const labels = useMemo(
    () => ({
      buttonLabel: earlyCheckinPickerTranslatedKeys[service.serviceId]?.button,
      descriptionLabel: earlyCheckinPickerTranslatedKeys[service.serviceId]?.description,
      title: earlyCheckinPickerTranslatedKeys[service.serviceId]?.title,
      subtitle: earlyCheckinPickerTranslatedKeys[service.serviceId]?.subtitle,

      currentDeparture: earlyCheckinPickerTranslatedKeys[service.serviceId]?.current_departure,
      newDeparture: earlyCheckinPickerTranslatedKeys[service.serviceId]?.new_departure,
      confirmButton: t("buttons__confirm")
    }),
    [earlyCheckinPickerTranslatedKeys, service.serviceId, t]
  );
  const { classes } = useStyles();
  const property = usePropertyById(reservation?.propertyId);

  const updatedArrivalTime = useMemo(() => {
    if (reservation.contractualCheckInTime) {
      const newArrival = subHours(
        new Date(reservation.contractualCheckInTime),
        service.maximumHours - hours
      );
      return formatISO(newArrival);
    }
  }, [reservation.contractualCheckInTime, hours, service.maximumHours]);

  const handleChange = useCallback(() => {
    const values = [
      {
        date: formatToIsoDate(new Date(reservation.contractualCheckInTime!)),
        count: service.maximumHours - hours
      }
    ].filter((item) => item.count);
    onChange({ values });
  }, [hours, reservation.contractualCheckInTime, onChange, service.maximumHours]);

  return (
    <Dialog
      title={labels.title}
      open
      onConfirm={handleChange}
      onDismiss={onClose}
      content={
        <>
          <Box m={2}>
            <Grid container direction="row" mt={3}>
              <Grid xs={6} item>
                <Paragraph color="text.secondary">{labels.currentDeparture}</Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Paragraph sx={{ textAlign: "right" }}>
                  {formatTime(
                    reservation?.contractualCheckInTime,
                    getI18nSelectedLanguage(),
                    property.timeZone
                  )}
                </Paragraph>
              </Grid>
            </Grid>
            <Grid container direction="row" mt={1} mb={2}>
              <Grid xs={6} item>
                <Paragraph color="text.secondary">{labels.newDeparture}</Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Paragraph sx={{ textAlign: "right" }}>
                  {formatTime(updatedArrivalTime, getI18nSelectedLanguage(), property.timeZone)}
                </Paragraph>
              </Grid>
            </Grid>
          </Box>
          <Grid container flexWrap="nowrap" alignItems="center" px={5}>
            <Slider
              track="inverted"
              value={hours}
              onChange={(event, newValue) => {
                setHours(newValue as number);
              }}
              max={service.maximumHours}
              min={0}
              aria-labelledby="input-slider"
            />
            <Paragraph className={classes.spaceNumberSlider}>
              {service.maximumHours - hours}
            </Paragraph>
          </Grid>
          <Box display="flex" alignItems="baseline" justifyContent="center" px={2.5} py={2}>
            <PricePreview price={toGross(multiply(price, service.maximumHours - hours))} />
          </Box>
        </>
      }
      buttonLabel={labels.confirmButton}
    />
  );
};
