export function getLocalStorageObject<T>(key: string): Record<string, T> {
  const cartItemsString = localStorage.getItem(key);
  if (!cartItemsString) {
    return {};
  }

  try {
    return JSON.parse(cartItemsString);
  } catch (e) {
    return {};
  }
}
