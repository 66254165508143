import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { skipPayment } from "../payment.slice";
import { PaymentDTO } from "../payment-dto";
import { useAppDispatch } from "../../../state/store";
import { useAddServicesToReservationMutation } from "../../../graphql/mutations/add-service-to-reservation.generated";
import { useCallback } from "react";
import { prepareServicesForPaymentV2 } from "../../../graphql/transform/transform-additional-services-availability";

export const usePayLater = ({
  paymentDTO,
  magicId,
  magicToken,
  onSuccessPayment
}: {
  paymentDTO: PaymentDTO;
  magicId: string;
  magicToken: string;
  onSuccessPayment: () => void;
}) => {
  const { isRESTVersion } = useApiVersion();

  const dispatch = useAppDispatch();

  const [addServiceToReservationAction] = useAddServicesToReservationMutation();

  const addServicesToReservation = useCallback(
    () =>
      paymentDTO.additionalServices && paymentDTO.additionalServices.length > 0
        ? addServiceToReservationAction({
            magicId: magicId,
            magicToken: magicToken,
            services: paymentDTO.additionalServices?.map(prepareServicesForPaymentV2) ?? []
          }).unwrap()
        : Promise.resolve(),
    [paymentDTO.additionalServices, magicToken, magicId, addServiceToReservationAction]
  );

  const skipPaymentV1 = () =>
    dispatch(skipPayment({ paymentDTO, magicId })).unwrap().then(onSuccessPayment);
  const skipPaymentV2 = () => addServicesToReservation().then(onSuccessPayment);

  return isRESTVersion ? skipPaymentV1 : skipPaymentV2;
};
