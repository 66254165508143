import React, { FC, useCallback, useContext, useState } from "react";
import { DisplayCmsSvg, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { PreferredCommunicationMethodSelection } from "../../../components/molecules/guest-flow-preferred-communication-method";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generatePortalProfileUrl } from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";

import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import {
  Editability,
  useGuestDataEditable
} from "../../booking-overview/hooks/use-guest-data-editable";
import { EditableNotification } from "../../../components/atoms/notification/editable-notification";
import { ProfileTemplate } from "../components/profile-template";
import { PreferredCommunicationAdditionalDataModal } from "../../../components/molecules/preferred-communication-additional-data-modal";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useLocation, useNavigate } from "react-router-dom";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { generateFastGuestFlowLink } from "../../guest-flow/pages/fast-guest-flow.page";

export const ProfileCommunicationPage: FC = () => {
  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const location = useLocation();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const { patchGuestFlowStep } = useGuestFlow();
  const { enabledCommunicationChannels } = useContext(TenantContext);
  const [selectedChannel, setSelectedChannel] = useState<string>(
    reservation?.extras?.preferredCommunicationChannel ?? ""
  );

  useSetupSubpageNavigation(
    location.state?.fastCheckin ? generateFastGuestFlowLink : generatePortalProfileUrl
  );

  const handleSubmit = useCallback(async () => {
    try {
      await patchGuestFlowStep({
        reservationValues: {
          ...reservation,
          extras: {
            ...reservation.extras!,
            preferredCommunicationChannel: selectedChannel ?? null
          }
        },
        subUpdate: SubUpdate.PREFERRED_CHANNEL
      });
      navigate(-1);
    } catch (e) {
      console.error("An error occurred patch profile page, preferred communication channel", e);
    }
  }, [patchGuestFlowStep, reservation, selectedChannel, navigate]);

  const { editable } = useGuestDataEditable({
    uneditableStatus: [ReservationStatus.CHECKED_OUT],
    reservation
  });
  if (editable === undefined) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Communication channel" />
      <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
        <ProfileTemplate>
          <PageHeadingInfo
            title={tCommon("title__preferred_channel_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__preferred_channel_icon?.url} />}
          />
          <EditableNotification editabilities={[editable]} />

          <PreferredCommunicationMethodSelection
            selected={selectedChannel}
            onSelect={(value) => setSelectedChannel(value)}
            disabled={editable !== Editability.EDITABLE}
            selectableChannels={enabledCommunicationChannels}
          />
          <PreferredCommunicationAdditionalDataModal
            channel={selectedChannel}
            resetToInitValue={() => setSelectedChannel("")}
            nextPageLink={generatePortalProfileUrl(reservation.magicId)}
            t={tCommon}
          />
          {selectedChannel !== reservation.extras?.preferredCommunicationChannel && (
            <SubmitButton
              label={tCommon("buttons__save")}
              hasBottomNavigation
              hasWhiteBackground
              onClick={handleSubmit}
            />
          )}
        </ProfileTemplate>
      </RoundedFullHeight>
    </>
  );
};
