import React, { FC } from "react";
import { Reservation } from "../../../domain-common/reservation";
import { useUnitGroupCmsData } from "../../../state/property-cms/use-unit-group-cms-data";
import { BookingOverviewItemBody } from "./booking-overview-item-body";
import { usePropertyById } from "../../property/use-property-by-id";
import { isBookingOnBehalfOfMyself, isBookingOnBehalfOfOthers } from "../../../util/reservation";
import { formatRange } from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

interface ReservationItemBodyProps {
  reservation: Reservation;
}

export const ReservationItemBody: FC<React.PropsWithChildren<ReservationItemBodyProps>> = ({
  reservation,
  children
}) => {
  const property = usePropertyById(reservation?.propertyId);

  const { firstStudioImage, unitGroupLabel } = useUnitGroupCmsData(
    reservation.propertyId,
    reservation?.unit?.unitGroupId || reservation?.unitGroup.id
  );

  return (
    <BookingOverviewItemBody
      title={
        reservation.unit?.id
          ? [unitGroupLabel, reservation.unit?.name].join(" ")
          : unitGroupLabel || ""
      }
      guest={`${reservation?.primaryGuest?.firstName || ""} ${
        reservation?.primaryGuest?.lastName || ""
      }`}
      isBookingOnBehalfOfMyself={isBookingOnBehalfOfMyself(reservation)}
      isBookingOnBehalfOfOthers={isBookingOnBehalfOfOthers(reservation)}
      subtitle={formatRange(
        property.timeZone,
        getI18nSelectedLanguage(),
        reservation?.arrival,
        reservation?.departure
      )}
      prismicImage={firstStudioImage}
    >
      {children}
    </BookingOverviewItemBody>
  );
};
