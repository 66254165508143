import React, { FC, useMemo } from "react";
import { Grid } from "@mui/material";
import { PaperButton } from "../../../../components";
import { generatePortalMyStayShopUrl } from "../../../../util/routing";
import { Reservation } from "../../../../domain-common/reservation";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useNavigate } from "react-router-dom";
import { useCMSData } from "../../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../../state/common-cms/common-cms.slice";
import { useFeatureFlags } from "../../../../util/hooks/use-configuration";

interface NavigationShopProps {
  reservation: Reservation;
  title?: string;
  iconUrl?: string;
}

export const NavigationShop: FC<NavigationShopProps> = ({ reservation, iconUrl, title }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const magicId = useMemo(() => reservation.magicId, [reservation.magicId]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { shopEnabled } = useFeatureFlags();

  if (shopEnabled) {
    return (
      <Grid item xs={6}>
        <PaperButton
          title={title ?? t("navigations__shop")}
          small
          onClick={() => {
            navigate(generatePortalMyStayShopUrl(magicId));
          }}
          iconUrl={iconUrl ?? cmsData?.data?.icons__my_stay_navigation_shop?.url}
        />
      </Grid>
    );
  } else {
    return null;
  }
};
