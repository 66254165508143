import { FC } from "react";
import { KeysHandlerProps } from "./keys-handler";
import { GetMobileApp } from "./get-mobile-app";
import { OpenDoorWithApp } from "./open-door-with-app";
import { useIsNative } from "@likemagic-tech/sv-magic-library";

export const KeysMobile: FC<React.PropsWithChildren<KeysHandlerProps>> = (props) => {
  const isNative = useIsNative();
  if (isNative) {
    return <OpenDoorWithApp {...props} />;
  }
  return <GetMobileApp />;
};
