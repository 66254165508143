import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Selector
} from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../../state/store";
import { BookingOverview, BookingOverviewFromJSON } from "../../../domain-common/booking-overview";
import { EntityStateStatus, isStatusLoading } from "../../../state/EntityStateStatus";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { MagicApi } from "../../../api/magic.api";
import { handleSliceError } from "../../../util/error-handling";
import {
  AdditionalServicesPaymentDTO,
  FolioPaymentDTO,
  ShopItemsPaymentDTO
} from "../../payment/payment-dto";
import { emptyFullPrice, FullPrice } from "../../../domain-common/full-price";
import { getTenantHeaders } from "../../../api/custom-headers";

export const bookingOverviewAdapter = createEntityAdapter<BookingOverview>({
  selectId: (bookingOverview: BookingOverview) => `${bookingOverview.id}_${bookingOverview.actor}`
});

const initialState = bookingOverviewAdapter.getInitialState<{
  totalPrice: FullPrice;
  status: EntityStateStatus;
  totalPriceStatus: EntityStateStatus;
}>({
  totalPrice: emptyFullPrice(),
  status: EntityStateStatus.IDLE,
  totalPriceStatus: EntityStateStatus.IDLE
});

export type BookingOverviewState = typeof initialState;

export const fetchBookerOverview = createAsyncThunk<
  BookingOverview[],
  { magicId: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>(
  "bookingOverview/fetch",
  async (arg, thunkAPI) => {
    try {
      return await MagicApi.fetchBookerView(arg.magicId, {
        signal: thunkAPI.signal,
        ...(await getTenantHeaders(thunkAPI.extra))
      });
    } catch (e) {
      return handleSliceError(e, thunkAPI.rejectWithValue);
    }
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().bookingOverview.status;
      const magicId = thunkAPI.getState().magicObject.magicObject?.magicId;
      // don't load already loaded booking overview
      if (
        magicId === arg.magicId &&
        (status === EntityStateStatus.LOADING || status === EntityStateStatus.SUCCEEDED)
      ) {
        return false;
      }
    }
  }
);

export const nextBookerOverviewMagicObject = createAction<BookingOverview>(
  "bookingOverview/nextBookerOverviewMagicObject"
);

export const fetchTotalPrice = createAsyncThunk<
  FullPrice,
  {
    magicId: string;
    shopItems?: ShopItemsPaymentDTO;
    additionalServices?: AdditionalServicesPaymentDTO;
    foliosToBePaid?: FolioPaymentDTO;
  },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("bookingOverview/fetchTotalPrice", async (arg, thunkAPI) => {
  try {
    return await MagicApi.getTotalPrice(arg, {
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const bookingOverviewSlice = createSlice({
  name: "bookingOverview",
  initialState,
  reducers: {
    initBookingOverview: () => ({
      ...initialState,
      ...bookingOverviewAdapter.getInitialState()
    }),
    clearTotalPrice: (state) => {
      state.totalPrice = emptyFullPrice();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(nextBookerOverviewMagicObject, (state, action) => {
        bookingOverviewAdapter.upsertOne(state, {
          ...action.payload,
          items: action.payload.items
        });
      })
      .addCase(fetchBookerOverview.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchBookerOverview.fulfilled, (state, action) => {
        bookingOverviewAdapter.removeAll(state);
        bookingOverviewAdapter.addMany(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchBookerOverview.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      })
      .addCase(fetchTotalPrice.pending, (state) => {
        state.totalPriceStatus = EntityStateStatus.LOADING;
      })
      .addCase(fetchTotalPrice.fulfilled, (state, action) => {
        state.totalPrice = action.payload;
        state.totalPriceStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchTotalPrice.rejected, (state) => {
        state.totalPriceStatus = EntityStateStatus.FAILED;
      });
  }
});

export const { initBookingOverview, clearTotalPrice } = bookingOverviewSlice.actions;

const { selectAll } = bookingOverviewAdapter.getSelectors<RootState>(
  (state) => state.bookingOverview
);

export const selectBookingOverview = createSelector(selectAll, (res) =>
  res.map((item) => BookingOverviewFromJSON(item))
);

const selectSelf: Selector<RootState, BookingOverviewState> = (state: RootState) =>
  state[bookingOverviewSlice.name];

export const selectTotalPrice = createSelector(selectSelf, (res) => {
  return res.totalPrice;
});

export const selectIsLoadingOverviewBooking = createSelector(selectSelf, (res) => {
  return isStatusLoading(res.status);
});
export const selectOverviewBookingStatus = createSelector(selectSelf, (res) => {
  return res.status;
});

export const selectIsLoadingOverviewBookingTotalPrice = createSelector(selectSelf, (res) => {
  return isStatusLoading(res.totalPriceStatus);
});
