import { Heading4 } from "@likemagic-tech/sv-magic-library";
import React from "react";

interface GetFolioPreviewTitleProps {
  isRESTVersion: boolean;
  tCommon: (s: string) => string;
  isMainFolio: boolean;
  number: string;
}

export const getFolioPreviewTitle = ({
  isRESTVersion,
  tCommon,
  isMainFolio,
  number
}: GetFolioPreviewTitleProps) => {
  return (
    <>
      {isRESTVersion && (
        <Heading4>
          {isMainFolio ? tCommon("labels__accommodations") : tCommon("labels__additions")}
        </Heading4>
      )}
      {!isRESTVersion && (
        <Heading4>
          {tCommon("labels__bill")} {number ? `#${number}` : ""}
        </Heading4>
      )}
    </>
  );
};
