import { useCommonChannelCodesMapperCmsData } from "./use-common-cms-data";

export const useCommonChannelCodeTranslation = (channelCode?: string): string | undefined => {
  const cmsData = useCommonChannelCodesMapperCmsData();

  return cmsData?.["channel_codes__mapper"]?.find(
    (channelCodeTranslation: { id: string; text: string }) =>
      channelCodeTranslation.id === channelCode
  )?.text;
};
