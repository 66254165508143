import { Box, Grid, useTheme } from "@mui/material";
import { FC } from "react";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import {
  PromoCodeMagicDiscount,
  PromoCodeMagicDiscountType
} from "../../../domain-common/search-unit-group";
import { PricePreview } from "../../../components/atoms/price-preview/price-preview";

interface DisplayPromocodeDiscountAmountProps {
  promoCodeMagicDiscountPercentage: number;
  promoCodeMagicDiscountType?: PromoCodeMagicDiscountType;
  promoCodeMagicDiscount?: PromoCodeMagicDiscount;
}

export const DisplayPromocodeDiscountAmount: FC<DisplayPromocodeDiscountAmountProps> = ({
  promoCodeMagicDiscountPercentage,
  promoCodeMagicDiscountType,
  promoCodeMagicDiscount
}) => {
  const theme = useTheme();
  return (
    <Grid item>
      {promoCodeMagicDiscount && (
        <Box sx={{ px: 1, bgcolor: theme.palette.error.main }}>
          <Paragraph sx={{ color: theme.palette.common.white }}>
            -{" "}
            {promoCodeMagicDiscountType === PromoCodeMagicDiscountType.PERCENTAGE ? (
              promoCodeMagicDiscountPercentage + "%"
            ) : (
              <PricePreview price={promoCodeMagicDiscount} />
            )}
          </Paragraph>
        </Box>
      )}
    </Grid>
  );
};
