export enum CmsSingleDocumentPerPropertyType {
  propertySpecfic = "property_specific",
  promoCodes = "promo_codes",
  shop = "shop",
  configuration = "configuration"
}

type PropertyId = string;

export type CmsSingleDocumentPerPropertyNamespace =
  `${CmsSingleDocumentPerPropertyType}#${PropertyId}`;

export const GuestFlowAndPortalListPerProperty: CmsSingleDocumentPerPropertyType[] = [
  CmsSingleDocumentPerPropertyType.propertySpecfic
];

export const singleDocumentWithPropertyNamespace = (
  type: CmsSingleDocumentPerPropertyType,
  propertyId: string
): CmsSingleDocumentPerPropertyNamespace => `${type}#${propertyId}`;
