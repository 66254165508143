import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { Paragraph, Subtitle } from "@likemagic-tech/sv-magic-library";
import { AccessibleDoor, Reservation } from "../../../domain-common/reservation";
import Dialpad from "@mui/icons-material/Dialpad";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";

interface SpecialKeyWithPinProps {
  specialCaseDoor?: AccessibleDoor;
  reservation: Reservation;
  t: (s: string) => string;
}
export const SpecialKeyWithPin: FC<SpecialKeyWithPinProps> = ({
  specialCaseDoor,
  reservation,
  t
}) => {
  return specialCaseDoor ? (
    <Box mb={4}>
      <Paragraph align="center">{t("labels__special_key_with_pin")}</Paragraph>
      {reservation?.unit?.name && (
        <Grid py={2} container alignItems="center" justifyContent="center">
          <DoorFrontOutlinedIcon fontSize="large" />
          <Subtitle ml={2}>{reservation?.unit?.name}</Subtitle>
        </Grid>
      )}
      <Grid py={2} container alignItems="center" justifyContent="center">
        <Dialpad />
        <Subtitle ml={2}>{specialCaseDoor.pin}</Subtitle>
      </Grid>
    </Box>
  ) : null;
};
