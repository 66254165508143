import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { SubmitButton } from "../../../components";
import { generateInitBookingOverviewUrl } from "../../booking-overview/booking-overview-navigation";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useNavigate } from "react-router-dom";

export const EmptyReservationPage = () => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const goToBookings = useCallback(() => {
    navigate(generateInitBookingOverviewUrl(magicId));
  }, [magicId, navigate]);

  return (
    <RoundedFullHeight pb={2} hasShadow>
      <Box mt={25} px={5}>
        <Paragraph fontSize="24px" lineHeight="28px" align="center">
          {tCommon("labels__no_reservation_selected")}
        </Paragraph>
        <SubmitButton
          label={tCommon("buttons__go_to_booking")}
          onClick={goToBookings}
          hasWhiteBackground
          hasBottomNavigation
        />
      </Box>
    </RoundedFullHeight>
  );
};
