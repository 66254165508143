import {
  AdditionalTravelBuddyFieldsConfig,
  parseDateString
} from "@likemagic-tech/sv-magic-library";
import yup from "../config/yup.config";
import { differenceInYears } from "date-fns";

export const MINOR_AGE = 18;

export const additionalTravelBuddyFieldValidation = (
  fields: AdditionalTravelBuddyFieldsConfig | undefined,
  validationCmsData: Record<string, any> | undefined,
  myStay: boolean
) => {
  const shape: any = {};
  const addressShape: any = {};

  if (fields?.dateOfBirth && fields?.dateOfBirth?.required) {
    shape["birthdate"] = yup
      .date()
      .nullable()
      .transform((_, originalValue: Date) => parseDateString(originalValue))
      .min("1900-01-01", validationCmsData?.validation__invalid_format || undefined)
      .max("2100-01-01", validationCmsData?.validation__invalid_format || undefined)
      .required(validationCmsData?.validation__birthdate_required || undefined)
      .typeError(validationCmsData?.validation__invalid_format || undefined);
  }

  if (fields?.nationalityCountryCode && fields?.nationalityCountryCode?.required) {
    shape["nationalityCountryCode"] = yup
      .string()
      .nullable()
      .required(validationCmsData?.validation__country_required || undefined);
  }

  if (fields?.countryCode && fields?.countryCode?.required) {
    addressShape["countryCode"] = yup
      .string()
      .nullable()
      .required(validationCmsData?.validation__country_required || undefined);
  }

  if (fields?.postalCode && fields?.postalCode?.required) {
    addressShape["postalCode"] = yup
      .string()
      .nullable()
      .required(validationCmsData?.validation__postal_code_required || undefined)
      .max(20, validationCmsData?.validation__postal_code_max || undefined);
  }

  if (fields?.city && fields?.city?.required) {
    addressShape["city"] = yup
      .string()
      .nullable()
      .required(validationCmsData?.validation__city_required || undefined);
  }

  if (fields?.addressLine1 && fields?.addressLine1?.required) {
    shape["addressLine1"] = yup
      .string()
      .nullable()
      .default("")
      .max(200, validationCmsData?.validation__address_max || undefined)
      .required(validationCmsData?.validation__address_required || undefined);
  }

  shape["address"] = yup.object().shape(addressShape).nullable();

  return myStay
    ? yup.object().shape({
        travelBuddy: yup.object().shape(shape)
      })
    : yup.object({ additionalGuests: yup.array().of(yup.object().shape(shape)) });
};

export const calculateAge = (birthDate: string | Date) =>
  differenceInYears(new Date(), new Date(birthDate));
