import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UpdatePrimaryAndSecondaryGuestDetailsMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  persons: Array<Types.SecondaryPersonInput> | Types.SecondaryPersonInput;
  primaryGuest: Types.UpdatePersonInput;
  flowState: Types.FlowStateUpdateInput;
  magicToken: Types.Scalars["String"];
}>;

export type UpdatePrimaryAndSecondaryGuestDetailsMutation = {
  __typename?: "Mutation";
  UpdateMainActor: { __typename?: "Person"; email?: string | null };
  UpdateSecondaryGuestDetails: Array<{ __typename?: "Person"; email?: string | null }>;
  UpdateFlowState: { __typename: "ReservationFlowState" };
};

export const UpdatePrimaryAndSecondaryGuestDetailsDocument = `
    mutation UpdatePrimaryAndSecondaryGuestDetails($magicId: String!, $persons: [SecondaryPersonInput!]!, $primaryGuest: UpdatePersonInput!, $flowState: FlowStateUpdateInput!, $magicToken: String!) {
  UpdateMainActor(
    magicId: $magicId
    person: $primaryGuest
    magicToken: $magicToken
  ) {
    email
  }
  UpdateSecondaryGuestDetails(
    magicId: $magicId
    persons: $persons
    magicToken: $magicToken
  ) {
    email
  }
  UpdateFlowState(
    flowState: $flowState
    magicId: $magicId
    magicToken: $magicToken
  ) {
    __typename
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UpdatePrimaryAndSecondaryGuestDetails: build.mutation<
      UpdatePrimaryAndSecondaryGuestDetailsMutation,
      UpdatePrimaryAndSecondaryGuestDetailsMutationVariables
    >({
      query: (variables) => ({ document: UpdatePrimaryAndSecondaryGuestDetailsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdatePrimaryAndSecondaryGuestDetailsMutation } = injectedRtkApi;
