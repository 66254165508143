export type FileWithPreview = { preview: string } & File;

export const assignPreviewToFile = (file: File | null): FileWithPreview | null => {
  if (!file) {
    return file;
  }

  return Object.assign({}, file, { preview: URL.createObjectURL(file) });
};

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/jpeg" });
}
