import { GuestFlowContext } from "./guest-flow-checkpoint-and-args";

export enum FlowStateEnum {
  BOOKING_INIT = "BOOKING_INIT",
  RESERVATION_INIT = "RESERVATION_INIT"
}

export interface FlowState<FC extends GuestFlowContext> {
  wasOrIsCompleted: boolean;
  completed: boolean;
  context: FC | null; // MIssing
  notificationPending: boolean;
  notificationSent: boolean;
  sentNotifications: string[];
  performedActions: string[]; // MIssing
  fastCheckinAvailable?: boolean; // MIssing
}

export interface HasFlowState<FC extends GuestFlowContext> {
  flowState: FlowState<FC>;
}

export const HasFlowStateFromJSON = <FC extends GuestFlowContext>(json: any): HasFlowState<FC> => {
  if (!json) {
    return json;
  }

  return {
    flowState: {
      wasOrIsCompleted: json["flowState"]["wasOrIsCompleted"],
      completed: json["flowState"]["completed"],
      context: json["flowState"]["context"],
      notificationPending: json["flowState"]["notificationPending"],
      notificationSent: json["flowState"]["notificationSent"],
      sentNotifications: json["flowState"]["sentNotifications"],
      performedActions: json["flowState"]["performedActions"],
      fastCheckinAvailable: json["flowState"]["fastCheckinAvailable"]
    }
  };
};
