import { Heading2, Paragraph, Subtitle } from "@likemagic-tech/sv-magic-library";
import { Box } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { DesktopCard } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { useGuestFlow } from "../use-guest-flow";
import { getTagReaderId } from "../../../util/tag-reader-id";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { KeyAssignHandler } from "../../key-assign/key-assign-handler";
import { useNavigate } from "react-router-dom";
import { useKioskConfig } from "../../../util/hooks/use-configuration";

export const AssignKeyPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { reservation, skipButton } = useGuestFlow();
  const tagReaderIdOld = getTagReaderId();
  const { tagReaderId } = useKioskConfig();
  const { isBottomNavbarActive } = useBottomNavbar();
  const navigate = useNavigate();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const logoutAction = useCallback(() => {
    navigate(`/logout`);
  }, [navigate]);

  const successComponent = useMemo(
    () => (
      <Box textAlign="center" mt={7} mx={6}>
        <Heading2 fontSize={20} variant="subtitle1">
          {t("confirmations__assign_key")}
        </Heading2>

        {reservation?.unit?.name && (
          <Subtitle mt={1}>
            {t("labels__unit")} <strong>{reservation?.unit?.name}</strong>
          </Subtitle>
        )}
      </Box>
    ),
    [t, reservation?.unit?.name]
  );

  if (!tagReaderId && !tagReaderIdOld) {
    return (
      <Box mt={25} px={5}>
        <DesktopCard>
          <Paragraph align="center">{t("labels__no_tagreaderid")}</Paragraph>
        </DesktopCard>
      </Box>
    );
  }

  return (
    <>
      <HelmetTitle suffix="Guest flow assign key" />
      <FlowTemplate icons={[skipButton]}>
        <DesktopCard>
          <GuestFlowBackgroundBox bottomNavBarIsHidden={!isBottomNavbarActive} progressBarIsHidden>
            <KeyAssignHandler
              successComponent={successComponent}
              reservation={reservation}
              encoderId={tagReaderId || tagReaderIdOld}
              logout={logoutAction}
            />
          </GuestFlowBackgroundBox>
        </DesktopCard>
      </FlowTemplate>
    </>
  );
};
