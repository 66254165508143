import { useCallback, useContext, useEffect } from "react";
import { WebSocketContext } from "./web-socket-provider";
import { setSessionId } from "../gtm/gtm.slice";
import { useDispatch } from "react-redux";
import { generateSmallUUID } from "../../util/data";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { MagicObject, MagicType } from "../../domain-common/magic-object";
import { nextMagicObject } from "../magic/magic-object.slice";
import { nextBookerOverviewMagicObject } from "../booking-overview/state/booking-overview.slice";
import { isKioskMode } from "../../util/kiosk-mode";
import { getTagReaderId } from "../../util/tag-reader-id";

export enum SubscriptionUseCase {
  BOOKING = "BOOKING",
  PORTAL = "PORTAL"
}

export type SubscriptionEventData =
  | { type: "KEEP_ALIVE" }
  | {
      type: "MAGIC_OBJECT";
      magicId: string;
      magicObject?: MagicObject;
    }
  | {
      type: "ERROR";
      status: number;
      sessionId: string;
      messageId: string;
    }
  | {
      type: "SUBSCRIPTION";
      sessionId: string;
      status: number;
      messageId: string;
      magicId: string;
      magicObject: MagicObject;
    };

export const useMagicWebSocket = () => {
  const { ws, initialised, lastIdentifierToSubscribe } = useContext(WebSocketContext);
  const { apiKey } = useContext(TenantContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (ws.current) {
      ws.current.onmessage = (ev: MessageEvent) => {
        try {
          const data: SubscriptionEventData = JSON.parse(ev.data);
          if (data.type === "KEEP_ALIVE" || data.type === "ERROR") {
            return;
          }
          if (data.type === "SUBSCRIPTION") {
            if (data.magicObject.magicType !== MagicType.BOOKER_OVERVIEW) {
              dispatch(nextMagicObject(data.magicObject));
            } else if (data.magicObject?.magicType === MagicType.BOOKER_OVERVIEW) {
              // @ts-ignore
              dispatch(nextBookerOverviewMagicObject(data.magicObject));
            }
            dispatch(setSessionId({ sessionId: data.sessionId }));
          }
          if (data.type === "MAGIC_OBJECT") {
            if (data.magicObject?.magicType !== MagicType.BOOKER_OVERVIEW) {
              dispatch(nextMagicObject(data.magicObject));
            } else if (data.magicObject?.magicType === MagicType.BOOKER_OVERVIEW) {
              // @ts-ignore
              dispatch(nextBookerOverviewMagicObject(data.magicObject));
            }
          }
        } catch (e) {
          console.log(e);
        }
      };

      ws.current.onclose = (event) => {
        console.warn(event);
        lastIdentifierToSubscribe.current = undefined;
      };
    }
  }, [ws, dispatch, apiKey, lastIdentifierToSubscribe, initialised]);

  const subscribe = useCallback(
    (magicId: string, magicToken: string, useCase: SubscriptionUseCase) => {
      if (
        ws.current &&
        initialised &&
        `${magicId}_${useCase}` !== lastIdentifierToSubscribe.current
      ) {
        const messageId = generateSmallUUID();
        lastIdentifierToSubscribe.current = `${magicId}_${useCase}`;

        ws.current.send(
          JSON.stringify({
            type: "SUBSCRIPTION",
            messageId,
            context: {
              magicId,
              magicToken,
              magicApiKey: apiKey,
              useCase,
              ...(isKioskMode() && {
                kioskTerminalId: getTagReaderId()
              })
              //TODO handle new config in here.
            }
          })
        );
      }
    },
    [ws, apiKey, initialised, lastIdentifierToSubscribe]
  );
  return { subscribe, initialised };
};
