import "@adyen/adyen-web/dist/adyen.css";
import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useAppDispatch } from "../../../state/store";
import { useSelector } from "react-redux";
import { clearPaymentState, selectPaymentSlice } from "../payment.slice";
import { Loader } from "../../../components/atoms/loaders/loader";
import { EntityStateStatus } from "../../../state/EntityStateStatus";
import { usePayment } from "./use-payment";
import { PaymentRedirectProps } from "../payment-redirect";

export const PaymentAdyenRedirect: React.FC<React.PropsWithChildren<PaymentRedirectProps>> = ({
  onSuccess,
  onFailed
}) => {
  const { search: queryParamsString } = useLocation();
  const { magicId } = useMagicIdParams();
  const dispatch = useAppDispatch();
  const { paymentDTO } = useSelector(selectPaymentSlice);
  const onSuccessPayment = useCallback(
    async (bookingMagicId?: string) => {
      onSuccess(bookingMagicId || magicId);
      dispatch(clearPaymentState());
    },
    [onSuccess, dispatch, magicId]
  );
  const {
    onPaymentDetailsSubmit,
    paymentDetailsResponse,
    processPaymentDetailsResponse,
    paymentDetailsStatus
  } = usePayment({ magicId, onSuccessPayment });

  const redirectResult = useMemo(() => {
    const queryParams = new URLSearchParams(queryParamsString);
    return queryParams.get("redirectResult");
  }, [queryParamsString]);

  useEffect(() => {
    if (!paymentDTO.adyenPrice.amount) {
      // Wait for Redux to restore payment slice
      return;
    }

    if (redirectResult) {
      onPaymentDetailsSubmit({ data: { details: { redirectResult: redirectResult } } }, null);
    }
  }, [redirectResult, paymentDTO, onPaymentDetailsSubmit]);

  // Handle paymentDetails response
  useEffect(() => {
    if (paymentDetailsStatus === EntityStateStatus.SUCCEEDED) {
      processPaymentDetailsResponse(paymentDetailsResponse);
    } else if (paymentDetailsStatus === EntityStateStatus.FAILED) {
      onFailed(magicId);
    }
  }, [
    paymentDetailsResponse,
    processPaymentDetailsResponse,
    paymentDetailsStatus,
    magicId,
    onFailed
  ]);

  return <Loader visible />;
};
