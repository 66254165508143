import { PrismicDocument } from "@prismicio/client";

import { MyStayEventDetails } from "../domain-common/my-stay-event-details";
import { getI18nSelectedLanguage } from "../util/lang-utils";

function formatTime(dateString: string) {
  return new Date(dateString).toLocaleTimeString(getI18nSelectedLanguage(), {
    hour: "2-digit",
    minute: "2-digit"
  });
}

export function PrismicDocumentToMyStayEventDetailsMapper(
  doc: PrismicDocument
): MyStayEventDetails {
  const from = doc.data["main__from"];
  const to = doc.data["main__to"];

  const date = from ? new Date(from).toLocaleDateString(getI18nSelectedLanguage()) : "";

  let time = from ? formatTime(from) : "";
  if (to && time) {
    time = [time, formatTime(to)].join("-");
  }

  const hasLocation =
    doc.data["main__address_line_1"] ||
    doc.data["main__address_line_2"] ||
    doc.data["main__city"] ||
    doc.data["main__zip"] ||
    doc.data["main__country"];

  const location = hasLocation
    ? {
        addressLine1: doc.data["main__address_line_1"],
        addressLine2: doc.data["main__address_line_2"],
        city: doc.data["main__city"],
        zip: doc.data["main__zip"],
        country: doc.data["main__country"],
        href: doc.data["main__google_maps_link"]
      }
    : undefined;

  return {
    id: doc.id,
    title: doc.data["main__title"],
    subtitle: doc.data["details__subtitle"],
    date,
    time,
    coverImage: doc.data["main__cover_image"]?.["url"],
    description: doc.data["details__event_description"],
    location
  };
}
