import { MyStayTipCard } from "../../../../domain-common/my-stay-tip-card";
import React, { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface TipLinkProps {
  tip: MyStayTipCard;
}

const isInternalLink = (link?: string): boolean => Boolean(link?.startsWith("/"));

export const TipLink: FC<PropsWithChildren<TipLinkProps>> = ({ tip, children }) => {
  // only for install app link
  if (tip.onClick) {
    return (
      <div style={{ textDecoration: "none" }} onClick={tip.onClick}>
        {children}
      </div>
    );
  }
  // if there is no link
  if (!tip.link) {
    return <div>{children}</div>;
  }
  // internal links
  if (isInternalLink(tip.link)) {
    return (
      <Link to={tip.link} style={{ textDecoration: "none" }}>
        {children}
      </Link>
    );
  }
  // external links
  return (
    <a href={tip.link} style={{ textDecoration: "none" }} target="_blank" rel="noreferrer noopener">
      {children}
    </a>
  );
};
