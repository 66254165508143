import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { getLocationOrigin } from "../../util/routing";

const DeepLinkDetector: React.FC<React.PropsWithChildren<any>> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slug = event.url.split(getLocationOrigin());

      if (slug && slug.length > 1) {
        navigate(slug[1]);
      }
    });
  }, [navigate]);

  return null;
};

export default DeepLinkDetector;
