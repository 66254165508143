import { calculateAge, MINOR_AGE } from "../additional-travel-buddy-fields-validation";

export const useIsMinor = () => {
  const isMinor = (birthdate: Date | string) => {
    const age = birthdate ? calculateAge(birthdate) : undefined;

    return age !== undefined && age < MINOR_AGE;
  };

  return { isMinor };
};
