import { PrismicDocument } from "@prismicio/client";
import { MyStayTipCard, TipType } from "../domain-common/my-stay-tip-card";
import { MyStayTipContentType } from "../domain-common/my-stay-tip-content-type";
import {
  generateInfoStayArticleUrl,
  generateMagicLink,
  generatePortalMyStayArticleDetailsUrl,
  isInternalLink
} from "../util/routing";

function getLink(doc: PrismicDocument, magicId?: string, propertyId?: string): string | undefined {
  if (!isInternalLink(doc.data["main__link"]) && doc.type === TipType.LINK) {
    // external link
    return doc.data["main__link"];
  }

  if (!magicId) {
    return generateInfoStayArticleUrl(doc.id, propertyId);
  }

  if (doc.type === TipType.LINK) {
    //internal link
    return generateMagicLink(magicId) + doc.data["main__link"];
  }

  if (doc.data["main__content_type"] === MyStayTipContentType.QUOTE) {
    // quote article doesn't handle click
    return;
  }

  return generatePortalMyStayArticleDetailsUrl(magicId, doc.id);
}

export function PrismicDocumentToMyStayTipCardMapper(magicId?: string, propertyId?: string) {
  return (doc: PrismicDocument): MyStayTipCard => ({
    id: doc.id,
    propertyId: doc.data["main__property_id"],
    title: doc.data["main__title"],
    subtitle: doc.data["card__subtitle"],
    coverImage: doc.data["main__cover_image"]?.["url"],
    contentType: doc.data["main__content_type"],
    type: doc.type as TipType,
    link: getLink(doc, magicId, propertyId),
    tags: doc.tags
  });
}
