import { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../state/store";
import { usePropertyCmsData } from "../../state/property-cms/use-property-cms-data";
import { Property } from "../../domain-common/properties-by-city";
import {
  fetchPropertiesByCity,
  selectPropertiesByCity,
  selectPropertiesByCityStatus
} from "./properties-by-city";
import { useGetAllPropertiesQuery } from "../../graphql/queries/GetProperty.generated";
import { mapQueryStatusToEntityStateStatus } from "../../state/EntityStateStatus";
import { TenantContext, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { transformPropertyV2ToCommon } from "../../graphql/transform/transform-property";

interface PropertyWithName extends Property {
  name: string;
  location: string;
}

const emptyPropertyByCity: Property = {
  currencyCode: "",
  propertyId: "",
  timeZone: ""
};

export const useProperties = () => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();
  const { apiKey, tenantId } = useContext(TenantContext);

  const property = useSelector(selectPropertiesByCity);

  const propertiesByCityStatus = useSelector(selectPropertiesByCityStatus);
  const { data, status } = useGetAllPropertiesQuery(
    {},
    { skip: isRESTVersion, refetchOnMountOrArgChange: false }
  );

  useEffect(() => {
    if (isRESTVersion) {
      dispatch(fetchPropertiesByCity());
    }
  }, [dispatch, isRESTVersion, apiKey, tenantId]);

  const computedData = isRESTVersion ? property : transformPropertyV2ToCommon(data);
  const computedStatus = isRESTVersion
    ? propertiesByCityStatus
    : mapQueryStatusToEntityStateStatus(status);

  return { data: computedData, status: computedStatus };
};

export const usePropertyById = (propertyId?: string): PropertyWithName => {
  const { data } = useProperties();

  const property = useMemo(
    () =>
      Object.values(data)
        ?.flatMap((properties) => properties)
        ?.find((property) => property.propertyId === propertyId),
    [data, propertyId]
  );
  const { propertyName, propertyLocation } = usePropertyCmsData(propertyId);

  return {
    ...emptyPropertyByCity,
    ...property,
    name: propertyName || propertyId || "",
    location: propertyLocation
  };
};
