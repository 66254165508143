import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";

interface Pin {
  lat: number;
  lng: number;
  label: string;
  address: string;
  iconUrl: string;
  onClick?: () => void;
}

const usePinStyle = makeStyles()(({ palette, spacing, shape }) => ({
  tooltipSpan: {
    marginLeft: spacing(-2),
    marginTop: spacing(-6),
    background: palette.grey[500],
    whiteSpace: "nowrap",
    borderRadius: shape.borderRadius * 2,
    padding: spacing(0.5),
    color: `${palette.common.white}!important`
  }
}));

const DEFAULT_ZOOM = 16;

const setInitZoomLevel = (googleMap: google.maps.Map, markersNo: number) => {
  const zoomChangeBoundsListener = google.maps.event.addListenerOnce(
    googleMap,
    "bounds_changed",
    function () {
      if ((markersNo === 1 && googleMap?.getZoom()) || 0 > DEFAULT_ZOOM) {
        googleMap.setZoom(DEFAULT_ZOOM);
      } else if (markersNo > 1 && googleMap?.getZoom()) {
        //Zoom out one level so that tooltip could fit
        googleMap.setZoom((googleMap.getZoom() || 1) - 1);
      }
    }
  );
  setTimeout(function () {
    google.maps.event.removeListener(zoomChangeBoundsListener);
  }, 2000);
};

const initGoogleMap = (googleMapRef: HTMLDivElement) =>
  new window.google.maps.Map(googleMapRef, {
    disableDefaultUI: true,
    zoom: DEFAULT_ZOOM
  });
export const MapPreviewGoogleJs: FC<{ markerList: Array<Pin> }> = ({ markerList }) => {
  const { googleMaps } = useContext(TenantContext);
  const { classes } = usePinStyle();
  const [status, setStatus] = useState<Status>();
  const theme = useTheme();

  const googleMapRef = useRef<HTMLDivElement | null>(null);

  const createMarker = useCallback(
    (markerObj: Pin, googleMap: any) => {
      const marker = new window.google.maps.Marker({
        position: {
          lat: markerObj.lat,
          lng: markerObj.lng
        },
        clickable: !!markerObj.onClick,
        map: googleMap,
        ...(markerObj.iconUrl && {
          icon: {
            url: markerObj.iconUrl,
            scaledSize: new window.google.maps.Size(40, 40)
          }
        }),
        ...(markerObj.label && {
          label: {
            className: classes.tooltipSpan,
            text: markerObj.label
          }
        })
      });

      if (markerObj.onClick) {
        google.maps.event.addListener(marker, "click", function () {
          markerObj.onClick?.();
        });
      }

      return marker;
    },
    [classes.tooltipSpan]
  );

  useEffect(() => {
    try {
      if (status === Status.SUCCESS && googleMapRef.current) {
        let bounds = new window.google.maps.LatLngBounds();
        const googleMap = initGoogleMap(googleMapRef.current);

        if (googleMap) {
          setInitZoomLevel(googleMap, markerList.length);
          markerList.forEach((x) => {
            const marker = createMarker(x, googleMap);
            if (marker && marker.getPosition()) {
              // @ts-ignore
              bounds.extend(marker.getPosition());
            }
          });

          googleMap.fitBounds(bounds);
        }
      }
    } catch (e) {
      console.log("maps", e);
    }
  }, [markerList, createMarker, status]);

  if (!googleMaps?.apiKey) {
    return <></>;
  }
  return (
    <Wrapper
      apiKey={googleMaps?.apiKey}
      callback={(status) => {
        setStatus(status);
      }}
    >
      <div
        ref={googleMapRef}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: theme.shape.borderRadius * 2.5
        }}
      />
    </Wrapper>
  );
};
