import { LogLevel } from "@likemagic-tech/sv-magic-library";
import fetchIntercept, { FetchInterceptorResponse } from "fetch-intercept";
import { LoggerApi } from "../api/logger.api";

const orgInfoLog = console.info;
const orgErrorLog = console.error;
const orgWarnLog = console.warn;

function interpolate(data: any, argumentArray?: Array<any>): string {
  const regex = /%s/;
  const _r = function (p: string, c: string) {
    return p.replace(regex, c);
  };

  return Array.isArray(argumentArray) ? argumentArray?.reduce(_r, data) : JSON.stringify(data);
}

const mapLogFn: Record<LogLevel, (data: any) => void> = {
  [LogLevel.NONE]: () => {},
  [LogLevel.WARN]: orgWarnLog,
  [LogLevel.ERROR]: orgErrorLog,
  [LogLevel.INFO]: orgInfoLog
};

//TODO implement skip by text properly
// const skipErrorsWithText = ["To locate the bad setState() call inside"];
const postLog = (logLevel: LogLevel, data: any, optionalParams?: Array<any>) => {
  const logText = interpolate(data, optionalParams);

  LoggerApi.postLog({
    logLevel: logLevel,
    payload: { data, optionalParams }
  });

  return mapLogFn[logLevel](logText);
};

const logLevelHierarchy = [LogLevel.NONE, LogLevel.ERROR, LogLevel.WARN, LogLevel.INFO];

export const isLogLevelActive = (config: LogLevel, logLevel: LogLevel) => {
  const indexOfConfig = logLevelHierarchy.indexOf(config);
  const index = logLevelHierarchy.indexOf(logLevel);

  return indexOfConfig >= index;
};

export const initLogger = (configLogLevel: LogLevel) => {
  fetchIntercept.register({
    response: (r: FetchInterceptorResponse) => {
      if (!r.ok) {
        const headers = {} as any;
        r.request.headers.forEach((value, key) => {
          headers[key] = value;
        });
        console.warn(
          `HTTP request to ${r.url ?? r.request.url} failed with headers: ${JSON.stringify(
            headers
          )}`
        );
      }

      return r;
    }
  });
  if (isLogLevelActive(configLogLevel, LogLevel.INFO)) {
    console.info = function (data, ...optionalParams) {
      postLog(LogLevel.INFO, data, optionalParams);
    };
  }

  if (isLogLevelActive(configLogLevel, LogLevel.WARN)) {
    console.warn = function (data, ...optionalParams) {
      postLog(LogLevel.WARN, data, optionalParams);
    };
  }
  if (isLogLevelActive(configLogLevel, LogLevel.ERROR)) {
    console.error = function (data, ...optionalParams) {
      postLog(LogLevel.ERROR, data, optionalParams);
    };
  }
};
