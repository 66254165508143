import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function AlertCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="1px"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12,16.5a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,16.5h0"
      />
      <line
        fill="none"
        stroke="currentColor"
        strokeWidth="1px"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="12"
        y1="13.5"
        x2="12"
        y2="5.25"
      />
      <circle
        fill="none"
        stroke="currentColor"
        strokeWidth="1px"
        strokeMiterlimit="10"
        cx="12"
        cy="12"
        r="11.25"
      />
    </SvgIcon>
  );
}
