import { Box, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useCallback, useMemo, useState } from "react";
import { Dialog } from "../../../components";
import { formatToIsoDate, Paragraph } from "@likemagic-tech/sv-magic-library";
import { PricePreview } from "../../../components/atoms/price-preview/price-preview";
import { DateAndCount } from "../../../api/dto/additional-services-availability-response";
import { ServiceModalHandlerProps } from "../service-selection/additional-service-modal-handler";
import { Counter } from "../../../components/molecules/counter";
import { useCmsPropertySpecificData } from "../../../state/cms/use-cms-per-property-data";
import { useServiceModalCmsData } from "../../../state/property-cms/use-service-modal-cms-data";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { multiply, toGross } from "../../../domain-common/full-price";

const useStyles = makeStyles()(() => ({
  dialogContentContainer: {
    alignSelf: "center"
  },
  counter: {
    "& div": {
      "& p": {
        fontSize: 28
      }
    },
    "& button": {
      padding: 0,
      borderRadius: 40,
      height: 40,
      width: 40,
      "& svg": {
        width: "100%",
        height: "100%"
      }
    }
  }
}));

export const CounterPickerDialog: React.FC<React.PropsWithChildren<ServiceModalHandlerProps>> = ({
  onClose,
  onChange,
  reservation,
  price,
  service,
  cartValue
}) => {
  const availableDays: Date[] = (service.bookableDates || []).map(
    (dateAndCount: DateAndCount) => new Date(dateAndCount.date) // TODO LM-374 use proper object
  );
  const countFirstDay = useMemo(
    () => cartValue?.dates?.[0]?.count || reservation.adults + reservation.childrenAges.length,
    [cartValue, reservation.adults, reservation.childrenAges]
  );
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const [count, setCount] = useState<number>(countFirstDay);

  const cmsPropertySpecificData = useCmsPropertySpecificData(reservation.propertyId);
  const { translatedKeys: datePickerTranslatedKeys } =
    useServiceModalCmsData(cmsPropertySpecificData);

  const labels = useMemo(
    () => ({
      buttonLabel: datePickerTranslatedKeys[service.serviceId]?.button,
      descriptionLabel: datePickerTranslatedKeys[service.serviceId]?.description,
      datePickerTitle: datePickerTranslatedKeys[service.serviceId]?.title,
      datePickerConfirmButton: t("buttons__confirm"),
      counterLabel: datePickerTranslatedKeys[service.serviceId]?.counter_label
    }),
    [datePickerTranslatedKeys, service.serviceId, t]
  );

  const { classes } = useStyles();

  const handleChange = useCallback(() => {
    if (count === 0) {
      onChange({ values: [] });
      return;
    }
    const values = availableDays.map((date) => ({
      date: formatToIsoDate(date),
      count: count
    }));
    onChange({ values });
  }, [onChange, count, availableDays]);

  return (
    <Dialog
      open={true}
      onConfirm={handleChange}
      onDismiss={onClose}
      title={labels.datePickerTitle}
      content={
        <Box className={classes.dialogContentContainer}>
          <Box py={2}>
            <Paragraph textAlign={"center"} pb={1}>
              {labels.counterLabel}
            </Paragraph>
            <Counter
              classes={{ root: classes.counter }}
              initialCount={countFirstDay}
              // user has to be able to go to 0, in order to clear the cart
              minValue={0}
              maxValue={service.maximum}
              onCountChange={setCount}
            />
          </Box>
          <Divider />
          <Box display="flex" alignItems="baseline" justifyContent="center" px={2.5} py={2}>
            <PricePreview price={toGross(multiply(price, availableDays.length * count))} />
          </Box>
        </Box>
      }
      buttonLabel={labels.datePickerConfirmButton}
    />
  );
};
