import { useMemo } from "react";

export const useServiceModalCmsData = (cmsData?: any) => {
  const translatedKeys: {
    [key: string]: {
      button: string;
      description: string;
      title: string;
      select_all: string;
      legend_highlighted: string;
      legend_selected: string;
      counter_label: string;
    };
  } = useMemo(
    () =>
      cmsData?.data?.service_modals?.reduce((acc: any, item: any) => {
        acc[item.id] = {
          ...item
        };
        return acc;
      }, {}) || {},
    [cmsData?.data?.service_modals]
  );
  return {
    translatedKeys
  };
};

export const useLateCheckoutModalCmsData = (cmsData?: any) => {
  const translatedKeys: {
    [key: string]: {
      button: string;
      description: string;
      title: string;
      subtitle: string;
      current_departure: string;
      new_departure: string;
    };
  } = useMemo(
    () =>
      cmsData?.data?.late_checkout_modals?.reduce((acc: any, item: any) => {
        acc[item.id] = {
          ...item
        };
        return acc;
      }, {}) || {},
    [cmsData?.data?.late_checkout_modals]
  );
  return {
    translatedKeys
  };
};
