import { GuestFlowContext } from "../../../domain-common/guest-flow-checkpoint-and-args";
import { Reservation } from "../../../domain-common/reservation";
import { CheckpointService } from "../../flow/checkpoint-service";
import { getNextFlowPage } from "../guest-flow-sequence";

// checkpoint is based on the 'lastConfirmedPage' flowState variable within the reservation.
// The value is retrieved from the backend and updated on page transition
export class GuestFlowBackendCheckpointService
  implements CheckpointService<Reservation, Reservation>
{
  save(): void {}

  async restore(reservation: Reservation): Promise<string> {
    return getNextFlowPage(
      (reservation.flowState?.context as GuestFlowContext)?.lastConfirmedPage,
      reservation
    );
  }
}
