import React, { FC, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { MyStayPagesEnum, MyStayPageToPath } from "../portal/my-stay-page-to-path";
import { NotFoundPage } from "../../components/organism/not-found.page";
import { InfoStayArticlePage } from "./pages/info-stay-article.page";
import { useSelector } from "react-redux";
import { selectLastMagicId } from "../restore-magic-context/restore-magic-context.slice";
import { generatePortalMyStayUrl } from "../../util/routing";
import { useNavigate } from "react-router";
import { InfoStayPage } from "./pages/info-stay.page";

export const InfoStayRouter: FC = () => {
  const lastMagicId = useSelector(selectLastMagicId);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (lastMagicId) {
      navigate(pathname.replace("/info-stay", generatePortalMyStayUrl(lastMagicId)));
    }
  }, [pathname, lastMagicId, navigate]);

  return (
    <Routes>
      <Route path="/" element={<InfoStayPage />} />
      <Route
        path={`${MyStayPageToPath[MyStayPagesEnum.ARTICLES]}/:articleId`}
        element={<InfoStayArticlePage />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
