import React, { FC } from "react";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import {
  CmsSingleDocumentPerPropertyType,
  singleDocumentWithPropertyNamespace
} from "../../../../state/cms/cms-single-document-per-property-type";
import { TipThumbnailCard } from "../tip-cards/tip-thumbnail-card";
import { MyStayTipContentType } from "../../../../domain-common/my-stay-tip-content-type";
import { TipType } from "../../../../domain-common/my-stay-tip-card";
import { generatePortalMyStayArticleDetailsUrl } from "../../../../util/routing";
import { useCmsPropertySpecificData } from "../../../../state/cms/use-cms-per-property-data";
import { Grid } from "@mui/material";

interface WifiCredentialsTipItemProps {
  magicId: string;
  propertyId: string;
  wifiCredentialsAvailable: boolean;
}

export const WiFiCredentialsTipItem: FC<WifiCredentialsTipItemProps> = ({
  magicId,
  propertyId,
  wifiCredentialsAvailable
}) => {
  const { t } = useTranslateWrapper({
    namespace: [
      singleDocumentWithPropertyNamespace(
        CmsSingleDocumentPerPropertyType.propertySpecfic,
        propertyId
      )
    ]
  });

  const cmsData = useCmsPropertySpecificData(propertyId);

  return wifiCredentialsAvailable ? (
    <Grid item xs={6} md={3}>
      <TipThumbnailCard
        tip={{
          id: "wiFiCredentials",
          contentType: MyStayTipContentType.THUMBNAIL_CARD,
          type: TipType.ARTICLE,
          title: t("articles__title_wifi_credentials"),
          coverImage: cmsData?.data?.articles__cover_image_wifi_credentials?.url,
          link: generatePortalMyStayArticleDetailsUrl(magicId, "wifi-credentials")
        }}
      />
    </Grid>
  ) : null;
};
