import { FC, useEffect } from "react";
import { usePwaConfig } from "../../../util/hooks/use-configuration";

export const FaviconSetter: FC<React.PropsWithChildren<unknown>> = () => {
  const pwa = usePwaConfig();
  useEffect(() => {
    const faviconUpdate = async () => {
      if (pwa) {
        const favicon192: any = document.getElementById("favicon-192");
        const favicon32: any = document.getElementById("favicon-32");
        const favicon16: any = document.getElementById("favicon-16");
        favicon192.href = `${window.location.origin}/${pwa?.icons.logoMedium}`;
        favicon32.href = `${window.location.origin}/${pwa?.icons.logoMedium}`;
        favicon16.href = `${window.location.origin}/${pwa?.icons.logoMedium}`;
      }
    };
    faviconUpdate();
  }, [pwa]);

  return null;
};
