import { useSelector } from "react-redux";
import { selectPaymentSlice } from "../payment.slice";
import { useCreateBookingMutation } from "../../../graphql/mutations/create-booking.generated";
import { useCreatePaymentRequestMutation } from "../../../graphql/mutations/create-payment-request.generated";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { generateSearchBookingPagesUrl } from "../../search-for-bookings/search-booking-navigation";
import { SearchBookingPages } from "../../search-for-bookings/search-booking-pages";
import { ServiceOrderRequest } from "../../../graphql/generated/graphql";
import { Buffer } from "buffer";
import { prepareServicesForPaymentV2 } from "../../../graphql/transform/transform-additional-services-availability";

const formatQueryParamsForBooking = (magicId?: string, magicToken?: string) =>
  `?magicId=${magicId}&magicToken=${magicToken}`;

interface UseMewsPayNewCardProps {
  returnUrl: string;
  magicId?: string;
  magicToken?: string;
}

export const useMewsPayNewCard = ({
  returnUrl,
  magicId,
  magicToken
}: UseMewsPayNewCardProps): (() => Promise<void>) => {
  const { paymentDTO } = useSelector(selectPaymentSlice);
  const [createBookingAction] = useCreateBookingMutation();
  const [createPaymentRequestAction] = useCreatePaymentRequestMutation();
  const navigate = useNavigate();

  const composeRedirectUrl = useCallback(
    (magicId: string, magicToken: string) => {
      const formatReturnUrl = new URLSearchParams(returnUrl.split("?")[1]);
      formatReturnUrl.append("magicId", magicId);
      formatReturnUrl.append("magicToken", magicToken);

      return window.location.origin + returnUrl.split("?")[0] + "?" + formatReturnUrl.toString();
    },
    [returnUrl]
  );

  const createBookingIfNeeded = useCallback(async () => {
    if (magicId && magicToken) {
      //Payment with reservation context
      return Promise.resolve({ magicId, magicToken });
    } else if (paymentDTO.bookingCreation) {
      //Payment for booking
      const createBookingResponse = await createBookingAction({
        request: {
          booker: paymentDTO.bookingCreation?.booker,
          reservations: paymentDTO.bookingCreation?.reservations?.map((item) => ({
            adultsAmount: item.adults,
            arrival: item.arrival,
            childrenAmount: item.childrenAges.length,
            departure: item.departure,
            pmsPropertyId: item.propertyId,
            pmsRatePlanId: item.ratePlanId,
            pmsUnitGroupId: item.unitGroupId
          }))
        }
      });

      if ("data" in createBookingResponse) {
        const newBookingData = createBookingResponse?.data.CreateBooking;
        navigate(
          generateSearchBookingPagesUrl(SearchBookingPages.PAYMENT_AND_BOOKER_DATA).concat(
            formatQueryParamsForBooking(newBookingData?.magicId, newBookingData?.magicToken)
          ),
          { replace: true }
        );
        return newBookingData;
      }
    } else {
      return Promise.reject("PaymentDTO is not prepared properly.");
    }
  }, [navigate, createBookingAction, paymentDTO.bookingCreation, magicToken, magicId]);

  const prepareServices: ServiceOrderRequest[] = useMemo(
    () => paymentDTO.additionalServices?.map(prepareServicesForPaymentV2) ?? [],
    [paymentDTO.additionalServices]
  );

  const getStripePaymentUlr = useCallback(async () => {
    const createBookingResponse = await createBookingIfNeeded();

    if (
      createBookingResponse &&
      createBookingResponse?.magicId &&
      createBookingResponse?.magicToken
    ) {
      const createPaymentRequest = await createPaymentRequestAction({
        magicId: createBookingResponse?.magicId,
        magicToken: createBookingResponse?.magicToken,
        paymentRequest: {
          grossPrice: {
            currency: paymentDTO.adyenPrice.currency,
            grossPriceInCents: paymentDTO.adyenPrice.amount
          },
          services: prepareServices
        }
      });
      if ("data" in createPaymentRequest) {
        return {
          magicId: createBookingResponse?.magicId,
          magicToken: createBookingResponse?.magicToken,
          paymentLink: createPaymentRequest.data.CreatePaymentRequest.paymentLink
        };
      }
    }
  }, [
    createBookingIfNeeded,
    createPaymentRequestAction,
    paymentDTO.adyenPrice.amount,
    paymentDTO.adyenPrice.currency,
    prepareServices
  ]);

  return useCallback(
    () =>
      getStripePaymentUlr().then((result) => {
        if (result) {
          window.location.href = result.paymentLink.concat(
            `?returnUrl=${Buffer.from(
              composeRedirectUrl(result.magicId, result.magicToken)
            ).toString("base64")}`
          );
        }
        throw new Error("Payment failed");
      }),
    [getStripePaymentUlr, composeRedirectUrl]
  );
};
