import { FC, useCallback, useEffect } from "react";
import { Loader } from "../../components/atoms/loaders/loader";
import { Button, Heading2, useAuth } from "@likemagic-tech/sv-magic-library";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../state/store";
import { clearUserInfoSlice } from "../../slices/keycloak.slice";
import { isKioskMode } from "../../util/kiosk-mode";
import { clearMagicDesktopDeviceHint } from "../../core/desktop-device-hint";
import {
  setLastLanguage,
  setLastMagicId,
  setLastPropertyId
} from "../restore-magic-context/restore-magic-context.slice";
import { clearInitialMagicId } from "../initial-magic-info/initial-magic-id.slice";
import { initMagicObject } from "../magic/magic-object.slice";
import { resetKeyAssignSlice } from "../key-assign/key-assign.slice";
import { Capacitor } from "@capacitor/core";
import { Box } from "@mui/material";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";

export const Logout: FC<React.PropsWithChildren<unknown>> = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const clearStateBeforeLogin = useCallback(() => {
    dispatch(clearUserInfoSlice());
    dispatch(setLastMagicId(null));
    dispatch(setLastPropertyId(null));
    dispatch(setLastLanguage(null));
    dispatch(clearInitialMagicId());
    dispatch(initMagicObject());
    dispatch(resetKeyAssignSlice());
  }, [dispatch]);

  useEffect(() => {
    if (isKioskMode()) {
      clearMagicDesktopDeviceHint();
      clearStateBeforeLogin();
    }
    if (auth.authenticated) {
      clearStateBeforeLogin();
      auth.logout();
    } else {
      clearStateBeforeLogin();
      //Redirect back to home screen
      navigate("/");
    }
  }, [auth, navigate, clearStateBeforeLogin]);

  if (Capacitor.isNativePlatform()) {
    return (
      <Box p={2} textAlign="center">
        <Heading2 my={5}>{t("labels__logout_is_successful")}</Heading2>
        <Button
          onClick={() => {
            navigate("/");
          }}
        >
          {t("buttons__go_to_home_page")}
        </Button>
      </Box>
    );
  } else {
    return <Loader visible />;
  }
};
