import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function PassportDeletedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M20.5922 5.44819H14.5922V4.9438C14.5922 3.5038 13.4166 2.35156 12 2.35156C10.5844 2.35156 9.40777 3.52718 9.40777 4.9438V5.44819H3.40777C1.87215 5.44819 0.600006 6.69599 0.600006 8.25595V18.8637C0.600006 20.3993 1.84781 21.6715 3.40777 21.6715H20.6155C22.1511 21.6715 23.4233 20.4237 23.4233 18.8637L23.4242 8.23243C23.3999 6.69587 22.152 5.44795 20.592 5.44795L20.5922 5.44819ZM10.2958 4.94474C10.2958 4.00912 11.0636 3.24035 12.0002 3.24035C12.9358 3.24035 13.7045 4.00816 13.7045 4.94474V7.44026H10.2958L10.2958 4.94474ZM22.5122 18.8647C22.5122 19.9204 21.6479 20.7604 20.6166 20.7604H3.40791C2.35229 20.7604 1.51229 19.896 1.51229 18.8647V8.2325C1.51229 7.17688 2.37668 6.33688 3.40791 6.33688H9.40791V7.8725C9.40791 8.1125 9.6001 8.32812 9.86353 8.32812H14.159C14.399 8.32812 14.6147 8.13593 14.6147 7.8725V6.33688H20.6147C21.6703 6.33688 22.5103 7.17688 22.5103 8.2325L22.5122 18.8647Z" />
      <path d="M19.6555 10.5371H13.32C13.08 10.5371 12.8644 10.7293 12.8644 10.9927C12.8644 11.2571 13.0566 11.4483 13.32 11.4483H19.6555C19.8955 11.4483 20.1111 11.2562 20.1111 10.9927C20.1121 10.7293 19.9199 10.5371 19.6555 10.5371Z" />
      <path d="M19.6555 14.0879H13.32C13.08 14.0879 12.8644 14.2801 12.8644 14.5435C12.8644 14.7835 13.0566 14.9991 13.32 14.9991H19.6555C19.8955 14.9991 20.1111 14.8069 20.1111 14.5435C20.1121 14.2801 19.9199 14.0879 19.6555 14.0879Z" />
      <path d="M19.6555 17.6152H13.32C13.08 17.6152 12.8644 17.8074 12.8644 18.0709C12.8644 18.3109 13.0566 18.5265 13.32 18.5265H19.6555C19.8955 18.5265 20.1111 18.3343 20.1111 18.0709C20.1121 17.8074 19.9199 17.6152 19.6555 17.6152Z" />
      <path d="M8.59225 14.2321C9.19225 13.7999 9.57664 13.1043 9.57664 12.3121C9.57664 10.9921 8.49664 9.91211 7.17664 9.91211C5.85664 9.91211 4.77664 10.9921 4.77664 12.3121C4.77664 13.1043 5.16102 13.7999 5.76102 14.2321C4.68102 14.8799 3.79321 16.2243 3.79321 17.9276C3.79321 18.1676 3.9854 18.3832 4.24883 18.3832C4.48883 18.3832 4.70445 18.1911 4.70445 17.9276C4.70445 16.272 5.92883 14.7121 7.19996 14.7121C8.49558 14.7121 9.69548 16.2721 9.69548 17.9276C9.69548 18.1676 9.88767 18.3832 10.1511 18.3832C10.3911 18.3832 10.6067 18.1911 10.6067 17.9276C10.5598 16.2242 9.67204 14.8799 8.59204 14.2321H8.59225ZM7.15225 13.7999C6.31225 13.7999 5.64006 13.1277 5.64006 12.2877C5.64006 11.4477 6.31225 10.7755 7.15225 10.7755C7.99225 10.7755 8.66444 11.4477 8.66444 12.2877C8.66444 13.1277 7.99225 13.7999 7.15225 13.7999V13.7999Z" />
    </SvgIcon>
  );
}
