import React, { FC } from "react";
import {
  Box,
  ButtonBase as MuiButtonBase,
  ButtonBaseProps,
  Paper,
  SxProps,
  Typography
} from "@mui/material";
import { responsiveVariables } from "../../layouts/hooks/use-is-mobile";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<PaperButtonProps>()((theme, props) => ({
  root: {
    borderRadius: props.small ? theme.shape.borderRadius * 1.5 : theme.shape.borderRadius * 2.5,
    boxShadow: "0px 4px 8px rgba(197, 185, 172, 0.06)",
    minHeight: theme.spacing(8),
    [theme.breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      minHeight: theme.spacing(14)
    },
    alignItems: "stretch",
    width: "100%",
    height: "100%"
  },
  paper: {
    padding: theme.spacing(2, 1),
    borderRadius: props.small ? theme.shape.borderRadius * 1.5 : theme.shape.borderRadius * 2.5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    opacity: props.disabled ? 0.5 : 1
  },
  typography: {
    overflowWrap: "break-word",
    width: "100%",
    hyphens: "auto"
  }
}));

export interface PaperButtonProps {
  title: string;
  subtitle?: string;
  small?: boolean;
  onClick?: ButtonBaseProps["onClick"];
  disabled?: boolean;
  sx?: SxProps;
  iconUrl?: string;
  className?: string;
}

export const PaperButton: FC<PaperButtonProps> = ({
  title,
  subtitle,
  small,
  disabled,
  sx,
  onClick,
  iconUrl,
  className
}) => {
  const { classes } = useStyles({ title, subtitle, small, disabled, sx, onClick, iconUrl });
  return (
    <MuiButtonBase
      focusRipple
      className={classes.root}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
    >
      <Paper elevation={0} className={`${classes.paper} ${className}`} sx={sx}>
        <>
          {iconUrl && (
            <Box mb={0.5}>
              <img src={iconUrl} alt={title} width={24} height={24} />
            </Box>
          )}
          <Typography
            align="center"
            variant={small ? "body1" : "h3"}
            className={classes.typography}
          >
            {title}
          </Typography>
          {!small && (
            <Typography align="center" className={classes.typography}>
              {subtitle}
            </Typography>
          )}
        </>
      </Paper>
    </MuiButtonBase>
  );
};
