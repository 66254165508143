import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { Reservation } from "../../../domain-common/reservation";
import { useSetPasswordMutation } from "../../../graphql/mutations/set-password.generated";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";

interface SetPasswordInterface {
  password?: string;
  reservation: Reservation;
}

export const useSetPassword = () => {
  const { isRESTVersion } = useApiVersion();
  const [setPasswordMutation] = useSetPasswordMutation();
  const { patchGuestFlowStep } = useGuestFlow();

  const setPasswordV1 = ({ reservation }: SetPasswordInterface) => {
    return patchGuestFlowStep({
      reservationValues: reservation,
      subUpdate: SubUpdate.FLOW_STATE
    });
  };

  const setPasswordV2 = ({ reservation, password }: SetPasswordInterface) => {
    setPasswordMutation({
      magicId: reservation.magicId,
      magicToken: reservation.magicToken,
      password: password ?? ""
    });
  };

  const setPassword = isRESTVersion ? setPasswordV1 : setPasswordV2;

  return { setPassword };
};
