import { Box, Card, CardContent, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { FC } from "react";

const useStyles = makeStyles()((theme: Theme) => ({
  logo: {
    maxWidth: theme.spacing(25),
    height: theme.spacing(8.75)
  }
}));

export const NoOffersAvailable: FC<React.PropsWithChildren<{ additionalText?: string }>> = ({
  additionalText
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { classes } = useStyles();

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="center" pt={3}>
          <img
            src={cmsData?.data?.icons__no_offers?.url}
            alt={cmsData?.data?.icons__no_offers?.alt}
            className={classes.logo}
          />
        </Box>
        <Box pt={4}>
          <Box>
            {additionalText ? (
              <Typography align="center">{additionalText}</Typography>
            ) : (
              <Typography align="center">{t("label__no_offers")}</Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
