import { FC } from "react";
import { CallToActionButton } from "./call-to-action-button";
import { useNavigate } from "react-router-dom";
import { generatePortalProfileUrl } from "../../../util/routing";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { ProfilePagesEnum, ProfilePageToPath } from "../../../features/portal/profile-page-to-path";

export const CallToActionIdCheckStatus: FC<{
  magicId: string;
}> = ({ magicId }) => {
  const navigate = useNavigate();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  return (
    <CallToActionButton
      onClick={() =>
        navigate(
          generatePortalProfileUrl(magicId).concat(ProfilePageToPath[ProfilePagesEnum.LEGAL])
        )
      }
      text={t("buttons__my_stay_id_check_declined")}
    />
  );
};
