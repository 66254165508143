import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";
import { MyStayArticleDetails } from "../../../domain-common/my-stay-article-details";
import { MyStayTipContentType } from "../../../domain-common/my-stay-tip-content-type";
import { RootState, useAppDispatch } from "../../../state/store";
import {
  fetchMyStayTipById,
  selectMyStayArticleDetailsById
} from "../../my-stay-tips/my-stay-tips.slice";
import { ContentDetails, ContentDetailsCover } from "../components/content-details";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { HelmetTitle } from "../../gtm/helmet-title";
import { DesktopCard } from "../../../components";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { BackButton } from "../../../components/atoms/back-button/back-button";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { usePropertyById } from "../../property/use-property-by-id";
import { useCmsClient } from "../../../api/cms-client/use-cms-client";
import { formatDate } from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

interface ArticleDetailsPageProps {}

export const ArticleDetailsPage: React.FC<
  React.PropsWithChildren<ArticleDetailsPageProps>
> = () => {
  const { magicId } = useMagicIdParams();
  const { articleId } = useParams<{ articleId: string }>();
  const dispatch = useAppDispatch();
  const articleDetails = useSelector<RootState, MyStayArticleDetails | null>((state) =>
    selectMyStayArticleDetailsById(state, articleId || "")
  );
  const prismic = useCmsClient();
  const isMobile = useIsMobile();
  const { setDisplayNavbar, setLeftButtons } = useNavbar();
  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const property = usePropertyById(reservation?.propertyId);
  useEffect(() => {
    setDisplayNavbar(!isMobile);
  }, [setDisplayNavbar, isMobile]);

  const backButton = useMemo(() => {
    return (
      <BackButton key="back_button" onClick={() => navigate(generatePortalMyStayUrl(magicId))} />
    );
  }, [navigate, magicId]);

  useEffect(() => {
    setLeftButtons([backButton]);
  }, [setLeftButtons, backButton]);

  useEffect(() => {
    const promise = dispatch(fetchMyStayTipById({ tipId: articleId, prismic: prismic }));
    return () => {
      promise.abort();
    };
  }, [dispatch, articleId, prismic]);

  const description = useMemo(() => {
    if (!articleDetails?.description) {
      return [];
    }
    const stringifyDescription = JSON.stringify(articleDetails.description);

    const person = reservation?.primaryGuest ?? reservation.travelBuddy;
    const arrival =
      formatDate(reservation.arrival, getI18nSelectedLanguage(), property.timeZone) || "";
    const departure =
      formatDate(reservation.departure, getI18nSelectedLanguage(), property.timeZone) || "";
    const firstName = person.firstName;
    const lastName = person.lastName;

    const enrichedDescription = stringifyDescription
      .replaceAll("{magic_link}", window.location.host.concat(`/magic/${magicId}`))
      .replaceAll("{firstName}", firstName)
      .replaceAll("{lastName}", lastName)
      .replaceAll("{arrival}", arrival)
      .replaceAll("{departure}", departure!);

    return JSON.parse(enrichedDescription);
  }, [
    articleDetails?.description,
    magicId,
    reservation.arrival,
    reservation.departure,
    reservation?.primaryGuest,
    reservation?.travelBuddy,
    property.timeZone
  ]);

  if (!articleDetails) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Article details" />

      <DesktopCard>
        <ContentDetails
          title={articleDetails.title}
          subtitle={articleDetails.subtitle}
          coverImage={articleDetails.coverImage}
          description={description}
          type={
            articleDetails.contentType === MyStayTipContentType.ICON_CARD
              ? ContentDetailsCover.SVG
              : ContentDetailsCover.IMAGE
          }
        />
      </DesktopCard>
    </>
  );
};
