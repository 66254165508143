import React, { useCallback } from "react";
import { TextFieldProps } from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/lab";
import { makeStyles } from "tss-react/mui";
import { format } from "date-fns";
import { Theme } from "@mui/material";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import { dateFormatForLanguage } from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";
import { WrappedInput } from "./wrapped-input";

export interface DatePickerProps extends Omit<TextFieldProps, "InputLabelProps" | "error"> {
  id: string;
  disabled: boolean;
  value?: TextFieldProps["value"];
  label?: string;
  error?: string | boolean;
  labelProps?: TextFieldProps["InputLabelProps"];
  onChange: {
    (e: React.ChangeEvent<HTMLInputElement>): void;
  };
}

const useStyles = makeStyles()(({ palette, spacing, shape }: Theme) => ({
  popperStyle: {
    width: "100%",
    paddingRight: spacing(1),
    paddingLeft: spacing(1)
  },
  datePicker: {
    "& > div": {
      flexDirection: "column"
    },
    borderRadius: shape.borderRadius * 2.5,
    "& .MuiButtonBase-root.Mui-selected": {
      color: "white",
      background: `${palette.primary.main}`
    },
    "& .PrivatePickersYear-yearButton.Mui-selected": {
      color: "white",
      background: `${palette.primary.main}`
    }
  },
  toolbar: {
    position: "relative",
    top: "33px",
    paddingBottom: "50px"
  },
  toolbarSelect: {
    float: "left",
    height: "20px",
    paddingLeft: "25px",
    fontWeight: "bold"
  },
  toolbarX: {
    float: "right",
    height: "24px",
    paddingRight: "25px",
    cursor: "pointer"
  }
}));

const isValidDate = (date: Date | null) => date && !!date.getDate();

export const DatePicker: React.FC<React.PropsWithChildren<DatePickerProps>> = (props) => {
  const { id, value: valueProp, onChange, label, error = false, disabled } = props;

  const { classes } = useStyles();
  const dateFormat = dateFormatForLanguage(getI18nSelectedLanguage());
  const isMobile = useIsMobile();

  const date = typeof valueProp === "string" && valueProp ? new Date(valueProp) : null;

  const dateChanged = useCallback(
    (newDate: Date | null) => {
      var a: any = {};
      a.target = {};

      a.target.value = isValidDate(newDate)
        ? format(newDate !== null ? newDate : new Date(), "yyyy-MM-dd")
        : newDate;
      a.target.id = id;
      onChange(a);
    },
    [id, onChange]
  );

  return (
    <DesktopDatePicker
      disabled={disabled}
      PopperProps={{ className: isMobile ? classes.popperStyle : "" }}
      PaperProps={{ classes: { root: `${classes.datePicker}` } }}
      showToolbar={true}
      value={date}
      inputFormat={dateFormat}
      onChange={(newDate) => {
        dateChanged(newDate);
      }}
      disableCloseOnSelect={false}
      label={label}
      ToolbarComponent={() => (
        <div className={`${classes.toolbar}`}>
          <div className={`${classes.toolbarSelect}`}>{label}</div>
        </div>
      )}
      renderInput={(params) => (
        <WrappedInput
          // TODO - fix this any
          label={label as any}
          value={date}
          fullWidth
          {...params}
          helperText={error}
          error={Boolean(error)}
        />
      )}
    />
  );
};
