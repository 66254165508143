import React, { FC, useCallback, useEffect, useRef } from "react";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { resetCheckoutState } from "../../checkout-flow/checkout-flow.slice";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { ConfirmationComponent } from "../../../components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export const MarketingConsentConfirmationPage: FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patchGuestFlowStep } = useGuestFlow();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const logoUrl = useLogoUrl();
  const isUpdatedRef = useRef(false);

  const { reservation } = useReservationContext();

  useEffect(() => {
    if (!isUpdatedRef.current && reservation?.extras) {
      const extras = {
        ...reservation?.extras,
        ...{ tcMarketingConsentDoubleOptin: true }
      };
      isUpdatedRef.current = true;

      patchGuestFlowStep({
        reservationValues: { ...reservation, extras },
        subUpdate: SubUpdate.PERSONAL_DATA
      });
    }
  }, [patchGuestFlowStep, reservation]);

  const goToMyStay = useCallback(() => {
    // This is needed to reset the CheckoutState.SUCCEEDED which redirects to check out page.
    dispatch(resetCheckoutState());
    navigate(generatePortalMyStayUrl(reservation.magicId));
  }, [dispatch, navigate, reservation.magicId]);

  return (
    <ConfirmationComponent
      subtitleInfo={t("confirmations__marketing_consent")}
      onConfirm={goToMyStay}
      submitButtonLabel={t("buttons__go_to_my_stay")}
      logoSrc={logoUrl}
    />
  );
};
