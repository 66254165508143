import React, { FC, useMemo } from "react";
import { Grid } from "@mui/material";
import { PaperButton } from "../../../../components";
import { Reservation } from "../../../../domain-common/reservation";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useNavigate } from "react-router-dom";
import { useCMSData } from "../../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../../state/common-cms/common-cms.slice";
import { generatePortalDynamicShopUrl } from "../../../../util/routing";

interface NavigationAmenitiesProps {
  reservation: Reservation;
  title?: string;
  iconUrl?: string;
  linkType?: string;
}

export const NavigationDynamicShop: FC<NavigationAmenitiesProps> = ({
  reservation,
  title,
  iconUrl,
  linkType
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const magicId = useMemo(() => reservation.magicId, [reservation.magicId]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  return (
    <Grid item xs={6}>
      <PaperButton
        title={title ?? t("navigations__amenities")}
        small
        onClick={() => {
          navigate(generatePortalDynamicShopUrl(magicId, linkType ?? ""));
        }}
        iconUrl={iconUrl ?? cmsData?.data?.icons__my_stay_navigation_amenities.url}
      />
    </Grid>
  );
};
