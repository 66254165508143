import { BaseApiClient, JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { SelfPouringStationAvailability } from "../domain-v1/self-pouring-station-availability";

const composeUrl = (url: string) => `/api/guest-journey-service/magic/${url}`;

class SelfPouringStationShopApiClient extends BaseApiClient {
  async fetchSelfPouringStationAvailability(
    { magicId, magicToken }: { magicId: string; magicToken: string },
    init: RequestInit
  ) {
    const response = await this.fetchApi(
      composeUrl(`${magicId}/self-pouring-station-availability`),
      {
        ...init,
        headers: {
          "sk-magic-token": magicToken
        }
      }
    );
    return new JSONApiResponse<Array<SelfPouringStationAvailability>>(response).value();
  }

  async reserveSelfPouringStation(
    { magicId, drinkId, magicToken }: { magicId: string; drinkId: string; magicToken: string },
    init: RequestInit
  ) {
    const response = await this.fetchApi(composeUrl(`${magicId}/drink/${drinkId}/reserve`), {
      ...init,
      method: "POST",
      headers: {
        "sk-magic-token": magicToken
      }
    });
    return new VoidApiResponse(response).value();
  }

  async pourSelfPouringStation(
    { magicId, drinkId, magicToken }: { magicId: string; drinkId: string; magicToken: string },
    init: RequestInit
  ) {
    const response = await this.fetchApi(composeUrl(`${magicId}/drink/${drinkId}/pour`), {
      ...init,
      headers: {
        "sk-magic-token": magicToken
      }
    });
    return new VoidApiResponse(response).value();
  }
}

export const SelfPouringStationShopApi = new SelfPouringStationShopApiClient();
