import { useAppDispatch } from "../../state/store";
import { useCallback } from "react";
import { getTotalPrice } from "../additional-services/service-selection/additional-services-cart.slice";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { unwrapResult } from "@reduxjs/toolkit";
import { api as getTotalUnpaidAmountApi } from "../../graphql/queries/GetTotalUnpaidAmount.generated";
import { transformGrossPriceToFullPrice } from "../../graphql/transform/transform-reservation";
import { AdditionalServiceCartItem } from "../../domain-common/additional-service-cart-item";
import { prepareServicesForPaymentV2 } from "../../graphql/transform/transform-additional-services-availability";

export const useAdditionalServicesTotalPrice = ({ magicId }: { magicId: string }) => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();

  const getTotalPriceV1 = useCallback(
    ({ serviceCartItems }: { serviceCartItems: AdditionalServiceCartItem[] }) =>
      dispatch(getTotalPrice({ magicId, serviceCartItems })).then(unwrapResult),
    [dispatch, magicId]
  );

  const getTotalPriceV2 = useCallback(
    ({ serviceCartItems }: { serviceCartItems: AdditionalServiceCartItem[] }) =>
      dispatch(
        getTotalUnpaidAmountApi.endpoints.GetTotalUnpaidAmount.initiate({
          magicId,
          services: serviceCartItems.map(prepareServicesForPaymentV2)
        })
      )
        .unwrap()
        .then((item) => transformGrossPriceToFullPrice(item?.GetTotalUnpaidAmount)),
    [dispatch, magicId]
  );

  return isRESTVersion ? getTotalPriceV1 : getTotalPriceV2;
};
