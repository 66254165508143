import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function MyStayIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <svg>
        <path
          d="M2.625 8.875L12 2.625L21.375 8.875M3.875 17.625H20.125M3.875 17.625V13.875H6.375C7.03804 13.875 7.67393 14.1384 8.14277 14.6072C8.61161 15.0761 8.875 15.712 8.875 16.375V17.625H3.875ZM20.125 17.625V21.375H3.875V12.625M20.125 17.625H9.5L10.3383 15.9483C10.6497 15.3254 11.1285 14.8014 11.7209 14.4352C12.3133 14.069 12.996 13.875 13.6925 13.875H17.625C18.288 13.875 18.9239 14.1384 19.3928 14.6072C19.8616 15.0761 20.125 15.712 20.125 16.375V17.625Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    </SvgIcon>
  );
}
