import { makeStyles } from "tss-react/mui";
import { FC, ReactElement } from "react";
import { Box, Grid } from "@mui/material";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { responsiveVariables } from "../../layouts/hooks/use-is-mobile";

interface StudioItemProps {
  icon: ReactElement;
  title: string;
  onClick: () => void;
  margin: number;
}

const useStyles = makeStyles()(({ breakpoints }) => ({
  itemWrapper: {
    display: "inline-block",
    cursor: "pointer",
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      "&:first-of-type": {
        marginLeft: 0
      }
    }
  }
}));

export const FeatureItem: FC<React.PropsWithChildren<StudioItemProps>> = ({
  icon,
  title,
  onClick,
  margin
}) => {
  const { classes } = useStyles();
  return (
    <Box m={margin} className={classes.itemWrapper} onClick={onClick}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>{icon}</Grid>
        {title && (
          <Grid item mt={0.5}>
            <Paragraph>{title}</Paragraph>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
