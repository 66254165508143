import { FC, useCallback, useMemo } from "react";
import { GuestFlowCheckpointToPath } from "../checkpoint/guest-flow-checkpoint-to-path";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { useGuestFlow } from "../use-guest-flow";
import { generateGuestFlowUrl } from "../guest-flow-sequence";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentRedirect } from "../../payment/payment-redirect";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { clearAdditionalServicesCart } from "../../additional-services/service-selection/additional-services-cart.slice";
import { clearPaymentResponses, clearPaymentState } from "../../payment/payment.slice";
import { useDispatch } from "react-redux";

export const PaymentRedirectPage: FC<React.PropsWithChildren<{ path: string }>> = () => {
  const { reservation, patchGuestFlowStep } = useGuestFlow();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const cleanedURLSearchParams: URLSearchParams = useMemo(() => {
    const cleanedURLSearchParams = new URLSearchParams(search);
    cleanedURLSearchParams.delete("redirectResult");
    return cleanedURLSearchParams;
  }, [search]);

  const onSuccess = useCallback(async () => {
    dispatch(clearAdditionalServicesCart());
    dispatch(clearPaymentResponses());
    dispatch(clearPaymentState());

    await patchGuestFlowStep({
      reservationValues: reservation,
      checkpoint: GuestFlowCheckpoint.PAYMENT,
      subUpdate: SubUpdate.PAYMENT
    });

    navigate({
      pathname: generateGuestFlowUrl(
        GuestFlowCheckpointToPath[GuestFlowCheckpoint.CONFIRMATION],
        reservation.magicId
      ),
      search: cleanedURLSearchParams.toString()
    });
  }, [cleanedURLSearchParams, patchGuestFlowStep, reservation, navigate, dispatch]);
  const failedRedirectTo = useCallback(() => {
    navigate({
      pathname: generateGuestFlowUrl(
        GuestFlowCheckpointToPath[GuestFlowCheckpoint.PAYMENT],
        reservation.magicId
      ),
      search: cleanedURLSearchParams.toString()
    });
  }, [cleanedURLSearchParams, reservation, navigate]);
  return <PaymentRedirect onSuccess={onSuccess} onFailed={failedRedirectTo} />;
};
