import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { selectIsLoadingLinkedAccounts } from "../../slices/accounts.slice";
import { selectIsCheckingIn } from "../checkin-flow/checkin-flow.slice";
import { selectIsCheckingOut } from "../checkout-flow/checkout-flow.slice";
import { selectIsReservationUpdating } from "../reservation-provider/reservation.slice";
import {
  selectIsInitiatePaymentLoading,
  selectIsPayByPaymentAccountLoading,
  selectIsPaymentByAccountLoading,
  selectIsPaymentDetailsLoading,
  selectIsPaymentMethodObjectLoading,
  selectIsSkipPaymentLoading,
  selectIsWithoutPaymentLoading
} from "../payment/payment.slice";
import { selectIsServicesLoading } from "../services/services.slice";
import { selectIsCheckingAdditionalServicesAvailability } from "../services/additional-services.slice";
import {
  selectIsLoadingLastMagicId,
  selectIsLoadingUserProfile,
  selectIsUserProfileDeleting
} from "../user-profile/user-profile.slice";
import { selectLoaderSlice } from "./loader.slice";
import { selectIsLoadingCancelingReservation } from "../portal/portal.slice";
import {
  selectExtendStayBookIsLoading,
  selectExtendStayPriceIsLoading
} from "../extend-stay/extend-stay.slice";
import {
  selectIsLoadingOverviewBooking,
  selectIsLoadingOverviewBookingTotalPrice
} from "../booking-overview/state/booking-overview.slice";
import { selectIsSearchAvailablePropertiesLoading } from "../search-for-bookings/search-available-properties.slice";
import { selectIsSearchAvailableUnitGroupsLoading } from "../search-for-bookings/search-available-unit-groups.slice";
import { selectIsCartTotalPriceLoading } from "../search-for-bookings/booking-cart.slice";
import { selectIsMagicObjectLoading } from "../magic/magic-object.slice";
import { selectAssignKeyIsLoading } from "../key-assign/key-assign.slice";
import { selectIsBoxReserving } from "../box-shop/box-shop.slice";
import { RootState } from "../../state/store";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useDebounce } from "../../util/hooks/use-debounce";
import { useMemo } from "react";
import { selectIsTotalPriceStatusForAdditionalServicesLoading } from "../additional-services/service-selection/additional-services-cart.slice";
import {
  selectIsSelfPouringStationLoading,
  selectIsSelfPouringStationPourLoading
} from "../self-pouring-station-shop/self-pouring-station-shop.slice";

// note that there is a 12 selector limit. If we go over that, you have to split it
const selectIsLoading = createSelector(
  selectIsReservationUpdating,
  selectIsCheckingIn,
  selectIsCheckingOut,
  selectIsPayByPaymentAccountLoading,
  selectIsInitiatePaymentLoading,
  selectIsCheckingAdditionalServicesAvailability,
  selectIsLoadingLinkedAccounts,
  selectIsLoadingLastMagicId,
  selectExtendStayPriceIsLoading,
  selectIsMagicObjectLoading,
  (
    isReservationUpdating,
    isCheckingIn,
    isCheckingOut,
    isPayByPaymentAccountLoading,
    isInitiatePaymentLoading,
    isCheckingAdditionalServicesAvailability,
    isLoadingLinkedAccounts,
    isLoadingUserProfileReservations,
    extendStayPriceIsLoading,
    isMagicObjectLoading
  ) =>
    isReservationUpdating ||
    isCheckingIn ||
    isCheckingOut ||
    isPayByPaymentAccountLoading ||
    isInitiatePaymentLoading ||
    isCheckingAdditionalServicesAvailability ||
    isLoadingLinkedAccounts ||
    isLoadingUserProfileReservations ||
    extendStayPriceIsLoading ||
    isMagicObjectLoading
);

// note that there is a 12 selector limit. If we go over that, you have to split it
// TODO create new one
const selectIsLoadingSecondSelector = createSelector(
  selectIsPaymentDetailsLoading,
  selectIsPaymentMethodObjectLoading,
  selectIsServicesLoading,
  selectExtendStayBookIsLoading,
  selectIsLoadingOverviewBooking,
  selectIsLoadingCancelingReservation,
  selectIsSearchAvailablePropertiesLoading,
  selectIsSearchAvailableUnitGroupsLoading,
  selectIsCartTotalPriceLoading,
  selectIsLoadingUserProfile,
  selectAssignKeyIsLoading,
  (
    isPaymentDetailsLoading,
    isPaymentMethodObjectLoading,
    isServicesLoading,
    extendStayBookIsLoading,
    isLoadingOverviewBooking,
    isLoadingCancelingReservation,
    isSearchAvailablePropertiesLoading,
    isSearchAvailableUnitGroupsLoading,
    isCartTotalPriceLoading,
    isLoadingUserProfile,
    isAssignTagLoading
  ) =>
    isPaymentDetailsLoading ||
    isPaymentMethodObjectLoading ||
    isServicesLoading ||
    extendStayBookIsLoading ||
    isLoadingOverviewBooking ||
    isLoadingCancelingReservation ||
    isSearchAvailablePropertiesLoading ||
    isSearchAvailableUnitGroupsLoading ||
    isCartTotalPriceLoading ||
    isLoadingUserProfile ||
    isAssignTagLoading
);

// note that there is a 12 selector limit. If we go over that, you have to split it
// TODO create new one
const selectIsLoadingThirdSelector = createSelector(
  selectIsUserProfileDeleting,
  selectIsPaymentByAccountLoading,
  selectIsLoadingOverviewBookingTotalPrice,
  selectIsBoxReserving,
  selectIsWithoutPaymentLoading,
  selectIsTotalPriceStatusForAdditionalServicesLoading,
  selectIsSkipPaymentLoading,
  selectIsSelfPouringStationPourLoading,
  selectIsSelfPouringStationLoading,
  (
    isUserProfileDeleting,
    isPaymentByAccountLoading,
    isLoadingOverviewBookingTotalPrice,
    isBoxReserving,
    isWithoutPaymentLoading,
    isTotalPriceStatusForAdditionalServicesLoading,
    isPaymentOnCheckoutLoading,
    isSelfPouringStationPourLoading,
    isSelfPouringStationLoading
  ) =>
    isUserProfileDeleting ||
    isPaymentByAccountLoading ||
    isLoadingOverviewBookingTotalPrice ||
    isBoxReserving ||
    isWithoutPaymentLoading ||
    isTotalPriceStatusForAdditionalServicesLoading ||
    isPaymentOnCheckoutLoading ||
    isSelfPouringStationPourLoading ||
    isSelfPouringStationLoading
);

const selectIsLoaderVisible = createSelector(
  selectLoaderSlice,
  selectIsLoading,
  selectIsLoadingSecondSelector,
  selectIsLoadingThirdSelector,
  (loaderSliceState, isLoading, isLoadingSecondSelector, isLoadingThirdSelector) =>
    (loaderSliceState.isRootSuspenseShowed ||
      isLoading ||
      isLoadingSecondSelector ||
      isLoadingThirdSelector) &&
    !loaderSliceState.isForceHideSpinners
);

export const useSpinners = () => {
  const { isRESTVersion } = useApiVersion();

  const loaderV1 = useSelector(selectIsLoaderVisible);
  const isApiSliceLoading = useSelector((state: RootState) =>
    Object.values(state.graphql.queries).some(
      (query) => query && query.status === QueryStatus.pending
    )
  );
  const isApiMutationsLoading = useSelector((state: RootState) =>
    Object.values(state.graphql.mutations).some(
      (mutation) => mutation && mutation.status === QueryStatus.pending
    )
  );

  const isLoaderVisibleMemo = useMemo(
    () => (isRESTVersion ? loaderV1 : isApiMutationsLoading || isApiSliceLoading),
    [isRESTVersion, loaderV1, isApiSliceLoading, isApiMutationsLoading]
  );

  const isLoaderVisible = useDebounce(isLoaderVisibleMemo, 50);

  return { isLoaderVisible };
};
