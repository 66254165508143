import { createSelector, createSlice, Selector } from "@reduxjs/toolkit";
import { CmsSingleDocumentPerPropertyType } from "../cms/cms-single-document-per-property-type";
import { CMSPageState } from "../cms/cms-single-page-factory";
import {
  createCMSSingleDocumentPerPropertyFetchThunk,
  createCMSSinglePagePerPropertyAdapter
} from "../cms/cms-single-page-per-property-factory";
import { RootState } from "../store";
import { CMSResourceStatus } from "../cms/cms-resource-status";
import { getI18nSelectedLanguage } from "../../util/lang-utils";

export type PropertyCMSState = CMSPageState;
export const propertyCMSAdapter = createCMSSinglePagePerPropertyAdapter();

const initialState: PropertyCMSState = propertyCMSAdapter.getInitialState();

export const fetchPropertySpecificCMS = createCMSSingleDocumentPerPropertyFetchThunk(
  CmsSingleDocumentPerPropertyType.propertySpecfic
);

export const propertyCMSSlice = createSlice({
  name: "propertyCMS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertySpecificCMS.pending, propertyCMSAdapter.handlePendingLoadingStatus)
      .addCase(fetchPropertySpecificCMS.fulfilled, (state, action) => {
        propertyCMSAdapter.handleFulfilledLoadingStatus(state, action);
        propertyCMSAdapter.addOne(state, action);
      })
      .addCase(fetchPropertySpecificCMS.rejected, propertyCMSAdapter.handleRejectedLoadingStatus);
  }
});

export const selectSelf: Selector<RootState, PropertyCMSState> = (state: RootState) =>
  state.cmsPerProperty[CmsSingleDocumentPerPropertyType.propertySpecfic];

export const { selectAll: selectPropertyCMS, selectById: selectPropertyCMSById } =
  propertyCMSAdapter.getSelectors<RootState>(selectSelf);

export const selectAllPropertyLocations = createSelector(selectPropertyCMS, (allPropertyCms) => {
  return allPropertyCms
    .filter((item) => item?.lang === getI18nSelectedLanguage())
    .map((propertyItem: any) => {
      return {
        latitude: propertyItem?.data?.address__geo_point?.latitude,
        longitude: propertyItem?.data?.address__geo_point?.longitude,
        mapPin: propertyItem?.data?.address__map_pin?.url,
        name: propertyItem?.data?.main__property_name,
        address: propertyItem?.data?.main__property_address,
        propertyId: propertyItem?.data?.main__property_id
      };
    });
});

export const selectIsAnyPropertyLoading = createSelector(selectSelf, (propertySpecificState) =>
  Object.values(propertySpecificState.loadingStatuses).some(
    (item) => item === CMSResourceStatus.PENDING
  )
);
