import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { FC, PropsWithChildren } from "react";
import CanvasDraw from "react-canvas-draw";
import { CloseIcon } from "../../icons";
import { Button, Heading5 } from "@likemagic-tech/sv-magic-library";

interface SignatureDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (image?: string) => void;
  labels: {
    title: string;
    reset: string;
    confirm: string;
  };
  disabled?: boolean;
  large?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  paper: {
    borderRadius: theme.shape.borderRadius * 2.5
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: "0 0 auto",
    margin: 0,
    padding: theme.spacing(2, 3)
  },
  canvas: {
    background: `${theme.palette.background.paper} !important`,
    border: `1px solid ${theme.palette.grey.A400} !important`
  },
  dialogActions: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    justifyContent: "space-between"
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center"
  }
}));

export const SignatureDialog: FC<PropsWithChildren<SignatureDialogProps>> = ({
  open,
  onClose,
  labels,
  onConfirm,
  children,
  disabled = false,
  large
}) => {
  const [canvas, setCanvas] = React.useState<CanvasDraw | null>(null);
  const theme = useTheme();
  const smUpMatches = useMediaQuery(theme.breakpoints.up("sm"));
  const { palette } = useTheme();
  const { classes } = useStyles();

  const handleReset = () => {
    canvas?.clear();
    setCanvas(null);
  };

  const handleConfirm = () => {
    // @ts-ignore
    onConfirm(canvas?.canvas?.drawing?.toDataURL("image/png"));
    onClose();
    setCanvas(null);
  };

  const handleClose = () => {
    onClose();
    setCanvas(null);
  };

  return (
    <Dialog classes={{ paper: classes.paper }} open={open}>
      <div className={classes.dialogTitle}>
        <Heading5>{labels.title}</Heading5>
        <IconButton onClick={handleClose} edge="end" size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <CanvasDraw
          onChange={setCanvas}
          canvasWidth={smUpMatches ? (large ? 500 : 290) : 260}
          canvasHeight={large ? 345 : 200}
          brushColor={palette.text.primary}
          brushRadius={2}
          lazyRadius={4}
          hideGrid={true}
          hideInterface={true}
          className={classes.canvas}
        />
      </DialogContent>
      {children}
      <DialogActions className={classes.dialogActions}>
        <Button variant="secondary" onClick={handleReset}>
          {labels.reset}
        </Button>
        <Button
          variant="primary"
          onClick={handleConfirm}
          disabled={!Boolean(canvas?.getSaveData()) || disabled}
        >
          {labels.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
