import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectGtmSlice } from "./gtm.slice";
import TagManager from "react-gtm-module";
import { areCookiesAccepted } from "../../util/cookies";
import { GtmECommerce } from "./gtm-domain";

declare global {
  interface Window {
    dataLayer: any[];
    [key: string]: any;
  }
}

export enum GtmEvent {
  PATHNAME_CHANGE = "PATHNAME_CHANGE",
  ADD_TO_CART = "add_to_cart",
  REMOVE_FROM_CART = "remove_from_cart",
  PURCHASE = "purchase"
}

export enum GtmActor {
  BOOKER = "BOOKER",
  GUEST = "GUEST",
  TRAVEL_BUDDY = "TRAVEL_BUDDY"
}

export interface GtmData {
  event: GtmEvent;
  pathname?: string;
  id?: string;
  pageId?: string;
  ecommerce?: GtmECommerce;
  actor?: GtmActor;
}

export const initGTM = (gtmId: string) => {
  if (gtmId && !window.dataLayer && areCookiesAccepted()) {
    TagManager.initialize({
      gtmId,
      events: {
        "gtm.start": new Date().getTime(),
        event: "gtm.js"
      },
      dataLayerName: "dataLayer"
    });
  }
};

export const useGtm = () => {
  const { sessionId } = useSelector(selectGtmSlice);

  const pushToGtm = useCallback(
    <T extends GtmData = GtmData>(data: T) => {
      if (window.dataLayer && areCookiesAccepted()) {
        TagManager.dataLayer({
          dataLayer: {
            sessionId,
            time: new Date().getTime(),
            ...data
          }
        });
      }
    },
    [sessionId]
  );

  return { pushToGtm };
};
