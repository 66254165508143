import Webcam from "react-webcam";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { PlusIcon } from "../../../icons";
import { isKioskMode } from "../../../../util/kiosk-mode";
// We are using isMobile from react-device-detect instead of the hook because during the rendering of the page for a small portion of time the media query says we are on mobile and the permission pop up is triggered.
import { isMobile, useMobileOrientation } from "react-device-detect";
import { dataUrlToFile } from "../../../../util/file-util";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import Compressor from "compressorjs";
import { Notification } from "../../../atoms";
import { CameraErrors, IdCameraProps, StyleProps } from "./id-camera-types";
import { CameraErrorsHint, CameraErrorsTitle, mapErrorType } from "./id-camera-utils";

const videoConstraints = () => ({
  facingMode: isKioskMode() ? "user" : "environment"
});

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  rootContainer: {
    height: props.isPortrait ? `calc(100vh - ${theme.spacing(8)})` : "100vh"
  },
  cameraOverlayContainer: {
    zIndex: 100,
    width: "100vw",
    height: props.isPortrait ? "80%" : "auto",
    padding: theme.spacing(2),
    position: "relative",
    display: props.isDesktop ? "flex" : "block",
    justifyContent: "center",
    flexDirection: "column"
  },
  webcam: {
    objectFit: "cover",
    "--size": "32px",

    padding: "10px",
    background: `linear-gradient(white var(--size), transparent 0 calc(100% - var(--size)), white 0) 0 0 / 4px 100%,
linear-gradient(white var(--size), transparent 0 calc(100% - var(--size)), white 0) 100% 0 / 4px 100%,
linear-gradient(to right, white var(--size), transparent 0 calc(100% - var(--size)), white 0) 0 0 / 100% 4px,
linear-gradient(to right, white var(--size), transparent 0 calc(100% - var(--size)), white 0) 0 100% / 100% 4px
`,
    backgroundRepeat: "no-repeat",
    height: props.isPortrait ? "80%" : `calc(100vh - ${theme.spacing(8)})`,
    width: "100%"
  },
  plusIcon: {
    position: "absolute",
    color: "white",
    top: props.isPortrait ? "38%" : "50%",
    left: "48%",
    "& > path": {
      fill: "white"
    }
  },
  cameraContainer: {
    width: props.isPortrait ? "100%" : "70%",
    height: props.isPortrait ? "70%" : "100%",
    background: theme.palette.grey.A700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  shotButton: {
    width: "70px",
    height: "70px",
    background: "red",
    borderRadius: "100%"
  },
  actions: {
    width: props.isPortrait ? "100%" : "30%",
    height: props.isPortrait ? "30%" : "100%",
    background: "black",
    justifyContent: "center",
    alignContent: "start",
    paddingTop: theme.spacing(3)
  },
  actionIcons: {
    color: "white",
    width: "2em",
    height: "2em"
  },
  picture: {
    width: props.isDesktop ? "70vw" : "100%",
    height: "100%"
  }
}));

export const IdCamera: FC<React.PropsWithChildren<IdCameraProps>> = ({ onChange, closeCamera }) => {
  const { isPortrait } = useMobileOrientation();
  const [error, setError] = useState<CameraErrors | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);
  const onError = (error: string | DOMException) => {
    const result = typeof error === "string" ? error : error?.name;
    setError(mapErrorType(result));
  };

  const { classes } = useStyles({
    isPortrait: isPortrait,
    isDesktop: !isMobile
  });
  const webcamRef = useRef<Webcam>(null);
  const [picture, setPicture] = useState<string | null | undefined>(null);

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const capture = useCallback(() => {
    // Dimensions will prevent a picture too big.
    const imageSrc = webcamRef?.current?.getScreenshot();
    setPicture(imageSrc);
  }, [webcamRef]);

  const confirmPicture = useCallback(async () => {
    if (picture) {
      const image = await dataUrlToFile(
        picture,
        `camera-image-${new Date().getMilliseconds()}.jpeg`
      );
      // @ts-ignore
      image.path = `camera-image-${new Date().getMilliseconds()}.jpeg`;
      new Compressor(image, {
        quality: 1,
        success(result) {
          onChange(result as File);
          closeCamera();
        }
      });
    }
  }, [onChange, picture, closeCamera]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: true
      })
      .then(() => {
        setLoaded(true);
      });

    return () => {
      setLoaded(false);
    };
  }, []);

  return (
    <Grid className={classes.rootContainer} container flexDirection={isPortrait ? "row" : "column"}>
      <Grid item className={classes.cameraContainer}>
        <Grid className={classes.cameraOverlayContainer}>
          {!picture && loaded && (
            <>
              <Webcam
                className={classes.webcam}
                audio={false}
                ref={webcamRef}
                onUserMediaError={onError}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints()}
                width="95%"
              />
              <PlusIcon className={classes.plusIcon} />

              <Box pt={2}>
                {error ? (
                  <Notification
                    title={t(CameraErrorsTitle(error))}
                    type="error"
                    subtitle={t(CameraErrorsHint(error))}
                  />
                ) : (
                  <Paragraph color={"common.white"} textAlign="center">
                    {t("labels__id_camera_hint")}
                  </Paragraph>
                )}
              </Box>
            </>
          )}
          {picture && <img className={classes.picture} src={picture} alt="profile_picture" />}
        </Grid>
      </Grid>
      <Grid container className={classes.actions}>
        {picture && (
          <Grid container justifyContent="space-around" alignItems="center">
            <DoneIcon className={classes.actionIcons} onClick={confirmPicture} />
            <ClearIcon className={classes.actionIcons} onClick={() => setPicture(null)} />
          </Grid>
        )}
        {!picture && <div className={classes.shotButton} onClick={capture} />}
      </Grid>
    </Grid>
  );
};
