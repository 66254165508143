import { getLocalStorageObject } from "../state/local-storage-state";

const LOCAL_TAG_READER_ID = "tagReaderId";

export const getTagReaderId = () => {
  const tagReaderIdObject = getLocalStorageObject(LOCAL_TAG_READER_ID);
  return tagReaderIdObject.tagReaderId as string;
};

export const setTagReaderId = (tagReaderId: string | null) => {
  try {
    if (tagReaderId) {
      localStorage.setItem(LOCAL_TAG_READER_ID, JSON.stringify({ tagReaderId }));
    }
  } catch (e) {
    console.error("Falied to set TagReaderId in local storage", e);
  }
};
