import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function WifiOff(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>
          {`.a{fill:none;stroke:#FFFFFF;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}`}
        </style>
      </defs>
      <title>wifi-off</title>

      <line className="a" x1="0.75" y1="23.25" x2="23.25" y2="0.75" />
      <circle className="a" cx="12" cy="20.25" r="3" />
      <path className="a" d="M17.3,14.947A7.477,7.477,0,0,0,15.047,13.4" />
      <path className="a" d="M11.251,8.273a11.95,11.95,0,0,0-7.736,3.492" />
      <path className="a" d="M20.485,11.765a12.017,12.017,0,0,0-2.53-1.936" />
      <path className="a" d="M14.952,4.016A16.484,16.484,0,0,0,.75,8.181" />
      <path className="a" d="M23.25,8.181A16.464,16.464,0,0,0,20.812,6.3" />
    </SvgIcon>
  );
}
