import { Price } from "./price";

export interface FullPrice {
  grossAmount: number;
  netAmount: number;
  currency: string;
}

// Good for displaying sane default value.
// Not good as input to `sumPrices` (currencies might not match)
export const emptyFullPrice = (currency = "CHF"): FullPrice => ({
  grossAmount: 0,
  netAmount: 0,
  currency
});
export const multiply = (fullPrice: FullPrice, multiplier: number): FullPrice => {
  return {
    currency: fullPrice.currency,
    grossAmount: fullPrice.grossAmount * multiplier,
    netAmount: fullPrice.netAmount * multiplier
  };
};
export const toGross = (fullPrice: FullPrice): Price => {
  return {
    amount: fullPrice?.grossAmount,
    currency: fullPrice?.currency
  };
};
export const toNet = (fullPrice: FullPrice): Price => {
  return {
    amount: fullPrice.netAmount,
    currency: fullPrice.currency
  };
};
export const toVat = (fullPrice: FullPrice): Price => {
  return {
    amount: fullPrice.grossAmount - fullPrice.netAmount,
    currency: fullPrice.currency
  };
};
