import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";
import { ConfirmationComponent } from "../../../components";
import { generatePortalMyStayUrl } from "../../../util/routing";

export const BoxStatusSuccessfullyClosed: FC<{ text: string }> = ({ text }) => {
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const logoUrl = useLogoUrl();

  return (
    <ConfirmationComponent
      onConfirm={() => navigate(generatePortalMyStayUrl(magicId))}
      subtitleInfo={text}
      submitButtonLabel={t("buttons__go_to_my_stay")}
      logoSrc={logoUrl}
    />
  );
};
