import { BookingOverview, BookingOverviewItem } from "../../domain-common/booking-overview";
import { transformActorV2ToCommon } from "./transform-utils";
import { MagicType } from "../../domain-common/magic-object";
import { GetMagicBookingOverviewsQuery } from "../queries/GetMagicBookingOverviews.generated";
import { Reservation as ReservationCommon } from "../../domain-common/reservation";
import { transformV2Reservation } from "./transform-reservation";
import { transformTravelBuddyV2ToCommon } from "./transform-travel-buddy";

// export const transformApiV2ToCommon = (
//   arg?: GetMagicBookingOverviewsQuery
// ): Array<BookingOverview> => {
//   return (
//     arg?.GetMagicBookingOverviews.map((item) =>
//       transformApiV2ToCommonBookingOverview(item)
//     ) ?? []
//   );
// };

type ExtractedBookingOverview = GetMagicBookingOverviewsQuery["GetMagicBookingOverviews"][number];
export const transformApiV2ToCommonBookingOverview = (
  bookingOverviewV2?: Partial<ExtractedBookingOverview> | null
): BookingOverview => {
  return {
    actor: transformActorV2ToCommon(bookingOverviewV2?.actor),
    flowState: {
      wasOrIsCompleted: bookingOverviewV2?.flowState?.wasOrIsCompleted ?? false,
      completed: bookingOverviewV2?.flowState?.completed ?? false,
      context: bookingOverviewV2?.flowState?.context,
      notificationPending: bookingOverviewV2?.flowState?.notificationPending ?? false,
      notificationSent: bookingOverviewV2?.flowState?.notificationSent ?? false,
      sentNotifications: bookingOverviewV2?.flowState?.sentNotifications ?? [],
      performedActions: bookingOverviewV2?.flowState?.performedActions ?? []
    },
    items: bookingOverviewV2?.items?.map(transformApiV2ToCommonBookingOverviewItem) ?? [],
    keycloakUUID: bookingOverviewV2?.userAccountUuid ?? "",
    magicId: bookingOverviewV2?.magicId ?? "",
    magicToken: bookingOverviewV2?.magicToken ?? "",
    id: "", //Booking overview does not have any meaningful id
    magicLink: "",
    magicType: MagicType.BOOKER_OVERVIEW
  };
};
type ExtractedBookingOverviewItem =
  GetMagicBookingOverviewsQuery["GetMagicBookingOverviews"][number]["items"][number];

const transformApiV2ToCommonBookingOverviewItem = (
  bookingOverviewItemV2: Partial<ExtractedBookingOverviewItem>
): BookingOverviewItem => {
  const reservations = bookingOverviewItemV2?.reservations?.reduce(
    (acc: Array<ReservationCommon>, reservation) => {
      if (reservation) {
        acc.push(transformV2Reservation(reservation));
      }
      return acc;
    },
    []
  );
  return {
    active: bookingOverviewItemV2?.active ?? false,
    arrival: bookingOverviewItemV2.arrival,
    departure: bookingOverviewItemV2.departure,
    id: bookingOverviewItemV2?.id ?? "",
    propertyId: bookingOverviewItemV2?.pmsPropertyId ?? "",
    reservations: reservations ?? [],
    //TODO fix travel buddy ts overall
    travelBuddies:
      (bookingOverviewItemV2?.travelBuddies?.map(
        transformTravelBuddyV2ToCommon
      ) as unknown as Array<ReservationCommon>) ?? [],
    bookingManagementCompleted: bookingOverviewItemV2.bookingManagementCompleted ?? false
  };
};
