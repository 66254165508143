import { generateMagicLink } from "../../util/routing";
import { BookingOverviewPages } from "./booking-overview-pages";
import { BookingOverviewPagesToPath } from "./booking-overview-checkpoint-to-path";

export const bookingOverviewPages = [
  BookingOverviewPages.PROPERTIES,
  BookingOverviewPages.BOOKING_RESERVATIONS,
  BookingOverviewPages.BOOKING_RESERVATION_DETAILS,
  BookingOverviewPages.CHECKOUT,
  BookingOverviewPages.PAYMENT,
  BookingOverviewPages.PAYMENT_REDIRECT
];
export const generateBookingOverviewPagesUrl = (
  page: BookingOverviewPages,
  magicId: string,
  bookingOverviewItemId?: string,
  reservationMagicId?: string
) => {
  return `${generateMagicLink(magicId)}/booking-overview${
    BookingOverviewPagesToPath[BookingOverviewPages.BOOKING_RESERVATIONS]
  }${BookingOverviewPagesToPath[page]}`
    .replace(":bookingOverviewItemId", bookingOverviewItemId || ":bookingOverviewItemId")
    .replace(":reservationMagicId", reservationMagicId || ":reservationMagicId");
};

export const generateBookingOverviewReservationsURL = (
  magicId: string,
  bookingOverviewItemId?: string
) => {
  return `${generateMagicLink(magicId)}/booking-overview${
    BookingOverviewPagesToPath[BookingOverviewPages.BOOKING_RESERVATIONS]
  }`.replace(":bookingOverviewItemId", bookingOverviewItemId || ":bookingOverviewItemId");
};

export const generateInitBookingOverviewUrl = (magicId: string) =>
  `${generateMagicLink(magicId)}/booking-overview${
    BookingOverviewPagesToPath[BookingOverviewPages.PROPERTIES]
  }`;
