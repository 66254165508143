import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { sha256 } from "js-sha256";
import { AuthApi } from "../api/auth.api";
import { UserProfileApi } from "../api/user-profile.api";
import { LinkedAccount } from "../domain-common/linked-account";
import { handleSliceError } from "../util/error-handling";
import { RootState, ThunkExtraArguments } from "../state/store";
import { ApiErrorJson, handleAuthHeader, TenantContext } from "@likemagic-tech/sv-magic-library";
import { useContext } from "react";

export const fetchLinkedAccounts = createAsyncThunk<
  LinkedAccount[],
  { realm: string; authServerUrl: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("auth/fetchLinkedAccounts", async (arg, thunkAPI) => {
  try {
    if (!arg.realm) {
      throw new Error("keycloak realm not found");
    }
    const requestInit = {
      headers: await handleAuthHeader(thunkAPI.extra.keycloak)
    };
    return AuthApi.getLinkedAccounts(
      { realm: arg.realm, authServerUrl: arg.authServerUrl },
      { signal: thunkAPI.signal, ...requestInit }
    );
  } catch (e: any) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const unlinkAccount = createAsyncThunk<
  void,
  { providerName: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>(
  "auth/unlinkAccount",

  async (arg, thunkAPI) => {
    try {
      const requestInit = {
        headers: await handleAuthHeader(thunkAPI.extra.keycloak)
      };
      return await UserProfileApi.unlinkAccount(
        {
          providerName: arg.providerName
        },
        { signal: thunkAPI.signal, ...requestInit }
      );
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        ...e.raw,
        message:
          e.raw.errorMessage === "federatedIdentityRemovingLastProviderMessage"
            ? "Can not remove last account"
            : e.raw.message
      });
    }
  }
);

type AccountsSliceState = {
  loadingLinkedAccounts: boolean;
  linkedAccounts: LinkedAccount[];
};

const initialState: AccountsSliceState = {
  loadingLinkedAccounts: false,
  linkedAccounts: []
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLinkedAccounts.pending, (state) => {
        state.loadingLinkedAccounts = true;
      })
      .addCase(fetchLinkedAccounts.fulfilled, (state, action) => {
        state.linkedAccounts = action.payload;
        state.loadingLinkedAccounts = false;
      })
      .addCase(fetchLinkedAccounts.rejected, (state) => {
        state.loadingLinkedAccounts = false;
      });
  }
});

export const selectAccountsSlice = (state: any) => state[accountsSlice.name];

export const selectAccountLinkingHrefs = createSelector(
  selectAccountsSlice,
  ({ linkedAccounts }) => {
    const { keycloak } = useContext(TenantContext);
    return linkedAccounts.reduce((acc: any, account: any) => {
      if (!keycloak.tokenParsed) {
        return acc;
      }

      const session = keycloak.tokenParsed.session_state!;
      const clientId = keycloak.clientId!;
      const nonce = keycloak.tokenParsed.nonce!;
      const input = nonce + session + clientId + account.providerName;

      // adapted solution https://gist.github.com/kukat/49f2a53b6d74140fc28d2ef8fb6f44ae
      const inputArrayBuffer = new TextEncoder().encode(input);
      const check = sha256.array(inputArrayBuffer);
      // @ts-ignore
      const test = String.fromCharCode.apply(null, new Uint8Array(check));
      const base64string = window.btoa(test);
      const hash = base64string.replace(/\+/g, "-").replace(/\//g, "_");

      const url = new URL(
        `${keycloak.authServerUrl}realms/${keycloak.realm}/broker/${account.providerName}/link`
      );
      let params = url.searchParams;

      params.append("client_id", clientId);
      params.append("nonce", nonce + "");
      params.append("hash", hash);

      return {
        ...acc,
        [account.providerName]: url
      };
    }, {}) as Record<string, URL>;
  }
);

export const selectIsLoadingLinkedAccounts = createSelector(
  selectAccountsSlice,
  (res) => res.loadingLinkedAccounts
);
