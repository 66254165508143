import React, { createContext, FC, useContext, useMemo } from "react";
import { Reservation, ReservationFromJSON } from "../../domain-common/reservation";
import { initPortal } from "../portal/portal.slice";
import { useAppDispatch } from "../../state/store";
import { EmptyReservationPage } from "../portal/pages/empty-reservation.page";
import { useNavbar } from "../../components/organism/top-navbar/navbar-context";
import { isReservation, isTravelBuddy } from "../../util/flow";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { isKioskMode } from "../../util/kiosk-mode";
import { useSelector } from "react-redux";
import { selectUpdateReservationStatus } from "./reservation.slice";
import { MagicObject } from "../../domain-common/magic-object";

interface ReservationContextValue {
  reservation: Reservation;
}

const ReservationContext = createContext<ReservationContextValue>({
  // @ts-ignore
  reservation: undefined
});

export const useReservationContext = () => {
  return useContext(ReservationContext);
};

export const ReservationProvider: FC<
  React.PropsWithChildren<{ magicObject: MagicObject | null }>
> = ({ children, magicObject }) => {
  const reservation = useMemo(() => {
    if (isReservation(magicObject)) {
      return ReservationFromJSON(magicObject);
    } else if (isTravelBuddy(magicObject)) {
      return ReservationFromJSON(magicObject);
    }
    return null;
  }, [magicObject]);

  const dispatch = useAppDispatch();
  const { setDisplayNavbar, setLeftButtons } = useNavbar();
  const { login } = useAuth();
  const reservationStatus = useSelector(selectUpdateReservationStatus);

  React.useEffect(() => {
    if (reservationStatus === EntityStateStatus.UNAUTHORIZED) {
      login({
        prompt: isKioskMode() ? "login" : undefined
      });
    }
  }, [login, reservationStatus]);

  React.useEffect(() => {
    setDisplayNavbar(true);

    return () => {
      dispatch(initPortal());
    };
  }, [dispatch, setLeftButtons, setDisplayNavbar]);

  if (reservation === null) {
    return <EmptyReservationPage />;
  }

  return (
    <ReservationContext.Provider value={{ reservation }}>{children}</ReservationContext.Provider>
  );
};
