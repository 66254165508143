import { SearchBookingPages } from "./search-booking-pages";

export const SearchBookingPagesToPath = {
  [SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES]: "/",
  [SearchBookingPages.SEARCH_AVAILABLE_UNIT_GROUPS]: "/offers",
  [SearchBookingPages.CART_BOOKINGS]: "/cart",
  [SearchBookingPages.PAYMENT_AND_BOOKER_DATA]: "/payment-and-booker",
  [SearchBookingPages.PAYMENT_REDIRECT]: "/payment-redirect",
  [SearchBookingPages.CONFIRMATION]: "/confirmation/:newMagicId"
};

export const searchBookingPages = [
  SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES,
  SearchBookingPages.CART_BOOKINGS,
  SearchBookingPages.PAYMENT_AND_BOOKER_DATA,
  SearchBookingPages.CONFIRMATION
];

export const generateSearchBookingPagesUrl = (page: SearchBookingPages) =>
  `/search${SearchBookingPagesToPath[page]}`;

export const generateInitSearchBookingUrl = () =>
  generateSearchBookingPagesUrl(SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES);

export const isPagePartOfTheBookingGuestPages = (pageName?: string) => {
  if (!pageName) {
    return false;
  }
  return searchBookingPages
    .map((item) => SearchBookingPagesToPath[item])
    .filter((value, index, self) => self.indexOf(value) === index)
    .some((item) => item.indexOf(pageName) > -1);
};
