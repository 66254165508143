import React, { useCallback, useState } from "react";
import { Box, Fab, Grid, Theme, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import SwipeableViews from "react-swipeable-views";
import { ArrowLeftIcon } from "../icons";

export interface ImageData {
  image: any;
  label: string;
}

export interface EventImagesProps {
  imagesData: Array<ImageData>;
  handleBack: () => void;
}

const viewSize = 180;

const useStyles = makeStyles<{ showFullImage: boolean }>()(
  ({ spacing, palette }: Theme, props) => ({
    backBox: {
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 2,
      padding: spacing(3)
    },
    backIcon: {
      background: palette.background.paper,
      color: palette.text.primary,
      boxShadow: "0px 4px 8px rgba(197, 185, 172, 0.06)",
      "&:hover": {
        background: palette.background.paper
      }
    },
    root: {
      width: "100%",
      height: viewSize,
      zIndex: -1
    },
    swipeableViewsWrapper: {
      position: props.showFullImage ? "fixed" : "inherit",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: props.showFullImage ? 1 : 0,
      backgroundColor: palette.common.black
    },
    swipeableView: {
      display: props.showFullImage ? "flex" : "inherit",
      height: "100vh"
    },
    counter: {
      position: "absolute",
      borderRadius: 6,
      backgroundColor: palette.common.black,
      opacity: 0.6,
      padding: spacing(1),
      zIndex: 1,
      color: palette.common.white,
      right: 20,
      top: props.showFullImage ? spacing(4) : viewSize - 80,
      fontWeight: "bold",
      letterSpacing: 4
    },
    imageLabel: {
      color: palette.common.white,
      alignSelf: "flex-start",
      paddingLeft: spacing(2)
    },
    image: {
      width: "100%"
    }
  })
);

//TODO rename to something more general
export const EventImages: React.FC<React.PropsWithChildren<EventImagesProps>> = ({
  imagesData,
  handleBack
}) => {
  const [showFullImage, setShowFullImage] = useState(false);

  const { classes } = useStyles({ showFullImage });
  const toggleShowFullscreenImage = useCallback((fullScreenImage: boolean) => {
    setShowFullImage(fullScreenImage);
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = imagesData.length;

  const handleStepChange = useCallback(
    (step: number) => {
      setActiveStep(step);
    },
    [setActiveStep]
  );

  const handleBackAndMore = () => {
    handleBack();
    toggleShowFullscreenImage(false);
  };
  const theme = useTheme();
  return (
    <Box mb={-4} className={classes.root}>
      <Box className={classes.backBox}>
        <Fab
          size="medium"
          aria-label="back"
          className={classes.backIcon}
          onClick={handleBackAndMore}
        >
          <ArrowLeftIcon />
        </Fab>
      </Box>
      <Box
        onClick={() => toggleShowFullscreenImage(true)}
        className={classes.swipeableViewsWrapper}
      >
        <Box className={classes.counter}>
          {activeStep + 1}/{maxSteps}
        </Box>
        <SwipeableViews
          enableMouseEvents
          index={activeStep}
          onChangeIndex={handleStepChange}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        >
          {imagesData.map((imageData: ImageData, index) => (
            <Grid
              key={"image-".concat(index.toString())}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.swipeableView}
            >
              <Grid item className={classes.image}>
                {imageData.image}
              </Grid>
              <Grid item className={classes.imageLabel}>
                {imageData.label}
              </Grid>
            </Grid>
          ))}
        </SwipeableViews>
      </Box>
    </Box>
  );
};
