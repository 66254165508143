import React, { FC, useCallback, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import {
  formatDateTime,
  formatRange,
  Heading3,
  isBeforeNow,
  Paragraph,
  useGlobalModal
} from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";
import { RemoveItemIcon } from "../../../../components/icons/remove.icon";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { usePropertyCmsData } from "../../../../state/property-cms/use-property-cms-data";
import { usePropertyById } from "../../../property/use-property-by-id";
import { useAppDispatch } from "../../../../state/store";
import { removeFromBookingCart } from "../../booking-cart.slice";
import { useUnitGroupCmsData } from "../../../../state/property-cms/use-unit-group-cms-data";
import { ReservationOfferDataWithUUID } from "../../../../domain-common/create-booking-payment";
import { usePropertyTextCmsData } from "../../../../state/property-cms/use-property-text-cms-data";
import { responsiveVariables } from "../../../../components/layouts/hooks/use-is-mobile";
import { GtmEvent, useGtm } from "../../../gtm/use-gtm";
import { differenceInCalendarDays } from "date-fns";
import { getI18nSelectedLanguage } from "../../../../util/lang-utils";

const useStyles = makeStyles()(({ spacing, palette, breakpoints, shape }) => ({
  root: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      backgroundColor: palette.background.paper,
      borderRadius: shape.borderRadius * 2.5
    }
  },
  studioName: {
    marginBottom: spacing(1)
  },
  freeCancellation: {
    textDecoration: "underline",
    wordBreak: "break-word",
    cursor: "pointer"
  },
  img: {
    borderRadius: shape.borderRadius * 2.5,
    height: "100%",
    width: "100%",
    minHeight: "110px",
    minWidth: "110px",
    objectFit: "cover"
  },
  removeButton: {
    float: "right",
    marginTop: spacing(-1),
    marginRight: spacing(-1)
  },

  cartItemParagraph: {
    color: palette.text.secondary,
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      marginBottom: spacing(1)
    }
  }
}));

export const SearchCartItem: FC<React.PropsWithChildren<ReservationOfferDataWithUUID>> = ({
  uuid,
  propertyId,
  ratePlanId,
  unitGroupId,
  adults,
  promoCodePMS,
  promoCodeMagicPMS,
  cancellationFee,
  childrenAges,
  arrival,
  departure,
  priceTotal
}) => {
  const { classes } = useStyles();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const { open: openModal } = useGlobalModal();

  const property = usePropertyById(propertyId);
  const dispatch = useAppDispatch();
  const { pushToGtm } = useGtm();

  const computedPersonsLabel = useMemo(() => {
    const result = `${adults} ${
      adults === 1 ? t("labels__single_adult") : t("labels__multiple_adult")
    }`;
    if (childrenAges.length) {
      const kidsLabel =
        childrenAges.length === 1 ? t("labels__single_child") : t("labels__multiple_child");
      return result.concat(`, ${childrenAges?.length} ${kidsLabel}`);
    }
    return result;
  }, [childrenAges, t, adults]);

  const { propertyName } = usePropertyCmsData(propertyId);
  const { unitGroupLabel, firstStudioImage } = useUnitGroupCmsData(propertyId, unitGroupId);

  const removeItemFromCart = useCallback(() => {
    pushToGtm({
      event: GtmEvent.REMOVE_FROM_CART,
      ecommerce: {
        items: [
          {
            item_id: ratePlanId,
            item_category: unitGroupId,
            location_id: propertyId,
            price: priceTotal?.amount ? String(priceTotal.amount / 100) : "0",
            quantity: 1,
            cartUUID: uuid,
            arrival: arrival,
            departure: departure,
            nights: differenceInCalendarDays(new Date(departure), new Date(arrival)),
            adults: adults || 1,
            children: childrenAges || null,
            promoCodePMS: promoCodePMS,
            promoCodeMagicPMS: promoCodeMagicPMS
          }
        ],
        currency: priceTotal?.currency,
        value: priceTotal ? String(priceTotal?.amount / 100) : "0"
      }
    });
    dispatch(removeFromBookingCart({ uuid: uuid }));
  }, [
    uuid,
    dispatch,
    adults,
    arrival,
    childrenAges,
    departure,
    promoCodePMS,
    promoCodeMagicPMS,
    propertyId,
    pushToGtm,
    ratePlanId,
    unitGroupId,
    priceTotal
  ]);

  const { textOutput: dialogCancellationPolicy } = usePropertyTextCmsData(
    "cancellation_policies__display_labels",
    property?.propertyId,
    cancellationFee?.code
  );

  const openCancellationPolicyModal = useCallback(() => {
    openModal({
      modalProps: {
        title: dialogCancellationPolicy.text,
        content: <>{dialogCancellationPolicy.details || ""}</>
      },
      modalActions: [{ variant: "primary", label: t("buttons__ok"), result: null }]
    });
  }, [t, openModal, dialogCancellationPolicy]);

  const cancellationText = useMemo(() => {
    if (cancellationFee.dueDateTime === null || isBeforeNow(cancellationFee.dueDateTime)) {
      return t("labels__cancellation_fee_not_refundable");
    } else {
      return `${t("labels__cancellation_free_until")} ${formatDateTime(
        cancellationFee.dueDateTime,
        getI18nSelectedLanguage(),
        property.timeZone
      )}`;
    }
  }, [cancellationFee.dueDateTime, property.timeZone, t]);

  return (
    <Box className={classes.root}>
      <Grid container direction="row">
        <Grid item maxWidth={120}>
          <img src={firstStudioImage?.url} alt={firstStudioImage?.alt} className={classes.img} />
        </Grid>
        <Grid item xs>
          <RemoveItemIcon onClick={removeItemFromCart} className={classes.removeButton} />
          <Grid container pl={1}>
            <Grid item xs={12} md={6}>
              <Paragraph className={classes.cartItemParagraph}>{propertyName}</Paragraph>
              <Heading3 className={classes.studioName}>{unitGroupLabel}</Heading3>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paragraph className={classes.cartItemParagraph}>
                {formatRange(property.timeZone, getI18nSelectedLanguage(), arrival, departure)}
              </Paragraph>

              <Paragraph className={classes.cartItemParagraph}>{computedPersonsLabel}</Paragraph>
            </Grid>

            <Paragraph
              color="textSecondary"
              className={classes.freeCancellation}
              onClick={openCancellationPolicyModal}
            >
              {cancellationText}
            </Paragraph>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
