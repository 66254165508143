import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetAvailableUnitGroupsQueryVariables = Types.Exact<{
  filter: Types.UnitGroupAvailabilityFilter;
}>;

export type GetAvailableUnitGroupsQuery = {
  __typename?: "Query";
  GetAvailableUnitGroups: Array<{
    __typename?: "UnitGroupAvailability";
    arrival: any;
    departure: any;
    numberOfAvailableUnits: number;
    availableUnitsThreshold: number;
    numberOfNights: number;
    property?: { __typename?: "Property"; pmsId: string } | null;
    rates: Array<{
      __typename?: "Rate";
      guaranteeType?: Types.GuaranteeType | null;
      currentCancellationFee?: {
        __typename?: "CancellationFee";
        description?: string | null;
        dueDateTime?: any | null;
        name?: string | null;
        pmsId?: string | null;
        price?: {
          __typename?: "GrossPrice";
          currency?: string | null;
          grossPriceInCents?: number | null;
        } | null;
      } | null;
      averagePricePerNight: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPriceInCents?: number | null;
      };
      ratePlan?: { __typename?: "RatePlan"; pmsId?: string | null; name?: string | null } | null;
      totalPrice: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPriceInCents?: number | null;
      };
    }>;
    unitGroup?: {
      __typename?: "UnitGroup";
      pmsId?: string | null;
      capacity?: number | null;
    } | null;
  }>;
};

export const GetAvailableUnitGroupsDocument = `
    query GetAvailableUnitGroups($filter: UnitGroupAvailabilityFilter!) {
  GetAvailableUnitGroups(filter: $filter) {
    property {
      pmsId
    }
    arrival
    departure
    numberOfAvailableUnits
    availableUnitsThreshold
    numberOfNights
    rates {
      currentCancellationFee {
        description
        dueDateTime
        name
        pmsId
        price {
          currency
          grossPriceInCents
        }
        pmsId
      }
      averagePricePerNight {
        currency
        grossPriceInCents
      }
      ratePlan {
        pmsId
        name
      }
      totalPrice {
        currency
        grossPriceInCents
      }
      guaranteeType
    }
    unitGroup {
      pmsId
      capacity
    }
    property {
      pmsId
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetAvailableUnitGroups: build.query<
      GetAvailableUnitGroupsQuery,
      GetAvailableUnitGroupsQueryVariables
    >({
      query: (variables) => ({ document: GetAvailableUnitGroupsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetAvailableUnitGroupsQuery, useLazyGetAvailableUnitGroupsQuery } =
  injectedRtkApi;
