import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { Button, Heading3, Paragraph } from "@likemagic-tech/sv-magic-library";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC, useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { toggleSpecialRateModal } from "../../search-available-properties.slice";
import { useAppDispatch } from "../../../../state/store";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { WrappedInput } from "../../../../components/atoms/input/wrapped-input";
import { useLogoUrl } from "../../../../util/hooks/use-logo-url";
import { PromoCodeType } from "../../promocode.slice";
import { useValidatePromoCode } from "../../hooks/use-validate-promocode";

const useStyles = makeStyles()((theme) => ({
  modal: {
    borderRadius: theme.shape.borderRadius * 3
  },
  logo: {
    maxWidth: theme.spacing(25),
    height: theme.spacing(8.75)
  }
}));

interface SearchBookingSpecialRateModalProps {
  onChange: (promoCodePMS?: string, promoCodeType?: PromoCodeType) => void;
}

export const SearchBookingSpecialRateModal: FC<
  React.PropsWithChildren<SearchBookingSpecialRateModalProps>
> = ({ onChange }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [promoCodeInput, setPromoCodeInput] = useState("");
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { validatePromoCode } = useValidatePromoCode();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const logoUrl = useLogoUrl();

  const closeDialog = useCallback(() => {
    dispatch(toggleSpecialRateModal());
    setError(false);
  }, [dispatch]);

  const confirmPromoCode = useCallback(async () => {
    if (promoCodeInput) {
      validatePromoCode({ promoCode: promoCodeInput })
        .then(({ promoCode, type }) => {
          onChange(promoCode, type);
          setIsValid(true);
        })
        .catch(() => {
          setError(true);
          setIsValid(false);
        });
    }
  }, [validatePromoCode, onChange, promoCodeInput]);

  return (
    <Dialog
      PaperProps={{ classes: { root: `${classes.modal}` } }}
      fullWidth={true}
      open
      onClose={closeDialog}
      maxWidth="xs"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Heading3>{tCommon("title__special_rate_pms")}</Heading3>
          </Grid>
          <Grid item>
            <IconButton onClick={closeDialog} size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ px: 3, paddingBottom: 2 }}>
        {!isValid ? (
          <>
            <Paragraph marginBottom={2}>{tCommon("subtitle__special_rate_pms")}</Paragraph>
            <WrappedInput
              value={promoCodeInput}
              label={tCommon("labels__special_rate_code")}
              error={error ? tCommon("validation__promocode_invalid") : ""}
              onChange={(event) => setPromoCodeInput(event.target.value)}
            />
          </>
        ) : (
          <Grid container alignItems="center" direction="column">
            <Grid item marginBottom={2}>
              {logoUrl && <img src={logoUrl} alt="logo" className={classes.logo} />}
            </Grid>
            <Grid item>
              <Paragraph>{tCommon("labels__special_rate_code_success")}</Paragraph>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Grid container direction="row" justifyContent="flex-end" spacing={1}>
          {!isValid ? (
            <>
              <Grid item>
                <Button variant="secondary" onClick={closeDialog} fullWidth sx={{ height: "100%" }}>
                  <Paragraph>{tCommon("buttons__cancel")}</Paragraph>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="primary"
                  onClick={confirmPromoCode}
                  fullWidth
                  sx={{ height: "100%" }}
                >
                  <Paragraph>{tCommon("buttons__add_special_rate")} </Paragraph>
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button variant="primary" onClick={closeDialog} fullWidth sx={{ height: "100%" }}>
                <Paragraph>{tCommon("buttons__done")}</Paragraph>
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
