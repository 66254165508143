import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useIsMobile } from "../layouts/hooks/use-is-mobile";

const useStyles = makeStyles()({
  removeFill: {
    fill: "none"
  }
});

export function BackArrowIcon(props: SvgIconProps) {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  return (
    <SvgIcon className={classes.removeFill} {...props}>
      <path
        d="M19.2656 10.25L2.12277 10.25"
        stroke={isMobile ? "white" : "black"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1231 2.25L2.12311 10.25L10.1231 18.25"
        stroke={isMobile ? "white" : "black"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
