import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function ShoppingCart1Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>{`.a{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}`}</style>
      </defs>
      <title>shopping-cart-1</title>
      <path
        className="a"
        d="M6,19.125h9.921A1.5,1.5,0,0,0,17.4,17.876l2.637-15.5a1.5,1.5,0,0,1,1.479-1.248H22.5"
      />
      <path className="a" d="M7.875,21.375a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375" />
      <path className="a" d="M15.375,21.375A.375.375,0,1,1,15,21.75a.375.375,0,0,1,.375-.375" />
      <path
        className="a"
        d="M17.953,14.625H5.882a3,3,0,0,1-2.91-2.272l-1.45-5.8a.75.75,0,0,1,.728-.932H19.484"
      />
    </SvgIcon>
  );
}
