import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function ArrowLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          d="M11.25,23.25c-0.2,0-0.389-0.078-0.53-0.22l-10.5-10.5c-0.07-0.07-0.125-0.152-0.163-0.245
		c-0.003-0.008-0.007-0.017-0.01-0.026C0.016,12.174,0,12.088,0,12c0-0.089,0.016-0.176,0.048-0.261
		c0.002-0.007,0.005-0.014,0.008-0.021c0.04-0.097,0.095-0.179,0.166-0.249L10.72,0.97c0.142-0.142,0.33-0.22,0.53-0.22
		s0.389,0.078,0.53,0.22C11.922,1.111,12,1.3,12,1.5s-0.078,0.389-0.22,0.53l-9.22,9.22H23.25c0.414,0,0.75,0.336,0.75,0.75
		s-0.336,0.75-0.75,0.75H2.561l9.22,9.22c0.292,0.292,0.292,0.768,0,1.061C11.639,23.172,11.45,23.25,11.25,23.25z"
        />
      </g>
    </SvgIcon>
  );
}
