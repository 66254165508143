import { Heading2 } from "@likemagic-tech/sv-magic-library";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { Box, Grid } from "@mui/material";
import { SearchBookingProperties } from "../components/search-booking-properties";
import {
  SearchBookingFilters,
  SearchBookingFiltersValues
} from "../components/filters/search-booking-filters";
import { useAppDispatch } from "../../../state/store";
import { useSearchBookNavigation } from "../hooks/use-search-book-navigation";
import { SearchBookingSpecialRateModal } from "../components/filters/search-booking-special-rate-modal";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useStylesForSearchFiltersWrapper } from "../components/use-dynamic-style-for-search-filter";
import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";
import { NotificationBannerWrapper } from "../../../components/atoms/notification/notification-banner-wrapper";
import { SearchBookingCities } from "../components/search-booking-cities";
import { useFeatureFlags, useKioskConfig } from "../../../util/hooks/use-configuration";
import { useSearchParams } from "../hooks/use-search-offer-params";
import { citySelection } from "../components/filters/search-booking-property-filter";
import { PropertiesByCity } from "../../../domain-common/properties-by-city";
import { useProperties } from "../../property/use-property-by-id";
import { isKioskMode } from "../../../util/kiosk-mode";
import { PromoCodeType, resetPromoCode } from "../promocode.slice";
import { useSelector } from "react-redux";
import { selectSearchBookingFilters } from "../search-available-properties.slice";

//Logic to preselect property if there is one property or one city
const getPreselectedPropertyId = (
  properties: PropertiesByCity,
  showOnlyCities: boolean,
  kioskPropertyId?: string
) => {
  const [firstValue] = Object.values(properties);
  if (kioskPropertyId) {
    return kioskPropertyId;
  } else if (showOnlyCities && Object.keys(properties).length) {
    return citySelection(firstValue);
  } else if (Object.keys(properties).length === 1 && firstValue.length === 1) {
    return firstValue[0]?.propertyId;
  } else {
    return "";
  }
};

export const SearchAvailablePropertiesPage = () => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { data: properties } = useProperties();
  const { searchByLocationEnabled } = useFeatureFlags();
  const { classes } = useStylesForSearchFiltersWrapper();
  const { availableButtons } = useSearchBookNavigation();
  const dispatch = useAppDispatch();
  const { searchOfferParamsDTO, setSearchOfferParamsDTO } = useSearchParams();
  const { arrival, departure, adults, childrenAges, propertyIds, promoCodePMS, promoCodeMagic } =
    searchOfferParamsDTO;
  const { specialRateModalOpen } = useSelector(selectSearchBookingFilters);

  const { propertyId: kioskPropertyId } = useKioskConfig();
  const preSelectedKioskPropertyId = useMemo(
    () => (isKioskMode() ? kioskPropertyId : undefined),
    [kioskPropertyId]
  );
  const defaultPropertyIds = useMemo(
    () => getPreselectedPropertyId(properties, searchByLocationEnabled, preSelectedKioskPropertyId),
    [properties, searchByLocationEnabled, preSelectedKioskPropertyId]
  );

  const searchValues = useMemo(() => {
    return {
      arrival,
      departure,
      adults,
      childrenAges,
      ...(promoCodePMS && { promoCodePMS }),
      ...(promoCodeMagic && {
        promoCodeMagic
      }),
      propertyIds: propertyIds || defaultPropertyIds
    };
  }, [
    arrival,
    departure,
    adults,
    childrenAges,
    propertyIds,
    promoCodeMagic,
    promoCodePMS,
    defaultPropertyIds
  ]);

  const onFiltersChange = useCallback(
    (values: SearchBookingFiltersValues) => {
      setSearchOfferParamsDTO({ ...values, propertyIds: values.propertyIds });
    },
    [setSearchOfferParamsDTO]
  );

  const resetPromoCodeAction = useCallback(() => {
    dispatch(resetPromoCode());
    setSearchOfferParamsDTO({
      ...searchOfferParamsDTO,
      promoCodePMS: undefined,
      promoCodeMagic: undefined
    });
  }, [dispatch, searchOfferParamsDTO, setSearchOfferParamsDTO]);

  const { setRightButtons, setDisplayNavbar, setLeftButtons } = useNavbar();

  useEffect(() => {
    setLeftButtons([]);
    setRightButtons([availableButtons]);
    setDisplayNavbar(true);
  }, [availableButtons, setRightButtons, setDisplayNavbar, setLeftButtons]);

  const onChangeRateModal = useCallback(
    (promoCode?: string, promoCodeType?: string) => {
      if (promoCodeType === PromoCodeType.PMS) {
        onFiltersChange({ ...searchValues, promoCodePMS: promoCode });
      } else if (promoCodeType === PromoCodeType.MAGIC) {
        onFiltersChange({ ...searchValues, promoCodeMagic: promoCode });
      } else {
        return;
      }
    },
    [onFiltersChange, searchValues]
  );

  return (
    <>
      <HelmetTitle suffix="Available properties" />

      <Box minHeight={126}>
        <Grid container p={2.5} direction="row" className={classes.searchFiltersWrapper}>
          <Grid item xs={12} md={2}>
            <Grid container alignItems="center" direction="column" spacing={0} mb={2.5}>
              <Grid item>
                <Box>
                  <Heading2 align="center" gutterBottom>
                    {tCommon("title__select_studio")}
                  </Heading2>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10}>
            <SearchBookingFilters
              values={searchValues}
              onChange={onFiltersChange}
              resetPromoCode={resetPromoCodeAction}
              showOnlyCities={searchByLocationEnabled}
              disablePropertyInput={!!preSelectedKioskPropertyId}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mx={1}>
        <NotificationBannerWrapper>
          {specialRateModalOpen && (
            <SearchBookingSpecialRateModal
              onChange={(promoCode, promoCodeType) => onChangeRateModal(promoCode, promoCodeType)}
            />
          )}
          {searchByLocationEnabled ? (
            <SearchBookingCities searchValues={searchValues} />
          ) : (
            <SearchBookingProperties searchValues={searchValues} />
          )}
        </NotificationBannerWrapper>
      </Box>
    </>
  );
};
