import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { Button, Heading3, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useKioskStyle } from "./find-reservation-kiosk.style";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import QRCode from "react-qr-code";

export enum KioskOptionType {
  CREATE_KEY = "CREATE_KEY",
  FIND_RESERVATION = "FIND_RESERVATION",
  CREATE_BOOKING = "CREATE_BOOKING"
}
export interface KioskOptionProp {
  type: KioskOptionType;
  title: string;
  buttonLabel: string;
  redirect: () => void;
  showQRCode: boolean;
  iconUrl?: string;
}
export const KioskOption: FC<KioskOptionProp> = ({
  type,
  redirect,
  title,
  buttonLabel,
  showQRCode,
  iconUrl
}) => {
  const { classes: kioskStyle } = useKioskStyle();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  return (
    <Grid container direction="column">
      <Grid item xs={6} gap={30}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={kioskStyle.kioskOption}
          mr={2}
          p={4}
        >
          {iconUrl && <img className={kioskStyle.icon} src={iconUrl} alt={`icon-${type}`} />}
          <Heading3 sx={{ m: "16px" }} align={"center"}>
            {t(title)}
          </Heading3>
          <Button
            variant="primary"
            onClick={redirect}
            sx={{
              mt: "auto",
              width: "222px",
              height: "40px",
              fontSize: "16px",
              fontWeight: "700"
            }}
          >
            {t(buttonLabel)}
          </Button>
        </Box>
      </Grid>
      {showQRCode && (
        <Grid item xs={6}>
          <Box sx={{ mt: 1 }} mr={2} textAlign="center">
            <div className={kioskStyle.qrText}>
              <Heading3 sx={{ mr: 1, display: "inline" }}>
                {t("labels__or")?.toUpperCase()}
              </Heading3>
              <Paragraph sx={{ display: "inline" }} width="70%" margin="auto">
                {t("labels__kiosk_qr_info")}
              </Paragraph>
            </div>
            <Box m={0} p={0}>
              <QRCode value={window.location.origin} size={100} />
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
