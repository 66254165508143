import { logDoorStatus } from "../portal/portal.slice";
import { useAppDispatch } from "../../state/store";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { api as logDoorStatusApi } from "../../graphql/mutations/log-door-actions.generated";
import { DoorAccessStatus } from "../../graphql/generated/graphql";

interface LogDoorStatusProps {
  magicId: string;
  magicToken: string;
  doorId: string;
  data: any;
  status: boolean;
}

export const useLogDoorStatus = () => {
  const { isRESTVersion } = useApiVersion();
  const dispatch = useAppDispatch();

  return (args: LogDoorStatusProps) =>
    isRESTVersion
      ? dispatch(logDoorStatus(args)).unwrap()
      : dispatch(
          logDoorStatusApi.endpoints.LogDoorAccess.initiate({
            magicId: args.magicId,
            magicToken: args.magicToken,
            doorId: args.doorId,
            doorLog: JSON.stringify(args.data),
            doorAccesStatus: args.status ? DoorAccessStatus.Ok : DoorAccessStatus.NotOk
          })
        ).unwrap();
};
