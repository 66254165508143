import { useMediaQuery, useTheme } from "@mui/material";
import { Breakpoint } from "@mui/material/styles";

export const responsiveVariables: {
  desktopComponentsWidth: number;
  firstDesktopSize: Breakpoint;
} = {
  desktopComponentsWidth: 1200,
  firstDesktopSize: "md"
};
export const useIsMobile = () => {
  const theme = useTheme();

  return !useMediaQuery(theme.breakpoints.up(responsiveVariables.firstDesktopSize));
};
