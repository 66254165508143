import "@adyen/adyen-web/dist/adyen.css";
import { Box } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmationType, DesktopCard, DisplayCmsSvg } from "../../../components";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";

import {
  generatePortalBoxShopPaymentSuccessUrl,
  generatePortalPaymentRedirectUrl,
  generatePortalPaymentSuccessUrl,
  generatePortalSelfPouringStationShopPaymentSuccessUrl
} from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import {
  clearPaymentResponses,
  clearPaymentState,
  selectPaymentSlice
} from "../../payment/payment.slice";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useAppDispatch } from "../../../state/store";
import { PaymentComponent } from "../../payment/payment-component";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { NotificationBannerWrapper } from "../../../components/atoms/notification/notification-banner-wrapper";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { clearAdditionalServicesCart } from "../../additional-services/service-selection/additional-services-cart.slice";
import { clearCart } from "../../shop/store/cart.slice";
import { useSelector } from "react-redux";

export const PortalPaymentPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { paymentDTO } = useSelector(selectPaymentSlice);
  const { magicId } = useMagicIdParams();
  const { search } = useLocation();

  const isBoxShopPayment = useMemo(
    () => search.indexOf(ConfirmationType.BOX_SHOP) !== -1,
    [search]
  );

  // TODO - could be using parseConfirmationTypeFromQueryParam
  const isSelfPouringStationPayment = useMemo(
    () => search.indexOf(ConfirmationType.SELF_POURING_STATION_SHOP) !== -1,
    [search]
  );

  const deviceId = useMemo(() => {
    const searchParamState = new URLSearchParams(search);
    return searchParamState.get("deviceId") || undefined;
  }, [search]);

  const clearAfterPayment = useCallback(() => {
    // clear carts for shop and additional items if needed
    if (paymentDTO.shopItems) {
      dispatch(clearCart({ magicId }));
    }
    if (paymentDTO.additionalServices) {
      dispatch(clearAdditionalServicesCart());
    }
    dispatch(clearPaymentResponses());
    dispatch(clearPaymentState());
  }, [dispatch, paymentDTO, magicId]);

  const onSuccessPayment = useCallback(() => {
    clearAfterPayment();

    if (isBoxShopPayment) {
      navigate(generatePortalBoxShopPaymentSuccessUrl(magicId, deviceId));
    } else if (isSelfPouringStationPayment) {
      navigate(generatePortalSelfPouringStationShopPaymentSuccessUrl(magicId, deviceId));
    } else {
      navigate({
        pathname: generatePortalPaymentSuccessUrl(magicId),
        search
      });
    }
  }, [
    navigate,
    magicId,
    search,
    isBoxShopPayment,
    deviceId,
    clearAfterPayment,
    isSelfPouringStationPayment
  ]);

  const returnUrl = useMemo(
    () => generatePortalPaymentRedirectUrl(magicId) + search,
    [search, magicId]
  );

  useSetupSubpageNavigation();

  return (
    <>
      <HelmetTitle suffix="Portal payment" />
      <RoundedFullHeight>
        <Box p={2.5}>
          <NotificationBannerWrapper />
          <DesktopCard>
            <PageHeadingInfo
              title={tCommon("title__payment_page")}
              icon={<DisplayCmsSvg url={cmsData?.data?.["icon__bill_icon"]?.url} />}
            />
            <PaymentComponent onSuccessPayment={onSuccessPayment} returnUrl={returnUrl} />
          </DesktopCard>
        </Box>
      </RoundedFullHeight>
    </>
  );
};
