import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { GuestFlowCheckpoint } from "./checkpoint/guest-flow-checkpoint";
import { GuestFlowCheckpointToPath } from "./checkpoint/guest-flow-checkpoint-to-path";
import {
  AddressPage,
  LegalPage,
  OverviewPage,
  PaymentPage,
  PersonalDataPage,
  PreferredChannelPage,
  ServicesPage
} from "./pages";
import { AssignStudioPage } from "./pages/assign-studio.page";
import { ConfirmationPage } from "./pages/confirmation.page";
import { AssignKeyPage } from "./pages/assign-key.page";
import { useGuestFlowCheckpoint } from "./checkpoint/use-guest-flow-checkpoint";
import { handleGuestFlow } from "../magic/use-magic-object-subpath-resolver";
import { useSubscribeToUseCase } from "../web-socket/use-subscribe-to-use-case";
import { SubscriptionUseCase } from "../web-socket/use-magic-web-socket";
import { useMagicIdParams } from "../magic/use-magic-id-params";
import { PaymentRedirectPage } from "./pages/payment-redirect.page";
import { FastGuestFlowPage } from "./pages/fast-guest-flow.page";
import { useReservationContext } from "../reservation-provider/reservation-provider";
import { NotFoundPage } from "../../components/organism/not-found.page";

export const GuestFlowRouterPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname: path } = useLocation();
  const { magicId } = useMagicIdParams();

  const { reservation } = useReservationContext();
  const { restore: restoreGuestFlow } = useGuestFlowCheckpoint();
  useEffect(() => {
    if (reservation) {
      handleGuestFlow(`/magic/${reservation.magicId}`, reservation, restoreGuestFlow);
    }
  }, [reservation, restoreGuestFlow]);

  useSubscribeToUseCase({
    magicId: reservation?.magicId,
    magicToken: reservation?.magicToken,
    topic: SubscriptionUseCase.PORTAL,
    forceSkipSubscribe: magicId !== reservation?.magicId
  });

  if (!reservation) {
    return <div>Loading</div>;
  }

  return (
    <Routes>
      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.PERSONAL_DATA]}
        element={<PersonalDataPage />}
      ></Route>
      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.PREFERRED_CHANNEL]}
        element={<PreferredChannelPage />}
      />
      <Route path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.LEGAL]} element={<LegalPage />} />
      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.ADDRESS]}
        element={<AddressPage />}
      />

      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.SERVICES]}
        element={<ServicesPage />}
      />
      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.OVERVIEW]}
        element={<OverviewPage />}
      />
      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.PAYMENT]}
        element={<PaymentPage />}
      />
      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.CONFIRMATION]}
        element={<ConfirmationPage />}
      />
      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.PAYMENT_REDIRECT]}
        element={<PaymentRedirectPage path={path} />}
      />
      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.ASSIGN_STUDIO]}
        element={<AssignStudioPage />}
      />
      <Route
        path={GuestFlowCheckpointToPath[GuestFlowCheckpoint.ASSIGN_KEY]}
        element={<AssignKeyPage />}
      />
      <Route path="/quick" element={<FastGuestFlowPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
