import { useCMSData, useCmsTabData } from "../cms/use-cms-data";
import { fetchCommonCMS, selectAllCommonCMSByLang } from "./common-cms.slice";

export const useCommonCmsData = () => useCMSData(selectAllCommonCMSByLang, fetchCommonCMS);

export const useCommonOfflineCmsData = () =>
  useCmsTabData(selectAllCommonCMSByLang, fetchCommonCMS, "offline");

export const useCommonValidationCmsData = () =>
  useCmsTabData(selectAllCommonCMSByLang, fetchCommonCMS, "validation");

export const useCommonForbiddenCmsData = () =>
  useCmsTabData(selectAllCommonCMSByLang, fetchCommonCMS, "403_error");

export const useCommonNotFoundCmsData = () =>
  useCmsTabData(selectAllCommonCMSByLang, fetchCommonCMS, "404_error");

export const useCommonGlobalErrorCmsData = () =>
  useCmsTabData(selectAllCommonCMSByLang, fetchCommonCMS, "global_error");

export const useCommonApplicationCmsData = () =>
  useCmsTabData(selectAllCommonCMSByLang, fetchCommonCMS, "application");

export const useCommonErrorsMapperCmsData = () =>
  useCmsTabData(selectAllCommonCMSByLang, fetchCommonCMS, "errors");

export const useCommonChannelCodesMapperCmsData = () =>
  useCmsTabData(selectAllCommonCMSByLang, fetchCommonCMS, "channel_codes");
