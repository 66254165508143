import * as Types from "../../generated/graphql";

export type TravelBuddyPersonFragmentFragment = {
  __typename?: "Person";
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  gender?: Types.Gender | null;
  birthdate?: any | null;
  termsAndConditionsMarketingConsent: boolean;
  termsAndConditionsMarketingConsentDoubleOptin: boolean;
  termsAndConditionsGeneral: boolean;
  termsAndConditionsOnline: boolean;
  preferredCommunicationChannel?: Types.CommunicationChannel | null;
  identificationDocumentNumber?: string | null;
  pmsId: string;
  preferredLanguage?: string | null;
  emergencyEvacuationHelpNeeded: boolean;
  enrolledInLoyaltyProgram: boolean;
  address?: {
    __typename?: "Address";
    addressLine1?: string | null;
    addressLine2?: string | null;
    postalCode?: string | null;
    city?: string | null;
    countryCode?: string | null;
  } | null;
};

export const TravelBuddyPersonFragmentFragmentDoc = `
    fragment TravelBuddyPersonFragment on Person {
  firstName
  lastName
  email
  phone
  gender
  address {
    addressLine1
    addressLine2
    postalCode
    city
    countryCode
  }
  birthdate
  termsAndConditionsMarketingConsent
  termsAndConditionsMarketingConsentDoubleOptin
  termsAndConditionsGeneral
  termsAndConditionsOnline
  preferredCommunicationChannel
  identificationDocumentNumber
  pmsId
  preferredLanguage
  emergencyEvacuationHelpNeeded
  enrolledInLoyaltyProgram
}
    `;
