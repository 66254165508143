import { FC, useCallback } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import {
  Heading2,
  Heading3,
  Heading4,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { Counter } from "../../../components/molecules/counter";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useServicesCmsData } from "../../services/use-services-cms-data";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { BoxType } from "../../../domain-v1/box-availability";
import { FullPrice, multiply, toGross } from "../../../domain-common/full-price";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { ServiceBoxItem } from "../pages/box-shop.page";

interface BoxItemModalProps {
  serviceBox: ServiceBoxItem;
  amount: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
}

export const BoxItemModal: FC<BoxItemModalProps> = ({ serviceBox, amount, setAmount }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { palette } = useTheme();
  const { reservation } = useReservationContext();

  const { serviceTitles: productServiceTitles } = useServicesCmsData(reservation.propertyId);

  const boxShopPriceLabel = useCallback(
    (type: BoxType, price: FullPrice, amount: number) => {
      if (price) {
        switch (type) {
          case BoxType.BUY:
            return formatPriceToString(toGross(price));
          case BoxType.BUY_CONSUMABLES:
          case BoxType.RENT:
            return formatPriceToString(toGross(multiply(price, amount)));
          case BoxType.BUY_FREE:
            return t("labels__box_shop_for_buy_free");
        }
      }
    },
    [t]
  );

  return (
    <Box minWidth="310px">
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <Heading3>{productServiceTitles[serviceBox.serviceId]}</Heading3>
        </Grid>
        {(serviceBox.boxCollection || serviceBox.unitId) && (
          <ParagraphBold gutterBottom align="center" color="text.secondary">
            {/* TODO move it to Prismic */}
            {serviceBox.boxCollection
              ? serviceBox.boxCollection
              : t("labels__unit") + " " + reservation.unit?.name}
          </ParagraphBold>
        )}
        <Grid item>
          <Box
            mt={2}
            minHeight={75}
            minWidth={75}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: palette.primary.main
            }}
          >
            <Heading4 sx={{ color: palette.background.paper, p: 1 }}>{serviceBox.boxName}</Heading4>
          </Box>
        </Grid>
      </Grid>
      {(serviceBox.type === BoxType.BUY_CONSUMABLES || serviceBox.type === BoxType.RENT) && (
        <Grid container mt={2} alignItems="center" direction="column">
          <Grid item>
            <ParagraphSmall>
              {serviceBox.type === BoxType.BUY_CONSUMABLES
                ? t("labels__box_shop_item")
                : t(`labels__box_shop_${serviceBox.boxRentType?.toLowerCase()}_plural`)}
            </ParagraphSmall>
          </Grid>
          <Grid item>
            <Counter
              initialCount={amount}
              minValue={1}
              onCountChange={setAmount}
              maxValue={serviceBox.type === BoxType.RENT ? serviceBox?.maximum : undefined}
            />
          </Grid>
        </Grid>
      )}
      <Box mt={2}>
        <Heading2 textAlign="center" sx={{ color: palette.text.primary }}>
          {boxShopPriceLabel(serviceBox.type, serviceBox.fullPrice, amount)}
        </Heading2>
      </Box>
    </Box>
  );
};
