import {
  createAsyncThunk,
  createEntityAdapter,
  Draft,
  EntityState,
  PayloadAction,
  SerializedError
} from "@reduxjs/toolkit";
import mergeWith from "lodash.mergewith";
import { PrismicData } from "../../api/cms-client/use-cms-client";
import { PrismicDocument } from "../../domain-common/prismic-document";
import { RootState, ThunkExtraArguments } from "../store";
import { CMSResourceStatus } from "./cms-resource-status";
import { createCmsSelectorsFactory } from "./cms-selectors";
import { CMSSingleDocumentTypes } from "./cms-single-document-types";
import { filter } from "@prismicio/client";

type CMSPageStateExtension = {
  loadingStatuses: {
    [lang: string]: CMSResourceStatus;
  };
};

export type CMSPageState = EntityState<PrismicDocument> & CMSPageStateExtension;

const adapterFactory = () => {
  const adapter = createEntityAdapter<PrismicDocument>({
    selectId: (model) => (model?.lang ? model.lang : "not_found")
  });

  function getInitialState(): CMSPageState {
    return adapter.getInitialState<CMSPageStateExtension>({
      loadingStatuses: {}
    });
  }

  function handlePendingLoadingStatus(
    state: Draft<CMSPageState>,
    action: PayloadAction<any, string, { arg: { language: string } }>
  ): void {
    const lang = action.meta.arg.language ?? action.payload?.lang;
    state.loadingStatuses[lang] = CMSResourceStatus.PENDING;
  }

  function handleFulfilledLoadingStatus(
    state: Draft<CMSPageState>,
    action: PayloadAction<any, string, { arg: { language: string } }>
  ): void {
    const lang = action.meta.arg.language ?? action.payload?.lang;
    state.loadingStatuses[lang] = CMSResourceStatus.FULFILLED;
  }

  function handleRejectedLoadingStatus(
    state: Draft<CMSPageState>,
    action: PayloadAction<
      any,
      string,
      { arg: { language: string }; aborted: boolean },
      SerializedError
    >
  ): void {
    const lang = action.meta.arg.language ?? action.payload?.lang;
    if (action.error.name === "AbortError") {
      if (state.loadingStatuses[lang] === CMSResourceStatus.PENDING) {
        state.loadingStatuses[lang] = CMSResourceStatus.IDLE;
      }
      return;
    }
    state.loadingStatuses[lang] = CMSResourceStatus.REJECTED;
  }

  const cmsSelectorsFactory = createCmsSelectorsFactory();

  return {
    ...adapter,
    getInitialState,
    handlePendingLoadingStatus,
    handleFulfilledLoadingStatus,
    handleRejectedLoadingStatus,
    ...cmsSelectorsFactory
  };
};

export const createCMSSingleDocumentFetchThunk = (
  documentType: CMSSingleDocumentTypes,
  fetchLinks?: Array<string>
) =>
  createAsyncThunk<
    PrismicDocument,
    {
      language: string;
      prismic: PrismicData;
      commonPrismic?: PrismicData;
      type?: "FORMAL" | "INFORMAL";
    },
    { state: RootState; extra: ThunkExtraArguments }
  >(
    `cms/${documentType}/fetchSingle`,
    async (arg) => {
      const lang = arg.language;

      if (!lang) {
        return Promise.reject("No language provided.");
      }

      const prismicResult = await arg.prismic.prismicApiClient.getSingle(documentType, {
        filters: [
          //We need to filter out those tags only in case we use LikeMagic Prismic
          filter.not(`document.tags`, ["FORMAL"]),
          filter.not(`document.tags`, ["INFORMAL"])
        ],
        lang,
        fetchLinks,
        ...arg.prismic.refOptions
      });
      if (arg.commonPrismic && arg.type) {
        try {
          const commonPrismicResult = await arg.commonPrismic.prismicApiClient.getSingle(
            documentType,
            {
              filters: [filter.at(`document.tags`, [arg.type])],
              lang,
              ...arg.commonPrismic.refOptions
            }
          );

          if (Object.keys(commonPrismicResult.data)) {
            const data = Object.keys(prismicResult.data).reduce((acc: any, next) => {
              if (prismicResult.data[next]) {
                acc[next] = prismicResult.data[next];
              }
              return acc;
            }, {});

            return mergeWith(
              commonPrismicResult,
              { data },
              (objValue: PrismicDocument, srcValue: any, key: string) => {
                if (
                  Array.isArray(objValue) &&
                  ["channel_codes__mapper", "error__mapper"].includes(key)
                ) {
                  return [...srcValue, ...objValue];
                }
                return undefined;
              }
            );
          }
        } catch (e) {
          console.warn(e);
        }
      }
      return prismicResult;
    },
    {
      condition(arg: { language: string }, thunkAPI): boolean | undefined {
        let lang = arg.language;
        let loadingStatus = thunkAPI.getState().cms[documentType].loadingStatuses[lang];

        if (
          loadingStatus === CMSResourceStatus.FULFILLED ||
          loadingStatus === CMSResourceStatus.PENDING
        ) {
          return false;
        }
      }
    }
  );

export const createCMSSinglePageAdapter = adapterFactory;
