import { Box, Grid, Slider } from "@mui/material";
import React, { FC, useCallback, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../state/store";
import { Heading4, Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Counter } from "../../../../components/molecules/counter";
import { Dialog, FakeInputBox } from "../../../../components";
import { Theme } from "@mui/material/styles";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import {
  selectSearchBookingFilters,
  togglePeoplePickerModal
} from "../../search-available-properties.slice";
import { useConfigBasedOnlyOnBaseUrl } from "../../../../util/hooks/use-configuration";

const useStyles = makeStyles()(({ spacing, palette, shape }) => ({
  modal: {
    borderRadius: shape.borderRadius * 3
  },
  closeButton: {
    position: "absolute",
    right: spacing(1),
    top: spacing(1),
    color: palette.grey[500]
  },
  content: {
    padding: spacing(0),
    marginTop: spacing(2)
  },
  title: {
    padding: spacing(3),
    paddingBottom: spacing(1)
  },
  numberPeopleContainer: {
    borderTop: `${spacing(0.05)} ${palette.grey[500]} solid`,
    marginBottom: spacing(1),
    padding: `${spacing(3)} ${spacing(2)} ${spacing(3)} ${spacing(3)}`
  },
  spaceNumberSlider: {
    marginLeft: spacing(3)
  },
  addChildrenContainer: {
    marginTop: spacing(2),
    marginBottom: spacing(4),
    padding: `${spacing(0)} ${spacing(2)}`
  },
  searchButton: {
    borderTop: `${spacing(0.05)} ${palette.grey[500]} solid`,
    padding: `${spacing(2)} ${spacing(2)} ${spacing(3)} ${spacing(2)} `
  }
}));

const useStylesPaper = makeStyles()(({ spacing, shape }: Theme) => ({
  paper: {
    padding: spacing(1),
    borderRadius: shape.borderRadius * 2.5,
    minWidth: 300,
    width: "40%"
  }
}));

interface SearchBookingPeoplePickerProps {
  adults: number;
  childrenAges: number[];
  onChange: ({ adults, childrenAges }: { adults: number; childrenAges: Array<number> }) => void;
}

export const SearchBookingPeoplePicker: FC<
  React.PropsWithChildren<SearchBookingPeoplePickerProps>
> = ({ adults, childrenAges, onChange }) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { classes: classesPaper } = useStylesPaper();
  const { peoplePickerModalOpen } = useSelector(selectSearchBookingFilters);
  const { search } = useConfigBasedOnlyOnBaseUrl();
  const adultAge = search?.childrenAgesConfig?.maxAge ? search?.childrenAgesConfig?.maxAge + 1 : "";

  const babyAge = search?.childrenAgesConfig?.minAge ? search?.childrenAgesConfig?.minAge - 1 : "";

  const childrenAgeMinValue = search?.childrenAgesConfig?.minAge
    ? search?.childrenAgesConfig?.minAge
    : 3;

  const [numberAdults, setNumberAdults] = useState(adults);
  const [numberKids, setNumberKids] = useState(childrenAges);

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const people = useMemo(() => {
    const adultLabel = tCommon("labels__adults");
    if (search?.adultsOnly) {
      return `${adults} ${adultLabel}`;
    } else {
      return `${adults} ${adultLabel}, ${childrenAges.length} ${tCommon("labels__children")}`;
    }
  }, [adults, childrenAges, tCommon, search?.adultsOnly]);

  const togglePeoplePickerCallback = useCallback(() => {
    dispatch(togglePeoplePickerModal());
    // This is needed to avoid a possible flickering when the user search N kids
    // opens again the dialog add a new kid and then simply close it with X.
    setTimeout(() => {
      if (peoplePickerModalOpen) {
        setNumberKids(childrenAges);
        setNumberAdults(adults);
      }
    }, 100);
  }, [dispatch, adults, childrenAges, peoplePickerModalOpen]);

  const updateKidAge = useCallback(
    (age: number, index: number) => {
      const copyArray = [...numberKids];
      copyArray[index] = age;
      setNumberKids(copyArray);
    },
    [numberKids, setNumberKids]
  );

  const sliders = useMemo(() => {
    return numberKids.map((kidAge, index) => (
      <Grid key={index} container flexWrap="nowrap" alignItems="center">
        <Slider
          value={kidAge}
          onChange={(event, newValue) => updateKidAge(newValue as number, index)}
          max={search?.childrenAgesConfig?.maxAge}
          track={false}
          min={search?.childrenAgesConfig?.minAge}
          aria-labelledby="input-slider"
        />
        <Paragraph variant="subtitle2" className={classes.spaceNumberSlider}>
          {kidAge || search?.childrenAgesConfig?.minAge}
        </Paragraph>
      </Grid>
    ));
  }, [
    numberKids,
    updateKidAge,
    classes.spaceNumberSlider,
    search?.childrenAgesConfig?.maxAge,
    search?.childrenAgesConfig?.minAge
  ]);

  const updateNumberOfKids = useCallback(
    (numberOfKids: number) => {
      if (numberOfKids !== numberKids.length) {
        const copyArray = [...numberKids];
        if (numberOfKids > numberKids.length) {
          copyArray.push(childrenAgeMinValue);
        } else {
          copyArray.pop();
        }
        setNumberKids(copyArray);
      }
    },
    [numberKids, setNumberKids, childrenAgeMinValue]
  );

  const triggerSearch = useCallback(() => {
    onChange({ adults: numberAdults, childrenAges: numberKids });
    dispatch(togglePeoplePickerModal());
  }, [dispatch, onChange, numberAdults, numberKids]);

  const dialogContent = useMemo(
    () => (
      <>
        <Grid
          container
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          className={classes.numberPeopleContainer}
        >
          <Grid container flexDirection="column">
            <Box>
              <Heading4>{tCommon("modals__adults_per_room_label")}</Heading4>
            </Box>
            <Box>
              <Paragraph>
                {tCommon("modals__age_for_adult", {
                  maxAge: adultAge.toString() || ""
                })}
              </Paragraph>
            </Box>
          </Grid>
          <Counter initialCount={numberAdults} minValue={1} onCountChange={setNumberAdults} />
        </Grid>
        {!search?.adultsOnly && (
          <>
            <Grid
              container
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
              className={classes.numberPeopleContainer}
            >
              <Grid container flexDirection="column">
                <Box>
                  <Heading4>{tCommon("modals__children_per_room_label")}</Heading4>
                </Box>
                <Box>
                  <Paragraph>
                    {`${tCommon("modals__age")} ${search?.childrenAgesConfig?.minAge}-${search
                      ?.childrenAgesConfig?.maxAge}`}
                  </Paragraph>
                </Box>
                {Number(search?.childrenAgesConfig?.minAge) > 0 && (
                  <Box paddingTop={1}>
                    <ParagraphSmall color="textSecondary">
                      {tCommon("modals__babies_not_required", {
                        minAge: babyAge.toString() || ""
                      })}
                    </ParagraphSmall>
                  </Box>
                )}
              </Grid>
              <Counter
                initialCount={numberKids.length}
                minValue={0}
                onCountChange={updateNumberOfKids}
              />
            </Grid>

            {numberKids.length > 0 && (
              <Grid className={classes.addChildrenContainer}>
                <Box>
                  <Heading4>{tCommon("modals__add_children")}</Heading4>
                </Box>
                {sliders}
              </Grid>
            )}
          </>
        )}
      </>
    ),
    [
      classes.addChildrenContainer,
      classes.numberPeopleContainer,
      numberKids.length,
      sliders,
      updateNumberOfKids,
      numberAdults,
      tCommon,
      search?.adultsOnly,
      search?.childrenAgesConfig?.maxAge,
      search?.childrenAgesConfig?.minAge,
      adultAge,
      babyAge
    ]
  );

  return (
    <>
      <FakeInputBox
        onClick={togglePeoplePickerCallback}
        className="FakeInputBox"
        fullWidth
        value={people}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Box textAlign="left">
          {React.createElement(ParagraphSmall, {}, <> {tCommon("labels__people_per_room")}</>)}
          <Paragraph>{people}</Paragraph>
        </Box>
      </FakeInputBox>

      <Dialog
        open={peoplePickerModalOpen}
        onDismiss={togglePeoplePickerCallback}
        title={tCommon("modals__people_per_room_title")}
        content={dialogContent}
        classNameActions={classes.searchButton}
        classNameContent={classes.content}
        onConfirm={triggerSearch}
        buttonLabel={tCommon("buttons__search")}
        classesDialogPaper={classesPaper}
      />
    </>
  );
};
