import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type SecondScreenActionMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
  deviceId: Types.Scalars["String"];
  eventType: Types.SecondScreenEventType;
  payload?: Types.InputMaybe<Types.Scalars["Json"]>;
}>;

export type SecondScreenActionMutation = {
  __typename?: "Mutation";
  SecondScreenAction?: boolean | null;
};

export const SecondScreenActionDocument = `
    mutation SecondScreenAction($magicId: String!, $magicToken: String!, $deviceId: String!, $eventType: SecondScreenEventType!, $payload: Json) {
  SecondScreenAction(
    magicId: $magicId
    magicToken: $magicToken
    deviceId: $deviceId
    eventType: $eventType
    payload: $payload
  )
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SecondScreenAction: build.mutation<
      SecondScreenActionMutation,
      SecondScreenActionMutationVariables
    >({
      query: (variables) => ({ document: SecondScreenActionDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useSecondScreenActionMutation } = injectedRtkApi;
