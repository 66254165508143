import React, { FC, useCallback, useMemo, useState } from "react";
import { styled } from "@mui/material";
import {
  dateFormatForLanguage,
  dateSizeStyles,
  focusDisableStyles,
  formatDate,
  formatToIsoDate,
  generateLeftSelectedRange,
  generateRightSelectedRange,
  generateSelectedRange,
  isSameDay,
  NullableString
} from "@likemagic-tech/sv-magic-library";
import { DateRange, DateRangePickerDayProps, StaticDateRangePicker } from "@mui/lab";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import { Dialog } from "../../atoms";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import MuiDateRangePickerDay from "@mui/lab/DateRangePickerDay";
import { endOfWeek, isFirstDayOfMonth, isLastDayOfMonth, isToday, startOfWeek } from "date-fns";
import { LocalizationDatePicker } from "./localization-date-picker";
import { makeStyles } from "tss-react/mui";
import { DateRangePickerPreview } from "./date-range-picker-preview";
import useDynamicLocale from "../../../util/hooks/use-dynamic-locale";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

interface RangeDatePickerProps {
  arrival: string;
  departure: string;
  onSubmit: (from: string, to: string) => void;
  minDate?: Date;
  error?: string | boolean;
}

const DateRangePickerDay = styled(MuiDateRangePickerDay)(({
  theme,
  isHighlighting,
  isStartOfHighlighting,
  isEndOfHighlighting,
  day,
  outsideCurrentMonth
}) => {
  const date = day as Date;

  const isStartOfTheMonth = isFirstDayOfMonth(date);
  const isLastOfTheMonth = isLastDayOfMonth(date);
  const isTodayBoolean = isToday(date);
  const locale = useDynamicLocale();

  const isStartOfTheWeek = isSameDay(
    startOfWeek(date, {
      locale
    }),
    date
  );
  const isEndOfTheWeek = isSameDay(
    endOfWeek(date, {
      locale
    }),
    date
  );

  return {
    "& button": {
      ...dateSizeStyles(theme.shape.borderRadius),
      ...focusDisableStyles(theme.palette),
      ...(isHighlighting && {
        color: theme.palette.common.black
      }),
      "&:not(.Mui-selected)": {
        border: "none"
      },
      "&.Mui-selected": {
        color: theme.palette.common.white
      }
    },

    ...(isHighlighting && !outsideCurrentMonth && generateSelectedRange(theme.palette)),

    ...((isStartOfHighlighting || isStartOfTheWeek || isStartOfTheMonth) &&
      isHighlighting &&
      !outsideCurrentMonth &&
      generateRightSelectedRange(theme.palette, theme.shape.borderRadius)),

    ...((isEndOfHighlighting || isEndOfTheWeek || isLastOfTheMonth) &&
      isHighlighting &&
      !outsideCurrentMonth &&
      generateLeftSelectedRange(theme.palette, theme.shape.borderRadius)),

    ...(isTodayBoolean &&
      !isHighlighting && {
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.common.black}`,
        borderRadius: theme.shape.borderRadius === 0 ? 0 : "50%"
      })
  };
});

const useStyles = makeStyles()({
  root: {
    "& div": {
      "& .PrivatePickersSlideTransition-root": {
        margin: "0 16px"
      },
      "& .MuiTypography-caption": {
        fontSize: 16,
        width: 40,
        height: 40
      }
    }
  }
});

export const DateRangePicker: FC<React.PropsWithChildren<RangeDatePickerProps>> = ({
  arrival,
  departure,
  onSubmit,
  minDate,
  error = false
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(!(arrival && departure));
  const { classes } = useStyles();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const isMobile = useIsMobile();
  const computedValue: RangeInput<string> = useMemo(
    () => [arrival, departure],
    [arrival, departure]
  );

  const [newRange, setNewRange] = useState(computedValue);

  const updateDates = useCallback((range: DateRange<Date>) => {
    if (range[0] && range[1] && !isSameDay(range[0], range[1])) {
      setNewRange([formatToIsoDate(range[0]), formatToIsoDate(range[1])]);
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, []);

  const renderWeekPickerDay = (
    date: Date,
    dateRangePickerDayProps: DateRangePickerDayProps<Date>
  ) => {
    // @ts-ignore
    const tsConvertedType: DateRangePickerDayProps<unknown> = dateRangePickerDayProps;
    return <DateRangePickerDay {...tsConvertedType} />;
  };

  const picker = useMemo(() => {
    return (
      <LocalizationDatePicker>
        <StaticDateRangePicker
          className={classes.root}
          renderInput={() => <> </>}
          calendars={isMobile ? 1 : 2}
          startText={t("labels__start_date")}
          endText={t("labels__end_date")}
          value={computedValue}
          onChange={updateDates}
          showToolbar={false}
          minDate={minDate}
          inputFormat={dateFormatForLanguage(getI18nSelectedLanguage())}
          renderDay={renderWeekPickerDay}
          showDaysOutsideCurrentMonth={false}
          displayStaticWrapperAs="desktop"
        />
      </LocalizationDatePicker>
    );
  }, [computedValue, minDate, isMobile, t, updateDates, classes.root]);
  const from = newRange[0]
    ? formatDate(computedValue[0] as NullableString, getI18nSelectedLanguage())
    : "";
  const to = newRange[1]
    ? formatDate(computedValue[1] as NullableString, getI18nSelectedLanguage())
    : "";

  return (
    <>
      <Dialog
        open={openModal}
        onConfirm={() => {
          setOpenModal(false);
          onSubmit(newRange[0] as string, newRange[1] as string);
        }}
        onDismiss={() => {
          setOpenModal(false);
          setNewRange(computedValue);
        }}
        content={picker}
        title={t("labels__date_picker")}
        buttonLabel={t("buttons__confirm")}
        buttonDisabled={isSubmitDisabled}
      />
      <DateRangePickerPreview
        fromLabel={t("labels__start_date")}
        toLabel={t("labels__end_date")}
        from={from}
        to={to}
        error={error}
        onClick={() => setOpenModal(true)}
      />
    </>
  );
};
