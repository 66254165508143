import { Box, Grid } from "@mui/material";
import React, { FC } from "react";

import { EmailIcon, MessagingWhatsappIcon, SmsIcon } from "../icons";
import { makeStyles } from "tss-react/mui";
import { CommunicationChannel, Paragraph } from "@likemagic-tech/sv-magic-library";
import { PrismicRichTextWrapper } from "../atoms";
import { useCMSData } from "../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../state/common-cms/common-cms.slice";
import { PaperRadioButton } from "../atoms/paper-radio-button/paper-radio-button";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";

interface PreferredCommunicationMethodSelectionProps {
  onSelect: (value: string) => void;
  selectableChannels: CommunicationChannel[];
  selected: string;
  error?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles()((t) => ({
  error: {
    color: t.palette.error.main,
    margin: t.spacing(1)
  }
}));

export const PreferredCommunicationMethodSelection: FC<
  PreferredCommunicationMethodSelectionProps
> = ({ onSelect, selected, selectableChannels, error, disabled = false }) => {
  const { classes } = useStyles();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const iconMapper = (channel: CommunicationChannel) => {
    return (
      <Box
        sx={{
          width: 24,
          height: 24
        }}
      >
        {channel === CommunicationChannel.WHATSAPP && <MessagingWhatsappIcon />}
        {channel === CommunicationChannel.SMS && <SmsIcon />}
        {channel === CommunicationChannel.EMAIL && <EmailIcon />}
      </Box>
    );
  };

  const contentMapper = (channel: CommunicationChannel) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          maxWidth: "100%",
          textAlign: "left"
        }}
      >
        <Paragraph>
          {channel === CommunicationChannel.WHATSAPP && tCommon("labels__whatsapp")}
          {channel === CommunicationChannel.SMS && tCommon("labels__sms")}
          {channel === CommunicationChannel.EMAIL && tCommon("labels__email")}
        </Paragraph>
      </Box>
    );
  };

  return (
    <>
      <Grid container gap={2}>
        {selectableChannels.includes(CommunicationChannel.WHATSAPP) && (
          <PaperRadioButton
            icon={iconMapper(CommunicationChannel.WHATSAPP)}
            content={contentMapper(CommunicationChannel.WHATSAPP)}
            selected={selected === CommunicationChannel.WHATSAPP}
            onClick={() => onSelect(CommunicationChannel.WHATSAPP)}
            disabled={disabled}
          />
        )}

        {selectableChannels.includes(CommunicationChannel.SMS) && (
          <PaperRadioButton
            icon={iconMapper(CommunicationChannel.SMS)}
            content={contentMapper(CommunicationChannel.SMS)}
            selected={selected === CommunicationChannel.SMS}
            onClick={() => onSelect(CommunicationChannel.SMS)}
            disabled={disabled}
          />
        )}

        {selectableChannels.includes(CommunicationChannel.EMAIL) && (
          <PaperRadioButton
            icon={iconMapper(CommunicationChannel.EMAIL)}
            content={contentMapper(CommunicationChannel.EMAIL)}
            selected={selected === CommunicationChannel.EMAIL}
            onClick={() => onSelect(CommunicationChannel.EMAIL)}
            disabled={disabled}
          />
        )}
      </Grid>
      {error && (
        <Grid className={classes.error}>
          {tCommon("validation__preferred_communication_channel_required")}
        </Grid>
      )}
      <Box sx={{ mt: 4 }}>
        <PrismicRichTextWrapper
          render={cmsData?.data?.["labels__communication_channel_description"]}
        />
      </Box>
    </>
  );
};
