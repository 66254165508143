import { PaymentMethodsResponse } from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types";
import { GetPaymentMethodsQuery } from "../queries/GetPaymentMethods.generated";
import { Price } from "../../domain-common/price";
import { GrossPriceInput, PaymentMethodType } from "../generated/graphql";
import { PaymentOption } from "../../features/payment/payment-constants";

type PaymentMethodNotNullable = Exclude<GetPaymentMethodsQuery["GetPaymentMethodsMagicId"], null>;
type CardPaymentMethod = Extract<
  PaymentMethodNotNullable[0],
  { __typename: "CardPaymentMethod" }
> | null;
type MagicStoredPaymentMethod = Extract<
  PaymentMethodNotNullable[1],
  { __typename: "StoredPaymentMethod" }
> | null;

export const transformPaymentMethods = (
  arg: PaymentMethodNotNullable | undefined
): PaymentMethodsResponse => {
  return {
    paymentMethods: transformCardPaymentMethod(arg),
    storedPaymentMethods: transformStoredPaymentMethod(arg)
  };
};

const transformCardPaymentMethod = (arg: PaymentMethodNotNullable | undefined) => {
  const cardPaymentMethod: CardPaymentMethod = arg?.find(
    (item) => item && item?.__typename === "CardPaymentMethod"
  ) as CardPaymentMethod;
  return cardPaymentMethod?.paymentMethods?.map((item) => ({
    brand: item?.brand ?? "",
    brands: item?.brands ?? ([] as any),
    configuration: item?.configuration,
    fundingSource: item?.fundingSource ?? "",
    group: undefined, // Configuration has wrong type
    inputDetails: item?.inputDetails ?? "",
    issuers: item?.issuers ?? "",
    name: item?.name ?? "",
    type: item?.type ?? ""
  }));
};

const transformStoredPaymentMethod = (arg: PaymentMethodNotNullable | undefined) => {
  const magicStoredPaymentMethods: MagicStoredPaymentMethod[] = arg?.filter(
    (item) => item && item?.__typename === "StoredPaymentMethod"
  ) as MagicStoredPaymentMethod[];
  return magicStoredPaymentMethods
    ?.map((item) => item?.config)
    .map((item) => ({
      supportedShopperInteractions: (item?.supportedShopperInteractions as any) ?? [],
      storedPaymentMethodId: item?.id ?? undefined,
      brand: item?.brand ?? "",
      configuration: undefined, // Configuration has wrong type
      group: undefined, // Configuration has wrong type
      name: item?.name ?? "",
      type: item?.type ?? ""
    }));
};

export const transformPaymentMethodType: (arg: PaymentMethodType) => PaymentOption | null = (
  arg: PaymentMethodType
) => {
  switch (arg) {
    case PaymentMethodType.AdyenWidget:
      return PaymentOption.ADYEN_WIDGET;
    case PaymentMethodType.PayAtCheckin:
      return PaymentOption.PAY_AT_CHECKIN;
    case PaymentMethodType.PayAtCheckout:
      return PaymentOption.PAY_AT_CHECKOUT;
    case PaymentMethodType.TerminalPayment:
      return PaymentOption.TERMINAL_PAYMENT;
    case PaymentMethodType.MewsPayment:
      return PaymentOption.NEW_CARD;
    default:
      return null;
  }
};

export const transformToGrossPrice = (price: Price): GrossPriceInput => {
  return {
    currency: price?.currency ?? "N/A",
    grossPriceInCents: price?.amount
  };
};
