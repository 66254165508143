import React, { ReactElement, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { IdentificationUploadErrorLabels } from "./id-document-dto";
import { assignPreviewToFile, FileWithPreview } from "../../../util/file-util";

interface IdentificationUploadProps {
  value: File | null;
  onChange: (image: File | null) => void;
  disabled?: boolean;
  identificationUploadErrorLabels: IdentificationUploadErrorLabels;
  setInternalError: (error: string | null) => void;
  showPreview: boolean;
  inputComponent: ReactElement;
}

// same limit as in BE (just a bit less, to be safe)
const maxFileSize = 7900000;

export const IdUploadComponent: React.FC<React.PropsWithChildren<IdentificationUploadProps>> = (
  props
) => {
  const {
    value,
    onChange,
    setInternalError,
    disabled,
    identificationUploadErrorLabels,
    inputComponent
  } = props;
  const [fileWithPreview, setFileWithPreview] = React.useState<FileWithPreview | null>(
    assignPreviewToFile(value)
  );

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      if (!acceptedFiles.length) {
        return;
      }

      setInternalError(null);

      if (fileWithPreview) {
        // Make sure to revoke the data uris to avoid memory leaks
        URL.revokeObjectURL(fileWithPreview.preview);
      }

      let file = acceptedFiles[0];
      onChange(file);
      setFileWithPreview(assignPreviewToFile(file));
    },
    [fileWithPreview, onChange, setInternalError]
  );

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    maxFiles: 1,
    useFsAccessApi: false,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg", ".svg"],
      "application/pdf": [".pdf"]
    },
    onDrop: onDrop,
    maxSize: maxFileSize,
    onDropRejected: (fileRejections) => {
      if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
        setInternalError(identificationUploadErrorLabels[fileRejections[0].errors[0].code]);
      }
    }
  });

  return (
    <section {...getRootProps()}>
      <input {...getInputProps()} />
      {inputComponent}
    </section>
  );
};
