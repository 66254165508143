import { PropertiesByCity } from "../../domain-common/properties-by-city";
import { GetAllPropertiesQuery } from "../queries/GetProperty.generated";

export const transformPropertyV2ToCommon = (value?: GetAllPropertiesQuery): PropertiesByCity => {
  return value?.GetPropertiesByCity?.grouped
    ? value.GetPropertiesByCity.grouped.reduce((acc: PropertiesByCity, next) => {
        if (next?.key !== null && next?.key !== undefined && next?.value) {
          acc[next.key ?? ""] = next.value.map((item) => ({
            propertyId: item?.pmsId ?? "",
            currencyCode: item?.defaultCurrency ?? "",
            timeZone: item?.timezone ?? ""
          }));
        }
        return acc;
      }, {})
    : {};
};
