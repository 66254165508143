import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  useContext,
  useState
} from "react";
import { TopNavigation } from "../..";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useIsNative } from "@likemagic-tech/sv-magic-library";

interface NavbarContextValue {
  setLeftButtons: (arg: any) => void;
  setRightButtons: (arg: any) => void;
  setProgress: (val: number) => void;
  setDisplayNavbar: (arg: boolean) => void;
  setDisplaySocialFooter: (arg: boolean) => void;
  displaySocialFooter: boolean;
}

export const NavbarContext = createContext<NavbarContextValue>({
  setLeftButtons: () => {},
  setRightButtons: () => {},
  setProgress: () => {},
  setDisplayNavbar: () => {},
  displaySocialFooter: true,
  setDisplaySocialFooter: () => {}
});

export const useNavbar = () => useContext(NavbarContext);
const useStyles = makeStyles<{ isNativePlatform: boolean }>()((_, props) => ({
  spaceForNotch: {
    paddingTop: props.isNativePlatform ? "env(safe-area-inset-bottom)" : 0
  }
}));

export interface NavbarProviderProps {}

export const NavbarProvider: FC<PropsWithChildren<NavbarProviderProps>> = ({ children }) => {
  const [displayNavbar, setDisplayNavbar] = useState<boolean>(false);
  // TODO - this is probably not the best place for this state, but with the current structure it requires a new context. The whole logic around header, navigation and footer could be refactored and added to the same context
  const [displaySocialFooter, setDisplaySocialFooter] = useState<boolean>(true);
  const [leftButtons, setLeftButtons] = useState<ReactElement[]>([]);
  const [rightButtons, setRightButtons] = useState<ReactElement[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const isNative = useIsNative();
  const { classes } = useStyles({ isNativePlatform: isNative });

  return (
    <NavbarContext.Provider
      value={{
        setLeftButtons,
        setRightButtons,
        setProgress,
        setDisplayNavbar,
        displaySocialFooter,
        setDisplaySocialFooter
      }}
    >
      {displayNavbar && (
        <TopNavigation leftButtons={leftButtons} rightButtons={rightButtons} progress={progress} />
      )}
      <Box className={classes.spaceForNotch}>{children}</Box>
    </NavbarContext.Provider>
  );
};
