import { useCallback } from "react";
import { startCheckin } from "../../checkin-flow/checkin-flow.slice";
import { useAppDispatch } from "../../../state/store";
import { Reservation } from "../../../domain-common/reservation";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useCheckinMutation } from "../../../graphql/mutations/checkin.generated";

export const useCheckIn = ({ reservation }: { reservation: Reservation }) => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();
  const checkinV1 = useCallback(() => {
    return dispatch(startCheckin({ reservation }));
  }, [reservation, dispatch]);

  const [checkinMutationV2] = useCheckinMutation();

  const checkinV2 = useCallback(() => {
    return checkinMutationV2({
      magicId: reservation.magicId,
      magicToken: reservation.magicToken
    });
  }, [reservation.magicId, reservation.magicToken, checkinMutationV2]);
  if (isRESTVersion) {
    return () => checkinV1().unwrap();
  } else {
    return () => checkinV2().unwrap();
  }
};
