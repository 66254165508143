export enum ProfilePagesEnum {
  ACCOUNT_CREATED = "ACCOUNT_CREATED",
  ACCOUNT = "ACCOUNT",
  PERSONAL = "PERSONAL",
  ALL_BOOKINGS = "ALL_BOOKINGS",
  COMMUNICATION = "COMMUNICATION",
  LEGAL = "LEGAL",
  ADDRESS = "ADDRESS",
  LANGUAGE = "LANGUAGE"
}

export const ProfilePageToPath: Record<ProfilePagesEnum, string> = {
  [ProfilePagesEnum.ACCOUNT]: "/account",
  [ProfilePagesEnum.ACCOUNT_CREATED]: "/account-created",
  [ProfilePagesEnum.PERSONAL]: "/personal",
  [ProfilePagesEnum.ALL_BOOKINGS]: "/all-bookings",
  [ProfilePagesEnum.COMMUNICATION]: "/communication",
  [ProfilePagesEnum.LEGAL]: "/legal",
  [ProfilePagesEnum.ADDRESS]: "/address",
  [ProfilePagesEnum.LANGUAGE]: "/language"
};
