import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function TravelBuddyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57171 2.46484C6.85568 2.46484 5.46457 3.85596 5.46457 5.57199C5.46457 7.28801 6.85568 8.67913 8.57171 8.67913C10.2877 8.67913 11.6789 7.28801 11.6789 5.57199C11.6789 3.85596 10.2877 2.46484 8.57171 2.46484ZM3.96457 5.57199C3.96457 3.02753 6.02726 0.964844 8.57171 0.964844C11.1162 0.964844 13.1789 3.02753 13.1789 5.57199C13.1789 8.11644 11.1162 10.1791 8.57171 10.1791C6.02726 10.1791 3.96457 8.11644 3.96457 5.57199Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57171 13.6077C6.72466 13.6077 4.95327 14.3415 3.64721 15.6475C2.34116 16.9536 1.60742 18.725 1.60742 20.572V21.5363H15.536V20.572C15.536 18.725 14.8023 16.9536 13.4962 15.6475C12.1901 14.3415 10.4188 13.6077 8.57171 13.6077ZM2.58655 14.5869C4.17391 12.9995 6.32684 12.1077 8.57171 12.1077C10.8166 12.1077 12.9695 12.9995 14.5569 14.5869C16.1442 16.1742 17.036 18.3271 17.036 20.572V22.2863C17.036 22.7005 16.7002 23.0363 16.286 23.0363H0.857422C0.443208 23.0363 0.107422 22.7005 0.107422 22.2863V20.572C0.107422 18.3271 0.999192 16.1742 2.58655 14.5869Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6789 1.71484C14.6789 1.30063 15.0147 0.964844 15.4289 0.964844C16.6508 0.964844 17.8226 1.45024 18.6866 2.31424C19.5506 3.17825 20.036 4.3501 20.036 5.57199C20.036 6.79388 19.5506 7.96572 18.6866 8.82973C17.8226 9.69374 16.6508 10.1791 15.4289 10.1791C15.0147 10.1791 14.6789 9.84334 14.6789 9.42913C14.6789 9.01492 15.0147 8.67913 15.4289 8.67913C16.2529 8.67913 17.0432 8.35177 17.6259 7.76907C18.2086 7.18637 18.536 6.39605 18.536 5.57199C18.536 4.74792 18.2086 3.95761 17.6259 3.3749C17.0432 2.7922 16.2529 2.46484 15.4289 2.46484C15.0147 2.46484 14.6789 2.12906 14.6789 1.71484Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4707 12.9167C17.618 12.5296 18.0512 12.3351 18.4384 12.4824C20.0406 13.0919 21.4201 14.1736 22.3941 15.5843C23.3681 16.995 23.8909 18.6681 23.8932 20.3824V22.2863C23.8932 22.7005 23.5574 23.0363 23.1432 23.0363H20.5717C20.1575 23.0363 19.8217 22.7005 19.8217 22.2863C19.8217 21.8721 20.1575 21.5363 20.5717 21.5363H22.3932V20.3844C22.3912 18.974 21.9611 17.5972 21.1598 16.4366C20.3583 15.2759 19.2234 14.3859 17.9051 13.8844C17.5179 13.7371 17.3235 13.3039 17.4707 12.9167Z"
        fill="black"
      />
    </SvgIcon>
  );
}
