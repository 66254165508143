import React, { FC } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { Heading4, Paragraph } from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";
import { PrismicImage } from "../../../state/property-cms/use-unit-group-cms-data";
import { BookingOnBehalfOfMyselfIcon } from "../../../components/icons/booking-on-behalf-of-myself.icon";
import { BookingOnBehalfOfOthersIcon } from "../../../components/icons/booking-on-behalf-of-others.icon";

interface BookingOverviewItemBodyProps {
  title: string;
  guest?: string;
  isBookingOnBehalfOfMyself?: boolean;
  isBookingOnBehalfOfOthers?: boolean;
  subtitle: string;
  prismicImage: PrismicImage | undefined;
}

const useStyles = makeStyles()((theme) => ({
  secondaryText: {
    color: theme.palette.grey[500]
  },
  img: {
    borderRadius: theme.shape.borderRadius * 2.5,
    width: "100px",
    height: "100px",
    objectFit: "cover"
  },
  imgBox: {
    maxHeight: "100px"
  }
}));

export const BookingOverviewItemBody: FC<React.PropsWithChildren<BookingOverviewItemBodyProps>> = ({
  title,
  guest,
  isBookingOnBehalfOfOthers,
  isBookingOnBehalfOfMyself,
  subtitle,
  prismicImage,
  children
}) => {
  const { classes } = useStyles();
  const { spacing } = useTheme();
  return (
    <Box alignItems="center" display="flex">
      <Box mr={1.5}>
        <Box className={classes.imgBox}>
          <img
            src={prismicImage?.url}
            alt={prismicImage?.alt?.toString()}
            className={classes.img}
          />
        </Box>
      </Box>
      <Grid container alignItems="center">
        <Grid item xs={12} md={5}>
          <Heading4>{title}</Heading4>
          {guest && (
            <Grid container alignItems="center" pt={spacing(1)} pl={"6px"} flexWrap={"nowrap"}>
              <Grid pr={"6px"}>
                {isBookingOnBehalfOfMyself && <BookingOnBehalfOfMyselfIcon />}
                {isBookingOnBehalfOfOthers && <BookingOnBehalfOfOthersIcon />}
              </Grid>
              <Grid>
                <Paragraph color="primary">{guest}</Paragraph>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph className={classes.secondaryText}>{subtitle}</Paragraph>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};
