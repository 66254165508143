import { useAppDispatch } from "../../../state/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useApiVersion, useAuth } from "@likemagic-tech/sv-magic-library";
import {
  fetchBookerOverviewInactive,
  selectBookingOverviewInactive
} from "../state/inactive-booking-overview.slice";
import { useGetBookingOverviewsQuery } from "../../../graphql/queries/GetBookingOverviews.generated";
import { transformApiV2ToCommonBookingOverview } from "../../../graphql/transform/transform-booking-overview";

export const useFetchInactiveBookerOverview = () => {
  const dispatch = useAppDispatch();
  const { getIsAuthenticated } = useAuth();
  const { isRESTVersion } = useApiVersion();

  const { data } = useGetBookingOverviewsQuery(
    { active: false },
    {
      skip: isRESTVersion || !getIsAuthenticated()
    }
  );

  useEffect(() => {
    if (getIsAuthenticated() && isRESTVersion) {
      dispatch(fetchBookerOverviewInactive());
    }
  }, [dispatch, getIsAuthenticated, isRESTVersion]);

  const bookerOverviewsInactiveV1 = useSelector(selectBookingOverviewInactive);
  const bookerOverviewsInactive = isRESTVersion
    ? bookerOverviewsInactiveV1
    : data?.GetBookingOverviews?.map(transformApiV2ToCommonBookingOverview) ?? [];
  return { bookerOverviewsInactive };
};
