import { useCallback } from "react";
import { useAppDispatch } from "../../../state/store";
import { AdditionalServicesPaymentDTO, PaymentDTO } from "../../payment/payment-dto";
import { clearPaymentState, initiatePayment } from "../../payment/payment.slice";
import { usePreparePayment } from "../../payment/use-prepare-payment";
import { clearAdditionalServicesCart } from "./additional-services-cart.slice";
import { useAddServicesToReservationMutation } from "../../../graphql/mutations/add-service-to-reservation.generated";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { prepareServicesForPaymentV2 } from "../../../graphql/transform/transform-additional-services-availability";

export const useBookFreeAdditionalServices = (magicId: string, magicToken: string) => {
  const dispatch = useAppDispatch();
  const { preparePayment } = usePreparePayment(magicId);
  const { isRESTVersion } = useApiVersion();
  const [addServiceToReservationAction] = useAddServicesToReservationMutation();

  const addServicesToReservation = useCallback(
    (paymentDTO: PaymentDTO) => {
      return isRESTVersion
        ? dispatch(initiatePayment({ magicId, data: { paymentDTO } })).unwrap()
        : addServiceToReservationAction({
            magicId,
            magicToken,
            services: paymentDTO.additionalServices?.map(prepareServicesForPaymentV2) ?? []
          }).unwrap();
    },
    [isRESTVersion, dispatch, addServiceToReservationAction, magicToken, magicId]
  );

  const bookFreeAdditionalServices = useCallback(
    async (freeAdditionalServices: AdditionalServicesPaymentDTO) => {
      const paymentDTO = await preparePayment({
        additionalServices: freeAdditionalServices
      });
      await addServicesToReservation(paymentDTO);
      dispatch(clearPaymentState());
      if (paymentDTO.additionalServices) {
        dispatch(clearAdditionalServicesCart());
      }
    },
    [dispatch, preparePayment, addServicesToReservation]
  );

  return { bookFreeAdditionalServices };
};
