import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector
} from "@reduxjs/toolkit";
import { MagicApi } from "../../../api/magic.api";
import { ShopCartItem } from "../../../domain-common/shop-cart-item";
import { RootState, ThunkExtraArguments } from "../../../state/store";
import {
  getLocalStorageCartItems,
  persistToLocalStorageCartLineItem
} from "./cart-store-local-storage";
import { handleSliceError } from "../../../util/error-handling";
import { isReservation, isTravelBuddy } from "../../../util/flow";
import { fetchMagicObject } from "../../magic/magic-object.slice";
import { emptyFullPrice, FullPrice } from "../../../domain-common/full-price";
import { getTenantHeaders } from "../../../api/custom-headers";

export type CartState = {
  cartItems: ShopCartItem[];
  totalPrice: FullPrice;
};

const initialState: CartState = { cartItems: [], totalPrice: emptyFullPrice() };

export const getTotalPrice = createAsyncThunk<
  FullPrice,
  { cartItems: Array<ShopCartItem> },
  { state: RootState; extra: ThunkExtraArguments }
>("cartSlice/getTotalPrice", async ({ cartItems }, thunkAPI) => {
  try {
    let magicId = thunkAPI.getState().magicObject.magicObject?.magicId;
    if (!cartItems.length) {
      return emptyFullPrice();
    }
    if (!magicId) {
      return emptyFullPrice();
    }

    return await MagicApi.getTotalPrice(
      {
        shopItems: cartItems,
        magicId
      },
      { signal: thunkAPI.signal, ...(await getTenantHeaders(thunkAPI.extra)) }
    );
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const updateCart = createAsyncThunk<
  { cartItems: ShopCartItem[] },
  { item: ShopCartItem; magicId: string },
  { state: RootState; extra: ThunkExtraArguments }
>("cartSlice/updateCart", async (arg, thunkAPI) => {
  try {
    const cartItems = [...thunkAPI.getState()["cartSlice"].cartItems];

    const idx = cartItems.findIndex((ci) => ci.serviceId === arg.item.serviceId);
    if (idx === -1) {
      cartItems.push(arg.item);
    } else if (arg.item.quantity === 0) {
      cartItems.splice(idx, 1);
    } else {
      cartItems[idx] = arg.item;
    }

    return Promise.resolve({ cartItems });
  } catch (e) {
    return Promise.reject(e);
  }
});

export const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    clearCart: (state, action: PayloadAction<{ magicId?: string }>) => {
      if (action.payload.magicId) {
        state.cartItems = [];
        state.totalPrice = emptyFullPrice();
        persistToLocalStorageCartLineItem(action.payload.magicId, []);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCart.fulfilled, (state, action) => {
        state.cartItems = action.payload.cartItems;
        persistToLocalStorageCartLineItem(action.meta.arg.magicId, action.payload.cartItems);
      })
      .addCase(getTotalPrice.fulfilled, (state, action) => {
        state.totalPrice = action.payload;
      })
      // Adding a matcher to invalidate cart items on magicId change
      .addMatcher(
        (action) => action.type === fetchMagicObject.fulfilled.type,
        (state, action) => {
          if (isReservation(action.payload) || isTravelBuddy(action.payload)) {
            state.cartItems = getLocalStorageCartItems(action.payload.magicId) ?? [];
          }
        }
      );
  }
});

export const { clearCart } = cartSlice.actions;

export const selectCartSlice: Selector<RootState, CartState> = (state: RootState) =>
  state[cartSlice.name];

export const selectCartNumberOfItems = createSelector(selectCartSlice, (res) =>
  res.cartItems.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0)
);

export const selectCartItems = createSelector(selectCartSlice, (s) => s.cartItems);
