import {
  AdditionalServicesAvailabilityResponse,
  Granularity,
  Visibility
} from "../../api/dto/additional-services-availability-response";
import { Granularity as GranularityV2, Visibility as VisibilityV2 } from "../generated/graphql";
import { GetAdditionalServicesAvailabilityQuery } from "../queries/GetAdditionalServicesAvailability.generated";
import { AdditionalServiceCartItem } from "../../domain-common/additional-service-cart-item";

type AdditionalServiceInputNotNullable = Exclude<
  GetAdditionalServicesAvailabilityQuery["GetAdditionalServicesAvailability"],
  null
>;
type AdditionalServiceInput = Exclude<AdditionalServiceInputNotNullable, undefined>;
type AdditionalServiceItem = AdditionalServiceInput[number];

export const transformAdditionalServicesAvailability = (
  param: AdditionalServiceItem
): AdditionalServicesAvailabilityResponse => {
  return {
    available: param?.available ?? false,
    serviceId: param?.pmsServiceId ?? "",
    granularity: mapGranularityV2toGranularityV1(param?.granularity),
    bookableDates:
      param?.bookableDates?.map((dateString) => ({
        date: dateString,
        count: 1
      })) ?? [],
    bookedDates:
      param?.bookedDates?.map((dateString) => ({
        date: dateString,
        count: 1
      })) ?? [],
    maximumHours: 0, //TODO mocked data
    code: "", //TODO mocked data
    visibilities:
      param?.visibilities?.map((visibility) => mapVisibilityV2toVisibilityV1(visibility)) ?? [],
    basePrice: {
      grossAmount: param?.basePrice.grossPriceInCents ?? 0,
      currency: param?.basePrice.currency ?? "CHF",
      netAmount: param?.basePrice.netPriceInCents ?? 0
    }
  };
};

const mapGranularityV2toGranularityV1 = (granularity?: GranularityV2 | null): Granularity => {
  switch (granularity) {
    case GranularityV2.AnyDay:
      return Granularity.ANY_DAY;
    case GranularityV2.AnyDayExceptArrivalDay:
      return Granularity.ANY_DAY_EXCEPT_ARRIVAL_DAY;
    case GranularityV2.WholeStay:
      return Granularity.WHOLE_STAY;
    case GranularityV2.WholeStayPayOnce:
      return Granularity.WHOLE_STAY_PAY_ONCE;
    default:
      return Granularity.UNKNOWN;
  }
};

const mapVisibilityV2toVisibilityV1 = (visibility?: VisibilityV2 | null): Visibility => {
  switch (visibility) {
    case VisibilityV2.GuestFlow:
      return Visibility.GUEST_FLOW;
    case VisibilityV2.MyStay:
      return Visibility.MY_STAY;
    case VisibilityV2.Backoffice:
      return Visibility.BACKOFFICE;
    default:
      return Visibility.MY_STAY;
  }
};

export const prepareServicesForPaymentV2 = (service: AdditionalServiceCartItem) => ({
  dates: service.dates?.map((item) => item.date),
  pmsServiceId: service.serviceId,
  quantity: service?.dates?.[0]?.count ?? 1
});

export const prepareShopItemsForPaymentV2 = (item: { serviceId: string; quantity: number }) => ({
  pmsServiceId: item.serviceId,
  quantity: item.quantity
});
