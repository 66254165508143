import * as Types from "../../generated/graphql";

export type PersonFragmentFragment = {
  __typename?: "Person";
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  gender?: Types.Gender | null;
  birthdate?: any | null;
  termsAndConditionsMarketingConsent: boolean;
  termsAndConditionsMarketingConsentDoubleOptin: boolean;
  termsAndConditionsGeneral: boolean;
  termsAndConditionsOnline: boolean;
  enrolledInLoyaltyProgram: boolean;
  emergencyEvacuationHelpNeeded: boolean;
  preferredCommunicationChannel?: Types.CommunicationChannel | null;
  identificationDocumentNumber?: string | null;
  nationality?: string | null;
  preferredLanguage?: string | null;
  pmsId: string;
  address?: {
    __typename?: "Address";
    addressLine1?: string | null;
    addressLine2?: string | null;
    postalCode?: string | null;
    city?: string | null;
    countryCode?: string | null;
    region?: string | null;
  } | null;
};

export const PersonFragmentFragmentDoc = `
    fragment PersonFragment on Person {
  firstName
  lastName
  email
  phone
  gender
  address {
    addressLine1
    addressLine2
    postalCode
    city
    countryCode
    region
  }
  birthdate
  termsAndConditionsMarketingConsent
  termsAndConditionsMarketingConsentDoubleOptin
  termsAndConditionsGeneral
  termsAndConditionsOnline
  enrolledInLoyaltyProgram
  emergencyEvacuationHelpNeeded
  preferredCommunicationChannel
  identificationDocumentNumber
  nationality
  preferredLanguage
  pmsId
}
    `;
