import { AsyncThunk, EntityId } from "@reduxjs/toolkit";
import { PrismicDocument } from "@prismicio/client";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getI18nSelectedLanguage } from "../../util/lang-utils";
import { RootState, ThunkExtraArguments, useAppDispatch } from "../store";
import { PrismicData, useCmsClient } from "../../api/cms-client/use-cms-client";

export function useCMSData(
  selector: (state: RootState, id: EntityId) => PrismicDocument | undefined,
  fetchAction: AsyncThunk<
    PrismicDocument,
    { language: string; prismic: PrismicData },
    { state: RootState; extra: ThunkExtraArguments }
  >
) {
  const dispatch = useAppDispatch();
  //If we call this before init of i18n it could be undefined (loader)
  const language = getI18nSelectedLanguage();

  const cmsData = useSelector<RootState, PrismicDocument | undefined>((state) =>
    selector(state, language)
  );
  const prismic = useCmsClient();
  useEffect(() => {
    const promise = dispatch(fetchAction({ language, prismic: prismic }));
    return () => {
      promise.abort();
    };
  }, [dispatch, fetchAction, prismic, language]);

  return cmsData;
}

const selectCmsTab = (tabName: string, data?: Record<string, any>) =>
  !data
    ? data
    : Object.keys(data)
        .filter((k) => k.startsWith(tabName))
        .reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            [currentValue]: data[currentValue]
          }),
          {} as Record<string, any>
        );

export const useCmsTabData = (
  selector: (state: RootState, id: EntityId) => PrismicDocument | undefined,
  fetchAction: AsyncThunk<
    PrismicDocument,
    { language: string; prismic: PrismicData },
    { state: RootState; extra: ThunkExtraArguments }
  >,
  tabName: string
) => {
  const data = useCMSData(selector, fetchAction)?.data;
  return useMemo(() => selectCmsTab(tabName, data), [tabName, data]);
};
