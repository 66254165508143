import { useAppDispatch } from "../../../state/store";
import { validatePromoCode as validatePromoCodeV1 } from "../promocode.slice";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";

export const useValidatePromoCode = () => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();

  const promoCodeV1 = ({ promoCode }: { promoCode: string }) =>
    dispatch(validatePromoCodeV1({ promoCode })).unwrap();

  const promoCodeV2 = ({ promoCode }: { promoCode: string }) =>
    Promise.reject("GraphQl version for useValidatePromoCode is not implemented yet. " + promoCode);

  const validatePromoCode = isRESTVersion ? promoCodeV1 : promoCodeV2;

  return { validatePromoCode };
};
