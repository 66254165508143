import { useSelector } from "react-redux";
import { closeBanner, selectBannerState } from "./banner.slice";
import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../state/store";

export const useBanner = () => {
  const bannerState = useSelector(selectBannerState);
  const dispatch = useAppDispatch();
  const onClose = useCallback(
    (event: any) => {
      event.stopPropagation();
      dispatch(closeBanner());
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      if (bannerState.isOpen) {
        dispatch(closeBanner());
      }
    };
  }, [dispatch, bannerState.isOpen]);

  return {
    bannerState,
    onClose
  };
};
