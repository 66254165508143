import { RootStore } from "../../state/store";
import { paymentSlice, PaymentState } from "./payment.slice";
import { persistToLocalStoragePaymentState } from "./payment-store-local-storage";

export const bookingCreationStorageIdentifier = "booking-creation";

let currentValue: PaymentState;
export const paymentListener = (storeArg: RootStore) => {
  let previousValue = currentValue;
  currentValue = storeArg.getState()[paymentSlice.name];
  const magicId = storeArg.getState()["magicObject"].magicObject?.magicId;

  const magicObjectV2 = Object.values(storeArg.getState().graphql.queries).filter(
    (item) => item?.endpointName === "GetMagicObject"
  )[0];
  // @ts-ignore
  const magicIdV2 = magicObjectV2?.originalArgs?.magicId;
  if (
    currentValue.paymentDTO.adyenPrice.amount ||
    currentValue.referenceId !== previousValue?.referenceId
  ) {
    // persist in local storage
    persistToLocalStoragePaymentState(
      magicIdV2 ?? bookingCreationStorageIdentifier,
      currentValue.paymentDTO,
      currentValue.referenceId
    );
  }

  // whenever payment changes
  if (
    currentValue.paymentDTO.adyenPrice.amount ||
    currentValue.referenceId !== previousValue?.referenceId
  ) {
    // persist in local storage
    persistToLocalStoragePaymentState(
      magicId ?? bookingCreationStorageIdentifier,
      currentValue.paymentDTO,
      currentValue.referenceId
    );
  }
};
