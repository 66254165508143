import React, { FC } from "react";
import { BookingOverviewItem as BookingOverviewItemDTO } from "../../../domain-common/booking-overview";
import { BookingOverviewItemWrapper } from "./booking-overview-item-wrapper";
import { usePropertyCmsData } from "../../../state/property-cms/use-property-cms-data";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
// If we import NotificationChipType directly from components
// jest test will have NotificationChipType as undefined and throw an error
// https://github.com/kulshekhar/ts-jest/issues/281#issuecomment-1028449872
import { NotificationChip, NotificationChipType } from "../../../components/atoms";
import { BookingOverviewItemBody } from "./booking-overview-item-body";
import { useNavigate } from "react-router-dom";
import { ArrowRight1Icon } from "../../../components/icons";
import { usePropertyById } from "../../property/use-property-by-id";
import { formatRange } from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

interface BookingOverviewItemPreviewProps {
  bookingOverviewItem: BookingOverviewItemDTO;
  goTo: (id: string) => string;
  highlighted: boolean;
}

export const BookingOverviewItem: FC<React.PropsWithChildren<BookingOverviewItemPreviewProps>> = ({
  bookingOverviewItem,
  goTo,
  highlighted
}) => {
  const navigate = useNavigate();
  const property = usePropertyById(bookingOverviewItem.propertyId);
  const { propertyFirstImage } = usePropertyCmsData(bookingOverviewItem.propertyId);
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  return (
    <BookingOverviewItemWrapper
      onClick={() => navigate(goTo(bookingOverviewItem.id))}
      icon={<ArrowRight1Icon fontSize="inherit" />}
      highlighted={highlighted}
    >
      <BookingOverviewItemBody
        title={property.name || ""}
        subtitle={formatRange(
          property.timeZone,
          getI18nSelectedLanguage(),
          bookingOverviewItem?.arrival,
          bookingOverviewItem?.departure
        )}
        prismicImage={propertyFirstImage}
      >
        <NotificationChip
          label={t(
            bookingOverviewItem.bookingManagementCompleted
              ? "chip__guest_data_completed"
              : "chip__guest_data_missing"
          )}
          type={
            bookingOverviewItem.bookingManagementCompleted
              ? NotificationChipType?.Success
              : NotificationChipType?.Error
          }
        />
      </BookingOverviewItemBody>
    </BookingOverviewItemWrapper>
  );
};
