import { MagicObject, MagicType } from "../../domain-common/magic-object";
import { Reservation as ReservationCommon } from "../../domain-common/reservation";
import { Booking as BookingCommon } from "../../domain-common/booking";
import { isBooking, isReservation, isTravelBuddy } from "../../util/flow";
import { GetMagicObjectQuery } from "../queries/GetMagicObject.generated";
import { transformActorV2ToCommon, transformPersonV2ToCommonPerson } from "./transform-utils";
import { TravelBuddy } from "../../domain-v1/travel-buddy";
import { transformV2Reservation } from "./transform-reservation";
import { transformTravelBuddyV2ToCommon } from "./transform-travel-buddy";

type MagicObjectInputV2 = GetMagicObjectQuery["GetMagicObject"];
type ReservationInputV2 = Extract<MagicObjectInputV2, { __typename: "Reservation" }>;

export type ReservationPrimaryGuestInputV2 = ReservationInputV2["primaryGuest"];
export type ReservationSecondaryGuestInputV2 = ReservationInputV2["secondaryGuests"];

export const transformApiV1ToCommonMagicObject = (
  v1MagicObject: MagicObject | null
): ReservationCommon | BookingCommon | TravelBuddy => {
  if (isReservation(v1MagicObject)) {
    return v1MagicObject;
  } else if (isBooking(v1MagicObject)) {
    return v1MagicObject;
  } else if (isTravelBuddy(v1MagicObject)) {
    return v1MagicObject;
  }
  throw Error("Not possible to map other object than Reservation | Booking | Travel Buddy");
};

export const transformApiV2ToCommonMagicObject = (
  magicObjectV2?: MagicObjectInputV2
): ReservationCommon | BookingCommon | TravelBuddy => {
  if (magicObjectV2 && magicObjectV2?.__typename === "Reservation") {
    return transformV2Reservation(magicObjectV2);
  } else if (magicObjectV2 && magicObjectV2.__typename === "Booking") {
    const booking = magicObjectV2;

    const reservations = booking?.reservations?.reduce(
      (acc: Array<ReservationCommon>, reservation) => {
        if (reservation) {
          acc.push(transformV2Reservation(reservation));
        }
        return acc;
      },
      []
    );

    //TODO check why flowState is undefined sometimes
    return {
      booker: transformPersonV2ToCommonPerson(null),
      actor: transformActorV2ToCommon(booking.actor),
      flowState: {
        wasOrIsCompleted: booking.flowState?.wasOrIsCompleted,
        completed: booking.flowState?.completed,
        context: booking.flowState?.context,
        notificationPending: booking.flowState?.notificationPending,
        notificationSent: booking.flowState?.notificationSent,
        sentNotifications: booking.flowState?.sentNotifications,
        performedActions: booking.flowState?.performedActions
      },
      id: booking.pmsId,
      keycloakUUID: booking.userAccountUuid ?? null,
      magicId: booking.magicId,
      magicToken: booking.magicToken ?? "",
      magicType: MagicType.BOOKING,
      magicLink: "",
      reservations: reservations ?? []
    };
  } else if (magicObjectV2 && magicObjectV2.__typename === "TravelBuddy") {
    return transformTravelBuddyV2ToCommon(magicObjectV2, 0);
  }
  console.error(magicObjectV2);
  throw Error("Magic Object is not Booking neither Reservation ");
};
