import { useCallback } from "react";
import { Reservation } from "../../../domain-common/reservation";
import { FlowSessionCheckpointService } from "../../flow/flow-session-checkpoint-service";
import { GuestFlowCheckpoint } from "./guest-flow-checkpoint";
import { GuestFlowCheckpointToPath } from "./guest-flow-checkpoint-to-path";
import { GuestFlowBackendCheckpointService } from "./guest-flow-backend-checkpoint-service";

interface UseGuestFlowCheckpoint {
  save: (reservation: Reservation, checkpoint: GuestFlowCheckpoint, checkpointArgs?: any) => void;
  restore: (reservation: Reservation) => Promise<string>;
}

const backendCheckpoint = new GuestFlowBackendCheckpointService();
const sessionCheckpoint = new FlowSessionCheckpointService(
  sessionStorage,
  GuestFlowCheckpointToPath
);

export const useGuestFlowCheckpoint = (): UseGuestFlowCheckpoint => {
  const save = useCallback(
    (reservation: Reservation, checkpoint: GuestFlowCheckpoint, checkpointArgs?: any) => {
      backendCheckpoint.save();
      sessionCheckpoint.save({
        magicObject: reservation,
        checkpoint,
        checkpointArgs
      });
    },
    []
  );

  const restore = useCallback(async (reservation: Reservation) => {
    return (
      (await sessionCheckpoint.restore(reservation)) ||
      (await backendCheckpoint.restore(reservation))
    );
  }, []);

  return {
    save,
    restore
  };
};
