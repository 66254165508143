import { ParagraphBold, Subtitle } from "@likemagic-tech/sv-magic-library";
import { Box, keyframes, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { FC, PropsWithChildren } from "react";
import { generateBlinkerAnimation } from "../../atoms/loaders/loader-utils";
import { SubmitButton } from "../../index";
import { useBottomNavbar } from "../../organism/bottom-navbar/bottom-navbar-context";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { Unit } from "../../../domain-common/unit";
import { ConfirmationIcon } from "../../atoms/confirmation-icon/confirmation-icon";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

const useStyles = makeStyles()((theme: Theme) => ({
  text: {
    textAlign: "center"
  },
  subtitleText: {
    opacity: 0,
    animation: `${keyframes`${generateBlinkerAnimation(0, 100, true)}`} 1s linear forwards`,
    animationDelay: "2s",
    marginBottom: theme.spacing(1.5)
  },
  logo: {
    maxWidth: theme.spacing(25),
    height: theme.spacing(8.75)
  },
  checkIcon: {
    fontSize: 220,
    color: theme.palette.secondary.main,
    strokeDasharray: 1000,
    strokeDashoffset: 1000,
    animation: "$drawAndZoomOut 3s cubic-bezier(0, 1.1, 0.04, 0.88) forwards"
  },
  content: {
    height: "50vh"
  }
}));

interface ConfirmationComponentProps {
  subtitleInfo: string;
  onConfirm: () => void;
  submitButtonLabel: string;
  logoSrc?: string;
  unit?: Unit;
}

export const ConfirmationComponent: FC<PropsWithChildren<ConfirmationComponentProps>> = ({
  subtitleInfo,
  onConfirm,
  submitButtonLabel,
  logoSrc,
  children,
  unit
}) => {
  const { classes } = useStyles();
  const { isBottomNavbarActive } = useBottomNavbar();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { tfeFeatureEnabled } = useFeatureFlags();

  return (
    <>
      <Box>
        {!tfeFeatureEnabled && (
          <Box display="flex" flexDirection="row" justifyContent="center" pt={3}>
            {logoSrc && <img src={logoSrc} alt="logo" className={classes.logo} />}
          </Box>
        )}
        <Box>
          <Box display="flex" justifyContent="center">
            <ConfirmationIcon />
          </Box>
          <Box className={classes.text} mx={6}>
            {tfeFeatureEnabled ? (
              <ParagraphBold pt={2.5}>{subtitleInfo}</ParagraphBold>
            ) : (
              <Subtitle className={classes.subtitleText}>{subtitleInfo}</Subtitle>
            )}
          </Box>
          {unit && (
            <Box className={classes.text} mx={6}>
              <Subtitle className={classes.subtitleText}>
                {t("labels__unit")} <strong>{unit.name}</strong>
              </Subtitle>
            </Box>
          )}
        </Box>
      </Box>
      {children}
      <Box p={2.5}>
        <SubmitButton
          label={submitButtonLabel}
          onClick={onConfirm}
          hasBottomNavigation={isBottomNavbarActive}
          hasWhiteBackground
        />
      </Box>
    </>
  );
};
