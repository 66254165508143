import React, { FC } from "react";
import { DesktopCard, DisplayCmsSvg } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { BoxShopEventHandler } from "../box-shop-event-handler";
import { BoxOpenMode } from "../box-shop-events";
import { useSetupSubpageNavigation } from "../../portal/hooks/use-setup-subpage-navigation";
import { useParams } from "react-router-dom";
import { useFetchBoxAvailabilityById } from "../hooks/use-fetch-box-availability-by-id";
import { useReservationContext } from "../../reservation-provider/reservation-provider";

export const BoxItemRentPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  useSetupSubpageNavigation();

  const { boxId } = useParams<{
    boxId: string;
  }>();

  const { reservation } = useReservationContext();

  const boxDetails = useFetchBoxAvailabilityById({
    boxId: boxId ?? "",
    magicId: reservation.magicId,
    magicToken: reservation.magicToken
  });

  return (
    <FlowTemplate>
      <RoundedFullHeight px={2.5}>
        <DesktopCard>
          {boxDetails !== undefined && (
            <BoxShopEventHandler
              boxDetails={boxDetails}
              mode={BoxOpenMode.GUEST_RETURN}
              idleInfos={{
                title: t("title__unlock_box_to_return_item"),
                icon: <DisplayCmsSvg url={cmsData?.data?.icon__get_item_from_box?.url} />
              }}
              successOpenedInfos={{
                title: t("title__box_door_successfully_opened_for_return"),
                icon: <DisplayCmsSvg url={cmsData?.data?.icon__please_close_box?.url} />
              }}
              successClosedInfos={{
                text: t("confirmations__box_shop_return")
              }}
            />
          )}
        </DesktopCard>
      </RoundedFullHeight>
    </FlowTemplate>
  );
};
