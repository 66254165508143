import { Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { FC, ReactElement } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";

interface BookingOverviewAccordionInfoProps {
  title: string;
  subtitle?: string;
  content: ReactElement;
}

export const BookingOverviewAccordionInfo: FC<BookingOverviewAccordionInfoProps> = ({
  title,
  subtitle,
  content
}) => {
  const isMobile = useIsMobile();
  return (
    <Accordion sx={{ padding: isMobile ? 0 : 3, boxShadow: "none" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="column" spacing={2.5}>
          <Grid item>
            <Heading2
              sx={{
                overflowWrap: "break-word",
                inlineSize: isMobile ? 250 : ""
              }}
              color="textPrimary"
            >
              {title}
            </Heading2>
          </Grid>
          {subtitle && (
            <Grid item>
              <Paragraph color="textSecondary">{subtitle}</Paragraph>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};
