import { Reservation } from "../../../domain-common/reservation";
import { FolioPreview } from "../folio/folio-preview";
import React, { FC, useMemo } from "react";
import { RateBreakdownPreview } from "../rate-breakdown/rate-breakdown-preview";
import { Charge } from "../../../domain-common/payment";
import {
  AdditionalServicesAvailabilityResponse,
  Granularity,
  Visibility
} from "../../../api/dto/additional-services-availability-response";
import { AdditionalServiceCartItem } from "../../../domain-common/additional-service-cart-item";
import { FullPrice, multiply } from "../../../domain-common/full-price";
import {
  getServiceDetails,
  useServicesCmsData
} from "../../../features/services/use-services-cms-data";
import { compareByStringProperty } from "../../../util/sort";
import { useSelector } from "react-redux";
import { selectAllAdditionalServicesCartItems } from "../../../features/additional-services/service-selection/additional-services-cart.slice";
import { useFetchAdditionalServices } from "../../../features/services/use-fetch-additional-services";
import { isTravelBuddy } from "../../../util/flow";
import { TotalPrice } from "../../molecules/total-price";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { usePms } from "../../../util/hooks/use-pms";
import { EmptyState } from "@likemagic-tech/sv-magic-library";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { useNavigate } from "react-router-dom";

interface DisplayBillProps {
  reservation: Reservation;
  notDeterminable: boolean;
}

export const DisplayBill: FC<DisplayBillProps> = ({ reservation, notDeterminable }) => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { isEndOfDayPms } = usePms();
  const navigate = useNavigate();

  const isThereAnyMainFolio = reservation.folios.some((item) => item.isMainFolio);
  const additionalServicesCartItems = useSelector(selectAllAdditionalServicesCartItems);
  const { serviceTitles } = useServicesCmsData(reservation.propertyId);

  // the fetch is needed to calculate the steps in case we need to skip the additional service page.
  const { selectableAdditionalServices } = useFetchAdditionalServices(
    reservation.magicId,
    Visibility.GUEST_FLOW,
    isTravelBuddy(reservation)
  );

  const additionalServicesCharges = useMemo(() => {
    return additionalServicesCartItems
      .reduce((finalList: Charge[], serviceCartItem) => {
        const config: AdditionalServicesAvailabilityResponse | undefined =
          selectableAdditionalServices.find(
            (service) => service.serviceId === serviceCartItem.serviceId
          );
        if (!config) {
          return finalList;
        }
        const computeQuantity = (serviceCartItem: AdditionalServiceCartItem) => {
          return (
            serviceCartItem.dates
              ?.map((dateAndCount) => dateAndCount.count)
              .reduce((a, b) => a + b, 0) || 0
          );
        };
        const computePrice = (serviceCartItem: AdditionalServiceCartItem): FullPrice => {
          if (config.granularity === Granularity.WHOLE_STAY_PAY_ONCE) {
            return config.basePrice;
          }
          return multiply(config.basePrice, computeQuantity(serviceCartItem));
        };

        const serviceTitle = getServiceDetails(
          serviceCartItem,
          serviceTitles,
          serviceCartItem.serviceId
        );

        finalList.push({
          name: serviceTitle,
          totalQuantity: computeQuantity(serviceCartItem),
          total: computePrice(serviceCartItem),
          folioId: "",
          serviceId: config.serviceId
        });
        return finalList;
      }, [])
      .sort(compareByStringProperty("name"));
  }, [additionalServicesCartItems, selectableAdditionalServices, serviceTitles]);

  // sort reservation.groupedRateBreakdown by groupedRateBreakdown.id
  const sortedGroupedRateBreakdown = reservation?.groupedRateBreakdown?.sort((a, b) =>
    a.id.localeCompare(b.id)
  );

  if (isEndOfDayPms) {
    return !notDeterminable ? (
      <>
        {sortedGroupedRateBreakdown.map((groupedRateBreakdown) => (
          <RateBreakdownPreview
            groupedRateBreakdown={groupedRateBreakdown}
            additionalCharges={additionalServicesCharges}
            key={groupedRateBreakdown.id}
          />
        ))}
        <TotalPrice
          price={reservation.totalPayments}
          title={tCommon("labels__total_paid")}
          titleGray={true}
          priceBold={true}
          priceGray={true}
        />
      </>
    ) : (
      <EmptyState
        title={tCommon("labels__folio_not_determined")}
        primaryButtonLabel={tCommon("buttons__my_stay")}
        primaryButtonOnClick={() => {
          navigate(generatePortalMyStayUrl(reservation.magicId));
        }}
        secondaryButtonOnClick={() => {
          navigate("/contact");
        }}
        secondaryButtonLabel={tCommon("buttons__contact")}
      />
    );
  } else {
    return (
      <>
        {reservation.folios.map((folio, index) => (
          <FolioPreview
            key={folio.id}
            folio={{ ...folio, formDebitors: [], selectedDebitorKey: "" }}
            reservation={reservation}
            index={index}
            additionalCharges={
              //TODO mews does not have main folio
              folio.isMainFolio || (!isThereAnyMainFolio && index === 0)
                ? additionalServicesCharges
                : []
            }
          />
        ))}
      </>
    );
  }
};
