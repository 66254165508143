import { Chip } from "@likemagic-tech/sv-magic-library";
import { ServiceTag } from "../../../domain-common/overview-service";
import { FC } from "react";

export const ServiceLabels: FC<{ type: ServiceTag; label: string }> = ({ type, label }) => {
  const mapColors: { [key in ServiceTag]: "success" | "warning" | "default" | "info" } = {
    [ServiceTag.NOT_OPENED]: "warning",
    [ServiceTag.OPENED]: "default",
    [ServiceTag.PURCHASED]: "success",
    [ServiceTag.INCLUDED]: "default",
    [ServiceTag.MANDATORY]: "info",
    [ServiceTag.KEY]: "default"
  };

  return <Chip size="small" color={mapColors[type]} label={label} />;
};
