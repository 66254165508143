import { useCommonErrorsMapperCmsData } from "./use-common-cms-data";
import { useMemo } from "react";
import { generatePortalMyStayArticleDetailsUrl } from "../../util/routing";
import { useNavigate } from "react-router-dom";
import { useMagicIdParams } from "../../features/magic/use-magic-id-params";

export type ErrorTranslation = {
  text: string;
  onClick?: () => void;
  buttonText?: string;
  keySliderText?: string;
};

export const getErrorEntry = (errorCmsData: any, errorId?: string) =>
  errorCmsData?.["errors__mapper"]?.find(
    (errorTranslation: { id: string; text: string }) => errorTranslation.id === errorId
  );
export const useCommonErrorTranslation = (errorId?: string): ErrorTranslation | undefined => {
  const cmsData = useCommonErrorsMapperCmsData();
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();

  const prismicEntry = getErrorEntry(cmsData, errorId);

  const onClick = useMemo(() => {
    if (prismicEntry?.article_alias) {
      return () =>
        navigate(generatePortalMyStayArticleDetailsUrl(magicId, prismicEntry.article_alias));
    }
  }, [prismicEntry, navigate, magicId]);

  return {
    text: prismicEntry?.text || cmsData?.["errors__default_label"],
    buttonText: prismicEntry?.button_text,
    keySliderText: prismicEntry?.key_slider_text,
    onClick
  };
};
