import { FC, useContext } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";

interface Pin {
  lat: number;
  lng: number;
  label: string;
  address: string;
  iconUrl: string;
  onClick?: () => void;
}

export const MapPreviewGoogleEmbed: FC<{ pin: Pin }> = ({ pin }) => {
  const { googleMaps } = useContext(TenantContext);

  if (!googleMaps?.apiKey) {
    <></>;
  }

  return (
    <iframe
      title={pin.label}
      width="100%"
      height="100%"
      loading="lazy"
      allowFullScreen
      style={{ border: 0 }}
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${googleMaps?.apiKey}
    &center=${pin.lat},${pin.lng}&q=${pin.label
      .replaceAll(" ", "+")
      .replaceAll("&", "and")
      .replaceAll("?", " ")},${pin.address
      .replaceAll("&", "and")
      .replaceAll("?", " ")
      .replaceAll(",", " ")}`}
    />
  );
};
