import { ReactElement } from "react";
import { OpenGlobalModal } from "@likemagic-tech/sv-magic-library";

export function getYesNoModalArg(
  title: string,
  content: string,
  yesBtnLbl: string,
  noBtnLbl: string
): OpenGlobalModal {
  return {
    modalProps: {
      title: title,
      content: <>{content}</>
    },
    modalActions: [
      {
        variant: "secondary",
        result: false,
        label: noBtnLbl
      },
      {
        variant: "primary",
        result: true,
        label: yesBtnLbl
      }
    ]
  };
}

export function getOkModalArg(
  title: string,
  content: ReactElement | string,
  okLabel: string
): OpenGlobalModal {
  return {
    modalProps: {
      title: title,
      content: <>{content}</>
    },
    modalActions: [
      {
        variant: "primary",
        result: "OK",
        label: okLabel
      }
    ]
  };
}
