import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { getI18nSelectedLanguage } from "../../util/lang-utils";
import { EntityStateStatus, isStatusLoading, isStatusSuccess } from "../../state/EntityStateStatus";
import { PrismicData } from "../../api/cms-client/use-cms-client";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { PrismicDocument } from "@prismicio/client/src/types/value/document";
import { RichTextField } from "@prismicio/types/src/value/richText";

export function PrismicDocumentToServiceDetailsMapper(doc: PrismicDocument): {
  id: string;
  title: string;
  subtitle: string;
  coverImage: string;
  description: RichTextField;
} {
  return {
    id: doc.id,
    title: doc.data["main__title"],
    subtitle: doc.data["main__subtitle"],
    coverImage: doc.data["main__cover_image"]?.["url"],
    description: doc.data["main__description"]
  };
}

const myStayTipsAdapter = createEntityAdapter<PrismicDocument>({
  selectId: (model) => model.id,
  sortComparer: (a, b) => {
    // equal items sort equally
    if (a === b) {
      return 0;
    }
    // nulls sort after anything else
    else if (a?.first_publication_date === null) {
      return 1;
    } else if (b?.first_publication_date === null) {
      return -1;
    }
    return a.first_publication_date < b.first_publication_date ? 1 : -1;
  }
});

const initialState = myStayTipsAdapter.getInitialState<{
  statusForAllServices: EntityStateStatus;
}>({ statusForAllServices: EntityStateStatus.IDLE });

export const fetchAllServiceDetails = createAsyncThunk<
  PrismicDocument[],
  { prismic: PrismicData },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>(
  "service-details-cms/fetchAllServiceDetails",
  async (arg) => {
    const aliasResponse = await arg.prismic.prismicApiClient.getByType("service_details", {
      lang: getI18nSelectedLanguage()
    });
    return aliasResponse.results;
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const statusPerService = thunkAPI.getState()["service-details-cms"].statusForAllServices;

      return !(isStatusSuccess(statusPerService) || isStatusLoading(statusPerService));
    }
  }
);

export const serviceDetailSlice = createSlice({
  name: "service-details-cms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllServiceDetails.pending, (state) => {
      state.statusForAllServices = EntityStateStatus.LOADING;
    });
    builder.addCase(fetchAllServiceDetails.rejected, (state) => {
      state.statusForAllServices = EntityStateStatus.FAILED;
    });
    builder.addCase(fetchAllServiceDetails.fulfilled, (state, action) => {
      state.statusForAllServices = EntityStateStatus.SUCCEEDED;
      myStayTipsAdapter.addMany(state, action.payload);
    });
  }
});

const selectSelf = (state: RootState) => state[serviceDetailSlice.name];

const { selectAll: selectAllMyStayTips } = myStayTipsAdapter.getSelectors(selectSelf);

const selectServiceDetailsByIdRaw = createSelector(
  selectAllMyStayTips,
  (_: any, id: string) => id,
  (res, id: string) =>
    res.filter((document) => document.id === id || document.data.main__alias === id)[0]
);

export const selectServiceDetailsById = createSelector(
  selectServiceDetailsByIdRaw,
  //Reuse article mapper
  (res) => (res ? PrismicDocumentToServiceDetailsMapper(res) : null)
);

export const selectServiceDetailsStatus = createSelector(
  selectSelf,
  (res) => res.statusForAllServices
);
