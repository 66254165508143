import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavbar } from "../../components/organism/top-navbar/navbar-context";
import { Button, Heading4 } from "@likemagic-tech/sv-magic-library";
import { FieldArray, Form, Formik } from "formik";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { useFormValidations } from "../../util/hooks/use-form-validations";
import { SecondScreenMainGuestForm } from "./components/second-screen-main-guest-form";
import { HelmetTitle } from "../gtm/helmet-title";
import { PageHeadingInfo } from "../../components/molecules/page-heading-info";
import { Box, Grid } from "@mui/material";
import { ProfileIcon } from "../../components/icons";
import { makeStyles } from "tss-react/mui";
import { SecondScreenCheckboxesForm } from "./components/second-screen-checkboxes-form";
import { SecondScreenTravelBuddyForm } from "./components/second-screen-travel-buddy-form";
import { SignatureDialog } from "../../components/organism/signature-component/signature-dialog";
import { useNavigate } from "react-router";
import { useUpdateReservation } from "../reservation-provider/use-update-reservation";
import { SubUpdate } from "../reservation-provider/sub-update";
import { useSecondScreenAction } from "./hooks/use-second-screen-action";
import { SecondScreenEventType } from "../../graphql/generated/graphql";
import { CheckboxTerms } from "../../components/molecules/personal-form/checkbox-terms";
import { prepareLegalFiles } from "../guest-flow/use-guest-flow";
import { useSecondScreenReservation } from "../second-screen-home-page/hooks/useSecondScreenReservation";
import { GuestFlowCheckpoint } from "../guest-flow/checkpoint/guest-flow-checkpoint";
import { transformSecondScreenRegistration } from "../../graphql/transform/transform-second-screen-registration";
import { SecondScreenCheckinFormDataType } from "../../domain-common/second-screen-checkin";
import yup from "../../config/yup.config";
import { isANewerCheckpoint } from "../guest-flow/guest-flow-sequence";

const useStyles = makeStyles()((theme) => ({
  mainGuest: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1)
  }
}));

export const SecondScreenCheckinPage: FC = () => {
  const { setDisplayNavbar } = useNavbar();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const { secondScreenCheckinValidation } = useFormValidations();
  const { classes } = useStyles();
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const navigate = useNavigate();
  const { patchGuestFlowStep } = useUpdateReservation();
  const { secondScreenAction } = useSecondScreenAction();
  const { secondScreenReservation: reservation, resetSecondScreenReservation } =
    useSecondScreenReservation();

  const [formValues, setFormValues] = useState<SecondScreenCheckinFormDataType | null>(null);

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    reservation?.extras?.tcGeneral && reservation?.extras?.tcOnline
  );

  useEffect(() => {
    setTermsAndConditionsAccepted(reservation?.extras?.tcGeneral && reservation?.extras?.tcOnline);
  }, [reservation?.extras?.tcGeneral, reservation?.extras?.tcOnline]);

  useEffect(() => {
    setDisplayNavbar(true);
  }, [setDisplayNavbar]);

  const handleSubmit = (values: any) => {
    setFormValues(values);
    setIsSignatureModalOpen(true);
  };

  const onModalClose = () => {
    setIsSignatureModalOpen(false);
  };

  const initValues: SecondScreenCheckinFormDataType = useMemo(
    () => ({
      mainGuest: {
        gender: reservation?.primaryGuest?.gender || null,
        firstName: reservation?.primaryGuest?.firstName || "",
        lastName: reservation?.primaryGuest?.lastName || "",
        phone: reservation?.primaryGuest?.phone || "",
        email: yup
          .string()
          .notBlacklistedEmail()
          .isValidSync(reservation?.primaryGuest?.email)
          ? reservation?.primaryGuest?.email!
          : "",
        addressLine1: reservation?.primaryGuest?.address?.addressLine1 || "",
        addressLine2: reservation?.primaryGuest?.address?.addressLine2 || "",
        city: reservation?.primaryGuest?.address?.city || "",
        region: reservation?.primaryGuest?.address?.region || "",
        postcode: reservation?.primaryGuest?.address?.postalCode || "",
        country: reservation?.primaryGuest?.address?.countryCode || "",
        termsAndConditions:
          (reservation?.extras?.tcGeneral && reservation?.extras?.tcOnline) ?? false,
        frequentFlyerProgram: "",
        membershipNumber: "",
        id: reservation?.primaryGuest.id || ""
      },
      travelBuddies:
        reservation?.travelBuddies?.map((buddy: any) => ({
          gender: buddy.travelBuddy.gender || null,
          firstName: buddy.travelBuddy.firstName || "",
          lastName: buddy.travelBuddy.lastName || "",
          phone: buddy.travelBuddy.phone || "",
          email: buddy.travelBuddy.email || "",
          id: buddy.travelBuddy.id || ""
        })) || [],
      emergencyEvacuationHelpNeeded: reservation?.extras?.emergencyEvacuationHelpNeeded || false,
      enrolledInLoyaltyProgram: reservation?.extras?.enrolledInLoyaltyProgram || false,
      maxCompanions: reservation?.maxCompanions
    }),
    [
      reservation?.primaryGuest?.gender,
      reservation?.primaryGuest?.firstName,
      reservation?.primaryGuest?.lastName,
      reservation?.primaryGuest?.phone,
      reservation?.primaryGuest?.email,
      reservation?.primaryGuest?.address?.addressLine1,
      reservation?.primaryGuest?.address?.addressLine2,
      reservation?.primaryGuest?.address?.city,
      reservation?.primaryGuest?.address?.region,
      reservation?.primaryGuest?.address?.postalCode,
      reservation?.primaryGuest?.address?.countryCode,
      reservation?.primaryGuest.id,
      reservation?.extras?.emergencyEvacuationHelpNeeded,
      reservation?.extras?.enrolledInLoyaltyProgram,
      reservation?.extras?.tcGeneral,
      reservation?.extras?.tcOnline,
      reservation?.travelBuddies,
      reservation?.maxCompanions
    ]
  );

  const handleSign = async (image?: string) => {
    const files = await prepareLegalFiles(image ?? null, null, null);
    if (formValues && reservation) {
      patchGuestFlowStep({
        subUpdate: SubUpdate.SECONDARY_SCREEN,
        files,
        checkpoint: isANewerCheckpoint(GuestFlowCheckpoint.ADDRESS, reservation)
          ? GuestFlowCheckpoint.ADDRESS
          : undefined,
        reservationValues: transformSecondScreenRegistration(formValues, reservation)
      }).then(() => {
        secondScreenAction(
          SecondScreenEventType.Signed,
          reservation?.magicId,
          reservation?.magicToken,
          {
            accepted: true,
            reason: "Success"
          }
        );
      });
    }
  };

  useEffect(() => {
    if (!reservation) {
      navigate("/");
    }
  }, [reservation, navigate]);

  return (
    <>
      <HelmetTitle suffix="Guest flow personal data" />
      <Box
        my={5.75}
        px={10}
        mx="auto"
        maxWidth={1200}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <PageHeadingInfo title={tCommon("labels__please_check_and_confirm_your_data")} />
        {initValues && (
          <Formik
            initialValues={initValues}
            onSubmit={handleSubmit}
            validationSchema={secondScreenCheckinValidation}
            enableReinitialize
          >
            {(formik) => (
              <Form noValidate>
                <Box className={classes.mainGuest}>
                  <ProfileIcon />
                  <Heading4>{tCommon("labels__main_guest")}</Heading4>
                </Box>
                <SecondScreenMainGuestForm
                  objectPathPrefix="mainGuest."
                  values={formik.values.mainGuest}
                  onChange={formik.handleChange}
                  touched={formik.touched.mainGuest}
                  errors={formik.errors.mainGuest}
                  setFieldValue={formik.setFieldValue}
                />

                <FieldArray
                  name="travelBuddies"
                  render={(props) => (
                    <SecondScreenTravelBuddyForm
                      {...props}
                      form={props.form}
                      push={props.push}
                      remove={props.remove}
                      objectPathPrefix={"travelBuddies"}
                      propertyId={reservation?.propertyId ?? ""}
                    />
                  )}
                />

                <Grid container spacing={8}>
                  <Grid item xs={12} md={8}>
                    <SecondScreenCheckboxesForm
                      values={formik.values}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} mt="auto">
                    <Button fullWidth type="submit">
                      {tCommon("buttons__confirm_information")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Box>
      <SignatureDialog
        open={isSignatureModalOpen}
        onClose={onModalClose}
        onConfirm={(image) => {
          handleSign(image).then(resetSecondScreenReservation);
        }}
        labels={{
          title: tCommon("modals__signature_dialog_title"),
          reset: tCommon("buttons__reset"),
          confirm: tCommon("buttons__confirm")
        }}
        disabled={!termsAndConditionsAccepted}
        large
      >
        <Box px={4}>
          <CheckboxTerms
            acceptedTerms={!!termsAndConditionsAccepted}
            onChange={() => {
              setTermsAndConditionsAccepted(!termsAndConditionsAccepted);
            }}
          />
        </Box>
      </SignatureDialog>
    </>
  );
};
