import {
  resetReservation,
  selectSecondScreenHomeReservation,
  setReservation
} from "../second-screen-home.slice";
import {
  ReservationInputV2,
  transformV2Reservation
} from "../../../graphql/transform/transform-reservation";
import { useAppDispatch } from "../../../state/store";
import { useSelector } from "react-redux";

export const useSecondScreenReservation = () => {
  const dispatch = useAppDispatch();
  const reservation = useSelector(selectSecondScreenHomeReservation);

  const setSecondScreenReservation = (reservation: ReservationInputV2) => {
    dispatch(
      setReservation({
        reservation: reservation ? transformV2Reservation(reservation) : undefined
      })
    );
  };

  const resetSecondScreenReservation = () => {
    dispatch(resetReservation());
  };

  return {
    setSecondScreenReservation,
    resetSecondScreenReservation,
    secondScreenReservation: reservation
  };
};
