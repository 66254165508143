import { FieldArray, Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { Debitor } from "../../../domain-common/debitor";
import { Folio, PrepaymentType } from "../../../domain-common/folio";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generatePortalMyStayUrl } from "../../../util/routing";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { FolioPreview } from "../../../components/organism/folio/folio-preview";
import { mapFolio } from "../../../components/organism/folio/folio-preview.util";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { HelmetTitle } from "../../gtm/helmet-title";
import { daysBeforeNow, EmptyState } from "@likemagic-tech/sv-magic-library";
import { Surcharges } from "../../../components/organism/surcharges/surcharges";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";

interface MyStayBillsPageProps {}

export interface BillFolio extends Folio {
  formDebitors: FormDebitor[];
  selectedDebitorKey: string; // the formDebitor.key
}

export interface FormDebitor {
  label: string;
  key: string;
  debitor: Debitor;
}

export const MyStayBillsPage: React.FC<React.PropsWithChildren<MyStayBillsPageProps>> = () => {
  useSetupSubpageNavigation(generatePortalMyStayUrl);
  const { reservation } = useReservationContext();
  const { patchGuestFlowStep } = useGuestFlow();
  const { spacing } = useTheme();
  const isMobile = useIsMobile();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const isNextStepAvailable = useMemo(() => {
    const isSomeFolioNotDetermined = reservation.folios.some(
      (item) => item.prepaymentType === PrepaymentType.NOT_DETERMINABLE
    );

    const isSomeRateBreakDownNotDetermined = reservation.groupedRateBreakdown.some((item) =>
      item.breakdownItems.some((splice) =>
        splice.items.some((i) => i.prepaymentType === PrepaymentType.NOT_DETERMINABLE)
      )
    );

    return !isSomeFolioNotDetermined && !isSomeRateBreakDownNotDetermined;
  }, [reservation.folios, reservation.groupedRateBreakdown]);

  const emptyFolios = useMemo(() => {
    return reservation.folios && reservation.folios.length === 0;
  }, [reservation.folios]);

  const isCheckoutTomorrow = useMemo(
    () => daysBeforeNow(reservation.departure) === 1,
    [reservation.departure]
  );
  const isCheckoutToday = useMemo(
    () => daysBeforeNow(reservation.departure) === 0,
    [reservation.departure]
  );
  const onSubmit = useCallback(
    async (values: { folios: BillFolio[] }) => {
      let updatedValues = {
        ...reservation,
        folios: values.folios.map((folio, index) => ({
          ...reservation.folios[index],
          debitor: folio.formDebitors.find(
            (formDebitor) => formDebitor.key === folio.selectedDebitorKey
          )!.debitor
        }))
      };
      if (isCheckoutTomorrow || isCheckoutToday) {
        updatedValues = {
          ...updatedValues,
          extras: { ...updatedValues.extras!, billConfirmed: true }
        };
      }
      await patchGuestFlowStep({
        reservationValues: updatedValues,
        subUpdate: SubUpdate.FLOW_STATE
      });
    },
    [reservation, patchGuestFlowStep, isCheckoutToday, isCheckoutTomorrow]
  );

  const submitButtonComponent = useCallback(
    (formikDirty: boolean) => {
      const billNeedsConfirming =
        !reservation?.extras?.billConfirmed && (isCheckoutToday || isCheckoutTomorrow);

      const submitButton = (label: string, disabled: boolean) => (
        <React.Fragment>
          <SubmitButton
            hasBottomNavigation={true}
            disabled={disabled}
            label={label}
            hasWhiteBackground
          />
        </React.Fragment>
      );

      if (
        billNeedsConfirming &&
        reservation.status !== ReservationStatus.CHECKED_OUT &&
        isNextStepAvailable
      ) {
        return submitButton(
          tCommon("buttons__confirm"),
          reservation.folios.some((folio) => folio.hasPendingPaymentLink)
        );
      }
      if (formikDirty) {
        return submitButton(tCommon("buttons__save"), false);
      }
      // no submit button
      return <React.Fragment />;
    },
    [isNextStepAvailable, isCheckoutTomorrow, isCheckoutToday, reservation, tCommon]
  );
  return (
    <>
      <HelmetTitle suffix="Bills" />
      <RoundedFullHeight p={2.5} className={!isMobile ? "MyStayBillsPage-root" : ""}>
        <DesktopCard>
          <PageHeadingInfo
            title={tCommon("title__bill_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__bill_icon?.url} />}
          />
          {!emptyFolios ? (
            <Formik
              enableReinitialize
              initialValues={{
                folios: reservation.folios.map((folio) => mapFolio(folio, reservation, tCommon))
              }}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <Form>
                  <FieldArray
                    name="folios"
                    render={() => (
                      <>
                        {formik.values.folios?.map((folio, index) => (
                          <FolioPreview
                            key={folio.id}
                            folio={folio}
                            reservation={reservation}
                            index={index}
                          />
                        ))}
                      </>
                    )}
                  />
                  {submitButtonComponent(formik.dirty)}
                </Form>
              )}
            </Formik>
          ) : (
            <Box my={spacing(6)}>
              <EmptyState title={tCommon("labels__no_items_to_display")} />
            </Box>
          )}
          <Surcharges />
        </DesktopCard>
      </RoundedFullHeight>
    </>
  );
};
