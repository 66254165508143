import { Box, Grid, ListItemButton, ListItemText } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { ArrowRight1Icon, InfoIcon } from "../../icons";
import { responsiveVariables } from "../../layouts/hooks/use-is-mobile";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";

interface ProfileListItemProps {
  to: string;
  label: React.ReactNode;
  details: ReactElement;
  icon?: ReactElement;
  infoWarningLabel?: string;
  fastCheckin?: boolean;
}

const useListItemTextStyles = makeStyles()(() => ({
  root: {
    marginTop: 0,
    marginBottom: 0
  },
  primary: {
    fontWeight: 700
  },
  secondary: {
    display: "flex"
  }
}));
const useListItemStyles = makeStyles<string | undefined>()(
  ({ spacing, breakpoints, palette, shape }, infoWarningLabel) => ({
    root: {
      [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
        backgroundColor: palette.background.paper,
        borderRadius: shape.borderRadius * 2.5,
        marginBottom: spacing(2),

        paddingTop: infoWarningLabel ? spacing(2) : spacing(3),
        paddingBottom: infoWarningLabel ? spacing(2) : spacing(3)
      },
      [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
        paddingTop: infoWarningLabel ? spacing(2) : spacing(3),
        paddingBottom: infoWarningLabel ? spacing(2) : spacing(3)
      }
    },
    selected: {
      "&.Mui-selected": {
        backgroundColor: palette.warning.main,
        "&:hover": {
          backgroundColor: palette.warning.main
        }
      }
    },
    disabled: {
      "&.Mui-disabled": {
        opacity: 0.5
      }
    }
  })
);

const infoIcon = <InfoIcon fontSize="inherit" style={{ marginRight: "4px" }} />;

export const StepPreviewItem: React.FC<ProfileListItemProps> = ({
  to,
  label,
  infoWarningLabel,
  icon,
  details,
  fastCheckin
}) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const secondary = infoWarningLabel ? (
    <>
      {infoIcon}
      {t(infoWarningLabel)}
    </>
  ) : undefined;
  const { classes: listItemClasses } = useListItemStyles(infoWarningLabel);
  const { classes: listItemTextClasses } = useListItemTextStyles();

  return (
    <ListItemButton component={Link} to={to} classes={listItemClasses} state={{ fastCheckin }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>{icon}</Grid>
            <Grid item>
              <Box mt={0.5}>
                <ListItemText primary={label} secondary={secondary} classes={listItemTextClasses} />
              </Box>
              <Box mt={2}>{details}</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignItems="center" display="flex">
          <ArrowRight1Icon fontSize="inherit" />
        </Grid>
      </Grid>
    </ListItemButton>
  );
};
