import { useSelector } from "react-redux";
import {
  searchAvailableProperties,
  searchAvailablePropertyAction
} from "../search-available-properties.slice";
import { useEffect } from "react";
import { useAppDispatch } from "../../../state/store";
import { SearchBookingFiltersValues } from "../components/filters/search-booking-filters";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";

export const useSearchAvailableProperties = ({
  searchValues
}: {
  searchValues: SearchBookingFiltersValues;
}) => {
  const dispatch = useAppDispatch();
  const propertiesApi = useSelector(searchAvailableProperties);
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();

  if (isGraphQlVersion) {
    console.warn("GraphQl version for useSearchAvailableProperties is not implemented yet.");
  }

  useEffect(() => {
    if (isRESTVersion) {
      const promise = dispatch(
        searchAvailablePropertyAction({
          ...searchValues
        })
      );

      return () => {
        promise.abort();
      };
    }
  }, [searchValues, dispatch, isRESTVersion]);

  const properties = propertiesApi ? propertiesApi : [];

  return { properties };
};
