import React, { FC, useCallback, useEffect, useMemo } from "react";
import { initialPaymentState } from "../../payment/payment.slice";
import { useAppDispatch } from "../../../state/store";
import { generateSearchBookingPagesUrl } from "../search-booking-navigation";
import { SearchBookingPages } from "../search-booking-pages";
import { clearBookingCart } from "../booking-cart.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentRedirect } from "../../payment/payment-redirect";
import { bookingCreationStorageIdentifier } from "../../payment/payment-store-listener";

export const SearchPaymentRedirectPage: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const cleanedURLSearchParams: URLSearchParams = useMemo(() => {
    const cleanedURLSearchParams = new URLSearchParams(search);
    cleanedURLSearchParams.delete("redirectResult");
    return cleanedURLSearchParams;
  }, [search]);

  useEffect(() => {
    dispatch(initialPaymentState({ identifier: bookingCreationStorageIdentifier }));
  }, [dispatch]);

  const onSuccess = useCallback(
    (newMagicId: string) => {
      dispatch(clearBookingCart());
      navigate({
        pathname: generateSearchBookingPagesUrl(SearchBookingPages.CONFIRMATION).replace(
          ":newMagicId",
          newMagicId || ""
        ),
        search: cleanedURLSearchParams.toString()
      });
    },
    [cleanedURLSearchParams, navigate, dispatch]
  );

  const onFailed = useCallback(() => {
    navigate({
      pathname: generateSearchBookingPagesUrl(SearchBookingPages.PAYMENT_AND_BOOKER_DATA),
      search: cleanedURLSearchParams.toString()
    });
  }, [cleanedURLSearchParams, navigate]);

  return <PaymentRedirect onSuccess={onSuccess} onFailed={onFailed} />;
};
