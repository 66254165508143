import React, { useEffect } from "react";
import {
  setEnfroceScanUI,
  setHideAssignKeyForKioskMode,
  setKioskId,
  setKioskMode,
  setKioskPropertyId,
  setKioskTerminalId,
  setKioskTerminalOnly
} from "../util/kiosk-mode";
import { setTagReaderId } from "../util/tag-reader-id";
import { setSecondScreenId } from "../util/second-screen-mode";

export const AppModeDetect: React.FC<React.PropsWithChildren<unknown>> = () => {
  // using window instead of useLocation allow us to move this before the context initialization (so we can set kiosk mode immediately)
  const queryParamsString = window.location.search;

  useEffect(() => {
    const queryParams = new URLSearchParams(queryParamsString);
    setKioskMode(queryParams.get("isKioskMode"));
    setEnfroceScanUI(queryParams.get("isEnforceScanUI"));
    setTagReaderId(queryParams.get("tagReaderId"));
    setKioskPropertyId(queryParams.get("kioskPropertyId"));
    setKioskTerminalId(queryParams.get("kioskTerminalId"));
    setKioskTerminalOnly(queryParams.get("kioskTerminalOnly"));
    setHideAssignKeyForKioskMode(queryParams.get("hideAssignKeyForKioskMode"));
    //new kiosk param
    setKioskId(queryParams.get("kioskId"));
    setSecondScreenId(queryParams.get("secondScreenId"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
