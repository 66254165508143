import { BaseApiClient, LogLevel, VoidApiResponse } from "@likemagic-tech/sv-magic-library";

interface LoggerParams {
  logLevel: LogLevel;
  payload: any;
}

class LoggerApiClient extends BaseApiClient {
  async postLog(data: LoggerParams, init?: RequestInit) {
    const dataWithApp = { ...data, app: "GUEST_JOURNEY" };
    const response = await this.fetchApi(`/api/frontend-log`, {
      ...init,
      method: "POST",
      body: JSON.stringify(dataWithApp)
    });

    return new VoidApiResponse(response).value();
  }
}

export const LoggerApi = new LoggerApiClient();
