import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ApiError } from "@likemagic-tech/sv-magic-library";

export const GraphqlClient = new GraphQLClient("/api/guest-service/graphql");

export const graphqlApi = createApi({
  reducerPath: "graphql",

  baseQuery: graphqlRequestBaseQuery({
    client: GraphqlClient,
    prepareHeaders: async (headers, api) => {
      // @ts-ignore
      const keycloak: any | undefined = api?.extra?.keycloak;
      if (keycloak.authenticated && keycloak?.isTokenExpired()) {
        await keycloak.updateToken(5);
      }

      if (keycloak.authenticated && keycloak?.token) {
        headers.delete("Authorization");
        headers.set("Authorization", `Bearer ${keycloak.token}`);
      }

      return headers;
    },
    customErrors: ({ name, stack, response }) => {
      const { message = "", errorCode = "" } = response?.errors?.length
        ? response?.errors[0]?.extensions
        : {};

      return new ApiError({
        error: name,
        id: errorCode,
        message: message,
        requestId: "",
        status: handleErrorStatus(errorCode),
        timestamp: new Date().toISOString(),
        trace: stack ?? ""
      }).toJSON();
    }
  }),
  endpoints: () => ({})
});

const handleErrorStatus = (errorCode: string) => {
  switch (errorCode) {
    case "errors_unauthorized":
      return 401;
    case "errors_forbidden":
      return 403;
    default:
      return 500;
  }
};
