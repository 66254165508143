import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useAppDispatch } from "../../state/store";
import { fetchUserProfile } from "./user-profile.slice";
import { api as userProfileApi } from "../../graphql/queries/GetUserProfile.generated";

export const useRefetchUserProfile = () => {
  const { isRESTVersion } = useApiVersion();
  const dispatch = useAppDispatch();
  return isRESTVersion
    ? () => dispatch(fetchUserProfile({ force: true }))
    : () =>
        dispatch(
          userProfileApi.endpoints.GetUserProfile.initiate(undefined, {
            forceRefetch: true
          })
        );
};
