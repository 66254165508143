import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import "../App.css";
import "../day-picker.css";
import { MainPage } from "../features/main/main.page";
import { Logout } from "../features/logout/logout";
import { SearchBookingRouterPage } from "../features/search-for-bookings/search-booking-router.page";
import { ContactPage } from "../features/contact/contact.page";
import { PrivacyPolicyPage } from "../features/privacy-policy/privacy-policy.page";
import { ReservationCancelConfirmation } from "../features/reservation-cancel-confirmation/reservation-cancel-confirmation";
import { NotFoundPage } from "../components/organism/not-found.page";
import { WebSocketProvider } from "../features/web-socket/web-socket-provider";
import { MagicPage } from "../features/magic/magic.page";
import { StaticPage } from "../features/static-page/static-page";
import { isSecondScreenMode } from "../util/second-screen-mode";
import { SecondScreenHomePage } from "../features/second-screen-home-page/second-screen-home.page";
import { SecondScreenCheckinPage } from "../features/second-screen-checkin/second-screen-checkin.page";
import { SecondScreenCheckBillPage } from "../features/second-screen-check-bill/second-screen-check-bill.page";
import { SecondScreenProvider } from "../features/second-screen-provider/second-screen-provider";
import { InfoStayRouter } from "../features/info-stay/info-stay.router";
import { LoginSuccessPage } from "../features/native-app/login-success-page";

const Router: FC = () => {
  if (isSecondScreenMode()) {
    return (
      <SecondScreenProvider>
        <Routes>
          <Route path="/" element={<SecondScreenHomePage />} />
          <Route path="/checkin" element={<SecondScreenCheckinPage />} />
          <Route path="/check-bill" element={<SecondScreenCheckBillPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </SecondScreenProvider>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/search/*" element={<SearchBookingRouterPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/login-success" element={<LoginSuccessPage />} />
      <Route path="/static-page/:contentId" element={<StaticPage />} />
      <Route path="/reservation-cancel-confirmation" element={<ReservationCancelConfirmation />} />
      <Route
        path="/magic/:magicId/*"
        element={
          <WebSocketProvider>
            <MagicPage />
          </WebSocketProvider>
        }
      />
      <Route path="/info-stay/*" element={<InfoStayRouter />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
