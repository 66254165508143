import { useEffect, useMemo } from "react";
import { Reservation } from "../../../domain-common/reservation";
import { useAppDispatch } from "../../../state/store";
import { fetchServices, selectServices } from "../../services/services.slice";
import { useSelector } from "react-redux";
import { Service, ServiceGroup } from "../../../domain-common/service";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";

function getDistinctServiceGroups(services: Service[]) {
  let allServiceGroups: ServiceGroup[] = services
    .map((s) => s.serviceGroup as unknown as ServiceGroup)
    .filter(Boolean);
  let distinctServiceGroupIds = new Set(allServiceGroups.map((sg) => sg.id));
  return Array.from(distinctServiceGroupIds.values()).map((sgId) =>
    allServiceGroups.find((sg) => sg.id === sgId)
  ) as unknown as ServiceGroup[];
}

export enum GroupNames {
  Food = "Food",
  Beverage = "Beverage",
  BoxRent = "BoxRent",
  BoxBuy = "BoxBuy",
  BoxBuyFree = "BoxBuyFree",
  BoxBuyConsumables = "BoxBuyConsumables",
  SelfDrink = "SelfDrink"
}

export const useFetchShopProducts = (reservation: Reservation, groupNames: Array<GroupNames>) => {
  const dispatch = useAppDispatch();
  const services = useSelector(selectServices);
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();

  if (isGraphQlVersion) {
    console.warn("GraphQl version for useFetchShopProducts is not implemented yet.");
  }

  useEffect(() => {
    if (isRESTVersion) {
      dispatch(fetchServices({ magicId: reservation.magicId }));
    }
  }, [dispatch, isRESTVersion, reservation.magicId]);

  const products = useMemo(
    () =>
      services
        .filter((p) => p.serviceGroup)
        .filter(
          // @ts-ignore
          (p) => p.serviceGroup && groupNames.includes(p?.serviceGroup?.name)
        )
        .filter((p) => p.serviceGroup && p.propertyId === reservation.propertyId),
    [services, reservation.propertyId, groupNames]
  );

  const availableFilters: Array<ServiceGroup> = useMemo(
    () =>
      getDistinctServiceGroups(
        products.filter((service) => service.propertyId === reservation.propertyId)
      ),
    [products, reservation.propertyId]
  );

  return { products, availableFilters };
};
