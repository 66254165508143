import { Box, Grid } from "@mui/material";
import React, { FC, useCallback } from "react";
import { FastInput } from "../../../components/molecules/fast-input";
import { FastPhoneInput } from "../../../components/molecules/fast-phone-input";
import { WrappedNativeSelect } from "../../../components/atoms/input/wrapped-native-select";
import { emptyGuest, Guest } from "../../../domain-common/guest";
import { Button, Heading4, useCountries, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { getYesNoModalArg } from "../../global-modal/global-modal-util";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { AddIcon, TravelBuddyIcon } from "../../../components/icons";
import { makeStyles } from "tss-react/mui";
import CloseIcon from "@mui/icons-material/Close";
import { useGenderOptions } from "../../../util/hooks/use-gender-options";

const useStyles = makeStyles()((theme) => ({
  addIcon: {
    fontSize: 14,
    fontWeight: "bold"
  },
  deleteIcon: {
    fontSize: 24,
    fontWeight: "bold"
  },
  travelBuddy: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1)
  }
}));

export interface SecondScreenTravelBuddyFormProps {
  push: (value: any) => void;
  remove: (index: number) => void;
  form: any;
  objectPathPrefix?: string;
  propertyId: string;
}

export const SecondScreenTravelBuddyForm: FC<SecondScreenTravelBuddyFormProps> = ({
  objectPathPrefix,
  push,
  form,
  remove,
  propertyId
}) => {
  const { open: openModal } = useGlobalModal();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { classes } = useStyles();
  const { genderOptions } = useGenderOptions();
  const { preferredCountries } = useCountries({
    propertyId
  });

  const addTravelBuddy = useCallback(() => push(emptyGuest()), [push]);
  const deleteTravelBuddy = useCallback(
    (buddyIndexToBeDeleted: number) => async () => {
      const modalResult: boolean | null = await openModal(
        getYesNoModalArg(
          tCommon("modals__delete_buddy_dialog_title"),
          tCommon("modals__delete_buddy_dialog_text"),
          tCommon("buttons__yes"),
          tCommon("buttons__no")
        )
      );
      if (modalResult) {
        remove(buddyIndexToBeDeleted);
      }
    },
    [openModal, tCommon, remove]
  );

  return (
    <>
      {form.values.travelBuddies.map((guest: Guest, index: number) => {
        // TODO - check this once we have real values
        if (form.values.ignoreUntilIdx && index < form.values.ignoreUntilIdx) {
          return <React.Fragment key={`empty-row-${index}`} />;
        }
        return (
          <Box key={`guest-${guest.objectId}`}>
            <Box mt={4}>
              <Grid container direction="row">
                <Grid item xs={6} container className={classes.travelBuddy}>
                  <TravelBuddyIcon />
                  <Heading4>{`${tCommon("labels__travel_buddy")} ${index + 1}`}</Heading4>
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
                  <Button variant="ghost" onClick={deleteTravelBuddy(index)}>
                    <CloseIcon className={classes.deleteIcon} />
                    <Box ml={1.5}>{tCommon("buttons__remove")}</Box>
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={4} md={2}>
                <WrappedNativeSelect
                  items={genderOptions}
                  id={[objectPathPrefix, `[${index}].gender`].join("")}
                  name={[objectPathPrefix, `[${index}].gender`].join("")}
                  value={form.values.travelBuddies[index].gender || ""}
                  label={tCommon("labels__gender")}
                  onChange={form.handleChange}
                />
              </Grid>
              <Grid item xs={8} md={4}>
                <FastInput
                  id={[objectPathPrefix, `[${index}].firstName`].join("")}
                  name={[objectPathPrefix, `[${index}].firstName`].join("")}
                  value={form.values.travelBuddies[index].firstName || ""}
                  label={tCommon("labels__first_name")}
                  error={form.touched?.firstName && form.errors?.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FastInput
                  id={[objectPathPrefix, `[${index}].lastName`].join("")}
                  name={[objectPathPrefix, `[${index}].lastName`].join("")}
                  value={form.values.travelBuddies[index].lastName || ""}
                  label={tCommon("labels__last_name")}
                  error={form.touched?.lastName && form.errors?.lastName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FastInput
                  id={[objectPathPrefix, `[${index}].email`].join("")}
                  name={[objectPathPrefix, `[${index}].email`].join("")}
                  value={form.values.travelBuddies[index].email || ""}
                  type="email"
                  label={tCommon("labels__email")}
                  error={form.touched?.email && form.errors?.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FastPhoneInput
                  name={[objectPathPrefix, `[${index}].phone`].join("")}
                  value={form.values.travelBuddies[index].phone || ""}
                  error={(form.touched?.phone && form.errors?.phone) || undefined}
                  label={tCommon("labels__phone")}
                  preferredCountries={preferredCountries}
                />
              </Grid>
            </Grid>
          </Box>
        );
      })}
      {form.values.maxCompanions > form.values.travelBuddies.length && (
        <Box mt={2}>
          <Button variant="ghost" onClick={addTravelBuddy}>
            <AddIcon className={classes.addIcon} />
            <Box ml={1.5}>{tCommon("buttons__add_buddy")}</Box>
          </Button>
        </Box>
      )}
    </>
  );
};
