import React, { useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { UnitStatusDialog } from "../../components/organism/guest-flow-call-to-action/unit-status-dialog";
import { KeysSpeedDial } from "../keys-handlers/keys-speed-dial/keys-speed-dial";
import { NotFoundPage } from "../../components/organism/not-found.page";
import { isTravelBuddy } from "../../util/flow";
import { CheckoutSuccessPage } from "./pages/checkout-success.page";
import { EventDetailsPage } from "./pages/event-details.page";
import { PortalPaymentSuccessPage } from "./pages/portal-payment-success.page";
import { PortalPaymentPage } from "./pages/portal-payment.page";
import { ProfileAccountCreatedPage } from "./pages/profile-account-created.page";
import { ProfileAccountPage } from "./pages/profile-account.page";
import { ProfileAddressPage } from "./pages/profile-address.page";
import { ProfileCommunicationPage } from "./pages/profile-communication.page";
import { ProfileLanguagePage } from "./pages/profile-language.page";
import { ProfileLegalPage } from "./pages/profile-legal.page";
import { ProfilePersonalPage } from "./pages/profile-personal.page";
import { ProfilePage } from "./pages/profile.page";
import { PortalPagesEnum, PortalPageToPath } from "./portal-page-to-path";
import { ProfilePagesEnum, ProfilePageToPath } from "./profile-page-to-path";

import { SubscriptionUseCase } from "../web-socket/use-magic-web-socket";
import { useMagicIdParams } from "../magic/use-magic-id-params";
import { useSubscribeToUseCase } from "../web-socket/use-subscribe-to-use-case";
import { PortalPaymentRedirectPage } from "./pages/portal-payment-redirect.page";
import { useIsMobile } from "../../components/layouts/hooks/use-is-mobile";
import { useAppDispatch } from "../../state/store";
import { useReservationContext } from "../reservation-provider/reservation-provider";
import { useFeatureFlags } from "../../util/hooks/use-configuration";
import { MyStayRoutes } from "./my-stay.router";
import { useNavbar } from "../../components/organism/top-navbar/navbar-context";
import { initPortal } from "./portal.slice";
import { ContactButtonNavigation } from "../../components/molecules/contact-button-navigation";
import { CartButtonNavigation } from "../../components/molecules/cart-button-navigation";
import { ProtectedRoute } from "../../util/protected-route";

export const PortalPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useLocation();
  const { magicId } = useMagicIdParams();
  const { reservation } = useReservationContext();
  const { setDisplayNavbar, setProgress, setLeftButtons, setRightButtons } = useNavbar();
  const { shopEnabled } = useFeatureFlags();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  React.useEffect(
    () => () => {
      dispatch(initPortal());
    },
    [dispatch]
  );

  useSubscribeToUseCase({
    magicId: reservation.magicId,
    magicToken: reservation.magicToken,
    topic: SubscriptionUseCase.PORTAL,
    forceSkipSubscribe: reservation.magicId !== magicId
  });

  const isMainPage = useMemo(
    () =>
      [
        PortalPageToPath[PortalPagesEnum.MY_STAY],
        PortalPageToPath[PortalPagesEnum.PROFILE],
        PortalPageToPath[PortalPagesEnum.BOOKING]
      ].some((pagePath) => pathname.endsWith(pagePath)),
    [pathname]
  );

  const isPaymentPage = useMemo(
    () =>
      [
        PortalPageToPath[PortalPagesEnum.PAYMENT],
        PortalPageToPath[PortalPagesEnum.PAYMENT_SUCCESS],
        PortalPageToPath[PortalPagesEnum.PAYMENT_REDIRECT]
      ].some((pagePath) => pathname.endsWith(pagePath)),
    [pathname]
  );

  React.useEffect(() => {
    if (isMainPage) {
      setProgress(0);
      setDisplayNavbar(true);
      setLeftButtons(null);
      const buttons = [
        <ContactButtonNavigation key="contact_button" propertyId={reservation.propertyId} />
      ];
      if (shopEnabled)
        buttons.unshift(<CartButtonNavigation key="cart_button" magicId={magicId} />);
      setRightButtons(buttons);
    }
  }, [
    setProgress,
    setDisplayNavbar,
    setLeftButtons,
    setRightButtons,
    magicId,
    isMainPage,
    shopEnabled,
    reservation.propertyId
  ]);

  return (
    <>
      <Routes>
        <Route path={`${PortalPageToPath[PortalPagesEnum.PROFILE]}`} element={<ProfilePage />} />
        {/*Profile region*/}
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PROFILE]}${
            ProfilePageToPath[ProfilePagesEnum.PERSONAL]
          }`}
          element={<ProfilePersonalPage />}
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PROFILE]}${
            ProfilePageToPath[ProfilePagesEnum.COMMUNICATION]
          }`}
          element={<ProfileCommunicationPage />}
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PROFILE]}${
            ProfilePageToPath[ProfilePagesEnum.LEGAL]
          }`}
          element={
            <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
              <ProfileLegalPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PROFILE]}${
            ProfilePageToPath[ProfilePagesEnum.ADDRESS]
          }`}
          element={
            <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
              <ProfileAddressPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PROFILE]}${
            ProfilePageToPath[ProfilePagesEnum.LANGUAGE]
          }`}
          element={<ProfileLanguagePage />}
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PROFILE]}${
            ProfilePageToPath[ProfilePagesEnum.ACCOUNT]
          }`}
          element={
            <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
              <ProfileAccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PROFILE]}${
            ProfilePageToPath[ProfilePagesEnum.ACCOUNT_CREATED]
          }`}
          element={
            <ProtectedRoute isAllowed={!isTravelBuddy(reservation)}>
              <ProfileAccountCreatedPage />
            </ProtectedRoute>
          }
        />
        {/*end region*/}
        {/*MyStay region*/}
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.MY_STAY]}/*`}
          element={<MyStayRoutes reservation={reservation} />}
        />

        {/*end region*/}
        {/*others*/}
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PAYMENT]}`}
          element={<PortalPaymentPage />}
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PAYMENT_REDIRECT]}`}
          element={<PortalPaymentRedirectPage />}
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.PAYMENT_SUCCESS]}`}
          element={<PortalPaymentSuccessPage />}
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.EVENTS]}/:eventId`}
          element={<EventDetailsPage />}
        />
        <Route
          path={`${PortalPageToPath[PortalPagesEnum.CHECKOUT_SUCCESS]}`}
          element={<CheckoutSuccessPage />}
        />
        {/*end others*/}
        {/*if user reach /portal should end up on the my-stay*/}
        <Route path="/" element={<Navigate to={pathname.concat("/my-stay")} replace />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <>
        {isMainPage && !isPaymentPage && isMobile && <KeysSpeedDial reservation={reservation} />}

        <UnitStatusDialog reservation={reservation} />
      </>
    </>
  );
};
