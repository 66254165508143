import React, { FC } from "react";
import { Reservation } from "../../../domain-common/reservation";
import { NotificationChip } from "../../../components";
import { useChipsAndButtonsBookingOverview } from "../hooks/use-chips-and-buttons-booking-overview";
import { Actor } from "../../../domain-common/booking-overview";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";

export const BookingOverviewReservationChip: FC<
  React.PropsWithChildren<{
    reservation: Reservation;
    actor?: Actor;
  }>
> = ({ reservation, actor }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { chipValue, chipType } = useChipsAndButtonsBookingOverview({
    actor: actor || Actor.TRAVEL_BUDDY,
    bookingOnBehalfOf: reservation?.extras?.bookingOnBehalfOf,
    reservationStatus: reservation?.status || ReservationStatus.NO_SHOW,
    notificationSent: reservation?.flowState?.notificationSent || false,
    guestFlowCompleted: reservation?.flowState?.wasOrIsCompleted || false,
    totalAllowedPaymentAmount: reservation?.totalAllowedPayment?.amount || 0
  });

  return chipValue ? <NotificationChip type={chipType} label={t(chipValue) || chipValue} /> : null;
};
