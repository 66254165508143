import { Address, AddressFromJSON } from "./address";
import { Company, CompanyFromJSON } from "./company";

export interface Debitor {
  name: string;
  title: string;
  firstName: string;
  type: string;
  address: Address;
  reference: string;
  email: string;
  company: Company;
}

export const DebitorFromJSON = (json: any): Debitor => {
  if (!json) {
    return json;
  }

  return {
    name: json["name"],
    title: json["title"],
    firstName: json["firstName"],
    type: json["type"],
    address: AddressFromJSON(json["address"]),
    reference: json["reference"],
    email: json["email"],
    company: CompanyFromJSON(json["company"])
  };
};
