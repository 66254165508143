import React, { FC, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { KeyAssignHandlerProps } from "./key-assign-handler";
import { Box, Grid } from "@mui/material";
import {
  isStatusFailed,
  isStatusIdle,
  isStatusLoading,
  isStatusSuccess
} from "../../state/EntityStateStatus";
import { SubmitButton } from "../../components";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { Heading2, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";
import { useEncodeKey } from "./use-encode-key";
import { errorHandleForComponentLazyLoad } from "../../util/code-split.util";

const AssignKeyAnimationRunning = lazy(() =>
  import("../guest-flow/components/assign-key-animation")
    .then(({ AssignKeyAnimationRunning }) => ({
      default: AssignKeyAnimationRunning
    }))
    .catch(errorHandleForComponentLazyLoad)
);

export const KeyAssignApi: FC<React.PropsWithChildren<KeyAssignHandlerProps>> = ({
  reservation,
  encoderId,
  successComponent,
  logout
}) => {
  const { encodeKey, keyStatus } = useEncodeKey();
  const [isWaitingTimeout, setIsWaitingTimeout] = useState(false);
  const config = useDoorProviderConfig(reservation.propertyId);

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const encodeKeyAction = useCallback(() => {
    encodeKey({ reservation, encoderId });
  }, [encodeKey, reservation, encoderId]);

  useEffect(() => {
    if (isStatusIdle(keyStatus) && !isWaitingTimeout) {
      setIsWaitingTimeout(true);
      setTimeout(() => {
        encodeKeyAction();
      }, 3000);
    }
  }, [keyStatus, encodeKeyAction, isWaitingTimeout]);

  const submitButton = useMemo(() => {
    if (isStatusSuccess(keyStatus)) {
      return <SubmitButton label={t("buttons__logout")} onClick={logout} hasWhiteBackground />;
    } else if (isStatusFailed(keyStatus)) {
      return (
        <SubmitButton
          label={t("buttons__try_again")}
          onClick={encodeKeyAction}
          disabled={isStatusLoading(keyStatus)}
        />
      );
    }
  }, [encodeKeyAction, logout, keyStatus, t]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" direction="column" p={2}>
        <Box maxWidth={400}>
          {isStatusSuccess(keyStatus) ? (
            successComponent
          ) : (
            <>
              <Box textAlign="center" pt={7} mx={6}>
                <Heading2 fontSize={20}>{t("labels__assign_key_api_instruction")}</Heading2>
              </Box>
              <Box pt={2.5}>
                <AssignKeyAnimationRunning doorProvider={config?.doorProvider} />
              </Box>
            </>
          )}
        </Box>
      </Grid>

      {submitButton}
    </>
  );
};
