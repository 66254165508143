import { graphqlApi } from "../graphql.api";
import {
  StreamMagicObjectDocument,
  StreamMagicObjectSubscription
} from "./subscribe-magic-object.generated";
import { api as getMagicObjectApi } from "../queries/GetMagicObject.generated";
import { api as getMagicBookingOverviewsApi } from "../queries/GetMagicBookingOverviews.generated";
import { createClient, Message } from "./subscribe.utils";
import {
  StreamBookingOverviewDocument,
  StreamBookingOverviewSubscription
} from "./subscribe-booking-overview.generated";

interface CustomSubscribeProps {
  magicId: string;
  magicToken: string;
  apiKey?: string;
  tenantId: string;
}

export const customSubscribeApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SubscribeToMagicObject: build.query<Message[], CustomSubscribeProps>({
      query: (arg) => ({
        document: StreamMagicObjectDocument,
        variables: arg
      }),
      providesTags: [],
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, dispatch }) {
        const client = createClient(arg);

        try {
          await cacheDataLoaded;
          const onNext = (value: { data: StreamMagicObjectSubscription }) => {
            if (value.data.StreamMagicObject) {
              dispatch(
                getMagicObjectApi.util.updateQueryData(
                  "GetMagicObject",
                  { magicId: arg.magicId },
                  (draft) => {
                    Object.assign(draft, {
                      GetMagicObject: {
                        ...value.data.StreamMagicObject
                      }
                    });
                  }
                )
              );
            } else {
              console.warn("Subscription has returned falsy data.", value.data);
            }
          };

          new Promise<void>((resolve, reject) => {
            client.subscribe(
              {
                query: StreamMagicObjectDocument,
                operationName: "StreamMagicObject",
                variables: { magicId: arg.magicId, magicToken: arg.magicToken }
              },
              {
                next: onNext,
                error: reject,
                complete: resolve
              }
            );
          });
        } catch (e) {
          console.error("WS subscription for V2 failed", e);
          client.dispose();
        }
        await cacheEntryRemoved;
        client.dispose();
      }
    }),
    SubscribeToBookingOverview: build.query<Message[], CustomSubscribeProps>({
      query: (arg) => ({
        document: StreamBookingOverviewDocument,
        variables: arg
      }),
      providesTags: [],
      async onCacheEntryAdded(arg, api) {
        const { cacheDataLoaded, cacheEntryRemoved, dispatch, getState } = api;
        const client = createClient(arg);

        try {
          await cacheDataLoaded;
          const onNext = (value: { data: StreamBookingOverviewSubscription }) => {
            if (value.data.StreamBookingOverview) {
              const queriedMagicIds = Object.values(getState().graphql.queries)
                .filter((item) => item && item?.endpointName === "GetMagicBookingOverviews")
                .map((item) => item?.originalArgs) as Array<{
                magicId: string;
              }>;

              queriedMagicIds?.forEach((item) =>
                dispatch(
                  getMagicBookingOverviewsApi.util.updateQueryData(
                    "GetMagicBookingOverviews",
                    item,
                    (draft) => {
                      const updatedDraft = draft.GetMagicBookingOverviews.map((item) => {
                        if (item.magicId === value.data.StreamBookingOverview.magicId) {
                          return value.data.StreamBookingOverview;
                        } else {
                          return item;
                        }
                      });

                      Object.assign(draft, {
                        GetMagicBookingOverviews: updatedDraft
                      });
                    }
                  )
                )
              );
            } else {
              console.warn("Subscription has returned falsy data.", value.data);
            }
          };

          new Promise<void>((resolve, reject) => {
            client.subscribe(
              {
                query: StreamBookingOverviewDocument,
                operationName: "StreamBookingOverview",
                variables: { magicId: arg.magicId, magicToken: arg.magicToken }
              },
              {
                next: onNext,
                error: reject,
                complete: resolve
              }
            );
          }).then((value) => {
            console.warn("Ws closed ", value);
          });
        } catch (e) {
          console.error("WS subscription for V2 failed", e);
          client.dispose();
        }
        await cacheEntryRemoved;
        client.dispose();
      }
    })
  }),

  overrideExisting: false
});
