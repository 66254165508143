import { BookingOnBehalfOf } from "./person";

export interface PublicTransportationTicket {
  name: string;
  code: string;
  link?: string;
  validUntil: string;
}

export interface WiFiCredentials {
  ssid: string;
  key: string;
}

export interface ReservationExtras {
  preferredCommunicationChannel: string | null;
  language?: string;
  tcGeneral: boolean;
  tcOnline: boolean;
  tcMarketingConsent: boolean;
  tcMarketingConsentDoubleOptin: boolean;
  cleanUnitDialogSeen: boolean;
  dirtyUnitDialogSeen: boolean;
  billConfirmed: boolean;
  passwordSet?: boolean;
  password?: string;
  bookingOnBehalfOf?: BookingOnBehalfOf;
  publicTransportationTickets?: Array<PublicTransportationTicket>;
  wiFiCredentials?: WiFiCredentials;
  enrolledInLoyaltyProgram?: boolean;
  emergencyEvacuationHelpNeeded?: boolean;
  tcMinors: boolean;
}

export const ReservationExtrasFromJSON = (json: any): ReservationExtras => {
  if (!json) {
    return json;
  }

  return {
    preferredCommunicationChannel: json["preferredCommunicationChannel"],
    tcGeneral: json["tcGeneral"],
    tcOnline: json["tcOnline"],
    tcMarketingConsent: json["tcMarketingConsent"],
    tcMarketingConsentDoubleOptin: json["tcMarketingConsentDoubleOptin"],
    cleanUnitDialogSeen: json["cleanUnitDialogSeen"],
    dirtyUnitDialogSeen: json["dirtyUnitDialogSeen"],
    billConfirmed: json["billConfirmed"],
    passwordSet: json["passwordSet"],
    password: json["password"],
    bookingOnBehalfOf: json["bookingOnBehalfOf"],
    publicTransportationTickets: json["publicTransportationTickets"],
    emergencyEvacuationHelpNeeded: json["emergencyEvacuationHelpNeeded"],
    wiFiCredentials: json["wiFiCredentials"],
    language: json["language"],
    tcMinors: json["tcMinors"],
    enrolledInLoyaltyProgram: json["enrolledInLoyaltyProgram"]
  };
};

export const emptyReservationExtras = (): ReservationExtras => ({
  preferredCommunicationChannel: null,
  tcGeneral: false,
  tcOnline: false,
  tcMarketingConsent: false,
  tcMarketingConsentDoubleOptin: false,
  cleanUnitDialogSeen: false,
  dirtyUnitDialogSeen: false,
  billConfirmed: false,
  passwordSet: false,
  password: "",
  publicTransportationTickets: [],
  wiFiCredentials: undefined,
  language: undefined,
  tcMinors: false,
  emergencyEvacuationHelpNeeded: false
});
