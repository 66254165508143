import React, { FC } from "react";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/styles";

interface SubtitleProps {
  subtitle?: string;
}

export const Subtitle: FC<SubtitleProps> = ({ subtitle }) => {
  const theme = useTheme();
  return (
    <Paragraph
      align="center"
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        fontSize: 14,
        color: theme.palette.grey[600]
      }}
    >
      {subtitle}
    </Paragraph>
  );
};
