import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox, Paragraph, useAuth, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { Form, Formik, FormikProps } from "formik";
import { Grid } from "@mui/material";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { PrismicRichTextWrapper } from "../../../components";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { makeStyles } from "tss-react/mui";
import { getOkModalArg } from "../../global-modal/global-modal-util";
import { WrappedInput } from "../../../components/atoms/input/wrapped-input";

const useStyles = makeStyles()(({ spacing, palette, shape }) => ({
  authenticatedContainer: {
    border: `${spacing(0.1)} solid ${palette.grey.A400}`,
    width: "100%",
    borderRadius: shape.borderRadius
  },
  tc: {
    cursor: "pointer",
    textDecoration: "underline",
    textAlign: "left"
  },
  fullWidth: {
    width: "100%"
  }
}));

interface BookerFields {
  firstName: string;
  lastName: string;
  email: string;
}

interface OnSubmitValues extends BookerFields {
  termsAccepted: boolean;
}

export const PaymentPersonalData: FC<
  React.PropsWithChildren<{
    setIsBookerFormDataComplete: (value: boolean) => void;
    setBooker: (value: { firstName: string; lastName: string; email: string }) => void;
    booker: BookerFields;
  }>
> = ({ setIsBookerFormDataComplete, setBooker, booker }) => {
  const { createBookingPersonValidationFields } = useFormValidations();
  const [formHasError, setFormHasError] = useState<boolean>(true);
  const { open: openModal } = useGlobalModal();
  const { classes } = useStyles();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const handleOpenInfo = useCallback(() => {
    openModal(
      getOkModalArg(
        tCommon("modals__title_tc"),
        <PrismicRichTextWrapper render={cmsData?.data?.["modals__tc_general"]} />,
        tCommon("buttons__ok")
      )
    );
  }, [cmsData?.data, openModal, tCommon]);
  const { getIsAuthenticated } = useAuth();
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
  const initialValues = useMemo(() => {
    return {
      ...booker,
      termsAccepted: false
    };
  }, [booker]);
  const submit = useCallback(
    (values: OnSubmitValues) => {
      if (values.termsAccepted) {
        setBooker({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email
        });
      }
    },
    [setBooker]
  );

  const onChange = useCallback(
    (
      event: React.ChangeEvent<any>,
      formik: FormikProps<{
        termsAccepted: boolean;
        firstName: string;
        lastName: string;
        email: string;
      }>
    ) => {
      formik.handleChange(event);
      if (event.target.id === "termsAccepted") {
        setIsCheckBoxSelected(event.target.checked);
      } else {
        setIsCheckBoxSelected(false);
        formik.setFieldValue("termsAccepted", false, false);
      }
    },
    []
  );

  useEffect(() => {
    setIsBookerFormDataComplete(isCheckBoxSelected && !formHasError);
  }, [setIsBookerFormDataComplete, formHasError, isCheckBoxSelected]);

  return (
    <>
      {getIsAuthenticated() ? (
        <Grid container my={2} flexDirection="column" alignItems="flex-start">
          <Grid
            mb={1}
            container
            flexDirection="column"
            p={2}
            className={classes.authenticatedContainer}
          >
            <Paragraph mb={0.1}>
              {booker.firstName} {booker.lastName}
            </Paragraph>
            <Paragraph>{booker.email}</Paragraph>
          </Grid>
          <Paragraph color="text.secondary" className={classes.tc} onClick={handleOpenInfo}>
            {tCommon("labels__tc")}
          </Paragraph>
        </Grid>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={createBookingPersonValidationFields}
        >
          {(formik) => (
            <Form className={classes.fullWidth}>
              <Grid container py={2}>
                <ErrorListener
                  setError={setFormHasError}
                  error={!!Object.keys(formik.errors).length}
                />
                <Grid item pr={1} xs={6}>
                  <WrappedInput
                    id="firstName"
                    label={tCommon("labels__first_name")}
                    value={formik.values.firstName}
                    onChange={(e) => onChange(e, formik)}
                    error={formik.touched?.firstName && formik.errors?.firstName}
                  />
                </Grid>
                <Grid item pl={1} xs={6}>
                  <WrappedInput
                    id="lastName"
                    label={tCommon("labels__last_name")}
                    value={formik.values.lastName}
                    onChange={(e) => onChange(e, formik)}
                    error={formik.touched?.lastName && formik.errors?.lastName}
                  />
                </Grid>
              </Grid>
              <Grid>
                <WrappedInput
                  fullWidth
                  id="email"
                  label={tCommon("labels__email")}
                  value={formik.values.email}
                  onChange={(e) => onChange(e, formik)}
                  error={formik.touched?.email && formik.errors?.email}
                />
              </Grid>
              <Grid container flexDirection="row" alignItems="center" sx={{ flexWrap: "nowrap" }}>
                <Checkbox
                  onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    onChange(e, formik);
                    await formik.submitForm();
                  }}
                  id="termsAccepted"
                  name="termsAccepted"
                  checked={formik.values.termsAccepted}
                  sx={{ paddingLeft: 0 }}
                />
                <Paragraph color="text.secondary" className={classes.tc} onClick={handleOpenInfo}>
                  {tCommon("labels__confirm_tc")}
                </Paragraph>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const ErrorListener: FC<
  React.PropsWithChildren<{
    setError: (value: boolean) => void;
    error: boolean;
  }>
> = ({ setError, error }) => {
  useEffect(() => {
    setError(error);
  }, [error, setError]);
  return null;
};
