import { createSlice, Selector } from "@reduxjs/toolkit";
import { CmsSingleDocumentPerPropertyType } from "../cms/cms-single-document-per-property-type";
import { CMSPageState } from "../cms/cms-single-page-factory";
import {
  createCMSSingleDocumentPerPropertyFetchThunk,
  createCMSSinglePagePerPropertyAdapter
} from "../cms/cms-single-page-per-property-factory";
import { RootState } from "../store";

export type ConfigurationCMSState = CMSPageState;
export const configurationCMSAdapter = createCMSSinglePagePerPropertyAdapter();

const initialState: ConfigurationCMSState = configurationCMSAdapter.getInitialState();

export const fetchConfigurationCMS = createCMSSingleDocumentPerPropertyFetchThunk(
  CmsSingleDocumentPerPropertyType.configuration
);

export const configurationCMSSlice = createSlice({
  name: "configurationCMS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfigurationCMS.pending, configurationCMSAdapter.handlePendingLoadingStatus)
      .addCase(fetchConfigurationCMS.fulfilled, (state, action) => {
        configurationCMSAdapter.handleFulfilledLoadingStatus(state, action);
        configurationCMSAdapter.addOne(state, action);
      })
      .addCase(fetchConfigurationCMS.rejected, configurationCMSAdapter.handleRejectedLoadingStatus);
  }
});

export const selectSelf: Selector<RootState, ConfigurationCMSState> = (state: RootState) =>
  state.cmsPerProperty[CmsSingleDocumentPerPropertyType.configuration];

export const { selectAll: selectConfigurationCMS, selectById: selectConfigurationCMSById } =
  configurationCMSAdapter.getSelectors<RootState>(selectSelf);
