import sessionStorage from "redux-persist/lib/storage/session";
import localStorage from "redux-persist/lib/storage";
import { initialMagicInfoSlice } from "../features/initial-magic-info/initial-magic-id.slice";
import { bookingCartSlice } from "../features/search-for-bookings/booking-cart.slice";
import { restoreMagicContextSlice } from "../features/restore-magic-context/restore-magic-context.slice";
import { promoCodeSlice } from "../features/search-for-bookings/promocode.slice";
import { dynamicShopCartSlice } from "../features/dynamic-shop/dynamic-shop.slice";

const defaultTimeout = 500;

export const persistConfig = {
  timeout: defaultTimeout,
  key: "root_v1", // Just increment version if some data has been changed in slices that are in the white list
  storage: sessionStorage,
  whitelist: [initialMagicInfoSlice.name, promoCodeSlice.name],
  blacklist: [bookingCartSlice.name]
};

// This allows us to blacklist properties that we don't want to persist in the session
// In this case we don't want to store a failed validation, so when the page is refreshed it's clean.
export const bookingCartConfig = {
  timeout: defaultTimeout,
  key: bookingCartSlice.name,
  storage: sessionStorage,
  blacklist: ["cartTotalPrice", "cartTotalPriceStatus"]
};

export const dynamicShopCartConfig = {
  timeout: defaultTimeout,
  key: dynamicShopCartSlice.name,
  storage: sessionStorage,
  blacklist: ["selectedFilters"]
};

export const restoreMagicContextConfig = {
  timeout: defaultTimeout,
  key: restoreMagicContextSlice.name,
  storage: localStorage,
  blacklist: []
};
