import { Button, Heading1, Heading2, useAuth } from "@likemagic-tech/sv-magic-library";
import { DesktopCard, Notification, SubmitButton } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import React, { useCallback, useEffect } from "react";
import { Box, Divider, Grid } from "@mui/material";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { SearchCartTotal } from "../components/cart/search-cart-total";
import { makeStyles } from "tss-react/mui";
import { SearchCartItem } from "../components/cart/search-cart-item";
import { generateSearchBookingPagesUrl } from "../search-booking-navigation";
import { SearchBookingPages } from "../search-booking-pages";
import { useSearchBookNavigation } from "../hooks/use-search-book-navigation";
import { useSelector } from "react-redux";
import { selectAllFromBookingCart, selectCartReservations } from "../booking-cart.slice";
import { generateSearchBookingCartBookingsUrl, getSearchUrl } from "../../../util/routing";
import { useAppDispatch } from "../../../state/store";
import { AddIcon } from "../../../components/icons";
import { HelmetTitle } from "../../gtm/helmet-title";
import { responsiveVariables, useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { useNavigate } from "react-router-dom";
import { preparePayment, setReferenceId } from "../../payment/payment.slice";
import { emptyPrice } from "../../../domain-common/price";
import { toGross } from "../../../domain-common/full-price";
import {
  useConfigBasedOnlyOnBaseUrl,
  useFeatureFlags
} from "../../../util/hooks/use-configuration";
import { PaymentType } from "../../../domain-common/booking-cart-total-price";
import { useBookingTotalPrice } from "../hooks/use-booking-total-price";
import { openBanner } from "../../banner/banner.slice";
import { isKioskMode } from "../../../util/kiosk-mode";
import { PromoCodeType, selectPromoCode } from "../promocode.slice";
import { useValidPromoCodeResponse } from "../hooks/use-valid-promocode-response";
import { generateUUID } from "../../../util/data";

const useStyles = makeStyles()(({ spacing, breakpoints }) => ({
  root: {
    [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
      margin: spacing(3)
    }
  },
  button: {
    marginTop: spacing(2)
  },
  addStudioLink: {
    marginBottom: spacing(2),
    padding: spacing(1),
    [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
      marginTop: spacing(2)
    }
  },
  divider: {
    marginLeft: spacing(-3),
    marginRight: spacing(-3)
  },
  addIcon: {
    marginRight: spacing(1),
    fontSize: 14,
    fontWeight: "bold"
  },
  verticallyCenteredLabel: {
    height: `calc(100vh - ${spacing(26)})` // header + footer + tabs + paddings
  }
}));

export const SearchCartPage = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { getIsAuthenticated, login } = useAuth();
  const navigate = useNavigate();
  const nextPagePath = generateSearchBookingPagesUrl(SearchBookingPages.PAYMENT_AND_BOOKER_DATA);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const cartItems = useSelector(selectAllFromBookingCart);

  const { search: searchConfig } = useConfigBasedOnlyOnBaseUrl();
  const { showVat } = useFeatureFlags();

  const promoCode = useSelector(selectPromoCode);
  const promoCodeData = useValidPromoCodeResponse({ promoCode: promoCode });

  const isMobile = useIsMobile();
  const formattedCartReservations = useSelector(selectCartReservations);

  const { totalBill } = useBookingTotalPrice(
    cartItems,
    promoCodeData.type === PromoCodeType.MAGIC ? promoCode : ""
  );

  const { availableButtons } = useSearchBookNavigation();
  useEffect(() => {
    if (isKioskMode()) {
      dispatch(
        openBanner({
          type: "warning",
          title: t("labels__search_unit_groups_kiosk_warning")
        })
      );
    }
  }, [dispatch, t]);
  const onAddStudioHandler = useCallback(() => {
    navigate(generateSearchBookingPagesUrl(SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES));
  }, [navigate]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSubmit = () => {
    if (!totalBill) {
      throw new Error("totalBill should be populated");
    }

    dispatch(
      preparePayment({
        adyenPrice: toGross(totalBill.total) || emptyPrice(),
        bookingCreation: {
          booker: { firstName: "", lastName: "", email: "" },
          reservations: formattedCartReservations,
          promoCodeMagic: promoCode
        }
      })
    );
    dispatch(setReferenceId(generateUUID()));

    navigate(nextPagePath);
  };

  return (
    <FlowTemplate handleBack={goBack} icons={availableButtons}>
      <HelmetTitle suffix="Booking cart" />
      <Heading1 textAlign={isMobile ? "center" : "left"} gutterBottom>
        {t("title__booking_cart_overview")}
      </Heading1>
      {searchConfig?.maxReservationsPerBooking &&
        cartItems.length >= searchConfig.maxReservationsPerBooking && (
          <Box pt={2}>
            <Notification
              type="warning"
              title={t("labels__maximum_reservations_per_booking_reached_info")}
            />
          </Box>
        )}
      {!cartItems.length && (
        <Grid
          className={classes.verticallyCenteredLabel}
          container
          alignItems="center"
          justifyContent="center"
          p={2.5}
        >
          <Heading2 textAlign="center">{t("labels__empty_cart")}</Heading2>
        </Grid>
      )}
      {!!cartItems.length && (
        <Box className={classes.root}>
          {cartItems.map((item) => (
            <SearchCartItem key={item.uuid} {...item} />
          ))}

          {isMobile && <Divider className={classes.divider} />}

          {!isKioskMode() && (
            <Button
              variant="ghost"
              className={classes.addStudioLink}
              onClick={onAddStudioHandler}
              startIcon={<AddIcon />}
            >
              {t("buttons__add_another_studio")}
            </Button>
          )}
          {isMobile && <Divider className={classes.divider} />}
          <DesktopCard>
            {totalBill?.paymentType === PaymentType.GUARANTEED_BY_COMPANY && (
              <Box pt={2}>
                <Notification type="warning" title={t("labels__booking_creation_on_ar")} />
              </Box>
            )}
            {totalBill && (
              <SearchCartTotal
                showVat={showVat}
                totalBill={totalBill}
                promoCodeData={promoCodeData}
              />
            )}

            {getIsAuthenticated() || isKioskMode() ? (
              <SubmitButton
                hasBottomNavigation
                hasWhiteBackground
                label={t("buttons__proceed")}
                onClick={onSubmit}
              />
            ) : (
              <Grid container spacing={1} alignContent="center" justifyContent="center">
                <Grid item xs={12} md={6}>
                  <SubmitButton
                    hasBottomNavigation
                    hasWhiteBackground
                    hasSecondaryFixedButton
                    label={t("buttons__proceed_as_member")}
                    onClick={async () =>
                      await login({
                        redirectUri: getSearchUrl(generateSearchBookingCartBookingsUrl())
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <SubmitButton
                    hasBottomNavigation
                    hasWhiteBackground
                    variant="secondary"
                    label={t("buttons__proceed_as_guest")}
                    onClick={onSubmit}
                  />
                </Grid>
              </Grid>
            )}
          </DesktopCard>
        </Box>
      )}
    </FlowTemplate>
  );
};
