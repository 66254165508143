import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../../state/store";
import { BookingOverview, BookingOverviewFromJSON } from "../../../domain-common/booking-overview";
import { EntityStateStatus } from "../../../state/EntityStateStatus";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { handleSliceError } from "../../../util/error-handling";
import { UserProfileApi } from "../../../api/user-profile.api";
import { bookingOverviewAdapter } from "./booking-overview.slice";
import { getTenantHeaders } from "../../../api/custom-headers";

export const inactiveBookingOverviewAdapter = createEntityAdapter<BookingOverview>({
  selectId: (inactiveBookingOverview: BookingOverview) =>
    `${inactiveBookingOverview.id}_${inactiveBookingOverview.actor}`
});

const initialState = inactiveBookingOverviewAdapter.getInitialState<{
  status: EntityStateStatus;
}>({
  status: EntityStateStatus.IDLE
});

export const fetchBookerOverviewInactive = createAsyncThunk<
  BookingOverview[],
  undefined,
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>(
  "inactiveBookingOverview/fetchInactive",
  async (arg, thunkAPI) => {
    try {
      return await UserProfileApi.fetchBookerViewInactive({
        signal: thunkAPI.signal,
        ...(await getTenantHeaders(thunkAPI.extra))
      });
    } catch (e) {
      return handleSliceError(e, thunkAPI.rejectWithValue);
    }
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().inactiveBookingOverview.status;
      if (status === EntityStateStatus.LOADING || status === EntityStateStatus.SUCCEEDED) {
        return false;
      }
    }
  }
);

export const inactiveBookingOverviewSlice = createSlice({
  name: "inactiveBookingOverview",
  initialState,
  reducers: {
    initInactiveBookingOverview: () => ({
      ...initialState,
      ...inactiveBookingOverviewAdapter.getInitialState()
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookerOverviewInactive.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchBookerOverviewInactive.fulfilled, (state, action) => {
        bookingOverviewAdapter.removeAll(state);
        inactiveBookingOverviewAdapter.addMany(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchBookerOverviewInactive.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { initInactiveBookingOverview } = inactiveBookingOverviewSlice.actions;

const { selectAll } = inactiveBookingOverviewAdapter.getSelectors<RootState>(
  (state) => state.inactiveBookingOverview
);

export const selectBookingOverviewInactive = createSelector(selectAll, (res) =>
  res.map((item) => BookingOverviewFromJSON(item))
);
