import { createSlice, Selector } from "@reduxjs/toolkit";
import {
  CMSPageState,
  createCMSSingleDocumentFetchThunk,
  createCMSSinglePageAdapter
} from "../cms/cms-single-page-factory";
import { RootState } from "../store";
import { CMSSingleDocumentTypes } from "../cms/cms-single-document-types";

export type ArticleOrderingCMSState = CMSPageState;
export const articleOrderingCMSAdapter = createCMSSinglePageAdapter();

const initialState: ArticleOrderingCMSState = articleOrderingCMSAdapter.getInitialState();

export const fetchArticleOrderingCMS = createCMSSingleDocumentFetchThunk(
  CMSSingleDocumentTypes.articleOrdering,
  [
    "article.main__title",
    "article.main__document_title",
    "article.main__property_id",
    "article.main__alias",
    "article.main__cover_image",
    "article.main__content_type",
    "article.card__subtitle",
    "article.details__subtitle",
    "article.details__description",
    "link.main__title",
    "link.main__document_title",
    "link.main__property_id",
    "link.main__alias",
    "link.main__cover_image",
    "link.main__content_type",
    "link.main__link",
    "link.card__subtitle"
  ]
);

export const articleOrderingCMSSlice = createSlice({
  name: "articleOrderingCMS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchArticleOrderingCMS.pending,
        articleOrderingCMSAdapter.handlePendingLoadingStatus
      )
      .addCase(fetchArticleOrderingCMS.fulfilled, (state, action) => {
        articleOrderingCMSAdapter.handleFulfilledLoadingStatus(state, action);
        articleOrderingCMSAdapter.addOne(state, action);
      })
      .addCase(
        fetchArticleOrderingCMS.rejected,
        articleOrderingCMSAdapter.handleRejectedLoadingStatus
      );
  }
});

export const selectSelf: Selector<RootState, ArticleOrderingCMSState> = (state: RootState) =>
  state.cms[CMSSingleDocumentTypes.articleOrdering];

export const { selectAll: selectArticleOrderingCMS, selectById: selectArticleOrderingCMSById } =
  articleOrderingCMSAdapter.getSelectors<RootState>(selectSelf);
