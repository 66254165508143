import { Heading3, Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { ReactElement } from "react";

const useStyles = makeStyles()((t) => ({
  icon: {
    height: t.spacing(6),
    width: t.spacing(6),

    marginBottom: t.spacing(3)
  },
  svgContainer: {
    "& svg *": {
      stroke: "inherit"
    }
  }
}));
export interface PageHeadingInfoProps {
  title: string;
  subtitle?: string;
  icon?: ReactElement;
}
export const PageHeadingInfo: React.FC<React.PropsWithChildren<PageHeadingInfoProps>> = ({
  title,
  subtitle,
  icon
}) => {
  const { classes } = useStyles();
  const { palette } = useTheme();

  return (
    <Box mb={3.75} textAlign="center" className={classes.svgContainer}>
      {icon && (
        <>
          {React.cloneElement(icon, {
            className: classes.icon,
            stroke: palette.text.secondary
          })}
        </>
      )}
      <Heading3 mb={1}>{title}</Heading3>
      <Paragraph>{subtitle}</Paragraph>
    </Box>
  );
};
