import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type EncodeKeyMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
  encoderId: Types.Scalars["String"];
}>;

export type EncodeKeyMutation = {
  __typename?: "Mutation";
  EncodeKey?:
    | { __typename: "Booking" }
    | { __typename: "Reservation" }
    | { __typename: "TravelBuddy" }
    | null;
};

export const EncodeKeyDocument = `
    mutation EncodeKey($magicId: String!, $magicToken: String!, $encoderId: String!) {
  EncodeKey(magicId: $magicId, magicToken: $magicToken, encoderId: $encoderId) {
    __typename
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    EncodeKey: build.mutation<EncodeKeyMutation, EncodeKeyMutationVariables>({
      query: (variables) => ({ document: EncodeKeyDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useEncodeKeyMutation } = injectedRtkApi;
