import { useMemo } from "react";
import { useCmsPropertySpecificData } from "../../state/cms/use-cms-per-property-data";
import { useCommonCmsData } from "../../state/common-cms/use-common-cms-data";

export const useServicesCmsData = (propertyId: string) => {
  const cmsData = useCmsPropertySpecificData(propertyId);
  const commonCmsData = useCommonCmsData();

  const cmsServices = cmsData?.data?.["services"];
  const commonCmsServices = commonCmsData?.data?.["services"];

  const serviceImages = useMemo(
    () => ({
      ...groupServiceImagesByServiceId(commonCmsServices),
      ...groupServiceImagesByServiceId(cmsServices)
    }),

    [cmsServices, commonCmsServices]
  );

  const serviceIcons = useMemo(
    () => ({
      ...groupServiceIconsByServiceId(commonCmsServices),
      ...groupServiceIconsByServiceId(cmsServices)
    }),

    [cmsServices, commonCmsServices]
  );

  const serviceTitles = useMemo(
    () => ({
      ...groupServiceTitlesByServiceId(commonCmsServices),
      ...groupServiceTitlesByServiceId(cmsServices)
    }),
    [cmsServices, commonCmsServices]
  );

  return {
    cmsData,
    serviceImages,
    serviceIcons,
    serviceTitles,
    fallbackImage:
      cmsData?.data?.["services__fallback_image"]?.url ||
      commonCmsServices?.data?.["services__fallback_image"]?.url,
    fallbackIcon:
      cmsData?.data?.["services__fallback_icon"]?.url ||
      commonCmsServices?.data?.["services__fallback_icon"]?.url
  };
};

interface CmsServiceWithImage {
  icon: { url: string };
  image: { url: string };
  service_id: string;
  title: string;
}

function groupCmsServiceImageByServiceId(
  cmsDataKey: "icon" | "image",
  cmsServices?: Array<CmsServiceWithImage>
) {
  if (!cmsServices) {
    return [];
  }
  return cmsServices.reduce(
    (previousValue: any, currentValue) => ({
      ...previousValue,
      [currentValue["service_id"]]: currentValue[cmsDataKey]?.url
    }),
    {}
  );
}

function groupCmsServiceTitleByServiceId(cmsServices?: Array<CmsServiceWithImage>) {
  if (!cmsServices) {
    return {};
  }
  return cmsServices.reduce(
    (previousValue: any, currentValue) => ({
      ...previousValue,
      [currentValue["service_id"]]: currentValue["title"]
    }),
    {}
  );
}

export function groupServiceIconsByServiceId(cmsServices?: Array<CmsServiceWithImage>) {
  return groupCmsServiceImageByServiceId("icon", cmsServices);
}

export function groupServiceImagesByServiceId(cmsServices?: Array<CmsServiceWithImage>) {
  return groupCmsServiceImageByServiceId("image", cmsServices);
}

export function groupServiceTitlesByServiceId(cmsServices?: Array<CmsServiceWithImage>) {
  return groupCmsServiceTitleByServiceId(cmsServices);
}

export const getServiceDetails = (
  service: {
    serviceId: string;
    code: string;
  },
  cmsData: any,
  fallbackValue: string
) => cmsData[service.serviceId] || cmsData[service.code] || fallbackValue;

export const getDisplayServiceDetails = (
  service: {
    service: {
      code: string;
      id: string;
    };
  },
  cmsData: any,
  fallbackValue: string
) => cmsData[service.service.id] || cmsData[service.service.code] || fallbackValue;
