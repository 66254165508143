export const SELECTED_PAYMENT_OPTION = "SELECTED_PAYMENT_OPTION";
export enum PaymentOption {
  NEW_CARD = "NEW_CARD",
  MEWS_TOKENIZED_CARD = "MEWS_TOKENIZED_CARD",
  ADYEN_WIDGET = "ADYEN_WIDGET",
  APALEO_TOKENIZED_CARD = "APALEO_TOKENIZED_CARD",
  TERMINAL_PAYMENT = "TERMINAL_PAYMENT",
  PAY_AT_CHECKOUT = "PAY_AT_CHECKOUT",
  PAY_AT_CHECKIN = "PAY_AT_CHECKIN",
  POST_PAYMENT = "POST_PAYMENT" //TODO rename to something more specific ?
}
// Payment options that have a flow on its own, not just an api call.
export const paymentOptionsNotJustApiCalls = [
  PaymentOption.ADYEN_WIDGET,
  PaymentOption.TERMINAL_PAYMENT
];

export const parseSelectedPaymentOptionFromQueryParam = (searchUrlString: string) => {
  const params = new URLSearchParams(searchUrlString);
  return params.get(SELECTED_PAYMENT_OPTION);
};
