import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React, { FC, PropsWithChildren, useMemo } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { NavigationItem } from "../../organism/bottom-navbar/bottom-navbar-context";
import { useTheme } from "@mui/styles";
import { Paragraph, ParagraphBold } from "@likemagic-tech/sv-magic-library";

const DisplayLabel: FC<PropsWithChildren<{ isSelected: boolean }>> = ({ isSelected, children }) =>
  isSelected ? <ParagraphBold>{children}</ParagraphBold> : <Paragraph> {children} </Paragraph>;

export const PortalBottomNavigation: React.FC<
  React.PropsWithChildren<{
    links: Array<NavigationItem>;
  }>
> = ({ links }) => {
  const { pathname } = useLocation();
  const theme = useTheme();

  const items = useMemo(
    () =>
      links.map((l) => {
        return (
          <BottomNavigationAction
            sx={{
              "&.MuiBottomNavigationAction-root": {
                color: theme.palette.common.black,
                borderBottomWidth: theme.spacing(0.5),
                borderStyle: "solid",
                borderColor: theme.palette.common.white,
                paddingBottom: theme.spacing(0.5)
              },
              "&.Mui-selected": {
                borderColor: theme.palette.primary.main,
                color: theme.palette.common.black
              }
            }}
            key={l.label}
            label={<DisplayLabel isSelected={l.isSelected}>{l.label}</DisplayLabel>}
            icon={l.isSelected ? l.selectedIcon : l.icon}
            component={RouterLink}
            to={l.to}
            value={l.to}
          />
        );
      }),
    [links, theme]
  );

  const link = useMemo(
    () => links.flatMap((l) => l.to).find((to) => pathname.startsWith(to) && to !== "/"),
    [links, pathname]
  );

  return (
    <BottomNavigation value={link} showLabels>
      {items}
    </BottomNavigation>
  );
};
