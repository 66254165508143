import * as Types from "../../generated/graphql";

export type PriceFragmentFragment = {
  __typename?: "Price";
  grossPriceInCents?: number | null;
  netPrice?: any | null;
  currency?: string | null;
};

export const PriceFragmentFragmentDoc = `
    fragment PriceFragment on Price {
  grossPriceInCents
  netPrice
  currency
}
    `;
