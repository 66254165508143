import { CameraErrors, CameraErrorsVariations } from "./id-camera-types";

export const CameraErrorsTitle = (errorType: CameraErrors) =>
  `validations__${CameraErrorsVariations[errorType]}_title`;
export const CameraErrorsHint = (errorType: CameraErrors) =>
  `validations__${CameraErrorsVariations[errorType]}_hint`;

export const mapErrorType = (errorType: CameraErrors | unknown) => {
  if (errorType === CameraErrors.NotAllowedError || errorType === CameraErrors.NotFoundError) {
    return errorType;
  }
  return CameraErrors.Error;
};
