import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import {
  Paragraph,
  ParagraphBold,
  SmallPaperCheckbox,
  useAuth
} from "@likemagic-tech/sv-magic-library";
import { DisplayCmsSvg, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { FastInput } from "../../../components/molecules/fast-input";
import { Reservation } from "../../../domain-common/reservation";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import {
  generatePortalProfileAccountCreatedUrl,
  generatePortalProfileAccountUrl,
  generatePortalProfileUrl
} from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useProfileAccountPage } from "../hooks/use-profile-account-page";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { ProfileTemplate } from "../components/profile-template";
import { AccountDeletion } from "../components/account/account-deletion";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../../user-profile/use-user-profile";
import { useUserInfo } from "../../booking-overview/hooks/use-user-info";
import { useSetPassword } from "../hooks/use-set-password";
import { useAppDispatch } from "../../../state/store";
import { openBanner } from "../../banner/banner.slice";

interface ProfileAccountPageProps {}

type FormData = {
  password?: string;
};

const formData: FormData = {
  password: ""
};

export const ProfileAccountPage: React.FC<
  React.PropsWithChildren<ProfileAccountPageProps>
> = () => {
  const { reservation } = useReservationContext();
  const { setPassword } = useSetPassword();
  const dispatch = useAppDispatch();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const navigate = useNavigate();
  const { authenticated } = useAuth();
  const { userInfo } = useUserInfo();
  const { userKeycloakUUID } = useUserProfile();

  const handleSubmit = React.useCallback(
    async (values: FormData) => {
      try {
        const valuesToPatch: Reservation = {
          ...reservation
        };

        // @ts-ignore
        valuesToPatch.extras = {
          ...valuesToPatch.extras,
          password: values.password!
        };

        valuesToPatch.extras!.passwordSet = !!values.password;

        const redirectTo = !userInfo?.email_verified
          ? generatePortalProfileAccountCreatedUrl(reservation.magicId)
          : generatePortalProfileAccountUrl(reservation.magicId);

        setPassword({ password: values.password, reservation: valuesToPatch });

        navigate(redirectTo);

        dispatch(
          openBanner({
            type: "success",
            title: tCommon("labels__set_password_success")
          })
        );
      } catch (e) {
        console.error("An error occurred account page.", e);
      }
    },
    [navigate, reservation, setPassword, userInfo?.email_verified, dispatch, tCommon]
  );

  const { linkedAccounts, accountLinkingHrefs, unlinkAccount, loadLinkedAccounts } =
    useProfileAccountPage();
  useSetupSubpageNavigation(generatePortalProfileUrl);

  const unlinkSocialAccount = useCallback(
    async (providerName: string) => {
      try {
        await unlinkAccount(providerName, userKeycloakUUID ?? "").unwrap();
        loadLinkedAccounts();
      } catch (e) {}
    },
    [unlinkAccount, loadLinkedAccounts, userKeycloakUUID]
  );

  return (
    <>
      <HelmetTitle suffix="Account" />
      <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
        <ProfileTemplate>
          <PageHeadingInfo
            title={
              authenticated && userInfo?.email_verified
                ? tCommon("title__account_page")
                : tCommon("title__new_account_page")
            }
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
          />
          {reservation.extras?.passwordSet && !authenticated && (
            <Box mt={25} px={5}>
              <Paragraph align="center">
                {tCommon("labels__account_text_waiting_for_email_verification")}
              </Paragraph>
            </Box>
          )}
          <Grid container spacing={2}>
            {!userInfo?.email_verified && !authenticated && (
              <>
                <Grid item xs={12}>
                  <Paragraph>
                    <span>{tCommon("labels__account_password_info")}</span>
                  </Paragraph>
                </Grid>
                <Grid item xs={12}>
                  <Formik initialValues={formData} onSubmit={handleSubmit}>
                    {(formik) => (
                      <Form noValidate>
                        <FastInput
                          id="password"
                          name="password"
                          autoComplete="password"
                          type="password"
                          label={tCommon("labels__password")}
                        />
                        {formik.dirty && (
                          <SubmitButton
                            label={tCommon("buttons__create")}
                            disabled={formik.isSubmitting}
                            hasBottomNavigation
                            hasWhiteBackground
                          />
                        )}
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </>
            )}

            {authenticated && linkedAccounts.length > 0 && (
              <Grid item xs={12}>
                <Paragraph>
                  <span>{tCommon("labels__link_social_account_info")}</span>
                </Paragraph>
              </Grid>
            )}
            {linkedAccounts.map((account: any) => {
              const url = new URL(accountLinkingHrefs[account.providerName]?.href);
              url.searchParams.set("redirect_uri", window.location.href);
              const linkHref = url.href;

              return (
                <Grid key={account.providerName} item xs={12} container direction="column">
                  <SmallPaperCheckbox
                    content={<ParagraphBold>{account.displayName}</ParagraphBold>}
                    checked={account.connected}
                    onChange={(event, checked) => {
                      if (checked) {
                        window.location.href = linkHref;
                      } else {
                        unlinkSocialAccount(account.providerName);
                      }
                    }}
                  />
                </Grid>
              );
            })}
            {authenticated && (
              <>
                <Grid item xs={12}>
                  <Formik
                    initialValues={formData}
                    onSubmit={async (values, { resetForm }) => {
                      await handleSubmit(values);
                      resetForm();
                    }}
                  >
                    {(formik) => (
                      <Form noValidate>
                        <FastInput
                          id="password"
                          name="password"
                          autoComplete="password"
                          type="password"
                          label={tCommon("labels__password")}
                          value={formik.values.password || ""}
                        />
                        {formik.values.password !== "" && (
                          <SubmitButton
                            hasSecondaryFixedButton
                            label={tCommon("buttons__save")}
                            disabled={formik.isSubmitting}
                            hasBottomNavigation
                            hasWhiteBackground
                          />
                        )}
                      </Form>
                    )}
                  </Formik>
                  {reservation.keycloakUUID === userKeycloakUUID && (
                    <Box mt={5}>
                      <AccountDeletion />
                    </Box>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </ProfileTemplate>
      </RoundedFullHeight>
    </>
  );
};
