import React, { FC, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { Charge } from "../../../domain-common/payment";
import { PricePreview } from "../../../components/atoms/price-preview/price-preview";
import { emptyPrice, Price } from "../../../domain-common/price";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { toGross, toNet } from "../../../domain-common/full-price";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";
import { Chip, Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { grey } from "@mui/material/colors";

interface StyleProps {
  hide: boolean;
}

const useStyles = makeStyles<StyleProps>()(({ palette }: Theme, props) => ({
  title: {
    color: palette.text.secondary,
    fontSize: 14,
    lineHeight: "17px"
  },
  color: {
    color: palette.text.primary
  },
  hide: {
    color: props.hide ? "transparent" : undefined
  },
  divider: {
    height: 3
  }
}));

interface ChargeItemProps {
  charge: Pick<
    Charge,
    "totalQuantity" | "name" | "total" | "translatedNames" | "isRent" | "subName"
  >;
  showVat: boolean;
  hide?: boolean;
  label?: string;
  rentLabels?: {
    chargedIfDamaged: string;
    rent: string;
  };
}

export const ChargeItem: FC<ChargeItemProps> = ({ charge, label, hide, showVat, rentLabels }) => {
  const { classes } = useStyles({ hide: !!hide });
  const language = getI18nSelectedLanguage().substring(0, 2).toLowerCase();
  const displayPrice: Price = useMemo(() => {
    if (hide) {
      return emptyPrice();
    }
    if (showVat) {
      return toNet(charge.total);
    }
    return toGross(charge.total);
  }, [charge, hide, showVat]);

  return (
    <Box my={2}>
      <Grid container direction="row">
        <Grid xs={8} item container direction="row" spacing={1} className={classes.title}>
          <Grid item xs={1} className={classes.color}>
            <Paragraph>{charge.totalQuantity}</Paragraph>
          </Grid>
          <Grid item xs={11} className={classes.color}>
            <Box display="inline-flex" alignItems="flex-end">
              <Paragraph mt={0.125}>
                {label
                  ? label
                  : charge.translatedNames
                    ? charge.translatedNames[language] !== undefined &&
                      charge.translatedNames[language]?.length > 0
                      ? charge.translatedNames[language]
                      : charge.name
                    : charge.name}
              </Paragraph>
              {charge.subName && (
                <Paragraph color={grey[600]} paddingLeft={0.5} whiteSpace="nowrap">
                  - {charge.subName}
                </Paragraph>
              )}
              {charge.isRent && rentLabels?.rent ? (
                <Chip sx={{ ml: 0.5 }} size="small" color="info" label={rentLabels?.rent} />
              ) : null}
            </Box>

            {charge.isRent && rentLabels?.chargedIfDamaged ? (
              <ParagraphSmall color="text.secondary">
                <span>{rentLabels?.chargedIfDamaged}</span>
              </ParagraphSmall>
            ) : null}
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Box
            textAlign="right"
            className={`${classes.color} ${classes.hide}`}
            sx={{
              textShadow: hide ? "0 0 16px #000" : "none",
              userSelect: hide ? "none" : undefined
            }}
          >
            <PricePreview
              price={{
                currency: displayPrice.currency,
                amount: charge.isRent ? 0 : displayPrice.amount
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
