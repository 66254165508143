import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useMemo } from "react";
import { PrismicRichTextWrapper, DisplayCmsSvg } from "../../../components";
import { MapLocation } from "../../../domain-common/map-location";
import { ArticleLayout } from "./article-layout";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { CalendarCheckIcon, TimeClockIcon } from "../../../components/icons";
import { MapLocationLink } from "../../../components/atoms/map-location-link/map-location-link";
import { responsiveVariables } from "../../../components/layouts/hooks/use-is-mobile";
import { ImageLimitedHeight } from "../../../domain-common/image-style";
import { RichTextField } from "@prismicio/types/src/value/richText";

export enum ContentDetailsCover {
  IMAGE,
  SVG
}

interface ContentDetailsProps {
  title: string;
  subtitle?: string;
  coverImage?: string;
  date?: string;
  time?: string;
  description: RichTextField;
  location?: MapLocation;
  type?: ContentDetailsCover;
}

const useStyles = makeStyles()(({ breakpoints, palette, spacing, shape }) => ({
  coverImage: {
    objectFit: "cover",
    display: "block",
    width: "100%",
    maxWidth: 900,
    height: 200,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "fixed",
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      position: "relative",
      borderRadius: shape.borderRadius * 2.5,
      maxWidth: `calc(100% - ${20 * 2}px)`,
      marginLeft: spacing(2.5),
      marginRight: spacing(2.5),
      height: "100%"
    }
  },
  coverSvg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
      width: "100%",
      maxWidth: 1024,
      height: 200,
      position: "fixed"
    }
  },
  dateAndTimeContainer: {
    borderTop: `1px solid ${palette.secondary.main}`,
    borderBottom: `1px solid ${palette.secondary.main}`,

    "& > *:first-of-type": {
      borderRight: `1px solid ${palette.secondary.main}`
    }
  },
  dateAndTimeParagraph: {
    padding: spacing(2),
    display: "flex"
  },
  dateAndTimeIcon: {
    marginRight: spacing(1.5),
    color: palette.text.secondary
  }
}));

export const ContentDetails: React.FC<React.PropsWithChildren<ContentDetailsProps>> = ({
  title,
  subtitle,
  coverImage,
  date,
  time,
  description,
  location,
  type = ContentDetailsCover.IMAGE
}) => {
  const { classes } = useStyles();

  const coverImageElement = useMemo(() => {
    return type === ContentDetailsCover.IMAGE ? (
      <ImageLimitedHeight src={coverImage} alt={"cover"} className={classes.coverImage} />
    ) : coverImage && ContentDetailsCover.SVG ? (
      <div className={classes.coverSvg}>
        <DisplayCmsSvg url={coverImage} style={{ fontSize: "120px" }} />
      </div>
    ) : (
      <div className={classes.coverImage} />
    );
  }, [classes, type, coverImage]);

  const content = useMemo(() => {
    return (
      <>
        {date && time && (
          <Grid container className={classes.dateAndTimeContainer}>
            <Grid item xs>
              <Paragraph className={classes.dateAndTimeParagraph}>
                <CalendarCheckIcon fontSize="inherit" className={classes.dateAndTimeIcon} />
                {date}
              </Paragraph>
            </Grid>
            <Grid item xs>
              <Paragraph className={classes.dateAndTimeParagraph}>
                <TimeClockIcon fontSize="inherit" className={classes.dateAndTimeIcon} />
                {time}
              </Paragraph>
            </Grid>
          </Grid>
        )}

        {location && <MapLocationLink location={location} />}

        {description && <PrismicRichTextWrapper render={description} />}
      </>
    );
  }, [date, description, time, classes, location]);
  return (
    <ArticleLayout
      coverImage={coverImageElement}
      title={title}
      subtitle={subtitle}
      content={content}
    />
  );
};
