import { FC, PropsWithChildren } from "react";
import { Card, CardActionArea } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { TipLink } from "./tip-link";
import { MyStayTipCard } from "../../../../domain-common/my-stay-tip-card";

interface CardWrapperProps {
  tip: MyStayTipCard;
}

const useStyles = makeStyles<{ clickable?: boolean }>()((theme, props) => ({
  root: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: props.clickable ? `0px 4px 16px ${theme.palette.grey[300]}` : "none",
      transition: props.clickable ? "box-shadow 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;" : "none"
    },
    backgroundColor: theme.palette.common.white
  },
  focusHighlight: {
    backgroundColor: "inherit"
  }
}));

export const CardWrapper: FC<PropsWithChildren<CardWrapperProps>> = ({ children, tip }) => {
  const { classes } = useStyles({ clickable: !!tip.link || !!tip.onClick });

  return (
    <TipLink tip={tip}>
      <Card sx={{ height: 250 }} classes={{ root: classes.root }}>
        <CardActionArea
          sx={{ height: 250 }}
          disableRipple
          classes={{ focusHighlight: classes.focusHighlight }}
        >
          {children}
        </CardActionArea>
      </Card>
    </TipLink>
  );
};
