import React, { FC } from "react";
import {
  PageHeadingInfo,
  PageHeadingInfoProps
} from "../../../components/molecules/page-heading-info";
import { Box } from "@mui/material";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";

export const BoxStatusSuccessfullyOpened: FC<PageHeadingInfoProps> = (props) => {
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  return (
    <>
      <PageHeadingInfo {...props} />
      <Box textAlign="center" mt={8}>
        <img src={cmsData?.data?.image__door_opened?.url} alt="Box opened" />
      </Box>
    </>
  );
};
