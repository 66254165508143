import { Theme } from "@mui/material/styles";
import React, { useCallback } from "react";
import { useOnScroll } from "../../../util/hooks/use-on-scroll";
import { makeStyles } from "tss-react/mui";

export interface ScrollScalingImageProps {
  /**
   * The `src` attribute for the `img` element.
   */
  src: string;
}

const IMG_SIZE = 200;

const useStyles = makeStyles<ScrollScalingImageProps>()((theme: Theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    width: IMG_SIZE,
    height: IMG_SIZE,
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: "6px",
    borderColor: theme.palette.background.default,
    overflow: "hidden",
    userSelect: "none",
    transformOrigin: "50% 0%",
    zIndex: 1100
  },
  img: {
    width: "100%",
    height: "100%",
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url("${props.src}")`,
    backgroundColor: theme.palette.background.paper
  }
}));

export const ScrollScalingImage: React.FC<React.PropsWithChildren<ScrollScalingImageProps>> = (
  props
) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const updateRefStyle = useCallback(
    (yPos: number) => {
      if (ref.current) {
        let scale = Math.min(Math.max(1 - yPos / IMG_SIZE, 0.5), 1.05);
        let translateY = Math.max(yPos, -8);
        ref.current.style.transform = `translateY(${translateY}px) scale(${scale})`;
        const opacity = yPos < IMG_SIZE / 2 ? 1 : 0;
        ref.current.style.opacity = "" + opacity;
      }
    },
    [ref]
  );
  useOnScroll(updateRefStyle);
  const { classes } = useStyles(props);

  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.img} />
    </div>
  );
};
