import { useSelector } from "react-redux";
import { getTotalPrice, selectCartItems, selectCartSlice } from "../store/cart.slice";
import { useEffect } from "react";
import { useAppDispatch } from "../../../state/store";

export const useShopTotalPrice = () => {
  const cartItems = useSelector(selectCartItems);
  const { totalPrice } = useSelector(selectCartSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const promise = dispatch(getTotalPrice({ cartItems }));
    return () => promise.abort();
  }, [dispatch, cartItems]);

  return { totalPrice };
};
