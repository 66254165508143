import { SvgIcon, SvgIconProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  removeFill: {
    fill: "none"
  }
});

export function MagnifierIcon(props: SvgIconProps) {
  const { classes } = useStyles();
  return (
    <SvgIcon className={classes.removeFill} {...props}>
      <path
        d="M14.7082 5.61377C16.1236 8.94432 14.5711 12.7917 11.2406 14.2072C7.91003 15.6226 4.06264 14.0701 2.64719 10.7396C1.23174 7.40901 2.78424 3.56162 6.11478 2.14618C9.44533 0.730726 13.2927 2.28322 14.7082 5.61377Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0178 13.517L19.8753 19.3753"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
