import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { selectBookingCartLength } from "../../booking-cart.slice";
import { Badge } from "@mui/material";
import { useIsMobile } from "../../../../components/layouts/hooks/use-is-mobile";

interface CartBadgeProps {
  placement: "header" | "fab";
}

interface CartBadgeStyle extends CartBadgeProps {
  isMobile: boolean;
}

const useStyles = makeStyles<CartBadgeStyle>()((theme, props) => ({
  root: {
    backgroundColor:
      props.placement === "header" && props?.isMobile
        ? theme.palette.common.black
        : theme.palette.primary.main,
    fontWeight: 700,
    color: "white"
  }
}));

export const CartBadge: React.FC<React.PropsWithChildren<CartBadgeProps>> = (props) => {
  const itemsInTheCart = useSelector(selectBookingCartLength);
  const isMobile = useIsMobile();

  const { classes } = useStyles({
    ...props,
    isMobile
  });
  return (
    <Badge badgeContent={itemsInTheCart} classes={{ badge: classes.root }}>
      {props.children}
    </Badge>
  );
};
