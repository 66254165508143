import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { CSSProperties } from "react";
import { use100vh } from "react-div-100vh";
import { RoundedFullHeightProps } from "../rounded-full-height";
import { responsiveVariables } from "./use-is-mobile";

interface UseRoundedFullHeightProps extends RoundedFullHeightProps {
  height: number;
}

function getSubtrahendHeight(theme: Theme, subtrahend?: number) {
  return parseInt(theme.spacing(subtrahend || 0));
}

function getMinHeightHelper(
  theme: Theme,
  topNavigationHeight: number,
  height: number | null,
  subtrahend?: number
) {
  const bottomNavigationHeight = theme.mixins.toolbar.minHeight as number;
  const subtrahendHeight = getSubtrahendHeight(theme, subtrahend);

  if (height) {
    return height - topNavigationHeight - bottomNavigationHeight - subtrahendHeight;
  }

  let result100vh = `calc(100vh - ${topNavigationHeight}px - ${bottomNavigationHeight}px`;
  result100vh += " - " + subtrahendHeight + "px";
  result100vh += ")";
  return result100vh;
}

function getMinHeightMinWidth600(theme: Theme) {
  return (props: UseRoundedFullHeightProps) => {
    const topNavigationHeight = (theme.mixins.toolbar[`@media (min-width:600px)`] as CSSProperties)
      .minHeight as number;

    return getMinHeightHelper(theme, topNavigationHeight, props.height, props.subtrahend);
  };
}

function getMinHeight(theme: Theme) {
  return (props: UseRoundedFullHeightProps) => {
    const topNavigationHeight = theme.mixins.toolbar.minHeight as number;

    return getMinHeightHelper(theme, topNavigationHeight, props.height, props.subtrahend);
  };
}

const useStyles = makeStyles<UseRoundedFullHeightProps>()((theme, props) => {
  return {
    box: {
      paddingTop: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      minHeight: "auto",
      [theme.breakpoints.down("md")]: {
        minHeight: getMinHeightMinWidth600(theme)(props)
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: getMinHeight(theme)(props)
      },
      [theme.breakpoints.up(responsiveVariables.firstDesktopSize)]: {
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    }
  };
});

export const useRoundedFullHeightStyles = (props: RoundedFullHeightProps) => {
  const height = use100vh();
  const propsForStyle: UseRoundedFullHeightProps = {
    ...props,
    height: height || 0
  };
  return useStyles(propsForStyle);
};
