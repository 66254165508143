import React, { FC, useCallback, useEffect, useMemo } from "react";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { useAppDispatch } from "../../../state/store";
import { Box } from "@mui/material";
import { usePropertyById } from "../../property/use-property-by-id";
import { Heading2 } from "@likemagic-tech/sv-magic-library";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import {
  generateBookingOverviewPagesUrl,
  generateInitBookingOverviewUrl
} from "../booking-overview-navigation";
import { SubmitButton } from "../../../components";
import { BookingOverviewPages } from "../booking-overview-pages";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useLocation, useNavigate } from "react-router-dom";
import { openBanner } from "../../banner/banner.slice";
import { isReservationPayable } from "../../../util/reservation";
import { BookingOverviewReservationChip } from "../components/booking-overview-reservation-chip";
import { BookingOverviewItemWrapper } from "../components/booking-overview-item-wrapper";
import { ReservationItemBody } from "../components/reservation-item-body";
import { ArrowRight1Icon } from "../../../components/icons";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useFetchAllBookerOverview } from "../hooks/use-fetch-all-booker-overview";
import { NotFoundPage } from "../../../components/organism/not-found.page";
import { isStatusSuccess } from "../../../state/EntityStateStatus";
import { Actor } from "../../../domain-common/booking-overview";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { useGetBookingOverviewMagicId } from "../booking-overview-subscription-provider";

export const BookingOverviewReservationsPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { magicId: magicIdFromUrl, bookingOverviewItemId } = useMagicIdParams();
  const magicId = useGetBookingOverviewMagicId({
    magicIdFromUrl: magicIdFromUrl
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { payFromBookingOverviewDisabled } = useFeatureFlags();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const {
    getBookingOverviewByBookingOverviewItemId,
    getBookingOverviewItemById,
    bookingOverviewActiveStatus
  } = useFetchAllBookerOverview({ magicId });

  const inviteLabel = useMemo(
    () =>
      t("labels__invitation_sent", {
        fullName: `${state?.firstName} ${state?.lastName}`
      }),
    [t, state?.firstName, state?.lastName]
  );

  useEffect(() => {
    if (state?.successfulInvite) {
      dispatch(
        openBanner({
          type: "success",
          title: inviteLabel
        })
      );
    }
  }, [state?.successfulInvite, dispatch, inviteLabel]);

  const bookingOverviewItem = getBookingOverviewItemById(bookingOverviewItemId);
  const bookerOverview = getBookingOverviewByBookingOverviewItemId(bookingOverviewItemId);

  // INFO: Actor needs to be Booker in order to pay, or if its Primary guest,
  // they should not be logged it in order to pay
  // or if its Primary guest, they should not be logged it in order to pay.
  // To be able to pay in case of primary guest.
  // The reservation magic id requested the booking overview must be the same reservation
  // the primary want's to pay for, otherwise payment fails.
  // And when the actor of the booking overview is a primary guest with an account,
  // he may sees other reservation which are part of a different booking.
  const isBookerOrNotLoggedIn =
    bookerOverview?.actor === Actor.BOOKER || !bookerOverview?.keycloakUUID;

  const reservationsToDisplay = useMemo(
    () =>
      bookingOverviewItem
        ? [...bookingOverviewItem?.reservations, ...bookingOverviewItem?.travelBuddies]
        : [],
    [bookingOverviewItem]
  );

  const payable = useMemo(() => {
    const payableReservations = bookingOverviewItem?.reservations.some((reservation) =>
      isReservationPayable(reservation)
    );
    return payableReservations !== undefined && payableReservations;
  }, [bookingOverviewItem?.reservations]);

  const property = usePropertyById(bookingOverviewItem?.propertyId);
  const openReservation = useCallback(
    (reservationId: string) =>
      generateBookingOverviewPagesUrl(
        BookingOverviewPages.BOOKING_RESERVATION_DETAILS,
        magicId,
        bookingOverviewItemId,
        reservationId
      ),
    [bookingOverviewItemId, magicId]
  );

  const goBack = useCallback(
    () => navigate(generateInitBookingOverviewUrl(magicId)),
    [magicId, navigate]
  );

  if (!bookingOverviewItem && isStatusSuccess(bookingOverviewActiveStatus)) {
    return <NotFoundPage />;
  }

  return (
    <FlowTemplate handleBack={goBack}>
      <HelmetTitle suffix="Reservations" />
      <Box px={2.5}>
        <Heading2>{property.name}</Heading2>

        <Box mt={2}>
          {reservationsToDisplay.map((reservation) => (
            <BookingOverviewItemWrapper
              key={reservation.magicId}
              onClick={() => navigate(openReservation(reservation.magicId))}
              highlighted={reservation.magicId === magicId}
              icon={<ArrowRight1Icon fontSize="inherit" />}
            >
              <ReservationItemBody reservation={reservation}>
                <BookingOverviewReservationChip
                  reservation={reservation}
                  actor={bookerOverview?.actor}
                />
              </ReservationItemBody>
            </BookingOverviewItemWrapper>
          ))}
        </Box>
        {payable &&
          bookingOverviewItem?.active &&
          isBookerOrNotLoggedIn &&
          !payFromBookingOverviewDisabled && (
            <SubmitButton
              hasBottomNavigation
              hasWhiteBackground
              variant="secondary"
              label={t("buttons__pay")}
              onClick={() =>
                navigate(
                  generateBookingOverviewPagesUrl(
                    BookingOverviewPages.CHECKOUT,
                    magicId,
                    bookingOverviewItemId
                  )
                )
              }
            />
          )}
      </Box>
    </FlowTemplate>
  );
};
