export interface Company {
  name: string;
  taxId: string;
  canCheckOutOnAr?: boolean;
}

export const CompanyFromJSON = (json: any): Company => {
  if (!json) {
    return json;
  }

  return {
    name: json["name"],
    taxId: json["taxId"],
    canCheckOutOnAr: json["canCheckoutOnAr"]
  };
};
