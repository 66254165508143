import React, { FC, useCallback, useMemo } from "react";
import { Box, Divider } from "@mui/material";
import { TotalPrice } from "../../molecules/total-price";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { RateBreakdown } from "../../../domain-common/grouped-rate-breakdown";
import { Charge } from "../../../domain-common/payment";
import { ChargeItem } from "../../../features/guest-flow/components/charge-item";
import { sortCharges } from "../../../util/reservation";
import { useHidePaymentConfig } from "../../../util/hooks/use-configuration";
import { sumFullPrices } from "../../atoms/price-preview/price-preview";
import { toGross } from "../../../domain-common/full-price";
import { PrepaymentType } from "../../../domain-common/folio";
import { Notification } from "../../atoms";

interface RateBreakdownPreviewProps {
  groupedRateBreakdown: RateBreakdown;
  additionalCharges?: Charge[];
}

export const RateBreakdownPreview: FC<RateBreakdownPreviewProps> = ({
  groupedRateBreakdown,
  additionalCharges
}) => {
  const { t: tCommon, exists } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const hidePrepaymentConfig = useHidePaymentConfig();

  const breakdownItems = useMemo(() => {
    return groupedRateBreakdown.breakdownItems
      .map((groupedRateBreakdownItem) => groupedRateBreakdownItem)
      .flatMap((item) => item.items);
  }, [groupedRateBreakdown]);

  const breakdownItemsToCharge: Charge[] = breakdownItems.map((item, index) => {
    return {
      name: item.name,
      totalQuantity: item.quantity,
      total: {
        netAmount: item.price.amount,
        currency: item.price.currency,
        grossAmount: item.price.amount
      },
      serviceId: item.type,
      folioId: `${item.type}-${index}`,
      translatedNames: undefined,
      prepayable: item.prepaid,
      hide: item.hide
    };
  });

  const existingChargesWithAdditionalCharges =
    groupedRateBreakdown.id === "ACCOMMODATION" && additionalCharges
      ? [...breakdownItemsToCharge, ...additionalCharges]
      : breakdownItemsToCharge;

  const sortedCharges = existingChargesWithAdditionalCharges.sort(sortCharges);

  const totalCharges = useMemo(() => {
    const prices = sortedCharges.flatMap((item) => item.total);
    return sumFullPrices(prices);
  }, [sortedCharges]);

  const mapTitle = useCallback(
    (id: string) => {
      switch (id) {
        case "ACCOMMODATION":
          return tCommon("labels__accommodations");
        case "SERVICE":
          return tCommon("labels__additions");
        default:
          return id;
      }
    },
    [tCommon]
  );

  const prepaymentTypeDifferentThenNone = useMemo(() => {
    return breakdownItems.find((item) => item.prepaymentType !== PrepaymentType.NONE);
  }, [breakdownItems])?.prepaymentType;

  return (
    <Box key={groupedRateBreakdown.id}>
      <Heading4>{mapTitle(groupedRateBreakdown.id)}</Heading4>

      {prepaymentTypeDifferentThenNone &&
        exists("labels__folio_prepayment_" + prepaymentTypeDifferentThenNone) && (
          <Box pt={2}>
            <Notification
              type="warning"
              title={tCommon("labels__folio_prepayment_" + prepaymentTypeDifferentThenNone)}
            />
          </Box>
        )}

      <Box mt={1.5}>
        {sortedCharges.map((item, index) => (
          <ChargeItem
            showVat={false}
            key={`key-${item.name}-${index}`}
            charge={item}
            hide={hidePrepaymentConfig && item.hide}
          />
        ))}
        <Box my={1.5}>
          <Divider />
        </Box>
      </Box>
      <TotalPrice
        price={toGross(totalCharges)}
        title={tCommon("labels__total_amount")}
        titleGray={true}
        priceBold={false}
        priceGray={true}
      />
      <Box my={1.5}>
        <Divider />
      </Box>
    </Box>
  );
};
