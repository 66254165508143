import React, { FC } from "react";
import { DesktopCard, DisplayCmsSvg } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { BoxShopEventHandler } from "../box-shop-event-handler";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { BoxOpenMode } from "../box-shop-events";
import { useParams } from "react-router-dom";
import { useFetchBoxAvailabilityById } from "../hooks/use-fetch-box-availability-by-id";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { BoxType } from "../../../domain-v1/box-availability";

export const BoxShopPaymentSuccessPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { reservation } = useReservationContext();

  const { deviceId } = useParams<{
    deviceId: string;
  }>();

  const boxDetails = useFetchBoxAvailabilityById({
    boxId: deviceId ?? "",
    magicId: reservation.magicId,
    magicToken: reservation.magicToken
  });

  const boxTypeWord = (type: BoxType) => {
    if (type) {
      switch (type) {
        case BoxType.BUY:
        case BoxType.BUY_CONSUMABLES:
          return t("labels__box_shop_success_buy");
        case BoxType.BUY_FREE:
          return t("labels__box_shop_success_book");
        case BoxType.RENT:
          return t("labels__box_shop_success_rent");
      }
    }
  };

  return (
    <FlowTemplate>
      <RoundedFullHeight px={2.5}>
        <DesktopCard>
          {boxDetails !== undefined && (
            <BoxShopEventHandler
              boxDetails={boxDetails}
              mode={BoxOpenMode.GUEST_BOUGHT}
              idleInfos={{
                icon: <DisplayCmsSvg url={cmsData?.data?.icon__get_item_from_box?.url} />,
                title: t("title__unlock_box_to_get_item", {
                  typeBox: boxTypeWord(boxDetails?.type) ?? ""
                })
              }}
              successOpenedInfos={{
                title: t("title__box_door_successfully_opened"),
                icon: <DisplayCmsSvg url={cmsData?.data?.icon__please_close_box?.url} />
              }}
              successClosedInfos={{
                text: t("confirmations__box_shop")
              }}
            />
          )}
        </DesktopCard>
      </RoundedFullHeight>
    </FlowTemplate>
  );
};
