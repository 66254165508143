import { useCmsPropertySpecificData } from "../cms/use-cms-per-property-data";
import { useMemo } from "react";
import { PrismicImage } from "./use-unit-group-cms-data";

interface LocationsList {
  icon: { alt: string; url: string };
  description: string;
}

export interface PropertyCardItem {
  image: PrismicImage;
  card_item_type: string;
}

export const usePropertyCmsData = (propertyId?: string) => {
  const cmsData = useCmsPropertySpecificData(propertyId);

  const propertyImages: Array<PrismicImage> = useMemo(
    () => cmsData?.data?.main__property_images?.map((item: any) => item?.image) || [],
    [cmsData?.data?.main__property_images]
  );

  const propertyFeatures = useMemo(
    () =>
      cmsData?.data?.main__features.map((item: any) => ({
        label: item.title,
        iconUrl: item.icon.url,
        dialogTitle: item.title,
        dialogText: item.description,
        size: {
          width: item.icon.dimensions?.width,
          height: item.icon.dimensions?.height
        }
      })),
    [cmsData?.data?.main__features]
  );

  const address = useMemo(
    () => ({
      location: {
        latitude: cmsData?.data?.address__geo_point?.latitude,
        longitude: cmsData?.data?.address__geo_point?.longitude,
        mapPin: cmsData?.data?.address__map_pin?.url
      },
      locationsList: cmsData?.data?.address__locations as LocationsList[]
    }),
    [cmsData?.data]
  );
  const propertyFallbackImage: PrismicImage | undefined =
    cmsData?.data?.["main__fallback_property_image"];

  const host = useMemo(
    () => ({
      name: cmsData?.data?.main__host_name,
      description: cmsData?.data?.main__host_rich_text_description,
      picture: cmsData?.data?.main__host_picture
    }),
    [cmsData?.data]
  );

  const propertyFirstImage: PrismicImage | undefined = useMemo(
    () => (propertyImages && propertyImages.length ? propertyImages[0] : propertyFallbackImage),

    [propertyImages, propertyFallbackImage]
  );

  const propertyDescription = useMemo(
    () => cmsData?.data?.main__property_rich_text_description,
    [cmsData?.data]
  );

  const propertyName: string = cmsData?.data?.["main__property_name"];
  const propertyLocation: string = cmsData?.data?.["main__property_location"];

  const propertyAddress: string = cmsData?.data?.["main__property_address"];

  const propertyLocationIcon: PrismicImage = cmsData?.data?.["main__property_pin_address_image"];

  const propertyCardItems: Array<PropertyCardItem> = useMemo(
    () => cmsData?.data?.main__property_card_items,
    [cmsData?.data?.main__property_card_items]
  );

  return {
    cmsData,
    propertyImages,
    propertyFirstImage,
    propertyFeatures,
    host,
    address,
    propertyDescription,
    propertyName,
    propertyLocation,
    propertyLocationIcon,
    propertyAddress,
    propertyCardItems
  };
};
