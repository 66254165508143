import { graphqlApi } from "../graphql.api";
import { createClient } from "./subscribe.utils";
import {
  StreamSecondScreenDeviceEventsDocument,
  StreamSecondScreenDeviceEventsSubscription
} from "./subscribe-second-screen-device-events.generated";
import { secondScreenHomeSlice } from "../../features/second-screen-home-page/second-screen-home.slice";
import { RootState } from "../../state/store";
import { SecondScreenEventType } from "../generated/graphql";
import { api as SecondScreenActionApi } from "../mutations/second-screen-action.generated";
import { getSecondScreenId } from "../../util/second-screen-mode";

interface StreamSecondScreenDeviceEventsProps {
  apiKey?: string;
  tenantId: string;
  deviceId: string;
  handleResponse: (response: { errors?: Array<{ message: string }>; data: any }) => void;
}

export const subscribeSecondScreenDeviceEventsApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SubscribeSecondScreenDeviceEvents: build.query<any, StreamSecondScreenDeviceEventsProps>({
      query: (arg) => ({
        document: StreamSecondScreenDeviceEventsDocument,
        variables: {
          deviceId: arg.deviceId
        }
      }),
      providesTags: [],
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, dispatch, getState }) {
        const client = createClient(arg);
        try {
          await cacheDataLoaded;
          const onNext = (value: { data: StreamSecondScreenDeviceEventsSubscription }) => {
            const state = getState() as RootState;
            const reservation = state[secondScreenHomeSlice.name].reservation;

            if (
              value.data.StreamSecondScreenDeviceEvents?.type ===
                SecondScreenEventType.VerifyAndSign &&
              value.data.StreamSecondScreenDeviceEvents.__typename === "VerifyAndSign" &&
              reservation
            ) {
              dispatch(
                SecondScreenActionApi.endpoints.SecondScreenAction.initiate({
                  eventType: SecondScreenEventType.VerifyAndSignAck,
                  magicId: value.data.StreamSecondScreenDeviceEvents.payload.reservation.magicId,
                  magicToken:
                    value.data.StreamSecondScreenDeviceEvents.payload.reservation.magicToken,
                  deviceId: getSecondScreenId(),
                  payload: {
                    accepted: false,
                    reason: "Already occupied"
                  }
                })
              );
            } else if (
              value.data.StreamSecondScreenDeviceEvents?.type ===
                SecondScreenEventType.VerifyBill &&
              value.data.StreamSecondScreenDeviceEvents.__typename === "VerifyBill" &&
              reservation
            ) {
              dispatch(
                SecondScreenActionApi.endpoints.SecondScreenAction.initiate({
                  eventType: SecondScreenEventType.VerifyBillAck,
                  magicId: value.data.StreamSecondScreenDeviceEvents.payload.reservation.magicId,
                  magicToken:
                    value.data.StreamSecondScreenDeviceEvents.payload.reservation.magicToken,
                  deviceId: getSecondScreenId(),
                  payload: {
                    accepted: false,
                    reason: "Already occupied"
                  }
                })
              );
            } else {
              arg.handleResponse(value);
            }
          };

          // notify server that the socket closed
          client.on("closed", (event: unknown) => {
            console.warn("Second screen subscription has been closed with event:", event);
            window.location.reload();
          });

          new Promise<void>((resolve, reject) => {
            client.subscribe(
              {
                query: StreamSecondScreenDeviceEventsDocument,
                operationName: "StreamSecondScreenDeviceEvents",
                variables: { deviceId: arg.deviceId }
              },
              {
                next: onNext,
                error: reject,
                complete: resolve
              }
            );
          });
        } catch (e) {
          console.error("WS subscription for V2 failed", e);
          client.dispose();
        }
        await cacheEntryRemoved;
        client.dispose();
      }
    })
  }),

  overrideExisting: false
});
