import { Box, Divider, Grid, List } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Paragraph, ParagraphBold, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import {
  DisplayCmsSvg,
  SubmitButton,
  NotificationChip,
  NotificationChipType
} from "../../../components";
import { AddIcon } from "../../../components/icons";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { TravelBuddy } from "../../../domain-common/travel-buddy";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generateContactPageUrl, generatePortalMyStayUrl } from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { ConfirmationDialogLabels } from "../../../components/atoms/dialog/confirmation-dialog";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { getYesNoModalArg } from "../../global-modal/global-modal-util";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { ProfileTemplate } from "../components/profile-template";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles()({
  textButton: {
    paddingLeft: 0,
    textDecoration: "none"
  },
  addIcon: {
    fontSize: 14,
    fontWeight: "bold"
  },
  deleteIcon: {
    fontSize: 14,
    fontWeight: "bold",
    transform: "rotate(45deg)"
  }
});

interface MyStayBuddyPageProps {}

export const MyStayBuddyPage: React.FC<React.PropsWithChildren<MyStayBuddyPageProps>> = () => {
  const { reservation } = useReservationContext();
  const { classes } = useStyles();
  const navigate = useNavigate();
  useSetupSubpageNavigation(generatePortalMyStayUrl);
  const { patchGuestFlowStep } = useGuestFlow();
  const { open: openModal } = useGlobalModal();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const createBuddy = useCallback(() => {
    navigate(`${generatePortalMyStayUrl(reservation.magicId)}/buddy/add`);
  }, [navigate, reservation]);

  const editBuddy = useCallback(
    (buddyIndex: number) => {
      navigate(`${generatePortalMyStayUrl(reservation.magicId)}/buddy/${buddyIndex}/edit`);
    },
    [navigate, reservation]
  );
  const dialogLabels: ConfirmationDialogLabels = React.useMemo(
    () => ({
      title: tCommon("modals__delete_buddy_dialog_title"),
      text: tCommon("modals__delete_buddy_dialog_text"),
      confirm: tCommon("buttons__yes"),
      deny: tCommon("buttons__no")
    }),
    [tCommon]
  );
  const deleteTravelBuddy = useCallback(
    (buddyIndexToBeDeleted: number) => async () => {
      const modalResult: boolean | null = await openModal(
        getYesNoModalArg(
          dialogLabels.title,
          dialogLabels.text,
          dialogLabels.confirm,
          dialogLabels.deny
        )
      );
      if (modalResult) {
        await patchGuestFlowStep({
          reservationValues: {
            ...reservation,
            additionalGuests: reservation.additionalGuests.filter(
              (_, index) => index !== buddyIndexToBeDeleted
            )
          },
          subUpdate: SubUpdate.PERSONAL_DATA_FOR_SECONDARY_GUESTS
        });
      }
    },
    [openModal, dialogLabels, patchGuestFlowStep, reservation]
  );

  const controlLabels = React.useMemo(
    () => ({
      heading: tCommon("labels__travel_buddy"),
      delete: tCommon("buttons__remove"),
      add: tCommon("buttons__add_buddy")
    }),
    [tCommon]
  );

  const isGuestCheckedOut = (): boolean => {
    return reservation.status === ReservationStatus.CHECKED_OUT;
  };

  return (
    <>
      <HelmetTitle suffix="Buddy list" />
      <RoundedFullHeight px={2}>
        <ProfileTemplate>
          <Grid
            container
            px={2.5}
            py={2}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Grid item maxWidth="100%">
              <PageHeadingInfo
                title={tCommon("title__travel_buddy_page")}
                subtitle={
                  reservation.additionalGuests.length === 0
                    ? tCommon("subtitle__for_travel_buddy_page_no_travel_buddies_yet")
                    : tCommon("subtitle__travel_buddy_page")
                }
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
              />
            </Grid>
          </Grid>

          {reservation.additionalGuests.length !== 0 && (
            <List component="nav" disablePadding>
              <Divider />
              {reservation.travelBuddies?.map((travelBuddy, index) => (
                <React.Fragment key={`buddy-${travelBuddy.magicId}-${index}`}>
                  <ExistingBuddy
                    travelBuddy={travelBuddy}
                    deleteBuddy={deleteTravelBuddy(travelBuddy.travelBuddyIndex)}
                    editBuddy={() => editBuddy(travelBuddy.travelBuddyIndex)}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
          {isGuestCheckedOut() &&
          reservation.maxCompanions > reservation.additionalGuests.length ? (
            <>
              <Box px={2.5} mt={7}>
                <Paragraph align={"center"}>{tCommon("labels__buddy_contact_us")}</Paragraph>
              </Box>
              <SubmitButton
                label={tCommon("buttons__contact")}
                onClick={() => navigate(`${generateContactPageUrl()}`)}
                hasBottomNavigation
                hasWhiteBackground
              />
            </>
          ) : (
            reservation.additionalGuests.length < reservation.maxCompanions && (
              <Box mt={2}>
                <Button variant="ghost" className={classes.textButton} onClick={createBuddy}>
                  <AddIcon className={classes.addIcon} />
                  <Box ml={1.5}>{controlLabels.add}</Box>
                </Button>
              </Box>
            )
          )}
        </ProfileTemplate>
      </RoundedFullHeight>
    </>
  );
};

const ExistingBuddy: React.FC<
  React.PropsWithChildren<{
    travelBuddy: TravelBuddy;
    deleteBuddy: () => void;
    editBuddy: () => void;
  }>
> = ({ travelBuddy, deleteBuddy, editBuddy }) => {
  const { reservation } = useReservationContext();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const isGuestCheckedOut = (): boolean => {
    return reservation.status === ReservationStatus.CHECKED_OUT;
  };

  const notificationChip = (
    <NotificationChip
      type={
        travelBuddy.flowState.notificationSent
          ? NotificationChipType.Success
          : NotificationChipType.Error
      }
      label={t(
        travelBuddy?.flowState.notificationSent
          ? "chip__reservation_invitation_sent"
          : "chip__reservation_invitation_not_sent"
      )}
    />
  );

  const name = (): string => {
    return [travelBuddy.travelBuddy.firstName, travelBuddy.travelBuddy.lastName].join(" ");
  };

  return (
    <>
      <Grid
        container
        py={2}
        flexDirection="row"
        alignItems="center"
        onClick={editBuddy}
        sx={{ cursor: "pointer" }}
      >
        {!isGuestCheckedOut() && (
          <Grid item xs={1} paddingTop={0.5}>
            <EditIcon />
          </Grid>
        )}
        <Grid item xs={10}>
          <Grid container alignItems="center" flexDirection="row">
            <Grid item xs={6}>
              <ParagraphBold sx={{ overflowWrap: "break-word" }}>{name()}</ParagraphBold>
            </Grid>
            <Grid item xs={6}>
              {notificationChip}
            </Grid>
          </Grid>
        </Grid>
        {!isGuestCheckedOut() && (
          <Grid item xs={1}>
            <Grid container alignItems="center" justifyContent="flex-end">
              <DeleteIcon
                onClick={(e) => {
                  deleteBuddy();
                  e.stopPropagation();
                }}
                fontSize="medium"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
