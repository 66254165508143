import { useEffect } from "react";
import { getKioskId, isKioskMode } from "../../util/kiosk-mode";
import { useIdleTimer } from "react-idle-timer";
import { useLocation, useNavigate } from "react-router-dom";
import fetchIntercept from "fetch-intercept";
import { getTagReaderId } from "../../util/tag-reader-id";

const LANDING_PAGE = "/";
const TIMEOUT_TIME = 1000 * 60 * 2;

export const KioskIdle = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { getRemainingTime } = useIdleTimer({
    timeout: TIMEOUT_TIME,
    onIdle: () => {
      if (isKioskMode() && getRemainingTime() <= 0 && location.pathname !== LANDING_PAGE) {
        navigate("/logout");
      }
    },
    debounce: 500
  });

  useEffect(() => {
    if (isKioskMode()) {
      fetchIntercept.register({
        request: function (url, config) {
          if (url.indexOf("/") !== 0) {
            return [url, config]; // This is not relative link - reaches outside world
          }
          return [
            url,
            {
              ...config,
              headers: {
                ...config.headers,
                ...(getTagReaderId() && {
                  "sk-magic-kiosk-terminal-id": getTagReaderId()
                }),
                ...(getKioskId() && {
                  "sk-magic-kiosk-id": getKioskId()
                })
              }
            }
          ];
        }
      });
    }
  }, []);

  return null;
};
