import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../state/store";
import { useShopCmsTranslation } from "../../../state/shop-cms/use-shop-cms-translation";
import { Box, Grid } from "@mui/material";
import { AddToCartDialog } from "../../shop/components/add-to-cart-dialog";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { AdditionalServiceButton } from "../../../components";
import { ShopType } from "../../../graphql/generated/graphql";
import {
  addToDynamicShopCart,
  DynamicShopCartItem,
  getDynamicShopCartItemId,
  removeFromDynamicShopCart,
  selectByIdFromDynamicShopCart
} from "../dynamic-shop.slice";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { multiply, toGross } from "../../../domain-common/full-price";

interface DynamicShopItemProps {
  item: DynamicShopCartItem;
  propertyId: string;
  shopType: ShopType;
}

export const DynamicShopItem: FC<DynamicShopItemProps> = ({ item, propertyId, shopType }) => {
  const itemFromMinibarCart = useSelector<RootState, DynamicShopCartItem | undefined>((state) =>
    selectByIdFromDynamicShopCart(state, getDynamicShopCartItemId(item))
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleAddToCartClose = (arg?: { count: number }) => {
    if (arg?.count && item) {
      dispatch(
        addToDynamicShopCart({
          serviceId: item.serviceId,
          fullPrice: item.fullPrice,
          quantity: arg.count,
          shopType,
          isRent: item.isRent
        })
      );
    } else {
      dispatch(removeFromDynamicShopCart(getDynamicShopCartItemId(item)));
    }

    setIsModalOpen(false);
  };

  const { findItem } = useShopCmsTranslation(propertyId, shopType);

  const cmsItem = findItem(item.serviceId);

  return (
    <Grid item xs container direction="column">
      <AdditionalServiceButton
        price={item.fullPrice}
        serviceName={cmsItem?.item_title ?? ""}
        icon={
          <Box textAlign="center">
            {cmsItem?.item_image?.url ? (
              <img
                width="64px"
                height="64px"
                src={cmsItem?.item_image?.url}
                alt={cmsItem?.item_image.alt}
              />
            ) : undefined}
          </Box>
        }
        quantity={itemFromMinibarCart?.quantity ?? 0}
        onClick={() => {
          setIsModalOpen(true);
        }}
        isRent={item.isRent}
        rentLabels={{
          rent: t("labels__box_shop_for_rent"),
          chargedIfDamaged: t("labels__rent_charge_explanation", {
            price: formatPriceToString(
              toGross(multiply(item.fullPrice, itemFromMinibarCart?.quantity ?? 1))
            )
          })
        }}
      />
      {isModalOpen && (
        <AddToCartDialog
          product={item ? { id: item?.serviceId, fullPrice: item.fullPrice } : null}
          onClose={handleAddToCartClose}
          primaryButtonLabel={
            itemFromMinibarCart?.quantity ? t("buttons__update") : t("buttons__add")
          }
          secondaryButtonLabel={t("buttons__remove")}
          image={cmsItem?.item_image.url}
          fallbackImage={"productFallbackImage"}
          title={cmsItem?.item_title ?? ""}
          initialCounter={itemFromMinibarCart?.quantity ? itemFromMinibarCart?.quantity : 1}
        />
      )}
    </Grid>
  );
};
