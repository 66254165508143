import React, { useEffect, useState } from "react";
import { initI18n } from "../config/i18n/i18n-next-config";
import { RootStore, useAppDispatch } from "../state/store";
import { defaultLanguageWithLocale, getLanguageWithDefault } from "../util/lang-utils";
import { useCmsClient, useCmsCommonClient } from "../api/cms-client/use-cms-client";
import { useConfigBasedOnlyOnBaseUrl } from "../util/hooks/use-configuration";
import { useStore } from "react-redux";

export const InitI18n: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const dispatch = useAppDispatch();
  const { toneOfVoice, allowedLocales } = useConfigBasedOnlyOnBaseUrl();
  const cmsClient = useCmsClient();
  const cmsCommonClient = useCmsCommonClient();
  const store: RootStore = useStore();

  useEffect(() => {
    if (cmsClient && allowedLocales?.length && store) {
      const queryLanguage = new URLSearchParams(window.location.search).get("lng") ?? undefined;
      initI18n({
        store,
        dispatch,
        prismic: cmsClient,
        commonPrismic: cmsCommonClient,
        commonPrismicType: toneOfVoice,
        fallbackLanguage: allowedLocales?.[0] || defaultLanguageWithLocale,
        allowedLocales,
        queryLanguage: getLanguageWithDefault(queryLanguage, allowedLocales)
      }).then(() => setInitialized(true));
    }
  }, [dispatch, cmsClient, cmsCommonClient, toneOfVoice, allowedLocales, store]);

  if (!initialized) {
    return null;
  }

  return <>{children}</>;
};
