import { Actor } from "../../domain-common/booking-overview";
import * as Types from "../generated/graphql";
import {
  Actor as ActorV2,
  BookingOnBehalfOf as BookingOnBehalfOfV2,
  CommunicationChannel as CommunicationChannelV2,
  IdCheckStatus as IdCheckStatusV2,
  PrepaymentType as PrepaymentTypeV2,
  ReservationStatus as ReservationStatusV2
} from "../generated/graphql";
import { BookingOnBehalfOf, Person, PersonGender } from "../../domain-common/person";
import { ReservationStatus } from "../../domain-common/reservation-status";
import { CommunicationChannel } from "@likemagic-tech/sv-magic-library";
import {
  ReservationPrimaryGuestInputV2,
  ReservationSecondaryGuestInputV2
} from "./transform-magic-object";
import { Guest } from "../../domain-common/guest";
import { defaultLanguageWithLocale } from "../../util/lang-utils";
import { IdCheckStatus } from "../../domain-common/id-check-status";
import { PrepaymentType } from "../../domain-common/folio";
import { transformPhone } from "./transform-reservation";

export const transformPersonV2ToCommonPerson = (
  v2person?: ReservationPrimaryGuestInputV2 | null
): Person => ({
  id: v2person?.pmsId,
  createdAt: "string",
  updatedAt: "string",
  firstName: v2person?.firstName ?? "",
  lastName: v2person?.lastName ?? "",
  birthdate: v2person?.birthdate,
  email: v2person?.email ?? "",
  phone: transformPhone(v2person?.phone ?? "", v2person?.address?.countryCode ?? ""),
  title: "",
  gender: transformGenderV2ToGenderCommon(v2person?.gender),
  preferredLocale: v2person?.preferredLanguage ?? defaultLanguageWithLocale,
  identificationType: "",
  identificationNumber: v2person?.identificationDocumentNumber ?? "",
  nationalityCountryCode: v2person?.nationality ?? "",
  address: {
    addressLine1: v2person?.address?.addressLine1 ?? "",
    addressLine2: v2person?.address?.addressLine2 ?? "",
    postalCode: v2person?.address?.postalCode ?? "",
    city: v2person?.address?.city ?? "",
    countryCode: v2person?.address?.countryCode ?? "",
    region: v2person?.address?.region ?? ""
  },
  company: "",
  guestIdentityId: "",
  enrolledInLoyaltyProgram: v2person?.enrolledInLoyaltyProgram ?? false,
  emergencyEvacuationHelpNeeded: v2person?.emergencyEvacuationHelpNeeded ?? false,
  minor: false
});
export const transformPersonV2ToCommonGuest = (
  v2person?: ReservationPrimaryGuestInputV2 | null
): Guest => {
  return {
    birthdate: v2person?.birthdate ?? undefined,
    firstName: v2person?.firstName ?? "",
    gender: transformGenderV2ToGenderCommon(v2person?.gender),
    lastName: v2person?.lastName ?? "",
    email: v2person?.email ?? "",
    phone: v2person?.phone ?? ""
  };
};
export const transformSecondaryGuestsV2ToCommonGuest = (
  v2person?: ReservationSecondaryGuestInputV2 | null
): Guest[] => {
  return (
    v2person?.map((item) => ({
      firstName: item?.firstName ?? "",
      gender: transformGenderV2ToGenderCommon(item?.gender),
      lastName: item?.lastName ?? "",
      email: item?.email ?? "",
      phone: transformPhone(item?.phone ?? ""),
      id: item?.pmsId ?? ""
    })) ?? []
  );
};
export const transformCommonPersonToPersonV2 = (
  person: Person
): Partial<ReservationPrimaryGuestInputV2> => {
  return {
    firstName: person.firstName,
    lastName: person.lastName,
    email: person.email,
    phone: transformPhone(person.phone, person?.address?.countryCode ?? ""),
    gender: transformGenderCommonToGenderV2(person.gender)
  };
};

export const transformStatusV2ToCommon = (
  status?: ReservationStatusV2 | null
): ReservationStatus => {
  switch (status) {
    case ReservationStatusV2.Cancelled:
      return ReservationStatus.DELETED;
    case ReservationStatusV2.NoShow:
      return ReservationStatus.NO_SHOW;
    case ReservationStatusV2.CheckedOut:
      return ReservationStatus.CHECKED_OUT;
    case ReservationStatusV2.Confirmed:
      return ReservationStatus.CONFIRMED;
    case ReservationStatusV2.InHouse:
      return ReservationStatus.IN_HOUSE;
  }

  throw Error("No status provided");
};

export const transformActorV2ToCommon = (actor?: ActorV2 | null): Actor => {
  switch (actor) {
    case ActorV2.Booker:
      return Actor.BOOKER;
    case ActorV2.PrimaryGuest:
      return Actor.PRIMARY_GUEST;
    case ActorV2.TravelBuddy:
      return Actor.TRAVEL_BUDDY;
  }

  throw Error("No status provided");
};

export const transformCommunicationChannelV2ToCommon = (
  channel?: CommunicationChannelV2 | null
): CommunicationChannel => {
  switch (channel) {
    case CommunicationChannelV2.Email:
      return CommunicationChannel.EMAIL;
    case CommunicationChannelV2.Sms:
      return CommunicationChannel.SMS;
    default:
      return CommunicationChannel.SMS;
  }
};

export const transformCommunicationCommonToChannelV2 = (
  channel?: string | null
): CommunicationChannelV2 => {
  switch (channel) {
    case CommunicationChannel.EMAIL:
      return CommunicationChannelV2.Email;
    case CommunicationChannel.SMS:
      return CommunicationChannelV2.Sms;
    case CommunicationChannel.WHATSAPP:
      return CommunicationChannelV2.Whatsapp;
    default:
      return CommunicationChannelV2.Sms;
  }
};

export const transformGenderV2ToGenderCommon = (
  gender?: Types.Gender | null
): PersonGender | null => {
  switch (gender) {
    case Types.Gender.Male:
      return PersonGender.MALE;
    case Types.Gender.Female:
      return PersonGender.FEMALE;
    case Types.Gender.Other:
      return PersonGender.OTHER;
    default:
      return null;
  }
};

export const transformGenderCommonToGenderV2 = (
  gender?: PersonGender | null
): Types.Gender | null => {
  switch (gender) {
    case PersonGender.MALE:
      return Types.Gender.Male;
    case PersonGender.FEMALE:
      return Types.Gender.Female;
    case PersonGender.OTHER:
      return Types.Gender.Other;
    default:
      return null;
  }
};

export const transformBookingOnBehalfOfToV2 = (arg?: BookingOnBehalfOf) => {
  switch (arg) {
    case BookingOnBehalfOf.OTHERS:
      return BookingOnBehalfOfV2.Others;
    case BookingOnBehalfOf.MYSELF:
      return BookingOnBehalfOfV2.Myself;
  }
};
export const transformBookingOnBehalfOfToCommon = (arg?: BookingOnBehalfOfV2 | null) => {
  switch (arg) {
    case BookingOnBehalfOfV2.Others:
      return BookingOnBehalfOf.OTHERS;
    case BookingOnBehalfOfV2.Myself:
      return BookingOnBehalfOf.MYSELF;
  }
};

export const transformIdCheckStatus = (idCheckStatusV2?: IdCheckStatusV2): IdCheckStatus => {
  switch (idCheckStatusV2) {
    case IdCheckStatusV2.Confirmed:
      return IdCheckStatus.CONFIRMED;
    case IdCheckStatusV2.Reuploaded:
      return IdCheckStatus.REUPLOADED;
    case IdCheckStatusV2.Declined:
      return IdCheckStatus.DECLINED;
    case IdCheckStatusV2.Unconfirmed:
      return IdCheckStatus.UNCONFIRMED;
    default:
      return IdCheckStatus.UNCONFIRMED;
  }
};

export const transformPrepaymentTypeToV2 = (arg?: PrepaymentType): PrepaymentTypeV2 => {
  switch (arg) {
    case PrepaymentType.NONE:
      return PrepaymentTypeV2.None;
    case PrepaymentType.AIRBNB:
      return PrepaymentTypeV2.Airbnb;
    case PrepaymentType.BOOKING_DOT_COM:
      return PrepaymentTypeV2.BookingDotCom;
    case PrepaymentType.BOOKING_DOT_COM_VCC_PREPAYMENT:
      return PrepaymentTypeV2.BookingDotComVccPrepayment;
    case PrepaymentType.BOOKING_DOT_COM_VCC_CREDIT_CARD:
      return PrepaymentTypeV2.BookingDotComVccCreditCard;
    case PrepaymentType.BOOKING_DOT_COM_BANK_TRANSFER_PREPAYMENT:
      return PrepaymentTypeV2.BookingDotComBankTransferPrepayment;
    case PrepaymentType.EXPEDIA:
      return PrepaymentTypeV2.Expedia;
    case PrepaymentType.PREPAID:
      return PrepaymentTypeV2.Prepaid;
    case PrepaymentType.AGODA_VCC:
      return PrepaymentTypeV2.AgodaVcc;
    case PrepaymentType.CHECKOUT_ON_AR:
      return PrepaymentTypeV2.CheckoutOnAr;
    case PrepaymentType.CHECKOUT_ON_AR_CUSTOMER:
      return PrepaymentTypeV2.CheckoutOnArCustomer;
    case PrepaymentType.NOT_DETERMINABLE:
      return PrepaymentTypeV2.NotDeterminable;
    case PrepaymentType.CTRIP:
      return PrepaymentTypeV2.Ctrip;
    case PrepaymentType.GENERIC_OTA:
      return PrepaymentTypeV2.GenericOta;
    case PrepaymentType.HOTELBEDS:
      return PrepaymentTypeV2.Hotelbeds;

    default:
      return PrepaymentTypeV2.None;
  }
};

export const transformV2PrepaymentTypeToCommon = (arg?: PrepaymentTypeV2): PrepaymentType => {
  switch (arg) {
    case PrepaymentTypeV2.None:
      return PrepaymentType.NONE;
    case PrepaymentTypeV2.Airbnb:
      return PrepaymentType.AIRBNB;
    case PrepaymentTypeV2.BookingDotCom:
      return PrepaymentType.BOOKING_DOT_COM;
    case PrepaymentTypeV2.BookingDotComVccPrepayment:
      return PrepaymentType.BOOKING_DOT_COM_VCC_PREPAYMENT;
    case PrepaymentTypeV2.BookingDotComVccCreditCard:
      return PrepaymentType.BOOKING_DOT_COM_VCC_CREDIT_CARD;
    case PrepaymentTypeV2.BookingDotComBankTransferPrepayment:
      return PrepaymentType.BOOKING_DOT_COM_BANK_TRANSFER_PREPAYMENT;
    case PrepaymentTypeV2.Expedia:
      return PrepaymentType.EXPEDIA;
    case PrepaymentTypeV2.Prepaid:
      return PrepaymentType.PREPAID;
    case PrepaymentTypeV2.AgodaVcc:
      return PrepaymentType.AGODA_VCC;
    case PrepaymentTypeV2.CheckoutOnAr:
      return PrepaymentType.CHECKOUT_ON_AR;
    case PrepaymentTypeV2.CheckoutOnArCustomer:
      return PrepaymentType.CHECKOUT_ON_AR_CUSTOMER;
    case PrepaymentTypeV2.NotDeterminable:
      return PrepaymentType.NOT_DETERMINABLE;
    case PrepaymentTypeV2.Ctrip:
      return PrepaymentType.CTRIP;
    case PrepaymentTypeV2.GenericOta:
      return PrepaymentType.GENERIC_OTA;
    case PrepaymentTypeV2.Hotelbeds:
      return PrepaymentType.HOTELBEDS;
    default:
      return PrepaymentType.NONE;
  }
};
