import { useSelector } from "react-redux";
import { selectShopDisplaySlice } from "../store/shop-display.slice";

export const useSelectedProducts = () => {
  const { selectedProduct, selectedFilters } = useSelector(selectShopDisplaySlice);

  return {
    selectedProduct,
    selectedFilters
  };
};
