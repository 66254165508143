import { createDraftSafeSelector, EntityState } from "@reduxjs/toolkit";
import { Dictionary, EntityId } from "@reduxjs/toolkit/src/entities/models";
import { PrismicDocument } from "../../domain-common/prismic-document";

export function createCmsSelectorsFactory() {
  function getCmsSelectors<V>(selectState: (state: V) => EntityState<PrismicDocument>) {
    const selectEntities = (state: EntityState<PrismicDocument>) => state.entities;
    const selectLang = (_: V, id: EntityId) => id;
    const selectByLang = <T>(entities: Dictionary<T>, lang: EntityId) => {
      return entities[lang];
    };

    const selectGlobalizedEntities = createDraftSafeSelector(selectState, selectEntities);

    // @ts-ignore Force typescript for createDraftSafeSelector maybe in new version this will be supported
    const selectByLangFn: <PrismicDocument>(state: V, id: EntityId) => PrismicDocument =
      createDraftSafeSelector(selectGlobalizedEntities, selectLang, selectByLang);
    return {
      selectByLang: selectByLangFn
    };
  }

  return { getCmsSelectors };
}
