import { Box } from "@mui/material";
import React, { FC } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/styles";

export const MyStayFooter: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const theme = useTheme();

  return (
    <Box p={1} pb={2}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Heading4
          color="textSecondary"
          align="center"
          sx={{
            display: "inline",
            backgroundSize: "1px 1em",
            boxShadow: `inset 0 -7px ${theme.palette.common.white}`
          }}
        >
          {t("labels__quote")}
        </Heading4>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Heading4
          color="textSecondary"
          align="center"
          sx={{ textDecoration: "underline", cursor: "pointer", display: "inline" }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          {t("buttons__back_to_top")}
        </Heading4>
      </Box>
    </Box>
  );
};
