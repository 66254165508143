import { Grid } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import React, { FC } from "react";
import { RadioButton, RadioButtonGroup, useCountries } from "@likemagic-tech/sv-magic-library";
import { PersonGender } from "../../../domain-common/person";
import { FastInput } from "../fast-input";
import { FastPhoneInput } from "../fast-phone-input";
import { useReservationContext } from "../../../features/reservation-provider/reservation-provider";

export type PersonFormData = {
  gender: string | null;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

export interface PersonFormProps {
  objectPathPrefix?: string;
  values: PersonFormData;
  onChange: (person: any) => void;
  touched?: FormikTouched<PersonFormData>;
  errors?: FormikErrors<PersonFormData>;
  labels: {
    genderFemale: string;
    genderMale: string;
    genderOther: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
  };
  nameEditable?: boolean;
  contactDataEditable?: boolean;
  diverseGenderEnabled: boolean;
  genderDisplayDisabled: boolean;
}

export const PersonForm: FC<PersonFormProps> = ({
  objectPathPrefix,
  values,
  onChange,
  touched,
  errors,
  labels,
  nameEditable,
  contactDataEditable,
  diverseGenderEnabled,
  genderDisplayDisabled
}) => {
  const { reservation } = useReservationContext();
  const { preferredCountries } = useCountries({
    propertyId: reservation?.propertyId
  });

  return (
    <Grid container spacing={2}>
      {!genderDisplayDisabled && (
        <Grid item xs={12}>
          <RadioButtonGroup
            name={[objectPathPrefix, `gender`].join("")}
            value={values.gender || ""}
            onChange={onChange}
          >
            <RadioButton
              value={PersonGender.FEMALE}
              label={labels?.genderFemale}
              disabled={nameEditable}
            />
            {diverseGenderEnabled && (
              <RadioButton
                value={PersonGender.OTHER}
                label={labels?.genderOther}
                disabled={nameEditable}
              />
            )}
            <RadioButton
              value={PersonGender.MALE}
              label={labels?.genderMale}
              disabled={nameEditable}
            />
          </RadioButtonGroup>
        </Grid>
      )}
      <Grid item xs={6}>
        <FastInput
          id={[objectPathPrefix, `firstName`].join("")}
          name={[objectPathPrefix, `firstName`].join("")}
          value={values.firstName || ""}
          label={labels?.firstName}
          error={touched?.firstName && errors?.firstName}
          disabled={nameEditable}
        />
      </Grid>
      <Grid item xs={6}>
        <FastInput
          id={[objectPathPrefix, `lastName`].join("")}
          name={[objectPathPrefix, `lastName`].join("")}
          value={values.lastName || ""}
          label={labels?.lastName}
          error={touched?.lastName && errors?.lastName}
          disabled={nameEditable}
        />
      </Grid>
      <Grid item xs={12}>
        <FastPhoneInput
          name={[objectPathPrefix, `phone`].join("")}
          value={values.phone || ""}
          error={touched?.phone && !!errors?.phone}
          helperText={touched?.phone && errors?.phone}
          label={labels?.phone}
          disabled={contactDataEditable}
          preferredCountries={preferredCountries}
        />
      </Grid>
      <Grid item xs={12}>
        <FastInput
          id={[objectPathPrefix, `email`].join("")}
          name={[objectPathPrefix, `email`].join("")}
          value={values.email || ""}
          type="email"
          label={labels?.email}
          error={touched?.email && errors?.email}
          disabled={contactDataEditable}
        />
      </Grid>
    </Grid>
  );
};
