import { useMemo } from "react";
import { useFeatureFlags } from "@likemagic-tech/sv-magic-library";
import { PersonGender } from "../../domain-common/person";
import { useTranslateWrapper } from "../i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";

export const useGenderOptions = () => {
  const { diverseGenderEnabled } = useFeatureFlags();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const genderOptions = useMemo(() => {
    const options = [
      { value: PersonGender.FEMALE, label: t("labels__gender_female") },
      { value: PersonGender.MALE, label: t("labels__gender_male") }
    ];

    if (diverseGenderEnabled) {
      options.push({ value: PersonGender.OTHER, label: t("labels__gender_other") });
    }
    return options;
  }, [t, diverseGenderEnabled]);

  return { genderOptions };
};
