import { useCmsPerPropertyData } from "../cms/use-cms-per-property-data";
import { fetchShopCMS, selectShopCMSById } from "./shop-cms.slice";
import { PrismicImage } from "../property-cms/use-unit-group-cms-data";
import { ShopType } from "../../graphql/generated/graphql";

export const useShopCmsTranslation = (propertyId: string, shopType: ShopType) => {
  const cmsData = useCmsPerPropertyData({
    propertyId: propertyId || "",
    selector: selectShopCMSById,
    fetchAction: fetchShopCMS,
    additionalFilters: {
      type: shopType
    }
  });

  const items = cmsData?.data?.items as [
    {
      item_id: string;
      item_image: PrismicImage;
      item_category: string;
      item_title: string;
    }
  ];
  const categories = cmsData?.data?.categories as [{ category_id: string; category_title: string }];
  const findItem = (itemId: string) => items?.find((item) => item.item_id === itemId);

  return {
    items,
    categories:
      categories?.map((item) => ({
        id: item.category_id,
        name: item.category_title
      })) ?? [],
    findItem
  };
};
