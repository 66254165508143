import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { FindReservationDTO, FindReservationResponseDTO } from "./find-reservation.dto";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { handleSliceError } from "../../util/error-handling";
import { MagicApi } from "../../api/magic.api";

export type FindReservationInitialState = {
  isOpened: boolean;
  reservations: Array<FindReservationResponseDTO>;
  propertyId: string;
  isKioskIntroVisible: boolean;
};

const initialState: FindReservationInitialState = {
  isOpened: false,
  propertyId: "",
  reservations: [],
  isKioskIntroVisible: false
};

export const findReservationAction = createAsyncThunk<
  Array<FindReservationResponseDTO>,
  FindReservationDTO,
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("findReservation/fetchReservations", async (arg, thunkAPI) => {
  try {
    return await MagicApi.findReservation(arg, {
      signal: thunkAPI.signal
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const findReservationSlice = createSlice({
  name: "findReservation",
  initialState,
  reducers: {
    openFindReservationModal: (
      state,
      action: PayloadAction<{
        reservations: Array<FindReservationResponseDTO>;
        propertyId: string;
      }>
    ) => {
      state.isOpened = true;
      state.reservations = action.payload.reservations;
      state.propertyId = action.payload.propertyId;
    },
    closeFindReservationModal: (state) => {
      state.isOpened = false;
      state.reservations = initialState.reservations;
    },
    setKioskIntro: (state, action: PayloadAction<{ isKioskIntroVisible: boolean }>) => {
      state.isKioskIntroVisible = action.payload.isKioskIntroVisible;
    }
  }
});

const selectFindReservation = (state: RootState) => state[findReservationSlice.name];

export const selectFindReservationModalStatus = createSelector(
  selectFindReservation,
  (res) => res.isOpened
);
export const selectFindReservationsList = createSelector(
  selectFindReservation,
  (res) => res.reservations
);

export const selectKioskIntro = createSelector(
  selectFindReservation,
  (res) => res.isKioskIntroVisible
);

export const selectSelectedProperty = createSelector(
  selectFindReservation,
  (res) => res.propertyId
);

export const { closeFindReservationModal, openFindReservationModal, setKioskIntro } =
  findReservationSlice.actions;
