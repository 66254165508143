import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function CalendarCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>
          {`.a,.b{fill:none;stroke:currentColor;strokeLinejoin:round;strokeWidth:1.5px;}.b{strokeLinecap:round;}`}
        </style>
      </defs>
      <title>calendar-check</title>
      <rect className="a" x="0.75" y="3.75" width="22.5" height="19.5" rx="1.5" ry="1.5" />
      <line className="a" x1="0.75" y1="9.75" x2="23.25" y2="9.75" />
      <line className="b" x1="6.75" y1="6" x2="6.75" y2="0.75" />
      <line className="b" x1="17.25" y1="6" x2="17.25" y2="0.75" />
      <polyline className="b" points="15.75 13.5 11.25 19.5 8.25 16.5" />
    </SvgIcon>
  );
}
