import { Heading2 } from "@likemagic-tech/sv-magic-library";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchBookNavigation } from "../hooks/use-search-book-navigation";
import { Box } from "@mui/material";
import { useAppDispatch } from "../../../state/store";
import { PaymentPersonalData } from "../components/payment-personal-data";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { SearchBookingPaymentComponent } from "../components/search-booking-payment-component";
import { DesktopCard } from "../../../components";
import { useUserInfo } from "../../booking-overview/hooks/use-user-info";
import { clearPaymentState, preparePayment, setReferenceId } from "../../payment/payment.slice";
import { useSelector } from "react-redux";
import {
  clearBookingCart,
  selectAllFromBookingCart,
  selectBooker,
  selectCartReservations,
  setBooker
} from "../booking-cart.slice";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { generateSearchBookingPagesUrl } from "../search-booking-navigation";
import { SearchBookingPages } from "../search-booking-pages";
import { toGross } from "../../../domain-common/full-price";
import { useBookingTotalPrice } from "../hooks/use-booking-total-price";
import { PaymentType } from "../../../domain-common/booking-cart-total-price";
import { SearchBookingWithoutPaymentCreateButton } from "../components/search-booking-without-payment-create-button";
import { PromoCodeType, resetPromoCode, selectPromoCode } from "../promocode.slice";
import { useValidPromoCodeResponse } from "../hooks/use-valid-promocode-response";
import { generateUUID } from "../../../util/data";

export const SearchPaymentAndBookerDataPage = () => {
  const navigate = useNavigate();
  const { availableButtons } = useSearchBookNavigation();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const { search } = useLocation();
  const bookingData = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    const magicId = urlParams.get("magicId") ?? undefined;
    const magicToken = urlParams.get("magicToken") ?? undefined;

    return {
      magicId,
      magicToken
    };
  }, [search]);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { userInfo } = useUserInfo();
  const booker = useSelector(selectBooker);

  const [isBookerFormDataComplete, setIsBookerFormDataComplete] = useState(false);
  const cartItems = useSelector(selectAllFromBookingCart);
  const cartReservations = useSelector(selectCartReservations);
  const promoCode = useSelector(selectPromoCode);
  const promoCodeData = useValidPromoCodeResponse({ promoCode: promoCode });

  const { totalBill: cartTotalPrice } = useBookingTotalPrice(
    cartItems,
    promoCodeData.type === PromoCodeType.MAGIC ? promoCode : ""
  );

  useEffect(() => {
    if (userInfo?.given_name && userInfo?.family_name && userInfo?.email) {
      dispatch(
        setBooker({
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          email: userInfo.email
        })
      );
      setIsBookerFormDataComplete(true);
    }
  }, [dispatch, userInfo]);

  const isAROrPM6HoldReservation =
    cartTotalPrice?.paymentType === PaymentType.GUARANTEED_BY_COMPANY ||
    cartTotalPrice?.paymentType === PaymentType.PM6HOLD;

  useEffect(() => {
    if (
      isBookerFormDataComplete &&
      cartTotalPrice?.total &&
      booker.email &&
      booker.lastName &&
      booker.firstName
    ) {
      dispatch(
        preparePayment({
          adyenPrice: toGross(cartTotalPrice?.total),
          bookingCreation: {
            booker,
            reservations: cartReservations,
            ...(promoCodeData.type === PromoCodeType.MAGIC &&
              promoCode && { promoCodeMagic: promoCode })
          }
        })
      );
      dispatch(setReferenceId(generateUUID()));
    }
  }, [
    isBookerFormDataComplete,
    booker,
    dispatch,
    cartTotalPrice,
    cartReservations,
    promoCodeData,
    promoCode
  ]);

  const disablePayment = useMemo(() => !isBookerFormDataComplete, [isBookerFormDataComplete]);

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSuccessPayment = useCallback(
    (magicId?: string) => {
      dispatch(clearPaymentState());
      dispatch(clearBookingCart());
      dispatch(resetPromoCode());

      navigate(
        generateSearchBookingPagesUrl(SearchBookingPages.CONFIRMATION).replace(
          ":newMagicId",
          bookingData.magicId || magicId || ""
        )
      );
    },
    [navigate, dispatch, bookingData.magicId]
  );
  const setBookerData = (booker: any) => {
    dispatch(setBooker(booker));
  };

  return (
    <FlowTemplate icons={availableButtons} handleBack={goBack}>
      <HelmetTitle suffix="Booking payment & booker data" />
      {!bookingData.magicId && (
        <>
          <Heading2 textAlign={isMobile ? "center" : "left"} gutterBottom>
            {tCommon("title__booker_data")}
          </Heading2>
          <Box py={2}>
            <DesktopCard>
              <Box px={2}>
                <PaymentPersonalData
                  setIsBookerFormDataComplete={setIsBookerFormDataComplete}
                  setBooker={setBookerData}
                  booker={booker}
                />
              </Box>
            </DesktopCard>
          </Box>
        </>
      )}
      {isAROrPM6HoldReservation ? (
        <SearchBookingWithoutPaymentCreateButton
          disablePayment={disablePayment}
          onSuccessPayment={onSuccessPayment}
          guaranteeType={cartTotalPrice?.paymentType}
        />
      ) : (
        // Force a proper re-render of a payment component with booker data is changed
        // This code fixes 3ds for non auth users
        <DesktopCard key={disablePayment ? "hide-payment" : "show-payment"}>
          {disablePayment && (
            <Box
              style={{
                position: "absolute",
                zIndex: 2,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                height: "61%", //TODO reconsider how to do a full height
                left: isMobile ? 0 : 16,
                right: isMobile ? 0 : 16
              }}
              onClick={(e: SyntheticEvent) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
          {booker.firstName && booker.lastName && booker.email && (
            <SearchBookingPaymentComponent
              onSuccessPayment={onSuccessPayment}
              magicId={bookingData.magicId}
              magicToken={bookingData.magicToken}
              guaranteeType={cartTotalPrice?.paymentType}
              returnUrl={generateSearchBookingPagesUrl(SearchBookingPages.PAYMENT_REDIRECT)}
            />
          )}
        </DesktopCard>
      )}
    </FlowTemplate>
  );
};
