import { useSelector } from "react-redux";
import {
  fetchTheLatestMagicId,
  selectStatusUserProfileLastMagicId,
  selectUserProfileLastMagicId
} from "./user-profile.slice";
import { useAppDispatch } from "../../state/store";
import { useEffect } from "react";
import { useApiVersion, useAuth } from "@likemagic-tech/sv-magic-library";
import { useGetLatestMagicObjectQuery } from "../../graphql/queries/GetLatestMagicObject.generated";
import { mapQueryStatusToEntityStateStatus } from "../../state/EntityStateStatus";

export const useUserProfileLastMagicId = () => {
  const { getIsAuthenticated } = useAuth();

  const { isRESTVersion } = useApiVersion();
  const userProfileLastMagicIdV1 = useSelector(selectUserProfileLastMagicId);
  const statusLatestMagicIdV1 = useSelector(selectStatusUserProfileLastMagicId);
  const dispatch = useAppDispatch();

  const { data, status } = useGetLatestMagicObjectQuery(
    {},
    { skip: !getIsAuthenticated() || isRESTVersion }
  );
  useEffect(() => {
    if (getIsAuthenticated() && isRESTVersion) {
      dispatch(fetchTheLatestMagicId());
    }
  }, [getIsAuthenticated, dispatch, isRESTVersion]);

  const userProfileLastMagicId = isRESTVersion
    ? userProfileLastMagicIdV1
    : data?.GetLatestMagicObject?.magicId;

  const lastMagicIdStatus = isRESTVersion
    ? statusLatestMagicIdV1
    : mapQueryStatusToEntityStateStatus(status);
  return { userProfileLastMagicId, lastMagicIdStatus };
};
