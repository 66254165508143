import { FC, useMemo } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { PropertiesByCity, Property } from "../../../../domain-common/properties-by-city";
import { usePropertyById } from "../../../property/use-property-by-id";
import { ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { useCityById } from "../../../property/use-city-by-id";
import { useThemeOverrideConfig } from "../../../../util/hooks/use-configuration";

interface SearchBookingPropertyFilterProps {
  properties: PropertiesByCity;
  value: string;
  disabled?: boolean;
  onChange: (event: SelectChangeEvent) => void;
  showOnlyCities?: boolean;
  showOnlyProperties?: boolean;
}

/**
 * City id is a list of property ids that are in the selected city (joint with coma ",")
 * @param properties
 */
export const citySelection = (properties: Array<Property>) =>
  properties.map((item) => item.propertyId)?.join(",");

const PropertyMenuItem: FC<React.PropsWithChildren<{ propertyId: string }>> = ({ propertyId }) => {
  const { name } = usePropertyById(propertyId);
  return <>{name}</>;
};

const CityMenuItem: FC<React.PropsWithChildren<{ cityId: string }>> = ({ cityId }) => {
  const cityName = useCityById(cityId);
  return <ParagraphBold>{cityName || cityId}</ParagraphBold>;
};

export const SearchBookingPropertyFilter: FC<
  React.PropsWithChildren<SearchBookingPropertyFilterProps>
> = ({ properties, value, disabled, onChange, showOnlyCities, showOnlyProperties }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const themeOverrideConfig = useThemeOverrideConfig();

  const dropdownOptions = useMemo(() => {
    return Object.keys(properties).flatMap((cityId) => {
      if (showOnlyCities) {
        const value = citySelection(properties[cityId]);
        return [
          <MenuItem key={cityId} value={value}>
            <CityMenuItem cityId={cityId} />
          </MenuItem>
        ];
      } else if (showOnlyProperties) {
        return properties[cityId].map((p) => (
          <MenuItem key={p.propertyId} value={p.propertyId}>
            <PropertyMenuItem propertyId={p.propertyId} />
          </MenuItem>
        ));
      } else {
        const propertiesPerCity = properties[cityId].map((p) => (
          <MenuItem key={p.propertyId} value={p.propertyId}>
            <PropertyMenuItem propertyId={p.propertyId} />
          </MenuItem>
        ));

        return [
          <MenuItem key={cityId} value={citySelection(properties[cityId])}>
            <CityMenuItem cityId={cityId} />
          </MenuItem>
        ].concat(propertiesPerCity);
      }
    });
  }, [properties, showOnlyCities, showOnlyProperties]);

  return (
    <FormControl
      variant={themeOverrideConfig?.input?.variant ?? "filled"}
      fullWidth
      sx={{
        marginTop: themeOverrideConfig?.input?.variant === "standard" ? "8px" : "unset"
      }}
    >
      <InputLabel>{t("labels__chose_hotel")}</InputLabel>
      <Select
        variant={themeOverrideConfig?.input?.variant ?? undefined}
        name="propertyId"
        fullWidth
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
      >
        {dropdownOptions}
      </Select>
    </FormControl>
  );
};
