import { useCmsPropertySpecificData } from "../cms/use-cms-per-property-data";
import { RichTextField } from "@prismicio/types/src/value/richText";

export const useRatePlan = (propertyId: string) => {
  const cmsData = useCmsPropertySpecificData(propertyId);
  const getRatePlanData = (
    ratePlanId: string,
    ratePlanTitle?: string,
    ratePlanDescription?: string
  ): {
    title: string;
    description?: RichTextField;
    descriptionString?: string;
  } => {
    //hotelpartner info
    if (!!ratePlanTitle) {
      return { title: ratePlanTitle!, descriptionString: ratePlanDescription! };
    }

    const ratePlan = cmsData?.data["rate_plans"]?.find((item: any) => item.id === ratePlanId);
    return ratePlan
      ? { title: ratePlan.title, description: ratePlan.description }
      : { title: "", description: [] };
  };
  return { getRatePlanData };
};
