import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { FC } from "react";
import { isKioskMode } from "../../../util/kiosk-mode";
import { responsiveVariables } from "../../layouts/hooks/use-is-mobile";
import { Button } from "@likemagic-tech/sv-magic-library";

export interface SubmitButtonStyleProps {
  hasBottomNavigation?: boolean;
  hasSecondaryFixedButton?: boolean;
  hasWhiteBackground?: boolean;
  notFullScreen?: boolean;
}

export interface SubmitButtonProps extends SubmitButtonStyleProps {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
  variant?: "primary" | "secondary" | "ghost" | "surface";
}

export const useStylesForSubmitButton = makeStyles<SubmitButtonStyleProps>()(
  ({ spacing, breakpoints, palette }, props) => ({
    container: {
      [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
        bottom: props.hasBottomNavigation
          ? spacing(props.hasSecondaryFixedButton ? 18 : 9)
          : spacing(2.5),
        maxWidth: `calc(1024px - ${spacing(5)})`,
        paddingBottom: "env(safe-area-inset-bottom)"
      },
      marginTop: spacing(3)
    },
    button: {
      width: "50%",
      [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
        width: `${props.notFullScreen ? "50%" : "100%"}`
      },

      margin: "auto"
    }
  })
);

export const SubmitButton: FC<SubmitButtonProps> = (props) => {
  const { label, disabled, onClick, variant } = props;
  const { classes } = useStylesForSubmitButton({
    notFullScreen: isKioskMode(),
    ...props
  });
  return (
    <Box display="flex" flexDirection="column" className={classes.container}>
      <Button
        variant={variant || "primary"}
        type="submit"
        disabled={disabled}
        onClick={onClick}
        className={classes.button}
      >
        {label}
      </Button>
    </Box>
  );
};
