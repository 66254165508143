import { BaseEntity, BaseEntityFromJSON } from "./base-entity";
import { Address, AddressFromJSON } from "./address";

export enum PersonGender {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other"
}

export enum BookingOnBehalfOf {
  MYSELF = "MYSELF",
  OTHERS = "OTHERS"
}

export interface Person extends BaseEntity {
  id?: string;
  firstName: string;
  lastName: string;
  birthdate?: string;
  email: string;
  phone: string;
  title: string;
  gender: PersonGender | null;
  preferredLocale: string;
  identificationType: string;
  identificationNumber: string;
  nationalityCountryCode: string;
  address: Address;
  company: any;
  guestIdentityId: string;
  enrolledInLoyaltyProgram?: boolean;
  emergencyEvacuationHelpNeeded?: boolean;
  minor: boolean;
}

export const PersonFromJSON = (json: any): Person => {
  if (!json) {
    return json;
  }

  const baseEntity = BaseEntityFromJSON(json);

  return {
    ...baseEntity,
    id: json["id"],
    firstName: json["firstName"],
    lastName: json["lastName"],
    birthdate: json["birthdate"],
    email: json["email"] ? json["email"] : "",
    phone: json["phone"] ? json["phone"] : "",
    title: json["title"],
    gender: json["gender"],
    preferredLocale: json["preferredLocale"],
    identificationType: json["identificationType"],
    identificationNumber: json["identificationNumber"],
    nationalityCountryCode: json["nationalityCountryCode"],
    address: AddressFromJSON(json["address"]),
    company: json["company"],
    guestIdentityId: json["guestIdentityId"],
    minor: json["minor"]
  };
};
