import { DialogContentText } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Reservation } from "../../../domain-common/reservation";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import { UnitCondition } from "../../../domain-common/unit";
import { useGuestFlow } from "../../../features/guest-flow/use-guest-flow";
import { useMagicIdParams } from "../../../features/magic/use-magic-id-params";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generatePortalMyStayKeysUrl, generatePortalMyStayUrl } from "../../../util/routing";
import { Dialog } from "../../atoms";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { SubUpdate } from "../../../features/reservation-provider/sub-update";
import { DoorProvider, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";

export interface UnitStatusDialogProps {
  reservation: Reservation;
}

export const UnitStatusDialog: React.FC<React.PropsWithChildren<UnitStatusDialogProps>> = ({
  reservation
}) => {
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { patchGuestFlowStep } = useGuestFlow();
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const config = useDoorProviderConfig(reservation.propertyId);

  const patchBackend = useCallback(
    (clean: boolean) => {
      if (clean) {
        return patchGuestFlowStep({
          reservationValues: {
            ...reservation,
            extras: { ...reservation.extras!, cleanUnitDialogSeen: true }
          },
          subUpdate: SubUpdate.FLOW_STATE
        });
      } else {
        return patchGuestFlowStep({
          reservationValues: {
            ...reservation,
            extras: { ...reservation.extras!, dirtyUnitDialogSeen: true }
          },
          subUpdate: SubUpdate.FLOW_STATE
        });
      }
    },
    [reservation, patchGuestFlowStep]
  );

  const onConfirmModal = useCallback(
    (clean: boolean) => () => {
      //we dont have digital keys, do not go to the keys page
      if (config?.doorProvider === DoorProvider.DORMA_KABA) {
        patchBackend(clean).then(() => navigate(`${generatePortalMyStayUrl(magicId)}`));
      } else {
        patchBackend(clean).then(() => navigate(`${generatePortalMyStayKeysUrl(magicId)}`));
      }
    },
    [magicId, navigate, patchBackend, config?.doorProvider]
  );

  const onDismissModal = useCallback(
    (clean: boolean) => () => {
      patchBackend(clean);
    },
    [patchBackend]
  );

  if (
    !reservation?.extras?.cleanUnitDialogSeen &&
    ((reservation.unitCleanOnCheckin && reservation.status === ReservationStatus.IN_HOUSE) ||
      (reservation.status === ReservationStatus.IN_HOUSE &&
        reservation?.unit?.status?.condition === UnitCondition.CLEAN))
  ) {
    return (
      <Dialog
        open
        onConfirm={onConfirmModal(true)}
        onDismiss={onDismissModal(true)}
        title={tCommon("modals__studio_ready_title")}
        content={<DialogContentText>{tCommon("modals__studio_ready_content")}</DialogContentText>}
        buttonLabel={tCommon("buttons__keys")}
      />
    );
  }

  if (
    !reservation?.extras?.dirtyUnitDialogSeen &&
    !reservation?.extras?.cleanUnitDialogSeen &&
    reservation.status === ReservationStatus.IN_HOUSE &&
    reservation?.unit?.status?.condition !== UnitCondition.CLEAN &&
    !reservation?.unitCleanOnCheckin
  ) {
    return (
      <Dialog
        open
        onConfirm={onConfirmModal(false)}
        onDismiss={onDismissModal(false)}
        title={tCommon("modals__studio_not_ready_title")}
        content={
          <DialogContentText>{tCommon("modals__studio_not_ready_content")}</DialogContentText>
        }
        buttonLabel={tCommon("buttons__ok")}
      />
    );
  }

  return <React.Fragment />;
};
