import { FC, useMemo } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useSelector } from "react-redux";
import { selectLastMagicId } from "../../../features/restore-magic-context/restore-magic-context.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { navigationLinksGenerator } from "../../organism/bottom-navbar/bottom-navbar-context";
import { SearchBookingPages } from "../../../features/search-for-bookings/search-booking-pages";
import { BookingOverviewPages } from "../../../features/booking-overview/booking-overview-pages";
import { PortalPagesEnum } from "../../../features/portal/portal-page-to-path";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import { makeStyles } from "tss-react/mui";
import { isKioskMode } from "../../../util/kiosk-mode";
import { LanguageSelector } from "../../molecules/language-selector";
import { Button } from "@likemagic-tech/sv-magic-library";
import { isSecondScreenMode } from "../../../util/second-screen-mode";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

const useStyles = makeStyles()(({ palette, spacing }) => ({
  buttonSelected: {
    height: spacing(8),
    borderWidth: 0,
    borderBottomWidth: spacing(0.25),
    borderColor: palette.primary.main,
    borderRadius: 0,
    borderStyle: "solid"
  }
}));

interface TopNavigationItemsProps {
  searchEnabled: boolean;
}

export const TopNavigationItems: FC<TopNavigationItemsProps> = ({ searchEnabled }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const lastMagicId = useSelector(selectLastMagicId);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const { ibeOnly } = useFeatureFlags();

  const desktopTopNavigation = useMemo(() => {
    return navigationLinksGenerator({
      ibeOnly,
      labels: {
        [SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES]: t("main__bottom_nav_search_label"),
        [BookingOverviewPages.PROPERTIES]: t("main__bottom_nav_booking_label"),
        [PortalPagesEnum.PROFILE]: t("main__bottom_nav_profile_label"),
        [PortalPagesEnum.MY_STAY]: t("main__bottom_nav_my_stay_label")
      },
      magicId: lastMagicId ?? "",
      isSearchEnabled: searchEnabled,
      pathname
    }).map((item) => (
      <Button
        key={item.label}
        variant="ghost"
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disableElevation
        sx={{
          color: "common.black",
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent"
          }
        }}
        className={item.isSelected ? classes.buttonSelected : ""}
        onClick={() => navigate(item.to)}
      >
        {item.label}
      </Button>
    ));
  }, [t, searchEnabled, ibeOnly, lastMagicId, navigate, classes.buttonSelected, pathname]);

  if (!(isMobile || isKioskMode() || isSecondScreenMode())) {
    return <>{desktopTopNavigation}</>;
  } else if (isKioskMode() || isSecondScreenMode()) {
    return <LanguageSelector />;
  }
  return null;
};
