import React, { FC } from "react";
import { Grid } from "@mui/material";
import { PaperButton } from "../../../../components";
import { Reservation } from "../../../../domain-common/reservation";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../../state/common-cms/common-cms.slice";
import { useCmsPropertySpecificData } from "../../../../state/cms/use-cms-per-property-data";

interface NavigationFAQProps {
  reservation: Reservation;
  title?: string;
  iconUrl?: string;
}

export const NavigationFAQ: FC<NavigationFAQProps> = ({ reservation, title, iconUrl }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const propertySpecificCmsData = useCmsPropertySpecificData(reservation.propertyId);

  if (propertySpecificCmsData?.data?.service__faq_url || cmsData?.data?.service__faq_url) {
    return (
      <Grid item xs={6}>
        <PaperButton
          title={title ?? t("navigations__faq")}
          small
          onClick={() => {
            window.open(
              propertySpecificCmsData?.data?.service__faq_url ?? cmsData?.data?.service__faq_url,
              "_blank"
            );
          }}
          iconUrl={iconUrl ?? cmsData?.data?.icons__my_stay_navigation_faq?.url}
        />
      </Grid>
    );
  } else {
    return null;
  }
};
