import { Heading1 } from "@likemagic-tech/sv-magic-library";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useEffect, useMemo } from "react";
import { Detector } from "react-detect-offline";
import { useCommonOfflineCmsData } from "../../state/common-cms/use-common-cms-data";
import { WifiOff } from "../icons/wifi-off";

const useStyles = makeStyles()((theme) => ({
  offlineContent: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1200,
    backgroundColor: theme.palette.error.main,
    transition: theme.transitions.create(["background-color", "opacity"], {
      duration: theme.transitions.duration.short
    })
  },
  icon: {
    fontSize: 120,
    color: "white"
  }
}));

interface OfflineViewProps {
  isOpen: boolean;
}

const OfflineView: React.FC<React.PropsWithChildren<OfflineViewProps>> = ({ isOpen }) => {
  const { classes } = useStyles();
  const offlineData = useCommonOfflineCmsData();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);
  return (
    <>
      {isOpen && (
        <Grid
          container
          className={classes.offlineContent}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <WifiOff className={classes.icon} fill="white" />
          <Box mt={4} mx={4}>
            {offlineData?.["offline__text"]?.map((item: any, index: number) => (
              <Heading1 textAlign="center" key={`offline-${index}`} color="common.white">
                {item.text}
              </Heading1>
            ))}
          </Box>
        </Grid>
      )}
    </>
  );
};
export const DetectOffline = () => {
  const polling: {
    enabled?: boolean;
    url?: string;
    interval?: number;
    timeout?: number;
  } = useMemo(
    () => ({
      url: `/api/ping`
    }),
    []
  );
  return (
    <Detector
      polling={polling}
      render={({ online }: { online: boolean }) => <OfflineView isOpen={!online} />}
    />
  );
};
