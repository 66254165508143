import { Button, Heading4, Paragraph } from "@likemagic-tech/sv-magic-library";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WarningRoundedIcon from "@mui/icons-material/Warning";
import { Alert, Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import { makeStyles } from "tss-react/mui";
import React, { useMemo } from "react";
import { useCommonErrorTranslation } from "../../../state/common-cms/use-common-error-translation";
import { InfoIcon } from "../../icons";

export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning"
}

interface BaseNotificationProps {
  type: "error" | "success" | "info" | "warning";
}

export interface NotificationProps extends BaseNotificationProps {
  title: string;
  subtitle?: string;
  errorId?: string;
  buttonText?: string;
  onClick?: () => void;
  onClose?: (event: any) => void;
}

const iconStyle = (color: string) => ({
  width: 32,
  height: 32,
  padding: 4,
  borderRadius: 22,
  background: color
});

const useStyles = makeStyles()((theme) => ({
  successIcon: {
    ...iconStyle(theme.palette.background.paper),
    color: theme.palette.success.main
  },
  errorIcon: {
    ...iconStyle(theme.palette.background.paper),
    color: theme.palette.error.main
  },
  infoIcon: {
    ...iconStyle(theme.palette.background.paper),
    color: theme.palette.info.main,
    padding: 0
  },
  warningIcon: {
    ...iconStyle(theme.palette.background.paper),
    color: theme.palette.warning.main,
    padding: 0
  },
  alertMessage: {
    width: "100%",
    borderRadius: theme.shape.borderRadius * 1.5
  }
}));

export const Notification: React.FC<React.PropsWithChildren<NotificationProps>> = (props) => {
  const { type, title, subtitle, errorId, onClick, onClose, buttonText } = props;

  const translatedError = useCommonErrorTranslation(errorId); // if no title was set then we opt for translatedError - because we cannot translate the error in banner.slice
  const realOnClick = useMemo(
    () => translatedError?.onClick || onClick,
    [onClick, translatedError]
  );
  const { classes } = useStyles();
  const theme = useTheme();

  const iconMapping = {
    [NotificationType.SUCCESS]: <CheckCircleRoundedIcon className={classes.successIcon} />,
    [NotificationType.ERROR]: <WarningRoundedIcon className={classes.errorIcon} />,
    [NotificationType.INFO]: <InfoIcon className={classes.infoIcon} />,
    [NotificationType.WARNING]: <InfoIcon className={classes.warningIcon} />
  };

  return (
    <Alert
      variant="filled"
      severity={type}
      iconMapping={iconMapping}
      sx={{
        backgroundColor: theme.palette[type].light,
        alignItems: "center"
      }}
      classes={{
        message: classes.alertMessage
      }}
      onClose={onClose}
    >
      <Grid container direction="row" justifyContent="space-between">
        <Grid item alignSelf="center">
          <Heading4 sx={{ color: theme.palette[type].dark }}>
            {title || translatedError?.text}
          </Heading4>
          {subtitle && <Paragraph sx={{ color: theme.palette[type].dark }}>{subtitle}</Paragraph>}
        </Grid>

        {realOnClick && (
          <Grid item>
            <Button
              variant={type === "error" ? "primary" : "secondary"}
              onClick={realOnClick}
              size="small"
            >
              {buttonText || translatedError?.buttonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </Alert>
  );
};
