import React, { FC, useCallback, useMemo } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import {
  generateCheckoutFlowConfirmationUrl,
  generateCheckoutFlowPaymentPageUrl
} from "../../../util/routing";
import { CheckoutFlowPages, getCheckoutFlowProgress } from "../checkout-flow-pages";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { GuestFlowBackgroundBox } from "../../guest-flow/components/guest-flow-background-box";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { mapFolio } from "../../../components/organism/folio/folio-preview.util";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { FolioPreview } from "../../../components/organism/folio/folio-preview";
import { Form, Formik } from "formik";
import { useTotalPrice } from "../../payment/use-total-price";
import { usePreparePayment } from "../../payment/use-prepare-payment";
import { useSelector } from "react-redux";
import { Charge } from "../../../domain-common/payment";
import { emptyFullPrice, multiply, toGross } from "../../../domain-common/full-price";
import { compareByStringProperty } from "../../../util/sort";
import { useShopCmsTranslation } from "../../../state/shop-cms/use-shop-cms-translation";
import { useUpdateFlowStateMutation } from "../../../graphql/mutations/update-flow-state.generated";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { ShopType } from "../../../graphql/generated/graphql";
import { RootState, useAppDispatch } from "../../../state/store";
import {
  clearDynamicShopCart,
  selectAllShopCartPerType
} from "../../dynamic-shop/dynamic-shop.slice";
import { useBookFreeAdditionalServices } from "../../additional-services/service-selection/use-book-free-additional-services";
import {
  dynamicShopCartItemsToAdditionalServicesPaymentDTO,
  dynamicShopCartItemsToShopItemsPaymentDTO
} from "../../dynamic-shop/dynamic-shop.util";

const currentPage = CheckoutFlowPages.BILL;

export const CheckoutBillOverviewPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { reservation } = useReservationContext();
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const { bookFreeAdditionalServices } = useBookFreeAdditionalServices(
    reservation.magicId,
    reservation.magicToken
  );
  const dispatch = useAppDispatch();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const { preparePayment } = usePreparePayment(magicId);
  const [updateFlowState] = useUpdateFlowStateMutation();

  const foliosToBePaid = useMemo(
    () => reservation.foliosToBePaid.map((f) => f.folioId),
    [reservation.foliosToBePaid]
  );
  const minibarCartItems = useSelector((state: RootState) =>
    selectAllShopCartPerType(state, ShopType.MinibarCheckoutFlow.toString())
  );

  const totalToBePaid = useTotalPrice(
    reservation.magicId,
    dynamicShopCartItemsToShopItemsPaymentDTO(minibarCartItems),
    undefined,
    foliosToBePaid
  );
  const skipPayment = useMemo(() => totalToBePaid?.grossAmount === 0, [totalToBePaid?.grossAmount]);

  const onSubmit = useCallback(async () => {
    if (totalToBePaid === null) {
      console.error("totalToBePaid === null during onSubmit");
      return;
    }

    if (totalToBePaid.grossAmount > 0) {
      await preparePayment({
        foliosToBePaid,
        shopItems: dynamicShopCartItemsToShopItemsPaymentDTO(minibarCartItems)
      });
    } else if (minibarCartItems.length > 0 && totalToBePaid.grossAmount) {
      await bookFreeAdditionalServices(
        dynamicShopCartItemsToAdditionalServicesPaymentDTO(minibarCartItems)
      );
      dispatch(clearDynamicShopCart());
    }

    await updateFlowState({
      magicId,
      magicToken: reservation.magicToken,
      flowState: {
        context: {
          ...reservation.flowState.context,
          lastConfirmedCheckoutPage: currentPage
        }
      }
    });
    if (skipPayment) {
      navigate(generateCheckoutFlowConfirmationUrl(magicId));
    } else {
      navigate(generateCheckoutFlowPaymentPageUrl(magicId));
    }
  }, [
    dispatch,
    bookFreeAdditionalServices,
    minibarCartItems,
    skipPayment,
    navigate,
    magicId,
    preparePayment,
    foliosToBePaid,
    totalToBePaid,
    reservation.magicToken,
    reservation.flowState.context,
    updateFlowState
  ]);

  const { findItem } = useShopCmsTranslation(reservation.propertyId, ShopType.MinibarCheckoutFlow);

  const minibarCharges = useMemo(() => {
    return minibarCartItems
      .reduce((finalList: Charge[], minibarCartItem) => {
        if (minibarCartItem.quantity > 0) {
          finalList.push({
            name: findItem(minibarCartItem.serviceId)?.item_title ?? minibarCartItem.serviceId,
            totalQuantity: minibarCartItem.quantity,
            total: multiply(minibarCartItem.fullPrice, minibarCartItem.quantity),
            folioId: "",
            serviceId: minibarCartItem.serviceId
          });
        }
        return finalList;
      }, [])
      .sort(compareByStringProperty("name"));
  }, [minibarCartItems, findItem]);
  const isThereAnyMainFolio = reservation.folios.some((item) => item.isMainFolio);

  return (
    <FlowTemplate handleBack={goBack} icons={[]} progress={getCheckoutFlowProgress(currentPage)}>
      <GuestFlowBackgroundBox>
        <Box p={2}>
          <DesktopCard>
            <PageHeadingInfo
              title={t("title__checkout_flow_bill_page")}
              icon={<DisplayCmsSvg url={cmsData?.data?.icon__bill_icon?.url} />}
            />
            <Formik initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <Form>
                  {reservation.folios
                    .map((folio) => mapFolio(folio, reservation, t))
                    .map((item, index) => (
                      <FolioPreview
                        key={`id-${item.id}`}
                        folio={item}
                        reservation={reservation}
                        index={index}
                        additionalCharges={
                          item.isMainFolio || (!isThereAnyMainFolio && index === 0)
                            ? minibarCharges
                            : []
                        }
                      />
                    ))}
                  <SubmitButton
                    label={
                      skipPayment
                        ? t("buttons__next")
                        : `${t("buttons__pay")} ${formatPriceToString(
                            toGross(totalToBePaid ?? emptyFullPrice())
                          )}`
                    }
                  />
                </Form>
              )}
            </Formik>
          </DesktopCard>
        </Box>
      </GuestFlowBackgroundBox>
    </FlowTemplate>
  );
};
