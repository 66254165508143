import { useAppDispatch } from "../../../state/store";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { PaymentDTO, PaymentServicePaymentPayload } from "../payment-dto";
import { useInitiatePaymentMutation } from "../../../graphql/mutations/initiate-payment.generated";
import {
  PayAndCreateBookingMutation,
  usePayAndCreateBookingMutation
} from "../../../graphql/mutations/pay-and-create-booking.generated";
import { transformToGrossPrice } from "../../../graphql/transform/transform-payment";
import { initiatePayment, selectPaymentSlice } from "../payment.slice";
import { useSelector } from "react-redux";
import { mapQueryStatusToEntityStateStatus } from "../../../state/EntityStateStatus";
import { useCallback, useMemo } from "react";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import {
  prepareServicesForPaymentV2,
  prepareShopItemsForPaymentV2
} from "../../../graphql/transform/transform-additional-services-availability";

type BookingCreation = PayAndCreateBookingMutation["PayAndCreateBooking"];
type CreatedBooking = Extract<BookingCreation, { __typename: "Booking" }>;
type PaymentAction = Extract<BookingCreation, { __typename: "PaymentAction" }>;

interface InitiatePaymentProps {
  data: {
    paymentServicePayload: PaymentServicePaymentPayload;
    paymentDTO: PaymentDTO;
    referenceId: string;
  };
  magicId?: string;
}
export const useInitiatePayment = () => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();
  const initiatePaymentV1 = useCallback(
    (arg: InitiatePaymentProps) => dispatch(initiatePayment(arg)),
    [dispatch]
  );

  const [initiatePaymentMutation, { data: paymentData, status: initPaymentStatus }] =
    useInitiatePaymentMutation();
  const [
    payAndCreateBookingMutation,
    { data: payAndCreateBookingData, status: payAndCreateBookingStatus }
  ] = usePayAndCreateBookingMutation();

  const { paymentResponse: paymentResponseV1, paymentStatus: paymentStatusV1 } =
    useSelector(selectPaymentSlice);
  const initiatePaymentV2 = useCallback(
    (arg: InitiatePaymentProps) => {
      const additionalServices = arg.data.paymentDTO.additionalServices?.map(
        prepareServicesForPaymentV2
      );
      const shopItems = arg.data.paymentDTO.shopItems?.map(prepareShopItemsForPaymentV2);

      return arg.magicId
        ? initiatePaymentMutation({
            magicId: arg.magicId,
            paymentRequest: {
              amount: transformToGrossPrice(arg.data.paymentDTO.adyenPrice),
              frontendPayload: arg.data.paymentServicePayload,
              reference: arg.data.referenceId,
              services: [...(additionalServices ?? []), ...(shopItems ?? [])]
            }
          })
        : (() => {
            if ((arg.data.paymentDTO.bookingCreation?.reservations?.length || 0) > 0) {
              return payAndCreateBookingMutation({
                pmsPropertyId:
                  arg.data.paymentDTO.bookingCreation?.reservations?.find(
                    (reservation) => reservation.propertyId
                  )?.propertyId ?? "",
                booking: {
                  booker: arg.data.paymentDTO.bookingCreation!.booker,
                  reservations: arg.data.paymentDTO.bookingCreation!.reservations!.map((item) => ({
                    adultsAmount: item.adults,
                    arrival: item.arrival,
                    childrenAmount: item.childrenAges.length,
                    departure: item.departure,
                    pmsPropertyId: item.propertyId,
                    pmsRatePlanId: item.ratePlanId,
                    pmsUnitGroupId: item.unitGroupId
                  }))
                },
                payment: {
                  amount: transformToGrossPrice(arg.data.paymentDTO.adyenPrice),
                  frontendPayload: arg.data.paymentServicePayload,
                  reference: arg.data.referenceId
                }
              });
            }
            return Promise.reject("Cannot create booking with no reservations");
          })();
    },
    [initiatePaymentMutation, payAndCreateBookingMutation]
  );

  const transformCreatedBookingResponse = useCallback((response: CreatedBooking) => {
    return { bookingMagicId: response.magicId };
  }, []);

  const transformPaymentActionResponse = useCallback((response: PaymentAction) => {
    return {
      action: response.checkoutPaymentsAction
      //todo add type
    };
  }, []);

  const transformBookingCreationResponse = useCallback(
    (response: BookingCreation) => {
      if (response.__typename === "Booking") {
        return transformCreatedBookingResponse(response);
      }
      return transformPaymentActionResponse(response);
    },
    [transformCreatedBookingResponse, transformPaymentActionResponse]
  );

  const paymentStatusV2 = useCallback(() => {
    if (initPaymentStatus !== QueryStatus.uninitialized) {
      return mapQueryStatusToEntityStateStatus(initPaymentStatus);
    }
    return mapQueryStatusToEntityStateStatus(payAndCreateBookingStatus);
  }, [initPaymentStatus, payAndCreateBookingStatus]);

  const initiatePaymentAction = useMemo(
    () => (isRESTVersion ? initiatePaymentV1 : initiatePaymentV2),
    [isRESTVersion, initiatePaymentV1, initiatePaymentV2]
  );
  const paymentStatus = isRESTVersion ? paymentStatusV1 : paymentStatusV2();

  const paymentResponse = useMemo(() => {
    if (isRESTVersion) {
      return paymentResponseV1;
    }
    if (initPaymentStatus !== QueryStatus.uninitialized) {
      return paymentData;
    }
    return payAndCreateBookingData
      ? transformBookingCreationResponse(payAndCreateBookingData.PayAndCreateBooking)
      : null;
  }, [
    isRESTVersion,
    paymentResponseV1,
    paymentData,
    payAndCreateBookingData,
    initPaymentStatus,
    transformBookingCreationResponse
  ]);
  return { initiatePayment: initiatePaymentAction, paymentResponse, paymentStatus };
};
