import { Price } from "./price";
import { FullPrice } from "./full-price";
import { PromoCodeMagicDiscount, PromoCodeMagicDiscountType } from "./search-unit-group";

export interface BookingCartTotalPrice {
  prepaid: boolean;
  total: FullPrice;
  cityTaxes: FullPrice;
  accommodation: FullPrice;
  services: FullPrice;
  paymentType: PaymentType;
  discount: Price;
  promoCodeMagicDiscount?: PromoCodeMagicDiscount;
  promoCodeMagicDiscountPercentage?: number;
  promoCodeMagicDiscountType?: PromoCodeMagicDiscountType;
  promoCodeMagicDiscountHidden?: boolean;
  accommodationBeforeDiscount?: FullPrice;
}
export enum PaymentType {
  GUARANTEED_BY_COMPANY = "GUARANTEED_BY_COMPANY",
  GUARANTEE = "GUARANTEE",
  PREPAYMENT = "PREPAYMENT",
  PM6HOLD = "PM6HOLD"
}
