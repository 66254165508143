import {
  Fade,
  SpeedDialAction as MuiSpeedDialAction,
  SpeedDialActionProps as MuiSpeedDialActionProps
} from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  fab: {
    position: "absolute",
    margin: 0,
    width: 48,
    height: 48,
    pointerEvents: "auto",
    color: theme.palette.text.primary,

    "&:nth-of-type(1)": {
      transform: "rotate(180deg) translateY(100px) rotate(-180deg)",

      "&$fabClosed": {
        transform: "rotate(180deg) translateY(0) rotate(-180deg) scale(0)"
      }
    },

    "&:nth-of-type(2)": {
      transform: "rotate(135deg) translateY(100px) rotate(-135deg)",
      "&$fabClosed": {
        transform: "rotate(135deg) translateY(0) rotate(-135deg) scale(0)"
      }
    },

    "&:nth-of-type(3)": {
      transform: "rotate(90deg) translateY(100px) rotate(-90deg)",
      "&$fabClosed": {
        transform: "rotate(90deg) translateY(0) rotate(-90deg) scale(0)"
      }
    }
  },
  fabClosed: {
    pointerEvents: "none"
  },
  title: {
    position: "absolute",
    bottom: 34,
    right: 2,
    color: "white",
    fontSize: 14,

    "&:nth-of-type(1)": {
      transform: "rotate(180deg) translateY(100px) rotate(-180deg)"
    },

    "&:nth-of-type(2)": {
      transform: "rotate(135deg) translateY(100px) rotate(-135deg)"
    },

    "&:nth-of-type(3)": {
      transform: "rotate(90deg) translateY(100px) rotate(-90deg)"
    }
  }
}));

type SpeedDialActionProps = MuiSpeedDialActionProps;

export const SpeedDialAction: React.FC<React.PropsWithChildren<SpeedDialActionProps>> = (props) => {
  const { classes } = useStyles();
  return (
    <>
      <MuiSpeedDialAction
        classes={{ fab: classes.fab, fabClosed: classes.fabClosed }}
        tooltipOpen={false}
        disableHoverListener
        {...props}
      />
      <Fade in={props.open}>
        <p className={classes.title}>{props.title}</p>
      </Fade>
    </>
  );
};
