import React from "react";
import { keyframes, SvgIcon, SvgIconProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  checkIcon: {
    strokeDasharray: 510,
    strokeDashoffset: 500,
    animation: `${keyframes`
     0% {
      strokeDasharray: 510,
    }
    100% {
      strokeDasharray: 1050,
    },`} 2s cubic-bezier(0.25, 0.1, 0.25, 1.0) forwards`
  }
}));

export function SuccessCircle(props: SvgIconProps) {
  const { classes } = useStyles();
  return (
    <SvgIcon viewBox="0 0 175.7 175.7" {...props} className={classes.checkIcon}>
      <style type="text/css">
        {`.st0{fill:none;stroke:#020203;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;}`}
      </style>
      <circle id="Circle" className="st0" cx="87.9" cy="87.9" r="85.4" />
      <polyline id="Check" className="st0" points="43.7,91.5 80.6,128.4 132.2,47.4 " />
    </SvgIcon>
  );
}
