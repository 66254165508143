import React from "react";
import { RoundBox, RoundBoxProps } from "../round-box/round-box";
import { useRoundedFullHeightStyles } from "./hooks/use-rounded-full-height-styles";

export interface RoundedFullHeightProps extends RoundBoxProps {
  subtrahend?: number;
}

export const RoundedFullHeight: React.FC<React.PropsWithChildren<RoundedFullHeightProps>> = (
  props
) => {
  const { classes } = useRoundedFullHeightStyles(props);
  return <RoundBox className={classes.box} {...props} />;
};
