import { useEffect } from "react";

import { Reservation } from "../../../domain-common/reservation";
import { GuestFlowCheckpoint } from "./guest-flow-checkpoint";
import { useGuestFlowCheckpoint } from "./use-guest-flow-checkpoint";

export const useSaveGuestFlowSessionCheckpoint = (
  reservation: Reservation,
  checkpoint: GuestFlowCheckpoint,
  checkpointArgs?: any
) => {
  const { save } = useGuestFlowCheckpoint();

  useEffect(() => {
    if (!reservation) {
      return;
    }
    save(reservation, checkpoint, checkpointArgs);
  }, [reservation, save, checkpoint, checkpointArgs]);
};
