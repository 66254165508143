import { getLocalStorageObject } from "../state/local-storage-state";

const LOCAL_STORAGE_SECOND_SCREEN_ID = "second_screen_id";

export const getSecondScreenId = () => {
  const storageValue = getLocalStorageObject(LOCAL_STORAGE_SECOND_SCREEN_ID);
  return (
    Object.keys(storageValue).length !== 0 ? storageValue.secondScreenId : undefined
  ) as string;
};

export const setSecondScreenId = (secondScreenId: string | null) => {
  try {
    if (secondScreenId) {
      localStorage.setItem(LOCAL_STORAGE_SECOND_SCREEN_ID, JSON.stringify({ secondScreenId }));
    }
  } catch (e) {
    console.error("Failed to set second screen id in local storage", e);
  }
};

export const isSecondScreenMode = () => {
  return getSecondScreenId() !== undefined;
};
