import { FC } from "react";
import { Input, InputProps } from "@likemagic-tech/sv-magic-library";
import { useThemeOverrideConfig } from "../../../util/hooks/use-configuration";

export const WrappedInput: FC<InputProps> = ({ children, ...props }) => {
  const themeOverrideConfig = useThemeOverrideConfig();

  return (
    <Input {...props} variant={themeOverrideConfig?.input?.variant ?? undefined}>
      {children}
    </Input>
  );
};
