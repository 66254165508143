import * as jsonpatch from "fast-json-patch";
import {
  AddOperation,
  CopyOperation,
  GetOperation,
  MoveOperation,
  Operation,
  RemoveOperation,
  ReplaceOperation,
  TestOperation
} from "fast-json-patch";
import { deepCopy } from "./data";

export function isValueOperation(
  arg: any
): arg is AddOperation<any> | ReplaceOperation<any> | TestOperation<any> | GetOperation<any> {
  return arg.value !== undefined;
}

export function getPatches<T>(original: T, modified: T): Operation[] {
  const originalToCompare: any = deepCopy(original);
  const reservationToCompare: any = deepCopy(modified);

  const patches = jsonpatch.compare(originalToCompare, reservationToCompare);
  return patches.map((patch) => {
    if (isValueOperation(patch) && typeof patch.value === "string") {
      patch.value = patch.value.trim();
      if (patch.value === "") {
        return { path: patch.path, op: "remove" };
      }
    }
    return patch;
  });
}

function addMissingObjects<T>(
  original: T,
  operation:
    | AddOperation<any>
    | RemoveOperation
    | ReplaceOperation<any>
    | MoveOperation
    | CopyOperation
    | TestOperation<any>
    | GetOperation<any>
) {
  const objectToPatch = { ...original };
  let properties = operation.path.split("/");
  properties.shift();
  properties.pop();
  let objectToFix: any = objectToPatch;
  for (let prop of properties) {
    if (objectToFix[prop] === undefined || objectToFix[prop] === null) {
      objectToFix[prop] = {};
    }
    objectToFix = objectToFix[prop];
  }
  return objectToPatch;
}

export function applyPatch<T>(original: T, patches: Operation[]): T {
  let objectToPatch = { ...original };
  patches.forEach((op) => {
    objectToPatch = addMissingObjects(objectToPatch, op);
  });

  return jsonpatch.applyPatch(objectToPatch, patches).newDocument;
}

export function applyOperation<T>(original: T, operation: Operation): T {
  const objectToPatch = addMissingObjects(original, operation);

  return jsonpatch.applyOperation(objectToPatch, operation).newDocument;
}
