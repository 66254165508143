import { isTravelBuddy } from "../../../../util/flow";
import { Grid } from "@mui/material";
import { PaperButton } from "../../../../components";
import { generatePortalMyStayBillsUrl } from "../../../../util/routing";
import React, { FC, useMemo } from "react";
import { Reservation } from "../../../../domain-common/reservation";
import { useNavigate } from "react-router-dom";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../../state/common-cms/common-cms.slice";

interface NavigationInvoiceProps {
  reservation: Reservation;
  title?: string;
  iconUrl?: string;
}

export const NavigationInvoice: FC<NavigationInvoiceProps> = ({ reservation, title, iconUrl }) => {
  const magicId = useMemo(() => reservation.magicId, [reservation.magicId]);
  const navigate = useNavigate();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  if (reservation?.folios && !isTravelBuddy(reservation)) {
    return (
      <Grid item xs={6}>
        <PaperButton
          title={title ?? t("navigations__invoice")}
          small
          onClick={() => {
            navigate(generatePortalMyStayBillsUrl(magicId));
          }}
          iconUrl={iconUrl ?? cmsData?.data?.icons__my_stay_navigation_invoice?.url}
        />
      </Grid>
    );
  } else {
    return null;
  }
};
