import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function KeyMainDoorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>
          {`.cls-1{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}`}
        </style>
      </defs>
      <title>real-estate-deal-key</title>
      <path className="cls-1" d="M20.25,12v9a1.5,1.5,0,0,1-1.5,1.5H8.25A1.5,1.5,0,0,1,6.75,21" />
      <line className="cls-1" x1="23.25" y1="9.75" x2="13.5" y2="1.5" />
      <line className="cls-1" x1="3.75" y1="9.75" x2="13.5" y2="1.5" />
      <polyline className="cls-1" points="18.75 2.25 21.75 2.25 21.75 5.25" />
      <circle className="cls-1" cx="3.75" cy="15.75" r="3" />
      <polyline className="cls-1" points="6.75 15.75 14.25 15.75 14.25 18" />
      <line className="cls-1" x1="11.25" y1="15.75" x2="11.25" y2="17.25" />
    </SvgIcon>
  );
}
