import SaltoSdk from "../../capacitor-plugins/salto-sdk-plugin";
import { DoorProvider } from "@likemagic-tech/sv-magic-library";
import SeosMobileSDK from "../../capacitor-plugins/seos-mobile-sdk-plugin";

export const openDoor = (doorProvider: DoorProvider, token: any) => {
  if (doorProvider === DoorProvider.SALTO_SPACE_MOBILE_KEY) {
    return SaltoSdk.openDoor({ mobileKeyToken: token.toString() });
  } else {
    return SeosMobileSDK.startScanning({});
  }
};

export const stopScanning = (doorProvider: DoorProvider) => {
  return SeosMobileSDK.stopScanning({});
};

const keyAssignedAvailableFor = [
  DoorProvider.FOUR_SUITES,
  DoorProvider.SALTO_SPACE_MOBILE_KEY,
  DoorProvider.SALTO_SPACE_ONLINE,
  DoorProvider.SALTO,
  DoorProvider.HAFELE,
  DoorProvider.AA_VISIONLINE,
  DoorProvider.AA_VOSTIO
];

export const isKeyAssignAvailable = (selectedDoorProvider?: DoorProvider) =>
  keyAssignedAvailableFor.find((doorProvider) => selectedDoorProvider === doorProvider);
