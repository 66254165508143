import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { MyStayPagesEnum, MyStayPageToPath } from "../portal/my-stay-page-to-path";
import { MyStayServicesPage } from "../portal/pages/my-stay-services.page";
import { ServiceDetailPage } from "./service-detail-page";
import { BookedServiceDatesList } from "./booked-services/booked-service-dates-list";
import { NotFoundPage } from "../../components/organism/not-found.page";

export const ServiceRouterPage: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Routes>
      <Route path={`/`} element={<MyStayServicesPage />} />
      <Route
        path={`${MyStayPageToPath[MyStayPagesEnum.SERVICES_DETAIL]}`}
        element={<ServiceDetailPage />}
      />
      <Route
        path={`${MyStayPageToPath[MyStayPagesEnum.SERVICES_LIST]}`}
        element={<BookedServiceDatesList />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
