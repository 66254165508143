import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function ArrowRight1Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>
          {`.cls-1{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;fill-rule:evenodd;}`}
        </style>
      </defs>
      <title>arrow-right-1</title>
      <path className="cls-1" d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25" />
    </SvgIcon>
  );
}
