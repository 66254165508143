import { RootStore } from "../../../state/store";
import { persistItemsToLocalStorage } from "../guest-flow-store-local-storage";
import {
  additionalServiceCartSlice,
  AdditionalServicesCartState
} from "./additional-services-cart.slice";

let currentValue: AdditionalServicesCartState;
export const additionalServicesCartStoreListener = (storeArg: RootStore) => {
  let previousValue = currentValue;
  currentValue = storeArg.getState()[additionalServiceCartSlice.name];
  const magicId = storeArg.getState()["magicObject"].magicObject?.magicId;

  // whenever changes
  if (magicId && currentValue.entities !== previousValue?.entities) {
    // and persist in local storage
    persistItemsToLocalStorage(magicId, {
      entities: currentValue.entities,
      ids: currentValue.ids
    });
  }

  const magicObjectV2 = Object.values(storeArg.getState().graphql.queries).filter(
    (item) => item?.endpointName === "GetMagicObject"
  )[0];
  // @ts-ignore
  const magicIdV2 = magicObjectV2?.originalArgs?.magicId;
  if (magicIdV2 && currentValue.entities !== previousValue.entities) {
    // persist in local storage
    persistItemsToLocalStorage(magicIdV2, {
      entities: currentValue.entities,
      ids: currentValue.ids
    });
  }
};
