import React, { FC, PropsWithChildren, useContext, useMemo } from "react";
import * as prismic from "@prismicio/client";
import { Client } from "@prismicio/client";
import { PrismicProvider } from "@prismicio/react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";

export const CmsClientProvider: FC<PropsWithChildren> = ({ children }) => {
  const { prismic: tenantPrismicConfig } = useContext(TenantContext);

  const client: Client = useMemo(() => {
    const apiEndpoint = tenantPrismicConfig?.apiEndpoint;
    const accessToken = tenantPrismicConfig?.accessToken;
    if (apiEndpoint) {
      return prismic.createClient(apiEndpoint, { accessToken });
    }
    throw new Error("Prismic has not been configured properly, apiEndpoint is missing");
  }, [tenantPrismicConfig?.apiEndpoint, tenantPrismicConfig?.accessToken]);

  return <PrismicProvider client={client}>{children}</PrismicProvider>;
};
