import { TenantContext, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MagicApi } from "../../api/magic.api";
import { useAppDispatch } from "../../state/store";
import { api as requestAccessApi } from "../../graphql/mutations/request-access.generated";

export const useRequestAccess = ({ magicId }: { magicId: string }) => {
  const { isRESTVersion } = useApiVersion();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { keycloak } = useContext(TenantContext);

  const v1 = useCallback(async () => {
    await MagicApi.requestAccess(
      { magicId },
      {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        }
      }
    )
      .then(() => navigate("/"))
      .catch(() => navigate("/"));
  }, [magicId, navigate, keycloak.token]);

  const v2 = useCallback(async () => {
    dispatch(
      requestAccessApi.endpoints.RequestAccess.initiate({
        magicId: magicId
      })
    )
      .then(() => navigate("/"))
      .catch(() => navigate("/"));
  }, [magicId, navigate, dispatch]);

  return isRESTVersion ? v1 : v2;
};
