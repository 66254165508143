import i18n from "i18next";
import Backend from "i18next-chained-backend";
import { initReactI18next } from "react-i18next";
import { PrismicData } from "../../api/cms-client/use-cms-client";
import { AppDispatch, RootStore } from "../../state/store";
import { I18nCmsReduxBackend } from "./i18n-cms-redux-backend";

interface I18nInitOptions {
  store: RootStore;
  dispatch: AppDispatch;
  prismic?: PrismicData;
  commonPrismic?: PrismicData;
  commonPrismicType?: string;
  fallbackLanguage: string;
  allowedLocales: Array<string>;
  queryLanguage?: string;
}

i18n
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
export const initI18n = ({
  store,
  dispatch,
  prismic,
  commonPrismic,
  commonPrismicType,
  fallbackLanguage,
  allowedLocales,
  queryLanguage
}: I18nInitOptions) => {
  return i18n.init({
    load: "currentOnly",
    fallbackLng: allowedLocales[0] || fallbackLanguage,
    debug: import.meta.env.DEV,
    lng: queryLanguage || allowedLocales[0] || fallbackLanguage,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true
    },
    backend: {
      backends: [I18nCmsReduxBackend],
      backendOptions: [
        {
          store,
          dispatch,
          prismic,
          commonPrismic,
          commonPrismicType,
          allowedLocales
        }
      ]
    },
    supportedLngs: allowedLocales,
    lowerCaseLng: true,
    preload: false
    //detection will be overridden in the language.selector.tsx
  });
};

export default i18n;
