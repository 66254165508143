import { Chip, Heading4, Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box, Grid } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { formatPriceToString } from "../price-preview/price-preview";
import { FullPrice, multiply, toGross } from "../../../domain-common/full-price";
import { PaperRadioButton } from "../paper-radio-button/paper-radio-button";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";

interface AdditionalServiceButtonProps {
  icon?: ReactElement;
  serviceName: string;
  price: FullPrice;
  quantity: number;
  onClick?: () => void;
  useWhiteBackground?: boolean;
  isRent?: boolean;
  rentLabels?: {
    chargedIfDamaged: string;
    rent: string;
  };
}
export const AdditionalServiceButton: FC<AdditionalServiceButtonProps> = ({
  serviceName,
  icon,
  price,
  onClick,
  quantity,
  useWhiteBackground,
  isRent,
  rentLabels
}) => {
  const isMobile = useIsMobile();
  return (
    <PaperRadioButton
      icon={icon}
      onClick={onClick}
      selected={quantity > 0}
      useWhiteBackground={useWhiteBackground}
      content={
        <Grid container direction="column">
          <Grid item>
            <Grid container direction={isMobile ? "column-reverse" : "row"} spacing={0.5}>
              <Grid item>
                <Paragraph mt={0.125} gutterBottom align="left">
                  {serviceName}
                </Paragraph>
              </Grid>
              <Grid item textAlign="left">
                {isRent && rentLabels?.rent && (
                  <Chip color="info" label={rentLabels.rent} size="small" />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item textAlign="left">
            <Box display={isRent ? "block" : "inline-flex"}>
              {isRent && rentLabels?.chargedIfDamaged ? (
                <Paragraph color="textSecondary">{rentLabels.chargedIfDamaged}</Paragraph>
              ) : (
                <Heading4 align="left">
                  {formatPriceToString(toGross(multiply(price, quantity > 0 ? quantity : 1)))}
                </Heading4>
              )}

              {quantity > 1 && (
                <Paragraph ml={isRent ? 0 : 0.5}>
                  ({quantity} x {formatPriceToString(toGross(price))})
                </Paragraph>
              )}
            </Box>
          </Grid>
        </Grid>
      }
    />
  );
};
