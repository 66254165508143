import { Button, Heading1, Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box, Dialog, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useCallback, useState } from "react";
import { AddIcon, MinusIcon, PlusIcon } from "../../../components/icons";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { emptyPrice } from "../../../domain-common/price";
import { FullPrice, toGross } from "../../../domain-common/full-price";

interface AddToCartDialogProps {
  product: { id: string; fullPrice: FullPrice } | null;
  onClose: (arg?: { count: number }) => void;
  primaryButtonLabel: string;
  secondaryButtonLabel?: string;
  image?: string;
  fallbackImage: string;
  title: string;
  initialCounter?: number;
}

const useStyles = makeStyles<{ imgSrc: string }>()((theme, props) => ({
  paper: {
    borderRadius: theme.shape.borderRadius * 2.5,
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  icon: {
    "&.Mui-disabled": {
      opacity: 0.5
    }
  },
  image: {
    backgroundImage: `url("${props.imgSrc}")`,
    height: 140,
    width: "100%",
    display: "block",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginBottom: theme.spacing(2)
  },
  counter: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(2)
  }
}));

export const AddToCartDialog: React.FC<React.PropsWithChildren<AddToCartDialogProps>> = ({
  product,
  onClose,
  primaryButtonLabel,
  secondaryButtonLabel,
  image,
  fallbackImage,
  title,
  initialCounter
}) => {
  const [count, setCount] = useState<number>(initialCounter ?? 1);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);
  const handleAdd = useCallback(() => {
    onClose({ count });
  }, [count, onClose]);

  const clearOut = useCallback(() => {
    onClose({ count: 0 });
  }, [onClose]);

  const handleOnExited = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const imgSrc = image ?? fallbackImage;
  const { classes } = useStyles({ imgSrc });
  return (
    <Dialog
      open
      classes={{ paper: classes.paper }}
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onExited: handleOnExited
      }}
    >
      <IconButton className={classes.closeButton} size="small" onClick={handleClose}>
        <AddIcon style={{ transform: "rotate(45deg)" }} />
      </IconButton>
      <Box display="flex" flexDirection="column" p={2}>
        <Box px={3.5} pt={1} pb={2}>
          <Paragraph align="center">{title || product?.id}</Paragraph>
        </Box>
        <div className={classes.image} />
        <div className={classes.counter}>
          <IconButton
            className={classes.icon}
            size="small"
            disabled={count <= 1}
            onClick={() => setCount((c) => Math.max(1, c - 1))}
          >
            <MinusIcon fontSize={"small"} />
          </IconButton>
          <Box px={2}>
            <Heading1>{count}</Heading1>
          </Box>
          <IconButton size="small" onClick={() => setCount((c) => c + 1)}>
            <PlusIcon fontSize={"small"} />
          </IconButton>
        </div>
        <Box display="flex" alignItems="baseline" justifyContent="center" mb={2}>
          <Paragraph>
            {formatPriceToString(product ? toGross(product.fullPrice) : emptyPrice())}
          </Paragraph>
        </Box>
        <Button variant="primary" size="small" onClick={handleAdd}>
          {primaryButtonLabel}
        </Button>
        {secondaryButtonLabel && (
          <Button sx={{ mt: 1 }} variant="secondary" size="small" onClick={clearOut}>
            {secondaryButtonLabel}
          </Button>
        )}
      </Box>
    </Dialog>
  );
};
