import { Reservation } from "../domain-common/reservation";
import { emptyTranslationContext } from "../features/magic/translation-context";
import { formatPriceToString } from "../components/atoms/price-preview/price-preview";
import { utcToZonedTime } from "date-fns-tz";
import {
  dateOrNull,
  daysBeforeNow,
  formatRange,
  formatTime
} from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "./lang-utils";

export function buildTranslationContext(source: any, defaultValues: any) {
  if (source instanceof Date) {
    return source.toLocaleDateString();
  }
  if (
    typeof defaultValues === "string" ||
    typeof defaultValues === "number" ||
    typeof defaultValues === "boolean"
  ) {
    return source ?? defaultValues;
  }

  const target: Record<any, any> = {};
  Object.keys(defaultValues).forEach((key) => {
    target[key] = buildTranslationContext(source?.[key], defaultValues[key]);
  });
  return target;
}

export const buildReservationComputedTranslationContext = (
  reservation: Reservation,
  deps?: { timeZone?: string }
) => {
  const arrival = dateOrNull(reservation?.arrival);

  return {
    stayRange: formatRange(
      deps?.timeZone,
      getI18nSelectedLanguage(),
      reservation?.arrival,
      reservation?.departure
    ),
    checkinCountdownDays: daysBeforeNow(reservation?.arrival),
    departureTime: reservation?.departure
      ? formatTime(reservation?.departure, getI18nSelectedLanguage(), deps?.timeZone)
      : "",
    arrivalTime: reservation?.arrival
      ? formatTime(reservation?.arrival, getI18nSelectedLanguage(), deps?.timeZone)
      : "",
    arrivalDate: arrival ? utcToZonedTime(arrival, deps?.timeZone || "").toLocaleDateString() : "",
    cancellationFeeAmount: reservation.cancellationFee
      ? formatPriceToString({
          amount: reservation.cancellationFee?.fee.amount * 100,
          currency: reservation.cancellationFee?.fee.currency
        })
      : ""
  };
};

export const buildReservationTranslationContext = (
  reservation: Reservation,
  deps?: { timeZone?: string }
) => {
  const computed = buildReservationComputedTranslationContext(reservation, deps);
  return buildTranslationContext(
    {
      reservation: {
        ...reservation,
        guest: reservation.primaryGuest || reservation.travelBuddy
      },
      timeZone: deps?.timeZone,
      computed
    },
    emptyTranslationContext
  );
};
