import React, { FC, useCallback } from "react";
import { Box, Divider, List } from "@mui/material";
import { HelmetTitle } from "../../gtm/helmet-title";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { DisplayCmsSvg, SubmitButton } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useSetupGuestFlowNavigation } from "../hooks/use-setup-guest-flow-navigation";
import { useProfilePage } from "../../portal/hooks/use-profile-page";
import { ProfilePagesEnum } from "../../portal/profile-page-to-path";
import { generateMagicLink, generatePortalProfilePagesUrl } from "../../../util/routing";
import { isTravelBuddy } from "../../../util/flow";
import { filterPagesForTravelBuddy } from "../../portal/travel-buddy-utils";
import { Paragraph, useCountries } from "@likemagic-tech/sv-magic-library";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { useGuestFlow } from "../use-guest-flow";
import { useNavigate } from "react-router-dom";
import { getNextFlowUrl } from "../guest-flow-sequence";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import {
  getItemsTitle,
  getStepDetails,
  getStepIcon
} from "../../../components/molecules/step-preview-item/step-preview-items-util";
import { StepPreviewItem } from "../../../components/molecules/step-preview-item/step-preview-item";
import { calculateCurrentPageForTermsAndConditionPageBasedOnServiceAvailability } from "../../../util/reservation";
import { useFetchAdditionalServices } from "../../services/use-fetch-additional-services";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { usePropertyById } from "../../property/use-property-by-id";
import { useAllowedLocales } from "../../../util/hooks/use-configuration";
import { Visibility } from "../../../api/dto/additional-services-availability-response";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

export const generateFastGuestFlowLink = (magicId: string) =>
  `${generateMagicLink(magicId)}/booking/guest/quick`;

const fastGuestFlowPageItems = [
  ProfilePagesEnum.PERSONAL,
  ProfilePagesEnum.COMMUNICATION,
  ProfilePagesEnum.LEGAL,
  ProfilePagesEnum.ADDRESS,
  ProfilePagesEnum.LANGUAGE
];
export const FastGuestFlowPage: FC<React.PropsWithChildren<unknown>> = () => {
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { magicId } = useMagicIdParams();
  const allowedLocales = useAllowedLocales();
  const isMobile = useIsMobile();
  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });
  const { reservation } = useReservationContext();
  const { isBottomNavbarActive } = useBottomNavbar();
  const { errorsPerPage } = useProfilePage(reservation);
  const { patchGuestFlowStep } = useGuestFlow();
  const navigate = useNavigate();
  const property = usePropertyById(reservation?.propertyId);
  const { getCountryNameByCode } = useCountries({ propertyId: reservation?.propertyId });

  // the fetch is needed to calculate the steps in case we need to skip the additional service page.
  const { selectableAdditionalServices } = useFetchAdditionalServices(
    reservation.magicId,
    Visibility.GUEST_FLOW,
    isTravelBuddy(reservation)
  );

  const getCountryNameByCodeLocal = useCallback(
    (code: string) => {
      return getCountryNameByCode(getI18nSelectedLanguage(), code);
    },
    [getCountryNameByCode]
  );

  const links = React.useMemo(() => {
    return fastGuestFlowPageItems
      .filter((page) => (isTravelBuddy(reservation) ? filterPagesForTravelBuddy(page) : true))
      .map((pageId) => {
        return {
          to: generatePortalProfilePagesUrl(magicId, pageId),
          label: t(getItemsTitle(pageId)),
          details: (
            <>
              {getStepDetails(
                reservation,
                pageId,
                t,
                allowedLocales,
                property.timeZone,
                getCountryNameByCodeLocal
              ).map(
                (item, index) =>
                  item && <Paragraph key={`${pageId}-item-${index}`}>{item}</Paragraph>
              )}
            </>
          ),
          icon: getStepIcon(pageId) ? (
            <DisplayCmsSvg color="secondary" url={cmsData?.data[getStepIcon(pageId)]?.url} />
          ) : undefined,
          infoWarningLabel: errorsPerPage[pageId]
            ? "validations__information_missing_message"
            : undefined
        };
      });
  }, [
    getCountryNameByCodeLocal,
    errorsPerPage,
    t,
    reservation,
    magicId,
    cmsData?.data,
    allowedLocales,
    property.timeZone
  ]);

  const onSubmit = useCallback(async () => {
    await patchGuestFlowStep({
      reservationValues: reservation,
      subUpdate: SubUpdate.PERSONAL_DATA,
      checkpoint: !!selectableAdditionalServices.length
        ? GuestFlowCheckpoint.ADDRESS
        : GuestFlowCheckpoint.SERVICES
    });
    navigate(
      getNextFlowUrl(
        calculateCurrentPageForTermsAndConditionPageBasedOnServiceAvailability(
          reservation,
          !!selectableAdditionalServices.length
        ),
        reservation
      )
    );
  }, [navigate, reservation, patchGuestFlowStep, selectableAdditionalServices.length]);

  return (
    <>
      <HelmetTitle suffix="Fast checkin" />
      <FlowTemplate>
        <GuestFlowBackgroundBox progressBarIsHidden>
          <Box p={5}>
            <PageHeadingInfo
              title={t("title__quick_checkin")}
              icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
            />
          </Box>
          <Box px={2.5}>
            <List component="nav">
              {isMobile && <Divider />}
              {links.map((l) => (
                <React.Fragment key={l.to}>
                  <StepPreviewItem
                    to={l.to}
                    label={l.label}
                    icon={l.icon}
                    details={l.details}
                    infoWarningLabel={l.infoWarningLabel}
                    fastCheckin
                  />
                  {isMobile && <Divider />}
                </React.Fragment>
              ))}
            </List>

            <SubmitButton
              onClick={onSubmit}
              label={t("buttons__next")}
              hasBottomNavigation={isBottomNavbarActive}
              hasWhiteBackground
            />
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
