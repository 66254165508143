import { createSlice, Selector } from "@reduxjs/toolkit";
import { CmsSingleDocumentPerPropertyType } from "../cms/cms-single-document-per-property-type";
import { CMSPageState } from "../cms/cms-single-page-factory";
import {
  createCMSSingleDocumentPerPropertyFetchThunk,
  createCMSSinglePagePerPropertyAdapter
} from "../cms/cms-single-page-per-property-factory";
import { RootState } from "../store";

export type PropertyCMSState = CMSPageState;
export const shopCMSAdapter = createCMSSinglePagePerPropertyAdapter();

const initialState: PropertyCMSState = shopCMSAdapter.getInitialState();

export const fetchShopCMS = createCMSSingleDocumentPerPropertyFetchThunk(
  CmsSingleDocumentPerPropertyType.shop
);

export const shopCmsSlice = createSlice({
  name: "shopCMS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopCMS.pending, shopCMSAdapter.handlePendingLoadingStatus)
      .addCase(fetchShopCMS.fulfilled, (state, action) => {
        shopCMSAdapter.handleFulfilledLoadingStatus(state, action);
        shopCMSAdapter.addOne(state, action);
      })
      .addCase(fetchShopCMS.rejected, shopCMSAdapter.handleRejectedLoadingStatus);
  }
});

export const selectSelf: Selector<RootState, PropertyCMSState> = (state: RootState) =>
  state.cmsPerProperty[CmsSingleDocumentPerPropertyType.shop];

export const { selectAll: selectShopCMS, selectById: selectShopCMSById } =
  shopCMSAdapter.getSelectors<RootState>(selectSelf);
