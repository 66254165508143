import React, { useMemo } from "react";
import { ContactButtonNavigation } from "../../../components/molecules/contact-button-navigation";
import { SearchBookingCartButtonNavigation } from "../../../components/molecules/search-booking-cart-button-navigation";
import { TopNavigationButton } from "../../../components";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useNavigate } from "react-router-dom";
import { isKioskMode } from "../../../util/kiosk-mode";

export const useSearchBookNavigation = () => {
  const contactButton = useMemo(() => {
    return <ContactButtonNavigation key="contactButton" />;
  }, []);
  const cartButton = useMemo(
    () => <SearchBookingCartButtonNavigation key="cartButtonNavigation" />,
    []
  );
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const navigate = useNavigate();
  const logoutButton = useMemo(
    () => (
      <TopNavigationButton
        key="logout"
        onClick={() => {
          navigate("/logout");
        }}
      >
        {t("buttons__logout")}
      </TopNavigationButton>
    ),
    [t, navigate]
  );

  const availableButtons = useMemo(
    () => (isKioskMode() ? [logoutButton] : [cartButton, contactButton]),
    [logoutButton, cartButton, contactButton]
  );

  return {
    contactButton,
    cartButton,
    logoutButton,
    availableButtons
  };
};
