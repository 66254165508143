import { Grid } from "@mui/material";
import React, { FC, useCallback, useMemo } from "react";
import { formatDate, formatTime, Paragraph } from "@likemagic-tech/sv-magic-library";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  generatePortalMyStayAdditionalBookedServicesDetailsUrl,
  generatePortalMyStayAdditionalBookedServicesListUrl
} from "../../../util/routing";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { useServiceDetails } from "../use-service-details";
import { OverviewService, ServiceTag } from "../../../domain-common/overview-service";
import { BookedServiceButton } from "./booked-service-button";
import { toGross } from "../../../domain-common/full-price";
import { MagicServiceCodeEnum } from "../../../domain-common/magic-service-code-enum";
import { Reservation } from "../../../domain-common/reservation";
import { BookedServiceTag } from "./booked-service-tag";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

interface BookedServiceItemProps {
  service: OverviewService;
  reservation: Reservation;
  timeZone: string;
  extensionReadOnly?: boolean;
}

export const BookedServiceItem: FC<React.PropsWithChildren<BookedServiceItemProps>> = ({
  service,
  reservation,
  timeZone,
  extensionReadOnly
}) => {
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  //Backwards compatibility
  const { data: dataByServiceId } = useServiceDetails(service.service.id);
  const { data: dataByServiceCode } = useServiceDetails(service.service.code);

  const isNextPageAvailable = useMemo(() => {
    const data = dataByServiceCode || dataByServiceId;
    if (!extensionReadOnly) {
      return service.dates.length > 1 && !!data;
    } else {
      return false;
    }
  }, [service.dates.length, dataByServiceId, dataByServiceCode, extensionReadOnly]);

  const onClick = useCallback(
    (serviceDate: string) => () => {
      const id = dataByServiceId ? service.service.id : service.service.code;

      if (service.dates.length === 1 && id && !extensionReadOnly) {
        const isServiceWithKey = service?.service?.tags?.includes(ServiceTag.KEY);

        navigate({
          pathname: generatePortalMyStayAdditionalBookedServicesDetailsUrl(
            reservation.magicId
          ).replace(":serviceId", id!),
          search: isServiceWithKey
            ? createSearchParams({
                serviceDate
              }).toString()
            : ""
        });
      } else if (id && !extensionReadOnly) {
        navigate(
          generatePortalMyStayAdditionalBookedServicesListUrl(reservation.magicId).replace(
            ":serviceId",
            id
          )
        );
      }
    },
    [reservation.magicId, service, navigate, dataByServiceId, extensionReadOnly]
  );

  const additionalInfo = useMemo(() => {
    switch (service.service.magicServiceCodeEnum) {
      case MagicServiceCodeEnum.LATE_CHECKOUT:
        return formatTime(reservation.departure, getI18nSelectedLanguage(), timeZone) || "";
      case MagicServiceCodeEnum.EARLY_CHECKIN:
        return formatTime(reservation.arrival, getI18nSelectedLanguage(), timeZone) || "";

      default:
        return service.dates.length > 1 ? `[${service.dates.length}]` : undefined;
    }
  }, [
    service.service.magicServiceCodeEnum,
    service.dates.length,
    reservation.arrival,
    reservation.departure,
    timeZone
  ]);

  return (
    <BookedServiceButton
      isNextPageAvailable={isNextPageAvailable}
      onClick={onClick(service?.dates[0]?.serviceDate)}
      amount={toGross(service.service.totalAmount)}
      propertyId={reservation.propertyId}
      service={service.service}
      additionalInfo={additionalInfo}
    >
      <Grid container flexWrap="wrap" alignItems="center">
        {service.dates.length === 1 ? (
          <Grid mr={0.5}>
            <Paragraph textAlign={isMobile ? "left" : "center"}>
              {formatDate(service.dates[0].serviceDate, getI18nSelectedLanguage(), timeZone)}
            </Paragraph>
          </Grid>
        ) : null}

        <Grid item>
          <Grid container spacing={1} alignItems="center">
            {service.service.tags.map((serviceTag) => (
              <Grid item key={`${service.service.code}-${serviceTag}`}>
                <BookedServiceTag tag={serviceTag} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </BookedServiceButton>
  );
};
