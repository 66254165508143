import { Button, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import React, { FC, useCallback } from "react";

interface CancellationDialogProps {
  cancelReservationAction: (() => Promise<void>) | undefined;
  labels: CancellationDialogLabels;
}

interface CancellationDialogLabels {
  buttonLabel: string;
  dialogTitle: string;
  dialogConfirmButtonLabel: string;
  dialogTextWithForCancellationPolicy: string;
  dialogTextForExternalReservation: string;
}

export const CancellationReservationDialog: FC<
  React.PropsWithChildren<CancellationDialogProps>
> = ({ cancelReservationAction, labels }) => {
  const { open: openModal } = useGlobalModal();

  const handleModal = useCallback(async () => {
    const textToBeShown =
      cancelReservationAction !== undefined
        ? labels.dialogTextWithForCancellationPolicy
        : labels.dialogTextForExternalReservation;
    const result = await openModal({
      modalProps: {
        title: labels.dialogTitle,
        content: <>{textToBeShown}</>
      },
      modalActions: cancelReservationAction
        ? [
            {
              variant: "primary",
              label: labels.buttonLabel,
              result: cancelReservationAction !== undefined
            }
          ]
        : [
            {
              variant: "primary",
              label: labels.dialogConfirmButtonLabel,
              result: false
            }
          ]
    });

    if (result && cancelReservationAction) {
      await cancelReservationAction();
    }
  }, [cancelReservationAction, labels, openModal]);

  return (
    <Button data-testid="cancel_button" variant="secondary" fullWidth onClick={handleModal}>
      {labels.buttonLabel}
    </Button>
  );
};
