import { createAsyncThunk, createSelector, createSlice, Selector } from "@reduxjs/toolkit";
import { PromoCodeAPI } from "../../api/promocode.api";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { getTenantHeaders } from "../../api/custom-headers";
import { handleSliceError } from "../../util/error-handling";

export enum PromoCodeType {
  MAGIC = "MAGIC",
  PMS = "PMS"
}

export type PromoCodeState = {
  validateStatus: EntityStateStatus;
  promoCode?: string;
  type?: PromoCodeType;
};

export const initialState: PromoCodeState = {
  promoCode: undefined,
  validateStatus: EntityStateStatus.IDLE,
  type: undefined
};

export const validatePromoCode = createAsyncThunk<
  { promoCode: string; type: PromoCodeType | undefined },
  { promoCode: string },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>(
  "search/validatePromoCode",
  async (arg, thunkAPI) => {
    try {
      const response = (await PromoCodeAPI.validate(arg, {
        signal: thunkAPI.signal,
        ...(await getTenantHeaders(thunkAPI.extra))
      })) as { valid: boolean; type: string | undefined };
      if (response.valid) {
        return {
          promoCode: arg.promoCode,
          type: response.type
        };
      } else {
        throw new Error("Error");
      }
    } catch (e) {
      return handleSliceError(e, thunkAPI.rejectWithValue);
    }
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const { validateStatus, promoCode } = thunkAPI.getState().promoCodeSlice;

      // don't load already loaded promocode data
      if (
        (validateStatus === EntityStateStatus.LOADING ||
          validateStatus === EntityStateStatus.SUCCEEDED) &&
        arg.promoCode !== promoCode
      ) {
        return false;
      }
    }
  }
);

export const promoCodeSlice = createSlice({
  name: "promoCodeSlice",
  initialState,
  reducers: {
    resetPromoCode: (state) => {
      state.promoCode = initialState.promoCode;
      state.type = initialState.type;
      state.validateStatus = initialState.validateStatus;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(validatePromoCode.pending, (state) => {
        state.validateStatus = EntityStateStatus.LOADING;
      })
      .addCase(validatePromoCode.fulfilled, (state, action) => {
        state.validateStatus = EntityStateStatus.SUCCEEDED;
        state.type = action.payload.type;
        state.promoCode = action.payload.promoCode ? action.meta.arg.promoCode : undefined;
      })
      .addCase(validatePromoCode.rejected, (state) => {
        state.promoCode = initialState.promoCode;
        state.validateStatus = EntityStateStatus.FAILED;
        state.type = initialState.type;
      });
  }
});

export const { resetPromoCode } = promoCodeSlice.actions;

const selectPromoCodeSlice: Selector<RootState, PromoCodeState> = (state: RootState) =>
  state[promoCodeSlice.name];

export const selectPromoCode = createSelector(selectPromoCodeSlice, (res) => res.promoCode);

export const selectPromoCodeType = createSelector(selectPromoCodeSlice, (res) => res.type);
