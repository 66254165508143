import { useCommonCmsData } from "../../state/common-cms/use-common-cms-data";
import { useCmsPropertySpecificData } from "../../state/cms/use-cms-per-property-data";
import { useSelector } from "react-redux";
import { selectLastPropertyId } from "../../features/restore-magic-context/restore-magic-context.slice";

export const useLogoUrl = () => {
  const cmsCommonData = useCommonCmsData();
  const propertyId = useSelector(selectLastPropertyId);
  const propertyCmsData = useCmsPropertySpecificData(propertyId);

  return propertyCmsData?.data.main__logo?.url
    ? propertyCmsData?.data.main__logo?.url
    : cmsCommonData?.data.main__logo?.url;
};
