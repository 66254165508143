import { FastField, FastFieldProps } from "formik";
import React, { FC } from "react";
import { PhoneInputProps } from "@likemagic-tech/sv-magic-library";
import { WrappedPhoneInput } from "../atoms/input/wrapped-phone-input";

export const FastPhoneInput: FC<PhoneInputProps> = (props) => {
  return (
    <FastField name={props.name}>
      {({ form }: FastFieldProps) => (
        <WrappedPhoneInput
          {...props}
          onChange={
            (value: string) => form.setFieldValue(props?.name ?? "", value || "", true) //For travel buddy it's removing the whole field from object
          }
        />
      )}
    </FastField>
  );
};
