import { Box } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { DesktopCard, DisplayCmsSvg, Notification, SubmitButton } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { GuestFormFormik } from "../../../components/molecules/guest-form-formik";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { PersonForm } from "../../../components/molecules/personal-form/person-form";
import { Guest } from "../../../domain-common/guest";
import { Person } from "../../../domain-common/person";
import { Reservation } from "../../../domain-common/reservation";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { isReservation, isTravelBuddy } from "../../../util/flow";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { getYesNoModalArg } from "../../global-modal/global-modal-util";
import { HelmetTitle } from "../../gtm/helmet-title";
import { getPersonMagicPerson } from "../../portal/travel-buddy-utils";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { useSaveGuestFlowSessionCheckpoint } from "../checkpoint/use-save-guest-flow-session-checkpoint";
import { ConfirmationDialogLabels } from "../../../components/atoms/dialog/confirmation-dialog";
import { getNextFlowUrl } from "../guest-flow-sequence";
import { useGuestFlow } from "../use-guest-flow";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { useSetupGuestFlowNavigation } from "../hooks/use-setup-guest-flow-navigation";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { isKioskMode } from "../../../util/kiosk-mode";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { ReservationStatus } from "../../../domain-common/reservation-status";
import {
  Editability,
  useGuestDataEditable
} from "../../booking-overview/hooks/use-guest-data-editable";
import { EditableNotification } from "../../../components/atoms/notification/editable-notification";
import { useAuth, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { openBanner } from "../../banner/banner.slice";
import { useAppDispatch } from "../../../state/store";
import { getErrorEntry } from "../../../state/common-cms/use-common-error-translation";
import {
  useCommonErrorsMapperCmsData,
  useCommonValidationCmsData
} from "../../../state/common-cms/use-common-cms-data";
import { SubUpdate } from "../../reservation-provider/sub-update";
import {
  useAdditionalTravelBuddyFieldsConfig,
  useFeatureFlags
} from "../../../util/hooks/use-configuration";
import { CheckboxTerms } from "../../../components/molecules/personal-form/checkbox-terms";
import { MarketingConsent } from "../../../components/molecules/personal-form/marketing-consent";
import {
  emptyReservationExtras,
  ReservationExtras
} from "../../../domain-common/reservation-extras";
import { MarketingConsentOptin } from "../../../components/molecules/personal-form/marketing-consent-optin";
import yup from "../../../config/yup.config";
import { additionalTravelBuddyFieldValidation } from "../../../util/additional-travel-buddy-fields-validation";
import { TravelBuddyTermsAndConditions } from "../../../components/molecules/personal-form/travel-buddy-terms-and-conditions";
import { useTheme } from "@mui/styles";
import { useHasMinors } from "../../../util/hooks/use-has-minors";
import { SpecialAssistance } from "../../../components/molecules/personal-form/special-assistance";
import { LoyaltyProgram } from "../../../components/molecules/personal-form/loyalty-program";

const CURRENT_PAGE = GuestFlowCheckpoint.PERSONAL_DATA;

type FormData = {
  person: Person;
  additionalGuests?: Guest[];
  maxCompanions?: number;
  password?: string;
  acceptedTerms?: boolean;
  emergencyEvacuationHelpNeeded?: boolean;
  extras: ReservationExtras;
};

function shouldShowAccountEmailChangeConfirmDialog(
  authenticated: boolean | undefined,
  values: FormData,
  reservation: Reservation
) {
  return (
    authenticated &&
    isReservation(reservation) &&
    reservation.keycloakUUID &&
    reservation.primaryGuest.email &&
    values.person.email !== reservation.primaryGuest.email
  );
}

export const PersonalDataPage: FC = () => {
  const navigate = useNavigate();

  const { patchGuestFlowStep, reservation, contactButton, skipButton, getProgress } =
    useGuestFlow();
  const { open: openModal } = useGlobalModal();
  const { getIsAuthenticated } = useAuth();
  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });
  const { isBottomNavbarActive } = useBottomNavbar();
  const {
    diverseGenderEnabled,
    marketingConsentEnabled,
    marketingConsentDoubleOptinEnabled,
    travelBuddyIsMinorValidationEnabled,
    tfeFeatureEnabled,
    genderDisplayDisabled
  } = useFeatureFlags();
  const personType = useMemo(() => getPersonMagicPerson(reservation), [reservation]);
  const dispatch = useAppDispatch();
  const cmsErrorData = useCommonErrorsMapperCmsData();
  const { spacing } = useTheme();
  const { hasMinors } = useHasMinors();

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const guestFormLabels = React.useMemo(
    () => ({
      firstName: tCommon("labels__first_name"),
      lastName: tCommon("labels__last_name"),
      genderFemale: tCommon("labels__gender_female"),
      genderMale: tCommon("labels__gender_male"),
      genderOther: tCommon("labels__gender_other"),
      phone: tCommon("labels__phone"),
      email: tCommon("labels__email"),
      birthdate: tCommon("labels__birthdate"),
      city: tCommon("labels__city"),
      nationalityCountryCode: tCommon("labels__nationality"),
      postalCode: tCommon("labels__postal_code"),
      address: tCommon("labels__address"),
      countryCode: tCommon("labels__country")
    }),
    [tCommon]
  );

  const dialogLabels: ConfirmationDialogLabels = React.useMemo(
    () => ({
      title: tCommon("modals__delete_buddy_dialog_title"),
      text: tCommon("modals__delete_buddy_dialog_text"),
      confirm: tCommon("buttons__yes"),
      deny: tCommon("buttons__no")
    }),
    [tCommon]
  );
  const controlLabels = React.useMemo(
    () => ({
      heading: tCommon("labels__travel_buddy"),
      delete: tCommon("buttons__remove"),
      add: tCommon("buttons__add_buddy")
    }),
    [tCommon]
  );

  useSaveGuestFlowSessionCheckpoint(reservation!, CURRENT_PAGE);
  const accountEmailChangeConfirmModalArg = getYesNoModalArg(
    tCommon("modals__confirm_email_change_title"),
    tCommon("modals__confirm_email_change_content"),
    tCommon("buttons__yes"),
    tCommon("buttons__no")
  );

  const handleError = useCallback(() => {
    const prismicSameDataEntry = getErrorEntry(
      cmsErrorData,
      "errors__travel_buddy_same_contact_data"
    );
    dispatch(
      openBanner({
        type: "error",
        title: prismicSameDataEntry?.text || cmsErrorData?.["errors__default_label"]
      })
    );
  }, [dispatch, cmsErrorData]);

  const handleSubmit = React.useCallback(
    async (values: FormData) => {
      const sameData = values.additionalGuests?.find(
        (guest) =>
          (guest.email && guest.email === values.person.email) ||
          (guest.phone && guest.phone === values.person.phone)
      );
      if (sameData) {
        handleError();
        return null;
      }
      const showAccountEmailChangeConfirmDialog = shouldShowAccountEmailChangeConfirmDialog(
        getIsAuthenticated(),
        values,
        reservation
      );
      try {
        if (showAccountEmailChangeConfirmDialog) {
          const result = await openModal(accountEmailChangeConfirmModalArg);
          if (!result) {
            return;
          }
        }

        const extras = isTravelBuddy(reservation)
          ? {
              ...values.extras,
              ...{
                tcGeneral: !!values.acceptedTerms,
                tcOnline: !!values.acceptedTerms
              }
            }
          : values.extras;

        const valuesToPatch: Reservation = {
          ...reservation,
          [personType]: values.person,
          extras
        };

        if (values.additionalGuests) {
          valuesToPatch.additionalGuests = values.additionalGuests;
        }
        await patchGuestFlowStep({
          reservationValues: valuesToPatch,
          checkpoint: CURRENT_PAGE,
          subUpdate: isTravelBuddy(valuesToPatch)
            ? SubUpdate.PERSONAL_DATA
            : SubUpdate.PERSONAL_DATA_WITH_SECONDARY_GUESTS
        });
        navigate(getNextFlowUrl(CURRENT_PAGE, valuesToPatch));
      } catch (e) {
        console.error("Update guest personal data went wrong", e);
      }
    },
    [
      navigate,
      patchGuestFlowStep,
      reservation,
      personType,
      getIsAuthenticated,
      openModal,
      accountEmailChangeConfirmModalArg,
      handleError
    ]
  );

  const progress = useMemo(() => getProgress(CURRENT_PAGE), [getProgress]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const { personalDataStepValidation, tcMinorsValidation } = useFormValidations();
  const validationCmsData = useCommonValidationCmsData();
  const additionalTravelBuddyFields = useAdditionalTravelBuddyFieldsConfig();

  const dynamicSchema = additionalTravelBuddyFieldValidation(
    additionalTravelBuddyFields,
    validationCmsData,
    false
  );

  const newPersonalDataStepValidationSchema =
    travelBuddyIsMinorValidationEnabled && !isTravelBuddy(reservation)
      ? personalDataStepValidation.concat(tcMinorsValidation).concat(dynamicSchema)
      : personalDataStepValidation.concat(dynamicSchema);

  const initialValues: FormData | null = useMemo(() => {
    if (!reservation) {
      return null;
    }
    const result: FormData = {
      person: reservation[personType],
      extras: reservation.extras || emptyReservationExtras()
    };

    if (isReservation(reservation)) {
      result.additionalGuests = reservation.additionalGuests;
      result.maxCompanions = reservation.maxCompanions;

      if (result.person.email) {
        result.person.email = yup.string().notBlacklistedEmail().isValidSync(result.person.email)
          ? result.person.email
          : "";
      }
    }
    if (isTravelBuddy(reservation)) {
      result.acceptedTerms = reservation.extras?.tcGeneral && reservation.extras.tcOnline;
    }
    return result;
  }, [reservation, personType]);

  const labels = useMemo(
    () => ({
      firstName: tCommon("labels__first_name"),
      lastName: tCommon("labels__last_name"),
      genderFemale: tCommon("labels__gender_female"),
      genderMale: tCommon("labels__gender_male"),
      genderOther: tCommon("labels__gender_other"),
      phone: tCommon("labels__phone"),
      email: tCommon("labels__email")
    }),
    [tCommon]
  );

  const topBarIcons = useMemo(() => {
    if (isReservation(reservation)) {
      if (isKioskMode()) {
        return [skipButton];
      }
      return [contactButton];
    }
    return [];
  }, [reservation, contactButton, skipButton]);

  const { editable } = useGuestDataEditable({
    uneditableStatus: [ReservationStatus.CHECKED_OUT],
    reservation
  });
  if (editable === undefined) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Guest flow personal data" />
      <FlowTemplate icons={topBarIcons} progress={progress}>
        <GuestFlowBackgroundBox>
          <Box p={2} pt={0}>
            <DesktopCard>
              <PageHeadingInfo
                title={tCommon("title__personal_data_page")}
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
              />
              <EditableNotification editabilities={[editable]} />
              {initialValues && (
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={newPersonalDataStepValidationSchema}
                >
                  {(formik) => (
                    <Form noValidate>
                      <PersonForm
                        objectPathPrefix={"person."}
                        values={formik.values.person}
                        onChange={formik.handleChange}
                        labels={labels}
                        nameEditable={editable !== Editability.EDITABLE || formik.isSubmitting}
                        contactDataEditable={
                          editable !== Editability.EDITABLE || formik.isSubmitting
                        }
                        touched={formik.touched.person}
                        errors={formik.errors.person}
                        diverseGenderEnabled={diverseGenderEnabled}
                        genderDisplayDisabled={genderDisplayDisabled}
                      />

                      {isTravelBuddy(reservation) && (
                        <CheckboxTerms
                          acceptedTerms={!!formik.values.acceptedTerms}
                          touched={!!formik.touched?.acceptedTerms}
                          errors={formik.errors?.acceptedTerms ?? ""}
                          onChange={formik.handleChange}
                        />
                      )}
                      {tfeFeatureEnabled && (
                        <SpecialAssistance
                          value={formik.values.extras?.emergencyEvacuationHelpNeeded ?? false}
                          onChange={formik.handleChange}
                          label={tCommon("labels__i_require_special_assistance")}
                        />
                      )}
                      {!tfeFeatureEnabled &&
                        marketingConsentEnabled &&
                        !marketingConsentDoubleOptinEnabled && (
                          <MarketingConsent
                            value={formik.values.extras?.tcMarketingConsent}
                            onChange={formik.handleChange}
                            label={tCommon("labels__marketing_consent")}
                          />
                        )}
                      {tfeFeatureEnabled &&
                        marketingConsentEnabled &&
                        !marketingConsentDoubleOptinEnabled && (
                          <LoyaltyProgram
                            value={formik.values.extras?.enrolledInLoyaltyProgram ?? false}
                            onChange={formik.handleChange}
                            label={tCommon("labels__marketing_consent")}
                          />
                        )}
                      {marketingConsentEnabled && marketingConsentDoubleOptinEnabled && (
                        <MarketingConsentOptin
                          value={formik.values.extras}
                          onChange={formik.handleChange}
                          label={tCommon("labels__marketing_consent_with_double_optin")}
                        />
                      )}
                      {isReservation(reservation) ? (
                        <>
                          {hasMinors(formik.values.additionalGuests) &&
                            travelBuddyIsMinorValidationEnabled && (
                              <Box my={spacing(4)}>
                                <Notification
                                  type="info"
                                  title={tCommon("labels__additional_travel_buddy_under_age_info")}
                                />
                              </Box>
                            )}
                          <FieldArray
                            name="additionalGuests"
                            render={(props) => (
                              <GuestFormFormik
                                {...props}
                                onChange={formik.handleChange}
                                dialogLabels={dialogLabels}
                                guestFormLabels={guestFormLabels}
                                controlLabels={controlLabels}
                              />
                            )}
                          />
                          {travelBuddyIsMinorValidationEnabled &&
                            formik.values?.additionalGuests?.length !== 0 && (
                              <TravelBuddyTermsAndConditions
                                value={formik.values.extras?.tcMinors}
                                onChange={formik.handleChange}
                                touched={formik.touched?.extras?.tcMinors ?? false}
                                errors={formik.errors?.extras?.tcMinors ?? ""}
                              />
                            )}
                        </>
                      ) : null}
                      <SubmitButton
                        label={formik.dirty ? tCommon("buttons__save") : tCommon("buttons__next")}
                        disabled={formik.isSubmitting}
                        hasWhiteBackground
                        hasBottomNavigation={isBottomNavbarActive}
                      />
                    </Form>
                  )}
                </Formik>
              )}
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
