import { EntityStateStatus } from "../../state/EntityStateStatus";
import { createAsyncThunk, createSelector, createSlice, Selector } from "@reduxjs/toolkit";
import { PropertiesByCity } from "../../domain-common/properties-by-city";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { PropertyApi } from "../../api/property.api";
import { getTenantHeaders } from "../../api/custom-headers";

interface PropertiesByCityInterface {
  status: EntityStateStatus;
  properties: PropertiesByCity;
}

const initialState: PropertiesByCityInterface = {
  status: EntityStateStatus.IDLE,
  properties: {}
};

export const fetchPropertiesByCity = createAsyncThunk<
  PropertiesByCity,
  undefined,
  { state: RootState; extra: ThunkExtraArguments }
>(
  "properties/fetchPropertiesGroupByCity",
  async (arg, thunkApi) => {
    return await PropertyApi.fetchPropertiesGroupByCity({
      signal: thunkApi.signal,
      ...(await getTenantHeaders(thunkApi.extra))
    });
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().properties.status;
      // don't load already loaded Properties
      if (status === EntityStateStatus.LOADING || status === EntityStateStatus.SUCCEEDED) {
        return false;
      }
    }
  }
);

export const propertiesSliceByCity = createSlice({
  name: "properties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertiesByCity.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchPropertiesByCity.fulfilled, (state, action) => {
        state.status = EntityStateStatus.SUCCEEDED;
        state.properties = action.payload;
      })
      .addCase(fetchPropertiesByCity.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      });
  }
});

const selectSelf: Selector<RootState, PropertiesByCityInterface> = (state: RootState) =>
  state[propertiesSliceByCity.name];

export const selectPropertiesByCity = createSelector(selectSelf, (res) => res.properties);
export const selectPropertiesByCityStatus = createSelector(selectSelf, (res) => res.status);

export const listOfProperties = (properties: PropertiesByCity) =>
  Object.values(properties).flatMap((properties) => properties);
