import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationComponent, DesktopCard } from "../../../components";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generatePortalMyStayUrl } from "../../../util/routing";

import { HelmetTitle } from "../../gtm/helmet-title";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { resetCheckoutState } from "../../checkout-flow/checkout-flow.slice";
import { useAppDispatch } from "../../../state/store";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useLogoUrl } from "../../../util/hooks/use-logo-url";

export const CheckoutSuccessPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const dispatch = useAppDispatch();
  const logoUrl = useLogoUrl();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const goToMyStay = useCallback(() => {
    // This is needed to reset the CheckoutState.SUCCEEDED which redirects to check out page.
    dispatch(resetCheckoutState());
    navigate(generatePortalMyStayUrl(reservation.magicId));
  }, [dispatch, navigate, reservation.magicId]);

  return (
    <>
      <HelmetTitle suffix="Checkout success" />
      <RoundedFullHeight px={2.5}>
        <FlowTemplate>
          <DesktopCard>
            <ConfirmationComponent
              subtitleInfo={tCommon("confirmations__checkout_success")}
              onConfirm={goToMyStay}
              submitButtonLabel={tCommon("buttons__go_to_my_stay")}
              logoSrc={logoUrl}
            />
          </DesktopCard>
        </FlowTemplate>
      </RoundedFullHeight>
    </>
  );
};
