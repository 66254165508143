import { RootState, useAppDispatch } from "../../../state/store";
import { fetchBoxDetails, selectBoxByBoxId } from "../box-shop.slice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { BoxAvailability } from "../../../domain-v1/box-availability";

interface UseFetchBoxAvailabilityByIdProps {
  boxId: string;
  magicId: string;
  magicToken: string;
}
export const useFetchBoxAvailabilityById = ({
  boxId,
  magicId,
  magicToken
}: UseFetchBoxAvailabilityByIdProps) => {
  const dispatch = useAppDispatch();
  const boxAvailability = useSelector<RootState, BoxAvailability | undefined>((state) =>
    selectBoxByBoxId(state, boxId)
  );
  useEffect(() => {
    dispatch(
      fetchBoxDetails({
        boxId: boxId,
        magicId: magicId,
        magicToken: magicToken
      })
    );
  }, [dispatch, magicId, magicToken, boxId]);

  return boxAvailability;
};
