import { createSlice, Selector } from "@reduxjs/toolkit";
import { CmsSingleDocumentPerPropertyType } from "../cms/cms-single-document-per-property-type";
import { CMSPageState } from "../cms/cms-single-page-factory";
import {
  createCMSSingleDocumentPerPropertyFetchThunk,
  createCMSSinglePagePerPropertyAdapter
} from "../cms/cms-single-page-per-property-factory";
import { RootState } from "../store";

export type PropertyCMSState = CMSPageState;
export const promoCodesCMSAdapter = createCMSSinglePagePerPropertyAdapter();

const initialState: PropertyCMSState = promoCodesCMSAdapter.getInitialState();

export const fetchPromoCodesCMS = createCMSSingleDocumentPerPropertyFetchThunk(
  CmsSingleDocumentPerPropertyType.promoCodes
);

export const promoCodesCMSSlice = createSlice({
  name: "promoCodesCMS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromoCodesCMS.pending, promoCodesCMSAdapter.handlePendingLoadingStatus)
      .addCase(fetchPromoCodesCMS.fulfilled, (state, action) => {
        promoCodesCMSAdapter.handleFulfilledLoadingStatus(state, action);
        promoCodesCMSAdapter.addOne(state, action);
      })
      .addCase(fetchPromoCodesCMS.rejected, promoCodesCMSAdapter.handleRejectedLoadingStatus);
  }
});

export const selectSelf: Selector<RootState, PropertyCMSState> = (state: RootState) =>
  state.cmsPerProperty[CmsSingleDocumentPerPropertyType.promoCodes];

export const { selectAll: selectPromoCodesCMS, selectById: selectPromoCodesCMSById } =
  promoCodesCMSAdapter.getSelectors<RootState>(selectSelf);
