import { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TrainTicket: FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon width="64" height="64" viewBox="0 0 64 64" {...props}>
      <path d="M42 45.3682C41.4667 45.3682 40.9627 45.1602 40.5867 44.7815L37.1733 41.3682H26.8293L23.416 44.7815C23.0373 45.1602 22.536 45.3682 22.0027 45.3682C21.4693 45.3682 20.9653 45.1602 20.5893 44.7815C19.8107 44.0028 19.8107 42.7335 20.5893 41.9522L22.0107 40.5308C20.7787 39.6775 20 38.2588 20 36.7015V22.0348C20 19.4615 22.0933 17.3682 24.6667 17.3682H39.3333C41.9067 17.3682 44 19.4615 44 22.0348V36.7015C44 38.2588 43.2213 39.6775 41.992 40.5308L43.4133 41.9522C44.192 42.7308 44.192 44.0002 43.4133 44.7815C43.0373 45.1602 42.5333 45.3682 42 45.3682ZM24 36.7015C24 37.0695 24.2987 37.3682 24.6667 37.3682H39.3333C39.7013 37.3682 40 37.0695 40 36.7015V33.3682H24V36.7015ZM40 29.3682V22.0348C40 21.6668 39.7013 21.3682 39.3333 21.3682H24.6667C24.2987 21.3682 24 21.6668 24 22.0348V29.3682H40Z" />
      <path d="M6 55.3682C2.69067 55.3682 0 52.6775 0 49.3682V39.3682C0 38.2642 0.896 37.3682 2 37.3682C5.30933 37.3682 8 34.6775 8 31.3682C8 28.0588 5.30933 25.3682 2 25.3682C0.896 25.3682 0 24.4722 0 23.3682V13.3682C0 10.0588 2.69067 7.36816 6 7.36816H58C61.3093 7.36816 64 10.0588 64 13.3682V23.3682C64 24.4722 63.104 25.3682 62 25.3682C58.6907 25.3682 56 28.0588 56 31.3682C56 34.6775 58.6907 37.3682 62 37.3682C63.104 37.3682 64 38.2642 64 39.3682V49.3682C64 52.6775 61.3093 55.3682 58 55.3682H6ZM4 49.3682C4 50.4722 4.896 51.3682 6 51.3682H58C59.104 51.3682 60 50.4722 60 49.3682V41.1682C55.3813 40.2348 52 36.1682 52 31.3682C52 26.5682 55.3813 22.5042 60 21.5682V13.3682C60 12.2642 59.104 11.3682 58 11.3682H6C4.896 11.3682 4 12.2642 4 13.3682V21.5682C8.61867 22.5015 12 26.5682 12 31.3682C12 36.1682 8.61867 40.2322 4 41.1682V49.3682Z" />
    </SvgIcon>
  );
};
