import { Box, Grid, SelectChangeEvent } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import React, { FC, useCallback, useMemo } from "react";
import { LegalFormData } from "../../domain-common/legal-form-data";
import { MagicFile } from "../../domain-common/magic-file";
import { ApiVersionEnum, useAuth, useCountries } from "@likemagic-tech/sv-magic-library";
import { SignatureUpload } from "../organism/signature-component/signature-upload";
import { isDisabledCountry } from "../../util/countries";
import { getI18nSelectedLanguage } from "../../util/lang-utils";
import { DatePicker } from "../atoms/input/date-picker";
import {
  IdentificationUploadErrorLabels,
  IdentificationUploadLabels
} from "../organism/id-document/id-document-dto";
import {
  useDisabledCountriesForIdentification,
  useDisabledCountriesForPassportUpload,
  useFeatureFlags
} from "../../util/hooks/use-configuration";
import IdDocumentComponent from "../organism/id-document/id-document-component";
import { fetchLegalImages } from "../../features/guest-flow/use-guest-flow";
import { Reservation } from "../../domain-common/reservation";
import { WrappedInput } from "../atoms/input/wrapped-input";
import { WrappedNativeSelect } from "../atoms/input/wrapped-native-select";
import { CheckboxTerms } from "./personal-form/checkbox-terms";
import { useReservationContext } from "../../features/reservation-provider/reservation-provider";

interface LegalFormProps {
  onChange: { (e: React.ChangeEvent<HTMLInputElement>): void };
  values: LegalFormData;
  touched: FormikTouched<LegalFormData>;
  errors: FormikErrors<LegalFormData>;
  uploadedIdentificationImage?: MagicFile;
  uploadedSignatureImage?: MagicFile;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  labels: Record<"dateOfBirth" | "nationality" | "identificationNumber", string>;
  identificationUploadErrorLabels: IdentificationUploadErrorLabels;
  identificationUploadLabels: IdentificationUploadLabels;
  signatureUploadLabels: {
    required: string;
    buttonLabel: string;
    deletedText: string;
    dialogTitle: string;
    dialogReset: string;
    dialogConfirm: string;
  };
  disabled: boolean;
  isLegalDataDeclined: boolean;
}

export const prepareLegalForm = async ({
  reservation,
  authToken,
  apiKey,
  isRESTVersion,
  isIdStatusDeclined
}: {
  reservation: Reservation;
  authToken?: string;
  isRESTVersion: boolean;
  apiKey?: string;
  isIdStatusDeclined: boolean;
}) => {
  let identificationImage = null;
  let signatureImage = null;
  try {
    // TODO: LM-1891
    // https://likemagic.atlassian.net/browse/LM-1891
    // Show spinner in case of loading legalImages
    const { identificationImage: idImage, signatureImage: signature } = await fetchLegalImages({
      reservation,
      authToken,
      apiVersion: isRESTVersion ? ApiVersionEnum.V1 : ApiVersionEnum.V2,
      apiKey
    });

    identificationImage = idImage;
    signatureImage = signature;
  } catch (e) {
    // We swallow the error in order to show what we can
    // otherwise form won't be visible
    console.error(e);
  }
  return isIdStatusDeclined
    ? {
        files: [],
        identificationImage: null,
        signatureImage: null,
        acceptedTerms: false,
        primaryGuest: {
          birthdate: "",
          identificationNumber: "",
          nationalityCountryCode: ""
        }
      }
    : {
        files: reservation.files,
        identificationImage,
        signatureImage,
        acceptedTerms: !!(reservation.extras?.tcGeneral && reservation.extras.tcOnline),
        primaryGuest: {
          birthdate: reservation.primaryGuest.birthdate ?? "",
          identificationNumber: reservation.primaryGuest?.identificationNumber ?? "",
          nationalityCountryCode: reservation.primaryGuest.nationalityCountryCode ?? ""
        }
      };
};

export const LegalForm: FC<LegalFormProps> = ({
  onChange,
  values,
  touched,
  errors,
  uploadedIdentificationImage,
  uploadedSignatureImage,
  setFieldValue,
  labels,
  identificationUploadErrorLabels,
  identificationUploadLabels,
  signatureUploadLabels,
  disabled,
  isLegalDataDeclined
}) => {
  const { getIsAuthenticated } = useAuth();
  const { documentsUploadEnabled, tfeFeatureEnabled } = useFeatureFlags();
  const { reservation } = useReservationContext();
  const { sortedCountries } = useCountries({ propertyId: reservation?.propertyId });

  const onSelectChange = (event: SelectChangeEvent<any>) =>
    onChange(event as React.ChangeEvent<HTMLInputElement>);

  const disabledCountriesForIdentification = useDisabledCountriesForIdentification();

  const isDisabledIdentification = useMemo(
    () =>
      isDisabledCountry(
        disabledCountriesForIdentification,
        values.primaryGuest.nationalityCountryCode
      ),
    [disabledCountriesForIdentification, values.primaryGuest.nationalityCountryCode]
  );

  const disabledCountriesForPassportUpload = useDisabledCountriesForPassportUpload();

  const isDisabledPassportUpload = useMemo(
    () =>
      isDisabledCountry(
        disabledCountriesForPassportUpload,
        values.primaryGuest.nationalityCountryCode
      ),
    [disabledCountriesForPassportUpload, values.primaryGuest.nationalityCountryCode]
  );

  const showPreview = useCallback(
    (file: any) => {
      return getIsAuthenticated() || isLegalDataDeclined ? false : !!file;
    },
    [getIsAuthenticated, isLegalDataDeclined]
  );

  return (
    <Grid item xs={12}>
      {!tfeFeatureEnabled && (
        <Grid item xs={12}>
          <Box mt={2} color="white">
            <DatePicker
              id={`primaryGuest.birthdate`}
              label={labels.dateOfBirth}
              value={values.primaryGuest.birthdate}
              onChange={onChange}
              disabled={disabled}
              error={touched?.primaryGuest?.birthdate && errors?.primaryGuest?.birthdate}
            />
          </Box>
        </Grid>
      )}
      {!tfeFeatureEnabled && (
        <Grid item xs={12}>
          <Box mt={2} color="white">
            <WrappedNativeSelect
              id={`primaryGuest.nationalityCountryCode`}
              value={values.primaryGuest.nationalityCountryCode || ""}
              label={labels.nationality}
              items={sortedCountries(getI18nSelectedLanguage())}
              onChange={onSelectChange}
              disabled={disabled}
              error={
                touched?.primaryGuest?.nationalityCountryCode &&
                errors?.primaryGuest?.nationalityCountryCode
              }
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box mt={2} color="white">
          {!isDisabledIdentification && (
            <WrappedInput
              id={`primaryGuest.identificationNumber`}
              value={values.primaryGuest.identificationNumber || ""}
              label={labels.identificationNumber}
              onChange={onChange}
              disabled={disabled}
              error={
                touched?.primaryGuest?.identificationNumber &&
                errors?.primaryGuest?.identificationNumber
              }
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {documentsUploadEnabled && !isDisabledPassportUpload && (
          <IdDocumentComponent
            value={values.identificationImage}
            onChange={(image) => setFieldValue("identificationImage", image, true)}
            error={touched?.identificationImage && errors?.identificationImage}
            identificationUploadErrorLabels={identificationUploadErrorLabels}
            identificationUploadLabels={identificationUploadLabels}
            disabled={disabled}
            showPreview={showPreview(uploadedIdentificationImage)}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box mt={2}>
          <SignatureUpload
            value={values.signatureImage}
            onChange={(image) => {
              setFieldValue("signatureImage", image, true);
            }}
            disabled={disabled}
            error={
              touched?.signatureImage && errors?.signatureImage && signatureUploadLabels?.required
            }
            showPreview={showPreview(uploadedSignatureImage)}
            labels={signatureUploadLabels}
          />
        </Box>
      </Grid>
      <CheckboxTerms
        acceptedTerms={values.acceptedTerms}
        touched={!!touched?.acceptedTerms}
        errors={errors?.acceptedTerms ?? ""}
        onChange={onChange}
        disabled={disabled}
      />
    </Grid>
  );
};
