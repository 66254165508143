import { CardContent, CardMedia } from "@mui/material";
import React, { FC } from "react";
import { MyStayTipCard } from "../../../../domain-common/my-stay-tip-card";
import { Title } from "./title";
import { Subtitle } from "./subtitle";
import { CardWrapper } from "./card-wrapper";

interface TipThumbnailCardProps {
  tip: MyStayTipCard;
}

export const TipThumbnailCard: FC<TipThumbnailCardProps> = ({ tip }) => {
  const showSubtitle = Boolean(tip.subtitle);

  return (
    <CardWrapper tip={tip}>
      <CardMedia component="img" height="120" image={tip.coverImage} title={tip.title} />
      <CardContent
        sx={{
          mb: 2.25,
          height: 130,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Title title={tip.title} />
        {showSubtitle && <Subtitle subtitle={tip.subtitle} />}
      </CardContent>
    </CardWrapper>
  );
};
