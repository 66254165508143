import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { NotFoundPage } from "../../components/organism/not-found.page";
import { CheckoutDepartureTimePage } from "./pages/checkout-departure-time.page";
import { CheckoutFlowCheckpointToPath } from "./checkout-flow-checkpoint-to-path";
import { CheckoutFlowPages } from "./checkout-flow-pages";
import { CheckoutMinibarPage } from "./pages/checkout-minibar.page";
import { CheckoutBillOverviewPage } from "./pages/checkout-bill-overview.page";
import { CheckoutPaymentPage } from "./pages/checkout-payment.page";
import { CheckoutConfirmationPage } from "./pages/checkout-confirmation.page";
import { CheckoutPaymentRedirect } from "./pages/checkout-payment-redirect";

export const CheckoutFlowRouterPages: FC = () => {
  return (
    <>
      <Routes>
        <Route
          path={CheckoutFlowCheckpointToPath[CheckoutFlowPages.DEPARTURE_TIME]}
          element={<CheckoutDepartureTimePage />}
        />
        <Route
          path={CheckoutFlowCheckpointToPath[CheckoutFlowPages.MINIBAR]}
          element={<CheckoutMinibarPage />}
        />
        <Route
          path={CheckoutFlowCheckpointToPath[CheckoutFlowPages.BILL]}
          element={<CheckoutBillOverviewPage />}
        />
        <Route
          path={CheckoutFlowCheckpointToPath[CheckoutFlowPages.PAYMENT]}
          element={<CheckoutPaymentPage />}
        />
        <Route
          path={CheckoutFlowCheckpointToPath[CheckoutFlowPages.PAYMENT_REDIRECT]}
          element={<CheckoutPaymentRedirect />}
        />
        <Route
          path={CheckoutFlowCheckpointToPath[CheckoutFlowPages.CONFIRMATION]}
          element={<CheckoutConfirmationPage />}
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
