import { AsyncThunk, EntityId } from "@reduxjs/toolkit";
import { PrismicDocument } from "@prismicio/client";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getI18nSelectedLanguage } from "../../util/lang-utils";
import { RootState, ThunkExtraArguments, useAppDispatch } from "../store";
import { selectIdFromActonPayload } from "./cms-single-page-per-property-factory";
import {
  fetchPropertySpecificCMS,
  selectPropertyCMSById
} from "../property-cms/property-cms.slice";
import { PrismicData, useCmsClient } from "../../api/cms-client/use-cms-client";

export interface AdditionalFiltersForPropertyDocuments {
  type?: string;
}

export function useCmsPerPropertyData({
  propertyId,
  additionalFilters,
  selector,
  fetchAction
}: {
  propertyId: string;
  selector: (state: RootState, id: EntityId) => PrismicDocument | undefined;
  additionalFilters?: AdditionalFiltersForPropertyDocuments;
  fetchAction: AsyncThunk<
    PrismicDocument,
    {
      language: string;
      propertyId: string;
      prismic: PrismicData;
      additionalFilters?: AdditionalFiltersForPropertyDocuments;
    },
    { state: RootState; extra: ThunkExtraArguments }
  >;
}) {
  const language = getI18nSelectedLanguage();

  const dispatch = useAppDispatch();
  const prismic = useCmsClient();

  const cmsData = useSelector<RootState, PrismicDocument | undefined>((state) =>
    selector(
      state,
      selectIdFromActonPayload({
        language,
        propertyId,
        additionalFilters
      })
    )
  );

  useEffect(() => {
    if (propertyId) {
      dispatch(
        fetchAction({
          language,
          prismic,
          propertyId,
          additionalFilters
        })
      );
    }
  }, [dispatch, fetchAction, propertyId, prismic, language, additionalFilters]);

  return cmsData;
}

export const useCmsPropertySpecificData = (propertyId?: string) => {
  return useCmsPerPropertyData({
    propertyId: propertyId || "",
    selector: selectPropertyCMSById,
    fetchAction: fetchPropertySpecificCMS
  });
};
