import React, { FC, useCallback, useMemo } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useNavigate } from "react-router";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import {
  generatePortalDynamicShopConfirmationUrl,
  generatePortalDynamicShopPaymentUrl
} from "../../../util/routing";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { mapFolio } from "../../../components/organism/folio/folio-preview.util";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { FolioPreview } from "../../../components/organism/folio/folio-preview";
import { Form, Formik } from "formik";
import { useTotalPrice } from "../../payment/use-total-price";
import { usePreparePayment } from "../../payment/use-prepare-payment";
import { useSelector } from "react-redux";
import { Charge } from "../../../domain-common/payment";
import { emptyFullPrice, multiply, toGross } from "../../../domain-common/full-price";
import { compareByStringProperty } from "../../../util/sort";
import { useShopCmsTranslation } from "../../../state/shop-cms/use-shop-cms-translation";
import { formatPriceToString } from "../../../components/atoms/price-preview/price-preview";
import { clearDynamicShopCart, selectAllShopCartPerType } from "../dynamic-shop.slice";
import { HelmetTitle } from "../../gtm/helmet-title";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { DynamicShopFlowPages, getDynamicShopFlowProgress } from "../dynamic-shop-pages";
import { useGetDynamicShopType } from "../use-get-dynamic-shop-type";
import { RootState, useAppDispatch } from "../../../state/store";
import {
  dynamicShopCartItemsToAdditionalServicesPaymentDTO,
  dynamicShopCartItemsToShopItemsPaymentDTO
} from "../dynamic-shop.util";
import { useBookFreeAdditionalServices } from "../../additional-services/service-selection/use-book-free-additional-services";

const currentPage = DynamicShopFlowPages.DYNAMIC_SHOP_BILL_OVERVIEW;

export const DynamicShopBillOverviewPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { reservation } = useReservationContext();
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const { preparePayment } = usePreparePayment(magicId);
  const shopType = useGetDynamicShopType();
  const { bookFreeAdditionalServices } = useBookFreeAdditionalServices(
    reservation.magicId,
    reservation.magicToken
  );

  const dynamicCartItems = useSelector((state: RootState) =>
    selectAllShopCartPerType(state, shopType)
  );

  const totalToBePaid = useTotalPrice(
    reservation.magicId,
    dynamicShopCartItemsToShopItemsPaymentDTO(dynamicCartItems),
    undefined
  );
  const skipPayment = useMemo(() => totalToBePaid?.grossAmount === 0, [totalToBePaid?.grossAmount]);
  const foliosToBePaid = useMemo(
    () => reservation.foliosToBePaid.map((f) => f.folioId),
    [reservation.foliosToBePaid]
  );

  const onSubmit = useCallback(async () => {
    if (totalToBePaid === null) {
      console.error("totalToBePaid === null during onSubmit");
      return;
    }

    if (totalToBePaid.grossAmount > 0) {
      await preparePayment({
        foliosToBePaid,
        shopItems: dynamicShopCartItemsToShopItemsPaymentDTO(dynamicCartItems)
      });
    } else if (dynamicCartItems.length > 0 && totalToBePaid.grossAmount <= 0) {
      await bookFreeAdditionalServices(
        dynamicShopCartItemsToAdditionalServicesPaymentDTO(dynamicCartItems)
      );
      dispatch(clearDynamicShopCart());
    }

    if (skipPayment) {
      navigate(generatePortalDynamicShopConfirmationUrl(magicId, shopType));
    } else {
      navigate(generatePortalDynamicShopPaymentUrl(magicId, shopType));
    }
  }, [
    dispatch,
    shopType,
    skipPayment,
    navigate,
    magicId,
    preparePayment,
    foliosToBePaid,
    totalToBePaid,
    bookFreeAdditionalServices,
    dynamicCartItems
  ]);

  const { findItem } = useShopCmsTranslation(reservation.propertyId, shopType);

  const minibarCharges = useMemo(() => {
    return dynamicCartItems
      .reduce((finalList: Charge[], minibarCartItem) => {
        if (minibarCartItem.quantity > 0) {
          finalList.push({
            name: findItem(minibarCartItem.serviceId)?.item_title ?? minibarCartItem.serviceId,
            totalQuantity: minibarCartItem.quantity,
            total: multiply(minibarCartItem.fullPrice, minibarCartItem.quantity),
            folioId: "",
            serviceId: minibarCartItem.serviceId,
            isRent: minibarCartItem.isRent
          });
        }
        return finalList;
      }, [])
      .sort(compareByStringProperty("name"));
  }, [dynamicCartItems, findItem]);

  const isThereAnyMainFolio = reservation.folios.some((item) => item.isMainFolio);

  return (
    <>
      <FlowTemplate
        icons={[]}
        handleBack={goBack}
        progress={getDynamicShopFlowProgress(currentPage)}
      >
        <HelmetTitle suffix={"Bill overview"} />
        <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
          <DesktopCard>
            <PageHeadingInfo
              title={t("title__dynamic_shop_bill_page")}
              icon={<DisplayCmsSvg url={cmsData?.data?.icon__bill_icon?.url} />}
            />
            <Formik initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <Form>
                  {reservation.folios
                    .map((folio) => mapFolio(folio, reservation, t))
                    .map((item, index) => (
                      <FolioPreview
                        key={`id-${item.id}`}
                        folio={item}
                        reservation={reservation}
                        index={index}
                        additionalCharges={
                          item.isMainFolio || (!isThereAnyMainFolio && index === 0)
                            ? minibarCharges
                            : []
                        }
                      />
                    ))}
                  <SubmitButton
                    label={
                      skipPayment
                        ? t("buttons__next")
                        : `${t("buttons__pay")} ${formatPriceToString(
                            toGross(totalToBePaid ?? emptyFullPrice())
                          )}`
                    }
                  />
                </Form>
              )}
            </Formik>
          </DesktopCard>
        </RoundedFullHeight>
      </FlowTemplate>
    </>
  );
};
