import React, { FC, lazy, useEffect, useMemo } from "react";
import "@adyen/adyen-web/dist/adyen.css";
import "../../payment/adyen/payment.css";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { PaymentType } from "../../../domain-common/booking-cart-total-price";
import { initialPaymentState } from "../../payment/payment.slice";
import { useAppDispatch } from "../../../state/store";
import { bookingCreationStorageIdentifier } from "../../payment/payment-store-listener";
import { PaymentOption, SELECTED_PAYMENT_OPTION } from "../../payment/payment-constants";
import { useSearchParams } from "../../additional-services/use-search-params";
import { useAdyenConfig } from "../../../util/hooks/use-configuration";
import { PaymentSelectComponent } from "../../payment/payment-select-component";
import { useHandlePaymentOption } from "../../payment/use-handle-payment-option";
import { TerminalPay } from "../../payment/terminal-pay/terminal-pay";
import { TerminalPayType } from "../../payment/terminal-pay/terminal-pay-type";
import { Backdrop } from "@mui/material";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { errorHandleForComponentLazyLoad } from "../../../util/code-split.util";

const PaymentAdyenWidgetComponent = lazy(() =>
  import("../../payment/adyen/payment-adyen-widget-component")
    .then(({ PaymentAdyenWidgetComponent }) => ({
      default: PaymentAdyenWidgetComponent
    }))
    .catch(errorHandleForComponentLazyLoad)
);

interface SearchBookingPaymentComponentProps {
  onSuccessPayment: (magicId?: string) => void;
  returnUrl: string;
  magicId?: string;
  magicToken?: string;
  guaranteeType?: PaymentType;
}

export const SearchBookingPaymentComponent: FC<SearchBookingPaymentComponentProps> = ({
  returnUrl,
  magicId,
  magicToken,
  guaranteeType,
  onSuccessPayment
}) => {
  const dispatch = useAppDispatch();

  const { handlePaymentOption, paymentOptions } = useHandlePaymentOption({
    magicId,
    magicToken,
    returnUrl,
    onSuccessPayment
  });
  const mustPrepay = useMemo(() => guaranteeType === PaymentType.PREPAYMENT, [guaranteeType]);

  useEffect(() => {
    dispatch(initialPaymentState({ identifier: bookingCreationStorageIdentifier }));
  }, [dispatch]);
  const adyenEnvConfig = useAdyenConfig();

  const { params } = useSearchParams({
    initObject: {
      [SELECTED_PAYMENT_OPTION]:
        [PaymentOption.ADYEN_WIDGET].includes(paymentOptions[0]) && paymentOptions.length === 1
          ? paymentOptions[0]
          : ""
    },
    replacePath: true
  });

  const selectedPaymentOption = useMemo(
    () => params[SELECTED_PAYMENT_OPTION] as PaymentOption,
    [params]
  );

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  if (PaymentOption.ADYEN_WIDGET === params[SELECTED_PAYMENT_OPTION]) {
    return (
      <>
        <PageHeadingInfo
          title={
            mustPrepay
              ? tCommon("title__payment_create_booking")
              : tCommon("title__payment_create_booking_guarantee")
          }
          subtitle={
            mustPrepay
              ? tCommon("subtitle__payment_create_booking_prepayment")
              : tCommon("subtitle__payment_create_booking_guarantee")
          }
        />
        <PaymentAdyenWidgetComponent
          onSuccessPayment={onSuccessPayment}
          returnUrl={returnUrl}
          labels={{
            payButtonLabel: mustPrepay
              ? `${tCommon("buttons__pay")}`
              : `${tCommon("buttons__guarantee")}`
          }}
          envConfig={adyenEnvConfig}
        />
      </>
    );
  } else if (selectedPaymentOption === PaymentOption.TERMINAL_PAYMENT) {
    return (
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: "rgba(256,256,256,0.8)"
        }}
        open
      >
        <TerminalPay
          magicId={magicId}
          onSuccess={onSuccessPayment}
          returnUrl={returnUrl.concat(
            `?${SELECTED_PAYMENT_OPTION}=${PaymentOption.TERMINAL_PAYMENT}`
          )}
          payType={TerminalPayType.PAYMENT}
        />
      </Backdrop>
    );
  } else {
    return (
      <PaymentSelectComponent
        paymentOptions={paymentOptions}
        handlePaymentOption={handlePaymentOption}
        selectedPaymentOption={selectedPaymentOption}
      />
    );
  }
};
