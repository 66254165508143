import {
  AdditionalTravelBuddyFieldsConfig,
  RadioButton,
  useCountries
} from "@likemagic-tech/sv-magic-library";
import { Grid } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import React from "react";
import { Guest, GuestFormDataType } from "../../domain-common/guest";
import { PersonGender } from "../../domain-common/person";
import { FastInput } from "./fast-input";
import { FastPhoneInput } from "./fast-phone-input";
import { FastRadioButtonGroup } from "./fast-radio-button-group";
import { DatePicker } from "../atoms/input/date-picker";
import { getI18nSelectedLanguage } from "../../util/lang-utils";
import { WrappedNativeSelect } from "../atoms/input/wrapped-native-select";
import { useReservationContext } from "../../features/reservation-provider/reservation-provider";
import { WrappedInput } from "../atoms/input/wrapped-input";

export type GuestFormLabels = {
  genderFemale: string;
  genderMale: string;
  genderOther: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  birthdate?: string;
  nationalityCountryCode?: string;
  postalCode?: string;
  address?: string;
  countryCode?: string;
  city?: string;
};

export interface GuestFormProps {
  objectPathPrefix: string;
  values: Guest;
  touched?: FormikTouched<GuestFormDataType>;
  errors?: FormikErrors<GuestFormDataType>;
  labels: GuestFormLabels;
  disabled?: boolean;
  diverseGenderEnabled: boolean;
  genderDisplayDisabled: boolean;
  onChange: (event: any) => void;
  additionalFields: AdditionalTravelBuddyFieldsConfig | undefined;
}

export const GuestForm: React.FC<GuestFormProps> = ({
  objectPathPrefix,
  values,
  touched,
  errors,
  labels,
  disabled,
  diverseGenderEnabled,
  genderDisplayDisabled,
  onChange,
  additionalFields
}) => {
  const { reservation } = useReservationContext();
  const { preferredCountries, sortedCountries } = useCountries({
    propertyId: reservation?.propertyId
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!genderDisplayDisabled && (
          <FastRadioButtonGroup name={`${objectPathPrefix}.gender`} value={values.gender || ""}>
            <RadioButton
              value={PersonGender.FEMALE}
              label={labels?.genderFemale}
              disabled={disabled}
            />
            {diverseGenderEnabled && (
              <RadioButton
                value={PersonGender.OTHER}
                label={labels?.genderOther}
                disabled={disabled}
              />
            )}
            <RadioButton value={PersonGender.MALE} label={labels?.genderMale} disabled={disabled} />
          </FastRadioButtonGroup>
        )}
      </Grid>
      <Grid item xs={6}>
        <FastInput
          name={`${objectPathPrefix}.firstName`}
          value={values.firstName || ""}
          label={labels?.firstName}
          error={touched?.firstName && errors?.firstName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FastInput
          name={`${objectPathPrefix}.lastName`}
          value={values.lastName || ""}
          label={labels?.lastName}
          error={touched?.lastName && errors?.lastName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <FastPhoneInput
          name={`${objectPathPrefix}.phone`}
          value={values.phone || ""}
          error={touched?.phone && !!errors?.phone}
          helperText={touched?.phone && errors?.phone}
          label={labels?.phone}
          disabled={disabled}
          preferredCountries={preferredCountries}
        />
      </Grid>
      <Grid item xs={12}>
        <FastInput
          id={`${objectPathPrefix}.email`}
          name={`${objectPathPrefix}.email`}
          value={values.email || ""}
          type="email"
          label={labels?.email}
          error={touched?.email && errors?.email}
          disabled={disabled}
        />
      </Grid>
      {additionalFields?.dateOfBirth && (
        <Grid item xs={6}>
          <DatePicker
            id={`${objectPathPrefix}.birthdate`}
            name={`${objectPathPrefix}.birthdate`}
            label={labels.birthdate}
            value={values.birthdate}
            error={touched?.birthdate && errors?.birthdate}
            disabled={disabled ?? false}
            onChange={onChange}
          />
        </Grid>
      )}
      {additionalFields?.nationalityCountryCode && (
        <Grid item xs={6}>
          <WrappedNativeSelect
            id={`${objectPathPrefix}.nationalityCountryCode`}
            label={labels.nationalityCountryCode ?? ""}
            value={values.nationalityCountryCode ?? ""}
            items={sortedCountries(getI18nSelectedLanguage())}
            onChange={onChange}
            disabled={disabled}
            error={touched?.nationalityCountryCode && errors?.nationalityCountryCode}
          />
        </Grid>
      )}
      {additionalFields?.addressLine1 && (
        <Grid item xs={6}>
          <WrappedInput
            id={`${objectPathPrefix}.address.addressLine1`}
            value={values.address?.addressLine1}
            onChange={onChange}
            label={labels.address}
            disabled={disabled}
            error={touched?.address?.addressLine1 && errors?.address?.addressLine1}
          />
        </Grid>
      )}
      {additionalFields?.postalCode && (
        <Grid item xs={6}>
          <WrappedInput
            id={`${objectPathPrefix}.address.postalCode`}
            value={values.address?.postalCode}
            onChange={onChange}
            label={labels.postalCode}
            disabled={disabled}
            error={touched?.address?.postalCode && errors?.address?.postalCode}
          />
        </Grid>
      )}
      {additionalFields?.city && (
        <Grid item xs={6}>
          <WrappedInput
            id={`${objectPathPrefix}.address.city`}
            value={values.address?.city}
            onChange={onChange}
            label={labels.city}
            disabled={disabled}
            error={touched?.address?.city && errors?.address?.city}
          />
        </Grid>
      )}
      {additionalFields?.countryCode && (
        <Grid item xs={6}>
          <WrappedNativeSelect
            id={`${objectPathPrefix}.address.countryCode`}
            name={`${objectPathPrefix}.address.countryCode`}
            label={labels.countryCode ?? ""}
            value={values.address?.countryCode ?? ""}
            items={sortedCountries(getI18nSelectedLanguage())}
            onChange={onChange}
            disabled={disabled}
            error={touched?.address?.countryCode && errors?.address?.countryCode}
          />
        </Grid>
      )}
    </Grid>
  );
};
