import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Dialog } from "../atoms";
import { useGuestFlow } from "../../features/guest-flow/use-guest-flow";
import { isReservation } from "../../util/flow";
import parsePhoneNumber from "libphonenumber-js/max";
import { useNavigate } from "react-router-dom";
import { GuestFlowCheckpoint } from "../../features/guest-flow/checkpoint/guest-flow-checkpoint";
import { Reservation } from "../../domain-common/reservation";
import { emptyReservationExtras, ReservationExtras } from "../../domain-common/reservation-extras";
import { Box, DialogContentText } from "@mui/material";
import { CommunicationChannel, useCountries } from "@likemagic-tech/sv-magic-library";
import yup from "../../config/yup.config";
import { SubUpdate } from "../../features/reservation-provider/sub-update";
import { WrappedInput } from "../atoms/input/wrapped-input";
import { WrappedPhoneInput } from "../atoms/input/wrapped-phone-input";

interface PreferredCommunicationAdditionalDataModalProps {
  channel: string;
  resetToInitValue: () => void;
  nextPageLink: string;
  t: (v: string) => string;
}

export const PreferredCommunicationAdditionalDataModal: FC<
  PreferredCommunicationAdditionalDataModalProps
> = ({ channel, resetToInitValue, nextPageLink, t }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const { patchGuestFlowStep, person, reservation } = useGuestFlow();
  const navigate = useNavigate();
  const { preferredCountries } = useCountries({ propertyId: reservation?.propertyId ?? "" });

  const field = useMemo(
    () => (channel === CommunicationChannel.EMAIL ? "email" : "phone"),
    [channel]
  );

  useEffect(() => {
    if (
      (CommunicationChannel.SMS === channel || CommunicationChannel.WHATSAPP === channel) &&
      !person.phone
    ) {
      setIsOpen(true);
    }
    if (CommunicationChannel.EMAIL === channel && !person.email) {
      setIsOpen(true);
    }
  }, [channel, person]);

  const isInputValid = useCallback(async () => {
    if (channel === CommunicationChannel.EMAIL) {
      const schema = yup.object().shape({
        email: yup.string().email()
      });
      return await schema.isValid({ email: value });
    } else {
      const phoneNumber = parsePhoneNumber(value);

      return phoneNumber?.isValid() && phoneNumber?.getType() === "MOBILE";
    }
  }, [value, channel]);

  const handleSubmit = useCallback(async () => {
    const validValue = await isInputValid();

    if (validValue) {
      const oldExtras = reservation.extras ? reservation.extras : emptyReservationExtras();
      const updatedExtras: ReservationExtras = {
        ...oldExtras,
        preferredCommunicationChannel: channel
      };
      const person = isReservation(reservation) ? "primaryGuest" : "travelBuddy";

      const newReservation: Reservation = {
        ...reservation,
        extras: updatedExtras,
        [person]: { ...reservation[person], [field]: value }
      };

      try {
        await patchGuestFlowStep({
          reservationValues: newReservation,
          checkpoint: GuestFlowCheckpoint.PREFERRED_CHANNEL,
          subUpdate: SubUpdate.PREFERRED_CHANNEL
        });
        navigate(nextPageLink);
      } catch (e) {
        console.error("An error occurred patch preferred communication channel", e);
      }
    } else {
      setError(t(`validation__${field}_format`));
    }
  }, [
    patchGuestFlowStep,
    isInputValid,
    field,
    reservation,
    navigate,
    value,
    t,
    channel,
    nextPageLink
  ]);

  const dismissModal = useCallback(() => {
    setIsOpen(false);
    resetToInitValue();
  }, [resetToInitValue]);

  const input = useMemo(() => {
    return channel === CommunicationChannel.EMAIL.toString() ? (
      <WrappedInput
        value={value}
        label={t("labels__email")}
        onChange={(e) => setValue(e.target.value)}
        error={error}
      />
    ) : (
      <WrappedPhoneInput
        value={value}
        label={t("labels__phone")}
        onChange={setValue}
        error={!!error}
        helperText={!!error && error}
        preferredCountries={preferredCountries}
      />
    );
  }, [channel, value, t, error, preferredCountries]);

  return (
    <Dialog
      open={isOpen}
      onConfirm={handleSubmit}
      onDismiss={dismissModal}
      title={t(`modals__preferred_channel_missing_${field}_title`)}
      content={
        <>
          <DialogContentText>
            {t(`modals__preferred_channel_missing_${field}_subtitle`)}
          </DialogContentText>
          <Box mt={2}>{input}</Box>
        </>
      }
      buttonLabel={t("buttons__save")}
    />
  );
};
