import { useAppDispatch } from "../../state/store";
import { useEffect, useMemo } from "react";
import {
  fetchUserProfile,
  selectUserKeycloakUUID,
  selectUserLanguage,
  selectUserProfileID
} from "./user-profile.slice";
import { useApiVersion, useAuth } from "@likemagic-tech/sv-magic-library";
import { useSelector } from "react-redux";
import { defaultLanguageWithLocale } from "../../util/lang-utils";
import { useGetUserProfileQuery } from "../../graphql/queries/GetUserProfile.generated";

export const useUserProfile = () => {
  const { isRESTVersion } = useApiVersion();

  const { getIsAuthenticated } = useAuth();
  const dispatch = useAppDispatch();
  const userLanguageV1 = useSelector(selectUserLanguage) || defaultLanguageWithLocale;
  const userKeycloakUUIDV1 = useSelector(selectUserKeycloakUUID);
  const userProfileIdV1 = useSelector(selectUserProfileID);

  useEffect(() => {
    if (getIsAuthenticated() && isRESTVersion) {
      dispatch(fetchUserProfile());
    }
  }, [dispatch, getIsAuthenticated, isRESTVersion]);

  const { data: userProfileV2 } = useGetUserProfileQuery(undefined, {
    skip: isRESTVersion || !getIsAuthenticated()
  });

  return useMemo(
    () => ({
      userLanguage: isRESTVersion
        ? userLanguageV1
        : userProfileV2?.GetUserProfile?.preferredLanguage ?? "",
      userKeycloakUUID: isRESTVersion
        ? userKeycloakUUIDV1
        : userProfileV2?.GetUserProfile?.userAccountUuid ?? "",
      userProfileId: isRESTVersion ? userProfileIdV1 : userProfileV2?.GetUserProfile?.id ?? ""
    }),
    [
      isRESTVersion,
      userLanguageV1,
      userKeycloakUUIDV1,
      userProfileIdV1,
      userProfileV2?.GetUserProfile?.preferredLanguage,
      userProfileV2?.GetUserProfile?.userAccountUuid,
      userProfileV2?.GetUserProfile?.id
    ]
  );
};
