import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function ShoppingCartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M2.66667 9.33333C2.66667 9.68696 2.80714 10.0261 3.05719 10.2761C3.30724 10.5262 3.64638 10.6667 4 10.6667H13.3333C13.6131 10.6665 13.8856 10.5783 14.1125 10.4146C14.3393 10.2509 14.5089 10.0201 14.5973 9.75467L15.9307 5.75467C15.9974 5.55433 16.0156 5.34101 15.9837 5.13227C15.9519 4.92353 15.8709 4.72533 15.7475 4.554C15.6241 4.38267 15.4617 4.2431 15.2738 4.14678C15.0859 4.05047 14.8778 4.00016 14.6667 4H5.33333V1.33333C5.33333 0.979711 5.19286 0.640573 4.94281 0.390524C4.69276 0.140476 4.35362 0 4 0L0 0V2.66667H2.66667V9.33333Z" />
      <path d="M3.3335 16C4.43807 16 5.3335 15.1046 5.3335 14C5.3335 12.8954 4.43807 12 3.3335 12C2.22893 12 1.3335 12.8954 1.3335 14C1.3335 15.1046 2.22893 16 3.3335 16Z" />
      <path d="M14 16C15.1046 16 16 15.1046 16 14C16 12.8954 15.1046 12 14 12C12.8954 12 12 12.8954 12 14C12 15.1046 12.8954 16 14 16Z" />
    </SvgIcon>
  );
}
