import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../state/store";
import {
  fetchAllServiceDetails,
  selectServiceDetailsById,
  selectServiceDetailsStatus
} from "./service-details-cms.slice";
import { useEffect } from "react";
import { useCmsClient } from "../../api/cms-client/use-cms-client";

export const useServiceDetails = (serviceId?: string) => {
  const prismic = useCmsClient();

  const dispatch = useAppDispatch();

  const serviceStatus = useSelector((state: RootState) => selectServiceDetailsStatus(state));

  const serviceDetailCMSData = useSelector((state: RootState) =>
    selectServiceDetailsById(state, serviceId || "")
  );

  useEffect(() => {
    dispatch(
      fetchAllServiceDetails({
        prismic: prismic
      })
    );
  }, [dispatch, serviceId, prismic]);

  return { data: serviceDetailCMSData, status: serviceStatus };
};
