import React from "react";
import { getPwaStartUrl } from "../util/routing";
import { usePwaConfig } from "../util/hooks/use-configuration";

interface ManifestProps {}

/**
 * This component requires <link rel="manifest" id="manifest-link"/> to be in index.html
 */
export const Manifest: React.FC<React.PropsWithChildren<ManifestProps>> = () => {
  const pwa = usePwaConfig();

  React.useEffect(() => {
    const manifestLink = document.getElementById("manifest-link");
    let startUrl = getPwaStartUrl();
    const myDynamicManifest = {
      short_name: pwa?.shortName,
      name: pwa?.name,
      icons: [
        {
          src: `${window.location.origin}/${pwa?.icons.favicon}`,
          sizes: "64x64 32x32 24x24 16x16",
          type: "image/x-icon"
        },
        {
          src: `${window.location.origin}/${pwa?.icons.logoMedium}`,
          type: "image/png",
          sizes: "192x192"
        },
        {
          src: `${window.location.origin}/${pwa?.icons.logoBig}`,
          type: "image/png",
          sizes: "512x512"
        },
        {
          src: `${window.location.origin}/${pwa?.icons.maskable}`,
          type: "image/png",
          sizes: "192x192",
          purpose: "maskable"
        }
      ],
      start_url: startUrl,
      display: "standalone",
      theme_color: pwa?.themeColor,
      background_color: pwa?.backgroundColor
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    manifestLink?.setAttribute("href", manifestURL);
  }, [pwa]);

  return null;
};
