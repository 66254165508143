export enum CheckoutFlowPages {
  DEPARTURE_TIME = "DEPARTURE_TIME",
  MINIBAR = "MINIBAR",
  BILL = "BILL",
  PAYMENT = "PAYMENT",
  PAYMENT_REDIRECT = "PAYMENT_REDIRECT",
  CONFIRMATION = "CONFIRMATION"
}

export const guestCheckoutFlowList: Array<CheckoutFlowPages> = [
  CheckoutFlowPages.DEPARTURE_TIME,
  CheckoutFlowPages.MINIBAR,
  CheckoutFlowPages.BILL,
  CheckoutFlowPages.PAYMENT,
  CheckoutFlowPages.CONFIRMATION
];

export const getCheckoutFlowProgress = (page: CheckoutFlowPages) => {
  return (
    ((guestCheckoutFlowList.findIndex((item) => item === page) + 1) * 100) /
    guestCheckoutFlowList.length
  );
};
