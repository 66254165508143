import React from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { responsiveVariables } from "../../layouts/hooks/use-is-mobile";
import { Button } from "@likemagic-tech/sv-magic-library";

export interface TopNavigationButtonProps {
  onClick: () => void;
}

const useStyles = makeStyles()(({ palette, breakpoints }: Theme) => ({
  root: {
    color: palette.background.paper,
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      color: palette.common.black
    },
    textDecoration: "none",
    fontSize: 17,
    fontWeight: "bold",
    padding: 0
  }
}));

export const TopNavigationButton: React.FC<React.PropsWithChildren<TopNavigationButtonProps>> = ({
  onClick,
  children
}) => {
  const { classes } = useStyles();
  return (
    <Button onClick={onClick} className={classes.root} variant="ghost">
      {children}
    </Button>
  );
};
