import { createSlice, Selector } from "@reduxjs/toolkit";
import { CMSSingleDocumentTypes } from "../cms/cms-single-document-types";
import {
  CMSPageState,
  createCMSSingleDocumentFetchThunk,
  createCMSSinglePageAdapter
} from "../cms/cms-single-page-factory";
import { RootState } from "../store";

export type CommonCMSState = CMSPageState;
export const commonCMSAdapter = createCMSSinglePageAdapter();

const initialState: CommonCMSState = commonCMSAdapter.getInitialState();

export const fetchCommonCMS = createCMSSingleDocumentFetchThunk(CMSSingleDocumentTypes.common);

export const commonCMSSlice = createSlice({
  name: "commonCMS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommonCMS.pending, commonCMSAdapter.handlePendingLoadingStatus)
      .addCase(fetchCommonCMS.fulfilled, (state, action) => {
        commonCMSAdapter.handleFulfilledLoadingStatus(state, action);
        commonCMSAdapter.addOne(state, action);
      })
      .addCase(fetchCommonCMS.rejected, commonCMSAdapter.handleRejectedLoadingStatus);
  }
});

export const selectSelf: Selector<RootState, CommonCMSState> = (state: RootState) =>
  state.cms[CMSSingleDocumentTypes.common];

export const { selectAll: selectAllCommonCMS, selectById: selectCommonCMSById } =
  commonCMSAdapter.getSelectors<RootState>(selectSelf);

export const { selectByLang: selectAllCommonCMSByLang } =
  commonCMSAdapter.getCmsSelectors(selectSelf);
