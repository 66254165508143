import { useCallback, useContext } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { QueryCacheKey } from "@reduxjs/toolkit/dist/query/core/apiState";
import { subscribeSecondScreenDeviceEventsApi } from "../../graphql/subscriptions/subscribe-second-screen-device-events";
import { useAppDispatch } from "../../state/store";

export const useSubscribeSecondScreenDeviceEvents = () => {
  const dispatch = useAppDispatch();
  const { tenantId, apiKey } = useContext(TenantContext);

  const subscribeAction = useCallback(
    ({
      deviceId,
      handleResponse
    }: {
      deviceId: string;
      handleResponse: (response: { errors?: Array<{ message: string }>; data: any }) => void;
    }) =>
      dispatch(
        subscribeSecondScreenDeviceEventsApi.endpoints.SubscribeSecondScreenDeviceEvents.initiate({
          tenantId,
          apiKey,
          deviceId,
          handleResponse
        })
      ),
    [dispatch, tenantId, apiKey]
  );

  const unsubscribeAction = useCallback(
    (queryCacheKey: QueryCacheKey) => {
      dispatch(
        subscribeSecondScreenDeviceEventsApi.internalActions.removeQueryResult({
          queryCacheKey: queryCacheKey
        })
      );
    },
    [dispatch]
  );

  return { subscribeAction, unsubscribeAction };
};
