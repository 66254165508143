import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CartButtonNavigation } from "../../../components/molecules/cart-button-navigation";
import { ContactButtonNavigation } from "../../../components/molecules/contact-button-navigation";
import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { BackButton } from "../../../components/atoms/back-button/back-button";

export const useSetupSubpageNavigation = (
  urlGenerator?: (magicId: string) => string,
  hideBackButton?: boolean
) => {
  const { reservation } = useReservationContext();
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const { shopEnabled } = useFeatureFlags();

  const { setLeftButtons, setRightButtons, setProgress, setDisplayNavbar } = useNavbar();
  const goBack = useCallback(
    () => (urlGenerator ? navigate(urlGenerator(reservation.magicId)) : navigate(-1)),
    [navigate, reservation.magicId, urlGenerator]
  );

  useEffect(() => {
    setProgress(0);
    setDisplayNavbar(true);
  }, [setProgress, setDisplayNavbar]);
  const backIconRef = useRef(
    hideBackButton ? null : <BackButton key="back_button" onClick={goBack} />
  );

  useEffect(() => {
    setLeftButtons([backIconRef.current]);
  }, [setLeftButtons]);

  useEffect(() => {
    const buttons = [<ContactButtonNavigation key="contact_button" />];
    if (shopEnabled) buttons.unshift(<CartButtonNavigation key="cart_button" magicId={magicId} />);
    setRightButtons(buttons);
  }, [setRightButtons, setProgress, setDisplayNavbar, magicId, shopEnabled]);
};
