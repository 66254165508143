import { createAsyncThunk, createSelector, createSlice, Selector } from "@reduxjs/toolkit";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { MagicApi } from "../../api/magic.api";
import { Reservation } from "../../domain-common/reservation";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { handleSliceError } from "../../util/error-handling";
import { getTenantHeaders } from "../../api/custom-headers";

interface CheckinFlowStateSlice {
  status: EntityStateStatus;
}

const initialState: CheckinFlowStateSlice = {
  status: EntityStateStatus.IDLE
};

export const startCheckin = createAsyncThunk<
  any,
  { reservation: Reservation },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("checkin/startCheckin", async (arg, thunkAPI) => {
  try {
    return await MagicApi.checkin(arg.reservation, {
      signal: thunkAPI.signal,
      ...(await getTenantHeaders(thunkAPI.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const checkinSlice = createSlice({
  name: "checkin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startCheckin.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(startCheckin.fulfilled, (state) => {
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(startCheckin.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      });
  }
});

const selectSelf: Selector<RootState, CheckinFlowStateSlice> = (state: RootState) =>
  state[checkinSlice.name];

export const selectIsCheckingIn = createSelector(
  selectSelf,
  (s) => s.status === EntityStateStatus.LOADING
);
