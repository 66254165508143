import React from "react";
import { Reservation } from "../../../domain-common/reservation";
import { GuestFlowCheckpoint } from "../../../features/guest-flow/checkpoint/guest-flow-checkpoint";
import { getNextFlowCheckpoint } from "../../../features/guest-flow/guest-flow-sequence";
import { CallToActionCheckpointButton } from "./call-to-action-checkpoint-button";
import { CallToActionGuestFlowFinishedButton } from "./call-to-action-guest-flow-finished-button";
import { isReservation } from "../../../util/flow";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { ReservationStatus } from "../../../domain-common/reservation-status";

interface GuestFlowCallToActionProps {
  reservation: Reservation;
}

export const GuestFlowCallToAction: React.FC<
  React.PropsWithChildren<GuestFlowCallToActionProps>
> = ({ reservation }) => {
  const { payAtCheckoutEnabled, checkoutFlowEnabled } = useFeatureFlags();

  const nextFlowCheckpoint: GuestFlowCheckpoint = React.useMemo(
    () => getNextFlowCheckpoint(reservation.flowState?.context?.lastConfirmedPage, reservation),
    [reservation]
  );

  if (
    !reservation.flowState.wasOrIsCompleted &&
    reservation.status !== ReservationStatus.IN_HOUSE &&
    reservation.status !== ReservationStatus.CHECKED_OUT
  ) {
    return (
      <CallToActionCheckpointButton
        reservation={reservation}
        nextFlowCheckpoint={nextFlowCheckpoint}
      />
    );
  }
  // There is no action for travel buddy when the flow is completed.
  if (isReservation(reservation)) {
    return (
      <CallToActionGuestFlowFinishedButton
        reservation={reservation}
        payAtCheckoutEnabled={payAtCheckoutEnabled}
        checkoutFlowEnabled={checkoutFlowEnabled}
      />
    );
  }
  return null;
};
