export interface Unit {
  id: string;
  name: string;
  unitGroupId: string;
  description: string;
  privacyMode?: boolean;
  status: UnitStatus;
}

export interface UnitStatus {
  isOccupied: boolean;
  condition: UnitCondition;
}

export enum UnitCondition {
  CLEAN = "Clean",
  CLEAN_TO_BE_INSPECTED = "CleanToBeInspected",
  DIRTY = "Dirty"
}
