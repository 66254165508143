import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ForbiddenPage } from "../../components/organism/forbidden.page";
import { NotFoundPage } from "../../components/organism/not-found.page";
import { EntityStateStatus, isStatusSuccess } from "../../state/EntityStateStatus";
import { useAppDispatch } from "../../state/store";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import { initialPaymentState } from "../payment/payment.slice";
import { initMagicObject } from "./magic-object.slice";
import { useFetchMagicObject } from "./use-fetch-magic-object";
import { useMagicObjectSubpathResolver } from "./use-magic-object-subpath-resolver";
import { usePreloaderForPrismic } from "./use-preloader-for-prismic";
import {
  setLastMagicId,
  setLastPropertyId
} from "../restore-magic-context/restore-magic-context.slice";
import {
  selectInitialMagicId,
  setInitialMagicId
} from "../initial-magic-info/initial-magic-id.slice";
import { initReservationSlice } from "../reservation-provider/reservation.slice";
import { BookingOverviewRouterPage } from "../booking-overview/booking-overview-router.page";
import { useMagicIdParams } from "./use-magic-id-params";
import { ReservationProvider } from "../reservation-provider/reservation-provider";
import { GuestFlowRouterPage } from "../guest-flow/guest-flow-router.page";
import { PortalPage } from "../portal/portal.page";
import { isKioskMode } from "../../util/kiosk-mode";
import { closeBanner } from "../banner/banner.slice";
import { isBooking, isReservation, isTravelBuddy } from "../../util/flow";
import { mostFrequent } from "../../util/array.util";
import { MarketingConsentConfirmationPage } from "../portal/pages/marketing-consent-confirmation.page";
import { CheckoutFlowRouterPages } from "../checkout-flow/checkout-flow-router-pages";

interface MagicPageProps {}

export const MagicPage: React.FC<React.PropsWithChildren<MagicPageProps>> = () => {
  const dispatch = useAppDispatch();
  const { magicId } = useMagicIdParams();
  const { login, authenticated } = useAuth();

  const initialMagicId = useSelector(selectInitialMagicId);
  const { magicObject, magicObjectStatus } = useFetchMagicObject({ magicId });
  useEffect(() => {
    if (!initialMagicId && isStatusSuccess(magicObjectStatus)) {
      dispatch(setInitialMagicId({ initialMagicId: magicId }));
    }
  }, [dispatch, initialMagicId, magicId, magicObjectStatus]);

  useEffect(() => {
    // In case of deep linking with the native app it can happen that there is an error banner for a first endpoint call.
    // This is needed just to clean it and to provide a good experience to the user.
    dispatch(closeBanner());
  }, [dispatch, authenticated]);

  useMagicObjectSubpathResolver(magicObject);
  usePreloaderForPrismic(magicObject);

  React.useEffect(() => {
    if (magicObject?.magicId) {
      dispatch(initialPaymentState({ identifier: magicObject.magicId }));
      // This should not be destructed after leaving page.
      // We need magicId so that we could return to the last visited reservation.
      dispatch(setLastMagicId(magicObject.magicId));
    }
  }, [magicObject?.magicId, dispatch]);

  React.useEffect(() => {
    if (isReservation(magicObject) || isTravelBuddy(magicObject)) {
      dispatch(setLastPropertyId(magicObject.propertyId));
    } else if (isBooking(magicObject)) {
      const mostFrequentPropertyId = mostFrequent(
        magicObject.reservations.map((res) => res.propertyId)
      );
      dispatch(setLastPropertyId(mostFrequentPropertyId));
    }
  }, [magicObject, dispatch]);

  React.useEffect(
    () => () => {
      dispatch(initMagicObject());
      dispatch(initReservationSlice());
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (magicObjectStatus === EntityStateStatus.UNAUTHORIZED) {
      login({
        prompt: isKioskMode() ? "login" : undefined
      });
    }
  }, [login, magicObjectStatus]);

  if (
    magicObjectStatus === EntityStateStatus.IDLE ||
    magicObjectStatus === EntityStateStatus.LOADING
  ) {
    return null;
  }

  if (magicObjectStatus === EntityStateStatus.FORBIDDEN) {
    return <ForbiddenPage />;
  }

  if (magicObjectStatus === EntityStateStatus.FAILED) {
    return <NotFoundPage magicObject={magicObject} magicObjectStatus={magicObjectStatus} />;
  }

  if (magicObjectStatus === EntityStateStatus.UNAUTHORIZED) {
    // do not render for unauthorized, useEffect will redirect to login
    return null;
  }

  return (
    <Routes>
      <Route path="booking-overview/*" element={<BookingOverviewRouterPage />} />
      <Route
        path="portal/*"
        element={
          <ReservationProvider magicObject={magicObject}>
            <PortalPage />
          </ReservationProvider>
        }
      />
      <Route
        path="booking/guest/*"
        element={
          <ReservationProvider magicObject={magicObject}>
            <GuestFlowRouterPage />
          </ReservationProvider>
        }
      />

      <Route
        path="booking/checkout/*"
        element={
          <ReservationProvider magicObject={magicObject}>
            <CheckoutFlowRouterPages />
          </ReservationProvider>
        }
      />

      <Route
        path="marketing-consent/"
        element={
          <ReservationProvider magicObject={magicObject}>
            <MarketingConsentConfirmationPage />
          </ReservationProvider>
        }
      />
      {/* Explanation: Here we catch the redirect, so it does not fall under 404 */}
      <Route path={"/"} element={null} />
      <Route
        path={"/*"}
        element={<NotFoundPage magicObjectStatus={magicObjectStatus} magicObject={magicObject} />}
      />
    </Routes>
  );
};
