import { BillFolio, FormDebitor } from "../../../features/portal/pages/my-stay-bills.page";
import { Folio } from "../../../domain-common/folio";
import { Reservation } from "../../../domain-common/reservation";
import { homeAddressDebitor } from "../../../util/reservation";

export const mapFolio = (
  folio: Folio,
  reservation: Reservation,
  tCommon: (s: string) => string
): BillFolio => {
  const formDebitors: FormDebitor[] = [
    {
      label: tCommon("labels__home_address"),
      debitor: homeAddressDebitor(reservation),
      key: "home_address"
    }
  ];
  let selectedDebitorKey = formDebitors[0].key;

  const debitor = folio?.debitor;
  if (
    debitor?.address &&
    //@ts-ignore
    (reservation?.primaryGuest?.address?.addressLine1 !== debitor?.address?.addressLine1 ||
      debitor?.company)
  ) {
    formDebitors.push({
      label: tCommon("labels__billing_address"),
      debitor: debitor,
      key: "billing_address"
    });
    selectedDebitorKey = formDebitors[1].key;
  }
  return {
    ...folio,
    formDebitors: formDebitors,
    selectedDebitorKey: selectedDebitorKey
  };
};
