import * as Types from "../generated/graphql";

import { BookingOverviewFragmentFragmentDoc } from "../queries/fragments/BookingOverviewFragment.generated";
import { TravelBuddyFragmentFragmentDoc } from "../queries/fragments/TravelBuddyFragment.generated";
import { TravelBuddyPersonFragmentFragmentDoc } from "../queries/fragments/TravelBuddyPersonFragment.generated";
import { ReservationEntityFragmentDoc } from "../queries/fragments/ReservationFragment.generated";
import { PersonFragmentFragmentDoc } from "../queries/fragments/PersonFragment.generated";
import { FolioFragmentFragmentDoc } from "../queries/fragments/FolioFragment.generated";
import { PriceFragmentFragmentDoc } from "../queries/fragments/PriceFragment.generated";
import { GroupedRateBreakdownFragmentFragmentDoc } from "../queries/fragments/RateBreakdownFragment.generated";
import { graphqlApi } from "../graphql.api";
export type StreamBookingOverviewSubscriptionVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
}>;

export type StreamBookingOverviewSubscription = {
  __typename?: "Subscription";
  StreamBookingOverview: {
    __typename: "BookingOverview";
    actor: Types.Actor;
    pmsBookingId: string;
    userAccountUuid?: string | null;
    magicId: string;
    magicToken: string;
    flowState: {
      __typename?: "FlowState";
      wasOrIsCompleted: boolean;
      completed: boolean;
      context: any;
      notificationPending: boolean;
      notificationSent: boolean;
      sentNotifications: Array<string>;
      performedActions: Array<string>;
      fastCheckinAvailable: boolean;
    };
    items: Array<{
      __typename?: "BookingOverviewItem";
      id: string;
      active: boolean;
      arrival: any;
      pmsBookingId: string;
      bookingManagementCompleted: boolean;
      departure: any;
      pmsPropertyId: string;
      travelBuddies: Array<{
        __typename: "TravelBuddy";
        pmsPropertyId: string;
        pmsReservationId: string;
        magicId: string;
        magicToken: string;
        actor: Types.Actor;
        pmsBookingId: string;
        bookingMagicId: string;
        bookingOverviewItemId: string;
        status: Types.ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        reservationId: number;
        reservationMagicId: string;
        unitCleanOnCheckin: boolean;
        created: any;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: Types.UnitState | null;
          floor?: string | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        flowState: {
          __typename?: "FlowState";
          completed: boolean;
          context: any;
          fastCheckinAvailable: boolean;
          notificationPending: boolean;
          notificationSent: boolean;
          performedActions: Array<string>;
          sentNotifications: Array<string>;
          wasOrIsCompleted: boolean;
        };
        person: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Types.Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          preferredCommunicationChannel?: Types.CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          pmsId: string;
          preferredLanguage?: string | null;
          emergencyEvacuationHelpNeeded: boolean;
          enrolledInLoyaltyProgram: boolean;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
          } | null;
        };
      }>;
      reservations: Array<{
        __typename: "Reservation";
        actor: Types.Actor;
        pmsId: string;
        bookingOverviewItemId: string;
        userAccountUuid?: string | null;
        maxCompanions: number;
        magicId: string;
        magicToken: string;
        magicLink: string;
        isExternalReservation: boolean;
        channel?: string | null;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        createdAt?: any | null;
        status: Types.ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        estimatedArrivalTime?: any | null;
        estimatedDepartureTime?: any | null;
        cancellationTime?: any | null;
        idCheckStatus: Types.IdCheckStatus;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        secondaryGuests?: Array<{
          __typename?: "Person";
          pmsId: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Types.Gender | null;
        } | null> | null;
        travelBuddies: Array<{
          __typename: "TravelBuddy";
          pmsPropertyId: string;
          pmsReservationId: string;
          magicId: string;
          magicToken: string;
          actor: Types.Actor;
          pmsBookingId: string;
          bookingMagicId: string;
          bookingOverviewItemId: string;
          status: Types.ReservationStatus;
          arrival: any;
          departure: any;
          checkinTime?: any | null;
          checkoutTime?: any | null;
          reservationId: number;
          reservationMagicId: string;
          unitCleanOnCheckin: boolean;
          created: any;
          accessibleDoors?: Array<{
            __typename?: "AccessibleDoor";
            id?: string | null;
            isGeneral?: boolean | null;
            pin?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
            title?: string | null;
          } | null> | null;
          unit?: {
            __typename?: "Unit";
            pmsId?: string | null;
            name?: string | null;
            state?: Types.UnitState | null;
            floor?: string | null;
          } | null;
          unitGroup?: {
            __typename?: "UnitGroup";
            pmsId?: string | null;
            capacity?: number | null;
          } | null;
          flowState: {
            __typename?: "FlowState";
            completed: boolean;
            context: any;
            fastCheckinAvailable: boolean;
            notificationPending: boolean;
            notificationSent: boolean;
            performedActions: Array<string>;
            sentNotifications: Array<string>;
            wasOrIsCompleted: boolean;
          };
          person: {
            __typename?: "Person";
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Types.Gender | null;
            birthdate?: any | null;
            termsAndConditionsMarketingConsent: boolean;
            termsAndConditionsMarketingConsentDoubleOptin: boolean;
            termsAndConditionsGeneral: boolean;
            termsAndConditionsOnline: boolean;
            preferredCommunicationChannel?: Types.CommunicationChannel | null;
            identificationDocumentNumber?: string | null;
            pmsId: string;
            preferredLanguage?: string | null;
            emergencyEvacuationHelpNeeded: boolean;
            enrolledInLoyaltyProgram: boolean;
            address?: {
              __typename?: "Address";
              addressLine1?: string | null;
              addressLine2?: string | null;
              postalCode?: string | null;
              city?: string | null;
              countryCode?: string | null;
            } | null;
          };
        }>;
        primaryGuest?: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Types.Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          enrolledInLoyaltyProgram: boolean;
          emergencyEvacuationHelpNeeded: boolean;
          preferredCommunicationChannel?: Types.CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          nationality?: string | null;
          preferredLanguage?: string | null;
          pmsId: string;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
            region?: string | null;
          } | null;
        } | null;
        property: { __typename?: "Property"; pmsId: string };
        totalCharges?: {
          __typename?: "Price";
          grossPriceInCents?: number | null;
          netPriceInCents?: number | null;
          grossPrice?: any | null;
          netPrice?: any | null;
          currency?: string | null;
        } | null;
        totalPayments?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        totalAllowedPayment?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: Types.UnitState | null;
          floor?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        folios: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: Types.FolioType;
          debitorType: Types.DebitorType;
          prepaymentType: Types.PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: Types.AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: Types.PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        bookedServicesOverview: Array<{
          __typename?: "BookedService";
          dates: Array<{
            __typename?: "BookedServiceDate";
            serviceDate?: any | null;
            tags?: Array<Types.BookedServiceTag> | null;
            amount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          }>;
          service?: {
            __typename?: "BookedServiceDetails";
            magicServiceCodeEnum?: string | null;
            name: string;
            pmsServiceId: string;
            tags?: Array<Types.BookedServiceTag> | null;
            totalAmount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          } | null;
        }>;
        flowState: {
          __typename?: "ReservationFlowState";
          wasOrIsCompleted: boolean;
          completed: boolean;
          context: any;
          notificationPending: boolean;
          notificationSent: boolean;
          sentNotifications: Array<string>;
          performedActions: Array<string>;
          fastCheckinAvailable: boolean;
          dirtyUnitDialogSeen?: boolean | null;
          cleanUnitDialogSeen?: boolean | null;
          billConfirmed?: boolean | null;
          bookingOnBehalfOf?: Types.BookingOnBehalfOf | null;
          unitCleanOnCheckin?: boolean | null;
        };
        files: Array<{
          __typename?: "MagicFileDescriptor";
          id: string;
          fileName: string;
          contentType: string;
          magicFileType: string;
          ownerId: string;
        }>;
        cancellationFees: Array<{
          __typename?: "CancellationFee";
          pmsId?: string | null;
          name?: string | null;
          description?: string | null;
          dueDateTime?: any | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        }>;
        currentCancellationFee?: {
          __typename?: "CancellationFee";
          description?: string | null;
          dueDateTime?: any | null;
          name?: string | null;
          pmsId?: string | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        } | null;
        foliosToBePaid: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: Types.FolioType;
          debitorType: Types.DebitorType;
          prepaymentType: Types.PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: Types.AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: Types.PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        groupedRateBreakdown?: {
          __typename?: "GroupedRateBreakdown";
          rateSpliceTypeTuples?: Array<{
            __typename?: "RateSpliceTypeEnumTuple";
            type?: Types.RateSpliceTypeEnumContract | null;
            totalCharges?: {
              __typename?: "GrossPrice";
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              currency?: string | null;
            } | null;
            pmsIdToRateSplicesTuples?: Array<{
              __typename?: "PmsIdToRateSplicesTuple";
              pmsId?: string | null;
              rateSplices?: Array<{
                __typename?: "RateSplice";
                displayName?: string | null;
                quantity?: number | null;
                type?: Types.RateSpliceTypeEnumContract | null;
                prepaymentType?: Types.PrepaymentType | null;
                hide?: boolean | null;
                prepaid?: boolean | null;
                grossPrice?: {
                  __typename?: "GrossPrice";
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  currency?: string | null;
                } | null;
              }> | null;
            }> | null;
          }> | null;
        } | null;
      }>;
    }>;
  };
};

export const StreamBookingOverviewDocument = `
    subscription StreamBookingOverview($magicId: String!, $magicToken: String!) {
  StreamBookingOverview(magicId: $magicId, magicToken: $magicToken) {
    ...BookingOverviewFragment
  }
}
    ${BookingOverviewFragmentFragmentDoc}
${TravelBuddyFragmentFragmentDoc}
${TravelBuddyPersonFragmentFragmentDoc}
${ReservationEntityFragmentDoc}
${PersonFragmentFragmentDoc}
${FolioFragmentFragmentDoc}
${PriceFragmentFragmentDoc}
${GroupedRateBreakdownFragmentFragmentDoc}`;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({})
});

export { injectedRtkApi as api };
export const {} = injectedRtkApi;
