import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function KeyRoomIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>{`.a{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}`}</style>
      </defs>
      <title>locker-room-key</title>
      <rect className="a" x="0.75" y="3.75" width="22.5" height="16.5" rx="1.5" ry="1.5" />
      <line className="a" x1="0.75" y1="8.25" x2="23.25" y2="8.25" />
      <circle className="a" cx="8.25" cy="14.25" r="3" />
      <polyline className="a" points="11.25 14.25 18.75 14.25 18.75 16.5" />
      <line className="a" x1="15.75" y1="14.25" x2="15.75" y2="15.75" />
    </SvgIcon>
  );
}
