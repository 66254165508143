import React, { FC, useCallback, useEffect } from "react";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { SelfPouringStationShopResultPage } from "./self-pouring-station-shop-result.page";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useAppDispatch } from "../../../state/store";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  pourSelfPouringStation,
  selectIsSelfPouringStationPourError,
  selectIsSelfPouringStationPourLoading
} from "../self-pouring-station-shop.slice";
import { unwrapResult } from "@reduxjs/toolkit";

export const SelfPouringStationShopLastStepPage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const dispatch = useAppDispatch();
  const { reservation } = useReservationContext();
  const { deviceId } = useParams<{
    deviceId: string;
  }>();

  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const isSelfPouringStationPourError = useSelector(selectIsSelfPouringStationPourError);
  const isSelfPouringStationPourLoading = useSelector(selectIsSelfPouringStationPourLoading);

  const pour = useCallback(async () => {
    await dispatch(
      pourSelfPouringStation({
        magicId: reservation.magicId,
        magicToken: reservation.magicToken,
        drinkId: deviceId ?? ""
      })
    ).then(unwrapResult);
  }, [reservation.magicId, reservation.magicToken, deviceId, dispatch]);

  useEffect(() => {
    if (reservation.magicId && reservation.magicToken && deviceId) {
      pour();
    }
  }, [pour, reservation.magicId, reservation.magicToken, deviceId]);

  if (isSelfPouringStationPourLoading) {
    return null;
  }

  if (isSelfPouringStationPourError) {
    return (
      <SelfPouringStationShopResultPage
        title={t("labels__self_pouring_station_unlocking_failed")}
        subtitle={t("labels__self_pouring_station_please_try_again_later")}
        iconUrl={cmsData?.data?.icon__self_pouring_station_failed?.url}
        secondaryButtonOnClick={pour}
      />
    );
  }

  return (
    <SelfPouringStationShopResultPage
      title={t("labels__please_open_the_handle_of_drink_tap")}
      iconUrl={cmsData?.data?.icon__pouring_station?.url}
      imageUrl={cmsData?.data?.image__pouring_station?.url}
    />
  );
};
