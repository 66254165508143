import { useCmsPropertySpecificData } from "../cms/use-cms-per-property-data";
import { useMemo } from "react";

export interface PrismicImage {
  url: string;
  alt: string;
  dimensions: { width: number; height: number };
}

export const useUnitGroupCmsData = (propertyId?: string, unitGroupId?: string) => {
  const cmsData = useCmsPropertySpecificData(propertyId);

  const unitGroupImages: Array<PrismicImage> = useMemo(
    () =>
      cmsData?.data?.unit_groups__images
        ?.filter((item: { image: PrismicImage; id: string }) => item.id === unitGroupId)
        .map((item: { image: PrismicImage }) => item.image) || [],
    [cmsData?.data?.unit_groups__images, unitGroupId]
  );

  const unitGroupFeatures: Array<{
    label: string;
    iconUrl: string;
    dialogTitle: string;
    dialogText: string;
  }> = useMemo(
    () =>
      cmsData?.data?.unit_groups__features
        ?.filter((item: { image: PrismicImage; id: string }) => item.id === unitGroupId)
        .map((item: any) => ({
          label: item.title,
          iconUrl: item.icon.url,
          dialogTitle: item.title,
          dialogText: item.description
        })) || [],
    [cmsData?.data?.unit_groups__features, unitGroupId]
  );
  const unitGroupFallbackImage = cmsData?.data?.["unit_groups__fallback_image"];

  const firstImageUlr: PrismicImage | undefined = useMemo(
    () => (unitGroupImages && unitGroupImages.length ? unitGroupImages[0] : unitGroupFallbackImage),
    [unitGroupImages, unitGroupFallbackImage]
  );

  const unitGroupLabel = useMemo(
    () =>
      cmsData?.data?.unit_groups__labels?.find(
        ({ id }: { id: string; text: string }) => id === unitGroupId
      )?.text || unitGroupId,
    [cmsData?.data?.unit_groups__labels, unitGroupId]
  );

  return {
    cmsData,
    unitGroupImages,
    unitGroupFallbackImage,
    unitGroupFeatures,
    firstStudioImage: firstImageUlr,
    unitGroupLabel
  };
};
