import { FlowStateEnum } from "../../domain-common/flow-state";
import { PortalStateEnum } from "../../domain-common/portal-state";

export const magicObjectStateToPathMapper: Record<FlowStateEnum | PortalStateEnum, string> = {
  [FlowStateEnum.BOOKING_INIT]: "/booker",
  [FlowStateEnum.RESERVATION_INIT]: "/booking/guest",
  [PortalStateEnum.PROFILE]: "/profile",
  [PortalStateEnum.MY_STAY]: "/my-stay",
  [PortalStateEnum.BOOKINGS]: "/bookings"
};
