import { useSelector } from "react-redux";
import {
  closeFindReservationModal,
  selectFindReservationModalStatus,
  selectFindReservationsList,
  selectSelectedProperty
} from "../find-reservation.slice";
import { useAppDispatch } from "../../../state/store";
import React, { useCallback, useMemo } from "react";
import { FindReservationModalItem } from "./find-reservation-modal-item";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { makeStyles } from "tss-react/mui";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { CloseIcon } from "../../../components/icons";
import { FindReservationResponseDTO } from "../find-reservation.dto";

const useStyles = makeStyles()((theme) => ({
  container: {
    "& > div:first-of-type": {
      borderTop: "none"
    },
    "& > div": {
      borderTop: `${theme.spacing(0.1)} ${theme.palette.grey[500]} solid`
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  title: {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1)
  },
  modal: {
    borderRadius: theme.shape.borderRadius * 3
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500]
  }
}));

const EMPTY_STRING = "";

const sortReservations = (
  reservations: Array<FindReservationResponseDTO>
): Array<FindReservationResponseDTO> => {
  const sortedWithStudioNumber = [...reservations]
    .filter((res) => res.studioNumber !== EMPTY_STRING)
    .sort((first, second) => first.studioNumber.localeCompare(second.studioNumber));
  const sortedWithoutStudioNumber = [...reservations]
    .filter((res) => res.studioNumber === EMPTY_STRING)
    .sort((first, second) => first.unitGroup.localeCompare(second.unitGroup));
  return [...sortedWithStudioNumber, ...sortedWithoutStudioNumber];
};

export const FindReservationModal = () => {
  const isOpened = useSelector(selectFindReservationModalStatus);
  const reservations = useSelector(selectFindReservationsList);
  const selectedPropertyId = useSelector(selectSelectedProperty);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const closeDialog = useCallback(() => dispatch(closeFindReservationModal()), [dispatch]);
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const labels = useMemo(
    () => ({
      singleAdult: t("labels__single_adult"),
      pluralAdult: t("labels__multiple_adult"),
      singleChild: t("labels__single_child"),
      pluralChildren: t("labels__multiple_child"),
      modalTitle: t("modals__found_more_reservations")
    }),
    [t]
  );

  const dialogContent = useMemo(
    () =>
      sortReservations(reservations).map((reservation) => (
        <FindReservationModalItem
          key={reservation.magicId}
          reservation={reservation}
          propertyId={selectedPropertyId}
          labels={labels}
        />
      )),
    [reservations, labels, selectedPropertyId]
  );

  return (
    <Dialog
      PaperProps={{ classes: { root: `${classes.modal}` } }}
      fullWidth={true}
      open={isOpened}
      onClose={closeDialog}
      maxWidth="xs"
    >
      <DialogTitle className={classes.title}>
        <Heading4 gutterBottom>{labels.modalTitle}</Heading4>
        <IconButton className={classes.closeButton} onClick={closeDialog} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.container}>{dialogContent}</DialogContent>
    </Dialog>
  );
};
