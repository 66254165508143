import { useAppDispatch } from "../../../state/store";
import { useEffect, useMemo } from "react";
import {
  fetchBookerOverview,
  selectBookingOverview,
  selectOverviewBookingStatus
} from "../state/booking-overview.slice";
import { useSelector } from "react-redux";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { transformApiV2ToCommonBookingOverview } from "../../../graphql/transform/transform-booking-overview";
import { useGetMagicBookingOverviewsQuery } from "../../../graphql/queries/GetMagicBookingOverviews.generated";
import { mapQueryStatusToEntityStateStatus } from "../../../state/EntityStateStatus";

export const useFetchActiveBookerOverview = ({ magicId }: { magicId: string }) => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();

  const { data, status } = useGetMagicBookingOverviewsQuery(
    { magicId: magicId },
    {
      skip: isRESTVersion || !magicId
    }
  );

  useEffect(() => {
    if (isRESTVersion && magicId) {
      dispatch(fetchBookerOverview({ magicId }));
    }
  }, [dispatch, magicId, isRESTVersion]);

  const bookingOverviewActiveStatusV1 = useSelector(selectOverviewBookingStatus);

  const bookingOverviewActiveStatus = useMemo(
    () =>
      isRESTVersion ? bookingOverviewActiveStatusV1 : mapQueryStatusToEntityStateStatus(status),
    [isRESTVersion, bookingOverviewActiveStatusV1, status]
  );

  const bookerOverviewsActiveV1 = useSelector(selectBookingOverview);
  const bookerOverviewsActive = isRESTVersion
    ? bookerOverviewsActiveV1
    : data?.GetMagicBookingOverviews.map((item) => transformApiV2ToCommonBookingOverview(item)) ??
      [];
  return { bookerOverviewsActive, bookingOverviewActiveStatus };
};
