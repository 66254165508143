import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type CheckinMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
}>;

export type CheckinMutation = {
  __typename?: "Mutation";
  Checkin?: { __typename?: "Reservation"; pmsId: string } | null;
};

export const CheckinDocument = `
    mutation Checkin($magicId: String!, $magicToken: String!) {
  Checkin(magicId: $magicId, magicToken: $magicToken) {
    pmsId
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    Checkin: build.mutation<CheckinMutation, CheckinMutationVariables>({
      query: (variables) => ({ document: CheckinDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useCheckinMutation } = injectedRtkApi;
