import { BaseApiClient, JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { BoxAvailability } from "../domain-v1/box-availability";
import { BoxRentedItem } from "../domain-v1/box-rented-item";

const BASE_URL = "/api/guest-journey-service/magic";
class BoxShopApiClient extends BaseApiClient {
  async reserveBox(
    { magicId, boxId, magicToken }: { magicId: string; boxId: string; magicToken: string },
    init: RequestInit
  ) {
    const response = await this.fetchApi(BASE_URL.concat(`/${magicId}/box/${boxId}/reserve`), {
      ...init,
      method: "POST",
      headers: {
        "sk-magic-token": magicToken
      }
    });
    return new VoidApiResponse(response).value();
  }

  async fetchBoxAvailability(
    { magicId, magicToken }: { magicId: string; magicToken: string },
    init: RequestInit
  ) {
    const url = BASE_URL + `/${magicId}/box-availability`;
    const response = await this.fetchApi(url, {
      ...init,
      headers: {
        "sk-magic-token": magicToken
      }
    });
    return new JSONApiResponse<Array<BoxAvailability>>(response).value();
  }
  async fetchBoxDetails(
    { magicId, magicToken, boxId }: { magicId: string; magicToken: string; boxId: string },
    init: RequestInit
  ) {
    const url = BASE_URL + `/${magicId}/box/${boxId}`;
    const response = await this.fetchApi(url, {
      ...init,
      headers: {
        "sk-magic-token": magicToken
      }
    });
    return new JSONApiResponse<BoxAvailability>(response).value();
  }

  async fetchRentedItems(
    { magicId, magicToken }: { magicId: string; magicToken: string },
    init: RequestInit
  ) {
    const url = BASE_URL + `/${magicId}/rented-boxes`;
    const response = await this.fetchApi(url, {
      ...init,
      headers: {
        ...init.headers,
        "sk-magic-token": magicToken
      }
    });
    return new JSONApiResponse<Array<BoxRentedItem>>(response).value();
  }
}

export const BoxShopApi = new BoxShopApiClient();
