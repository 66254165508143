import { useEffect } from "react";
import { CartButtonNavigation } from "../../../components/molecules/cart-button-navigation";
import { ContactButtonNavigation } from "../../../components/molecules/contact-button-navigation";
import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";

import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";

export const useSetupMagicPageTopNavButtons = ({
  magicId,
  propertyId
}: {
  magicId: string;
  propertyId?: string;
}) => {
  const { setLeftButtons, setRightButtons } = useNavbar();
  const { setDisplayBottomNavbar } = useBottomNavbar();
  const { shopEnabled } = useFeatureFlags();

  useEffect(() => {
    setLeftButtons([]);
    setDisplayBottomNavbar(true);
    const buttons = [<ContactButtonNavigation key="contact_button" propertyId={propertyId} />];
    if (shopEnabled) {
      buttons.unshift(<CartButtonNavigation key="cart_button" magicId={magicId} />);
    }
    setRightButtons(buttons);
  }, [setLeftButtons, setRightButtons, setDisplayBottomNavbar, magicId, shopEnabled, propertyId]);
};
