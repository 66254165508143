import { MagicObject } from "../../domain-common/magic-object";
import { isBooking, isReservation, isTravelBuddy } from "../flow";
import { defaultLanguageWithLocale } from "../lang-utils";

export const computeLanguage = (magicObject: MagicObject | null): string | undefined => {
  if (isReservation(magicObject)) {
    return magicObject?.primaryGuest?.preferredLocale?.toLowerCase() || defaultLanguageWithLocale;
  } else if (isTravelBuddy(magicObject)) {
    return magicObject?.travelBuddy?.preferredLocale?.toLowerCase() || defaultLanguageWithLocale;
  } else if (isBooking(magicObject)) {
    return magicObject?.booker?.preferredLocale?.toLowerCase() || defaultLanguageWithLocale;
  }
  return undefined;
};
