import {
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import {
  AdditionalServicesAvailabilityResponse,
  Visibility
} from "../../api/dto/additional-services-availability-response";
import { MagicApi } from "../../api/magic.api";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { handleSliceError } from "../../util/error-handling";
import { getTenantHeaders } from "../../api/custom-headers";

const additionalServicesAdapter = createEntityAdapter<AdditionalServicesAvailabilityResponse>({
  selectId: (model) => model.serviceId
});

const initialState = additionalServicesAdapter.getInitialState<{
  availabilityStatus: EntityStateStatus;
  availability?: AdditionalServicesAvailabilityResponse[];
}>({
  availabilityStatus: EntityStateStatus.IDLE
});

export const checkAdditionalServicesAvailability = createAsyncThunk<
  AdditionalServicesAvailabilityResponse[],
  { magicId: string },
  { state: RootState; extra: ThunkExtraArguments }
>("services/additionalServicesAvailability", async (arg, thunkApi) => {
  try {
    return await MagicApi.additionalServicesAvailability(arg, {
      signal: thunkApi.signal,
      ...(await getTenantHeaders(thunkApi.extra))
    });
  } catch (e) {
    return handleSliceError(e, thunkApi.rejectWithValue);
  }
});

export const additionalServicesSlice = createSlice({
  name: "additionalServices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkAdditionalServicesAvailability.pending, (state) => {
        state.availabilityStatus = EntityStateStatus.LOADING;
      })
      .addCase(checkAdditionalServicesAvailability.rejected, (state) => {
        state.availabilityStatus = EntityStateStatus.FAILED;
      })
      .addCase(checkAdditionalServicesAvailability.fulfilled, (state, action) => {
        state.availabilityStatus = EntityStateStatus.SUCCEEDED;

        state.availability = action.payload;
      });
  }
});

const selectSelf = (state: RootState) => state[additionalServicesSlice.name];

export const selectIsCheckingAdditionalServicesAvailability = createSelector(
  selectSelf,
  (s) => s.availabilityStatus === EntityStateStatus.LOADING
);

export const selectSelectableAdditionalServices = createDraftSafeSelector(
  selectSelf,
  (_: any, visibility: Visibility) => visibility,
  (state, visibility) =>
    (state.availability || [])
      .map((availability) => {
        if (availability.visibilities && !availability.visibilities.includes(visibility)) {
          return { ...availability, available: false };
        }
        return availability;
      })
      .filter((availability) => availability.available)
);
