import { BaseApiClient, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { Reservation } from "../domain-common/reservation";
import { MagicFileType } from "../domain-common/magic-file-type";
import { convertBlobToBase64 } from "./files.api";
import { MagicFile } from "../domain-common/magic-file";

export interface UploadFileToReservationOpts {
  reservation: Reservation;
  file: File;
  fileType: MagicFileType;
}

const BASE_URL = "/api/guest-service";

class GuestServiceApiClient extends BaseApiClient {
  async getMagicFile(file: MagicFile, apiKey?: string, authToken?: string): Promise<Blob> {
    const encodedFileName = encodeURIComponent(file.fileName);
    const response: Response = await this.fetchApi(
      `${BASE_URL}/files/${file.metaData.ownerId}/${encodedFileName}`,
      {
        headers: {
          accept: "*/*",
          "sk-magic-api-key": apiKey ?? "",
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    return await response.blob();
  }

  async getMagicFileBase64(file: MagicFile, apiKey?: string, authToken?: string): Promise<string> {
    const blob = await this.getMagicFile(file, apiKey, authToken);
    return convertBlobToBase64(blob);
  }

  async uploadFileToReservation(arg: UploadFileToReservationOpts, init?: RequestInit) {
    const { reservation, file, fileType } = arg;
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.fetch(BASE_URL + `/files/${reservation.magicId}/${fileType}`, {
      ...init,
      method: "POST",
      headers: {
        "sk-magic-token": reservation.magicToken
      },
      body: formData
    });
    return new VoidApiResponse(response).value();
  }
}

export const GuestServiceApi = new GuestServiceApiClient();
