import { MagicFileType } from "./magic-file-type";

export interface MagicFile {
  id: string;
  fileName: string;
  contentType: string;
  metaData: {
    ownerId: string;
    folioId?: string;
    magicFileType: MagicFileType;
  };
}

export function MagicFileFromJSON(json: any): MagicFile {
  if (!json) {
    return json;
  }
  return {
    id: json["id"],
    fileName: json["fileName"],
    contentType: json["contentType"],
    metaData: {
      ownerId: json["metaData"]["ownerId"],
      folioId: json["metaData"]["folioId"],
      magicFileType: json["metaData"]["magicFileType"]
    }
  };
}
