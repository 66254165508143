import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UpdateEstimatedDepartureTimeMutationVariables = Types.Exact<{
  estimatedDepartureTime: Types.Scalars["Instant"];
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
  context?: Types.InputMaybe<Types.Scalars["Json"]>;
}>;

export type UpdateEstimatedDepartureTimeMutation = {
  __typename?: "Mutation";
  UpdateEstimatedDepartureTime?: boolean | null;
  UpdateFlowState: { __typename: "ReservationFlowState" };
};

export const UpdateEstimatedDepartureTimeDocument = `
    mutation UpdateEstimatedDepartureTime($estimatedDepartureTime: Instant!, $magicId: String!, $magicToken: String!, $context: Json) {
  UpdateEstimatedDepartureTime(
    estimatedDepartureTime: $estimatedDepartureTime
    magicId: $magicId
    magicToken: $magicToken
  )
  UpdateFlowState(
    magicId: $magicId
    magicToken: $magicToken
    flowState: {context: $context}
  ) {
    __typename
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UpdateEstimatedDepartureTime: build.mutation<
      UpdateEstimatedDepartureTimeMutation,
      UpdateEstimatedDepartureTimeMutationVariables
    >({
      query: (variables) => ({ document: UpdateEstimatedDepartureTimeDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdateEstimatedDepartureTimeMutation } = injectedRtkApi;
