import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetShopAvailabilityQueryVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  shopTypesIncludeFilter?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.ShopType>> | Types.InputMaybe<Types.ShopType>
  >;
}>;

export type GetShopAvailabilityQuery = {
  __typename?: "Query";
  GetAdditionalServicesAvailability?: Array<{
    __typename?: "AdditionalServiceAvailabilityDTO";
    pmsServiceId: string;
    available: boolean;
    bookedDates?: Array<any | null> | null;
    bookableDates?: Array<any | null> | null;
    granularity: Types.Granularity;
    payAtCheckout?: boolean | null;
    basePrice: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
      taxes?: Array<{
        __typename?: "Tax";
        amount?: any | null;
        amountInCents?: number | null;
        code?: string | null;
      } | null> | null;
    };
  } | null> | null;
};

export const GetShopAvailabilityDocument = `
    query GetShopAvailability($magicId: String!, $shopTypesIncludeFilter: [ShopType]) {
  GetAdditionalServicesAvailability(
    magicId: $magicId
    shopTypesIncludeFilter: $shopTypesIncludeFilter
  ) {
    pmsServiceId
    available
    bookedDates
    bookableDates
    basePrice {
      currency
      grossPrice
      grossPriceInCents
      netPrice
      netPriceInCents
      taxes {
        amount
        amountInCents
        code
      }
    }
    granularity
    payAtCheckout
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetShopAvailability: build.query<GetShopAvailabilityQuery, GetShopAvailabilityQueryVariables>({
      query: (variables) => ({ document: GetShopAvailabilityDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetShopAvailabilityQuery, useLazyGetShopAvailabilityQuery } = injectedRtkApi;
