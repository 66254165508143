import { filter } from "@prismicio/client";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState
} from "@reduxjs/toolkit";
import { PrismicData } from "../../api/cms-client/use-cms-client";
import { PrismicDocument } from "../../domain-common/prismic-document";
import { PrismicDocumentToMyStayEventCardMapper } from "../../mapper/prismic-document-to-my-stay-event-card-mapper";
import { PrismicDocumentToMyStayEventDetailsMapper } from "../../mapper/prismic-document-to-my-stay-event-details-mapper";
import { RootState } from "../../state/store";
import { getI18nSelectedLanguage } from "../../util/lang-utils";
import { Query } from "@prismicio/client/dist/types/api/query";

export type MyStayEventsState = EntityState<PrismicDocument> & {
  pending: boolean;
  page: number;
  totalPages: number;
};

const myStayEventsAdapter = createEntityAdapter<PrismicDocument>({
  selectId: (model) => model.id
});

const initialState = myStayEventsAdapter.getInitialState<{
  pending: boolean;
  page: number;
  totalPages: number;
}>({ pending: false, totalPages: 0, page: 0 });

export const fetchMyStayEvents = createAsyncThunk<
  Query<PrismicDocument>,
  { page?: number; prismic: PrismicData; lang: string; propertyId: string }
>("myStayEvents/fetchMyStayEvents", async (arg) => {
  const options = {
    lang: arg.lang,
    orderings: { field: "my.event.main__from" },
    pageSize: 20
  };
  if (arg?.page) {
    // @ts-ignore
    options.page = arg.page;
  }
  return await arg.prismic.prismicApiClient.get({
    filters: [
      filter.at("document.type", "event"),
      filter.dateAfter("my.event.main__to", new Date()),
      filter.at(`my.event.main__property_id`, arg.propertyId)
    ],
    ...options,
    ...arg.prismic.refOptions
  });
});

export const fetchMyStayEventById = createAsyncThunk<
  PrismicDocument,
  { eventId?: string; prismic: PrismicData }
>("myStayEvents/fetchMyStayEventById", async (arg) => {
  if (!arg.eventId) {
    throw new Error("`eventId` should be defined");
  }
  const response = await arg.prismic.prismicApiClient.get({
    filters: [filter.at("document.id", arg.eventId)],
    lang: getI18nSelectedLanguage(),
    ...arg.prismic.refOptions
  });
  return response.results[0];
});

export const myStayEventsSlice = createSlice({
  name: "myStayEvents",
  initialState,
  reducers: {
    initDisplayList: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyStayEvents.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchMyStayEvents.fulfilled, (state, action) => {
        myStayEventsAdapter.addMany(state, action.payload.results);
        state.totalPages = action.payload.total_pages;
        state.page = action.payload.page;
        state.pending = false;
      })
      .addCase(fetchMyStayEvents.rejected, (state) => {
        state.pending = false;
      })
      .addCase(fetchMyStayEventById.fulfilled, (state, action) => {
        myStayEventsAdapter.addOne(state, action.payload);
      });
  }
});

export const { initDisplayList } = myStayEventsSlice.actions;

export const selectSelf = (state: RootState): MyStayEventsState => state[myStayEventsSlice.name];

export const selectPending = createSelector(selectSelf, (res) => res.pending);

export const { selectAll: selectAllMyStayEvents, selectById: selectMyStayEventById } =
  myStayEventsAdapter.getSelectors(selectSelf);

export const selectAllMyStayEventCards = createSelector(selectAllMyStayEvents, (res) =>
  res.map(PrismicDocumentToMyStayEventCardMapper)
);

export const selectMyStayEventDetailsById = createSelector(selectMyStayEventById, (res) =>
  res ? PrismicDocumentToMyStayEventDetailsMapper(res) : null
);
