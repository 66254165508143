import { useSelector } from "react-redux";
import { useServicesCmsData } from "../../services/use-services-cms-data";
import { selectCartItems } from "../store/cart.slice";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useCallback, useMemo } from "react";
import { GroupNames, useFetchShopProducts } from "./use-fetch-shop-products";
import { toGross } from "../../../domain-common/full-price";
import { emptyPrice } from "../../../domain-common/price";
import { CartLineItem } from "../../../domain-common/cart-line-item";

export const useCartPage = () => {
  const { reservation } = useReservationContext();
  const { products } = useFetchShopProducts(reservation, [GroupNames.Beverage, GroupNames.Food]);
  const cartItems = useSelector(selectCartItems);

  const cartLineItems: Array<CartLineItem> = useMemo(
    () =>
      cartItems.reduce((acc: Array<CartLineItem>, ci) => {
        const product = products.find((p) => p.serviceId === ci.serviceId);
        if (product) {
          const price = product ? toGross(product.fullPrice) : emptyPrice();
          acc.push({
            price,
            quantity: ci.quantity,
            product
          });
        }

        return acc;
      }, []),
    [cartItems, products]
  );

  // fetch services and their cms data
  useServicesCmsData(reservation.propertyId);

  const findCartLineItem = useCallback(
    (serviceId?: string) => cartLineItems.find((item) => item.product.serviceId === serviceId),
    [cartLineItems]
  );

  return {
    cartLineItems,
    cartItems,
    findCartLineItem
  };
};
