import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type CreatePaymentRequestMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
  paymentRequest: Types.PaymentRequestInput;
}>;

export type CreatePaymentRequestMutation = {
  __typename?: "Mutation";
  CreatePaymentRequest: {
    __typename?: "PaymentRequestResponse";
    paymentLink: string;
    pmsId: string;
    state: string;
  };
};

export const CreatePaymentRequestDocument = `
    mutation CreatePaymentRequest($magicId: String!, $magicToken: String!, $paymentRequest: PaymentRequestInput!) {
  CreatePaymentRequest(
    magicId: $magicId
    magicToken: $magicToken
    paymentRequest: $paymentRequest
  ) {
    paymentLink
    pmsId
    state
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    CreatePaymentRequest: build.mutation<
      CreatePaymentRequestMutation,
      CreatePaymentRequestMutationVariables
    >({
      query: (variables) => ({ document: CreatePaymentRequestDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useCreatePaymentRequestMutation } = injectedRtkApi;
