export interface Address {
  addressLine1: string;
  addressLine2: string | null;
  postalCode: string;
  city: string;
  countryCode: string;
  region: string;
}

export const AddressFromJSON = (json: any): Address => {
  if (!json) {
    return json;
  }

  return {
    addressLine1: json["addressLine1"],
    addressLine2: json["addressLine2"],
    postalCode: json["postalCode"] || null,
    city: json["city"],
    countryCode: json["countryCode"],
    region: json["region"]
  };
};
