import { FC, PropsWithChildren } from "react";
import { NotFoundPage } from "../components/organism/not-found.page";

export const ProtectedRoute: FC<PropsWithChildren<{ isAllowed: boolean }>> = ({
  isAllowed,
  children
}) => {
  if (!isAllowed) {
    return <NotFoundPage />;
  }

  return <>{children}</>;
};
