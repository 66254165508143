import { useEffect, useMemo } from "react";
import { RootState, useAppDispatch } from "../../state/store";
import {
  checkAdditionalServicesAvailability,
  selectSelectableAdditionalServices
} from "./additional-services.slice";
import { useSelector } from "react-redux";
import {
  AdditionalServicesAvailabilityResponse,
  Visibility
} from "../../api/dto/additional-services-availability-response";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useGetAdditionalServicesAvailabilityQuery } from "../../graphql/queries/GetAdditionalServicesAvailability.generated";
import { transformAdditionalServicesAvailability } from "../../graphql/transform/transform-additional-services-availability";

export const useFetchAdditionalServices = (
  magicId: string,
  visibility: Visibility,
  isTravelBuddy?: boolean
) => {
  const dispatch = useAppDispatch();

  const { isRESTVersion } = useApiVersion();

  const { data } = useGetAdditionalServicesAvailabilityQuery(
    { magicId: magicId },
    { skip: isRESTVersion || isTravelBuddy, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isRESTVersion && !isTravelBuddy) {
      const promise = dispatch(checkAdditionalServicesAvailability({ magicId }));
      return () => promise.abort();
    }
  }, [dispatch, magicId, isRESTVersion, isTravelBuddy]);

  const selectableAdditionalServicesV1 = useSelector<
    RootState,
    AdditionalServicesAvailabilityResponse[]
  >((state) => selectSelectableAdditionalServices(state, visibility));

  const selectableAdditionalServicesV2 = useMemo(() => {
    return data?.GetAdditionalServicesAvailability?.map(transformAdditionalServicesAvailability)
      .map((availability) => {
        if (
          availability.visibilities &&
          availability.visibilities.length > 0 &&
          !availability.visibilities.includes(visibility)
        ) {
          return { ...availability, available: false };
        }
        return availability;
      })
      .filter((availability) => availability.available);
  }, [data?.GetAdditionalServicesAvailability, visibility]);

  const selectableAdditionalServices = (
    isRESTVersion ? selectableAdditionalServicesV1 : selectableAdditionalServicesV2 ?? []
  )?.filter((item) => item.available);

  return { selectableAdditionalServices };
};
