import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { FC, useCallback, useMemo, useState } from "react";
import { DisplayCmsSvg, Notification, SubmitButton } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { GuestForm } from "../../../components/molecules/guest-form";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { generatePortalMyStayBuddiesUrl } from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { useSetupSubpageNavigation } from "../hooks/use-setup-subpage-navigation";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useNavigate } from "react-router-dom";
import { openBanner } from "../../banner/banner.slice";
import { ProfileTemplate } from "../components/profile-template";
import { useAppDispatch } from "../../../state/store";
import {
  useAdditionalTravelBuddyFieldsConfig,
  useFeatureFlags
} from "../../../util/hooks/use-configuration";
import { useGuestFlow } from "../../guest-flow/use-guest-flow";
import { emptyGuest, Guest, GuestFormDataType } from "../../../domain-common/guest";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { additionalTravelBuddyFieldValidation } from "../../../util/additional-travel-buddy-fields-validation";
import { useCommonValidationCmsData } from "../../../state/common-cms/use-common-cms-data";
import {
  emptyReservationExtras,
  ReservationExtras
} from "../../../domain-common/reservation-extras";
import { TravelBuddyTermsAndConditions } from "../../../components/molecules/personal-form/travel-buddy-terms-and-conditions";
import { useIsMinor } from "../../../util/hooks/use-is-minor";
import { useTheme } from "@mui/styles";

interface MyStayBuddyAddPageProps {}

export const MyStayBuddyAddPage: FC<MyStayBuddyAddPageProps> = () => {
  const { reservation } = useReservationContext();
  const navigate = useNavigate();
  const { travelBuddyValidation, tcMinorsValidation } = useFormValidations();
  const { diverseGenderEnabled, travelBuddyIsMinorValidationEnabled, genderDisplayDisabled } =
    useFeatureFlags();
  const validationCmsData = useCommonValidationCmsData();
  const { spacing } = useTheme();
  const { isMinor } = useIsMinor();

  const additionalTravelBuddyFields = useAdditionalTravelBuddyFieldsConfig();

  const dynamicSchema = additionalTravelBuddyFieldValidation(
    additionalTravelBuddyFields,
    validationCmsData,
    true
  );

  const newSchema = travelBuddyIsMinorValidationEnabled
    ? travelBuddyValidation.concat(tcMinorsValidation).concat(dynamicSchema)
    : travelBuddyValidation.concat(dynamicSchema);

  useSetupSubpageNavigation(generatePortalMyStayBuddiesUrl);
  const dispatch = useAppDispatch();
  const { patchGuestFlowStep } = useGuestFlow();

  const [travelBuddy] = useState<GuestFormDataType>(emptyGuest());
  const [extras] = useState<ReservationExtras>(emptyReservationExtras());

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const hasSameEmailOrPhone = (travelBuddy: Guest, other: Guest | GuestFormDataType) => {
    return (
      (travelBuddy.email && travelBuddy.email === other.email) ||
      (travelBuddy.phone && travelBuddy.phone === other.phone)
    );
  };

  const handleSubmit = useCallback(
    async (values: { travelBuddy: GuestFormDataType; extras: ReservationExtras }) => {
      if (reservation.additionalGuests.length >= reservation.maxCompanions) {
        return dispatch(
          openBanner({
            errorId: "errors__travel_buddy_max_companion_exceeded",
            type: "error",
            title: ""
          })
        );
      }
      const sameData = hasSameEmailOrPhone(values.travelBuddy, reservation.primaryGuest);
      const sameAsOtherBuddy = reservation.additionalGuests.some((additionalGuest: Guest) =>
        hasSameEmailOrPhone(values.travelBuddy, additionalGuest)
      );
      if (sameData) {
        return dispatch(
          openBanner({
            errorId: "errors__travel_buddy_same_contact_data",
            type: "error",
            title: ""
          })
        );
      } else if (sameAsOtherBuddy) {
        return dispatch(
          openBanner({
            errorId: "errors__travel_buddy_same_contact_data_other_buddy",
            type: "error",
            title: ""
          })
        );
      } else {
        try {
          await patchGuestFlowStep({
            reservationValues: {
              ...reservation,
              additionalGuests: [...reservation.additionalGuests, values.travelBuddy],
              extras: {
                ...values.extras,
                tcMinors: values.extras.tcMinors
              }
            },
            subUpdate: SubUpdate.PERSONAL_DATA_FOR_SECONDARY_GUESTS
          });
          navigate(generatePortalMyStayBuddiesUrl(reservation.magicId));
        } catch (e) {
          console.error("Update buddy data [portal] went wrong", e);
        }
      }
    },
    [reservation, patchGuestFlowStep, dispatch, navigate]
  );

  const labels = useMemo(
    () => ({
      firstName: tCommon("labels__first_name"),
      lastName: tCommon("labels__last_name"),
      genderFemale: tCommon("labels__gender_female"),
      genderMale: tCommon("labels__gender_male"),
      genderOther: tCommon("labels__gender_other"),
      phone: tCommon("labels__phone"),
      email: tCommon("labels__email"),
      birthdate: tCommon("labels__birthdate"),
      nationalityCountryCode: tCommon("labels__nationality"),
      postalCode: tCommon("labels__postal_code"),
      address: tCommon("labels__address"),
      countryCode: tCommon("labels__country"),
      city: tCommon("labels__city")
    }),
    [tCommon]
  );

  const initialValues = { ...travelBuddy };
  const extrasInitialValues = { ...extras };

  return (
    <>
      <HelmetTitle suffix="Add Travel Buddy" />
      <RoundedFullHeight>
        <ProfileTemplate>
          <Grid
            container
            px={2.5}
            py={2}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Grid item>
              <PageHeadingInfo
                title={tCommon("title__travel_buddy_page")}
                subtitle={tCommon("subtitle__travel_buddy_page")}
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
              />
            </Grid>
          </Grid>
          <Box pl={2.5} pr={2.5}>
            <Formik
              enableReinitialize
              initialValues={{ travelBuddy: initialValues, extras: extrasInitialValues }}
              onSubmit={handleSubmit}
              validationSchema={newSchema}
            >
              {(formik) => (
                <Form noValidate>
                  {isMinor(formik.values.travelBuddy.birthdate ?? "") &&
                    travelBuddyIsMinorValidationEnabled && (
                      <Box my={spacing(4)}>
                        <Notification
                          type="info"
                          title={tCommon("labels__additional_travel_buddy_under_age_info")}
                        />
                      </Box>
                    )}
                  <GuestForm
                    objectPathPrefix={`travelBuddy`}
                    values={formik.values.travelBuddy}
                    labels={labels}
                    disabled={formik.isSubmitting}
                    touched={formik.touched?.travelBuddy}
                    errors={formik.errors?.travelBuddy}
                    diverseGenderEnabled={diverseGenderEnabled}
                    genderDisplayDisabled={genderDisplayDisabled}
                    onChange={formik.handleChange}
                    additionalFields={additionalTravelBuddyFields}
                  />
                  {travelBuddyIsMinorValidationEnabled && (
                    <TravelBuddyTermsAndConditions
                      value={formik.values.extras.tcMinors ?? false}
                      onChange={formik.handleChange}
                      touched={formik.touched?.extras?.tcMinors ?? false}
                      errors={formik.errors?.extras?.tcMinors ?? ""}
                    />
                  )}
                  {formik.dirty && (
                    <SubmitButton
                      label={tCommon("buttons__save")}
                      disabled={formik.isSubmitting}
                      hasBottomNavigation
                      hasWhiteBackground
                    />
                  )}
                </Form>
              )}
            </Formik>
          </Box>
        </ProfileTemplate>
      </RoundedFullHeight>
    </>
  );
};
