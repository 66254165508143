import { Box } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import { useSaveGuestFlowSessionCheckpoint } from "../checkpoint/use-save-guest-flow-session-checkpoint";
import { getNextFlowUrl, getPrevFlowUrl } from "../guest-flow-sequence";
import { selectIsReservationUpdating } from "../../reservation-provider/reservation.slice";
import { useGuestFlow } from "../use-guest-flow";
import { useSetupGuestFlowNavigation } from "../hooks/use-setup-guest-flow-navigation";
import { useBottomNavbar } from "../../../components/organism/bottom-navbar/bottom-navbar-context";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { isReservation, isTravelBuddy } from "../../../util/flow";
import { isKioskMode } from "../../../util/kiosk-mode";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { ServiceSelectionComponent } from "../../additional-services/service-selection/service-selection-component";
import { Visibility } from "../../../api/dto/additional-services-availability-response";
import { useFetchAdditionalServices } from "../../services/use-fetch-additional-services";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { SubUpdate } from "../../reservation-provider/sub-update";

interface ServicesPageProps {}

const CURRENT_PAGE = GuestFlowCheckpoint.SERVICES;

export const ServicesPage: React.FC<React.PropsWithChildren<ServicesPageProps>> = () => {
  const navigate = useNavigate();
  const { reservation, contactButton, skipButton, getProgress, patchGuestFlowStep } =
    useGuestFlow();
  const { isBottomNavbarActive } = useBottomNavbar();
  const { magicId } = useMagicIdParams();
  const { selectableAdditionalServices } = useFetchAdditionalServices(
    magicId,
    Visibility.GUEST_FLOW,
    isTravelBuddy(reservation)
  );

  const isReservationUpdating = useSelector(selectIsReservationUpdating);

  const onBack = useCallback(() => {
    navigate(getPrevFlowUrl(CURRENT_PAGE, reservation));
  }, [navigate, reservation]);

  const progress = useMemo(() => getProgress(CURRENT_PAGE), [getProgress]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  useSaveGuestFlowSessionCheckpoint(reservation!, CURRENT_PAGE);
  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });

  const handleSubmit = React.useCallback(async () => {
    try {
      await patchGuestFlowStep({
        reservationValues: reservation,
        checkpoint: CURRENT_PAGE,
        subUpdate: SubUpdate.SERVICES
      });

      navigate(getNextFlowUrl(CURRENT_PAGE, reservation));
    } catch (e) {
      console.error("Update services data went wrong", e);
    }
  }, [patchGuestFlowStep, navigate, reservation]);

  const submitButtonLabel = useMemo(
    () =>
      selectableAdditionalServices.length > 0 ? tCommon("buttons__next") : tCommon("buttons__skip"),
    [tCommon, selectableAdditionalServices]
  );

  const topBarIcons = useMemo(() => {
    if (isReservation(reservation)) {
      if (isKioskMode()) {
        return [skipButton];
      }
      return [contactButton];
    }
    return [];
  }, [reservation, contactButton, skipButton]);

  return (
    <>
      <HelmetTitle suffix="Guest flow services" />
      <FlowTemplate icons={topBarIcons} progress={progress} handleBack={onBack}>
        <GuestFlowBackgroundBox>
          <Box p={2} pt={0}>
            <DesktopCard>
              <PageHeadingInfo
                title={tCommon("title__services_page")}
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__services_icon?.url} />}
              />

              <ServiceSelectionComponent
                reservation={reservation}
                visibility={Visibility.GUEST_FLOW}
              />

              <SubmitButton
                label={submitButtonLabel}
                disabled={isReservationUpdating}
                hasBottomNavigation={isBottomNavbarActive}
                hasWhiteBackground
                onClick={handleSubmit}
              />
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
