import React, { useCallback } from "react";
import { Property } from "../../domain-common/properties-by-city";
import { dateOrNull, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useAppDispatch } from "../../state/store";
import { api as SearchReservationApi } from "../../graphql/queries/SearchReservations.generated";
import { transformSearchReservationData } from "../../graphql/transform/transform-search-reservation-data";
import { findReservationAction } from "./find-reservation.slice";
import { unwrapResult } from "@reduxjs/toolkit";

export interface UseFindReservationState {
  propertyId: string;
  firstName: string;
  lastName: string;
  arrival: string;
  departure: string;
}

let unresolvedInitialValues = {
  propertyId: "",
  firstName: "",
  lastName: "",
  departure: "",
  arrival: ""
};

export const useFindReservation = (properties: Property[]) => {
  const { isGraphQlVersion } = useApiVersion();

  const initialValues = React.useMemo(() => {
    return {
      ...unresolvedInitialValues,
      propertyId:
        properties.length > 0 ? properties[0].propertyId : unresolvedInitialValues.propertyId
    };
  }, [properties]);

  const dispatch = useAppDispatch();
  const findReservation = useCallback(
    ({ propertyId, firstName, lastName, arrival, departure }: UseFindReservationState) => {
      return !isGraphQlVersion
        ? dispatch(
            findReservationAction({
              propertyId,
              firstName,
              lastName,
              arrival: dateOrNull(arrival),
              departure: dateOrNull(departure)
            })
          ).then(unwrapResult)
        : dispatch(
            SearchReservationApi.endpoints.SearchReservations.initiate(
              {
                searchData: {
                  arrival,
                  departure,
                  primaryGuest: {
                    firstName,
                    lastName
                  },
                  pmsPropertyId: propertyId
                }
              },
              {
                forceRefetch: true
              }
            )
          ).then(({ data }) => transformSearchReservationData(data?.SearchReservations));
    },
    [dispatch, isGraphQlVersion]
  );

  return {
    initialValues,
    findReservation,
    properties
  };
};
