import { FC } from "react";
import { PageHeadingInfo } from "../../components/molecules/page-heading-info";
import { DesktopCard } from "../../components";
import { RoundedFullHeight } from "../../components/layouts/rounded-full-height";
import { Box, Grid, Slider } from "@mui/material";
import { useSetupSubpageNavigation } from "../portal/hooks/use-setup-subpage-navigation";
export const RoomControlPage: FC = () => {
  useSetupSubpageNavigation();

  const marks = [
    {
      value: 18,
      label: "18°C"
    },
    {
      value: 20,
      label: "20°C"
    },
    {
      value: 22,
      label: "22°C"
    },
    {
      value: 24,
      label: "24°C"
    },
    {
      value: 26,
      label: "26°C"
    }
  ];

  function valuetext(value: number) {
    return `${value}°C`;
  }
  return (
    <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
      <DesktopCard>
        <Box p={3}>
          <PageHeadingInfo title="Room control" />
          <Grid container direction="column" display="grid">
            <p>Adjust your room temperatur to your liking</p>
            <Box p={3}>
              <Slider
                aria-label="Always visible"
                min={18}
                max={26}
                defaultValue={21}
                getAriaValueText={valuetext}
                valueLabelFormat={valuetext}
                step={1}
                marks={marks}
                valueLabelDisplay="on"
              />
            </Box>
          </Grid>
        </Box>
      </DesktopCard>
    </RoundedFullHeight>
  );
};
