import { FC, PropsWithChildren } from "react";
import { Box, Grid, IconButton, Link } from "@mui/material";
import Facebook from "@mui/icons-material/Facebook";
import Twitter from "@mui/icons-material/Twitter";
import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import { makeStyles } from "tss-react/mui";
import { isKioskMode } from "../../../util/kiosk-mode";
import { useGetStaticPageData } from "../../../features/static-page/static-page";
import { useNavigate } from "react-router-dom";
import { Tiktok } from "../../icons/tiktok";
import { useNavbar } from "../../organism/top-navbar/navbar-context";

const socialMapper = {
  FACEBOOK: <Facebook />,
  INSTAGRAM: <Instagram />,
  TWITTER: <Twitter />,
  LINKEDIN: <LinkedIn />,
  TIKTOK: <Tiktok />
};

const useStyles = makeStyles()(({ spacing }) => ({
  wrapper: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    margin: `0 ${spacing(2)}`
  }
}));

export const SocialFooter: FC<PropsWithChildren<unknown>> = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const isMobile = useIsMobile();
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { classes } = useStyles();
  const staticPageData = useGetStaticPageData();
  const navigate = useNavigate();
  const { displaySocialFooter } = useNavbar();

  if (!displaySocialFooter) {
    return null;
  }

  return isMobile || isKioskMode() ? null : (
    <Box className={classes.wrapper}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {cmsData?.data?.main__social.map((item: { id: string; value: string }) => (
            <IconButton
              key={item.id}
              sx={{ color: "text.secondary" }}
              onClick={() => {
                window.open(item.value);
              }}
            >
              {Object.keys(socialMapper).find((i) => item.id === i)
                ? // @ts-ignore TS do not understand line above
                  socialMapper[item.id]
                : item.id}
            </IconButton>
          ))}
        </Grid>

        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" gap={0.5}>
            {Object.keys(staticPageData).length ? (
              <>
                {Object.keys(staticPageData).map((id) => (
                  <Link
                    onClick={() => navigate(`/static-page/${id}`)}
                    key={id}
                    component="button"
                    variant="body1"
                    color="text.secondary"
                  >
                    {staticPageData[id].title}
                  </Link>
                ))}
                <Paragraph color="text.secondary"> | </Paragraph>
              </>
            ) : null}
            <Paragraph textAlign="right" color="text.secondary">
              {t("main__copyrights")}
            </Paragraph>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
