import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UnlinkIdpMutationVariables = Types.Exact<{
  idp: Types.Scalars["String"];
  userAccountUuid?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type UnlinkIdpMutation = { __typename?: "Mutation"; UnlinkIdp: boolean };

export const UnlinkIdpDocument = `
    mutation UnlinkIdp($idp: String!, $userAccountUuid: String) {
  UnlinkIdp(idp: $idp, userAccountUuid: $userAccountUuid)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UnlinkIdp: build.mutation<UnlinkIdpMutation, UnlinkIdpMutationVariables>({
      query: (variables) => ({ document: UnlinkIdpDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUnlinkIdpMutation } = injectedRtkApi;
