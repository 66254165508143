import { Box, CardContent } from "@mui/material";
import React, { FC } from "react";
import { DisplayCmsSvg } from "../../../../components";
import { MyStayTipCard } from "../../../../domain-common/my-stay-tip-card";
import { Title } from "./title";
import { Subtitle } from "./subtitle";
import { CardWrapper } from "./card-wrapper";

interface TipIconCardProps {
  tip: MyStayTipCard;
}

export const TipIconCard: FC<TipIconCardProps> = ({ tip }) => {
  const showSubtitle = Boolean(tip.subtitle);

  return (
    <CardWrapper tip={tip}>
      <CardContent>
        {tip.coverImage && (
          <Box pb={1} textAlign="center">
            <DisplayCmsSvg
              url={tip.coverImage}
              size={{ width: 48, height: 48 }}
              color="secondary"
            />
          </Box>
        )}
        <Title title={tip.title} />
        {showSubtitle && <Subtitle subtitle={tip.subtitle} />}
      </CardContent>
    </CardWrapper>
  );
};
