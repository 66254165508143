import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
  Selector
} from "@reduxjs/toolkit";
import { MagicApi } from "../../../api/magic.api";
import { AdditionalServiceCartItem } from "../../../domain-common/additional-service-cart-item";
import { EntityStateStatus, isStatusLoading } from "../../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../../state/store";
import { handleSliceError } from "../../../util/error-handling";
import { isReservation, isTravelBuddy } from "../../../util/flow";
import { fetchMagicObject } from "../../magic/magic-object.slice";
import {
  additionalServiceCartItemsToAdditionalServicesPaymentDTO,
  invalidateCart
} from "./additional-services.util";
import { getLocalStorageItems } from "../guest-flow-store-local-storage";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { checkAdditionalServicesAvailability } from "../../services/additional-services.slice";
import { emptyFullPrice, FullPrice } from "../../../domain-common/full-price";
import isEqual from "lodash/isEqual";
import { getTenantHeaders } from "../../../api/custom-headers";

const additionalServicesCartAdapter = createEntityAdapter<AdditionalServiceCartItem>({
  selectId: (model) => model.serviceId
});

const initialState = additionalServicesCartAdapter.getInitialState<{
  totalPrice: FullPrice;
  totalPriceStatus: EntityStateStatus;
}>({
  totalPrice: emptyFullPrice(),
  totalPriceStatus: EntityStateStatus.IDLE
});

export type AdditionalServicesCartState = typeof initialState;

export const getTotalPrice = createAsyncThunk<
  FullPrice,
  { magicId: string; serviceCartItems: AdditionalServiceCartItem[] },
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("additionalServiceCart/getTotalPrice", async ({ magicId, serviceCartItems }, thunkAPI) => {
  try {
    if (!Object.keys(serviceCartItems).length) {
      return emptyFullPrice();
    }
    if (!magicId) {
      return emptyFullPrice();
    }

    return await MagicApi.getTotalPrice(
      {
        additionalServices:
          additionalServiceCartItemsToAdditionalServicesPaymentDTO(serviceCartItems),
        magicId
      },
      {
        signal: thunkAPI.signal,
        ...(await getTenantHeaders(thunkAPI.extra))
      }
    );
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const additionalServiceCartSlice = createSlice({
  name: "additionalServiceCart",
  initialState,
  reducers: {
    initAdditionalServicesCart: (state, action: PayloadAction<{ magicId: string }>) => {
      const fromStorage = getLocalStorageItems<EntityState<AdditionalServiceCartItem>>(
        action.payload.magicId
      );

      state.entities = fromStorage?.entities ?? {};
      state.ids = fromStorage?.ids ?? [];
    },
    addToCart: (state, action: PayloadAction<AdditionalServiceCartItem>) => {
      additionalServicesCartAdapter.upsertOne(state, action.payload);
    },
    removeFromCart: (state, action: PayloadAction<{ serviceId: string }>) => {
      additionalServicesCartAdapter.removeOne(state, action.payload.serviceId);
    },
    clearAdditionalServicesCart: (state) => {
      state.totalPrice = emptyFullPrice();
      additionalServicesCartAdapter.removeAll(state);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTotalPrice.fulfilled, (state, action) => {
        state.totalPriceStatus = EntityStateStatus.SUCCEEDED;
        state.totalPrice = action.payload;
      })
      .addCase(getTotalPrice.pending, (state) => {
        state.totalPriceStatus = EntityStateStatus.LOADING;
      })
      .addCase(getTotalPrice.rejected, (state) => {
        state.totalPriceStatus = EntityStateStatus.FAILED;
      })
      .addCase(checkAdditionalServicesAvailability.fulfilled, (state, action) => {
        const localStorage =
          getLocalStorageItems<EntityState<AdditionalServiceCartItem>>(action?.meta?.arg?.magicId)
            ?.entities || {};

        const result = invalidateCart(localStorage, action.payload);

        state.entities = result ? result : {};
        state.ids = Object.keys(result);
      })
      // invalidate cart based on magicObject
      .addMatcher(
        (action) => action.type === fetchMagicObject.fulfilled.type,
        (state, action) => {
          if (isReservation(action.payload) || isTravelBuddy(action.payload)) {
            const fromStorage = getLocalStorageItems<EntityState<AdditionalServiceCartItem>>(
              action.payload.magicId
            );

            if (!isEqual(state.entities, fromStorage?.entities)) {
              state.entities = fromStorage?.entities ?? state.entities;
              state.ids = fromStorage?.ids ?? state.ids;
            }
            // TODO fetch /additional-services-availability (if needed)
            // remove
            // * services with availability=false
            // * dates that are in the cart and that are not in config.bookableDates
            // * "somewhere": if requesting this from my-stay.services (Visibility.MY_STAY), also filter out services that are Visibility.GUEST_FLOW
          }
        }
      );
  }
});

export const {
  initAdditionalServicesCart,
  addToCart,
  removeFromCart,
  clearAdditionalServicesCart
} = additionalServiceCartSlice.actions;

export const selectAdditionalServicesCart: Selector<RootState, AdditionalServicesCartState> = (
  state: RootState
) => state[additionalServiceCartSlice.name];

export const {
  selectAll: selectAllAdditionalServicesCartItems,
  selectById: selectAdditionalServiceById
} = additionalServicesCartAdapter.getSelectors(selectAdditionalServicesCart);

export const selectIsTotalPriceStatusForAdditionalServicesLoading = createSelector(
  selectAdditionalServicesCart,
  (res) => isStatusLoading(res.totalPriceStatus)
);
