import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function RemoveItemIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 29 29">
      <circle cx="14.5" cy="14.5" r="14.5" fill="black" />
      <path
        d="M20.3837 8.6162C20.1493 8.38186 19.8314 8.25021 19.5 8.25021C19.1685 8.25021 18.8506 8.38186 18.6162 8.6162L14.5 12.7324L10.3837 8.6162C10.148 8.3885 9.83222 8.26251 9.50448 8.26535C9.17673 8.2682 8.86322 8.39966 8.63146 8.63142C8.3997 8.86318 8.26823 9.1767 8.26539 9.50445C8.26254 9.83219 8.38853 10.1479 8.61623 10.3837L12.7325 14.4999L8.61623 18.6162C8.49684 18.7315 8.40161 18.8694 8.3361 19.0219C8.27059 19.1744 8.23611 19.3385 8.23467 19.5044C8.23322 19.6704 8.26485 19.835 8.3277 19.9886C8.39055 20.1423 8.48337 20.2818 8.60074 20.3992C8.7181 20.5166 8.85767 20.6094 9.01129 20.6722C9.16491 20.7351 9.32951 20.7667 9.49548 20.7653C9.66146 20.7638 9.82548 20.7293 9.97799 20.6638C10.1305 20.5983 10.2684 20.5031 10.3837 20.3837L14.5 16.2674L18.6162 20.3837C18.852 20.6114 19.1677 20.7374 19.4955 20.7345C19.8232 20.7317 20.1367 20.6002 20.3685 20.3685C20.6003 20.1367 20.7317 19.8232 20.7346 19.4954C20.7374 19.1677 20.6114 18.8519 20.3837 18.6162L16.2675 14.4999L20.3837 10.3837C20.6181 10.1493 20.7497 9.8314 20.7497 9.49995C20.7497 9.16849 20.6181 8.85061 20.3837 8.6162Z"
        fill="white"
      />
    </SvgIcon>
  );
}
