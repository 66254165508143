import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function TimeClockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>{`.a{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}`}</style>
      </defs>
      <title>time-clock-circle-1</title>
      <circle className="a" cx="12" cy="12" r="10.5" />
      <circle className="a" cx="12" cy="12" r="1.5" />
      <line className="a" x1="12" y1="10.5" x2="12" y2="5.25" />
      <line className="a" x1="13.061" y1="13.061" x2="15.75" y2="15.75" />
    </SvgIcon>
  );
}
