import { useAppDispatch } from "../../../state/store";
import { selectPromoCodeType, validatePromoCode } from "../promocode.slice";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

export const useValidPromoCodeResponse = ({ promoCode }: { promoCode: string | undefined }) => {
  const dispatch = useAppDispatch();
  const { isRESTVersion } = useApiVersion();

  const promoCodeType = useSelector(selectPromoCodeType);

  useEffect(() => {
    if (isRESTVersion && !promoCodeType && promoCode) {
      dispatch(validatePromoCode({ promoCode }));
    }
  }, [dispatch, promoCodeType, promoCode, isRESTVersion]);

  return useMemo(
    () =>
      isRESTVersion
        ? { type: promoCodeType, promoCode: promoCode }
        : { type: undefined, promoCode: undefined },
    [isRESTVersion, promoCodeType, promoCode]
  );
};
