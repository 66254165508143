import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetUserProfileQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserProfileQuery = {
  __typename?: "Query";
  GetUserProfile?: {
    __typename?: "UserProfile";
    preferredLanguage?: string | null;
    userAccountUuid?: string | null;
    id: number;
  } | null;
};

export const GetUserProfileDocument = `
    query GetUserProfile {
  GetUserProfile {
    preferredLanguage
    userAccountUuid
    id
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetUserProfile: build.query<GetUserProfileQuery, GetUserProfileQueryVariables | void>({
      query: (variables) => ({ document: GetUserProfileDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetUserProfileQuery, useLazyGetUserProfileQuery } = injectedRtkApi;
