import React, { FC, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { isAndroid, isIOS } from "react-device-detect";
import { AppStoreIcon, PlayStoreIcon } from "../../components/icons";
import { makeStyles } from "tss-react/mui";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { QRCode } from "react-qrcode-logo";
import { StoreType } from "../portal/pages/public-transportation-ticket-article";
import { Button, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useIsMobile } from "../../components/layouts/hooks/use-is-mobile";

const useStyles = makeStyles()(() => ({
  storeButtons: {
    width: 175,
    height: 55,
    padding: 0
  }
}));

export const GetMobileApp: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const platforms = useMemo(() => {
    return [
      {
        id: StoreType.GOOGLE,
        url: t("main__play_store_url"),
        icon: <PlayStoreIcon />,
        isVisible: isAndroid || !isIOS
      },
      {
        id: StoreType.APPLE,
        url: t("main__app_store_url"),
        icon: <AppStoreIcon />,
        isVisible: isIOS || !isAndroid
      }
    ];
  }, [t]);
  const isMobile = useIsMobile();
  const goTo = (url: string) => () => {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.warn("This device is not supported");
    }
  };
  const { classes } = useStyles();
  return (
    <Box>
      <Paragraph py={2} textAlign="center">
        {t("labels__download_app_explanation")}
      </Paragraph>

      <Grid container justifyContent="center">
        {platforms.map((platform) =>
          platform.isVisible ? (
            <Box mx={1} key={platform.id}>
              <Grid container alignItems="center" direction="column">
                <Button
                  fullWidth
                  variant="ghost"
                  onClick={goTo(platform.url)}
                  className={classes.storeButtons}
                >
                  {platform.icon}
                </Button>
                {!isMobile && (
                  <>
                    <Box mt={1}>
                      <Paragraph color="text.primary" sx={{ textTransform: "uppercase" }}>
                        {t("labels__or")}
                      </Paragraph>
                    </Box>
                    <Box height={250}>
                      <QRCode value={t(platform.url)} size={220} />
                    </Box>
                  </>
                )}
              </Grid>
            </Box>
          ) : null
        )}
      </Grid>
    </Box>
  );
};
