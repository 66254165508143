import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type SyncPaymentRequestMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
}>;

export type SyncPaymentRequestMutation = {
  __typename?: "Mutation";
  SyncPaymentRequest: {
    __typename?: "PaymentRequestResponse";
    pmsId: string;
    state: string;
    paymentLink: string;
  };
};

export const SyncPaymentRequestDocument = `
    mutation SyncPaymentRequest($magicId: String!) {
  SyncPaymentRequest(magicId: $magicId) {
    pmsId
    state
    paymentLink
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SyncPaymentRequest: build.mutation<
      SyncPaymentRequestMutation,
      SyncPaymentRequestMutationVariables
    >({
      query: (variables) => ({ document: SyncPaymentRequestDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useSyncPaymentRequestMutation } = injectedRtkApi;
