import { Reservation } from "../../domain-common/reservation";
import { isReservation } from "../../util/flow";
import { ProfilePagesEnum } from "./profile-page-to-path";

export const travelBuddyProfilePages = [
  ProfilePagesEnum.PERSONAL,
  ProfilePagesEnum.COMMUNICATION,
  ProfilePagesEnum.LANGUAGE
];
const reservationFields = [
  "files",
  "additionalGuests",
  "folios",
  "foliosToBePaid",
  "groupedCharges"
];

export enum MagicPersonEnum {
  PRIMARY_GUEST = "primaryGuest",
  TRAVEL_BUDDY = "travelBuddy",
  BOOKER = "booker"
}

export const filterPagesForTravelBuddy = (page: ProfilePagesEnum) =>
  travelBuddyProfilePages.some((buddyPage) => page.indexOf(buddyPage) > -1);

export const filterFieldsForTravelBuddy = (reservationField: string) =>
  !reservationFields.some((field) => reservationField.indexOf(field) > -1);

export const getPersonMagicPerson = (reservation: Reservation) =>
  isReservation(reservation) ? MagicPersonEnum.PRIMARY_GUEST : MagicPersonEnum.TRAVEL_BUDDY;
