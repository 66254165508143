export interface MobileSDKInvitationCodeResponse {
  invitationCode: string;
  status: Status;
}
export enum Status {
  INVITATION_PENDING = "INVITATION_PENDING",
  ACKNOWLEDGED = "ACKNOWLEDGED",
  ACTIVATING = "ACTIVATING",
  ACTIVATION_FAILURE = "ACTIVATION_FAILURE",
  ACTIVE = "ACTIVE",
  TERMINATED = "TERMINATED",
  TERMINATING_FAILURE = "TERMINATING_FAILURE"
}
