import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type PayAndCreateBookingMutationVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"];
  payment: Types.WidgetPaymentRequest;
  booking: Types.PotentialBooking;
}>;

export type PayAndCreateBookingMutation = {
  __typename?: "Mutation";
  PayAndCreateBooking:
    | {
        __typename: "Booking";
        magicId: string;
        reservations?: Array<{ __typename?: "Reservation"; magicId: string } | null> | null;
      }
    | {
        __typename: "PaymentAction";
        checkoutPaymentsAction?: any | null;
        correlationId?: string | null;
        transitoryPaymentDTO?: {
          __typename?: "TransitoryPayment";
          paymentMethod?: string | null;
          shopperReference?: string | null;
        } | null;
      };
};

export const PayAndCreateBookingDocument = `
    mutation PayAndCreateBooking($pmsPropertyId: String!, $payment: WidgetPaymentRequest!, $booking: PotentialBooking!) {
  PayAndCreateBooking(
    pmsPropertyId: $pmsPropertyId
    payment: $payment
    booking: $booking
  ) {
    ... on PaymentAction {
      __typename
      checkoutPaymentsAction
      transitoryPaymentDTO {
        paymentMethod
        shopperReference
      }
      correlationId
    }
    ... on Booking {
      __typename
      magicId
      reservations {
        magicId
      }
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    PayAndCreateBooking: build.mutation<
      PayAndCreateBookingMutation,
      PayAndCreateBookingMutationVariables
    >({
      query: (variables) => ({ document: PayAndCreateBookingDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { usePayAndCreateBookingMutation } = injectedRtkApi;
