import React from "react";
import { NotificationChipType } from "./chip-type";
import { Check, DoNotDisturb, PriorityHigh } from "@mui/icons-material";
import { WrappedChip } from "../chip/wrapped-chip";

export type NotificationChipProps = {
  label: string;
  type: NotificationChipType;
};

interface ChipType {
  color: "success" | "error";
  icon: JSX.Element;
}

export const NotificationChip: React.FC<React.PropsWithChildren<NotificationChipProps>> = (
  props
) => {
  const { label, type } = props;

  const computeType = (type: NotificationChipType): ChipType => {
    switch (type) {
      case NotificationChipType.Success:
        return {
          icon: <Check fontSize="small" />,
          color: "success"
        };
      case NotificationChipType.Error:
        return {
          icon: <PriorityHigh fontSize="small" />,
          color: "error"
        };
      case NotificationChipType.DoNotDisturb:
        return {
          icon: <DoNotDisturb fontSize="small" />,
          color: "error"
        };
    }
  };

  return (
    <WrappedChip
      size="small"
      color={computeType(type).color}
      leftIcon={computeType(type).icon}
      label={label?.toUpperCase()}
    />
  );
};
