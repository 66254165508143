import { Box } from "@mui/material";
import React, { lazy, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DisplayCmsSvg } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useDispatch } from "react-redux";
import { clearPaymentState } from "../../payment/payment.slice";
import { useSetupGuestFlowNavigation } from "../../guest-flow/hooks/use-setup-guest-flow-navigation";
import { generateBookingOverviewPagesUrl } from "../booking-overview-navigation";
import { BookingOverviewPages } from "../booking-overview-pages";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useAdyenConfig } from "../../../util/hooks/use-configuration";
import { useFetchAllBookerOverview } from "../hooks/use-fetch-all-booker-overview";
import { useGetBookingOverviewMagicId } from "../booking-overview-subscription-provider";
import { errorHandleForComponentLazyLoad } from "../../../util/code-split.util";

const PaymentComponent = lazy(() =>
  import("../../payment/adyen/payment-adyen-widget-component")
    .then(({ PaymentAdyenWidgetComponent }) => ({
      default: PaymentAdyenWidgetComponent
    }))
    .catch(errorHandleForComponentLazyLoad)
);

interface PaymentPageProps {}

export const BookingOverviewPaymentPage: React.FC<
  React.PropsWithChildren<PaymentPageProps>
> = () => {
  const navigate = useNavigate();
  const { magicId: magicIdFromUrl, bookingOverviewItemId } = useMagicIdParams();
  const magicId = useGetBookingOverviewMagicId({
    magicIdFromUrl: magicIdFromUrl
  });

  const adyenEnvConfig = useAdyenConfig();

  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });
  const dispatch = useDispatch();
  const { getBookingOverviewByBookingOverviewItemId } = useFetchAllBookerOverview({
    magicId
  });

  const bookerOverview = getBookingOverviewByBookingOverviewItemId(bookingOverviewItemId);

  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const handleBack = useCallback(() => {
    navigate(
      generateBookingOverviewPagesUrl(BookingOverviewPages.CHECKOUT, magicId, bookingOverviewItemId)
    );
  }, [navigate, magicId, bookingOverviewItemId]);

  const onSuccessPayment = useCallback(() => {
    dispatch(clearPaymentState());
    navigate(
      generateBookingOverviewPagesUrl(BookingOverviewPages.CHECKOUT, magicId, bookingOverviewItemId)
    );
  }, [navigate, magicId, bookingOverviewItemId, dispatch]);

  const returnUrl = useMemo(
    () =>
      generateBookingOverviewPagesUrl(
        BookingOverviewPages.PAYMENT_REDIRECT,
        bookerOverview?.magicId || magicId,
        bookingOverviewItemId
      ),
    [magicId, bookingOverviewItemId, bookerOverview?.magicId]
  );

  return (
    <>
      <HelmetTitle suffix="Payment" />
      <FlowTemplate handleBack={handleBack}>
        <Box p={2.5}>
          <PageHeadingInfo
            title={tCommon("title__payment_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__bill_icon?.url} />}
          />
        </Box>
        <PaymentComponent
          onSuccessPayment={onSuccessPayment}
          returnUrl={returnUrl}
          magicId={bookerOverview?.magicId || ""}
          envConfig={adyenEnvConfig!}
          labels={{
            payButtonLabel: `${tCommon("buttons__pay")}`
          }}
        />
      </FlowTemplate>
    </>
  );
};
