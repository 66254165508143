import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function SmsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <path d="M2.89528 22.5256C2.43948 22.5256 1.99741 22.4524 1.58035 22.3081L0.630615 21.9795L1.36038 21.2885C2.32629 20.3739 2.57006 19.3189 2.58592 18.5287C0.916482 16.6777 1.29671e-08 14.3685 1.29671e-08 12.0001C-0.000305074 6.19645 5.38296 1.47461 11.9997 1.47461C18.6164 1.47461 24 6.19645 24 12.0001C24 17.8035 18.6164 22.525 11.9997 22.525C10.0297 22.525 8.08634 22.0982 6.35436 21.2873C5.70178 21.7495 4.38349 22.5256 2.89528 22.5256ZM6.21127 19.8546L6.56151 20.0319C8.20807 20.8644 10.0886 21.305 11.9994 21.305C17.9434 21.305 22.7793 17.1308 22.7793 12.0004C22.7793 6.86947 17.9434 2.69526 11.9994 2.69526C6.05537 2.69526 1.21974 6.86947 1.21974 12.0001C1.21974 14.1324 2.07825 16.217 3.63756 17.8697L3.7962 18.0378L3.80352 18.2688C3.83037 19.0983 3.68149 20.2231 2.94409 21.3047C4.35726 21.2821 5.65632 20.2985 5.90466 20.0999L6.21127 19.8546Z" />
        <path d="M6.17923 14.8789C5.73167 14.8789 5.36831 14.8438 5.08763 14.7733C4.80756 14.7035 4.61383 14.6315 4.50735 14.5583C4.4805 14.5314 4.47044 14.4701 4.47684 14.3731C4.48325 14.277 4.4985 14.1763 4.52199 14.0732C4.54518 13.9701 4.57539 13.8773 4.612 13.7983C4.64861 13.7184 4.68339 13.6811 4.71725 13.6882C4.89085 13.7483 5.07939 13.8068 5.28288 13.8633C5.48638 13.9197 5.74509 13.9481 6.05872 13.9481C6.37937 13.9481 6.65089 13.902 6.87513 13.8081C7.09846 13.7144 7.21073 13.5475 7.21073 13.3074C7.21073 13.0996 7.1369 12.9315 6.99015 12.8016C6.8434 12.6716 6.5795 12.5328 6.19906 12.3867C5.99191 12.3058 5.79695 12.2176 5.61329 12.1206C5.42932 12.0242 5.26793 11.9104 5.12759 11.7801C4.98725 11.6502 4.87742 11.4949 4.79718 11.3149C4.71695 11.1349 4.67667 10.921 4.67667 10.6739C4.67667 10.4738 4.71542 10.2834 4.792 10.1031C4.86857 9.92277 4.98725 9.76748 5.14742 9.63751C5.30759 9.50754 5.51292 9.40381 5.7634 9.32724C6.01387 9.25036 6.31255 9.21191 6.65974 9.21191C6.88642 9.21191 7.07527 9.2171 7.22537 9.22686C7.37548 9.23693 7.49873 9.25035 7.59606 9.26713C7.69246 9.28391 7.77118 9.30222 7.83128 9.32236C7.89138 9.34249 7.94813 9.35897 8.00152 9.37239C8.04819 9.39222 8.07169 9.4508 8.07169 9.54751C8.07169 9.64422 8.05796 9.74765 8.03141 9.85809C8.00487 9.96792 7.96796 10.0649 7.92158 10.1482C7.8746 10.2318 7.82792 10.2669 7.78124 10.2535C7.66775 10.2202 7.51246 10.1836 7.31568 10.1437C7.11859 10.1034 6.927 10.0835 6.73998 10.0835C6.35923 10.0835 6.09747 10.1372 5.95408 10.2437C5.81038 10.3505 5.73868 10.4939 5.73868 10.6742C5.73868 10.8679 5.81892 11.0232 5.97909 11.1398C6.13926 11.2563 6.38974 11.375 6.72991 11.4952C7.27053 11.7087 7.66958 11.954 7.92646 12.2311C8.18335 12.5081 8.3121 12.8568 8.3121 13.2775C8.3121 13.5914 8.25199 13.852 8.13209 14.0591C8.01158 14.266 7.85141 14.4298 7.65128 14.5491C7.45114 14.6699 7.22232 14.7547 6.96544 14.8048C6.70825 14.8539 6.44618 14.8789 6.17923 14.8789Z" />
        <path d="M13.3985 14.829C13.2585 14.829 13.1666 14.8058 13.1236 14.7588C13.0797 14.7124 13.0586 14.6224 13.0586 14.4882V10.9837C12.7917 11.4175 12.5857 11.7528 12.4421 11.9898C12.2981 12.2272 12.194 12.4008 12.1269 12.5109C12.0601 12.6205 12.0198 12.686 12.0061 12.7059C11.993 12.726 11.9869 12.7397 11.9869 12.7465C11.9271 12.8465 11.885 12.9115 11.8618 12.9417C11.838 12.9725 11.7895 12.9866 11.7162 12.9866H11.5561C11.4694 12.9866 11.4124 12.9716 11.3858 12.9417C11.3593 12.9115 11.319 12.8465 11.2656 12.7465L10.2442 11.0044V14.5492C10.2442 14.6362 10.2293 14.7048 10.199 14.7548C10.1692 14.8049 10.0907 14.8299 9.96383 14.8299H9.54372C9.45006 14.8299 9.3686 14.8149 9.29813 14.7847C9.22826 14.7548 9.19287 14.6566 9.19287 14.4891V9.68246C9.19287 9.54883 9.21789 9.46036 9.26792 9.41703C9.31796 9.37371 9.38965 9.35205 9.48331 9.35205H9.98396C10.0572 9.35205 10.1191 9.36181 10.1692 9.38195C10.2192 9.40208 10.2707 9.45212 10.3244 9.53205L11.6558 11.6549L12.9875 9.55219C13.0278 9.47866 13.0757 9.4271 13.1334 9.3969C13.1904 9.36669 13.2447 9.35205 13.2984 9.35205H13.8494C13.9763 9.35205 14.0493 9.37859 14.07 9.43198C14.0901 9.48568 14.0999 9.57232 14.0999 9.69253V14.5382C14.0999 14.6383 14.0868 14.7121 14.0599 14.7591C14.0331 14.8061 13.9562 14.829 13.8299 14.829H13.3985Z" />
        <path d="M16.7334 14.8789C16.2856 14.8789 15.9225 14.8438 15.6418 14.7733C15.3618 14.7035 15.1677 14.6315 15.0615 14.5583C15.0347 14.5314 15.0246 14.4701 15.031 14.3731C15.0374 14.277 15.0521 14.1763 15.0759 14.0732C15.0991 13.9701 15.129 13.8773 15.1659 13.7983C15.2022 13.7181 15.237 13.6811 15.2711 13.6882C15.4447 13.7483 15.6336 13.8068 15.8368 13.8633C16.04 13.92 16.2987 13.9481 16.6126 13.9481C16.9333 13.9481 17.2045 13.902 17.429 13.8081C17.652 13.7144 17.7649 13.5475 17.7649 13.3074C17.7649 13.0996 17.6911 12.9315 17.544 12.8016C17.397 12.6716 17.1334 12.5328 16.7526 12.3867C16.5455 12.3058 16.3508 12.2176 16.1669 12.1206C15.9829 12.0242 15.8215 11.9104 15.6809 11.7801C15.5408 11.6502 15.431 11.4949 15.3508 11.3149C15.2702 11.1346 15.2303 10.921 15.2303 10.6739C15.2303 10.4738 15.269 10.2834 15.3453 10.1031C15.4222 9.92277 15.5408 9.76748 15.701 9.63751C15.8612 9.50754 16.0662 9.40381 16.3167 9.32724C16.5675 9.25036 16.8664 9.21191 17.213 9.21191C17.44 9.21191 17.6289 9.2171 17.7787 9.22686C17.9288 9.23693 18.052 9.25035 18.1496 9.26713C18.2457 9.28391 18.3245 9.30222 18.3849 9.32236C18.4447 9.34219 18.5017 9.35897 18.5554 9.37239C18.6018 9.39222 18.6253 9.4508 18.6253 9.54751C18.6253 9.64422 18.6115 9.74765 18.5847 9.85809C18.5585 9.96792 18.5215 10.0649 18.4755 10.1482C18.4285 10.2318 18.3815 10.2669 18.3348 10.2535C18.2216 10.2202 18.0657 10.1836 17.8696 10.1437C17.6725 10.1034 17.4806 10.0835 17.2939 10.0835C16.9131 10.0835 16.6511 10.1372 16.508 10.2437C16.3643 10.3505 16.2923 10.4939 16.2923 10.6742C16.2923 10.8679 16.3728 11.0232 16.533 11.1398C16.6929 11.2563 16.9436 11.375 17.2838 11.4952C17.8241 11.7087 18.2235 11.954 18.4804 12.2311C18.7372 12.5081 18.866 12.8568 18.866 13.2775C18.866 13.5914 18.8059 13.852 18.686 14.0591C18.5655 14.266 18.4053 14.4298 18.2052 14.5491C18.005 14.6699 17.7762 14.7547 17.5193 14.8048C17.2615 14.8539 17.0004 14.8789 16.7334 14.8789Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
