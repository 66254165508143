import {
  fetchArticleOrderingCMS,
  selectArticleOrderingCMSById
} from "./article-ordering-cms.slice";
import { useCMSData } from "../cms/use-cms-data";
import { PrismicDocumentToMyStayTipCardMapper } from "../../mapper/prismic-document-to-my-stay-tip-card";

export const useArticleOrderingCms = (magicId: string, propertyId: string) => {
  const cmsData = useCMSData(selectArticleOrderingCMSById, fetchArticleOrderingCMS);

  const articles =
    cmsData?.data["article_ordering"]
      ?.map((item: any) => item.article_order)
      ?.filter((item: any) => !!item)
      ?.map(PrismicDocumentToMyStayTipCardMapper(magicId, propertyId)) || undefined;

  return {
    items: articles
  };
};
