import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useNavbar } from "../../../components/organism/top-navbar/navbar-context";
import { MyStayEventDetails } from "../../../domain-common/my-stay-event-details";
import { RootState, useAppDispatch } from "../../../state/store";
import {
  fetchMyStayEventById,
  selectMyStayEventDetailsById
} from "../../my-stay-events/my-stay-events.slice";
import { ContentDetails } from "../components/content-details";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { DesktopCard } from "../../../components";
import { BackButton } from "../../../components/atoms/back-button/back-button";
import { useCmsClient } from "../../../api/cms-client/use-cms-client";

interface EventDetailsPageProps {}

export const EventDetailsPage: React.FC<React.PropsWithChildren<EventDetailsPageProps>> = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const dispatch = useAppDispatch();
  const eventDetails = useSelector<RootState, MyStayEventDetails | null>((state) =>
    selectMyStayEventDetailsById(state, eventId || "")
  );
  const navigate = useNavigate();

  const { setDisplayNavbar } = useNavbar();
  const prismic = useCmsClient();
  const isMobile = useIsMobile();

  useEffect(() => {
    setDisplayNavbar(!isMobile);
  }, [setDisplayNavbar, isMobile]);

  useEffect(() => {
    const promise = dispatch(fetchMyStayEventById({ eventId, prismic: prismic }));
    return () => {
      promise.abort();
    };
  }, [dispatch, eventId, prismic]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!eventDetails) {
    return null;
  }

  return (
    <>
      <HelmetTitle suffix="Event details" />
      {!isMobile && (
        <BackButton
          key="back_button"
          sx={{ marginTop: -2, marginBottom: 2 }}
          onClick={handleBack}
        />
      )}
      <DesktopCard>
        <ContentDetails
          title={eventDetails.title}
          subtitle={eventDetails.subtitle}
          coverImage={eventDetails.coverImage}
          description={eventDetails.description}
          date={eventDetails.date}
          time={eventDetails.time}
          location={eventDetails.location}
        />
      </DesktopCard>
    </>
  );
};
