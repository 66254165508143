import { makeStyles } from "tss-react/mui";
import React, { forwardRef, useImperativeHandle, useLayoutEffect } from "react";
import BezierEasing from "bezier-easing";

interface UnlockProps {
  lockShankOpen?: boolean;
}

const useStyles = makeStyles<UnlockProps>()((theme, props) => ({
  circle: {
    transform: "rotate(-90deg)",
    transformOrigin: "50% 50%"
  },
  lockShank: {
    transformOrigin: "40% 50%",
    transform: `rotate3d(0, 1, 0, ${props.lockShankOpen ? 150 : 0}deg)`,
    transition: theme.transitions.create("transform")
  }
}));

const radius = 80;
const circumference = radius * 2 * Math.PI;
const easing = BezierEasing(0.4, 0, 0.2, 1);

export const UnlockAnimation = forwardRef<any, UnlockProps>((props, ref: React.Ref<any>) => {
  const { classes } = useStyles(props);
  const circleRef = React.useRef<SVGCircleElement>(null);
  const currentPercent = React.useRef(0);

  useImperativeHandle(ref, () => ({
    setPercent(percent: number) {
      currentPercent.current = percent;
      const offset = circumference - easing(percent / 100) * circumference;
      if (circleRef.current) {
        if (offset > 0) {
          circleRef.current.style.strokeDashoffset = offset + "";
        } else {
          circleRef.current.style.strokeDashoffset = "0";
        }
      }
    },
    getPercent() {
      return currentPercent.current;
    }
  }));

  useLayoutEffect(() => {
    if (circleRef.current) {
      circleRef.current.style.strokeDashoffset = circumference + "";
    }
  }, []);

  return (
    <svg width="166" height="166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        x="54.60415267944336"
        y="75.53185162810087"
        width="56.791694237251704"
        height="46.46593495787897"
        rx="3"
        ry="3"
      />
      <path
        className={classes.lockShank}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M64.92991674305395,74.97067089996581 V62.06346706307021 a18.070085371653832,18.070085371653832 0 0 1 36.140170743307664,0 V74.97067089996581 "
      />
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        x1="82.99999979806921"
        y1="93.60193946735399"
        x2="82.99999979806921"
        y2="103.92770154683467"
      />
      <circle
        cx="83"
        cy="83"
        r={radius}
        fill="transparent"
        stroke="white"
        strokeWidth="3"
        strokeDasharray={`${circumference} ${circumference}`}
        ref={circleRef}
        className={classes.circle}
      />
    </svg>
  );
});
