import React, { FC, useEffect, useMemo, useState } from "react";
import { SearchUnitGroup } from "../../../domain-common/search-unit-group";
import { Box, Card, CardActions, CardContent, Grid } from "@mui/material";
import { Button, Heading2, Heading3 } from "@likemagic-tech/sv-magic-library";
import { UnitGroupFeatures } from "./unit-group-features/unit-group-features";
import { Ribbon } from "../../../components";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { UnitGroupRates } from "./unit-group-rates";
import { useUnitGroupCmsData } from "../../../state/property-cms/use-unit-group-cms-data";
import { CarouselGallery } from "../../../components/molecules/carousel-gallery/carousel-gallery";
import { usePropertyTextCmsData } from "../../../state/property-cms/use-property-text-cms-data";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { Price } from "../../../domain-common/price";

export const UnitGroupCard: FC<
  React.PropsWithChildren<{
    unitGroup: SearchUnitGroup;
    openModal: (data: {
      ratePlanId: string;
      unitGroupId: string;
      propertyId: string;
      priceTotal: Price;
    }) => void;
  }>
> = ({ unitGroup, openModal }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const isMobile = useIsMobile();

  const availableUnitGroupsRibbonText = t("labels__only_availableunitsthreshold_left", {
    availableUnitsThreshold:
      // TODO this ternary doesn't make sense. We don't display the label if >=
      unitGroup.availableUnits < unitGroup.availableUnitsThreshold
        ? unitGroup.availableUnits?.toString()
        : unitGroup.availableUnitsThreshold?.toString()
  });

  const [ratePlanId, setRatePlanId] = useState<string>("");

  useEffect(() => {
    const lowestRatePlan = unitGroup.offers
      .map((offer) => ({
        id: offer.ratePlanId,
        pricePerNight: offer.pricePerNight.amount
      }))
      // TODO: this should be handled by BE. Remove if it's not needed
      .sort((a, b) => a.pricePerNight - b.pricePerNight);

    if (lowestRatePlan[0]) {
      setRatePlanId(lowestRatePlan[0].id);
    }
  }, [unitGroup.offers]);
  const { unitGroupImages } = useUnitGroupCmsData(unitGroup.propertyId, unitGroup.unitGroupId);

  const { textOutput: unitGroupLabel } = usePropertyTextCmsData(
    "unit_groups__labels",
    unitGroup.propertyId,
    unitGroup.unitGroupId
  );
  const ribbonText = useMemo(() => {
    return unitGroup.availableUnits <= unitGroup.availableUnitsThreshold
      ? availableUnitGroupsRibbonText
      : undefined;
  }, [availableUnitGroupsRibbonText, unitGroup.availableUnits, unitGroup.availableUnitsThreshold]);

  const maxPersonsLabel = useMemo(() => {
    if (unitGroup.maxPersons === 1) {
      return `1 ${t("labels__person")}`;
    } else {
      return `1 - ${unitGroup.maxPersons} ${t("labels__people")}`;
    }
  }, [unitGroup.maxPersons, t]);

  return (
    <Card sx={{ overflow: "visible" }}>
      {!!ribbonText && <Ribbon text={ribbonText} position="topRight" containerSideLength={100} />}
      <Grid container>
        <Grid item xs={12} md={5} p={isMobile ? 0 : 2}>
          <Grid container direction={isMobile ? "column-reverse" : "column"}>
            <Grid item xs={12} style={{ maxWidth: "100%" }}>
              <Box m={isMobile ? 2 : 0}>
                <Heading2>{unitGroupLabel.text}</Heading2>
                <Heading3>{maxPersonsLabel}</Heading3>
              </Box>
              <Box my={1}>
                <div>
                  <UnitGroupFeatures
                    unitGroupId={unitGroup.unitGroupId}
                    propertyId={unitGroup.propertyId}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <CarouselGallery items={unitGroupImages} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={7}>
          <CardContent>
            {!isMobile && (
              <Box mb={2}>
                <Heading2>{t("labels__rates")}</Heading2>
              </Box>
            )}
            <Box>
              <UnitGroupRates
                value={ratePlanId}
                onChange={setRatePlanId}
                unitGroupId={unitGroup.unitGroupId}
                propertyId={unitGroup.propertyId}
                offers={unitGroup.offers}
                numberOfNights={unitGroup.numberOfNights}
              />
            </Box>
          </CardContent>
          <CardActions>
            <Button
              variant="primary"
              fullWidth
              onClick={() => {
                openModal({
                  ratePlanId,
                  unitGroupId: unitGroup.unitGroupId,
                  propertyId: unitGroup.propertyId,
                  priceTotal: unitGroup.offers.find((offer) => offer.ratePlanId === ratePlanId)!
                    .priceTotal
                });
              }}
            >
              {t("buttons__book")}
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};
