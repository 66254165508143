import { Box } from "@mui/material";
import React, { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DesktopCard, DisplayCmsSvg } from "../../../components";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { HelmetTitle } from "../../gtm/helmet-title";
import { GuestFlowCheckpoint } from "../checkpoint/guest-flow-checkpoint";
import {
  generateGuestFlowUrlFromCheckpoint,
  getNextFlowUrl,
  getPrevFlowUrl
} from "../guest-flow-sequence";
import { useGuestFlow } from "../use-guest-flow";
import {
  clearPaymentResponses,
  clearPaymentState,
  selectPaymentSlice
} from "../../payment/payment.slice";
import { useSetupGuestFlowNavigation } from "../hooks/use-setup-guest-flow-navigation";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { isReservation } from "../../../util/flow";
import { isKioskMode } from "../../../util/kiosk-mode";
import { GuestFlowBackgroundBox } from "../components/guest-flow-background-box";
import { useAppDispatch } from "../../../state/store";
import { PaymentComponent } from "../../payment/payment-component";
import { SubUpdate } from "../../reservation-provider/sub-update";
import { clearAdditionalServicesCart } from "../../additional-services/service-selection/additional-services-cart.slice";
import { useSelector } from "react-redux";

const CURRENT_PAGE = GuestFlowCheckpoint.PAYMENT;

interface PaymentPageProps {}

export const PaymentPage: FC<React.PropsWithChildren<PaymentPageProps>> = () => {
  const navigate = useNavigate();
  const { reservation, contactButton, patchGuestFlowStep, skipButton } = useGuestFlow();
  useSetupGuestFlowNavigation({ showBottomBar: true, showTopNavBar: true });
  const dispatch = useAppDispatch();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { paymentDTO } = useSelector(selectPaymentSlice);

  const clearAfterPayment = useCallback(() => {
    if (paymentDTO.additionalServices) {
      dispatch(clearAdditionalServicesCart());
    }
    dispatch(clearPaymentResponses());
  }, [dispatch, paymentDTO]);

  const onSuccessPayment = useCallback(() => {
    clearAfterPayment();
    patchGuestFlowStep({
      reservationValues: reservation,
      checkpoint: CURRENT_PAGE,
      subUpdate: SubUpdate.PAYMENT
    });
    dispatch(clearPaymentState());
    navigate(getNextFlowUrl(CURRENT_PAGE, reservation));
  }, [navigate, reservation, patchGuestFlowStep, dispatch, clearAfterPayment]);

  const returnUrl = useMemo(
    () => generateGuestFlowUrlFromCheckpoint(GuestFlowCheckpoint.PAYMENT_REDIRECT, reservation),
    [reservation]
  );

  const onBack = useCallback(() => {
    navigate(getPrevFlowUrl(CURRENT_PAGE, reservation));
  }, [navigate, reservation]);
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);

  const topBarIcons = useMemo(() => {
    if (isReservation(reservation)) {
      if (isKioskMode()) {
        return [skipButton];
      }
      return [contactButton];
    }
    return [];
  }, [reservation, contactButton, skipButton]);

  return (
    <>
      <HelmetTitle suffix="Guest flow payment" />
      <FlowTemplate handleBack={onBack} icons={topBarIcons}>
        <GuestFlowBackgroundBox progressBarIsHidden={true} bottomNavBarIsHidden={isKioskMode()}>
          <Box p={2} pt={0}>
            <DesktopCard>
              <PageHeadingInfo
                title={tCommon("title__payment_page")}
                icon={<DisplayCmsSvg url={cmsData?.data?.icon__bill_icon?.url} />}
              />
              <PaymentComponent onSuccessPayment={onSuccessPayment} returnUrl={returnUrl} />
            </DesktopCard>
          </Box>
        </GuestFlowBackgroundBox>
      </FlowTemplate>
    </>
  );
};
