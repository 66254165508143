import { BaseApiClient, JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";

import { Charge } from "../domain-common/payment";
import { FullPrice } from "../domain-common/full-price";

const BASE_URL = "/api/guest-journey-service/magic";

export interface ExtendStayRequestDTO {
  from: string;
  to: string;
}

export interface ExtendStayPriceSummaryDTO {
  total: FullPrice;
  accommodation: Charge;
  cityTaxes: Array<Charge>;
  services: Array<Charge>;
  includedServices: Array<Charge>;
}

class ExtendStayApiClient extends BaseApiClient {
  async price(
    param: {
      magicId: string;
      extendStayRequestDTO: ExtendStayRequestDTO;
    },
    init?: RequestInit
  ) {
    const response = await this.fetchApi(`${BASE_URL}/${param.magicId}/extend/price`, {
      ...init,
      method: "POST",
      body: JSON.stringify(param.extendStayRequestDTO)
    });
    return new JSONApiResponse<ExtendStayPriceSummaryDTO>(response).value();
  }

  async book(
    param: {
      magicId: string;
      extendStayRequestDTO: ExtendStayRequestDTO;
    },
    init?: RequestInit
  ) {
    const response = await this.fetchApi(`${BASE_URL}/${param.magicId}/extend`, {
      ...init,
      method: "POST",
      body: JSON.stringify(param.extendStayRequestDTO)
    });
    return new VoidApiResponse(response).value();
  }
}

export const ExtendStayApi = new ExtendStayApiClient();
