import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CmsSingleDocumentPerPropertyNamespace } from "../state/cms/cms-single-document-per-property-type";
import { CMSSingleDocumentTypes } from "../state/cms/cms-single-document-types";
import { buildReservationTranslationContext } from "./translation";
import { useReservationContext } from "../features/reservation-provider/reservation-provider";

interface TranslateWrapperOptions {
  currentReservationId?: string;
  timeZone?: string;
}

export type TranslateWrapperNamespace =
  | CMSSingleDocumentTypes
  | CmsSingleDocumentPerPropertyNamespace;

interface TranslateWrapperProps {
  namespace: TranslateWrapperNamespace[];
  options?: TranslateWrapperOptions;
}

export const useTranslateWrapper = (props: TranslateWrapperProps) => {
  const { reservation } = useReservationContext();
  const translationContext = useMemo(
    () =>
      reservation
        ? buildReservationTranslationContext(reservation, {
            timeZone: props.options?.timeZone
          })
        : {},
    [props.options?.timeZone, reservation]
  );

  const { t } = useTranslation(props.namespace);

  const tApplied = useCallback(
    (key: string, additionalInterpolationMap?: Record<string, string>) =>
      t(key, { ...translationContext, ...additionalInterpolationMap }),
    [t, translationContext]
  );
  // t("xxx") returns "xxx" if key does not exist on prismic
  const orDefault = useCallback(
    (t: any, label: string, fallback?: string) => (t(label) !== label ? t(label) : fallback),
    []
  );

  // i18n.exists didn't work this is workaround
  const exists = (key: string) => t(key) !== key && t(key);

  return { t: tApplied, exists, orDefault };
};
