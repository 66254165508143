import React, { useCallback } from "react";
import { Reservation } from "../../../domain-common/reservation";
import { GuestFlowBackendCheckpointService } from "../../../features/guest-flow/checkpoint/guest-flow-backend-checkpoint-service";
import { GuestFlowCheckpoint } from "../../../features/guest-flow/checkpoint/guest-flow-checkpoint";
import { generateGuestFlowUrl } from "../../../features/guest-flow/guest-flow-sequence";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CallToActionButton } from "./call-to-action-button";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useNavigate } from "react-router-dom";

interface CallToActionCheckpointButtonProps {
  reservation: Reservation;
  nextFlowCheckpoint: GuestFlowCheckpoint;
}

const backendCheckpoint = new GuestFlowBackendCheckpointService();
export const CallToActionCheckpointButton: React.FC<
  React.PropsWithChildren<CallToActionCheckpointButtonProps>
> = ({ reservation }) => {
  const navigate = useNavigate();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const handleClick = useCallback(async () => {
    let suffix = await backendCheckpoint.restore(reservation);
    let path = generateGuestFlowUrl(suffix, reservation.magicId);
    return navigate(path);
  }, [reservation, navigate]);

  return (
    <CallToActionButton
      text={t(
        !!reservation.flowState.context?.lastConfirmedPage
          ? "buttons__continue_checkin"
          : "buttons__start_checkin"
      )}
      onClick={handleClick}
    />
  );
};
