import {
  createAsyncThunk,
  createEntityAdapter,
  Draft,
  EntityState,
  PayloadAction,
  SerializedError
} from "@reduxjs/toolkit";
import { PrismicData } from "../../api/cms-client/use-cms-client";
import { PrismicDocumentPerProperty } from "../../domain-common/prismic-document";
import { RootState, ThunkExtraArguments } from "../store";
import { CMSResourceStatus } from "./cms-resource-status";
import { createCmsSelectorsFactory } from "./cms-selectors";
import { CmsSingleDocumentPerPropertyType } from "./cms-single-document-per-property-type";
import { filter } from "@prismicio/client";
import { AdditionalFiltersForPropertyDocuments } from "./use-cms-per-property-data";

type CMSPageStateExtension = {
  loadingStatuses: {
    [id: string]: CMSResourceStatus;
  };
};

export type CMSPagePerPropertyState = EntityState<PrismicDocumentPerProperty> &
  CMSPageStateExtension;

export interface PayloadArg {
  language: string;
  propertyId: string;
  prismic: PrismicData;
  additionalFilters?: AdditionalFiltersForPropertyDocuments;
}

/**
 * Define the way how the selector id will be stored
 * @param model
 */
export const selectId = (model: PrismicDocumentPerProperty) => {
  return [
    model?.lang ? model?.lang : "not_found",
    model?.data?.main__property_id,
    model?.data?.main__type ?? ""
  ].join();
};

/**
 * Define selector id how it will read from state
 * @param arg
 */
export const selectIdFromActonPayload = (arg: {
  language: string;
  propertyId: string;
  additionalFilters?: AdditionalFiltersForPropertyDocuments;
}) => [arg.language, arg.propertyId, arg?.additionalFilters?.type ?? ""].join();

const adapterFactory = () => {
  const adapter = createEntityAdapter<PrismicDocumentPerProperty>({
    selectId
  });

  function getInitialState(): CMSPagePerPropertyState {
    return adapter.getInitialState<CMSPageStateExtension>({
      loadingStatuses: {}
    });
  }

  function handlePendingLoadingStatus(
    state: Draft<CMSPagePerPropertyState>,
    action: PayloadAction<any, string, { arg: PayloadArg }>
  ): void {
    const id = selectIdFromActonPayload(action.meta.arg);
    state.loadingStatuses[id] = CMSResourceStatus.PENDING;
  }

  function handleFulfilledLoadingStatus(
    state: Draft<CMSPagePerPropertyState>,
    action: PayloadAction<any, string, { arg: PayloadArg }>
  ): void {
    const id = selectIdFromActonPayload(action.meta.arg);
    state.loadingStatuses[id] = CMSResourceStatus.FULFILLED;
  }

  function handleRejectedLoadingStatus(
    state: Draft<CMSPagePerPropertyState>,
    action: PayloadAction<any, string, { arg: PayloadArg; aborted: boolean }, SerializedError>
  ): void {
    let id = selectIdFromActonPayload(action.meta.arg);
    if (action.error.name === "AbortError") {
      if (state.loadingStatuses[id] === CMSResourceStatus.PENDING) {
        state.loadingStatuses[id] = CMSResourceStatus.IDLE;
      }
      return;
    }
    state.loadingStatuses[id] = CMSResourceStatus.REJECTED;
  }

  const cmsSelectorsFactory = createCmsSelectorsFactory();

  return {
    ...adapter,
    getInitialState,
    handlePendingLoadingStatus,
    handleFulfilledLoadingStatus,
    handleRejectedLoadingStatus,
    ...cmsSelectorsFactory
  };
};

export const createCMSSingleDocumentPerPropertyFetchThunk = (
  documentType: CmsSingleDocumentPerPropertyType
) =>
  createAsyncThunk<
    PrismicDocumentPerProperty,
    PayloadArg,
    { state: RootState; extra: ThunkExtraArguments }
  >(
    `cms/${documentType}/fetchSinglePerProperty`,
    async (arg) => {
      let lang = arg.language;

      const additionalFiltersArray = arg?.additionalFilters?.type
        ? [filter.at(`my.${documentType}.main__type`, arg?.additionalFilters?.type)]
        : [];

      return arg.prismic.prismicApiClient.getFirst({
        filters: [
          filter.at("document.type", documentType),
          filter.at(`my.${documentType}.main__property_id`, arg.propertyId),
          ...additionalFiltersArray
        ],
        lang,
        pageSize: 1,
        ...arg.prismic.refOptions
      });
    },
    {
      condition(
        arg: { language: string; propertyId: string; prismic: PrismicData },
        thunkAPI
      ): boolean | undefined {
        let id = selectIdFromActonPayload(arg);
        let loadingStatus = thunkAPI.getState().cmsPerProperty[documentType].loadingStatuses[id];

        if (
          loadingStatus === CMSResourceStatus.FULFILLED ||
          loadingStatus === CMSResourceStatus.PENDING
        ) {
          return false;
        }
      }
    }
  );

export const createCMSSinglePagePerPropertyAdapter = adapterFactory;
