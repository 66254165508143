import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UpdatePrimaryGuestMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  person: Types.UpdatePersonInput;
  flowState: Types.FlowStateUpdateInput;
  magicToken: Types.Scalars["String"];
}>;

export type UpdatePrimaryGuestMutation = {
  __typename?: "Mutation";
  UpdateMainActor: { __typename: "Person" };
  UpdateFlowState: { __typename: "ReservationFlowState" };
};

export const UpdatePrimaryGuestDocument = `
    mutation UpdatePrimaryGuest($magicId: String!, $person: UpdatePersonInput!, $flowState: FlowStateUpdateInput!, $magicToken: String!) {
  UpdateMainActor(magicId: $magicId, person: $person, magicToken: $magicToken) {
    __typename
  }
  UpdateFlowState(
    flowState: $flowState
    magicId: $magicId
    magicToken: $magicToken
  ) {
    __typename
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UpdatePrimaryGuest: build.mutation<
      UpdatePrimaryGuestMutation,
      UpdatePrimaryGuestMutationVariables
    >({
      query: (variables) => ({ document: UpdatePrimaryGuestDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdatePrimaryGuestMutation } = injectedRtkApi;
