import { useNavigate } from "react-router-dom";
import { useMagicIdParams } from "../magic/use-magic-id-params";
import { useCallback, useMemo, useState } from "react";
import { useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useFeatureFlags, useHidePaymentConfig } from "../../util/hooks/use-configuration";
import { getOkModalArg } from "../global-modal/global-modal-util";
import { generatePortalMyStayOpenBalanceUrl, generateSearchPageUrl } from "../../util/routing";
import { Reservation } from "../../domain-common/reservation";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { ReservationStatus } from "../../domain-common/reservation-status";

interface UseExtendStayProps {
  reservation?: Reservation;
}
export const useExtendStayModal = ({ reservation }: UseExtendStayProps) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const [extendModalOpen, setExtendModalOpen] = useState(false);
  const { open: openModal } = useGlobalModal();
  const { searchEnabled } = useFeatureFlags();
  const hidePrepaymentConfig = useHidePaymentConfig(reservation?.propertyId);
  // INFO: We want to disable extend stay if there's an open balance.
  // Because we do not show all of the things the user will pay other than the extend stay.
  const openBalance = useMemo(
    () => reservation?.flowState?.wasOrIsCompleted && reservation?.totalAllowedPayment?.amount > 0,
    [reservation]
  );

  const [unavailable, setUnavailable] = useState(false);
  const extendStayActionDisabled = useMemo(
    () =>
      !reservation?.arrival ||
      !reservation?.departure ||
      (reservation?.status !== ReservationStatus.CONFIRMED &&
        reservation?.status !== ReservationStatus.IN_HOUSE),
    [reservation]
  );

  const onClick = useCallback(async () => {
    if (openBalance) {
      const result = await openModal(
        getOkModalArg(
          t("modals__title_extend_stay_unavailable_because_of_open_balance"),

          t("modals__body_extend_stay_unavailable_because_of_open_balance"),
          t("buttons__open_balance")
        )
      );
      if (result) {
        navigate(generatePortalMyStayOpenBalanceUrl(magicId));
      }
    } else if (reservation?.isExternalReservation) {
      const result = await openModal(
        getOkModalArg(
          t("modals__extend_stay_dialog_title"),
          t("modals__extend_stay_dialog_content"),
          t("buttons__ok")
        )
      );
      if (result && searchEnabled) {
        navigate(generateSearchPageUrl());
      }
    } else if (
      hidePrepaymentConfig &&
      reservation?.folios[0]?.prepaymentType &&
      hidePrepaymentConfig[reservation?.folios[0]?.prepaymentType]
    ) {
      const result = await openModal(
        getOkModalArg(
          t("modals__extend_stay_hide_prepayment_dialog_title"),
          t("modals__extend_stay_hide_prepayment_dialog_content"),
          t("buttons__ok")
        )
      );
      if (result && searchEnabled) {
        navigate(generateSearchPageUrl());
      }
    } else {
      setUnavailable(false);
      setExtendModalOpen(true);
    }
  }, [
    openModal,
    t,
    searchEnabled,
    navigate,
    hidePrepaymentConfig,
    reservation,
    magicId,
    openBalance
  ]);

  return {
    onClick,
    extendModalOpen,
    setExtendModalOpen,
    unavailable,
    setUnavailable,
    extendStayActionDisabled
  };
};
