import * as Types from "../../generated/graphql";

import { PriceFragmentFragmentDoc } from "./PriceFragment.generated";
export type FolioFragmentFragment = {
  __typename?: "Folio";
  pmsId?: string | null;
  type: Types.FolioType;
  debitorType: Types.DebitorType;
  prepaymentType: Types.PrepaymentType;
  pmsCustomerId?: string | null;
  propertyId?: number | null;
  pmsCompanyId?: string | null;
  number?: string | null;
  closed?: any | null;
  totalCharges?: {
    __typename?: "Price";
    currency?: string | null;
    grossPrice?: any | null;
    grossPriceInCents?: number | null;
    netPrice?: any | null;
    netPriceInCents?: number | null;
    taxes?: Array<{
      __typename?: "Tax";
      amount?: any | null;
      amountInCents?: number | null;
      code?: string | null;
    } | null> | null;
  } | null;
  totalPayments?: {
    __typename?: "GrossPrice";
    currency?: string | null;
    grossPrice?: any | null;
    grossPriceInCents?: number | null;
  } | null;
  groupedCharges: Array<{
    __typename?: "GroupedCharge";
    name: string;
    subName?: string | null;
    pmsServiceId?: string | null;
    totalQuantity: number;
    prepayable: boolean;
    translatedNames?: any | null;
    totalPrice: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
    };
  }>;
  orderItems: Array<{
    __typename?: "OrderItem";
    pmsId: string;
    pmsFolioId?: string | null;
    pmsReservationId?: string | null;
    start?: any | null;
    end?: any | null;
    accountingState: Types.AccountingState;
    translatedNames?: any | null;
    price: {
      __typename?: "Price";
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      currency?: string | null;
    };
  }>;
  payments: Array<{
    __typename?: "Payment";
    pmsId: string;
    folioId?: number | null;
    state: Types.PaymentState;
    price: {
      __typename?: "Price";
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      currency?: string | null;
    };
  }>;
};

export const FolioFragmentFragmentDoc = `
    fragment FolioFragment on Folio {
  pmsId
  type
  debitorType
  prepaymentType
  pmsCustomerId
  propertyId
  pmsCompanyId
  number
  closed
  totalCharges {
    currency
    grossPrice
    grossPriceInCents
    netPrice
    netPriceInCents
    taxes {
      amount
      amountInCents
      code
    }
  }
  totalPayments {
    currency
    grossPrice
    grossPriceInCents
  }
  groupedCharges {
    name
    subName
    pmsServiceId
    totalQuantity
    prepayable
    translatedNames
    totalPrice {
      currency
      grossPrice
      grossPriceInCents
      netPrice
      netPriceInCents
    }
  }
  orderItems {
    pmsId
    pmsFolioId
    pmsReservationId
    start
    end
    accountingState
    translatedNames
    price {
      ...PriceFragment
    }
  }
  payments {
    pmsId
    folioId
    price {
      ...PriceFragment
    }
    state
  }
}
    `;
