import { useArticleOrderingCms } from "../../state/article-ordering-cms/use-article-ordering-cms";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../state/store";
import {
  fetchMyStayTips,
  selectAllMyStayTipCards
} from "../../features/my-stay-tips/my-stay-tips.slice";
import { MyStayTipCard } from "../../domain-common/my-stay-tip-card";
import { useCmsClient } from "../../api/cms-client/use-cms-client";
import { useEffect } from "react";
import { getI18nSelectedLanguage } from "../lang-utils";

const UNAUTHENTICATED_TAG = "UNAUTHENTICATED";
const AUTH_TAG = "AUTH";
const DIRECT_LINK_ONLY_TAG = "DIRECT_LINK_ONLY";

interface UseArticlesProps {
  magicId: string;
  propertyId: string;
  authEnabled?: boolean;
  authenticated?: boolean;
  isTravelBuddy: boolean;
  serviceCodes: Array<string> | null;
  unitGroupId: string;
}

export const useArticles = ({
  magicId,
  propertyId,
  authEnabled,
  authenticated,
  isTravelBuddy,
  serviceCodes,
  unitGroupId
}: UseArticlesProps): { tips: MyStayTipCard[] } => {
  const dispatch = useAppDispatch();
  const prismic = useCmsClient();

  // Check if there are ordered articles from article_ordering document type in prismic
  const { items: orderedArticles } = useArticleOrderingCms(magicId, propertyId);

  // If there are no ordered articles, get all articles
  const allArticles = useSelector((state: RootState) =>
    !orderedArticles || !orderedArticles?.length
      ? selectAllMyStayTipCards(state, propertyId)
      : undefined
  );

  const articles: MyStayTipCard[] = orderedArticles?.length ? orderedArticles : allArticles;

  //fetch articles
  useEffect(() => {
    const lang = getI18nSelectedLanguage();

    const promise = dispatch(
      fetchMyStayTips({
        prismic,
        lang
      })
    );
    return () => {
      promise.abort();
    };
  }, [dispatch, prismic]);

  // Filter articles based on user authentication status and tags - c/p from the old implementation
  const filteredArticles = articles
    .filter((card) => {
      const isAuthCard = card.tags?.some((tag) => tag === AUTH_TAG);
      const isUnauthenticatedCard = card.tags?.some((tag) => tag === UNAUTHENTICATED_TAG);
      const isDirectLinkOnly = card.tags?.some((tag) => tag === DIRECT_LINK_ONLY_TAG);
      const isServiceCard = card.tags?.some((tag) => tag === "SERVICE");
      const isUnitGroupCard = card.tags?.some((tag) => tag === "UNIT_GROUP");

      if (isDirectLinkOnly) {
        // still visible when directly landing e.g. on http://localhost.simone-dev:3000/magic/vmbEGYJO0RXJJ27Y8ZUwvGmerxN57Vgy/portal/my-stay/articles/privacy_mode
        return false;
      }

      // card tagged with UNAUTHENTICATED should just be displayed if user is not authenticated
      if (isUnauthenticatedCard && authenticated) {
        return false;
      }

      // card tagged with AUTH should just be displayed if auth is enabled and not travel buddy
      if (isAuthCard && !authEnabled) {
        return false;
      } else if (isAuthCard && isTravelBuddy) {
        return false;
      }

      // card tagged with a service code
      if (isServiceCard) {
        return card.tags?.some((tag) => serviceCodes && serviceCodes.includes(tag));
      }

      // card tagged with a unit_group
      if (isUnitGroupCard) {
        return card.tags?.some((tag) => tag === unitGroupId);
      }

      return true;
    })
    .filter((card) => {
      return !(card.propertyId && card.propertyId !== propertyId);
    });

  return {
    tips: filteredArticles
  };
};
