import { useCallback, useEffect, useMemo, useState } from "react";
import { useMewsPayNewCard } from "./mews-pay/use-mews-pay-new-card";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { selectPaymentSlice } from "./payment.slice";
import { useSelector } from "react-redux";
import { usePayWithTokenizedCard } from "./pay-with-tokenized-card/use-pay-with-tokenized-card";
import { useSearchParams } from "../additional-services/use-search-params";
import { PaymentOption, SELECTED_PAYMENT_OPTION } from "./payment-constants";
import { getKioskTerminalId, isKioskMode, isTerminalOnly } from "../../util/kiosk-mode";
import { useKioskConfig } from "../../util/hooks/use-configuration";
import { PaymentAccount } from "../../domain-common/payment-account";
import { usePayLater } from "./pay-later/use-pay-later";
import { CONFIRMATION_TYPE_KEY, ConfirmationType } from "../../components";
import { usePms } from "../../util/hooks/use-pms";
import { useGetPaymentMethodsQuery } from "../../graphql/queries/GetPaymentMethods.generated";
import { transformPaymentMethodType } from "../../graphql/transform/transform-payment";
import { generateUUID } from "../../util/data";

interface UseHandlePaymentOptionArg {
  magicId?: string;
  magicToken?: string;
  returnUrl: string;
  onSuccessPayment: (bookingMagicId?: string) => void;
  paymentAccount?: PaymentAccount;
  skipPaymentType?: PaymentOption;
}
function nullPredicate<Tvalue>(value: Tvalue | null | undefined): value is Tvalue {
  return value !== null && value !== undefined;
}

export const useHandlePaymentOption = ({
  returnUrl,
  onSuccessPayment,
  magicId,
  magicToken,
  paymentAccount,
  skipPaymentType
}: UseHandlePaymentOptionArg) => {
  const [paymentOptionsV1, setPaymentOptionsV1] = useState<Array<PaymentOption>>([]);
  const payWithNewCard = useMewsPayNewCard({
    returnUrl,
    magicId,
    magicToken
  });

  const { payByPaymentAccountOnClick } = usePayWithTokenizedCard({
    magicId: magicId ?? "",
    onSuccessPayment
  });

  const { setParams, params } = useSearchParams({});

  const { paymentDTO } = useSelector(selectPaymentSlice);

  const { isRESTVersion } = useApiVersion();
  const { pms } = usePms();
  const { paymentByTerminalId, paymentByTerminalOnly } = useKioskConfig();
  const payByPaymentAccount = useMemo(
    () => paymentAccount && paymentDTO.adyenPrice.amount > 0,
    [paymentAccount, paymentDTO.adyenPrice.amount]
  );
  const skipPayment = usePayLater({
    magicId: magicId || "",
    magicToken: magicToken || "",
    paymentDTO,
    onSuccessPayment
  });

  // We need useMemo otherwise it will be generated every time
  const uuid = useMemo(() => generateUUID(), []);

  const { data } = useGetPaymentMethodsQuery(
    {
      magicId,
      shopperReference: magicId ?? uuid
    },
    { skip: isRESTVersion }
  );

  const paymentOptionsV2: PaymentOption[] | undefined = useMemo(() => {
    return (
      data?.GetPaymentMethodsMagicId.map((paymentMethod) =>
        transformPaymentMethodType(paymentMethod.type)
      ).filter(nullPredicate) ?? []
    );
  }, [data]);

  useEffect(() => {
    if (isRESTVersion) {
      const newPaymentMethod = PaymentOption.ADYEN_WIDGET;

      const payWithArOption = payByPaymentAccount ? [PaymentOption.APALEO_TOKENIZED_CARD] : [];

      const skipPaymentOption =
        skipPaymentType &&
        params[CONFIRMATION_TYPE_KEY] !== ConfirmationType.SELF_POURING_STATION_SHOP &&
        params[CONFIRMATION_TYPE_KEY] !== ConfirmationType.BOX_SHOP &&
        params[CONFIRMATION_TYPE_KEY] !== ConfirmationType.OPEN_BALANCE
          ? [skipPaymentType]
          : [];

      const terminalPay =
        isKioskMode() && (getKioskTerminalId() || paymentByTerminalId)
          ? [PaymentOption.TERMINAL_PAYMENT]
          : [];

      if (isTerminalOnly() || paymentByTerminalOnly) {
        setPaymentOptionsV1(terminalPay);
      } else {
        setPaymentOptionsV1([
          ...skipPaymentOption,
          newPaymentMethod,
          ...payWithArOption,
          ...terminalPay
        ]);
      }
    }
  }, [
    isRESTVersion,
    setPaymentOptionsV1,
    payByPaymentAccount,
    pms,
    paymentByTerminalId,
    paymentByTerminalOnly,
    skipPaymentType,
    params
  ]);

  const handlePaymentOption = useCallback(
    ({ paymentOption }: { paymentOption: PaymentOption }) => {
      switch (paymentOption) {
        case PaymentOption.NEW_CARD:
          return payWithNewCard();
        case PaymentOption.ADYEN_WIDGET:
          setParams({
            [SELECTED_PAYMENT_OPTION]: PaymentOption.ADYEN_WIDGET
          });
          return Promise.resolve();
        case PaymentOption.APALEO_TOKENIZED_CARD:
          return payByPaymentAccountOnClick();
        case PaymentOption.PAY_AT_CHECKOUT:
        case PaymentOption.PAY_AT_CHECKIN:
          return skipPayment();
        case PaymentOption.TERMINAL_PAYMENT:
          setParams({
            [SELECTED_PAYMENT_OPTION]: PaymentOption.TERMINAL_PAYMENT
          });
          return Promise.resolve();

        default:
          return Promise.reject(`Payment ${paymentOption}  is not supported yet.`);
      }
    },
    [payWithNewCard, payByPaymentAccountOnClick, setParams, skipPayment]
  );

  const paymentOptions = useMemo(
    () => (isRESTVersion ? paymentOptionsV1 : paymentOptionsV2),
    [isRESTVersion, paymentOptionsV1, paymentOptionsV2]
  );

  return { handlePaymentOption, paymentOptions };
};
