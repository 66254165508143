import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type InitiatePaymentDetailsMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  paymentDetailsRequest: Types.WidgetPaymentDetailsRequest;
}>;

export type InitiatePaymentDetailsMutation = {
  __typename?: "Mutation";
  PaymentDetails?: { __typename?: "Void"; None?: string | null } | null;
};

export const InitiatePaymentDetailsDocument = `
    mutation InitiatePaymentDetails($magicId: String!, $paymentDetailsRequest: WidgetPaymentDetailsRequest!) {
  PaymentDetails(magicId: $magicId, paymentDetails: $paymentDetailsRequest) {
    None
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    InitiatePaymentDetails: build.mutation<
      InitiatePaymentDetailsMutation,
      InitiatePaymentDetailsMutationVariables
    >({
      query: (variables) => ({ document: InitiatePaymentDetailsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useInitiatePaymentDetailsMutation } = injectedRtkApi;
