import { BaseApiClient, JSONApiResponse } from "@likemagic-tech/sv-magic-library";

const BASE_URL = "/api/guest-journey-service/promo-codes";

class PromoCodeApiClient extends BaseApiClient {
  async validate(data: { promoCode: string }, init?: RequestInit) {
    const response = await this.fetchApi(BASE_URL.concat("/validate"), {
      ...init,
      method: "POST",
      body: JSON.stringify(data)
    });
    return new JSONApiResponse(response).value();
  }
}

export const PromoCodeAPI = new PromoCodeApiClient();
