import { useCallback, useMemo } from "react";
import { getYesNoModalArg } from "../../global-modal/global-modal-util";
import { Reservation } from "../../../domain-common/reservation";
import { Person } from "../../../domain-common/person";
import { Actor, BookingOverview } from "../../../domain-common/booking-overview";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useAuth, useGlobalModal, UserInfo } from "@likemagic-tech/sv-magic-library";
import { isReservation, isTravelBuddy } from "../../../util/flow";
import { Guest } from "../../../domain-common/guest";
import { useNavigate } from "react-router-dom";
import { MagicPersonEnum } from "../../portal/travel-buddy-utils";
import { generateBookingOverviewReservationsURL } from "../booking-overview-navigation";
import { GuestFlowCheckpoint } from "../../guest-flow/checkpoint/guest-flow-checkpoint";
import { getNextFlowUrl } from "../../guest-flow/guest-flow-sequence";
import { useUserInfo } from "./use-user-info";
import { generateFastGuestFlowLink } from "../../guest-flow/pages/fast-guest-flow.page";
import { isBookingOnBehalfOfMyself } from "../../../util/reservation";
import { PatchGuestFlowStepProps } from "../../reservation-provider/use-update-reservation";
import { SubUpdate } from "../../reservation-provider/sub-update";
import {
  emptyReservationExtras,
  ReservationExtras
} from "../../../domain-common/reservation-extras";

interface UsePersonaPageFormHandlerProps {
  reservation?: Reservation;
  currentMagicId: string;
  actor?: Actor;
  patchGuestFlowStep: (props: PatchGuestFlowStepProps) => Promise<any>;
  bookingOverviewItemId: string;
  bookingOverview?: BookingOverview;
  initialReservation?: Reservation;
}

export type PersonalFormData = {
  person: Person;
  additionalGuests?: Guest[];
  maxCompanions?: number;
  password?: string;
  extras: ReservationExtras;
};

// exported so it can be tested...
export function shouldShowResendNotificationDialog(
  values: PersonalFormData,
  reservation: Reservation
) {
  return (
    isReservation(reservation) &&
    !isBookingOnBehalfOfMyself(values) &&
    reservation.flowState?.notificationSent &&
    ((reservation.primaryGuest.email && values.person.email !== reservation.primaryGuest.email) ||
      (reservation.primaryGuest.phone && values.person.phone !== reservation.primaryGuest.phone))
  );
}

export function shouldShowAccountEmailChangeConfirmDialog(
  authenticated: boolean | undefined,
  userInfo: UserInfo | undefined,
  values: PersonalFormData,
  reservation: Reservation,
  bookingOverview?: BookingOverview
) {
  return (
    authenticated &&
    isReservation(reservation) &&
    bookingOverview &&
    bookingOverview.keycloakUUID &&
    // Admins should know what they're doing and don't need the popup (unless it's for their own reservation)
    bookingOverview.keycloakUUID === userInfo?.sub &&
    reservation.primaryGuest.email &&
    isBookingOnBehalfOfMyself(values) &&
    // was already set to MYSELF, and email changed
    ((bookingOverview.keycloakUUID === reservation.keycloakUUID &&
      values.person.email !== reservation.primaryGuest.email) ||
      // just changed to 'MYSELF'
      (reservation.extras?.bookingOnBehalfOf !== values.extras.bookingOnBehalfOf &&
        userInfo &&
        userInfo.email &&
        values.person.email !== userInfo.email))
  );
}

export const usePersonalPageFormHandler = ({
  reservation,
  actor,
  currentMagicId,
  patchGuestFlowStep,
  bookingOverview,
  bookingOverviewItemId
}: UsePersonaPageFormHandlerProps) => {
  const navigate = useNavigate();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { getIsAuthenticated } = useAuth();
  const { userInfo } = useUserInfo();

  const { open: openModal } = useGlobalModal();

  const personType: MagicPersonEnum = useMemo(
    () =>
      actor === Actor.TRAVEL_BUDDY ? MagicPersonEnum.TRAVEL_BUDDY : MagicPersonEnum.PRIMARY_GUEST,
    [actor]
  );

  const accountEmailChangeConfirmModalArg = getYesNoModalArg(
    t(`modals__confirm_email_change_title`),
    t(`modals__confirm_email_change_content`),
    t(`buttons__yes`),
    t(`buttons__no`)
  );
  const resendNotificationConfirmModalArg = getYesNoModalArg(
    t(`modals__resend_notification_change_title`),
    t(`modals__resend_notification_change_content`),
    t(`buttons__yes`),
    t(`buttons__no`)
  );
  const handleSubmit = useCallback(
    async (
      values: PersonalFormData,
      forceSendNotification?: boolean,
      redirectToGuestFlow?: boolean
    ) => {
      if (!reservation) {
        console.warn("Reservation not yet initialised");
        return;
      }
      const showResendNotificationConfirmDialog = shouldShowResendNotificationDialog(
        values,
        reservation
      );

      const showAccountEmailChangeConfirmDialog = shouldShowAccountEmailChangeConfirmDialog(
        getIsAuthenticated(),
        userInfo,
        values,
        reservation,
        bookingOverview
      );
      try {
        let isResendModalConfirmed: boolean | null = false;
        if (showResendNotificationConfirmDialog) {
          isResendModalConfirmed = await openModal(resendNotificationConfirmModalArg);
        }

        if (showAccountEmailChangeConfirmDialog) {
          const result = await openModal(accountEmailChangeConfirmModalArg);
          if (!result) {
            return;
          }
        }

        let valuesToPatch: Reservation = {
          ...reservation,
          [personType]: values.person,
          extras: {
            ...values.extras
          }
        };
        if (values.additionalGuests) {
          valuesToPatch.additionalGuests = values.additionalGuests;
        }

        const sendNotification = !!forceSendNotification || !!isResendModalConfirmed;

        await patchGuestFlowStep({
          actor,
          checkpoint: isBookingOnBehalfOfMyself(values)
            ? GuestFlowCheckpoint.PERSONAL_DATA
            : undefined,
          files: undefined,
          reservationValues: valuesToPatch,
          sendNotification,
          subUpdate: isTravelBuddy(valuesToPatch)
            ? SubUpdate.PERSONAL_DATA
            : SubUpdate.PERSONAL_DATA_WITH_SECONDARY_GUESTS,
          initialReservationValues: reservation
        });

        if (!redirectToGuestFlow) {
          const goToReservationsList = generateBookingOverviewReservationsURL(
            currentMagicId,
            bookingOverviewItemId
          );

          navigate(
            goToReservationsList,
            { state: { sendNotification } }
              ? {
                  state: {
                    successfulInvite: true,
                    firstName: values.person.firstName,
                    lastName: values.person.lastName
                  }
                }
              : {}
          );
        } else {
          navigate(
            reservation.flowState.fastCheckinAvailable
              ? generateFastGuestFlowLink(reservation.magicId)
              : getNextFlowUrl(GuestFlowCheckpoint.PERSONAL_DATA, valuesToPatch)
          );
        }
      } catch (e) {
        console.error("Update guest personal data went wrong", e);
        return Promise.reject(e);
      }
    },
    [
      reservation,
      getIsAuthenticated,
      userInfo,
      bookingOverview,
      personType,
      patchGuestFlowStep,
      actor,
      openModal,
      resendNotificationConfirmModalArg,
      accountEmailChangeConfirmModalArg,
      currentMagicId,
      bookingOverviewItemId,
      navigate
    ]
  );

  const initialValues: PersonalFormData | null = useMemo(() => {
    if (!reservation) {
      return null;
    }
    let result: PersonalFormData = {
      person: reservation[personType],
      extras: reservation.extras || emptyReservationExtras()
    };
    if (isReservation(reservation)) {
      result.additionalGuests = reservation.additionalGuests;
      result.maxCompanions = reservation.maxCompanions;
    }
    return result;
  }, [reservation, personType]);

  return {
    handleSubmit,
    personType,
    initialValues
  };
};
