import React, { FC, useCallback } from "react";
import { SubmitButton } from "../../components";
import { ShopFilter } from "../shop/components/shop-filter";
import { Box, Grid, Theme } from "@mui/material";
import { DynamicShopItem } from "./components/dynamic-shop-item";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { RootState, useAppDispatch } from "../../state/store";
import { useShopCmsTranslation } from "../../state/shop-cms/use-shop-cms-translation";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { responsiveVariables } from "../../components/layouts/hooks/use-is-mobile";
import { Reservation } from "../../domain-common/reservation";
import { useDynamicShopItems } from "./use-dynamic-shop-items";
import { ShopType } from "../../graphql/generated/graphql";
import {
  clearDynamicShopFilter,
  selectAllShopCartPerType,
  selectDynamicShopSelectedFilters,
  toggleDynamicShopFilter
} from "./dynamic-shop.slice";
import { generatePortalDynamicShopBillOverviewUrl } from "../../util/routing";
import { useNavigate } from "react-router-dom";

interface DynamicShopProps {
  reservation: Reservation;
  shopType: ShopType;
  onNext?: () => void;
}

const useStyles = makeStyles()(({ breakpoints }: Theme) => ({
  container: {
    maxHeight: "calc(100vh - 436px)",
    overflowY: "auto",
    [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
      maxHeight: "calc(100vh - 464px)"
    }
  }
}));

const isFlowTypeShop = (type: ShopType) => [ShopType.MinibarCheckoutFlow].includes(type);

export const DynamicShop: FC<DynamicShopProps> = ({ reservation, onNext, shopType }) => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dyanamicShopItemsRaw = useSelector((state: RootState) =>
    selectAllShopCartPerType(state, shopType)
  );

  const { categories } = useShopCmsTranslation(reservation.propertyId, shopType);
  const selectedFilters = useSelector(selectDynamicShopSelectedFilters);

  const { filteredItems } = useDynamicShopItems({
    magicId: reservation.magicId,
    propertyId: reservation.propertyId,
    shopType
  });

  const handleSubmit = useCallback(() => {
    navigate(generatePortalDynamicShopBillOverviewUrl(reservation.magicId, shopType));
  }, [navigate, reservation.magicId, shopType]);

  return (
    <>
      <Box mb={2}>
        <ShopFilter
          filters={selectedFilters}
          availableFilters={categories}
          onFilterClick={(f) => {
            if (f?.id) {
              dispatch(toggleDynamicShopFilter(f ? { filterId: f.id } : undefined));
            } else {
              dispatch(clearDynamicShopFilter());
            }
          }}
        />
      </Box>
      <Grid container spacing={2} className={classes.container}>
        {filteredItems
          .map((item) => ({ ...item, quantity: 0 }))
          .map((item) => (
            <Grid item key={item.serviceId} xs={12} md={6}>
              <DynamicShopItem
                propertyId={reservation.propertyId}
                item={item}
                shopType={shopType}
              />
            </Grid>
          ))}
      </Grid>

      <SubmitButton
        label={
          isFlowTypeShop(shopType) && dyanamicShopItemsRaw.length === 0
            ? t("buttons__skip")
            : t("buttons__next")
        }
        onClick={() => (!!onNext ? onNext() : handleSubmit())}
        disabled={!isFlowTypeShop(shopType) && dyanamicShopItemsRaw.length === 0}
      />
    </>
  );
};
