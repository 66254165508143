export enum PortalPagesEnum {
  MY_STAY = "MY_STAY",
  BOOKING = "BOOKING",
  PROFILE = "PROFILE",
  EVENTS = "EVENTS",
  PAYMENT = "PAYMENT",
  PAYMENT_REDIRECT = "PAYMENT_REDIRECT",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS"
}

export const PortalPageToPath: Record<PortalPagesEnum, string> = {
  [PortalPagesEnum.BOOKING]: "/booking",
  [PortalPagesEnum.MY_STAY]: "/my-stay",
  [PortalPagesEnum.PROFILE]: "/profile",
  [PortalPagesEnum.EVENTS]: "/events",
  [PortalPagesEnum.PAYMENT]: "/payment",
  [PortalPagesEnum.PAYMENT_REDIRECT]: "/payment-redirect",
  [PortalPagesEnum.PAYMENT_SUCCESS]: "/payment-success",
  [PortalPagesEnum.CHECKOUT_SUCCESS]: "/checkout-success"
};
