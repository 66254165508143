import React from "react";
import { Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { usePropertyCmsData } from "../../../state/property-cms/use-property-cms-data";
import { DisplayCmsSvg } from "../../../components";
import { Price } from "../../../domain-common/price";
import { CarouselGallery } from "../../../components/molecules/carousel-gallery/carousel-gallery";
import { PriceWithAdditional } from "../../../components/atoms/price-with-additional/price-with-additional";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";

interface SearchPropertyCardProps {
  propertyId: string;
  pricePerNight: Price;
  priceTotal: Price;
  numberOfNights: number;
}

const useStyles = makeStyles()((theme) => ({
  paragraph: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "left",
    paddingBottom: theme.spacing(1.5)
  },
  icon: {
    width: 30,
    paddingRight: theme.spacing(0.5)
  },
  rate: {
    height: "70px",
    fontSize: "20px",
    backgroundColor: theme.palette.grey[300]
  },
  ratePrice: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const SearchPropertyCard: React.FC<React.PropsWithChildren<SearchPropertyCardProps>> = ({
  propertyId,
  pricePerNight,
  priceTotal,
  numberOfNights
}) => {
  const { classes } = useStyles();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const isMobile = useIsMobile();
  const { propertyImages, propertyLocationIcon, propertyName, propertyAddress, propertyCardItems } =
    usePropertyCmsData(propertyId);

  return (
    <Card>
      <Grid container flexDirection="row">
        <Grid item xs={12} md={5}>
          {propertyImages && <CarouselGallery items={propertyImages} />}
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            minHeight={isMobile ? 0 : 300}
          >
            <Grid item xs={12}>
              <CardContent>
                <Heading2 className={classes.paragraph}>{propertyName}</Heading2>
                <Paragraph className={classes.paragraph}>
                  <DisplayCmsSvg url={propertyLocationIcon?.url} className={classes.icon} />
                  {propertyAddress}
                </Paragraph>
                {propertyCardItems &&
                  propertyCardItems.map((item) => (
                    <Paragraph key={item.card_item_type} className={classes.paragraph}>
                      <DisplayCmsSvg url={item.image.url} className={classes.icon} />
                      {item.card_item_type}
                    </Paragraph>
                  ))}
              </CardContent>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={1}
                className={classes.rate}
              >
                <PriceWithAdditional
                  pricePerNight={pricePerNight}
                  numberOfNights={numberOfNights}
                  suffixPerNight={tCommon("labels__price_suffix")}
                  priceTotal={priceTotal}
                  prefixPerNight={tCommon("labels__price_prefix")}
                  prefixTotal={tCommon("labels__price_prefix")}
                  suffixTotal={
                    numberOfNights
                      ? `${numberOfNights} ${tCommon("labels__total_price_suffix")}`
                      : tCommon("labels__total_price_suffix")
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
