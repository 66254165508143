import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function ArrowRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>
          {`.cls-1,.cls-2{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}.cls-2{fill-rule:evenodd;}`}
        </style>
      </defs>
      <title>arrow-right</title>
      <line className="cls-1" x1="0.75" y1="12" x2="23.25" y2="12" />
      <polyline className="cls-2" points="12.75 22.5 23.25 12 12.75 1.5" />
    </SvgIcon>
  );
}
