import { useCallback, useMemo } from "react";
import { useCmsPropertySpecificData } from "../cms/use-cms-per-property-data";

export const usePropertyTextCmsData = (
  prismicKey: string,
  propertyId?: string,
  textId?: string,
  description?: string
) => {
  const cmsData = useCmsPropertySpecificData(propertyId);

  const prismicKeyValue = cmsData?.data?.[prismicKey];

  const findTextOutput = useCallback(
    (newTextId?: string) => {
      return (
        prismicKeyValue?.find(
          (item: { id: string; text: string; details?: string }) => item.id === newTextId
        ) || { id: newTextId, text: !!description ? description : newTextId }
      );
    },
    [description, prismicKeyValue]
  );
  const textOutput: {
    id: string;
    text: string;
    details?: string;
  } = useMemo(() => {
    return findTextOutput(textId);
  }, [textId, findTextOutput]);

  return {
    textOutput,
    findTextOutput
  };
};
