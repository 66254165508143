import { FC, useMemo } from "react";
import { MapPreviewGoogleJs } from "./map-preview-google-js";
import { Box, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAllPropertyLocations } from "../../state/property-cms/property-cms.slice";
import { MapPreviewGoogleEmbed } from "./map-preview-google-embed";
import { useDebounce } from "../../util/hooks/use-debounce";

interface MapPreviewProps {
  selectedPropertyId?: string;
  onPinClick?: (propertyId: string) => () => void;
}

export const FixMapWrapper = styled(Box)(() => ({
  position: "fixed",
  width: "44%",
  height: "70vh",
  maxWidth: 576
}));

export const MapPreview: FC<MapPreviewProps> = ({ selectedPropertyId, onPinClick }) => {
  const locations = useSelector(selectAllPropertyLocations);

  const debouncedLocations = useDebounce(locations, 500);

  const pins = useMemo(() => {
    return debouncedLocations
      .filter((item) =>
        selectedPropertyId ? selectedPropertyId?.split(",").includes(item.propertyId || "") : true
      )
      .map((data) => ({
        lat: data.latitude,
        lng: data.longitude,
        label: data.name ?? "",
        address: data.address,
        iconUrl: data.mapPin,
        propertyId: data.propertyId,
        onClick: () => onPinClick?.(data.propertyId)()
      }))
      .filter((item) => item.lat && item.lng);
  }, [selectedPropertyId, debouncedLocations, onPinClick]);

  if (pins.length === 1) {
    return <MapPreviewGoogleEmbed pin={pins[0]} />;
  }

  if (pins.length > 1) {
    return <MapPreviewGoogleJs markerList={pins} />;
  }

  return null;
};
