import { FC, useCallback, useMemo } from "react";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { generatePortalPaymentSuccessUrl, generatePortalPaymentUrl } from "../../../util/routing";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentRedirect } from "../../payment/payment-redirect";
import { clearCart } from "../../shop/store/cart.slice";
import { clearAdditionalServicesCart } from "../../additional-services/service-selection/additional-services-cart.slice";
import { useDispatch } from "react-redux";
import { clearPaymentResponses, clearPaymentState } from "../../payment/payment.slice";
import { clearDynamicShopCart } from "../../dynamic-shop/dynamic-shop.slice";

export const PortalPaymentRedirectPage: FC = () => {
  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const cleanedURLSearchParams: URLSearchParams = useMemo(() => {
    const cleanedURLSearchParams = new URLSearchParams(search);
    cleanedURLSearchParams.delete("redirectResult");

    return cleanedURLSearchParams;
  }, [search]);

  const onSuccess = useCallback(() => {
    dispatch(clearCart({ magicId }));
    dispatch(clearAdditionalServicesCart());
    dispatch(clearPaymentResponses());
    dispatch(clearPaymentState());
    dispatch(clearDynamicShopCart());

    navigate({
      pathname: generatePortalPaymentSuccessUrl(magicId),
      search: cleanedURLSearchParams.toString()
    });
  }, [cleanedURLSearchParams, navigate, magicId, dispatch]);

  const onFailed = useCallback(() => {
    navigate({
      pathname: generatePortalPaymentUrl(magicId),
      search: cleanedURLSearchParams.toString()
    });
  }, [cleanedURLSearchParams, navigate, magicId]);

  return <PaymentRedirect onSuccess={onSuccess} onFailed={onFailed} />;
};
