import { Charge } from "../domain-common/payment";
import { Reservation } from "../domain-common/reservation";
import { ReservationStatus } from "../domain-common/reservation-status";
import { isTravelBuddy } from "./flow";
import { GuestFlowCheckpoint } from "../features/guest-flow/checkpoint/guest-flow-checkpoint";
import { BookingOnBehalfOf } from "../domain-common/person";
import { PersonalFormData } from "../features/booking-overview/hooks/use-personal-page-form-handler";
import { addDays, isBefore, isSameDay } from "date-fns";

export const isReservationPayable = (reservation: Reservation): boolean =>
  reservation.totalAllowedPayment?.amount > 0;

export const sortCharges = (a: Charge, b: Charge) => a.name?.localeCompare(b.name);

export function homeAddressDebitor(reservation: Reservation) {
  return {
    address: reservation.primaryGuest.address,
    company: {
      name: "",
      taxId: ""
    },
    name: reservation.primaryGuest.lastName,
    firstName: reservation.primaryGuest.firstName,
    reference: "",
    email: "",
    title: reservation.primaryGuest.title,
    type: ""
  };
}

export const isReservationReadyToRequestPrivateKeys = (reservation: Reservation) =>
  (reservation.accessibleDoors === null ||
    (reservation.accessibleDoors?.length > 0 && !isTravelBuddy(reservation))) &&
  reservation.status === ReservationStatus.CONFIRMED;

export const calculateCurrentPageForTermsAndConditionPageBasedOnServiceAvailability = (
  reservation: Reservation,
  additionalServicesAvailable: boolean
): GuestFlowCheckpoint => {
  // we must avoid service page for travel buddy flow, because it does not contain additional services page
  if (isTravelBuddy(reservation)) {
    return GuestFlowCheckpoint.CONFIRMATION;
  }

  return additionalServicesAvailable ? GuestFlowCheckpoint.ADDRESS : GuestFlowCheckpoint.SERVICES;
};

export const isRequestKeyAccessibleWithOpenBalance = (
  isOpenBalance: boolean,
  payAtCheckout: boolean,
  status: ReservationStatus
) => {
  if (status === ReservationStatus.IN_HOUSE || payAtCheckout) {
    return true;
  } else {
    return !isOpenBalance;
  }
};

export const isBookingOnBehalfOfMyself = (reservation: Reservation | PersonalFormData): boolean =>
  BookingOnBehalfOf.MYSELF === reservation?.extras?.bookingOnBehalfOf;

export const isBookingOnBehalfOfOthers = (reservation: Reservation | PersonalFormData): boolean =>
  BookingOnBehalfOf.OTHERS === reservation?.extras?.bookingOnBehalfOf;

export const isCheckInAvailable = (arrival: string, currentTime: string) => {
  const arrivalTimeMinInMilliseconds = new Date(arrival).getTime();

  //TODO https://likemagic.atlassian.net/browse/LM-1704
  // Use correct timestamp from tenant config
  const arrivalTimeMaxInMilliseconds = addDays(arrivalTimeMinInMilliseconds, 1).setUTCHours(
    4,
    0,
    0,
    0
  );

  return (
    (isSameDay(new Date(currentTime), arrivalTimeMinInMilliseconds) ||
      isSameDay(new Date(currentTime), arrivalTimeMaxInMilliseconds)) &&
    isBefore(new Date(currentTime), arrivalTimeMaxInMilliseconds)
  );
};
