import React from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { BoxProps } from "@mui/material/Box/Box";
import { responsiveVariables } from "../layouts/hooks/use-is-mobile";

export interface RoundBoxProps extends BoxProps {
  edgesColor?: string;
  hasShadow?: boolean;
}

const useStyles = makeStyles<RoundBoxProps>()(({ palette, breakpoints, shape }: Theme, props) => ({
  root: {
    position: "relative",
    [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
      borderRadius: `${shape.borderRadius * 3}px ${shape.borderRadius * 3}px 0px 0px`,
      boxShadow: props.hasShadow ? "0px 4px 8px rgba(197, 185, 172, 0.06)" : "",
      backgroundColor: palette.background.paper,
      "&::before": {
        backgroundColor: props.edgesColor ? props.edgesColor : palette.primary.main,
        content: '""',
        display: "block",
        height: 24,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: -1
      }
    }
  }
}));

export const RoundBox: React.FC<React.PropsWithChildren<RoundBoxProps>> = (props) => {
  const { children, edgesColor, className, hasShadow, ...otherProps } = props;
  const { classes } = useStyles(props);
  return (
    <Box className={`${classes.root} ${className}`} {...otherProps}>
      {children}
    </Box>
  );
};
