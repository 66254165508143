import { formatRange, Heading3, Paragraph, useAuth } from "@likemagic-tech/sv-magic-library";
import { Box, Collapse, Grid } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";
import { generatePortalEventDetailsUrl } from "../../../util/routing";
import { HelmetTitle } from "../../gtm/helmet-title";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { MyStayEventList } from "../../my-stay-events/my-stay-event-list";
import { MyStayFooter } from "../components/my-stay-footer";
import { MyStayTipList } from "../components/my-stay-tip-list";
import { useSetupMagicPageTopNavButtons } from "../hooks/use-setup-magic-page-top-nav-buttons";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { isTravelBuddy } from "../../../util/flow";
import { PublicTransportTicketTipItem } from "../components/fixed-tip-items/public-transport-ticket-tip-item";
import { InstallAppTipItem } from "../components/fixed-tip-items/install-app-tip-item";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { getAllPaidServices } from "../../additional-services/service-selection/additional-services.util";
import { WiFiCredentialsTipItem } from "../components/fixed-tip-items/wifi-credentials-tip-item";
import { MyStayNavigation } from "../components/my-stay-navigation";
import { useArticles } from "../../../util/hooks/use-articles";
import { MyStayHero } from "../components/my-stay-hero";
import { Notification } from "../../../components";
import { ReservationChip } from "../../../components/molecules/reservation-chip";
import { GuestFlowCallToAction } from "../../../components/organism/guest-flow-call-to-action/guest-flow-call-to-action";
import { getPersonMagicPerson } from "../travel-buddy-utils";
import { usePropertyById } from "../../property/use-property-by-id";
import { useBanner } from "../../banner/use-banner";
import { useTheme } from "@mui/styles";
import { makeStyles } from "tss-react/mui";
import { useUnitGroupCmsData } from "../../../state/property-cms/use-unit-group-cms-data";

const useStyles = makeStyles()(() => ({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0
  },
  chipBox: {
    textAlign: "center"
  },
  error: {
    width: "100%"
  }
}));
export const MyStayPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { magicId } = useMagicIdParams();
  const { authenticated } = useAuth();
  const { authEnabled } = useFeatureFlags();
  const { reservation } = useReservationContext();
  const { tips } = useArticles({
    magicId: reservation.magicId,
    propertyId: reservation?.propertyId,
    authEnabled,
    authenticated,
    isTravelBuddy: isTravelBuddy(reservation),
    serviceCodes: getAllPaidServices(reservation),
    unitGroupId: reservation.unitGroup.id
  });

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { classes } = useStyles();

  const personType = useMemo(() => getPersonMagicPerson(reservation), [reservation]);
  const property = usePropertyById(reservation?.propertyId);

  const stayRange = useMemo(
    () =>
      formatRange(
        property.timeZone,
        getI18nSelectedLanguage(),
        reservation?.arrival,
        reservation?.departure
      ),
    [reservation?.arrival, reservation?.departure, property.timeZone]
  );
  const { bannerState, onClose } = useBanner();
  const { payAtCheckoutEnabled, checkoutFlowEnabled } = useFeatureFlags();

  const { unitGroupImages, firstStudioImage, unitGroupLabel } = useUnitGroupCmsData(
    reservation?.propertyId,
    reservation?.unit?.unitGroupId || reservation?.unitGroup.id
  );

  useSetupMagicPageTopNavButtons({
    magicId,
    propertyId: reservation.propertyId
  });

  const fixedTips = useMemo(
    () => [
      <InstallAppTipItem key="installApp" />,
      <PublicTransportTicketTipItem
        key="publicTransportTip"
        magicId={magicId}
        propertyId={reservation.propertyId}
        ticketsAvailable={!!reservation.extras?.publicTransportationTickets?.length}
      />,
      <WiFiCredentialsTipItem
        key="wiFiCredentialsTip"
        magicId={magicId}
        propertyId={reservation.propertyId}
        wifiCredentialsAvailable={!!reservation.extras?.wiFiCredentials}
      />
    ],
    [
      reservation.extras?.publicTransportationTickets?.length,
      reservation.extras?.wiFiCredentials,
      magicId,
      reservation.propertyId
    ]
  );

  const images = useMemo(() => {
    const firstImageToList = firstStudioImage ? [firstStudioImage] : [];

    return unitGroupImages.length > 1 ? unitGroupImages : firstImageToList;
  }, [unitGroupImages, firstStudioImage]);

  return (
    <>
      <HelmetTitle suffix="Main" />

      <Grid container>
        <Grid item xs={12} md={6}>
          <MyStayHero images={images}>
            <Grid
              maxWidth="100%"
              container
              alignItems="center"
              justifyContent="center"
              spacing={0}
              flexDirection="column"
              pt={1.5}
            >
              <Grid item xs={12} className={classes.error}>
                <Collapse in={bannerState.isOpen}>
                  <Box m={1}>
                    <Notification
                      type={bannerState.type}
                      title={bannerState.title}
                      errorId={bannerState.errorId}
                      onClose={onClose}
                    />
                  </Box>
                </Collapse>
              </Grid>
              <Grid item>
                <Box px={2}>
                  <Heading3 gutterBottom align="center">
                    {t("labels__welcome")} {reservation[personType]?.firstName}
                  </Heading3>
                </Box>
              </Grid>
              <Grid item>
                <Box px={2}>
                  <Paragraph align="center" sx={{ mb: theme.spacing(2.5) }}>
                    {t("labels__you_are_staying_in")} {""}
                    {reservation?.unit?.name
                      ? [unitGroupLabel, reservation?.unit?.name].join(" ")
                      : unitGroupLabel}
                  </Paragraph>
                </Box>
              </Grid>
              <Grid item>
                <Paragraph gutterBottom sx={{ color: theme.palette.grey[600] }}>
                  {stayRange}
                </Paragraph>
              </Grid>
              <Grid item container direction={"column"}>
                <Box pl={2.5} pr={2.5} className={classes.chipBox} mt={theme.spacing(1.5)}>
                  <ReservationChip
                    reservation={reservation}
                    timeZone={property.timeZone}
                    payAtCheckoutEnabled={payAtCheckoutEnabled}
                    checkoutFlowEnabled={checkoutFlowEnabled}
                  />
                </Box>
                <Box py={2} px={2.5} display="flex" flexDirection="column" textAlign="center">
                  <GuestFlowCallToAction reservation={reservation} />
                </Box>
              </Grid>
            </Grid>
          </MyStayHero>
        </Grid>
        <Grid item xs={12} md={6}>
          <MyStayNavigation reservation={reservation} />
        </Grid>
      </Grid>
      {tips && (
        <Box ml={2.5} mt={2.5}>
          <Paragraph color="textSecondary">{t("labels__tips")}</Paragraph>
        </Box>
      )}
      {tips?.length ? <MyStayTipList tips={tips} fixedTips={fixedTips} /> : undefined}

      <MyStayEventList
        onEventClick={(eventId) => navigate(generatePortalEventDetailsUrl(magicId, eventId))}
        propertyId={reservation?.propertyId}
      />

      <MyStayFooter />
    </>
  );
};
