import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { BookingOverviewPropertiesPage } from "./pages/booking-overview-properties.page";
import { BookingOverviewPages } from "./booking-overview-pages";
import { BookingOverviewPagesToPath } from "./booking-overview-checkpoint-to-path";
import { useSelector } from "react-redux";
import { RoundedFullHeight } from "../../components/layouts/rounded-full-height";
import { useBottomNavbar } from "../../components/organism/bottom-navbar/bottom-navbar-context";
import { selectInitialMagicId } from "../initial-magic-info/initial-magic-id.slice";
import { useSetupMagicPageTopNavButtons } from "../portal/hooks/use-setup-magic-page-top-nav-buttons";
import { useMagicIdParams } from "../magic/use-magic-id-params";
import { NotFoundPage } from "../../components/organism/not-found.page";
import { initBookingOverview } from "./state/booking-overview.slice";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useAppDispatch } from "../../state/store";
import { BookingOverviewItemRouter } from "./booking-overview-item-router";

export const BookingOverviewRouterPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isRESTVersion } = useApiVersion();
  const dispatch = useAppDispatch();
  const { magicId: magicIdParam } = useMagicIdParams();
  const initialMagicId = useSelector(selectInitialMagicId);
  // use initial magic id if it's not undefined
  const magicId = initialMagicId ? initialMagicId : magicIdParam;

  useSetupMagicPageTopNavButtons({ magicId });

  //clean up in case of leaving booking overview section.
  useEffect(
    () => () => {
      if (isRESTVersion) {
        dispatch(initBookingOverview());
      }
    },
    [dispatch, isRESTVersion]
  );

  const { setDisplayBottomNavbar } = useBottomNavbar();

  useEffect(() => {
    setDisplayBottomNavbar(true);
  }, [setDisplayBottomNavbar]);

  return (
    <>
      <RoundedFullHeight subtrahend={0}>
        <Routes>
          <Route
            path={BookingOverviewPagesToPath[BookingOverviewPages.PROPERTIES]}
            element={<BookingOverviewPropertiesPage />}
          />

          <Route
            path={`${BookingOverviewPagesToPath[BookingOverviewPages.BOOKING_RESERVATIONS]}/*`}
            element={<BookingOverviewItemRouter />}
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </RoundedFullHeight>
    </>
  );
};
