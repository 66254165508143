import {
  BookingOverview,
  BookingOverviewItem as BookingOverviewItemDTO
} from "../../../domain-common/booking-overview";
import React, { FC, useCallback, useState } from "react";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { generateBookingOverviewReservationsURL } from "../booking-overview-navigation";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BookingOverviewItem } from "../components/booking-overview-item";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { compareDates, daysBeforeNow, Heading2, useAuth } from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";

import { HelmetTitle } from "../../gtm/helmet-title";
import { useIsMobile } from "../../../components/layouts/hooks/use-is-mobile";
import { useFetchAllBookerOverview } from "../hooks/use-fetch-all-booker-overview";
import { useGetBookingOverviewMagicId } from "../booking-overview-subscription-provider";

enum BookingOverviewTabs {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

const useStyles = makeStyles()(({ spacing }) => ({
  verticallyCenteredLabel: {
    height: `calc(100vh - ${spacing(26)})` // header + footer + tabs + paddings
  },
  tabPanel: {
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2.5)
  }
}));

export const BookingOverviewPropertiesPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { magicId: magicIdFromUrl } = useMagicIdParams();
  const magicId = useGetBookingOverviewMagicId({ magicIdFromUrl });

  const { bookerOverviewsActive, bookerOverviewsInactive } = useFetchAllBookerOverview({ magicId });

  const { getIsAuthenticated } = useAuth();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const isMobile = useIsMobile();
  const { classes } = useStyles();

  const goToBookingOverviewItem = useCallback(
    (bookingOverviewItemId: string) =>
      generateBookingOverviewReservationsURL(magicId, bookingOverviewItemId),
    [magicId]
  );

  const [tabValue, setTabValue] = useState(BookingOverviewTabs.ACTIVE);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newTabValue: BookingOverviewTabs) => {
      setTabValue(newTabValue);
    },
    []
  );

  const getItemPreview = useCallback(
    (bookerOverviews: BookingOverview[], tab?: BookingOverviewTabs) => {
      const items: BookingOverviewItemDTO[] = bookerOverviews
        .flatMap((bo: BookingOverview) => bo.items)
        .sort((itemA, itemB) =>
          compareDates(itemA.arrival, itemB.arrival, tab === BookingOverviewTabs.ACTIVE)
        );

      if (!items.length) {
        return (
          <Grid
            className={classes.verticallyCenteredLabel}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Heading2>{t("labels__no_reservation_selected")}</Heading2>
          </Grid>
        );
      }

      const map = (item: BookingOverviewItemDTO) => {
        const highlighted =
          item.reservations.some((reservation) => reservation.magicId === magicIdFromUrl) ||
          item.travelBuddies.some((travelBuddy) => travelBuddy.magicId === magicIdFromUrl);
        return (
          <BookingOverviewItem
            key={`${item.id}`}
            bookingOverviewItem={item}
            highlighted={highlighted}
            goTo={goToBookingOverviewItem}
          />
        );
      };

      if (tab !== BookingOverviewTabs.ACTIVE) {
        return items.map((item) => map(item));
      }

      // split into 'current' and upcoming
      const withinRange = (item: BookingOverviewItemDTO) => daysBeforeNow(item.arrival) <= 0;
      const currentItems = items.filter((item) => withinRange(item));
      const upcomingItems = items.filter((item) => !withinRange(item));
      let layoutElements: React.ReactElement[] = [];
      if (currentItems.length > 0) {
        layoutElements.push(
          <Heading2 key="current_bookings">{t("labels__current_bookings")}</Heading2>
        );
        currentItems.map((item) => map(item)).forEach((item) => layoutElements.push(item));
      }
      if (upcomingItems.length > 0) {
        layoutElements.push(
          <Heading2 key="upcoming_bookings" mt={layoutElements.length === 0 ? 0 : 3}>
            {t("labels__upcoming_bookings")}
          </Heading2>
        );
        upcomingItems.map((item) => map(item)).forEach((item) => layoutElements.push(item));
      }
      return layoutElements;
    },
    [goToBookingOverviewItem, t, classes.verticallyCenteredLabel, magicIdFromUrl]
  );

  return (
    <FlowTemplate>
      <HelmetTitle suffix="Properties" />
      {getIsAuthenticated() ? (
        <TabContext value={tabValue}>
          <TabList variant={isMobile ? "fullWidth" : "standard"} onChange={handleChange}>
            <Tab
              data-testid="active-tab"
              label={t("labels__active")}
              value={BookingOverviewTabs.ACTIVE}
            />
            <Tab
              data-testid="inactive-tab"
              label={t("labels__inactive")}
              value={BookingOverviewTabs.INACTIVE}
            />
          </TabList>
          <TabPanel value={BookingOverviewTabs.ACTIVE} className={classes.tabPanel}>
            {getItemPreview(bookerOverviewsActive, BookingOverviewTabs.ACTIVE)}
          </TabPanel>
          <TabPanel value={BookingOverviewTabs.INACTIVE} className={classes.tabPanel}>
            {getItemPreview(bookerOverviewsInactive, BookingOverviewTabs.INACTIVE)}
          </TabPanel>
        </TabContext>
      ) : (
        <Box p={3}>{getItemPreview(bookerOverviewsActive, BookingOverviewTabs.ACTIVE)}</Box>
      )}
    </FlowTemplate>
  );
};
