import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { BookedServiceButton } from "../../additional-services/booked-services/booked-service-button";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { DesktopCard, DisplayCmsSvg } from "../../../components";
import { RoundedFullHeight } from "../../../components/layouts/rounded-full-height";
import { Alert, Box, Grid } from "@mui/material";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { BookedServiceTag } from "../../additional-services/booked-services/booked-service-tag";
import { usePropertyById } from "../../property/use-property-by-id";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { generatePortalBoxItemRentUrl } from "../../../util/routing";
import { formatDateTime, isBeforeNow, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useSetupSubpageNavigation } from "../../portal/hooks/use-setup-subpage-navigation";
import { useRentedProducts } from "../hooks/use-rented-products";
import { toGross } from "../../../domain-common/full-price";
import { isTravelBuddy } from "../../../util/flow";
import { getI18nSelectedLanguage } from "../../../util/lang-utils";

export const BoxRentPage: FC = () => {
  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const { rentedItems } = useRentedProducts({
    magicId: reservation.magicId,
    magicToken: reservation.magicToken,
    isTravelBuddy: isTravelBuddy(reservation)
  });

  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const property = usePropertyById(reservation?.propertyId);
  const { magicId } = useMagicIdParams();
  useSetupSubpageNavigation();

  const onClick = (boxId: string) => navigate(generatePortalBoxItemRentUrl(magicId, boxId));
  return (
    <RoundedFullHeight pl={2.5} pr={2.5} pb={2.5}>
      <DesktopCard>
        <Box p={3}>
          <PageHeadingInfo
            title={t("title__box_rent_page")}
            icon={<DisplayCmsSvg url={cmsData?.data?.icon__cart_icon?.url} />}
          />
          <Grid container direction="column" display="grid">
            {rentedItems.map((item, index) => (
              <BookedServiceButton
                key={`service-list-button-${index}`}
                isNextPageAvailable={true}
                onClick={() => onClick(item.boxId)}
                amount={toGross(item.amount)}
                propertyId={reservation.propertyId}
                service={item.service}
              >
                <Grid container flexWrap="wrap" alignItems="center" spacing={1}>
                  <Grid item>
                    <Alert
                      sx={{
                        padding: 0.5,
                        "& .MuiAlert-icon": {
                          marginRight: 0
                        }
                      }}
                      severity={isBeforeNow(item.dueDate, -1) ? "error" : "success"}
                      variant="standard"
                      icon={<> </>}
                    >
                      <Paragraph>
                        {formatDateTime(item.dueDate, getI18nSelectedLanguage(), property.timeZone)}
                      </Paragraph>
                    </Alert>
                  </Grid>

                  <Grid item sx={{ flex: 1 }}>
                    <Grid container spacing={1} alignItems="center">
                      {item.tags.map((serviceTag) => (
                        <Grid item key={`tag-item-${serviceTag}`}>
                          <BookedServiceTag tag={serviceTag} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </BookedServiceButton>
            ))}
          </Grid>
        </Box>
      </DesktopCard>
    </RoundedFullHeight>
  );
};
