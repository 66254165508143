import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "../../components/atoms/loaders/loader";
import { useAppDispatch } from "../../state/store";
import { newVersionDetected } from "./pwa.slice";
import { usePwa } from "./use-pwa";

interface ServiceWorkerUpdaterProps {}

export const ServiceWorkerUpdater: React.FC<
  React.PropsWithChildren<ServiceWorkerUpdaterProps>
> = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const {
    serviceWorkerController,
    installNewVersion,
    isApplicationUpdating,
    isNewVersionDetected
  } = usePwa();

  React.useEffect(() => {
    async function update() {
      await serviceWorkerController.getWb()?.update();
    }

    update();
  }, [pathname, serviceWorkerController]);

  React.useEffect(() => {
    const listener = async () => {
      dispatch(newVersionDetected());
    };
    serviceWorkerController.addWaitingListener(listener);

    return () => {
      serviceWorkerController.removeWaitingListener(listener);
    };
  }, [serviceWorkerController, dispatch]);

  // In case a new version is available instead of showing the dialog we force the update.
  useEffect(() => {
    if (isNewVersionDetected) {
      installNewVersion?.();
    }
  }, [isNewVersionDetected, installNewVersion]);

  if (isApplicationUpdating) {
    return <Loader visible={true} />;
  }

  return null;
};
