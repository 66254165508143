import { FC, useContext } from "react";
import { PmsType, TenantContext, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { PaymentAdyenRedirect } from "./adyen/payment-adyen-redirect";
import { PaymentMewsPayRedirect } from "./mews-pay/payment-mews-pay-redirect";

export interface PaymentRedirectProps {
  onFailed: (magicId: string) => void;
  onSuccess: (magicId: string) => void;
}

export const PaymentRedirect: FC<PaymentRedirectProps> = (props) => {
  const { isRESTVersion } = useApiVersion();
  const { pms } = useContext(TenantContext);

  //TODO we need to have basic payment method in tenant config
  return isRESTVersion || pms !== PmsType.MEWS ? (
    <PaymentAdyenRedirect {...props} />
  ) : (
    <PaymentMewsPayRedirect {...props} />
  );
};
