import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector
} from "@reduxjs/toolkit";
import { ApiError, ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { MagicApi } from "../../api/magic.api";
import { Reservation } from "../../domain-common/reservation";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { Operation } from "fast-json-patch";
import { getPatches } from "../../util/jsonpatch.util";
import { TravelBuddy } from "../../domain-common/travel-buddy";
import { handleSliceError } from "../../util/error-handling";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { MobileSDKInvitationCodeResponse } from "../../api/dto/mobilesdkinvitationcode-response";
import { getTenantHeaders } from "../../api/custom-headers";

type PortalSliceState = {
  reservationCancelStatus: EntityStateStatus;
  patchingTravelBuddyStatus: EntityStateStatus;
  keys: {
    error: any;
    errorId?: string;
    opened: boolean;
  };
  mobileSDKInvitationCode?: MobileSDKInvitationCodeResponse;
};

const initialState: PortalSliceState = {
  reservationCancelStatus: EntityStateStatus.IDLE,
  patchingTravelBuddyStatus: EntityStateStatus.IDLE,
  keys: {
    error: null,
    opened: false
  },
  mobileSDKInvitationCode: undefined
};

export interface PatchTravelBuddyArgs {
  originalTravelBuddy: TravelBuddy | undefined;
  updatedTravelBuddy: TravelBuddy;
  sendNotification: boolean;
}

export const openDoor = createAsyncThunk<
  void,
  { reservation: Reservation; doorId: string },
  { state: RootState; extra: ThunkExtraArguments }
>("portalSlice/openDoor", async (arg: { reservation: Reservation; doorId: string }, thunkApi) => {
  try {
    return await MagicApi.openDoor(arg.doorId, arg.reservation, {
      signal: thunkApi.signal,
      ...(await getTenantHeaders(thunkApi.extra))
    });
  } catch (e) {
    if (e instanceof ApiError) {
      return thunkApi.rejectWithValue(e.toJSON());
    }
    return thunkApi.rejectWithValue(e);
  }
});

export const logDoorStatus = createAsyncThunk<
  void,
  {
    magicId: string;
    magicToken: string;
    doorId: string;
    data: any;
    status: boolean;
  },
  { state: RootState; extra: ThunkExtraArguments }
>(
  "portalSlice/logDoorStatus",
  async (
    arg: {
      magicId: string;
      magicToken: string;
      doorId: string;
      data: any;
      status: boolean;
    },
    thunkApi
  ) => {
    try {
      return await MagicApi.logDoorStatus(
        arg.doorId,
        arg.magicId,
        arg.magicToken,
        arg.status,
        arg.data,
        {
          signal: thunkApi.signal,
          ...(await getTenantHeaders(thunkApi.extra))
        }
      );
    } catch (e) {
      if (e instanceof ApiError) {
        return thunkApi.rejectWithValue(e.toJSON());
      }
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const getMobileSDKInvitationCodeAction = createAsyncThunk<
  MobileSDKInvitationCodeResponse,
  {
    magicId: string;
  },
  { state: RootState; extra: ThunkExtraArguments }
>(
  "portalSlice/getMobileSDKInvitationCode",
  async (
    arg: {
      magicId: string;
    },
    thunkApi
  ) => {
    try {
      return await MagicApi.getMobileSDKInvitationCode(arg, {
        signal: thunkApi.signal,
        ...(await getTenantHeaders(thunkApi.extra))
      });
    } catch (e) {
      if (e instanceof ApiError) {
        return thunkApi.rejectWithValue(e.toJSON());
      }
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const acceptMobileSDKInvitationCode = createAsyncThunk<
  void,
  {
    magicId: string;
    magicToken: string;
  },
  { state: RootState; extra: ThunkExtraArguments }
>(
  "portalSlice/acceptMobileSDKInvitationCode",
  async (
    arg: {
      magicId: string;
      magicToken: string;
    },
    thunkApi
  ) => {
    try {
      return await MagicApi.acceptMobileSDKInvitationCode(arg.magicId, arg.magicToken, {
        signal: thunkApi.signal,
        ...(await getTenantHeaders(thunkApi.extra))
      });
    } catch (e) {
      if (e instanceof ApiError) {
        return thunkApi.rejectWithValue(e.toJSON());
      }
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const cancelReservationAction = createAsyncThunk<
  void,
  { reservation: Reservation },
  { state: RootState; extra: ThunkExtraArguments }
>("portalSlice/cancelReservation", async (arg: { reservation: Reservation }, thunkApi) => {
  try {
    return await MagicApi.cancelReservation(arg.reservation, {
      signal: thunkApi.signal,
      ...(await getTenantHeaders(thunkApi.extra))
    });
  } catch (e) {
    if (e instanceof ApiError) {
      return thunkApi.rejectWithValue(e.toJSON());
    }
    return thunkApi.rejectWithValue(e);
  }
});

export const patchTravelBuddy = createAsyncThunk<
  void,
  PatchTravelBuddyArgs,
  { state: RootState; rejectValue: ApiErrorJson; extra: ThunkExtraArguments }
>("portalSlice/patchTravelBuddy", async (arg, thunkAPI) => {
  try {
    const originalTravelBuddy = arg.originalTravelBuddy;
    let travelBuddyValues = arg.updatedTravelBuddy;

    if (!originalTravelBuddy) {
      throw new Error("Travel Buddy is not in travelBuddyFlow state");
    }

    let travelBuddyPatches: Operation[] = [];
    if (travelBuddyValues && originalTravelBuddy) {
      travelBuddyPatches = getPatches(originalTravelBuddy, travelBuddyValues).filter(
        (value) => value.path.indexOf("objectId") === -1
      );
      if (!(travelBuddyPatches.length === 0 && !arg.sendNotification)) {
        await MagicApi.patchTravelBuddy(
          {
            travelBuddy: travelBuddyValues,
            patches: travelBuddyPatches,
            sendNotification: arg.sendNotification
          },
          {
            signal: thunkAPI.signal,
            ...(await getTenantHeaders(thunkAPI.extra))
          }
        );
      }
    }
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const portalSlice = createSlice({
  name: "portalSlice",
  initialState,
  reducers: {
    initPortal: () => initialState,
    setUnlocked: (state, action: PayloadAction<{ unlocked: boolean }>) => {
      state.keys.opened = action.payload.unlocked;
    },
    clearError: (state) => {
      state.keys.error = null;
      state.keys.errorId = undefined;
    },
    resetCancelStatus: (state) => {
      state.reservationCancelStatus = EntityStateStatus.IDLE;
    },
    resetMobileSDKInvitationCode: (state) => {
      state.mobileSDKInvitationCode = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(openDoor.pending, (state) => {
        state.keys.error = null;
        state.keys.errorId = undefined;
        state.keys.opened = false;
      })
      .addCase(openDoor.rejected, (state, action) => {
        state.keys.error = action.error;
        state.keys.errorId = (action?.payload as ApiError)?.id;
      })
      .addCase(openDoor.fulfilled, (state) => {
        state.keys.opened = true;
      })
      .addCase(patchTravelBuddy.pending, (state) => {
        state.patchingTravelBuddyStatus = EntityStateStatus.LOADING;
      })
      .addCase(patchTravelBuddy.fulfilled, (state) => {
        state.patchingTravelBuddyStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(patchTravelBuddy.rejected, (state) => {
        state.patchingTravelBuddyStatus = EntityStateStatus.FAILED;
      })
      .addCase(cancelReservationAction.pending, (state) => {
        state.reservationCancelStatus = EntityStateStatus.LOADING;
      })
      .addCase(cancelReservationAction.fulfilled, (state) => {
        state.reservationCancelStatus = EntityStateStatus.SUCCEEDED;
      })
      .addCase(cancelReservationAction.rejected, (state) => {
        state.reservationCancelStatus = EntityStateStatus.FAILED;
      })
      .addCase(getMobileSDKInvitationCodeAction.fulfilled, (state, action) => {
        state.mobileSDKInvitationCode = action?.payload;
      });
  }
});

export const {
  initPortal,
  setUnlocked,
  clearError,
  resetCancelStatus,
  resetMobileSDKInvitationCode
} = portalSlice.actions;

const selectSelf: Selector<RootState, PortalSliceState> = (state: RootState) =>
  state[portalSlice.name];

export const selectKeysOpen = createSelector(selectSelf, (res) => res.keys.opened);

export const selectKeysError = createSelector(selectSelf, (res) => res.keys.error);

export const selectCanceledReservation = createSelector(
  selectSelf,
  (res) => res.reservationCancelStatus === EntityStateStatus.SUCCEEDED
);

export const selectIsLoadingCancelingReservation = createSelector(
  selectSelf,
  (res) => res.reservationCancelStatus === EntityStateStatus.LOADING
);

export const selectMobileSDKInvitationCode = createSelector(
  selectSelf,
  (res) => res.mobileSDKInvitationCode
);
