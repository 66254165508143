import { useAppDispatch } from "../../state/store";
import { Reservation } from "../../domain-common/reservation";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { encodeKeyWithAPI, selectAssignKeyWithAPIStatus } from "./key-assign.slice";
import { useEncodeKeyMutation } from "../../graphql/mutations/encode-key.generated";
import { useSelector } from "react-redux";
import { mapQueryStatusToEntityStateStatus } from "../../state/EntityStateStatus";

interface EncodeKeyProps {
  reservation: Reservation;
  encoderId: string;
}
export const useEncodeKey = () => {
  const { isRESTVersion } = useApiVersion();
  const dispatch = useAppDispatch();
  const [encodeKeyV2, { status: statusV2 }] = useEncodeKeyMutation();
  const statusV1 = useSelector(selectAssignKeyWithAPIStatus);

  const keyStatus = isRESTVersion ? statusV1 : mapQueryStatusToEntityStateStatus(statusV2);
  const encodeKey = ({ reservation, encoderId }: EncodeKeyProps) =>
    isRESTVersion
      ? dispatch(encodeKeyWithAPI({ reservation, encoderId }))
      : encodeKeyV2({
          magicId: reservation.magicId,
          magicToken: reservation.magicToken,
          encoderId
        });

  return { encodeKey, keyStatus };
};
