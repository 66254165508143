import { Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";

const useStyles = makeStyles<{ imageUrl: string }>()((theme, props) => {
  const closeSize = theme.spacing(3.75);
  return {
    root: {
      position: "relative"
    },
    paper: {
      width: 60,
      height: 76,
      paddingTop: 0,
      backgroundImage: `url("${props.imageUrl}")`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      marginTop: parseInt(closeSize) / 2
    },
    close: {
      width: closeSize,
      height: closeSize,
      backgroundColor: "black",
      position: "absolute",
      zIndex: 1,
      top: -parseInt(closeSize) / 2,
      right: -parseInt(closeSize) / 2,
      borderRadius: "50%",
      color: "white",
      display: "flex",
      justifyContent: "center",
      fontSize: "30px",
      paddingTop: "3px",
      userSelect: "none",
      cursor: "pointer"
    }
  };
});

export const CartListItemImage: React.FC<
  React.PropsWithChildren<{
    imageUrl: string;
    onClose?: () => void;
  }>
> = ({ imageUrl, onClose }) => {
  const { classes } = useStyles({ imageUrl });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} />
      <span className={classes.close} onClick={onClose}>
        &times;
      </span>
    </div>
  );
};
