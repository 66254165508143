import { Box, FormHelperText, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { TaskListEditIcon } from "../../icons/task-list-edit.icon";
import { DocumentPreview } from "../../molecules/document-preview";
import { SignatureDialog } from "./signature-dialog";
import { Button } from "@likemagic-tech/sv-magic-library";
import { isKioskMode } from "../../../util/kiosk-mode";

interface SignatureUploadProps {
  value: string | null;
  onChange: (base64Image: string | null) => void;
  disabled?: boolean;
  error?: string | boolean;
  showPreview?: boolean;
  labels: {
    buttonLabel: string;
    deletedText: string;
    dialogTitle: string;
    dialogReset: string;
    dialogConfirm: string;
  };
}

const useStyles = makeStyles()((theme: Theme) => ({
  buttonError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    "&:hover": {
      borderColor: theme.palette.error.main
    }
  },
  signatureBox: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  image: {
    width: "100%",
    maxWidth: "290px"
  }
}));

export const SignatureUpload: React.FC<React.PropsWithChildren<SignatureUploadProps>> = (props) => {
  const { value, onChange, disabled, error, showPreview, labels } = props;
  const [openSignatureDialog, setOpenSignatureDialog] = React.useState(false);

  const handleSignatureOpen = () => {
    setOpenSignatureDialog(true);
  };

  const handleSignatureConfirm = (image?: string) => {
    setOpenSignatureDialog(false);
    onChange(image || null);
  };

  const handleSignatureClose = () => {
    setOpenSignatureDialog(false);
  };

  const { classes } = useStyles();

  return (
    <Box display="flex" flexDirection="column">
      {!showPreview && !value && (
        <Button
          variant="secondary"
          size="small"
          disabled={disabled}
          onClick={handleSignatureOpen}
          className={`${error ? classes.buttonError : ""}`}
        >
          {labels.buttonLabel}
        </Button>
      )}
      {showPreview && <DocumentPreview icon={<TaskListEditIcon />} text={labels.deletedText} />}
      {value && (
        <Box
          onClick={() => {
            if (disabled) {
              return;
            }
            handleSignatureOpen();
          }}
          className={classes.signatureBox}
        >
          <img src={value} alt="signature" className={classes.image} />
        </Box>
      )}
      {error && (
        <FormHelperText error required>
          {error}
        </FormHelperText>
      )}
      <SignatureDialog
        open={openSignatureDialog}
        onClose={handleSignatureClose}
        onConfirm={handleSignatureConfirm}
        large={isKioskMode()}
        labels={{
          title: labels.dialogTitle,
          reset: labels.dialogReset,
          confirm: labels.dialogConfirm
        }}
      />
    </Box>
  );
};
