import { createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { RootState } from "../../state/store";
import { ApiError } from "@likemagic-tech/sv-magic-library";

export interface BannerSliceState {
  title: string;
  subtitle?: string;
  errorId?: string;
  isOpen: boolean;
  type: "error" | "success" | "warning";
}

const initialState: BannerSliceState = {
  isOpen: false,
  type: "error",
  title: ""
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    openBanner: (state, action: PayloadAction<Omit<BannerSliceState, "isOpen">>) => {
      state.isOpen = true;
      state.title = action.payload.title;
      state.subtitle = action.payload.subtitle;
      state.type = action.payload.type;
      state.errorId = action.payload.errorId;
    },
    closeBanner: (state) => {
      state.isOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith("/rejected"),
      (state, action) => {
        const error: ApiError = action.payload;

        if (
          error?.status >= 400 &&
          error?.status !== 403 &&
          !action.meta.arg?.skipBannerError &&
          !action.meta.arg?.originalArgs?.skipBannerError
        ) {
          state.isOpen = true;
          state.title = "";
          state.subtitle = "";
          //  setting the errorId so the title will be resolved from prismic in the Notification component itself
          state.errorId = action.payload.id;
          state.type = "error";
        }
      }
    );
  }
});

export const { openBanner, closeBanner } = bannerSlice.actions;

export const selectBannerState: Selector<RootState, BannerSliceState> = (state: RootState) =>
  state[bannerSlice.name];
