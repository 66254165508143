import React, { FC, useCallback } from "react";
import { Reservation } from "../../../domain-common/reservation";
import { KeyIcon } from "../../../components/icons";
import { useKeysSpeedDialStyles } from "./use-keys-speed-dial-styles";
import { use100vh } from "react-div-100vh";
import { Fab } from "../../../components/atoms/fab/fab";
import { generatePortalMyStayKeysUrl } from "../../../util/routing";
import { useNavigate } from "react-router-dom";

export const KeySpeedDialOffline: FC<React.PropsWithChildren<{ reservation: Reservation }>> = ({
  reservation
}) => {
  const height100vh = use100vh();
  const { classes } = useKeysSpeedDialStyles({
    height100vh
  });

  const navigate = useNavigate();

  const goToKeysPage = useCallback(async () => {
    navigate(generatePortalMyStayKeysUrl(reservation.magicId));
  }, [reservation, navigate]);

  return (
    <Fab
      className={classes.speedDial}
      sx={{
        borderRadius: 5,
        backgroundColor: "common.black",
        "&:hover": {
          backgroundColor: "common.black"
        },
        width: 64,
        height: 64
      }}
      onClick={goToKeysPage}
    >
      <KeyIcon sx={{ color: "common.white" }} />
    </Fab>
  );
};
