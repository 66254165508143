import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type InitiatePaymentMutationVariables = Types.Exact<{
  magicId: Types.Scalars["String"];
  paymentRequest: Types.WidgetPaymentRequest;
}>;

export type InitiatePaymentMutation = {
  __typename?: "Mutation";
  InitiatePayment?:
    | {
        __typename: "PaymentAction";
        checkoutPaymentsAction?: any | null;
        transitoryPaymentDTO?: {
          __typename?: "TransitoryPayment";
          paymentMethod?: string | null;
          shopperReference?: string | null;
        } | null;
      }
    | { __typename: "Void"; None?: string | null }
    | null;
};

export const InitiatePaymentDocument = `
    mutation InitiatePayment($magicId: String!, $paymentRequest: WidgetPaymentRequest!) {
  InitiatePayment(magicId: $magicId, payment: $paymentRequest) {
    ... on Void {
      __typename
      None
    }
    ... on PaymentAction {
      __typename
      checkoutPaymentsAction
      transitoryPaymentDTO {
        paymentMethod
        shopperReference
      }
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    InitiatePayment: build.mutation<InitiatePaymentMutation, InitiatePaymentMutationVariables>({
      query: (variables) => ({ document: InitiatePaymentDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useInitiatePaymentMutation } = injectedRtkApi;
