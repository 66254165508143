import { registerPlugin } from "@capacitor/core";

export interface SeosMobileSDKPlugin {
  startScanning(options: {}): Promise<{ value: string }>;
  stopScanning(options: {}): Promise<{ value: string }>;
  setupEndpoint(options: { invitationCode: string }): Promise<{ value: string }>;
}

const SeosMobileSDK = registerPlugin<SeosMobileSDKPlugin>("SeosMobile");

export default SeosMobileSDK;
