import { FindReservationResponseDTO } from "../find-reservation.dto";
import React, { FC, useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { ArrowRight1Icon } from "../../../components/icons";
import { Grid } from "@mui/material";
import { Heading4, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useNavigate } from "react-router-dom";
import { generateMagicLink } from "../../../util/routing";
import { closeFindReservationModal } from "../find-reservation.slice";
import { useAppDispatch } from "../../../state/store";
import { useUnitGroupCmsData } from "../../../state/property-cms/use-unit-group-cms-data";

interface FindReservationModalItemProps {
  reservation: FindReservationResponseDTO;
  propertyId: string;
  labels: {
    singleAdult: string;
    pluralAdult: string;
    singleChild: string;
    pluralChildren: string;
  };
}

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(3, 3),
    paddingRight: theme.spacing(1),
    cursor: "pointer"
  },
  button: {
    color: theme.palette.grey[500]
  }
}));

export const FindReservationModalItem: FC<
  React.PropsWithChildren<FindReservationModalItemProps>
> = ({ reservation, labels, propertyId }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const personsInReservation = useMemo(() => {
    const adults = `${reservation.adults} ${
      reservation.adults === 1 ? labels.singleAdult : labels.pluralAdult
    }`;
    const children = `${reservation.children} ${
      reservation.children === 1 ? labels.singleChild : labels.pluralChildren
    }`;
    return `${adults} ${children}`;
  }, [reservation.adults, reservation.children, labels]);

  const goToReservation = useCallback(() => {
    navigate(generateMagicLink(reservation.magicId));
    dispatch(closeFindReservationModal());
  }, [reservation.magicId, navigate, dispatch]);

  const { unitGroupLabel } = useUnitGroupCmsData(propertyId, reservation?.unitGroupId);
  return (
    <Grid
      justifyContent="space-between"
      container
      flexDirection="row"
      alignItems="center"
      className={classes.container}
      onClick={goToReservation}
      data-testid="go-to-reservation"
    >
      <div>
        <Heading4 gutterBottom>
          {unitGroupLabel} {reservation.studioNumber}
        </Heading4>
        <Paragraph>{personsInReservation}</Paragraph>
      </div>
      <ArrowRight1Icon className={classes.button} />
    </Grid>
  );
};
