export enum MyStayPagesEnum {
  KEYS = "KEYS",
  BILLS = "BILLS",
  OPEN_BALANCE = "OPEN_BALANCE",
  ALL_BOOKINGS = "ALL_BOOKINGS",
  BUDDIES = "BUDDIES",
  SERVICES = "SERVICES",
  SERVICES_DETAIL = "SERVICES_DETAIL",
  SERVICES_LIST = "SERVICES_LIST",
  EXTEND_STAY_BILL = "EXTEND_STAY_BILL",
  SHOP = "SHOP",
  BOX_SHOP = "BOX_SHOP",
  BOX_SHOP_PAYMENT_SUCCESS = "BOX_SHOP_PAYMENT_SUCCESS",
  CART = "CART",
  ARTICLES = "ARTICLES",
  BOX_RENT = "BOX_RENT",
  BOX_RENT_ITEM = "BOX_RENT_ITEM",
  ROOM_CONTROL = "ROOM_CONTROL",
  SELF_POURING_STATION_SHOP = "SELF_POURING_STATION_SHOP",
  SELF_POURING_STATION_SHOP_PAYMENT_SUCCESS = "SELF_POURING_STATION_SHOP_PAYMENT_SUCCESS",
  DYNAMIC_SHOP = "DYNAMIC_SHOP",
  DYNAMIC_SHOP_BILL_OVERVIEW = "DYNAMIC_SHOP_BILL_OVERVIEW",
  DYNAMIC_SHOP_PAYMENT = "DYNAMIC_SHOP_DYNAMIC_SHOP_PAYMENT",
  DYNAMIC_SHOP_CONFIRMATION = "DYNAMIC_SHOP_CONFIRMATION"
}

export const MyStayPageToPath: Record<MyStayPagesEnum, string> = {
  [MyStayPagesEnum.KEYS]: "/keys",
  [MyStayPagesEnum.OPEN_BALANCE]: "/open-balance",
  [MyStayPagesEnum.BILLS]: "/bills",
  [MyStayPagesEnum.ALL_BOOKINGS]: "/all-bookings",
  [MyStayPagesEnum.BUDDIES]: "/buddies",
  [MyStayPagesEnum.SERVICES]: "/services",
  [MyStayPagesEnum.SERVICES_DETAIL]: "/:serviceId/service-details",
  [MyStayPagesEnum.SERVICES_LIST]: "/:serviceId/list",
  [MyStayPagesEnum.EXTEND_STAY_BILL]: "/extend-stay-bill",
  [MyStayPagesEnum.SHOP]: "/shop",
  [MyStayPagesEnum.BOX_SHOP]: "/box-shop",
  [MyStayPagesEnum.BOX_RENT]: "/box-rent",
  [MyStayPagesEnum.BOX_RENT_ITEM]: "/:boxId/box-rent",
  [MyStayPagesEnum.BOX_SHOP_PAYMENT_SUCCESS]: "/:deviceId/box-shop-payment-success",
  [MyStayPagesEnum.CART]: "/shop/cart",
  [MyStayPagesEnum.ARTICLES]: "/articles",
  [MyStayPagesEnum.ROOM_CONTROL]: "/room-control",
  [MyStayPagesEnum.SELF_POURING_STATION_SHOP]: "/self-pouring-station-shop",
  [MyStayPagesEnum.SELF_POURING_STATION_SHOP_PAYMENT_SUCCESS]:
    "/:deviceId/self-pouring-station-shop-payment-success",
  [MyStayPagesEnum.DYNAMIC_SHOP]: "/dynamic-shop",
  [MyStayPagesEnum.DYNAMIC_SHOP_BILL_OVERVIEW]: "/bill-overview",
  [MyStayPagesEnum.DYNAMIC_SHOP_PAYMENT]: "/payment",
  [MyStayPagesEnum.DYNAMIC_SHOP_CONFIRMATION]: "/confirmation"
};
