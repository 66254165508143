import { BaseApiClient, JSONApiResponse } from "@likemagic-tech/sv-magic-library";
import { generateAnyURLSearchParams } from "../features/search-for-bookings/hooks/use-search-offer-params";
import { SearchUnitGroup } from "../domain-common/search-unit-group";
import { SearchAvailableBookingProperty } from "../domain-common/search-available-booking-property";

const BASE_URL = "/api/guest-journey-service";

class SearchApiClient extends BaseApiClient {
  async searchForAvailableProperties(
    filters: {
      arrival: string;
      departure: string;
      propertyIds: string;
      adults: number;
      childrenAges: Array<number>;
      promoCodePMS?: string;
      promoCodeMagic?: string;
    },
    init?: RequestInit
  ) {
    const searchParams = generateAnyURLSearchParams(filters);
    const response = await this.fetch(
      BASE_URL + `/search/available-properties?${searchParams.toString()}`,
      {
        ...init,
        method: "GET"
      }
    );
    return new JSONApiResponse<Array<SearchAvailableBookingProperty>>(response).value();
  }

  async searchForAvailableUnitGroups(
    filters: {
      arrival: string;
      departure: string;
      propertyIds: string;
      adults: number;
      childrenAges: Array<number>;
      promoCodePMS?: string;
      promoCodeMagic?: string;
      language?: string;
    },
    init?: RequestInit
  ) {
    const searchParams = generateAnyURLSearchParams({
      arrival: filters.arrival,
      departure: filters.departure,
      propertyIds: filters.propertyIds,
      adults: filters.adults,
      childrenAges: filters.childrenAges,
      promoCode: filters.promoCodePMS,
      promoCodeMagic: filters.promoCodeMagic,
      language: filters.language
    });

    const response = await this.fetch(
      BASE_URL + `/search/available-unit-groups?${searchParams.toString()}`,
      {
        ...init,
        method: "GET"
      }
    );
    return new JSONApiResponse<Array<SearchUnitGroup>>(response).value();
  }
}

export const SearchApi = new SearchApiClient();
