import React, { FC, ReactElement, useContext } from "react";
import { PortalPagesEnum } from "../../../features/portal/portal-page-to-path";
import { BookingOverviewPages } from "../../../features/booking-overview/booking-overview-pages";
import { SearchBookingPages } from "../../../features/search-for-bookings/search-booking-pages";
import { BedIcon, MagnifierIcon, MyStayIcon, ProfileIcon } from "../../icons";
import { generateInitSearchBookingUrl } from "../../../features/search-for-bookings/search-booking-navigation";
import { generateInitBookingOverviewUrl } from "../../../features/booking-overview/booking-overview-navigation";
import { generatePortalMyStayUrl, generatePortalProfileUrl } from "../../../util/routing";
import { Box } from "@mui/material";
import { PortalBottomNavigation } from "../../index";
import { useSelector } from "react-redux";
import { selectLastMagicId } from "../../../features/restore-magic-context/restore-magic-context.slice";
import { isKioskMode } from "../../../util/kiosk-mode";
import { useIsMobile } from "../../layouts/hooks/use-is-mobile";
import { useLocation } from "react-router-dom";
import { useFeatureFlags } from "../../../util/hooks/use-configuration";
import { makeStyles } from "tss-react/mui";

const transitionStyle = {
  transition: "transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  transitionDelay: "0.1s",
  color: "black"
};
let selectedIconStyle = {
  transform: "scale(1.15)",
  color: "black"
};

interface BottomBarStyleInterface {
  showBottomBar: boolean;
}

const useStyles = makeStyles<BottomBarStyleInterface>()(
  ({ palette, spacing }, { showBottomBar }) => ({
    footer: showBottomBar
      ? {
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1001,
          paddingBottom: "env(safe-area-inset-bottom)",
          backgroundColor: palette.background.paper,
          boxShadow: "0px 0px 13px rgba(60, 60, 60, 0.05)"
        }
      : { display: "none" },
    spaceForFooter: showBottomBar
      ? {
          height: `calc(${spacing(7)} + env(safe-area-inset-bottom))`,
          width: "100%"
        }
      : { display: "none" },
    pageContainer: !showBottomBar
      ? {
          minHeight: `calc(100vh - 110px - ${spacing(2)})`,
          position: "relative",
          overflow: "hidden"
        }
      : {}
  })
);

interface NavigationLabels {
  [PortalPagesEnum.PROFILE]: string;
  [BookingOverviewPages.PROPERTIES]: string;
  [PortalPagesEnum.MY_STAY]: string;
  [SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES]: string;
}

export interface NavigationItem {
  label: string;
  icon: ReactElement;
  selectedIcon: ReactElement;
  to: string;
  isSelected: boolean;
}

const goToSecuredPages =
  (lastMagicId: string, navigationLabel: string) => (action: (magicId: string) => string) =>
    lastMagicId ? action(lastMagicId) : `/?redirectTo=${navigationLabel}`;

export const navigationLinksGenerator: (props: {
  labels: NavigationLabels;
  magicId: string;
  isSearchEnabled: boolean;
  ibeOnly: boolean;
  pathname: string;
}) => Array<NavigationItem> = ({ labels, magicId, isSearchEnabled, pathname, ibeOnly }) => {
  if (ibeOnly) {
    return [
      {
        label: labels[SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES],
        icon: <MagnifierIcon style={transitionStyle} />,
        selectedIcon: <MagnifierIcon style={selectedIconStyle} />,
        to: generateInitSearchBookingUrl(),
        isSelected: true
      }
    ];
  }
  return [
    {
      label: labels[SearchBookingPages.SEARCH_AVAILABLE_PROPERTIES],
      icon: <MagnifierIcon style={transitionStyle} />,
      selectedIcon: <MagnifierIcon style={selectedIconStyle} />,
      to: generateInitSearchBookingUrl()
    },
    {
      label: labels[BookingOverviewPages.PROPERTIES],
      icon: <BedIcon style={transitionStyle} />,
      selectedIcon: <BedIcon style={selectedIconStyle} />,
      to: goToSecuredPages(magicId, BookingOverviewPages.PROPERTIES)(generateInitBookingOverviewUrl)
    },
    {
      label: labels[PortalPagesEnum.MY_STAY],
      icon: <MyStayIcon style={transitionStyle} />,
      selectedIcon: <MyStayIcon style={selectedIconStyle} />,
      to: goToSecuredPages(magicId, PortalPagesEnum.MY_STAY)(generatePortalMyStayUrl)
    },
    {
      label: labels[PortalPagesEnum.PROFILE],
      icon: <ProfileIcon style={transitionStyle} />,
      selectedIcon: <ProfileIcon style={selectedIconStyle} />,
      to: goToSecuredPages(magicId, PortalPagesEnum.PROFILE)(generatePortalProfileUrl)
    }
  ]
    .filter((action) => action.to !== generateInitSearchBookingUrl() || isSearchEnabled)
    .map((item) => ({
      ...item,
      isSelected: pathname.startsWith(item.to) && item.to !== "/"
    }));
};

interface BottomNavbarContextValue {
  setDisplayBottomNavbar: (arg: boolean) => void;
  isBottomNavbarActive: boolean;
}

export const BottomNavbarContext = React.createContext<BottomNavbarContextValue>({
  setDisplayBottomNavbar: () => {},
  isBottomNavbarActive: false
});

export const useBottomNavbar = () => useContext(BottomNavbarContext);

export const BottomNavbarProvider: FC<
  React.PropsWithChildren<{
    labels: NavigationLabels;
  }>
> = ({ labels, children }) => {
  const [displayNavbar, setDisplayBottomNavbar] = React.useState<boolean>(true);
  const { searchEnabled, ibeOnly } = useFeatureFlags();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const { classes } = useStyles({ showBottomBar: !isKioskMode() && isMobile });
  const lastMagicId = useSelector(selectLastMagicId);

  return (
    <BottomNavbarContext.Provider
      value={{ setDisplayBottomNavbar, isBottomNavbarActive: displayNavbar }}
    >
      <Box className={classes.pageContainer}>{children}</Box>
      {displayNavbar && (
        <>
          <Box className={classes.footer}>
            <PortalBottomNavigation
              links={navigationLinksGenerator({
                labels,
                magicId: lastMagicId ?? "",
                isSearchEnabled: !!searchEnabled,
                ibeOnly,
                pathname
              })}
            />
          </Box>
          <Box className={classes.spaceForFooter} />
        </>
      )}
    </BottomNavbarContext.Provider>
  );
};
