import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../state/store";
import { iOSApplicationInstalled } from "./pwa-util";
import { isIOS } from "react-device-detect";

const initialState = {
  isNewVersionDetected: false,
  isApplicationUpdating: false,
  showInstallAppButton: isIOS ? !iOSApplicationInstalled() : false,
  showIosInstallAppPrompt: false
};
export const pwaSlice = createSlice({
  name: "pwa",
  reducers: {
    newVersionDetected: (state) => {
      state.isNewVersionDetected = true;
    },
    updateApplication: (state) => {
      state.isApplicationUpdating = true;
      state.isNewVersionDetected = false;
    },
    showInstallButton: (state) => {
      state.showInstallAppButton = true;
    },
    hideInstallButton: (state) => {
      state.showInstallAppButton = false;
    },
    showIosInstallPrompt: (state) => {
      state.showIosInstallAppPrompt = true;
    },
    hideIosInstallPrompt: (state) => {
      state.showIosInstallAppPrompt = false;
    }
  },
  initialState
});

export const {
  newVersionDetected,
  updateApplication,
  showInstallButton,
  hideInstallButton,
  showIosInstallPrompt,
  hideIosInstallPrompt
} = pwaSlice.actions;

export const selectServiceWorkerSlice = (state: RootState) => state[pwaSlice.name];
