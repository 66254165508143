import { Service } from "../domain-common/service";
import { BaseApiClient, JSONApiResponse } from "@likemagic-tech/sv-magic-library";

const BASE_URL = "/api/guest-journey-service/magic";

export interface GetServicesArgs {
  magicId: string;
}

class ServiceApiClient extends BaseApiClient {
  async getServices({ magicId }: GetServicesArgs, init?: RequestInit): Promise<Service[]> {
    const url = BASE_URL + `/${magicId}/services`;
    const response = await this.fetchApi(url, init);
    return new JSONApiResponse<Service[]>(response).value();
  }
}

export const ServiceApi = new ServiceApiClient();
