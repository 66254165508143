import { OverviewService } from "./overview-service";
import { HasFlowState } from "./flow-state";
import { Folio, FolioFromJSON, PrepaymentType } from "./folio";
import { Guest, GuestFromJSON } from "./guest";
import { GuestFlowContext } from "./guest-flow-checkpoint-and-args";
import { MagicFile, MagicFileFromJSON } from "./magic-file";
import { MagicObject, MagicObjectFromJSON } from "./magic-object";
import { Charge, FolioToBePaid } from "./payment";
import { Person, PersonFromJSON } from "./person";
import { Price, PriceFromJSON } from "./price";
import { ReservationExtras, ReservationExtrasFromJSON } from "./reservation-extras";
import { ReservationStatus } from "./reservation-status";
import { TravelBuddy, TravelBuddyFromJSON } from "./travel-buddy";
import { Unit } from "./unit";
import { CancellationFee } from "./cancellation-fee";
import { PaymentAccount, PaymentAccountFromJSON } from "./payment-account";
import { FullPrice, FullPriceFromJSON } from "./full-price";
import { IdCheckStatus } from "./id-check-status";
import { RateBreakdown } from "./grouped-rate-breakdown";

export interface AccessibleDoor {
  id: string;
  title: string;
  pin?: string;
  is_general: boolean;
}

export interface Reservation extends MagicObject<GuestFlowContext>, HasFlowState<GuestFlowContext> {
  accessibleDoors: AccessibleDoor[] | null;
  additionalGuests: Array<Guest>;
  adults: number;
  childrenAges: Array<number>;
  extras: ReservationExtras | null;
  primaryGuest: Person;
  travelBuddy: Person;
  keycloakUUID: string | null;
  propertyId: string;
  status: ReservationStatus;
  arrival?: string;
  departure?: string;
  checkInTime?: string;
  contractualCheckInTime?: string;
  checkOutTime?: string;
  estimatedArrivalTime?: string;
  estimatedDepartureTime?: string;
  cancellationTime?: string;
  unitCleanOnCheckin: boolean;
  unit?: Unit;
  unitGroup: {
    id: string;
    maxPersons: number;
  };
  maxCompanions: number;
  files: MagicFile[];
  folios: Array<Folio>;
  groupedRateBreakdown: Array<RateBreakdown>;
  foliosToBePaid: Array<FolioToBePaid>;
  totalAllowedPayment: Price;
  totalCharges: FullPrice;
  totalPayments: Price;
  paymentAccount?: PaymentAccount;
  groupedCharges: Array<Charge>;
  servicesOverview: Array<OverviewService> | null;
  travelBuddies: Array<TravelBuddy>;
  created?: string;
  channelCode?: string;
  cancellationFee?: CancellationFee;
  isExternalReservation: boolean;
  ratePlanId?: string;
  idCheckStatus: IdCheckStatus;
  prepaymentType?: PrepaymentType;
}

export const ReservationFromJSON = (json: any): Reservation => {
  if (!json) {
    return json;
  }

  const magicObject = MagicObjectFromJSON<GuestFlowContext, Reservation>(json);

  return {
    ...magicObject,
    primaryGuest: PersonFromJSON(json["primaryGuest"]),
    travelBuddy: PersonFromJSON(json["travelBuddy"]),
    keycloakUUID: json["keycloakUUID"],
    propertyId: json["propertyId"],
    status: json["status"],
    arrival: json["arrival"],
    departure: json["departure"],
    checkInTime: json["checkInTime"],
    checkOutTime: json["checkOutTime"],
    cancellationTime: json["cancellationTime"],
    unitCleanOnCheckin: json["unitCleanOnCheckin"],
    unit: json["unit"],
    unitGroup: json["unitGroup"],
    maxCompanions: json["maxCompanions"],
    adults: json["adults"],
    extras: ReservationExtrasFromJSON(json["extras"]),
    files: Array.isArray(json["files"]) ? json["files"].map(MagicFileFromJSON) : [],
    additionalGuests: Array.isArray(json["additionalGuests"])
      ? json["additionalGuests"].map(GuestFromJSON)
      : [],
    childrenAges: Array.isArray(json["childrenAges"]) ? json["childrenAges"] : [],
    folios: Array.isArray(json["folios"]) ? json["folios"].map(FolioFromJSON) : [],
    groupedRateBreakdown: json["groupedRateBreakdown"] ?? [],
    foliosToBePaid: Array.isArray(json["foliosToBePaid"]) ? json["foliosToBePaid"] : [],
    groupedCharges: Array.isArray(json["groupedCharges"]) ? json["groupedCharges"] : [],
    totalAllowedPayment: PriceFromJSON(json["totalAllowedPayment"]),
    totalCharges: FullPriceFromJSON(json["totalCharges"]),
    totalPayments: PriceFromJSON(json["totalPayments"]),
    paymentAccount: PaymentAccountFromJSON(json["paymentAccount"]),
    servicesOverview: json["servicesOverview"],
    travelBuddies: Array.isArray(json["travelBuddies"])
      ? json["travelBuddies"].map(TravelBuddyFromJSON)
      : [],
    created: json["created"],
    channelCode: json["channelCode"],
    cancellationFee: json["cancellationFee"],
    isExternalReservation: json["isExternalReservation"],
    idCheckStatus: json["idCheckStatus"] ?? IdCheckStatus.UNCONFIRMED
  };
};
