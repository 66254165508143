import { LinkedAccount } from "../domain-common/linked-account";
import { BaseApiClient, JSONApiResponse } from "@likemagic-tech/sv-magic-library";

interface GetLinkedAccountsParams {
  realm: string;
  authServerUrl?: string;
}

class AuthApiClient extends BaseApiClient {
  async getLinkedAccounts(params: GetLinkedAccountsParams, init?: RequestInit) {
    const response = await this.fetchApi(
      `${params.authServerUrl}realms/${params.realm}/account/linked-accounts`,
      { ...init }
    );

    return new JSONApiResponse<LinkedAccount[]>(response).value();
  }
}

export const AuthApi = new AuthApiClient();
