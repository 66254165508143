import React, { FC, useCallback, useEffect } from "react";
import { Button, Paragraph, useAuth, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useSelector } from "react-redux";
import { selectUserProfileIsDeleted } from "../../../user-profile/user-profile.slice";
import { getYesNoModalArg } from "../../../global-modal/global-modal-util";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  useTheme
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTranslateWrapper } from "../../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../../state/cms/cms-single-document-types";
import { useUserProfile } from "../../../user-profile/use-user-profile";
import { useAccountDeletion } from "../../hooks/use-account-deletion";

export const AccountDeletion: FC<React.PropsWithChildren<unknown>> = () => {
  const { open: openModal } = useGlobalModal();
  const userProfileIsDeleted = useSelector(selectUserProfileIsDeleted);
  const auth = useAuth();
  const theme = useTheme();
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const { userKeycloakUUID } = useUserProfile();

  const { deleteAccountFunction } = useAccountDeletion();

  useEffect(() => {
    if (userProfileIsDeleted) {
      auth.logout();
    }
  }, [userProfileIsDeleted, auth]);

  const onDelete = useCallback(async () => {
    const result = await openModal(
      getYesNoModalArg(
        t("modals__account_deletion_title"),
        t("modals__account_deletion__content"),
        t("buttons__yes"),
        t("buttons__no")
      )
    );
    if (result && userKeycloakUUID) {
      deleteAccountFunction({ userProfileId: userKeycloakUUID });
    }
  }, [openModal, deleteAccountFunction, userKeycloakUUID, t]);

  return (
    <Accordion sx={{ width: "100%", borderRadius: theme.shape.borderRadius * 2.5 }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Paragraph>{t("labels__account_deletion_explanation_title")}</Paragraph>
      </AccordionSummary>
      <AccordionDetails>
        <Paragraph>{t("labels__account_deletion_explanation_content")}</Paragraph>
      </AccordionDetails>
      <AccordionActions>
        <Button sx={{ backgroundColor: "error.main" }} variant="primary" onClick={onDelete}>
          {t("buttons__delete")}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};
