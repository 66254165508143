import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function KeyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>{`.a{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}`}</style>
      </defs>
      <title>login-key</title>
      <path
        className="a"
        d="M12.818,14.517l2.545-2.545,1.752.47a1.572,1.572,0,0,0,1.924-1.924l-.47-1.752.784-.784,1.752.47a1.571,1.571,0,0,0,1.924-1.924L22.56,4.776a2.356,2.356,0,0,0-3.333-3.332L9.486,11.185a6.283,6.283,0,1,0,3.332,3.332Z"
      />
      <circle className="a" cx="5.922" cy="18.081" r="1.571" />
    </SvgIcon>
  );
}
