import { GetMagicObjectQuery } from "../queries/GetMagicObject.generated";
import { Reservation as ReservationCommon } from "../../domain-common/reservation";
import {
  transformActorV2ToCommon,
  transformBookingOnBehalfOfToCommon,
  transformIdCheckStatus,
  transformPersonV2ToCommonPerson,
  transformSecondaryGuestsV2ToCommonGuest,
  transformStatusV2ToCommon,
  transformV2PrepaymentTypeToCommon
} from "./transform-utils";
import { MagicFileType } from "../../domain-common/magic-file-type";
import { MagicType } from "../../domain-common/magic-object";
import { emptyPrice } from "../../domain-v1/price";
import { emptyFullPrice, FullPrice, toGross } from "../../domain-common/full-price";
import { OverviewService, ServiceTag } from "../../domain-common/overview-service";
import {
  GrossPrice,
  Price as PriceV2,
  RateSpliceTypeEnumTuple,
  UnitState
} from "../generated/graphql";
import { MagicServiceCodeEnum } from "../../domain-common/magic-service-code-enum";
import { Folio } from "../../domain-common/folio";
import { UnitCondition } from "../../domain-common/unit";
import { transformTravelBuddyV2ToCommon } from "./transform-travel-buddy";
import { Price } from "../../domain-common/price";
import { Charge } from "../../domain-common/payment";
import { CountryCode, getPhoneCode } from "libphonenumber-js";

type MagicObjectInputV2 = GetMagicObjectQuery["GetMagicObject"];
export type ReservationInputV2 = Extract<MagicObjectInputV2, { __typename: "Reservation" }>;

export const transformV2Reservation = (reservation: ReservationInputV2): ReservationCommon => {
  return {
    arrival: reservation.arrival,
    departure: reservation.departure,
    checkInTime: reservation.checkinTime,
    checkOutTime: reservation.checkoutTime,
    estimatedArrivalTime: reservation.estimatedArrivalTime,
    estimatedDepartureTime: reservation.estimatedDepartureTime,
    created: reservation.createdAt,
    accessibleDoors:
      reservation.accessibleDoors?.map((itemV2) => ({
        id: itemV2?.id ?? "",
        title: itemV2?.title ?? "",
        pin: itemV2?.pin ?? "",
        is_general: itemV2?.isGeneral ?? true
      })) ?? [],
    actor: transformActorV2ToCommon(reservation.actor),
    additionalGuests: transformSecondaryGuestsV2ToCommonGuest(reservation?.secondaryGuests),
    adults: reservation.adultsAmount ?? 0,
    childrenAges: reservation.childrenAmount
      ? Array.from(Array(reservation.childrenAmount).keys())
      : [],
    files: reservation.files.map((item) => ({
      contentType: item.contentType,
      fileName: item.fileName,
      id: item.id,
      metaData: {
        magicFileType: item.magicFileType as MagicFileType,
        ownerId: item.ownerId
      }
    })),
    idCheckStatus: transformIdCheckStatus(reservation.idCheckStatus),
    extras: {
      preferredCommunicationChannel:
        reservation.primaryGuest?.preferredCommunicationChannel ?? null,
      dirtyUnitDialogSeen: reservation.flowState.dirtyUnitDialogSeen ?? false,
      cleanUnitDialogSeen: reservation.flowState.cleanUnitDialogSeen ?? false,
      billConfirmed: reservation.flowState.billConfirmed ?? false,
      tcGeneral: reservation.primaryGuest?.termsAndConditionsGeneral ?? false,
      tcOnline: reservation.primaryGuest?.termsAndConditionsOnline ?? false,
      tcMarketingConsentDoubleOptin:
        reservation.primaryGuest?.termsAndConditionsMarketingConsentDoubleOptin ?? false,
      tcMarketingConsent: reservation.primaryGuest?.termsAndConditionsMarketingConsent ?? false,
      bookingOnBehalfOf: transformBookingOnBehalfOfToCommon(
        reservation?.flowState?.bookingOnBehalfOf
      ),
      enrolledInLoyaltyProgram: reservation.primaryGuest?.enrolledInLoyaltyProgram ?? false,
      emergencyEvacuationHelpNeeded:
        reservation.primaryGuest?.emergencyEvacuationHelpNeeded ?? false,
      tcMinors: false
    },
    flowState: {
      wasOrIsCompleted: reservation.flowState.wasOrIsCompleted,
      completed: reservation.flowState.completed,
      context: reservation.flowState.context,
      notificationPending: reservation.flowState.notificationPending,
      notificationSent: reservation.flowState.notificationSent,
      sentNotifications: reservation.flowState.sentNotifications,
      performedActions: reservation.flowState.performedActions,
      fastCheckinAvailable: reservation.flowState.fastCheckinAvailable
    },
    folios: reservation.folios.map(transformFolioV2ToCommon),
    groupedRateBreakdown:
      reservation?.groupedRateBreakdown?.rateSpliceTypeTuples?.map(transformRateSpliceTypeTuples) ??
      [],
    foliosToBePaid: reservation.foliosToBePaid.map((item) => ({
      folioId: item.pmsId ?? "",
      price: emptyPrice()
    })),
    groupedCharges: reservation.folios
      .map((folio) => folio.groupedCharges.map((item) => mapGroupCharges(item, folio.pmsId ?? "")))
      .flatMap((item) => item),
    id: reservation.pmsId,
    isExternalReservation: reservation.isExternalReservation,
    channelCode: reservation.channel ?? undefined,
    keycloakUUID: reservation.userAccountUuid ?? null,
    magicId: reservation.magicId,
    magicLink: reservation.magicLink,
    magicToken: reservation.magicToken ?? "",
    magicType: MagicType.RESERVATION,
    maxCompanions: reservation.maxCompanions ?? 0,
    primaryGuest: transformPersonV2ToCommonPerson(reservation.primaryGuest),
    propertyId: reservation.property.pmsId.toString(),
    servicesOverview: reservation.bookedServicesOverview.map(
      transformBookedServiceOverviewV2ToCommon
    ),
    status: transformStatusV2ToCommon(reservation?.status),
    totalAllowedPayment: transformGrossPriceToPrice(reservation.totalAllowedPayment),
    totalCharges: transformFullPrice(reservation.totalCharges),
    totalPayments: transformGrossPriceToPrice(reservation.totalPayments),
    travelBuddies: reservation.travelBuddies.map((item, index) =>
      transformTravelBuddyV2ToCommon(item, index)
    ),
    // @ts-ignore ain't going to be added for reservation (ony for tb)
    travelBuddy: null,
    unitCleanOnCheckin: !!reservation?.flowState?.unitCleanOnCheckin,
    bookingOverviewItemId: reservation.bookingOverviewItemId,
    unitGroup: {
      id: reservation?.unitGroup?.pmsId ?? "",
      maxPersons: reservation.unitGroup?.capacity ?? 0
    },
    unit: {
      id: reservation?.unit?.pmsId ?? "",
      name: reservation?.unit?.name ?? "",
      unitGroupId: "",
      privacyMode: reservation?.unit?.privacyMode ?? false,
      description: "",
      status: {
        isOccupied: false,
        condition: unitStateV2ToUnitCondition(reservation?.unit?.state)
      }
    },
    cancellationFee: {
      id: reservation?.cancellationFees[0]?.pmsId ?? "",
      fee: toGross(
        transformGrossPriceToFullPrice(reservation?.cancellationFees[0]?.price) ?? emptyFullPrice()
      ),
      code: reservation?.cancellationFees[0]?.pmsId ?? "",
      name: reservation?.cancellationFees[0]?.name ?? "",
      description: reservation?.cancellationFees[0]?.description ?? "",
      dueDateTime: reservation?.cancellationFees[0]?.dueDateTime ?? ""
    }
  };
};

type BookedServiceOverviewV2Type = ReservationInputV2["bookedServicesOverview"][number];

const transformBookedServiceOverviewV2ToCommon = (
  serviceOverviewV2: BookedServiceOverviewV2Type
): OverviewService => {
  return {
    dates: serviceOverviewV2.dates.map((item) => ({
      amount: transformFullPrice(item.amount),
      tags:
        serviceOverviewV2?.service?.tags?.map((item) => {
          return stringToEnum(ServiceTag, item);
        }) ?? [],
      serviceDate: item.serviceDate
    })),
    service: {
      code: serviceOverviewV2?.service?.magicServiceCodeEnum ?? "",
      id: serviceOverviewV2?.service?.pmsServiceId ?? "",
      magicServiceCodeEnum: stringToEnum(
        MagicServiceCodeEnum,
        serviceOverviewV2?.service?.magicServiceCodeEnum
      ),
      tags:
        serviceOverviewV2?.service?.tags?.map((item) => {
          return stringToEnum(ServiceTag, item);
        }) ?? [],
      totalAmount: transformFullPrice(serviceOverviewV2?.service?.totalAmount)
    }
  };
};

const transformRateSpliceTypeTuples = (item?: RateSpliceTypeEnumTuple) => ({
  id: item?.type ?? "",
  totalCharges: transformGrossPriceToPrice(item?.totalCharges),
  breakdownItems:
    item?.pmsIdToRateSplicesTuples?.map((itemTuples) => ({
      id: itemTuples.pmsId ?? "",
      items:
        itemTuples.rateSplices?.map((itemSplices) => ({
          price: transformGrossPriceToPrice(itemSplices.grossPrice),
          name: itemSplices.displayName ?? "",
          quantity: itemSplices.quantity ?? 0,
          type: itemSplices.type ?? "",
          prepaid: itemSplices.prepaid ?? false,
          hide: itemSplices.hide ?? false,
          prepaymentType: transformV2PrepaymentTypeToCommon(itemSplices.prepaymentType ?? undefined)
        })) ?? []
    })) ?? []
});

export const transformGrossPriceToFullPrice = (priceV2?: GrossPrice | null): FullPrice | null => {
  if (priceV2) {
    return {
      currency: priceV2?.currency ?? "N/A",
      grossAmount: priceV2?.grossPriceInCents ?? 0,
      netAmount: 0
    };
  } else {
    return null;
  }
};

export const transformGrossPriceToPrice = (priceV2?: GrossPrice | null): Price => {
  if (priceV2) {
    return {
      currency: priceV2?.currency ?? "N/A",
      amount: priceV2?.grossPriceInCents ?? 0
    };
  } else {
    return emptyPrice();
  }
};

export const transformFullPrice = (priceV2?: PriceV2 | null): FullPrice => {
  return {
    currency: priceV2?.currency ?? "N/A",
    grossAmount: priceV2?.grossPriceInCents ?? 0,
    netAmount: priceV2?.netPriceInCents ?? 0
  };
};

function stringToEnum<T>(enumType: any, value?: string | null): T {
  return value ? enumType[value] : undefined;
}

type FolioV2Type = ReservationInputV2["folios"][number];

const transformFolioV2ToCommon = (folioV2: FolioV2Type): Folio => {
  return {
    company: {
      name: "",
      taxId: "",
      canCheckOutOnAr: false
    },
    debitor: {
      name: "",
      title: "",
      firstName: "",
      type: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        postalCode: "",
        city: "",
        countryCode: "",
        region: ""
      },
      reference: "",
      email: "",
      company: {
        name: "",
        taxId: "",
        canCheckOutOnAr: false
      }
    },
    hasPendingPaymentLink: false,
    id: folioV2.pmsId ?? "",
    isMainFolio: false, // TODO change once LM-1502 is implemented
    magicId: folioV2.pmsId ?? "",
    prepaymentType: transformV2PrepaymentTypeToCommon(folioV2.prepaymentType),
    totalCharges: transformFullPrice(folioV2.totalCharges),
    totalPayments: transformGrossPriceToPrice(folioV2.totalPayments),
    number: folioV2.number ?? ""
  };
};

const mapGroupCharges = (arg: FolioV2Type["groupedCharges"][number], folioId: string): Charge => {
  return {
    folioId: folioId,
    name: arg.name,
    subName: arg.subName ?? "",
    prepayable: arg.prepayable,
    serviceId: arg.pmsServiceId ?? "",
    total: transformFullPrice(arg.totalPrice),
    totalQuantity: arg.totalQuantity,
    translatedNames: arg.translatedNames
  };
};

export const unitStateV2ToUnitCondition = (arg?: UnitState | null): UnitCondition => {
  switch (arg) {
    case UnitState.Clean:
      return UnitCondition.CLEAN;
    case UnitState.Dirty:
      return UnitCondition.DIRTY;
    case UnitState.CleanToBeInspected:
      return UnitCondition.CLEAN_TO_BE_INSPECTED;
    default:
      return UnitCondition.DIRTY;
  }
};

export const transformPhone = (phone: string, countryCodeFromAddress?: string): string => {
  if (phone.startsWith("00")) {
    return `+${phone.substring(2)}`;
  } else if (phone.startsWith("0")) {
    const countryPhoneCode = countryCodeFromAddress
      ? `+${getPhoneCode(countryCodeFromAddress as CountryCode)}` //getPhoneCode returns only the number we are missing +
      : "0";
    return countryPhoneCode + phone.substring(1);
  } else {
    return phone;
  }
};
