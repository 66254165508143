import { Box, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { ParagraphBold } from "@likemagic-tech/sv-magic-library";

interface ContactButtonProps {
  line1: string;
  line2?: string;
  href: string;
  icon: any;
  rel?: string;
  target?: string;
}

const useStyles = makeStyles()((theme) => ({
  button: {
    background: "rgba(0, 0, 0, 0.04)",
    borderRadius: theme.shape.borderRadius * 2.5,
    justifyContent: "flex-start",
    height: "70px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    },
    "& svg *": {
      stroke: "inherit"
    }
  },
  icon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(2)
  },
  line2: {
    color: theme.palette.text.secondary,
    overflow: "hidden"
  }
}));

export const ContactButton: React.FC<React.PropsWithChildren<ContactButtonProps>> = ({
  line1,
  line2,
  icon,
  href,
  target,
  rel
}) => {
  const { classes } = useStyles();

  return (
    <Button
      classes={{
        root: classes.button,
        startIcon: classes.icon
      }}
      startIcon={React.cloneElement(icon, {
        style: { width: "30px", height: "30px" }
      })}
      href={href}
      target={target}
      rel={rel}
      disableElevation
      fullWidth
    >
      <Box display="grid" flexDirection="column">
        <ParagraphBold color="text.primary" gutterBottom>
          {line1}
        </ParagraphBold>
        {line2 && (
          <ParagraphBold className={classes.line2} textOverflow="ellipsis">
            {line2}
          </ParagraphBold>
        )}
      </Box>
    </Button>
  );
};
