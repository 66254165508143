import { FormHelperText, Grid } from "@mui/material";
import { Checkbox, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { ChangeEvent, FC, useEffect, useMemo } from "react";
import { PrismicRichTextWrapper } from "../../atoms";
import { getOkModalArg } from "../../../features/global-modal/global-modal-util";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

interface CheckboxTermsProps {
  acceptedTerms: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  disabled?: boolean;
}

export const CheckboxTerms: FC<CheckboxTermsProps> = ({
  acceptedTerms,
  onChange,
  touched,
  errors,
  disabled
}) => {
  const { open: openModal } = useGlobalModal();
  const { t: tCommon } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const location = useLocation();
  const navigate = useNavigate();

  const modalsContent = useMemo(() => {
    const termsAndConditions = {
      content: <PrismicRichTextWrapper render={cmsData?.data?.["modals__tc_general"]} />,
      title: tCommon("labels__tc_general_title"),
      button: tCommon("buttons__ok")
    };

    const privacy = {
      content: <PrismicRichTextWrapper render={cmsData?.data?.["modals__tc_online"]} />,
      title: tCommon("labels__tc_online_title"),
      button: tCommon("buttons__ok")
    };
    return { termsAndConditions, privacy };
  }, [tCommon, cmsData?.data]);

  const modalType = useMemo(
    () => location?.hash?.replaceAll("#", "") as "termsAndConditions" | "privacy" | "",
    [location.hash]
  );

  useEffect(() => {
    if (modalType) {
      openModal(
        getOkModalArg(
          modalsContent[modalType]?.title,
          modalsContent[modalType]?.content,
          modalsContent[modalType]?.button
        )
      );

      return () => {
        navigate("#");
      };
    }
  }, [modalType, openModal, modalsContent, navigate]);

  const checkBoxLabel = useMemo(() => {
    const labelAsString = JSON.stringify(cmsData?.data?.labels__terms_and_conditions);
    const enrichedLabelAsString = labelAsString.replaceAll("https://", "");
    return JSON.parse(enrichedLabelAsString);
  }, [cmsData?.data?.labels__terms_and_conditions]);

  return (
    <>
      <Grid container direction="row" alignItems="baseline" mt={2} sx={{ flexWrap: "nowrap" }}>
        <Grid item>
          <Checkbox
            name="acceptedTerms"
            id="acceptedTerms"
            checked={acceptedTerms}
            onChange={onChange}
            disabled={disabled}
            sx={{ paddingLeft: 0, paddingTop: 0.5 }}
          />
        </Grid>
        <Grid>
          <PrismicRichTextWrapper render={checkBoxLabel} linksTarget="_self" />
        </Grid>
      </Grid>
      <FormHelperText style={{ color: "red" }}>{touched && errors}</FormHelperText>
    </>
  );
};
