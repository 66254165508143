import { AdditionalServicesPaymentDTO, ShopItemsPaymentDTO } from "../payment/payment-dto";
import { DynamicShopCartItem } from "./dynamic-shop.slice";
import { formatIsoStringToIsoDate } from "@likemagic-tech/sv-magic-library";

export function dynamicShopCartItemsToAdditionalServicesPaymentDTO(
  items: Array<DynamicShopCartItem>
): AdditionalServicesPaymentDTO {
  return items.map((item) => ({
    serviceId: item.serviceId,
    code: item.serviceId,
    dates: [{ date: formatIsoStringToIsoDate(new Date().toISOString()), count: item.quantity }]
  }));
}

export function dynamicShopCartItemsToShopItemsPaymentDTO(
  items: Array<DynamicShopCartItem>
): ShopItemsPaymentDTO {
  return items.map((item) => ({
    serviceId: item.serviceId,
    quantity: item.quantity
  }));
}
