import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FilesApi } from "../../api/files.api";
import { TopNavigationButton } from "../../components";
import { LegalFormData } from "../../domain-common/legal-form-data";
import { MagicFile } from "../../domain-common/magic-file";
import { MagicFileType } from "../../domain-common/magic-file-type";
import { Reservation } from "../../domain-common/reservation";
import { CMSSingleDocumentTypes } from "../../state/cms/cms-single-document-types";
import { base64ToFile } from "../../util/data";
import { isTravelBuddy } from "../../util/flow";
import { useTranslateWrapper } from "../../util/i18n-wrapper";
import { isKioskMode } from "../../util/kiosk-mode";
import { generatePortalMyStayUrl } from "../../util/routing";
import { GuestFlowCheckpoint } from "./checkpoint/guest-flow-checkpoint";
import { getFlowPages } from "./guest-flow-sequence";
import { MagicFileUploadDto } from "./magic-file-upload-dto";
import { ContactButtonNavigation } from "../../components/molecules/contact-button-navigation";
import { CartButtonNavigation } from "../../components/molecules/cart-button-navigation";
import { useReservationContext } from "../reservation-provider/reservation-provider";
import { useUpdateReservation } from "../reservation-provider/use-update-reservation";
import { ApiVersionEnum } from "@likemagic-tech/sv-magic-library";
import { GuestServiceApi } from "../../api/guest-service.apiV2";

const magicFileUploadDtoFromFile = (file: File, fileType: MagicFileType): MagicFileUploadDto => ({
  file,
  fileType
});

const magicFileUploadDtoFromBase64 = async (
  base64Content: string,
  fileName: string,
  fileType: MagicFileType
): Promise<MagicFileUploadDto> => {
  const file = await base64ToFile(base64Content, `${fileName}.png`);
  return magicFileUploadDtoFromFile(file, fileType);
};

const fetchFileData = (
  file: MagicFile,
  version: ApiVersionEnum,
  apiKey?: string,
  authToken?: string
) =>
  (version === ApiVersionEnum.V1 ? FilesApi : GuestServiceApi).getMagicFile(
    file,
    apiKey,
    authToken
  );

const fetchFileDataBase64 = (
  file: MagicFile,
  version: ApiVersionEnum,
  apiKey?: string,
  authToken?: string
) =>
  (version === ApiVersionEnum.V1 ? FilesApi : GuestServiceApi).getMagicFileBase64(
    file,
    apiKey,
    authToken
  );

export async function fetchLegalImages({
  reservation,
  authToken,
  apiKey,
  apiVersion
}: {
  reservation: Reservation;
  authToken?: string;
  apiVersion: ApiVersionEnum;
  apiKey?: string;
}) {
  let identificationImage = null;
  let signatureImage = null;

  const identificationDocument = reservation?.files.find(
    (file) => file.metaData.magicFileType === MagicFileType.IDENTIFICATION_DOCUMENT
  );

  if (identificationDocument && authToken) {
    const identificationFileData = await fetchFileData(
      identificationDocument,
      apiVersion,
      apiKey,
      authToken
    );
    if (identificationFileData) {
      identificationImage = new File([identificationFileData], identificationDocument.fileName, {
        type: identificationDocument.contentType
      });
    }
  }

  const signatureDocument = reservation?.files.find(
    (file) => file.metaData.magicFileType === MagicFileType.SIGNATURE_DOCUMENT
  );

  if (signatureDocument && authToken) {
    const signatureFileData = await fetchFileDataBase64(
      signatureDocument,
      apiVersion,
      apiKey,
      authToken
    );
    if (signatureFileData) {
      signatureImage = signatureFileData;
    }
  }

  return { identificationImage, signatureImage };
}

export async function prepareLegalFiles(
  signatureImage: string | null,
  identificationImage: File | null,
  initialValues: LegalFormData | null
) {
  let signatureFileDto;
  if (signatureImage && initialValues?.signatureImage !== signatureImage) {
    signatureFileDto = await magicFileUploadDtoFromBase64(
      signatureImage,
      MagicFileType.SIGNATURE_DOCUMENT,
      MagicFileType.SIGNATURE_DOCUMENT
    );
  }

  let identificationFileDto;
  if (identificationImage && initialValues?.identificationImage !== identificationImage) {
    identificationFileDto = magicFileUploadDtoFromFile(
      identificationImage,
      MagicFileType.IDENTIFICATION_DOCUMENT
    );
  }

  return [identificationFileDto, signatureFileDto].filter(Boolean) as MagicFileUploadDto[];
}

export const useGuestFlow = () => {
  const { patchGuestFlowStep } = useUpdateReservation();

  const navigate = useNavigate();
  const { reservation } = useReservationContext();

  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const skipButtonAction = useCallback(() => {
    if (isKioskMode()) {
      navigate(`/logout`);
    } else {
      navigate(generatePortalMyStayUrl(reservation.magicId));
    }
  }, [navigate, reservation.magicId]);

  const skipButton = useMemo(() => {
    return (
      <TopNavigationButton key="skipButton" onClick={skipButtonAction}>
        {t("buttons__logout")}
      </TopNavigationButton>
    );
  }, [t, skipButtonAction]);

  const contactButton = useMemo(() => {
    return <ContactButtonNavigation key="contactButton" propertyId={reservation.propertyId} />;
  }, [reservation.propertyId]);

  const cartButton = useMemo(() => {
    return <CartButtonNavigation key="cartButtonNavigation" magicId={reservation.magicId} />;
  }, [reservation.magicId]);

  const getProgress = useCallback(
    (curr: GuestFlowCheckpoint) =>
      (getFlowPages(reservation).findIndex((x) => x === curr) /
        getFlowPages(reservation).findIndex(
          (x) =>
            x ===
            (isTravelBuddy(reservation)
              ? GuestFlowCheckpoint.CONFIRMATION
              : GuestFlowCheckpoint.SERVICES)
        )) *
      100,
    [reservation]
  );

  const person = useMemo(() => {
    return reservation.primaryGuest || reservation.travelBuddy;
  }, [reservation]);

  return {
    reservation,
    patchGuestFlowStep,
    skipButton,
    skipButtonAction,
    cartButton,
    contactButton,
    getProgress,
    person,
    magicFileUploadDtoFromFile,
    magicFileUploadDtoFromBase64,
    prepareLegalFiles
  };
};
