import React, { FC, useCallback, useState } from "react";
import {
  AdditionalServicesAvailabilityResponse,
  Visibility
} from "../../../api/dto/additional-services-availability-response";
import { Reservation } from "../../../domain-common/reservation";
import { Box, Grid } from "@mui/material";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { AdditionalServiceModalHandler } from "./additional-service-modal-handler";
import { useAppDispatch } from "../../../state/store";
import {
  addToCart,
  removeFromCart,
  selectAllAdditionalServicesCartItems
} from "./additional-services-cart.slice";
import { calculateInteractionType } from "./additional-services.util";
import { AdditionalServiceButtonRow } from "./additional-service-button-row";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { useFetchAdditionalServices } from "../../services/use-fetch-additional-services";
import { useSelector } from "react-redux";
import { isTravelBuddy } from "../../../util/flow";
import { InteractionType } from "./aditional-interaction-type";

export const ServiceSelectionComponent: FC<
  React.PropsWithChildren<{
    reservation: Reservation;
    visibility: Visibility;
  }>
> = ({ reservation, visibility }) => {
  const [selectedService, setSelectedService] = useState<
    AdditionalServicesAvailabilityResponse | undefined
  >(undefined);
  const dispatch = useAppDispatch();

  const { magicId } = useMagicIdParams();
  const additionalServicesCartItems = useSelector(selectAllAdditionalServicesCartItems);

  const { selectableAdditionalServices } = useFetchAdditionalServices(
    magicId,
    visibility,
    isTravelBuddy(reservation)
  );
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });

  const onChangeBoolean = useCallback(
    (service: AdditionalServicesAvailabilityResponse) => {
      const isSelected = additionalServicesCartItems.find((serviceCartItem) => {
        return serviceCartItem.serviceId === service.serviceId;
      });

      if (!isSelected) {
        dispatch(
          addToCart({
            serviceId: service.serviceId,
            code: service.code,
            dates: service.bookableDates?.map((date) => ({
              ...date,
              count: 1
            }))
          })
        );
      } else {
        dispatch(removeFromCart({ serviceId: service.serviceId }));
      }
    },
    [dispatch, additionalServicesCartItems]
  );

  const handleServiceSelect = (service: AdditionalServicesAvailabilityResponse) => {
    if (calculateInteractionType(service) === InteractionType.BOOLEAN) {
      onChangeBoolean(service);
    } else {
      setSelectedService(service);
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {selectableAdditionalServices.map((additionalService) => (
          <AdditionalServiceButtonRow
            key={additionalService.serviceId}
            reservation={reservation}
            onSelectService={handleServiceSelect}
            service={additionalService}
          />
        ))}
        {selectableAdditionalServices.length === 0 && (
          <Box mt={3} px={5}>
            <Paragraph align="center"> {t("labels__no_services")}</Paragraph>
          </Box>
        )}
      </Grid>

      {selectedService && (
        <AdditionalServiceModalHandler
          reservation={reservation}
          selectedService={selectedService}
          clearSelectedService={() => setSelectedService(undefined)}
        />
      )}
    </>
  );
};
